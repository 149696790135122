<div class="card" title="Database">
    <div class="content-tab" style="display: unset; margin-left:20px;">
        <div *ngFor="let item of arrAutoData; let i = index" style="padding-bottom: 0.75rem;">
            <app-core-checkbox (chkChange)="onChkChangeImportAutoData($event,item)" style="margin-top:5px;"
                [hasFormCtrl]="false" [(valueModel)]="item.checked" [chkLabel]="item.dbName"
                [typeValue]="'boolean'"></app-core-checkbox>
        </div>
    </div>
</div>
<div class="btn-panel-class">
    
    <app-button [labelName]="'OK' | translate " style="margin-left:5px; margin-top: 10px;"
        (click)="close()" [typeButton]="'save'">
    </app-button>
</div>
<app-loading [class.hidden]="!loading"></app-loading>