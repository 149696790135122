<div class="run-bpmn-class">
  <div class="infomation">
    <div class="content-infomation">
      <div class="lab-info">{{'Job' | translate}}</div>
      <div class="btn-job">
        <div class="btn-job-btn" (click)="isShowIma?backStepWorkflow():''"
          [ngClass]="{'hiddenImage': isShowIma === false, 'show-image': isShowIma === true }">
          <i class="pi pi-arrow-left"></i>
          <label>{{ 'Back step' | translate}}</label>
        </div>
        <div style="margin-right: 0;" class="btn-job-btn" (click)="isShowIma?onCheckStepApprove():''"
          [ngClass]="{'hiddenImage': isShowIma === false, 'show-image': isShowIma === true }">
          <i class="pi pi-send"></i>
          <label>{{labSend | translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <p-tabView (onChange)="tapChange($event)">
    <p-tabPanel header="{{'Detail' | translate }}">
      <div style="height: 100%; width: 100%;">
        <app-core-window #coreWindow [isMapApp]="true" [windowId]="windowId" [whereWf]="whereWf"
          (createDynamicEvent)="onCoreWindowEmitEvent($event)" [isWfApp]="true"></app-core-window>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{'Job' | translate }}">
      <div class="wp-job">
        <div class="wp-form" class="col-3" style="height: 600px; overflow: auto;">
          <div [formGroup]="formData">
            <ng-container *ngFor="let field of fieldList">
              <ng-container *ngIf="field.FIELD_SHOW == 'Y'">
                <ng-container [ngSwitch]="field.FIELD_TYPE">
                  <ng-container *ngSwitchCase="'text'">
                    <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                      [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED"></app-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'number'">
                    <app-input [type]="'number'" [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                      [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED">
                    </app-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'date'">
                    <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                      [disabledCtrl]="field.FIELD_DISABLED" [formControlName]="field.FIELD_NAME"
                      [calConfig]="{ showTime: false }"></app-core-datetime>
                  </ng-container>
                  <ng-container *ngSwitchCase="'datetime'">
                    <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                      [disabledCtrl]="field.FIELD_DISABLED" [formControlName]="field.FIELD_NAME"
                      [calConfig]="{ showTime: true }"></app-core-datetime>
                  </ng-container>
                  <ng-container *ngSwitchCase="'select'">
                    <app-select [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                      [formControlName]="field.FIELD_NAME" [dataLookup]="lookupCondition[field.FIELD_NAME]"
                      displayField="DESCR" [disabledCtrl]="field.FIELD_DISABLED" valueField="CODE">
                    </app-select>
                  </ng-container>
                  <ng-container *ngSwitchCase="'text-button'">
                    <app-input [required]="false" [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                      [formControlName]="field.FIELD_NAME" type="text-button" (iconClick)="selectUser()"></app-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'T'">
                    <app-input [type]="'text-area'" [labelName]="field.FIELD_LABEL"
                      [disabledCtrl]="field.FIELD_DISABLED" [isLabelLeft]="false"
                      [formControlName]="field.FIELD_NAME"></app-input>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <div>{{field.FIELD_TYPE}} : {{field.FIELD_LABEL}}</div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <!-- <div class="btn-save">
                      <app-button [labelName]="'Save' | translate" [ngClass]="{'hiddenImage': isShowIma === false, 'show-image': isShowIma === true }"  typeButton="save" icon='pi pi-save' style="margin-left: 10px;"
                      (click)="isShowIma?onClickSave(true, false):''"></app-button>
                  </div> -->
        </div>
        <div class="wp-workflow col-9">
          <div class="wp-diagram">
            <div class="canvas">
              <div #divDiagram class="diagram"></div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{'History' | translate }}">
      <div class="history">
        <app-core-basic-table [dataTable]="tableDataHistory" displayField='key' [tableConfig]="tableConfig"
          [hasTool]="false" [toolConfig]="toolTableConfig" [columnTable]="tableRowDefination1"></app-core-basic-table>
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template #templateNextStep>
    <div class="wp-nextstep" [formGroup]="formDataStep">
      <app-select nz-row [nzSpan]="nzSpan" [required]="true" [labelName]="'Step' | translate" [isLabelLeft]="false"
        formControlName="nextStep" [dataLookup]="arrNextStep" displayField="DESCR" valueField="CODE"
        [isAppendBody]="false"></app-select>
      <app-core-radio-button [listRadio]="listRadio" formControlName="assignOwner"></app-core-radio-button>
      <div *ngIf="isShowUserNextStep">
        <app-input [required]="isRequiredAssignedTo1" [disabledCtrl]="isBlockAssign" [readonly]="isBlockAssign"
          [labelName]="'Assign to' | translate" [isLabelLeft]="false" formControlName="assignedTo1" type="text-button"
          (iconClick)="selectUser()" [maxlength]="1000"></app-input>
      </div>
      <app-input type="text-area" nz-row [nzSpan]="nzSpan" [labelName]="'Note' | translate" [isLabelLeft]="false"
        formControlName="note1"></app-input>
    </div>
    <div style="margin-top: 15px; text-align: right;">
      <app-button labelName='Cancel' typeButton="cancel" (clickEvent)="onCancelStep()"></app-button>
      <app-button labelName='OK' typeButton="save" style="margin-left: 10px;"
        (clickEvent)="onAcceptStep()"></app-button>
    </div>
  </ng-template>
  <ng-template #tempUser>
    <div style="padding-bottom: 10px;">
      <app-core-tree [dataTree]="dataUser" [nodeSelectedModel]="defaultCheckedUser" [treeConfig]="treeConfigUser"
        (selectNode)="onCheckUser($event)" (unSelectNode)="onUnCheckUser($event)"></app-core-tree>
    </div>
    <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
      <app-button labelName='Cancel' typeButton="cancel" (clickEvent)="onCancelUser()"></app-button>
      <app-button labelName='OK' typeButton="save" style="margin-left: 10px;" (clickEvent)="onAddUser()"></app-button>
    </div>
  </ng-template>
  <ng-template #tempApprove>
    <div [formGroup]="formApprove">
      <app-select nz-row [nzSpan]="nzSpan" [required]="true" [labelName]="'Approve' | translate " [isLabelLeft]="false"
        formControlName="typeApprove" [dataLookup]="arrSelectApprove" displayField="DESCR" valueField="CODE"
        [isAppendBody]="false"></app-select>
      <app-input type="text-area" nz-row [nzSpan]="nzSpan" [labelName]="'Note'" [isLabelLeft]="false"
        formControlName="noteApprove"></app-input>

      <div class="divBtnClass" style="margin-top: 15px; padding: 0px; align-items: flex-end;">
        <app-button labelName='Cancel' typeButton="cancel" (clickEvent)="onCancelApprove()"></app-button>
        <app-button labelName='Ok' typeButton="save" style="margin-left: 10px;"
          (clickEvent)="onConfirmApprove()"></app-button>
      </div>
    </div>

  </ng-template>
  <app-dialog-prime #dialogPrime [isAppendBody]="true"></app-dialog-prime>
  <app-dialog-prime #dialogUser [isAppendBody]="true"></app-dialog-prime>
</div>