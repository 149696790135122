import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { CoreSearchComponent } from '../core-search/core-search.component';
import { CoreWindowComponent } from '../core-window';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';
import { RequestService } from '../services/request.service';

import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-input-search',
    templateUrl: './input-search.component.html',
    styleUrls: ['./input-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR
            , useExisting: forwardRef(() => InputSearchComponent)
            , multi: true
        },
        RequestService
    ]
})
export class InputSearchComponent implements OnInit, OnChanges, ControlValueAccessor {
    @ViewChild('dialogComponent', { static: true }) dialogPrime!: DialogPrimeComponent;
    @Output() zoomClick: EventEmitter<any> = new EventEmitter();
    @Output() Event: EventEmitter<any> = new EventEmitter();
    @Output() valueModelChange: EventEmitter<any> = new EventEmitter();
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    
    ctrl: FormControl = new FormControl();

    @Input() required = false;
    @Input() disabledCtrl = false;
    @Input() showLabel = true;
    @Input() labelName = '';
    @Input() labelWidth = '200';
    @Input() placeholder = '';
    @Input() isLabelLeft = true;
    @Input() allowClear = true;
    @Input() maxlength = '200';
    @Input() dataSource: any = null;
    @Input() hasFormCtrl: boolean = true;
    @Input() hasButton: boolean = false
    @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
    @Output() returnObj: EventEmitter<any> = new EventEmitter()
    private _isReadOnly = false;
    @Input('isReadOnly')
    set isReadOnly(val: boolean) {
        this._isReadOnly = val;
        if (this.ctrl) {
            // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }
    }
    get isReadOnly() {
        return this._isReadOnly;
    }
    _valueModel: any = null;
    @Input()
    get valueModel() {
        return this._valueModel;
    }

    set valueModel(value) {
        if (this.required) {
            if (value !== null && value !== '') {
                this.requiredClass = false;
            } else {
                this.requiredClass = true
            }
        } else {
            this.requiredClass = false
        }
        if (this.isBindData && value) {
            this.searchWithValue(value)
        }

        // this._valueModel = value;
        // this.valueModelChange.emit(this._valueModel);
    }
    isBindData: any = true
    public dataDescription: any = '';
    public dataLutFilter: any = null;
    public requiredClass = false;

    private windowId = null;
    private type = 'search'; // Kiểu nhập dữ liệu: 'search' là mở form tìm kiếm, 'input' là nhập tay trên control
    private initFirstTime = true; // Đánh đấu khởi tạo ban đầu
    private defaultWhere: any = null; // Điều kiện lọc khi có relate-field (trường hợp cha con)

    private onChange: (value: any) => void = () => { };
    private onTouched: () => void = () => { };

    constructor(
        private appService: AppService,
        private reqService: RequestService,
        private renderer: Renderer2,
        // public fillterInputService: FillterInputService
    ) { }

    ngOnInit() {
        this.initForm();
        this.switchTypeOfRequestService();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabledCtrl && this.ctrl) {
            this.disabledCtrl ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }

        if (changes.required && this.ctrl && this.initFirstTime) {
            if (this.ctrl) {
                this.requiredClass = true;
                this.ctrl.setValidators(this.required === true ? [Validators.required] : [Validators.nullValidator]);
            }
        }

        if (changes.dataSource && this.initFirstTime) {
            this.init();
        }

        this.type = 'search';
    }

    queryWindow() {
        this.reqService.switchType(this.appService.dataAccessType);
        return this.reqService.service.search({
            url: this.appService.urlWS + '/SysWindows',
            where: [
                'WindowId', '=', this.dataSource.FOREIGNWINDOW_ID
            ],
            logic: 'and'
        })
    }

    public openWindowToAdd() {
        if (this.disabledCtrl || this.isReadOnly) {
            return;
        }

        this.queryWindow().subscribe((res: any) => {
            if (res.success) {
                if (res.features.length > 0) {
                    if (res.features[0].WindowType === 'PLUGIN') {
                        this.btnCalcClick.emit()
                    } else {
                        const configForm = {
                            windowId: this.dataSource.FOREIGNWINDOW_ID,
                            isMapApp: false,
                            isActive: true,
                            isShowBtnChoose: true
                        };

                        const dialogReturn: CoreWindowComponent = this.dialogPrime.showDialog(CoreWindowComponent, configForm, 'Addition');
                        dialogReturn.isShowBtnChoose = true; // kích hoạt tay
                        dialogReturn.initData(); // kích hoạt tay

                        dialogReturn.rowEmit.subscribe(res => {
                            if (res) {
                                this.dialogPrime.closeDialog();
                                this.type = 'search';
                                this.dataDescription = this.dataSource.COLUMNDISPLAY ? res[this.dataSource.COLUMNDISPLAY] : null;
                                this.returnObj.emit(res)
                                const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
                                this.ctrl.setValue(res[tag]);
                                this.requiredClass = false;
                                this.onChange(res[tag]);

                            }
                        });
                    }
                }
            }
        })


    }

    public onSelectChange(event: any) {
        if (event && event.DATA) {
            this.onChange(event.DATA);
        } else {
            this.onChange(null)
        }
    }

    private initForm() {
        this.ctrl = new FormControl('', {
            updateOn: 'blur',
            validators: this.required === true ? Validators.required : Validators.nullValidator
        });

        this.isReadOnly ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });

        if (this.required) {
            this.requiredClass = true;
        }

        this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
            if (this.type !== 'input') {
                this.dataDescription = val ? val[this.dataSource.COLUMNDISPLAY] : null
                if (val === null || val === '') {
                    this.requiredClass = this.required;
                    // this.onChange(null)
                    // this.returnObj.emit(null); // Không cần onChange ở đây vì hàm onAutoCompleteBlur cũng đã emit onChange null rồi
                } else {
                    this.requiredClass = false;
                }
            }
        });
    }

    private switchTypeOfRequestService() {
        switch (this.dataSource.SERVICE_TYPE) {
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgrest':
                this.reqService.switchType('postgre');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }
    }

    private init() {
        this.getDefaultWhere(null);

        // Chỉnh sửa theo yêu cầu của anh Vinh - ngày 25/09/2023
        this.switchTypeOfRequestService();
        this.windowId = this.dataSource.FOREIGNWINDOW_ID;
        if (!this.dataSource.FOREIGNTABLE || this.dataSource.FOREIGNTABLE === '') {
            return;
        }
        let _where: any[] = [];
        if (this.defaultWhere) {
            _where.push(this.defaultWhere);
        }
        this.reqService.service.search({
            url: this.dataSource.FOREIGNTABLE,
            where: _where
        }).subscribe(res => {
            this.dataLutFilter = res;
        });

        // this.reqService.switchType(this.appService.dataAccessType);
        // this.reqService.service.search({
        //     url: this.appService.urlWS + '/svwindowtabs',
        //     where: [
        //         ['WindowType', '=', 'SEARCH'],
        //         ['TabLevel', '=', 0],
        //         ['OrderNo', '=', 1],
        //         ['TableId', '=', this.dataSource.FOREIGNTABLE_ID]
        //     ],
        //     logic: 'and'
        // }).subscribe(res => {
        //     this.switchTypeOfRequestService();
        //     if (res.success) {
        //         if (res.features[0]) {
        //             this.windowId = res.features[0].WindowId;
        //         }
        //     }
        // }, err => {
        //     this.switchTypeOfRequestService();
        // });
    }

    onOpenForm() {
        if (this.disabledCtrl || this.isReadOnly) {
            return;
        }

        const configForm = {
            windowId: this.windowId,
            isMapApp: false,
            isActive: true,
            defaultWhere: this.defaultWhere
        };

        const dialogReturn: CoreSearchComponent = this.dialogPrime.showDialog(CoreSearchComponent, configForm, 'Search');
        dialogReturn.initWindow(); // kích hoạt tay
        dialogReturn.closeDialog.subscribe(res => {
            this.dialogPrime.closeDialog();
        });

        dialogReturn.rowEmit.subscribe(response => {
            response.forEach((res: any) => {
                if (res) {
                    this.type = 'search';
                    this.dataDescription = res[this.dataSource.COLUMNDISPLAY];
                    const descr = this.dataSource.COLUMNDISPLAY ?? this.dataSource.COLUMNCODE;
                    res['DESCR'] = res[descr];
                    if (this.hasFormCtrl) {
                        this.returnObj.emit(res)
                        this.ctrl.setValue(res);
                        this.requiredClass = false;
                        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
                        this.onChange(res[tag]);

                    } else {
                        this._valueModel = res
                        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
                        this.valueModelChange.emit(res[tag])
                        this.valueChange.emit()
                    }

                }
            });

            this.dialogPrime.closeDialog();

        });
    }

    results: any = [];
    search(evt: any) {
        this.type = 'input';
        this.requiredClass = this.required;
        const url = this.dataSource.FOREIGNTABLE;
        this.switchTypeOfRequestService();
        const tag = this.dataSource.COLUMNCODE ? this.dataSource.COLUMNCODE : this.dataSource.COLUMNKEY;
        const where = ['and', [tag, 'like', evt.query]];
        if (this.defaultWhere) {
            where.push(this.defaultWhere);
        }
        this.results = [];
        this.reqService.service.search({
            url,
            where
        }).subscribe((res: any) => {
            if (res.success) {
                const arr: any = [];
                const descr = this.dataSource.COLUMNDISPLAY ?? this.dataSource.COLUMNCODE;
                res.features.forEach((item: any) => {
                    item.DESCR = item[descr];
                    arr.push(item);
                });
                this.results = [...arr]
            }
        });
    }

    searchWithValue(_value: any) {
        const url = this.dataSource.FOREIGNTABLE;
        this.switchTypeOfRequestService();
        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
        const where = [[tag, '=', _value]]
        this.reqService.service.search({
            url,
            where
        }).subscribe((res: any) => {
            if (res.success && res.features.length > 0) {
                this.dataDescription = res.features[0][this.dataSource.COLUMNDISPLAY];
                const descr = this.dataSource.COLUMNDISPLAY ?? this.dataSource.COLUMNCODE;
                res.features[0]['DESCR'] = res.features[0][descr];
                this.isBindData = false;
                this._valueModel = res.features[0];
            }
        });
    }

    onAutoCompleteBlur(event: any) {
        if (event && event.target) {
            if (event.target.value === '') {
                this.type = 'input';
                this.dataDescription = undefined;
                this.returnObj.emit(null);
                this.onChange(null)

                this.requiredClass = this.required;
            }
        }
    }

    onDropdownClick(evt: any) {
        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
        this.type = 'search'; // khi chọn 1 bản ghi thì chuyển sang type search
        this.returnObj.emit(evt)
        this.ctrl.setValue(evt);
        this.dataDescription = evt ? evt[this.dataSource.COLUMNDISPLAY] : undefined;

        this.onChange(evt[tag]);

        this.Event.emit(evt[tag]);
    }

    /** Đặt giá trị cho control độc lập, không phụ thuộc vào control khác */
    private setValueForSingleControl(value: any) {
        if (value && typeof (value) === 'object') {
            this.type = 'input';
            if (value[this.dataSource.FIELD_NAME]) {
                this.switchTypeOfRequestService();
                const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
                this.reqService.service.search({
                    url: this.dataSource.FOREIGNTABLE,
                    where: [tag, '=', value[this.dataSource.FIELD_NAME]],
                    logic: 'and'
                }).subscribe(res => {
                    if (res.success) {
                        if (res.features.length > 0) {
                            const obj = res.features.find((v: any) => v[tag] === value[this.dataSource.FIELD_NAME])
                            if (obj) {
                                const descr = this.dataSource.COLUMNDISPLAY ?? this.dataSource.COLUMNCODE;
                                obj['DESCR'] = obj[descr]
                                this.dataDescription = this.dataSource.COLUMNDISPLAY ? obj[this.dataSource.COLUMNDISPLAY] : null
                                this.returnObj.emit(obj)
                                this.ctrl.setValue(obj, { emitEvent: false });
                                this.requiredClass = false;
                                this.onChange(obj[tag]);

                            } else {
                                this.returnObj.emit(null);
                                this.ctrl.setValue(null, { emitEvent: false });
                                this.onChange(null)

                                this.dataDescription = undefined;
                                this.requiredClass = true;
                            }
                        } else {
                            this.returnObj.emit(null);
                            this.ctrl.setValue(null, { emitEvent: false });
                            this.onChange(null)

                            this.dataDescription = undefined;
                            this.requiredClass = true;
                        }
                    }
                });
            } else {
                this.returnObj.emit(null);
                this.ctrl.setValue(null, { emitEvent: false });
                this.dataDescription = null;
                this.onChange(null)

            }
        } else {
            this.type = 'search';
            this.dataDescription = null;
            // this.ctrl.setValue(value);

            if (value) { // có value, ví dụ '0028'
                const url = this.dataSource.FOREIGNTABLE;
                this.switchTypeOfRequestService();
                const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
                const where = [[tag, '=', value]];
                this.reqService.service.search({
                    url,
                    where
                }).subscribe((res: any) => {
                    if (res.success && res.features.length > 0) {
                        this.dataDescription = res.features[0][this.dataSource.COLUMNDISPLAY];
                        const descr = this.dataSource.COLUMNDISPLAY ?? this.dataSource.COLUMNCODE;
                        res.features[0]['DESCR'] = res.features[0][descr];
                        this.returnObj.emit(res.features[0]);
                        this.ctrl.setValue(res.features[0]);
                        this.onChange(res.features[0][tag]);
                    }
                });
            } else {
                this.ctrl.setValue(value);
            }
        }

        if (this.required) {
            if (value === null || value === '') {
                this.requiredClass = true;
            } else {
                this.requiredClass = false;
            }
        } else {
            this.requiredClass = false;
        }
    }

    private setValueNull() {
        this.returnObj.emit(null)
        this.ctrl.setValue(null, { emitEvent: false });
        this.dataDescription = undefined;
        this.requiredClass = true;
        this.onChange(null);
    }

    private setValueFromParent(value: any) {
        if (value === undefined || value === null) {
            this.resetControl();
            return;
        }

        this.getDefaultWhere(value)
        this.returnObj.emit(null);
        this.onChange(null)

        if (this.initFirstTime) {
            this.switchTypeOfRequestService()
            this.reqService.service.search({
                url: this.dataSource.FOREIGNTABLE,
                where: this.defaultWhere,
                logic: 'and'
            }).subscribe(res => {
                if (res.success) {
                    if (res.features.length > 0) {
                        if (value.hasOwnProperty('___isBindValue')) {
                            const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
                            const obj = res.features.find((v: any) => v[tag] === value[this.dataSource.FIELD_NAME]);
                            if (obj) {
                                const descr = this.dataSource.COLUMNDISPLAY ?? this.dataSource.COLUMNCODE;
                                obj['DESCR'] = obj[descr]
                                this.dataDescription = this.dataSource.COLUMNDISPLAY ? obj[this.dataSource.COLUMNDISPLAY] : null
                                this.returnObj.emit(obj)
                                this.ctrl.setValue(obj, { emitEvent: false });
                                this.requiredClass = false;
                                this.onChange(obj[tag]);
                                // this.ctrl.setValue(obj, { emitEvent: false });
                                // this.requiredClass = false;
                                // this.onChange(obj);

                                return;
                            }
                        }
                    }

                    this.setValueNull();

                    if (this.dataSource.BINDFIELDNAME && this.dataSource.BINDFIELDNAME !== '') {
                        // trường hợp relate-field: set thẳng giá trị từ 1 trường trong obj trả về từ control cha
                        this.getLookupWithBindFieldName(value, res);
                        return;
                    }
                }
            })
        }
    }

    getLookupWithBindFieldName(value: any, res: any) {
        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
        if (!res || !res.features) {
            return;
        }
        const obj = res.features.find((v: any) => v[tag] === value[this.dataSource.BINDFIELDNAME]);
        if (obj) {
            const descr = this.dataSource.COLUMNDISPLAY ?? this.dataSource.COLUMNCODE;
            obj['DESCR'] = obj[descr]
            this.dataDescription = this.dataSource.COLUMNDISPLAY ? obj[this.dataSource.COLUMNDISPLAY] : null
            this.returnObj.emit(obj)
            this.ctrl.setValue(obj, { emitEvent: false });
            this.requiredClass = false;
            this.onChange(obj[tag]);

            return;
        }
    }

    /** Clear toàn bộ dữ liệu trong control */
    resetControl() {
        this.defaultWhere = null;
        this.dataDescription = null;
        this.requiredClass = this.required;
        this.returnObj.emit(null);
        if (this.hasFormCtrl) {
            this.ctrl.setValue(null, { emitEvent: false });
        } else {
            this.valueModel = null;
        }
        this.onChange(null)

    }

    /** Tạo default where */
    private getDefaultWhere(val: any = null) {
        let where: any = [];
        if (val !== null && val !== undefined) {
            where.push([this.dataSource.WHEREFIELDNAME, '=', val[this.dataSource.WHEREFIELDNAME]]);
        }

        if (this.dataSource.WHERECLAUSE !== null && this.dataSource.WHERECLAUSE !== undefined) {
            const jsonWhere = JSON.parse(this.dataSource.WHERECLAUSE);
            where.push(jsonWhere);
        }

        if (where.length === 0) {
            where = null;
        }

        this.defaultWhere = where;
    }

    // tslint:disable-next-line:variable-name
    writeValue(_value: any): void {
        if (this.dataSource) {
            if (this.dataSource.FIELD_PARENT_ID === null || this.dataSource.FIELD_PARENT_ID === undefined) {
                // Trường hợp đứng 1 mình
                this.setValueForSingleControl(_value);
                return;
            }

            if (this.dataSource.WHEREFIELDNAME && this.dataSource.WHEREFIELDNAME !== '') {
                // trường hợp relate-field: lọc lookup từ giá trị của control cha
                this.setValueFromParent(_value);
                return;
            }

            if (this.dataSource.BINDFIELDNAME && this.dataSource.BINDFIELDNAME !== '') {
                // trường hợp relate-field: set thẳng giá trị từ 1 trường trong obj trả về từ control cha
                this.getLookupWithBindFieldName(_value, this.dataLutFilter);
                return;
            }
        }
    }



    registerOnChange(fn: (value: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

}