import {
    AfterContentInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
    SimpleChanges,
    EventEmitter,
    OnInit,
    ComponentFactoryResolver,
    ViewContainerRef,
    Type,
    TemplateRef,
    ChangeDetectorRef
} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { BpmnModeler } from' bpmn-js/lib/Modeler';
// import BpmnViewer from 'bpmn-js/lib/Viewer';
import { TranslateService } from '@ngx-translate/core';
import * as converter from 'xml-js';
import { combineLatest, from, Observable } from 'rxjs';
// import * as BpmnJS from 'bpmn-js/dist/bpmn-modeler.production.min.js';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { CONFIG_FIELD_HISTORY } from '../run-job/array-field-history';
import { ActivatedRoute } from '@angular/router';
import { parse } from 'zipson/lib';
import { RequestService } from 'app/core/services/request.service';
import { AuthService } from 'app/app-base/auth.service';
import { toolTableConfig } from 'app/app-base/interface/app-config';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';
import { CoreWindowComponent } from 'app/core/core-window';
import { NotificationService } from 'app/notification/generated';
declare var require: any
let BpmnViewer = require('bpmn-js/lib/Viewer');
let BpmnModeler = require('bpmn-js/lib/Modeler');

@Component({
    selector: 'app-run-bpmn-workflow',
    templateUrl: './run-bpmn-workflow.component.html',
    styleUrls: ['./run-bpmn-workflow.component.scss']
})
export class RunBPMNComponent implements OnInit, OnDestroy {
    // @ViewChild('containerRef', { static: false, read: ViewContainerRef })
    @ViewChild('formSelectStep', { static: true }) formSelectStep!: TemplateRef<any>;
    @ViewChild('dialogPrime', { static: true }) dialogPrime!: DialogPrimeComponent;
    @ViewChild('dialogUser', { static: true }) dialogUser!: DialogPrimeComponent;
    @ViewChild('divDiagram') private divDiagram!: ElementRef;
    @ViewChild('templateNextStep') templateNextStep!: TemplateRef<any>;
    @ViewChild('tempUser') tempUser!: TemplateRef<any>;
    @ViewChild('tempApprove') tempApprove!: TemplateRef<any>;
    @ViewChild('coreWindow') coreWindow!: CoreWindowComponent
    createDynamicEvent: EventEmitter<any> = new EventEmitter();


    // tslint:disable-next-line: no-output-on-prefix
    @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
    createTblFinish: EventEmitter<any> = new EventEmitter();
    private createTblHisFinish: EventEmitter<any> = new EventEmitter();
    public w$: any = 'w$';
    indexTap = 0;
    whereWf:any = null;
    public tableRowDefination:any = [];
    public pageIndex = 1;
    public tableDataSource = [];
    pageSize = 10;
    isOpenFormAddWf = true;
    public mode = 'table';
    arrNextStep:any = [];
    arrSelectApprove:any = [];
    isNextStep = true;
    currentJobType:any = null;
    defaultCheckedUser:any = [];
     strCheckedUser: any = '';
     dataUser = [];
     treeConfigUser: any = {
        selectionMode: 'checkbox',
        filter: false,
        virtualScroll: false,
    }

    currentData:any = null;
    public formData: FormGroup = new FormGroup({});
    public formDataStep!: FormGroup;
    public formApprove!: FormGroup;
    public isVisible:boolean = false;
    public urlImage = '';
    public divCurentStep = '';
    public isShowIma = false;
    bpmnJS: any;
    public tableDataHistory:any = [];
    public tableRowDefination1:any = [];
    public nzSpan = 24;
    public fieldList:any = [];
    configColumHis:any = CONFIG_FIELD_HISTORY;
    labSend = 'Send';
    arrTypeHis:any = { 1: 'Chấp nhận', 2: 'Gửi đi', 3: 'Trả lại', 4: 'Chuyển tiếp', 5: 'Hoàn thành', 6:'Approve', 7: 'Un approve'};
    arrTab: any = [{
        code: 'workflow',
        title: 'Workflow'
    }, {
        code: 'detail',
        title: 'Detail'
    }, {
        code: 'history',
        title: 'History'
    }];
    tableConfig = {
        scrollHeight: "auto",
        responsiveLayout: 'scroll',
        styleClass: "p-datatable-gridlines",
        paginator: true,
        rows: 20
    }
    toolTableConfig: toolTableConfig = {
        isAdd: true,
        isDelete: true,
        isEdit: true,
        isSave: true,
        isSearch: false
    }
    public listRadio = [
        { CODE: '1', DESCR: 'User' },
        { CODE: '2', DESCR: 'Owner' }
    ]

    private xml = `<?xml version="1.0" encoding="UTF-8"?>
    <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_00f3jo1" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="3.0.0-dev">
      <bpmn:process id="Process_03i8cud" isExecutable="true">
        <bpmn:startEvent id="StartEvent_1" />
      </bpmn:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_03i8cud">
          <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
            <dc:Bounds x="179" y="159" width="36" height="36" />
          </bpmndi:BPMNShape>
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </bpmn:definitions>`;

    dataConfig: any;
    loading!: boolean;
    formLoadFinish = false;
    currentStep: any;
    arrStep: any[] = [];
    diagram: any;
    currentStepOb: any;
    windowId: any;
    lookupCondition:any = {};
    arrJobtypeStep:any = [];
    clientId:any = null;
    public userid: any;
    percCompleJob = null;
    arrJobType:any = [];
    currentUser:any = null;
    stepApprove:any = null;
    dataUpdateField:any = null;
    stepExteralWorkflow:any = null;
    isShowUserNextStep = true;
    isRequiredAssignedTo1 = true;
    idNextStepSlect:any = null
    isBlockAssign: boolean = false

    dlgWidth = '900px';
    constructor(
        private appService: AppService,
        private workflowService: BpmnWorkflowService,
        private router: ActivatedRoute,
        private componentFactoryResolver: ComponentFactoryResolver,
        private reqService: RequestService,
        private authService: AuthService,
        private cd: ChangeDetectorRef,
        private http: HttpClient,
        public translate: TranslateService,
        private notifyService: NotificationService


    ) {
      // translate.addLangs(['vi', 'en']);
      // if (sessionStorage.getItem('language')) {
      //     translate.setDefaultLang(sessionStorage.getItem('language')!);
      //     translate.use(sessionStorage.getItem('language')!);
      //     // this.selectedValue = sessionStorage.getItem('language')!;
      // } else {
      //     translate.setDefaultLang('vi');
      //     sessionStorage.setItem('language', 'vi');
      //     // this.selectedValue = 'vi';
      // }

    }

    ngOnDestroy(): void {
        // this.onCloseEvent.emit(true);
    }

    ngOnInit(): void {
        if (localStorage) {
            const User = this.appService.currentUser;
            if (User !== null && User !== undefined) {
                this.currentUser = this.appService.convertStringToJson(User).username;
            }
        }

        // mobile wf
        const params:any = this.router.snapshot.paramMap;
        const windowId = eval(params['params']['windowId']);
        const jobId = eval(params['params']['jobId']);
        const username = params['params']['username'];
        const password = params['params']['password'];
        const clientId = eval(params['params']['clientId']);
        const appid = eval(params['params']['appId']);
        if (username && password && appid) {
            // Là ứng dụng bản đồ trên mobile
            // this.isWebApp = false;
            // this.loginMobile({ username, password, appid });
        } else {
            // Là ứng dụng bản đồ trên web
            // this.initWebMap();
        }

        if (windowId && jobId) {
            const paramJob = ['JobId', '=', jobId];
            const conFigWindow = this.workflowService.initData(windowId);
            const dataJob =  this.workflowService.queryJob(paramJob);
            const listReq = [conFigWindow, dataJob];
            combineLatest(listReq).subscribe((res: any) => {
                const result =  res[0].model.config ? parse(res[0].model.config) : null;
                const wdConfig = this.reqService.mapConfig(result);
                if (wdConfig.error) {
                    this.appService.alert(wdConfig.error, 'error');
                    return;
                }
                const objConfig = {dataSource: {FIELD_LIST: wdConfig.tabs[0].fields}, currentData: res[1].features[0]};
                if (res[1].features.length > 0) {
                    this.currentData =  res[1].features[0];
                    this.getWorkflowModel(this.currentData.JobTypeId);
                    this.queryJobtypeStep(this.currentData.JobTypeId);
                    this.dataLookUp(this.currentData.JobTypeId);
                }
                this.dataConfig = objConfig;
                this.currentUser = username;
                this.clientId = clientId;
            }, (err:any) => {
              console.log(err);
            });
        }

        this.lookupCondition['AssignedTo'] = [];
        this.formDataStep = new FormGroup({
            nextStep: new FormControl(),
            assignedTo1: new FormControl(),
            note1: new FormControl(),
            assignOwner: new FormControl()
        });
        this.formApprove = new FormGroup({
            typeApprove: new FormControl(),
            noteApprove: new FormControl()
        });

        this.formDataStep.controls['assignOwner'].valueChanges.subscribe(res => {
            console.log(res);
            if (res !== null) {
                if (res === '2' || res.CODE === '2') {
                    this.isRequiredAssignedTo1 = false;
                    this.cd.detectChanges()
                    this.isShowUserNextStep = false;

                } else {
                    this.isShowUserNextStep = true;
                    this.cd.detectChanges()
                    this.isRequiredAssignedTo1 = true

                }
                this.cd.detectChanges()
            }
        });

        this.formDataStep.controls['assignedTo1'].valueChanges.subscribe(res => {
            if (res !== null) {

            }
        })

    }
    public run(evt:any) {
        this.currentData = evt.currentData[evt.currentData.length -1];
        if (evt.isMobile) {
            // const jobid = this.currentData.JobId;
            // const windowId = evt.WindowId;
        } else {
            this.init(evt);
        }
    }

    init(evt:any){
        if (this.currentData !== null){
            console.log(evt);
            const urlJob = evt.dataSource.INFORMATION.URL_EDIT;
            if (urlJob !== null && urlJob !== '') {
                let i = urlJob.indexOf('/WFJOBs');
                let url1 = urlJob.substr(0, i);
                this.workflowService.url = url1 + '/';

                this.cd.detectChanges();
                this.clientId = this.appService.ClientId;
                const user = this.appService.currentUser;
                this.userid = user ?  JSON.parse(user).userid : null;

                this.dataConfig = evt;
                this.getWorkflowModel(this.currentData.JobTypeId);
                this.queryJobtypeStep(this.currentData.JobTypeId);
                this.dataLookUp(this.currentData.JobTypeId);
                this.bpmnJS = new BpmnViewer.default({
                    container: this.divDiagram.nativeElement,
                    height: 1000,
                    width: 1500
                });
                this.tabInfo();
                this.tabHis();

            }

            // this.importDiagram(this.xml);
        } else {
            this.onCloseEvent.emit(false);
        }
    }

    udAssignStatus(job:any){
        const params = ['JobId', '=', job.JobId];
        this.workflowService.queryJob(params).subscribe(res => {
            if (res.success) {
                const paramsJob = res.features[0];
                paramsJob.AssignStatusId = 1;
                this.workflowService.updateJob(paramsJob).subscribe(item => {
                }, err => {
                    this.appService.notification('Đã xảy ra lỗi', 'error');
                });
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    getJob(jobId:any) {
        const params = ['JobId', '=', jobId];
        this.workflowService.queryJob(params).subscribe(res => {
            if (res.success && res.features.length > 0) {
                this.currentData = res.features[0];
                this.getWorkflowModel(this.currentData.JobTypeId);
                this.queryJobtypeStep(this.currentData.JobTypeId);
            }
        });
    }

    dataLookUp(JobTypeId:any) {
        const params = ['ClientId', '=', this.clientId];
        const paramsJobTypeUser = ['JobTypeId', '=', JobTypeId];

        const arrStatus = this.workflowService.querySatus();
        const arrUser = this.workflowService.queryJobtypeUser(paramsJobTypeUser);
        const arrJobtype = this.workflowService.queryJobtype(params);
        const arrAssignStatus = this.workflowService.queryAssignStatus([]);
        const arrPriority = this.workflowService.queryPriority([]);
        const list = [arrStatus, arrUser, arrJobtype, arrAssignStatus, arrPriority];
        combineLatest(list).subscribe((res: any) => {
            this.lookupCondition['JobStatusId'] = [];
            this.lookupCondition['AssignedTo'] = [];
            this.lookupCondition['JobTypeId'] = [];
            this.lookupCondition['AssignStatusId'] = [];
            this.lookupCondition['PriorityId'] = [];

            if (res[0].success) {
                res[0].features.forEach((item: { JobStatusId: any; StatusName: any; }) => {
                    this.lookupCondition['JobStatusId'].push({
                        CODE: item.JobStatusId,
                        DESCR: item.StatusName
                    });
                });
            }
            if (res[1].success) {
                res[1].features.forEach((item: { Username: any; }) => {
                    this.lookupCondition['AssignedTo'].push({
                        CODE: item.Username,
                        DESCR: item.Username
                    });
                });
            }
            if (res[2].success) {
                this.arrJobType = res[2].features;
                res[2].features.forEach((item: { JobTypeId: any; JobTypeName: any; }) => {
                    this.lookupCondition['JobTypeId'].push({
                        CODE: item.JobTypeId,
                        DESCR: item.JobTypeName
                    });
                });
            }

            if (res[3].success) {
                res[3].features.forEach((item: { AssignStatusId: any; Description: any; }) => {
                    this.lookupCondition['AssignStatusId'].push({
                        CODE: item.AssignStatusId,
                        DESCR: item.Description
                    });
                });
            }

            if (res[4].success) {
                res[4].features.forEach((item: { PriorityId: any; Description: any; }) => {
                    this.lookupCondition['PriorityId'].push({
                        CODE: item.PriorityId,
                        DESCR: item.Description
                    });
                });
            }
            this.lookupCondition['SendFrom'] =  this.lookupCondition['AssignedTo'];
            this.initForm();

        });
    }

    initForm() {
        this.loading = true;
        this.fieldList = [];
        this.formData.reset();
        this.dataConfig.dataSource.FIELD_LIST.forEach((item: any) => {
            if (item.fieldname === 'JobTypeId' || item.fieldname === 'JobStatusId' || item.fieldname === 'AssignStatusId') {
                item.disabledCtrl = true;
            }
            if (item.fieldname === 'AssignedTo') {
                item.fieldtype = "text-button";
            }
            this.fieldList.push({
                FIELD_NAME: item.fieldname,
                FIELD_LABEL: item.alias,
                FIELD_TYPE: item.fieldtype,
                FIELD_ORDER: item.orderno,
                FIELD_SHOW: item.isdisplay,
                FIELD_ISSEARCH: item.issearch,
                FIELD_GROUPTYPE: item.fieldgroup,
                FIELD_TABLELINK: item.domainid,
                FIELD_DISABLED: item.disabledCtrl
            });
        });

        this.fieldList = this.fieldList.sort((a: { FIELD_ORDER: number; }, b: { FIELD_ORDER: number; }) => a.FIELD_ORDER - b.FIELD_ORDER);
        const group:any = {};
        this.fieldList.forEach((item:any) => {
            group[item.FIELD_NAME] = new FormControl();
        });

        const currentLang:string = 'vi'

        this.fieldList.forEach((field: any) => {
          try {
              const descr = JSON.parse(field.FIELD_LABEL);
              if (descr) {
                const alias = descr[currentLang];
                field.FIELD_LABEL = alias;

              }

          } catch (error) {

          }
      });

        this.formData = new FormGroup(group);
        this.bindDataToForm();

    }

    bindDataToForm() {
        Object.keys(this.formData.controls).forEach(key => {
            this.formData.controls[key].setValue(this.dataConfig.currentData[key]);
        });
        this.formLoadFinish = true;
    }

    queryJobtypeStep(JobTypeId:any) {
        const param = ['JobTypeId', '=', this.currentData.JobTypeId];
        this.workflowService.queryJobtypeStep(param).subscribe(res => {
            if (res.success) {
                this.arrJobtypeStep = res.features;

            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    buildTreeUser(arrUser:any) {
        let  arrUserTree:any = [];
        arrUser.forEach((ele:any) => {
            const params = {
                key: ele.CODE,
                label: ele.CODE,
                id: ele.CODE,
                data: ele,
                expanded: false,
                children: []
            };
            arrUserTree.push(params);
        });
        arrUserTree = [...arrUserTree];

        return arrUserTree;
    }

    selectUser(){
        this.strCheckedUser = '';
        this.defaultCheckedUser = [];
        // const jobTypeId = this.formDataStep.controls['JobTypeId'].value;
        // const params = ['JobTypeId', '=', jobTypeId];
        const userSlected = this.formDataStep.controls['assignedTo1'].value;
        userSlected !== null ? this.strCheckedUser = userSlected : this.strCheckedUser = '';
        this.dataUser = this.buildTreeUser(this.lookupCondition['AssignedTo']);
        if (userSlected !== '' && userSlected !== null) {
            const listUser = userSlected.split(",");
            listUser.forEach((user:any) => {
            this.dataUser.forEach((ele: any) => {
                if (ele.id === user) {
                    this.defaultCheckedUser.push(ele);
                }
                });
            });
        }

        this.dialogUser.title = this.translate.instant('Select user');
        this.dialogUser.isComponent = false;
        this.dialogUser.templateRef = this.tempUser;
        this.dialogUser.onShow();
    }

    onCancelUser(){
        this.dialogUser.onHideDialog();
    }

    onUnCheckUser(evt:any){
        let userRemove = evt.currentNode.id;
        let arrCheckedUserStep = this.strCheckedUser.split(",");
        arrCheckedUserStep =  arrCheckedUserStep.filter((fill:any) => fill !== userRemove);
        let strUser = '';
        arrCheckedUserStep.forEach((res:any) => {
            strUser = strUser + res + ',';
        });
        this.strCheckedUser =  strUser.substring(0, strUser.length -1);
        this.formDataStep.controls['assignedTo1'].setValue(this.strCheckedUser)
    }

    onCheckUser(evt:any){
        let userNewAdd = evt.currentNode.id;
        const valUSerStep = this.formDataStep.controls['assignedTo1'].value;
        if (valUSerStep !== null && valUSerStep !== '') {
            this.strCheckedUser = valUSerStep + ','+ userNewAdd;
        }else {
            this.strCheckedUser !== '' ? this.strCheckedUser =  this.strCheckedUser + ',' +  userNewAdd : this.strCheckedUser = userNewAdd;
        }
    }

    onAddUser(){
        this.strCheckedUser.length  > 0 ?  this.formDataStep.controls['assignedTo1'].setValue(this.strCheckedUser) :  this.formDataStep.controls['assignedTo1'].setValue(null);
        this.dialogUser.onHideDialog();
    }

    getWorkflowModel(JobTypeId:any) {
        // get digram
        const param = ['JobTypeId', '=', JobTypeId];
        this.workflowService.queryJobtype(param).subscribe(res => {
            if (res.success && res.features.length > 0) {
                this.currentJobType = res.features[0];
                const data = res.features[0].JobTypeData;
                const options = { compact: true, ignoreComment: true, spaces: 4 };
                this.diagram = JSON.parse(data);
                this.diagram = converter.js2xml(this.diagram, options);
                this.bpmnJS.importXML(this.diagram, (err: any) => {
                    this.getCurentStep();
                    const canvas = this.bpmnJS.get('canvas');
                    canvas.zoom('fit-viewport');
                    if (err) {
                        console.log(err);
                    }
                });
                const canvas = this.bpmnJS.get('canvas');
                canvas.zoom('fit-viewport');
                // this.bpmnJS.get('canvas').zoom('fit-viewport');
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });

    }

    getCurentStep() {
        const param = ['JobId', '=', this.currentData.JobId];
        this.workflowService.queryJobStep(param).subscribe(res => {
            if (res.success) {
                if (res.features.length > 0) {
                    this.currentStepOb = res.features[0];
                    this.currentStep = res.features[0].CurStep;
                    const params = ['JobId', '=', this.currentData.JobId];
                    this.workflowService.queryJob(params).subscribe(job => {
                        if (job.success && job.features.length > 0) {
                            this.isShowIma = (this.currentUser && this.currentUser.toUpperCase() === (job.features[0].AssignedTo && (job.features[0].AssignedTo).toUpperCase())) ? true : false;
                            this.percCompleJob = job.features[0].PercComplete;
                            Object.keys(this.formData.controls).forEach(key => {
                                this.formData.controls[key].setValue(job.features[0][key]);
                            });

                        }
                    });
                    this.isCheckAllCase();
                    this.queryEndStep();
                    const paramTypeStep = ['JobTypeId', '=', this.currentData.JobTypeId];
                    this.workflowService.queryJobtypeStep(paramTypeStep).subscribe(res => {
                        if (res.success) {
                            if (res.features.length > 0) {
                                this.arrStep = res.features;
                                this.arrStep.forEach((item:any) => {
                                    if (item.StepId === this.currentStep) {
                                        item.isActive = 'running';
                                        return;
                                    }
                                    // else if (item.IsComplete) {
                                    //     item.isActive = 'complete';
                                    //     return false;
                                    // }
                                    else {
                                        item.isActive = 'notComplete';
                                    }
                                });
                                this.onChangColor();
                                const f = this.arrStep.filter((fil: { StepId: any; }) => fil.StepId === this.currentStep);
                                f[0] ? this.divCurentStep = f[0].StepName : '';

                            }
                        }
                    });
                }
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    queryEndStep(){
        const paramXref = [['StepId', '=', this.currentStep], ['JobTypeId', '=', this.currentData.JobTypeId]];
        this.workflowService.queryStepXref(paramXref).subscribe(res => {
            if (res.success) {
                if (res.features.length > 0) {
                    if (res.features[0].NextStepId === '' || res.features[0].NextStepId === null) {
                        this.labSend = 'Complete';
                    }
                }
            }
        });
    }

    addHis(isEndJob: boolean) {
        let assignedTo:any = '';
        if (!isEndJob) {
            const value = this.formDataStep.controls['assignOwner'].value;
            const valueAssignOwner:any = typeof(value) === 'object' ? value.CODE : value;
            if ( valueAssignOwner === '2') {
                assignedTo =  this.currentData.OwnedBy;
            } else {
                const valueAssignedTo1 = this.formDataStep.controls['assignedTo1'].value
                assignedTo = typeof(valueAssignedTo1) === 'object' ? valueAssignedTo1.CODE : valueAssignedTo1;
            }
        } else {
            assignedTo = this.currentUser;
        }

        const paramHis = [['JobId', '=', this.currentData.JobId], ['StepId', '=', this.currentStep]];
        this.workflowService.queryHistory(paramHis).subscribe(res => {
            if (res.success) {
                // add step complete
                if (res.features.length > 0) {
                    const timeStart = res.features[0].UpdateTime;
                    const duration = this.getWorkingTime(timeStart);
                    const paramsCompelteStep = {
                        UpdateTime: this.formatDateHisAdd(new Date()),
                        Description: this.formDataStep.controls['note1'].value,
                        AssignedTo: this.currentData.AssignedTo,
                        StepName: this.divCurentStep,
                        SendFrom:  this.currentData.SendFrom,
                        StepId: this.currentStep,
                        ActivityId: 5,
                        JobId: this.currentData.JobId,
                        Duration: duration,
                        JobTypeId: this.currentData.JobTypeId,
                        ClientId: this.clientId
                    };
                    this.workflowService.addHistory(paramsCompelteStep).subscribe(res => {
                        if (!isEndJob) {
                            // add next step
                            const nextStepId = typeof(this.formDataStep.controls['nextStep'].value) === 'object' ? this.formDataStep.controls['nextStep'].value.CODE : this.formDataStep.controls['nextStep'].value;
                            const nextStepName =  this.arrJobtypeStep.filter((fill: { StepId: any; }) => fill.StepId === nextStepId);
                            const paramsNextStep = {
                                UpdateTime: this.formatDateHisAdd(new Date()),
                                Description: this.formDataStep.controls['note1'].value,
                                AssignedTo: assignedTo,
                                StepName: nextStepName[0].StepName,
                                SendFrom: this.currentUser,
                                StepId: nextStepId,
                                ActivityId: 2,
                                JobId: this.currentData.JobId,
                                Duration: null,
                                JobTypeId: this.currentData.JobTypeId,
                                ClientId: this.clientId
                            };
                            this.workflowService.addHistory(paramsNextStep).subscribe(res => {
                            }, err => {
                                this.appService.notification('Đã xảy ra lỗi', 'error');
                            });
                        }
                    }, err => {
                        this.appService.notification('Đã xảy ra lỗi', 'error');
                    });
                }
            }
        });
    }

    getWorkingTime(startDate: any){
        const start = new Date(startDate).getTime();
        const end = new Date().getTime();
        const time = Math.abs(end - start);
        const timeWork = (time / 3600000).toFixed(2);

        return Number(timeWork);
    }

    activeStep(step: number) {
        this.arrStep.forEach((element: { isActive: string; }, ind: number) => {
            ind += 1;
            if (ind > step) {
                element.isActive = 'notComplete';
            } else if (ind === step) {
                element.isActive = 'running';
            } else {
                element.isActive = 'complete';
            }
        });
        this.onChangColor();

    }

    onChangColor() {
        this.bpmnJS.importXML(this.diagram, (err: any) => { });
        this.bpmnJS.on('shape.added', (e: { element: any; }) => {
            const element = e.element;
            const businessObject:any = element.businessObject;
            const face = this.bpmnJS.get('graphicsFactory');
            // var modeling = this.bpmnJS.get('modeling');
            const f = this.arrStep.filter((fil: { StepId: any; }) => fil.StepId === element.id);
            let elementRegistry:any = null;
            let gfx:any = null;
            let type:any = element.waypoints ? 'connection' : 'shape';
            if (f.length > 0) {
                switch (f[0].isActive) {
                    case 'running':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#FFFF00');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                    case 'complete':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#CCFF33');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                    case 'notComplete':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#98FB98');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                }

            }
        });
    }

    updateStatus() {
        const paramJob = ['JobId', '=', this.currentData.JobId]
        this.workflowService.queryJob(paramJob).subscribe(res => {
            if (res.success && res.features.length > 0) {
                const param = res.features[0];
                param.StatusId = 4;
                this.workflowService.updateJob(param).subscribe(res => {
                }, err => {
                    this.appService.notification('Đã xảy ra lỗi', 'error');
                });
            }

        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    private importDiagram(xml: string): Observable<{ warnings: Array<any> }> {
        return from(this.bpmnJS.importXML(xml) as Promise<{ warnings: Array<any> }>);
    }

    markStepCompleteButtonClicked() {
        this.formDataStep.reset();
        this.isBlockAssign = false;
        this.isNextStep = true;
        this.arrNextStep = [];
        const param = this.currentStepOb;
        const paramXref = [['StepId', '=', this.currentStep], ['JobTypeId', '=', this.currentData.JobTypeId]];
        // neu la external wf thi se chay theo nhanh rieng
        if (this.stepExteralWorkflow !== null) {
            this.externalWorkflow();
        } else {
            this.workflowService.queryStepXref(paramXref).subscribe(res => {
                if (res.success) {
                    if (res.features.length > 0) {
                        if (res.features[0].NextStepId !== '' && res.features[0].NextStepId !== null) {
                            res.features.forEach(item => {
                                this.arrJobtypeStep.forEach((res: any) => {
                                    if (item.NextStepId === res.StepId) {
                                        this.arrNextStep.push({
                                            CODE: item.NextStepId,
                                            DESCR: res.StepName,
                                            AssignedTo: res.AssignedTo,
                                            AssignOwner: res.AssignOwner
                                        });
                                        // check step có bị khóa assign không
                                        this.isBlockAssign = res.ChangeAssign === true ? true : false;
                                    }
                                });
                            });
                            this.onShowFormStep(false);
                        } else {
                            // ket thuc
                            param.CurStep = res.features[0].NextStepId;
                            param.CurStepName = res.features[0].NextStepId;
                            this.workflowService.updateJobStep(param).subscribe(res => {
                                if (res.success) {
                                    this.appService.notification('Thực hiện thành công', 'success');
                                    this.updateDataField();
                                    this.addHis(true);
                                    this.getWorkflowModel(this.currentData.JobTypeId);
                                    this.onClickSave(false, false, true);
                                }

                            }, err => {
                                console.log(err);
                            });
                        }
                    }
                }
            });
        }

    }

    backStepWorkflow() {
        this.formDataStep.reset();
        this.isNextStep = false;
        this.arrNextStep = [];
        const params = [['StepId', '=', this.currentStep], ['JobTypeId', '=', this.currentData.JobTypeId]];
        this.workflowService.queryStepXref(params).subscribe(res => {
            if (res.success) {
                if (res.features.length > 1) {
                  let arrStepDone:any = [];
                  this.tableDataHistory.forEach((item:any) => {
                    if (item.activityId === 5) {
                      arrStepDone.push(item)
                    }
                  })

                  if (arrStepDone.length > 0) {
                     let stepBack = arrStepDone[arrStepDone.length - 1];
                      this.arrNextStep.push({
                          CODE: stepBack.stepId,
                          DESCR: stepBack.stepName,
                          AssignedTo:stepBack.assignedTo,
                      });
                      this.onShowFormStep(false);
                  }
                } else {
                    this.appService.notification('Không có cấu hình', 'info');
                }
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    externalWorkflow(){
        const paramStep = [['JobTypeId', '=', this.stepExteralWorkflow.ExternalWorkflow], ['StepId', '=', this.stepExteralWorkflow.ExternalStep]];
        this.workflowService.queryJobtypeStep(paramStep).subscribe(res => {
            if (res.success && res.features[0]){
                const valStep = res.features[0];
                this.arrNextStep.push({
                    CODE: valStep.StepId,
                    DESCR: valStep.StepName,
                    AssignedTo: valStep.AssignedTo
                });
                this.onShowFormStep(false);

            }
        });

    }

    isCheckAllCase(){
        this.stepApprove = null;
        this.stepExteralWorkflow = null;
        this.arrJobtypeStep.forEach((item:any) => {
            if (item.StepId === this.currentStep) {
                if (item.TypeApprove !== null && item.TypeApprove !== '') {
                    this.stepApprove = item;
                    if (this.currentData.JobStatusId == 5 && this.stepApprove !== null || this.stepApprove.DirectApprove === true) {
                        this.labSend = 'Approve';
                    }
                }
                if (item.ExternalWorkflow !== null) {
                    this.stepExteralWorkflow  = item;
                }
            }
        });
    }

    updateDataField(){
        this.dataUpdateField = null
        this.arrJobtypeStep.forEach((item:any) => {
            if (item.StepId === this.currentStep) {
                if (item.ColumnApprove !== null && item.ColumnApprove !== '') {
                    this.dataUpdateField = item
                }
            }
        })
        if ( this.dataUpdateField !== null) {
            this.onUpdateStatusRecord1(this.dataUpdateField.ValueApprove, this.dataUpdateField)
        }
    }

    queryHistory(){
        const params = [['StepId','=',this.currentStep],['JobId','=', this.currentData.JobId]];
        let  arrStepAprove:any = [];
        let arrUserApporive = [];
        let userAssignApprove:any = [];
        this.workflowService.queryHistory(params).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    if (item.ActivityId === 6 || item.ActivityId === 7) {
                        arrStepAprove.push(item);
                    }
                });

                arrUserApporive = this.stepApprove.UserApprove.split(',');
                if (arrStepAprove.length > 0) {
                    arrUserApporive.forEach((user:any) => {
                        const f = arrStepAprove.filter((fill:any) => fill.SendFrom === user);
                        if (!f[0]) {
                            userAssignApprove.push(user);
                        }
                    });
                } else {
                    userAssignApprove = arrUserApporive;
                }

                this.arrNextStep = [{
                    CODE: this.stepApprove.StepId,
                    DESCR: this.stepApprove.StepName,
                    AssignedTo: userAssignApprove[0]
                }];
            }
        });
    }

    onCheckStepApprove(){
        if (this.stepApprove !== null ) {
            //JobStatusId = 5: Waiting approve
            if (this.currentData.JobStatusId === 5 || this.stepApprove.DirectApprove === true) {
                this.onApprove();
            } else if (this.currentData.JobStatusId === 4){   //JobStatusId = 4: Pending
                this.markStepCompleteButtonClicked();
            } else  {
                this.arrNextStep = [{
                    CODE: this.stepApprove.StepId,
                    DESCR: this.stepApprove.StepName,
                    AssignedTo: this.stepApprove.UserApprove,
                    AssignOwner: this.stepApprove.AssignOwner
                }];
                this.onShowFormStep(true);
            }
        } else {
            this.markStepCompleteButtonClicked();
        }
    }

    onApprove(){
        const paramTable = ['TableId','=', this.stepApprove.TableIdApprove];
        this.arrSelectApprove = [];
        this.workflowService.queryColBPMN(paramTable).subscribe(res => {
            if (res.success) {
                if (res.features.length > 0) {
                    const f = res.features.filter(fill => fill.ColumnName === this.stepApprove.ColumnApprove);
                    if (f[0]) {
                        const valColumn = f[0].DomainId;
                        if (valColumn !== null ) {
                            const paramDomain = ['DomainId','=', valColumn];
                            this.workflowService.querySysDomain(paramDomain).subscribe((item:any) => {
                                if (item.success) {
                                    if (item.features[0]) {
                                        const domain = item.features[0].Domain;
                                        let arrDomain = JSON.parse(domain);
                                        if (!Array.isArray(arrDomain)) {
                                            arrDomain = JSON.parse(arrDomain);
                                        }
                                        if (!Array.isArray(arrDomain)) {
                                            arrDomain = JSON.parse(arrDomain);
                                        }
                                        if (!Array.isArray(arrDomain)) {
                                            arrDomain = JSON.parse(arrDomain);
                                        }
                                        arrDomain.forEach((itemD:any) => {
                                            this.arrSelectApprove.push({
                                                CODE: itemD[0],
                                                DESCR: itemD[1]
                                            })
                                        });

                                        if (this.stepApprove.ValueApprove && this.stepApprove.ValueApprove !== '') {
                                          const valAprroveConfig = Number(this.stepApprove.ValueApprove);
                                          this.formApprove.controls['typeApprove'].setValue(valAprroveConfig)

                                        }

                                        this.onShowDialogApprove();

                                    }
                                }
                            });
                        } else {
                            this.appService.notification("Chưa cấu hình domain trường phê duyệt", 'warning');
                        }
                    }

                }
            }
        });
    }

    onConfirmApprove(){
        if (this.formApprove.valid) {
            const typeApprove =  this.formApprove.controls['typeApprove'].value
            const valApprove = typeof(typeApprove) === 'object'? typeApprove.CODE : typeApprove;
            const note = this.formApprove.controls['noteApprove'].value;
            let activityId = null;
            const valAprroveConfig = Number(this.stepApprove.ValueApprove);
            valApprove == valAprroveConfig ? activityId = 6 : activityId = 7;
            const paramsNextStep = {
                UpdateTime: this.formatDateHisAdd(new Date()),
                Description: note,
                AssignedTo: this.currentUser,
                StepName:  this.stepApprove.StepName,
                SendFrom: this.currentUser,
                StepId: this.stepApprove.StepId,
                ActivityId: activityId,
                JobId: this.currentData.JobId,
                Duration: null,
                JobTypeId: this.currentData.JobTypeId,
                ClientId: this.clientId
            };
            this.workflowService.addHistory(paramsNextStep).subscribe(res => {
                if (res.success) {
                    this.onUpdateStatusRecord(valApprove);
                    this.dialogUser.onHideDialog();
                }
            }, err => {
                this.appService.notification('Đã xảy ra lỗi', 'error');
            });
        }
    }

    onCancelApprove(){
        this.dialogUser.onHideDialog();
    }

    onShowDialogApprove(){
        this.dialogUser.title = 'Approve';
        this.dialogUser.isComponent = false;
        this.dialogUser.templateRef = this.tempApprove;
        this.dialogUser.onShow();
    }

    onUpdateStatusRecord(valueApprove:any){
        const params = [['StepId','=',this.currentStep],['JobId','=', this.currentData.JobId]];
        let arrUserApporive = [];
        let countNumApprove = 0;
        let countNumUnApprove = 0;
        let arrStepAprove:any = [];
        const typeApprove = this.stepApprove.TypeApprove;
        this.workflowService.queryHistory(params).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    if (item.ActivityId === 6 || item.ActivityId === 7) {
                        arrStepAprove.push(item);
                    }
                    if (item.ActivityId === 6) {
                        countNumApprove ++;
                    } else if (item.ActivityId === 7) {
                        countNumUnApprove ++;
                    }
                });
                arrUserApporive = this.stepApprove.UserApprove.split(',');
                if (typeApprove === 1) {
                    this.onUpdateStatusRecord1(valueApprove);

                } else if (typeApprove === 2 || typeApprove === 3) {
                    if (countNumUnApprove > 0) {
                        this.onBeforeApprove();
                    } else {
                        if (countNumApprove >= arrUserApporive.length) {
                            this.onUpdateStatusRecord1(Number(this.stepApprove.ValueApprove));
                        } else {
                            this.onBeforeApprove();
                        }
                    }
                }
            }
        });
    }

    onUpdateStatusRecord1(valueApprove:any, dataApprove: any = null){
        const paramTable = ['TableId','=', this.stepApprove.TableIdApprove];
        this.workflowService.querySysTable(paramTable).subscribe(item => {
            if (item.success && item.features.length > 0) {
               const val = item.features[0];
                const tableType = val.TableType;
               let headers = new HttpHeaders({'Content-Type': 'application/json'});
               const options: any = {headers};
                const param = ['TableID', '=', this.stepApprove.TableIdApprove];
                this.workflowService.queryColBPMN(param).subscribe(res => {
                    if (res.success) {
                        let paramsKey:any = null;
                        let column = dataApprove ? dataApprove.ColumnApprove : this.stepApprove.ColumnApprove;
                        res.features.forEach(item => {
                            if (item.IsPriKey === 'Y') {
                                paramsKey = item.ColumnName;
                            }
                        });



                        if (paramsKey !== null && this.currentData.RecordId !== null) {
                            let obj:any = {
                                primaryKey :  paramsKey,
                                data: [{}]
                            };

                            if (tableType === 'FeatureServer' || tableType === 'MapServer') {
                                let feature =  [{
                                    "attributes": obj
                                }];
                                var content = {
                                    features: JSON.stringify(feature),
                                    f: "json"
                                };

                                this.workflowService.esriRequest(val.UrlEdit, content, 'edit').subscribe(res => {
                                    if (res.updateResults[0].success) {
                                        // Nếu là update field thì k cần chạy hàm này || Hàm này chỉ dùng cho trường hợp approve
                                        dataApprove ? '' : this.onBeforeApprove(true) ;
                                        // load lại tab detail
                                        this.coreWindow.reloadTable()
                                    } else {
                                        this.appService.notification('Đã xảy ra lỗi cập nhật trường phê duyệt','warning');
                                    }

                                }, err => {
                                    console.log(err);
                                    this.appService.notification('Đã xảy ra lỗi cập nhật trường phê duyệt','warning');
                                });

                            } else if (tableType === 'CloudData') {
                                // Thêm trường hợp phê duyệt cho bảng con
                                if (this.currentData.TableId !== this.stepApprove.TableIdApprove) {
                                    const paramSysTab = ['WindowId', '=', this.currentData.WindowId]
                                    this.workflowService.querySysTab(paramSysTab).subscribe(res => {
                                        if (res.success && res.features[0]) {
                                            console.log(res);
                                            let tabChildrent:any = null;
                                            res.features.forEach((item:any) => {
                                                if (item.TableId === this.stepApprove.TableIdApprove) {
                                                    tabChildrent = item
                                                }
                                            })

                                            if (tabChildrent !== null) {
                                                const parentLinkedField = tabChildrent.TruongLienKetCha;
                                                if (parentLinkedField !== null && parentLinkedField !== '') {
                                                    const paramsChildrent = [parentLinkedField, '=', this.currentData.RecordId]
                                                    this.workflowService.querySysTableClould(paramsChildrent, val.UrlEdit).subscribe(res => {
                                                        if (res.success && res.features[0]) {
                                                            let arrValueChilrent:any = []
                                                            res.features.forEach((item:any) => {
                                                                arrValueChilrent.push(item[paramsKey])
                                                            })

                                                            arrValueChilrent.forEach((idChildrent:any) => {
                                                                obj['data'][0][paramsKey] = idChildrent
                                                                obj['data'][0][column] = valueApprove
                                                                this.http.put(val.UrlEdit, obj, options).subscribe((res:any) => {
                                                                    if (res.result) {
                                                                        // Nếu là update field thì k cần chạy hàm này || Hàm này chỉ dùng cho trường hợp approve
                                                                        dataApprove ? '' : this.onBeforeApprove(true);
                                                                        // load lại tab detail
                                                                        this.coreWindow.reloadTable()
                                                                    }
                                                                });
                                                            })

                                                        }

                                                    }, err => {
                                                        console.log(err);

                                                    })
                                                } else {
                                                    this.appService.notification('Không có cấu hình trường liên kết cha cho bảng phê duyệt')
                                                }

                                            } else {
                                                this.appService.notification('Không có cấu hình tab cha cho bảng phê duyệt', 'error')
                                            }

                                        }
                                    })
                                } else {
                                    // phê duyệt bảng cha
                                    obj['data'][0][paramsKey] = this.currentData.RecordId
                                    obj['data'][0][column] = valueApprove
                                    this.http.put(val.UrlEdit, obj, options).subscribe((res:any) => {
                                        if (res.result) {
                                            // Nếu là update field thì k cần chạy hàm này || Hàm này chỉ dùng cho trường hợp approve
                                            dataApprove ? '' : this.onBeforeApprove(true);
                                            // load lại tab detail
                                            this.coreWindow.reloadTable()
                                        }
                                    });
                                }

                            }

                        } else {
                            this.appService.notification('Lỗi truy vấn khóa chính', 'warning')
                        }
                    }

                 });
            }
        });

    }

    onBeforeApprove(isUdJobStatus:boolean = false){
        const typeApprove = this.stepApprove.TypeApprove;
        const params = ['JobId', '=', this.currentData.JobId];
        const paramsHis = [['StepId','=',this.currentStep],['JobId','=', this.currentData.JobId]];
        let  arrStepAprove:any = [];
        let arrUserApporive = [];
        let userAssignApprove:any = [];
        this.workflowService.queryHistory(paramsHis).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    if (item.ActivityId === 6 || item.ActivityId === 7) {
                        arrStepAprove.push(item);
                    }
                });

                arrUserApporive = this.stepApprove.UserApprove.split(',');
                if (arrStepAprove.length > 0) {
                    arrUserApporive.forEach((user:any) => {
                        const f = arrStepAprove.filter((fill:any) => fill.SendFrom === user);
                        if (!f[0]) {
                            userAssignApprove.push(user);
                        }
                    });
                } else {
                    userAssignApprove = arrUserApporive;
                }

                if (typeApprove === 1 || userAssignApprove.length === 0) {
                    userAssignApprove[0] = this.currentData.SendFrom;
                }

                // Check kiểm tra sau phê duyệt có gửi lại hay đi tiếp
                if (this.stepApprove.DirectApprove) {
                    this.markStepCompleteButtonClicked()
                    const objNext = {
                        SendFrom : this.currentUser,
                        AssignedTo: userAssignApprove[0]
                    }
                    this.sendMailAfterApprove(objNext)
                } else {
                    this.workflowService.queryJob(params).subscribe(res => {
                        if (res.success) {
                            const job = res.features[0];
                            job.AssignedTo = userAssignApprove[0];
                            job.AssignStatusId = null;
                            job.SendFrom = this.currentUser
                            job.JobStatusId = isUdJobStatus ? 4 : 5;
                            this.workflowService.updateJob(job).subscribe(res => {
                                if (res.success) {
                                    this.appService.notification('Thực hiện thành công', 'success');
                                    this.getCurentStep();
                                    this.onAddNotification(job.AssignedTo, job.JobName, job.PriorityId);
                                    this.sendMailAfterApprove(job); // gửi mail sau khi phê duyệt
                                }
                            });
                        }
                    });
                }
            }
        });
    }

    public async onShowFormStep(isAprrove:boolean) {
        this.formDataStep.controls['assignOwner'].setValue('1');
        let click = null;
        if (this.arrNextStep.length === 1) {
            this.formDataStep.controls['nextStep'].setValue(this.arrNextStep[0].CODE);
            this.formDataStep.controls['assignedTo1'].setValue(this.arrNextStep[0].AssignedTo);
            if (this.arrNextStep[0].AssignOwner) {
                this.formDataStep.controls['assignOwner'].setValue('2');
            }
        } else {
            click = this.formDataStep.controls['nextStep'].valueChanges.subscribe(res => {
                if (res !== null) {
                    const f = this.arrJobtypeStep.filter((fil: any ) => fil.StepId === res);
                    if (f[0]) {
                        this.formDataStep.controls['assignedTo1'].setValue(f[0].AssignedTo);
                        if (f[0].AssignOwner) {
                            this.formDataStep.controls['assignOwner'].setValue('2');
                        } else {
                            this.formDataStep.controls['assignOwner'].setValue('1');
                        }

                    }
                } else {
                    this.formDataStep.controls['assignedTo1'].setValue(null);
                }
            });

            let hasWhereStep:any = false;
            for (let index = 0; index < this.arrNextStep.length; index++) {
              const f = this.arrJobtypeStep.filter((fill:any) =>  fill.StepId === this.arrNextStep[index].CODE);
              if (f[0]) {
                const where = f[0].WhereStep;
                if (where && where !== '') {
                  hasWhereStep = true
                } else {
                  hasWhereStep = false
                  break
                }
              }
            }

            if (hasWhereStep) {
              // Nếu step có where
              this.arrNextStep.forEach(async (item:any) => {
                const f = this.arrJobtypeStep.filter((fill:any) =>  fill.StepId === item.CODE);
                if (f[0]) {
                    const where = f[0].WhereStep;
                    if (where && where !== '') {
                        let correctedStr = where.replace(/'/g, '"');
                        correctedStr = correctedStr.replace(/\s+/g, '');
                        try {
                            let arrWhere = JSON.parse(correctedStr);
                            this.onCheckWhereStep((res:any) => {
                              if (res[arrWhere[0]]) {
                                const checkWhereStep =  eval(`${res[arrWhere[0]]} ${arrWhere[1]} ${arrWhere[2]}`)
                                if (checkWhereStep) {
                                  this.formDataStep.controls['nextStep'].setValue(item.CODE);
                                  this.formDataStep.controls['assignedTo1'].setValue(f[0].AssignedTo);
                                }
                              }
                            }, (error:any) => {
                              console.log(error);
                            });

                      } catch (e) {
                          console.error("Failed to parse JSON string:", e);
                      }

                    } else {
                      // this.formDataStep.controls['nextStep'].setValue(item.CODE);
                      // this.formDataStep.controls['assignedTo1'].setValue(f[0].AssignedTo);
                    }

                  if (f[0].AssignOwner) {
                      this.formDataStep.controls['assignOwner'].setValue('2');
                  } else {
                      this.formDataStep.controls['assignOwner'].setValue('1');
                  }
                }
              });

            } else {
              // Set bước tiếp theo
              this.onSlectNextStep()
            }
        }

        isAprrove === true ?  this.dialogPrime.title = this.translate.instant('Send to Approve') : this.dialogPrime.title = this.translate.instant('Assign next step');
        this.dialogPrime.isComponent = false;
        this.dialogPrime.templateRef = this.templateNextStep;
        this.dialogPrime.onShow();
    }

    onSlectNextStep(){
      if (this.arrNextStep.length ===  1) {
        this.formDataStep.controls['nextStep'].setValue((this.arrNextStep[0].CODE))
        this.formDataStep.controls['assignedTo1'].setValue(this.arrNextStep[0].AssignedTo);
      } else if (this.arrNextStep.length ===  2) {
        let arrNextStepSect:any = [...this.arrNextStep]
        this.arrNextStep.forEach((element:any) => {
          const f = this.tableDataHistory.filter((fill:any) => fill.stepId === element.CODE)
          if(f[0]) {
            arrNextStepSect = arrNextStepSect.filter((fil:any) => fil.CODE !== f[0].stepId)
          }
        });

        if (arrNextStepSect.length > 0) {
          this.formDataStep.controls['nextStep'].setValue((arrNextStepSect[0].CODE))
          this.formDataStep.controls['assignedTo1'].setValue(arrNextStepSect[0].AssignedTo);
        }
      }
    }


    onCheckWhereStep(callback:any, error:any){
      const paramTable = ['TableId','=', this.currentData.TableId];
      this.workflowService.querySysTable(paramTable).subscribe(item => {
          if (item.success && item.features.length > 0) {
            const val = item.features[0];
            const param = ['TableID', '=', this.currentData.TableId];
            this.workflowService.queryColBPMN(param).subscribe(res => {
              if (res.success) {
                  let paramsKey:any = null;
                  res.features.forEach(item => {
                      if (item.IsPriKey === 'Y') {
                          paramsKey = item.ColumnName;
                      }
                  });
                  const  headers = new HttpHeaders({'Content-Type': 'application/json'});
                  let obj:any = {}
                  obj['where'] =  `${paramsKey} =  ${this.currentData.RecordId}`

                  const options: any = {
                       headers,
                       params: obj,
                   };

                  this.http.get(val.UrlEdit, options).subscribe((res:any) => {
                    if (res.result) {
                      if (res.datas[0]) {
                        callback(res.datas[0])
                      }

                    } else {
                      error(res)
                    }
                  });
                } else {
                  error(res)
                }
            }, err => error(err))
          }
      })

    }

    onAcceptStep() {
        if (this.formDataStep.valid) {
            // kiem tra step aprrove hoặc k
            this.idNextStepSlect = null
            if (this.stepApprove !== null) {
                const params = ['StepId','=', this.stepApprove.StepId];
                this.workflowService.queryHistory(params).subscribe(res => {
                    if (res.success && res.features.length > 0) {
                        const valStep = this.formDataStep.controls['nextStep'].value;
                        let currentStepName:any = null;
                        const params = this.currentStepOb;
                        params.CurStep = valStep !== null ? typeof (valStep) === 'object' ? valStep.CODE : valStep : valStep;
                        this.idNextStepSlect = params.CurStep
                        const f =  this.arrNextStep.filter((fill:any) => fill.CODE === params.CurStep);
                        if (f[0]) {
                            currentStepName = f[0].DESCR;
                        }
                        params.CurStepName = currentStepName;
                        this.workflowService.updateJobStep(params).subscribe(res => {
                            this.appService.notification('Thực hiện thành công', 'success');
                            this.onCancelStep();
                            this.getWorkflowModel(this.currentData.JobTypeId);
                            this.addHis(false);
                            this.onCloseEvent.emit(true);
                        }, err => {
                            this.appService.notification('Đã xảy ra lỗi', 'error');
                        });
                        this.onClickSave(false, true, false);
                    } else {
                        this.onClickSave(false, true, false);
                    }
                });
            } else if(this.stepExteralWorkflow !== null) {
                const params = ['JobId', '=', this.currentData.JobId];
                this.workflowService.queryJob(params).subscribe(res => {
                    if (res.success && res.features[0]) {
                        const job = res.features[0];
                        // const valAssign = this.formDataStep.controls['assignedTo1'].value;
                        // job.AssignedTo = valAssign !== null ? typeof (valAssign) === 'object' ? valAssign.CODE : valAssign : valAssign;

                        let valAssign:any = '';
                        const value = this.formDataStep.controls['assignOwner'].value;
                        const valueAssignOwner:any = typeof(value) === 'object' ? value.CODE : value;
                        if ( valueAssignOwner === '2') {
                            valAssign =  this.currentData.OwnedBy;
                        } else {
                            const valueAssignedTo1 = this.formDataStep.controls['assignedTo1'].value
                            valAssign = typeof(valueAssignedTo1) === 'object' ? valueAssignedTo1.CODE : valueAssignedTo1;
                        }

                        job.AssignedTo = valAssign;

                        job.JobTypeId = this.stepExteralWorkflow.ExternalWorkflow;
                        job.AssignStatusId = job.SendFrom !== job.AssignedTo ? null : 1;
                        this.currentData = job;
                        this.workflowService.updateJob(job).subscribe(item => {
                            if (item.success) {
                                const obj = {
                                    JobStepId : this.currentStepOb.JobStepId,
                                    CurStep: this.stepExteralWorkflow.ExternalStep,
                                    JobId: this.currentData.JobId,
                                    ClientId: this.clientId,
                                    CurStepName: this.stepExteralWorkflow.StepName
                                }
                                this.idNextStepSlect = obj.CurStep
                                this.workflowService.updateJobStep(obj).subscribe(r => {
                                    if (r.success) {
                                        this.updateDataField();
                                        this.getWorkflowModel(this.currentData.JobTypeId);
                                        this.queryJobtypeStep(this.currentData.JobTypeId);
                                        this.appService.notification('Thực hiện thành công', 'success');
                                        this.dialogPrime.onHideDialog();
                                        this.onCloseEvent.emit(true);
                                    }
                                });
                            }
                        });
                    }
                });
            } else {
                const valStep = this.formDataStep.controls['nextStep'].value;
                const params = this.currentStepOb;
                params.CurStep = valStep !== null ? typeof (valStep) === 'object' ? valStep.CODE : valStep : valStep;
                this.idNextStepSlect = params.CurStep
                params.CurStepName = valStep !== null ? typeof (valStep) === 'object' ? valStep.DESCR : valStep : valStep;
                this.workflowService.updateJobStep(params).subscribe(res => {
                    this.appService.notification('Thực hiện thành công', 'success');
                    this.onCancelStep();
                    this.getWorkflowModel(this.currentData.JobTypeId);
                    this.addHis(false);
                    this.onCloseEvent.emit(true);
                }, err => {
                    this.appService.notification('Đã xảy ra lỗi', 'error');
                });
                this.onClickSave(false, true, false);
            }

        } else {
            this.appService.createMessage('warning', this.appService.getMessage('0006'));
        }
    }

    onCancelStep() {
        this.dialogPrime.onHideDialog();
    }

    onClickSave(isShowNoti: boolean, IsUdUser: boolean, isUdStatus: boolean = false) {
        const params:any = {};
        Object.keys(this.formData.value).forEach(key => {
            params[key] = this.formData.controls[key].value;
        });
        const paramsJob:any = {
            JobId: this.currentData.JobId,
            JobName: params['JobName'],
            Description: params['Description'],
            AssignedTo: params['AssignedTo'] !== null ? typeof (params['AssignedTo']) === 'object' ? params['AssignedTo'].CODE : params['AssignedTo'] : params['AssignedTo'],
            ParentJobId: params['ParentJobId'],
            StartDate: this.formatDate(params['StartDate']),
            DueDate: this.formatDate(params['DueDate']),
            Note: params['Note'],
            OwnedBy: params['OwnedBy'],
            PercComplete: params['PercComplete'],
            JobTypeId: params['JobTypeId'] !== null ? typeof (params['JobTypeId']) === 'object' ? params['JobTypeId'].CODE : params['JobTypeId'] : params['JobTypeId'],
            JobStatusId: params['JobStatusId'] !== null ? typeof (params['JobStatusId']) === 'object' ? params['JobStatusId'].CODE : params['JobStatusId'] : params['JobStatusId'],
            AssignStatusId: 1,
            PriorityId:  params['PriorityId'] !== null ? typeof (params['PriorityId']) === 'object' ? params['PriorityId'].CODE : params['PriorityId'] : params['PriorityId'],
            SendFrom: this.currentUser,
            WindowId: params['WindowId'],
            TableId: params['TableId'],
            RecordId: params['RecordId'],
            ClientId: this.clientId,
            ApplicationId: this.currentData.ApplicationId,
            CreateDate: this.currentData.CreateDate,
            UpdateTime:  this.formatDate(new Date()),
        };

        // cap nhat % hoan thanh
        // onSave
        if (isShowNoti) {
            this.workflowService.updateJob(paramsJob).subscribe(res => {
                if (res.success) {
                    this.appService.notification('Lưu thành công','success');
                    this.getCurentStep();
                    // this.addNotification(paramsJob.AssignedTo);
                }
            }, err => {
                this.appService.notification('Đã xảy ra lỗi', 'error');
            });
        } else {
            // isUdStatus: kết thúc

            paramsJob.SendFrom = this.currentUser;
            isUdStatus === true ? paramsJob.JobStatusId = 3 : '';
            isUdStatus === true ? paramsJob.PercComplete = 100 : '';
            isUdStatus === true ? paramsJob.AssignStatusId = null : '';
            isUdStatus === true ? paramsJob.AssignedTo = null : '';

            let valAssign:any = '';
            const value = this.formDataStep.controls['assignOwner'].value;
            const valueAssignOwner:any = value !== null ?  typeof(value) === 'object' ? value.CODE : value : value;
            if (valueAssignOwner !== null) {
                if ( valueAssignOwner === '2') {
                    valAssign =  paramsJob.OwnedBy;
                } else {
                    const valueAssignedTo1 = this.formDataStep.controls['assignedTo1'].value
                    valAssign = typeof(valueAssignedTo1) === 'object' ? valueAssignedTo1.CODE : valueAssignedTo1;
                }
            }

            paramsJob.Note =  this.formDataStep.controls['note1'].value;

            IsUdUser === true ? paramsJob.AssignedTo = valAssign !== null ? typeof (valAssign) === 'object' ? valAssign.CODE : valAssign : valAssign : valAssign;
            // check neu giao cho tôi hoặc giao cho người khác
            paramsJob.AssignedTo ===  paramsJob.SendFrom ? paramsJob.AssignStatusId = 1 :  paramsJob.AssignStatusId = null;
            // check step dau tien
            this.currentJobType.JobStepStart === this.currentStep ? paramsJob.JobStatusId = 2 : '';
            // neu la step approve se doi trang thai la 5
            this.stepApprove !== null ?  paramsJob.JobStatusId = 5 : ''

            this.checkSendMail(paramsJob) // Ham nay gui mail cho nguoi tiep theo neu co cau hinh gui email thông báo
            const params = [['JobTypeId', '=', this.currentData.JobTypeId], ['StepId', '=', this.currentStep]];
            this.workflowService.queryJobtypeStep(params).subscribe(res => {
                if (res.success) {
                    if (res.features[0]) {
                        // tinh %
                        if (!isUdStatus) {
                            let valComplete = null;
                            const dayStep = res.features[0].Duration == null ? 0 : res.features[0].Duration;
                            let dayJob:any = this.percCompleJob == null ? 0 : this.percCompleJob;
                            const f:any = this.arrJobType.filter((fil: { JobTypeId: any; }) => fil.JobTypeId === this.currentData.JobTypeId);
                            if (f[0]) {
                                if (dayStep !== 0) {
                                    const totolPer = 0;
                                    const dayJobType = f[0].NumDayComplete;
                                    valComplete = Math.round((dayStep / dayJobType) * 100);
                                    paramsJob['PercComplete'] = this.isNextStep === true ? dayJob += valComplete : dayJob -= valComplete;
                                }
                            }
                        }

                        this.workflowService.updateJob(paramsJob).subscribe(res => {
                            if (res.success) {
                                this.onAddNotification(paramsJob.AssignedTo, paramsJob.JobName, paramsJob.PriorityId);
                            }
                        }, err => {
                            this.appService.notification('Đã xảy ra lỗi', 'error');
                        });
                    }
                }
            }, err => {
                this.appService.notification('Đã xảy ra lỗi', 'error');
            });
        }

    }

    checkSendMail(objJobNext:any){
        // kiem tra buoc tiep theo co cau hinh send mail khong

        const paramJobStep = [['StepId', '=', this.idNextStepSlect], ['JobTypeId', '=', this.currentData.JobTypeId]];

        this.workflowService.queryJobtypeStep(paramJobStep).subscribe(res => {
            if (res.success && res.features[0]) {
                const sendMail = res.features[0].SendMail;
                const teamplatEmail = res.features[0].TemplateEmail;
                // const typeApprove = res.features[0].TypeApprove;
                // Gửi mail cho người nhận bước tiếp theo
                if (sendMail) {
                    if (objJobNext.AssignedTo !== '' && objJobNext.AssignedTo !== null) {
                        let arrRQ: any = [];
                        const check = objJobNext.AssignedTo.includes(',')
                        if (check) {
                            // gửi mail: người nhận là số nhiều
                            const arrUserName = objJobNext.AssignedTo.split(',');
                            arrUserName.forEach((user:any) => {
                                const params = ['UserName', '=', user.trim()]
                                arrRQ.push(this.workflowService.queryUserBpmn(params))
                            })

                        } else {
                            // gửi mail 1 user
                            const paramsUser = ['Username', '=', objJobNext.AssignedTo]
                            arrRQ = [this.workflowService.queryUserBpmn(paramsUser)]
                        }

                        combineLatest(arrRQ).subscribe( res => {
                            console.log(res);
                            res.forEach( (item: any) => {
                                if (item.success && item.features[0]) {
                                    const email = item.features[0].Email;
                                    if (email !== null && email !== '') {
                                        this.onSendMail(email.trim(), objJobNext, teamplatEmail)
                                    }
                                }
                            })
                        })
                    }

                }


            }
        })
    }

    sendMailAfterApprove(objJobNext:any){
        // Gửi mail theo column Email
        const paramJobStep = [['StepId', '=', this.currentStep], ['JobTypeId', '=', this.currentData.JobTypeId]];

        this.workflowService.queryJobtypeStep(paramJobStep).subscribe(res => {
            if (res.success && res.features[0]) {
                const columnEmail = res.features[0].ColumnEmail;
                const teamplatEmail = res.features[0].TemplateEmail;
                if (columnEmail !== null && columnEmail !== '') {
                    const paramTable = ['TableId','=', this.currentData.TableId];
                    this.workflowService.querySysTable(paramTable).subscribe(item => {
                        if (item.success && item.features.length > 0) {
                           const sysTable = item.features[0];
                           let params:any = {
                                outFields: '*'
                           }
                           params['where'] = `${sysTable.ColumnKey} = ${this.currentData.RecordId}`
                           const  headers = new HttpHeaders({'Content-Type': 'application/json'});
                           const options: any = {
                                headers,
                                params: params,
                            };

                           this.http.get(sysTable.UrlView, options).subscribe((res:any) => {
                                if (res.result && res.datas.length > 0) {
                                        const email = res.datas[0][columnEmail];
                                    if (email !== null && email !== '') {
                                        this.onSendMail(email, objJobNext, teamplatEmail)
                                    }

                                }
                           })

                        }
                    })
                }
            }
        })

    }


    onSendMail(email:any, objJobNext:any, teamplatEmail:string = '', valueObject:any = {}){
        const teampDefault = `<h3>Bạn nhận được công việc từ ${objJobNext.SendFrom} - ${this.currentData.JobName}<h3></br>
        <a href=${this.appService.urlReport} target="_blank" title="IzyBuilder">CoreTech</a>`;
        const HoTen = 'Trịnh Đình Tuấn'
        const teamp = teamplatEmail !== '' ? teamplatEmail : teampDefault;
        // console.log(eval(teamp));

        const params = {
            "contentHTML": teamp,
            "email": email,
            "subject": "Thông báo",
            "displayName": "IzyBuilder"
        }

        this.appService.reqUrl(this.workflowService.urlMail, params).subscribe(res => {
            console.log('mall >>>>>', email, res);
            if (!res.success) {
                this.appService.notification('Gửi email không thành công', 'error')
            }

        })
    }

    public tapChange(e: any) {
        console.log(e);
        if (e.index === 1) {
            this.getWorkflowModel(this.currentData.JobTypeId);
        }
    }

    clickTapWf() {

    }

    public tabHis() {
        this.loading = true;
        const handle = this.createTblHisFinish.subscribe(() => {
            this.queryDataHis();
            handle.unsubscribe();
        });

        this.loading = false;
        this.tableRowDefination1 = [];
        Object.keys(this.configColumHis['sys_column']).forEach(item => {
            if (this.configColumHis['sys_column'][item].isReadField) {
                this.tableRowDefination1.push({
                    title: this.configColumHis['sys_column'][item].ColumnAlias,
                    key: this.configColumHis['sys_column'][item].ColumnName,
                    isEdit: false,
                    isOnlyForm: true,
                    // rowOrder: this.configColumHis['sys_column'][item].ColumnIndex,
                    // rowType: this.configColumHis['sys_column'][item].ColumnType,
                    // rowIsShow: true
                });
            }
        });
        this.tableRowDefination1 = this.tableRowDefination1.sort((a: { rowOrder: number; }, b: { rowOrder: number; }) => a.rowOrder - b.rowOrder);
        this.createTblHisFinish.emit();
    }
    public queryDataHis() {
        this.tableDataHistory = [];
        const params = ['JobId', '=', this.currentData.JobId];
        this.workflowService.queryHistory(params).subscribe(resp => {
            resp.features.forEach(item => {
                this.tableDataHistory.push({
                    type: this.arrTypeHis[item.ActivityId],
                    sendFrom: item.SendFrom,
                    assignedTo: item.AssignedTo,
                    stepName: item.StepName,
                    date: this.formatDateHis(item.UpdateTime),
                    message: item.Description,
                    activityId: item.ActivityId,
                    stepId: item.StepId
                });
            });
            this.tableDataHistory = JSON.parse(JSON.stringify(this.tableDataHistory));
        });
    }

    public tabInfo() {
        this.fieldList = [];
        const param = ['TableID', '=', this.currentData.TableId];
        let paramsKey:any = null;
        this.workflowService.queryColBPMN(param).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    if (item.IsPriKey === 'Y') {
                        paramsKey = item.ColumnName;
                    }
                });
                if (paramsKey !== null && this.currentData.RecordId !== null) {
                    this.whereWf = [paramsKey, '=', this.currentData.RecordId];
                    this.windowId = this.currentData.WindowId;
                } else {
                    this.appService.notification('Lỗi truy vấn detail', 'warning');
                }
            }

         });
    }

    onAddNotification(toUsername:any, jobName:any, priorityId:any){
        const paramsWindow = [['ApplicationID', '=', null],['ClientId', '=', this.clientId]];
        let description = 'Bạn được giao công việc từ ' + this.currentUser;
        jobName !== null ? description =  description + ' - ' + jobName : '';
        this.workflowService.querySysWindow(paramsWindow).subscribe(window => {
          if (window.success && window.features[0]) {
            const windowIdJob = window.features[0].WindowId;
            const paramSysTab = ['WindowId', '=', windowIdJob];
            this.workflowService.querySysTab(paramSysTab).subscribe(tab => {
              if (tab.success && tab.features[0]) {
                const tableIdJob = tab.features[0].TableId;
                const paramsNotify = {
                  FromUserId: null,
                  FromUsername: this.currentUser,
                  ToUserId: null,
                  ToUsername: toUsername,
                  SendDate: this.formatDate(new Date()),
                  Description: description,
                  WindowId: windowIdJob,
                  TableId: tableIdJob,
                  RecordId:  this.currentData.JobId,
                  PriorityId: priorityId,
                  ClientId: this.clientId
                };
                this.workflowService.addNotification(paramsNotify).subscribe(res => {
                  if (!res.success) {
                    this.appService.notification('Lỗi thêm mới Notification', 'error');
                  } else {
                    // Gọi broadcast để gửi thông báo tới service worker
                    const valNotifi:any = res.features[0]
                    this.notifyService.broadcast({
                      notificationId: valNotifi.NotificationId,
                      fromUserId: 0,
                      fromUsername: valNotifi.FromUsername,
                      toUserId: 0,
                      toUsername: valNotifi.ToUsername,
                      sendDate: this.formatDate(new Date()),
                      description: valNotifi.Description,
                      windowId: valNotifi.WindowId,
                      tableId: valNotifi.TableId,
                      recordId: valNotifi.RecordId,
                      priorityId: valNotifi.PriorityId,
                      isRead: "",
                      clientId: valNotifi.ClientId
                    }).subscribe(res => {
                      console.log(res);

                      // phải subscibe thì request mới chạy
                    }, err => {
                      console.log(err);

                    });
                  }
                }, err => {
                  this.appService.notification('Lỗi thêm mới Notification', 'error');
                });
              }
            });
          } else {
            this.appService.notification('Lỗi thêm mới Notification', 'error');
          }
        });
    }
    onCoreWindowEmitEvent(event: any) {
      this.createDynamicEvent.emit(event);
    }

    private deepCloneObject(obj: object) {
        return JSON.parse(JSON.stringify(obj));
    }

    public formatDate(date: string | number | Date | null) {
        let dateString:any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getFullYear();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += 'T';
            dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();

        } else {
            dateString = null;
        }
        return dateString;
    }

    formatDateHisAdd(date: string | number | Date | null){
        let dateString:any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getFullYear();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += ' ';
            dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();
        } else {
            dateString = null;
        }
        return dateString;
    }

    formatDateHis(date: string | number | Date | null) {
        let dateString:any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getFullYear();
            dateString += ' ';
            dateString += newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds();
        } else {
            dateString = null;
        }
        return dateString;
    }
}
