import { Injectable } from '@angular/core';
import { RequestService } from 'app/core/services/request.service';
import { AppService } from '../../app-base/app.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    public url = this.appService.urlWS;
    public tableName = 'SysMenuItems';

    constructor(
        private appService: AppService,
        private reqService: RequestService
    ) {
        this.reqService.switchType(this.appService.dataAccessType);
    }

    // New
    public getDetail(id: any) {
        const params = [['MenuItemId', '=', id]];
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: params,
            logic: 'and'
        });
        return resp;
    }


    public getMenuItem(params: any, orderby: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: params,
            logic: 'and',
            orderBy: orderby
        });
        return resp;
    }

    public addMenu(params : any) {
        const odataParams = {
            url: this.url + '/SysMenuItems',
            primaryKey: 'MenuItemId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public deleteMenu(params : any) {
        const odataParams = {
            url: this.url + '/SysMenuItems',
            primaryKey: 'MenuItemId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public updateMenu(params : any) {
        const odataParams = {
            url: this.url + '/SysMenuItems',
            primaryKey: 'MenuItemId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public updateMenuWizard(params : any) {
        const odataParams = {
            url: this.url + '/SysMenuItems',
            primaryKey: 'menuitemid',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public getWindow(params : any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysWindows',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public getTab(params : any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysTabs',
            where: params,
            logic: 'and'
        });
        return resp;
    }
}
