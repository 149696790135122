import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/notification/generated';

@Component({
  selector: 'app-back-job',
  templateUrl: './back-job.component.html',
  styleUrls: ['./back-job.component.scss']
})
export class BackJobComponent implements OnInit {
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  // @ViewChild('dialogPrime', { static: true }) dialogPrime?: DialogPrimeComponent;
  @ViewChild('formBack', { static: true }) formBack!: TemplateRef<any>;

  arrUser:any = [];
  username = new FormControl();
  noteJob = new FormControl();
  nzSpan = 24;
  currentData:any =  null;
  isDisableUser = true;
  currentUser = null;
  isShowUser = false;
  currentJob:any = null;
  currentStep:any = null;
  slectType = 'B';
  clientId: any;

  public listRadio = [
    { CODE: 'back', DESCR: this.translate.instant('Back job')},
    { CODE: 'forward', DESCR: this.translate.instant('Forward job')}
]
public radioCtrl = new FormControl('back');


  constructor(
    private workflowService: BpmnWorkflowService,
    private appService: AppService,
    public translate: TranslateService,
    private notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    if (localStorage) {
      this.currentUser = JSON.parse(this.appService.currentUser).username;
      this.radioCtrl.valueChanges.subscribe((val: any) => {
        this.onCheckboxChange(val.CODE);
    });
  }
  }

  run(evt:any){
    // this.onCloseEvent.emit(false);
    if (evt.currentData.length > 0){
      this.currentData = evt.currentData[evt.currentData.length -1];
      this.clientId = this.appService.ClientId;
      const urlJob = evt.dataSource.INFORMATION.URL_EDIT;
      if (urlJob !== null && urlJob !== '') {
          let i = urlJob.indexOf('/WFJOBs');
          let url1 = urlJob.substr(0, i);
          this.workflowService.url = url1 + '/';

          this.queryUserJobType(this.currentData);
      }

      // this.onShowForm();
    } else {
      this.onCloseEvent.emit(true);
    }

  }

  queryUserJobType(job:any){
    const params = ['JobTypeId', '=', job.JobTypeId];
    this.workflowService.queryJobtypeUser(params).subscribe(res => {
      if (res.success) {
        res.features.forEach(item => {
          this.arrUser.push({CODE: item.Username, DESCR: item.Username});
        });
        this.arrUser = [...this.arrUser];
      }
    });
  }

  ok(){
      const valCtrl =  this.radioCtrl.value
      const typeCtrl =  typeof(valCtrl) === 'object' ? valCtrl.CODE : valCtrl

      const params = ['JobId', '=', this.currentData.JobId];
      this.workflowService.queryJob(params).subscribe(res => {
        if (res.success) {
          this.currentJob = res.features[0];
          const paramJob = res.features[0];
          this.workflowService.queryJobStep(params).subscribe((Jt: any) => {
            if (Jt.success && Jt.features.length > 0) {
              const curStep = Jt.features[0].CurStep;
              const paramJobTypeStep = [['StepId', '=', curStep], ['JobTypeId', '=', paramJob.JobTypeId]];
              const paramJobType =  ['JobTypeId', '=', paramJob.JobTypeId];
              this.workflowService.queryJobtypeStep(paramJobTypeStep).subscribe(res => {
                if (res.success){
                  if (res.features[0]) {
                    const step = res.features[0];
                    this.currentStep = step.StepName;
                    const duration = step.Duration;
                    if (typeCtrl === 'back') {
                      const AssignedTo =  paramJob.AssignedTo;
                      paramJob.AssignedTo = paramJob.SendFrom;
                      paramJob.SendFrom = AssignedTo;
                      paramJob.AssignStatusId = 2;
                      paramJob.Note = this.noteJob.value;
                      if (duration !== null) {
                        this.workflowService.queryJobtype(paramJobType).subscribe((jobType: any) => {
                          if (jobType.success) {
                            // kiem tra step co phai step bat dau
                            let isStepStart = false;
                            jobType.features[0].JobStepStart === curStep ? isStepStart = true : '';
                            if (jobType.features[0].NumDayComplete !== null) {
                              const valComplete = Math.round((duration / jobType.features[0].NumDayComplete) * 100);
                              if (this.currentJob.PercComplete >= valComplete) {
                                const newPerComplete = this.currentJob.PercComplete - valComplete;
                                paramJob.PercComplete = newPerComplete;
                                this.onUpdateJob(paramJob, curStep, Jt, isStepStart);
                              } else {
                                this.onUpdateJob(paramJob, curStep, Jt, isStepStart);
                              }
                            } else {
                              this.onUpdateJob(paramJob, curStep, Jt, isStepStart);
                            }
                          }
                        });
                      }
                    } else if (typeCtrl === 'forward'){
                      if (this.username.valid) {
                        const assignedTo =  this.username.value !== null ? typeof (this.username.value) === 'object' ? this.username.value.CODE : this.username.value : this.username.value;
                        paramJob.AssignedTo = assignedTo;
                        paramJob.SendFrom = this.currentUser;
                        paramJob.AssignStatusId = 3;
                        paramJob.Note = this.noteJob.value;
                        this.workflowService.updateJob(paramJob).subscribe((res:any) => {
                          if (res.success){
                            const job = res.features;
                            this.onAddNotification(job.assignedTo, job.jobName, job.priorityId);
                            this.addHistory(paramJob.AssignedTo, curStep, false);
                            this.appService.notification('Thực hiện thành công', 'success');
                            this.onCloseEvent.emit(true);
                          }
                        });
                      } else {
                        this.appService.createMessage('warning', this.appService.getMessage('0006'));
                      }
                    }
                  }
                }
              }, err => {
                this.appService.notification('Đã xảy ra lỗi', 'error');
              });
            }
          });
        }
      });
  }

  onUpdateJob(params:any, currentStep:any, Jt:any, isStepStart:any){
    this.workflowService.updateJob(params).subscribe((ud:any) => {
      if (ud.success) {
        const job = ud.features;
        this.onAddNotification(job.assignedTo, job.jobName, job.priorityId);
        if (!isStepStart) {
          this.updateJobStep(params, currentStep, Jt);
        } else {
          this.onCloseEvent.emit(true);
          this.appService.notification('Thực hiện thành công', 'success');
        }
        this.addHistory(params.AssignedTo, currentStep, true);
      }
    });
  }

  updateJobStep(job:any, curStep:any, Jt:any){
    const paramXref = [['JobTypeId', '=', job.JobTypeId], ['ClientId', '=', this.clientId]];
    this.workflowService.queryStepXref(paramXref).subscribe(res => {
      if (res.success) {
        const arrXref = res.features;
        arrXref.forEach(step => {
          if (step.NextStepId === curStep) {
            const curStepNew = step.StepId;
            const prUdJt = Jt.features[0];
            prUdJt.CurStep = curStepNew;
            this.workflowService.updateJobStep(prUdJt).subscribe(jt => {
              if (jt.success) {
                this.appService.notification('Thực hiện thành công', 'success');
                this.onCloseEvent.emit(true);
              }
            });
          }
        });
      }
    }, err => {
      this.appService.notification('Đã xảy ra lỗi', 'error');
    });
  }

  public onCheckboxChange(value:any){
    if (value === 'forward') {
      this.isShowUser = true;
    } else {
      this.isShowUser = false;
    }
  }

  onAddNotification(toUsername:any, jobName:any, priorityId:any){
    const paramsWindow = [['ApplicationID', '=', null],['ClientId', '=', this.clientId]];
    let description = 'Bạn được giao công việc từ ' + this.currentUser;
    jobName !== null ? description =  description + ' - ' + jobName : '';
    this.workflowService.querySysWindow(paramsWindow).subscribe(window => {
      if (window.success && window.features[0]) {
        const windowIdJob = window.features[0].WindowId;
        const paramSysTab = ['WindowId', '=', windowIdJob];
        this.workflowService.querySysTab(paramSysTab).subscribe(tab => {
          if (tab.success && tab.features[0]) {
            const tableIdJob = tab.features[0].TableId;
            const paramsNotify = {
              FromUserId: null,
              FromUsername: this.currentUser,
              ToUserId: null,
              ToUsername: toUsername,
              SendDate: this.formatDate(new Date()),
              Description: description,
              WindowId: windowIdJob,
              TableId: tableIdJob,
              RecordId:  this.currentData.JobId,
              PriorityId: priorityId,
              ClientId: this.clientId
            };
            this.workflowService.addNotification(paramsNotify).subscribe(res => {
              if (!res.success) {
                this.appService.notification('Lỗi thêm mới Notification', 'error');
              }else {
                // Gọi broadcast để gửi thông báo tới service worker
                const valNotifi:any = res.features[0]
                this.notifyService.broadcast({
                  notificationId: valNotifi.NotificationId,
                  fromUserId: 0,
                  fromUsername: valNotifi.FromUsername,
                  toUserId: 0,
                  toUsername: valNotifi.ToUsername,
                  sendDate: this.formatDate(new Date()),
                  description: valNotifi.Description,
                  windowId: valNotifi.WindowId,
                  tableId: valNotifi.TableId,
                  recordId: valNotifi.RecordId,
                  priorityId: valNotifi.PriorityId,
                  isRead: "",
                  clientId: valNotifi.ClientId
                }).subscribe(res => {
                  console.log(res);

                  // phải subscibe thì request mới chạy
                }, err => {
                  console.log(err);

                });
              }
            }, err => {
              this.appService.notification('Lỗi thêm mới Notification', 'error');
            });
          }
        });
      } else {
        this.appService.notification('Lỗi thêm mới Notification', 'error');
      }
    });
}


public formatDate(date: string | number | Date | null) {
  let dateString:any = '';
  if (date !== '' && date !== null) {
      const newDate = new Date(date);
      dateString += newDate.getFullYear();
      dateString += '-';
      dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
      dateString += '-';
      dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
      dateString += 'T';
      dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
      dateString += ':';
      dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
      dateString += ':';
      dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();

  } else {
      dateString = null;
  }
  return dateString;
}

  onCancel(){
    this.onCloseEvent.emit(true);
  }

  addHistory(assignedTo:any, currentStep:any, isBack:any){
    const params = {
      UpdateTime: this.formatDateHisAdd(new Date()),
      Description: this.noteJob.value,
      StepName: this.currentStep,
      AssignedTo: assignedTo,
      SendFrom: this.currentUser,
      StepId: currentStep,
      ActivityId: isBack === true ? 3 : 4,
      JobId: this.currentData.JobId,
      JobTypeId: this.currentData.JobTypeId,
      ClientId: this.clientId
    };
    this.workflowService.addHistory(params).subscribe(res => {
    }, err => {
        this.appService.notification('Đã xảy ra lỗi', 'error');
    });
  }

  formatDateHisAdd(date:any){
    let dateString:any = '';
    if (date !== '' && date !== null) {
        const newDate = new Date(date);
        dateString += newDate.getFullYear();
        dateString += '-';
        dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
        dateString += '-';
        dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
        dateString += ' ';
        dateString += newDate.getHours();
        dateString += ':';
        dateString += newDate.getMinutes();
        dateString += ':';
        dateString += newDate.getSeconds();
    } else {
        dateString = null;
    }
    return dateString;
  }
}
