import { Component, OnInit } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { loaderOptions } from 'app/gis/arcgis3x/config/loader-options';
import { GisService } from 'app/gis/arcgis3x/services/gis.service';
import { ReportService } from 'app/reports/services/reports.service';
import { loadModules } from 'esri-loader';

@Component({
  selector: 'app-demo-landinfo',
  template: '',
})
export class DemoLandinfoComponent implements OnInit {
  public params: any = {};
  constructor(
    private appService: AppService,
    private gisService: GisService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void { }

  async getMenuToolItem(evt: any) {
    const url = this.reportService.getUrlHost(`view/${evt.report.WindowId}`);
    let urlView = (evt.dataSource && evt.dataSource.INFORMATION) ? evt.dataSource.INFORMATION.URL_VIEW + '/query?where=' + evt.KHOA_CHINH + '=' + evt.currentData[evt.KHOA_CHINH] : '';
    this.params['urlView'] = urlView !== '' ? urlView : '';
    this.getGeometry(evt, url);
  }

  async getUrlToken(_url: any) {
    let url = _url;
    const token = await this.appService.getArcGISToken(_url);
    if (token) {
      url += '?token=' + token;
    }
    return url;
  }


  async getGeometry(data: any, urlReport: any) {
    // Trường hợp đồ họa và thuộc tính là 2 bảng tách rời => cần phải dựa vào columndohoa để query dữ liệu
    const fieldList = data.dataSource.FIELD_LIST.filter((fil: any) => fil.columndohoa !== null);
    if (fieldList.length > 0) {
      console.log(data.dataSource.INFORMATION.URL_VIEW_MAP);

      const url_viewMap = data.dataSource.INFORMATION.URL_VIEW_MAP;
      if (url_viewMap.includes("MapServer")) {
        // Khởi tạo câu lệnh where theo kiểu của ArcGIS luôn, không cần quy chuẩn về dạng where ODATA
        let where = '';
        fieldList.forEach((field: any) => {
          if (field.fieldtype === 'number') {
            where += `${field.columndohoa} = ${data.currentData[field.fieldname]} AND `;
          } else {
            where += `${field.columndohoa} = '${data.currentData[field.fieldname]}' AND `;
          }
        });
        where = where.substring(0, where.length - 5);

        this.gisService.queryGeometry({
          url: url_viewMap,
          geometry: null,
          where
        }).subscribe((response: any) => {
          if (response && response.features) {
            const graphic = response.features[0];
            if (graphic && graphic.geometry && graphic.geometry.type) {
              this.getImageMapThua(data, graphic.geometry, where);
            } else {
              this.loadParams(data);
              console.log('Không có đồ họa!');
            }
          }
        }, (err: any) => {
          console.log('err', JSON.stringify(err));
        });
      } else {
        this.loadParams(data);
        console.log('Không có url đồ họa!');
      }
    } else {
      this.loadParams(data);
      console.log('Không có trường đồ họa!');
    }
  }

  loadParams(data: any) {
    const ReportCurentData = JSON.stringify(this.params);
    const url = `${this.appService.urlReport}report/loadreport`;
    if (data.isMobile) {
      window['nsWebViewBridge'].emit('report', { url });
    } else {
      let win: any = window.open(url, '_blank');
      win.PamramsReport = ReportCurentData;
    }
  }

  async getImageMapThua(data: any, geo: any, where: any) {
    const urlView = data.dataSource.INFORMATION.URL_VIEW_MAP;
    const [
      ImageParameters, ArcGISDynamicMapServiceLayer
    ] = await loadModules([
      'esri/layers/ImageParameters',
      'esri/layers/ArcGISDynamicMapServiceLayer',
    ], loaderOptions);

    const _url_Base = urlView.slice(0, urlView.length - 2);
    const url_Base = await this.getUrlToken(_url_Base);
    const BASE_MAP = new ArcGISDynamicMapServiceLayer(url_Base);

    const param = new ImageParameters();
    param.dpi = 96;
    param.layerOption = ImageParameters.LAYER_OPTION_SHOW;
    param.bbox = geo.getExtent();
    param.layerDefinitions = [where];
    param.layerIds = [urlView.slice(urlView.length - 1)];
    BASE_MAP.exportMapImage(param, (resp: any) => {
      this.params['urlImage'] = (resp.href);
      this.loadParams(data);
    }, (error: any) => {
      console.log(error);

    });
  }
}
