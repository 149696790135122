<div>
  <div class="toolbar-header">
    <div class="toolbarleft">
      <div>
        <app-button [labelName]="'Thêm mới' | translate" typeButton="save" (clickEvent)="openDialogAdd()"
          icon='pi pi-file-o' *ngIf="permissionList['ADD']" style="margin-left: 10px;">
        </app-button>
        <app-button [labelName]="'Add many' | translate" typeButton="save" (clickEvent)="openDialogAddMany()"
          icon='pi pi-file-o' *ngIf="permissionList['ADDMANY']" style="margin-left: 10px;">
        </app-button>
        <app-button [labelName]="'Tìm kiếm' | translate" typeButton="save" (clickEvent)="openDialogSearch()"
          icon='pi pi-search' *ngIf="permissionList['SEARCH']" style="margin-left: 10px;">
        </app-button>
      </div>
      <div *ngIf="showFilter" style="margin-top: 10px;">
        <form [formGroup]="filterFormGroup" class="filter-field-form">
          <app-select [showLabel]="false" [formControlName]="'cboTypeCalendar'" style="margin-right: 10px;"
            [dataLookup]="arrTypeCalendar" [placeholder]="'Kiểu lịch'" [controlWidth]="'150'"></app-select>
        </form>
      </div>
    </div>
  </div>
  <div>
    <div style="margin-top: 10px">
      <full-calendar #calendar [options]="calendarOptions"></full-calendar>
    </div>
  </div>
</div>

<ng-template #tmpFormSearch>
  <form [formGroup]="formGroupSearch">
    <ng-container style="width: 100%;">
      <div style="width: 100%; height: 100%; padding: 0 2rem;">
        <div class="form-add-header">Tìm kiếm</div>
        <div style="margin-bottom: 5px;">
          <app-input [labelName]="'Người tạo' | translate" [formControlName]="'searchUser'" [maxlength]="200"
            [isLabelLeft]="true">
          </app-input>
        </div>
        <div style="margin-bottom: 5px;">
          <app-input [labelName]="'Nội dung' | translate" [formControlName]="'searchContent'" [maxlength]="200"
            [isLabelLeft]="true">
          </app-input>
        </div>
        <div style="margin-bottom: 5px;">
          <!-- <app-date-range [labelName]="'Từ ngày - đến ngày'" [formControlName]="'searchTime'">
          </app-date-range> -->

          <div style="margin-bottom: 5px;">
            <app-core-datetime [formControlName]="'searchTimeStart'" [labelName]="'Từ ngày' | translate"
              [required]="false" [isLabelLeft]="true"
              [calConfig]="{ showTime: false, selectionMode: 'range', yearNavigator: true, monthNavigator: true, yearRange: '1900:2099' }">
            </app-core-datetime>
          </div>
          <div>
            <app-core-datetime [formControlName]="'searchTimeEnd'" [labelName]="'Đến ngày' | translate"
              [required]="false" [isLabelLeft]="true"
              [calConfig]="{ showTime: false, selectionMode: 'range', yearNavigator: true, monthNavigator: true, yearRange: '1900:2099' }">
            </app-core-datetime>
          </div>
        </div>

        <div style="display: flex; justify-content: center;">
          <app-button class="ml-3" typeButton="save" [labelName]="'Tìm kiếm' | translate"
            (clickEvent)="btnTimKiemClick()">
          </app-button>
          <app-button class="ml-3" typeButton="close" [labelName]="'Thoát' | translate"
            (clickEvent)="closeDialogSearch()">
          </app-button>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>
<ng-template #tmpFormAddCalendar>
  <ng-container style="width: 100%;">
    <div style="width: 100%; height: 100%; padding: 0 2rem;">
      <form [formGroup]="formGroupAdd">
        <div style="margin-bottom: 5px;">
          <app-input [labelWidth]="'200'" [labelName]="'Sự kiện' | translate" [required]="true"
            [formControlName]="'eventAdd'" [maxlength]="200" [isLabelLeft]="true">
          </app-input>
        </div>
        <!-- *ngIf="!_extWindow" -->
        <div style="margin-bottom: 5px;">
          <app-select [disabledCtrl]="_extWindow" [labelName]="'Detail window' | translate"
            [formControlName]="'chooseWindow'" [dataLookup]="arrWindow" displayField="DESCR" valueField="CODE"
            [required]="false">
          </app-select>
        </div>
        <div style="margin-bottom: 5px;">
          <!-- <app-date-range [labelName]="'Từ ngày - đến ngày'"
            [formatDate]="checkedDay ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm:ss'" [showTime]="checkedDay ? false : true"
            [formControlName]="'dateFromTo'">
          </app-date-range> -->
          <div style="margin-bottom: 5px;">
            <app-core-datetime [formControlName]="'dateFromAdd'" [labelName]="'Từ ngày' | translate" [required]="true"
              [isLabelLeft]="true"
              [calConfig]="{ showTime: checkedDay ? false : true, maxDate: maxDate, showSeconds: true }">
            </app-core-datetime>
          </div>
          <div>
            <app-core-datetime labelWidth="200" [formControlName]="'dateToAdd'" [labelName]="'Đến ngày' | translate"
              [required]="true" [isLabelLeft]="true"
              [calConfig]="{ showTime: checkedDay ? false : true, minDate: minDate, showSeconds: true }">
            </app-core-datetime>
          </div>
        </div>
        <div style="margin: 10px 0px;">
          <app-core-checkbox [hasFormCtrl]="true" [chkLabel]="'Cả ngày' | translate"
            [formControlName]="'chkTimeUnitAdd'">
          </app-core-checkbox>
          <app-core-checkbox style="margin-left: 10px;" [hasFormCtrl]="true" [chkLabel]="'Gửi thông báo' | translate"
            [formControlName]="'chkSendNotifi'">
          </app-core-checkbox>
        </div>
      </form>

      <form [formGroup]="formGroupNotiAdd">
        <ng-container *ngIf="checkedSendNotifi">
          <div style="margin-bottom: 5px;">
            <app-input [labelName]="'Nội dung thông báo' | translate" [required]="true"
              [formControlName]="'eventNotifiAdd'" [maxlength]="'200'" [isLabelLeft]="true">
            </app-input>
          </div>
          <div style="margin-bottom: 5px;">
            <!-- <app-core-datetime [formControlName]="'dateSendNotifiAdd'" [labelName]="'Ngày gửi' | translate"
              [required]="true" [isLabelLeft]="true" [calConfig]="{ showTime: checkedDay ? false : true }">
            </app-core-datetime> -->
            <!-- <app-input [labelName]="'Gửi trước: (ngày)' | translate" [required]="true" [type]="'number'"
              [formControlName]="'numberDate'" [maxlength]="'200'" [isLabelLeft]="true">
            </app-input> -->
            <app-select [labelName]="'Thời điểm gửi' | translate" [formControlName]="'numberDate'"
              [dataLookup]="arrDateSend" displayField="DESCR" valueField="CODE" [required]="true">
            </app-select>
          </div>
          <div style="margin-bottom: 5px;">
            <app-select [labelName]="'Mức độ ưu tiên' | translate" [formControlName]="'priorityAdd'"
              [dataLookup]="arrPriotity" displayField="DESCR" valueField="CODE" [required]="true">
            </app-select>
          </div>
          <div style="margin-bottom: 5px;">
            <app-input [required]="true" [labelName]="'Người dùng'" [isLabelLeft]="true"
              [formControlName]="'treeUserAdd'" type="text-button" (iconClick)="selectUser('single')"></app-input>
          </div>
        </ng-container>
      </form>

      <div style="display: flex; justify-content: center;" class="form-add-footer">
        <app-button class="ml-3" [labelName]="'Đồng ý' | translate" typeButton="save" (clickEvent)="openDialogWindow('single')">
        </app-button>
        <app-button class="ml-3" [labelName]="'Thoát' | translate" typeButton="close" (clickEvent)="closeDialogAdd()">
        </app-button>
      </div>
    </div>
  </ng-container>

</ng-template>
<ng-template #tmpFormAddManyCalendar>
  <ng-container style="width: 100%;">
    <div style="width: 100%; height: 100%; padding: 0 2rem;">
      <form [formGroup]="formGroupAddMany">
        <div style="margin-bottom: 5px;">
          <app-input [labelWidth]="'200'" [labelName]="'Sự kiện' | translate" [required]="true"
            [formControlName]="'eventAdd'" [maxlength]="200" [isLabelLeft]="true">
          </app-input>
        </div>
        <!-- *ngIf="!_extWindow" -->
        <div style="margin-bottom: 5px;">
          <app-select [disabledCtrl]="_extWindow" [labelName]="'Detail window' | translate"
            [formControlName]="'chooseWindow'" [dataLookup]="arrWindow" displayField="DESCR" valueField="CODE"
            [required]="false">
          </app-select>
        </div>
        <div style="margin-bottom: 5px;">
          <div *ngFor="let item of arrDateSelect" style="margin-bottom: 5px; display: flex;">
            <app-core-datetime style="    width: 100%;" [formControlName]="item.dateStr + '_start'" [labelName]="'Từ - đến' | translate"
              [required]="true" [isLabelLeft]="true"
              [calConfig]="{ showTime: checkedDay ? false : true, maxDate: maxDate, showSeconds: true }">
            </app-core-datetime>
            <app-core-datetime labelWidth="200" [formControlName]="item.dateStr + '_end'" [required]="true"
              [showLabel]="false" [isLabelLeft]="true"
              [calConfig]="{ showTime: checkedDay ? false : true, minDate: minDate, showSeconds: true }">
            </app-core-datetime>
          </div>
          <!-- <div>
            <app-core-datetime labelWidth="200" [formControlName]="'dateToAdd'" [labelName]="'Đến ngày' | translate"
              [required]="true" [isLabelLeft]="true"
              [calConfig]="{ showTime: checkedDay ? false : true, minDate: minDate, showSeconds: true }">
            </app-core-datetime>
          </div> -->
        </div>
        <div style="margin: 10px 0px;">
          <app-core-checkbox [hasFormCtrl]="true" [chkLabel]="'Cả ngày' | translate"
            [formControlName]="'chkTimeUnitAdd'">
          </app-core-checkbox>
          <app-core-checkbox style="margin-left: 10px;" [hasFormCtrl]="true" [chkLabel]="'Gửi thông báo' | translate"
            [formControlName]="'chkSendNotifi'">
          </app-core-checkbox>
        </div>
      </form>

      <form [formGroup]="formGroupNotiAddMany">
        <ng-container *ngIf="checkedSendNotifi">
          <div style="margin-bottom: 5px;">
            <app-input [labelName]="'Nội dung thông báo' | translate" [required]="true"
              [formControlName]="'eventNotifiAdd'" [maxlength]="'200'" [isLabelLeft]="true">
            </app-input>
          </div>
          <div style="margin-bottom: 5px;">
            <!-- <app-core-datetime [formControlName]="'dateSendNotifiAdd'" [labelName]="'Ngày gửi' | translate"
              [required]="true" [isLabelLeft]="true" [calConfig]="{ showTime: checkedDay ? false : true }">
            </app-core-datetime> -->
            <!-- <app-input [labelName]="'Gửi trước: (ngày)' | translate" [required]="true" [type]="'number'"
              [formControlName]="'numberDate'" [maxlength]="'200'" [isLabelLeft]="true">
            </app-input> -->
            <app-select [labelName]="'Thời điểm gửi' | translate" [formControlName]="'numberDate'"
              [dataLookup]="arrDateSend" displayField="DESCR" valueField="CODE" [required]="true">
            </app-select>
          </div>
          <div style="margin-bottom: 5px;">
            <app-select [labelName]="'Mức độ ưu tiên' | translate" [formControlName]="'priorityAdd'"
              [dataLookup]="arrPriotity" displayField="DESCR" valueField="CODE" [required]="true">
            </app-select>
          </div>
          <div style="margin-bottom: 5px;">
            <app-input [required]="true" [labelName]="'Người dùng'" [isLabelLeft]="true"
              [formControlName]="'treeUserAdd'" type="text-button" (iconClick)="selectUser('multi')"></app-input>
          </div>
        </ng-container>
      </form>

      <div style="display: flex; justify-content: center;" class="form-add-footer">
        <app-button class="ml-3" [labelName]="'Đồng ý' | translate" typeButton="save" (clickEvent)="openDialogWindow('many')">
        </app-button>
        <app-button class="ml-3" [labelName]="'Thoát' | translate" typeButton="close"
          (clickEvent)="closeDialogAddMany()">
        </app-button>
      </div>
    </div>
  </ng-container>

</ng-template>

<ng-template #tempUser>
  <div style="padding-bottom: 10px; height: 400px; overflow: auto;">
    <app-core-tree [dataTree]="arrUser" [(nodeSelectedModel)]="nodeSelected" [treeConfig]="treeConfig">
    </app-core-tree>
  </div>
  <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
    <app-button labelName='Cancel' typeButton="cancel" (clickEvent)="onCancelUser()"></app-button>
    <app-button labelName='OK' typeButton="save" style="margin-left: 10px;" (clickEvent)="onAddUser()"></app-button>
  </div>
</ng-template>

<app-dialog-prime #dialogFormSearch></app-dialog-prime>
<app-dialog-prime #dialogFormAdd></app-dialog-prime>
<app-dialog-prime #dialogFormAddMany></app-dialog-prime>
<app-dialog-prime #dialogFormEdit [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>
<app-dialog-prime #dialogUser [isAppendBody]="true"></app-dialog-prime>
<app-dialog-prime #dialogComponent [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>