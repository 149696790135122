<div class="bgClass" *ngIf="objConfigSystem !== null"
  [ngStyle]="{'background': 'url(' + urlBgLogin +') top center / cover no-repeat'}">

  <div class="form-body-container">
    <div class="logo-company">
      <div [ngStyle]="{'background-image': 'url(' + urlIcnLogin +')'}">
      </div>
      <span>WELCOME</span>
    </div>
    <div>
      <!-- <ng-container *ngTemplateOutlet="tmpAddTable,  context: {dataCol: item};"></ng-container> -->
      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'login'">
          <ng-container *ngTemplateOutlet="tmpLogin"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'signup'">
          <ng-container *ngTemplateOutlet="tmpSignUp"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- <ng-template #tmpLogin let-dataCol="dataCol"></ng-template> -->
  <ng-template #tmpSignUp>
    <app-sign-up (onChangeModeForm)="handlerChangeModeForm($event)"></app-sign-up>
  </ng-template>
  <ng-template #tmpLogin>
    <form [formGroup]="validateForm" class="login-form" (keyup.enter)="login()" (ngSubmit)="login()">
      <div class="titleClass">
        <label>{{'LOGIN' | translate}}</label>
      </div>
      <div class="fluid grid formgrid">
        <!-- <div class="field col-md-8 md-12">
          <app-input [type]="'text'" [labelName]="txtUser | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
            formControlName="USERNAME"></app-input>
        </div>
        <div class="field col-md-4 md-12">
          <app-input [type]="'text'" [labelName]="txtClientCode | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
            formControlName="CLIENTCODE"></app-input>
        </div> -->
        <div
          style="display: flex; align-items: center; width: calc(60% - 5px); padding: 0px 15px; padding-bottom: 10px;">
          <app-input [type]="'text'" [labelName]="txtUser | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
            style="width: 100%;" formControlName="USERNAME"></app-input>
        </div>
        <div style="width: 5px; font-weight: bold; display: flex; align-items: flex-end; padding-bottom: 10px;">.</div>
        <div style="display: flex; align-items: center; width: 40%; padding: 0px 15px; padding-bottom: 10px;">
          <app-input [type]="'text'" [labelName]="txtClientCode | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
            style="width: 100%;" formControlName="CLIENTCODE"></app-input>
        </div>

        <div class="field col-md-12 md-12">
          <app-input [type]="'password'" [labelName]="txtPass  | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
            formControlName="PASSWORD"></app-input>
        </div>
        <!-- <div class="field col-md-12 md-12">
          <div class="fluid grid formgrid">
            <div class="field col-md-6 md-6 p-field-checkbox">
              <app-core-checkbox [hasFormCtrl]="true" [chkLabel]="'Remember' | translate" formControlName="REMEMBER"
                [typeValue]="'boolean'"></app-core-checkbox>
            </div>
            <div class="field col-md-6 md-6">
              <span class="login-form-forgot" (click)="forgotPassword()">{{'Forgot Password' | translate}}</span>
            </div>
          </div>
        </div> -->
        <div
          style="display: flex; align-items: center; padding: 0px 15px; justify-content: space-between; width: 100%; padding-bottom: 10px;">
          <div style="width: 50%;">
            <app-core-checkbox [hasFormCtrl]="true" [chkLabel]="'Remember' | translate" formControlName="REMEMBER"
              [typeValue]="'boolean'"></app-core-checkbox>
          </div>
          <div style="width: 50%;">
            <span class="login-form-forgot" (click)="forgotPassword()">{{'Forgot Password' | translate}}</span>
          </div>


        </div>
        <div class="field col-md-12 md-12">
          <p-button [label]="'LOGIN' | translate" (onClick)="login()"></p-button>
        </div>
        <div class="field col-md-12 md-12 forn--bt__sigin">
          <span (click)="handlerSignup()" class="login-form-sign-up">{{"Sign up for a trial" |
            translate}}</span>
          <span (click)="handlerSignupUsing()" class="login-form-sign-up">{{"Register to use" |
            translate}}</span>
        </div>
      </div>
    </form>
  </ng-template>


  <div class="change-language">
    <div class="img-language" [style.backgroundImage]="'url(./assets/langImg/' + language.img + ')'">
    </div>
    <div style="color: #ffffff;">{{selectedValue}}</div>
    <i class="pi pi-angle-down ml-3" style="color: #ffffff; font-size: 16px; cursor: pointer;"
      (click)="opLanguage.toggle($event)"></i>
    <p-overlayPanel #opLanguage [style]="{width: '75px'}">
      <ng-template pTemplate>
        <div class="fluid grid formgrid">
          <!-- <ng-container *ngFor="let language of appPages">
            <div class="field col-md-12" (click)="switchLang(language, opLanguage)">
              <img [src]="language.icon" [alt]="language.title" style="width: 100%;" />
            </div>
          </ng-container> -->
          <ng-container *ngFor="let language of appService.listLang">
            <div class="item-menu-class" (click)="switchLang(language, opLanguage)" style="padding-bottom: 5px;">
              <img [src]="'./assets/langImg/' + language.img" [alt]="language.DESCR"
                style="width: 100%; height: 100%;" />
            </div>
          </ng-container>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>

</div>

<app-loading [class.hidden]="!loading"></app-loading>