import { Injectable } from '@angular/core';
import { parse, stringify } from 'zipson';
import { AppService } from 'app/app-base/app.service';
@Injectable()
export class ReportViewerActionService {

  constructor(private appService: AppService) { }

  // Xử lý các tham số config
  async processParamConfig(evt: any) {
    // Khởi tạo biến lưu trữ xem có tồn tại dữ liệu không?
    let isMappingData = false;
    // Tham số trong config
    const objParam = evt.report.ExtType ? JSON.parse(evt.report.ExtType) : null;
    // Tham số dữ liệu của bản ghi
    const objCurentData = evt.currentData ? evt.currentData : null;
    let params: any = {};
    // Tham số id report
    params['idReport'] = evt.report.WindowId;
    const configReport = (evt.report.config && evt.report.config !== null && evt.report.config !== '') ? parse(evt.report.config) : null;

    if (configReport) {
      if (configReport.tableUrl.includes("token=")) {
        params['tokenType'] = "tokenGIS";
      }
      if (configReport.tableUrl.includes("tokenbearer=")) {
        params['tokenType'] = "tokenTABLE";
      }
    }

    // Nếu tồn tại thì mapping tham số đã config với dữ liệu của bản ghi
    if (objCurentData != null && objParam != null) {
      for (const key in objCurentData) {
        if (Object.hasOwnProperty.call(objCurentData, key)) {
          for (const param in objParam) {
            if (Object.prototype.hasOwnProperty.call(objParam, param)) {
              if (key === param) {
                isMappingData = true;
                const variable = objCurentData[param].toString().includes("+") ? objCurentData[param].replaceAll("+", "dau_cong") : objCurentData[param];
                params[param] = variable;
              }
            }
          }
        }
      }
    }
    // Dữ liệu của bản ghi không tồn tại hoặc không có dữ liệu mapping
    if (!isMappingData) {
      for (const param in objParam) {
        if (Object.prototype.hasOwnProperty.call(objParam, param)) {
          const variable = objParam[param];
          params[param] = variable;
          if (params[param].includes("c$")) {
            let parts = params[param].split('.');
            params[param] = window.c$[parts[1]]
          }
        }
      }
    }

    let stringToken = '';
    if (params.tokenType && params.tokenType === "tokenGIS") {
      let urlView = configReport.urlView;
      const tokentArc = await this.appService.getArcGISToken(urlView);
      let token = tokentArc ? tokentArc : '';
      stringToken = token !== '' ? token : '';
      params.token = stringToken;
    }

    if (params.tokenType && params.tokenType === "tokenTABLE") {
      if (evt.isMobile) {
        stringToken = evt.token
        params.token = stringToken
      } else {
        stringToken = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']).token;
        params.token = stringToken;
      }

    }

    return params;
  }
}
