<p-table #pTable [value]="dataTable" [(selection)]="selectionRow" [selectionMode]="selectionMode"
    [columns]="columnTable" [scrollable]="true" scrollDirection="both" (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)" (onEditComplete)="onEditComplete($event)" (onEditInit)="onEditInit($event)"
    (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" (onPage)="onPageChange($event)" [(first)]="firstIndex">

    <ng-template pTemplate="caption">
        <div class="p-d-flex" *ngIf="hasTool" style="display: flex; justify-content: flex-start;">
            <button type="button" pButton icon="pi pi-file-o" *ngIf="toolConfig.isAdd" (click)="onToolClick('add')"
                class="p-mr-2" label="{{'Add' | translate}}"
                style="margin-right: 5px; color: var(--color_text); background-color: transparent; border-color: transparent; "></button>
            <button type="button" pButton icon="pi pi-pencil" *ngIf="toolConfig.isEdit" (click)="onToolClick('edit')"
                class="p-mr-2" label="{{'Edit' | translate}}"
                style="margin-right: 5px; color: var(--color_text); background-color: transparent; border-color: transparent; "></button>
            <button type="button" pButton icon="pi pi-save" *ngIf="toolConfig.isSave" (click)="onToolClick('save')"
                class="p-mr-2" label="{{'Save' | translate}}"
                style="margin-right: 5px; color: var(--color_text); background-color: transparent; border-color: transparent; "></button>
            <button type="button" pButton icon="pi pi-trash" *ngIf="toolConfig.isDelete" (click)="onToolClick('delete')"
                class="p-mr-2" label="{{'Delete' | translate}}"
                style="margin-right: 5px; color: var(--color_text); background-color: transparent; border-color: transparent; "></button>
            <button type="button" pButton icon="pi pi-search" *ngIf="toolConfig.isSearch"
                (click)="onToolClick('search')" class="p-mr-2" label="{{'Search' | translate}}"
                style="margin-right: 5px; color: var(--color_text); background-color: transparent; border-color: transparent; "></button>
            <ng-container *ngFor="let item of arrToolCustomize">
                <button type="button" pButton [icon]="item.icon" (click)="onToolClick(item.toolName)" class="p-mr-2"
                    label="{{item.title | translate}}"
                    style="margin-right: 5px; color: var(--color_text); background-color: transparent; border-color: transparent; "></button>
            </ng-container>
        </div>
        <div class="p-d-flex" style="display: flex; align-items: center; padding-left: 10px;"
            *ngIf="hasTool && toolConfig.isDelete && isMutiSelect">
            <div style="color: red; font-size: 12px; padding-right: 10px; cursor: pointer;"
                (click)="onToolClick('delete-ok')">{{'Delete'
                | translate}}</div>
            <div style="color: var(--color_text); font-size: 12px; padding-right: 10px; cursor: pointer;"
                (click)="onToolClick('delete-cancel')">
                {{'Cancel' | translate}}</div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngIf="isMutiSelect">
                <th style="width: 40px" pFrozenColumn [frozen]="true" alignFrozen="left">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
            </ng-container>
            <ng-container *ngFor="let col of columns; let ind = index">
                <ng-container *ngIf="sortFieldList.includes(col[displayField])">
                    <th *ngIf="!fieldHiddenList.includes(col[displayField])" pFrozenColumn
                        [frozen]="arrFrozenColumns.includes(col[displayField])" [pSortableColumn]="col[displayField]"
                        [ngClass]="{'td-mutiselect-class': arrFrozenColumns.includes(col[displayField]), 'no-frozen-class': arrFrozenColumns.length === 0}"
                        [ngStyle]="{'width': col[setWidthField] ? col[setWidthField] : '200px'}">
                        <div
                            style="display: flex; align-items: center; justify-content: space-between; width: 100%; word-break: break-word;  white-space: normal;">
                            {{col[titleField] | translate}}
                            <div style="display: flex; align-items: center;">
                                <p-sortIcon [field]="col[displayField]"></p-sortIcon>
                                <p-columnFilter type="text" [showClearButton]="false" [field]="col[displayField]"
                                    display="menu" *ngIf="filterFieldList.includes(col[displayField])"></p-columnFilter>
                            </div>
                        </div>

                    </th>
                </ng-container>
                <ng-container *ngIf="!sortFieldList.includes(col[displayField])">
                    <th *ngIf="!fieldHiddenList.includes(col[displayField])" pFrozenColumn
                        [frozen]="arrFrozenColumns.includes(col[displayField])"
                        [ngClass]="{'td-mutiselect-class': arrFrozenColumns.includes(col[displayField]), 'no-frozen-class': arrFrozenColumns.length === 0}"
                        [ngStyle]="{'width': col[setWidthField] ? col[setWidthField] : '200px'}">
                        <div
                            style="display: flex; align-items: center; justify-content: space-between; width: 100%; word-break: break-word;  white-space: normal;">
                            {{col[titleField] | translate}}
                            <div style="display: flex;  align-items: center;">
                                <p-columnFilter type="text" [field]="col[displayField]" display="menu"
                                    *ngIf="filterFieldList.includes(col[displayField])"></p-columnFilter>
                            </div>
                        </div>
                    </th>
                </ng-container>

            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="isMutiSelect ? null : rowData" (dblclick)="onDbClick($event, rowData)">
            <td style="width: 40px" *ngIf="isMutiSelect" pFrozenColumn [frozen]="true" alignFrozen="left">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <ng-container *ngFor="let item of columns">
                <ng-container *ngIf="!fieldHiddenList.includes(item[displayField])">
                    <!-- <ng-container *ngIf="fieldEditList.length > 0">
                        <ng-container *ngFor="let field of fieldEditList"> -->
                    <ng-container *ngIf="checkEdit(item) <= -1">
                        <ng-container *ngIf="!percentField || !item[percentField]">
                            <td [ngStyle]="{'width': item[setWidthField] ? item[setWidthField] : '200px', 
                            'background-color': fieldColor.includes(item[displayField]) && rowData[keyColor] ? rowData[keyColor]: 'var(--color_bg_panel)'}"
                                pFrozenColumn [frozen]="arrFrozenColumns.includes(item[displayField])"
                                [ngClass]="{'td-mutiselect-class': arrFrozenColumns.includes(item[displayField]), 'no-frozen-class': arrFrozenColumns.length === 0}">
                                {{rowData[item[displayField]]}}</td>
                        </ng-container>
                        <ng-container *ngIf="percentField && item[percentField]">
                            <td [ngStyle]="{'width': item[setWidthField] ? item[setWidthField] : '200px',
                            'background-color': fieldColor.includes(item[displayField]) && rowData[keyColor] ? rowData[keyColor]: 'var(--color_bg_panel)'}"
                                pFrozenColumn [frozen]="arrFrozenColumns.includes(item[displayField])"
                                [ngClass]="{'td-mutiselect-class': arrFrozenColumns.includes(item[displayField]), 'no-frozen-class': arrFrozenColumns.length === 0}">
                                <div style="display: flex; align-items: center; width: 100%; height: 100%;">
                                    <div style="width: calc(100% - 50px); height: 100%;">
                                        <p-progressBar [value]="rowData[item[displayField]]" [showValue]="false">
                                        </p-progressBar>
                                    </div>

                                    <label
                                        style="padding-left: 5px; font-size: 12px; margin-bottom: 0px; width: 50px; ">{{rowData[totalValueField]}}</label>
                                </div>

                            </td>
                        </ng-container>

                    </ng-container>
                    <ng-container *ngIf="checkEdit(item) > -1">
                        <td [pEditableColumn]="rowData" [pEditableColumnField]="item[displayField]" pFrozenColumn
                            [frozen]="arrFrozenColumns.includes(item[displayField])" 
                            [ngClass]="{'td-mutiselect-class': arrFrozenColumns.includes(item[displayField]), 'no-frozen-class': arrFrozenColumns.length === 0}"
                            [ngStyle]="{'width': item[setWidthField] ? item[setWidthField] : '200px', 
                            'background-color': fieldColor.includes(item[displayField]) && rowData[keyColor] ? rowData[keyColor]: 'var(--color_bg_panel)'}">
                            <p-cellEditor style="width: 100%;">
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="rowData[item[displayField]]"
                                        style="width: 100%;" (keydown) = "onKeyDown($event)"
                                        *ngIf="fieldEditList[checkEdit(item)].typeInput === 'text'">
                                    <ng-container *ngIf="fieldEditList[checkEdit(item)].typeInput === 'number'">
                                        <p-inputNumber [(ngModel)]="rowData[item[displayField]]" (keydown) = "onKeyDown($event)">
                                        </p-inputNumber>
                                    </ng-container>
                                    <ng-container *ngIf="fieldEditList[checkEdit(item)].typeInput === 'checkbox'">
                                        <!-- <p-inputNumber [(ngModel)]="rowData[item[displayField]]">
                                        </p-inputNumber> -->
                                        <!-- <app-core-checkbox [hasFormCtrl]="false" chkName="groupname2" [chkLabel]=""
                                            [(valueModel)]="rowData[item[displayField]]" [typeValue]="'string'">
                                        </app-core-checkbox> -->
                                        <app-core-state-checkbox [hasFormCtrl]="false" [chkLabel]=""
                                            [(valueModel)]="rowData[item[displayField]]"
                                            [typeValue]="'string'"></app-core-state-checkbox>

                                    </ng-container>
                                    <ng-container *ngIf="fieldEditList[checkEdit(item)].typeInput === 'select'">
                                        <app-select [hasFormCtrl]="false"
                                            [displayField]="fieldEditList[checkEdit(item)][descrKey]"
                                            [valueField]="fieldEditList[checkEdit(item)][valueKey]"
                                            [dataLookup]="fieldEditList[checkEdit(item)].arrLookUp" [showLabel]='false'
                                            [isLabelLeft]="false" [(valueModel)]="rowData[item[displayField]]">
                                        </app-select>
                                    </ng-container>
                                    <!-- <ng-container *ngIf="fieldEditList[checkEdit(item)].typeInput === 'json-tran'">
                                        <app-core-whereclause mode='json' [isShowLabel] = 'false' [hasFormCtrl]="false"
                                            [titleDialog]='item[displayField]' [(valueModel)]="rowData[item[displayField]]" [typeField]='"lang"'
                                            [maxLength]="'2000'"
                                            [isTypeArray]="true"></app-core-whereclause>
                                    </ng-container> -->
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{rowData[item[displayField]]}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </ng-container>
                    <!-- </ng-container>
                    </ng-container>
                    <ng-container *ngIf="fieldEditList.length === 0">
                        <td [ngStyle]="{'width': item[setWidthField] ? item[setWidthField] : '200px'}" pFrozenColumn
                            [ngClass]="{'td-mutiselect-class': arrFrozenColumns.includes(item[displayField])}"
                            [frozen]="arrFrozenColumns.includes(item[displayField])">{{rowData[item[displayField]]}}
                        </td>
                    </ng-container> -->

                </ng-container>


            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length" style="border: none !important;">
                {{'No Data' | translate}}
            </td>
        </tr>
    </ng-template>
</p-table>