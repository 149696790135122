import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { RequestService } from 'app/core/services/request.service';

@Component({
    selector: 'app-search-advance',
    templateUrl: './search-advance.component.html',
    styleUrls: ['./search-advance.component.scss'],
    providers: [RequestService]
})
export class SearchAdvanceComponent implements OnInit, OnChanges {
    @Output() searchComplete: EventEmitter<any> = new EventEmitter();
    /** Hiển thị nút chấp nhận hay không. Mặc định false */
    @Input() showImportBtn = false;
    /** Dữ liệu cấu hình gốc */
    @Input() dataSource: any = null;


    public formData: FormGroup = new FormGroup({});
    public list: any = {};
    public lookupCondition: any = {};
    public fullLookup: any = null;
    public calConfig = { showTime: false, selectionMode: 'range', yearNavigator: true, monthNavigator: true, yearRange: '1900:2099' }

    public listRadio = [
        { CODE: 'and', DESCR: 'AND' },
        { CODE: 'or', DESCR: 'OR' }
    ]
    public radioCtrl = new FormControl('and');
    private radioValue: any = 'and';
    private count = 0;

    constructor(
        private appService: AppService,
        private reqService: RequestService
    ) { }

    ngOnInit(): void {
        this.fullLookup = this.appService.getLookup();
        this.addNewClause();

        this.radioCtrl.valueChanges.subscribe(res => {
            this.radioValue = res.CODE;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataSource) {
            if (this.dataSource !== null && this.dataSource !== undefined) {
                this.init();
            }
        }
    }

    private async init() {
        const listKey: any[] = [];
        this.dataSource.FIELD_LIST.forEach((field: any) => {
            if (field.issearch === 'Y') {
                listKey.push({
                    CODE: field.fieldname,
                    DESCR: field.alias && field.alias !== '' ? field.alias.trim() : field.fieldname,
                    TYPE: field.fieldtype,
                    COLUMN_TYPE: field.columntype,
                    DOMAIN_ID: field.domainid,
                    FIELD_FILTER: {
                        FOREIGNTABLE: field.foreigntable,
                        FOREIGNTABLE_ID: field.foreigntableid,
                        COLUMNKEY: field.columnkey,
                        COLUMNDISPLAY: field.columndisplay,
                        COLUMNCODE: field.columncode,
                        TREECOLUMN: field.treecolumn,
                        WHEREFIELDNAME: field.wherefieldname, // sử dụng cho control select
                        FOREIGNWINDOW_ID: field.foreignwindowid,
                        FIELD_ISDOMAIN: field.isfromdomain === 'Y',
                        FIELD_PARENT_ID: field.parentfieldid,
                        // Dùng cho filter-input (phân biệt khi có 2 control khác fieldname, nhưng đều trỏ vào chung 1 bảng)
                        FIELD_NAME: field.fieldname ? field.fieldname.trim() : field.fieldname,
                        SERVICE_TYPE: field.foreigntableservicetype && field.foreigntableservicetype !== '' ?
                            field.foreigntableservicetype : this.dataSource.INFORMATION.SERVICE_TYPE // Control sử dụng kiểu service type nào

                    }
                });
            }
        });

        this.lookupCondition['key'] = listKey;

        const listOperator = [
            { CODE: '=', DESCR: '=' },
            { CODE: '<>', DESCR: '<>' },
            { CODE: '>', DESCR: '>' },
            { CODE: '>=', DESCR: '>=' },
            { CODE: '<', DESCR: '<' },
            { CODE: '<=', DESCR: '<=' },
            { CODE: 'like', DESCR: 'like' },
            // { CODE: 'in', DESCR: 'in' },
            // { CODE: '!in', DESCR: 'not in' }
        ];

        this.lookupCondition['operator'] = listOperator;

        switch (this.dataSource.INFORMATION.SERVICE_TYPE) {
            // case 'AutoData':
            //     this.reqService.switchType('autodata');
            //     break;
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgre':
                this.reqService.switchType('postgre');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }
    }

    private addNewClause() {
        this.count++;
        const count = this.count; // for local calculate
        const key = new FormControl();
        const operator = new FormControl();
        const value = new FormControl();
        const type = '';

        key.valueChanges.subscribe(resp => {
            const res = this.lookupCondition['key'].filter((fil: any) => fil.CODE === resp)[0];
            this.list[count][4] = false; // đánh dấu disable control operator hay không
            this.list[count][3] = res ? res.TYPE : 'text';
            this.lookupCondition['value' + count] = [];
            if (res && res.TYPE === 'select') {
                if (res.DOMAIN_ID) {
                    this.lookupCondition['value' + count] = this.fullLookup[res.DOMAIN_ID];
                    this.list[count][5] = null;
                } else {
                    this.lookupCondition['value' + count] = [];
                    this.list[count][5] = res.FIELD_FILTER;
                }
            }
            if (res && res.TYPE === 'date') {
                this.list[count][4] = true;
                operator.setValue(this.lookupCondition['operator'][0]);
            }
            value.reset();
        });

        this.formData.addControl('key' + count, key);
        this.formData.addControl('operator' + count, operator);
        this.formData.addControl('value' + count, value);

        this.list[count] = ['key' + count, 'operator' + count, 'value' + count, type, false];
    }

    onApply() {
        if (this.formData.valid) {
            const keys: any[] = [];
            const operators: any[] = [];
            const values: any[] = [];
            Object.keys(this.formData.value).forEach(item => {
                if (item.includes('key')) {
                    keys.push(this.formData.value[item]);
                } else if (item.includes('value')) {
                    values.push(this.formData.value[item]);
                } else {
                    operators.push(this.formData.value[item]);
                }
            });

            const where: any[] = [];
            where.push(this.radioValue);
            keys.forEach((resp, index) => {
                // Control select giờ không trả ra Object nữa mà chỉ trả ra CODE, muốn tìm lại object cần phải lọc
                const item = this.lookupCondition['key'].filter((fil: any) => fil.CODE === resp)[0];
                if (item.TYPE === 'select') {
                    const val = values[index] && values[index].CODE ? values[index].CODE : values[index];
                    where.push([item.CODE, operators[index], val]);
                } else if (item.TYPE === 'date') {
                    const value = values[index];
                    if (value && value[0]) {
                        if (!value[1]) {
                            value[1] = value[0];
                        }
                        if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                            // kiểu date của arcgis hơi khác
                            const date0 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[0], 'first')}'`;
                            const date1 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[1], 'last')}'`;
                            // date0 sẽ tìm kiếm từ 00h00p00s, date1 sẽ tìm kiếm từ 23h59p59s của ngày
                            where.push([item.CODE, '>=', date0]);
                            where.push([item.CODE, '<=', date1]);
                        } else {
                            const date0 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[0], 'first');
                            const date1 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[1], 'last');
                            where.push([item.CODE, '>=', date0]);
                            where.push([item.CODE, '<=', date1]);
                        }
                    }
                } else {
                    if (item.COLUMN_TYPE === 'number') {
                        where.push([item.CODE, operators[index], Number(values[index])]);
                    } else {
                        where.push([item.CODE, operators[index], values[index]]);
                    }
                }
            });

            const data = {
                where,
                typeButton: 'btnSearchAdvanced2'
            };

            this.searchComplete.emit(data);
        } else {
            this.appService.notification('Invalid Form !!!', 'erorr');
        }
    }

    onAcceptRecord() {
        const data = {
            typeButton: 'btnAccept'
        };
        this.searchComplete.emit(data);
    }

    onCancel() {
        this.searchComplete.emit({
            typeButton: 'btnCancel'
        });
    }

    onAddClause() {
        this.addNewClause();
    }

    onDeleteRow(key: any) {
        if (Object.keys(this.list).length > 1) {
            // this.list = this.list.filter(fil => fil !== row);
            this.formData.removeControl(this.list[key][0]);
            this.formData.removeControl(this.list[key][1]);
            this.formData.removeControl(this.list[key][2]);
            delete this.list[key];
        }
    }

}
