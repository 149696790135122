import { Injectable } from "@angular/core";
import { CoreModule } from "app/core/core.module";
// import * as SystemJS from 'systemjs'
declare const System: any;
declare const applyImportMap: any;
// const { System, applyImportMap } = require('systemjs');
@Injectable()
export class PluginFileService {
  constructor() {
  }
  public async loadFile(url: any) {
    
    const sysjs = new System.constructor()
    sysjs.addImportMap({
      imports: {
        "module-a": url,
        "CoreModule": CoreModule
      }
    });
    // sysjs.import('CoreModule');
    // sysjs.resolve(CoreModule)
    const module = sysjs.import('module-a');

    return module
    // var js = document.createElement('script');
    // js.type = 'module'
    // js.src = url;

    // js.onload = function () {

    //  }
    // document.head.appendChild(js);
  }
}