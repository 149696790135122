import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
// import { ArcGISService } from './arcgis.service';
import { ArcGIS3XService } from './arcgis3x.service';
import { AutoDataService } from './auto-data.service';
import { PostgreService } from './postgre.service';
import { SqlService } from './sql-odata.service';
import { OracleService } from './oracle.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  service!: BaseInterface;
  type = '';

  // protected arcgis: ArcGISService;
  protected arcgix3x: ArcGIS3XService;
  protected postgre: PostgreService;
  protected sql: SqlService;
  protected autodata: AutoDataService;
  protected oracle: OracleService;

  constructor(
    injector: Injector
  ) {
    // window['DataAccess'] = this;
    // this.arcgis = injector.get(ArcGISService);
    this.postgre = injector.get(PostgreService);
    this.sql = injector.get(SqlService);
    this.arcgix3x = injector.get(ArcGIS3XService);
    this.autodata = injector.get(AutoDataService);
    this.oracle = injector.get(OracleService);

    // Default service: sql;
    this.service = this.sql;
  }

  switchType(type: 'clouddata' | 'arcgis' | 'arcgis3x' | 'postgre' | 'sql' | 'oracle') {
    this.type = type;
    switch (type) {
      case 'clouddata':
        this.service = this.autodata;
        break;
      case 'arcgis':
        // this.service = this.arcgis;
        this.service = this.arcgix3x;
        break;
      case 'arcgis3x':
        // this.service = this.arcgis;
        this.service = this.arcgix3x;
        break;
      case 'postgre':
        this.service = this.postgre;
        break;
      case 'sql':
        this.service = this.sql;
        break;
      case 'oracle':
        this.service = this.oracle;
        break;
      default:
        this.service = this.sql;
        break;
    }
  }

  /**
   * Chuyển đổi định dạng date về Format YYYY-MM-DD hoặc YYYY-MM-DD HH:MI:SS
   *
   * type = 'first' sẽ có thêm phần giờ phút giây là 00h00p00s
   *
   * type = 'last' sẽ có thêm phần giờ phút giây là 23h59p59s
   *
   * type = 'none' sẽ không có thêm phần giờ phút giây
   */
  public formatDateTo_YYYYMMDD_HHMISS(date: string | number | Date, type: 'first' | 'last' | 'none') {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    let str = [year, month, day].join('-');
    if (type === 'first') {
      str += ' 00:00:00';
    } else if (type === 'last') {
      str += ' 23:59:59';
    }
    return str;
  }

  /**
   * Cũng là hàm chuyển đổi định dạng, nhưng là dành cho SQL
   *
   * Chuyển đổi định dạng date về Format YYYY-MM-DD hoặc YYYY-MM-DD HH:MI:SS
   *
   * type = 'first' sẽ có thêm phần giờ phút giây là 00h00p00s
   *
   * type = 'last' sẽ có thêm phần giờ phút giây là 23h59p59s
   *
   * type = 'none' sẽ không có thêm phần giờ phút giây
   */
  public formatDateTo_YYYYMMDD_HHMISS_SQL(date: string | number | Date, type: 'first' | 'last' | 'none') {
    const d = new Date(date);
    // setHours là lấy giờ theo Zone, setUTCHours là lấy giờ quốc tế
    if (type === 'first') {
      d.setHours(0, 0, 0, 0);
      // d.setUTCHours(0, 0, 0, 0);
    } else if (type === 'last') {
      d.setHours(23, 59, 59);
      // d.setUTCHours(23, 59, 59);
    }
    return d;
  }

  mapConfig(conf: { window: any[]; tabs: any[]; fields: string | any[]; }) {

    const _sys = {
      window: ['windowid', 'windowname', 'windowtype', 'applicationid', 'componentname', 'servicetype'],
      tab: ['tabid', 'parenttabid', 'tabname', 'tablevel', 'orderno', 'truonglienketcon', 'truonglienketcha', 'whereclause', 'orderbyclause', 'truongtrunggiancon', 'truongtrunggiancha', 'windowid', 'tableid', 'bangtrunggian', 'layoutcolumn', 'banglienket', 'tablename', 'tabletype', 'columnkey', 'hasattachment', 'iscache', 'urledit', 'urlview', 'columndisplay', 'tablelienket', 'tabledetail', 'columnkeytrunggian', 'iscollapse', 'fillterfield', 'fillterdefaultvalue', 'initshow', 'onchange', 'urlviewdohoa', 'urleditdohoa', 'tableworkflowId', 'jobtypeids', 'layerindex', 'issinglelineedit', 'lineperpage', 'columncode', 'istabtrunggian', 'kvhccolumn', 'columnworkflow', 'columnlinklayer', 'isrefreshtabparrent', 'columnlock', 'logtype', 'description'],
      field: ['fieldid', 'fieldname', 'alias', 'isdisplaygrid', 'isdisplay', 'displaylength', 'orderno', 'isreadonly', 'fieldlength', 'vformat', 'defaultvalue', 'isrequire', 'isunique', 'fieldgroup', 'workflowtype', 'tabid', 'columnid', 'fieldtype', 'issearch', 'issearchtonghop', 'parentfieldid', 'wherefieldname', 'columnname', 'foreigntable', 'columnkey', 'columndisplay', 'isfromdomain', 'domainid', 'foreigntableid', 'columncode', 'displaylogic', 'calculation', 'placeholder', 'columntype', 'foreignwindowid', 'disablelogic', 'columndohoa', 'isfilterfield', 'filterdefaultvalue', 'treecolumn', 'isfrozen', 'whereclause', 'calculationlabel', 'colspan', 'bindfieldname', 'fieldautodata', 'foreigntableservicetype'],
      menuitem: ['menuitemid', 'menuitemname', 'parentid', 'orderno', 'description', 'issummary', 'applicationid', 'windowid', 'clientid', 'tabid', 'menuitemtype', 'icon', 'execname', 'linkwindowid', 'menulevel']
    };

    const lookup: any = {};
    const lookupField: any = {};
    const lookupFieldName: any = {};
    const winconf: any = { tabs: [], error: null };

    _sys.window.forEach((item, i) => {
      winconf[item] = conf.window[i];
    });

    /** Khởi tạo look up trước */
    conf.tabs.forEach((item: any[]) => {
      const tab: any = { fields: [], tabs: [], menuitems: [], children: [], maxLevel: 0 };
      _sys.tab.forEach((val, index) => tab[val] = item[index]);
      if (tab.tablevel === 0) { winconf.tabs.push(tab); }
      lookup[tab.tabid] = tab;

      if (tab.parenttabid) {
        const parenttab = lookup[tab.parenttabid];
        tab.parent = parenttab;
        if (parenttab) {
          parenttab.children.push(tab);
          if (tab.tablevel > 0) {
            if (tab.tablevel > parenttab.tablevel) {
              parenttab.tabs.push(tab);
              if (tab.tablevel > parenttab.maxLevel) { parenttab.maxLevel = tab.tablevel; }
            } else {
              lookup[parenttab.parenttabid].tabs.push(tab);
            }
          }
        }
      }
    });

    for (let i = 0; i < conf.fields.length; i++) {
      const field: any = {};
      for (let j = 0; j < _sys.field.length; j++) {
        field[_sys.field[j]] = conf.fields[i][j];
      }
      // var tab=lookup[field['tabid']];
      // tab.fields.push(field);
      lookup[field['tabid']].fields.push(field);
      // khoanb
      lookupField[field['fieldid']] = field;
      // lookupFieldName[field['fieldname']] = field;
      lookupFieldName[lookup[field['tabid']].tabname + '.' + field['fieldname']] = field;
    }
    // khoanb calculation
    // const REGEXP = /(?<=\[).*?(?=\])/g; // RegEx này gây lỗi ở Safari (iOS)
    const REGEXP = /\[([^\][]*)]/g;
    const REGEXP_SUM = /sum|count|avg|min|max/g;
    console.log(lookupField);
    Object.keys(lookupField).forEach((key) => {
      const field = lookupField[key];
      const tab = lookup[field.tabid];
      // console.log(tab, field);
      if (field.parentfieldid && lookupField[field.parentfieldid]) {
        if (!lookupField[field.parentfieldid].children) {
          lookupField[field.parentfieldid].children = [];
        }
        lookupField[field.parentfieldid].children.push(field);
      }
      if (field.calculation) {
        field.calculationInfos = [];
        //  const fldnames = field.calculation.match(REGEXP);
        const names = field.calculation.match(REGEXP);
        const funcs = field.calculation.match(REGEXP_SUM);
        let f = 0;
        for (let i = 0; i < names.length; i++) {
          let info: any = null;
          let name: string = names[i]; // names[i] có dạng '[<string>]'
          name = name.substring(1, name.length - 1); // xóa 2 dấu [ ] trong string
          const isFullName = name.includes('.');
          const nameLookup = isFullName ? name : tab.tabname + '.' + name;
          if (!lookupFieldName[nameLookup]) {
            lookupFieldName[nameLookup] = {};
          }
          if (lookupFieldName[nameLookup].children === undefined) {
            lookupFieldName[nameLookup].children = [];
          }
          lookupFieldName[nameLookup].children.push(field);
          if (isFullName) {
            const tokens = name.split('.');
            const tabName = tokens[0];
            // Có tabName => tìm tabId tương ứng tabName
            for (let key in lookup) {
              if (lookup[key].tabname === tabName) {
                info = {
                  func: funcs ? funcs[f++] : null,
                  tab: lookup[key].tabid,
                  field: tokens[1]
                };
                // tslint:disable-next-line:align
                break;
              }
            }
          } else { info = { field: name }; }



          if (!info) {
            winconf.error = `Field ${field.fieldname} in Tab ${tab.tablename}: Field calculation get wrong!!!`;
            return;
          }

          field.calculation = field.calculation.replace(info.func ? info.func + '[' + name + ']' : '[' + name + ']', '_v[' + i + ']');
          field.calculationInfos.push(info);
        }
      }
    });

    console.log(winconf);

    return winconf;
  }
}

export interface BaseInterface {
  search(p: OdataParams): Observable<SearchResponse>;
  insert(p: OdataParams): Observable<InsertResponse>;
  update(p: OdataParams): Observable<UpdateResponse>;
  delete(p: OdataParams): Observable<DeleteResponse>;
  query(q: QdataParams): Observable<any>;
  queryCustom(q: QdataParams): Observable<any>; // Thiếu option nhập formData
  /** lấy record dựa trên id */
  getRecordById(q: IdParams): Observable<any>;
  searchForMobile(p: OdataParams, token: any): Observable<SearchResponse>;
}

export interface DeleteResponse {
  success: boolean;
  data: Array<any>;
  message: string;
}

export interface UpdateResponse {
  success: boolean;
  message: string;
  features?: any;
}

export interface InsertResponse {
  features: any;
  total: number;
  success: boolean;
  message: string;
}

export interface SearchResponse {
  features: Array<any>;
  total: number;
  success: boolean;
  message: string;
}

export interface QdataParams {
  url: string;
  params: any; // params cần gửi, có dạng object, ví dụ { name: 'mr.a', age: 41, sex: 'male'}
  proxy?: string; // đường dẫn của proxy, ví dụ: https://coretech:1313. chỉ có proxy khi khác domain, nếu không chỉ định proxy thì lấy mặc định từ appservice
  method?: 'GET' | 'POST';
  contentType?: string; // content type trong header
  responseType?: string; // kiểu dữ liệu trả về, bao gồm: 'arraybuffer' | 'blob' | 'json' | 'text'
}

export interface OdataParams {
  url: string;
  select?: Array<string>;
  where?: any; // Quy ước: where = [] => lấy toàn bộ dữ liệu, where = null => không tìm dữ liệu
  logic?: string;
  orderBy?: Array<string>;
  groupBy?: Array<string>; // tạm thời chưa dùng
  pageSize?: number;
  startRecord?: number;
  data?: any;
  primaryKey?: string;
  and?: any; // cấu trúc giống where
  or?: any; // cấu trúc giống where
  geometry?: any; // Đồ họa thêm vào trong bảng
  distinct?: boolean; // Có query distinct hay không (true = có)
  returnGeometry?: boolean; // Có trả về geometry hay không
  spatialRelationship?: string; // kiểu relationship của geometry
  token?: string;
}

export interface IdParams {
  url: string;
  id: any;
}
