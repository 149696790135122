import { combineLatest } from 'rxjs';
import { RequestService } from 'app/core/services/request.service';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';

@Component({
  selector: 'app-form-edit-calendar',
  templateUrl: './form-edit-calendar.component.html',
  styleUrls: ['./form-edit-calendar.component.scss']
})
export class FormEditCalendarComponent implements OnInit {

  // @Output() saveSuccessed: EventEmitter<any> = new EventEmitter();
  public mode = 'edit';
  @Output() saveSuccess: EventEmitter<any> = new EventEmitter();

  public _windowIdCal = null;
  public ExtType = null;
  public nameType = '';
  checkedDay: Boolean = true;
  crtFromDate: any = null;
  crtToDate: any = null;
  crtEvent: any = null;
  windowIdDetail: any = null;

  nodeSelected: any = [];
  public arrUser: any[] = [];

  minDate: any = null;
  maxDate: any = null;

  whereCal: any = null;

  urlResource = '';
  url_Muon_Tra = '';

  treeConfig: any = { selectionMode: 'checkbox' };

  @ViewChild('dialogNotifi', { static: false }) dialogNotifi!: DialogPrimeComponent;
  @ViewChild('tmpFormNotifi', { static: false }) tmpFormNotifi!: TemplateRef<any>;

  @ViewChild('dialogUser', { static: true }) dialogUser!: DialogPrimeComponent;
  @ViewChild('tempUser') tempUser!: TemplateRef<any>;

  public formGroupEdit: FormGroup = new FormGroup({});
  public formGroupNotifi: FormGroup = new FormGroup({});

  dialogFormEdit: any = null;

  arrPriotity = [{
    CODE: 1,
    DESCR: "Thấp"
  }, {
    CODE: 2,
    DESCR: "Trung bình"
  }, {
    CODE: 3,
    DESCR: "Cao"
  }];

  arrDateSend = [{
    CODE: 0,
    DESCR: "Ngay bây giờ"
  }, {
    CODE: 1,
    DESCR: "Trước 1 ngày"
  }, {
    CODE: 2,
    DESCR: "Trước 2 ngày"
  }, {
    CODE: 3,
    DESCR: "Trước 3 ngày"
  }, {
    CODE: 4,
    DESCR: "Trước 4 ngày"
  }, {
    CODE: 5,
    DESCR: "Trước 5 ngày"
  }, {
    CODE: 6,
    DESCR: "Trước 6 ngày"
  }, {
    CODE: 7,
    DESCR: "Trước 7 ngày"
  }, {
    CODE: 8,
    DESCR: "Trước 8 ngày"
  }, {
    CODE: 9,
    DESCR: "Trước 9 ngày"
  }, {
    CODE: 10,
    DESCR: "Trước 10 ngày"
  }];

  arrTab: any = [{
    code: 'calendar',
    title: 'Calendar',
    isDisabled: false
  }, {
    code: 'detail',
    title: 'Detail',
    isDisabled: false
  }]

  arrTypeCalendar: any = [];
  createDynamicEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public appService: AppService,
    private reqService: RequestService,
  ) {
    this.formGroupEdit = new FormGroup({
      eventEdit: new FormControl(''),
      dateFromEdit: new FormControl(new Date),
      dateToEdit: new FormControl(new Date),
      chkTimeUnitEdit: new FormControl(true)
    });
    this.formGroupNotifi = new FormGroup({
      eventNotifi: new FormControl(''),
      // dateSendNotifi: new FormControl(new Date()),
      numberDate: new FormControl(0),
      priority: new FormControl(1),
      treeUser: new FormControl()
    });
    this.formGroupEdit.controls['chkTimeUnitEdit'].valueChanges.subscribe((res: any) => {
      this.onCheckChangeModeEdit(res);
    });

    this.formGroupEdit.controls['dateFromEdit'].valueChanges.subscribe((res: any) => {
      if (res) {
        this.minDate = new Date(res);
      }
    });
    this.formGroupEdit.controls['dateToEdit'].valueChanges.subscribe((res: any) => {
      if (res) {
        this.maxDate = new Date(res);
      }
    });

    
    this.urlResource = this.appService.urlWS + '/CalCalendars';
    this.url_Muon_Tra = this.appService.urlWS + '/CalDetails';
  }

  ngOnInit(): void {
  }

  onCoreWindowEmitEvent(event: any) {
    this.createDynamicEvent.emit(event);
  }

  bindValueForm(data: any, allDay: any) {
    console.log(data);
    this.formGroupEdit.controls.eventEdit.setValue(data.content);
    this.checkedDay = allDay;
    this.minDate = new Date(data.fromDate);
    this.maxDate = new Date(data.toDate);
    this.windowIdDetail = data.windowId;
    if (this.windowIdDetail) {
      const params = ['TableID', '=', data.tableId];
      let paramsKey: any = null;
      this.reqService.service.search({
        url: this.appService.urlWS + '/SysColumns',
        where: params,
        logic: 'and'
      }).subscribe(res => {
        if (res.success) {
          res.features.forEach(item => {
            if (item.IsPriKey === 'Y') {
              paramsKey = item.ColumnName;
            }
          });
          if (paramsKey !== null && data.recordId !== null) {
            this.whereCal = [paramsKey, '=', data.recordId];
          } else {
            this.appService.notification('Lỗi truy vấn detail', 'warning')
          }
        }
      });
    }
    this.formGroupEdit.controls.chkTimeUnitEdit.setValue(this.checkedDay);
    this.crtFromDate = data.fromDate;
    this.crtToDate = data.toDate;

    this.formGroupEdit.controls.dateFromEdit.setValue(new Date(data.fromDate));
    this.formGroupEdit.controls.dateToEdit.setValue(new Date(data.toDate));
  }

  onCheckChangeModeEdit(evt: Boolean) {
    this.checkedDay = evt;
    if (this.crtFromDate && this.crtToDate) {
      this.crtFromDate = new Date(this.crtFromDate);
      this.crtToDate = new Date(this.crtToDate);

      this.formGroupEdit.controls.dateFromEdit.setValue(this.crtFromDate);
      this.formGroupEdit.controls.dateToEdit.setValue(this.crtToDate);
    }
  }

  onEditCalendar() {
    if (this.formGroupEdit.valid) {
      let strDateStart = '';
      let strDateEnd = '';
      let dateStartUTC = null;
      let dateEndUTC = null;

      const currentUser = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);

      let dateStart = this.formGroupEdit.controls.dateFromEdit.value;
      let dateEnd = this.formGroupEdit.controls.dateToEdit.value;
      if (typeof (this.formGroupEdit.controls.dateFromEdit.value) === 'number' && typeof (this.formGroupEdit.controls.dateToEdit.value) === 'number') {
        dateStart = new Date(this.formGroupEdit.controls.dateFromEdit.value);
        dateEnd = new Date(this.formGroupEdit.controls.dateToEdit.value);
      }

      if (this.formGroupEdit.controls.chkTimeUnitEdit.value) {
        const yearStart = dateStart.getFullYear();
        const monthStart = dateStart.getMonth();
        const dayStart = dateStart.getDate();
        dateStartUTC = Date.UTC(yearStart, monthStart, dayStart);

        const yearEnd = dateEnd.getFullYear();
        const monthEnd = dateEnd.getMonth();
        const dayEnd = dateEnd.getDate();
        dateEndUTC = Date.UTC(yearEnd, monthEnd, dayEnd);

        strDateStart = yearStart + '-' + this.addZero(monthStart + 1) + '-' + this.addZero(dayStart) + 'T00:00:00';
        strDateEnd = yearEnd + '-' + this.addZero(monthEnd + 1) + '-' + this.addZero(dayEnd) + 'T24:00:00';
      } else {
        const yearStart = dateStart.getFullYear();
        const monthStart = dateStart.getMonth();
        const dayStart = dateStart.getDate();
        const hoursStart = dateStart.getHours();
        const minutesStart = dateStart.getMinutes();
        const secondsStart = dateStart.getSeconds();
        dateStartUTC = Date.UTC(yearStart, monthStart, dayStart, hoursStart, minutesStart, secondsStart);
        // tslint:disable-next-line:max-line-length
        strDateStart = yearStart + '-' + this.addZero(monthStart + 1) + '-' + this.addZero(dayStart) + 'T' + this.addZero(hoursStart) + ':' + this.addZero(minutesStart) + ':00';

        const yearEnd = dateEnd.getFullYear();
        const monthEnd = dateEnd.getMonth();
        const dayEnd = dateEnd.getDate();
        const hoursEnd = dateEnd.getHours();
        const minutesEnd = dateEnd.getMinutes();
        const secondsEnd = dateEnd.getSeconds();
        dateEndUTC = Date.UTC(yearEnd, monthEnd, dayEnd, hoursEnd, minutesEnd, secondsEnd);
        // tslint:disable-next-line:max-line-length
        strDateEnd = yearEnd + '-' + this.addZero(monthEnd + 1) + '-' + this.addZero(dayEnd) + 'T' + this.addZero(hoursEnd) + ':' + this.addZero(minutesEnd) + ':00';
      }
      var calDetailId = '';
      var recordId = null;
      var tableId = null;
      var windowId = null;
      if (this.crtEvent) {
        calDetailId = this.crtEvent.event._def.extendedProps.tag.calDetailId;
        recordId = this.crtEvent.event._def.extendedProps.tag.recordId;
        tableId = this.crtEvent.event._def.extendedProps.tag.tableId;
        windowId = this.crtEvent.event._def.extendedProps.tag.windowId;
      }
      const data = {
        calDetailId: calDetailId,
        calCalendarId: this.ExtType,
        fromDate: new Date(dateStartUTC),
        toDate: new Date(dateEndUTC),
        timeUnit: this.formGroupEdit.controls.chkTimeUnitEdit.value ? 1 : 0,
        content: this.formGroupEdit.controls.eventEdit.value,
        userId: currentUser.userid,
        userName: currentUser.username,
        recordId: recordId,
        tableId: tableId,
        windowId: windowId,
        status: 1,
        ClientId: JSON.parse(this.appService.currentUser!).clientid,
      };
      const params = {
        url: this.url_Muon_Tra,
        primaryKey: 'calDetailId',
        data,
      };

      this.reqService.service.update(params).subscribe(res => {
        console.log(res);

        if (res.success) {
          this.reqService.switchType('sql');
          this.appService.notification('Chỉnh sửa lịch thành công', 'success');
          this.closeDialogEdit();
          this.saveSuccess.emit();
        } else {
          this.appService.notification('Đã xảy ra lỗi. Vui lòng thử lại.', 'error');
          this.closeDialogEdit();
        }
      });
    } else {
      this.appService.notification('Vui lòng điền đầy đủ thông tin.', 'error');
    }
  }

  btnDeleteClick() {
    console.log(this.crtEvent);

    var dataTag = null;
    if (this.crtEvent) {
      dataTag = this.crtEvent.event._def.extendedProps.tag;
    }
    // if (dataTag.windowId && dataTag.recordId) {
    //   this.appService.notification('Không thế xóa dữ liệu có thông tin chi tiết.', 'error');
    // } else {
    this.reqService.service.delete({
      url: this.url_Muon_Tra,
      data: dataTag,
      primaryKey: 'calDetailId',
    }).subscribe(res => {
      console.log('res btn delete', res);

      if (res.success) {
        if (this.crtEvent) {
          this.crtEvent.event.remove();
        }
        this.appService.notification('Xóa lịch thành công', 'success');
        this.dialogFormEdit.closeDialog();
      } else {
        this.appService.notification('Đã xảy ra lỗi. Vui lòng thử lại.', 'error');
        this.dialogFormEdit.closeDialog();
      }
    }, err => {
      this.appService.notification('Đã xảy ra lỗi. Vui lòng thử lại.', 'error');
      this.dialogFormEdit.closeDialog();
    });
    // }
  }

  closeDialogEdit() {
    this.dialogFormEdit.closeDialog();
  }

  openDialogNotifi() {
    this.dialogNotifi.title = 'Gửi thông báo';
    this.dialogNotifi.isComponent = false;
    this.dialogNotifi.templateRef = this.tmpFormNotifi;
    this.dialogNotifi.onShow();

    this.formGroupNotifi.reset();
    this.nodeSelected = [];

    this.formGroupNotifi.controls.eventNotifi.setValue(this.formGroupEdit.controls.eventEdit.value);
    // this.formGroupNotifi.controls.dateSendNotifi.setValue(this.formGroupEdit.controls.dateFromEdit.value);
    this.formGroupNotifi.controls.numberDate.setValue(0);
    this.formGroupNotifi.controls.priority.setValue(1);
  }

  onSendNotifiModeEdit() {
    if (this.formGroupNotifi.valid) {
      let dateFrom = this.formGroupEdit.controls.dateFromEdit.value;
      let dateTo = this.formGroupEdit.controls.dateToEdit.value;
      if (typeof (this.formGroupEdit.controls.dateFromEdit.value) === 'number' && typeof (this.formGroupEdit.controls.dateToEdit.value) === 'number') {
        dateFrom = new Date(this.formGroupEdit.controls.dateFromEdit.value);
        dateTo = new Date(this.formGroupEdit.controls.dateToEdit.value);
      }
      // let dateSend = this.formGroupNotifi.controls.dateSendNotifi.value;
      // if (typeof (this.formGroupNotifi.controls.dateSendNotifi.value) === 'number') {
      //   dateSend = new Date(this.formGroupNotifi.controls.dateSendNotifi.value);
      // }


      const numberDate = typeof (this.formGroupNotifi.controls.numberDate.value) === 'object' ? this.formGroupNotifi.controls.numberDate.value.CODE : this.formGroupNotifi.controls.numberDate.value
      const dateNow = new Date();
      let dateSend = numberDate == 0 ? new Date() : new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds());
      if (numberDate != 0) {
        dateSend.setDate(dateFrom.getDate() - numberDate);
      }

      const year = dateSend.getFullYear();
      const month = dateSend.getMonth();
      const date = dateSend.getDate();
      const hour = dateSend.getHours();
      const minute = dateSend.getMinutes();
      const second = dateSend.getSeconds();
      const dateUTC = Date.UTC(year, month, date, hour, minute, second);
      dateSend = new Date(dateUTC);

      if (this.nodeSelected.length > 0) {
        const listRequest: any[] = [];
        const currentUser = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);

        const yearFrom = dateFrom.getFullYear()
        const monthFrom = dateFrom.getMonth();
        const dayFrom = dateFrom.getDate();
        const hoursFrom = dateFrom.getHours();
        const minutesFrom = dateFrom.getMinutes();
        const secondsFrom = dateFrom.getSeconds();
        var strDateFrom = this.addZero(dayFrom) + '/' + this.addZero(monthFrom + 1) + '/' + yearFrom;

        if (!this.formGroupEdit.controls.chkTimeUnitEdit.value) {
          strDateFrom = this.addZero(hoursFrom) + ':' + this.addZero(minutesFrom) + ':' + this.addZero(secondsFrom) + ' ' + this.addZero(dayFrom) + '/' + this.addZero(monthFrom + 1) + '/' + yearFrom;
        }

        const yearTo = dateTo.getFullYear()
        const monthTo = dateTo.getMonth();
        const dayTo = dateTo.getDate();
        const hoursTo = dateTo.getHours();
        const minutesTo = dateTo.getMinutes();
        const secondsTo = dateTo.getSeconds();
        var strDateTo = this.addZero(dayTo) + '/' + this.addZero(monthTo + 1) + '/' + yearTo;

        if (!this.formGroupEdit.controls.chkTimeUnitEdit.value) {
          strDateTo = this.addZero(hoursTo) + ':' + this.addZero(minutesTo) + ':' + this.addZero(secondsTo) + ' ' + this.addZero(dayTo) + '/' + this.addZero(monthTo + 1) + '/' + yearTo;
        }
        let calDetailId: any = null;
        if (this.crtEvent) {
          calDetailId = this.crtEvent.event._def.extendedProps.tag.calDetailId;
        }
        const arrUserSelect = this.nodeSelected.filter((fil: any) => !fil.children);
        var strDescr = '';
        if (this.nameType != '') {
          strDescr = this.nameType + ': ' + this.formGroupNotifi.controls.eventNotifi.value + ' - Từ: ' + strDateFrom + ' Đến: ' + strDateTo;
        } else {
          strDescr = this.formGroupNotifi.controls.eventNotifi.value + ' - Từ: ' + strDateFrom + ' Đến: ' + strDateTo;
        }
        arrUserSelect.forEach((user: any) => {
          const params: any = {
            FromUserId: currentUser.userid,
            FromUsername: currentUser.username,
            ToUserId: user.userId,
            ToUsername: user.userName,
            // SendDate: this.formatDateNotifi(dateSend),
            SendDate: dateSend,
            Description: strDescr,
            WindowId: this._windowIdCal,
            TableId: null,
            RecordId: calDetailId,
            PriorityId: this.formGroupNotifi.controls.priority.value.CODE ? this.formGroupNotifi.controls.priority.value.CODE : this.formGroupNotifi.controls.priority.value
          };

          const odataParams = {
            url: this.appService.urlWS + '/SysNotifications',
            primaryKey: 'NotificationId',
            data: params
          };
          const resp = this.reqService.service.insert(odataParams);
          listRequest.push(resp);
        });
        combineLatest(listRequest).subscribe(res => {
          this.appService.notification('Gửi thông báo thành công.', 'success');
          this.closeDialogNotifi();
        }, err => {
          this.appService.notification(this.appService.getMessage('0018'), 'error');
        });
      } else {
        this.appService.notification('Vui lòng chọn ít nhất một người dùng để gửi thông báo!', 'error');
      }
    } else {
      this.appService.notification(this.appService.getMessage('0017'), 'error');
    }
  }

  flattenTree(arr: any) {
    let res: any = [];
    arr.forEach((ele: any) => {
      res.push(ele);
      if (ele.children) {
        const temp = this.flattenTree(ele.children);
        res = res.concat(temp);
      }
    });
    return res;
  }

  selectUser() {
    this.dialogUser.title = 'Select User';
    this.dialogUser.isComponent = false;
    this.dialogUser.templateRef = this.tempUser;
    this.dialogUser.onShow();

    var strUserSelected = '';
    strUserSelected = this.formGroupNotifi.controls["treeUser"].value;
    var arrCheckUser: any[] = [];
    if (strUserSelected !== null && strUserSelected !== '') {
      arrCheckUser = strUserSelected.split(', ');
    }
    this.nodeSelected = [];
    const arrUserFlat = this.flattenTree(this.arrUser);
    arrUserFlat.forEach((user: any) => {
      if (user.children) {
        const length = user.children.filter((x: any) => arrCheckUser.includes(x.userName)).length
        if (length > 0 && length === user.children.length) {
          this.nodeSelected.push(user);
        }
      }
      if (arrCheckUser.includes(user.userName)) {
        this.nodeSelected.push(user);
      }
    });
  }

  onAddUser() {
    var strUserSelected = '';
    const arrUserSelect = this.nodeSelected.filter((fil: any) => !fil.children)
    arrUserSelect.forEach((user: any, idx: any) => {
      if (idx === arrUserSelect.length - 1) {
        strUserSelected += user.userName;
      } else {
        strUserSelected += user.userName + ', ';
      }
    });
    strUserSelected.length > 0 ? this.formGroupNotifi.controls['treeUser'].setValue(strUserSelected) : this.formGroupNotifi.controls['treeUser'].setValue(null);

    this.dialogUser.onHideDialog();
  }

  onCancelUser() {
    this.dialogUser.onHideDialog();
  }

  closeDialogNotifi() {
    this.dialogNotifi.closeDialog();
  }

  public formatDateNotifi(date: any) {
    let dateString: any = '';
    if (date !== '' && date !== null) {
      const newDate = new Date(date);
      dateString += newDate.getFullYear();
      dateString += '-';
      dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
      dateString += '-';
      dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
      dateString += 'T';
      dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
      dateString += ':';
      dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
      dateString += ':';
      dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();
    } else {
      dateString = null;
    }
    return dateString;
  }

  addZero(i: any) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }
}
