<div class="div-body">
    <div class="field col-md-12 mb-0">
        <app-core-radio-button [listRadio]="listRadio" [formControl]="radioCtrl"></app-core-radio-button>
    </div>

    <div class="field col-md-12 p-nogutter" [formGroup]="formData">
        <div class="mt-3 d-flex align-items-center">
            <div class="clause-row col-md-11">
                <div class="col-md-4">
                    {{ 'Key' | translate }}
                </div>
                <div class="col-md-4">
                    {{ 'Logic' | translate }}
                </div>
                <div class="col-md-4">
                    {{ 'Value' | translate }}
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <ng-container *ngFor="let item of list | keyvalue">
            <div class="mt-3 d-flex align-items-center">
                <div class="clause-row col-md-11">
                    <div class="col-md-4">
                        <app-select [showLabel]="false" [formControlName]="$any(item.value)[0]" [required]="true"
                            [dataLookup]="lookupCondition['key']" displayField="DESCR" valueField="CODE"></app-select>
                    </div>
                    <div class="col-md-4">
                        <app-select [showLabel]="false" [formControlName]="$any(item.value)[1]"
                            [disabledCtrl]="$any(item.value)[4]" [required]="true"
                            [dataLookup]="lookupCondition['operator']" displayField="DESCR" valueField="CODE">
                        </app-select>
                    </div>
                    <div class="col-md-4">
                        <ng-container [ngSwitch]="$any(item.value)[3]">
                            <ng-container *ngSwitchCase="'select'">
                                <app-select [showLabel]="false" [formControlName]="$any(item.value)[2]"
                                    [dataSource]="$any(item.value)[5]"
                                    [dataLookup]="lookupCondition[$any(item.value)[2]]"></app-select>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <app-core-datetime [showLabel]="false" [formControlName]="$any(item.value)[2]"
                                    [appendTo]="'body'" [calConfig]="calConfig">
                                </app-core-datetime>
                            </ng-container>
                            <ng-container *ngSwitchCase="'datetime'">
                                <app-core-datetime [showLabel]="false" [formControlName]="$any(item.value)[2]"
                                    [appendTo]="'body'" [calConfig]="calConfig">
                                </app-core-datetime>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <app-input [showLabel]="false" [formControlName]="$any(item.value)[2]">
                                </app-input>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-1">
                    <i class="pi pi-trash" (click)="onDeleteRow(item.key)"
                        style="font-size: 2rem; cursor: pointer;"></i>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="fluid grid formgrid btn-footer">
    <div class="field col-md-5 p-0">
        <app-button [labelName]="'Add Clause' | translate" (clickEvent)="onAddClause()">
        </app-button>
    </div>

    <div class="field col-md-7 p-0 d-flex justify-content-end">
        <app-button [labelName]="'Cancel' | translate" [typeButton]="'cancel'" (clickEvent)="onCancel()"></app-button>
        <app-button [labelName]="'Apply' | translate" style="margin-left: 10px" (clickEvent)="onApply()">
        </app-button>
        <ng-container *ngIf="showImportBtn">
            <app-button [labelName]="'Accept' | translate" style="margin-left: 10px" (clickEvent)="onAcceptRecord()">
            </app-button>
        </ng-container>
    </div>
</div>