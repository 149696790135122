import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';
// import BpmnModeler from 'bpmn-js/lib/Modeler';
import * as converter from 'xml-js';
import { AuthService } from 'app/app-base/auth.service';
import { RequestService } from 'app/core/services/request.service';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/app-base/app.service';
import { APP_CONFIG } from 'app/app-base/mockup-data/app-config';
// import BpmnViewer from 'bpmn-js/lib/Viewer';
declare var require: any
let BpmnViewer = require('bpmn-js/lib/Viewer');

@Component({
  selector: 'app-diagram-mobile',
  templateUrl: './diagram-mobile.component.html',
  styleUrls: ['./diagram-mobile.component.scss']
})
export class DiagramMobileComponent implements OnInit {
  @ViewChild('divDiagram', { static: true }) private divDiagram!: ElementRef;
  currentData:any = null;
  diagram: any = null;
//   bpmnJS: BpmnModeler;
    bpmnJS:any
  arrStep: any[] = [];
  currentStep: any;



  private xml = `<?xml version="1.0" encoding="UTF-8"?>
    <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_00f3jo1" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="3.0.0-dev">
      <bpmn:process id="Process_03i8cud" isExecutable="true">
        <bpmn:startEvent id="StartEvent_1" />
      </bpmn:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_03i8cud">
          <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
            <dc:Bounds x="179" y="159" width="36" height="36" />
          </bpmndi:BPMNShape>
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </bpmn:definitions>`;
  constructor(
    private workflowService: BpmnWorkflowService,
    private reqService: RequestService,
    private authService: AuthService,
    private router: ActivatedRoute,
    private appService: AppService,



  ) { }

  ngOnInit(): void {
    const params:any = this.router.snapshot.paramMap;
    const username = params['params']['username'];
    const password = params['params']['password'];
    const jobId = eval(params['params']['jobId']);

    this.loginMobile({ username, password});
    const paramJob = ['JobId', '=', jobId];
    this.workflowService.queryJob(paramJob).subscribe(res => {
      if (res.features.length > 0) {
        this.currentData =  res.features[0];
        this.getWorkflowModel(this.currentData.JobTypeId);
        this.bpmnJS = new BpmnViewer.default({
          container: this.divDiagram.nativeElement,
          height: 1000,
          width: 1500
      });
      }
    });
  }
  getWorkflowModel(JobTypeId:any) {
    // get digram
    const param = ['JobTypeId', '=', JobTypeId];
    this.workflowService.queryJobtype(param).subscribe(res => {
        console.log(res);
        if (res.success && res.features.length > 0) {
            // this.currentJobType = res.features[0];
            const data = res.features[0].JobTypeData;
            const options = { compact: true, ignoreComment: true, spaces: 4 };
            this.diagram = JSON.parse(data);
            this.diagram = converter.js2xml(this.diagram, options);
            this.bpmnJS.importXML(this.diagram, (err:any) => {
                this.getCurentStep();
                const canvas = this.bpmnJS.get('canvas');
                canvas.zoom('fit-viewport');

                if (err) {
                    console.log(err);
                }
            });
            const canvas = this.bpmnJS.get('canvas');
            canvas.zoom('fit-viewport');

            // this.bpmnJS.get('canvas').zoom('fit-viewport');
        }
    }, err => {
        // this.appService.notification('Đã xảy ra lỗi', 'error');
    });


  }
  
  getCurentStep() {
    const param = ['JobId', '=', this.currentData.JobId];
    this.workflowService.queryJobStep(param).subscribe(res => {
        console.log(res);
        if (res.success) {
            if (res.features.length > 0) {
                // this.currentStepOb = res.features[0];
                this.currentStep = res.features[0].CurStep;
                const params = ['JobId', '=', this.currentData.JobId];
                this.workflowService.queryJob(params).subscribe(job => {
                    // if (job.success && job.features.length > 0) {
                    //     this.isShowIma = (this.currentUser === job.features[0].AssignedTo && this.currentStep !== '') ? true : false;
                    //     this.percCompleJob = job.features[0].PercComplete;
                    //     Object.keys(this.formData.controls).forEach(key => {
                    //         this.formData.controls[key].setValue(job.features[0][key]);
                    //     });
                    // }
                });

                // this.queryEndStep();
                const paramTypeStep = ['JobTypeId', '=', this.currentData.JobTypeId];
                this.workflowService.queryJobtypeStep(paramTypeStep).subscribe(res => {
                    if (res.success) {
                        if (res.features.length > 0) {
                            this.arrStep = res.features;
                            this.arrStep.forEach((item:any) => {
                                if (item.StepId === this.currentStep) {
                                    item.isActive = 'running';
                                    return;
                                }
                                else {
                                    item.isActive = 'notComplete';

                                }
                            });
                            this.onChangColor();
                            // const f = this.arrStep.filter(fil => fil.StepId === this.currentStep);
                            // f[0] ? this.divCurentStep = f[0].StepName : '';

                        }
                    }
                });
            }
        }
    }, err => {
        // this.appService.notification('Đã xảy ra lỗi', 'error');
    });
}

onChangColor() {
  this.bpmnJS.importXML(this.diagram, (err:any) => { });
  this.bpmnJS.on('shape.added', (e:any) => {
      const element = e.element;
      const businessObject = element.businessObject;
      const face = this.bpmnJS.get('graphicsFactory');
    //   var modeling = this.bpmnJS.get('modeling');
      const f = this.arrStep.filter(fil => fil.StepId === element.id);
      let elementRegistry:any = null;
      let gfx:any = null;
      let type:any = element.waypoints ? 'connection' : 'shape';
      if (f.length > 0) {
          switch (f[0].isActive) {
              case 'running':
                  businessObject.di.set('stroke', '#000000');
                  businessObject.di.set('fill', '#FFFF00');
                  elementRegistry = this.bpmnJS.get('elementRegistry');
                  gfx = elementRegistry.getGraphics(element);
                  type = element.waypoints ? 'connection' : 'shape';
                  face.update(type, element, gfx);
                  break;
              case 'complete':
                  businessObject.di.set('stroke', '#000000');
                  businessObject.di.set('fill', '#CCFF33');
                  elementRegistry = this.bpmnJS.get('elementRegistry');
                  gfx = elementRegistry.getGraphics(element);
                  type = element.waypoints ? 'connection' : 'shape';
                  face.update(type, element, gfx);
                  break;
              case 'notComplete':
                  businessObject.di.set('stroke', '#000000');
                  businessObject.di.set('fill', '#98FB98');
                  elementRegistry = this.bpmnJS.get('elementRegistry');
                  gfx = elementRegistry.getGraphics(element);
                  type = element.waypoints ? 'connection' : 'shape';
                  face.update(type, element, gfx);
                  break;
          }

      }
  });
}

private loginMobile(params:any) {
  const data = {
      USERNAME: params.username,
      PASSWORD: params.password,
  };
  this.authService.login(data).subscribe((response1) => {
      if (response1.success === true) {
          const _params = {
              appid: params.appid,
              userid: response1.model.token.userid,
          };
          this.reqService.service.query({
              url: this.appService.urlWS + '/Config/Init',
              params: _params
          }).subscribe((response) => {
              this.appService.appConfig = {
                  Systems: APP_CONFIG.Systems,
                  Menus: response.dsMenu,
                  Services: response.services,
                  Layers: response.layers,
                  Applications:
                      this.appService.application == null
                          ? null
                          : this.appService.application,

                  FunctionPermistion: response.functionPermission,
                  PermissionEditorTool: response.permissionEditorTool,
                  DSKVHC: response.dskvhc,
                  wherekvhc: response.wherekvhc,
                  sys_combo: response.sys_combo,
                  sys_config: response.sys_config,
                  objTheme: response.objTheme,
                  listCalendar: response.listCalendar
              };
              if (!this.appService.c$) {
                  this.appService.c$ = {};
                  this.appService.setNowDate();
                  this.appService.c$.dateFormat = 'dd/mm/yy';
              }
              this.appService.c$.sys_combo = response.sys_combo;
              this.appService.c$.sys_config = response.sys_config;
              this.appService.c$.kvhc = response.sys_combo['-1'] ? JSON.parse(response.sys_combo['-1']) : [];
              // Lấy locaStorage ở đây

              // this.initMobileHandle.emit();
          });
      } else {
          let msg = '';
          if (!response1.success) {
              msg = 'Sai tài khoản, mật khẩu';
          }
          this.appService.alert(msg, 'error');
      }
  }, err => {
  });
}


}
