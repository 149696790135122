<!-- <button 
    [ngClass]="{'color-btn': typeButton === 'color', 'reset-btn':typeButton === 'reset' }" 
    nz-button nzBlock
    [nzType]="typeColor"
    [disabled]="disabledCtrl"
    [title]="title"
    (click)="onClick($event)">
    {{labelName}}
    <ng-container *ngIf="icon">
        <i nz-icon [nzType]="icon" nzTheme="outline"></i>
    </ng-container>
</button> -->

<!-- <button pButton type="button" [disabled]="disabledCtrl" [title]="title"
    (click)="onClick($event)"> {{labelName}}</button> -->
<ng-container *ngIf="!isOnlyIcon">
    <p-button [label]="labelName" (onClick)="onClick($event)" #btn
        [styleClass]="typeButton === 'save' ? 'btn-save-class' : typeButton === 'reset' ? 'btn-reset-class' : 'btn-cancel-class'"
        [icon]="icon" [iconPos]="iconPos" [disabled]="disabledCtrl"></p-button>
</ng-container>
<ng-container *ngIf="isOnlyIcon">
    <p-button (onClick)="onClick($event)" #btn [icon]="icon" [iconPos]="iconPos" [disabled]="disabledCtrl" class="p-button-rounded"
        [styleClass]="typeButton === 'save' ? 'btn-save-icon-class' : typeButton === 'reset' ? 'btn-reset-icon-class' : 'btn-cancel-icon-class'">
    </p-button>
</ng-container>