<ng-container *ngIf="hasFormCtrl">
    <div *ngIf = "isCoreForm" style="padding-top: 30px;"></div>
    <span style="display: flex; align-items: center; height: 30px;" [ngClass] = "{'label-readonly': isReadOnly}">
        <p-triStateCheckbox [label]="notUseTranslate ? chkLabel : (chkLabel | translate)" [readonly]="isDisabled"
            [disabled]="!isDisabled && isReadOnly" [formControl]="ctrl" [checkboxTrueIcon]="checkboxTrueIcon"
            [checkboxFalseIcon]="checkboxFalseIcon" (onChange)="onChkChange($event)"></p-triStateCheckbox>
        <ng-container *ngIf="hasButton">
            <button pButton pRipple type="button" icon="pi pi-plus" style="height: 30px;width: 30px; margin-left: 3px;"
                (click)="btnCalcClick.emit()"></button>
        </ng-container>
    </span>

</ng-container>
<ng-container *ngIf="!hasFormCtrl">
    <div *ngIf = "isCoreForm" style="padding-top: 30px;"></div>
    <span style="display: flex; align-items: center; height: 30px;" [ngClass] = "{'label-readonly': isReadOnly}">
        <p-triStateCheckbox [label]=" notUseTranslate ? chkLabel : (chkLabel | translate)" [readonly]="isDisabled"
            [disabled]="!isDisabled && isReadOnly" [(ngModel)]="valueModel" [checkboxTrueIcon]="checkboxTrueIcon"
            [checkboxFalseIcon]="checkboxFalseIcon" (onChange)="onChkChange($event)"></p-triStateCheckbox>
        <ng-container *ngIf="hasButton">
            <button pButton pRipple type="button" icon="pi pi-plus" style="height: 30px;width: 30px; margin-left: 3px;"
                (click)="btnCalcClick.emit()"></button>
        </ng-container>
    </span>

</ng-container>