<div class="d-flex" [ngClass]="{'NoLabelLeft': !isLabelLeft}" [style.width.px]="controlWidth">
    <div *ngIf="showLabel" [style.width.px]="labelWidth" class="labelClass" [ngClass] = "{'label-readonly': isReadOnly}">
        {{labelName || "No title" | translate}}
        <span *ngIf="_required" [style.color]="'red'">&nbsp;*</span>
    </div>
    <div style="display: flex; width: 100%;" [ngClass]="{'input-readonly': isReadOnly}"
        [ngStyle]="{'max-width': isLabelLeft && showLabel ? 'calc(100% - 120px)' : '100%' }">
        <ng-container *ngIf="hasFormCtrl">
            <span style="display: flex; align-items: center; height: 100%; width: 100%;">
                <p-treeSelect [formControl]="ctrl" [options]="dataLookupBinding" [placeholder]="placeholder"
                    [ngClass]="{'core-required': requiredClass}" (onNodeSelect)="onChangeValue($event)"
                    [appendTo]="'body'" [disabled]="disabledCtrl || isReadOnly"></p-treeSelect>
                <ng-container *ngIf="hasButton">
                    <button pButton pRipple type="button" icon="pi pi-plus" style="height: 30px;width: 30px;"
                        (click)="btnCalcClick.emit()"></button>
                </ng-container>
            </span>

        </ng-container>
        <ng-container *ngIf="!hasFormCtrl">
            <span style="display: flex; align-items: center; height: 100%; width: 100%;">
                <p-treeSelect [(ngModel)]="valueModel" [options]="dataLookupBinding" [placeholder]="placeholder"
                    [ngClass]="{'core-required': requiredClass}" (onNodeSelect)="onChangeValue($event)"
                    [appendTo]="'body'" [disabled]="disabledCtrl || isReadOnly"></p-treeSelect>
                <ng-container *ngIf="hasButton">
                    <button pButton pRipple type="button" icon="pi pi-plus" style="height: 30px;width: 30px;"
                        (click)="btnCalcClick.emit()"></button>
                </ng-container>
            </span>

        </ng-container>
    </div>
</div>