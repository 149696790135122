import { SendParamsForConfigComponent } from "app/reports/report-viewer/load-viewer-report/send-params-for-config.component";
import { DemoLandinfoComponent } from "app/reports/report-viewer/custom-viewer-report/demo-landinfo.component";
import { QuotationReportComponent } from "app/reports/report-viewer/custom-viewer-report/quotation-report.component";
import { FilterCustomReportComponent } from "app/reports/report-viewer/custom-viewer-report/filter-report/filter-custom-report.component";

export interface IConfigCustomizeReport {
  FUNCTION_ID: string;
  COMPONENT: any;
  NAME: string;
}

export const configReportCustomize: IConfigCustomizeReport[] = [
  {
    FUNCTION_ID: "1",
    COMPONENT: DemoLandinfoComponent,
    NAME: "DemoLandinfoComponent"
  },
  {
    FUNCTION_ID: "2",
    COMPONENT: SendParamsForConfigComponent,
    NAME: "SendParamsForConfigComponent"
  },
  {
    FUNCTION_ID: "2",
    COMPONENT: QuotationReportComponent,
    NAME: "QuotationReportComponent"
  },
  {
    FUNCTION_ID: "3",
    COMPONENT: FilterCustomReportComponent,
    NAME: "FilterCustomReportComponent"
  }
]

export const componentCustomizeParams = [
  {
    CODE: 'DemoLandinfoComponent',
    DESCR: 'DemoLandinfoComponent'
  },
  {
    CODE: 'QuotationReportComponent',
    DESCR: 'QuotationReportComponent'
  },
  {
    CODE: 'FilterCustomReportComponent',
    DESCR: 'FilterCustomReportComponent'
  }
]
