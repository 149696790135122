import { Router } from '@angular/router';
import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { AppConfig, AppConfigApplication } from './interface/app-config';
import 'reflect-metadata';
import { Message, MessageList } from './interface/message-config';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpContextToken, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { loadModules } from 'esri-loader';
import { parse, stringify } from 'zipson';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RequestService, OdataParams } from 'app/core/services/request.service';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';

@Injectable()
export class AppService {

  domainLock: any = null

  public themeClient: any = null;
  public scaleMap = '';
  public urlInit = ''; // environment variable
  public urlInitQutation = ''; // environment variable
  public urlReport = ''; // environment variable
  public urlProxy = ''; // environment variable
  public dataAccessType: any = ''; // environment variable
  public urlGetTheme = ''; // environment variable
  public c$: any = null;
  public urlOdataAttachment = ''; // environment variable
  public urlAutoData = ''; // environment variable
  public urlAutoDataSys = ''; // environment variable
  public urlWorkflow = ''; // environment variable
  public urlWS = ''; // environment variable
  public tempClientId: number = 0; // environment variable
  public envGooglePay = ''; // environment variable
  public urlWebdatarocks = ''; // environment variable
  public applicationServerPublicKey = ''; // environment variable
  public urlRegister = '';  // URL register
  public urlRecapcha = ''; // URL Recapcha

  public objConfigSystem = null;
  public application: any = [];
  public appConfig!: AppConfig | null | any;
  public appConfig1!: AppConfigApplication;
  public ClientId!: number;
  public ApplicationId!: number;
  public token = '';
  public tokenrefresh = '';
  // public currentClientSubject: BehaviorSubject<any>;
  // public currentClient: Observable<any>;
  public currentUser!: any;

  public notifyResponse: any = null;
  public objStoreAppId: any = null;



    public listLang: any[] = [{
        CODE: 'vi',
        DESCR: 'Tiếng Việt',
        img: 'vietnam.png'
    }, {
        CODE: 'en',
        DESCR: 'English',
        img: 'english.png'
    }]
    objCoord: any = {}
    // For ArcGIS Service
    public isLoadArcGIS3x = false; // Kiểm tra đã load thư viện Arcgis js api hay chưa, có thể fix được lỗi multipleDefine
    public appConfigAppId0: any = null; // Lưu cấu hình appId = 0 để chạy job và calendar ở Header

  @Output() iconClickEvt: EventEmitter<any> = new EventEmitter();
  test: EventEmitter<any> = new EventEmitter();
    //Sự kiện lấy location cho field location
    getLocation: EventEmitter<any> = new EventEmitter();
    returnLocation: EventEmitter<any> = new EventEmitter();
  // Danh sách các sự kiện trong hệ thống đăng ký ở đây, các sự kiện dùng để liên kết từ module này sang module kia
  // Ví dụ: Sự kiện nút zoom ở table sẽ thực thi mà zoom ở module bản đồ
  // Sự kiện zoom với đồ họa trên bản đồ
  zoomEvent: EventEmitter<any> = new EventEmitter();
  // Sự kiện Kích hoạt đồ vẽ đồ họa để tìm kiếm
  searchByGraphic: EventEmitter<any> = new EventEmitter();
  // Sự kiện dừng kích hoạt vẽ đồ họa
  deActiveDraw: EventEmitter<any> = new EventEmitter();
  // Sự kiện cập nhật logo ở header
  updateLogo: EventEmitter<any> = new EventEmitter();
  // Sự kiện vẽ đồ họa trên bản đồ khi query dữ liệu của 1 bảng mà có geometry
  drawGeometryFromTable: EventEmitter<any> = new EventEmitter();
  // Sự kiện thêm mới thuộc tính thành công và update dữ liệu ở bên đồ họa
  insertAttributesToMap: EventEmitter<any> = new EventEmitter();
  // Sự kiện core-header notification mở 1 thông báo - tạo ra component
  notifyOpenWindow: EventEmitter<any> = new EventEmitter();
  // Sự kiện tạo token của ARCGIS Service
  generateArcGISTokenEvent: EventEmitter<boolean> = new EventEmitter();
  // event của notification
  onNotificationPushNew: EventEmitter<boolean> = new EventEmitter();
    REQUEST_TYPE = new HttpContextToken<string>(() => '');
  timestampOffset: number = 0;
  public selecteMenuItemUM!: string;
  constructor(
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
        private injector: Injector


  ) {
    if (sessionStorage) {
      if (sessionStorage.length > 0) {
        try {
          let a: any = this.currentUser = sessionStorage.getItem(this.hostName1 + 'currentUser');
          a = a ? JSON.parse(a) : a;
          this.ClientId = a ? a.clientid : null;
          if (a) {
            this.ClientId = a.clientid;
          } else {
            sessionStorage.clear();
          }
        } catch (error) {
          sessionStorage.clear();
        }
      }

      const currentApp = sessionStorage.getItem(this.hostName1 + '_CurrentApp');
      const obj = currentApp ? JSON.parse(currentApp) : null;
      if (obj) {
        const appId = Number(obj.appId);
        this.ApplicationId = appId;
        this.objStoreAppId = obj;
      }
    }

    const a = new Date();
    this.timestampOffset = a.getTimezoneOffset() * 60 * 1000;
    // this.currentClientSubject = new BehaviorSubject<any>(null);
    // this.currentClient = this.currentClientSubject.asObservable();
  }
  // public get clientid(): any {
  //     return this.currentClientSubject.value;
  // }

  /**
   * Get application host name.
   * `const host = this.app.hostName;`
   */
  get hostName(): string {
    return document.getElementsByTagName('base')[0].href;
  }

  get hostName1(): string {
    return this.hostName.toString().split('/')[2];
  }
  // get hostNameViwaco(): string {
  //     return document.getElementsByTagName('base')[0].href.toString().replace('/viwaco2/', '');
  // }

  deepCloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  /** Loại bỏ các phần tử trùng lặp */
  removeDuplicateValue(array: Array<any>) {
    array = array.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    return array;
  }

  setNowDate() {
    // trả về dạng date của javaScript
    this.c$.now = function () {
      return new Date();
    }
  }

  maxWidthImage: any = 800
  async compressImage(file: any) {
    return new Promise((resolve, reject) => {
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = (event: any) => {

      // };
      // reader.onerror = error => reject(error);
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        const canvas = document.createElement('canvas');
        canvas.width = width > this.maxWidthImage ? this.maxWidthImage : width;
        canvas.height = Math.round(height * canvas.width / width);;
        const ctx: any = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob: any) => {
          const file_return = new File([blob], file.name, { type: file.type })
          resolve(file_return)
        })
        // const base64String = canvas.toDataURL('image/png');
        // resolve(base64String);
      };
    });
  }

  /** Lấy các lookup để hiển thị combo box */
  getLookup() {
    const result: any = {};
    let data: any = this.appConfig ? this.appConfig.sys_combo : null;
    if (this.appConfigAppId0) {
      if (!data) {
        data = this.appConfigAppId0.sys_combo;
      } else {
        Object.keys(this.appConfigAppId0.sys_combo).forEach(key => {
          data[key] = this.appConfigAppId0.sys_combo[key];
        });
      }
    }
    if (data !== null && data !== undefined) {
      Object.keys(data).forEach((key: any) => {
        try {
          const lookup: { CODE: any; DESCR: any; }[] = [];
          let list = JSON.parse(data[key]);
          if (typeof (list) === 'string') {
            list = JSON.parse(list);
          }
          if (Number(key) === -1) {
            list.forEach((item: any[]) => {
              const obj = {
                CODE: item[0],
                DESCR: item[1],
                PARENT_CODE: item[2]
              };

              lookup.push(obj);
            })

          } else {
            list.forEach((item: any[]) => {
              const obj = {
                CODE: item[0],
                DESCR: item[1],
                COLOR_CODE: undefined
              };
              if (item[2]) {
                obj['COLOR_CODE'] = item[2];
              }
              lookup.push(obj);
            });
          }

          result[key] = lookup;
        } catch (error) {
          // console.log('error', error);
        }
      });
    }

    return result;
  }

  /**
   * Get cookie value with name reference
   * `const csrf: string = this.app.getCookie('CSRF_TOKEN');`
   * @param name ชื่อของ cookie
   */
  getCookie(name: string): any {
    const value = '; ' + document.cookie;
    const parts: any = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

    /** Hàm lấy token cho sql-odata */
    public getToken(): string {
        let str = '';
        //  this.currentUser = sessionStorage.getItem(this.hostName1 + 'currentUser');
        if (this.currentUser) {
            str = JSON.parse(this.currentUser).token;
            this.token = str;
            this.tokenrefresh = JSON.parse(this.currentUser).tokenRefesh;
        }
        return str;
    }

    public async registerTokenOracle(val: { user: string, pass: string, url: string }) {
        let tokenList: any = localStorage.getItem('oracleTokenList');
        let hasDomain = false; // biến kiểm tra đã có domain này trong danh sách token hay chưa
        if (tokenList) {
            tokenList = parse(tokenList);
            tokenList.forEach(async (item: any) => {
                if (item.url === val.url) { // Nếu trong danh sách token lưu trữ trong Session đã có domain này
                    hasDomain = true;
                }
            });
        } else {
            tokenList = [];
        }

        if (!hasDomain) { // nếu chưa có domain => gettoken
            await this.generateTokenOracle(val, tokenList);
        }
    }

    private async generateTokenOracle(val: any, tokenList: any[]) {
        let url = val.url + '/oauth/token';
        // Khác domain thì dùng proxy????
        if (this.getDomainName(url) !== this.getDomainName(location.hostname)) {
            url = `${this.urlProxy}?${url}`;
        }
        const auth = "Basic " + btoa(`${val.user}:${val.pass}`);
        const headers = new HttpHeaders({
            "Content-Type": 'application/x-www-form-urlencoded',
            "Authorization": auth
        });

        const paramsToSend: any = {
            grant_type: 'client_credentials'
        };

        const formData = new HttpParams({ fromObject: paramsToSend });
        const response: any = await this.http.post(url, formData, { headers }).pipe(take(1)).toPromise();
        response['url'] = val.url;
        response['user'] = val.user;
        response['pass'] = val.pass;

        const index = tokenList.findIndex((fil: any) => fil.url = val.url);
        if (index !== -1) {
            tokenList[index] = response;
        } else {
            tokenList.push(response);
        }
        localStorage.setItem('oracleTokenList', stringify(tokenList));
        return response;
    }

    public getTokenOracle(url: string) {
        let tokenList: any = localStorage.getItem('oracleTokenList');
        let token = null;
        if (tokenList) {
            tokenList = parse(tokenList);
            tokenList.forEach((item: any) => {
                if (url.includes(item.url)) { // Nếu trong danh sách token lưu trữ trong Session đã có domain này
                    token = item.access_token;
                }
            });
        }

        return token;
    }

    public refreshTokenOracle(url: string) {
        let tokenList: any = localStorage.getItem('oracleTokenList');
        let token: any = null;
        if (tokenList) {
            tokenList = parse(tokenList);
            tokenList.forEach((item: any) => {
                if (url.includes(item.url)) { // Nếu trong danh sách token lưu trữ trong Session đã có domain này
                    token = item;
                }
            });
        }
        const auth = "Basic " + btoa(`${token.user}:${token.pass}`);
        const headers = new HttpHeaders({
            "Content-Type": 'application/x-www-form-urlencoded',
            "Authorization": auth
        });

        const paramsToSend: any = {
            grant_type: 'client_credentials'
        };
        let tokenUrl = token.url + '/oauth/token';
        // Khác domain thì dùng proxy????
        if (this.getDomainName(url) !== this.getDomainName(location.hostname)) {
            tokenUrl = `${this.urlProxy}?${tokenUrl}`;
        }
        const formData = new HttpParams({ fromObject: paramsToSend });
        return this.http.post(tokenUrl, formData, { headers }).pipe(map((response: any) => {
            console.log(response);
            response['url'] = token.url;
            response['user'] = token.user;
            response['pass'] = token.pass;

            const index = tokenList.findIndex((fil: any) => fil.url = token.url);
            console.log(index)
            if (index !== -1) {
                tokenList[index] = response;
            } else {
                tokenList.push(response);
            }
            localStorage.setItem('oracleTokenList', stringify(tokenList));
            return response;
        }))

    }

  /** Đệ quy lấy tất cả menu theo type: type có giá trị bao gồm 'SHORTCUT' và 'TOOL' và 'MENU' */
  public getMenuType(menu: Array<any>, type: any) {
    let array: any[] = [];
    if (menu) {
      menu.forEach(item => {
        if (item.sub_function && item.sub_function.length > 0) {
          const sub_function = this.getMenuType(item.sub_function, type);
          item.SUB_FUNCTION = sub_function;
        } else {
          item.SUB_FUNCTION = [];
        }

        if (item.menuitemtype === type) {
          array.push(item);
        }
      });
    }
        array = array.sort((a: any, b: any) => a.order_no - b.order_no)
    return array;
  }

  /** Quản lý dịch vụ arcgis services bằng identity manager */
  public async registerArcGISService(appConfig: AppConfig = this.appConfig) {
    if (appConfig && appConfig.Services) {
      let count = 0;
      for (let i = 0; i < appConfig.Services.length; i++) {
        const item = appConfig.Services[i];
        let valid = true;
        if (!(item.userNamePortal && item.userNamePortal !== '')) {
          valid = false;
        }
        if (!(item.passWordPortal && item.passWordPortal !== '')) {
          valid = false;
        }
        if (!(item.urlToken && item.urlToken !== '')) {
          valid = false;
        }

        if (valid) { // Nếu cả 3 giá trị trên đều có dữ liệu => dịch vụ riêng tư => đăng ký quản lý bởi Identify Manager
          await this.registerArcGISDomain({
            username: item.userNamePortal,
            password: item.passWordPortal,
            portalUrl: item.urlToken
          });
        } else {
          count++;
        }
      }

      if (count === appConfig.Services.length) {
        this.generateArcGISTokenEvent.emit();
      }
    } else {
      this.generateArcGISTokenEvent.emit();
    }

        // Kẹp thêm đoạn lấy token của oracle
        if (appConfig && appConfig.Services) {
            for (let i = 0; i < appConfig.Services.length; i++) {
                const item = appConfig.Services[i];
                let valid = true;
                if (!(item.userSchema && item.userSchema !== '')) {
                    valid = false;
                }
                if (!(item.passwordSchema && item.passwordSchema !== '')) {
                    valid = false;
                }
                if (!(item.urlEdit && item.urlEdit !== '')) {
                    valid = false;
                }

                if (valid) { // Nếu cả 3 giá trị trên đều có dữ liệu => dịch vụ riêng tư => đăng ký quản lý bởi Identify Manager
                    await this.registerTokenOracle({
                        user: item.userSchema,
                        pass: item.passwordSchema,
                        url: item.urlEdit
                    });
                }
            }
        }
  }

  /** Hàm đăng ký sử dụng các dịch vụ riêng tư của ArcGIS, quản lý bởi Identity Manager */
  private async registerArcGISDomain(obj: { username: string, password: string, portalUrl: string }) {
    const username = obj.username;
    const password = obj.password;
    const portalUrl = obj.portalUrl;

    const server = portalUrl + '/sharing/rest';
    const tokenServiceUrl = server + '/generateToken';

    const serverInfo = {
      tokenServiceUrl
    };

    const userInfo = {
      username,
      password
    };

    let tokenList: any = localStorage.getItem('arcgisTokenList');
    let hasPortal = false; // biến kiểm tra đã có portal này trong danh sách token hay chưa
    if (tokenList) {
      tokenList = parse(tokenList);
      tokenList.forEach(async (item: any) => {
        if (item.server === server) { // Nếu trong danh sách token lưu trữ trong Session đã có server này
          if (item.expires - Date.now() <= 0) { // token hết hạn
            await this.generateToken(serverInfo, userInfo, server, tokenList);
          }
          hasPortal = true;
        }
      });
    } else {
      tokenList = [];
    }

    if (!hasPortal) { // nếu chưa có portal này trong danh sách => tạo token và quản lý theo "esri/identity/IdentityManager"
      await this.generateToken(serverInfo, userInfo, server, tokenList);
    } else {
      const obj = tokenList.filter((fil: any) => fil.server === server);
      if (obj && obj.length > 0) {
        const [esriId] = await loadModules(["esri/IdentityManager"]);
        const check = esriId.credentials.filter((fil: any) => server.includes(fil.server));
        if (!(check && check.length > 0)) {
          esriId.registerToken(obj[0]);
        }
      }
      this.generateArcGISTokenEvent.emit();
    }
  }

  private async generateToken(serverInfo: any, userInfo: any, server: string, tokenList: any[]) {
    const [esriId, esriConfig] = await loadModules(["esri/IdentityManager", 'esri/config']);
    esriConfig.defaults.io.proxyUrl = this.urlProxy;
    const tokenInfo = await esriId.generateToken(serverInfo, userInfo);
    const obj = { ...tokenInfo, server };
    esriId.registerToken(obj);
    const check = tokenList.filter(fil => fil.server === server);
    if (check && check.length > 0) {
      tokenList = tokenList.filter(fil => fil.server !== server);
    }
    tokenList.push(obj);
    localStorage.setItem('arcgisTokenList', stringify(tokenList));
    this.generateArcGISTokenEvent.emit();
  }

  /** lấy token cho service ArcGIS. Tham số truyền vào là url cần request, kết quả trả ra là 1 promise trả về string token hoặc null */
  public async getArcGISToken(url: string, config = null) {
    // return null; // Test token required
    const appConfig = config ?? this.appConfig;
    let tokenListStr = null;
    if (localStorage) {
      tokenListStr = localStorage.getItem('arcgisTokenList');
    }
    const tokenList = tokenListStr ? parse(tokenListStr) : [];
    const referer = this.getReferer(url);

    const a = tokenList.filter((fil: any) => fil.referer === referer);
    if (a.length > 0) { // có token
      const currentTimeStamp = new Date().getTime(); // lấy thời gian hiện tại (timestamp)
      if (currentTimeStamp < a[0].expires) { // token vẫn còn hạn
        return a[0].token;
      } else { // hết hạn token => lấy token mới
        return await this.requestArcGISToken(appConfig, tokenList, referer);
      }
    } else { // Chưa có server này trong danh sách token
      const res = await this.requestArcGISToken(appConfig, tokenList, referer, 'arcgis.com');
      if (res) { // Kiểm tra xem có phải token của arcgis online
        return res;
      } else {
        return await this.requestArcGISToken(appConfig, tokenList, referer);
      }
    }
  }

  /** Hàm chung cho việc request lấy token của ArcGIS */
  private async requestArcGISToken(appConfig: AppConfig | null, tokenList: any[], referer: string, url?: string | undefined) {
    if (!appConfig || !appConfig.Services || appConfig.Services.length === 0) {
      return null;
    }
    const urlForCheck = url ?? referer;
    // Lưu lại trong mảng token rồi gán lại vào localStorage
    const list = appConfig.Services.filter((fil: any) => fil.urlToken && fil.urlToken.indexOf(urlForCheck) !== -1);
    if (list.length > 0) { // có service tìm được
      const service = list[0];
      const token = await this.queryArcgisToken(service);
      if (token === null || token === undefined) {
        return null;
      }
      token['referer'] = referer;
      tokenList = tokenList.filter((fil: { referer: any; }) => fil.referer !== referer);
      tokenList.push(token);
      if (localStorage) {
        localStorage.setItem('arcgisTokenList', stringify(tokenList));
      }
      const response = {
        ...token,
        userid: service.userNamePortal,
        server: referer,
        tokenUrl: service.urlToken
      };
      await this.registerCredentialArcGIS(response);
      return token['token'];
    } else {
      return null;
    }
  }

  /** Request token dành riêng cho ArcGIS JS API (phiên bản 3.x) */
  private esriRequest(
    url: string,
    params: any = {}
  ): Observable<any> {
    const usePost = true;
    const subject: Subject<any> = new Subject();

    loadModules(['esri/request', 'esri/config']).then(
      ([request, esriConfig]) => {
        esriConfig.defaults.io.proxyUrl = this.urlProxy;
        const reqData = {
          url,
          content: params,
          handleAs: 'json',
          sync: false,
          callbackParamName: 'callback',
        };
        request(reqData, { usePost, useProxy: true }).then(
          (response: any) => subject.next(response),
          (error: any) => subject.next(error)
        );
      }
    );

    return subject.asObservable();
  }

  private async queryArcgisToken(service: any) {
    var urlToken = '';
    if (!service.urlToken.includes('generateToken')) {
      const urlToken = service.urlToken + '/sharing/rest/generateToken';
    }
    else {
      urlToken = service.urlToken;
    }
    const body = {
      username: service.userNamePortal,
      password: service.passWordPortal,
      client: 'referer',
      referer: this.getReferer(urlToken),
      expiration: 60 * 12, // hạn của token là 1 tiếng
      f: 'json'
    };

    const res = await this.esriRequest(urlToken, body).pipe(take(1)).toPromise();
    if (res instanceof Error) {
      return null;
    }

    return res;
  }

  private async registerCredentialArcGIS(response: { server: any; tokenUrl: any; userId: any; token: any; expires: any; ssl: any; }) {
    const [
      idManager,
      ServerInfo,
      Credential
    ] = await loadModules([
      'esri/IdentityManager',
      'esri/ServerInfo',
      'esri/Credential'
    ]);

    const serverInfo = new ServerInfo();
    serverInfo.server = response.server;
    serverInfo.tokenServiceUrl = response.tokenUrl;

    const idObject: any = {};
    idObject.serverInfos = [serverInfo];
    const credential = {
      server: serverInfo.server,
      userId: response.userId,
      token: response.token,
      expires: response.expires,
      ssl: response.ssl
    };

    idObject.credentials = [credential];
    idManager.initialize(idObject);
  }

  public getReferer(url: string) {
    const newUrl = url;
    if (newUrl) {
      const firstIndex = newUrl.indexOf('://') + 3;
      const lastIndex = newUrl.indexOf('/', firstIndex);

      if (lastIndex > 0) {
        return newUrl.substring(firstIndex, lastIndex);
      } else {
        // Nếu sau https:// không còn / thì giữ nguyên url
        return newUrl;
      }
    } else {
      return '';
    }
  }

  /**
   * Get domain name from url
   * `const domain: string = this.app.getDomainName('https://example.com/path/to/service');`
   * @param url Url
   */
  getDomainName(url: string): string {

    const domainNameStartIndex = url.indexOf('//');
    let domainName = '';

    if (domainNameStartIndex >= 0) {
      domainName = url.substring(domainNameStartIndex + 2);
    } else {
      domainName = url;
    }
    const domainNameEndIndex = domainName.indexOf('/');
    if (domainNameEndIndex >= 0) {
      domainName = domainName.substring(0, domainNameEndIndex);

      // cắt phế thêm cả port nếu có
      const portIndex = domainName.indexOf(':');
      if (portIndex >= 0) {
        domainName = domainName.substring(0, portIndex);
      }
    }
    return domainName;
  }

  convertStringToJson(jsonStr: string): any {
    return JSON.parse(jsonStr);
  }

  /** Hàm tạo động FormGroup, với tham số đầu vào là 1 mảng các mảng
   *
   * mỗi mảng sẽ gồm 4 thông số theo thứ tự:
   *
   * tên formControl
   *
   * giá trị mặc định
   *
   * require hay không (true hoặc false)
   *
   * có pattern không (có thì điền string pattern, không có thì điền null)
   */
  createFormGroup(data: Array<any>): FormGroup {
    const group: any = {};
    data.forEach(item => {
      group[item[0]] = new FormControl(item[1]);

      const require = item[2] ? Validators.required : null;
      const pattern = item[3] ? Validators.pattern(item[3]) : null;

      if (require && pattern) {
        group[item[0]].setValidators(Validators.compose([require, pattern]));
      } else if (require) {
        group[item[0]].setValidators([require]);
      } else if (pattern) {
        group[item[0]].setValidators([pattern]);
      }
    });

    const formGroup = new FormGroup(group);
    return formGroup;
  }

  getMessage(messageCode: string): string {
    this.setLang();
    let msgStr = 'Invalid Message Code !!';
    const messages = MessageList.filter((mes: Message) => mes.MESSAGE_CODE === messageCode);
    if (messages.length > 0) {
      msgStr = messages[0].MESSAGE_DESCR;

    }
    // msgStr = this.translate.instant(msgStr);
    return msgStr;
  }
  public number2Text(sNumber: string) {
    if (sNumber.includes('.')) {
      const index = sNumber.lastIndexOf('.');
      sNumber = sNumber.substr(0, index);
    }
    let mDigit: number;
    let mLen: number;
    let i: number;
    let mTemp!: string;
    const mNumText: string[] = [
      'không',
      'một',
      'hai',
      'ba',
      'bốn',
      'năm',
      'sáu',
      'bảy',
      'tám',
      'chín'];
    mLen = sNumber.length;
    for (i = 1; (i <= mLen); i++) {
      mDigit = Number.parseFloat(sNumber.substr((i - 1), 1));
      if ((mTemp == null)) {
        //  mTemp = mTemp.toString();
        mTemp = mNumText[mDigit];
      }
      else {
        mTemp = (mTemp + (' ' + mNumText[mDigit]));
      }

      if ((mLen === i)) {
        break;
      }

      switch (((mLen - i)
        % 9)) {
        case 0:
          mTemp = (mTemp + ' tỷ');
          if ((sNumber.substr(i, 3) === '000')) {
            i += 3;
          }

          if ((sNumber.substr(i, 3) === '000')) {
            i += 3;
          }

          if ((sNumber.substr(i, 3) === '000')) {
            i += 3;
          }

          break;
        case 6:
          mTemp = (mTemp + ' triệu');
          if ((sNumber.substr(i, 3) === '000')) {
            i += 3;
          }

          if ((sNumber.substr(i, 3) === '000')) {
            i += 3;
          }

          break;
        case 3:
          mTemp = (mTemp + ' nghìn');
          if ((sNumber.substr(i, 3) === '000')) {
            i += 3;
          }

          break;
        default:
          {
            switch (((mLen - i) % 3)) {
              case 2:
                mTemp = (mTemp + ' trăm');
                break;
              case 1:
                mTemp = (mTemp + ' mươi');
                break;
            }
          }
      }

    }

    if ((mTemp != null)) {
      // Lo�i b� tr��ng h�p x00
      mTemp = mTemp.toString().replace('không mươi không', '');
      // Lo�i b� tr��ng h�p 00x
      mTemp = mTemp.toString().replace('không mươi ', 'linh ');
      // Lo�i b� tr��ng h�p x0, x>=2
      mTemp = mTemp.toString().replace('mươi không', 'mươi');
      // Fix tr��ng h�p 10
      mTemp = mTemp.toString().replace('một mươi', 'mười');
      // Fix tr��ng h�p x4, x>=2
      mTemp = mTemp.toString().replace('mươi bốn', 'mươi tư');
      // Fix tr��ng h�p x04
      mTemp = mTemp.toString().replace('linh bốn', 'linh tư');
      // Fix tr��ng h�p x5, x>=2
      mTemp = mTemp.toString().replace('mươi năm', 'mươi lăm');
      // Fix tr��ng h�p x1, x>=2
      mTemp = mTemp.toString().replace('mươi một', 'mươi mốt');
      // Fix tr��ng h�p x15
      mTemp = mTemp.toString().replace('mười năm', 'mười lăm');
      // B� k� t� space
    }
    return mTemp.trim();

  }
  /**
   * Show snackbar
   * message
   * type => 'success' , 'error', 'info', 'warning'
   */
  notification(message: string, type: string = 'info', title = 'Messages from the system'): Observable<boolean> {
    this.setLang();
    const subject: Subject<boolean> = new Subject();
    // const titleLang = this.translate.instant(title);
    // const messageLang = this.translate.instant(message);
    const titleLang = title;
    const messageLang = message;
    let time = 3 * 1000;
    // if (type === 'error') {
    //     time = 5 * 60 * 1000;
    // }

    this.translate.get([titleLang, messageLang]).subscribe(value => {
      this.messageService.add({
        key: 'notification',
        severity: type,
        summary: value[titleLang],
        detail: value[messageLang],
        life: time,
        // sticky: type === 'error'
      });
    });
    return subject.asObservable();
  }

  /**
   * Alert
   * message
   * type => 'success' , 'error', 'info', 'warning'
   */
  alert(message: string, type: string = 'info', isFixed: boolean = false): Observable<boolean> {
    this.setLang();
    const subject: Subject<boolean> = new Subject();
    const title = 'Message from the system';
    const messageLang = message;
    // const title = isFixed ? 'Message from the system' : this.translate.instant('Messages from the system');
    // const messageLang = isFixed ? message : this.translate.instant(message);

    this.translate.get([title, messageLang]).subscribe(value => {
      this.messageService.add({
        key: 'alert',
        severity: type,
        summary: isFixed ? title : value[title],
        detail: isFixed ? messageLang : value[messageLang],
      });
    });
    return subject.asObservable();
  }

  /**
   * Confirm dialog
   * message
   */
  confirm(message: string, isFixed?: boolean): Observable<boolean> {
    this.setLang();
    const subject: Subject<boolean> = new Subject();
    const title = 'Messages from the system';
    const msg = message;
    // const title = isFixed ? 'Messages from the system' : this.translate.instant('Messages from the system');

    this.translate.get([title, msg, 'Cancel']).subscribe(value => {
      const titleTemp = isFixed ? title : value[title];
      const messageTemp = value[msg];
      const cancelTemp = isFixed ? 'Cancel' : value['Cancel'];

      this.confirmationService.confirm({
        header: titleTemp,
        message: messageTemp,
        acceptLabel: isFixed ? 'Confirm' : 'OK',
        rejectLabel: cancelTemp,
        acceptButtonStyleClass: 'btn-save-class',
        rejectButtonStyleClass: 'btn-cancel-class',
        accept: () => subject.next(true),
        reject: () => subject.next(false)
      });
    });

    return subject.asObservable();
  }

  reduce(dataLList: any[], keyGroupBy: string): any {
    return dataLList.reduce((result: any, item: any) => ({
      ...result,
      [item[keyGroupBy]]: [...(result[item[keyGroupBy]] || []),
        item,
      ],
    }), {});
  }

  enCode(key: any): string {
    return window.btoa(key);
  }

  deCode(enCode: any): any {
    return window.atob(enCode);
  }

  cloneObjectFunc(obj: any) {
    return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
  }

  /** Sort lại mảng theo thuộc tính: ví dụ Array<any>.sort(dynamicsort) */
  dynamicsort(property: any, order: any) {
    let sort_order = 1;
    if (order === 'desc') {
      sort_order = -1;
    }
    return (a: any, b: any) => {
      // a should come before b in the sorted order
      if (a[property] < b[property]) {
        return -1 * sort_order;
        // a should come after b in the sorted order
      } else if (a[property] > b[property]) {
        return 1 * sort_order;
        // a and b are the same
      } else {
        return 0 * sort_order;
      }
    };
  }

  // khoanb
  returnParam(formGroup: any, valueField?: any) {
    const params: any = {};
    const key_field = valueField ? valueField : 'CODE'
    Object.keys(formGroup.value).forEach(key => {
      const _value = formGroup.controls[key].value;
      if (_value !== null && typeof (_value) === 'object') {
        params[key] = _value[key_field];
      } else { params[key] = _value; }
    });
    return params;
  }

  createMessage(type: string, title: string): void {
    this.setLang();
    // const titleLang: any = this.translate.stream(title);
    let titleLang: any = '';
    this.translate.get(title).subscribe((text: string) => {
      titleLang = text;
      this.messageService.add({
        key: 'message',
        severity: type === 'warning' ? 'warn' : type,
        summary: titleLang
      });
    });
    // this.nzMessage.create(type, titleLang, {
    //     nzDuration: 5000
    // });
  }
  onMessage(maloi: any) {
    switch (maloi) {
      //loi
      case '0002':
        this.createMessage('error', this.getMessage('0002'));
        break;
      case '0001':
        this.createMessage('success', this.getMessage('0001'));
        break;
      case '0003':
        this.createMessage('success', this.getMessage('0003'));
        break;
      case '0004':
        this.createMessage('error', this.getMessage('0004'));
        break;
      case '0006':
        this.createMessage('info', this.getMessage('0006'));
        break;
      case '0007':
        this.createMessage('info', this.getMessage('0007'));
        break;
      case '0008':
        this.createMessage('error', this.getMessage('0008'));
        break;
      case '0010':
        this.createMessage('error', this.getMessage('0010'));
        break;
      case '0011':
        this.createMessage('info', this.getMessage('0011'));
        break;
      case '0012':
        this.createMessage('info', this.getMessage('0012'));
        break;

      case '0013':
        this.createMessage('info', this.getMessage('0013'));
        break;
      case '0098':
        this.createMessage('info', this.getMessage('0098'));
        break;
      case '0099':
        this.createMessage('info', this.getMessage('0099'));
        break;
      case '0037':
        this.createMessage('warning', this.getMessage('0037'));
        break;
      case '0054':
        this.createMessage('warning', this.getMessage('0054'));
        break;
      case '0055':
        this.createMessage('warning', this.getMessage('0055'));
        break;
      default:
        break;

    }


    }
    setLang() {
        const lang: any = sessionStorage.getItem('language')
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }
    softCopyJSON(data: any): object {
        const cloned: any = {};
        // tslint:disable-next-line:forin
        for (const key in data) {
            cloned[key] = data[key];
        }
        return cloned;
    }
    private _reqUrl(url: string, params: any): Observable<any> {
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        // let options = new RequestOptions({ headers: headers });

    const paramsToSend: any = this.softCopyJSON(params);
    const domainName = this.getDomainName(url);
    if (domainName.toLowerCase() === window.location.host.toLowerCase()) {
      paramsToSend['CSRF_TOKEN'] = this.getCookie('CSRF_TOKEN');
    }

    return this.http.post(url, paramsToSend, {
      headers
    });
  }

  reqUrl1(url: string, params: any = {}): Observable<any> {
    return this._reqUrl1(url, params).pipe(
      map((jsonResponse: any) => {
        if (jsonResponse == null) {
          throw new Error('request return empty response');
        } else if (jsonResponse.success == null) {
          throw new Error(`json response does not contain 'success' parameter`);
        } else if (jsonResponse.success !== true) {
          if (jsonResponse.message === 'NOT_AUTHORIZED') {
            // this.hideLoading();
            this.router.navigate(['/login']);
          } else {
            return jsonResponse;
          }
        } else {
          return jsonResponse;
        }
      })
    );
  }
  private _reqUrl1(url: string, params: any): Observable<any> {
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    const headers = new HttpHeaders({
      'Content-Type': "application/x-www-form-urlencoded"
    });
    // headers.append('Content-Type',  'application/x-www-form-urlencoded');
    // let options = new RequestOptions({ headers: headers });

    const paramsToSend: any = this.softCopyJSON(params);
    const domainName = this.getDomainName(url);
    if (domainName.toLowerCase() === window.location.host.toLowerCase()) {
      paramsToSend['CSRF_TOKEN'] = this.getCookie('CSRF_TOKEN');
    }
    const formData = new HttpParams({ fromObject: paramsToSend });
    return this.http.post(url, formData, {
      headers
    });
  }
  reqUrlAlePay(url: string, params: any = {}): Observable<any> {
    return this._reqUrl(url, params).pipe(
      map((jsonResponse: any) => {
        if (jsonResponse == null) {
          throw new Error('request return empty response');
        } else if (jsonResponse.code !== "000") {
          throw new Error(`json response does not contain 'success' parameter`);
        } else if (jsonResponse.code === "000") {
          if (jsonResponse.message === 'NOT_AUTHORIZED') {
            // this.hideLoading();
            this.router.navigate(['/login']);
          } else {
            return jsonResponse;
          }
        } else {
          return jsonResponse;
        }
      })
    );
  }
  reqUrl(url: string, params: any = {}): Observable<any> {
    return this._reqUrl(url, params).pipe(
      map((jsonResponse: any) => {
        if (jsonResponse == null) {
          throw new Error('request return empty response');
        } else if (jsonResponse.success == null) {
          throw new Error(`json response does not contain 'success' parameter`);
        } else if (jsonResponse.success !== true) {
          if (jsonResponse.message === 'NOT_AUTHORIZED') {
            // this.hideLoading();
            this.router.navigate(['/login']);
          } else {
            return jsonResponse;
          }
        } else {
          return jsonResponse;
        }
      })
    );
  }
  convertDateToString(date_input: any, isTime: any) {
    const date = new Date(date_input);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate().toString();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1).toString();
    const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours().toString();
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes().toString();
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds().toString();
    const val_time = h + ':' + m + ':' + s + ' ' + day + '-' + month + '-' + date.getFullYear();
    let val_date = day + '-' + month + '-' + date.getFullYear();;
    return isTime ? val_time : val_date;
  }

    // add notification
    addNotification(params: any) {
        const reqService = new RequestService(this.injector);
        reqService.switchType('sql');
        const resp = reqService.service.insert({
            url: this.urlWS + '/SysNotifications',
            primaryKey: 'NotificationId',
            data: params
        });
        return resp;
    }

    addBroadCast(params: any) {
        let baseUrl = this.urlInit ? this.urlInit : 'https://water.esrivn.net/CoreTechApi';
        let url_ = baseUrl + "/api/Notification/broadcast";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(params);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processBroadcast(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processBroadcast(<any>response_);
                } catch (e) {
                    return <Observable<void>><any>throwError(e);
                }
            } else
                return <Observable<void>><any>throwError(response_);
        }));
    }

    // Cụm code copy từ notificationService => anh Vinh yêu cầu đưa 2 hàm addBroadcast và addNotification ra dùng chung nên copy

    protected processBroadcast(response: HttpResponseBase): Observable<void> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return this.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return of<void>(<any>null);
            }));
        } else if (status !== 200 && status !== 204) {
            return this.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return of<void>(<any>null);
    }

    private throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
        if (result !== null && result !== undefined)
            return throwError(result);
        else
            return throwError(new SwaggerException(message, status, response, headers, null));
    }

    private blobToText(blob: any): Observable<string> {
        return new Observable<string>((observer: any) => {
            if (!blob) {
                observer.next("");
                observer.complete();
            } else {
                let reader = new FileReader();
                reader.onload = function () {
                    observer.next(this.result);
                    observer.complete();
                }
                reader.readAsText(blob);
            }
        });
    }

    // Hết Cụm code copy từ notificationService

}

export class SwaggerException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}

export enum ResponseType {
  json,
  text,
  arrayBuffer
}

