import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/app-base/app.service';
import { ReportActionService } from 'app/reports/services/report-action.service';
import { StimulsoftViewerComponent } from 'stimulsoft-viewer-angular';
@Component({
  selector: 'app-param-config',
  styleUrls: ['./param-config.component.scss'],
  templateUrl: './param-config.component.html'
})
export class ParamConfigComponent implements OnInit {
  @ViewChild('viewer', { static: true }) viewer!: StimulsoftViewerComponent;
  loadAPI: Promise<any> | undefined;
  paramsAction: any = null;
  paramsUrl: any = null;
  paramsProperties: any = null;

  constructor(
    private appService: AppService,
    private router: ActivatedRoute,
    private raService: ReportActionService,
  ) {
    this.loadAPI = new Promise(async (resolve) => {
      await this.loadScript('assets/js/ns-webview-bridge.js');
      await this.loadScript('assets/js/metadata-view-port.js');
      resolve(true);
    });
  }

  private loadScript(url: string) {
    return new Promise((resolve) => {

      // tslint:disable-next-line:no-angle-bracket-type-assertion
      const body = <HTMLDivElement>document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = true;
      script.defer = true;
      body.appendChild(script);
      script.onload = () => {
        resolve(true);
      }
    })
  }

  async ngOnInit() {

    this.loadAPI?.then(res => {
      if (window['nsWebViewBridge']) {
        window['nsWebViewBridge'].on('eventReportNormal', (data: any) => {
          this.loadReport(data)
        })
      } else {

      }

    })
    let params: any = null;
    if (window.PamramsReport) {
      if (sessionStorage.getItem('PamramsReport')) {
        sessionStorage.removeItem('PamramsReport');
      }
      sessionStorage.setItem('PamramsReport', window.PamramsReport)
      params = JSON.parse(window.PamramsReport);
    } else {
      let PamramsReport: any = sessionStorage.getItem('PamramsReport');
      params = JSON.parse(PamramsReport);
    }
    this.loadReport(params)
  }

  loadReport(params: any) {

    if (params) {

      // const paramMap: any = this.router.snapshot.paramMap;
      // const params = JSON.parse(paramMap['params'].params);
      // const params = JSON.parse(window.PamramsReport);

      let stringToken = '';
      if (params.tokenType && params.tokenType === "tokenGIS") {
        stringToken = params.token !== '' ? "&token=" + params.token : '';
        params.token = stringToken;
      }

      if (params.tokenType && params.tokenType === "tokenTABLE") {
        stringToken = "?tokenbearer=" + params.token;
        params.token = stringToken;
      }


      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (params[key] !== null && params[key] !== "") {
            const element = params[key].toString().includes("dau_cong") ? params[key].replaceAll("dau_cong", "+") : params[key];
            params[key] = element;
            if (this.decodeURLRecursively(params) === true) {
              params[key] = decodeURIComponent(element);
            }
          }
        }
      }
      
      this.paramsProperties = params;
      this.paramsUrl = `${this.appService.urlInit}/ViewerNoParam/{action}`;
      this.paramsAction = 'InitViewerNoParam';
      this.viewer.height = '100%';
      // this.viewer.properties = params;
      // this.viewer.requestUrl = `${this.appService.urlInit}/ViewerNoParam/{action}`;
      // this.viewer.action = 'InitViewerNoParam';
    }
  }

  decodeURLRecursively(url: any) {
    let bool = false;
    if (typeof url === 'string') {
      if (url.indexOf('%') !== -1) {
        bool = true;
      }
    }
    return bool;
  }
}
