<p-dialog #pDialog [(visible)]="display" [styleClass]='hasFooter ? "dialog-hasfooter-class " + styleClass : styleClass ' [showHeader]="isShowTitle"
    [position]="positionDialog" maskStyleClass = 'core-dialog-class'
    [style]="{'width': isMini ? 'auto' : !isMobileSize ? widthPanel : '90vw', 'max-width': isMaxWidth ? MaxWidthPanel : 'none', 'z-index': baseZIndex, 'max-height': maxiMizable ? '100%' : !isMobileSize ? heightPanel : '70vh', position: position, top: !isMobileSize ? top : '20px', 'bottom': bottom, right: right, 'left': left, 'height': isMini ? '50px' : heightDialog,  'transition': isMini ? 'width 0.5s, height 0.5s': 'none'}"
    [baseZIndex]="baseZIndex" [autoZIndex]="autoZIndex" (onHide)="onHideDialog()"
    [appendTo]="isAppendBody ? 'body' : ''" [modal]="isModal" [contentStyle]="objStyle" [maximizable]="maxiMizable">
    <!-- [contentStyle]="{'overflow':'visible'}" -->
    <ng-template pTemplate="header">
        <div class="header-dialog-class">
            <label style="padding-right: 10px; margin-bottom: 0px;">{{preTitle | translate}} {{title |
                translate}}</label>
            <ng-container *ngIf="isMinimize">
                <i class="pi pi-chevron-circle-up" style="cursor: pointer;" (click)="onClickToolHeader('hide')"
                    *ngIf="!isMini"></i>
                <i class="pi pi-chevron-circle-down" style="cursor: pointer;" (click)="onClickToolHeader('show')"
                    *ngIf="isMini"></i>
            </ng-container>

        </div>

    </ng-template>
    <ng-template pTemplate="content">
        <div [ngStyle]="{'display': isMinimize && isMini ? 'none' : 'block'}" style="padding: 1.25rem; height: 100%;">
            <ng-container *ngIf="isComponent">
                <ng-container *ngTemplateOutlet="templateContent"></ng-container>
            </ng-container>
            <ng-container *ngIf="!isComponent">
                <ng-container *ngTemplateOutlet="templateRef, context: myContext"></ng-container>
            </ng-container>
        </div>

    </ng-template>
    <ng-template pTemplate="footer" >
        <div [ngStyle]="{'display': isMinimize && isMini ? 'none' : 'block'}">
            <div class="div-footer-dialog" *ngIf="hasFooter">
                <ng-container *ngTemplateOutlet="tmpFooter"></ng-container>
            </div>
        </div>
       
        
    </ng-template>


</p-dialog>
<ng-template #templateContent>
    <div style="width: 100%;" #form></div>
</ng-template>