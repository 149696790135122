<app-core-tab [tabs]='arrTab' [title]="titleRef" [content]="contentRef"></app-core-tab>
<ng-template #titleRef let-tab>
  <div class="tab-title">
    {{tab.title}}
  </div>
</ng-template>
<ng-template #contentRef let-tab>
  <ng-container *ngIf="tab.code === 'manager'">
    <ng-container *ngTemplateOutlet="manager"></ng-container>
  </ng-container>
  <ng-container *ngIf="tab.code === 'report'">
    <ng-container *ngTemplateOutlet="report"></ng-container>
  </ng-container>
</ng-template>

<ng-template #manager>
  <div class="wp-container">
    <div class="combo" style="display: flex;">
      <app-select [labelName]="'Workflow' | translate" [isLabelLeft]="false" [formControl]="jobType"
        [dataLookup]="lookUpDataJobType" [required]="true" displayField="DESCR" valueField="CODE"></app-select>
      <app-select [labelName]="'Status' | translate" [isLabelLeft]="false" [formControl]="CbJobStatus"
        [dataLookup]="dataStatus" [required]="false" displayField="DESCR" valueField="CODE" style="margin-left: 15px;">
      </app-select>
      <app-select [labelName]="'User' | translate" [isLabelLeft]="false" [formControl]="CbassignTo"
        [dataLookup]="dataUser" [required]="false" displayField="DESCR" valueField="CODE"
        style="margin-left: 15px;"></app-select>

      <app-input [type]="'text'" [labelName]="'Job name' | translate" style="margin-left: 15px;" [isLabelLeft]="false"
        [formControl]="textNameJob"></app-input>
      <div style="margin-top: 20px">
        <app-button [labelName]="'Search' | translate" typeButton="save" icon='pi pi-search' style="margin-left: 15px;"
          (click)="onSearchJob()"></app-button>
      </div>
    </div>
    <div class="process-job">
      <div class="title">
        <div>{{'Total job' |translate}} : {{totalJob}}</div>
        <!-- <div class="wp-legend">
                    <div class="region">
                        <label for="">Validate</label>
                        <div style="width: 15px; height: 12px; background: #32CD32;"></div>
                    </div>
                    <div class="region">
                        <label for="">Expire</label>
                        <div style="width: 15px; height: 12px; background: #f70a0a;"></div>
                    </div>
                </div> -->
      </div>
      <hr>
      <div>
        <app-core-basic-table [dataTable]="tableDataJob" displayField='key' [tableConfig]="tableConfig"
          [hasTool]="false" [columnTable]="tableRowDefinationJob"
          (dbClickEvt)="onDblClickRow($event)"></app-core-basic-table>`
      </div>
    </div>
  </div>
</ng-template>

<ng-template #report>
  <div class="report-container">
    <div class="total-dashboard">
      <div class="wp-item">
        <div class="title-item">
          <label>{{'Job' | translate}}</label>
        </div>
        <div class="content-item">
          <div class="total-job">
            <label>{{totalJobReport}}</label>
          </div>
          <div class="wp-detail-task">
            <!-- <label for="">Job</label> -->
            <div class="detail-task">
              <div class="color-status-init"></div>
              <div class="num-detail">{{totalJobInit}}</div>
              <div class="text-detail">{{'Init' | translate}}</div>
            </div>
            <div class="detail-task">
              <div class="color-status-working"></div>
              <div class="num-detail">{{totalJobWorking}}</div>
              <div class="text-detail">{{'Working' | translate}}</div>
            </div>
            <div class="detail-task">
              <div class="color-status-done"></div>
              <div class="num-detail">{{totalJobDone}}</div>
              <div class="text-detail">{{'Done' | translate}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="wp-item">
        <div class="title-item">{{'Workflow' | translate}}</div>
        <div class="content-item">
          <label class="value-total">{{totalWorkflow}}</label>
        </div>
      </div>
      <div class="wp-item">
        <div class="title-item">{{'Member' | translate}}</div>
        <div class="content-item">
          <label class="value-total">{{totalMember}}</label>
        </div>
      </div>
    </div>
    <div style="width: 800px; margin-bottom: 15px; display: flex;">
      <div style="width: 350px;">
        <app-select [labelName]="'Workflow' | translate" [isLabelLeft]="true" [formControl]="jobTypeReport"
          [dataLookup]="lookUpDataJobType" [required]="true" displayField="DESCR" valueField="CODE"></app-select>
      </div>

      <div style="width:340px; margin-left: 40px;">
        <app-core-datetime [isLabelLeft]="true" [formControl]="searchTime" [labelName]="'Filter' | translate"
          [appendTo]="'body'" [calConfig]="{ showTime: true, selectionMode: 'range'}"></app-core-datetime>
        <!-- <app-date-range nz-row  [labelName]="'Fillter'"
                [formControl] ="searchTime"></app-date-range> -->
      </div>
      <div style="margin-left: 15px;">
        <!-- <button style="text-align: center;" nz-button nzType="primary" (click)="search()">Tìm kiếm</button> -->
        <app-button [labelName]="'Search'| translate" typeButton="save" icon='pi pi-search' style="margin-left: 10px;"
          (click)="search()"></app-button>
      </div>
    </div>
    <div class="time-working">
      <div class="tbl">
        <div class="title-report">
          <label>{{'Time to complete the mission' | translate}}</label>
          <div class="total-job-hour">
            <label>{{total_job_count}}&nbsp;</label>
            <label>jobs - </label>
            <label>&nbsp; {{total_hour_count}}</label>
            <label>h avg duration</label>
          </div>
        </div>
        <div>
          <app-core-basic-table [dataTable]="tableTimeWorking" displayField='key' [tableConfig]="tableConfig"
            [hasTool]="false" [columnTable]="tableRowDefinationTimeWK"></app-core-basic-table>
        </div>
      </div>
    </div>
    <div class="time-working">
      <div class="title-report">
        <label>{{"Executors in each stage" | translate}}</label>
      </div>
      <div class="tbl">
        <app-core-basic-table [dataTable]="tableDataAssign" displayField='key' [tableConfig]="tableConfig"
          [hasTool]="false" [columnTable]="tableRowDefinationAssign"></app-core-basic-table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #TemplateInfoJob>
  <div class="wp-container">
    <p-tabView (onChange)="tapChange($event)">
      <p-tabPanel header="Detail">
        <div class="wp-detail" style="height: 750px">
          <app-core-window [isMapApp]="true" [windowId]="windowId" [whereWf]="whereWf"
            [isWfApp]="true"></app-core-window>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Diagram">
        <div class="wp-workflow" style="height: 750px">
          <div class="canvas">
            <div #divWorkflow class="diagram"></div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Job">
        <div class="wp-job" style="height: 750px">
          <div [formGroup]="formData" class="col-12 grid">
            <ng-container *ngFor="let field of fieldList">
              <ng-container *ngIf="field.FIELD_SHOW == 'Y'">
                <div class="col-3 pt-0">
                  <ng-container [ngSwitch]="field.FIELD_TYPE">
                    <ng-container *ngSwitchCase="'text'">
                      <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                        [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED"></app-input>
                    </ng-container>
                    <ng-container *ngSwitchCase="'number'">
                      <app-input [type]="'number'" [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                        [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED">
                      </app-input>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">
                      <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                        [disabledCtrl]="field.FIELD_DISABLED" [formControlName]="field.FIELD_NAME"></app-core-datetime>
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">
                      <app-select [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                        [formControlName]="field.FIELD_NAME" [dataLookup]="lookupCondition[field.FIELD_NAME]"
                        displayField="DESCR" [disabledCtrl]="field.FIELD_DISABLED" valueField="CODE">
                      </app-select>
                    </ng-container>
                    <ng-container *ngSwitchCase="'T'">
                      <app-input [type]="'text-area'" [labelName]="field.FIELD_LABEL"
                        [disabledCtrl]="field.FIELD_DISABLED" [isLabelLeft]="false"
                        [formControlName]="field.FIELD_NAME"></app-input>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <div>{{field.FIELD_TYPE}} : {{field.FIELD_LABEL}}</div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="History">
        <div style="height: 750px">
          <app-core-basic-table [dataTable]="tableData" displayField='key' [tableConfig]="tableConfig" [hasTool]="false"
            [columnTable]="tableRowDefination"></app-core-basic-table>
        </div>

      </p-tabPanel>
    </p-tabView>
  </div>
</ng-template>

<app-dialog-prime #dialogPrime [isAppendBody]="true"></app-dialog-prime>
<!-- <ng-template #titleRef_child let-tab>
    <div class="tab-title">
       {{tab.title}}
    </div>
</ng-template> -->
<!-- <ng-template #contentRef_child let-tab>
    <ng-container *ngIf="tab.code === 'diagram'">
        <ng-container *ngTemplateOutlet="diagram"></ng-container>
    </ng-container>
    <ng-container *ngIf="tab.code === 'detail'">
        <ng-container *ngTemplateOutlet="detail"></ng-container>
    </ng-container>
    <ng-container *ngIf="tab.code === 'job'">
        <ng-container *ngTemplateOutlet="job"></ng-container>
    </ng-container>
    <ng-container *ngIf="tab.code === 'history'">
        <ng-container *ngTemplateOutlet="history"></ng-container>
    </ng-container>
</ng-template> -->
<!-- <ng-template #diagram>
    <div class="wp-workflow">
        <div class="canvas">
            <div #divWorkflow class="diagram"></div>
        </div>
    </div>
</ng-template>
<ng-template #detail>
    <div class="wp-detail">
        <app-core-window [isMapApp]="true" [windowId]="windowId" [whereWf]="whereWf" [isWfApp]="true"></app-core-window>
    </div>
</ng-template>
<ng-template #job>
    <div [formGroup]="formData" class="col-12 grid">
        <ng-container *ngFor="let field of fieldList">
            <ng-container *ngIf="field.FIELD_SHOW == 'Y'">
                <div class="col-3 pt-0">
                    <ng-container [ngSwitch]="field.FIELD_TYPE">
                        <ng-container *ngSwitchCase="'text'">
                            <app-input  [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                            [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED"></app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'number'">
                            <app-input [type]="'number'" [labelName]="field.FIELD_LABEL"
                            [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED">
                        </app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                            <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [disabledCtrl]="field.FIELD_DISABLED"
                            [formControlName]="field.FIELD_NAME"></app-core-datetime>
                        </ng-container>
                        <ng-container *ngSwitchCase="'select'">
                            <app-select  [labelName]="field.FIELD_LABEL"
                            [isLabelLeft]="false" [formControlName]="field.FIELD_NAME"
                            [dataLookup]="lookupCondition[field.FIELD_NAME]" displayField="DESCR" [disabledCtrl]="field.FIELD_DISABLED"
                            valueField="CODE">
                        </app-select>
                        </ng-container>
                        <ng-container *ngSwitchCase="'T'">
                            <app-input [type]="'text-area'" [labelName]="field.FIELD_LABEL" [disabledCtrl]="field.FIELD_DISABLED"
                            [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" ></app-input>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div>{{field.FIELD_TYPE}} : {{field.FIELD_LABEL}}</div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
<ng-template #history>
    <app-core-basic-table [dataTable]="tableData" displayField='key' [tableConfig]="tableConfig" [hasTool]="false"
         [columnTable]="tableRowDefination"></app-core-basic-table>
</ng-template> -->