<ng-container [ngSwitch]="SystemType">
  <ng-container *ngSwitchCase="'UM'">
    <ng-container *ngTemplateOutlet="umHeader"></ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="defaultHeader"></ng-container>
  </ng-container>
</ng-container>

<ng-template #umHeader>
  <div class="header__body">
    <ng-container *ngTemplateOutlet="responseMenu"></ng-container>
    <div class="block-left">
      <div (click)="opShortcut.toggle($event)" class="divImgClass" style="width: 60px !important;">
        <div [style.backgroundImage]="'url(' + urlIconHeader + ')'" class="img-class">
        </div>

      </div>

      <div style="padding: 0px 5px; display: flex; justify-content: center; flex-direction: column;">
        <div class="bg-left-header">{{nameApp | uppercase | translate}}</div>
        <div class="divNameClass">{{ tenTrang | translate}}</div>
      </div>
    </div>

    <div class="block-center">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

    <div class="block-right">
      <ul>
        <li>
          <div class="icn-home">
            <i class="pi pi-home" style="font-size: 24px; color: var(--color_header_text);" (click)="backToMenu()"
              [title]="'Home' | translate "></i>
          </div>
          <div class="icn-bell" (click)="notificationCount > 0 ? templateNotifycation.toggle($event) : ''">
            <ng-container *ngIf="severity === 'success'">
              <i class="pi pi-bell p-mr-4 p-text-secondary cursor-pointer" [severity]="severity" pBadge
                style="font-size: 24px; color: var(--color_header_text)" [title]="'Notification' | translate "
                [value]="notificationCount.toString()"></i>
            </ng-container>
            <ng-container *ngIf="severity === 'warning'">
              <i class="pi pi-bell p-mr-4 p-text-secondary cursor-pointer" [severity]="severity" pBadge
                style="font-size: 24px; color: var(--color_header_text)" [title]="'Notification' | translate "
                [value]="notificationCount.toString()"></i>
            </ng-container>
            <ng-container *ngIf="severity === 'danger'">
              <i class="pi pi-bell p-mr-4 p-text-secondary cursor-pointer" [severity]="severity" pBadge
                style="font-size: 24px; color: var(--color_header_text)" [title]="'Notification' | translate "
                [value]="notificationCount.toString()"></i>
            </ng-container>
            <!-- <ng-container *ngTemplateOutlet="templateNotifycation"></ng-container> -->
          </div>
          <div class="icn-setting">
            <!-- <i class="pi pi-cog" style="font-size: 24px; color: var(--color_header_text);"
                            (click)="settingUM()"></i> -->
            <i class="pi pi-calendar-minus" style="font-size: 24px; color: var(--color_header_text)"
              (click)="openCalendarList($event, opCalendar)" [title]="'Calendar' | translate "></i>
          </div>
          <div class="icn-setting">
            <i class="pi pi-tags" [title]="'Job' | translate " style="font-size: 24px; color: var(--color_header_text)"
              (click)="openJob()"></i>
          </div>
        </li>
        <li class="dash"></li>
        <li>
          <div class="user-icon">
            <i class="far fa-user"></i>
          </div>
          <span class="titleUser ml-3">{{'Hi' | translate}}, &nbsp;</span>
          <span class="nameUser"> {{userLogin}} </span>

          <i class="pi pi-angle-down ml-3" style="color: var(--color_header_text); font-size: 16px; cursor: pointer;"
            (click)="op.toggle($event)"></i>


        </li>
        <li class="dash"></li>
        <li>
          <div class="img-language" [style.backgroundImage]="'url(./assets/langImg/' + language.img + ')'">
          </div>
          <div style="color: var(--color_header_text);">{{selectedValue}}</div>
          <i class="pi pi-angle-down ml-3"
            style="color: var(--color_header_text); font-size: var(--font_size_big); cursor: pointer; margin-right: 5px;"
            (click)="opLanguage.toggle($event)"></i>
        </li>
        <li class="dash"></li>
        <li style="padding-right: 10px;">
          <!-- <div class="icn-help" (click)="onClickHelp()" title="Help [F1]">
                    </div> -->
          <i class="pi pi-question-circle"
            style="color: var(--color_header_text); font-size: var(--font_size_big); cursor: pointer;"
            (click)="onClickHelp()" title="Help [F1]" [title]="'Help' | translate "></i>
        </li>
      </ul>
      <!-- <app-dialog-prime></app-dialog-prime>
          <app-dialog-prime #dialogClause></app-dialog-prime> -->
      <!-- <ng-template #tmpWhereClause>
              <app-change-password (changeComplate)="formChangeClose()"></app-change-password>
          </ng-template> -->
    </div>
  </div>
</ng-template>

<ng-template #defaultHeader>
  <div class="header__body">
    <div class="block-left">
      <div (click)="opShortcut.toggle($event)" class="divImgClass">
        <div [style.backgroundImage]="'url(' + urlIconHeader + ')'" class="img-class"></div>
      </div>

      <div class="bg-left-body">
        <div class="bg-left-header">{{ nameApp | uppercase | translate}}</div>
        <div class="divNameClass">{{ tenTrang | translate}}</div>
      </div>
    </div>

    <div class="block-right">
      <ul>
        <li>
          <div class="icn-home">
            <i class="pi pi-home" style="font-size: 24px; color: var(--color_header_text);" (click)="backToMenu()"
              [title]="'Home' | translate "></i>
          </div>
          <div class="icn-bell" (click)="notificationCount > 0 ? templateNotifycation.toggle($event) : ''">
            <ng-container *ngIf="severity === 'success'">
              <i class="pi pi-bell p-mr-4 p-text-secondary cursor-pointer" [severity]="severity" pBadge
                style="font-size: 24px; color: var(--color_header_text)" [title]="'Notification' | translate "
                [value]="notificationCount.toString()"></i>
            </ng-container>
            <ng-container *ngIf="severity === 'warning'">
              <i class="pi pi-bell p-mr-4 p-text-secondary cursor-pointer" [severity]="severity" pBadge
                style="font-size: 24px; color: var(--color_header_text)" [title]="'Notification' | translate "
                [value]="notificationCount.toString()"></i>
            </ng-container>
            <ng-container *ngIf="severity === 'danger'">
              <i class="pi pi-bell p-mr-4 p-text-secondary cursor-pointer" [severity]="severity" pBadge
                style="font-size: 24px; color: var(--color_header_text)" [title]="'Notification' | translate "
                [value]="notificationCount.toString()"></i>
            </ng-container>
            <!-- <ng-container *ngTemplateOutlet="templateNotifycation"></ng-container> -->
          </div>
          <div class="icn-setting" *ngIf="isShowCalendar">
            <!-- <i class="pi pi-cog" style="font-size: 24px; color: var(--color_header_text);"
                            (click)="settingUM()"></i> -->
            <i class="pi pi-calendar-minus" style="font-size: 24px; color: var(--color_header_text)"
              (click)="openCalendarList($event, opCalendar)" [title]="'Calendar' | translate "></i>
          </div>
          <div class="icn-setting">
            <i class="pi pi-tags" [title]="'Job' | translate " style="font-size: 24px; color: var(--color_header_text)"
              (click)="openJob()"></i>
          </div>
        </li>
        <li class="dash"></li>
        <li>
          <div class="user-icon">
            <i class="far fa-user"></i>
          </div>
          <span class="titleUser ml-3">{{'Hi' | translate}}, <span class="nameUser">{{userLogin}}</span>
          </span>
          <i class="pi pi-angle-down ml-3"
            style="color: var(--color_header_text); font-size: var(--font_size_big); cursor: pointer;"
            (click)="op.toggle($event)"></i>
        </li>
        <li class="dash"></li>
        <li>
          <div class="img-language" [style.backgroundImage]="'url(./assets/langImg/' + language.img + ')'">
          </div>
          <div style="color: var(--color_header_text);">{{selectedValue}}</div>
          <i class="pi pi-angle-down ml-3"
            style="color: var(--color_header_text); font-size: var(--font_size_big); cursor: pointer; margin-right: 5px;"
            (click)="opLanguage.toggle($event)"></i>
        </li>
        <li class="dash"></li>
        <li style="padding-right: 10px;">
          <!-- <div class="icn-help" (click)="onClickHelp()" title="Help [F1]">
                    </div> -->
          <i class="pi pi-question-circle"
            style="color: var(--color_header_text); font-size: var(--font_size_big); cursor: pointer;"
            (click)="onClickHelp()" title="Help [F1]" [title]="'Help' | translate "></i>
        </li>
      </ul>

      <app-dialog-prime #dialogComponent [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>
    </div>
  </div>
</ng-template>

<p-overlayPanel [appendTo]="'body'" #templateNotifycation [style]="{width: '290px', left: '55px'}">
  <ng-template pTemplate>
    <div class="notification-body">
      <ng-container *ngFor="let item of listNotify">
        <div class="notification-item" (click)="onReadNotify(item, templateNotifycation)"
          [ngClass]="{'has-read': item.IsRead === 'Y'}"
          [ngStyle]="{'background-color': item.bgColor, 'border': item.IsRead === 'Y' ? 'none' : '1px solid black' }">
          <div class="item-description">
            <div>{{item.Description}}</div>
            <div>Ngày gửi: {{item.sendDate}}</div>
          </div>
          <div>
            <i class="mr-3 pi pi-trash" style="font-size: 1.5rem" (click)="onRemoveNotify($event, item)"></i>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</p-overlayPanel>

<ng-template #tmpFormDetailCal>
  <form [formGroup]="formGroupDetailCal">
    <ng-container style="width: 100%;">
      <div style="width: 100%; height: 100%; padding: 0 2rem;">
        <div style="margin-bottom: 5px;">
          <app-input [labelWidth]="'200'" [labelName]="'Sự kiện' | translate" [required]="true"
            [formControlName]="'eventAdd'" [maxlength]="200" [isLabelLeft]="true" [disabledCtrl]="true">
          </app-input>
        </div>
        <div style="margin-bottom: 5px;">
          <div style="margin-bottom: 5px;">
            <app-core-datetime [formControlName]="'dateFromAdd'" [labelName]="'Từ ngày' | translate" [required]="true"
              [isLabelLeft]="true" [calConfig]="{ showTime: checkedDay ? false : true, maxDate: maxDate }"
              [disabledCtrl]="true">
            </app-core-datetime>
          </div>
          <div>
            <app-core-datetime labelWidth="200" [formControlName]="'dateToAdd'" [labelName]="'Đến ngày' | translate"
              [required]="true" [isLabelLeft]="true"
              [calConfig]="{ showTime: checkedDay ? false : true, minDate: minDate }" [disabledCtrl]="true">
            </app-core-datetime>
          </div>
        </div>
        <div style="margin: 10px 0px;">
          <app-core-checkbox [hasFormCtrl]="true" [chkLabel]="'Cả ngày' | translate"
            [formControlName]="'chkTimeUnitAdd'" [isDisabled]="true">
          </app-core-checkbox>
        </div>

        <ng-container>
          <div style="margin-bottom: 5px;">
            <app-input [labelName]="'Nội dung thông báo' | translate" [required]="true"
              [formControlName]="'eventNotifiAdd'" [maxlength]="'200'" [isLabelLeft]="true" [disabledCtrl]="true">
            </app-input>
          </div>
          <div style="margin-bottom: 5px;">
            <app-core-datetime [formControlName]="'dateSendNotifiAdd'" [labelName]="'Ngày gửi' | translate"
              [required]="true" [isLabelLeft]="true" [calConfig]="{ showTime: false }" [disabledCtrl]="true">
            </app-core-datetime>
          </div>
          <div style="margin-bottom: 5px;">
            <app-select [labelName]="'Mức độ ưu tiên' | translate" [formControlName]="'priorityAdd'"
              [dataLookup]="arrPriotity" displayField="DESCR" valueField="CODE" [required]="true" [disabledCtrl]="true">
            </app-select>
          </div>
          <!-- <div style="margin-bottom: 5px;">
            <app-input [required]="true" [labelName]="'Người dùng'" [isLabelLeft]="true" [formControlName]="'userSendNoti'"></app-input>
          </div> -->
        </ng-container>

        <div style="display: flex; justify-content: center;" class="form-add-footer">
          <!-- <app-button class="ml-3" [labelName]="'Đồng ý' | translate" typeButton="save" (clickEvent)="openDialogWindow()">
        </app-button> -->
          <app-button class="ml-3" [labelName]="'Thoát' | translate" typeButton="close"
            (clickEvent)="closeDialogDetailCal()">
          </app-button>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<p-overlayPanel (onShow)="handleClickShowOverlayPanel()" styleClass="OverlayPanelDashboardMini" [appendTo]="'body'"
  #opShortcut [style]="{ background: 'url(' + bgClient +') bottom right / cover no-repeat'}">
  <ng-template pTemplate>
    <div class="div__overlayPanel">
      <ng-container *ngIf="listAppShortcutSys.length > 0">
        <div class="margin-0 padding-0 row divListApp__Shortcuts--Sys">
          <div class="margin-0 padding-0 col-sm-12">
            <div class="Shortcuts__body">
              <ng-container *ngFor="let item of listAppShortcutSys">
                <div (click)="openApplication(item, true)" [pTooltip]="item.applicationName | translate"
                  class="Shortcuts__Content">
                  <div class="Shortcuts__Img"
                    [ngStyle]="{'background': 'url(' + ((item.icon !== null && item.icon !== '') ? baseUrl + item.icon : this.imageDefault) +') center / contain no-repeat'}">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>


      <ng-container *ngIf="listAppShortcut.length > 0">
        <div class="margin-0 padding-0 row divListApp__Shortcuts--app">
          <div class="margin-0 padding-0 col-sm-12">
            <div class="Shortcuts__body">
              <ng-container *ngFor="let item of listAppShortcut">
                <div [ngStyle]="{'background-color': item.colorCode !== null ? item.colorCode : '#000'}"
                  (click)="openApplication(item, false)" [pTooltip]="item.titleApp" class="Shortcuts__Content">
                  <div class="Shortcuts__Img"
                    [ngStyle]="{'background': 'url(' + ((item.icon !== null && item.icon !== '') ? baseUrl + item.icon : this.imageDefault) +') center / contain no-repeat'}">
                  </div>
                  <div class="Shortcuts__Title">{{item.applicationName}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>


  </ng-template>
</p-overlayPanel>

<p-overlayPanel [appendTo]="'body'" #op [style]="{width: '160px'}">
  <ng-template pTemplate>
    <div class="over-panel-class" style="padding: 10px;">
      <div class="item-menu-class" (click)="btnChangePassWord()">
        <i class="fas fa-key"></i> {{'Change password' | translate}}
      </div>
      <div class="item-menu-class" (click)='logout()'>
        <i class="fas fa-sign-out-alt"></i> {{'Logout' | translate}}
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel [appendTo]="'body'" #opLanguage [style]="{width: '75px'}">
  <ng-template pTemplate>
    <div class="over-panel-class" style="padding: 10px;">
      <ng-container *ngFor="let language of appService.listLang">
        <div class="item-menu-class" (click)="switchLang(language, opLanguage)"
          style="padding-bottom: 5px; justify-content: center;">
          <img [src]="'./assets/langImg/' + language.img" [alt]="language.DESCR" style="width: 100%; height: 100%;" />
        </div>
      </ng-container>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel [appendTo]="'body'" #opCalendar>
  <ng-template pTemplate>
    <ng-container *ngIf="listCalendar">
      <div class="over-panel-class" style="padding: 10px;">
        <ng-container *ngFor="let item of listCalendar">
          <div class="item-menu-class" (click)="onOpenCalendar(item, opCalendar)">{{item.calendarName}}</div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</p-overlayPanel>
<app-dialog-prime #dialogHelp [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>
<app-dialog-prime [isMaxWidth]="true" #dialogClause [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>
<app-dialog-prime #dialogCalendar [maxiMizable]="true" [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>
<ng-template #tmpWhereClause>
  <app-pass-change (changeComplate)="formChangeClose()"></app-pass-change>
</ng-template>
<ng-template #tmpAttachment>
  <app-core-attachment [applicationId]="appService.c$['appId']" [isModePlugin]="true"
    [permissionList]="permissionAttach" [dataSource]="dataSoureAttachment"></app-core-attachment>
</ng-template>