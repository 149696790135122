<router-outlet></router-outlet>
<p-toast key="notification"></p-toast>
<p-toast key="message" position="top-right"></p-toast>
<p-toast key="alert" position="top-center" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-confirmDialog [style]="{ width: '30vw' }" [baseZIndex]="9999999" styleClass = "confirm-dialog-class"></p-confirmDialog>
<!-- <app-project-manager></app-project-manager> -->