// import { param } from 'jquery';
import { Injectable } from '@angular/core';
import { AppService, ResponseType } from '../../app-base/app.service';

import { CoreTechTable } from './../config/um-tables';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestService, OdataParams } from './../../core/services/request.service';
import { catchError, map } from 'rxjs/operators';
import { SearchResponse } from './application.service';
import { Observable, Subject } from 'rxjs';
import { loadModules, loadScript } from 'esri-loader';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class ConfigServicesDataService {
    public url = this.appService.urlWS;
    public urlInit = this.appService.urlInit;

    constructor(
        private appService: AppService,
        private reqService: RequestService,
        private http: HttpClient,
    ) {

        this.reqService.switchType(this.appService.dataAccessType);
    }
    public getWindow(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysWindows',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public async init() {
        if (!this.appService.isLoadArcGIS3x) {
            this.appService.isLoadArcGIS3x = true;
        }
    }

    queryDBAutodata() {
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }
        return this.http.get('https://coretech.vn:1314/api/mssql/databases/', {
            headers
        }).pipe(map((res: any) => {
            // console.log(res);
            const resp: any = {};
            if (res.length > 0) {
                resp.total = res.length;
                resp.success = true;
                resp.features = res;
                resp.message = 'Query success';
            } else {
                resp.total = res.length;
                resp.success = false;
                resp.features = res;
                resp.message = 'Query error';
            }
            return resp;
        }));
    }
    // khoanb ODATA
    public queryDichVu(params: any, tablename: string) {
        this.reqService.switchType('sql');
        const resp = this.reqService.service.search({
            url: this.url + '/' + tablename,
            where: params,
            logic: 'and'

        });
        return resp;
    }
    public queryDichVuOrderBy(params: any, tablename: string, order: any = []) {
        // const params = [
        //     ['ClientCode', '=', clientcode]

        // ];
        this.reqService.switchType('sql');
        const resp = this.reqService.service.search({
            url: this.url + '/' + tablename,
            where: params,
            logic: 'and',
            orderBy: order
        });
        return resp;
    }
    // ODATA
    public addDichVu(params: any, table: any, key: any) {
        this.reqService.switchType('sql');
        const odataParams = {
            url: this.url + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    // ODATA
    public updateDichVu(params: any, table: any, key: any) {
        this.reqService.switchType('sql');
        const odataParams = {
            url: this.url + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
    // ODATA
    public deleteDichVu(params: any, table: any, key: any) {
        this.reqService.switchType('sql');
        const odataParams = {
            url: this.url + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }


    public queryTreeServices(params: any) {
        const ex = null;
        const result = this.reqService.service.query({
            url: this.url + '/' + 'Config/GetListConfigService',
            params,
            method: 'GET'
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                success: false,
                model: [],
                message: 'Tìm thất bại'
            };
            if (res) {
                resp.success = true;
                resp.model = res;
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(ex!));
        return result;
    }
    public queryAutoDataDatabase(url: any, method: any, params: any) {
        const ex = null;
        const result = this.reqService.service.query({
            url: url,
            params: params,
            method: method
        }).pipe(map((res: any) => {
            let resp: any = {};
            if (res.length > 0) {
                resp.total = res.length;
                resp.success = true;
                resp.features = res;
                resp.message = 'Query success';
            } else {
                resp.total = res.length;
                resp.success = false;
                resp.features = res;
                resp.message = res.message;
            }
            return resp;
        }), catchError(ex!));
        return result;
    }
    // ham query customize

    public queryCustomize(url: string, method: any, param: any, type: any = 'json') {
        const ex = null;
        const result = this.reqService.service.query({
            url: url,
            params: param,
            method: method,
            responseType: type
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                success: false,
                model: [],
                message: 'Tìm thất bại'
            };
            if (res) {
                resp.success = true;
                resp.model = res;
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(ex!));
        return result;

    }

    getListTable(url: any) {
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const a = localStorage ? localStorage.getItem(this.appService.hostName1 + 'currentUser') : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.get(url, {
            headers
        }).pipe(map((res: any) => {
            const resp: any = {};
            if (res.length > 0) {
                resp.total = res.length;
                resp.success = true;
                resp.model = res;
                // resp.total = resp.length;
                resp.message = 'Query success';
            } else {
                resp.total = res.length;
                resp.success = false;
                resp.model = res;
                // resp.total = resp.length;
                resp.message = 'Query error';
            }
            return resp;
            // return res;
        }));
    }

    getListTableOrcale(url: any) {
        const oldType: any = this.reqService.type;
        this.reqService.switchType('oracle');
        return this.reqService.service.queryCustom({
            url,
            params: {},
            method: 'GET'
        }).pipe(map((res: any) => {
            console.log(res);
            this.reqService.switchType(oldType);
            const resp: any = {};
            if (res.count > 0) {
                resp.total = res.count;
                resp.success = true;
                resp.features = res.items;
                // resp.total = resp.length;
                resp.message = 'Query success';
            } else {
                resp.total = res.count;
                resp.success = false;
                resp.features = res.items;
                // resp.total = resp.length;
                resp.message = 'Query error';
            }
            return resp;
        }));
    }
    getLisFieldTableOracle(url: any) {
        // let headers = new HttpHeaders({
        //     Accept: 'text/plain',
        //     'Content-Type': 'application/json'
        // });

        // const a = localStorage ? localStorage.getItem(this.appService.hostName1 + 'currentUser') : null;
        // const currentUser = a ? JSON.parse(a) : null;
        // if (currentUser && currentUser.token) {
        //     headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        // }

        // return this.http.get(url, {
        //     headers
        // }).pipe(map((res: any) => {
        //     const resp: any = {};
        //     if (res.members.length > 0) {
        //         resp.total = res.members.length;
        //         resp.success = true;
        //         resp.features = res.members;
        //         resp.primaryKey = res.primarykey
        //         // resp.total = resp.length;
        //         resp.message = 'Query success';
        //     } else {
        //         resp.total = res.members.length;
        //         resp.success = false;
        //         resp.features = res.members;
        //         resp.primaryKey = res.primarykey
        //         // resp.total = resp.length;
        //         resp.message = 'Query error';
        //     }
        //     return resp;
        //     // return res;
        // }));
        const oldType: any = this.reqService.type;
        this.reqService.switchType('oracle');
        return this.reqService.service.queryCustom({
            url,
            params: {},
            method: 'GET'
        }).pipe(map((res: any) => {
            console.log(res);
            this.reqService.switchType(oldType);
            const resp: any = {};
           
                if (res.members.length > 0) {
                    resp.total = res.members.length;
                    resp.success = true;
                    resp.features = res.members;
                    resp.primaryKey = res.primarykey
                    // resp.total = resp.length;
                    resp.message = 'Query success';
                } else {
                    resp.total = res.members.length;
                    resp.success = false;
                    resp.features = res.members;
                    resp.primaryKey = res.primarykey
                    // resp.total = resp.length;
                    resp.message = 'Query error';
                }
                return resp;
        }));
    }

    // public querySysMaps(params: any) {
    //     const resp = this.reqService.service.search({
    //         url: this.url + '/SysMaps',
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }


    // query services tableid
    public getTableByServiceTable(TableName: string, serviceId: number) {
        const pTable = CoreTechTable.SysTables;
        const params = [
            ['ServiceId', '=', serviceId],
            ['TableName', '=', TableName],
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + pTable,
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public getTableByService(serviceId: number) {

        const pTable = CoreTechTable.SysTables;
        const params = [
            ['ServiceId', '=', serviceId]
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + pTable,
            where: params,
            logic: 'and',
            orderBy: ['TableDetail']
        });
        return resp;
    }

    // Layer

    //hiencn
    public getService(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + `/` + 'SysServices',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    //

    public getLayerById(layerId: number) {
        const params = [
            ['layerId', '=', layerId]

        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + CoreTechTable.SysLayers,
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public getLayerByApp(appid: number) {
        const params = [
            ['applicationId', '=', appid]
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + CoreTechTable.SysLayers,
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public getWindowByApp(appid: number) {
        const params = [
            ['applicationId', '=', appid]
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + CoreTechTable.SysWindows,
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public getLayerByServiceApplication(serviceId: number, appId: number) {
        const params = [
            ['ServiceId', '=', serviceId],
            ['ApplicationId', '=', appId],
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + CoreTechTable.SysLayers,
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public getLayerByService(serviceId: number) {
        const params = [
            ['ServiceId', '=', serviceId]
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + CoreTechTable.SysLayers,
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // get services by app
    public getTableByApp(appid: number) {
        const params = [
            ['applicationId', '=', appid],
            ['or', ['ServiceType', '=', 'MapServer'], ['ServiceType', '=', 'WEBMAP'], ['ServiceType', '=', 'Web Scene'], ['ServiceType', '=', 'SceneServer']],
            ['TableDetail', '<>', 'Table']
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/svtablebyapplications`,
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public updateLayers1(key: any, params: any) {
        const odataParams = {
            url: this.url + '/' + CoreTechTable.SysLayers,
            primaryKey: 'LayerId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
    // public updateLayers(key: any, params: any) {
    //     const odataParams = {
    //         url: this.url + '/' + CoreTechTable.SysLayers,
    //         primaryKey: 'layerId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }

    public UpdateLinkDoHoa(obj: any) {
        return this.reqService.service.query({
            url: this.appService.urlWS + '/SysTables/UpdateLinkDoHoa',
            params: obj,
            method: 'POST'
        });
    }
    public UpdateColumnDoHoa(obj: any) {
        return this.reqService.service.query({
            url: this.appService.urlWS + '/SysTables/UpdateColumnDoHoa',
            params: obj,
            method: 'POST'
        });
    }
    public updateLayersWizard(params: any) {
        const odataParams = {
            url: this.url + '/' + CoreTechTable.SysLayers,
            primaryKey: 'LayerId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
    public addLayers(params: any) {
        const odataParams = {
            url: this.url + '/' + CoreTechTable.SysLayers,
            primaryKey: 'layerId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    public deleteLayers(params: any) {
        const odataParams = {
            url: this.url + '/' + CoreTechTable.SysLayers,
            primaryKey: 'layerId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }
    public dataSeclect(): any {
        return [
            { CODE: 'Y', DESCR: 'Có' },
            { CODE: 'N', DESCR: 'Không' },
        ];
    }
    public dataSelectService(): any {
        return [
            { CODE: 'Y', DESCR: 'Có' },
            { CODE: 'N', DESCR: 'Không' },
        ];
    }
    public dataSelectHascache(): any {
        return [
            { CODE: 'N', DESCR: 'Không' },
            { CODE: 'Y', DESCR: 'Image TileCache' },
            { CODE: 'V', DESCR: 'Vector TileCache' },
            { CODE: 'S', DESCR: 'Scene Service' }
        ];
    }
    public dataTblDetail(): any {
        return [
            {
                CODE: 'Table',
                DESCR: 'Table',
            },
            {
                CODE: 'View',
                DESCR: 'View',
            },
            {
                CODE: 'Point',
                DESCR: 'Point',
            },
            {
                CODE: 'Polyline',
                DESCR: 'Polyline',
            },
            {
                CODE: 'Polygon',
                DESCR: 'Polygon',
            },
            {
                CODE: 'esriGeometryMultiPatch',
                DESCR: 'esriGeometryMultiPatch',
            }
        ];
    }
    public dataSelectRelationShip(): any {
        return [
            {
                CODE: 'N',
                DESCR: 'Không',
            },
            {
                CODE: 'Y',
                DESCR: 'Bảng liên kết',
            },
            {
                CODE: 'D',
                DESCR: 'Domain',
            },
        ];
    }
    public dataComponent(): any {
        return [
            {
                CODE: 'AddWorkflowComponent',
                DESCR: 'Add Arcgis Workflow'
            },
            {
                CODE: 'AddBPMNComponent',
                DESCR: 'Add Workflow Layout'
            },
            {
                CODE: 'ArcgisWorkflowComponent',
                DESCR: 'ArcgisWorkflow Layout'
            },
            {
                CODE: 'WorkflowComponent',
                DESCR: 'Workflow Layout'
            },
            {
                CODE: 'ConfigWindowComponent',
                DESCR: 'Form Layout',
            },
            {
                CODE: 'CoreSearchComponent',
                DESCR: 'Search Layout',
            },
            {
                CODE: 'TrainingPriceCalculationComponent',
                DESCR: 'Training Layout',
            },
            {
                CODE: 'ProcessQuotationComponent',
                DESCR: 'Change Status Quotation Layout',
            },
            {
                CODE: 'ReportRenderToApplicationComponent',
                DESCR: 'ReportRenderToApplicationComponent',
            },
            {
                CODE: 'ViewerGisComponent',
                DESCR: 'ViewerGisComponent',
            }
        ];
    }
    public dataComponentOnChange(): any {
        return [
            // {
            //     CODE: 'AddWorkflowComponent',
            //     DESCR: 'Form Add Arcgis Workflow'
            // },
            {
                CODE: 'AddBPMNComponent',
                DESCR: 'Form Add Workflow'
            }
        ];
    }
    public dataSelectTypeColumn(): any {
        return [
            {
                CODE: 'number',
                DESCR: 'Number',
            },
            {
                CODE: 'text',
                DESCR: 'String',
            },
            {
                CODE: 'date',
                DESCR: 'Date',
            },
            // {
            //     CODE: 'select',
            //     DESCR: 'ComboBox',
            // },
            // {
            //     CODE: 'G',
            //     DESCR: 'Geometry',
            // },
            // {
            //     CODE: 'B',
            //     DESCR: 'Boolean',
            // },
            // {
            //     CODE: 'search',
            //     DESCR: 'Search',
            // },
            // {
            //     CODE: 'checkbox',
            //     DESCR: 'CheckBox',
            // },
            {
                CODE: 'img64',
                DESCR: 'img64',
            },
            {
                CODE: 'textarea',
                DESCR: 'TextArea',
            }
            , {
                CODE: 'timestamp',
                DESCR: 'timestamp',
            }, {
                CODE: 'boolean',
                DESCR: 'Boolean',
            }
        ];
    }
    public dataSelectTypeServices1(): any {
        return [
            {
                CODE: 'MapServer',
                DESCR: 'MapServer',
            },
            {
                CODE: 'FeatureServer',
                DESCR: 'FeatureServer',
            },
            {
                CODE: 'WEBMAP',
                DESCR: 'WEBMAP'
            }
            ,
            {
                CODE: 'Web Scene',
                DESCR: 'Web Scene'
            }
            ,
            {
                CODE: 'SceneServer',
                DESCR: 'SceneServer'
            }
        ];
    }
    public dataSelectTypeServices(): any {
        return [
            {
                CODE: 'MapServer',
                DESCR: 'MapServer',
            },
            {
                CODE: 'FeatureServer',
                DESCR: 'FeatureServer',
            },
            // {
            //     CODE: 'WorkflowServices',
            //     DESCR: 'WorkflowServices',
            // },
            {
                CODE: 'SQL',
                DESCR: 'SQL',
            },
            {
                CODE: 'oracle',
                DESCR: 'Oracle'
            }
            ,
            {
                CODE: 'CloudData',
                DESCR: 'CloudData'
            }
            ,
            {
                CODE: 'WEBMAP',
                DESCR: 'WEBMAP'
            }
            ,
            {
                CODE: 'Web Scene',
                DESCR: 'Web Scene'
            }
            ,
            {
                CODE: 'SceneServer',
                DESCR: 'SceneServer'
            }
        ];
    }
    public createFormService(): FormGroup {
        const formGroup = new FormGroup({
            ServiceId: new FormControl(null),
            ServiceName: new FormControl(null, Validators.required),
            UrlView: new FormControl(null),
            UrlEdit: new FormControl(null),
            UrlVectorTile: new FormControl(null),
            ServiceType: new FormControl(null),
            IsVisible: new FormControl(null),
            IsIdentify: new FormControl(null),
            OrderNo: new FormControl(null),
            ClientId: new FormControl(null, Validators.required),
            SpatialReference: new FormControl(null),
            HasTileCache: new FormControl(null),
            UrlToken: new FormControl(null),
            UserNamePortal: new FormControl(null),
            PassWordPortal: new FormControl(null),
            UrlThumbnail: new FormControl(null),
            SchemaName: new FormControl(null),
            UserSchema: new FormControl(null),
            PasswordSchema: new FormControl(null),
            SceneLayerType: new FormControl(null)
        });
        return formGroup;
    }
    public createFormSysMap(): FormGroup {
        const formGroup = new FormGroup({
            MapId: new FormControl(null),
            ServiceId: new FormControl(null),
            MapName: new FormControl(null, Validators.required),
            UrlView: new FormControl(null),
            UrlEdit: new FormControl(null),
            UrlVectorTile: new FormControl(null),
            MapType: new FormControl(null),
            IsVisible: new FormControl(null),
            IsIdentify: new FormControl(null),
            IsDisplay: new FormControl(null),
            ClientId: new FormControl(null),
            OptionName: new FormControl(null),
            HasTileCache: new FormControl(null),
            ApplicationId: new FormControl(null),
            SceneLayerType: new FormControl(null)
        });
        return formGroup;
    }
    public createFormLayer(): FormGroup {
        const formGroup = new FormGroup({
            LayerId: new FormControl(null),
            LayerName: new FormControl(null, Validators.required),
            Alias: new FormControl(null),
            IsIdentify: new FormControl(null),
            LayerIndex: new FormControl(null),
            OrderNo: new FormControl(null),
            IsAddMap: new FormControl(null),
            Opacity: new FormControl(null),
            IsAddToc: new FormControl(null),
            IsVisible: new FormControl(null),
            WindowId: new FormControl(null),
            IsEditLayer: new FormControl(null),
            ConfigIdentify: new FormControl(null),
            TableId: new FormControl(null),
            IsSelect: new FormControl(null)
        });
        return formGroup;
    }
    public createFormTable(): FormGroup {
        const formGroup = new FormGroup({
            TableId: new FormControl(null),
            TableName: new FormControl(null, Validators.required),
            TableType: new FormControl(null),
            Alias: new FormControl(null, Validators.required),
            ColumnKey: new FormControl(null),
            ApplicationId: new FormControl(null),
            HasAttachment: new FormControl(),
            TableLienKet: new FormControl(null),
            IsCache: new FormControl(),
            LayerIndex: new FormControl(null),
            LayerId: new FormControl(null),
            ServiceId: new FormControl(null),
            ClientId: new FormControl(null),
            KvhcColumn: new FormControl(null),
            TableDetail: new FormControl(null),
            UrlEdit: new FormControl(null),
            UrlView: new FormControl(null),
            ColumnDisplay: new FormControl(null),
            ColumnCode: new FormControl(null),
            OnChange: new FormControl(null),
            TableWorkFlowId: new FormControl(null),
            JobTypeIds: new FormControl(null),
            TreeColumn: new FormControl(null),
            ColumnWorkflow: new FormControl(null),
            ColumnLinkLayer: new FormControl(null),
            ColumnLock: new FormControl(null),
            LogType: new FormControl()
        });
        return formGroup;
    }
    public createFormColumn(): FormGroup {
        const formGroup = new FormGroup({
            ColumnId: new FormControl(null),
            ColumnName: new FormControl(null, Validators.required),
            Alias: new FormControl(null),
            ColumnType: new FormControl(null),
            Length: new FormControl(null, [
                Validators.pattern('^[0-9]+$'),
            ]),
            OrderNo: new FormControl(null, [
                Validators.pattern('^[0-9]+$'),
            ]),
            IsNotNull: new FormControl(null),
            IsPriKey: new FormControl(null),
            ForeignTableId: new FormControl(null),
            IsFromDomain: new FormControl(null),
            IsLienKetDoHoa: new FormControl(null),
            ColumnDoHoa: new FormControl(null),
            DomainId: new FormControl(null),
            ColumnParent: new FormControl(null),

        });
        return formGroup;
    }

    // khoanb  liên quan tới services Arcgis
    // public getTableFromMapServices(url:any,method:any,params: any) {
    //     this.reqService.switchType('arcgis3x');
    //     return this.reqService.service.query({
    //         url,
    //         method:method,
    //         params:params
    //     }).pipe(map(res => {
    //         this.reqService.switchType(this.appService.dataAccessType);
    //         return res;
    //     }));
    // }


    public getDataFromMapServices(url: any, method: any, params: any) {
        this.reqService.switchType('arcgis3x');
        return this.reqService.service.queryCustom({
            url,
            params: params,
            method: method,
            responseType: 'json'

            //  proxy:'false'
        }).pipe(map(res => {
            this.reqService.switchType(this.appService.dataAccessType);
            return res;
        }));

    }
    public getDataOrgId(url: any, method: any, params: any) {
        this.reqService.switchType('arcgis3x');
        return this.reqService.service.queryCustom({
            url,
            params: params,
            method: method,
            responseType:'json'
              
          //  proxy:'false'
        }).pipe(map(res => {
            this.reqService.switchType(this.appService.dataAccessType);
            return res;
        }));

    }
    // query baos cao   
    public getdulieubaocao(url: any, typeservice: any) {
        if (typeservice === 'SQL') {
            this.reqService.switchType('sql');
        }
        else {
            this.reqService.switchType('arcgis3x');
        }

        return this.reqService.service.search({
            url,
            where: []
        }).pipe(map(res => {
            this.reqService.switchType(this.appService.dataAccessType);
            return res;
        }));
    }

    // wf
    cloudgetAppByUsername(params: any) {
        return this.reqService.service.query({
            url: this.appService.urlWS + '/Login/cloudgetAppByUsername',
            params,
        }).pipe(map(res => {
            return res;
        }));
    }

    public queryJobtype(url: any, params: any) {
        const resp = this.reqService.service.search({
            url: url + '/WfJobTypes',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public queryUserBpmn(url: any, params: any) {
        const resp = this.reqService.service.search({
            url: url + '/WfUsers',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addUserBpmn(url: any, params: any) {
        const resp = this.reqService.service.insert({
            url: url + '/WfUsers',
            data: params
        });
        return resp;
    }

    public deleteUserBpmn(url: any, params: any) {
        const odataParams = {
            url: url + '/WfUsers',
            primaryKey: 'Username',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }


    esriRequest(
        url: string,
        params: any = {},
        usePost: boolean = false,
        useProxy: boolean = false
    ): Observable<any> {
        const subject: Subject<any> = new Subject();

        loadModules(['esri/request', 'esri/config']).then(
            ([request, esriConfig]) => {
                const reqData = {
                    url: url,
                    content: params,
                    handleAs: 'json',
                    sync: true,
                    callbackParamName: 'callback',
                };
                request(reqData, { usePost: usePost, useProxy: useProxy }).then(
                    (response: any) => subject.next(response),
                    (error: any) => subject.next(error)
                );
            }
        );
        return subject.asObservable();
    }


    getColumns(url: any) {
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.get(url, {
            headers
        }).pipe(map((res: any) => {
            const resp: any = {};
            if (res.length > 0) {
                resp.total = res.length;
                resp.success = true;
                resp.model = res;
                resp.message = 'Query success';
            } else {
                resp.total = res.length;
                resp.success = false;
                resp.model = res;
                resp.message = 'Query error';
            }
            return resp;
            // return res;
        }));
    }
}
