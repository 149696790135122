import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-core-state-checkbox',
  templateUrl: './core-state-checkbox.component.html',
  styleUrls: ['./core-state-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR
      , useExisting: forwardRef(() => CoreStateCheckboxComponent)
      , multi: true
    }
  ]
})
export class CoreStateCheckboxComponent implements OnInit, AfterContentChecked {
  ctrl: FormControl = new FormControl();
  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };
  // @ViewChild('chk') chk!: Checkbox | any;
  @Output() valueModelChange = new EventEmitter();
  @Output() chkChange = new EventEmitter();
  @Output() blurEvent: EventEmitter<any> = new EventEmitter();
  @Input() hasFormCtrl: boolean = false;
  @Input() chkLabel: any = '';
  @Input() isDisabled: boolean = false;
  @Input() checkboxTrueIcon: any = 'pi pi-check';
  @Input() checkboxFalseIcon: any = 'pi pi-times';
  @Input() typeValue: 'boolean' | 'string' | 'number' = 'boolean';
  @Input() labelLeftOut: boolean = false;
  @Input() hasButton: boolean = false
  @Input() isCoreForm: boolean = false
  @Input() notUseTranslate = false;
  @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
  _valueModel: any = false;
  @Input()
  get valueModel() {
    return this._valueModel;
  }

  set valueModel(value) {
    this.cdr.detectChanges();
    const val = value === true || value === 'Y' || value === 1 ? true : value === false || value === 'N' || value === 0 ? false : null
    this._valueModel = val;
    // this.valueModelChange.emit(this._valueModel);
  }
  private _isReadOnly = false;
  @Input('isReadOnly')
  set isReadOnly(val: boolean) {
    this._isReadOnly = val;
    if (this.ctrl) {
      // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
    }
  }
  get isReadOnly() {
    return this._isReadOnly;
  }
  constructor(
    private cdr: ChangeDetectorRef
  ) {
    this.initForm()
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  initForm() {
    this.ctrl = new FormControl(null, {
      updateOn: 'change'
    });
    this.isReadOnly ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });

    this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
      let value: any = null
      if (this.typeValue === 'string') {
        value = val === true || val === 'Y' || val === 1 ? 'Y' : val === false || val === 'N' || val === 0 ? 'N' : null
      } else if (this.typeValue === 'number') {
        value = val === true || val === 'Y' || val === 1 ? 1 : val === false || val === 'N' || val === 0 ? 0 : null
      } else {
        value = val
      }
      this.onChange(value)
      // this.chkChange.emit(value)
    })
  }
  onChkChange(evt: any) {
    const val = evt.value
    let value: any = null
    if (this.typeValue === 'string') {
      value = val === true || val === 'Y' || val === 1 ? 'Y' : val === false || val === 'N' || val === 0 ? 'N' : null
    } else if (this.typeValue === 'number') {
      value = val === true || val === 'Y' || val === 1 ? 1 : val === false || val === 'N' || val === 0 ? 0 : null
    } else {
      value = val
    }
    this.chkChange.emit(value)
    this._valueModel = value
    this.valueModelChange.emit(this._valueModel);
    
  }
  writeValue(_value: any): void {
    this.cdr.detectChanges()
    const val = _value === true || _value === 'Y' || _value === 1 ? true : _value === false || _value === 'N' || _value === 0 ? false : null
    if (this.hasFormCtrl) {
      this.ctrl.setValue(val)
    }

  }
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
}
