import * as esriLoader from 'esri-loader';


export class DefaultSymbol {
    // Symbol cho các điểm, đường, vùng.
    point: any;
    polyline: any;
    polygon: any;
    polygonT: any;
    circle: any;
    rectangle: any;
    currentLocation: any;

    // Vùng được identify
    polygonIdentify: any;

    // Dùng cho highlight geometry edit
    pointHighlight: any;
    polylineHighlight: any;
    polygonHighlight: any;

    constructor() {
        esriLoader.loadModules([
            'esri/symbols/PictureMarkerSymbol',
            'esri/symbols/SimpleMarkerSymbol',
            'esri/symbols/SimpleLineSymbol',
            'esri/symbols/SimpleFillSymbol',
            'esri/symbols/TextSymbol',
            'esri/symbols/Font'
        ]).then(([
            PictureMarkerSymbol,
            SimpleMarkerSymbol,
            SimpleLineSymbol,
            SimpleFillSymbol,
            TextSymbol,
            Font
        ]) => {
            this.point = new SimpleMarkerSymbol({
                type: 'esriSMS',
                style: 'esriSMSCircle',
                color: [255, 255, 255],
                size: 12,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [0, 255, 255],
                    width: 3
                }
            });

            this.polyline = new SimpleLineSymbol({
                type: 'esriSLS',
                style: 'esriSLSSolid',
                color: [0, 255, 255],
                width: 5
            });

            this.polygon = new SimpleFillSymbol({
                type: 'esriSFS',
                style: 'esriSFSSolid',
                color: [0, 0, 0, 50],
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [255, 0, 0, 255],
                    width: 2
                }
            });

            this.pointHighlight = new SimpleMarkerSymbol({
                type: 'esriSMS',
                style: 'esriSMSCircle',
                color: [255, 255, 255],
                size: 12,
                angle: 0,
                xoffset: 0,
                yoffset: 0,
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [0, 255, 255],
                    width: 3
                }
            });

            this.polylineHighlight = new SimpleLineSymbol({
                type: 'esriSLS',
                style: 'esriSLSSolid',
                color: [0, 255, 255],
                width: 7
            });

            this.polygonHighlight = new SimpleFillSymbol({
                type: 'esriSFS',
                style: 'esriSFSNull',
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [0, 255, 255],
                    width: 2
                }
            });

            this.polygonT = new SimpleFillSymbol({
                type: 'esriSFS',
                style: 'esriSFSSolid',
                color: [0, 0, 0, 0],
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [255, 0, 0, 255],
                    width: 2
                }
            });

            this.polygonIdentify = new SimpleFillSymbol({
                type: 'esriSFS',
                style: 'esriSFSSolid',
                color: [0, 0, 0, 50],
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [0, 255, 0, 255],
                    width: 2
                }
            });

            this.circle = new SimpleFillSymbol({
                type: 'esriSFS',
                style: 'esriSFSSolid',
                color: [0, 0, 0, 50],
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [255, 0, 0, 255],
                    width: 2
                }
            });

            this.rectangle = new SimpleFillSymbol({
                type: 'esriSFS',
                style: 'esriSFSSolid',
                color: [0, 0, 0, 50],
                outline: {
                    type: 'esriSLS',
                    style: 'esriSLSSolid',
                    color: [255, 0, 0, 255],
                    width: 2
                }
            });

            this.currentLocation = new PictureMarkerSymbol({
                url: 'assets/imgs/basic-tool/web_pin_current.png',
                height: 24,
                width: 24,
                type: 'esriPMS',
                xoffset: 0,
                yoffset: 12
            });
        });
    }
}
