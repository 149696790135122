import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { RequestService} from 'app/core/services/request.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tao-hao-mon',
  templateUrl: './tao-hao-mon.component.html',
  styleUrls: ['./tao-hao-mon.component.scss'],
  providers: [RequestService]
})
export class TaoHaoMonComponent implements OnInit {
  MaTaiSan: any = null;
  DataThongTinKhauHao: any[] = [];
  KC1 : number = 0;
  KC2 : number = 0;
  constructor(
    public reqService: RequestService,
    public appService: AppService,
    private resolver: ComponentFactoryResolver,
    public translate: TranslateService,
  ) { 
    reqService.switchType('clouddata');
  }

  ngOnInit(): void {
  }

  run (data: any, isReload: any) {
    console.log("Thêm hao mòn",'data', data, 'isReload', isReload);
    this.DataThongTinKhauHao = [];
    if (data.currentData.length !== 0){ 
      this.MaTaiSan = data.currentData[0].maTaiSan;
      const param = ['maTaiSan', '=', this.MaTaiSan];
      this.reqService.service.search(
        {
        url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/TS_ThongTinKhauHao',
        where: param
        }
      ).subscribe(res => {  
        console.log('res', res);
        if (res.success) { 
          if (res.features.length > 0) {  
            res.features.forEach(evt => { 
              // this.DataThongTinKhauHao.push({ 
              //   HeSoDieuChinh: evt.HeSoDieuChinh,
              //   KhoiLuongSanPhamHoanThanh: evt.KhoiLuongSanPhamHoanThanh,
              //   NamThu: evt.NamThu,
              //   SoLuongTheoCongSuat: evt.SoLuongTheoCongSuat,
              //   Thang: evt.Thang,
              //   ThangHaoMonTT:evt.ThangHaoMonTT,
              //   batDauKhauHao: evt.batDauKhauHao,
              //   conLai: evt.conLai,
              //   hangThangKhauHao: evt.hangThangKhauHao,
              //   haoMon: evt.haoMon,
              //   ketThucKhauHao: evt.ketThucKhauHao,
              //   maKhauHao: evt.maKhauHao,
              //   maPhuongPhapKhauHao: evt.maPhuongPhapKhauHao,
              //   nguyenGia: evt.nguyenGia,
              //   soThangKhauHao: evt.soThangKhauHao,
              //   tyLeKhauHao: evt.tyLeKhauHao,
              // })
              if(evt.batDauKhauHao !== null && evt.ketThucKhauHao!== null && evt.maPhuongPhapKhauHao === 1){
                this.GetKhauHao(evt);
              }
            })
            
          } else {
            this.appService.alert('Không có Thông tin khấu hao !!!');
          }
        } else {
          this.appService.alert('Something went wrong!!!', 'error');
        }
      }, err => {
        this.appService.alert('ERROR !!!', 'error');
        console.log("err", err);
      });
    } else {
        this.appService.alert('ERROR !!!', 'error');
    }

  }

  GetKhauHao (evt :any){
    // console.log('evt', evt);
    const NewDatebatDauKhauHao = new Date(evt.batDauKhauHao);
    const D1 = NewDatebatDauKhauHao.getDate();
    const M1 = NewDatebatDauKhauHao.getMonth()+1;
    const Y1 = NewDatebatDauKhauHao.getFullYear();
    const NewDateketThucKhauHao = new Date(evt.ketThucKhauHao);
    const D2 = NewDateketThucKhauHao.getDate();
    const M2 = NewDateketThucKhauHao.getMonth() +1;
    const Y2 = NewDateketThucKhauHao.getFullYear();
    const ThangHaoMonTT = this.TinhKhoangCach(D1, M1,Y1,D2, M2,Y2);
    //console.log ('ThangHaoMonTT', ThangHaoMonTT);

    const data: any = { 
      maKhauHao: evt.maKhauHao,
      ThangHaoMonTT: ThangHaoMonTT,
    }
    
    this.reqService.service.update({
      url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/TS_ThongTinKhauHao',
      data,
      primaryKey: 'maKhauHao'
    }).subscribe((resp: any) => {
      if (resp.success) {
        this.appService.createMessage('success', 'Update infomation success')
      } else {
        this.appService.createMessage('error', 'Update infomation error')
      }

    }, err => {
      this.appService.createMessage('error', 'error')
    })

  }

  TinhKhoangCach(D1: number, M1: number,Y1: number,D2: number, M2: number,Y2: number) {
   // console.log("batDauKhauHao:", D1, M1,Y1, "ketThucKhauHao:", D2, M2,Y2)
    this.KC1 = this.TinhSoNgay(D1, M1,Y1);
    this.KC2 = this.TinhSoNgay(D2, M2,Y2);
    const SoNgay = Math.abs(this.KC2 - this.KC1);
    //console.log ('SoNgay', SoNgay);
    return Math.abs(SoNgay/30)
  }

  TinhSoNgay (D: any, M: any,Y: any) { 
    let KC : number = 0;
    for (var i=1;i<Y; i++ ){
      if(this.LaNamNhuan(i)===true) {
        KC = KC +366;
      } else {
        KC = KC +365;
      }
    }
    KC = KC + this.TinhSoNgayTuDauNam(D,M,Y);
    return KC;
  }

  LaNamNhuan (i :number){
    if((i%4===0 && i%100 !==0) || (i%4===0)){
      return true;
    }else {
      return false;
    }
  }

  TinhSoNgayTuDauNam (D: number, M: number,Y: number) {  
    let SoNgay = D;
    for (let i=1;i<M; i++ ){ 
      SoNgay = SoNgay + this.TinhSoNgayTrongThang(i,Y);
    }
    return SoNgay;
  }

  TinhSoNgayTrongThang (M: number, Y: number) {  
   if (M === 2){
    if(this.LaNamNhuan(Y)){
      return 29;
    } else {
      return 28
    }
   } else {
    if (M === 2 || M === 6||M === 9||M === 11) {
      return 30
   
    } else{
      return 31
    }
  }

  }

}
