import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef, EventEmitter, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';
// import BpmnModeler from 'bpmn-js/lib/Modeler';
// import BpmnViewer from 'bpmn-js/lib/Viewer';
import * as converter from 'xml-js';
import { ColumManager, columReportWorking, columReportAssign, columTableJob } from './colum';
import { combineLatest } from 'rxjs';
import { CONFIG_FIELD_HISTORY } from 'app/workflow/config/config-field';
import { RunBPMNComponent } from '../menu-tool/run-job/run-bpmn-workflow.component';
import { APP_CONFIG } from 'app/app-base/mockup-data/app-config';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from 'app/core/services/request.service';
import { AuthService } from 'app/app-base/auth.service';
import { HttpClient } from '@angular/common/http';
import { parse } from 'zipson/lib';
import { Information } from 'app/core/core-window';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';
import { TranslateService } from '@ngx-translate/core';
declare var require: any
let BpmnViewer = require('bpmn-js/lib/Viewer');
@Component({
    selector: 'app-workflow-manager',
    templateUrl: './workflow-manager.component.html',
    styleUrls: ['./workflow-manager.component.scss']
})
export class WorkflowManagerComponent implements OnInit {
    @ViewChild('divWorkflow') private divWorkflow!: ElementRef;
    @ViewChild('dialogPrime', { static: true }) dialogPrime!: DialogPrimeComponent;
    @ViewChild('TemplateInfoJob') TemplateInfoJob!: TemplateRef<any>;
    jobType = new FormControl();
    CbassignTo = new FormControl();
    CbJobStatus = new FormControl();
    textNameJob = new FormControl();
    jobTypeReport = new FormControl();
    searchTime = new FormControl();
    dataJobType: any = [];
    lookUpDataJobType: any = [];
    timeUnit = null;
    arrJob: any = [];
    dataUser: any = [];
    dataStatus: any = [
        { CODE: 1, DESCR: 'Init' },
        { CODE: 2, DESCR: 'Running' },
        { CODE: 3, DESCR: 'Finish' },
    ];
    dataPriority: any = [
        { CODE: 1, DESCR: 'Low' },
        { CODE: 2, DESCR: 'Medium' },
        { CODE: 3, DESCR: 'Hight' },
    ];
    dataAssignedStatus: any = [
        { CODE: 1, DESCR: 'Get job' },
        { CODE: 2, DESCR: 'Back job' },
        { CODE: 3, DESCR: 'Forward' },
    ];
    arrFieldHide: any = ['RecordId', 'TableId', 'WindowId', 'JobId', 'ClientId', 'ApplicationId', 'UpdateTime'];
    arrTypeActivityHis: any = { 1: 'Chấp nhận', 2: 'Gửi đi', 3: 'Trả lại', 4: 'Chuyển tiếp', 5: 'Hoàn thành' };
    arrTab: any = [{
        code: 'manager',
        title: 'Manager'
    }, {
        code: 'report',
        title: 'Report'
    }];
    arrTab_child: any = [{
        code: 'diagram',
        title: 'Diagram'
    }, {
        code: 'detail',
        title: 'Detail'
    }, {
        code: 'job',
        title: 'job'
    }, {
        code: 'history',
        title: 'History'
    }
    ];

    // bpmnJS: BpmnModeler;
    bpmnJS: any;
    currentStep: any = null;
    arrStep: any[] = [];
    diagram: any = null;
    AssigTo: any = null;
    public divCurentStep = '';
    tableRowDefination: any = [];
    tableRowDefinationTimeWK: any = [];
    tableRowDefinationAssign: any = [];
    tableData: any = [];
    tableDataJob: any = [];
    tableRowDefinationJob: any = [];
    tableDataAssign: any = [];
    pageSize = 10;
    ColumManager: any = ColumManager;
    columReportTime: any = columReportWorking;
    columAssign: any = columReportAssign;
    columnTableJob: any = columTableJob;
    lookupCondition: any = {};
    totalJob = 0;
    configColumHis: any = CONFIG_FIELD_HISTORY;
    currentData: any = null;
    fieldList: any = [];
    tableTimeWorking: any = [];
    currentComp: any = null;
    indexTap = 0;
    windowId = null;
    whereWf: any = null;
    public formData: FormGroup = new FormGroup({});
    public nzSpan = 11;
    userid: any;
    applicationId: any;
    clientId: any;
    // report
    totalJobReport = 0;
    totalJobWorking = 0;
    totalJobDone = 0;
    totalJobInit = 0;
    totalWorkflow = 0;
    totalMember = 0;
    total_job_count = 0;
    total_hour_count: any;
    tableConfig = {
        scrollHeight: "auto",
        responsiveLayout: 'scroll',
        styleClass: "p-datatable-gridlines",
        paginator: true,
        rows: 15
    }
    arrItemDashboard = [{
        title: 'Job',
        total: 12
    },
    {
        title: 'Job',
        total: 12
    },
    ];
    lookupTab: any = {};
    dataSoure: any;
    columnKey: any = null;


    constructor(
        private appService: AppService,
        private workflowService: BpmnWorkflowService,
        private cd: ChangeDetectorRef,
        private router: ActivatedRoute,
        private reqService: RequestService,
        private authService: AuthService,
        private http: HttpClient,
        public translate: TranslateService,

    ) { }

    ngOnInit(): void {
        const user = localStorage.getItem(this.appService.hostName1 + 'currentUser');
        user ? this.userid = JSON.parse(user).userid : '';
        this.applicationId = Number(this.appService.objStoreAppId.appId);
        // const client = localStorage.getItem(this.appService.hostName1 + 'currentUser');
        this.clientId = this.appService.ClientId;
        const params: any = this.router.snapshot.paramMap;
        const applicationId = eval(params['params']['applicationId']);
        const clientId = eval(params['params']['clientId']);
        const username = params['params']['username'];
        const password = eval(params['params']['password']);
        const appid = eval(params['params']['applicationId']);
        if (applicationId && clientId && appid) {
            this.loginMobile({ username, password, appid });
            this.applicationId = applicationId;
            this.clientId = clientId;
        }



        this.getJobType();
        this.getUserWf();
        this.lookupCondition['JobStatusId'] = this.dataStatus;
        this.lookupCondition['AssignedTo'] = [];
        this.lookupCondition['JobTypeId'] = [];
        this.lookupCondition['AssignStatusId'] = this.dataAssignedStatus;
        this.lookupCondition['PriorityId'] = this.dataPriority;

        this.jobType.valueChanges.subscribe(item => {
            this.getJobByJobType();
        });

        this.CbassignTo.valueChanges.subscribe(item => {
            this.getJobByJobType();
        });

        this.CbJobStatus.valueChanges.subscribe(item => {
            this.getJobByJobType();
        });


        // report
        this.jobTypeReport.valueChanges.subscribe(item => {
            const idJobType = item !== null ? typeof (item) === 'object' ? item.CODE : item : item;
            if (idJobType !== null) {
                const f = this.dataJobType.filter((fill: any) => fill.JobTypeId === idJobType);
                if (f[0]) {
                    this.timeUnit = f[0].TimeUnit;
                }
            }

            this.initTableTimeReport(idJobType, null, null);
            // this.initTableAssign(idJobType);
        });
        this.queryDashboard();

    }
    private loginMobile(params: any) {
        const data = {
            USERNAME: params.username,
            PASSWORD: params.password,
        };
        this.authService.login(data).subscribe((response1: any) => {
            if (response1.success === true) {
                const _params = {
                    appid: params.appid,
                    userid: response1.model.token.userid,
                };
                this.reqService.service.query({
                    url: this.appService.urlWS + '/Config/Init',
                    params: _params
                }).subscribe((response: any) => {
                    this.appService.appConfig = {
                        Systems: APP_CONFIG.Systems,
                        Menus: response.dsMenu,
                        FunctionConfigs: response.functionConfig,
                        Services: response.services,
                        Layers: response.layers,
                        Applications:
                            this.appService.application == null
                                ? null
                                : this.appService.application,

                        FunctionPermistion: response.functionPermission,
                        PermissionEditorTool: response.permissionEditorTool,
                        DSKVHC: response.dskvhc,
                        wherekvhc: response.wherekvhc,
                        sys_combo: response.sys_combo,
                        sys_config: response.sys_config,
                        objTheme: response.objTheme,
                        listCalendar: response.listCalendar
                    };
                    if (!this.appService.c$) {
                        this.appService.c$ = {};
                        this.appService.c$.dateFormat = 'dd/mm/yy';
                    }
                    this.appService.c$.sys_combo = response.sys_combo;
                    this.appService.c$.sys_config = response.sys_config;
                    this.appService.c$.kvhc = response.sys_combo['-1'] ? JSON.parse(response.sys_combo['-1']) : [];
                    // Lấy locaStorage ở đây

                    // this.initMobileHandle.emit();
                });
            } else {
                let msg = '';
                if (!response1.success) {
                    msg = 'Sai tài khoản, mật khẩu';
                }
                this.appService.alert(msg, 'error');
            }
        }, (err: any) => {
        });
    }

    getCurentStep() {
        const param = ['JobId', '=', this.currentData.JobId];
        this.divCurentStep = '';
        this.workflowService.queryJobStep(param).subscribe(res => {
            if (res.success) {
                if (res.features.length > 0) {
                    this.currentStep = res.features[0].CurStep;
                    const idJobType = this.jobType.value !== null ? typeof (this.jobType.value) === 'object' ? this.jobType.value.CODE : this.jobType.value : this.jobType.value;
                    const paramTypeStep = ['JobTypeId', '=', idJobType];
                    this.workflowService.queryJobtypeStep(paramTypeStep).subscribe(res => {
                        if (res.success) {
                            if (res.features.length > 0) {
                                this.arrStep = res.features;
                                this.arrStep.forEach((item: any) => {
                                    if (item.StepId === this.currentStep) {
                                        item.isActive = 'running';
                                        return;
                                    }
                                    else {
                                        item.isActive = 'notComplete';
                                    }
                                });
                                this.onChangColor();
                                const f = this.arrStep.filter((fil: any) => fil.StepId === this.currentStep);
                                f[0] ? this.divCurentStep = f[0].StepName : '';

                            }
                        }
                    });
                }
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    onChangColor() {
        this.bpmnJS.importXML(this.diagram, (err: any) => { });
        this.bpmnJS.on('shape.added', (e: any) => {
            const element = e.element;
            const businessObject = element.businessObject;
            const face = this.bpmnJS.get('graphicsFactory');
            let elementRegistry = null;
            let gfx = null;
            let type = element.waypoints ? 'connection' : 'shape';
            // var modeling = this.bpmnJS.get('modeling');
            const f = this.arrStep.filter((fil: any) => fil.StepId === element.id);
            if (f.length > 0) {
                switch (f[0].isActive) {
                    case 'running':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#FFFF00');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                    case 'complete':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#CCFF33');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                    case 'notComplete':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#98FB98');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                }

            }
        });
    }

    getJobByJobType() {
        this.tableData = [];
        this.arrJob = [];
        let tableId: any = null;
        let windowId: any = null;
        let windowConfig = null;
        this.tableDataJob = [];
        let paramsJob: any = {};
        this.tableRowDefinationJob = [];
        const idJobType = this.jobType.value !== null ? typeof (this.jobType.value) === 'object' ? this.jobType.value.CODE : this.jobType.value : this.jobType.value;
        const idAssign = this.CbassignTo.value !== null ? typeof (this.CbassignTo.value) === 'object' ? this.CbassignTo.value.CODE : this.CbassignTo.value : this.CbassignTo.value;
        const idJobStatus = this.CbJobStatus.value !== null ? typeof (this.CbJobStatus.value) === 'object' ? this.CbJobStatus.value.CODE : this.CbJobStatus.value : this.CbJobStatus.value;
        const valJobName = this.textNameJob.value;
        if (idJobType !== null) {
            paramsJob['JobTypeId'] = idJobType;
        }
        if (idAssign !== null) {
            paramsJob['AssignedTo'] = idAssign;
            // paramsJob.push(['AssignedTo', '=', idAssign]);
        }
        if (idJobStatus !== null) {
            paramsJob['JobStatusId'] = idJobStatus;
            // paramsJob.push(['JobStatusId', '=', idJobStatus]);
        }
        if (valJobName !== null) {
            paramsJob['JobName'] = valJobName;
            // paramsJob.push(['JobName', 'like', valJobName]);
        }

        // this.dataJobType.forEach((item:any) => {
        //   if (item.JobTypeId === idJobType) {
        //     tableId = item.TableId;
        //     windowId = item.WindowId;
        //   }
        // });
        // const params = {};
        // const url = `${this.appService.urlWS}/SysCaches/SysCacheWindow/${windowId}`;
        // this.reqService.service.query({url, params}).subscribe(res => {
        //     if (res.success) {
        //       windowConfig = res.model.config ? parse(res.model.config) : null;
        //       this.initWindow(windowConfig);
        //     } else {
        //       this.appService.notification('Không đọc được cấu hình');
        //     }
        // }, err => {
        //     this.appService.notification('Không đọc được cấu hình');
        // });


        // if (paramsJob.length > 0) {
        //   this.workflowService.queryJob(paramsJob).subscribe(res => {
        //     if (res.success) {
        //       this.totalJob = res.features.length;
        //       if (this.totalJob > 0) {
        //         res.features.forEach(item => {
        //           this.arrJob.push(item.RecordId);
        //         });

        //         if (tableId !== null) {
        //           const params = ['TableId','=', tableId];
        //           this.workflowService.querySysTable(params).subscribe((res:any) => {
        //             if (res.success) {
        //               const obj = {
        //                 orderByFields: res.features[0].ColumnKey + ' desc',
        //                 where: res.features[0].ColumnKey + ' in (' + this.arrJob + ')'
        //               }
        //               this.workflowService.getValueTable(obj, res.features[0].UrlView).subscribe((item:any) => {
        //                 if (item.result) {
        //                   this.tableDataJob = item.datas;
        //                 }
        //               });
        //             }
        //           });
        //         }
        //       }
        //     }
        //   });
        // }
        Object.keys(this.columnTableJob).forEach(key => {
            this.tableRowDefinationJob.push({
                title: this.translate.instant(this.columnTableJob[key].ColumnName),
                key: this.columnTableJob[key].ColumnName,
                isEdit: false,
                isOnlyForm: true,
                orderNo: this.columnTableJob[key].ColumnIndex
            });
        });
        this.workflowService.queryManagerJob(paramsJob).subscribe(res => {
            if (res.success) {
                this.totalJob = res.features.model.length;
                this.tableDataJob = res.features.model;
            }
        });
    }


    private async initWindow(windowConfig: any) {
        this.tableRowDefinationJob = [];
        const wdConfig = this.reqService.mapConfig(windowConfig);
        if (wdConfig.error) {
            this.appService.alert(wdConfig.error, 'error');
            return;
        }
        let arrField: any = [];
        this.lookupTab = {};
        // this.windowType = wdConfig['servicetype'];
        this.dataSoure = this.buildTabs(wdConfig.tabs, 0);
        this.dataSoure.forEach((res: any) => {
            if (res.INFORMATION.TAB_LEVEL === '0' && res.INFORMATION.SEQ_NO === '1') {
                arrField = res.FIELD_LIST;
                this.columnKey = arrField[0].columnkey;
            }
        });

        arrField.forEach((item: any) => {
            if (item.isdisplay === 'Y') {
                this.tableRowDefinationJob.push({
                    title: item.alias,
                    key: item.fieldname,
                    isEdit: false,
                    isOnlyForm: true,
                    orderNo: item.orderno
                });
            }
        });
        this.tableRowDefinationJob.sort((a: any, b: any) => a.orderNo - b.orderNo);
        // tinh chỉnh CHILDREN
        Object.keys(this.lookupTab).forEach(key => {
            if (this.lookupTab[key].INFORMATION.PARENT_ID) {
                this.lookupTab[this.lookupTab[key].INFORMATION.PARENT_ID].CHILDREN.push(this.lookupTab[key]);
            }
        });

        // this.initWindowSuccess.emit();
    }

    private buildTabs(tabs: Array<any>, level: number) {
        const list: any[] = [];
        tabs.forEach(tab => {
            const listPer = this.appService.appConfig?.FunctionPermistion?.filter(
                (fil: any) => fil.tableid === tab.tableid
            );
            if (listPer && listPer.length > 0) {
                const a = listPer.filter((fil: any) => fil.functioncode === 'VIEW');
                if (a && a.length > 0) {
                    return;
                }
            }
            const fieldList: any[] = [];
            tab.fields.forEach((field: any) => {
                let disabledCtrl = false;
                if (field.isreadonly === 'Y') { disabledCtrl = true; }
                // Field defaultvalue dùng để điền sẵn dữ liệu vào control
                fieldList.push({ ...field, disabledCtrl });
            });

            const where = tab.whereclause ? JSON.parse(tab.whereclause) : [];
            // các biến chưa sử dụng: tablelienket, layoutcolumn, iscache, iscollapse, columndisplay
            const information: Information = {
                TAB_ID: tab.tabid,
                PARENT_ID: tab.parenttabid,
                TABLE_ID: tab.tableid,
                DESCR: tab.tabname,
                TAB_LEVEL: tab.tablevel !== null ? tab.tablevel.toString() : '0',
                TAB_MAX_LEVEL: tab.maxLevel,
                TABLE_DETAIL: tab.tabledetail,
                SEQ_NO: tab.orderno ? tab.orderno.toString() : null,
                DEFAULT_WHERE: where,
                IS_LIENKETDOHOA: 'N',
                IS_SINGLE_LINE_EDIT: tab.issingglelineedit,
                IS_TAB_TRUNG_GIAN: tab.istabtrunggian === 'Y',
                IS_REFRESH_PARENT_TAB: tab.isrefreshtabparrent === 'Y',
                KHOA_CHINH: tab.columnkey,
                TRUONG_LK_CON: tab.truonglienketcon,
                TRUONG_LK_CHA: tab.truonglienketcha,
                TRUONG_LKTG_CON: tab.truongtrunggiancon,
                TRUONG_LKTG_CHA: tab.truongtrunggiancha,
                BANG_CHA: tab.banglienket,
                BANG_LK: tab.bangtrunggian,
                KHOA_CHINH_BANG_LK: tab.columnkeytrunggian,
                WHERE: (tab.tablevel === 0 && tab.orderno === 1 || tab.parenttabid === null) ? where : null,
                TABLE_NAME: tab.tablename,
                URL_EDIT: tab.urledit,
                URL_VIEW: tab.urlview,
                URL_EDIT_MAP: tab.urleditdohoa,
                URL_VIEW_MAP: tab.urlviewdohoa,
                LAYOUT_COLUMN: tab.layoutcolumn,
                PARENT_DATA: null,
                SERVICE_TYPE: tab.tabletype,
                WINDOW_ID: 0,
                FILTER_FIELD: tab.fillterfield,
                FILTER_DEFAULT_VALUE: tab.fillterdefaultvalue,
                ORDER_BY: tab.orderbyclause ?? null,
                INIT_SHOW: tab.initshow === 'Y',
                ONCHANGE: tab.onchange,
                COLUMN_DOHOA: tab.columndohoa, // chưa thấy sử dụng
                COLUMN_CODE: tab.columncode, // Sử dụng để filter nhanh
                COLUMN_DISPLAY: tab.columndisplay,
                TABLEWORKFLOWID: tab.tableworkflowId,
                JOBTYPEIDS: tab.jobtypeids,
                LAYERINDEX: tab.layerindex,
                HASATTACHMENT: tab.hasattachment,
                KVHC_COLUMN: tab.kvhccolumn,
                ColumnWorkflow: tab.columnworkflow,
                ColumnLinkLayer: tab.columnlinklayer,
                COLUMN_KEY: tab.columnkey,
                COLUMN_LOCK: tab.columnlock,
                ARCHIVE_TYPE: tab.logtype,
                NOT_SEND: tab.fieldautodata === 'Y'
            };

            // if (this.isWfApp) {
            //     information.WHERE = information.TAB_LEVEL === '0' && information.SEQ_NO === '1' ? this.whereWf : null;
            // }
            // get layout config
            // let layout = null;
            // if (this.layoutConfig !== null && this.layoutConfig !== undefined) {
            //     this.layoutConfig.components.forEach((comp: any) => {
            //         if (comp.TabID.toString() === information.TAB_ID.toString()) {
            //             layout = comp;
            //         }
            //     });
            // }

            const childTabs = this.buildTabs(tab.tabs, tab.tablevel + 1);
            const child = [];
            for (let i = tab.tablevel; i < tab.maxLevel; i++) {
                child.push(childTabs.filter(fil => fil.INFORMATION.TAB_LEVEL === (i + 1).toString()));
            }

            const item = {
                FIELD_LIST: fieldList,
                // LAYOUT_CONFIG: layout,
                INFORMATION: information,
                HIDE: false,
                TABS: child,
                CHILDREN: [],
                WHERE: information.WHERE
            };

            list.push(item);

            this.lookupTab[tab.tabid] = item;
        });

        return list;
    }

    onSearchJob() {
        this.getJobByJobType();
        // this.tableData = [];
        // this.arrJob = [];
        // const jobName = this.textNameJob.value;
        // const idJobType = this.jobType.value !== null ? typeof (this.jobType.value) === 'object' ? this.jobType.value.CODE : this.jobType.value : this.jobType.value;
        // const params = [['JobName', 'like', jobName], ['JobTypeId', '=', idJobType]];
        // this.workflowService.queryJob(params).subscribe(res => {
        //   console.log(res);
        //   this.totalJob = res.features.length;
        //   if (this.totalJob > 0) {
        //     this.onClickRow(res.features[0]);
        //     res.features.forEach(item => {
        //       const nowTime = Date.now();
        //       const dueDate = new Date(item.DueDate).getTime();
        //       if (dueDate >= nowTime) {
        //         item.color = 0;
        //       } else {
        //         item.color = 1;
        //       }
        //     });
        //     this.arrJob = res.features;
        //   }
        // });
    }

    onChangeAssign(idUser: any) {
        this.arrJob = this.arrJob.filter((fill: any) => fill.AssignedTo === idUser);
    }

    onRowClick(row: any) {
    }

    initDataTable(data: any) {
        this.tableData = [];
        data.forEach((item: any) => {
            this.tableData.push({
                JobId: item.JobId,
                JobName: item.JobName,
                AssignedTo: item.AssignedTo,
                Description: item.Description,
                DueDate: this.formatDateHis(item.DueDate),
                JobTypeId: item.JobTypeId,
                OwnedBy: item.OwnedBy,
                PercComplete: item.PercComplete,
                StartDate: this.formatDateHis(item.StartDate),
                StatusId: item.StatusId,
            });
        });
    }

    getJobType() {
        const params = ['ApplicationId', '=', this.applicationId];
        this.dataJobType = [];
        this.lookUpDataJobType = [];
        this.lookupCondition['JobTypeId'] = [];
        this.workflowService.queryJobtype(params).subscribe(res => {
            if (res.success && res.features.length > 0) {
                this.dataJobType = res.features;
                res.features.forEach(item => {
                    this.lookUpDataJobType.push({
                        CODE: item.JobTypeId,
                        DESCR: item.JobTypeName
                    });
                });
                this.jobType.setValue(this.lookUpDataJobType[0].CODE);
                this.jobTypeReport.setValue(this.lookUpDataJobType[0].CODE);
                this.lookupCondition['JobTypeId'] = this.lookUpDataJobType;
            }
        });
    }

    getUserWf() {
        this.dataUser = [];
        const params = ['ClientId', '=', this.clientId];
        this.workflowService.queryUserBpmn(params).subscribe(res => {
            if (res.message) {
                res.features.forEach(item => {
                    this.dataUser.push({
                        CODE: item.Username,
                        DESCR: item.Username
                    });
                });
                this.lookupCondition['AssignedTo'] = this.dataUser;
                this.lookupCondition['SendFrom'] = this.dataUser;
            }
        });
    }

    onDblClickRow(data: any) {
        this.dialogPrime.isComponent = false;
        this.dialogPrime.templateRef = this.TemplateInfoJob;
        this.dialogPrime.onShow();
        this.initJob(data);
        this.cd.detectChanges();
        this.bpmnJS = new BpmnViewer.default({
            container: this.divWorkflow.nativeElement,
            height: 500,
            width: 900
        });
    }

    initJob(data: any) {
        // this.currentData = data;
        // this.initFormWf();
        // this.initDetailTab();
        // this.initTableHis();

        // const paramsJob = ['RecordId', '=',  this.dataRow[this.columnKey]];
        const paramsJob = ['JobId', '=', data.jobId];
        this.workflowService.queryJob(paramsJob).subscribe(res => {
            if (res.success && res.features[0]) {
                this.currentData = res.features[0];
                this.initFormWf();
                this.initDetailTab();
                this.initTableHis();

            }
        });
    }

    public tapChange(e: any) {
        if (e.index === 1) {
            const params = [['ApplicationId', '=', this.applicationId], ['JobTypeId', '=', this.currentData.JobTypeId]];
            this.workflowService.queryJobtype(params).subscribe(res => {
                if (res.success && res.features[0]) {
                    const data = res.features[0].JobTypeData;
                    const options = { compact: true, ignoreComment: true, spaces: 4 };
                    this.diagram = JSON.parse(data);
                    this.diagram = converter.js2xml(this.diagram, options);
                    this.bpmnJS.importXML(this.diagram, (err: any) => {
                        this.getCurentStep();
                        this.cd.detectChanges();
                        if (err) {
                            console.log(err);
                        }
                    });
                    const canvas = this.bpmnJS.get('canvas');
                    canvas.zoom('fit-viewport');
                }
            });


        }
    }

    initDetailTab() {
        this.windowId = null;
        this.fieldList = [];
        const param = ['TableID', '=', this.currentData.TableId];
        let paramsKey: any = null;
        this.workflowService.queryColBPMN(param).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    if (item.IsPriKey === 'Y') {
                        paramsKey = item.ColumnName;
                    }
                });
                if (paramsKey !== null && this.currentData.RecordId !== null) {
                    this.whereWf = [paramsKey, '=', this.currentData.RecordId];
                    this.windowId = this.currentData.WindowId;
                    this.cd.detectChanges();
                } else {
                    this.appService.notification('Lỗi truy vấn detail', 'warning');
                }
            }

        });

    }

    onCblClickChart(item: any) {
        this.currentData = item;
        // this.initFormWf();
    }

    initFormWf() {
        const params = [['ClientId', '=', this.clientId], ['TableName', '=', 'WfJob']];
        this.workflowService.querySysTable(params).subscribe(res => {
            if (res.success) {
                if (res.features[0]) {
                    const tableId = res.features[0].TableId;
                    this.initInfoWf(tableId);
                }
            } else {
                this.appService.notification('Lỗi truy vấn', 'error');
                // this.dialogPrime.onHideDialog();
            }
        }, err => {
            this.appService.notification('Lỗi truy vấn', 'error');
            // this.dialogPrime.onHideDialog();
        });
    }


    initInfoWf(tableId: any) {
        this.formData.reset();
        if (tableId !== null) {
            this.fieldList = [];
            const param = ['TableID', '=', tableId];
            this.workflowService.queryColBPMN(param).subscribe(res => {
                if (res.success) {
                    res.features.forEach(item => {
                        this.fieldList.push({
                            FIELD_NAME: item.ColumnName,
                            FIELD_LABEL: item.Alias,
                            FIELD_TYPE: this.getTypeColumnTblJob(item.ColumnName),
                            orderno: item.OrderNo,
                            FIELD_SHOW: 'Y'
                        });
                    });

          // const FIELD_LIST = this.fieldList;
          // this.dialogPrime.title = 'Job information';
          // this.dialogPrime.isComponent = true;
          // this.dialogPrime.widthPanel = '80vw';
          // this.currentComp = this.dialogPrime.showDialog(RunBPMNComponent, {}, null);
          // this.currentComp.run({
          //   currentData: this.currentData,
          //   dataSource: {FIELD_LIST},
          //   primaryKey: {},
          //   params: {}
          // });


                    this.fieldList.forEach((item: any) => {
                        const f = this.arrFieldHide.filter((fill: any) => fill === item.FIELD_NAME);
                        if (f[0] || item.FIELD_KEY === 'Y') {
                            item.FIELD_SHOW = 'N';
                        }
                    });

                    // this.fieldList = this.fieldList.sort((a, b) => a.FIELD_ORDER - b.FIELD_ORDER);
                    const group: any = {};
                    this.fieldList.forEach((item: any) => {
                        group[item.FIELD_NAME] = new FormControl();
                    });

                    this.formData = new FormGroup(group);
                    Object.keys(this.formData.controls).forEach(key => {
                        this.formData.controls[key].setValue(this.currentData[key]);
                    });

          // this.onShowFormCreate();
          // this.dataLookUp();
          // let jobName = '';
          // if (this.columnCode !== null ){
          //   jobName = this.dataEmit.params[this.columnCode];

                    // }
                    // this.formData.controls['JobName'].setValue(jobName);
                    // this.formData.controls['JobTypeId'].valueChanges.subscribe(item => {
                    //   if (item !== null) {
                    //     typeof(item) === 'object' ? this.onChangeJobTypeAndDate(item.CODE) : this.onChangeJobTypeAndDate(item);
                    //   }
                    // });
                }
            }, err => {
                console.log(err);
            });

        } else {

        }
    }

    getColumTable() {
        this.tableRowDefination = [];
        const params = ['ClientId', '=', this.clientId];
        const arrStatus = this.workflowService.querySatus();
        const arrUser = this.workflowService.queryUserBpmn(params);
        const arrJobtype = this.workflowService.queryJobtype(params);
        const list = [arrStatus, arrUser, arrJobtype];

        combineLatest(list).subscribe((res: any) => {
            this.lookupCondition['StatusId'] = [];
            this.lookupCondition['AssignedTo'] = [];
            this.lookupCondition['JobTypeId'] = [];
            if (res[0].success) {
                res[0].features.forEach((item: any) => {
                    this.lookupCondition['StatusId'].push({
                        CODE: item.StatusId,
                        DESCR: item.StatusName
                    });
                });
            }
            if (res[1].success) {
                res[1].features.forEach((item: any) => {
                    this.lookupCondition['AssignedTo'].push({
                        CODE: item.Username,
                        DESCR: item.Username
                    });
                });
            }
            if (res[2].success) {
                res[2].features.forEach((item: any) => {
                    this.lookupCondition['JobTypeId'].push({
                        CODE: item.JobTypeId,
                        DESCR: item.JobTypeName
                    });
                });
            }

            Object.keys(this.ColumManager).forEach(item => {
                if (this.ColumManager[item].isReadField) {
                    this.tableRowDefination.push({
                        rowHeader: this.ColumManager[item].ColumnAlias,
                        rowFieldName: this.ColumManager[item].ColumnName,
                        rowOrder: this.ColumManager[item].ColumnIndex,
                        rowType: this.ColumManager[item].ColumnType,
                        rowIsShow: true
                    });
                }
            });
            this.tableRowDefination = this.tableRowDefination.sort((a: any, b: any) => a.rowOrder - b.rowOrder);
            this.initDataTable(this.arrJob);
        });

        // this.tableRowDefination .forEach(item => {
        //   if (item.rowType === 'C') {
        //     this.lookupCondition[item.rowFieldName] = [];
        //     const lookup = this.lookupCondition[item.rowFieldName];
        //     if (lookup !== undefined) {
        //         Object.keys(lookup).forEach(elem => {
        //             this.lookupCondition[item.rowFieldName].push({
        //                 CODE: lookup[elem].CODE.toString(),
        //                 DESCR: lookup[elem].DESCR.toString()
        //             });
        //         });
        //     }
        //   }
        // });
    }

    public initTableHis() {
        this.tableRowDefination = [];
        Object.keys(this.configColumHis['sys_column']).forEach(item => {
            if (this.configColumHis['sys_column'][item].isReadField) {
                this.tableRowDefination.push({
                    title: this.configColumHis['sys_column'][item].ColumnAlias,
                    key: this.configColumHis['sys_column'][item].ColumnName,
                    isEdit: false,
                    isOnlyForm: true
                });
            }
        });
        this.tableRowDefination = this.tableRowDefination.sort((a: any, b: any) => a.rowOrder - b.rowOrder);
        this.currentData !== null ? this.queryDataHis() : '';
    }

    public queryDataHis() {
        this.tableData = [];
        const params = ['JobId', '=', this.currentData.JobId];
        this.workflowService.queryHistory(params).subscribe(resp => {
            resp.features.forEach(item => {
                this.tableData.push({
                    type: this.arrTypeActivityHis[item.ActivityId],
                    sendFrom: item.SendFrom,
                    stepName: item.StepName,
                    assignedTo: item.AssignedTo,
                    date: this.formatDateHis(item.UpdateTime),
                    message: item.Description
                });
            });
            this.tableData = JSON.parse(JSON.stringify(this.tableData));
        });
    }

    // report
    initTableTimeReport(item: any, startDate: any, endDate: any) {
        // query data
        this.total_hour_count = 0;
        const paramsJob = (startDate && endDate) === null ? ['JobTypeId', '=', item] : [['UpdateTime', '>=', new Date(this.searchTime.value[0])], ['UpdateTime', '<=', new Date(this.searchTime.value[1])]];
        const paramsJobType = ['JobTypeId', '=', item];
        this.tableRowDefinationTimeWK = [];
        this.tableTimeWorking = [];
        Object.keys(this.columReportTime).forEach(item => {
            // if (this.configColumHis['sys_column'][item].isReadField) {
            this.tableRowDefinationTimeWK.push({
                title: this.translate.instant(this.columReportTime[item].ColumnName),
                key: this.columReportTime[item].ColumnName,
                isEdit: false,
                isOnlyForm: true,
                isShowPercent: this.columReportTime[item].ColumnName === 'totalDurationPercent' ? true : false
                // rowOrder: this.columReportTime[item].ColumnIndex,
                // rowType: this.columReportTime[item].ColumnType,
                // rowIsShow: true
            });
            // }
        });
        this.tableRowDefinationTimeWK = this.tableRowDefinationTimeWK.sort((a: any, b: any) => a.rowOrder - b.rowOrder);
        let arrStep: any = [];
        let arrQuery = [];
        const queryStep = this.workflowService.queryJobtypeStep(paramsJobType);
        const queryJob = this.workflowService.queryJob(paramsJob);
        arrQuery = [queryStep, queryJob];
        combineLatest(arrQuery).subscribe((res: any) => {
            arrStep = res[0].features;
            // arrStep['totalDuration'] = 0;
            const arrJob = res[1].features;
            this.total_job_count = arrJob.length;
            arrJob.length === 0 ? this.initTableAssign(item, [], []) : '';
            const requestHis: any = [];
            arrJob.forEach((element: any) => {
                const params = ['JobId', '=', element.JobId];
                requestHis.push(this.workflowService.queryHistory(params));
            });

            combineLatest(requestHis).subscribe((his: any) => {
                let totalWorking = 0;
                arrStep.forEach((step: any) => {
                    step.TotalDuration = 0;
                    step.Mission = 0;
                    if (this.timeUnit === 1) {
                        step.SLA = step.Duration * 24;
                    } else if (this.timeUnit === 2) {
                        step.SLA = step.Duration;
                    } else if (this.timeUnit === 3) {
                        step.SLA = (step.Duration / 60).toFixed(2);
                    }

                    his.forEach((his: any) => {
                        his.features.forEach((element: any) => {
                            if (step.StepId === element.StepId) {
                                step.Mission += 1;
                                if (element.ActivityId === 5) {
                                    if (element.Duration !== null) {
                                        step.TotalDuration += element.Duration;
                                    }
                                }
                            }
                        });
                    });
                });

                arrStep.forEach((res: any) => {
                    res.TotalDuration = (res.TotalDuration / arrJob.length);
                    totalWorking += Number(res.TotalDuration);
                });
                this.total_hour_count = totalWorking.toFixed(2);
                arrStep.forEach((res: any) => {
                    const percent1 = Number((res.TotalDuration / this.total_hour_count).toFixed(2)) * 100;
                    const totalDuration1 = res.TotalDuration;
                    // res.totalDurationPercent = {percent: percent1, totalDuration: totalDuration1}; tam thời khoa chờ fix core table
                    // style tạm cho column table
                    res.totalDuration1 = totalDuration1 + ' h';
                    res.totalDurationPercent = percent1 + 'h';
                    res.SLA = res.SLA + 'h';
                    res.Mission = res.Mission + ' Times';
                });
                this.tableTimeWorking = [...arrStep];
                console.log(this.tableRowDefinationTimeWK);
                this.initTableAssign(item, his, arrStep);
            });
        });
    }

    initTableAssign(item: any, arrHis: any, arrStep: any) {
        const params = ['JobTypeId', '=', item];
        this.tableRowDefinationAssign = [];
        this.tableDataAssign = [];
        Object.keys(this.columAssign).forEach(item => {
            // if (this.configColumHis['sys_column'][item].isReadField) {
            this.tableRowDefinationAssign.push({
                title: this.translate.instant(this.columAssign[item].ColumnName),
                key: this.columAssign[item].ColumnName,
                isEdit: false,
                isOnlyForm: true,
                // rowOrder: this.columAssign[item].ColumnIndex,
                // rowType: this.columAssign[item].ColumnType,
                // rowIsShow: true
            });
            // }
        });
        this.tableRowDefinationAssign = this.tableRowDefinationAssign.sort((a: any, b: any) => a.rowOrder - b.rowOrder);

        // const handle = this.loadDataHis.subscribe(his => {
        //   handle.unsubscribe();
        if (arrHis.length > 0) {
            this.workflowService.queryJobtypeUser(params).subscribe(user => {
                if (user.success) {
                    const arrUser = user.features;
                    arrUser.forEach(eleUser => {
                        eleUser.Times = 0;
                        eleUser.WaitingTime = 0;
                        eleUser.OutOfDate = 0;
                        eleUser.OverDueTime = 0;
                        eleUser.SLA = 0;
                        eleUser.Member_manager = eleUser.Username
                        arrHis.forEach((his: any) => {
                            his.features.forEach((eleHis: any) => {
                                if (eleUser.Username === eleHis.AssignedTo) {
                                    // 1 - Accept; 5 - Complete
                                    if (eleHis.WfActivity !== 1 && eleHis.WfActivity !== 5) {
                                        eleUser.Times += 1;
                                    }
                                    // 1 là Accept
                                    if (eleHis.ActivityId === 1) {
                                        eleUser.WaitingTime = (Number(eleUser.WaitingTime) + Number(eleHis.Duration)).toFixed(2);
                                    }
                                    // 5 là complete
                                    if (eleHis.ActivityId === 5) {
                                        const f = arrStep.filter((fill: any) => fill.StepId === eleHis.StepId);
                                        if (f[0]) {
                                            if (f[0].Duration < eleHis.Duration) {
                                                eleUser.OutOfDate += 1;
                                                eleUser.OverDueTime += Number(eleHis.Duration) - Number(f[0].Duration);
                                                eleUser.OverDueTime = parseFloat(eleUser.OverDueTime).toFixed(2);
                                            }
                                            // SLA
                                            if (this.timeUnit === 1) {
                                                eleUser.SLA = f[0].Duration * 24;
                                            } else if (this.timeUnit === 2) {
                                                eleUser.SLA = f[0].Duration;
                                            } else if (this.timeUnit === 3) {
                                                eleUser.SLA = (f[0].Duration / 60).toFixed(2);
                                            }
                                        }
                                    }
                                }
                            });
                        });
                    });
                    this.tableDataAssign = [...arrUser];
                    // style lại column cho table mới
                    arrUser.forEach(res => {
                        res.Times = res.Times + ' Times'
                        res.WaitingTime = res.WaitingTime + ' h'
                        res.OutOfDate = res.OutOfDate + ' Times'
                        res.OverDueTime = res.OverDueTime + ' h'
                        res.SLA = res.SLA + ' h'
                    });
                }
            });
        }
    }

    search() {
        if (this.searchTime.value !== null) {
            const startDate = this.searchTime.value[0];
            const endDate = this.searchTime.value[1];
            if ((startDate && endDate) !== null) {
                const item = this.jobTypeReport.value;
                const idJobType = item !== null ? typeof (item) === 'object' ? item.CODE : item : item;
                this.initTableTimeReport(idJobType, startDate, endDate);
            }
        }
    }

    queryDashboard() {
        const paramsJobType = ['ApplicationId', '=', this.applicationId];
        const listRequestJob: any = [];
        const listRequestStatusJob1: any = [];
        const listRequestStatusJob2: any = [];
        const listRequestStatusJob3: any = [];
        const listRequestUserJT: any = [];

        let countJob = 0;
        this.workflowService.queryJobtype(paramsJobType).subscribe(jobType => {
            if (jobType.success) {
                this.totalWorkflow = jobType.total;
                jobType.features.forEach(eleJobType => {
                    const paramJob = ['JobTypeId', '=', eleJobType.JobTypeId];
                    const paramStatusJob1 = this.workflowService.queryJob([['JobTypeId', '=', eleJobType.JobTypeId], ['JobStatusId', '=', 1]]);
                    const paramStatusJob2 = this.workflowService.queryJob([['JobTypeId', '=', eleJobType.JobTypeId], ['JobStatusId', '=', 2]]);
                    const paramStatusJob3 = this.workflowService.queryJob([['JobTypeId', '=', eleJobType.JobTypeId], ['JobStatusId', '=', 3]]);

                    listRequestStatusJob1.push(paramStatusJob1);
                    listRequestStatusJob2.push(paramStatusJob2);
                    listRequestStatusJob3.push(paramStatusJob3);
                    listRequestJob.push(this.workflowService.queryJob(paramJob));

                    const paramsUserJobType = this.workflowService.queryJobtypeUser(['JobTypeId', '=', eleJobType.JobTypeId]);
                    listRequestUserJT.push(paramsUserJobType);
                });
                combineLatest(listRequestJob).subscribe((res) => {
                    res.forEach((item: any) => {
                        countJob += item.total;
                    });
                    this.totalJobReport = countJob;
                });

                combineLatest(listRequestStatusJob1).subscribe((res => {
                    let count = 0;
                    res.forEach((item: any) => {
                        count += item.total;
                    });
                    this.totalJobInit = count;

                }));
                combineLatest(listRequestStatusJob2).subscribe((res => {
                    let count = 0;
                    res.forEach((item: any) => {
                        count += item.total;
                    });
                    this.totalJobWorking = count;
                }));
                combineLatest(listRequestStatusJob3).subscribe((res => {
                    let count = 0;
                    res.forEach((item: any) => {
                        count += item.total;
                    });
                    this.totalJobDone = count;
                }));
                combineLatest(listRequestUserJT).subscribe((res => {
                    let count = 0;
                    res.forEach((item: any) => {
                        count += item.total;
                    });
                    this.totalMember = count;
                }));
            }
        });
    }

    formatDateHis(date: any) {
        let dateString: any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getFullYear();
            dateString += ' ';
            dateString += newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds();
        } else {
            dateString = null;
        }
        return dateString;
    }

    getTypeColumnTblJob(nameField: string) {
        const objTblJob: any = {
            JobId: 'number',
            JobName: 'text',
            Description: 'text',
            SendFrom: 'select',
            AssignedTo: 'select',
            AssignedType: 'select',
            ParentJobId: 'number',
            StartDate: 'date',
            Note: 'text',
            DueDate: 'date',
            UpdateTime: 'date',
            PercComplete: 'number',
            OwnedBy: 'text',
            JobStatusId: 'select',
            JobTypeId: 'select',
            PriorityId: 'select',
            WindowId: 'number',
            TableId: 'number',
            RecordId: 'number',
            AssignStatusId: 'select'
        };
        return objTblJob[nameField];
    }

    public formatComboBox(data: any, item: any) {
        const val = data[item];
        let result = null;
        if (val !== '') {
            result = this.lookupCondition[item].filter((fil: any) => fil.CODE === val);
            if (result.length > 0) {
                return result[0].DESCR;
            } else {
                return val;
            }

        } else {
            return result;
        }
    }

}
