import * as esriLoader from 'esri-loader';

// declare variables for esri class
let GraphicsLayer: any;
let Graphic: any;
let Point: any;
let SpatialReference: any;


export class GraphicUtil {

    // private GraphicsLayer: any;
    // private Graphic: any;

    /** Graphic layer name. */
    graphicLayerName = 'graphicLayerCore';

    /**
     * Provide `esri/layers/GraphicsLayer` class.
     * @deprecated Deprecated in 5 minutes.
     */
    graphicLayer: any;


    constructor(private map: any) {
        esriLoader.loadModules([
            'esri/layers/GraphicsLayer', 'esri/graphic', 'esri/geometry/Point', 'esri/SpatialReference'
        ]).then(([
            _GraphicsLayer, _Graphic, _Point, _SpatialReference
        ]) => {
            GraphicsLayer = _GraphicsLayer;
            Graphic = _Graphic;
            Point = _Point;
            SpatialReference = _SpatialReference;

            const graphicLayer = new GraphicsLayer({ id: this.graphicLayerName });
            this.map.addLayer(graphicLayer);
        });
    }

    create(geometry: any, symbol?: any, attribute?: any, infoTemplate?: any) {
        return new Graphic(geometry, symbol, attribute, infoTemplate);
    }

    createPoint(x: any, y: any, sp: any) {
        return new Point(x, y, new SpatialReference({ wkid: sp }));
    }

    createGraphicLayer(graphicLayerName: string, index?: any) {
        let graphicLayer = this.map.getLayer(graphicLayerName);
        if (graphicLayer === undefined) {
            graphicLayer = new GraphicsLayer({ id: graphicLayerName });
            this.map.addLayer(graphicLayer);
        }
        return graphicLayer;
    }
    /**
     * Adds a graphic.
     * @param graphic esri/graphic class.
     * @param graphicLayerName Name of graphic layer.
     */
    add(graphic: any, graphicLayerName: string = this.graphicLayerName): any {
        // let graphicLayer = this.map.getLayer(graphicLayerName);
        // if (graphicLayer == undefined) {
        //   graphicLayer = new GraphicsLayer({ id: graphicLayerName });
        //   this.map.addLayer(graphicLayer);
        // }
        const graphicLayer = this.createGraphicLayer(graphicLayerName);
        return graphicLayer.add(graphic);
    }

    /**
     * Removes a graphic.
     * @param graphic esri/graphic class.
     * @param graphicLayerName Name of graphic layer.
     */
    remove(graphic: any, graphicLayerName: string = this.graphicLayerName) {
        const graphicLayer = this.map.getLayer(graphicLayerName);
        if (graphicLayer !== undefined) {
            graphicLayer.remove(graphic);
        }
    }

    /**
     * Clears all graphics in `graphicLayerCore` layer.
     * @param graphicLayerName Name of graphic layer.
     */
    clear(graphicLayerName: string = this.graphicLayerName) {
        const graphicLayer = this.map.getLayer(graphicLayerName);
        if (graphicLayer !== undefined) {
            graphicLayer.clear();
        }
    }

    /** Clears all graphics in map. */
    clearAll() {
        let layer;

        // clear created layer
        this.map.graphicsLayerIds.forEach((graphicsLayerId: any) => {
            layer = this.map.getLayer(graphicsLayerId);
            if (layer.type !== 'Feature Layer') {
                layer.clear();
            }
        });

        // clear default layer
        this.map.graphics.clear();
    }

}
