import { convertObject } from 'app/app-base/interface/reflect-convert';
import { APP_CONFIG } from './mockup-data/app-config';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, throwError, Subject } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppService } from 'app/app-base/app.service';
import { User } from './login/login-model';
import {
  Application,
} from './interface/app-config';
import { RequestService } from 'app/core/services/request.service';
import { HttpClient } from '@angular/common/http';
import { ThemeService } from 'app/core/themes/theme.service';
import { PushNotificationService } from 'app/notification/notification.service';

@Injectable()
export class AuthService {
  // store the URL so we can redirect after logging in
  redirectUrl!: string;
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public currentLoginSubject: BehaviorSubject<boolean>;
  public currentLogin: Observable<boolean>;

  public currentLoginPortalSubject: BehaviorSubject<boolean>;
  public currentPortalLogin: Observable<boolean>;
  private tokenTimer: any;
  // private _isLoggedInPortal = false;
  public appid = 0;
  public remember = false;
  public _isLoggedInPortal = false;
  private handleIntervalNotifications = null;

  constructor(
    private appService: AppService,
    private reqService: RequestService,
    private router: Router,
    private http: HttpClient,
    private themeService: ThemeService,
    private pushNotify: PushNotificationService
  ) {

    let currentUser = null;
    let currentLogin = null;

        if (localStorage) {
            if (localStorage.length > 0) {
                const a = this.appService.currentUser; // this.appService.currentUser;
                const b = sessionStorage.getItem(this.appService.hostName1 + 'currentLogin');
                const c = localStorage.getItem('loginPortal');
                currentUser = a ? JSON.parse(a) : null;
                currentLogin = b ? JSON.parse(b) : null;
                this._isLoggedInPortal = c ? JSON.parse(c) as boolean : false;
            }
        }

    this.currentUserSubject = new BehaviorSubject<User>(currentUser);
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentLoginSubject = new BehaviorSubject<boolean>(currentLogin);
    this.currentLogin = this.currentLoginSubject.asObservable();

    this.currentLoginPortalSubject = new BehaviorSubject<boolean>(this._isLoggedInPortal);
    this.currentPortalLogin = this.currentLoginPortalSubject.asObservable();

    this.reqService.switchType(this.appService.dataAccessType);
  }

  public get isLoggedInPortal(): boolean {
    return this.currentLoginPortalSubject.value ?? null;
  }

  // public set isLoggedInPortal(value: boolean) {
  //     this._isLoggedInPortal = value;
  // }

  // Update application for list
  public updateListAppForAppservice(params: any) {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/Login/cloudgetAppByUsername',
      params,
    }).subscribe(res => {
      this.appService.application = res.model
    })
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value ?? null;
  }

  public get currentLoginValue(): boolean {
    return this.currentLoginSubject.value ?? null;
  }

  public getJSON(): Observable<any> {
    return this.http.get('assets/datas/application/data.json');
  }


  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout().subscribe((res) => {
        if (res.success) {
          this.router.navigate(['/login']);
        }
      });
    }, duration * 1000);
  }

  private getConfigInit(_params?: any): Observable<boolean> {
    const subject: Subject<any> = new Subject();
    const appId = this.appService.objStoreAppId.appId;
    const params = {
      url: this.appService.urlWS + '/Config/Init',
      params: _params ?? {
        appid: appId,
        // appid: 1,
        userid: this.currentUserValue.userid,
      }
    };
    this.appService.objStoreAppId.appId = params.params.appid.toString();
    sessionStorage.setItem(this.appService.hostName1 + '_CurrentApp', JSON.stringify(this.appService.objStoreAppId));

    this.reqService.service.query(params).subscribe(response => {
      this.appService.appConfig = {
        Systems: APP_CONFIG.Systems,
        Menus: response.dsMenu,
        Services: response.services,
        Layers: response.layers,
        Applications:
          this.appService.application === null
            ? null
            : this.appService.application,

        FunctionPermistion: response.functionPermission,
        PermissionEditorTool: response.permissionEditorTool,
        DSKVHC: response.dskvhc,
        wherekvhc: response.wherekvhc,
        sys_combo: response.sys_combo,
        sys_config: response.sys_config,
        objTheme: response.objTheme,
        listCalendar: response.listCalendar,
        themeClient: response.themeClient
      };
      if (!this.appService.c$) {
        this.appService.c$ = {};
        this.appService.setNowDate();
        this.appService.c$.dateFormat = 'dd/mm/yy';
      }
      this.appService.c$.sys_combo = response.sys_combo;
      this.appService.c$.sys_config = response.sys_config;
      this.appService.c$.kvhc = response.sys_combo['-1'] ? JSON.parse(response.sys_combo['-1']) : [];
      const currenruser = JSON.parse(this.appService.currentUser)
      this.appService.c$.token = currenruser.token
      this.appService.c$.urlAutoData = this.appService.urlAutoData + '/' + currenruser.dbname + '/data/' + currenruser.schema
      this.appService.c$.urlWS = this.appService.urlWS
      if (response.objTheme !== null) {
        const content = JSON.parse(response.objTheme.themeContent);
        this.themeService.setActiveTheme(content)
      } else {
        if (response.themeClient) {
          const content = JSON.parse(response.themeClient.themeContent);
          this.themeService.setActiveTheme(content)
        } else {
          this.themeService.setActiveTheme(null)
        }

      }

      this.pushNotify.sendDataToServiceWorker();
      window.c$ = this.appService.c$
      // console.log(this.appService.application)
      subject.next(true);
    }), catchError((err: any) => {
      return err;
    });

    return subject.asObservable();
  }

  getAuthorize() {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/Config/file_get_contents?username=' + JSON.parse(this.appService.currentUser).username + '&clientcode=' + JSON.parse(this.appService.currentUser).ClientCode,
      params: {},
      responseType: 'text',
      contentType: 'text/plain',
      method: 'GET'
    });
  }

  isLoggedIn(urlRequest: string): Observable<boolean> {
    let _url = urlRequest;
    const href = document.location.href;
    const hostName = this.appService.hostName;

    if (_url.includes('license')) {
      return of(true);
    }
    if (_url.includes('access_token')) {
      let str = _url.toString().split('=')[1].replace('&expires_in', "");
      localStorage.setItem('gistoken', str);
    }
    // (href === hostName && _url === '/') => về trang chủ???
    const valid = (href !== hostName || _url === '/' || (href === hostName && _url === '/'));
    console.log(valid, this.appService.appConfig);
    if (valid && !this.appService.appConfig) { // url chính xác nhưng chưa có config
      if (!this.currentUserValue) { // Chưa đăng nhập
        return of(false);
      }
      const date = new Date(this.currentUserValue.expiresAt);
      if (date && date.getTime() < Date.now()) { // Kiểm tra hạn sử dụng của token user
        return of(false);
      }

      const params = { userid: this.currentUserValue.userid, clientid: this.currentUserValue.clientid };
      const url = this.appService.urlWS;
      return this.reqService.service.query({
        url: url + '/Login/cloudgetAppByUsername',
        params,
      }).pipe(mergeMap((res: any) => {
        // lấy danh sách Application
        const lst: Application[] = [];
        let str = '';
        let a = res.model;
        a = a.sort((b: any, c: any) => b.applicationId - c.applicationId);
        a.forEach((element: any) => {
          if (element.applicationId != null) {
            const app = convertObject(Application, element);
            if (!str.includes(app.applicationId.toString())) {
              str = str + ',' + app.applicationId.toString();
              lst.push(app);
            }
          }
        });
        this.appService.application = lst;

        if (this.appService.application !== null) {

          const data = this.appService.application.filter((fil: any) => fil.linkUrl === _url);

          if (data.length > 0) {
            if (this.appService.objStoreAppId.appId) {
              // Lấy cấu hình của app
              return this.getConfigInit().pipe(map(kq => {
                return true;
              }));
            }
          } else if (_url === '/') {
            return of(this.currentLoginValue);
          }
          else if (_url.includes('um')) {
            // console.log(data)
            //  this.router.navigate(['/um']);
            // return of(this.currentLoginValue);

            return this.getConfigInit().pipe(map(kq => {
              return true;
            }));
          }
          // else if (_url.includes('mobigis')) {
          //     this.router.navigate(['/mobigis']);
          // }
          else {
            this.appService.appConfig = null;
            if (this.currentLoginValue) {
              this.router.navigate(['/']);
            }
            return of(false);
          }
        }

        return of(false);
      }));
    } else {
      if (this.appService.appConfig && this.appid !== 0) {
        if (this.appService.objStoreAppId.appId === this.appid.toString()) {
          if (this.appService.appConfig.objTheme !== null && this.appService.appConfig.objTheme !== undefined) {
            const content = JSON.parse(this.appService.appConfig.objTheme.themeContent);
            this.themeService.setActiveTheme(content)
          } else {
            if (this.appService.appConfig.themeClient) {
              const content = JSON.parse(this.appService.appConfig.themeClient.themeContent);
              this.themeService.setActiveTheme(content)
            } else {
              this.themeService.setActiveTheme(null)
            }
          }

          return of(true);
        } else {
          const params = {
            appid: this.appid.toString(),
            userid: this.currentUserValue.userid,
          }
          return this.getConfigInit(params).pipe(map(kq => {
            return true;
          }));
        }
      } else if (this.appService.appConfig != null && this.appid === 0) {
        if (this.appService.appConfig.objTheme !== null && this.appService.appConfig.objTheme !== undefined) {
          const content = JSON.parse(this.appService.appConfig.objTheme.themeContent);
          this.themeService.setActiveTheme(content)
        } else {
          if (this.appService.appConfig.themeClient) {
            const content = JSON.parse(this.appService.appConfig.themeClient.themeContent);
            this.themeService.setActiveTheme(content)
          } else {
            this.themeService.setActiveTheme(null)
          }
        }
        this.appService.appConfig = null;
        return of(true);
      } else {
        const params = {
          appid: this.appid,
          userid: this.currentUserValue.userid,
        };
        if (this.currentLoginValue) {
          return this.getConfigInit(params).pipe(map(kq => {
            if (this.appService.appConfig && this.appService.appConfig.Applications) {
              const data = this.appService.appConfig.Applications.filter(
                (fil: any) =>
                  fil.applicationId === this.appid
              );
              if (data.length > 0) {
                return this.currentLoginValue;
              } else {
                this.appService.appConfig = null;
                if (this.currentLoginValue) {
                  this.router.navigate(['/']);
                }
                return false;
              }
            } else {
              this.appService.appConfig = null;
              if (this.currentLoginValue) {
                this.router.navigate(['/']);
              }
              return false;
            }
          }));
        } else {
          return of(this.currentLoginValue);
        }
      }
    }
  }

  login(credential: any): Observable<any> {
    // const urlProxy = 'https://water.esrivn.net/viwaco2/Handlers/AppProxy.ashx?';
    const urlProxy = '';
    const url = `${urlProxy}${this.appService.urlWS}/Login/UserLogin`;
    const params = {
      clientcode: credential.CLIENTCODE,
      userName: credential.USERNAME,
      passWord: credential.PASSWORD
    };
    return this.reqService.service.query({
      url,
      params,
      method: 'POST'
    }).pipe(
      map((response: any) => {
        if (response.model && response.model.success) {
          // // dat phien lam viec
          const ketqua = response.model;
          const expiresInDuration = ketqua.token.expiresIn * 60;
          //  this.setAuthTimer(expiresInDuration);
          const now = new Date();
          //   const expirationDate = new Date(now + expiresInDuration * 60 * 1000).toUTCString();
          const expires = new Date(Date.now() + expiresInDuration * 1000);
          const myuser: User = {};
          myuser.username = ketqua.token.username;
          myuser.token = ketqua.token.token;
          myuser.password = ketqua.token.password;
          myuser.expiresin = ketqua.token.expiresIn;
          myuser.Roles = ketqua.token.roles;
          myuser.expiresAt = expires;
          myuser.tokenRefesh = ketqua.token.tokenRefesh;
          myuser.ClientCode = ketqua.token.clientcode;
          myuser.clientid = ketqua.token.clientid;
          myuser.userid = ketqua.token.userid;
          myuser.schema = ketqua.token.schema;
          myuser.schemadefault = ketqua.token.schemadefault;
          myuser.dbname = ketqua.token.dbname;
          myuser.status = ketqua.token.status;
          myuser.urlPortal = ketqua.token.urlPortal;
          myuser.userPortal = ketqua.token.userPortal;
          myuser.passwordPortal = ketqua.token.passwordPortal;
          myuser.groupportal = ketqua.token.groupportal;
          myuser.isarcgisonline = ketqua.token.isarcgisonline;
          myuser.urlarcgisonline = ketqua.token.urlarcgisonline;
          myuser.userarcgisonline = ketqua.token.userarcgisonline;
          myuser.passwordarcgisonline = ketqua.token.passwordarcgisonline;
          myuser.grouparcgisonline = ketqua.token.grouparcgisonline;
          if (sessionStorage) {
            if (!this.appService.c$) {
              this.appService.c$ = {};
              this.appService.setNowDate();
              this.appService.c$.dateFormat = 'dd/mm/yy';
            }
            this.appService.c$['username'] = myuser.username;
            this.appService.c$['userid'] = myuser.userid;
            this.appService.c$['clientid'] = myuser ? myuser.clientid : null;
            sessionStorage.setItem(this.appService.hostName1 + 'currentUser', JSON.stringify(myuser));
            sessionStorage.setItem(this.appService.hostName1 + 'currentLogin', 'true');
          }

          this.pushNotify.sendDataToServiceWorker();

          this.appService.currentUser = JSON.stringify(myuser);
          this.appService.ClientId = Number(myuser.clientid);
          this.currentUserSubject.next(myuser);
          this.currentLoginSubject.next(true);
        }
        return response;
      }), catchError(this.handleLoginError)
    );
  }

  private handleLoginError(error: any) {
    const rq = {
      features: [],
      total: 0,
      success: false,
      message: error
    };
    return throwError(rq);
  }

  logout(): Observable<any> {
    const urlProxy = '';
    this.reqService.switchType(this.appService.dataAccessType);
    const url = `${urlProxy}${this.appService.urlWS}/Login/Logout`;
    return this.reqService.service.query({
      url,
      params: null,
      method: 'POST'
    }).pipe(map((response) => {
      this.themeService.setActiveTheme(null)
      if (localStorage || sessionStorage) {
        const lang = localStorage.getItem('language');
        console.log(lang)
        if (typeof lang === 'string') {
          sessionStorage.removeItem(this.appService.hostName1 + 'currentLogin');
          localStorage.setItem('language', lang);
          if (!this.remember) {
            let loginportal = localStorage.getItem('loginPortal');
            if (localStorage.getItem('loginPortal') != 'true') {
              sessionStorage.clear();
            }
            sessionStorage.removeItem(this.appService.hostName1 + 'currentUser');
            localStorage.clear();
            sessionStorage.setItem('language', lang);
            sessionStorage.clear();
            localStorage.setItem('loginPortal', loginportal!);
          }

          const remember = localStorage.getItem(this.appService.hostName1 + 'loginRemember');
          if (remember !== 'true') {
            localStorage.removeItem(this.appService.hostName1 + 'loginInfo');
          }

          this._isLoggedInPortal = false;
        }
        localStorage.removeItem('arcgisTokenList');
        localStorage.removeItem('gistoken');
      }

      clearTimeout(this.tokenTimer);
      this.appService.currentUser = null;
      this.appService.application = [];
      this.appService.appConfig = null;
      this.pushNotify.unsubscribeUser();
      return response;
    }));
  }
}
