import { Component, EventEmitter, OnDestroy, Input, OnInit, Output, TemplateRef, ViewChild, HostListener, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app-base/app.service';
import { AuthService } from 'app/app-base/auth.service';
import { RequestService } from '../services/request.service';
import { formatDate } from '@angular/common';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ThemeService } from '../themes/theme.service';
import { take } from 'rxjs/operators';
import { CalendarComponent } from 'app/calendar/calendar.component';
import { CoreWindowComponent } from '../core-window';
import { APP_CONFIG } from 'app/app-base/mockup-data/app-config';
import { functionConfig } from 'app/app-base/interface/function-config';
import { viewProcessReport } from 'app/reports/configs/layoutReport';

@Component({
  selector: 'app-core-header',
  templateUrl: './core-header.component.html',
  styleUrls: ['./core-header.component.scss'],
  providers: [RequestService]
})
export class CoreHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('tmpAttachment') tmpAttachment!: TemplateRef<any>
  @ViewChild('dialogHelp') dialogHelp!: DialogPrimeComponent
  @ViewChild('dialogClause', { static: false }) dialogClause!: DialogPrimeComponent;
  @ViewChild('dialogCalendar', { static: true }) dialogCalendar!: DialogPrimeComponent;
  @ViewChild('templateNotifycation', { static: false }) templateNotifycation!: OverlayPanel;
  @ViewChild('tmpWhereClause') tmpWhereClause!: TemplateRef<any>;
  @Input() SystemType = 'default';
  @Input() content!: TemplateRef<any>;
  @Input() responseMenu!: TemplateRef<any>;
  // Event change language
  @Output() changeLanguage: EventEmitter<any> = new EventEmitter();

  @ViewChild('dialogComponent', { static: false }) dialogPrime!: DialogPrimeComponent;
  @ViewChild('tmpFormDetailCal', { static: false }) tmpFormDetailCal!: TemplateRef<any>; // for calendar
  // for calendar
  arrPriotity = [{
    CODE: 1,
    DESCR: "Thấp"
  }, {
    CODE: 2,
    DESCR: "Trung bình"
  }, {
    CODE: 3,
    DESCR: "Cao"
  }];
  checkedDay = false;
  minDate: any = null;
  maxDate: any = null;
  public formGroupDetailCal: FormGroup = new FormGroup({});

  public iconClient: any = null;
  public nameApp = '';
  public tenTrang = '';
  public userLogin = '';
  public urlIconHeader: any = null;
  public notificationCount = 0; // Số lượng notification
  public isOpenNotify = false; // Mở/Đóng bảng notifications
  public listNotify: any[] = []; // Danh sách notiffications
  public severity = 'success'; // Màu priority cao nhất, 1: success, 2: warning, 3: danger

  private handleInterval: any = null;
  private handleRequest: any = null; // kiểm soát request
  public bgClient: any = null;
  appPages = [
    { title: 'vi', icon: 'assets/um-images/vi-VN.svg' },
    { title: 'en', icon: 'assets/um-images/en-EN.svg' }
  ];

  public selectedValue = 'vi';
  public language: any = null;

  listAppShortcut: any = [];
  listAppShortcutSys: any = [];
  imageDefault = 'assets/imgs/application-default.png';;
  baseUrl = this.appService.urlInit;
  arrLang: any[] = []
  appIdUm: any = null;
  public listCalendar: any = null;

    public isShowJob = false; // Hiển thị icon mở job
    public isShowCalendar = false; // Hiển thị icon mở calendar
    permissionAttach: any = {
        ADD: true,
        DELETE: true,
        SAVE: true,
        EDIT: true
    }
    private handleLanguageChange: any = null;
    private handleLogo: any = null;
    private handleEventNotifyPush: any = null;
    
    constructor(
        private reqService: RequestService,
        public appService: AppService,
        private authService: AuthService,
        private router: Router,
        public translate: TranslateService,
        private titleService: Title,
        private themeService: ThemeService,
        private viewRef: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
    ) {
        if (localStorage) {
            this.arrLang = [...this.appService.listLang]
            const arr: any[] = []
            this.arrLang.forEach((item: any) => {
                arr.push(item.CODE)
            })
            translate.addLangs(arr);
            const languague = sessionStorage.getItem('language');
            if (languague) {
                translate.setDefaultLang(languague);
                translate.use(languague);
                this.selectedValue = languague;

                // if (languague === 'vi') {
                //   this.language = this.appPages[0];
                // } else {
                //   this.language = this.appPages[1];
                // }
                const obj = this.arrLang.find((v: any) => v.CODE === languague)
                if (obj) {
                    this.language = obj
                }
            } else {
                translate.setDefaultLang('vi');
                sessionStorage.setItem('language', 'vi');
                this.selectedValue = 'vi';
                // this.language = this.appPages[0];
                const obj = this.arrLang.find((v: any) => v.CODE === 'vi')
                if (obj) {
                    this.language = obj
                }
            }
        }
        // Vì có providers nên reqService ở core-header là 1 service riêng phục vụ cho core-header
        this.reqService.switchType(this.appService.dataAccessType);

    this.formGroupDetailCal = new FormGroup({
      eventAdd: new FormControl(''),
      dateFromAdd: new FormControl(new Date),
      dateToAdd: new FormControl(new Date),
      chkTimeUnitAdd: new FormControl(true),
      eventNotifiAdd: new FormControl(''),
      dateSendNotifiAdd: new FormControl(new Date),
      priorityAdd: new FormControl(1)
    });
  }

  async handleClickShowOverlayPanel() {
    this.getApplication();
  }

  getJsonDescriptionApp(stringDesc: any) {
    let description = null;
    try {
      let obj: any = JSON.parse(stringDesc)
      description = obj[this.selectedValue]
    } catch (error) {
      description = stringDesc
    }
    return description;
  }

  async ngOnInit(): Promise<void> {
    this.getApplication();

    const currentUser = this.appService.currentUser;
    if (currentUser) {
      this.userLogin = this.appService.convertStringToJson(
        currentUser
      ).username + '.' + this.appService.convertStringToJson(
        currentUser
      ).ClientCode;
    }

    if (!this.appService.c$) {
      this.appService.c$ = {};
      this.appService.setNowDate();
      this.appService.c$.dateFormat = 'dd/mm/yy';
    }

    if (sessionStorage) {
      const currentApp = sessionStorage.getItem(this.appService.hostName1 + '_CurrentApp');
      const obj = currentApp ? JSON.parse(currentApp) : null;
      if (obj) {
        const nameApp = obj.nameApp;
        const appId = Number(obj.appId);
        const description = obj.tenTrang;
        this.appService.c$['appId'] = appId;

        if (nameApp !== '' && nameApp !== null && nameApp !== undefined) {
          this.nameApp = this.SystemType === 'UM' ? 'Manager' : nameApp.toString();
        }

        if (description) {
          this.tenTrang = this.getJsonDescriptionApp(description);
        }
      }
    }

    this.handleLogo = this.appService.updateLogo.subscribe((res) => {
      this.getClient(res);
    });
    this.initNotifications();
    await this.initJobAndCalendar();
    this.getClient();
  }


  ngOnDestroy(): void {
    this.clearLoopNotifications();
    if (this.handleLanguageChange) {
      this.handleLanguageChange.unsubscribe();
    }

    if (this.handleLogo) {
      this.handleLogo.unsubscribe();
    }

    if (this.handleEventNotifyPush) {
      this.handleEventNotifyPush.unsubscribe();
    }
  }

  backToMenu() {
    this.themeService.setActiveTheme(this.appService.themeClient)
    this.titleService.setTitle('IzyBuilder');
    if (this.appService.currentUser) {

      this.router.navigate(['']);
    } else {

      this.router.navigate(['/login']);
    }
  }



  async initJobAndCalendar() {
    // INIT JOB
    if (this.appService.appConfigAppId0) {
      // console.log('CONFIG APPID 0: >>> ', this.appService.appConfigAppId0);
      return;
    }
    const params = {
      url: this.appService.urlWS + '/Config/Init',
      params: {
        appid: 0,
        userid: this.appService.c$['userid'],
      }
    };
    const response = await this.reqService.service.query(params).pipe(take(1)).toPromise();
    this.appService.appConfigAppId0 = {
      Systems: APP_CONFIG.Systems,
      Menus: response.dsMenu,
      Services: response.services,
      Layers: response.layers,
      Applications:
        this.appService.application === null
          ? null
          : this.appService.application,

      FunctionPermistion: response.functionPermission,
      PermissionEditorTool: response.permissionEditorTool,
      DSKVHC: response.dskvhc,
      wherekvhc: response.wherekvhc,
      sys_combo: response.sys_combo,
      sys_config: response.sys_config,
      objTheme: response.objTheme,
      listCalendar: response.listCalendar,
      themeClient: response.themeClient
    };
    this.isShowJob = response.dsMenu && response.dsMenu.length > 0;

    // INIT CALENDAR
    const resp = await this.reqService.service.query({
      url: this.appService.urlWS + '/Config/GetCalendar',
      params: {
        userid: this.appService.c$['userid']
      },
      method: 'GET'
    }).pipe(take(1)).toPromise();

    this.isShowCalendar = resp && resp.length > 0;
    if (resp) {
      this.listCalendar = resp;
    }
  }

  async openCalendarList(e: any, opCalendar: any) {
    opCalendar.toggle(e);
  }

  // Dùng chung dialog với calendar
  async openJob(notifyItem: any = null) {
    const url = this.appService.urlWS + '/syswindows';

    const resp = await this.reqService.service.search({
      url,
      where: [
        ['ApplicationId', '=', null],
        ['ClientId', '=', this.appService.ClientId]
      ]
    }).pipe(take(1)).toPromise();

    if (resp && resp.success && resp.features.length > 0) {
      const comp: CoreWindowComponent = this.dialogCalendar.showDialog(CoreWindowComponent, {
        windowId: resp.features[0].WindowId,
        dataNotify: notifyItem
      }, 'Job');
      comp.run();

      comp.createDynamicEvent.subscribe((res: any) => {
        this.createDynamicComponent(res);
      });
    }

  }

  // Hàm tạo component khi click vào menu tool ở tab hoặc khi có initOnChange xảy ra
  private createDynamicComponent(event: any) {
    const _config = event.value._config;
    let data = event.value.data;
    let menu = event.value.menu;
    let currentComp = data ? data.currentComponent : null;
    // Còn thiếu trường hợp của Quotation và khi initOnChanges
    let f: any = null;
    if (event.type === 'menu-tool-click-window-customize') {
      f = functionConfig.filter(fil => fil.NAME === menu.windowcustomize);
    } else if (event.type === 'on-change-in-table') {
      f = functionConfig.filter(fil => fil.NAME === event.value.windowName);
      data = {
        dataSource: event.value.dataSource,
        primaryKey: event.value.val,
        params: event.value.params
      };
      if (f && f[0]) {
        f[0].IS_OPEN_DIALOG = false;
      }
    } else {
      f = functionConfig.filter(
        (fil) => fil.NAME === _config.ComponentName
      );

      if (_config.WindowId) {
        data.windowId = _config.WindowId;
      }
    }

    if (f && f[0]) {
      if (f[0].NAME === viewProcessReport.NAME) {
        if (data.currentData !== null) {
          const comp: any = this.renderComponent(f[0].NAME, this.viewRef);
          data.currentData.forEach((row: any) => {
            const data1 = {
              parentData: data.dataSource.INFORMATION.PARENT_DATA,
              currentData: row,
              windowId: data.dataSource.INFORMATION.WINDOW_ID,
              KHOA_CHINH: data.dataSource.INFORMATION.KHOA_CHINH,
              menu,
              report: event.value.res.features[0],
              dataSource: data.dataSource,
              typeRun: 'MENUTOOL'
            };
            comp.run(data1);
          });
        } else {
          this.appService.alert('Please choose one record!', 'warning');
        }

        return;
      }

      if (f[0].IS_OPEN_DIALOG) {
        const dialogPrime: DialogPrimeComponent = this.renderComponent("DialogPrimeComponent", this.viewRef);
        dialogPrime.isAppendBody = true;
        dialogPrime.isMinimize = true;
        dialogPrime.createByDynamic = true;

        const dialogComp = dialogPrime.showDialog(
          f[0].COMPONENT,
          {},
          menu.name // Đã anh Vinh chỉ định, chắc như đinh đóng cột vào lúc 14h49p 02/08/2022
        );

        if (dialogComp.dlgWidth) {
          dialogPrime.widthPanel = dialogComp.dlgWidth;
        }

        if (dialogComp.dlgHeight) {
          dialogPrime.heightPanel = dialogComp.dlgHeight;
        }

        if (dialogComp.onCloseEvent) {
          const handle = dialogComp.onCloseEvent.subscribe((evt: any) => {
            dialogPrime.closeDialog();
            if (evt && currentComp) {
              currentComp.onReloadTable();
            }
            handle.unsubscribe();
            dialogPrime.seflDestroy();
          });
        }

        if (dialogComp.createDynamicEvent) {
          dialogComp.createDynamicEvent.subscribe((res: any) => {
            this.createDynamicComponent(res);
          });
        }

        dialogComp.run(data);
      } else {
        const comp: any = this.renderComponent(f[0].NAME, this.viewRef);
        if (comp.onCloseEvent) {
          const handle = comp.onCloseEvent.subscribe((evt: any) => {
            if (evt && currentComp) {
              currentComp.onReloadTable();
            }
            handle.unsubscribe();
          });
        }

        if (comp.createDynamicEvent) {
          comp.createDynamicEvent.subscribe((res: any) => {
            this.createDynamicComponent(res);
          });
        }

        comp.run(data);
      }
    }
  }

  private renderComponent(component: any, target: any): any {
    const f = functionConfig.filter(fil => fil.NAME === component.trim());
    try {
      const componentFactory = this.resolver.resolveComponentFactory(f[0].COMPONENT);
      const componentRef = target.createComponent(componentFactory);
      return componentRef.instance;
    } catch (error) {
      return null;
    }
  }

  onOpenCalendar(item: any, opCalendar: any) {
    opCalendar.hide();
    const comp = this.dialogCalendar.showDialog(CalendarComponent, {
      ExtType: item.calCalendarId,
      _extWindow: null,
      _windowIdCal: null,
      _listCalendar: this.listCalendar
    }, 'Calendar');
  }

    switchLang(lang: any, opLanguage: any) {
        if (localStorage) {
            this.translate.setDefaultLang(lang.CODE);
            this.translate.use(lang.CODE);
            sessionStorage.setItem('language', lang.CODE);
            this.language = lang;
            this.selectedValue = lang.CODE;
            opLanguage.hide();
            this.changeLanguage.emit(lang.CODE);
        }
        if (sessionStorage) {
            const currentApp = sessionStorage.getItem(this.appService.hostName1 + '_CurrentApp');
            const obj = currentApp ? JSON.parse(currentApp) : null;
            this.tenTrang = this.getJsonDescriptionApp(obj.tenTrang);
        }
        this.listAppShortcut.forEach((item: any) => {
            item.titleApp = this.getJsonDescriptionApp(item.description);
        })
    }

  preventEvent(event: any) {
    event.stopPropagation();
  }

  getClient(response: any = null) {
    if (response) {
      this.iconClient = response.features.length > 0 ? this.appService.urlInit + response.features[0]['IconClient'] : '';
      const a = sessionStorage.getItem(this.appService.hostName1 + '_CurrentApp');
      if (a) {
        this.urlIconHeader = JSON.parse(a).logoApp;
      }
      this.bgClient = response.features.length > 0 ? this.appService.urlInit + response.features[0]['ImageBackGround'] : '';
      if (this.router.url === '/') { // đang ở dashboard thì dùng tên công ty
        this.nameApp = response.features[0]['ClientName'];
        this.tenTrang = response.features[0]['Description'];
        this.urlIconHeader = this.iconClient;
      }
    } else {
      let id = 0;
      const user = this.appService.currentUser;
      if (user) {
        id = JSON.parse(user).clientid;
      }
      const params = [['clientId', '=', id]];
      this.reqService.service.search({
        url: this.appService.urlWS + '/SysClients',
        where: params,
        logic: 'and'
      }).subscribe((res: any) => {
        this.iconClient = res.features.length > 0 ? this.appService.urlInit + res.features[0]['IconClient'] : '';
        const a = sessionStorage.getItem(this.appService.hostName1 + '_CurrentApp');
        if (a) {
          this.urlIconHeader = JSON.parse(a).logoApp;
        }
        this.bgClient = res.features.length > 0 ? this.appService.urlInit + res.features[0]['ImageBackGround'] : '';
        if (this.router.url === '/') { // đang ở dashboard thì dùng tên công ty
          this.nameApp = res.features[0]['ClientName'];
          this.tenTrang = res.features[0]['Description'];
          this.urlIconHeader = this.iconClient;
        }
      });
    }

  }

  btnChangePassWord() {
    this.dialogClause.title = this.translate.instant('Change password');
    this.dialogClause.isComponent = false;
    this.dialogClause.templateRef = this.tmpWhereClause;
    this.dialogClause.onShow();
  }

  formChangeClose() {
    this.dialogClause.closeDialog();
    setTimeout(() => {
      this.logout();
    }, 1000)
  }

  logout() {
    let loginPortal = JSON.parse(localStorage.getItem('loginPortal') as string) as boolean;
    var currentUser = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);
    this.authService.logout().subscribe((res: any) => {
      if (res.success) {
        if (loginPortal == true) {
          //  const currentUser = localStorage.getItem(this.appService.hostName1 + 'currentUser');
          const str =
            '&url=' + this.appService.hostName + 'login';
          const urlportalhome = currentUser.urlPortal +
            '/home/logout_portal.html?oauth_state=' +
            str;
          localStorage.removeItem('loginPortal');
          // localStorage.clear();
          sessionStorage.removeItem('localhostcurrentUser');
          sessionStorage.clear();
          window.location.href = urlportalhome;
          this.router.navigate(['login']);
        }
        else {
          // localStorage.clear();
          // sessionStorage.clear();
          this.router.navigate(['login']);
        }
      }
    });
  }


  onRemoveNotify(event: any, item: any) {
    event.stopPropagation(); // Ngăn chặn sự kiện onReadNotify
    if (item['NotificationId']) {
      this.reqService.service.delete({
        data: item,
        primaryKey: 'NotificationId',
        url: this.appService.urlWS + '/SysNotifications'
      }).subscribe(res => {
        if (res.success) {
          this.listNotify = this.listNotify.filter(fil => fil !== item);
          this.notificationCount--;
          if (this.listNotify.length === 0) {
            this.notificationCount = 0;
          }
        }
      });
    }
  }

  onReadNotify(item: any, templateNotifycation: any) {
    if (item.WindowId) {
      this.reqService.service.search({
        url: this.appService.urlWS + '/syswindows',
        where: ['windowid', '=', Number(item.WindowId)],
        logic: 'and',
      }).subscribe(res => {
        if (res.features.length > 0) {
          if (res.features[0].ApplicationId === null && res.features[0].WindowName && res.features[0].WindowName.toLowerCase().includes('job')) {
            templateNotifycation.hide();
            this.openJob(item);
            return;
          }
          const a = this.appService.application.filter((fil: any) => fil.applicationId === res.features[0].ApplicationId);
          if (a && a.length > 0) {
            this.templateNotifycation.hide();
            const obj = {
              notifyItem: item,
              windowItem: res.features[0]
            }
            if (a[0].applicationId === this.appService.ApplicationId) {
              this.appService.notifyResponse = null; // Dùng emit thì không dùng tới biến này
              this.appService.notifyOpenWindow.emit(obj);
              return;
            }
            const b = a[0];
            this.appService.ApplicationId = this.authService.appid = b.applicationId;

            const obj1 = {
              nameApp: b.applicationName,
              logoApp: b.icon,
              tenTrang: this.getJsonDescriptionApp(b.description),
              appId: b.applicationId
            };
            const string = JSON.stringify(obj1);
            sessionStorage.setItem(this.appService.hostName1 + '_CurrentApp', string);
            this.appService.objStoreAppId = obj1;

            this.appService.notifyResponse = obj; // Dùng biến này vì sau khi router xong mới bắt đầu mở menu
            this.router.navigate([b.linkUrl]);
          }
        }
      });

      if (item['IsRead'] === null) {
        item['IsRead'] = 'N';
        const data = Object.assign({}, item);
        delete data['sendDate'];
        this.reqService.service.update({
          data,
          primaryKey: 'NotificationId',
          url: this.appService.urlWS + '/SysNotifications'
        }).subscribe(res => {
          if (res.success) {
            if (this.notificationCount > 0) {
              this.notificationCount--;
            }
          }
        });
      }
    } else {
      if (item['IsRead'] === null) {
        item['IsRead'] = 'Y';
        const data = Object.assign({}, item);
        delete data['sendDate'];
        this.reqService.service.update({
          data,
          primaryKey: 'NotificationId',
          url: this.appService.urlWS + '/SysNotifications'
        }).subscribe(res => {
          if (res.success) {
            if (this.notificationCount > 0) {
              this.notificationCount--;
            }
          }
        });
      }
    }

  }

  closeDialogDetailCal() {
    this.dialogPrime.closeDialog();
  }


  initNotifications() {
    this.clearLoopNotifications();

    // Bỏ kiểu 15s request 1 lần
    // const seconds = 15; // thời gian mở lại thông báo (tính theo ms)
    // this.handleInterval = setInterval(() => {
    //     this.requestNotifications();
    // }, seconds * 1000);

    this.handleEventNotifyPush = this.appService.onNotificationPushNew.subscribe(res => {
      // Khi có sự kiện của service worker trả ra => request lại notification
      this.requestNotifications();
    });

    // Request sau để tránh trường hợp request lỗi làm interval không được khởi tạo
    this.requestNotifications();
  }

  private requestNotifications() {
    if (this.handleRequest) {
      this.handleRequest.unsubscribe();
    }
    if (this.appService.c$ && this.appService.c$.username) {
      this.reqService.switchType(this.appService.dataAccessType);
      this.handleRequest = this.reqService.service.search({
        url: this.appService.urlWS + '/SysNotifications',
        where: [
          'and',
          ['ToUsername', 'like', this.appService.c$.username],
          ['ClientId', '=', this.appService.c$.clientid],
          [
            'or',
            ['IsRead', '=', null],
            ['IsRead', '=', 'N']
          ],
          ['SendDate', '<=', this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(Date.now(), 'last')]
        ],
        // where: [],
        orderBy: ['IsRead asc', 'PriorityId desc', 'SendDate desc']
      }).subscribe(res => {
        this.handleRequest.unsubscribe();
        this.notificationCount = res.total;
        if (res.success) {
          // const list = [];
          let maxPriority = 1;
          res.features.forEach(item => {
            let date = new Date(item.SendDate);
            // Bỏ việc tạo lại date do sql-odata hiện tại trả đủ giờ
            // date = new Date(date.getTime() - this.appService.timestampOffset); // Tạo lại date (do sql-odata trả về date bị thiếu giờ - zone offset)
            item.sendDate = formatDate(date, 'dd-MM-yyyy HH:mm:ss', 'vi-VN');
            // item.PriorityId = 1 + Math.round(Math.random() * 2); // Code test priority
            if (maxPriority < item.PriorityId) {
              maxPriority = item.PriorityId;
            }
            if (item.PriorityId === 1) {
              item.bgColor = '#689F38';
            } else if (item.PriorityId === 2) {
              item.bgColor = '#FBC02D';
            } else if (item.PriorityId === 3) {
              item.bgColor = '#D32F2F';
            } else {
              item.bgColor = '#FFFFFF';
            }
          });
          this.severity = maxPriority === 3 ? 'danger' : maxPriority === 2 ? 'warning' : 'success';
          this.listNotify = res.features;
        }
      }, err => {
        this.handleRequest.unsubscribe();
      });
    } else {
      this.notificationCount = 0;
    }
  }

  clearLoopNotifications() {
    if (this.handleInterval) {
      clearInterval(this.handleInterval);
    }

    if (this.handleRequest) {
      this.handleRequest.unsubscribe();
    }
  }

  getApplication() {
    this.listAppShortcut = [];
    this.listAppShortcutSys = [];
    let icon = '';
    const arr: any = [];
    const arrSys: any = [];
    const apps = this.appService.application.filter((sys: any) => sys.isAppSystem !== 'Y');
    const appSys = this.appService.application.filter((sys: any) => sys.isAppSystem === 'Y');


    const listApss = apps;
    const listAppSys = appSys;
    listAppSys.sort((a: any, b: any) => a.orderNo - b.orderNo);
    listAppSys.forEach((item: any, index: any) => {
      if (item.linkUrl === "/um") this.appIdUm = item.applicationId;
      arrSys.push(item);
    });
    this.listAppShortcutSys = arrSys;

    // sort theo orderNo
    listApss.sort((a: any, b: any) => a.orderNo - b.orderNo);
    listApss.forEach((item: any, index: any) => {
      const _item = { ...item }
      if (_item.isAppSystem !== "G") {
        if (_item.icon) {
          _item.icon = this.baseUrl + _item.icon
        } else {
          _item.icon = this.imageDefault
        }
      }
      _item.titleApp = this.getJsonDescriptionApp(_item.description)
      arr.push(_item);
    });
    this.listAppShortcut = arr;
  }

  openApplication(app: any, isAppSys: any) {
    this.appService.ApplicationId = this.authService.appid = app.applicationId;
    const obj = {
      nameApp: app.applicationName,
      logoApp: this.appService.urlInit +'/'+ app.icon,
      // tenTrang: this.getJsonDescriptionApp(app.description),
      tenTrang: app.description,
      appId: app.applicationId
    };

    const string = JSON.stringify(obj);
    this.appService.objStoreAppId = obj;
    sessionStorage.setItem(this.appService.hostName1 + '_CurrentApp', string);
    this.urlIconHeader = this.appService.urlInit +'/' + app.icon;

    const urllink = app.linkUrl;
    if (this.router.url !== app.linkUrl) {
      this.router.navigate([urllink]);
    } else {
      this.redirectTo(urllink);
    }
  }
  getTheme(themeId: any) {
    const url = this.appService.urlGetTheme;
    this.reqService.switchType(this.appService.dataAccessType);
    return this.reqService.service.search({
      url,
      where: ['SysThemeId', '=', themeId]
    })
  }
  settingUM() {
    // this.themeService.setActiveTheme(this.)
    const a = this.listAppShortcutSys.filter((fil: any) => fil.linkUrl === '/um');
    this.appService.ApplicationId = this.authService.appid = this.appIdUm;
    const obj = {
      nameApp: 'MANAGER',
      logoApp: a && a.length > 0 ? this.appService.urlInit +'/' + a[0].icon : '',
      tenTrang: 'MANAGER',
      appId: this.appIdUm
    };
    const string = JSON.stringify(obj);
    this.appService.objStoreAppId = obj;
    sessionStorage.setItem(this.appService.hostName1 + '_CurrentApp', string);


    const url = `um/${1}`;
    this.router.navigate([url]);

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  dataSoureAttachment: any = null
  onClickHelp() {
    this.dataSoureAttachment = {
      dataSource: {
        INFORMATION: {
          SERVICE_TYPE: 'SQL',
          TABLE_ID: 36,
          KHOA_CHINH: 'ApplicationId',
          DESCR: ''
        }
      },
      currentData: {
        ApplicationId: this.appService.c$['appId']
      }
    }
    this.dialogHelp.isComponent = false;
    this.dialogHelp.title = 'Attachment File'
    this.dialogHelp.widthPanel = '80vw'
    this.dialogHelp.heightDialog = '80vh'
    this.dialogHelp.templateRef = this.tmpAttachment
    this.dialogHelp.onShow()
    // gọi ra help
    // window.open('https://coretech.vn:8088', '_blank')
  }

  @HostListener('document:keydown.F1', ['$event'])
  private onKeyDownF1(e: KeyboardEvent) {
    e.preventDefault();
    this.onClickHelp();
  }

}
