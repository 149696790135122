import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { result } from 'lodash';
import { AppService } from '../app.service';
import { SignupService } from './signup.service';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  siteKeyCapcha: string = '6LdUx54pAAAAADQrxmhgIpBMWX_tyGLOcmieV2e4'
  tokenCapcha: string = ''
  siteKeyCapchaServerSide: string = '6LdUx54pAAAAANt7Cx-qeqG5U_nr9tDbFW6U2zqD'
  @Output() onChangeModeForm: EventEmitter<any> = new EventEmitter();
  validateForm: FormGroup;
  patternEmail: any = null;
  patternPhone: any = null;
  patternName: any = null;
  urlSendMai: any = '';
  userName: any = '';
  passWord: any = '';
  contentMail: any = '';

  @ViewChild('dialogComponent', { static: false }) dialogComponent!: DialogPrimeComponent
  @ViewChild('messageSuccess', { static: false }) messageSuccess!: TemplateRef<any>

  isRegister: boolean = true;
  constructor(
    private signupService: SignupService,
    private appService: AppService,
    private http: HttpClient
  ) {
    this.validateForm = new FormGroup({
      hoTen: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      dienThoai: new FormControl('', [Validators.required, Validators.pattern("[0-9 ]{9,11}")]),
      tenCongTy: new FormControl('', [Validators.required]),
      linhVuc: new FormControl('', [Validators.required]),
      moTa: new FormControl('', [Validators.required])
    }, { updateOn: 'change' });
  }

  resolved(evt: any) {

  }
  handleCaptchaResponse(evt: any) {
    console.log(evt);
    this.tokenCapcha = evt.response
  }
  onBlurName(evt: any) {
    this.patternName = this.validateForm.get('hoTen');
  }

  onBlurEmail(evt: any) {
    this.patternEmail = this.validateForm.get('email');
  }

  onBlurPhone(evt: any) {
    this.patternPhone = this.validateForm.get('dienThoai');
  }

  async ngOnInit() {
    this.http.get('assets/config-payment/configPaymentTrial.json').subscribe((data: any) => {
      this.urlSendMai = this.appService.urlInit + data.urlSendMailCreateUserSuccess;
      this.userName = data.user;
      this.passWord = data.pass;
      this.contentMail = data.contentMailTrail
    });
  }


  handleOpenMessageDialog() {
    this.dialogComponent.isComponent = false
    this.dialogComponent.isShowTitle = false;
    this.dialogComponent.onShow();
    this.dialogComponent.templateRef = this.messageSuccess
    this.dialogComponent.onClose.subscribe(() => {
      this.onChangeModeForm.emit('login');
    })
  }

  handleCloseMessageDialog() {
    this.dialogComponent.closeDialog()
  }
  handlerModeLogin() {
    this.onChangeModeForm.emit('login');
  }

  CheckValidateForm() {
    this.patternName = this.validateForm.get('hoTen');
    this.patternEmail = this.validateForm.get('email');
    this.patternPhone = this.validateForm.get('dienThoai');
    if (this.patternName && this.patternName.errors && (this.patternName.errors.required || this.patternName.errors.pattern)) {
      return false;
    }

    if (this.patternEmail && this.patternEmail.errors && (this.patternEmail.errors.required || this.patternEmail.errors.pattern)) {
      return false;
    }
    if (this.patternPhone && this.patternPhone.errors && (this.patternPhone.errors.required || this.patternPhone.errors.pattern)) {
      return false;
    }
    return true;
  }


  // handleSendMail(data: any) {
  //   let { HoTen, DienThoai, Email, LinhVuc, MaCongTy, MoTa, TenCongTy } = data;
  //   let paramsendMail = {
  //     NAME: TenCongTy,
  //     EMAIL: Email,
  //     USER: this.userName,
  //     PASS: this.passWord,
  //     LINKAPP: this.appService.urlReport,
  //     CONTENT: this.contentMail
  //   }
  //   this.appService.reqUrl(this.urlSendMai, paramsendMail).subscribe(evt => {

  //     // this.appService.createMessage('success', this.appService.getMessage('0046'));
  //     this.handleOpenMessageDialog();
  //   });
  // }

  handlerRegister() {
    if (this.CheckValidateForm()) {
      // const paramsQuery = [['Email', '=', this.validateForm.controls['email'].value], ['DienThoai', '=', this.validateForm.controls['dienThoai'].value]];
      // this.signupService.queryUserForEmaiAndPhone(paramsQuery).subscribe((user: any) => {
      const params: any = {};
      // Object.keys(this.validateForm.value).forEach(key => {
      //   params[key] = this.validateForm.controls[key].value;
      // });

      params['cap'] = this.tokenCapcha
      params['HoTen'] = this.validateForm.controls['hoTen'].value
      params['Email'] = this.validateForm.controls['email'].value
      params['DienThoai'] = this.validateForm.controls['dienThoai'].value
      params['TenCongTy'] = this.validateForm.controls['tenCongTy'].value
      params['LinhVuc'] = this.validateForm.controls['linhVuc'].value
      params['MoTa'] = this.validateForm.controls['moTa'].value
      params['IsActive'] = "N";
      params['MaCongTy'] = "MaCongTy";
      // if (user.total > 0) {
      //   this.handleSendMail(user.features[0])
      //   this.appService.createMessage('error', this.appService.getMessage('0051'));
      // } else {
      this.isRegister = false
      this.signupService.addUser(params).subscribe((results: any) => {
        // this.handleSendMail(results.features[0])
        if (results.success) {
          // this.appService.createMessage('success', this.appService.getMessage('0046'));
          this.handleOpenMessageDialog();
        } else {
          this.appService.createMessage('error', this.appService.getMessage('0045'));
        }
        this.isRegister = true;
      }, (err: any) => {
        console.log('error', err);
        this.isRegister = true;
      });
      //}
      //});
    }
  }
}
