import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Button } from 'primeng/button';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @ViewChild('btn') btn!: Button | any
    typeColor = '';
    @Input() labelName = 'Button';
    @Input() typeButton = 'save'; // reset, save, cancel, field
    @Input() icon = '';
    @Input() iconPos = 'left';
    @Input() disabledCtrl = false;
    @Input() isOnlyIcon = false;
    // @Input() isActive
    @Output() clickEvent: EventEmitter<any> = new EventEmitter();

    private count = 0;

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {}

    onClick(evt: any) {
        // if (this.count % 2) {
        //     this.typeButton = 'primary';
        // } else {
        //     this.typeButton = 'default';
        // }
        // this.count++;
        if(!this.disabledCtrl) {
            this.clickEvent.emit(evt);
        }
       
    }
}
