import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { RequestService, InsertResponse} from 'app/core/services/request.service';

@Component({
  selector: 'app-tao-hang-muc',
  templateUrl: './tao-hang-muc.component.html',
  styleUrls: ['./tao-hang-muc.component.scss'],
  providers: [RequestService]
})
export class TaoHangMucComponent implements OnInit {
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  MaLoaiTaiSan: any = null;
  public menuList: any[] = [];
  _currentItemApplication: any = null;
  public showAll = false;
  constructor(
    public reqService: RequestService,
    public appService: AppService,
    private resolver: ComponentFactoryResolver,
  ) { 
    reqService.switchType('clouddata');
  }

  ngOnInit(): void {
    
  }

  run(data: any, isReload: any) { 
    this.menuList = [];
    // console.log('data', data, 'isReload', isReload);
    // console.log('idKiemTra',data.lookup[data.dataSource.INFORMATION.PARENT_ID].INFORMATION.PARENT_DATA);
    const IdKiemTra = data.lookup[data.dataSource.INFORMATION.PARENT_ID].INFORMATION.PARENT_DATA.idKiemTra;
    const IdTaiSan = data.parentData.maTaiSan;
    const param = ['idLoaiTaiSan', '=', data.parentData.maLoaiTaiSan];
    this.reqService.service.search(
      {
      url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/DmHangMuc',
      where: param
      }
    ).subscribe(res => { 
     // console.log('res', res);
      if (res.success) {
        if (res.features.length > 0) { 
          res.features.forEach(e => { 
            this.menuList.push({
              checked: false,
              idHangMuc: e.idHangMuc,
              tenHangMuc: e.tenHangMuc,
              idKiemTra: IdKiemTra,
              idTaiSan: IdTaiSan
            });
          });
          this.Filter_DmHangMuc(IdKiemTra);
          
        } else {
          this.appService.alert('Không có hạng mục kiểm tra tương ứng với tài sản !!!');
          this.onCloseEvent.emit(true);
        }

      } else {
        this.appService.alert('Something went wrong!!!', 'error');
        this.onCloseEvent.emit(true);
      }
    }, err => {
      this.appService.alert('Something went wrong!!!', 'error');
      this.onCloseEvent.emit(true);
    });
  }

  Filter_DmHangMuc (IdKiemTra: number){ 
    const Param = ['idKiemTra', '=', IdKiemTra];
     this.reqService.service.search(
      {
      url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/MtHangMucKiemTra',
      where: Param
      }
    ).subscribe(res2 => {  
      // console.log('res2', res2, 'this.menuList', this.menuList);
      if(res2.features.length > 0){
        res2.features.forEach(e => {  
          // console.log('e', e);
          this.menuList = this.menuList.filter(function(i) {
            return (e.idHangMuc !== i.idHangMuc);
          });
        })
        if(this.menuList.length === 0){
          this.appService.alert('Hạng mục kiểm tra đã thêm mới !!!');
          this.onCloseEvent.emit(true);
        }
      }
    }, err => {
      this.appService.alert('Something went wrong!!!', 'error');
      console.log('err', err);
      this.onCloseEvent.emit(true);
    });

  }

  onSave() {
    //console.log('onSave', this.menuList);
    const mes = 'Tài sản có ' + this.menuList.length +' hạng mục kiểm tra. Thêm mới tất cả !!!';
    this.appService.confirm(mes).subscribe((conf) => {
      if (conf) {
        this.menuList.forEach(f => { 
          const Param ={
            idHangMuc: f.idHangMuc,
            idKiemTra: f.idKiemTra,
            idTaiSan: f.idTaiSan,
            daKiemTra: (f.checked)? 'Y': null
          }
          //console.log('Param', Param);
          this.reqService.service.insert({
            url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/MtHangMucKiemTra',
            data: Param
            //primaryKey: infor.KHOA_CHINH
          }).subscribe((val: InsertResponse) => {
              // console.log('val', val)
              this.onCloseEvent.emit(true);
              this.appService.notification(
                this.appService.getMessage('0001'),
                'success'
              );
          },(err) => {
            this.onCloseEvent.emit(true);
                this.appService.notification(
                    this.appService.getMessage('0002'),
                    'error'
                );
          });
        });
      } else{
        this.onCloseEvent.emit(true);
      }
    });
  }

  
}
