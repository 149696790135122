<div class="core-datetime-class" [ngClass]="{'core-labelleft-class': isLabelLeft, 'NoLabelLeft': !isLabelLeft}"
    [style.width.px]="controlWidth">
    <!-- <label for="icon" style="padding-right: 5px;">{{labelName}}</label> -->
    <div class="titleClass" *ngIf="showLabel" [style.width.px]="labelWidth" [ngClass] = "{'label-readonly': isReadOnly}">
        {{labelName || "No title" }}
        <span *ngIf="required" [style.color]="'red'">&nbsp;*</span>
    </div>
    <ng-container *ngIf="hasFormCtrl">
        <span style="width: 100%; display: flex; align-items: center;">
            <p-calendar #pCalender [dateFormat]="c$.dateFormat" (onSelect)="onSelectDate($event)" [formControl]="ctrl"
                [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}" [showIcon]="showIcon"
                [showOnFocus]="!disabledCtrl && !isReadOnly" [readonlyInput]="disabledCtrl" [showSeconds] = "true" (onFocus) = "onFocusEle($event)"
                [disabled]="(!disabledCtrl && isReadOnly) || disabledCtrl" [placeholder]="placeholder" [appendTo]="appendTo"
                [showTime]="isShowTime" (onBlur)="onDateBlur()"></p-calendar>
            <ng-container *ngIf="hasButton">
                <button pButton pRipple type="button" icon="pi pi-plus" (click)="btnCalcClick.emit()"
                    style="height: 30px;width: 30px;"></button>
            </ng-container>
        </span>

    </ng-container>
    <ng-container *ngIf="!hasFormCtrl">
        <span style="width: 100%; display: flex; align-items: center;">
            <p-calendar #pCalender [dateFormat]="c$.dateFormat" (onSelect)="onSelectDate($event)" 
                [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}" [showIcon]="showIcon"
                [readonlyInput]="disabledCtrl" [showOnFocus]="!disabledCtrl && !isReadOnly" [showSeconds] = "true"
                [disabled]="(!disabledCtrl && isReadOnly) || disabledCtrl" (onBlur)="blurEvent.emit()" [placeholder]="placeholder"
                [appendTo]="appendTo" [showTime]="isShowTime" [(ngModel)]='valueModel'></p-calendar>
            <ng-container *ngIf="hasButton">
                <button pButton pRipple type="button" icon="pi pi-plus" (click)="btnCalcClick.emit()"
                    style="height: 30px;width: 30px;"></button>
            </ng-container>
        </span>

    </ng-container>


</div>