import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { RequestService } from '../services/request.service';
import { CoreWindowService } from '../core-window/core-window.service';
import { take } from 'rxjs/operators';
import { PluginService } from 'app/plugins/plugins.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';
import { WorkflowManagerComponent } from 'app/workflow/workflow-manager/workflow-manager.component';
import { AddBPMNComponent } from 'app/workflow/menu-tool/add-job/add-bpmn.component';
import { ViewJobComponent } from 'app/workflow/menu-tool/view-job/view-job-workflow.component';
import { RecallJobComponent } from 'app/workflow/menu-tool/restore-job/recall-job.component';
import { GetJobComponent } from 'app/workflow/menu-tool/get-job/get-job.component';
import { BackJobComponent } from 'app/workflow/menu-tool/back-job/back-job.component';
import { RunBPMNComponent } from 'app/workflow/menu-tool/run-job/run-bpmn-workflow.component';
import { TrainingPriceCalculationComponent } from 'app/customize/quotation/training-price-calculation/training-price-calculation.component';
import { ProcessQuotationComponent } from 'app/customize/quotation/process-quotation/process-quotation.component';
import { LetterFormComponent } from 'app/customize/quotation/letter-form/letter-form.component';
import { TaoHangMucComponent } from 'app/customize/speedmain/tao-hang-muc/tao-hang-muc.component';
import { ThemTaiSanComponent } from 'app/customize/speedmain/them-tai-san/them-tai-san.component';
import { AddSupplierItemsComponent } from 'app/customize/TrackingSystem/add-supplier-items/add-supplier-items.component';
import { TaoHaoMonComponent } from 'app/customize/speedmain/tao-hao-mon/tao-hao-mon.component';
import { ReportViewerComponent } from 'app/reports/report-viewer/report-viewer.component';
import { CalendarComponent } from 'app/calendar/calendar.component';
import { GroupportalComponent } from 'app/um/functions/application/groupportal/groupportal.component';
import { ChoosedatabaseComponent } from 'app/um/functions/application/choosedatabase/choosedatabase.component';
import { AttachmentsComponent } from '../attachments/attachments.component';
declare function run(): any;
@Component({
    selector: 'app-core-form',
    templateUrl: './core-form.component.html',
    styleUrls: ['./core-form.component.scss'],
    providers: [RequestService]
})
export class CoreFormComponent implements OnInit, OnDestroy {

    // attachment cũ
    @ViewChild('attachment', { static: false }) appAttachment!: AttachmentsComponent;
    public modeAttachment = 'view';   
    public saveAttachmentEmit: EventEmitter<any> = new EventEmitter();
    //
    @ViewChild('inputFile') inputFile!: ElementRef;
    @ViewChild('dialogComponent') dialogComponent!: DialogPrimeComponent
    @Input() mode = '';
    @Input() dataSource: any = null;
    @Input() bindData: any = null;
    @Input() isModeView = false; // mặc định không phải chế độ xem
    @Input() parent: any = null

    public fieldan: any[] = []; // field ẩn
    public fielddisable: any[] = [];
    // khởi tạo các biến cho form
    public isLoadFormSuccess = false;
    public formGroup: FormGroup = new FormGroup({});
    public tableColumnDefination = [];
    public nzGutter = 10;
    public fieldGroup: any = {};
    public fieldNoGroup: any[] = [];
    public hasFieldGroup = false;
    public checktabStruct = true;
    public hasAttachment = false;

    public typeOfFormControl = 'default';
    public okTitle = '';
    public dataEmit: EventEmitter<any> = new EventEmitter();
    public updateForm: EventEmitter<any> = new EventEmitter();
    /** Dữ liệu của các combo box */
    public lookupCondition: any = null;
    /** Hiển thị button thêm hay clear, mặc định giá trị là false: Ẩn button */
    public isShowButton = false;

    private isFirstTime = true; // Lần đầu khởi tạo?
    private list: any[] = []; // mảng các field

    private preventRelateField = true; // Ngăn chặn sự kiện relate field khi bind 1 cụm dữ liệu vào form (do bất đồng bộ)
    private listUnsubscribe: any[] = [];


    public mockuplayout: any = null;

    constructor(
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private coreWindowService: CoreWindowService,
        private reqService: RequestService,
        private pluginService: PluginService,
        private translate: TranslateService
    ) { }

    handleLanguageChange: any = null;
    ngOnInit(): void {
        this.handleLanguageChange = this.translate.onLangChange.subscribe(res => {
            if (res && this.list.length > 0) {
                this.updateLabelControl();
            }
        });
    }

    private updateLabelControl() {
        if (!this.dataSource) {
            return;
        }
        this.list.forEach(item => {
            const findField = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === item.FIELD_NAME);
            try {
                const descr = JSON.parse(findField[0].alias);
                const text = descr[this.translate.currentLang];
                item.FIELD_LABEL = text;
            } catch (error) {

            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataSource && this.isFirstTime) {
            this.isFirstTime = false;
            if (this.dataSource !== undefined && this.dataSource !== null) {
                if (this.dataSource.FIELD_LIST.length > 0) {
                    // lấy thông số về Primary key của tab cha => chưa xong
                    // Tạo lookup
                    this.lookupCondition = this.appService.getLookup();
                    this.initLayout();
                }
            }
        }

        if (changes.bindData) {
            if (this.bindData !== null && this.bindData !== undefined) {
                this.bindDataToForm(this.bindData);
            } else {
                // clear form
                this.formGroup.reset();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.listUnsubscribe) {
            this.listUnsubscribe.forEach(item => {
                item.unsubscribe();
            })
        }

        if (this.handleLanguageChange) {
            this.handleLanguageChange.unsubscribe();
        }
    }

    public initLayout() {
        this.isLoadFormSuccess = false;
        this.cd.detectChanges();
        if (this.dataSource.LAYOUT_CONFIG !== null && this.dataSource.LAYOUT_CONFIG.rows.length > 0) {
            this.typeOfFormControl = 'layout';
            this.initFormControlByLayout();
        } else {
            this.typeOfFormControl = 'default';
            this.initFormControlByDefault();
        }
    }

    getDefaultValue(defaultvalue: any) {
        let val = null
        try {
            val = eval(defaultvalue);
            if (val == defaultvalue) {
                // trường hợp default là 1 string số: kiểu dạng '0', '1', .... thì eval('0') = 0 => giữ nguyên trạng
                val = defaultvalue;
            }
        } catch (error) {
            val = defaultvalue;
        }
        return val
    }

    private initFormControlByDefault() {
        const group: any = {};
        this.fieldGroup = {};
        this.fieldNoGroup = [];
        this.hasFieldGroup = false;
        this.tableColumnDefination = [];
        this.hasAttachment = this.dataSource.INFORMATION.HASATTACHMENT === 'S';
        const fieldList = this.dataSource.FIELD_LIST;
        const list: any[] = [];
        const layout = !!this.dataSource.INFORMATION.LAYOUT_COLUMN ? this.dataSource.INFORMATION.LAYOUT_COLUMN : 2;
        fieldList.forEach((item: any) => {
            if (item.isdisplay === 'Y') { // Chỉ khởi tạo control cho các field có isdisplay === 'Y'
                let chbType = 'string'; // kiểu giá trị cho control checkbox
                if (item.columntype === 'number') {
                    chbType = 'number';
                } else if (item.columntype === 'boolean') {
                    chbType = 'boolean'
                }

                const obj = {
                    FIELD_ID: item.fieldid,
                    FIELD_NAME: item.fieldname ? item.fieldname.trim() : item.fieldname,
                    FIELD_LABEL: item.alias && item.alias !== '' ? item.alias.trim() : item.fieldname,
                    FIELD_TYPE: item.fieldtype,
                    FIELD_TABLELINK: item.domainid,
                    FIELD_REQUIRED: item.isrequire === 'Y',
                    FIELD_DISABLED: this.isModeView,
                    FIELD_LENGTH: item.fieldlength,
                    FIELD_SPAN: 12 / layout,
                    FIELD_ISDOMAIN: item.isfromdomain === 'Y',
                    FIELD_PK: this.dataSource.INFORMATION.KHOA_CHINH === item.fieldname,
                    FIELD_PARENT_ID: item.parentfieldid,
                    FIELD_WHEREFIELDNAME: item.wherefieldname,
                    FIELD_BINDFIELDNAME: item.bindfieldname,
                    FIELD_PLACEHOLDER: item.placeholder ?? '',
                    FIELD_VFORMAT: item.vformat,
                    FIELD_DISPLAY_LOGIC: item.displaylogic && item.displaylogic !== '' ? item.displaylogic : null,
                    FIELD_DISABLE_LOGIC: item.disablelogic && item.disablelogic !== '' ? item.disablelogic : null,
                    FIELD_DEFAULT_VALUE: item.defaultvalue ?? null,
                    FIELD_READ_ONLY: item.isreadonly === 'Y' ? true : false,
                    FIELD_CHB_TYPE: chbType,
                    FIELD_ISUNIQUE: item.isunique === 'Y',
                    NOT_SEND: item.fieldautodata === 'Y',
                    DISPLAY_LENGTH: item.displaylength,

                    FIELD_FILTER: {
                        FOREIGNTABLE: item.foreigntable,
                        FOREIGNTABLE_ID: item.foreigntableid,
                        COLUMNKEY: item.columnkey,
                        COLUMNDISPLAY: item.columndisplay,
                        COLUMNCODE: item.columncode,
                        TREECOLUMN: item.treecolumn,
                        WHEREFIELDNAME: item.wherefieldname, // sử dụng cho control select
                        BINDFIELDNAME: item.bindfieldname,
                        WHERECLAUSE: item.whereclause,
                        FOREIGNWINDOW_ID: item.foreignwindowid,
                        FIELD_ISDOMAIN: item.isfromdomain === 'Y',
                        FIELD_PARENT_ID: item.parentfieldid,
                        // Dùng cho filter-input (phân biệt khi có 2 control khác fieldname, nhưng đều trỏ vào chung 1 bảng)
                        FIELD_NAME: item.fieldname ? item.fieldname.trim() : item.fieldname,
                        SERVICE_TYPE: item.foreigntableservicetype && item.foreigntableservicetype !== '' ?
                            item.foreigntableservicetype : this.dataSource.INFORMATION.SERVICE_TYPE // Control sử dụng kiểu service type nào

                    },
                    FIELD_CHILD: item.children,
                    FIELD_INFO: item.calculationInfos,
                    FIELD_CALCULATION_LABEL: item.calculationlabel
                };
                if (item.isunique === 'Y') {
                    // obj.FIELD_SPAN = 12;
                    // Sửa lại thành khi có isunique thì xuống dòng
                }
                if (item.colspan) {
                    obj.FIELD_SPAN = obj.FIELD_SPAN * item.colspan;
                }
                list.push(obj);

                // Default value
                let val = null;
                // if (obj.FIELD_DEFAULT_VALUE) {
                //     // if (typeof (obj.FIELD_DEFAULT_VALUE) === 'string' && obj.FIELD_DEFAULT_VALUE.startsWith('c$')) {
                //     //     val = eval(obj.FIELD_DEFAULT_VALUE);
                //     // } else {
                //     //     val = obj.FIELD_DEFAULT_VALUE;
                //     // }

                //     try {
                //         val = eval(obj.FIELD_DEFAULT_VALUE);
                //     } catch (error) {
                //         val = obj.FIELD_DEFAULT_VALUE;
                //     }
                // }

                if (
                    item.fieldgroup !== null &&
                    item.fieldgroup !== '' &&
                    item.fieldgroup !== undefined
                ) {
                    this.hasFieldGroup = true;
                    if (this.fieldGroup[item.fieldgroup]) {
                        this.fieldGroup[item.fieldgroup].push(obj);
                    } else {
                        // nếu chưa có key thì tạo key là array, sau đó push obj mới tạo vào
                        this.fieldGroup[item.fieldgroup] = [];
                        this.fieldGroup[item.fieldgroup].push(obj);
                    }
                    if (obj.FIELD_TYPE !== 'html') {
                        group[obj.FIELD_NAME] = new FormControl(val);
                    }

                } else {
                    this.fieldNoGroup.push(obj);
                    if (obj.FIELD_TYPE !== 'html') {
                        group[obj.FIELD_NAME] = new FormControl(val);
                    }
                }

                if (obj.FIELD_INFO !== undefined) {
                    if (obj.FIELD_INFO[0].func) {
                        this.coreWindowService.calcObservable.subscribe(message => {
                            if (message != null && message !== 'NaN' && this.mode !== 'add') {
                                group[obj.FIELD_NAME].setValue(message);
                            }
                        });
                    }
                }
            } else {
                // Không khởi tạo control nhưng vẫn tạo formcontrol và bind dữ liệu vào
                // Default value
                let val = null;
                // if (item.defaultvalue) {
                //     try {
                //         val = eval(item.defaultvalue);
                //     } catch (error) {
                //         val = item.defaultvalue;
                //     }
                // }
                group[item.fieldname] = new FormControl(val);
            }
        });
        this.formGroup = new FormGroup(group);

        // Xử lý liên quan tới relate field (trường phụ thuộc)
        list.forEach(field => {
            if (field.FIELD_PARENT_ID !== null) {
                const parents = list.filter(fil => fil.FIELD_ID === field.FIELD_PARENT_ID);
                if (parents.length > 0) {
                    const parent = parents[0];
                    const handle = group[parent.FIELD_NAME].valueChanges.subscribe((res: any) => {

                        if (this.preventRelateField) {
                            return;
                        }

                        if (res) {
                            // trường hợp relate field
                            if (field.FIELD_WHEREFIELDNAME && field.FIELD_WHEREFIELDNAME !== '') {
                                let _val = this.objValueForm[parent.FIELD_NAME]

                                if (field.FIELD_TYPE === 'qrcode') {
                                    _val = this.objValueForm[parent.FIELD_NAME] !== undefined ? this.objValueForm[parent.FIELD_NAME] : this.objValueForm;
                                }
                                group[field.FIELD_NAME].setValue(_val);
                            } else if (field.FIELD_BINDFIELDNAME && field.FIELD_BINDFIELDNAME !== '') {
                                const REGEXP = /\[([^\][]*)]/g;
                                const names = field.FIELD_BINDFIELDNAME.match(REGEXP);
                                let cloneBindFieldName: string = field.FIELD_BINDFIELDNAME;
                                if (names && names.length > 0) {
                                    // FIELD_BINDFIELDNAME là biểu thức tính toán
                                    names.forEach((name: string) => {
                                        let fieldName = name.substring(1, name.length - 1);
                                        if (fieldName.includes('.')) {
                                            const tabName = fieldName.split('.')[0]; // Tên tab
                                            const fieldNameInTab = fieldName.split('.')[1]; // Tên field trong tab
                                            for (let i in this.coreWindowService.lookupTab) {
                                                const tab = this.coreWindowService.lookupTab[i];
                                                if (tab.INFORMATION.TABLE_NAME === tabName) {
                                                    const _val = tab.INFORMATION.CURRENT_DATA ? tab.INFORMATION.CURRENT_DATA[fieldNameInTab] : null;
                                                    if (_val) {
                                                        cloneBindFieldName = cloneBindFieldName.replace(fieldName, _val);
                                                    }
                                                }
                                            }
                                        } else {
                                            cloneBindFieldName = cloneBindFieldName.replace(fieldName, this.objValueForm[parent.FIELD_NAME][fieldName]);
                                        }
                                    });
                                    const value = Math.round(eval(cloneBindFieldName));
                                    group[field.FIELD_NAME].setValue(value);
                                } else {
                                    const fieldListCheck = ['search', 'select', 'treeselect'];
                                    if (!this.objValueForm[parent.FIELD_NAME] || this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME] === undefined) {
                                        return;
                                    }
                                    // FIELD_BINDFIELDNAME không phải biểu thức tính toán
                                    // kiểm tra kiểu control của field
                                    if (fieldListCheck.includes(field.FIELD_TYPE)) {
                                        // trường hợp truyền obj
                                        group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME]);
                                    } else {
                                        // trường hợp truyền giá trị: string/number/boolean
                                        group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME]);
                                    }
                                }
                            }
                        } else {
                            // Không có giá trị tại trường cha => xóa trường con
                            group[field.FIELD_NAME].reset();
                        }
                        group[field.FIELD_NAME].markAsDirty();
                    });
                    this.listUnsubscribe.push(handle);
                }
            }
            if (field.FIELD_CHILD !== undefined) {
                field.FIELD_CHILD.forEach((child: any) => {
                    if (child.calculation) {
                        const handle = group[field.FIELD_NAME].valueChanges.subscribe((res: any) => {
                            const _v = [];
                            for (let v = 0; v < child.calculationInfos.length; v++) {
                                const info = child.calculationInfos[v];

                                if (info.tab !== undefined) { // Nếu có tab => giá trị lấy từ tab, info.tab có giá trị là number
                                    const tabss = this.coreWindowService.lookupTab[info.tab];
                                    const _val = tabss.INFORMATION.CURRENT_DATA ? tabss.INFORMATION.CURRENT_DATA[info.field] : null;
                                    if (_val) {
                                        _v.push(_val);
                                    }
                                } else {
                                    if (group[info.field]) {
                                        _v.push(group[info.field].value);
                                    }
                                }
                            }
                            const value = eval(child.calculation);
                            if (!value || value.toString() === 'NaN') {
                                return;
                            }

                            if (group[child.fieldname]) {
                                group[child.fieldname].setValue(value, { emitEvent: false });
                            }
                        });
                        this.listUnsubscribe.push(handle);
                    }
                });
            }

            const fieldDpLogic = field.FIELD_DISPLAY_LOGIC;
            const arrayDpLogic = JSON.parse(fieldDpLogic);
            if (fieldDpLogic && Array.isArray(arrayDpLogic)) {
                if (arrayDpLogic && arrayDpLogic.length > 0) {
                    arrayDpLogic.forEach((item: any) => {
                        if (item && Array.isArray(item)) {
                            const fieldname = item[0];

                            if (group[fieldname] !== undefined) {
                                const handle = group[fieldname].valueChanges.subscribe((res: any) => {
                                    this.checkLogic(arrayDpLogic, field, 'FIELD_HIDE');
                                });
                                this.checkLogic(arrayDpLogic, field, 'FIELD_HIDE');
                                this.listUnsubscribe.push(handle);
                            } else { // không có field này được tạo trong control => là field ẩn
                                this.fieldan.push(field);
                            }
                        }
                    });
                }
            }

            if (field.FIELD_DISABLE_LOGIC) {
                const arrayDisableLogic = JSON.parse(field.FIELD_DISABLE_LOGIC);
                if (Array.isArray(arrayDisableLogic)) {
                    arrayDisableLogic.forEach(logic => {
                        if (logic && Array.isArray(logic)) {
                            const fieldname = logic[0];
                            if (group[fieldname] !== undefined) {
                                const handle = group[fieldname].valueChanges.subscribe((res: any) => {
                                    if (this.preventRelateField) {
                                        return;
                                    }
                                    this.checkLogic(arrayDisableLogic, field, 'FIELD_READ_ONLY');
                                });
                                this.listUnsubscribe.push(handle);
                            }
                            this.fielddisable.push(field);
                        }
                    })
                }
            }
        });

        this.fieldan.forEach((element: any) => {
            if (element.FIELD_NAME === 'UserReject' || element.FIELD_NAME === 'DateReject' || element.FIELD_NAME === 'DateApproved' || element.FIELD_NAME === 'UserApproved' || element.FIELD_NAME === 'DateCreate') {
                element.FIELD_HIDE = true;
            }
            const fieldDpLogic = element.FIELD_DISPLAY_LOGIC;
            const arrayDpLogic = JSON.parse(fieldDpLogic);
            if (fieldDpLogic && Array.isArray(arrayDpLogic)) { // FIELD_DISPLAY_LOGIC có dạng [[key, operator, value]]
                this.checkLogic(arrayDpLogic, element, 'FIELD_HIDE');
            }
        });

        if (this.mode !== 'add') {
            // Xử lý liên quan tới relate field nhưng là liên quan giữa 2 tab với nhau
            this.checkParentFromAnotherTab();
        }

        this.list = list;
        this.updateLabelControl();
        this.isLoadFormSuccess = true;
    }

    // Kiểm tra liên kết cha con từ tab khác
    private checkParentFromAnotherTab() {
        let listAll: any[] = [];
        for (let key in this.coreWindowService.lookupTab) {
            if (key !== this.dataSource.INFORMATION.TAB_ID.toString()) {
                const dataSource = this.coreWindowService.lookupTab[key];
                listAll = listAll.concat(dataSource.FIELD_LIST);
            }
        }

        let list: any[] = this.dataSource.FIELD_LIST.filter((fil: any) => fil.parentfieldid !== null);
        list.forEach(field => {
            const checked = listAll.filter(fil => fil.fieldid === field.parentfieldid);
            // Checked dùng để tìm ra cấu hình của trường cha ở các tab khác
            if (checked.length > 0) {
                for (let key in this.coreWindowService.lookupTab) {
                    const dataSource = this.coreWindowService.lookupTab[key];
                    const checked2 = dataSource.FIELD_LIST.filter((fil: any) => fil.fieldid === checked[0].fieldid);
                    if (checked2.length > 0) {
                        // checked2 dùng để kiểm tra xem DataSource nào chưa trường cha
                        if (!dataSource.INFORMATION.CURRENT_DATA) {
                            // Không có CURRENT DATA là do lần đầu init table => dữ liệu chưa load xong
                            return;
                        }
                        const res = dataSource.INFORMATION.CURRENT_DATA[checked2[0].fieldname];
                        let _value: any = {};
                        _value[checked2[0].fieldname] = res;
                        if (this.mode !== 'add') {
                            _value = this.dataSource.INFORMATION.CURRENT_DATA;
                            if (_value) {
                                _value[checked2[0].fieldname] = res;
                            }
                        }
                        if (res) {
                            // trường hợp relate field
                            if (field.wherefieldname && field.wherefieldname !== '') {
                                // WHEREFIELDNAME không phải biểu thức tính toán
                                let _val = res;

                                if (field.FIELD_TYPE === 'qrcode') {
                                    _val = res[field.wherefieldname] !== undefined ? res[field.wherefieldname] : res;
                                }

                                this.formGroup.controls[field.fieldname].setValue(_value);
                            } else if (field.bindfieldname && field.bindfieldname !== '') {

                            }
                        } else {
                            // Clear?
                        }
                    }
                }
            }
        });
    }

    objValueForm: any = {}
    onReturnObjValue(data: any, field: any) {
        this.objValueForm[field.FIELD_NAME] = data;
    }

    private initFormControlByLayout() {
        let group: any = {};
        const layout = this.dataSource.LAYOUT_CONFIG;
        this.hasAttachment = this.dataSource.INFORMATION.HASATTACHMENT === 'S';
        // this.hasAttachment = true;
        this.list = [];
        this.fieldan = [];
        this.fielddisable = [];
        // Đã đệ quy ở hàm createForm
        this.fieldNoGroup = this.createForm(group, layout.rows);
        this.dataSource.FIELD_LIST.forEach((item: any) => {
            if (!group[item.fieldname]) {
                // Không khởi tạo control nhưng vẫn tạo formcontrol và bind dữ liệu vào
                // Default value
                let val = null;
                // if (item.defaultvalue) {
                //     try {
                //         val = eval(item.defaultvalue);
                //     } catch (error) {
                //         val = item.defaultvalue;
                //     }
                // }
                group[item.fieldname] = new FormControl(val);
            }
        });
        // this.rebuildStructureLayout()

        // Xử lý liên quan tới relate field (trường phụ thuộc)
        this.list.forEach((field: any) => {
            if (field.FIELD_PARENT_ID !== null) {
                const parents = this.list.filter(fil => fil.FIELD_ID === field.FIELD_PARENT_ID);
                if (parents.length > 0) {
                    const parent = parents[0]; // cấu hình field cha của control
                    const handle = group[parent.FIELD_NAME].valueChanges.subscribe((res: any) => {
                        if (this.preventRelateField) {
                            return;
                        }

                        if (res) {
                            // trường hợp relate field
                            if (field.FIELD_WHEREFIELDNAME && field.FIELD_WHEREFIELDNAME !== '') {
                                let _val = this.objValueForm[parent.FIELD_NAME]

                                if (field.FIELD_TYPE === 'qrcode') {
                                    _val = this.objValueForm[parent.FIELD_NAME] !== undefined ? this.objValueForm[parent.FIELD_NAME] : this.objValueForm;
                                }
                                group[field.FIELD_NAME].setValue(_val);
                            } else if (field.FIELD_BINDFIELDNAME && field.FIELD_BINDFIELDNAME !== '') {
                                const REGEXP = /\[([^\][]*)]/g;
                                const names = field.FIELD_BINDFIELDNAME.match(REGEXP);
                                let cloneBindFieldName: string = field.FIELD_BINDFIELDNAME;
                                if (names && names.length > 0) {
                                    // FIELD_BINDFIELDNAME là biểu thức tính toán
                                    names.forEach((name: string) => {
                                        let fieldName = name.substring(1, name.length - 1);
                                        if (fieldName.includes('.')) {
                                            const tabName = fieldName.split('.')[0]; // Tên tab
                                            const fieldNameInTab = fieldName.split('.')[1]; // Tên field trong tab
                                            for (let i in this.coreWindowService.lookupTab) {
                                                const tab = this.coreWindowService.lookupTab[i];
                                                if (tab.INFORMATION.TABLE_NAME === tabName) {
                                                    const _val = tab.INFORMATION.CURRENT_DATA ? tab.INFORMATION.CURRENT_DATA[fieldNameInTab] : null;
                                                    if (_val) {
                                                        cloneBindFieldName = cloneBindFieldName.replace(fieldName, _val);
                                                    }
                                                }
                                            }
                                        } else {
                                            cloneBindFieldName = cloneBindFieldName.replace(fieldName, this.objValueForm[parent.FIELD_NAME][fieldName]);
                                        }
                                    });
                                    const value = Math.round(eval(cloneBindFieldName));
                                    group[field.FIELD_NAME].setValue(value);
                                } else {
                                    const fieldListCheck = ['search', 'select', 'treeselect'];
                                    if (!this.objValueForm[parent.FIELD_NAME] || this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME] === undefined) {
                                        return;
                                    }
                                    // FIELD_BINDFIELDNAME không phải biểu thức tính toán
                                    // kiểm tra kiểu control của field
                                    if (fieldListCheck.includes(field.FIELD_TYPE)) {
                                        // trường hợp truyền obj
                                        group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME]);
                                    } else {
                                        // trường hợp truyền giá trị: string/number/boolean
                                        group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME]);
                                    }
                                }
                            }
                        } else {
                            // Không có giá trị tại trường cha => xóa trường con
                            group[field.FIELD_NAME].reset();
                        }
                        group[field.FIELD_NAME].markAsDirty();
                    });
                    this.listUnsubscribe.push(handle);
                }
            }
            if (field.FIELD_CHILD) {
                field.FIELD_CHILD.forEach((child: any) => {
                    if (child.calculation) {
                        const handle = group[field.FIELD_NAME].valueChanges.subscribe((res: any) => {
                            const _v = [];
                            for (let v = 0; v < child.calculationInfos.length; v++) {
                                const info = child.calculationInfos[v];
                                if (info.tab !== undefined) { // Nếu có tab => giá trị lấy từ tab, info.tab có giá trị là number
                                    const tabss = this.coreWindowService.lookupTab[info.tab];
                                    const _val = tabss.INFORMATION.CURRENT_DATA ? tabss.INFORMATION.CURRENT_DATA[info.field] : null;
                                    if (_val) {
                                        _v.push(_val);
                                    }
                                } else {
                                    if (group[info.field]) {
                                        _v.push(group[info.field].value);
                                    }
                                }
                            }
                            const value = eval(child.calculation);
                            if (!value || value.toString() === 'NaN') {
                                return;
                            }

                            if (group[child.fieldname]) {
                                group[child.fieldname].setValue(value, { emitEvent: false });
                            }
                        });
                        this.listUnsubscribe.push(handle);
                    }

                });
            }
            const fieldDpLogic = field.FIELD_DISPLAY_LOGIC || null;
            const arrayDpLogic = JSON.parse(fieldDpLogic);
            if (fieldDpLogic && Array.isArray(arrayDpLogic)) {
                if (arrayDpLogic && arrayDpLogic.length > 0) {
                    arrayDpLogic.forEach((item: any) => {
                        if (item && Array.isArray(item)) {
                            const fieldname = item[0];
                            if (group[fieldname] !== undefined) {
                                const handle = group[fieldname].valueChanges.subscribe((res: any) => {
                                    this.checkLogic(arrayDpLogic, field, 'FIELD_HIDE');
                                });
                                this.checkLogic(arrayDpLogic, field, 'FIELD_HIDE');
                                this.listUnsubscribe.push(handle);
                            } else { // không có field này được tạo trong control => là field ẩn
                                this.fieldan.push(field);
                            }
                        }
                    });
                }
            }

            if (field.FIELD_DISABLE_LOGIC) {
                const arrayDisableLogic = JSON.parse(field.FIELD_DISABLE_LOGIC);
                if (Array.isArray(arrayDisableLogic)) {
                    arrayDisableLogic.forEach(logic => {
                        if (logic && Array.isArray(logic)) {
                            const fieldname = logic[0];
                            if (group[fieldname] !== undefined) {
                                const handle = group[fieldname].valueChanges.subscribe((res: any) => {
                                    if (this.preventRelateField) {
                                        return;
                                    }
                                    this.checkLogic(arrayDisableLogic, field, 'FIELD_READ_ONLY');
                                });
                                this.listUnsubscribe.push(handle);
                            }
                            this.fielddisable.push(field);
                        }
                    })
                }
            }
        });
        this.fieldan.forEach((element: any) => {
            if (element.FIELD_NAME === 'UserReject' || element.FIELD_NAME === 'DateReject' || element.FIELD_NAME === 'DateApproved' || element.FIELD_NAME === 'UserApproved' || element.FIELD_NAME === 'DateCreate') {
                element.FIELD_HIDE = true;
            }
            const fieldDpLogic = element.FIELD_DISPLAY_LOGIC;
            const arrayDpLogic = JSON.parse(fieldDpLogic);
            if (fieldDpLogic && Array.isArray(arrayDpLogic)) { // FIELD_DISPLAY_LOGIC có dạng [[key, operator, value]]
                this.checkLogic(arrayDpLogic, element, 'FIELD_HIDE');
            }
        });

        this.updateLabelControl();

        this.formGroup = new FormGroup(group);
        if (this.mode !== 'add') {
            // Xử lý liên quan tới relate field nhưng là liên quan giữa 2 tab với nhau
            this.checkParentFromAnotherTab();
        }

        this.isLoadFormSuccess = true;
        this.cd.detectChanges();
    }

    // Kiểm tra logic
    private checkLogic(arrayLogic: any[], field: any, logicName: string) {
        let count = 0;
        let countItemArray = 0;
        let typeOfLogic = 'and';
        arrayLogic.forEach(item => {
            if (Array.isArray(item)) {
                countItemArray++;
                const fieldname = item[0];
                const operator = item[1];
                const valueLogic = item[2];

                let res = null; // giá trị so sánh mặc định là null
                if (this.formGroup.contains(fieldname)) {
                    // Ưu tiên 1: nếu có control thì lấy theo giá trị ở control
                    res = this.formGroup.controls[fieldname].value;
                } else if (this.bindData && this.bindData[fieldname]) {
                    // Ưu tiên 2: Nếu có bindData => đang ở chế độ view hoặc edit => lấy theo giá trị ở bindData
                    res = this.bindData[fieldname];
                }

                const value = res !== null && res !== undefined && res.CODE ? res.CODE : res;
                let dataCompare = value;
                if (typeof (value) === 'string') {
                    dataCompare = dataCompare.toString();
                }
                // Cẩn thận với trường hợp so sánh null > null, 1 > null, 1 < null ... (những trường hợp đặc biệt)

                switch (operator) {
                    case '=':
                        count += dataCompare == valueLogic ? 1 : 0;
                        break;
                    case '<>':
                    case '!=':
                        count += dataCompare !== valueLogic ? 1 : 0;
                        break;
                    case '>':
                        count += dataCompare > valueLogic ? 1 : 0;
                        break;
                    case '>=':
                        count += dataCompare >= valueLogic ? 1 : 0;
                        break;
                    case '<':
                        count += dataCompare < valueLogic ? 1 : 0;
                        break;
                    case '<=':
                        count += dataCompare <= valueLogic ? 1 : 0;
                        break;
                    case 'like':
                        dataCompare = dataCompare.toString();
                        count = dataCompare.includes(valueLogic.toString()) ? 1 : 0;
                        break;
                    default:
                        break;
                }
            } else {
                typeOfLogic = item;
            }
        });

        if (typeOfLogic === 'and') {
            field[logicName] = count === countItemArray ? true : false;
        } else if (typeOfLogic === 'or') {
            field[logicName] = count > 0 ? true : false;
        }

        if (logicName === 'FIELD_READ_ONLY') {
            field['FIELD_READ_ONLY'] = field[logicName];
        }

        if (logicName === 'FIELD_HIDE') {
            field['FIELD_HIDE'] = !field[logicName];
        }
    }

    // Khởi tạo giá trị khi mở form insert: infor: là information của form
    public initValueWhenInsert(infor: any) {
        this.preventRelateField = false;
        this.list.forEach(field => {
            if (!this.formGroup || !this.formGroup.controls[field.FIELD_NAME]) {
                return;
            }
            if (field.FIELD_DEFAULT_VALUE) {
                let val: any = null;
                // if (typeof (field.FIELD_DEFAULT_VALUE) === 'string' && field.FIELD_DEFAULT_VALUE.startsWith('c$')) {
                //     val = eval(field.FIELD_DEFAULT_VALUE);
                // } else {
                //     val = field.FIELD_DEFAULT_VALUE;
                // }
                // try {
                //     val = eval(field.FIELD_DEFAULT_VALUE);
                // } catch (error) {
                //     val = field.FIELD_DEFAULT_VALUE;
                // }
                // this.formGroup.controls[field.FIELD_NAME].setValue(val);
                val = this.getDefaultValue(field.FIELD_DEFAULT_VALUE)
                if (val instanceof Promise) {
                    // const val_pr = await val
                    setTimeout(() => {
                        val.then((val_pr: any) => {
                            this.formGroup.controls[field.FIELD_NAME].setValue(val_pr, { emitEvent: false });
                        })
                    }, 500)
                   

                } else {
                    setTimeout(() => {
                        this.formGroup.controls[field.FIELD_NAME].setValue(val, { emitEvent: false });
                    }, 500)
                }
            } else {
                this.formGroup.controls[field.FIELD_NAME].setValue(null, { emitEvent: false });
            }
        });

        // Set giá trị mặc định của tab cha (trường liên kết)
        // Trường hợp 1 - nhiều
        if (infor.IS_TAB_TRUNG_GIAN || infor.BANG_LK === undefined || infor.BANG_LK === null) {
            if (infor.PARENT_DATA && this.formGroup.contains(infor.TRUONG_LK_CON)) {
                let fieldLienKet: any = this.list.filter(fil => fil.FIELD_NAME === infor.TRUONG_LK_CON);
                if (fieldLienKet && fieldLienKet.length > 0) {
                    fieldLienKet = fieldLienKet[0];
                    const arrayCheck = ['search', 'select']; // Những control cần truyền object thì nhét ở đây
                    if (arrayCheck.includes(fieldLienKet.FIELD_TYPE)) {
                        this.formGroup.controls[infor.TRUONG_LK_CON].setValue(infor.PARENT_DATA);
                    } else {
                        this.formGroup.controls[infor.TRUONG_LK_CON].setValue(infor.PARENT_DATA[infor.TRUONG_LK_CHA]);
                    }
                }
            }
        }

        this.checkParentFromAnotherTab();
    }

    private createForm(group: any, rows: Array<any>) {
        const fieldList = this.dataSource.FIELD_LIST;
        const totalGroup: any[] = [];
        rows.forEach(row => {
            const fieldGroup: any[] = [];
            row.forEach((item: any) => {
                const cell = item.cell[0] ?? item.cell;
                if (cell.rows) {
                    fieldGroup.push({
                        nzSpan: cell.nzSpan ? cell.nzSpan : 12,
                        rows: this.createForm(group, cell.rows),
                        title: cell.label && cell.label !== '' ? cell.label : null
                    });
                } else {
                    const fields = cell.fieldid ? fieldList.filter((fil: any) => fil.fieldid.toString() === cell.fieldid.toString()
                        && fil.isdisplay === 'Y') : [];
                    if (fields.length > 0) {
                        const field = fields[0];

                        let chbType = 'string';
                        if (field.columntype === 'number') {
                            chbType = 'number';
                        } else if (field.columntype === 'boolean') {
                            chbType = 'boolean'
                        }

                        const obj = {
                            FIELD_ID: field.fieldid,
                            FIELD_NAME: field.fieldname ? field.fieldname.trim() : field.fieldname,
                            FIELD_LABEL: field.alias ? field.alias.trim() : field.fieldname,
                            FIELD_TYPE: field.fieldtype,
                            FIELD_TABLELINK: field.domainid,
                            FIELD_REQUIRED: field.isrequire === 'Y',
                            FIELD_DISABLED: this.isModeView,
                            FIELD_DEFAULT_VALUE: field.defaultvalue ?? null,
                            FIELD_LENGTH: field.fieldlength,
                            FIELD_SPAN: cell.nzSpan,
                            FIELD_ROWSPAN: cell.rowSpan,
                            FIELD_COLSPAN: cell.colSpan,
                            FIELD_ISDOMAIN: field.isfromdomain === 'Y',
                            FIELD_PK: this.dataSource.INFORMATION.KHOA_CHINH === field.fieldname,
                            FIELD_PARENT_ID: field.parentfieldid,
                            FIELD_WHEREFIELDNAME: field.wherefieldname,
                            FIELD_BINDFIELDNAME: field.bindfieldname,
                            FIELD_PLACEHOLDER: field.placeholder ?? '',
                            FIELD_VFORMAT: field.vformat,
                            FIELD_DISPLAY_LOGIC: field.displaylogic && field.displaylogic !== '' ? field.displaylogic : null,
                            FIELD_DISABLE_LOGIC: field.disablelogic && field.disablelogic !== '' ? field.disablelogic : null,
                            FIELD_READ_ONLY: field.isreadonly === 'Y' ? true : false,
                            NOT_SEND: field.fieldautodata === 'Y',
                            FIELD_CHB_TYPE: chbType,
                            DISPLAY_LENGTH: item.displaylength,
                            FIELD_FILTER: { // Dành cho select và input-search
                                FOREIGNTABLE: field.foreigntable,
                                FOREIGNTABLE_ID: field.foreigntableid,
                                COLUMNKEY: field.columnkey,
                                COLUMNDISPLAY: field.columndisplay,
                                COLUMNCODE: field.columncode,
                                TREECOLUMN: field.treecolumn,
                                WHEREFIELDNAME: field.wherefieldname, // sử dụng cho control select
                                BINDFIELDNAME: field.bindfieldname,
                                WHERECLAUSE: field.whereclause,
                                FOREIGNWINDOW_ID: field.foreignwindowid,
                                FIELD_ISDOMAIN: field.isfromdomain === 'Y',
                                FIELD_PARENT_ID: field.parentfieldid,
                                // Dùng cho filter-input (phân biệt khi có 2 control khác fieldname, nhưng đều trỏ vào chung 1 bảng)
                                FIELD_NAME: field.fieldname ? field.fieldname.trim() : field.fieldname,
                                SERVICE_TYPE: field.foreigntableservicetype && field.foreigntableservicetype !== '' ?
                                    field.foreigntableservicetype : this.dataSource.INFORMATION.SERVICE_TYPE // Control sử dụng kiểu service type nào
                            },
                            FIELD_CHILD: field.children,
                            FIELD_INFO: field.calculationInfos,
                            FIELD_CALCULATION_LABEL: field.calculationlabel
                        };
                        fieldGroup.push(obj);
                        this.list.push(obj);

                        let val = null;
                        // if (obj.FIELD_DEFAULT_VALUE) {
                        //     // if (typeof (obj.FIELD_DEFAULT_VALUE) === 'string' && obj.FIELD_DEFAULT_VALUE.startsWith('c$')) {
                        //     //     val = eval(obj.FIELD_DEFAULT_VALUE);
                        //     // } else {
                        //     //     val = obj.FIELD_DEFAULT_VALUE;
                        //     // }
                        //     try {
                        //         val = eval(obj.FIELD_DEFAULT_VALUE);
                        //     } catch (error) {
                        //         val = obj.FIELD_DEFAULT_VALUE;
                        //     }
                        // }
                        if (obj.FIELD_TYPE !== 'html') {
                            group[obj.FIELD_NAME] = new FormControl();
                        }


                        if (obj.FIELD_INFO !== undefined) {
                            if (obj.FIELD_INFO[0].func) {
                                this.coreWindowService.calcObservable.subscribe(message => {
                                    if (message != null && message !== 'NaN' && this.mode !== 'add') {
                                        group[obj.FIELD_NAME].setValue(message);
                                    }
                                });
                            }
                        }
                    } else {
                        fieldGroup.push({
                            FIELD_SPAN: cell.nzSpan,
                            FIELD_TYPE: null
                        });
                    }
                }
            });
            totalGroup.push(fieldGroup);
        });

        return totalGroup;
    }
    attachmentData: any = null
    /** Hàm này dùng để đẩy dữ liệu từ bản ghi được chọn vào các control, đồng thời load ảnh attachments */
    public bindDataToForm(data: any) {
        if (data === null || data === undefined) {
            return;
        }

        // Bật ngăn chặn relate-field
        this.preventRelateField = true;

        if (this.hasAttachment) {
            const obj = {
                url: this.dataSource.INFORMATION.URL_EDIT,
                recordId: data[this.dataSource.INFORMATION.KHOA_CHINH],
                tableId: this.dataSource.INFORMATION.TABLE_ID,
                windowId: this.dataSource.INFORMATION.WINDOW_ID,
                serviceType: this.dataSource.INFORMATION.SERVICE_TYPE
            };
            this.attachmentData = this.appService.deepCloneObject(obj);
        }


        const patchData: any = {};

        // Thêm biến ___isBindValue để các hàm writeValue ở các control sau khi nhận được dữ liệu biết được đây là điền dữ liệu có sẵn vào
        Object.keys(this.formGroup.controls).forEach((key) => {
            const fields = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === key);
            const field = fields.length > 0 ? fields[0] : null;
            if (field) {
                const fieldVisible = this.list.filter(fil => fil.FIELD_NAME === field.fieldname);
                if (fieldVisible.length > 0) {
                    if (field && field.fieldtype === 'search') {
                        const bindData = JSON.parse(JSON.stringify(data));
                        const _field = this.list.filter(fil => fil.FIELD_NAME === field.fieldname);
                        if (_field && _field.length > 0) {
                            if (_field[0].FIELD_HIDE) {
                                patchData[key] = data[field.fieldname];
                            } else {
                                bindData['___isBindValue'] = true;
                                patchData[key] = bindData;
                            }
                        }
                    } else if (field && field.fieldtype === 'select') {
                        if (field.isfromdomain === 'Y') {
                            patchData[key] = data[key];
                        } else {
                            const obj: any = {
                                CODE: data[field.fieldname],
                                DESCR: data[field.columndisplay],
                            };
                            // obj[field.columncode] = data[field.columncode];
                            // obj[field.columnkey] = data[field.columnkey];
                            // obj[field.wherefieldname] = data[field.wherefieldname];

                            if (field.parentfieldid) {
                                const parent = this.list.filter(fil => fil.FIELD_ID === field.parentfieldid);
                                if (parent.length > 0) {
                                    obj[field.wherefieldname] = data[parent[0].FIELD_NAME]
                                }
                            }
                            patchData[key] = obj;
                        }
                    } else {
                        patchData[key] = data[key];
                    }
                } else {
                    // Nếu field này không đc hiển thị thì lấy dữ liệu từ data có sẵn
                    patchData[key] = data[field.fieldname];
                }
            }
        });
        this.formGroup.patchValue(patchData);

        //khoanb tinh toan
        this.dataSource.FIELD_LIST.forEach((field: any) => {
            if (field.children) {
                if (field.children[0].calculationInfos) {
                    for (let v = 0; v < field.children[0].calculationInfos.length; v++) {
                        const info = field.children[0].calculationInfos[v];

                        if (info.func) {// childs
                            this.coreWindowService.calcObservable.subscribe(message => {
                                if (message != null && !Number.isNaN(message)) {
                                    console.log('calculation >>>> ' + message);
                                }
                            });
                        }
                    }
                }
            }
        });

        this.fieldNoGroup.forEach(mang => {
            if (mang.length != undefined) {
                mang.forEach((element: any) => {
                    if (element.FIELD_CALCULATION_LABEL != null) {
                        let bt = element.FIELD_CALCULATION_LABEL;
                        Object.keys(this.formGroup.controls).forEach((key) => {
                            if (bt.includes(key)) {
                                let ch = bt.toString().split('"]');
                                let str = '';
                                for (let index = 0; index < ch.length; index++) {
                                    if (index != ch.length) {
                                        let abc = ch[index];
                                        abc = abc.replace(key, data[key]);
                                        str = str + '"]' + abc;
                                    }
                                    else {
                                        let abc = ch[index];
                                        abc = abc.replace(key, data[key]);
                                        str = str + abc;
                                    }
                                }
                                // ch.forEach((abc: any) => {
                                //     abc=  abc.replace(key, data[key]);
                                // });
                                //bt = ch.toString();

                                str = str.substring(2, str.length);
                                let alias = '';
                                try {

                                    alias = eval(str);
                                } catch (error) {
                                    this.appService.createMessage('error', 'Returned value error' + str)

                                }

                                //   let t = eval("[\"D\"]=='D' ? 'Draf' : 'completed'");
                                // alert(t);
                                // alert(check);
                                element.FIELD_LABEL = alias;
                            }
                            // const fields = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === key);
                            // const field = fields.length > 0 ? fields[0] : null;

                        });
                    }
                });
            }

        });

        this.fieldNoGroup.forEach(mang => {
            if (mang.length != undefined) {
                mang.forEach((element: any) => {
                    if (element.FIELD_CALCULATION_LABEL != null) {
                        let bt = element.FIELD_CALCULATION_LABEL;
                        Object.keys(this.formGroup.controls).forEach((key) => {
                            if (bt.includes(key)) {
                                //let value = data[key] == null? 123: 1111;
                                let ch = bt.toString().split('"]');
                                let str = '';
                                for (let index = 0; index < ch.length; index++) {
                                    if (index != ch.length) {
                                        let abc = ch[index];
                                        abc = abc.replace(key, data[key]);
                                        str = str + '"]' + abc;
                                    }
                                    else {
                                        let abc = ch[index];
                                        abc = abc.replace(key, data[key]);
                                        str = str + abc;
                                    }
                                }
                                str = str.substring(2, str.length);

                                let alias = '';
                                try {

                                    alias = eval(str);
                                } catch (error) {

                                    this.appService.createMessage('error', 'Returned value error' + str)
                                }

                                //   let t = eval("[\"D\"]=='D' ? 'Draf' : 'completed'");
                                // alert(t);
                                // alert(check);
                                element.FIELD_LABEL = alias;
                            }
                            // const fields = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === key);
                            // const field = fields.length > 0 ? fields[0] : null;

                        });
                    }
                });
            }
        });

        this.fieldan.forEach(field => {
            const fieldDpLogic = field.FIELD_DISPLAY_LOGIC;
            const arrayDpLogic = JSON.parse(fieldDpLogic);
            if (fieldDpLogic && Array.isArray(arrayDpLogic)) { // FIELD_DISPLAY_LOGIC có dạng [[key, operator, value]]
                this.checkLogic(arrayDpLogic, field, 'FIELD_HIDE');
            }
        });

        // // khoanb gán dữ liệu vào báo cáo
        // const a = this.dataSource.FIELD_LIST.filter(fil => fil.fieldname === this.dataSource.INFORMATION.KHOA_CHINH);
        // if (a.length > 0) {
        //     const obj = {
        //         QuotationId: data[a[0].fieldname],
        //         key: a[0].fieldname
        //     };
        //     this.getdulieuservice.changeReport(obj);
        //     this.getdulieuservice.changeQuotationId(data[a[0].fieldname]);
        // }
    }
    onDeleteImg(field: any) {
        this.formGroup.controls[field.FIELD_NAME].setValue(null)
        const ind = this.arrFileImage.findIndex((v: any) => v.fieldname === field.FIELD_NAME)
        if (ind > -1) {
            this.arrFileImage.splice(ind, 1)
        }
    }
    public editForm(isActive: boolean) {
        this.isModeView = !isActive;
        this.preventRelateField = false;
        // Duyệt các control với trường hợp default
        if (this.typeOfFormControl === 'default') {
            Object.keys(this.formGroup.controls).forEach(key => {
                // lấy dữ liệu field;
                const a = this.fieldNoGroup.filter(fil => fil.FIELD_NAME === key);
                if (a.length > 0) {
                    a[0].FIELD_DISABLED = !isActive;
                }

                if (this.hasFieldGroup) {
                    Object.keys(this.fieldGroup).forEach((group: any) => {
                        const b = this.fieldGroup[group].filter((fil: any) => fil.FIELD_NAME === key);
                        if (b.length > 0) {
                            b[0].FIELD_DISABLED = !isActive;
                        }
                    });
                }
            });
        }

        if (this.typeOfFormControl === 'layout') {
            this.editLayout(this.fieldNoGroup);
        }
    }
    onUploadFile(field: any) {
        if (!field.FIELD_DISABLED) {
            this.inputFile.nativeElement.click()
            this.inputFile.nativeElement.onchange = (evt: any) => {
                this.changeFile(evt, field)
            }
        }

    }
    arrFileImage: any[] = []
    checkFieldImage(field: any) {
        let chk: boolean = false
        if (this.formGroup.value[field.FIELD_NAME] && this.formGroup.value[field.FIELD_NAME] !== '') {
            chk = true
        } else[
            chk = false
        ]
        return chk;
    }
    fileMaxSize: any = 20
    changeFile(evt: any, field: any) {
        const files = (evt.target as HTMLInputElement).files;
        if (files && files.length > 0) {

            const file = files[0];

            const size = file.size / 1024 / 1024; // unit in MB
            if (size < this.fileMaxSize) {
                const reader = new FileReader();
                reader.onload = async (e: any) => {
                    const ind_img = this.arrFileImage.findIndex((v: any) => v.fieldname === field.FIELD_NAME)
                    if (ind_img > -1) {
                        this.arrFileImage[ind_img].file = file
                    } else {
                        this.arrFileImage.push({
                            fieldname: field.FIELD_NAME,
                            file: await this.appService.compressImage(file)
                        })
                    }
                    this.inputFile.nativeElement.value = null
                    const val = e.target.result
                    this.formGroup.controls[field.FIELD_NAME].setValue(val)
                };
                reader.readAsDataURL(file);

            } else {
                this.inputFile.nativeElement.value = null
                this.appService.alert(this.appService.getMessage('0019') + this.fileMaxSize + ' MB!', 'error');
            }
        }
        // this.inputFile.nativeElement.value = null
    }
    baseUrl: any = null
    queryWindowPlugin(windowid: any) {
        this.reqService.switchType('sql');
        const urlRequest = this.appService.urlWS + '/SysWindows';
        const res = this.reqService.service.search({
            url: urlRequest,
            where: ['WindowId', '=', windowid]
        })
        return res
    }
    onClickTextButton(field: any) {
        if (field.FIELD_FILTER.FOREIGNWINDOW_ID) {
            this.queryWindowPlugin(field.FIELD_FILTER.FOREIGNWINDOW_ID).subscribe(async (resp: any) => {
                if (resp.success && resp.features.length > 0) {
                    if (resp.features[0].WindowType === 'CUSTOMIZE') {
                        // console.log(resp.features[0])
                        if (resp.features[0].ComponentName && resp.features[0].ComponentName != '') {
                            console.log(resp.features[0])
                            const comp = this.getComponent(resp.features[0].ComponentName)
                            let titleDialog = null
                            try {
                                const titleJson = JSON.parse(resp.features[0].Description)
                                titleDialog = titleJson[this.translate.currentLang];
                            } catch (error) {
                                titleDialog = resp.features[0].Description
                            }
                            const dialogCurrent = this.dialogComponent.showDialog(comp, {
                                valueForm: this.formGroup.value
                            }, titleDialog)
                            if (dialogCurrent.updateForm) {
                                const handle = dialogCurrent.updateForm.subscribe((evt: any) => {
                                    this.dialogComponent.closeDialog();
                                    if (evt) {
                                        this.formGroup.controls[field.FIELD_NAME].setValue(evt)
                                    }
                                    handle.unsubscribe();
                                })
                            }
                        } else {
                            this.appService.createMessage('success', "Don't have component")
                        }
                    }


                }
            })

        }
    }
    getComponent(componentName: any) {
        let comp: any = null
        switch (componentName) {
            case 'WorkflowManagerComponent':
                comp = WorkflowManagerComponent
                break;
            case 'AddBPMNComponent':
                comp = AddBPMNComponent
                break;
            case 'ViewJobComponent':
                comp = ViewJobComponent
                break;
            case 'RecallJobComponent':
                comp = RecallJobComponent
                break;
            case 'GetJobComponent':
                comp = GetJobComponent
                break;
            case 'BackJobComponent':
                comp = BackJobComponent
                break;
            case 'RunBPMNComponent':
                comp = RunBPMNComponent
                break;
            case 'TrainingPriceCalculationComponent':
                comp = TrainingPriceCalculationComponent
                break;
            case 'ProcessQuotationComponent':
                comp = ProcessQuotationComponent
                break;
            case 'LetterFormComponent':
                comp = LetterFormComponent
                break;
            case 'TaoHangMucComponent':
                comp = TaoHangMucComponent
                break;
            case 'ThemTaiSanComponent':
                comp = ThemTaiSanComponent
                break;
            case 'AddSupplierItemsComponent':
                comp = AddSupplierItemsComponent
                break;
            case 'TaoHaoMonComponent':
                comp = TaoHaoMonComponent
                break;
            case 'ReportViewerComponent':
                comp = ReportViewerComponent
                break;
            case 'CalendarComponent':
                comp = CalendarComponent
                break;
            case 'GroupportalComponent':
                comp = GroupportalComponent
                break;
            case 'ChoosedatabaseComponent':
                comp = ChoosedatabaseComponent
                break;
            default:
                break;

        }
        return comp
    }
    // handleReturnLocation: any = null
    async onClickBtn(field: any, type: any) {

        if (field.FIELD_FILTER.FOREIGNWINDOW_ID) {
            this.queryWindowPlugin(field.FIELD_FILTER.FOREIGNWINDOW_ID).subscribe(async (resp: any) => {
                if (resp.success) {
                    if (resp.features[0].ComponentName && resp.features[0].ComponentName != '') {
                        if (resp.features[0].WindowType === 'PLUGIN') {
                            this.reqService.switchType(this.appService.dataAccessType);
                            const urlRequest = this.appService.urlWS + '/Attachs';
                            const appId = this.appService.c$['appId'];
                            const where = [
                                ['TableId', '=', 57],
                                ['RecordId', '=', Number(field.FIELD_FILTER.FOREIGNWINDOW_ID)],
                                ['ClientId', '=', this.appService.ClientId],
                                ['ApplicationId', '=', appId],
                                ['AttachName', '=', resp.features[0].ComponentName]
                            ];
                            const res = await this.reqService.service.search({
                                url: urlRequest,
                                where
                            }).pipe(take(1)).toPromise();

                            if (res.success) {
                                if (res.features.length > 0) {
                                    const item = res.features[0];
                                    let urlPlugin = (this.appService.urlProxy + this.appService.urlOdataAttachment + '/' + item.UrlFile).replace(/\\/g, '/');
                                    this.baseUrl = this.appService.urlOdataAttachment + '/' + item.UrlFile

                                    const val_f = this.appService.returnParam(this.formGroup)
                                    this.pluginService.loadFileScript(urlPlugin).then((p: any) => {
                                        window['c$'].plugin = p
                                        p.run(this, { data: [this.formGroup.value], parentData: this.dataSource.INFORMATION.PARENT_DATA })
                                    })
                                    try {
                                        // this.formGroup.patchValue(obj)
                                        this.updateForm.subscribe((res: any) => {
                                            const val_c = this.formGroup.value
                                            const arrKey = Object.keys(val_c)
                                            Object.keys(res).forEach((key: any) => {
                                                if (arrKey.includes(key)) {
                                                    val_c[key] = res[key]
                                                    const f_obj = this.dataSource.FIELD_LIST.find((v: any) => v.fieldname === key)
                                                    if (f_obj) {
                                                        if (f_obj.fieldtype === 'search' || f_obj.fieldtype === 'select') {
                                                            this.formGroup.controls[key].setValue(val_c)
                                                        } else {
                                                            this.formGroup.controls[key].setValue(val_c[key])
                                                        }
                                                    }


                                                }
                                            })
                                            // this.formGroup.patchValue(val)
                                        })
                                    } catch (error) {
                                        this.appService.createMessage('error', 'Returned value error')
                                    }
                                    // const val = this.appService.returnParam(this.formGroup)
                                    // if (typeof (filejs) === 'function') {
                                    //     const obj = filejs.call(this, this, { data: [this.formGroup.value], parentData: this.dataSource.INFORMATION.PARENT_DATA, field: field })
                                    //     try {
                                    //         // this.formGroup.patchValue(obj)
                                    //         this.updateForm.subscribe((res: any) => {
                                    //             const val = this.formGroup.value
                                    //             const arrKey = Object.keys(val)
                                    //             Object.keys(res).forEach((key: any) => {
                                    //                 if (arrKey.includes(key)) {
                                    //                     val[key] = res[key]
                                    //                     const f_obj = this.dataSource.FIELD_LIST.find((v: any) => v.fieldname === key)
                                    //                     if (f_obj) {
                                    //                         if (f_obj.fieldtype === 'search' || f_obj.fieldtype === 'select') {
                                    //                             this.formGroup.controls[key].setValue(val)
                                    //                         } else {
                                    //                             this.formGroup.controls[key].setValue(val[key])
                                    //                         }
                                    //                     }


                                    //                 }
                                    //             })
                                    //             // this.formGroup.patchValue(val)
                                    //         })
                                    //     } catch (error) {
                                    //         this.appService.createMessage('error', 'Returned value error')
                                    //     }
                                    // } else {
                                    //     const obj = filejs.run(this, { data: [this.formGroup.value], parentData: this.dataSource.INFORMATION.PARENT_DATA, field: field })
                                    //     try {
                                    //         // this.formGroup.patchValue(obj)
                                    //         this.updateForm.subscribe((res: any) => {
                                    //             const val = this.formGroup.value
                                    //             const arrKey = Object.keys(val)
                                    //             Object.keys(res).forEach((key: any) => {
                                    //                 if (arrKey.includes(key)) {
                                    //                     val[key] = res[key]
                                    //                     const f_obj = this.dataSource.FIELD_LIST.find((v: any) => v.fieldname === key)
                                    //                     if (f_obj) {
                                    //                         if (f_obj.fieldtype === 'search' || f_obj.fieldtype === 'select') {
                                    //                             this.formGroup.controls[key].setValue(val)
                                    //                         } else {
                                    //                             this.formGroup.controls[key].setValue(val[key])
                                    //                         }
                                    //                     }
                                    //                 }
                                    //             })
                                    //             // this.formGroup.patchValue(val)
                                    //         })
                                    //     } catch (error) {
                                    //         this.appService.createMessage('error', 'Returned value error')
                                    //     }
                                    // }

                                }
                            }
                        } else if (resp.features[0].WindowType === 'CUSTOMIZE') {
                            console.log(resp.features[0])
                        }

                    } else {
                        this.appService.createMessage('success', "Selector hasn't been selected")
                    }

                }
            })

        }


    }
    public onAttachmentSave() {
        this.saveAttachmentEmit.emit();
    }

    private editLayout(rows: any) {
        const fieldList = this.dataSource.FIELD_LIST;
        rows.forEach((row: any) => {
            row.forEach((col: any) => {
                if (col.rows) {
                    this.editLayout(col.rows);
                } else {
                    const field = fieldList.filter((fil: any) => fil.fieldname === col.FIELD_NAME)[0];
                    if (field) {
                        if (col.FIELD_DISABLED !== undefined) { col.FIELD_DISABLED = this.isModeView; }
                    }
                }
            });
        });
        this.fielddisable.forEach(field => {
            // DaiNT thêm đoạn này để kiểm tra field disable logic khi bật sang chế độ edit
            const arrayDisableLogic = JSON.parse(field.FIELD_DISABLE_LOGIC);
            if (Array.isArray(arrayDisableLogic)) {
                arrayDisableLogic.forEach(logic => {
                    if (logic && Array.isArray(logic)) {
                        this.checkLogic(arrayDisableLogic, field, 'FIELD_READ_ONLY');
                    }
                })
            }

            // Code cũ của quotation - không thay đổi gì cả
            const i = field.FIELD_DISABLE_LOGIC.lastIndexOf('[');
            const k = field.FIELD_DISABLE_LOGIC.lastIndexOf(']');
            const fieldname1 = field.FIELD_DISABLE_LOGIC.substring(i + 2, k - 1);
            if (field.FIELD_DISABLE_LOGIC.includes('=')) {
                const q = field.FIELD_DISABLE_LOGIC.lastIndexOf('=');
                const dk = field.FIELD_DISABLE_LOGIC.substr(q + 1, field.FIELD_DISABLE_LOGIC.length - q - 1);
                const value1 = this.bindData[fieldname1];
                if (value1 && field.FIELD_DISABLE_LOGIC.toString().includes('ProductId')) {
                    field.FIELD_DISABLED = true;
                }
                else {
                    field.FIELD_DISABLED = false;
                }
                if (field.FIELD_DISABLE_LOGIC.toString().includes('ItemTypeId') && value1 === '5') {
                    field.FIELD_DISABLED = true;
                }
            }
        });
    }

    public btnOkClick() {
        if (this.formGroup.valid) {
            this.dataEmit.emit(this.formGroup);
        } else {
            this.appService.alert(this.appService.getMessage('0017'), 'error');
        }
    }

    public btnCancelClick() {
        this.dataEmit.emit(null);
    }

    public btnResetClick() {
        const params: any = {};
        const list = this.dataSource.FIELD_LIST.filter((fil: any) => fil.defaultvalue);
        list.forEach((item: any) => {
            let val = null;
            if (typeof (item.defaultvalue) === 'string' && item.defaultvalue.startsWith('c$')) {
                val = eval(item.defaultvalue);
            } else {
                val = item.defaultvalue;
            }
            params[item.fieldname] = val;
        });
        this.formGroup.reset(params, { emitEvent: false });

        // if (this.appAttachment) {
        //     this.appAttachment.onReset();
        // }


        const infor = this.dataSource.INFORMATION;
        // Set giá trị mặc định của tab cha (trường liên kết)
        // Trường hợp 1 - nhiều
        if (infor.IS_TAB_TRUNG_GIAN || infor.BANG_LK === undefined || infor.BANG_LK === null) {
            if (infor.PARENT_DATA && this.formGroup.contains(infor.TRUONG_LK_CON)) {
                let fieldLienKet: any = this.list.filter(fil => fil.FIELD_NAME === infor.TRUONG_LK_CON);
                if (fieldLienKet && fieldLienKet.length > 0) {
                    fieldLienKet = fieldLienKet[0];
                    const arrayCheck = ['search', 'select']; // Những control cần truyền object thì nhét ở đây
                    if (arrayCheck.includes(fieldLienKet.FIELD_TYPE)) {
                        this.formGroup.controls[infor.TRUONG_LK_CON].setValue(infor.PARENT_DATA);
                    } else {
                        this.formGroup.controls[infor.TRUONG_LK_CON].setValue(infor.PARENT_DATA[infor.TRUONG_LK_CHA]);
                    }
                }
            }
        }
    }
    checkTextIsLink(txt: any) {
        const str: RegExp = /^[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/
        return str.test(txt)
    }
    onOpenLink(field: any) {
        if (this.formGroup.value[field.FIELD_NAME] && this.formGroup.value[field.FIELD_NAME] !== '' && this.checkTextIsLink(this.formGroup.value[field.FIELD_NAME])) {
            window.open(this.formGroup.value[field.FIELD_NAME], '_blank')
        }
    }
    // Attachment mode simple
    public insertAttachment(val: any) {
        if (this.hasAttachment) {
            // const obj = {
            //     url: this.dataSource.INFORMATION.URL_EDIT,
            //     id: val.objectId // Fix cứng .objectId được vì dành riêng cho ArcGIS
            // };
            try {
                if (val === null) {
                    this.saveAttachmentEmit.emit();
                    return;
                }
                const obj = {
                    url: this.dataSource.INFORMATION.URL_EDIT,
                    recordId: val.objectId, // val[this.dataSource.INFORMATION.KHOA_CHINH],
                    tableId: this.dataSource.INFORMATION.TABLE_ID,
                    windowId: this.dataSource.INFORMATION.WINDOW_ID,
                    serviceType: this.dataSource.INFORMATION.SERVICE_TYPE
                };

                this.appAttachment.uploadFileAfterInsert({
                    data: this.appService.deepCloneObject(obj)
                });
            } catch (error) {
                this.saveAttachmentEmit.emit();
            }
        } else {
            this.saveAttachmentEmit.emit();
        }
    }
}
