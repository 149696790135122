<div>
  <form [formGroup]="formGroup" class="filter-field-form">
    <app-select [labelName]="'Field' | translate" [showLabel]="true" [formControlName]="'cboField'" (returnObj)="onReturnObjField($event)" [required]="true"
      style="margin-right: 10px;" [dataLookup]="_arrField" [placeholder]="'Field' | translate">
    </app-select>
    <app-select [labelName]="'Table' | translate" [showLabel]="true" [formControlName]="'cboTable'" (returnObj)="onReturnObjTable($event)" [required]="true"
      style="margin-right: 10px;" [dataLookup]="arrTable" [placeholder]="'Table' | translate">
    </app-select>
    <app-select [labelName]="'Field relation' | translate" [showLabel]="true" [formControlName]="'cboFieldRelation'" (returnObj)="onReturnObjFieldRelation($event)"
      [required]="true" style="margin-right: 10px;" [dataLookup]="arrFieldRelation"
      [placeholder]="'Field relation' | translate">
    </app-select>
    <div style="display: flex; justify-content: center;" class="form-add-footer">
      <app-button class="ml-3" [labelName]="'Đồng ý' | translate" typeButton="save" (clickEvent)="clickBuildTree()">
      </app-button>
      <app-button class="ml-3" [labelName]="'Thoát' | translate" typeButton="close" (clickEvent)="closeDialog()">
      </app-button>
    </div>
  </form>
</div>
