import { Injectable, Injector } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { Observable } from 'rxjs';
import { ResponseData } from '../model/response-data.interface';

@Injectable()
export class UmService {

  constructor(
    private appService: AppService
  ) {
  }
}
