import { Injectable } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { loadModules } from 'esri-loader';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseInterface, DeleteResponse, InsertResponse, OdataParams, QdataParams, SearchResponse, UpdateResponse } from 'app/core/services/request.service';
import { map } from 'rxjs/operators';


@Injectable()
export class ArcGisWorkflowService implements BaseInterface {
    public url = 'https://gis107.esrivn.net/server/rest/services/workflow/MyWorkflowManagerService/WMServer';
    constructor(private appService: AppService,  private http: HttpClient,) { }

    public async init() {
        if (!this.appService.isLoadArcGIS3x) {
            this.appService.isLoadArcGIS3x = true;
        }
    }

    /** params có dạng object: { id: 1, name: 'hehe' } */
    query(q: QdataParams): Observable<any> {
        const url = q.url;
        const urlRequest = q.proxy ? `${q.proxy}/${url}` : url;

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });

        if (method === 'GET') {
            return this.http.get(urlRequest, {
                headers,
                params: paramsToSend
            });
        } else {
            return this.http.post(urlRequest, q.params, {
                headers,
                params: paramsToSend
            });
        }
    }

    queryCustom(q: QdataParams): Observable<any> {
        return of(true);
    }

    getRecordById(id: any): Observable<any> {
        return of(true);
    }
    
    search(p: OdataParams): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        // let convertWhere = this.decodeSql(p.where, p.logic);
        // if (p.or) {
        //     convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        // }
        // if (p.and) {
        //     convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        // }

        // if (p.select) { convertWhere += '&$select=' + p.select; }
        // if (p.orderby) {
        //     convertWhere += '&$orderby=' + p.orderby.join(',');
        // }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        // url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        return this.http.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(map((res:any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }));
    }

    searchForMobile(p: OdataParams, token:any): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        // let convertWhere = this.decodeSql(p.where, p.logic);
        // if (p.or) {
        //     convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        // }
        // if (p.and) {
        //     convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        // }

        // if (p.select) { convertWhere += '&$select=' + p.select; }
        // if (p.orderby) {
        //     convertWhere += '&$orderby=' + p.orderby.join(',');
        // }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        // url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        return this.http.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(map((res:any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }));
    }

    insert(p: OdataParams): Observable<InsertResponse> {
        const url = p.url;
        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
        });

        // không gửi khóa chính cùng giá trị vì service odata sẽ bị lỗi
        const key = p.primaryKey;
        key ? delete p.data[key] : '';

        return this.http.post(url, p.data, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            const data:any = {};
            Object.keys(res.model).forEach(key => {
                data[key.charAt(0).toUpperCase() + key.slice(1)] = res.model[key];
            });
            const result: InsertResponse = {
                features: [data],
                success: res.success,
                total: 1,
                message: res.message
            };

            return result;
        }));
    }

    update(p: OdataParams): Observable<UpdateResponse> {
        const urlRequest = p.url;
        const key = p.primaryKey;
        const url = key ? `${urlRequest}/${p.data[key]}` : '';

        // delete p.data[key];

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
        });

        return this.http.put(url, p.data, { headers }).pipe(map((res:any) => {
            const resp: UpdateResponse = {
                message: res['message'],
                success: res['success']
            };
            return resp;

        }));
    }

    public delete(p: OdataParams): Observable<DeleteResponse> {
        // const params = p.data[p.dataSource.INFORMATION.KHOA_CHINH];
        const key =  p.primaryKey;
        const params = key ? p.data[key] : '';
        const urlRequest = p.url;
        const url = `${urlRequest}/${params}`;

        // const url = `${p.url}/${params}`;

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        return this.http.delete(url, { headers }).pipe(map((res: any) => {
            const resp: DeleteResponse = {
                data: [res.model],
                success: res.success,
                message: res.message
            };
            return resp;
        }));
    }

    public queryData(){
        console.log(123);
        
    }


    reqUrlGet(
        url: string,
        params: any = {} // ,
        // tslint:disable-next-line: no-use-before-declare
        // responseType: ResponseType = ResponseType.text
    ): Observable<any> {
        return this._reqUrlGet(url, params);
    }

    private _reqUrlGet(url: string, params: any): Observable<any> {
        // let headers = new Headers({ 'Content-Type': 'application/json' });
        const paramsToSend:any = new HttpParams({ fromObject: params });
        // let options = new RequestOptions({ headers: headers });
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: paramsToSend
        };
        // const paramsToSend = this.softCopyJSON(params);

        const domainName = this.getDomainName(url);
        if (domainName.toLowerCase() === window.location.host.toLowerCase()) {
            paramsToSend['CSRF_TOKEN'] = this.getCookie('CSRF_TOKEN');
        }

        return this.http.get(url, httpOptions);
        // const response = this.http.get(url, {headers: headers, params: paramsToSend});
    }

    getCookie(name:any): string {
        const value = '; ' + document.cookie;
        const parts: any = value.split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        } else {
            return '';
        }

    }

    getDomainName(url: string): string {
        const domainNameStartIndex = url.indexOf('//');
        let domainName = '';

        if (domainNameStartIndex >= 0) {
            domainName = url.substring(domainNameStartIndex + 2);
        } else {
            domainName = url;
        }
        const domainNameEndIndex = domainName.indexOf('/');
        if (domainNameEndIndex >= 0) {
            domainName = domainName.substring(0, domainNameEndIndex);
        }
        return domainName;
    }

    
    public esriRequest(
        url: string,
        params: any = {},
        type: string = '',
        usePost: boolean = true,
        useProxy: boolean = true
    ): Observable<any> {
        const subject: Subject<any> = new Subject();
        let newUrl = url;

        // switch (type) {
        //     case 'add':
        //         newUrl += '/addFeatures';
        //         break;
        //     case 'delete':
        //         newUrl += '/deleteFeatures';
        //         break;
        //     case 'edit':
        //         newUrl += '/updateFeatures';
        //         break;
        //     case 'query':
        //         newUrl += '/query';
        //         break;
        //     default:
        //         break;
        // }

        loadModules(['esri/request', 'esri/config']).then(
            ([esriRequest, esriConfig]) => {
                esriConfig.defaults.io.proxyUrl = this.appService.urlProxy;
                // esriConfig.defaults.io.corsEnabledServers.push("gis107.esrivn.net");
                const reqData = {
                    url: newUrl,
                    // content: JSON.stringify(params),
                    content: params,
                    handleAs: 'json',
                    sync: true,
                    callbackParamName: 'callback',
                };
                console.log('reqData', reqData);
                esriRequest(reqData, { usePost, useProxy }).then(
                    (response:any) => subject.next(response),
                    (error:any) => subject.next(error)
                );
            }
        );

        return subject.asObservable();
    }


    public sendRequest(url:any, inputParams:any, appendURL:any, successCallBack:any, errorCallBack:any) {
        loadModules([
            'esri/request','esri/config'
        ]).then(([
            esriRequest,esriConfig
        ]) => {
            const usePost: boolean = true;
            const useProxy: boolean = true;
            // esriConfig.defaults.io.corsEnabledServers.push("gis107.esrivn.net");
            esriConfig.defaults.io.proxyUrl = this.appService.urlProxy;
            url += appendURL;
            inputParams.f = 'json';
            const request = esriRequest({
                url: url,
                content: inputParams,
                handleAs: 'json',
                callbackParamName: 'callback'
             },{usePost,useProxy});
            request.then(successCallBack, errorCallBack);
        });
    }

    public  sendRequestFile(formToSend:any, appendURL:any, successCallBack:any, errorCallBack:any) {
        loadModules([
            'esri/request'
        ]).then(([
            esriRequest
        ]) => {
            const usePost: boolean = true;
            const useProxy: boolean = true;
            let requestUrl = this.url;
            requestUrl += appendURL;
            const inputParams = {};
            // if (this.token) {
            //     inputParams.token = this.token;
            // }
            // if (this.disableClientCaching) {
            //     inputParams._ts = new Date().getTime();
            // }
            const  request = esriRequest({
                url: requestUrl,
                content: inputParams,
                form: formToSend,
                handleAs: 'json',
                callbackParamName: 'callback'
            },{usePost,useProxy});

            request.then(successCallBack, errorCallBack);
        });
    }


    public queryDataWf(url:any, id:any, userLogin:any): Observable<any> {
        // this.appService.showLoading();
        const subject: Subject<any> = new Subject();
        // this.currentParamJobType = id;
        loadModules(['esri/request', 'esri/layers/FeatureLayer']).then(
            ([esriRequest, FeatureLayer]) => {
                esriRequest(
                    {
                        url: url + '/jobs/query?id=' + id + '&user=' + userLogin + '&f=json',
                        content: {
                            f: 'json',
                        },
                    },{
                        usePost: true,
                    }
                ).then(
                    (resp:any) => {
                        console.log(resp);
                        // this.appService.hideLoading();
                        // const data = {
                        //     data: resp,
                        //     type: 'search',
                        // };
                        subject.next(resp);
                    },
                    (err:any) => {
                        console.log(err);
                        subject.next(err);
                        // this.appService.hideLoading();
                        this.appService.notification('Không thể truy vẫn dữ liệu', 'info');
                    }
                );
            }
        );

        return subject.asObservable();
    }

    public getAllUsers(urlWf:any): Observable<any> {
        const subject: Subject<any> = new Subject();
        const params = {};
        this.sendRequest(urlWf,params, '/community/users',  (response:any) => {
            subject.next(response.users);
        }, (error:any) => subject.next(error));
        return subject.asObservable();
    }

    public getAllGroups(urlWf:any):Observable<any> {
        const subject: Subject<any> = new Subject();
        const params = {};
        this.sendRequest(urlWf,params, '/community/groups', (response:any) => {
            subject.next(response.groups);
        }, (error:any) => subject.next(error));
        return subject.asObservable();
    }

    public getActivityLog(urlWf:any,jobId:any, successCallBack:any, errorCallBack:any) {
        const params = {};
        this.sendRequest(urlWf, params, '/jobs/' + jobId + '/activity', (response:any) => {
            successCallBack(response.activity);
        }, errorCallBack);
    }
    public createJob(urlWf:any,parameters:any, user:any, successCallBack:any, errorCallBack:any) {
        loadModules([
            'esri/geometry/Multipoint'
        ]).then(([
            Multipoint,
            QueryTask
         ]) => {
            const  params = parameters;
            params.user = this.formatDomainUsername(user);
            if (params.ownedBy != null) {
                params.ownedBy = this.formatDomainUsername(params.ownedBy);
            }
            if (params.assignedTo != null) {
                params.assignedTo = this.formatDomainUsername(params.assignedTo);
            }
            // if (params.startDate != null) {
            //     params.startDate = Date.parse(params.startDate);
            // }
            // if (params.dueDate != null) {
            //     params.dueDate = Date.parse(params.dueDate);
            // }
            if (params.aoi != null) {
                params.aoi = JSON.stringify(params.aoi.toJson());
            }
            if (params.loi != null) {
                const loi = params.loi;
                params.loi = null;
                if (loi.type === 'polygon') {
                    params.aoi = JSON.stringify(loi.toJson());
                } else if (loi.type === 'multipoint') {
                    params.poi = JSON.stringify(loi.toJson());
                } else if (loi.type === 'point') {
                    const  multiPoint = new Multipoint(loi.spatialReference);
                    multiPoint.addPoint(loi);
                    params.poi = JSON.stringify(multiPoint.toJson());
                }
            }
            this.sendRequest(urlWf,parameters, '/jobs/create', (response:any) => {
                successCallBack(response.jobIds);
            }, errorCallBack);
        });
    }

    public getJob(urlWf:any,jobId:any): Observable<any> {
        const subject: Subject<any> = new Subject();
        const params = {};
        this.sendRequest(urlWf,params, '/jobs/' + jobId, (response:any) => {
            subject.next(response);
        }, (err:any) => subject.next(err));

        return subject.asObservable();
    }

    public deleteJob(urlWf:any,user:any, jobId:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user,
            jobs: jobId
        };
        this.sendRequest(urlWf,params, '/jobs/delete', (response:any) => {
            successCallBack(response);
        }, errorCallBack);
    }

    public getWorkflowImageURL(jobId:any) {
        const timestamp = new Date().getTime();
        const imageUrl = this.url + '/jobs/' + jobId + '/workflow?f=image&ts=' + timestamp;
        return imageUrl;
    }

    public getCurrentSteps(urlWf:any,jobId:any, successCallBack:any, errorCallBack:any) {
        const params = {};
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/workflow/steps/current', (response:any) => {
            successCallBack(response.steps);
        }, errorCallBack);
    }

    public getCurrentSteps1(urlWf:any,jobId:any): Observable<any> {
        const subject: Subject<any> = new Subject();
        const params = {};

        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/workflow/steps/current', (response:any) => {
            subject.next(response.steps);
        }, (err:any) => {
            subject.next(err);
        });

        return subject.asObservable();

    }

    public formatDomainUsername(username:any) {
        if (username && username.length > 0){
            // replace all occurences of backslash with "|" in the string
            username = username.replace(/\\/g, '|');
        }
        return username;
    }

    public markStepsAsDone(urlWf:any,jobId:any, stepIds:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user,
            steps: stepIds
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/workflow/steps/markAsDone', (response:any) => {
            successCallBack(response.executeInfo);
        }, errorCallBack);
    }

    public canRunStep(urlWf:any, jobId:any, stepId:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/workflow/steps/' + stepId + '/canRun', (response:any) => {
            successCallBack(response.canRun);
        }, errorCallBack);
    }

    public canRunStep1(urlWf:any,jobId:any, stepId:any, user:any): Observable<any> {
        const subject: Subject<any> = new Subject();
        const params = {
            user
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/workflow/steps/' + stepId + '/canRun', (response:any) => {
            subject.next(response.canRun);
        }, (err:any) => {subject.next(err); });

        return subject.asObservable();
    }

    public resolveConflict(urlWf:any,jobId:any, stepId:any, optionReturnCode:any, optionStepIds:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user: this.formatDomainUsername(user),
            optionReturnCode,
            optionSteps: optionStepIds.toString()
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/workflow/steps/' + stepId + '/resolveConflict', successCallBack, errorCallBack);
    }

    public getAttachments(urlWf:any,jobId:any, successCallBack:any, errorCallBack:any) {
        const params = {};
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/attachments', (response:any) => {
            successCallBack(response.attachments);
        }, errorCallBack);
    }

    public getAttachmentContentURL(jobId:any, attachmentId:any) {
        const contentURL = this.url + '/jobs/' + jobId + '/attachments/' + attachmentId + '/content?f=file';
        return contentURL;
    }

    public deleteAttachment(urlWf:any,jobId:any, attachmentId:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user: this.formatDomainUsername(user),
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/attachments/' + attachmentId + '/delete', successCallBack, errorCallBack);
    }

    public updateJob(urlWf:any, parameters:any, user:any, successCallBack:any, errorCallBack:any) {
            parameters.user = this.formatDomainUsername(user);
            if (parameters.ownedBy != null) {
                parameters.ownedBy = this.formatDomainUsername(parameters.ownedBy);
            }
            if (parameters.assignedTo != null) {
                parameters.assignedTo = this.formatDomainUsername(parameters.assignedTo);
            }
            if (parameters.startDate != null) {
                // parameters.startDate = Date.parse(parameters.startDate);
                parameters.startDate =  parameters.startDate;
            }
            if (parameters.dueDate != null) {
                // parameters.dueDate = Date.parse(parameters.dueDate);
                parameters.dueDate =  parameters.dueDate;
            }
            // if (parameters.loi != null) {
            //     let loi = parameters.loi;
            //     parameters.loi = null;
            //     if (loi.type == "polygon") {
            //         parameters.aoi = loi.toJson();
            //     } else if (loi.type == "multipoint") {
            //         parameters.poi = loi.toJson();
            //     } else if (loi.type == "point") {
            //         let multiPoint = new Multipoint(loi.spatialReference);
            //         multiPoint.addPoint(loi);
            //         parameters.poi = multiPoint.toJson();
            //     }
            // }

            const props:any = {};
            // tslint:disable-next-line: forin
            for (const key in parameters) {
                props[key] = parameters[key];
            }

            parameters['properties'] = JSON.stringify(props);
            this.sendRequest(urlWf,parameters, '/jobs/' + parameters.jobId + '/update', successCallBack, errorCallBack);
        }

    public  getExtendedProperties(urlWf:any, jobId:any, successCallBack:any, errorCallBack:any) {
        const params = {};
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/extendedProperties', (response:any) => {
            successCallBack(response.containers);
        }, errorCallBack);
    }

    public updateEXtendedProperties(urlWf:any, parameters:any, jobId:any, tableName:any, recordId:any, successCallBack:any, errorCallBack:any) {
        this.sendRequest(urlWf,parameters, '/jobs/' + jobId + '/extendedProperties/' + tableName + '/' + recordId + '/update', (response:any) => {
            successCallBack(response);
        }, errorCallBack);
    }

    public addLinkedFileAttachment(urlWf:any, jobId:any, filePath:any, user:any, successCallBack:any, errorCallBack:any){
        const  params = {
            user,
            storageType: 1,
            filePath
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/attachments/add',  (response:any) => {
            successCallBack(response.attachmentId);
        }, errorCallBack);
    }

    public addEmbeddedAttachment(user:any, jobId:any, form:any, successCallBack:any, errorCallBack:any) {
        //  const user = this.formatDomainUsername(user);
        const urlToSend = '/jobs/' + jobId + '/attachments/add?user=' + user + '&storageType=2&f=json';
        // dom.byId('user').value = user;
        this.sendRequestFile(form, urlToSend, (response:any) => {
            successCallBack(response.attachmentId);
        }, errorCallBack);
    }

  
    public closeJob(urlWf:any, jobId:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/close', successCallBack, errorCallBack);
    }

    public reopenClosedJobs(urlWf:any, jobIds:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user,
            jobs: jobIds
        };
        this.sendRequest(urlWf,params, '/jobs/reopen', successCallBack, errorCallBack);
    }

    public getNotes(urlWf:any, jobId:any, successCallBack:any, errorCallBack:any) {
        const params = {};
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/notes',  (response:any) => {
            successCallBack(response.notes);
        }, errorCallBack);
    }

    public updateNotes(urlWf:any, jobId:any, notes:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user,
            notes
        };
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/notes/update', successCallBack, errorCallBack);
    }

    public getReportContentURL(reportId:any, user:any) {
        let contentUrl = this.url + '/reports/' + reportId + '/generate';
        // make it work on 10.1
        contentUrl += '?f=file';
        if (user !== null && user !== '') {
            contentUrl += '&user=' + this.formatDomainUsername(user);
        }
        // if (this.token != null && this.token != "") {
        //     contentUrl += "&token=" + this.token;
        // }
        // if (this.timeZoneOffset != 0) {
        //     contentUrl += "&timeZoneOffset=" + this.timeZoneOffset;
        // }
        // if (this.proxyURL) {
        //     contentUrl = this.proxyURL + "?" + contentUrl;
        // }
        return contentUrl;
    }

    public searchJobs(urlWf:any, text:any, user:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user,
            text
        };

        this.sendRequest(urlWf,params, '/jobs/search', successCallBack, errorCallBack);
    }

    public executeSteps(urlWf:any, user:any, stepIds:any, jobId:any, successCallBack:any, errorCallBack:any) {
        const params = {
            user,
            steps: stepIds.toString(),
            auto: false
        };
        this.sendRequest(urlWf, params, '/jobs/' + jobId + '/workflow/steps/execute', (response:any) => {
            successCallBack(response.executeInfo);
        }, errorCallBack);
    }

    public getAllSteps(urlWf:any, jobId:any, successCallBack:any, errorCallBack:any) {
        const  params = {};
        this.sendRequest(urlWf,params, '/jobs/' + jobId + '/workflow/steps',  (response:any) => {
            successCallBack(response.steps);
        }, errorCallBack);
    }

}
