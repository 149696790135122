<div class="d-flex" [ngClass]="{'NoLabelLeft': !isLabelLeft}">
    <div *ngIf="showLabel" [style.width.px]="labelWidth" class="labelClass" [ngClass] = "{'label-readonly': isReadOnly}">
        <!-- {{labelName || "No title" | translate}} -->
        <ng-container *ngIf="notUseTranslate">
            {{labelName || "No title" }}
        </ng-container>
        <ng-container *ngIf="!notUseTranslate">
            {{labelName || "No title" | translate }}
        </ng-container>
        <span *ngIf="_required" [style.color]="'red'">&nbsp;*</span>
    </div>
    <div style="display: flex; width: 100%;"
        [ngStyle]="{'max-width': isLabelLeft && showLabel ? 'calc(100% - 120px)' : '100%' }">
        <ng-container *ngIf="hasFormCtrl">
            <!-- <div style="width: 65%;">
                <p-colorPicker [formControl]="ctrl" [appendTo]="'body'" [disabled]='disabledCtrl' [format]="format"
                    [inline]="isInline" (onChange)="onChangeColor($event)"></p-colorPicker>
            </div>
            <div style="width: 35%; padding-left: 10px;" class="text-color-class">
                {{ctrl.value}}
            </div> -->
            <div class="p-inputgroup div-ctrl-class" [ngClass]="{'core-required': requiredClass}">
                <input type="text" [formControl]="ctrl" [ngClass]="{'input-readonly': isReadOnly}"
                    [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly" [placeholder]="placeholder"
                    pInputText>
                <span class="p-inputgroup-addon">
                    <p-colorPicker [appendTo]="'body'" [disabled]='disabledCtrl || isReadOnly' [format]="format"
                        [inline]="isInline" [(ngModel)]="valueColor" (onChange)="onChangeColor($event)"></p-colorPicker>
                </span>

            </div>

        </ng-container>
        <ng-container *ngIf="!hasFormCtrl">
            <!-- <div style="width: 65%;">
                <p-colorPicker [(ngModel)]="valueModel" [appendTo]="'body'" [disabled]='disabledCtrl' [format]="format"
                    [inline]="isInline" (onChange)="onChangeColor($event)"></p-colorPicker>
            </div>
            <div style="width: 35%; padding-left: 10px;" class="text-color-class">
                {{valueModel}}
            </div> -->
            <div class="p-inputgroup div-ctrl-class">
                <input type="text" [(ngModel)]="valueModel" [placeholder]="placeholder"
                    [ngClass]="{'input-readonly': isReadOnly}" [readonly]="disabledCtrl"
                    [disabled]="!disabledCtrl && isReadOnly" (keydown)="onChangeValue($event)" pInputText>
                <span class="p-inputgroup-addon">
                    <p-colorPicker [appendTo]="'body'" [disabled]='disabledCtrl || isReadOnly' [format]="format" [inline]="isInline"
                        [(ngModel)]="valueModel" (onChange)="onChangeColor($event)"></p-colorPicker>
                </span>
            </div>

        </ng-container>
    </div>
</div>