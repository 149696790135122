import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/app-base/auth.guard';
import { ErrorInterceptor } from 'app/app-base/ErrorInterceptor';
import { JwtInterceptor } from 'app/app-base/JwtInterceptor ';
import { WorkflowDesignComponent } from './workflow-design/workflow-design.component';
import { DiagramMobileComponent } from './diagram-mobile/diagram-mobile.component';
import { WorkflowManagerComponent } from './workflow-manager/workflow-manager.component';
import { RunBPMNComponent } from './menu-tool/run-job/run-bpmn-workflow.component';

const routes: Routes = [
    {
        path: '',
        component: WorkflowDesignComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'run/:windowId/:jobId/:clientId/:username/:password/:appId',
        component: RunBPMNComponent,
        canActivate: [AuthGuard]
    },{
        path: 'diagram/:jobId/:username/:password',
        component: DiagramMobileComponent,
        // canActivate: [AuthGuard]
     },{
        path: 'manager/:applicationId/:clientId/:username/:password',
        component: WorkflowManagerComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule],
    providers: []
})
export class WorkflowRoutingModule { }
