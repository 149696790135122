
<ng-template #formAdd>
    <div class="wp">
        <div *ngIf="formLoadFinish">
            <div [formGroup]="formDataParams" class="col-12 grid">
                <ng-container *ngFor="let field of fieldListPrams">
                    <ng-container *ngIf="field.FIELD_SHOW == 'Y'">
                        <div class="col-6 pt-0">
                            <ng-container [ngSwitch]="field.FIELD_TYPE">
                                <ng-container *ngSwitchCase="'text'">
                                    <app-input  [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED"></app-input>
                                </ng-container>
                                <ng-container *ngSwitchCase="'number'">
                                    <app-input [type]="'number'" [labelName]="field.FIELD_LABEL"
                                    [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED">
                                </app-input>
                                </ng-container>
                                <ng-container *ngSwitchCase="'date'">
                                    <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [disabledCtrl]="field.FIELD_DISABLED"
                                    [formControlName]="field.FIELD_NAME"></app-core-datetime>
                                </ng-container>
                                <ng-container *ngSwitchCase="'datetime'">
                                    <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [disabledCtrl]="field.FIELD_DISABLED"
                                    [formControlName]="field.FIELD_NAME"   [calConfig]="{ showTime: true }" ></app-core-datetime>
                                </ng-container>
                                <ng-container *ngSwitchCase="'select'">
                                    <app-select  [labelName]="field.FIELD_LABEL"
                                    [isLabelLeft]="false" [formControlName]="field.FIELD_NAME"
                                    [dataLookup]="lookupCondition[field.FIELD_NAME]" displayField="DESCR" [disabledCtrl]="field.FIELD_DISABLED"
                                    valueField="CODE">
                                </app-select>
                                </ng-container>
                                <ng-container *ngSwitchCase="'T'">
                                    <app-input [type]="'text-area'" [labelName]="field.FIELD_LABEL" [disabledCtrl]="field.FIELD_DISABLED"
                                    [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" ></app-input>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div>{{field.FIELD_TYPE}} : {{field.FIELD_LABEL}}</div>
                                </ng-container>
                            </ng-container>
                        </div>    
                    </ng-container>
                </ng-container>
            </div>
            <hr>
            <div class="titleFormWf">Insert Job</div>
            <div [formGroup]="formData" class="col-12 grid">
                <ng-container *ngFor="let field of fieldList">
                    <ng-container *ngIf="field.FIELD_SHOW">
                        <div class="col-6 pt-0">
                            <ng-container [ngSwitch]="field.FIELD_TYPE">
                                <ng-container *ngSwitchCase="'text'">
                                    <app-input  [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED"></app-input>
                                </ng-container>
                                <ng-container *ngSwitchCase="'number'">
                                    <app-input [type]="'number'" [labelName]="field.FIELD_LABEL"
                                    [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED">
                                </app-input>
                                </ng-container>
                                <ng-container *ngSwitchCase="'date'">
                                    <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [disabledCtrl]="field.FIELD_DISABLED"
                                    [formControlName]="field.FIELD_NAME"></app-core-datetime>
                                </ng-container>
                                <ng-container *ngSwitchCase="'datetime'">
                                    <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [disabledCtrl]="field.FIELD_DISABLED"
                                    [formControlName]="field.FIELD_NAME"   [calConfig]="{ showTime: true }" ></app-core-datetime>
                                </ng-container>
                                <ng-container *ngSwitchCase="'select'">
                                    <app-select  [labelName]="field.FIELD_LABEL"
                                    [isLabelLeft]="false" [formControlName]="field.FIELD_NAME"
                                    [dataLookup]="lookupCondition[field.FIELD_NAME]" displayField="DESCR" [disabledCtrl]="field.FIELD_DISABLED"
                                    valueField="CODE">
                                </app-select>
                                </ng-container>
                                <ng-container *ngSwitchCase="'text-button'">
                                    <app-input  [required]="false" [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"  [formControlName]="field.FIELD_NAME" type="text-button" (iconClick)="selectUser()"></app-input>
                                </ng-container>
                                <ng-container *ngSwitchCase="'T'">
                                    <app-input [type]="'text-area'" [labelName]="field.FIELD_LABEL" [disabledCtrl]="field.FIELD_DISABLED"
                                    [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" ></app-input>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div>{{field.FIELD_TYPE}} : {{field.FIELD_LABEL}}</div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <!-- <div class="btn-add-wf">
                <app-button [labelName]="'Cancel'" [typeButton]="'cancel'"(clickEvent)="btnCancelClick()"></app-button>
                <app-button [labelName]="'Reset'" [typeButton]="'reset'" style="margin-left: 10px" (clickEvent)="btnResetClick()"></app-button>
                <app-button [labelName]="'Ok'" style="margin-left: 10px" (clickEvent)="btnOkClick()"></app-button>
            </div> -->
            </div>
        </div>
</ng-template>

<app-dialog-prime #dialogPrime [isAppendBody]="true"></app-dialog-prime>
<app-dialog-prime #dialogUser [isAppendBody]="true"></app-dialog-prime>

<ng-template #tempUser>
    <div style="padding-bottom: 10px; height: 400px; overflow: auto;">
        <app-core-tree  [dataTree] = "dataUser"  [nodeSelectedModel]="defaultCheckedUser" [treeConfig]="treeConfigUser" 
        (selectNode)="onCheckUser($event)" (unSelectNode)="onUnCheckUser($event)"></app-core-tree>
    </div>
    <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
        <app-button labelName='Cancel' typeButton="cancel" (clickEvent)="onCancelUser()"></app-button>
        <app-button labelName='OK' typeButton="save"  style="margin-left: 10px;"  (clickEvent)="onAddUser()"></app-button>
    </div>
</ng-template>


