import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CoreModule } from 'app/core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WorkflowDesignComponent } from './workflow-design/workflow-design.component';
import { BpmnWorkflowService } from './services/workflow.service';
import { WorkflowRoutingModule } from './workflow-routing.module';
import { ArcGisWorkflowService } from './services/arcgis-workflow.service';
import { WorkflowManagerComponent } from './workflow-manager/workflow-manager.component';
import { RequestService } from 'app/core/services/request.service';
import { WfService } from './services/wf-odata.service';

import { ErrorInterceptor } from 'app/app-base/ErrorInterceptor';
import { JwtInterceptor } from 'app/app-base/JwtInterceptor ';
import { TimeoutInterceptor } from 'app/app-base/TimeoutInterceptor';
import { DiagramMobileComponent } from './diagram-mobile/diagram-mobile.component';
import { RunBPMNComponent } from './menu-tool/run-job/run-bpmn-workflow.component';
import { GetJobComponent } from './menu-tool/get-job/get-job.component';
import { BackJobComponent } from './menu-tool/back-job/back-job.component';
import { RecallJobComponent } from './menu-tool/restore-job/recall-job.component';
import { AddBPMNComponent } from './menu-tool/add-job/add-bpmn.component';
import { SharedModule } from 'src/shared/shared.module';
import { ViewJobComponent } from './menu-tool/view-job/view-job-workflow.component';
import { PrimeNgModule } from 'app/core/prime-ng.module';
import { TranslateModule } from '@ngx-translate/core';


const comp = [
    WorkflowDesignComponent,
    RunBPMNComponent,
    AddBPMNComponent,
    ViewJobComponent,
    WorkflowManagerComponent,
    GetJobComponent,
    BackJobComponent,
    RecallJobComponent,
    DiagramMobileComponent
];
@NgModule({
    declarations: comp,
    exports: comp,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DragDropModule,
        ScrollingModule,
        CoreModule,
        HttpClientModule,
        WorkflowRoutingModule,
        TranslateModule,
        SharedModule,
        PrimeNgModule
    ],
    providers: [
        BpmnWorkflowService,
        ArcGisWorkflowService,
        RequestService,
        WfService,
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class WorkflowModule { }
