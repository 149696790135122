import { DecimalPipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { TableColumnDefination } from '../core-table/core-table.component';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from '../services/request.service';

@Injectable({
    providedIn: 'root'
})
export class CoreWindowService {
    lookupTab: any = null;
    data: any = null;
    selectRelateLookup: any = {};

    // daint: nhóm variable dùng để thực hiện calculation giữa các tab trong 1 window
    public dataCalculation = null;
    public calcSubject = new BehaviorSubject(this.dataCalculation);
    public calcObservable = this.calcSubject.asObservable();

    private reqServiceSQL: RequestService;
    private reqServiceAutoData: RequestService;
    private reqServiceArcGIS: RequestService;

    constructor(
        private decimal: DecimalPipe,
        injector: Injector
    ) { 
        this.reqServiceSQL = new RequestService(injector);
        this.reqServiceAutoData = new RequestService(injector);
        this.reqServiceArcGIS = new RequestService(injector);

        this.reqServiceArcGIS.switchType('arcgis3x');
        this.reqServiceAutoData.switchType('clouddata');
        this.reqServiceSQL.switchType('sql');
    }

    updateCalc(val: any) {
        this.dataCalculation = val;
        this.calcSubject.next(val);
    }

    convertDateToUTC(date: any) {
        const d = new Date(date);
        let str_y = d.getFullYear();
        let str_m = d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
        let str_d = d.getDate() > 9 ? d.getDate() : '0' + (d.getDate())

        let str_t_h = d.getHours() > 9 ? d.getHours() : '0' + (d.getHours())
        let str_t_m = d.getMinutes() > 9 ? d.getMinutes() : '0' + (d.getMinutes())
        let str_t_s = d.getSeconds() > 9 ? d.getSeconds() : '0' + (d.getSeconds())

        // let str = str_y + '-' + str_m + '-' + str_d + ' ' + str_t_h + ':' + str_t_m + ':' + str_t_s + 'Z'; // old version - error with safari
        let str = str_y + '/' + str_m + '/' + str_d + ' ' + str_t_h + ':' + str_t_m + ':' + str_t_s;
        return new Date(str)
    }

    /** Chuyển đổi dữ liệu Date và combo box. Đầu vào gồm: định dạng cột, danh sách dữ liệu, mảng lookup và reqService từng table (tránh bị nhầm lẫn kiểu request) */
    public async convertDateAndComboBox(tableColumnDefination: any[], resultList: any[], lookupCondition: any[], dataSource: any) {
        let reqService: any = null;
        switch (dataSource.INFORMATION.SERVICE_TYPE) {
            case 'CloudData':
                reqService = this.reqServiceAutoData;
                break;
            case 'SQL':
                reqService = this.reqServiceSQL;
                break;
            case 'FeatureServer':
            case 'MapServer':
            case 'WorkflowServices':
                reqService = this.reqServiceArcGIS;
                break;
            case 'postgrest':
                reqService = this.reqServiceArcGIS;
                break;
            case 'oracle':
                reqService = this.reqServiceArcGIS;
                break;
            default:
                reqService = this.reqServiceSQL;
                break;
        }

        const dateColumns = tableColumnDefination.filter(fil => fil.rowType === 'date');
        if (dateColumns.length > 0) {
            dateColumns.forEach(col => {
                const colName = col.rowFieldName + '_D';
                resultList.forEach(item => {
                    let dateString = '';
                    let newDate: any = null;
                    if (item[col.rowFieldName] !== undefined && item[col.rowFieldName] !== null) {
                        // const newDate = new Date(item[col.rowFieldName]);
                        // const now_d = new Date()
                        const d = new Date(item[col.rowFieldName])
                        newDate = this.convertDateToUTC(d)
                        item[col.rowFieldName] = newDate
                        // newDate.setHours(newDate.getHours() - (now_d.getTimezoneOffset() / 60))
                        if (!isNaN(newDate.getTime())) {
                            dateString += newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
                            dateString += '-';
                            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
                            dateString += '-' + newDate.getFullYear();
                        }

                    }
                    item[colName] = dateString;

                });
            });
        }

        const dateTimeColumns = tableColumnDefination.filter(fil => fil.rowType === 'datetime');
        if (dateTimeColumns.length > 0) {
            dateTimeColumns.forEach(col => {
                const colName = col.rowFieldName + '_DT';
                const colName_v = col.rowFieldName + '_V';
                resultList.forEach(item => {
                    let dateString = '';
                    if (item[col.rowFieldName] !== undefined && item[col.rowFieldName] !== null) {
                        // const newDate = new Date(item[col.rowFieldName]);
                        const d = new Date(item[col.rowFieldName])
                        const newDate = this.convertDateToUTC(d)
                        item[col.rowFieldName] = newDate
                        if (!isNaN(newDate.getTime())) {
                            dateString += newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
                            dateString += '-';
                            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
                            dateString += '-' + newDate.getFullYear();
                            dateString += ' ';
                            dateString += newDate.getHours() > 9 ? newDate.getHours() : '0' + newDate.getHours();
                            dateString += ':';
                            dateString += newDate.getMinutes() > 9 ? newDate.getMinutes() : '0' + newDate.getMinutes();
                            dateString += ':';
                            dateString += newDate.getSeconds() > 9 ? newDate.getSeconds() : '0' + newDate.getSeconds();
                        }
                        item[colName_v] = new Date(item[col.rowFieldName]);
                    }
                    item[colName] = dateString;
                });
            });
        }

        const comboBoxColumns = tableColumnDefination.filter(fil => fil.rowType === 'select');
        if (comboBoxColumns.length > 0) {
            // Dùng for thay vì forEach vì forEach không xử lý đồng bộ được
            for (let i = 0; i < comboBoxColumns.length; i++) {
                const col = comboBoxColumns[i];
                const colName = col.rowFieldName + '_C';
                for (let j = 0; j < resultList.length; j++) {
                    const item = resultList[j];
                    const val = item[col.rowFieldName];
                    let comboBoxStringVal = '';
                    if (col.rowDomainId) {
                        // có domain id
                        if (lookupCondition[col.rowDomainId] && val !== undefined && val !== null) {
                            let result = null;
                            if (val.CODE) {
                                result = lookupCondition[col.rowDomainId].filter((fil: any) => fil.CODE.toString() === val.CODE.toString());
                            } else {
                                result = lookupCondition[col.rowDomainId].filter((fil: any) => fil.CODE.toString() === val.toString());
                            }

                            if (result.length > 0) {
                                comboBoxStringVal = result[0].DESCR;
                            }
                        }
                    } else {
                        comboBoxStringVal = await this.getValueComboBox(item, val, col, lookupCondition, colName, reqService);
                    }

                    item[colName] = comboBoxStringVal;
                }
            }
        }

        const numberColumns = tableColumnDefination.filter(fil => fil.rowType === 'number');
        if (numberColumns.length > 0) {
            numberColumns.forEach(col => {
                const colName = col.rowFieldName + '_N';
                let obj: any = null;
                try {
                    obj = JSON.parse(col.rowFormat);
                } catch (error) {

                }
                let maxFraction = 0;
                let minFraction = 0;
                let vFormat: any = null;
                if (obj && obj.maxFractionDigits) {
                    maxFraction = obj.maxFractionDigits;
                    vFormat = `.${minFraction}-${maxFraction}`;
                }
                if (obj && obj.minFractionDigits) {
                    if (maxFraction) {
                        if (minFraction <= maxFraction) {
                            minFraction = obj.minFractionDigits
                        }

                    } else {
                        minFraction = obj.minFractionDigits
                    }

                    vFormat = `.${minFraction}-${maxFraction}`;
                }
                resultList.forEach(item => {
                    let val = item[col.rowFieldName];
                    try {
                        if (vFormat) {
                            val = this.decimal.transform(+val, vFormat);
                        }
                    } catch (error) { }
                    item[colName] = val;
                });
            });
        }

        // Dữ liệu resultList đã có sẵn với trường col.rowFieldFilter.COLUMNCODE nên không cần query
        const inputSearchColumns = tableColumnDefination.filter(fil => fil.rowType === 'search');
        if (inputSearchColumns.length > 0) {
            inputSearchColumns.forEach(col => {
                const colName = col.rowFieldName + '_S';
                const tag = col.rowFieldFilter.COLUMNDISPLAY ? col.rowFieldFilter.COLUMNDISPLAY : col.rowFieldFilter.COLUMNCODE;
                const tag_2 = col.rowFieldFilter.COLUMNKEY ? col.rowFieldFilter.COLUMNKEY : col.rowFieldFilter.COLUMNCODE;
                const tag_2_value: any[] = []; // lưu trữ các giá trị để tạo câu điều kiện tìm kiếm

                // Nếu trong result list đã có sẵn trường tương ứng columndisplay => dùng luôn
                // Nếu không có thì tạo câu điều kiện truy vấn vào bảng => lấy ra danh sách giá trị tương ứng để hiển thị
                resultList.forEach(item => {
                    if (item[tag]) {
                        item[colName] = item[col.rowFieldFilter.COLUMNDISPLAY] ?? item[col.rowFieldName];
                    } else {
                        if (item[col.rowFieldName]) {
                            const checked = tag_2_value.filter(fil => fil && fil === item[col.rowFieldName]);
                            if (checked.length === 0) {
                                tag_2_value.push(item[col.rowFieldName]);
                            }
                        } else {
                            item[colName] = item[col.rowFieldName];
                        }
                    }

                });
                if (tag_2_value.length > 0) {
                    const where: any[] = [tag_2, 'in', tag_2_value];
                    this.getValueSearch(resultList, where, col, colName)
                }
            })
        }

        // Đặt ở cuối để không sót trường hợp nào
        const rowFromDomain = tableColumnDefination.filter(fil => fil.rowIsDomain);
        if (rowFromDomain) {
            rowFromDomain.forEach(col => {
                if (lookupCondition[col.rowDomainId]) {
                    resultList.forEach(item => {
                        const val = item[col.rowFieldName];
                        let result = lookupCondition[col.rowDomainId].filter((fil: any) => val && fil.CODE.toString() === val.toString());
                        if (result.length > 0) {
                            if (result[0].COLOR_CODE && result[0].COLOR_CODE.indexOf('#') === 0) {
                                item['__color_code_' + col.rowFieldName] = result[0].COLOR_CODE;
                            }
                        }
                    });
                }
            })
        }
    }

    async getValueSearch(list: any[], where: any[], col: any, colname: any) {
        const tag_2 = col.rowFieldFilter.COLUMNKEY ? col.rowFieldFilter.COLUMNKEY : col.rowFieldFilter.COLUMNCODE;
        const dataSource = col.rowFieldFilter;
        const url = dataSource.FOREIGNTABLE;
        let reqService: any = null;
        switch (dataSource.SERVICE_TYPE) {
            case 'CloudData':
                reqService = this.reqServiceAutoData;
                break;
            case 'SQL':
                reqService = this.reqServiceSQL;
                break;
            case 'FeatureServer':
            case 'MapServer':
            case 'WorkflowServices':
                reqService = this.reqServiceArcGIS;
                break;
            case 'postgrest':
                reqService = this.reqServiceArcGIS;
                break;
            case 'oracle':
                reqService = this.reqServiceArcGIS;
                break;
            default:
                reqService = this.reqServiceSQL;
                break;
        }
        const res = await reqService.service.search({ url, where, select: [tag_2, col.rowFieldFilter.COLUMNDISPLAY] }).pipe(take(1)).toPromise();
        const tag_3 = col.rowFieldFilter.COLUMNCODE ? col.rowFieldFilter.COLUMNCODE : col.rowFieldFilter.COLUMNKEY;
        list.forEach(item => {
            const obj = res.features.find((v: any) => v[tag_2] == item[col.rowFieldName]);
            if (obj) {
                item[colname] = col.rowFieldFilter.COLUMNDISPLAY ? obj[col.rowFieldFilter.COLUMNDISPLAY] : item[tag_3]
            } else {
                item[colname] = null
            }
        });
    }

    /** Lấy giá trị lookup từ url */
    private async getValueComboBox(item: any, val: any, col: TableColumnDefination, lookup: any[], colName: any, reqService: any): Promise<string> {
        let str = '';
        let dataSource = col.rowFieldFilter;

        let lookupCondition: any[] = [];
        // if (dataSource.WHEREFIELDNAME && dataSource.WHEREFIELDNAME !== '') {
        //     if (dataSource.PARENT_FIELDID) {
        //         if (dataSource.PARENT_COLDEF) {
        //             console.log('DATASOURCE >>>>>>>>>> ', dataSource);
        //             // Trường hợp phụ thuộc vào giá trị control khác trong cùng 1 tab
        //             const value = item[dataSource.PARENT_COLDEF.rowFieldName];
        //             lookupCondition = await this.getLookupFromParent(value, col, lookup, colName, reqService);
        //         } else {
        //             // Trường hợp phụ thuộc vào giá trị control đang nằm ở tab khác => Cần cách giải quyết khác thay vì query all
        //             lookupCondition = await this.getLookupFromUrl({ dataSource });
        //         }
        //     } else {
        //         // Trường hợp đứng độc lập nhưng vẫn có điều kiện riêng của bản thân
        //         lookupCondition = await this.getLookupFromUrl({ dataSource });
        //     }
        // } else { // Control đứng lẻ 1 mình
        //     lookupCondition = await this.getLookupFromUrl({ dataSource });
        // }

        if (!lookup[colName]) {
            lookupCondition = await this.getLookupFromUrl({ dataSource });
            lookup[colName] = lookupCondition;
        } else {
            lookupCondition = lookup[colName];
        }
        const a = lookupCondition.filter(fil => fil.CODE === val);
        if (a && a.length > 0) {
            str = a[0].DESCR;
        }
        return str;
    }

    // Lấy lookup từ url (sử dụng request)
    private async getLookupFromUrl(obj: { dataSource: any }) {
        const dataSource = obj.dataSource;
        const url = dataSource.FOREIGNTABLE;
        let where: any[] = []; // điều kiện lấy toàn bộ

        let reqService: any = null;
        switch (dataSource.SERVICE_TYPE) {
            case 'CloudData':
                reqService = this.reqServiceAutoData;
                break;
            case 'SQL':
                reqService = this.reqServiceSQL;
                break;
            case 'FeatureServer':
            case 'MapServer':
            case 'WorkflowServices':
                reqService = this.reqServiceArcGIS;
                break;
            case 'postgrest':
                reqService = this.reqServiceArcGIS;
                break;
            case 'oracle':
                reqService = this.reqServiceArcGIS;
                break;
            default:
                reqService = this.reqServiceSQL;
                break;
        }

        const res = await reqService.service.search({ url, where, logic: 'and' }).pipe(take(1)).toPromise();
        const lookupCondition: any = [];
        res.features.forEach((item: any) => {
            const obj: any = {};
            obj['CODE'] = dataSource.COLUMNKEY ? item[dataSource.COLUMNKEY] : item[dataSource.COLUMNCODE];
            obj['DESCR'] = item[dataSource.COLUMNDISPLAY];
            lookupCondition.push(obj);
        });
        return lookupCondition;
    }

    // Clone Từ Select => Select thay đổi phải thay đổi theo
    private async getLookupFromParent(value: any, col: any, lookup: any[], colName: any, reqService: any) {
        const dataSource = col.rowFieldFilter;
        if (!this.selectRelateLookup[col.rowFieldName]) {
            this.selectRelateLookup[col.rowFieldName] = [];
        }

        const url = dataSource.FOREIGNTABLE;
        // let where = [dataSource.WHEREFIELDNAME, '=', value]; // k lọc theo điều kiện nữa, lấy toàn bộ 1 lần cho tiết kiệm request
        let where: any[] = [];
        const res = await reqService.service.search({
            url,
            where,
            logic: 'and'
        }).pipe(take(1)).toPromise();
        const lookupCondition: any = [];
        this.selectRelateLookup[col.rowFieldName].push(value);
        res.features.forEach((item: any) => {
            const obj: any = {};
            obj['CODE'] = dataSource.COLUMNCODE ? item[dataSource.COLUMNCODE] : item[dataSource.COLUMNKEY];
            obj['DESCR'] = item[dataSource.COLUMNDISPLAY];
            lookupCondition.push(obj);
        });
        if (!lookup[colName]) {
            lookup[colName] = [];
        }
        lookup[colName] = lookup[colName].concat(lookupCondition);
        return lookup[colName];
        // if (this.selectRelateLookup[col.rowFieldName].includes(value)) {
        //     return lookup[colName];
        // } else {
        //     const url = dataSource.FOREIGNTABLE;
        //     // let where = [dataSource.WHEREFIELDNAME, '=', value]; // k lọc theo điều kiện nữa, lấy toàn bộ 1 lần cho tiết kiệm request
        //     let where: any[] = [];
        //     const res = await reqService.service.search({
        //         url,
        //         where,
        //         logic: 'and'
        //     }).pipe(take(1)).toPromise();
        //     const lookupCondition: any = [];
        //     this.selectRelateLookup[col.rowFieldName].push(value);
        //     res.features.forEach((item: any) => {
        //         const obj: any = {};
        //         obj['CODE'] = dataSource.COLUMNCODE ? item[dataSource.COLUMNCODE] : item[dataSource.COLUMNKEY];
        //         obj['DESCR'] = item[dataSource.COLUMNDISPLAY];
        //         lookupCondition.push(obj);
        //     });
        //     if (!lookup[colName]) {
        //         lookup[colName] = [];
        //     }
        //     lookup[colName] = lookup[colName].concat(lookupCondition);
        //     return lookup[colName];
        // }

    }

    /** Tìm kiếm Parent Tab dựa trên tabId, truyền vào id của tab sẽ ra tab bố, truyền id tab bố ra tab ông ... */
    public findParentTab(tabId: any) {
        let childTab: any = null;
        Object.keys(this.lookupTab).forEach(key => {
            const tab = this.lookupTab[key];
            if (tab.INFORMATION.TAB_ID === tabId) {
                childTab = tab;
            }
        });

        let parentTab = null;
        if (childTab) {
            Object.keys(this.lookupTab).forEach(key => {
                const tab = this.lookupTab[key];
                if (tab.INFORMATION.TAB_ID === childTab.INFORMATION.PARENT_ID) {
                    parentTab = tab;
                }
            });
        }

        return parentTab;
    }
}
