import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AppService } from 'app/app-base/app.service';
import { User } from './login/login-model';
import { RequestService } from 'app/core/services/request.service';
import { Router } from '@angular/router';
import { PushNotificationService } from 'app/notification/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefeshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private authService: AuthService,
        private appService: AppService,
        private reqService: RequestService,
        private router: Router,
        private pushNotify: PushNotificationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err && err.status && err.status === 401) {
                // auto logout if 401 response returned from api
                // this.authService.logout();
                // location.reload(true);

                this.handle401(request, next);
            }
            const error = err ? err.error ? err.error.message : err.statusText : 'Unknown Error';
            return throwError(error);
        }));
    }
    private handle401(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefeshing) {
            this.isRefeshing = true;
            this.refreshTokenSubject.next(null);
            const requestType = request.context.get(this.appService.REQUEST_TYPE);
            if (requestType === 'oracle') {
                return this.appService.refreshTokenOracle(request.url).subscribe((response: any) => {
                    if (response) {
                        this.isRefeshing = false;
                        return next.handle(request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${response.access_token}`
                            }
                        }));
                    } else {
                        this.isRefeshing = false;
                        alert('Your working session is over time!');
                        this.authService.logout().subscribe((response: any) => {
                            this.router.navigate(['/login']);
                        });
                        return null;
                    }
                })
            } else {
                const tokenrefresh = this.appService.tokenrefresh;
                const url = `${this.appService.urlWS}/Login/RefreshToken`;
                const params: any = {};
                params['refreshToken'] = tokenrefresh;
                return this.reqService.service.query({ url, params, method: 'POST' }).subscribe((res: any) => {
                    if (res.success) {
                        this.isRefeshing = false;
                        const usermoi = res.token;
                        const usercu = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);
                        const myuser: User = {};
                        myuser.schema = usercu.schema;
                        myuser.schemadefault = usercu.schemadefault;
                        myuser.username = usercu.username;
                        myuser.password = usercu.password;
                        myuser.Roles = usercu.roles;
                        myuser.ClientCode = usercu.clientcode;
                        myuser.clientid = usercu.clientid;
                        myuser.userid = usercu.userid;
                        myuser.token = usermoi.token;
                        myuser.expiresin = usermoi.expiresIn;
                        myuser.tokenRefesh = usermoi.tokenRefesh;
                        const expiresInDuration = usermoi.expiresIn * 60;
                        myuser.status = usermoi.status;
                        myuser.staffid = usermoi.staffid;
                        
                        // this.setAuthTimer(expiresInDuration);
                        const now = new Date();
                        // const expirationDate = new Date(now + expiresInDuration * 60 * 1000).toUTCString();
                        const expires = new Date(Date.now() + expiresInDuration * 1000);
                        myuser.expiresAt = expires;
                        if (localStorage) {
                            if (!this.appService.c$) {
                                this.appService.c$ = {};
                                this.appService.setNowDate();
                                this.appService.c$.dateFormat = 'dd/mm/yy';
                            }
                            this.appService.c$['username'] = myuser.username;
                            this.appService.c$['userid'] = myuser.userid;
                            this.appService.c$['clientid'] = myuser ? myuser.clientid : null;
                            let staffid = null;
                            try {
                                staffid = JSON.parse(myuser.staffid);
                            } catch (error) {
        
                            }
                            this.appService.c$['staffid'] = staffid;
                            sessionStorage.setItem(this.appService.hostName1 + 'currentUser', JSON.stringify(myuser));
                            sessionStorage.setItem(this.appService.hostName1 + 'currentLogin', 'true');
                        }
    
                        this.pushNotify.sendDataToServiceWorker();
    
                        this.authService.currentUserSubject.next(myuser);
                        this.authService.currentLoginSubject.next(true);
                        return next.handle(request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${this.authService.currentUserValue.token}`
                            }
                        }));
                    } else {
                        this.isRefeshing = false;
                        alert('Your working session is over time!');
                        this.authService.logout().subscribe((response: any) => {
                            this.router.navigate(['/login']);
                        });
                        return null;
                    }
                });
            }
        } else {
            const checkAuth = request.headers.get('Authorization'); // Kiểm tra đã gửi tham số Authorzation chưa, gửi thì dùng token này
            if (checkAuth) {
                return next.handle(request);
            } else {
                return next.handle(request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.authService.currentUserValue.token}`
                    }
                }));
            }
        }
    }
}
