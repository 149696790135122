import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { clone } from 'lodash';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseInterface, DeleteResponse, IdParams, InsertResponse, OdataParams, QdataParams, SearchResponse, UpdateResponse } from './request.service';

@Injectable()
export class AutoDataService implements BaseInterface {

    constructor(
        private appService: AppService,
        private http: HttpClient
    ) {
        // this.testDecode();
    }

    /** type: 'search' || 'insert' || 'update' || 'delete' */
    private request(
        url: string,
        params: any = {},
        type: string = ''
    ): Observable<any> {
        let newUrl = url;
        let method: any = 'post'; // mặc định phương thức gọi là post

        switch (type) {
            case 'search':
                method = 'get';
                break;
            case 'insert':
                method = 'post';
                break;
            case 'update':
                method = 'put';
                break;
            case 'delete':
                method = 'delete';
                break;
            default:
                break;
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        const options: any = {
            params,
            responseType: 'text',
            headers,
            body: {}
        };

        if (method !== 'get') {
            options['body'] = params;
            delete options.params;
        }


        return this.http.request(method, newUrl, options);
    }
    private requestWithToken(
        url: string,
        params: any = {},
        type: string = '',
        token: string
    ): Observable<any> {
        let newUrl = url;
        let method: any = 'post'; // mặc định phương thức gọi là post

        switch (type) {
            case 'search':
                method = 'get';
                break;
            case 'insert':
                method = 'post';
                break;
            case 'update':
                method = 'put';
                break;
            case 'delete':
                method = 'delete';
                break;
            default:
                break;
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        // const a = localStorage ? this.appService.currentUser : null;
        // const currentUser = a ? JSON.parse(a) : null;
        // if (currentUser && currentUser.token) {
        //     headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        // }
        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }

        const options: any = {
            params,
            responseType: 'json',
            headers,
            body: {}
        };

        if (method !== 'get') {
            options['body'] = params;
            delete options.params;
        }


        return this.http.request(method, newUrl, options);
    }


    query(q: QdataParams): Observable<any> {
        const url = q.url;
        const urlRequest = q.proxy ? `${q.proxy}${url}` : url;

        const contentType = q.contentType ?? 'application/json';

        let headers = new HttpHeaders({
            Accept: 'text/plain'
        });

        if (contentType !== 'unset') {
            headers = headers.append('Content-Type', contentType);
        }

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });

        const options: any = {
            headers,
            params: paramsToSend,
            responseType: q.responseType ?? 'json'
        };

        if (method === 'GET') {
            return this.http.get(urlRequest, options);
        } else {
            return this.http.post(urlRequest, q.params, options);
        }
    }

    queryCustom(q: QdataParams): Observable<any> {
        const url = q.url;
        const urlRequest = q.proxy ? `${q.proxy}${url}` : url;

        const contentType = q.contentType ?? 'application/json';

        let headers = new HttpHeaders({
            Accept: 'text/plain'
        });

        if (contentType !== 'unset') {
            headers = headers.append('Content-Type', contentType);
        }

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });
        const options: any = {
            headers,
            params: paramsToSend,
            responseType: q.responseType ?? 'json'
        };

        if (method === 'GET') {
            return this.http.get(urlRequest, options);
        } else {
            return this.http.post(urlRequest, q.params, options);
        }
    }

    getRecordById(q: IdParams): Observable<any> {
        const url = q.url;
        const params = {
            where: '1=1',
            f: 'json',
            outFields: ['*'],
            primaryKeys: q.id,
        }
        return this.request(url, params, 'search').pipe(map((resp: any) => {
            let res = null;
            try {
                res = JSON.parse(resp);
            } catch (error) {
                // meaning resp is string, cannot JSON.parse
            }

            let result: any = null;
            if (res) {
                if (res.httpCode && res.httpCode !== 200) {
                    return result;
                }
    
                if (res.message === 'Timeout exceeded') {
                    return result;
                }
    
                if (res.datas) {
                    result = res.datas;
                } else {
                    result = null;
                }
            } else {
                result = null;
            }
           
            return result;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    search(p: OdataParams) {
        const where = this.decodeSql(p.where);
        const url = `${p.url}`;
        const outFields = p.select ? [p.select] : ['*'];
        const _params = {
            ...p.data,
            where,
            outFields
        };

        if (p.geometry) {
            _params['geometry'] = JSON.stringify(p.geometry);
            if (p.geometry.type === 'polygon') {
                _params['geometryType'] = 'esriGeometryPolygon';
                _params.spatialRel = 'esriSpatialRelIntersects';
            }
        }

        if (p.distinct) {
            _params['returnDistinct'] = true;
        }
        if (p.orderBy) {
            _params['orderByFields'] = p.orderBy.join(',');
        }
        if (p.groupBy) {
            _params['groupByFields'] = p.groupBy.join(',');
        }

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.startRecord !== null && p.startRecord !== undefined) {
            _params['resultOffset'] = p.startRecord.toString();
        }

        if (p.pageSize) {
            _params['resultRecordCount'] = p.pageSize.toString();
        }


        return this.request(url, _params, 'search').pipe(map((resp: any) => {
            let res = null;
            try {
                res = JSON.parse(resp);
            } catch (error) {
                // meaning resp is string, cannot JSON.parse
                res = resp;
            }

            const result: SearchResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Search Success'
            };


            if (res) {
                if (res.httpCode && res.httpCode !== 200) {
                    result.success = false;
                    result.message = 'Request Error';
                    return result;
                }
    
                if (res.message === 'Timeout exceeded') {
                    result.success = false;
                    result.message = 'Request timeout';
                    return result;
                }
    
                if (res.datas) {
                    result.features = res.datas;
                    result.total = res.numberRecords ?? 0;
                } else {
                    result.success = res.result;
                    result.message = res.message;
                }
            } else {
                result.success = false;
                result.message = resp;
            }
            
            return result;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    searchForMobile(p: OdataParams, token: any): Observable<SearchResponse> {
        const where = this.decodeSql(p.where);
        const url = `${p.url}`;
        const outFields = p.select ? [p.select] : ['*'];
        const _params = {
            ...p.data,
            where,
            outFields
        };

        if (p.geometry) {
            _params['geometry'] = JSON.stringify(p.geometry);
            if (p.geometry.type === 'polygon') {
                _params['geometryType'] = 'esriGeometryPolygon';
                _params.spatialRel = 'esriSpatialRelIntersects';
            }
        }

        if (p.distinct) {
            _params['returnDistinct'] = true;
        }
        if (p.orderBy) {
            _params['orderByFields'] = p.orderBy.join(',');
        }
        if (p.groupBy) {
            _params['groupByFields'] = p.groupBy.join(',');
        }

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.startRecord !== null && p.startRecord !== undefined) {
            _params['resultOffset'] = p.startRecord.toString();
        }

        if (p.pageSize) {
            _params['resultRecordCount'] = p.pageSize.toString();
        }


        return this.requestWithToken(url, _params, 'search', token).pipe(map((res: any) => {
            const result: SearchResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Search Success'
            };

            if (res.httpCode && res.httpCode !== 200) {
                result.success = false;
                result.message = 'Request Error';
                return result;
            }

            if (res.message === 'Timeout exceeded') {
                result.success = false;
                result.message = 'Request timeout';
                return result;
            }

            if (res && res.datas) {
                result.features = res.datas;
                result.total = res.numberRecords ?? 0;
                // result.total = res[1] ? res[1].datas[0].Count : 0;
            } else {
                result.success = res.result;
                result.message = res.message;
            }
            return result;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    insert(p: OdataParams): Observable<any> {
        const url = `${p.url}`;

        // const params: any = {};

        // không gửi khóa chính cùng giá trị vì service auto-data sẽ bị lỗi
        // Update ngày 6 tháng 5 năm 2022, có gửi cả primaryKey, nếu k muốn gửi thì người dùng phải ẩn trường primaryKey đi
        // if (p.primaryKey) {
        //     delete p.data[p.primaryKey];
        // }
        // params['datas'] = [p.data];

        let data = Array.isArray(p.data) ? p.data : [p.data];

        return this.request(url, data, 'insert').pipe(map((resp) => {
            let res = null;
            try {
                res = JSON.parse(resp);
            } catch (error) {
                
            }
            const result: InsertResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Insert Success'
            };

            if (res) {
                if (res.result === false) {
                    result.success = false;
                    result.message = 'Insert Failed';
                }
    
                if (res.message === 'Timeout exceeded') {
                    result.success = false;
                    result.message = 'Request timeout';
                }

                const data: any = Object.assign({}, p.data);
                if (p.primaryKey) {
                    data[p.primaryKey] = res.datas['id'];
                }
                result.features = res.datas;
            } else {
                result.success = false;
                result.message = resp;
            }
            return result;
        }), catchError((error: any) => {
            const rq: InsertResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Insert Error'
            };
            return of(rq)
        }));
    }

    // Cập nhật ngày 05/05/2022: Thay đổi params từ object thành truyền thẳng mảng (áp dụng cả cho insert)
    // Cập nhật ngày 26/09/2023: Thay đổi params từ mảng sang object có dạng { primaryKey: 'ObjectId', data: [{ObjectId: 1, maSV: 123213}]}
    update(p: OdataParams) {
        const url = `${p.url}`;
        const obj: any = {};
        obj['primaryKey'] = p.primaryKey;
        obj['data'] = Array.isArray(p.data) ? p.data : [p.data];

        return this.request(url, obj, 'update').pipe(map((resp: any) => {
            let res = null;
            try {
                res = JSON.parse(resp);
            } catch (error) {
                // meaning resp is string, cannot JSON.parse
            }
            const result: UpdateResponse = {
                success: true,
                message: ''
            };
            if (res) {
                if (res.name === 'Error' || res.result === false) {
                    return {
                        success: false,
                        message: res.message
                    };
                }
            } else {
                result.success = false;
                result.message = resp;
            }

            return result;
        }), catchError((error: any) => {
            const rq: UpdateResponse = {
                success: false,
                message: error ? error.message : 'Update Error'
            };
            return of(rq)
        }));
    }

    delete(p: OdataParams): Observable<any> {
        const url = `${p.url}`;
        const params: any = {};

        // tại sao lại có '' trong dữ liệu values????
        params['idDelete'] = {
            key: p.primaryKey,
            values: p.data && p.primaryKey ? p.data[p.primaryKey] === null ? null : `'${p.data[p.primaryKey]}'` : null
        };
        // console.log(params);
        // return of(true);

        return this.request(url, params, 'delete').pipe(map(resp => {
            let res = null;
            try {
                res = JSON.parse(resp);
            } catch (error) {
                // meaning resp is string, cannot JSON.parse
            }

            const response: DeleteResponse = {
                data: res && res.data ? res.data.deleteResults : [],
                success: res && res.result ? true : false,
                message: res ? 'Xóa thành công' : resp
            };
            return response;
        }), catchError((error: any) => {
            const rq: DeleteResponse = {
                success: false,
                data: [],
                message: error ? error.message : 'Delete Error'
            };
            return of(rq)
        }));
    }

    private decodeSql(whereClause: any[]) {
        let where = ''; let logic = 'and';
        if (whereClause === null || whereClause === undefined) {
            return '';
        }

        if (whereClause.length === 0) {
            return '1=1';
        }
        // const cloneWhere = JSON.parse(JSON.stringify(whereClause)); // sử dụng JSON parse và stringify sẽ bị mất ngày giờ
        const cloneWhere = clone(whereClause);
        if (cloneWhere.length > 0) {
            if (!Array.isArray(cloneWhere[0]) && cloneWhere[0] !== 'and' && cloneWhere[0] !== 'or') {
                where = this.decodeDeep(cloneWhere, '');
            } else {
                cloneWhere.forEach((item: any, index: number) => {
                    if (index === 0 && (item === 'and' || item === 'or')) {
                        logic = item;
                    } else {
                        if (Array.isArray(item) && item.length === 0) {
                            return;
                        }
                        if (Array.isArray(item) && (item[0] === 'and' || item[0] === 'or')) {
                            where += `(${this.decodeSql(item)}) ${logic} `;
                        } else if (Array.isArray(item[0])) {
                            where += `(${this.decodeSql(item)}) and `;
                        } else {
                            where += this.decodeDeep(item, logic);
                        }
                    }
                });
                const number = logic === 'and' ? 5 : 4;
                where = where.substring(0, where.length - number);
            }
        }

        return where;
    }

    private decodeDeep(item: any, logic: string) {
        const key = item[0];
        const operator = item[1];
        let value = item[2];
        let where = '';

        if (typeof (value) === 'string' && value.startsWith('c$')) {
            // where = `${item[0]} ${item[1]} ${eval(value)}`;
            value = eval(value);
        }

        if (value instanceof Date) {
            value = formatDate(value, 'yyyy-MM-dd HH:mm:ss', 'vi-VN');
        }

        switch (operator) {
            case 'like':
                where = `${key} LIKE N'%${value}%'`;
                break;
            case '!=':
            case '<>':
                if (typeof (value) === 'string') {
                    where = `${key} ${operator} N'${value}'`;
                } else {
                    if (value === null) {
                        where = `${key} is not null`;
                    } else {
                        where = `${key} ${operator} ${value}`;
                    }
                }
                break;
            case 'in':
                let type = 'number'; let val = '';
                if (value && value.length > 0) {
                    if (typeof (value[0]) === 'string') {
                        type = 'string';
                    }
                }
                if (type === 'string') {
                    value.forEach((x: string) => {
                        val = `${val}'${x}',`;
                    });
                    val = val.substring(0, val.length - 1);
                } else {
                    val = value.join(',')
                }

                if (value.length === 0 || val === '') { // val = '' thì sẽ là key in () => là 1 = 2
                    where = `1 = 2`;
                } else if (value.includes(null)) {
                    where = `${key} is null`;
                    const value1 = value.filter((fil: any) => fil !== null);
                    if (value1 && value1.length > 0) {
                        where = `${where} or ${key} in (${value1.join(',')})`
                    }
                } else {
                    where = `${key} ${operator} (${val})`;
                }
                break;
            case 'YEAR':
            case 'MONTH':
                where = `${operator}(${key}) = ${value}`;
                break;
            default:
                if (typeof (value) === 'string') {
                    where = `${key} ${operator} N'${value}'`;
                } else {
                    if (value === null) {
                        where = `${key} is null`;
                    } else {
                        where = `${key} ${operator} ${value}`;
                    }
                }
                break;
        }
        if (logic !== '') {
            where += ` ${logic} `;
        }
        return where;
    }

    private testDecode() {
        // this.delete({
        //     url: 'https://cloud.applicationjs.com/auto/applicationjs_cloud2/data/cloud2/VBNGANCHANT',
        //     primaryKey: 'SOVB',
        //     data: { SOVB: null }
        // }).subscribe(res => {
        //     console.log(res);
        // });
        const where = ['table', 'in', ['1', '2']];
        const where1 = ['and', ['table', 'in', [1, 2]], [['data', 'like', '1234']]];
        const where2 = [
            'or',
            [
                'and',
                ['table', '=', 1],
                ['tableName', 'like', 'super']
            ],
            ['data', 'like', '1234']
        ];
        const whereCurrentUser = ['username', '=', 'c$.username'];

        // console.log('TEST AUTODATA DECODE WHERE: ', this.decodeSql(where));
        // console.log('TEST AUTODATA DECODE WHERE 1: ', this.decodeSql(where1));
        // console.log('TEST AUTODATA DECODE WHERE 2: ', this.decodeSql(where2));
        // console.log('TEST AUTODATA DECODE WHERE USERNAME: ', this.decodeSql(whereCurrentUser));
    }
}
