
export * from './core-window.component';

export interface Information {
    DESCR: string; // Tên tab
    PARENT_ID: any; // ID của tab cha
    TAB_ID: any; // Id của tab
    TAB_LEVEL: string; // Cấp độ của tab
    TAB_MAX_LEVEL: number;
    TABLE_ID: any; // Phân biệt case nhiều - nhiều hay 1 - nhiều
    TABLE_DETAIL: string;
    SEQ_NO: string; // thứ tự của mỗi tab tại mỗi level
    WHERE: any; // câu điều kiện truy vấn
    DEFAULT_WHERE: any[];
    IS_LIENKETDOHOA: string;
    IS_SINGLE_LINE_EDIT: any;
    IS_REFRESH_PARENT_TAB: any; // reload lại dữ liệu tab cha khi tab con thêm/sửa/xóa
    IS_TAB_TRUNG_GIAN: any; // đánh đấu tab (bảng) là trung gian kết nối tới 2 tab khác (đều có trong window)
    PARENT_DATA: any; // Dữ liệu hiện hành của tab cha, dùng cho insert, delete
    LAYOUT_COLUMN: number;
    KHOA_CHINH: string;
    KHOA_CHINH_BANG_LK: string;
    TRUONG_LK_CON: string;
    TRUONG_LK_CHA: string;
    TRUONG_LKTG_CON: string;
    TRUONG_LKTG_CHA: string;
    BANG_CHA: string;
    BANG_LK: any;
    KVHC_COLUMN: string;
    COLUMN_KEY: string;
    // For Table
    URL_EDIT: string;
    URL_VIEW: string;
    URL_EDIT_MAP: string;
    URL_VIEW_MAP: string;
    TABLE_NAME: string;
    SERVICE_TYPE: string;
    WINDOW_ID: number;
    COLUMN_DOHOA: string;
    COLUMN_CODE: string;
    COLUMN_DISPLAY: string;
    TABLE_TYPE?: string; // Thông tin của bảng được truy vấn theo phương thức service nào: arcgis, sql hay postgrest

    // ARCGIS ???
    HASATTACHMENT: string; // match with field hasattachment
    FILTER_FIELD: string;
    FILTER_DEFAULT_VALUE: any;
    ORDER_BY: any;
    INIT_SHOW: boolean;
    ONCHANGE: string;
    TABLEWORKFLOWID: number;
    JOBTYPEIDS: string;
    LAYERINDEX: number;

    ColumnWorkflow: string;
    ColumnLinkLayer: string;

    COLUMN_LOCK: string;
    ARCHIVE_TYPE: any;
    NOT_SEND: boolean; // Nếu bằng true thì không gửi tham số này đến request
}

export interface TabStruct {
    FIELD_LIST: Array<any>;
    LAYOUT_CONFIG: any;
    INFORMATION: Information;
    SUB_TAB: TabStruct[];
    URLREQUEST: string;
}
