import { dojoConfig } from './dojo-config';

export const jsapi_config = {
    js: `https://js.arcgis.com/3.40/init.js`,
    style: `https://js.arcgis.com/3.40/esri/css/esri.css`,
    dojo: `https://js.arcgis.com/3.40/dijit/themes/claro/claro.css`,
    themelight: `https://js.arcgis.com/3.40/esri/themes/light/main.css`,
    styleMesurement: `https://js.arcgis.com/3.40/esri/themes/calcite/dijit/calcite.css`,
    styleMesurement_2: `https://js.arcgis.com/3.40/esri/themes/calcite/esri/esri.css`
};

export const loaderOptions = {
    // version: '3.38',
    url: jsapi_config.js,
    css: jsapi_config.style,
    dojoConfig
};
