import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DangkyRoutingModule } from './dangky-routing.module';
import { DangkyComponent } from './dangky.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'src/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    DangkyComponent
  ],
  imports: [
    CommonModule,
    DangkyRoutingModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports:[DangkyComponent]
})
export class DangkyModule { }
