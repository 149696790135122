import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { RequestService } from '../services/request.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-core-color-picker',
  templateUrl: './core-color-picker.component.html',
  styleUrls: ['./core-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR
      , useExisting: forwardRef(() => CoreColorPickerComponent)
      , multi: true
    },
    RequestService
  ]
})
export class CoreColorPickerComponent implements OnInit {
  ctrl: FormControl = new FormControl();
  valueColor: any = null;
  @Output() valueModelChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() notUseTranslate = false;
  _disabled = false;
  @Input('disabledCtrl')
  set disabledCtrl(val: boolean) {
    this._disabled = val;
    // if (this.ctrl) {
    //   val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
    // }
  }
  get disabledCtrl() {
    return this._disabled;
  }
  private _isReadOnly = false;
    @Input('isReadOnly')
    set isReadOnly(val: boolean) {
        this._isReadOnly = val;
        if (this.ctrl) {
            // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }
    }
    get isReadOnly() {
        return this._isReadOnly;
    }
  requiredClass = false;
  _required = false;
  @Input('required')
  set required(val: boolean) {
    this._required = val;
    this.requiredClass = val;

    if (this.hasFormCtrl) {
      this.ctrl.setValidators(val === true ? [Validators.required] : [Validators.nullValidator]);
    } else {
      if (val) {
        if (this.valueModel !== null && this.valueModel !== '') {
          this.requiredClass = false;
        } else {
          this.requiredClass = true
        }
      } else {
        this.requiredClass = false
      }
    }
  }
  get required() {
    return this._required;
  }
  _valueModel: any;
  @Input()
  get valueModel() {
    return this._valueModel === "" ? null : this._valueModel;
  }

  set valueModel(value) {
    if (this.required) {
      if (value !== null && value !== '') {
        this.requiredClass = false;
      } else {
        this.requiredClass = true
      }
    } else {
      this.requiredClass = false
    }
    this._valueModel = value;
    this.valueModelChange.emit(this._valueModel);
  }
  @Input() showLabel = true;
  @Input() labelName = '';
  @Input() labelWidth = '200';
  @Input() isLabelLeft = true;
  @Input() hasFormCtrl: boolean = true;
  @Input() format: any = 'hex';
  @Input() isInline: any = false;
  @Input() placeholder: any = '';
  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };
  constructor() {
    this.initForm();
  }

  ngOnInit(): void {
  }
  private initForm() {
    this.ctrl = new FormControl(null, {
      validators: this._required === true ? Validators.required : Validators.nullValidator
    });

    this.isReadOnly ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });

    this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
      if(val !== null && val !== '') {
        this.requiredClass = false;
      } else {
        this.requiredClass = this.required ? true : false
      }
      this.onChange(val);
      this.valueColor = val;
      this.valueChange.emit(val)
    });
  }
  onChangeValue(evt: any) {
    this.valueChange.emit(evt.target.value)
  }
  onChangeColor(evt: any) {
    if (this.hasFormCtrl) {
      this.ctrl.setValue(evt.value)
    } else {
      this.valueChange.emit(evt.value)
    }

    // this.valueChange.emit(evt.value);
  }
  writeValue(_value: any): void {
    if(_value !== null && _value !== '') {
      this.requiredClass = false;
    } else {
      this.requiredClass = this.required ? true : false
    }
    this.valueColor = _value;
    this.ctrl.setValue(_value, { emitEvent: false })
  }
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

}
