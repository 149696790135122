import 'reflect-metadata';

export function convertType<T>(type: T) {
    return (target: object, propertyName: string) => {
        Reflect.defineMetadata(propertyName, type, target);
    };
}
export function convertObject<T>(outputType: new () => T, input: any): any {
    if (input) {
        const output: any = new outputType();

        const convertProperties = Reflect.getMetadataKeys(output);

        for (const key of Object.keys(input)) {
            if (convertProperties.includes(key)) {
                // if (Array.isArray(input[key])) {
                // output[key] = convertList(Reflect.getMetadata(key, output), input[key]);
                // } else {
                output[key] = convertObject(Reflect.getMetadata(key, output), input[key]);
                // }
            } else {
                output[key] = input[key];
            }
        }
        return output;
    }
}
