import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {

  public contentElement!: HTMLElement;

  private _page!: NavigationConfig['page'];
  get page(): NavigationConfig['page'] { return this._page; }
  set page(page: NavigationConfig['page']) {
    this.goToPage(page);
  }

  constructor() { }

  private setClassName(page: NavigationConfig['page']) {
    this._page = page;
    this.contentElement.className = 'um-sidenav-content page-' + page;
  }

  public goToPage(page: NavigationConfig['page']) {
    this.setClassName(page);
  }

  public toggleSwitchPage(page1: NavigationConfig['page'], page2: NavigationConfig['page']) {
    this.page = this.page !== page1 ? page1 : page2;
  }

}

interface NavigationConfig {
  page: 1 | 2 | 3;
}
