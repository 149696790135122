export enum CoreTechTable {
    SysAccesses = 'SysAccesses',
    SysApplication = 'SysApplications',
    SysCaches = 'SysCaches',
    SysClients = 'SysClients',

    SysColumn = 'SysColumns',
    SysFields = 'SysFields',
    SysDmFunctions = 'SysDmFunctions',
    SysGroups = 'SysGroups',

    SysKvhcs = 'SysKvhcs',
    SysLayers = 'SysLayers',
    SysMenuItems = 'SysMenuItems',
    SysRoles = 'SysRoles',

    SysRoleFunctions = 'SysRoleFunctions',
    SysRoleMenus = 'SysRoleMenus',
    SysServices = 'SysServices',
    SysTabs = 'SysTabs',

    SysTables = 'SysTables',
    SysUsers = 'SysUsers',
    SysUserKvhcs = 'SysUserKvhcs',
    SysWindows = 'SysWindows',

    svaccessapplications = 'svaccessapplications',
    svrolemenumenuitems = 'svrolemenumenuitems',
    svuserkvhcs = 'svuserkvhcs',
    svroleaccesses = 'svroleaccess',
    SysAppServices = 'SysAppServices',
    svReports = 'svReports',
    svgroupaccesses = 'svgroupaccesses',
   svColumnTable = 'svColumnTables',
   SysDomain = 'SysDomains',
   CalCalendars = 'CalCalendars',
   SysThemes = 'SysThemes'
}
