import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';
import { NotificationService } from 'app/notification/generated';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-add-bpmn',
  templateUrl: './add-bpmn.component.html',
  styleUrls: ['./add-bpmn.component.scss']
})
export class AddBPMNComponent implements OnInit {
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('dialogPrime', { static: true }) dialogPrime!: DialogPrimeComponent;
  @ViewChild('dialogUser', { static: true }) dialogUser!: DialogPrimeComponent;
  @ViewChild('formAdd', { static: true }) formAdd!: TemplateRef<any>;
  @ViewChild('tempUser') tempUser!: TemplateRef<any>;

  public formData: FormGroup = new FormGroup({});
  public formDataParams: FormGroup = new FormGroup({});
  dataSource: any;
  fieldList: any[] = [];
  fieldListPrams: any[] = [];
  lookupCondition: any = {};
  arrFieldHide = ['RecordId', 'TableId', 'WindowId', 'PercComplete', 'OwnedBy', 'JobStatusId', 'SendFrom', 'AssignStatusId', 'ApplicationId', 'UpdateTime', 'ClientId'];
  public nzSpan = 11;
  formLoadFinish = true;
  currentUser:any = null;
  recordId = null;
  urlWorkflow = '';
  arrJobType: any = [];
  dataEmit: any = null;
  columnCode: any = null;
  columnDisplay:any = null
  clientId: any;
  dataUserJobtype = [];
  defaultCheckedUser: any = [];
  strCheckedUser: any = '';
  dataUser = [];
  treeConfigUser: any = {
    selectionMode: 'checkbox',
    filter: false,
    virtualScroll: false,
  }
  applicationId: any = null;

  constructor(
    private appService: AppService,
    private workflowService: BpmnWorkflowService,
    private cd: ChangeDetectorRef,
    private notifyService: NotificationService
  ) { }

  ngOnInit() {
  }

  public run(p: any) {
    console.log(p);
    this.clientId = this.appService.ClientId;
    this.applicationId = Number(this.appService.objStoreAppId?.appId);
    if (this.applicationId === null) {
      this.appService.notification('Lỗi tạo công việc ngoài ứng dụng', 'error')
      return
    }
    this.currentUser = JSON.parse(this.appService.currentUser).username;
    this.dataEmit = p;
    this.dataSource = p.dataSource;
    this.recordId = p.primaryKey.features[0].objectId ? p.primaryKey.features[0].objectId : null;
    this.getConfigJobtype(p.dataSource.INFORMATION.TABLE_ID);
  }

  getConfigJobtype(tableId: any) {
    this.columnCode = this.dataSource.INFORMATION.COLUMN_CODE;
    this.columnDisplay = this.dataSource.INFORMATION.COLUMN_DISPLAY;


    let jobName = '';
    if (this.columnCode !== null) {
      if (this.dataEmit.params[this.columnCode]) {
        jobName = this.dataEmit.params[this.columnCode];
      }
    }

    this.lookupCondition['JobTypeId'] = [];
    const param = [['TableId', '=', tableId], ['ApplicationId', '=', this.applicationId]];
    this.workflowService.queryJobtype(param).subscribe(res => {
      if (res.success) {
        this.arrJobType = res.features;
        if (res.features.length === 1) {
          this.onAddJob(res.features[0], jobName);
        } else if (res.features.length > 1) {
          res.features.forEach(item => {
            this.lookupCondition['JobTypeId'].push({
              CODE: item.JobTypeId,
              DESCR: item.JobTypeName
            });
          });
          this.onCheck(jobName);
        }
      }
    });
  }

  onCheck(jobName: any) {
    if (this.dataSource.INFORMATION.ColumnWorkflow !== null && this.dataSource.INFORMATION.ColumnWorkflow !== '') {
      this.dataSource.FIELD_LIST.forEach((item: any) => {
        if (item.fieldname === this.dataSource.INFORMATION.ColumnWorkflow) {
          if (item.isfromdomain === 'Y') {
            const lookUp = this.appService.getLookup();
            const arrLookUp = lookUp[item.domainid];
            arrLookUp.forEach((res: any) => {
              if (res.CODE.toString() === this.dataEmit.params[this.dataSource.INFORMATION.ColumnWorkflow].toString()) {
                const idJobType = res.CODE.toString();
                const val: any = this.arrJobType.filter((fil: any) => fil.JobTypeId.toString() === idJobType);
                if (val[0]) {
                  this.onAddJob(val[0], jobName);
                } else {
                  this.appService.notification('Không có cấu hình id cho cột workflow', 'warning');
                }
              }
            });
          }
        }
      });
    } else {
      this.appService.notification('Chưa cấu hình cột workflow', 'warning');
    }
  }

  onChangeJobTypeAndDate(JobTypeId: any) {
    const val: any = this.arrJobType.filter((fil: any) => fil.JobTypeId === JobTypeId);
    if (val[0]) {
      const timeNow = new Date();
      const nowDate = timeNow.getTime();
      let dueDate = null;
      if (val[0].TimeUnit === 1) {
        dueDate = timeNow.setDate(timeNow.getDate() + val[0].NumDayComplete);
      } else if (val[0].TimeUnit === 2) {
        const minute = val[0].NumDayComplete * 60 * 60 * 1000;
        dueDate = nowDate + minute;
      } else if (val[0].TimeUnit === 3) {
        const minute = val[0].NumDayComplete * 60 * 1000;
        dueDate = nowDate + minute;
      }
      this.formData.controls['StartDate'].setValue(nowDate);
      this.formData.controls['DueDate'].setValue(dueDate);
      this.getUserJobType(JobTypeId, val[0].AssignedTo);
    }
  }

  getUserJobType(JobTypeId: any, AssigedTo: any) {
    this.formData.controls['AssignedTo'].setValue(null);
    const params = ['JobTypeId', '=', JobTypeId];
    this.lookupCondition['AssignedTo'] = [];
    this.workflowService.queryJobtypeUser(params).subscribe(res => {
      if (res.success) {
        res.features.forEach(ele => {
          this.lookupCondition['AssignedTo'].push({
            CODE: ele.Username,
            DESCR: ele.Username
          });
        });
        if (AssigedTo !== null) {
          this.formData.controls['AssignedTo'].setValue(AssigedTo);
        } else {
          this.lookupCondition['AssignedTo'].length > 0 ? this.formData.controls['AssignedTo'].setValue(this.lookupCondition['AssignedTo'][0].CODE) : null;
        }
        // AssigedTo == null ?  this.formData.controls['AssignedTo'].setValue(this.lookupCondition['AssignedTo'][0].CODE) : this.formData.controls['AssignedTo'].setValue(AssigedTo) ;
      }
    });
  }

  public async onShowFormCreate() {
    this.dialogPrime.isComponent = false;
    this.dialogPrime.title = this.dataEmit.dataSource.INFORMATION.DESCR;
    this.dialogPrime.templateRef = this.formAdd;
    this.dialogPrime.onShow();
    this.formData.reset();
  }

  dataLookUp() {
    this.arrJobType = [];
    const paramsJobType = [['ClientId', '=', this.clientId], ['ApplicationId', '=', this.applicationId]];
    const arrStatus = this.workflowService.querySatus();
    const arrJobtype = this.workflowService.queryJobtype(paramsJobType);
    const arrPriority = this.workflowService.queryPriority([]);
    const list = [arrStatus, arrJobtype, arrPriority];
    combineLatest(list).subscribe((res: any) => {
      this.lookupCondition['StatusId'] = [];
      this.lookupCondition['PriorityId'] = [];
      // this.lookupCondition['AssignedTo'] = [];
      if (res[0].success) {
        res[0].features.forEach((item: any) => {
          this.lookupCondition['StatusId'].push({
            CODE: item.StatusId,
            DESCR: item.StatusName
          });
        });
      }
      if (res[1].success) {
        this.arrJobType = res[1].features;
      }

      if (res[2].success) {
        res[2].features.forEach((item: any) => {
          this.lookupCondition['PriorityId'].push({
            CODE: item.PriorityId,
            DESCR: item.Description
          });
        });
      }
      // this.getConfigJobtype();
      this.arrJobType = [... this.arrJobType];
      this.lookupCondition['PriorityId'] = [...this.lookupCondition['PriorityId']];
      this.formData.controls['JobTypeId'].setValue(this.lookupCondition['JobTypeId'][0].CODE);
      this.formData.controls['PriorityId'].setValue(2);
      this.cd.detectChanges();
    });
  }

  onAddJob(jobType: any, jobName: any) {
    const timeNow = new Date();
    const nowDate = timeNow.getTime();
    const NumDayComplete = jobType.NumDayComplete;
    const dueDate = timeNow.setDate(timeNow.getDate() + NumDayComplete);
    let asignedTo: any = '';
    let assignStatusId: any = null;
    jobType.AssignedTo === null ? this.currentUser : jobType.AssignedTo;
    if (jobType.AssignedTo !== null) {
      asignedTo = jobType.AssignedTo;
      if (asignedTo.toUpperCase() === this.currentUser.toUpperCase()) {
        assignStatusId = 1;
      }
    } else {
      asignedTo = this.currentUser;
      assignStatusId = 1;
    }

    let description = ''
    if (this.columnDisplay && this.columnDisplay !== null) {
      if (this.dataEmit.params[this.columnDisplay]) {
        description = this.dataEmit.params[this.columnDisplay];
      }
    }


    const paramsJob = {
      JobName: jobName,
      Description: description,
      AssignedTo: asignedTo,
      StartDate: this.formatDate(nowDate),
      DueDate: this.formatDate(dueDate),
      Note: '',
      OwnedBy: this.currentUser,
      PercComplete: 0,
      JobTypeId: jobType.JobTypeId,
      SendFrom: this.currentUser,
      JobStatusId: 1,
      AssignStatusId: assignStatusId,
      PriorityId: 2,
      UpdateTime: this.formatDate(nowDate),
      WindowId: jobType.WindowId,
      TableId: this.dataSource.INFORMATION.TABLE_ID,
      RecordId: this.recordId,
      ClientId: this.clientId,
      ApplicationId: this.applicationId,
      CreateDate: this.formatDate(nowDate)
    };

    this.workflowService.addJob(paramsJob).subscribe((res: any) => {
      if (res.success) {
        this.dialogPrime.onHideDialog();
        this.addJobStep(res.features[0].JobId, jobType, false);
        this.addHistory(res.features[0].JobId, paramsJob.JobTypeId, paramsJob.AssignedTo);
        this.onAddNotification(res.features[0]);
      } else {
        this.appService.notification('Lỗi thêm mới công việc', 'error');
        this.dialogPrime.onHideDialog();
      }
    }, err => {
      this.appService.notification('Lỗi thêm mới công việc', 'error');
      this.dialogPrime.onHideDialog();
    });
  }

  // nut nay gio k dung nua (show dialog)
  // public btnOkClick() {
  //     if (this.formData.valid) {
  //       const params:any = {};
  //       Object.keys(this.formData.value).forEach(key => {
  //         params[key] = this.formData.controls[key].value;
  //       });

  //       const jobTypeId = params['JobTypeId'] !== null ? typeof (params['JobTypeId']) === 'object' ? params['JobTypeId'].CODE : params['JobTypeId'] : params['JobTypeId'];
  //       const val:any = this.arrJobType.filter((fil:any) => fil.JobTypeId === jobTypeId);
  //       if (val[0]) {
  //         const paramsJob = {
  //           JobName: params['JobName'],
  //           Description: params['Description'],
  //           AssignedTo: params['AssignedTo'] !== null ? typeof (params['AssignedTo']) === 'object' ? params['AssignedTo'].CODE : params['AssignedTo'] : params['AssignedTo'],
  //           StartDate: this.formatDate(params['StartDate']),
  //           DueDate: this.formatDate(params['DueDate']),
  //           Note: params['Note'],
  //           OwnedBy: this.currentUser,
  //           PercComplete: 0,
  //           JobTypeId: jobTypeId,
  //           SendFrom: this.currentUser,
  //           JobStatusId: 1,
  //           AssignStatusId: null,
  //           UpdateTime: this.formatDate(new Date()),
  //           PriorityId:  params['PriorityId'] !== null ? typeof (params['PriorityId']) === 'object' ? params['PriorityId'].CODE : params['PriorityId'] : params['PriorityId'],
  //           WindowId: val[0].WindowId,
  //           TableId: val[0].TableId,
  //           RecordId: this.recordId,
  //           ClientId: this.clientId,
  //           ApplicationId: this.applicationId
  //         };
  //         this.workflowService.addJob(paramsJob).subscribe((res:any) => {
  //           if (res.success) {
  //             this.dialogPrime.onHideDialog();
  //             this.addJobStep(res.features[0].JobId, paramsJob.JobTypeId, true);
  //             this.addHistory(res.features[0].JobId, paramsJob.JobTypeId, paramsJob.AssignedTo);
  //             this.onAddNotification(res.model);
  //           } else {
  //             this.appService.notification('Lỗi thêm mới', 'error');
  //           }
  //         }, err => {
  //           this.appService.notification('Lỗi thêm mới', 'error');
  //         });
  //       }

  //     } else {
  //         this.appService.createMessage('warning', this.appService.getMessage('0006'));
  //       }
  // }

  selectUser() {
    this.strCheckedUser = '';
    const jobTypeId = this.formData.controls['JobTypeId'].value;
    const params = ['JobTypeId', '=', jobTypeId];
    this.workflowService.queryJobtypeUser(params).subscribe(res => {
      if (res.success) {
        this.dataUser = this.buildTreeUser(res.features);
        const userSlected = this.formData.controls['AssignedTo'].value;
        if (userSlected !== '' && userSlected !== null) {
          const listUser = userSlected.split(",");
          listUser.forEach((user: any) => {
            this.dataUser.forEach((ele: any) => {
              if (ele.id === user) {
                this.defaultCheckedUser.push(ele);
              }
            });
          });
        }

        this.dialogUser.title = 'Select User';
        this.dialogUser.isComponent = false;
        this.dialogUser.templateRef = this.tempUser;
        this.dialogUser.onShow();
      }
    });
  }

  buildTreeUser(arrUser: any) {
    let arrUserTree: any = [];
    arrUser.forEach((ele: any) => {
      const params = {
        key: ele.Username,
        label: ele.Username,
        id: ele.Username,
        data: ele,
        expanded: false,
        children: []
      };
      arrUserTree.push(params);
    });
    arrUserTree = [...arrUserTree];
    return arrUserTree;
  }

  onCancelUser() { }
  onUnCheckUser(evt: any) {
    let userRemove = evt.currentNode.id;
    let arrCheckedUserStep = this.strCheckedUser.split(",");
    arrCheckedUserStep = arrCheckedUserStep.filter((fill: any) => fill !== userRemove);
    let strUser = '';
    arrCheckedUserStep.forEach((res: any) => {
      strUser = strUser + res + ',';
    });
    this.strCheckedUser = strUser.substring(0, strUser.length - 1);
  }
  onCheckUser(evt: any) {
    let userNewAdd = evt.currentNode.id;
    const valUSerStep = this.formData.controls['AssignedTo'].value;
    if (valUSerStep !== null) {
      this.strCheckedUser = valUSerStep + ',' + userNewAdd
    } else {
      this.strCheckedUser !== '' ? this.strCheckedUser = this.strCheckedUser + ',' + userNewAdd : this.strCheckedUser = userNewAdd;
    }
  }
  onAddUser() {
    this.strCheckedUser.length > 0 ? this.formData.controls['AssignedTo'].setValue(this.strCheckedUser) : this.formData.controls['AssignedTo'].setValue(null);
    this.dialogUser.onHideDialog();
  }

  onAddNotification(job: any) {
    const paramsWindow = [['ApplicationID', '=', null], ['ClientId', '=', this.clientId]];
    let description = 'Bạn được giao công việc từ ' + this.currentUser;
    job.JobName !== null ? description = description + ' - ' + job.JobName : '';
    this.workflowService.querySysWindow(paramsWindow).subscribe(window => {
      if (window.success && window.features[0]) {
        const windowIdJob = window.features[0].WindowId;
        const paramSysTab = ['WindowId', '=', windowIdJob];
        this.workflowService.querySysTab(paramSysTab).subscribe(tab => {
          if (tab.success && tab.features[0]) {
            const tableIdJob = tab.features[0].TableId;
            const paramsNotify = {
              FromUserId: null,
              FromUsername: this.currentUser,
              ToUserId: null,
              ToUsername: job.AssignedTo,
              SendDate: this.formatDateNotifi(new Date()),
              Description: description,
              WindowId: windowIdJob,
              TableId: tableIdJob,
              RecordId: job.JobId,
              PriorityId: job.PriorityId,
              ClientId: this.clientId
            };

            this.workflowService.addNotification(paramsNotify).subscribe((res:any) => {
              if (!res.success) {
                this.appService.notification('Lỗi thêm mới Notification', 'error');
              } else {
                // Gọi broadcast để gửi thông báo tới service worker
                const valNotifi:any = res.features[0]
                const paramsBroadCast:any = {
                  notificationId: valNotifi.NotificationId,
                  fromUserId: 0,
                  fromUsername: valNotifi.FromUsername,
                  toUserId: 0,
                  toUsername: valNotifi.ToUsername,
                  sendDate: this.formatDateNotifi(new Date()),
                  description: valNotifi.Description,
                  windowId: valNotifi.WindowId,
                  tableId: valNotifi.TableId,
                  recordId: valNotifi.RecordId,
                  priorityId: valNotifi.PriorityId,
                  isRead: "",
                  clientId: valNotifi.ClientId
                }
                this.notifyService.broadcast(paramsBroadCast).subscribe(res => {
                  console.log(res);
                  // phải subscibe thì request mới chạy
                }, err => {
                  console.log(err);
                  this.appService.notification('Lỗi thêm mới Notification', 'error');


                });
              }
            }, err => {
              this.appService.notification('Lỗi thêm mới Notification', 'error');
            });
          }
        });
      }
    });


  }

  addJobStep(jobId: any, jobType: any, selectJobtype: any) {
    if (selectJobtype) {
      this.arrJobType.forEach((element: any) => {
        if (element.JobTypeId === jobType) {
          console.log(element);
          const params = {
            CurStep: element.JobStepStart,
            PrevStep: null,
            JobId: jobId
          };
          this.workflowService.addJobStep(params).subscribe(res => {
            if (res.success) {
              this.appService.notification('Job Inserted Successfully', 'success');
              this.formData.reset();
            }
          }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
          });
        }
      });
    } else {
      const paramJobTypeStep = [['JobTypeId', '=', jobType.JobTypeId], ['StepId', '=', jobType.JobStepStart]];
      this.workflowService.queryJobtypeStep(paramJobTypeStep).subscribe(val => {
        if (val.success) {
          if (val.total > 0) {
            const params = {
              CurStep: jobType.JobStepStart,
              PrevStep: null,
              JobId: jobId,
              ClientId: this.clientId,
              CurStepName: val.features[0].StepName
            };
            this.workflowService.addJobStep(params).subscribe(res => {
              if (res.success) {
                this.appService.notification('Job Inserted Successfully', 'success');
              }
            }, err => {
              this.appService.notification('Đã xảy ra lỗi', 'error');
            });
          }
        }
      });

    }
  }

  public btnResetClick() {
    this.formData.reset();
  }

  public btnCancelClick() {
    this.dialogPrime.onHideDialog();
  }

  addHistory(jobId: any, jobType: any, assignedTo: any) {
    const paramsJobType = ['JobTypeId', '=', jobType];
    this.workflowService.queryJobtype(paramsJobType).subscribe(res => {
      if (res.success) {
        const stepStart = res.features[0].JobStepStart;
        const params = {
          UpdateTime: this.formatDate(new Date()),
          Description: '',
          AssignedTo: assignedTo,
          StepName: '',
          SendFrom: this.currentUser,
          StepId: stepStart,
          ActivityId: 2,
          JobId: jobId,
          Duration: null,
          JobTypeId: jobType,
          ClientId: this.clientId
        };
        this.workflowService.addHistory(params).subscribe(res => {
          console.log(res);
        });
      }
    });
  }

  initFormWf() {
    const params = [['ClientId', '=', this.clientId], ['TableName', '=', 'WfJob']];
    this.workflowService.querySysTable(params).subscribe(res => {
      if (res.success) {
        if (res.features[0]) {
          const tableId = res.features[0].TableId;
          this.initInfoWf(tableId);
        }
      } else {
        this.appService.notification('Lỗi truy vấn', 'error');
        this.dialogPrime.onHideDialog();
      }
      console.log(res);
    }, err => {
      this.appService.notification('Lỗi truy vấn', 'error');
      this.dialogPrime.onHideDialog();
    });
  }


  initInfoWf(tableId: any) {
    if (tableId !== null) {
      this.fieldList = [];
      const param = ['TableID', '=', tableId];
      this.workflowService.queryColBPMN(param).subscribe(res => {
        if (res.success) {
          res.features.forEach(item => {
            this.fieldList.push({
              FIELD_NAME: item.ColumnName,
              FIELD_LABEL: item.Alias,
              FIELD_TYPE: this.getTypeColumnTblJob(item.ColumnName),
              FIELD_ORDER: item.OrderNo,
              FIELD_TABLELINK: item.DomainId,
              FIELD_KEY: item.IsPriKey
            });
          });

          this.fieldList.forEach(item => {
            const f = this.arrFieldHide.filter(fill => fill === item.FIELD_NAME);
            if (f[0] || item.FIELD_KEY === 'Y') {
              item.FIELD_SHOW = false;
            } else {
              item.FIELD_SHOW = true;
            }
          });

          this.fieldList = this.fieldList.sort((a, b) => a.FIELD_ORDER - b.FIELD_ORDER);
          const group: any = {};
          this.fieldList.forEach(item => {
            group[item.FIELD_NAME] = new FormControl();
          });

          this.formData = new FormGroup(group);
          this.dataLookUp();
          this.onShowFormCreate();
          let jobName = '';
          if (this.columnCode !== null) {
            jobName = this.dataEmit.params[this.columnCode];
          }
          this.formData.controls['JobName'].setValue(jobName);
          this.formData.controls['JobTypeId'].valueChanges.subscribe(item => {
            if (item !== null) {
              typeof (item) === 'object' ? this.onChangeJobTypeAndDate(item.CODE) : this.onChangeJobTypeAndDate(item);
            }
          });
        }
      }, err => {
        console.log(err);
      });

    } else {

    }
  }

  initFormPrams(fields: any, params: any) {
    this.fieldListPrams = [];
    fields.forEach((item: any) => {
      this.fieldListPrams.push({
        FIELD_NAME: item.fieldname,
        FIELD_LABEL: item.alias,
        FIELD_TYPE: item.fieldtype,
        FIELD_ORDER: item.orderno,
        FIELD_TABLELINK: item.domainid,
        FIELD_KEY: item.IsPriKey,
        FIELD_SHOW: item.isdisplay,
        FIELD_DISABLED: true
      });
    });

    this.fieldListPrams = this.fieldListPrams.sort((a, b) => a.FIELD_ORDER - b.FIELD_ORDER);
    const group: any = {};
    this.fieldListPrams.forEach(item => {
      group[item.FIELD_NAME] = new FormControl();
    });
    this.formDataParams = new FormGroup(group);
    Object.keys(this.formDataParams.controls).forEach(key => {
      this.formDataParams.controls[key].setValue(params[key]);
    });

  }

  getTypeColumnTblJob(nameField: string) {
    const objTblJob: any = {
      JobId: 'number',
      JobName: 'text',
      Description: 'text',
      AssignedTo: 'text-button',
      AssignedType: 'select',
      ParentJobId: 'number',
      StartDate: 'date',
      Note: 'text',
      DueDate: 'date',
      PercComplete: 'number',
      OwnedBy: 'text',
      StatusId: 'select',
      JobTypeId: 'select',
      PriorityId: 'select',
      WindowId: 'number',
      TableId: 'number',
      RecordId: 'number'
    };
    return objTblJob[nameField];
  }

  public formatDate(date: any) {
    let dateString: any = '';
    if (date !== '' && date !== null) {
      const newDate = new Date(date);
      dateString += newDate.getFullYear();
      dateString += '-';
      dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
      dateString += '-';
      dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
      dateString += ' ';
      dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
      dateString += ':';
      dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
      dateString += ':';
      dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();
    } else {
      dateString = null;
    }
    return dateString;
  }

  public formatDateNotifi(date: any) {
    let dateString: any = '';
    if (date !== '' && date !== null) {
      const newDate = new Date(date);
      dateString += newDate.getFullYear();
      dateString += '-';
      dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
      dateString += '-';
      dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
      dateString += 'T';
      dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
      dateString += ':';
      dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
      dateString += ':';
      dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();
    } else {
      dateString = null;
    }
    return dateString;
  }


}
