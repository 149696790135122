import { Injectable } from '@angular/core';
import { BaseInterface, DeleteResponse, IdParams, InsertResponse, OdataParams, QdataParams, SearchResponse, UpdateResponse } from './request.service';
import { Observable, of } from 'rxjs';
import { clone } from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppService } from 'app/app-base/app.service';

@Injectable()
export class OracleService implements BaseInterface {

    constructor(
        private http: HttpClient,
        private appService: AppService
    ) {
        this.test();
    }

    private request(
        url: string,
        params: any = {},
        type: string = ''
    ): Observable<any> {
        let method: any = 'post'; // mặc định phương thức gọi là post
        let newUrl = url;
        if (url[url.length - 1] !== '/') {
            newUrl = url + '/';
        }

        switch (type) {
            case 'search':
                method = 'get';
                break;
            case 'insert':
                method = 'post';
                break;
            case 'update':
                method = 'put';
                newUrl = url;
                break;
            case 'delete':
                method = 'delete';
                newUrl = url;
                break;
            default:
                break;
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            // 'request-service': 'oracle'
        });

        let context = new HttpContext().set(this.appService.REQUEST_TYPE, 'oracle');

        const token = this.appService.getTokenOracle(newUrl);
        if (token) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }

        // luôn luôn gắn proxy với service oracle
        newUrl = `${this.appService.urlProxy}?${newUrl}`;

        // Tổng quát khi params truyền vào là 1 object
        const options: any = {
            params,
            responseType: 'json',
            headers,
            body: {},
            context
        };

        // Kiểm tra khi params truyền vào là 1 options cho request (có cả params, header)
        if (params.params) {
            options.params = params.params;
        }
        if (params.headers) {
            if (token) {
                params.headers = params.headers.append('Authorization', 'Bearer ' + token);
            }
            options.headers = params.headers;
        }

       

        if (method !== 'get') {
            options['body'] = params;
            delete options.params;
        }

        return this.http.request(method, newUrl, options);
    }

    search(p: OdataParams): Observable<SearchResponse> {

        const new_where = this.decodeSql(p.where);
        const params: any = {};
        if (new_where) {
            params['q'] = JSON.stringify(new_where);
            // params['q'] = `{"new_column_1":{"$like":"www"}}`;
        }

        return this.request(p.url, params, 'search').pipe(map((res: any) => {
            console.log('ORACLE RESPONSE >>>>>>>>> ', res);
            const result: SearchResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Search Success'
            };

            if (res && res.items) {
                res.items.forEach((x: any) => {
                    const obj = Object.assign({}, x);
                    delete obj.links;
                    result.features.push(obj);
                });
                result.total = res.count;
                // result.total = res[1] ? res[1].datas[0].Count : 0;
            } else {
                result.success = res.result;
                result.message = res.message;
            }
            return result;
        }), catchError((error: any) => {
            console.log('ORACLE ERROR >>>>>>>>> ', error);
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    searchForMobile(p: OdataParams, token: any): Observable<SearchResponse> {
        const response: SearchResponse = {
            features: [],
            total: 0,
            success: true,
            message: 'search success'
        };
        return of(response);
    }

    insert(p: OdataParams): Observable<InsertResponse> {
        const url = `${p.url}`;
        // params gửi đi của Oracle là 1 object

        return this.request(url, p.data, 'insert').pipe(map((resp) => {
            const result: InsertResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Insert Success'
            };

            if (resp) {
                delete resp['links'];
                result.features.push(resp);
            } else {
                result.success = false;
                result.message = resp;
            }
            return result;
        }), catchError((error: any) => {
            const rq: InsertResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Insert Error'
            };
            return of(rq)
        }));
    }

    update(p: OdataParams): Observable<UpdateResponse> {
        const valuePK = p.primaryKey ? p.data[p.primaryKey] : null;
        const url = `${p.url}/${valuePK}/PUT`; 

        return this.request(url, p.data, 'insert').pipe(map((resp: any) => {
            const result: UpdateResponse = {
                success: true,
                message: ''
            };
            if (!resp) {
                result.success = false;
                result.message = resp;
            }

            return result;
        }), catchError((error: any) => {
            const rq: UpdateResponse = {
                success: false,
                message: error ? error.message : 'Update Error'
            };
            return of(rq)
        }));
    }

    delete(p: OdataParams): Observable<DeleteResponse> {
        const valuePK = p.primaryKey ? p.data[p.primaryKey] : null;
        const url = `${p.url}/${valuePK}/DELETE`;

        return this.request(url, {}, 'insert').pipe(map(resp => {
            console.log(resp);
            let message = 'Xóa thành công';
            if (resp) {
                message = `Xóa ${resp.rowsDeleted} thành công`;
            }

            const response: DeleteResponse = {
                data: [],
                success: resp ? true : false,
                message
            };
            return response;
        }), catchError((error: any) => {
            console.log('error delete >>>>>>>>>> ', error)
            const rq: DeleteResponse = {
                success: false,
                data: [],
                message: error ? error.message : 'Delete Error'
            };
            return of(rq)
        }));
    }

    query(q: QdataParams): Observable<any> {
        const url = q.url;
        const urlRequest = q.proxy ? `${q.proxy}${url}` : url;

        const contentType = q.contentType ?? 'application/json';

        let headers = new HttpHeaders({
            Accept: 'text/plain'
        });

        if (contentType !== 'unset') {
            headers = headers.append('Content-Type', contentType);
        }

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });

        const options: any = {
            headers,
            params: paramsToSend,
            responseType: q.responseType ?? 'json'
        };

        if (method === 'GET') {
            return this.request(urlRequest, options, 'search');
        } else {
            return this.request(urlRequest, options, 'insert');
        }
    }

    queryCustom(q: QdataParams): Observable<any> {
        const url = q.url;
        const urlRequest = q.proxy ? `${q.proxy}${url}` : url;

        const contentType = q.contentType ?? 'application/json';

        let headers = new HttpHeaders({
            Accept: 'text/plain'
        });

        if (contentType !== 'unset') {
            headers = headers.append('Content-Type', contentType);
        }

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });
        const options: any = {
            headers,
            params: paramsToSend,
            responseType: q.responseType ?? 'json'
        };

        if (method === 'GET') {
            return this.request(urlRequest, options, 'search');
        } else {
            return this.request(urlRequest, options, 'insert');
        }
    }

    getRecordById(q: IdParams): Observable<any> {
        const url = q.url + '/' + q.id;
        return this.request(url, {}, 'search').pipe(map((resp: any) => {
            delete resp.links;
            return resp;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    private decodeSql(whereClause: any[]) {
        let where: any = {};
        let logic = 'and';
        if (whereClause === null || whereClause === undefined) {
            return null;
        }

        if (whereClause.length === 0) {
            return '';
        }
        // const cloneWhere = JSON.parse(JSON.stringify(whereClause)); // sử dụng JSON parse và stringify sẽ bị mất ngày giờ
        const cloneWhere = clone(whereClause);
        if (cloneWhere.length > 0) {
            if (!Array.isArray(cloneWhere[0]) && cloneWhere[0] !== 'and' && cloneWhere[0] !== 'or') {
                where = this.decodeDeep(cloneWhere);
            } else {
                cloneWhere.forEach((item: any, index: number) => {
                    if (index === 0 && (item === 'and' || item === 'or')) {
                        where[item] = [];
                        logic = item;
                    } else {
                        if (Array.isArray(item) && item.length === 0) {
                            return;
                        }


                        if (Array.isArray(item) && (item[0] === 'and' || item[0] === 'or')) {
                            where[logic].push(this.decodeSql(item));
                        } else {
                            where[logic] = this.decodeSql(item);
                        }
                    }
                });
            }
        }

        return where;
    }

    // Trả về 1 object chứa key và value
    private decodeDeep(item: any) {
        const key = item[0];
        const operator = item[1];
        let value = item[2];
        let where: any = {};

        if (typeof (value) === 'string' && value.startsWith('c$')) {
            value = eval(value);
        }

        // if (value instanceof Date) {
        //     value = formatDate(value, 'yyyy-MM-dd HH:mm:ss', 'vi-VN');
        // }

        let val: any = {};
        where = {};

        const resp = listOperatorOracle.filter(fil => fil.key === operator);

        if (resp && resp.length > 0) {
            switch (operator) {
                case 'like':
                    val[resp[0].value] = `%${value}%`;
                    break;
                default:
                    val[resp[0].value] = value;
                    break;
            }
            where[key] = val;
        }
        return where;
    }

    private test() {
        const where1 = ['or', ['table', 'in', [1, 2]], ['data', 'like', '1234']];
        console.log(this.decodeSql(where1));
    }
}

export const listOperatorOracle = [
    { key: '=', value: '$eq' },
    { key: '<>', value: '$ne' },
    { key: '>', value: '$gt' },
    { key: '>=', value: '$gte' },
    { key: '<', value: '$lt' },
    { key: '<=', value: '$lte' },
    { key: 'like', value: '$like' },
    { key: 'in', value: '$in' },
    // { key: '!=', value: 'not eq' },
    // { key: '!<>', value: 'not ne' },
    // { key: '!>', value: 'not gt' },
    // { key: '!>=', value: 'not ge' },
    // { key: '!<', value: 'not lt' },
    // { key: '!<=', value: 'not le' },
    // { key: '!like', value: 'not like' },
    // { key: '!in', value: 'not in' }
];
