<div>
  <app-dialog-prime #dialogFormCoreTechData></app-dialog-prime>
  <ng-template #tmpFormCoreTechData>
    <app-dialog-data-services *ngIf="loadFormCoreTechDataSuccess" [currentApp]="keyCurrentApp" [dataFormCoreTechData]="dataFormCoreTechData"
      (closeDialogFormCoreTechData)="closeDialogFormCoreTechData()" (dataJSON)="bindDataWebDatarocks($event)">
    </app-dialog-data-services>
  </ng-template>

  <app-dialog-prime #dialogFormSave></app-dialog-prime>
  <ng-template #tmpFormSave>
    <app-dialog-form-save *ngIf="loadFormSuccess" (saveSuccess)="EventsaveSuccess()" [currentApp]="keyCurrentApp"
      [dataFormSave]="dataFormSave" (closeDialogFormSave)="FormChangeClose()">
    </app-dialog-form-save>
  </ng-template>

  <app-dialog-prime #dialogBuildTree></app-dialog-prime>
  <ng-template #tmpBuildTree>
    <app-dialog-build-tree [arrField]="dataSourceCoreTech.column" (onBuildTree)="onBuildTree($event)"
      (closeDialogBuildTree)="closeDialogBuildTree()"></app-dialog-build-tree>
  </ng-template>

  <app-dialog-prime #dialogFilterData></app-dialog-prime>
  <ng-template #tmpFilterData>
    <div style="display: flex;">
      <div style="width: 50%;">
        <h2>Fields</h2>
        <div cdkDropList #fieldList="cdkDropList" [cdkDropListData]="listColumnForFilter"
          [cdkDropListConnectedTo]="[filterList]" (cdkDropListDropped)="dropFilter($event, 'field')">
          <div class="item-list" *ngFor="let item of listColumnForFilter" cdkDrag>
            <app-core-checkbox [chkLabel]="item.DESCR | translate " [valueModel]="item.checked"
              (chkChange)="checkedChange($event, item)">
            </app-core-checkbox>
          </div>
        </div>
      </div>

      <div style="width: 50%;">
        <h2>Report filter</h2>
        <div class="drag-container" cdkDropList #filterList="cdkDropList" [cdkDropListData]="listFilter"
          [cdkDropListConnectedTo]="[fieldList]" (cdkDropListDropped)="dropFilter($event, 'filter')">
          <div style="height: 30px; display: flex; align-items: center;" *ngFor="let item of listFilter" cdkDrag>
            {{item.DESCR}}</div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center;" class="form-add-footer">
      <app-button class="ml-3" [labelName]="'Đồng ý' | translate" typeButton="save" (clickEvent)="buildFilter()">
      </app-button>
      <app-button class="ml-3" [labelName]="'Thoát' | translate" typeButton="close" (clickEvent)="closeDialogFilter()">
      </app-button>
    </div>
  </ng-template>

  <div *ngIf="listFilterSelected.length > 0">
    <form [formGroup]="formGroup" style="display: flex; margin: 5px; flex-wrap: wrap;">
      <ng-container *ngFor="let filter of listFilterSelected; let i=index">
        <div style="margin-left: 5px; margin-bottom: 5px;">
          <app-select [labelName]="filter.DESCR | translate" [formControlName]="filter.DESCR"
            [dataLookup]="filter.arrLookup" displayField="DESCR" valueField="CODE" [required]="false">
          </app-select>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="theme-panel" *ngIf="_dataRock && _isDesign">
    <ng-container *ngFor="let theme of arrTheme; let i=index">
      <div class="btn-theme {{theme.className}}" [ngClass]="{'btn-active': theme.isActive}"
        (click)="changeTheme(theme)">
        <span class="theme-name-short">{{theme.shortName}}</span>
        <span class="theme-name">{{theme.fullName}}</span>
      </div>
    </ng-container>
  </div>

  <div style="width: 100%; overflow: hidden; display: flex;">
    <!-- ; 'grid-chart': isShowChart; -->
    <!-- [ngClass]="{'full-grid': !isShowChart}" -->
    <!-- <div [ngClass]="{'full-grid': typeDisplay === 'grid', 'grid-chart': typeDisplay !== 'grid' }"> -->
    <div id="gridContainer" class='full-grid'>
      <app-wbr-pivot #pivot1 [toolbar]="true" [width]="'100%'" [height]="'calc(100vh - 60px)'"
        [customizeCell]="onCustomizeCell" (ready)="onPivotReady($event)" (reportcomplete)="onReportComplete()"
        (beforetoolbarcreated)="customizeToolbar($event)">
      </app-wbr-pivot>
    </div>
    <!-- <app-wbr-pivot #pivot1 [toolbar]="true" [width]="'100%'" [height]="500"></app-wbr-pivot> -->
    <!-- ; 'hide-chart': !isShowChart} -->
    <!-- [ngClass]="{'show-chart': isShowChart" -->
    <!-- <div [ngClass]="{'show-chart': typeDisplay !== 'grid', 'hide-chart': typeDisplay === 'grid' }"> -->
    <div id="chartContainer" class="hide-chart">
      <div class="chart-container" id="highchartsContainer"></div>
    </div>
  </div>
</div>
<!-- <div>wwebdatatocks</div> -->