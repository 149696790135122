import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppService } from './app.service';
import { CoreModule } from '../core/core.module';
import { LoginComponent } from './login/login.component';
import { LoginRoutingModule } from './login/login-routing.module';
import { AuthGuard } from './auth.guard';
import { TranslateModule } from '@ngx-translate/core';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignupService } from './sign-up/signup.service';
import { DangkyModule } from './dangky/dangky.module';
// import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    CoreModule,
    TranslateModule,
     DangkyModule
  ],
  declarations: [
    LoginComponent,
    SignUpComponent
    
  ],
  providers: [
    AppService,
    AuthGuard,
    SignupService
  ]
})
export class AppBaseModule { }
