import { map } from 'rxjs/operators';
import { interfaceServices } from './../../um/model/config-services.interface';
import { CoreTechTable } from './../../um/config/um-tables';
import { AppService } from 'app/app-base/app.service';
import { ConfigDomainData, ConfigTableData } from './../../um/model/config-table-data.interface';
import { Injectable } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { RequestService } from './request.service';


@Injectable({
    providedIn: 'root',
})
export class SavedomainService {
    public url = this.appService.urlWS;
    constructor(
        private appService: AppService,
        private reqService: RequestService
    ) {
        this.reqService.switchType(this.appService.dataAccessType);
    }
    public saveDomain(params: any, DomainType = 'SQL'): Observable<boolean> {
        const result = new Subject<boolean>();
        if (DomainType === 'SQL') {
            this.reqService.switchType('sql');
            const objdomain = {} as ConfigDomainData;
            objdomain.ApplicationID = this.appService.ApplicationId;
            objdomain.ClientId = this.appService.ClientId;// this.appService.clientid as number;
            objdomain.TableID = params.tableId;
            objdomain.DomainType = DomainType;
            objdomain.DomainName = params.tableName;

            const mangDomain: any = [];
            // this.url = this.appService.urlWS;
            this.getDataTable(params).subscribe((res) => {
                if (res.success === true) {
                    const recored = res.features;
                    recored.forEach((element: any) => {
                        mangDomain.push([
                            element[`${params.columnKey}`],
                            element[`${params.columnDisplay}`],
                        ]);
                    });
                    objdomain.Domain = JSON.stringify(JSON.stringify(mangDomain));

                    const odataParams = {
                        url: this.appService.urlWS,
                        table: CoreTechTable.SysDomain,
                        primaryKey: 'DomainId',
                        data: objdomain,
                    };
                    // query bang domain
                    const paramsdomain = [['TableId', '=', objdomain.TableID]];
                    this.getDoMainByTable(paramsdomain).subscribe((res) => {
                        if (res.success) {
                            if (res.features.length > 0) {
                                // update
                                objdomain.DomainId = res.features[0].DomainId;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };

                                this.reqService.service
                                    .update(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                result.next(true);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                            else {
                                // them moi
                                objdomain.DomainId = 0;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };
                                this.reqService.service
                                    .insert(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                result.next(true);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                        }

                    });
                } else {
                    result.next(false);
                    result.complete();
                }
            });
        }
        else {
            this.reqService.switchType('arcgis3x');
            const objdomain = {} as ConfigDomainData;
            objdomain.ApplicationID = this.appService.ApplicationId;
            objdomain.ClientId = this.appService.ClientId; // this.appService.clientid as number;
            objdomain.TableID = params.tableId;
            objdomain.DomainType = DomainType;
            objdomain.DomainName = params.tableName;

            const mangDomain: any = [];
            // this.url = this.appService.urlWS;
            this.getDataTable(params).subscribe((res) => {
                if (res.success === true) {
                    const recored = res.features;
                    recored.forEach((element: any) => {
                        mangDomain.push([
                            element[`${params.columnKey}`],
                            element[`${params.columnDisplay}`],
                        ]);
                    });
                    objdomain.Domain = JSON.stringify(JSON.stringify(mangDomain));

                    const odataParams = {
                        url: this.appService.urlWS,
                        table: CoreTechTable.SysDomain,
                        primaryKey: 'DomainId',
                        data: objdomain,
                    };
                    this.reqService.switchType('sql');
                    // query bang domain
                    const paramsdomain = ['TableId', '=', objdomain.TableID];
                    this.getDoMainByTable(paramsdomain).subscribe((res) => {
                        if (res.success) {
                            if (res.features.length > 0) {
                                // update
                                objdomain.DomainId = res.features[0].DomainId;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };

                                this.reqService.service
                                    .update(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                result.next(true);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                            else {
                                // them moi
                                objdomain.DomainId = 0;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };
                                this.reqService.service
                                    .insert(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                result.next(true);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                        }

                    });
                } else {
                    result.next(false);
                    result.complete();
                }
            });
        }
        return result.asObservable();
    }

    public saveDomainFix(params: any, DomainType = 'SQL', appid = 0): Observable<boolean> {
        const result = new Subject<boolean>();
        if (DomainType === 'SQL') {
            this.reqService.switchType('sql');
            const objdomain = {} as ConfigDomainData;
            objdomain.ApplicationID = appid;
            objdomain.ClientId = this.appService.ClientId;//JSON.parse(localStorage[this.appService.hostName1+'currentUser']).clientid; // this.appService.clientid as number;
            objdomain.TableID = params.TableId;
            objdomain.DomainType = DomainType;
            objdomain.DomainName = params.TableName;
            objdomain.IsConfig = params.IsConfig;
            const mangDomain: any = [];
            // this.url = this.appService.urlWS;
            this.getDataTable(params).subscribe((res) => {
                if (res.success === true) {
                    const recored = res.features;
                    if (params.ColumnCode != null) {
                        recored.forEach((element: any) => {
                            mangDomain.push([
                                element[`${params.ColumnCode}`],
                                element[`${params.ColumnDisplay}`],
                            ]);
                        });
                    }
                    else {
                        recored.forEach((element: any) => {
                            mangDomain.push([
                                element[`${params.ColumnKey}`],
                                element[`${params.ColumnDisplay}`],
                            ]);
                        });
                    }

                    objdomain.Domain = JSON.stringify(JSON.stringify(mangDomain));

                    const odataParams = {
                        url: this.appService.urlWS,
                        table: CoreTechTable.SysDomain,
                        primaryKey: 'DomainId',
                        data: objdomain,
                    };
                    // query bang domain
                    const paramsdomain = [['TableId', '=', objdomain.TableID], ['ApplicationId', '=', appid]];
                    this.getDoMainByTable(paramsdomain).subscribe((res) => {
                        if (res.success) {
                            if (res.features.length > 0) {
                                // update
                                objdomain.DomainId = res.features[0].DomainId;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };

                                this.reqService.service
                                    .update(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                const ketqua: any = {};
                                                ketqua.value = objdomain;
                                                ketqua.result = 'update';
                                                result.next(ketqua);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                            else {
                                // them moi
                                objdomain.DomainId = 0;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };
                                this.reqService.service
                                    .insert(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                const ketqua: any = {};
                                                ketqua.value = a.features[0];
                                                ketqua.result = 'insert';
                                                result.next(ketqua);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                        }

                    });
                } else {
                    result.next(false);
                    result.complete();
                }
            });
        }
        else {
            this.reqService.switchType('arcgis3x');
            const objdomain = {} as ConfigDomainData;
            objdomain.ApplicationID = appid;
            objdomain.ClientId = this.appService.ClientId;// this.appService.clientid as number;
            objdomain.TableID = params.TableId;
            objdomain.DomainType = "FIX";
            objdomain.DomainName = params.TableName;
            objdomain.IsConfig = params.IsConfig;
            const mangDomain: any = [];
            // this.url = this.appService.urlWS;
            this.getDataTable(params).subscribe((res) => {
                if (res.success === true) {
                    const recored = res.features;
                    if (params.ColumnCode != null) {
                        recored.forEach((element: any) => {
                            mangDomain.push([
                                element[`${params.ColumnCode}`],
                                element[`${params.ColumnDisplay}`],
                            ]);
                        });
                    }
                    else {
                        recored.forEach((element: any) => {
                            mangDomain.push([
                                element[`${params.ColumnKey}`],
                                element[`${params.ColumnDisplay}`],
                            ]);
                        });
                    }
                    objdomain.Domain = JSON.stringify(JSON.stringify(mangDomain));

                    const odataParams = {
                        url: this.appService.urlWS,
                        table: CoreTechTable.SysDomain,
                        primaryKey: 'DomainId',
                        data: objdomain,
                    };
                    this.reqService.switchType('sql');
                    // query bang domain
                    const paramsdomain = [['TableId', '=', objdomain.TableID], ['ApplicationId', '=', appid]];
                    this.getDoMainByTable(paramsdomain).subscribe((res) => {
                        if (res.success) {
                            if (res.features.length > 0) {
                                // update
                                objdomain.DomainId = res.features[0].DomainId;
                                objdomain.IsConfig = res.features[0].IsConfig;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };

                                this.reqService.service
                                    .update(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                const ketqua: any = {};
                                                ketqua.value = objdomain;
                                                ketqua.result = 'update';
                                                result.next(ketqua);
                                                // result.next(true);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                            else {
                                // them moi
                                objdomain.DomainId = 0;
                                const odataParams = {
                                    url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };
                                this.reqService.service
                                    .insert(odataParams)
                                    .subscribe(
                                        (a) => {
                                            if (a.success) {
                                                // result.next(true);
                                                const ketqua: any = {};
                                                ketqua.value = res.features[0];
                                                ketqua.result = 'insert';
                                                result.next(ketqua);
                                                result.complete();
                                            } else {
                                                result.next(false);
                                                result.complete();
                                            }
                                        },
                                        (err) => {
                                            result.next(false);
                                            result.complete();
                                        }
                                    );
                            }
                        }

                    });
                } else {
                    result.next(false);
                    result.complete();
                }
            });
        }
        return result.asObservable();
    }
    public getDataTable(params: any) {
        // const bang = params as ConfigTableData;
        if (params.TableType == 'AutoData' || params.TableType == 'CloudData') {
            this.reqService.switchType('clouddata');
        }

        this.url = params.urlEdit === undefined ? params.UrlEdit : params.urlEdit;
        const where: any = [];
        const resp = this.reqService.service.search({
            url: this.url,
            where,
            logic: 'and',
        }).pipe(map((res: any) => {
            this.reqService.switchType('sql');
            return res;
        }));
        return resp;
    }

    public getDoMainByTable(params: any) {
        this.reqService.switchType('sql');
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
            where: params,
            logic: 'and',
        });
        return resp;
    }
    public getTable(params: any) {
        const pTable = CoreTechTable.SysTables;
        this.reqService.switchType('sql');
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + `/` + pTable,
            where: params,
            logic: 'and'
        });
        return resp;
    }
    // hàm tự động save caches khi save ở table
    public saveDomainFixNew(tableid: any, appid = 0): Observable<boolean> {
        let param = ['TableId', '=', tableid];
        const result = new Subject<boolean>();
        this.getTable(param).subscribe(resp => {
            if (resp.success && resp.features.length > 0) {
                let objtable = resp.features[0];
                if (objtable.IsCache === "Y") {
                    if (objtable.TableType === 'SQL') {
                        this.reqService.switchType('sql');
                        const objdomain = {} as ConfigDomainData;
                        objdomain.ApplicationID = appid;
                        objdomain.ClientId = this.appService.ClientId;// this.appService.clientid as number;
                        objdomain.TableID = objtable.TableId;
                        objdomain.DomainType = objtable.TableType;
                        objdomain.DomainName = objtable.TableName;

                        const mangDomain: any = [];

                        this.getDataTable(objtable).subscribe((res) => {
                            if (res.success === true) {
                                const recored = res.features;
                                recored.forEach((element: any) => {
                                    mangDomain.push([
                                        element[`${objtable.ColumnKey}`],
                                        element[`${objtable.ColumnDisplay}`],
                                    ]);
                                });
                                objdomain.Domain = JSON.stringify(JSON.stringify(mangDomain));

                                const odataParams = {
                                    url: this.appService.urlWS,
                                    table: CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };
                                // query bang domain
                                const paramsdomain = [['TableId', '=', objdomain.TableID], ['ApplicationId', '=', appid]];
                                this.getDoMainByTable(paramsdomain).subscribe((res) => {
                                    if (res.success) {
                                        if (res.features.length > 0) {
                                            // update
                                            objdomain.DomainId = res.features[0].DomainId;
                                            objdomain.IsConfig = res.features[0].IsConfig;
                                            const odataParams = {
                                                url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                                primaryKey: 'DomainId',
                                                data: objdomain,
                                            };

                                            for (var key in this.appService.appConfig.sys_combo) {
                                                var objectInKey = this.appService.appConfig.sys_combo;
                                                if (key == objdomain.DomainId.toString()) {
                                                    delete this.appService.appConfig.sys_combo[key];
                                                    this.appService.appConfig.sys_combo[objdomain.DomainId] = JSON.stringify(JSON.stringify(mangDomain));
                                                    //  this.appService.appConfig.sys_combo.push(key,)
                                                }
                                            }
                                            // this.appService.appConfig.sys_combo["'"+objdomain.DomainId+"'"]= mangDomain;
                                            //    Object.keys(this.appService.appConfig.sys_combo).forEach((key) => {
                                            //     console.log(key);

                                            //     });
                                            this.reqService.service
                                                .update(odataParams)
                                                .subscribe(
                                                    (a) => {
                                                        if (a.success) {
                                                            result.next(true);
                                                            result.complete();
                                                        } else {
                                                            result.next(false);
                                                            result.complete();
                                                        }
                                                    },
                                                    (err) => {
                                                        result.next(false);
                                                        result.complete();
                                                    }
                                                );
                                        }
                                        else {
                                            // them moi
                                            objdomain.DomainId = 0;
                                            const odataParams = {
                                                url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                                primaryKey: 'DomainId',
                                                data: objdomain,
                                            };
                                            this.reqService.service
                                                .insert(odataParams)
                                                .subscribe(
                                                    (a) => {
                                                        if (a.success) {
                                                            result.next(true);
                                                            result.complete();
                                                        } else {
                                                            result.next(false);
                                                            result.complete();
                                                        }
                                                    },
                                                    (err) => {
                                                        result.next(false);
                                                        result.complete();
                                                    }
                                                );
                                        }
                                    }

                                });
                            } else {
                                result.next(false);
                                result.complete();
                            }
                        });
                    }
                    else {
                        this.reqService.switchType('arcgis3x');
                        const objdomain = {} as ConfigDomainData;
                        objdomain.ApplicationID = appid;
                        objdomain.ClientId = this.appService.ClientId; // this.appService.clientid as number;
                        objdomain.TableID = objtable.TableId;
                        objdomain.DomainType = objtable.TableType;
                        objdomain.DomainName = objtable.TableName;


                        const mangDomain: any = [];
                        // this.url = this.appService.urlWS;
                        this.getDataTable(objtable).subscribe((res) => {
                            if (res.success === true) {
                                const recored = res.features;
                                recored.forEach((element: any) => {
                                    mangDomain.push([
                                        element[`${objtable.ColumnKey}`],
                                        element[`${objtable.ColumnDisplay}`],
                                    ]);
                                });
                                objdomain.Domain = JSON.stringify(JSON.stringify(mangDomain));

                                const odataParams = {
                                    url: this.appService.urlWS,
                                    table: CoreTechTable.SysDomain,
                                    primaryKey: 'DomainId',
                                    data: objdomain,
                                };
                                this.reqService.switchType('sql');
                                // query bang domain
                                const paramsdomain = [['TableId', '=', objdomain.TableID], ['ApplicationId', '=', appid]];
                                this.getDoMainByTable(paramsdomain).subscribe((res) => {
                                    if (res.success) {
                                        if (res.features.length > 0) {
                                            // update
                                            objdomain.DomainId = res.features[0].DomainId;
                                            objdomain.IsConfig = res.features[0].IsConfig;
                                            const odataParams = {
                                                url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                                primaryKey: 'DomainId',
                                                data: objdomain,
                                            };
                                            for (var key in this.appService.appConfig.sys_combo) {
                                                var objectInKey = this.appService.appConfig.sys_combo;
                                                if (key == objdomain.DomainId.toString()) {
                                                    delete this.appService.appConfig.sys_combo[key];
                                                    this.appService.appConfig.sys_combo[objdomain.DomainId] = JSON.stringify(JSON.stringify(mangDomain));
                                                    //  this.appService.appConfig.sys_combo.push(key,)
                                                }
                                            }
                                            // this.appService.appConfig.sys_combo["'"+objdomain.DomainId+"'"]= mangDomain;
                                            //    Object.keys(this.appService.appConfig.sys_combo).forEach((key) => {
                                            //        console.log(key);

                                            // });
                                            this.reqService.service
                                                .update(odataParams)
                                                .subscribe(
                                                    (a) => {
                                                        if (a.success) {
                                                            result.next(true);
                                                            result.complete();
                                                        } else {
                                                            result.next(false);
                                                            result.complete();
                                                        }
                                                    },
                                                    (err) => {
                                                        result.next(false);
                                                        result.complete();
                                                    }
                                                );
                                        }
                                        else {
                                            // them moi
                                            objdomain.DomainId = 0;
                                            const odataParams = {
                                                url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                                                primaryKey: 'DomainId',
                                                data: objdomain,
                                            };
                                            this.reqService.service
                                                .insert(odataParams)
                                                .subscribe(
                                                    (a) => {
                                                        if (a.success) {
                                                            result.next(true);
                                                            result.complete();
                                                        } else {
                                                            result.next(false);
                                                            result.complete();
                                                        }
                                                    },
                                                    (err) => {
                                                        result.next(false);
                                                        result.complete();
                                                    }
                                                );
                                        }
                                    }

                                });
                            } else {
                                result.next(false);
                                result.complete();
                            }
                        });
                    }
                }
            }

        });
        return result.asObservable();
    }

    // autoSaveDomainTable(tableid: any) {
    //     // const paramsTable = ['TableId', '=', tableid];
    //     // this.getTable(paramsTable).subscribe((resTbl: any) => {
    //     //     if (resTbl.success && resTbl.features.length > 0 && resTbl.features[0].IsCache === 'Y') {
    //     //         const objTable = resTbl.features[0]

    //     //     }
    //     // })
    //     const paramsDomain = ['TableId', '=', tableid]
    //     this.getDoMainByTable(paramsDomain).subscribe((resDomain: any) => {
    //         if (resDomain.success && resDomain.features.length > 0) {
    //             const objDomain = resDomain.features[0]
    //             if (objDomain.DomainType === 'SQL' && objDomain.Domain) {
    //                 const arrValDomain = JSON.parse(objDomain.Domain)
    //                 arrValDomain.forEach((item: any) => {

    //                 })
    //             }
    //         }
    //     })
    // }
    autoSaveDomainTable(tableid: any, clientId: any) {
        const paramsTable = ['TableId', '=', tableid];
        this.getTable(paramsTable).subscribe((resTbl: any) => {
            if (resTbl.success && resTbl.features.length > 0 && resTbl.features[0].IsCache === 'Y') {
                const objTable = resTbl.features[0]
                const paramsDomain = [['ClientId', '=', clientId], ['TableId', '=', tableid]]
                const arr = [this.getDoMainByTable(paramsDomain), this.getDataTable(objTable)]
                // this.getDataTable(objTable).subscribe((res: any) => {

                // })
                combineLatest(arr).subscribe((arrRes: any) => {
                    const arrData = arrRes[1].success ? arrRes[1].features : []
                    const arrDomainInfo = arrRes[0].success ? arrRes[0].features : []
                    if (arrData.length > 0) {
                        arrDomainInfo.forEach((item: any) => {
                            const arrValDomain = JSON.parse(item.Domain)
                            const arrVal: any[] = []
                            arrData.forEach((data: any) => {
                                const arrCache: any[] = []
                                const obj = arrValDomain.find((v: any) => v[0] === data[objTable.ColumnCode])
                                if (obj) {
                                    arrCache.push(data[objTable.ColumnCode], data[objTable.ColumnDisplay])
                                    if (obj[2] !== undefined) {
                                        arrCache.push(obj[2])
                                        if (obj[3] !== undefined) {
                                            arrCache.push(obj[3])
                                        }
                                    }
                                    arrVal.push(arrCache)
                                } else {
                                    arrCache.push(data[objTable.ColumnCode], data[objTable.ColumnDisplay])
                                    if (arrValDomain[0][2] !== undefined) {
                                        arrCache.push(arrValDomain[0][2])
                                        if (arrValDomain[0][3] !== undefined) {
                                            arrCache.push(arrValDomain[0][3])
                                        }
                                    }
                                    arrVal.push(arrCache)
                                }
                            })
                            item.Domain = JSON.stringify(arrVal)
                        })
                    } else {
                        arrDomainInfo.forEach((item: any) => {
                            item.Domain = JSON.stringify(null)
                        })
                    }
                    console.log(arrDomainInfo)
                    const arrSave: any[] = []
                    arrDomainInfo.forEach((item: any) => {
                        const odataParams = {
                            url: this.appService.urlWS + '/' + CoreTechTable.SysDomain,
                            primaryKey: 'DomainId',
                            data: item,
                        };
                        arrSave.push(this.reqService.service.update(odataParams))
                    })
                    this.reqService.switchType('sql')
                    combineLatest(arrSave).subscribe()
                   
                })
            }
        })
    }
   
}
