<div class="div-attach-class">
    <div class="div-tree-attach-class" [ngStyle]="{'display': isFulDialog ? 'none' : 'block'}">
        <app-core-tree [dataTree]="arrDataTree" [treeConfig]="treeConfig" [templateRef]="default" [hasTemplate]="true"
            (selectNode)="onNodeSelect($event)" (nodeExpandEvt)="onNodeExpand($event)">
        </app-core-tree>

        <ng-template #default let-node="treenode">
            <div class="div-nodecustom-class" style="cursor: pointer;">
                <div class="div-titlenode-class" (click)="onNodeSelect(node)">
                    <div class="div-nodetitle-class">
                        <span>
                            {{ node.title | translate }}
                        </span>
                    </div>
                </div>
                <div class="div-btnnode-class" style="justify-content: flex-end;">
                    <!-- <div class="div-iconbtn-class" style="justify-content: flex-end;">
                        <i class="pi pi-plus-circle btn-tree-class" *ngIf="node.isAddIcon"
                            (click)="onClickAdd($event, node)"></i>
                    </div> -->
                    <div class="div-iconbtn-class" style="justify-content: flex-end;"
                        *ngIf="node.isDeleteIcon && !permissionList['DELETE']">

                        <i class="pi pi-minus-circle btn-tree-class" (click)="onClickDeleteItem($event, node)"
                            *ngIf="node.isDeleteIcon"></i>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="div-expand-class" (click)="isFulDialog = !isFulDialog">
        <i class="pi" [ngClass]="{'pi-chevron-right': isFulDialog, 'pi-chevron-left': !isFulDialog}"></i>
    </div>
    <div class="div-file-attach-class" [ngStyle]="{'width': !isFulDialog ? 'calc(100% - 300px)' : 'calc(100% - 12px)'}">
        <app-core-tab [tabs]='arrTabAttachment' [title]="titleRef" [content]="contentRef"
            (closeTabEvt)="onCloseTab($event)" [(activeTabIndex)]="tabIndex">
        </app-core-tab>
        <ng-template #titleRef let-tab>
            <div class="tab-title" style="display: flex; align-items: center; justify-content: space-between;">
                <label style="margin-bottom: 0; display: flex; align-items: center;">
                    <i class="pi {{tab.icon}}" style="font-size: var(--font_size_medium);"></i>&nbsp;
                    {{ tab.displayName }}</label>
                <!-- <i class="pi pi-times" style="font-size: 14px"></i> -->
            </div>
        </ng-template>
        <ng-template #contentRef let-tab>
            <ng-container *ngIf='tab.modeTab === "file"'>
                <div class="body-file-class" [ngStyle]="{'height': isModePlugin ? '100%' : 'auto'}">
                    <div style=" margin: 0; height: 100%" class="row" *ngIf="!isModePlugin || permissionList['EDIT']"
                        [ngStyle]="{'width': !tab.isFullSize ? 'calc(100% - 250px)' : 'calc(100% - 15px)'}">
                        <img src="{{tab.arrDataImg[0].url}}" style="width: 100%;">
                    </div>
                    <div style=" margin: 0;" *ngIf="isModePlugin && !permissionList['EDIT']"
                        [ngStyle]="{'width': !tab.isFullSize ? 'calc(100% - 250px)' : 'calc(100% - 12px)'}">
                        <div style="height: calc(100% - 46px); overflow: auto;">
                            <ng-container *ngIf="checkFileType(tab.arrDataImg[0].contentType)">
                                <div [id]="'aceDiv_' + tab.id" style="height: 100%; width: 100%;"></div>
                            </ng-container>
                            <ng-container *ngIf="!checkFileType(tab.arrDataImg[0].contentType)">
                                <img src="{{tab.arrDataImg[0].url}}" style="width: 100%;">
                            </ng-container>
                        </div>
                        <div style="display: flex; align-items: center; justify-content: flex-end; margin-top: 10px; height: 36px;"
                            *ngIf="checkFileType(tab.arrDataImg[0].contentType) && !permissionList['SAVE'] && !permissionList['EDIT']">
                            <app-button [labelName]='"Update File" | translate' typeButton="save"
                                (clickEvent)="onUpdateFile(tab)" icon='pi pi-save'>
                            </app-button>
                        </div>
                    </div>
                    <div class="div-expand-class" (click)="onFullBody(tab)">
                        <i class="pi"
                            [ngClass]="{'pi-chevron-right': !tab.isFullSize, 'pi-chevron-left': tab.isFullSize}"></i>
                    </div>
                    <!-- <div class="div-formfile-class" *ngIf="type !== 'arcgis'"
                        [ngStyle]="{'display': tab.isFullSize ? 'none' : 'block'}"> -->
                    <div class="div-formfile-class"
                        [ngStyle]="{'display': tab.isFullSize ? 'none' : 'block'}">
                        <app-input [labelName]="'Attachment Name'" [isLabelLeft]="false" [hasButton]="false"
                            labelWidth='80' [hasFormCtrl]="false" [disabledCtrl]="permissionList['EDIT']"
                            [maxlength]="200" [required]="true" [isReadOnly]="(userId && tab.arrDataImg[0].userid !== userId)"
                            [(valueModel)]="tab.arrDataImg[0].dataAttachment.AttachName">
                        </app-input>
                        <app-input [labelName]="'Description'" [isLabelLeft]="false" [hasButton]="false" labelWidth='80'
                            [hasFormCtrl]="false" [disabledCtrl]="permissionList['EDIT']" [maxlength]="100"
                            [required]="false" [isReadOnly]="(userId && tab.arrDataImg[0].userid !== userId)"
                            [(valueModel)]="tab.arrDataImg[0].dataAttachment.Description">
                        </app-input>
                        <div style="display: flex; align-items: center; margin-top: 10px; justify-content: flex-end;">
                            <ng-container *ngIf="!permissionList['SAVE'] && !permissionList['EDIT']">

                                <app-button [labelName]='"Reset" | translate' typeButton="reset"
                                    (clickEvent)="onResetFileInfo()">
                                </app-button>
                                <app-button [labelName]='"Update" | translate' typeButton="save" *ngIf = "(tab.arrDataImg[0].userid === userId || !userId)"
                                    style="margin-left: 10px;" (clickEvent)="onUpdateAttach()" icon='pi pi-save'>
                                </app-button>
                            </ng-container>
                            <!-- <ng-container *ngIf="!isImage(tab.arrDataImg[0].contentType)">
                                <app-button [labelName]='"View file" | translate' style="margin-left: 10px;"
                                    typeButton="save" (clickEvent)="onViewFile(tab)">
                                </app-button>
                            </ng-container> -->
                        </div>
                        <div style="display: flex; align-items: center; margin-top: 10px; justify-content: flex-end;"
                            *ngIf="!isImage(tab.arrDataImg[0].contentType)">
                            <app-button [labelName]='"View file" | translate' style="margin-left: 10px;"
                                typeButton="save" (clickEvent)="onViewFile(tab)">
                            </app-button>
                        </div>
                    </div>
                </div>


            </ng-container>
            <ng-container *ngIf='tab.modeTab === "group"'>
                <!-- <ng-container *ngIf="type !== 'arcgis'"> -->
                    <div class="div-title-class">{{'Group' | translate}}</div>
                    <div class="div-group-class">

                        <div class="div-group-item-class" (click)=" onClickAddGroup(tab)"
                            *ngIf="!permissionList['ADD']">
                            <div class="item-add-class"
                                style="display: flex; align-items: center; justify-content: center; height: 70px; width: 70px; border: 5px solid var(--color_text);">
                                <i class="pi pi-plus" style="display: block; font-size: 50px;"></i>
                            </div>
                            <span class="div-thum-class txt-ellipsis">{{'New Group' | translate}}</span>
                        </div>

                        <div class="div-add-class" *ngIf="tab.isAddGroup">
                            <div
                                style="display: flex; align-items: center; justify-content: center; height: 70px; width: 70px;">
                                <i class="pi pi-folder" style="display: block; font-size: 80px;"></i>
                            </div>
                            <div class="div-thum-class">
                                <div style="width: calc(100% - 40px); display: flex; align-items: center;">
                                    <app-input [hasFormCtrl]='false' [(valueModel)]="tab.newGroupName" type="text"
                                        [required]="true" [showLabel]='false'></app-input>
                                </div>

                                <div class="div-btn-group-class" style="background-color: var(--color_core);"
                                    (click)="onAddGroup(tab)">
                                    <i class="pi pi-check" style="display: block; color: var(--color_icon_core);"></i>
                                </div>
                                <div class="div-btn-group-class" style="border-left: none;">
                                    <i class="pi pi-times" style="display: block; color: var(--color_text);"
                                        (click)="onCancelAddGroup(tab)"></i>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngFor="let group of tab.arrGroup">
                            <div class="div-group-item-class" (click)="openGroup(tab, group, 'group')">
                                <div
                                    style="display: flex; align-items: center; justify-content: center; height: 70px; width: 70px;">
                                    <i class="pi pi-folder" style="display: block; font-size: 80px;"></i>
                                </div>
                                <span class="div-thum-class txt-ellipsis">{{group}}</span>
                            </div>
                        </ng-container>
                    </div>
                <!-- </ng-container> -->

                <div class="div-title-class">{{'File' | translate}}</div>
                <div class="div-group-class">
                    <div class="div-file-class" *ngIf="!permissionList['ADD']"
                        style="display: flex; align-items: center; justify-content: center; flex-direction: column; margin-left: 5px;">
                        <div class="div-file-add-class" style="height: '150px'; width: '150px';"
                            (click)="onUploadNewFile(tab); upload.click()" title="{{'Upload file' | translate}}">

                        </div>
                        <span class="div-imgname-class txt-ellipsis">{{'Upload file' | translate}}</span>
                    </div>

                    <input #upload hidden type="file" (change)="readURL($event)" [accept]="acceptFile" />
                    <ng-container *ngFor="let img of tab.arrDataImg">
                        <div class="div-file-class"
                            style="display: flex; align-items: center; justify-content: center; flex-direction: column; margin-left: 5px;">
                            <div class="file-item-class" [title]='img.descr ? img.descr : img.name'
                                style="height: '150px'; width: '150px';" (click)="openGroup(tab, img, 'file')">
                                <img src="{{img.url}}" height="145" width="145">
                                <div class="icon-delete-class" (click)="onDeleteFile(tab, img, $event)"
                                    *ngIf="!permissionList['DELETE'] && (img.userid === userId || !userId)">
                                    <i class="pi pi-times"></i>
                                </div>
                            </div>
                            <div class="div-imgname-class" title="{{img.name ? img.name : img.descr | translate}}">
                                <span class="txt-ellipsis" style="width: 100%;">{{img.name ? img.name : img.descr |
                                    translate}}</span>
                            </div>
                        </div>


                        <!-- <div class="div-group-item-class"></div> -->

                    </ng-container>
                </div>
            </ng-container>

        </ng-template>
    </div>
</div>
<ng-template #tmpAttachInfo>
    <form [formGroup]="formAttachment">
        <div class="row">
            <div class="col-md-6">
                <app-input [labelName]="'Group Parent'" [isLabelLeft]="false" [hasButton]="false"
                    formControlName="AttachGroupParent" [disabledCtrl]="true" [maxlength]="200" [required]="false"
                    [isReadOnly]="true">
                </app-input>
            </div>
            <div class="col-md-6">
                <app-input [labelName]="'Group'" [isLabelLeft]="false" [hasButton]="false" formControlName="AttachGroup"
                    [disabledCtrl]="true" [maxlength]="200" [required]="false" [isReadOnly]="true">
                </app-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <app-input [labelName]="'Attachment Name'" [isLabelLeft]="false" [hasButton]="false"
                    formControlName="AttachName" [disabledCtrl]="false" [maxlength]="200" [required]="true"
                    [isReadOnly]="false">
                </app-input>
            </div>
            <div class="col-md-6">
                <app-input [labelName]="'Description'" [isLabelLeft]="false" [hasButton]="false"
                    formControlName="Description" [disabledCtrl]="false" [maxlength]="100" [required]="false"
                    [isReadOnly]="false">
                </app-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <app-core-checkbox [chkLabel]="'Keep file name'" [hasFormCtrl]="true" [isCoreForm]="false"
                    [isReadOnly]="isModePlugin" formControlName="KeepFileName" [hasButton]="false"
                    [typeValue]="'boolean'">
                </app-core-checkbox>
            </div>
        </div>
        <div class="divBtnClass" style="padding-top: 10px; justify-content: flex-end; display: flex;">
            <app-button [labelName]='"Close" | translate' typeButton="reset" (clickEvent)="onClickReset()">
            </app-button>

            <app-button [labelName]='"Save" | translate' typeButton="save" (clickEvent)="onSaveAttach()"
                icon='pi pi-save' style="margin-left: 10px;">
            </app-button>
        </div>
    </form>
</ng-template>
<app-loading [class.hidden]="!loading"></app-loading>
<app-dialog-prime #dialogComponent [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>