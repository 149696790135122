
import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { AppService } from '../../app-base/app.service';
import { RequestService } from 'app/core/services/request.service';
import { combineLatest, Observable } from 'rxjs';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { CoreTechTable } from '../config/um-tables';

@Injectable()
export class ClientService {
    public url = this.appService.urlWS + '/SysClients';
    // public folderRoot = 'Clients';
    private urlProxy = '';
    public fileList: any = []; // Danh sách các file mới thêm
    // public clientId = null;
    // public fileName = null;
    public listAttachment: any = []; // Danh sách toàn bộ các file hiển thị trên layout
    public listDeteleAttachment: any = []; // Danh sách các file xóa
    public checkChange = false;

    private fileMaxSize = 5; // Dung lượng file tối đa được gửi, tính theo đơn vị MB
    // private baseList = []; // Clone lưu trữ danh sách các file ban đầu của control, dùng để reset
    private baseFileUrl = 'assets/imgs/others/file_base.png';
    @ViewChild('upload', { static: false }) uploadNode!: ElementRef;

    constructor(
        private appService: AppService,
        private reqService: RequestService,
        private http: HttpClient,
    ) {
        // this.reqService.switchType(this.appService.dataAccessType);
        this.urlProxy = this.appService.urlProxy + '?';
    }

    public queryThemeClient(params: any) {
        const resp = this.reqService.service.search({
            url: `${this.appService.urlWS}/${CoreTechTable.SysThemes}`,
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public queryClientbyId(id: number) {
        const params = [['clientId', '=', id]];
        const resp = this.reqService.service.search({
            url: this.url,
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public queryLicensebyCode(clientcode: string) {
        const params = [['ClientCode', '=', clientcode]];
        const resp = this.reqService.service.search({
            url: this.appService.urlWS.replace('odata', 'odataLicense') + "/SysLicenses",
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public updateClient(params: any) {
        const odataParams = {
            url: this.url,
            table: 'SysClient',
            primaryKey: 'ClientId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    // public updateClientFiel(params, table) {
    //     const odataParams = {
    //         url: this.url + '/' + table,
    //         primaryKey: 'ClientId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }

    // // New
    // public getDetail(id) {
    //     const params = ['ClientId', '=', id];
    //     const resp = this.reqService.service.search({
    //         url: this.url,
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }
    // public queryClient(params) {
    //     const resp = this.reqService.service.search({
    //         url: this.url,
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }

    // public addClient(params) {
    //     const odataParams = {
    //         url: this.url,
    //         primaryKey: 'ClientId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.insert(odataParams);
    //     return resp;
    // }

    // public deleteClient(params) {
    //     const odataParams = {
    //         url: this.url,
    //         primaryKey: 'ClientId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.delete(odataParams);
    //     return resp;
    // }
    // public getJSON(): Observable<any> {
    //     return this.http.get('assets/datas/typicode.json');
    // }

    // postAPI(data): Observable<any> {
    //     return this.http.post('assets/datas/typicode.json', '[{}]');
    // }

    // /** Kiểm tra xem kiểu dữ liệu có phải ảnh hay không */
    private isImage(type: string): boolean {
        if (type && type.split('/').length > 0) {
            if (type.split('/')[0] === 'image') {
                return true;
            }
        }
        return false;
    }

    // /** Đọc và lưu trữ file từ local vào hàng chờ bộ nhớ của máy */
    public readURL(event: Event | any) {
        if ((event.target as HTMLInputElement).files) {
            if ((event.target as HTMLInputElement).files![0]) {

                const file: any = (event.target as HTMLInputElement).files![0];
                const size = file.size / 1024 / 1024; // unit in MB
                if (size < this.fileMaxSize) {
                    file['params'] = event['params'];
                    var checkFileList = this.fileList.filter((fil: any) => file['params'].fileName === fil['params'].fileName);
                    if (checkFileList.length > 0) {
                        const index = this.fileList.indexOf(checkFileList[0]);
                        this.fileList[index] = file;
                    } else {
                        this.fileList.push(file);
                    }

                    if (this.uploadNode !== undefined && this.uploadNode !== null) {
                        this.uploadNode.nativeElement.value = '';
                    }
                    this.checkChange = true;

                    const reader = new FileReader();

                    reader.onload = (e: any) => {
                        this.listAttachment.push({
                            id: null,
                            contentType: file.type,
                            size: file.size,
                            name: file.name,
                            url: this.isImage(file.type) ? e.target.result : this.baseFileUrl,
                            params: event['params']
                        });
                        console.log(this.fileList, file);
                    };

                    reader.readAsDataURL(file);
                } else {
                    this.appService.alert(this.appService.getMessage('0019') + this.fileMaxSize + ' MB!', 'error');
                }
            }
        }
    }

    public uploadFileClient() {
        const urlRequest = this.appService.urlOdataAttachment + '/odata/ProccessFile/PostFileClient';
        const listRequest:any = [];
        this.fileList.forEach((item: any) => {
            const form = new FormData();
            form.append('file', item);
            for (const key in item.params) {
                if (Object.prototype.hasOwnProperty.call(item.params, key)) {
                    form.append(key, item.params[key]);
                }
            }
            listRequest.push(
                this.reqService.service.query({
                    url: urlRequest,
                    params: form,
                    method: 'POST',
                    contentType: 'unset',
                    proxy: this.urlProxy
                })
            );
        });
        return listRequest;
    }

    closeBaseFile(item: any) {
        const a = this.fileList.filter((fil: any) => fil.params.fileName === item);; // Tìm danh sách các file đang ở local
        const index = this.fileList.indexOf(a[0]); // lấy vị trí của file trong danh sách để xóa file tương ứng ở fileList
        this.fileList = this.fileList.filter((fil: any, idx: any) => idx !== index);
        this.listAttachment = this.listAttachment.filter((fil: any) => fil.params.fileName !== item);
        this.checkChange = true;
        console.log("fileList", this.fileList);
        console.log("listAttachment", this.listAttachment);
    }

    // /** Xóa dữ liệu SQL ODATA */
    public deleteSqlOdataAttachment() {
        const list = [];
        list.push(this.listDeteleAttachment);

        if (this.listDeteleAttachment.length > 0) {
            // Sau khi xóa trong dữ liệu trong DB => xóa tiếp dữ liệu trên server
            const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
            const listServer: any = [];
            this.listDeteleAttachment.forEach((item: any, index: any) => {
                listServer.push(this.reqService.service.delete({
                    url: urlServer + '?url=' + this.listDeteleAttachment[index]
                }));
            });

            combineLatest(listServer).subscribe(resp => {
            });
        } else {
        }
    }


    // public async saveImageFile(files, folder, nameImg, paramLogo, table) {
    //     //  upload image
    //     if (files.length > 0) {
    //         const form = new FormData();
    //         form.append('file', files[0]);
    //         form.append('rootFolder', this.folderRoot);
    //         form.append('subRootFolder', folder);
    //         form.append('nameFile', nameImg);

    //         const req = new HttpRequest('POST', this.appService.urlWS + '/ProccessFile/CreateFolder', form, {
    //         });

    //         await this.http
    //             .request(req)
    //             .pipe(filter(e => e instanceof HttpResponse))
    //             .subscribe(
    //                 async () => {
    //                     await this.updateClientFiel(paramLogo, table).subscribe(s => { });
    //                 },
    //                 () => {

    //                     this.msg.error('upload failed.');
    //                 }
    //             );
    //     }
    // }
}
