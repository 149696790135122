import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app-base/app.service';
import { configReportCustomize } from '../report-designer/config/configCustomizeReport';
import { ReportActionService } from '../services/report-action.service';
import { ReportViewerActionService } from '../services/report-viewer-action.service';

@Component({
  selector: 'app-report-viewer',
  template: `<ng-container #viewContainerRef></ng-container>`
})
export class ReportViewerComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true }) viewContainerRef!: ViewContainerRef;
  public loading = false;
  public path = `${this.appService.urlReport}/report/view`;
  constructor(
    private appService: AppService,
    private resolver: ComponentFactoryResolver,
    private router: Router,
    private raService: ReportActionService,
    // public viewContainerRef: ViewContainerRef,
    private rvService: ReportViewerActionService,
    public translate: TranslateService,
  ) { }


  ngOnInit(): void {
  }

  private renderComponent(component: any) {
    const f = configReportCustomize.filter(fil => fil.NAME === component.trim());
    try {
      if (this.viewContainerRef) { this.viewContainerRef.clear(); }
      const componentFactory = this.resolver.resolveComponentFactory(f[0].COMPONENT);
      const componentRef = this.viewContainerRef.createComponent(componentFactory);
      return componentRef;
    } catch (error) {
      return null;
    }
  }

  async run(evt: any) {
    if (evt.typeRun === 'DESIGNERTOOL') {
      this.getDesignerTool(evt);
    } else {
      // if (evt.dataSource && evt.dataSource.LAYOUT_CONFIG && evt.dataSource.LAYOUT_CONFIG.name === 'QUOTATION') {
      //   this.getMenuTool(evt);
      // } else {
      await this.raService.getSysCacheForWindowId(evt.report.WindowId).subscribe(async (syscache: any) => {
        if (syscache.features[0].Config !== null && syscache.features[0].Config !== "") {
          evt.report['config'] = syscache.features[0].Config;
        }
        // Nếu tồn tại component custom và ngược lại
        if (syscache.features[0].Layout) {
          const params = await this.rvService.processParamConfig(evt);
          this.loadComponent(evt, syscache.features[0].Layout, params);
        } else {
          const params = await this.rvService.processParamConfig(evt);
          this.loadComponent(evt, 'SendParamsForConfigComponent', params);
        }

      });
      // }
    }
  }

  runForMobile(evt: any) {
    
    if (evt.typeRun === 'DESIGNERTOOL') {
      this.getDesignerTool(evt);
    } else {
      // if (evt.dataSource && evt.dataSource.LAYOUT_CONFIG && evt.dataSource.LAYOUT_CONFIG.name === 'QUOTATION') {
      //   this.getMenuTool(evt);
      // } else {
      this.raService.getSysCacheForWindowId(evt.report.WindowId).subscribe((syscache: any) => {
        if (syscache.features[0].Config !== null && syscache.features[0].Config !== "") {
          evt.report['config'] = syscache.features[0].Config;
        }
        // Nếu tồn tại component custom và ngược lại

        if (syscache.features[0].Layout) {
          const params = this.rvService.processParamConfig(evt);
          if (window['nsWebViewBridge']) {
            window['nsWebViewBridge'].emit('reportParams', params);
            return;
          }
          this.loadComponent(evt, syscache.features[0].Layout, params);
        } else {
          const params = this.rvService.processParamConfig(evt);
          if (window['nsWebViewBridge']) {
            window['nsWebViewBridge'].emit('reportParams', params);
            return;
          }
          this.loadComponent(evt, 'SendParamsForConfigComponent', params);
        }
      }, err => {
        
      });
      // }
    }
  }

  getDesignerTool(data: any) {
    const dataReportInfo = JSON.stringify(data.dataWindow);
    if (localStorage) {
      if (localStorage.getItem('ReportDataInfo')) { localStorage.removeItem('ReportDataInfo'); }
      localStorage.setItem('ReportDataInfo', dataReportInfo);
    }

    localStorage.setItem(this.appService.hostName1 + 'nameApp', this.translate.instant('REPORT SYSTEM'));
    localStorage.setItem(this.appService.hostName1 + 'tenTrang', this.translate.instant('REPORT SYSTEM'));
    this.router.navigate([]).then(result => {
      window.open('report', '_blank');
    });
  }


  async loadComponent(evt: any, comp: any, params: any) {
    const compReport: any = this.renderComponent(comp);
    if (compReport) {
      compReport.instance.params = params;
      await compReport.instance.getMenuToolItem(evt);
    }
  }

  getMenuTool(data: any) {
    const key = data.KHOA_CHINH;
    const QuotationId = data.currentData[key];
    const reportId = data.report.WindowId;
    const url = `${this.path}/${key}/${QuotationId}/${reportId}`;
    if (data.isMobile) {
      const mobigis = window['_mobigis'];
      mobigis.emitUrlReport.emit(url);
    } else {
      this.router.navigate([]).then(result => {
        window.open(url, '_blank');
      });
    }
  }
}
