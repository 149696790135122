<!-- <p-dialog header="Confirm reject this quotation" [(visible)]="display" appendTo='body' [closable]="false"
    [style]="{width: '35vw'}">
    <ng-container *ngTemplateOutlet="tmpReject"></ng-container>
</p-dialog> -->
<div #tmpReject *ngIf="display">
    <div [formGroup]="formGroup">
        <app-input [labelName]="'Why do you reject this quotation?'" [required]="false" formControlName="REASON"
            [maxlength]="'500'" [isLabelLeft]="false" type='text-area' style="font-size: 14px !important;">
        </app-input>
    </div>
    <div class="divRejectClass">

        <!-- <button nz-button class="btnClearClass" style="margin-right: 10px; margin-left: 0px;"
            (click)="onClose()">{{'Cancel'}}</button>
        <button nz-button class="btnSaveClass" style="margin-right: 0px; margin-left: 0px;"
            (click)="onReject()">{{'Reject'}}</button> -->
        <app-button [labelName]='"Cancel"' typeButton="reset" (clickEvent)="onClose()">
        </app-button>
        <app-button [labelName]='"Reject"' typeButton="save" 
            (clickEvent)="onReject()" style="margin-left: 10px;">
        </app-button>
    </div>
</div>