import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { DeleteResponse, OdataParams, QdataParams, RequestService } from 'app/core/services/request.service';
import { catchError, combineLatest, map } from 'rxjs/operators';
import { AppService, ResponseType } from '../../app-base/app.service';
import { CoreTechTable } from '../config/um-tables';
import { ConfigFieldData } from '../model';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    @Output() layoutInWinzard: EventEmitter<any> = new EventEmitter();
    public url = this.appService.urlWS;
    public tableName = 'SysWindow';

    constructor(
        private appService: AppService,
        private http: HttpClient,
        private reqService: RequestService
    ) {
        this.reqService.switchType(this.appService.dataAccessType);
    }

    public queryWindow(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysWindows',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addWindow(params: any) {
        const odataParams = {
            url: this.url + '/SysWindows',
            primaryKey: 'WindowId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public deleteWindow(params: any) {
        const odataParams = {
            url: this.url + '/SysWindows',
            primaryKey: 'WindowId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public updateWindow(params: any) {
        const odataParams = {
            url: this.url + '/SysWindows',
            primaryKey: 'WindowId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public deleteDomain(params: any) {
        const odataParams = {
            url: this.url + '/SysDomains',
            primaryKey: 'DomainId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public getTableByAppId(appId: any) {
        const resp = this.reqService.service.query({
            url: this.url + '/Config/GetListTableByWindow',
            params: {
                'ApplicationId': appId
            }
        });
        return resp;
    }

    public getColumnByTableId(tblId: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysColumns',
            where: ['TableId', '=', tblId],
            logic: 'and'
        });
        return resp;
    }

    public getFields(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysFields',
            where: params,
            logic: 'and',
            orderBy: ['OrderNo']
        });
        return resp;
    }

    public getTableBpmnService(clientid: any) {
        const resp = this.reqService.service.query({
            url: this.url + '/Svtablebyapplications/GetBpmnServiceTable',
            params: {
                ClientId: clientid
            }
        });
        return resp;
    }

    public getService(clientid: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysServices',
            where: [['ServiceName', '=', 'bpmn workflow'], ['ClientId', '=', clientid]],
            logic: 'and'
        });
        return resp;
    }
    public getTableByService(serviceId: number) {

        const pTable = CoreTechTable.SysTables;
        const params = [
            ['ServiceId', '=', serviceId]
        ];
        const resp = this.reqService.service.search({
            url: this.url + `/` + pTable,
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public getTable(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysTables',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public getColumns(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysColumns',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // create windowconfig
    public getWindowById(windowId: string) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysWindows',
            where: ['WindowId', '=', windowId],
            logic: 'and'
        });
        return resp;
    }
    // khoanb ODATA
    public queryDichVu(params: any, tablename: string) {
        const resp = this.reqService.service.search({
            url: this.url + '/' + tablename,
            where: params,
            logic: 'and'

        });
        return resp;
    }
    public queryDichVuOrderBy(params: any, tablename: string, order: any = []) {
        // const params = [
        //     ['ClientCode', '=', clientcode]

        // ];
        const resp = this.reqService.service.search({
            url: this.url + '/' + tablename,
            where: params,
            logic: 'and',
            orderBy: order
        });
        return resp;
    }
    // ODATA
    public addDichVu(params: any, table: any, key: any) {
        const odataParams = {
            url: this.url + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    // ODATA
    public updateDichVu(params: any, table: any, key: any) {
        const odataParams = {
            url: this.url + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
    // ODATA
    public deleteDichVu(params: any, table: any, key: any) {

        const odataParams = {
            url: this.url + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }
    public getTabByWindowId(windowId: string, order: string[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysTabs',
            where: ['WindowId', '=', windowId],
            logic: 'and',
            orderBy: order
        });
        return resp;
    }

    public getFieldByWhere(where: any[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysFields',
            where,
            logic: 'and',
            orderBy: ['OrderNo']
        });
        return resp;
    }

    // end

    // menu tool
    public getMenuToolByTabId(tabId: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: ['TabId', '=', tabId],
            logic: 'and'
        });
        return resp;
    }
    public getMenuByLevel(level: any, windowId: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: [['MenuLevel', '=', level], ['WindowId', '=', windowId]],
            logic: 'and'
        });
        return resp;
    }

    public getMenuTool(windowId: string) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: [['WindowId', '=', windowId], ['MenuItemType', '=', 'TOOL'], , ['MenuLevel', '<', 4]],
            logic: 'and'
        });
        return resp;
    }

    public deleteMenuTool(params: { MenuItemId: any; }) {
        const odataParams = {
            url: this.url + '/SysMenuItems',
            primaryKey: 'MenuItemId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }
    public addMenuTool(params: {}) {
        const odataParams = {
            url: this.url + '/SysMenuItems',
            primaryKey: 'MenuItemId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    public updateMenuTool(params: {}) {
        const odataParams = {
            url: this.url + '/SysMenuItems',
            primaryKey: 'MenuItemId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
    // end
    public addTab(params: { TableId?: any; ParentTabId?: any; TabName?: any; Description?: any; TabLevel?: number; OrderNo?: any; TruongLienKetCon?: null; TruongLienKetCha?: null; WhereClause?: null; OrderByClause?: null; TruongTrungGianCon?: null; TruongTrungGianCha?: null; BangLienKetId?: null; BangTrungGianId?: null; LayoutColumn?: number; WindowId?: string; ClientId?: any; }) {
        const odataParams = {
            url: this.url + '/SysTabs',
            primaryKey: 'TabId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    public SearchColumn(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysColumns',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public deleteTab(params: { TabId: any; }) {
        const odataParams = {
            url: this.url + '/SysTabs',
            primaryKey: 'TabId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public updateTab(params: {}) {
        const odataParams = {
            url: this.url + '/SysTabs',
            primaryKey: 'TabId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public addField(params: ConfigFieldData) {
        const odataParams = {
            url: this.url + '/SysFields',
            primaryKey: 'FieldId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public deleteField(params: { FieldId: any; }) {
        const odataParams = {
            url: this.url + '/SysFields',
            primaryKey: 'FieldId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public updateField(params: {}) {
        const odataParams = {
            url: this.url + '/SysFields',
            primaryKey: 'FieldId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public getColumnByColumnId(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysColumns',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public getTabs(params: any[], orderno: string[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysTabs',
            where: params,
            logic: 'and',
            orderBy: orderno
        });
        return resp;
    }
    public getAppService(params: any[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/Svtablebyapplications',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    getFieldColumn(params: string[], orderBy: string[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/SvFieldColumns',
            where: params,
            logic: 'and',
            orderBy
        });
        return resp;
    }
    getMenuItem(params: string[][], orderBy: string[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: params,
            logic: 'and',
            orderBy
        });
        return resp;
    }

    public updateCache(params: { Config: string; }) {
        const odataParams = {
            url: this.url + '/SysCaches',
            primaryKey: 'CacheId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
    public getAllCache() {
        const resp = this.reqService.service.search({
            url: this.url + '/SysCaches',
            //  where: params,
            logic: 'and'
        });
        return resp;
    }
    public addCache(params: { Config: string; }) {
        const odataParams = {
            url: this.url + '/SysCaches',
            primaryKey: 'CacheId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    public copyWindow(id: any, appId: any) {

        const resp = this.reqService.service.query({
            url: this.url + '/Config/CopyWindow',
            params: { WindowId: id, AppdichId: appId }

        });
        return resp;
    }

    public copyTab(id: any) {

        const resp = this.reqService.service.query({
            url: this.url + '/Config/CopyTab',
            params: { TabId: id }

        });
        return resp;
    }

    public getCacheByWindowId(params: any[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysCaches',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    getSvColumnTable(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/svColumnTables',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    queryCalendar(params: any[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/CalCalendars',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    postAttachmentPlugin(params: any) {
        const urlRequest = this.appService.urlOdataAttachment + '/odata/ProccessFile/PostAttactment';

        const listRequest: any[] = [];
        const formData = new FormData();
        Object.keys(params).forEach(key => {
            formData.append(key, params[key])
        })
        const res = this.reqService.service.query({
            url: urlRequest,
            params: formData,
            method: 'POST',
            contentType: 'unset',
            proxy: this.appService.urlProxy + '?'
        })
        return res;

    }
    public insertDataToSqlTable(params: any) {
        const urlRequest = this.appService.urlWS + '/Attachs';
        const odataParams = {
            url: urlRequest,
            primaryKey: 'AttachId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    queryAttachment(params: any[]) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/Attachs',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    queryFileAttachment(url: any) {
        const contentType = 'application/json';

        let headers = new HttpHeaders({
            Accept: 'text/plain'
        });

        headers = headers.append('Content-Type', contentType);
        const res = this.http.get(this.appService.urlProxy + '?' + url, { headers, responseType: 'text' })
        return res;
    }
    deleteAttachmentFile(urlFile: any) {
        const urlDelete = this.appService.urlWS + '/Attachs';
        const list: any[] = [];
        const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }
        const res = this.http.delete(urlServer + '?url=' + urlFile, { headers })
        return res.pipe(
            map((res: any) => {
                const resp: DeleteResponse = {
                    data: res.model,
                    success: res.success,
                    message: res.message
                };
                return resp;
            }))
        // const res = this.reqService.service.delete({
        //     url: urlServer + '?url=' + urlFile
        // })
        // return res;


    }
    updateAttachmentInfo(params: any) {
        const odataParams = {
            url: this.appService.urlWS + '/Attachs',
            primaryKey: 'AttachId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    getApplication(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysApplications',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    getListProcedure(url: any, schemaName: any) {
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.get(url + '/procedure/' + schemaName, {
            headers
        }).pipe(map((res: any) => {
            let resp: any = {};
            if (res.length > 0) {
                resp.total = res.length;
                resp.success = true;
                resp.features = res;
                resp.message = 'Query success';
            } else {
                resp.total = res.length;
                resp.success = false;
                resp.features = res;
                resp.message = 'Query error';
            }
            return resp;
            // return res;
        }));
    }

    execProcedure(urlExec: any, params: any) {
        const url = urlExec + '/coretech_new/' + 'script/dbo'
        let headers = new HttpHeaders({
          Accept: 'text/plain',
          'Content-Type': 'application/json'
        });
    
        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        if (currentUser && currentUser.token) {
          headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }
    
        return this.http.post(url, params, { headers }).pipe(map((res: any) => {
          // Cập nhật lại model => In hoa ký tự đầu tiên
          let resp: any = {};
          resp.total = res.numberRecords;
          resp.success = res.result;
          resp.features = res.datas ? res.datas : [];
          resp.message = res.message;
          return resp;
          // return res
        }), catchError(this.handleSearchError));
      }
      private handleSearchError(error: any) {
        return throwError(error);
      }
}
