import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseInterface, DeleteResponse, InsertResponse, OdataParams, QdataParams, SearchResponse, UpdateResponse } from './request.service';

@Injectable()
export class PostgreService implements BaseInterface {

    private urlProxy = '';

    constructor(
        private http: HttpClient
    ) {
        // this.testDecode();
    }

    /** params có dạng object: { id: 1, name: 'hehe' } */
    query(q: QdataParams): Observable<any> {
        const url = q.url;
        const urlRequest = q.proxy ? `${q.proxy}/${url}` : url;

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });

        if (method === 'GET') {
            return this.http.get(urlRequest, {
                headers,
                params: paramsToSend
            });
        } else {
            return this.http.post(urlRequest, q.params, {
                headers,
                params: paramsToSend
            });
        }
    }

    queryCustom(q: QdataParams): Observable<any> {
        return of(true);
    }

    getRecordById(id: any): Observable<any> {
        return of(true);
    }

    /** Tìm kiếm dữ liệu cho ODATA => Đã hoàn thành */
    search(p: OdataParams): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        const val = this.decodeSql1(p.where);
        // const val = '';
        // let convertWhere = val !== '' ? val : this.decodeSql(p.where, p.logic);
        let convertWhere = val;
        if (p.or) {
            convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        }
        if (p.and) {
            convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        }

        if (p.select) { convertWhere += '&$select=' + p.select; }
        if (p.orderBy) {
            convertWhere += '&$orderby=' + p.orderBy.join(',');
        }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        return this.http.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(this.handleSearchError));
    }

    searchForMobile(p: OdataParams, token: any): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        const val = this.decodeSql1(p.where);
        // const val = '';
        // let convertWhere = val !== '' ? val : this.decodeSql(p.where, p.logic);
        let convertWhere = val;
        if (p.or) {
            convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        }
        if (p.and) {
            convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        }

        if (p.select) { convertWhere += '&$select=' + p.select; }
        if (p.orderBy) {
            convertWhere += '&$orderby=' + p.orderBy.join(',');
        }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        return this.http.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(this.handleSearchError));
    }

    private handleSearchError(error: { message: any; }) {
        const rq: SearchResponse = {
            features: [],
            total: 0,
            success: false,
            message: error.message
        };
        return throwError(rq);
    }

    /** Cập nhật dữ liệu cho bảng ghi => Chưa hoàn thành */
    insert(p: OdataParams): Observable<InsertResponse> {
        const url = p.url;
        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
        });

        // không gửi khóa chính cùng giá trị vì service odata sẽ bị lỗi
        if (p.primaryKey) {
            delete p.data[p.primaryKey];
        }

        return this.http.post(url, p.data, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            const data: any = {};
            Object.keys(res.model).forEach(key => {
                data[key.charAt(0).toUpperCase() + key.slice(1)] = res.model[key];
            });
            const result: InsertResponse = {
                features: [data],
                success: res.success,
                total: 1,
                message: res.message
            };

            return result;
        }), catchError(this.handleInsertError));
    }

    private handleInsertError(error: { message: any; }) {
        const rq: InsertResponse = {
            features: [],
            total: 0,
            success: false,
            message: error.message
        };
        return throwError(rq);
    }

    /** Clone từ connect-database và đã tinh chỉnh lại => Đã hoàn thành */
    update(p: OdataParams): Observable<UpdateResponse> {
        const urlRequest = p.url;
        const key: any = p.primaryKey;
        const url = `${urlRequest}/${p.data[key]}`;

        // delete p.data[key];

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
        });

        return this.http.put(url, p.data, { headers }).pipe(map((res: any) => {
            const resp: UpdateResponse = {
                message: res['message'],
                success: res['success']
            };
            return resp;

        }), catchError(this.handleUpdateError));
    }

    private handleUpdateError(error: { message: any; }) {
        const rq: UpdateResponse = {
            success: false,
            message: error.message
        };
        return throwError(rq);
    }

    /** Clone từ connect-database và đã tinh chỉnh lại => Đã hoàn thành */
    public delete(p: OdataParams): Observable<DeleteResponse> {
        const params = p.primaryKey ? p.data[p.primaryKey] : null;
        const urlRequest = p.url;
        const url = `${urlRequest}/${params}`;

        // const url = `${p.url}/${params}`;

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        return this.http.delete(url, { headers }).pipe(
            map((res: any) => {
                const resp: DeleteResponse = {
                    data: [res.model],
                    success: res.success,
                    message: res.message
                };
                return resp;
            }),
            catchError(err => {
                const rq: DeleteResponse = {
                    data: [],
                    success: false,
                    message: err
                };
                return throwError(rq);
            })
        );
    }

    private handleDeleteError(error: any) {
        const rq: DeleteResponse = {
            data: [],
            success: false,
            message: error
        };
        return throwError(rq);
    }

    private decodeSql(where: any[], logic: string) {
        let decode = '';
        if (where) {
            if (where.length > 0) {
                decode += '$filter=';
                if (Array.isArray(where[0])) {
                    // Trường hợp multi
                    where.forEach((item: any, index: number) => {
                        decode = this.decode(item);
                        decode += index < (where.length - 1) && logic ? ` ${logic} ` : '';
                    });
                } else {
                    // Trường hợp where chỉ là dạng [key, operator, value]
                    decode = this.decode(where);
                }
            }
        }
        // if (p.select) { decode += '&select=' + p.select; }
        return decode;
    }

    private decodeSql1(where: string | any[], isBase = true) {
        let decode = ''; let logic = 'and';
        if (where) {
            if (where.length > 0) {
                if (isBase) {
                    decode = '$filter=';
                }
                const cloneWhere = JSON.parse(JSON.stringify(where));
                if (!Array.isArray(cloneWhere[0]) && cloneWhere[0] !== 'and' && cloneWhere[0] !== 'or') {
                    // Trường hợp mảng 1 chiều
                    decode += this.decode(cloneWhere);
                } else {
                    cloneWhere.forEach((item: string | string[], index: number) => {
                        if (index === 0 && (item === 'and' || item === 'or')) {
                            logic = item;
                        } else {
                            if (Array.isArray(item)) {
                                if (item[0] === 'and' || item[0] === 'or') {
                                    decode += `(${this.decodeSql1(item, false)}) ${logic} `;
                                } else {
                                    // item là 1 array có dạng [key, operator, value];
                                    decode += this.decode(item);
                                    decode += index < (cloneWhere.length - 1) && logic ? ` ${logic} ` : '';
                                }
                            } else {
                                // cloneWhere bây giờ là dạng [key, operator, value], chỉ chạy 1 lần duy nhất với index === 0;
                                if (index === 0) {
                                    decode += this.decode(cloneWhere);
                                }
                            }
                        }
                    });
                }

            }
        }
        return decode;
    }

    private testDecode() {
        const where = ['table', 'in', [1, 2]];
        const where1 = ['or', ['table', 'in', [1, 2]], ['data', 'like', '1234']];
        const where2 = [
            'or',
            [
                'and',
                ['table', '=', 1],
                ['tableName', 'like', 'super']
            ],
            ['data', 'like', '1234']
        ];

        console.log('TEST SQL DECODE WHERE: ', this.decodeSql1(where));
        console.log('TEST SQL DECODE WHERE 1: ', this.decodeSql1(where1));
        console.log('TEST SQL DECODE WHERE 2: ', this.decodeSql1(where2));
    }

    private decode(item: any[]) {
        let str = '';
        // item là 1 array có dạng [key, operator, value];
        let val = item[2];
        let key = item[0];
        const resp = listOperatorSqlOdata.filter(fil => fil.key === item[1]);

        if (typeof (val) === 'string') {
            val = this.replaceSpecialCharacters(val);
            const first = val.charAt(0);
            const last = val.charAt(val.length - 1);
            if (first === '\'' && last === '\'') {
                val = '\'' + eval(val.substring(1, val.length - 1)) + '\'';
                val = val.toLowerCase();
                key = `tolower(${key})`;
            } else if (first === '{' && last === '}') {
                val = eval(val.substring(1, val.length - 1));
            } else if (first === '(' && last === ')') {
                val = val.substring(1, val.length - 1);
                val = val.split(',');
                // key = `tolower(${key})`;
            } else {
                val = `'${val}'`.toLowerCase();
                key = `tolower(${key})`;
            }
        } else {
            try {
                // convert to date format
                val = val.toISOString();
            } catch (error) {
            }
        }

        switch (resp[0].value) {
            case 'like':
                str += `contains(${key}, ${val})`;
                break;
            case 'in.':
                str += `(${key} in. (${val.join(',')}))`;
                break;
            case 'not.in.':
                str += `(${key} not.in. (${val.join(',')}))`;
                // str += `not(${key} in (${val.join(',')}))`;
                break;
            default:
                str += `${key} ${resp[0].value} ${val}`;
                break;
        }

        return str;
    }

    private replaceSpecialCharacters(attribute: string) {
        // replace the single quotes
        attribute = attribute.replace(/%/g, '%25');
        attribute = attribute.replace(/\+/g, '%2B');
        attribute = attribute.replace(/\//g, '%2F');
        attribute = attribute.replace(/\?/g, '%3F');

        attribute = attribute.replace(/#/g, '%23');
        attribute = attribute.replace(/&/g, '%26');
        return attribute;
    }

    // private toCsv(data: any[]) {
    //     if (data.length) {
    //         const keys = Object.keys(data[0]);
    //         let csv = keys.toString();
    //         data.forEach((item: { [x: string]: any; }) => {
    //             const line = [];
    //             for (let j = 0; j < keys.length; j++) {
    //                 const value = item[keys[j]];
    //                 (value == null) ? line.push('NULL') : line.push(value);
    //             }
    //             csv += '\n' + line;
    //         });
    //         return csv;
    //     }
    // }
}

export const listOperatorSqlOdata = [
    { key: 'is', value: 'is.' },
    { key: '!is', value: 'not.is.' },
    { key: 'like', value: 'like.' },
    { key: '!like', value: 'not.like.' },
    { key: 'in', value: 'in.' },
    { key: '!in', value: 'not.in.' },
    { key: 'like', value: 'like' },
    { key: '=', value: 'eq.' },
    { key: '!=', value: 'not.eq.' },
    { key: '>', value: 'gt.' },
    { key: '>=', value: 'gte.' },
    { key: '<', value: 'lt.' },
    { key: '<=', value: 'lte.' }
];

export const OPERATOR = {
    is: 'is.',
    '!is': 'not.is.',
    like: 'like.',
    '!like': 'not.like.',
    in: 'in.(',
    '!in': 'not.in.(',
    '=': 'eq.',
    '!=': 'not.eq.',
    '>': 'gt.',
    '>=': 'gte.',
    '<': 'lt.',
    '<=': 'lte.'
};
