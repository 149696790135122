import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { RequestService, InsertResponse} from 'app/core/services/request.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-them-tai-san',
  templateUrl: './them-tai-san.component.html',
  styleUrls: ['./them-tai-san.component.scss'],
  providers: [RequestService]
})
export class ThemTaiSanComponent implements OnInit {
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  public menuList: any[] = [];
  _currentItemApplication: any = null;
  public showAll = false;
  constructor(
    public reqService: RequestService,
    public appService: AppService,
    private resolver: ComponentFactoryResolver,
    public translate: TranslateService,
  ) { 
    reqService.switchType('clouddata');
  }

  ngOnInit(): void {
  }

  run (data: any, isReload: any) {
    // console.log("Thêm tài sản",'data', data, 'isReload', isReload);
    this.menuList = [];
    var Number = 0;
    if (data.currentData.length !== 0){
      const IdKiemTra = data.currentData[0].idKiemTra;
      const param = ['idKeHoachKiemTra', '=', data.currentData[0].idKeHoachKiemTra];
      this.reqService.service.search(
        {
        url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/TgTaiSan_LapKeHoach',
        where: param
        }
      ).subscribe(res => {  
       // console.log('res', res);
        if (res.success) { 
          if (res.features.length > 0) { 
            res.features.forEach(evt => { 
              const Param = ['maTaiSan', '=', evt.idTaiSan];
              this.reqService.service.search(
                {
                url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/TS_ThongTinChung',
                where: Param
                }
              ).subscribe(res2 => {  
                //console.log('res2', res2);
                if(res2.features.length > 0){
                  res2.features.forEach(e => { 
                    this.menuList.push({
                      checked: false,
                      idKiemTra: IdKiemTra,
                      idTaiSan: e.maTaiSan,
                      tenTaiSan: e.tenTaiSan
                    });
                  });
                    this.FilterTS_ThongTinChung(IdKiemTra);
                  
                } else {
                  Number+=1;
                }
                if (Number === res.features.length){
                  this.appService.alert('Không có tài sản kế hoạch kiểm tra !!!');
                  this.onCloseEvent.emit(true);
                }
              }, err => {
                this.appService.alert('Something went wrong!!!', 'error');
                console.log('err', err);
                // this.onCloseEvent.emit(true);
              });
              
            });
          } else {
            this.appService.alert('Không có tài sản kế hoạch kiểm tra !!!');
            this.onCloseEvent.emit(true);
          }
        } else {
          this.appService.alert('Something went wrong!!!', 'error');
          this.onCloseEvent.emit(true);
        }
      }, err => {
        this.appService.alert('Something went wrong!!!', 'error');
        this.onCloseEvent.emit(true);
      });
    } else {
        this.appService.alert('Thực hiện không đúng quy trình kế hoạch kiểm tra  !!!');
        this.onCloseEvent.emit(true);
    }
  }

  FilterTS_ThongTinChung (IdKiemTra: number){ 
    const Param = ['idKiemTra', '=', IdKiemTra];
     this.reqService.service.search(
      {
      url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/TgTaiSan_KiemTra',
      where: Param
      }
    ).subscribe(res2 => {  
      // console.log('res2', res2);
      if(res2.features.length > 0){
        res2.features.forEach(e => {  
          // console.log('e', e);
          this.menuList = this.menuList.filter(function(i) {
            return (e.idTaiSan !== i.idTaiSan);
          });
          
        })
        if(this.menuList.length === 0){
          this.appService.alert('Tài Sản đã thêm mới !!!');
          this.onCloseEvent.emit(true);
        }
      }
    }, err => {
      this.appService.alert('Something went wrong!!!', 'error');
      console.log('err', err);
      this.onCloseEvent.emit(true);
    });

  }

  public onCheckboxChangeApplication(evt: any, item: any, index: number) { 
    // console.log('evt', evt, 'item', item, 'index', index);
    item.checked = evt.checked;
    if (evt.checked) { 

    } else {

    }
    // console.log(this.menuList);
  }
  onClickItemApplication(item: any) { 
    // console.log('item', item);
  }

  onSave() {
    //console.log('onSave', this.menuList);
    const mes = 'Thêm mới thông tin tài sản !!!';
    this.appService.confirm(mes).subscribe((conf) => {
      if (conf) {
        if ( this.menuList.length >0 ){
          this.menuList.forEach(f => {
            if (f.checked){
              //console.log('f', f);
              const Param ={
                idKiemTra: f.idKiemTra,
                idTaiSan: f.idTaiSan
              }

              this.reqService.service.insert({
                url: 'https://water.esrivn.net/CoreTechApi/auto/autodata/data/dbo/TgTaiSan_KiemTra',
                data: Param
                //primaryKey: infor.KHOA_CHINH
              }).subscribe((val: InsertResponse) => {
                  //console.log('val', val)
                  this.onCloseEvent.emit(true);
                  this.appService.notification(
                    this.appService.getMessage('0001'),
                    'success'
                  );
              },(err) => {
                this.onCloseEvent.emit(true);
                    this.appService.notification(
                        this.appService.getMessage('0002'),
                        'error'
                    );
              });
            }
            
          });
        } else {
          this.onCloseEvent.emit(true);
        }

      } else{
        this.onCloseEvent.emit(true);
      }
    });
  }


}
