import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Tree } from 'primeng/tree';

@Component({
  selector: 'app-core-tree',
  templateUrl: './core-tree.component.html',
  styleUrls: ['./core-tree.component.scss']
})
export class CoreTreeComponent implements OnInit, AfterContentChecked {
  _config: any;
  @Input() dataTree: any = [];
  @Input() hasTemplate: boolean = false;
  @Input() myContext = {};
  @Input() dragNode: boolean = false;
  @Input() dropNode: boolean = false;
  currentNode: any = null;
  @ViewChild('treeMenu') treeMenu: Tree | any;
  @Output() selectNode: EventEmitter<any> = new EventEmitter();
  @Output() unSelectNode: EventEmitter<any> = new EventEmitter();
  @Output() nodeSelectedModelChange = new EventEmitter();
  @Output() nodeExpandEvt = new EventEmitter();
  @Output() nodeCollapseEvt = new EventEmitter();
  @Output() nodeDropEvt = new EventEmitter();
  @Output() treeFilterEvt = new EventEmitter();
  @Input()
  get treeConfig(): any {
    return this._config;
  }
  set treeConfig(config: any) {
    this._config = config;
    this.setConfigTree(config);
  }
  private _templateRef!: TemplateRef<any>;
  @Input()
  get templateRef(): TemplateRef<any> {
    return this._templateRef;
  }
  set templateRef(templateRef: TemplateRef<any>) {
    // this.hasTemplate = true;
    this._templateRef = templateRef;
  }
  nodeSelected: any;
  _nodeSelectedModel: any;
  @Input()
  get nodeSelectedModel() {
    return this._nodeSelectedModel;
  }

  set nodeSelectedModel(nodeSelected) {
    this._nodeSelectedModel = nodeSelected;
    this.nodeSelectedModelChange.emit(this._nodeSelectedModel);
  }
  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // console.log('aaasa')
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  setConfigTree(config: any) {
    this.cdr.detectChanges();

    Object.keys(config).forEach((key: any) => {
      this.treeMenu[key] = config[key]
    })
    // console.log(this.treeMenu)

  }
  nodeSelect(evt: any) {
    if (this.treeConfig['selectionMode'] === 'single') {
      if (this.currentNode && evt.node.key === this.currentNode.key) {
        this.unSelectNode.emit(this.nodeSelectedModel);
        this.currentNode = null;
        this.nodeSelectedModel = null;
      } else {
        this.currentNode = evt.node;
        this.selectNode.emit(this.nodeSelectedModel);

      }
    } else {
      const obj = {
        nodes: this.nodeSelectedModel,
        currentNode: evt.node
      }
      this.selectNode.emit(obj);
    }


  }
  nodeUnselect(evt: any) {
    const obj: any = {
      nodes: this.nodeSelectedModel,
      currentNode: evt.node
    }
    this.unSelectNode.emit(obj);
  }
  nodeExpand(evt: any) {
    this.nodeExpandEvt.emit(evt)
  }
  nodeCollapse(evt: any) {
    this.nodeCollapseEvt.emit(evt)
  }
  nodeDrop(evt: any) {
    this.nodeDropEvt.emit(evt)
  }
  treeFilter(evt: any) {
    this.treeFilterEvt.emit(evt)
  }
}
