import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'app/um/services';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';
import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { RequestService, SearchResponse } from 'app/core/services/request.service';
import { FullCalendarComponent, CalendarOptions, EventInput, EventClickArg } from '@fullcalendar/angular';
import { combineLatest } from 'rxjs';
import { CoreWindowComponent } from 'app/core/core-window';
import { FormEditCalendarComponent } from 'app/core/form-edit-calendar/form-edit-calendar.component';
import allLocales from '@fullcalendar/core/locales-all';
// import viLocale from '@fullcalendar/core/locales/vi'


@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
    public _ExtType: any = null;
    @Input('ExtType')
    set ExtType(_ExtType: any) {
        this._ExtType = Number(_ExtType);
        this.reqService.switchType(this.appService.dataAccessType);
        const where: any[] = ['and', ["calCalendarId", '=', Number(_ExtType)]];
        this.reqService.service.search({
            url: this.urlResource,
            where
        }).subscribe(res => {
            if (res.features.length > 0) {
                this.nameType = res.features[0].CalendarName;
            }
        })
    }
    public _extWindow: any = null;
    public nameType = '';
    public _windowIdCal = null;
    public _listCalendar: any = null;

    public formGroupSearch: FormGroup = new FormGroup({});
    public formGroupAdd: FormGroup = new FormGroup({});
    public formGroupAddMany: FormGroup = new FormGroup({});
    public formGroupNotiAdd: FormGroup = new FormGroup({});
    public formGroupNotiAddMany: FormGroup = new FormGroup({});
    public formGroupEdit: FormGroup = new FormGroup({});
    public formGroupNotifi: FormGroup = new FormGroup({});
    public filterFormGroup: FormGroup = new FormGroup({});

    arrPriotity = [{
        CODE: 1,
        DESCR: "Thấp"
    }, {
        CODE: 2,
        DESCR: "Trung bình"
    }, {
        CODE: 3,
        DESCR: "Cao"
    }];

    arrDateSend = [{
        CODE: 0,
        DESCR: "Ngay bây giờ"
    }, {
        CODE: 1,
        DESCR: "Trước 1 ngày"
    }, {
        CODE: 2,
        DESCR: "Trước 2 ngày"
    }, {
        CODE: 3,
        DESCR: "Trước 3 ngày"
    }, {
        CODE: 4,
        DESCR: "Trước 4 ngày"
    }, {
        CODE: 5,
        DESCR: "Trước 5 ngày"
    }, {
        CODE: 6,
        DESCR: "Trước 6 ngày"
    }, {
        CODE: 7,
        DESCR: "Trước 7 ngày"
    }, {
        CODE: 8,
        DESCR: "Trước 8 ngày"
    }, {
        CODE: 9,
        DESCR: "Trước 9 ngày"
    }, {
        CODE: 10,
        DESCR: "Trước 10 ngày"
    }];

    arrWindow: any = [];
    whereCal: any = null;
    windowIdDetail: any = null;
    arrTab: any = [{
        code: 'calendar',
        title: 'Calendar'
    }, {
        code: 'detail',
        title: 'Detail'
    }];

    public is_showtool = true;
    public permissionList: any = {};

    public clientId = '';
    public arrUser: any[] = [];
    treeConfig: any = { selectionMode: 'checkbox' };
    nodeSelected: any = [];

    defaultCheckedUser = [];
    defaultExpandedKeys = ['0-0', '0-0-0', '0-0-1'];
    defaultSelectedKeys = ['0-0-0'];

    @ViewChild('calendar', { static: true }) calendar!: FullCalendarComponent;

    @ViewChild('dialogComponent', { static: false }) dialogPrime!: DialogPrimeComponent;

    @ViewChild('dialogFormSearch', { static: false }) dialogFormSearch!: DialogPrimeComponent;
    @ViewChild('tmpFormSearch', { static: false }) tmpFormSearch!: TemplateRef<any>;

    @ViewChild('dialogFormAdd', { static: false }) dialogFormAdd!: DialogPrimeComponent;
    @ViewChild('tmpFormAddCalendar', { static: false }) tmpFormAddCalendar!: TemplateRef<any>;

    @ViewChild('dialogFormAddMany', { static: false }) dialogFormAddMany!: DialogPrimeComponent;
    @ViewChild('tmpFormAddManyCalendar', { static: false }) tmpFormAddManyCalendar!: TemplateRef<any>;



    @ViewChild('dialogFormEdit', { static: false }) dialogFormEdit!: DialogPrimeComponent;
    @ViewChild('tmpFormEditCalendar', { static: false }) tmpFormEditCalendar!: TemplateRef<any>;

    @ViewChild('dialogUser', { static: true }) dialogUser!: DialogPrimeComponent;
    @ViewChild('tempUser') tempUser!: TemplateRef<any>;

    mode = 'add';
    isOpenFormAdd = false;
    isOpenFormSearch = false;
    isOpenFormNotifi = false;
    checkedDay: Boolean = true;
    checkedSendNotifi: Boolean = false;
    crtEvent: EventClickArg | null = null;
    crtDateClick: any = null;
    crtFromDate: any = null;
    crtToDate: any = null;
    urlResource = '';
    url_Muon_Tra = '';
    arrDateSelect: any = [];

    crtWhere = {};

    MILISEC_GMT = 60000 * (new Date()).getTimezoneOffset();

    public _language: any = "vi";
    @Input('language')
    get language() { return this._language; }
    set language(language) {
        this._language = language;
        if (language === 'vi') {
            this.calendar["calendar"].setOption('locale', 'vi');
        } else {
            this.calendar["calendar"].setOption('locale', 'en-au');
        }
    }

    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        timeZone: 'Asia/Ho_Chi_Minh',
        // headerToolbar: {
        //     left: 'prev,next today',
        //     center: 'title',
        //     right: 'month,agendaWeek,agendaDay,listMonth'
        // },
        // selectable: true,
        editable: true,
        locales: allLocales,
        locale: sessionStorage.getItem('language') === 'vi' ? 'vi' : 'en-au',

        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek, dayGridMonth'
        },
        dayMaxEvents: true,
        eventDidMount: function (info: any) {
            if (info.event._def.extendedProps.description) {
                info.el.title = info.event._def.extendedProps.description;
            }
        },
        eventSourceSuccess: (evt) => {
            // this.calendar["calendar"]["el"].addEventListener('dblclick', () => {
            //   this.clickDate(this.crtDateClick);
            // });
            this.calendar["calendar"]["el"]["children"][1].addEventListener('dblclick', () => {
                this.clickDate(this.crtDateClick);
            });

        },
        eventClick: (event) => {
            if (event.event.display !== "background") {
                this.openDialogEdit(event);
            }
        },
        dateClick: (evt) => {
            this.crtDateClick = evt;
            var findEvt = this.calendar["calendar"].getEventById(evt.dateStr);
            findEvt ? findEvt.remove() : this.calendar["calendar"].addEvent({ id: evt.dateStr, date: evt.date, allDay: true, display: 'background' });

            const itemChk = this.arrDateSelect.filter((fil: any) => fil.dateStr === evt.dateStr)
            if (itemChk.length > 0) {
                // evt.dayEl.style.backgroundColor = '#fff';
                this.arrDateSelect = this.arrDateSelect.filter((fil: any) => fil.dateStr !== evt.dateStr)
            } else {
                // evt.dayEl.style.backgroundColor = '#BCE8F14D';
                this.arrDateSelect.push(evt);
            }

            this.arrDateSelect.sort((a: any, b: any) => {
                return a.date - b.date;
            });
        }
    };

    minDate: any = null;
    maxDate: any = null;
    showFilter = false;
    arrTypeCalendar: any = [];
    typeFilter: any = null;

    createDynamicEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        public appService: AppService,
        private reqService: RequestService,
        public translate: TranslateService,
        private applicationService: ApplicationService
    ) {
        this.formGroupSearch = new FormGroup({
            searchUser: new FormControl(''),
            searchContent: new FormControl(''),
            // searchTime: new FormControl(null),
            searchTimeStart: new FormControl(null),
            searchTimeEnd: new FormControl(null)
        });
        this.formGroupAdd = new FormGroup({
            eventAdd: new FormControl(''),
            dateFromAdd: new FormControl(new Date),
            dateToAdd: new FormControl(new Date),
            chooseWindow: new FormControl(null),
            chkTimeUnitAdd: new FormControl(true),
            chkSendNotifi: new FormControl(true),
            // eventNotifiAdd: new FormControl(''),
            // numberDate: new FormControl(null),
            // priorityAdd: new FormControl(1),
            // treeUserAdd: new FormControl()
        });
        this.formGroupNotiAdd = new FormGroup({
            eventNotifiAdd: new FormControl(''),
            // dateSendNotifiAdd: new FormControl(new Date),
            numberDate: new FormControl(0),
            priorityAdd: new FormControl(1),
            treeUserAdd: new FormControl()
        });
        this.formGroupNotiAddMany = new FormGroup({
            eventNotifiAdd: new FormControl(''),
            // dateSendNotifiAdd: new FormControl(new Date),
            numberDate: new FormControl(0),
            priorityAdd: new FormControl(1),
            treeUserAdd: new FormControl()
        });
        this.formGroupEdit = new FormGroup({
            eventEdit: new FormControl(''),
            dateFromEdit: new FormControl(new Date),
            dateToEdit: new FormControl(new Date),
            chkTimeUnitEdit: new FormControl(true)
        });
        this.formGroupNotifi = new FormGroup({
            eventNotifi: new FormControl(''),
            dateSendNotifi: new FormControl(new Date()),
            priority: new FormControl(1),
            treeUser: new FormControl()
        });
        this.filterFormGroup = new FormGroup({
            cboTypeCalendar: new FormControl(null)
        });

        this.formGroupAdd.controls['chkTimeUnitAdd'].valueChanges.subscribe((res: any) => {
            this.onCheckChangeModeAdd(res);
        });
        this.formGroupEdit.controls['chkTimeUnitEdit'].valueChanges.subscribe((res: any) => {
            this.onCheckChangeModeEdit(res);
        });
        this.formGroupAdd.controls['chkSendNotifi'].valueChanges.subscribe((res: any) => {
            this.checkedSendNotifi = res;
            this.formGroupNotiAdd.controls['eventNotifiAdd'].setValue(this.formGroupAdd.controls['eventAdd'].value);
            // this.formGroupNotiAdd.controls['dateSendNotifiAdd'].setValue(this.formGroupAdd.controls['dateFromAdd'].value);
        });

        this.urlResource = this.appService.urlWS + '/CalCalendars';
        this.url_Muon_Tra = this.appService.urlWS + '/CalDetails';

        // this.formGroupSearch.controls['dateFromSearch'].valueChanges.subscribe((res: any) => {
        //   if (res) {
        //     this.minDate = new Date(res);
        //   }
        // });
        // this.formGroupSearch.controls['dateToSearch'].valueChanges.subscribe((res: any) => {
        //   if (res) {
        //     this.maxDate = new Date(res);
        //   }
        // });

        this.formGroupAdd.controls['dateFromAdd'].valueChanges.subscribe((res: any) => {
            if (res) {
                this.minDate = new Date(res);
            }
        });
        this.formGroupAdd.controls['dateToAdd'].valueChanges.subscribe((res: any) => {
            if (res) {
                this.maxDate = new Date(res);
            }
        });

        this.formGroupEdit.controls['dateFromEdit'].valueChanges.subscribe((res: any) => {
            if (res) {
                this.minDate = new Date(res);
            }
        });
        this.formGroupEdit.controls['dateToEdit'].valueChanges.subscribe((res: any) => {
            if (res) {
                this.maxDate = new Date(res);
            }
        });

    }

    ngOnInit() {
        const localClient: any = this.appService.currentUser;
        this.clientId = JSON.parse(localClient).clientid;
        this.reqService.switchType(this.appService.dataAccessType);
        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        const where: any[] = ['and'];
        const p = {
            logic: 'and',
            where: where,
        };

        if (this._listCalendar) {
            const _where: any[] = ['or'];
            if (this._listCalendar.length > 0) {
                this._listCalendar.forEach((item: any) => {
                    _where.push(['calCalendarId', '=', item.calCalendarId]);
                    this.arrTypeCalendar.push({ CODE: item.calCalendarId, DESCR: item.calendarName, isReadOnly: item.readOnly });
                });
                where.push(_where);
                if (this._listCalendar.length > 1) {
                    this.showFilter = true;
                    this.typeFilter = this._ExtType;
                    this.filterFormGroup.controls.cboTypeCalendar.setValue(this._ExtType);
                } else {
                    this.showFilter = false;
                }
            } else {
                p.where.push(['userId', '=', currentUser ? currentUser.userid : null]);
            }
        }

        this.crtWhere = p;
        this.searchDataCal(this.crtWhere);

        this.filterFormGroup.controls['cboTypeCalendar'].valueChanges.subscribe((res: any) => {
            if (res) {
                if (typeof (res) === 'object') {
                    this.typeFilter = res.CODE;
                } else {
                    this.typeFilter = res;
                }
            } else {
                this.typeFilter = null;
            }

            this.searchDataCal(this.crtWhere);
        });

        this.getUserAll();

        this.permissionList = {
            ADD: true,
            ADDMANY: true,
            EDIT: true,
            DELETE: true,
            SEARCH: true,
            NOTIFI: true
        };

        this.getAllWindow();
    }

    getAllWindow() {
        const params = {
            pageSize: 1000,
            pageNumber: 1,
            ClientId: JSON.parse(this.appService.currentUser!).clientid,
            applicationId: this.appService.objStoreAppId.appId
        };
        this.applicationService.queryTreeApp(params).subscribe((res: any) => {
            if (res.success) {
                this.arrWindow = [];
                res.model.forEach((app: any) => {
                    if (app.applicationId.toString() === this.appService.objStoreAppId.appId) {
                        app.sysWindow.forEach((element: any) => {
                            if (element.windowType === 'FORM') {
                                this.arrWindow.push({
                                    CODE: element.windowId,
                                    DESCR: element.windowName,
                                    APPID: element.applicationId,
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    flattenTree(arr: any) {
        let res: any = [];
        arr.forEach((ele: any) => {
            res.push(ele);
            if (ele.children) {
                const temp = this.flattenTree(ele.children);
                res = res.concat(temp);
            }
        });
        return res;
    }

    selectUser(type: any) {
        const crtFormGr = type === 'single' ? this.formGroupNotiAdd : this.formGroupNotiAddMany
        this.dialogUser.title = 'Select User';
        this.dialogUser.isComponent = false;
        this.dialogUser.templateRef = this.tempUser;
        this.dialogUser.onShow();

        var strUserSelected = '';
        if (this.mode === 'add' || this.mode === 'addMany') {
            strUserSelected = crtFormGr.controls["treeUserAdd"].value;
        } else {
            strUserSelected = this.formGroupNotifi.controls["treeUser"].value;
        }
        var arrCheckUser: any[] = [];
        if (strUserSelected !== null && strUserSelected !== '') {
            arrCheckUser = strUserSelected.split(', ');
        }
        this.nodeSelected = [];
        const arrUserFlat = this.flattenTree(this.arrUser);
        arrUserFlat.forEach((user: any) => {
            if (user.children) {
                const length = user.children.filter((x: any) => arrCheckUser.includes(x.userName)).length
                if (length > 0 && length === user.children.length) {
                    this.nodeSelected.push(user);
                }
            }
            if (arrCheckUser.includes(user.userName)) {
                this.nodeSelected.push(user);
            }
        });
    }

    onAddUser() {
        var strUserSelected = '';
        const arrUserSelect = this.nodeSelected.filter((fil: any) => !fil.children)
        arrUserSelect.forEach((user: any, idx: any) => {
            if (idx === arrUserSelect.length - 1) {
                strUserSelected += user.userName;
            } else {
                strUserSelected += user.userName + ', ';
            }
        });
        const crtFormGr = this.mode === 'addMany' ? this.formGroupNotiAddMany : this.formGroupNotiAdd
        if (this.mode === 'add' || this.mode === 'addMany') {
            strUserSelected.length > 0 ? crtFormGr.controls['treeUserAdd'].setValue(strUserSelected) : crtFormGr.controls['treeUserAdd'].setValue(null);
        } else {
            strUserSelected.length > 0 ? this.formGroupNotifi.controls['treeUser'].setValue(strUserSelected) : this.formGroupNotifi.controls['treeUser'].setValue(null);
        }
        this.dialogUser.onHideDialog();
    }
    onCancelUser() {
        this.dialogUser.onHideDialog();
    }

    getUserAll() {
        const resp = this.reqService.service.query({
            url: this.appService.urlWS + '/Config/GetUserIdByApplication',
            params: {
                pageSize: 1000,
                pageNumber: 1,
                appid: this.appService.objStoreAppId.appId
            },
            method: 'GET'
        }).subscribe(res => {
            const arrRole: any = [];
            const arrUserUnique: any = [];
            res.forEach((element: any) => {
                if (arrUserUnique.length > 0) {
                    const itemCheck = arrUserUnique.filter((fil: any) => fil.userId === element.userId);
                    if (itemCheck.length === 0) {
                        arrUserUnique.push(element);
                    }
                } else {
                    arrUserUnique.push(element);
                }
                if (arrRole.length === 0) {
                    arrRole.push({
                        roleId: element.roleId,
                        roleName: element.roleName,
                        user: []
                    });
                } else {
                    const f = arrRole.filter((fill: any) => fill.roleId === element.roleId);
                    if (!f[0]) {
                        arrRole.push({
                            roleId: element.roleId,
                            roleName: element.roleName,
                            user: []
                        });
                    }
                }
            });

            arrRole.forEach((element: any) => {
                const f = res.filter((fill: any) => fill.roleId === element.roleId);
                if (f.length > 0) {
                    f.forEach((res: any) => {
                        element.user.push(res);
                    });
                }
            });

            this.arrUser = this.buildTreeUser(arrRole, arrUserUnique);
        })
    }

    buildTreeUser(arrRole: any, arrUser: any) {
        let arrRoleTree: any = [];
        arrRole.forEach((ele: any) => {
            const params = {
                key: ele.roleId,
                label: ele.roleName,
                id: ele.roleId,
                data: ele,
                expanded: true,
                // isLeaf: false,
                children: []
            };
            arrRoleTree.push(params);
        });
        arrUser.forEach((item: any) => {
            arrRoleTree.forEach((ele: any) => {
                if (item.roleId === ele.key) {
                    item.key = item.userName;
                    item.label = item.userName;
                    item.id = item.userName;
                    item.data = item;
                    item.isLeaf = true;
                    // item.expanded = true;
                    ele.children.push(item);
                }
            });
        });
        arrRoleTree = [...arrRoleTree];

        return arrRoleTree;
    }

    filterDuplicateArrObj(array: any[], key: any) {
        const result = array.reduce((accumulator, element) => {
            if (!accumulator.find((el: any) => el[key] === element[key])) {
                accumulator.push(element);
            }
            return accumulator;
        }, []);
        const _result = result.filter((ele: any) => {
            return ele[key] != null;
        });
        return _result;
    }

    getMenuChecked(arrMenu: any[]) {
        let result: any[] = [];
        arrMenu.forEach(ele => {
            if (ele.children.length === 0) {
                if (ele.origin.data.IsSummary !== 'Y') { result.push(ele.key); }
            } else {
                const temp = this.getMenuChecked(ele.children);
                result = result.concat(temp);
            }
        });
        return result;
    }

    bindDataCalendar(res: any[]) {
        const dataEvent: EventInput[] = [];
        res.forEach((item, idx) => {
            const dateStart = new Date(item.fromDate);
            const dateEnd = new Date(item.toDate);

            let strDateStart = '';
            let strDateEnd = '';

            const yearStart = dateStart.getFullYear();
            const monthStart = this.addZero(dateStart.getMonth() + 1);
            const dayStart = this.addZero(dateStart.getDate());
            const hoursStart = this.addZero(dateStart.getHours());
            const minutesStart = this.addZero(dateStart.getMinutes());
            const secondsStart = this.addZero(dateStart.getSeconds());

            const yearEnd = dateEnd.getFullYear();
            const monthEnd = this.addZero(dateEnd.getMonth() + 1);
            const dayEnd = this.addZero(dateEnd.getDate());
            const hoursEnd = this.addZero(dateEnd.getHours());
            const minutesEnd = this.addZero(dateEnd.getMinutes());
            const secondsEnd = this.addZero(dateEnd.getSeconds());

            if (item.timeUnit === 1) {
                strDateStart = yearStart + '-' + monthStart + '-' + dayStart + 'T00:00:00';
                strDateEnd = yearEnd + '-' + monthEnd + '-' + dayEnd + 'T24:00:00';
            } else {
                strDateStart = yearStart + '-' + monthStart + '-' + dayStart + 'T' + hoursStart + ':' + minutesStart + ':' + secondsStart;
                strDateEnd = yearEnd + '-' + monthEnd + '-' + dayEnd + 'T' + hoursEnd + ':' + minutesEnd + ':' + secondsEnd;
            }

            dataEvent.push(
                // tslint:disable-next-line:max-line-length
                { title: item.userName + ' - ' + item.content, allDay: item.timeUnit === 1 ? true : false, description: item.content + ' ' + strDateStart + ' - ' + strDateEnd, start: strDateStart, end: strDateEnd, tag: item },
            );
        });
        this.calendarOptions.events = dataEvent;
    }

    clickDate(evt: any) {
        if (evt && evt.date) {
            this.mode = 'add';
            this.checkedDay = true;

            this.dialogFormAdd.title = 'Thêm mới';
            this.dialogFormAdd.isComponent = false;
            this.dialogFormAdd.templateRef = this.tmpFormAddCalendar;
            this.dialogFormAdd.onShow();

            this.formGroupAdd.controls.eventAdd.setValue('');
            this.formGroupAdd.controls.chooseWindow.setValue(this._extWindow);


            this.formGroupAdd.controls.dateFromAdd.setValue(evt.date);
            this.formGroupAdd.controls.dateToAdd.setValue(evt.date);
            this.formGroupAdd.controls.chkTimeUnitAdd.setValue(true);
            this.formGroupAdd.controls.chkSendNotifi.setValue(false);

            // this.formGroupNotiAdd.controls.dateSendNotifiAdd.setValue(new Date());
            this.formGroupNotiAdd.controls["treeUserAdd"].setValue(null);
            this.formGroupNotiAdd.controls.numberDate.setValue(0);

            this.checkedSendNotifi = false;
            this.nodeSelected = [];
            this.minDate = null;
            this.maxDate = null;
        } else {
            this.dialogFormAdd.closeDialog();
        }
    }

    onCheckChangeModeAddMany(evt: Boolean) {
        this.checkedDay = evt;
    }

    onCheckChangeModeAdd(evt: Boolean) {
        this.checkedDay = evt;
        if (this.crtFromDate && this.crtToDate) {
            this.crtFromDate = new Date(this.crtFromDate);
            this.crtToDate = new Date(this.crtToDate);

            this.formGroupAdd.controls.dateFromAdd.setValue(this.crtFromDate);
            this.formGroupAdd.controls.dateToAdd.setValue(this.crtToDate);
        }
    }

    onCheckChangeModeEdit(evt: Boolean) {
        this.checkedDay = evt;
        if (this.crtFromDate && this.crtToDate) {
            this.crtFromDate = new Date(this.crtFromDate);
            this.crtToDate = new Date(this.crtToDate);

            this.formGroupEdit.controls.dateFromEdit.setValue(this.crtFromDate);
            this.formGroupEdit.controls.dateToEdit.setValue(this.crtToDate);
        }
    }

    openDialogSearch() {
        if (this.dialogFormAdd.display) {
            this.dialogFormAdd.closeDialog();
        }
        if (this.dialogFormEdit.display) {
            this.dialogFormEdit.closeDialog();
        }

        this.dialogFormSearch.title = 'Tìm kiếm';
        this.dialogFormSearch.isComponent = false;
        this.dialogFormSearch.templateRef = this.tmpFormSearch;
        this.dialogFormSearch.widthPanel = '40%';
        this.dialogFormSearch.onShow();

        this.formGroupSearch.reset();
        this.minDate = null;
        this.maxDate = null;
    }

    btnTimKiemClick() {
        const currentUser = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);
        const where: any[] = ['and'];
        const p = {
            logic: 'and',
            where: where,
        };
        if (this._listCalendar) {
            if (this._listCalendar.length > 0) {
                const _where: any[] = ['or'];
                this._listCalendar.forEach((item: any) => {
                    _where.push(["calCalendarId", '=', item.calCalendarId]);
                })
                p.where.push(_where);
            } else {
                p.where.push(["userId", '=', currentUser.userid]);
            }
        }

        if (this.formGroupSearch.controls.searchUser.value !== null && this.formGroupSearch.controls.searchUser.value !== '') {
            p.where.push(["userName", 'like', this.formGroupSearch.controls.searchUser.value]);
        }
        if (this.formGroupSearch.controls.searchContent.value !== null && this.formGroupSearch.controls.searchContent.value !== '') {
            p.where.push(["content", 'like', this.formGroupSearch.controls.searchContent.value]);
        }

        if (this.formGroupSearch.controls.searchTimeStart.value) {
            if (this.formGroupSearch.controls.searchTimeStart.value[0]) {
                const date0 = this.formatDateTo_YYYYMMDD_HHMISS_SQL(this.formGroupSearch.controls.searchTimeStart.value[0], 'first');
                p.where.push(['fromDate', '>=', date0]);
            }

            if (this.formGroupSearch.controls.searchTimeStart.value[0]) {
                const date1 = this.formatDateTo_YYYYMMDD_HHMISS_SQL(this.formGroupSearch.controls.searchTimeStart.value[0], 'last');
                p.where.push(['fromDate', '<=', date1]);
            }
        }
        if (this.formGroupSearch.controls.searchTimeEnd.value) {
            if (this.formGroupSearch.controls.searchTimeEnd.value[0]) {
                const date0 = this.formatDateTo_YYYYMMDD_HHMISS_SQL(this.formGroupSearch.controls.searchTimeEnd.value[0], 'first');
                p.where.push(['toDate', '>=', date0]);
            }
            if (this.formGroupSearch.controls.searchTimeEnd.value[1]) {
                const date1 = this.formatDateTo_YYYYMMDD_HHMISS_SQL(this.formGroupSearch.controls.searchTimeEnd.value[1], 'last');
                p.where.push(['toDate', '<=', date1]);
            }
        }

        this.searchDataCal(p);
    }

    private formatDateTo_YYYYMMDD_HHMISS_SQL(date: any, type: 'first' | 'last' | 'none') {
        const d = new Date(date);
        // setHours là lấy giờ theo Zone, setUTCHours là lấy giờ quốc tế
        if (type === 'first') {
            d.setHours(0, 0, 0, 0);
        } else if (type === 'last') {
            d.setHours(23, 59, 59);
        }
        return d;
    }

    searchDataCal(p: any) {
        const url = `${this.url_Muon_Tra}`;
        const where = p.where;
        const params = { url, where };

        if (where) {
            this.reqService.service.search(params).subscribe(
                (res: SearchResponse) => {
                    this.calendar.getApi().removeAllEvents();
                    if (res.features.length > 0) {
                        const data: any[] = [];
                        if (this.typeFilter) {
                            res.features = res.features.filter((fil: any) => fil.CalCalendarId === this.typeFilter);
                        }
                        res.features.forEach(item => {
                            data.push({
                                calCalendarId: item.CalCalendarId,
                                calDetailId: item.CalDetailId,
                                fromDate: item.FromDate,
                                toDate: item.ToDate,
                                timeUnit: item.TimeUnit,
                                content: item.Content,
                                userId: item.UserId,
                                userName: item.UserName,
                                windowId: item.WindowId,
                                tableId: item.TableId,
                                recordId: item.RecordId
                            });
                        });
                        this.bindDataCalendar(data);
                        this.closeDialogSearch();
                    } else {
                        this.bindDataCalendar([]);
                    }
                }
            );
        } else {
            // Xóa trắng dữ liệu
            this.calendar.getApi().removeAllEvents();
        }
    }

    closeDialogSearch() {
        this.dialogFormSearch.closeDialog();
    }

    openDialogAdd() {
        if (this.dialogFormSearch.display) {
            this.dialogFormSearch.closeDialog();
        }
        if (this.dialogFormEdit.display) {
            this.dialogFormEdit.closeDialog();
        }
        this.formGroupAdd.reset();

        this.mode = 'add';
        this.checkedDay = true;

        this.dialogFormAdd.title = 'Thêm mới';
        this.dialogFormAdd.isComponent = false;
        this.dialogFormAdd.templateRef = this.tmpFormAddCalendar;
        this.dialogFormAdd.onShow();

        this.formGroupAdd.controls.eventAdd.setValue('');
        this.formGroupAdd.controls.chooseWindow.setValue(this._extWindow);

        this.formGroupAdd.controls.dateFromAdd.setValue(new Date());
        this.formGroupAdd.controls.dateToAdd.setValue(new Date());
        this.formGroupAdd.controls.chkTimeUnitAdd.setValue(true);
        this.formGroupAdd.controls.chkSendNotifi.setValue(false);

        // this.formGroupNotiAdd.controls.dateSendNotifiAdd.setValue(new Date());
        this.formGroupNotiAdd.controls["treeUserAdd"].setValue(null);
        this.formGroupNotiAdd.controls.numberDate.setValue(0);

        this.checkedSendNotifi = false;
        this.nodeSelected = [];
        this.minDate = null;
        this.maxDate = null;
    }

    openDialogAddMany() {
        if (this.arrDateSelect.length > 0) {
            if (this.dialogFormSearch.display) {
                this.dialogFormSearch.closeDialog();
            }
            if (this.dialogFormEdit.display) {
                this.dialogFormEdit.closeDialog();
            }
            if (this.dialogFormAdd.display) {
                this.dialogFormAdd.closeDialog();
            }
            // this.formGroupAdd.reset();
            const group: any = {
                eventAdd: new FormControl(''),
                chooseWindow: new FormControl(this._extWindow),
                chkTimeUnitAdd: new FormControl(true),
                chkSendNotifi: new FormControl(false)
            };
            this.arrDateSelect.forEach((itemDate: any) => {
                group[itemDate.dateStr + '_start'] = new FormControl(itemDate.date);
                group[itemDate.dateStr + '_end'] = new FormControl(itemDate.date);
            })
            this.formGroupAddMany = new FormGroup(group);

            this.mode = 'addMany';
            this.checkedDay = true;

            this.dialogFormAddMany.title = 'Add many';
            this.dialogFormAddMany.isComponent = false;
            this.dialogFormAddMany.templateRef = this.tmpFormAddManyCalendar;
            this.dialogFormAddMany.onShow();

            // this.formGroupAddMany.controls.eventAdd.setValue('');
            // this.formGroupAddMany.controls.chooseWindow.setValue(this._extWindow);

            // this.formGroupAddMany.controls.dateFromAdd.setValue(new Date());
            // this.formGroupAddMany.controls.dateToAdd.setValue(new Date());
            // this.formGroupAddMany.controls.chkTimeUnitAddMany.setValue(true);
            // this.formGroupAddMany.controls.chkSendNotifi.setValue(false);

            this.formGroupNotiAdd.controls["treeUserAdd"].setValue(null);
            this.formGroupNotiAdd.controls.numberDate.setValue(0);

            this.formGroupAddMany.controls['chkTimeUnitAdd'].valueChanges.subscribe((res: any) => {
                this.onCheckChangeModeAddMany(res);
            });
            this.formGroupAddMany.controls['chkSendNotifi'].valueChanges.subscribe((res: any) => {
                this.checkedSendNotifi = res;
                this.formGroupNotiAddMany.controls['eventNotifiAdd'].setValue(this.formGroupAddMany.controls['eventAdd'].value);
            });

            this.checkedSendNotifi = false;
            this.nodeSelected = [];
            // this.minDate = null;
            // this.maxDate = null;
        } else {
            this.appService.notification('Chưa chọn ngày để thêm sự kiện.', 'error');
        }
    }

    openDialogWindow(type: any) {
        // this._extWindow = 2929;
        const checkFormGroup = type === 'single' ? this.formGroupAdd : this.formGroupAddMany;

        if (checkFormGroup.valid) {
            if (this.checkedSendNotifi) {
                if (this.formGroupNotiAdd.valid) {
                    if (this._extWindow) {
                        // open window detail defaut
                        this.closeDialogAdd();
                        const itemWindow = this.arrWindow.filter((fil: any) => fil.CODE === this._extWindow)
                        if (itemWindow.length > 0) {
                            this.generateCoreWindowComp({
                                description: itemWindow[0].DESCR,
                                windowId: itemWindow[0].CODE
                            }, type);
                        } else {
                            this.appService.notification('Không tìm thấy thông tin cửa sổ chi tiết.', 'error');
                        }
                    } else {
                        if (checkFormGroup.controls['chooseWindow'].value) {
                            this.closeDialogAdd();
                            this.generateCoreWindowComp({
                                description: checkFormGroup.controls['chooseWindow'].value.DESCR,
                                windowId: checkFormGroup.controls['chooseWindow'].value.CODE,
                            }, type);
                        } else {
                            this.onAddCalendar({
                                tableId: null,
                                recordId: null
                            }, type);
                        }
                    }
                } else {
                    this.appService.notification('Vui lòng điền đầy đủ thông tin.', 'error');
                }
            } else {
                if (this._extWindow) {
                    // open window detail defaut
                    this.closeDialogAdd();
                    const itemWindow = this.arrWindow.filter((fil: any) => fil.CODE === this._extWindow);
                    if (itemWindow.length > 0) {
                        this.generateCoreWindowComp({
                            description: itemWindow[0].DESCR,
                            windowId: itemWindow[0].CODE
                        }, type);
                    } else {
                        this.appService.notification('Không tìm thấy thông tin cửa sổ chi tiết.', 'error');
                    }
                } else {
                    if (checkFormGroup.controls['chooseWindow'].value) {
                        this.closeDialogAdd();
                        this.generateCoreWindowComp({
                            description: checkFormGroup.controls['chooseWindow'].value.DESCR,
                            windowId: checkFormGroup.controls['chooseWindow'].value.CODE
                        }, type);
                    } else {
                        this.onAddCalendar({
                            tableId: null,
                            recordId: null
                        }, type);
                    }
                }
            }
        } else {
            this.appService.notification('Vui lòng điền đầy đủ thông tin.', 'error');
        }
    }

    private generateCoreWindowComp(obj: { description: string; windowId: any }, type: any) {
        const dialogComp: CoreWindowComponent = this.dialogPrime.showDialog(
            CoreWindowComponent,
            {}, obj.description
        );

        dialogComp.windowId = obj.windowId;
        dialogComp.eventCalendar.subscribe(data => {
            this.onAddCalendar(data, type)
        });

        dialogComp.createDynamicEvent.subscribe(res => {
            this.createDynamicEvent.emit(res);
        });
        dialogComp.initData();
    }

    onAddCalendar(data: any, type: any) {
        let strDateStart = '';
        let strDateEnd = '';
        let dateStartUTC = null;
        let dateEndUTC = null;

        const currentUser = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);

        let params: any = type === 'single' ? {} : [];
        if (type === 'single') {
            let dateStart = this.formGroupAdd.controls.dateFromAdd.value;
            let dateEnd = this.formGroupAdd.controls.dateToAdd.value;
            // if (typeof (this.formGroupAdd.controls.dateFromAdd.value) === 'number') {
            if (typeof (dateStart) === 'number') {
                dateStart = new Date(dateStart);
            }
            if (typeof (dateEnd) === 'number') {
                dateEnd = new Date(dateEnd);
            }
            // dateStart = new Date(this.formGroupAdd.controls.dateFromAdd.value);
            // dateEnd = new Date(this.formGroupAdd.controls.dateToAdd.value);
            // }

            if (this.formGroupAdd.controls.chkTimeUnitAdd.value) {
                const yearStart = dateStart.getFullYear();
                const monthStart = dateStart.getMonth();
                const dayStart = dateStart.getDate();
                dateStartUTC = Date.UTC(yearStart, monthStart, dayStart);

                const yearEnd = dateEnd.getFullYear();
                const monthEnd = dateEnd.getMonth();
                const dayEnd = dateEnd.getDate();
                dateEndUTC = Date.UTC(yearEnd, monthEnd, dayEnd);

                strDateStart = yearStart + '-' + this.addZero(monthStart + 1) + '-' + this.addZero(dayStart) + 'T00:00:00';
                strDateEnd = yearEnd + '-' + this.addZero(monthEnd + 1) + '-' + this.addZero(dayEnd) + 'T24:00:00';
            } else {
                const yearStart = dateStart.getFullYear();
                const monthStart = dateStart.getMonth();
                const dayStart = dateStart.getDate();
                const hoursStart = dateStart.getHours();
                const minutesStart = dateStart.getMinutes();
                const secondsStart = dateStart.getSeconds();
                dateStartUTC = Date.UTC(yearStart, monthStart, dayStart, hoursStart, minutesStart, secondsStart);
                // tslint:disable-next-line:max-line-length
                strDateStart = yearStart + '-' + this.addZero(monthStart + 1) + '-' + this.addZero(dayStart) + 'T' + this.addZero(hoursStart) + ':' + this.addZero(minutesStart) + this.addZero(secondsStart);

                const yearEnd = dateEnd.getFullYear();
                const monthEnd = dateEnd.getMonth();
                const dayEnd = dateEnd.getDate();
                const hoursEnd = dateEnd.getHours();
                const minutesEnd = dateEnd.getMinutes();
                const secondsEnd = dateEnd.getSeconds();
                dateEndUTC = Date.UTC(yearEnd, monthEnd, dayEnd, hoursEnd, minutesEnd, secondsEnd);
                // tslint:disable-next-line:max-line-length
                strDateEnd = yearEnd + '-' + this.addZero(monthEnd + 1) + '-' + this.addZero(dayEnd) + 'T' + this.addZero(hoursEnd) + ':' + this.addZero(minutesEnd) + this.addZero(secondsStart);
            }

            const windowId = this.formGroupAdd.controls['chooseWindow'].value ? this.formGroupAdd.controls['chooseWindow'].value.CODE : null;

            params = {
                calCalendarId: this._ExtType,
                fromDate: new Date(dateStartUTC),
                toDate: new Date(dateEndUTC),
                // fromDate: 'abc',
                // toDate: new Date(dateEndUTC),
                timeUnit: this.formGroupAdd.controls.chkTimeUnitAdd.value ? 1 : 0,
                content: this.formGroupAdd.controls.eventAdd.value,
                userId: currentUser.userid,
                userName: currentUser.username,
                recordId: data.recordId,
                tableId: data.tableId,
                windowId: this._extWindow ?? windowId,
                status: 1,
                ClientId: this.clientId
            };
        } else {
            this.arrDateSelect.forEach((date: any) => {
                let dateStart = this.formGroupAddMany.controls[date.dateStr + '_start'].value;
                let dateEnd = this.formGroupAddMany.controls[date.dateStr + '_end'].value;
                if (typeof (dateStart) === 'number') {
                    dateStart = new Date(dateStart);
                }
                if (typeof (dateEnd) === 'number') {
                    dateEnd = new Date(dateEnd);
                }

                if (this.formGroupAddMany.controls.chkTimeUnitAdd.value) {
                    const yearStart = dateStart.getFullYear();
                    const monthStart = dateStart.getMonth();
                    const dayStart = dateStart.getDate();
                    dateStartUTC = Date.UTC(yearStart, monthStart, dayStart);

                    const yearEnd = dateEnd.getFullYear();
                    const monthEnd = dateEnd.getMonth();
                    const dayEnd = dateEnd.getDate();
                    dateEndUTC = Date.UTC(yearEnd, monthEnd, dayEnd);

                    strDateStart = yearStart + '-' + this.addZero(monthStart + 1) + '-' + this.addZero(dayStart) + 'T00:00:00';
                    strDateEnd = yearEnd + '-' + this.addZero(monthEnd + 1) + '-' + this.addZero(dayEnd) + 'T24:00:00';
                } else {
                    const yearStart = dateStart.getFullYear();
                    const monthStart = dateStart.getMonth();
                    const dayStart = dateStart.getDate();
                    const hoursStart = dateStart.getHours();
                    const minutesStart = dateStart.getMinutes();
                    const secondsStart = dateStart.getSeconds();
                    dateStartUTC = Date.UTC(yearStart, monthStart, dayStart, hoursStart, minutesStart, secondsStart);
                    // tslint:disable-next-line:max-line-length
                    strDateStart = yearStart + '-' + this.addZero(monthStart + 1) + '-' + this.addZero(dayStart) + 'T' + this.addZero(hoursStart) + ':' + this.addZero(minutesStart) + this.addZero(secondsStart);

                    const yearEnd = dateEnd.getFullYear();
                    const monthEnd = dateEnd.getMonth();
                    const dayEnd = dateEnd.getDate();
                    const hoursEnd = dateEnd.getHours();
                    const minutesEnd = dateEnd.getMinutes();
                    const secondsEnd = dateEnd.getSeconds();
                    dateEndUTC = Date.UTC(yearEnd, monthEnd, dayEnd, hoursEnd, minutesEnd, secondsEnd);
                    // tslint:disable-next-line:max-line-length
                    strDateEnd = yearEnd + '-' + this.addZero(monthEnd + 1) + '-' + this.addZero(dayEnd) + 'T' + this.addZero(hoursEnd) + ':' + this.addZero(minutesEnd) + this.addZero(secondsStart);
                }

                const windowId = this.formGroupAddMany.controls['chooseWindow'].value ? this.formGroupAddMany.controls['chooseWindow'].value.CODE : null;

                params.push({
                    calCalendarId: this._ExtType,
                    fromDate: new Date(dateStartUTC),
                    toDate: new Date(dateEndUTC),
                    // fromDate: 'abc',
                    // toDate: new Date(dateEndUTC),
                    timeUnit: this.formGroupAddMany.controls.chkTimeUnitAdd.value ? 1 : 0,
                    content: this.formGroupAddMany.controls.eventAdd.value,
                    userId: currentUser.userid,
                    userName: currentUser.username,
                    recordId: data.recordId,
                    tableId: data.tableId,
                    windowId: this._extWindow ?? windowId,
                    status: 1,
                    ClientId: this.clientId
                });
            })
        }

        this.reqService.service.insert({
            url: this.url_Muon_Tra,
            data: params,
            primaryKey: 'calDetailId',
        }).subscribe(res => {
            if (res.success) {
                if (this.checkedSendNotifi) {
                    this.onSendNotifiModeAdd(res.features[0].CalDetailId, type);
                } else {
                    this.reqService.switchType('sql');
                    this.calendar.getApi().removeAllEvents();
                    this.searchDataCal(this.crtWhere);
                    this.appService.notification('Thêm lịch thành công', 'success');
                    // this.dialogPrime.closeDialog();
                    this.closeDialogAdd();
                }
            } else {
                this.appService.notification('Đã xảy ra lỗi. Vui lòng thử lại.', 'error');
                // this.dialogPrime.closeDialog();
                this.closeDialogAdd();
            }
        });
    }

    onSendNotifiModeAdd(recordDetailCal: any, type: any) {
        const crtFormAdd = type === 'single' ? this.formGroupAdd : this.formGroupAddMany;
        const crtFormNotiAdd = type === 'single' ? this.formGroupNotiAdd : this.formGroupNotiAddMany;

        let dateFrom = this.formGroupAdd.controls.dateFromAdd.value;
        let dateTo = this.formGroupAdd.controls.dateToAdd.value;
        if (type === 'multi') {
            dateFrom = this.formGroupAddMany.controls.dateFromAdd.value;
            dateTo = this.formGroupAddMany.controls.dateToAdd.value;
        }
        if (typeof (dateFrom) === 'number') {
            dateFrom = new Date(dateFrom);
        }
        if (typeof (dateTo) === 'number') {
            dateTo = new Date(dateTo);
        }

        const numberDate = typeof (crtFormNotiAdd.controls.numberDate.value) === 'object' ? crtFormNotiAdd.controls.numberDate.value.CODE : crtFormNotiAdd.controls.numberDate.value
        const dateNow = new Date();
        let dateSend = numberDate == 0 ? new Date() : new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds());
        if (numberDate !== 0) {
            dateSend.setDate(dateFrom.getDate() - numberDate);
        }

        const year = dateSend.getFullYear();
        const month = dateSend.getMonth();
        const date = dateSend.getDate();
        const hour = dateSend.getHours();
        const minute = dateSend.getMinutes();
        const second = dateSend.getSeconds();
        const dateUTC = Date.UTC(year, month, date, hour, minute, second);
        dateSend = new Date(dateUTC);

        if (this.nodeSelected.length > 0) {
            const listRequest: any[] = [];
            const currentUser = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);

            const yearFrom = dateFrom.getFullYear()
            const monthFrom = dateFrom.getMonth();
            const dayFrom = dateFrom.getDate();
            const hoursFrom = dateFrom.getHours();
            const minutesFrom = dateFrom.getMinutes();
            const secondsFrom = dateFrom.getSeconds();
            var strDateFrom = this.addZero(dayFrom) + '/' + this.addZero(monthFrom + 1) + '/' + yearFrom;

            if (!crtFormAdd.controls.chkTimeUnitAdd.value) {
                strDateFrom = this.addZero(hoursFrom) + ':' + this.addZero(minutesFrom) + ':' + this.addZero(secondsFrom) + ' ' + this.addZero(dayFrom) + '/' + this.addZero(monthFrom + 1) + '/' + yearFrom;
            }

            const yearTo = dateTo.getFullYear();
            const monthTo = dateTo.getMonth();
            const dayTo = dateTo.getDate();
            const hoursTo = dateTo.getHours();
            const minutesTo = dateTo.getMinutes();
            const secondsTo = dateTo.getSeconds();
            var strDateTo = this.addZero(dayTo) + '/' + this.addZero(monthTo + 1) + '/' + yearTo;

            if (!crtFormAdd.controls.chkTimeUnitAdd.value) {
                strDateTo = this.addZero(hoursTo) + ':' + this.addZero(minutesTo) + ':' + this.addZero(secondsTo) + ' ' + this.addZero(dayTo) + '/' + this.addZero(monthTo + 1) + '/' + yearTo;
            }
            const arrUserSelect = this.nodeSelected.filter((fil: any) => !fil.children);
            var strDescr = '';
            if (this.nameType != '') {
                strDescr = this.nameType + ': ' + crtFormNotiAdd.controls.eventNotifiAdd.value + ' - Từ: ' + strDateFrom + ' Đến: ' + strDateTo;
            } else {
                strDescr = crtFormNotiAdd.controls.eventNotifiAdd.value + ' - Từ: ' + strDateFrom + ' Đến: ' + strDateTo;
            }
            arrUserSelect.forEach((user: any) => {
                const params: any = {
                    FromUserId: currentUser.userid,
                    FromUsername: currentUser.username,
                    ToUserId: user.userId,
                    ToUsername: user.userName,
                    // SendDate: this.formatDateNotifi(dateSend),
                    SendDate: dateSend,
                    Description: strDescr,
                    WindowId: this._windowIdCal,
                    TableId: null,
                    RecordId: recordDetailCal,
                    PriorityId: crtFormNotiAdd.controls.priorityAdd.value.CODE ? crtFormNotiAdd.controls.priorityAdd.value.CODE : crtFormNotiAdd.controls.priorityAdd.value
                };

                const odataParams = {
                    url: this.appService.urlWS + '/SysNotifications',
                    primaryKey: 'NotificationId',
                    data: params
                };
                const resp = this.reqService.service.insert(odataParams);
                listRequest.push(resp);
            });
            combineLatest(listRequest).subscribe(res => {
                this.appService.notification('Gửi thông báo thành công.', 'success');
                this.reqService.switchType('sql');
                this.calendar.getApi().removeAllEvents();
                this.searchDataCal(this.crtWhere);
                this.appService.notification('Thêm lịch thành công', 'success');
                // this.dialogPrime.closeDialog();
                this.closeDialogAdd();
                this.closeDialogAddMany();
            }, err => {
                this.appService.notification(this.appService.getMessage('0018'), 'error');
            });
        } else {
            this.appService.notification('Vui lòng chọn ít nhất một người dùng để gửi thông báo!', 'error');
        }
    }

    closeDialogAdd() {
        this.dialogFormAdd.closeDialog();
    }

    closeDialogAddMany() {
        this.arrDateSelect.forEach((item: any) => {
            var findEvt = this.calendar["calendar"].getEventById(item.dateStr);
            if (findEvt) {
                findEvt.remove()
            }
        });
        this.arrDateSelect = [];
        this.dialogFormAddMany.closeDialog();
    }

    openDialogEdit(evt: EventClickArg) {
        const currentUser = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);
        this.crtEvent = evt;

        const dialogEdit: FormEditCalendarComponent = this.dialogPrime.showDialog(
            FormEditCalendarComponent,
            {}, 'Sửa đổi lịch'
        );

        this.mode = 'edit';
        dialogEdit.mode = 'edit';
        if (this.crtEvent.event._def.extendedProps.tag.userId !== currentUser.userid) {
            this.mode = 'view';
            dialogEdit.mode = 'view';
        }

        dialogEdit.createDynamicEvent.subscribe(res => {
            this.createDynamicEvent.emit(res);
        });

        const crtTypeCal = this.arrTypeCalendar.filter((fil: any) => fil.CODE === this.crtEvent?.event._def.extendedProps.tag.calCalendarId);
        if (crtTypeCal.length > 0 && crtTypeCal[0].isReadOnly) {
            this.mode = 'view';
            dialogEdit.mode = 'view';
        }

        dialogEdit.crtEvent = this.crtEvent;
        dialogEdit.windowIdDetail = this.crtEvent.event._def.extendedProps.tag.windowId;
        dialogEdit.ExtType = this._ExtType;
        dialogEdit.nameType = this.nameType;
        dialogEdit._windowIdCal = this._windowIdCal;
        dialogEdit.arrUser = this.arrUser;
        dialogEdit.dialogFormEdit = this.dialogPrime;
        dialogEdit.bindValueForm(this.crtEvent.event._def.extendedProps.tag, evt.event._def.allDay);
        dialogEdit.saveSuccess.subscribe(data => {
            this.calendar.getApi().removeAllEvents();
            this.searchDataCal(this.crtWhere);
        });
    }

    public formatDateNotifi(date: any) {
        let dateString: any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getFullYear();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += 'T';
            dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();
        } else {
            dateString = null;
        }
        return dateString;
    }

    addZero(i: any) {
        if (i < 10) {
            i = '0' + i;
        }
        return i;
    }

    disabledHours(): number[] {
        return [1, 2, 3];
    }

    disabledMinutes(hour: number): number[] {
        if (hour === 4) {
            return [20, 21, 22, 23, 24, 25];
        } else {
            return [];
        }
    }

    disabledSeconds(hour: number, minute: number): number[] {
        if (hour === 5 && minute === 1) {
            return [20, 21, 22, 23, 24, 25];
        } else {
            return [];
        }
    }
}
