import { mapOption } from './../config/map-option';
import { Injectable, Output, EventEmitter } from '@angular/core';

import { Toolbars } from '../classes/toolbars';
import { GraphicUtil } from '../classes/graphic-util';
import { DefaultSymbol } from '../classes/default-symbol';
import { Observable, of, Subject } from 'rxjs';
import { loadModules } from 'esri-loader';
import { EsriConfigService } from './esri-config.service';
import { ProjectionUtilService } from './projection-util.service';
import { AppService } from 'app/app-base/app.service';
import { isArray } from 'lodash';

@Injectable()
export class GisService {
    /**
     * Provide global map object(version 3).
     * @returns `esri/map`
     */
    get map(): any {
        return this._map;
    }
    set map(map: any) {
        this.toolbars = new Toolbars(map);
        this.graphicUtil = new GraphicUtil(map);
        this.symbol = new DefaultSymbol();

        this._map = map;
        window['_map'] = map; // remove production
        // this.basemapSelected = map.getBasemap();
        this.basemapSelected = 'basemapStreetMap';
    }
    private _map: any;
    mapNode!: Element;
    /** Provide navigation and draw tools. */
    /** Provide navigation and draw tools. */
    toolbars!: Toolbars;
    /** Provide graphic layer and utility method. */
    /** Provide graphic layer and utility method. */
    graphicUtil!: GraphicUtil;
    /** Default symbol style for point, polyline, and polygon */
    /** Default symbol style for point, polyline, and polygon */
    symbol!: DefaultSymbol | any;
    /** A client-side geometry engine. */
    geometryEngine: any;
    /** Utility methods for working with graphics. */
    esriGraphicsUtils: any;

    /** Keep the last center */
    lastCenter: any;
    /** Keep the last expan left pnel */
    /** Keep the last expan left pnel */
    lastExpanLeftPanel!: number;
    /** Keep the last basemap selected */
    /** Keep the last basemap selected */
    basemapSelected!: string;

    isActiveIdentify = false;
    kvhc: any = null; // lưu trữ kvhc config
    handleClickMap: any = null;
    mapResponse: any = null;

    windowId = null; // Lưu trữ id của window đang kích hoạt hiện tại
    isWebApp = true; // Map khởi tạo trên web hay mobile
    // thay đổi kiểu vẽ trên bản đồ
    getDataServiceReport: EventEmitter<any> = new EventEmitter();
    getDataServiceReportOB = null;

    tocEmitEvent: EventEmitter<any> = new EventEmitter(); // Sự kiện do ToC emit ra
    listLayerIdentify: any[] = [];

    constructor(
        protected readonly esriConfig: EsriConfigService,

        /**
         * Tool for converting the coordinate system.
         */
        public projectionUtil: ProjectionUtilService,
        public appService: AppService
    ) { }

    resetAllVariable() {
        this.listLayerIdentify = [];
        this.windowId = null;
        this.kvhc = null;
        this.isActiveIdentify = false;
    }

    /** Tương lai sử dụng cho print */
    public printTask(
        url: string,
        params: any = {},
        template: any = {}
    ): Observable<any> {
        const subject: Subject<any> = new Subject();
        loadModules([
            'esri/tasks/PrintParameters',
            'esri/tasks/PrintTask',
            'esri/tasks/PrintTemplate',
        ]).then(async ([PrintParameters, PrintTask, PrintTemplate]) => {
            let urlRequest = url;

            const printTask = new PrintTask(urlRequest);
            const printParam = new PrintParameters();
            const printTemplate = new PrintTemplate();
            Object.keys(template).forEach((key) => {
                printTemplate[key] = template[key];
            });
            Object.keys(params).forEach((key) => {
                printParam[key] = params[key];
            });
            printParam.template = printTemplate;
            printTask.execute(
                printParam,
                (response: any) => {
                    subject.next(response);
                },
                (error: any) => {
                    subject.error(error);
                }
            );
        });
        return subject.asObservable();
    }

    // chuyển đổi thành câu lệnh where của arcgis
    public decodeWhere(where: any) {
        let str = '';
        if (where) {
            str = this.decodeSql(where);
        }

        return str;
    }

    private decodeSql(whereClause: any[]) {
        let where = ''; let logic = 'and';
        if (whereClause === null || whereClause === undefined) {
            return '';
        }

        if (whereClause.length === 0) {
            return '1=1';
        }
        const cloneWhere = JSON.parse(JSON.stringify(whereClause));
        if (cloneWhere.length > 0) {
            if (!Array.isArray(cloneWhere[0]) && cloneWhere[0] !== 'and' && cloneWhere[0] !== 'or') {
                where = this.decodeDeep(cloneWhere, '');
            } else {
                cloneWhere.forEach((item: any, index: any) => {
                    if (index === 0 && (item === 'and' || item === 'or')) {
                        logic = item;
                    } else {
                        if (Array.isArray(item) && (item[0] === 'and' || item[0] === 'or')) {
                            where += `(${this.decodeSql(item)}) ${logic} `;
                        } else {
                            where += this.decodeDeep(item, logic);
                        }
                    }
                });
                const number = logic === 'and' ? 5 : 4;
                where = where.substring(0, where.length - number);
            }
        }

        return where;
    }

    private decodeDeep(item: any, logic: string) {
        const key = item[0];
        const operator = item[1];
        let value = item[2];
        let where = '';

        if (typeof (value) === 'string' && value.startsWith('c$')) {
            // where = `${item[0]} ${item[1]} ${eval(value)}`;
            value = eval(value);
        }

        switch (operator) {
            case 'like':
                where = `${key} LIKE N'%${value}%'`;
                break;
            case 'in':
                let type = 'number'; let val = '';
                if (value && value.length > 0) {
                    if (typeof (value[0]) === 'string') {
                        type = 'string';
                    }
                }
                if (type === 'string') {
                    value.forEach((x: string) => {
                        val = `${val}'${x}',`;
                    });
                    val = val.substring(0, val.length - 1);
                } else {
                    val = value.join(',')
                }
                where = `${key} ${operator} (${val})`;
                break;
            default:
                if (typeof (value) === 'string') {
                    if (value.startsWith('DATE ')) {
                        where += `${key} ${operator} ${value}`;
                    } else {
                        where += `${key} ${operator} '${value}'`;
                    }
                } else {
                    where = `${key} ${operator} ${value}`;
                }
                break;
        }
        if (logic !== '') {
            where += ` ${logic} `;
        }
        return where;
    }

    /** Sử dụng cho Identify tool */
    public IdentifyMap(_layer: any, _layerIndex: any, _draw: any, _extent: any): Observable<any> {
        const layer = _layer;
        const draw = _draw;
        const extent = _extent;
        const subject: Subject<any> = new Subject();
        loadModules([
            'esri/tasks/IdentifyTask',
            'esri/tasks/IdentifyParameters',
        ]).then(async ([IdentifyTask, IdentifyParameters]) => {
            let url = layer.url;
            const identifyTask = new IdentifyTask(url);
            const identifyParams = new IdentifyParameters();
            identifyParams.geometry = draw.geometry;
            identifyParams.mapExtent = extent;
            identifyParams.returnGeometry = true;
            // identifyParams.returnFieldName = true;
            identifyParams.tolerance = 3;
            identifyParams.layerIds = [_layerIndex];
            identifyParams.layerOption =
                IdentifyParameters.LAYER_OPTION_TOP;
            // LAYER_OPTION_TOP, LAYER_OPTION_VISIBLE

            //  this.appService.showLoading();
            identifyTask.execute(identifyParams).then(
                (response: any) => {
                    subject.next(response);
                },
                (error: any) => {
                    subject.next(error);
                }
            ).catch((error: any) => { });
        }).catch((err) => { });

        return subject.asObservable();
    }

    /** Refresh lại toàn bộ bản đồ */
    public refreshAllMap() {
        this.map.layerIds.forEach((item: string) => {
            const layer = this.map.getLayer(item);
            if (layer && layer.refresh) {
                layer.refresh();
            }
        });

        this.map.graphicsLayerIds.forEach((item: string) => {
            const layer = this.map.getLayer(item);
            if (layer && layer.refresh) {
                layer.refresh();
            }
        });
    }

    /** Refresh lại layer chỉ định, đầu vào là tên layer hoặc danh sách tên layer */
    public refreshLayer(name: string | string[]) {
        let layer = null;
        const listLayerName = isArray(name) ? name : [name];
        if (this.map.isWebMap) { // Map được tạo bởi webmap
            const list: any[] = [];
            this.map.graphicsLayerIds.forEach((item: string) => {
                list.push(this.map.getLayer(item));
            })

            listLayerName.forEach(item => {
                const a = list.filter((fil: any) => fil.configName === item);
                if (a && a.length > 0) {
                    if (a[0] && a[0].refresh) {
                        a[0].refresh();
                    }
                }
            });
        } else { // Map tạo bình thường (bởi service trong appService.appConfig.Services)
            listLayerName.forEach(item => {
                layer = this.map.getLayer(item);
                if (layer) {
                    layer.refresh();
                }
            });
        }

    }

    /** Phóng tới hình ảnh, đầu vào gồm geometry và level zoom */
    zoomToGeometry(geometry: object | object[] | any, factor?: any) {
        if (
            geometry instanceof Array === false &&
            geometry['type'] === 'point'
        ) {
            this.map.centerAndZoom(geometry, factor ?? 17);
        } else {
            if (geometry instanceof Array === false) {
                geometry = [geometry];
            }
            const graphics: any[] = (geometry as Array<
                object
            >).map((_geometry) => this.graphicUtil.create(_geometry));
            const extent = this.esriGraphicsUtils.graphicsExtent(graphics);
            this.map.setExtent(extent.expand(1.5), true);
        }
    }

    public highlightGeometry(geometry: any, graphicLayer: any, isHighlightLayer = false) {
        let graphic = null; let symbol = null;
        if (geometry.type === 'polyline') {
            symbol = isHighlightLayer ? this.symbol.polylineHighlight : this.symbol.polyline;
            graphic = this.graphicUtil.create(geometry, symbol);
        } else if (geometry.type === 'point') {
            symbol = isHighlightLayer ? this.symbol.pointHighlight : this.symbol.point;
            graphic = this.graphicUtil.create(geometry, symbol);
        } else {
            symbol = isHighlightLayer ? this.symbol.polygonHighlight : this.symbol.polygon;
            graphic = this.graphicUtil.create(geometry, symbol);
        }
        this.graphicUtil.add(graphic, graphicLayer);
    }

    fullExtent(): void {
        loadModules(['esri/geometry/Extent', 'esri/geometry/Point']).then(
            ([Extent, Point]) => {
                const centerPoint = new Point(mapOption.center);
                this._map.centerAndZoom(centerPoint, 12);
            }
        );
    }

    // Khởi tạo extent dựa trên tham số
    async createExtent(params: any) {
        const [Extent] = await loadModules(['esri/geometry/Extent']);
        return new Extent(params);
    }

    queryTaskWhere(url: string, where: string): Observable<any> {
        const subject: Subject<any> = new Subject();

        loadModules(['esri/tasks/query', 'esri/tasks/QueryTask']).then(
            async ([Query, QueryTask]) => {
                const queryParam = new Query();
                let servQuery = url;
                const queryTask = new QueryTask(servQuery);

                queryParam.outFields = ['*'];
                queryParam.returnGeometry = true;
                queryParam.where = where;

                queryTask.execute(
                    queryParam,
                    (response: any) => {
                        subject.next(response);
                    },
                    (error: any) => {
                        subject.next(error);
                    }
                );
            }
        );
        return subject.asObservable();
    }

    queryTask(url: string, params: any = {}): Observable<any> {
        const subject: Subject<any> = new Subject();
        loadModules(['esri/tasks/query', 'esri/tasks/QueryTask']).then(
            async ([Query, QueryTask]) => {
                const queryParam = new Query();
                let servQuery = url;
                const queryTask = new QueryTask(servQuery);
                Object.keys(params).forEach((key) => {
                    queryParam[key] = params[key];
                });
                queryTask.execute(
                    queryParam,
                    (response: any) => {
                        subject.next(response);
                    },
                    (error: any) => {
                        subject.next(error);
                    }
                );
            }
        );
        return subject.asObservable();
    }

    queryGeometry(p: ArcGISParameter) {
        const subject: Subject<any> = new Subject();
        loadModules(['esri/tasks/query', 'esri/tasks/QueryTask']).then(
            async ([Query, QueryTask]) => {
                const query = new Query();
                let url = p.url;

                const queryTask = new QueryTask(url);
                query.outFields = ['*'];
                query.returnGeometry = true;
                query.outSpatialReference = { wkid: 102100 };
                query.geometry = p.geometry;
                query.where = p.where ?? null;
                queryTask.execute(
                    query,
                    (response: any) => {
                        subject.next(response);
                    },
                    (error: any) => {
                        subject.next(error);
                    }
                );
            }
        );

        return subject.asObservable();
    }

    // add layer
    public addLayer(item: any) {
        loadModules([
            'esri/layers/ArcGISDynamicMapServiceLayer',
            'esri/layers/ArcGISTiledMapServiceLayer',
            'esri/layers/ArcGISImageServiceLayer',
        ]).then(
            ([
                ArcGISDynamicMapServiceLayer,
                ArcGISTiledMapServiceLayer,
                ArcGISImageServiceLayer,
            ]) => {
                let layer = null;
                if (item.SERVICE_TYPE === 'TILED') {
                    layer = new ArcGISTiledMapServiceLayer(item.SERVICE_URL, {
                        id: item.SERVICE_ID,
                    });
                } else if (item.SERVICE_TYPE === 'DYNAMIC') {
                    layer = new ArcGISDynamicMapServiceLayer(item.SERVICE_URL, {
                        id: item.SERVICE_ID,
                    });
                } else if (item.SERVICE_TYPE === 'IMAGE') {
                    layer = new ArcGISImageServiceLayer(item.SERVICE_URL, {
                        id: item.SERVICE_ID,
                    });
                }
                if (item.SERVICE_VISIBLE === 1) {
                    layer.visible = true;
                } else {
                    layer.visible = false;
                }
                this.map.addLayer(layer);
            }
        );
    }
}

export interface ArcGISParameter {
    url: string;
    geometry: any;
    where?: any;
}

