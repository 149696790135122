<div class="div-letter-class">
    <form [formGroup]="formLetter">
        <div class="row user-form-row" style="position: relative; margin-top: 0;">
            <div class="col-md-12">
                <app-input [labelName]="'Bidding name'" [required]="false" formControlName="tenGoiThau"
                    [isLabelLeft]="false" type="text-area">
                </app-input>
            </div>
        </div>
        <div class="row user-form-row" style="position: relative; margin-top: 0;">
            <div class="col-md-12">
                <app-input [labelName]="'Contractor'" [required]="false" formControlName="tenNhaThau"
                    [isLabelLeft]="false" type="text-area">
                </app-input>
            </div>
        </div>
        <div class="row user-form-row" style="position: relative; margin-top: 0;">
            <div class="col-md-12">
                <app-input [labelName]="'Contractor Address'" [required]="false" formControlName="diaChiNhaThau"
                    [isLabelLeft]="false" type="text-area" [maxlength]="'500'">
                </app-input>
            </div>

        </div>
        <div class="row user-form-row" style="position: relative; margin-top: 0;">
            <div class="col-md-12">
                <app-input [labelName]="'Investor'" [required]="false" formControlName="chuDauTu" [isLabelLeft]="false"
                    type="text-area">
                </app-input>
            </div>

        </div>

    </form>
    <div class="divBtnClass">
        <!-- <button nz-button class="btnClearClass" style="margin-left: 0px;" (click)="onResetForm()">{{'Reset'}}</button>
        <button style="margin-left: 10px;" nz-button class="btnSaveClass" (click)="onSaveLetter()"
            *ngIf="modeSub !== 'E'">{{'Add'}}</button>
        <button nz-button class="btnSaveClass" style="margin-left: 10px;" *ngIf="modeSub === 'E'"
            (click)="onEditLetter()">{{'Update'}}</button>
        <button nz-button class="btnSaveClass" style="margin-left: 10px;" (click)="onPrintLetter()">{{'Print'}}</button> -->
        <app-button [labelName]='"Cancel"' typeButton="reset" (clickEvent)="onResetForm()"> </app-button>
        <app-button [labelName]='"Add"' typeButton="save" (clickEvent)="onSaveLetter()" style="margin-left: 10px;"
            *ngIf="modeSub !== 'E'">
        </app-button>
        <app-button [labelName]='"Update"' typeButton="save" (clickEvent)="onEditLetter()" style="margin-left: 10px;"
            *ngIf="modeSub === 'E'">
        </app-button>
        <app-button [labelName]='"Print"' typeButton="save" (clickEvent)="onPrintLetter()" style="margin-left: 10px;">
        </app-button>
    </div>
</div>
<ng-container #containerRef></ng-container>