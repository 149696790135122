import { Component, OnInit } from '@angular/core';
import { RequestService } from 'app/core/services/request.service';
import { AppService } from 'app/app-base/app.service';
import { CoreWindowService } from 'app/core/core-window/core-window.service';

@Component({
    selector: 'app-search-mix',
    templateUrl: './search-mix.component.html',
    styleUrls: ['./search-mix.component.scss']
})
export class SearchMixComponent implements OnInit {

    constructor(
        private appService: AppService,
        private reqService: RequestService,
        private coreWindowService: CoreWindowService
    ) { }

    ngOnInit(): void {
        const tabList: any[] = [];
        const baseTabStruct = this.coreWindowService.lookupTab;
        if (baseTabStruct) {
            Object.keys(baseTabStruct).forEach(key => {
                const item = baseTabStruct[key];
                item.FIELD_LIST.forEach((field: any) => {
                    if (field.issearchtonghop === 'Y') {
                        tabList.push(field);
                    }
                });
            });
        }

        console.log(tabList);
    }

}
