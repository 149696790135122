<ng-container *ngIf="isLoadFormSuccess">
    <form [formGroup]="formGroup" style="padding: 1rem;">
        <ng-container *ngIf="typeOfFormControl === 'layout'">
            <div class="fluid grid formgrid formLayout">
                <ng-container *ngTemplateOutlet="tableInCell; context: { $implicit: fieldNoGroup }"></ng-container>
            </div>

            <ng-template #tableInCell let-rows>
                <ng-container *ngFor="let row of rows">
                    <div class="fluid grid formgrid col-12">
                        <ng-container *ngFor="let cell of row">
                            <ng-container *ngIf="cell.rows">
                                <div class="field col-md-{{cell.nzSpan}} md-4">
                                    <ng-container *ngIf="cell.title && cell.title !== ''">
                                        <p-accordion expandIcon='pi pi-chevron-circle-down'
                                            collapseIcon='pi pi-chevron-circle-up'>
                                            <p-accordionTab [header]="cell.title" [selected]="true">
                                                <div class="fluid grid formgrid">
                                                    <ng-container
                                                        *ngTemplateOutlet="tableInCell; context: { $implicit: cell.rows }">
                                                    </ng-container>
                                                </div>
                                            </p-accordionTab>
                                        </p-accordion>
                                    </ng-container>
                                    <ng-container *ngIf="!cell.title || cell.title === ''">
                                        <div class="fluid grid formgrid">
                                            <ng-container
                                                *ngTemplateOutlet="tableInCell; context: { $implicit: cell.rows }">
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!(cell.rows)">
                                <ng-container *ngTemplateOutlet="templateField; context: { $implicit: cell }">
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="typeOfFormControl === 'default'">
            <div class="fluid grid formgrid formDefault">
                <ng-container *ngFor="let field of fieldNoGroup">
                    <ng-container *ngIf="field.FIELD_ISUNIQUE">
                        <!-- Dùng để ngắt dòng -->
                        <div style="width: 100%"></div>
                    </ng-container>
                    <ng-container *ngTemplateOutlet="templateField; context: { $implicit: field }">
                    </ng-container>
                </ng-container>
            </div>

            <ng-container *ngIf="hasFieldGroup">
                <div class="fluid grid formgrid formDefault ">
                    <ng-container *ngFor="let group of fieldGroup | keyvalue">
                        <div class="field col-12 md-12">
                            <p-accordion expandIcon='pi pi-chevron-circle-down' collapseIcon='pi pi-chevron-circle-up'>
                                <p-accordionTab [header]="$any(group).key" [selected]="true">
                                    <ng-container *ngFor="let field of $any(group).value">
                                        <ng-container *ngIf="field.FIELD_ISUNIQUE">
                                            <!-- Dùng để ngắt dòng -->
                                            <div style="width: 100%"></div>
                                        </ng-container>
                                        <ng-container *ngTemplateOutlet="templateField; context: { $implicit: field }">
                                        </ng-container>
                                    </ng-container>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #templateField let-field>
            <ng-container *ngIf="field.FIELD_HIDE !== true">
                <div class="field col-md-{{field.FIELD_SPAN}} md-4">
                    <ng-container [ngSwitch]="field.FIELD_TYPE">
                        <ng-container *ngSwitchCase="'search'">
                            <app-input-search [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [dataSource]="field.FIELD_FILTER" [formControlName]="field.FIELD_NAME"
                                [isReadOnly]="field.FIELD_READ_ONLY" [required]="field.FIELD_REQUIRED"
                                [disabledCtrl]="field.FIELD_DISABLED"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')"
                                (returnObj)="onReturnObjValue($event, field)"
                                [placeholder]="field.FIELD_PLACEHOLDER"></app-input-search>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [formControlName]="field.FIELD_NAME"
                                [disabledCtrl]="field.FIELD_DISABLED" [maxlength]="field.FIELD_LENGTH"
                                [vFormat]="field.FIELD_VFORMAT" [required]="field.FIELD_REQUIRED"
                                [isReadOnly]="field.FIELD_READ_ONLY" [placeholder]="field.FIELD_PLACEHOLDER"
                                [notUseTranslate]="true">
                            </app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'textarea'">
                            <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [isReadOnly]="field.FIELD_READ_ONLY" [formControlName]="field.FIELD_NAME"
                                [disabledCtrl]="field.FIELD_DISABLED" [maxlength]="field.FIELD_LENGTH"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                [vFormat]="field.FIELD_VFORMAT" (btnCalcClick)="onClickBtn(field, 'input')"
                                [required]="field.FIELD_REQUIRED" [placeholder]="field.FIELD_PLACEHOLDER"
                                type="text-area" [notUseTranslate]="true">
                            </app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text-button'">
                            <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [isReadOnly]="field.FIELD_READ_ONLY" [formControlName]="field.FIELD_NAME"
                                [hasButton]="false" (iconClick) = "onClickTextButton(field)"
                                (btnCalcClick)="onClickBtn(field, 'input')" [disabledCtrl]="field.FIELD_DISABLED"
                                [placeholder]="field.FIELD_PLACEHOLDER" [required]="field.FIELD_REQUIRED"
                                [maxlength]="field.FIELD_LENGTH" type="text-button" typeIcon="ellipsis-h"
                                [notUseTranslate]="true">
                            </app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'weblink'">
                            <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [isOpenLink]="true"
                                [isReadOnly]="field.FIELD_READ_ONLY" [formControlName]="field.FIELD_NAME"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                [pattern]="'[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?'"
                                (btnCalcClick)="onClickBtn(field, 'input')" [disabledCtrl]="field.FIELD_DISABLED"
                                [placeholder]="field.FIELD_PLACEHOLDER" [required]="field.FIELD_REQUIRED"
                                (iconClick)="onOpenLink(field)" [maxlength]="field.FIELD_LENGTH" type="text-button"
                                typeIcon="arrow-right" [notUseTranslate]="true">
                            </app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'number'">
                            <app-input [type]="'number'" [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [required]="field.FIELD_REQUIRED" [formControlName]="field.FIELD_NAME"
                                [disabledCtrl]="field.FIELD_DISABLED" [maxlength]="field.FIELD_LENGTH"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [isReadOnly]="field.FIELD_READ_ONLY"
                                [placeholder]="field.FIELD_PLACEHOLDER" [vFormat]="field.FIELD_VFORMAT"
                                [notUseTranslate]="true">
                            </app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                            <app-core-datetime [formControlName]="field.FIELD_NAME"
                                [placeholder]="field.FIELD_PLACEHOLDER" [labelName]="field.FIELD_LABEL"
                                [required]="field.FIELD_REQUIRED" [disabledCtrl]="field.FIELD_DISABLED"
                                [isReadOnly]="field.FIELD_READ_ONLY"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')"
                                [calConfig]="{ showTime: false, yearNavigator: true, monthNavigator: true, yearRange: '1900:2099' }"></app-core-datetime>
                        </ng-container>
                        <ng-container *ngSwitchCase="'datetime'">
                            <app-core-datetime [formControlName]="field.FIELD_NAME"
                                [placeholder]="field.FIELD_PLACEHOLDER" [labelName]="field.FIELD_LABEL"
                                [required]="field.FIELD_REQUIRED" [disabledCtrl]="field.FIELD_DISABLED"
                                [isReadOnly]="field.FIELD_READ_ONLY"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')"
                                [calConfig]="{ showTime: true, yearNavigator: true, monthNavigator: true, yearRange: '1900:2099' }"></app-core-datetime>
                        </ng-container>
                        <ng-container *ngSwitchCase="'select'">
                            <app-select [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [formControlName]="field.FIELD_NAME" [isReadOnly]="field.FIELD_READ_ONLY"
                                [dataLookup]="lookupCondition[field.FIELD_TABLELINK]" displayField="DESCR"
                                valueField="CODE" [disabledCtrl]="field.FIELD_DISABLED"
                                (returnObj)="onReturnObjValue($event, field)"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [required]="field.FIELD_REQUIRED"
                                [placeholder]="field.FIELD_PLACEHOLDER" [dataSource]="field.FIELD_FILTER"
                                [notUseTranslate]="true">
                            </app-select>
                        </ng-container>
                        <ng-container *ngSwitchCase="'treeselect'">
                            <app-core-tree-select [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [isReadOnly]="field.FIELD_READ_ONLY" [formControlName]="field.FIELD_NAME"
                                [disabledCtrl]="field.FIELD_DISABLED" [required]="field.FIELD_REQUIRED"
                                [placeholder]="field.FIELD_PLACEHOLDER" (returnObj)="onReturnObjValue($event, field)"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')"
                                [dataLookup]="field.FIELD_ISDOMAIN ? {data: lookupCondition[field.FIELD_TABLELINK], dataSource: field} : null"
                                [dataSource]="field.FIELD_ISDOMAIN ? null : field.FIELD_FILTER">
                            </app-core-tree-select>
                        </ng-container>
                        <ng-container *ngSwitchCase="'tree'">
                            <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [dataSource]="field.FIELD_FILTER" [formControlName]="field.FIELD_NAME"
                                [disabledCtrl]="field.FIELD_DISABLED" [isReadOnly]="field.FIELD_READ_ONLY"
                                [placeholder]="field.FIELD_PLACEHOLDER" [required]="field.FIELD_REQUIRED"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [maxlength]="field.FIELD_LENGTH"
                                type="input-tree" [notUseTranslate]="true">
                            </app-input>
                        </ng-container>
                        <ng-container *ngSwitchCase="'array'">
                            <app-core-whereclause mode='array' [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [titleDialog]='field.FIELD_LABEL' [formControlName]="field.FIELD_NAME"
                                [maxLength]="field.FIELD_LENGTH" [isDisabledCtrl]="field.FIELD_DISABLED"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [isReadOnly]="field.FIELD_READ_ONLY"
                                [required]="field.FIELD_REQUIRED" [isTypeArray]="true"></app-core-whereclause>
                        </ng-container>
                        <ng-container *ngSwitchCase="'json'">
                            <app-core-whereclause mode='json' [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [titleDialog]='field.FIELD_LABEL' [formControlName]="field.FIELD_NAME"
                                [maxLength]="field.FIELD_LENGTH" [isDisabledCtrl]="field.FIELD_DISABLED"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [isReadOnly]="field.FIELD_READ_ONLY"
                                [required]="field.FIELD_REQUIRED" [isTypeArray]="true"></app-core-whereclause>
                        </ng-container>
                        <ng-container *ngSwitchCase="'location'">
                            <app-core-whereclause mode='location' [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [titleDialog]='field.FIELD_LABEL' [formControlName]="field.FIELD_NAME"
                                [maxLength]="field.FIELD_LENGTH" [isDisabledCtrl]="field.FIELD_DISABLED"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'location')" [isReadOnly]="field.FIELD_READ_ONLY"
                                [required]="field.FIELD_REQUIRED" [isTypeArray]="false"></app-core-whereclause>
                        </ng-container>
                        <ng-container *ngSwitchCase="'checkbox'">
                            <app-core-checkbox [chkLabel]="field.FIELD_LABEL" [hasFormCtrl]="true" [isCoreForm]="true"
                                [isReadOnly]="field.FIELD_READ_ONLY" [formControlName]="field.FIELD_NAME"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [required]="field.FIELD_REQUIRED"
                                [isDisabled]="field.FIELD_DISABLED" [typeValue]="field.FIELD_CHB_TYPE">
                            </app-core-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'tricheckbox'">
                            <app-core-state-checkbox [chkLabel]="field.FIELD_LABEL" [hasFormCtrl]="true"
                                [isCoreForm]="true" [isReadOnly]="field.FIELD_READ_ONLY"
                                [formControlName]="field.FIELD_NAME" [required]="field.FIELD_REQUIRED"
                                [isDisabled]="field.FIELD_DISABLED"
                                [hasButton]="field.FIELD_FILTER.FOREIGNWINDOW_ID ? true : false"
                                (btnCalcClick)="onClickBtn(field, 'input')" [typeValue]="field.FIELD_CHB_TYPE">
                            </app-core-state-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'qrcode'">
                            <app-core-qr-code [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                [notUseTranslate]="true" [formControlName]="field.FIELD_NAME"></app-core-qr-code>
                        </ng-container>
                        <ng-container *ngSwitchCase="'image'">
                            <div class="div-titlefield-class">{{field.FIELD_LABEL}}<span
                                    style="color: var(--color_required);" *ngIf='field.FIELD_REQUIRED'>*</span></div>
                            <div [ngStyle]="{'width': field.DISPLAY_LENGTH ? field.DISPLAY_LENGTH + 'px' : '200px',
                            'height': field.FIELD_LENGTH ? field.FIELD_LENGTH + 'px' : 'auto', 'cursor': !field.FIELD_DISABLED ? 'pointer' : 'auto' }"
                                class="div-fieldimg-class" (click)="onUploadFile(field)">
                                <input [formControlName]="field.FIELD_NAME" hidden>
                                <!-- <p-image [src]="'assets/imgs/IconDefaultShortcut.png'" alt="Image"
                                    [preview]="!field.FIELD_DISABLED ? false : true" [width]="'100%'" height="100%">
                                </p-image> -->
                                <p-image
                                    [src]="checkFieldImage(field) ? formGroup.value[field.FIELD_NAME] : 'assets/imgs/table_tools/Upload_file.png'"
                                    alt="Image"
                                    [preview]="!checkFieldImage(field) || !field.FIELD_DISABLED ? false : true"
                                    [width]="field.DISPLAY_LENGTH ? field.DISPLAY_LENGTH : '200px'"
                                    [height]="field.FIELD_LENGTH ? field.FIELD_LENGTH : 'auto'">

                                </p-image>
                                <div class="div-icondelete-class"
                                    *ngIf="checkFieldImage(field) && !field.FIELD_DISABLED"
                                    (click)="$event.stopPropagation(); onDeleteImg(field)">
                                    <i class="pi pi-times" style="font-size: var(--font_size_medium);"></i>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'button'">
                            <div style="height: 100%; width: 100%; display: flex; align-items: center;">
                                <app-button [labelName]='field.FIELD_LABEL' typeButton="save" icon='pi pi-book'
                                    [disabledCtrl]="field.FIELD_DISABLED" (clickEvent)="onClickBtn(field, 'button')">
                                </app-button>
                            </div>

                        </ng-container>
                        <ng-container *ngSwitchCase="'html'">
                            <div style="height: 100%; width: 100%; display: flex; align-items: center;">
                                <!-- <app-button [labelName]='field.FIELD_LABEL' typeButton="save" icon='pi pi-book'
                                    [disabledCtrl]="field.FIELD_DISABLED" (clickEvent)="onClickBtn(field, 'button')">
                                </app-button> -->
                                <span [innerHTML]="field.FIELD_LABEL"></span>
                            </div>

                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div style="height: 0px;"></div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-template>
    </form>
</ng-container>
<ng-container *ngIf="hasAttachment">
    <div style="padding: 1rem;">
        <div class="fluid grid formgrid">
            <div class="field col-12 md-12">
                <app-attachments #attachment [mode]="modeAttachment" [data]="attachmentData"
                    (save)="onAttachmentSave()">
                </app-attachments>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isShowButton">
    <div class="fluid grid formgrid btn-footer" style="display: flex; justify-content: flex-end;">
        <app-button [labelName]="'Cancel' | translate" [typeButton]="'cancel'" style="margin-top: 10px;"
            (clickEvent)="btnCancelClick()"></app-button>
        <ng-container *ngIf="mode === 'add'">
            <app-button [labelName]="'Reset' | translate" [typeButton]="'reset'" style="margin-left: 10px; margin-top: 10px;"
                (clickEvent)="btnResetClick()"></app-button>
        </ng-container>
        <app-button [labelName]="okTitle | translate" style="margin-left: 10px; margin-top: 10px; margin-bottom: 10px;"
            (clickEvent)="btnOkClick()"></app-button>
    </div>
</ng-container>
<input #inputFile type="file" accept="image/*" hidden>
<app-dialog-prime #dialogComponent [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>