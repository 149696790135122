<div class="card">
    <form [formGroup]="formGroupLogin" class="fluid grid formgrid pt-4">
        <div class="field col-6 md-6">
            <app-input [labelName]="'Url Portal' | translate " [required]="false" formControlName="urlPortal"
                [maxlength]="1000" [isLabelLeft]="false">
            </app-input>
        </div>
        <div class="field col-6 md-6 grid" style="padding: 0px">
            <div class="field col-6 md-6">
                <app-input [labelName]="'Username Portal' | translate " [required]="false"
                    formControlName="userPortalLogin" [maxlength]="1000" [isLabelLeft]="false">
                </app-input>
            </div>
            <div class="field col-6 md-6">
                <app-input [labelName]="'Password Portal' | translate " [required]="false"
                    [type]="passwordVisible ? 'text' : 'password'" formControlName="passWordPortalLogin"
                    [maxlength]="1000" [isLabelLeft]="false">
                </app-input>
            </div>
        </div>
    </form>
    <div class="divBtnClass" style="padding: 0px;">
        <app-button [labelName]="'Reset' | translate " (click)="resetLogin()" style="margin-left:5px;"
            [typeButton]="'reset'">
        </app-button>
        <app-button [labelName]="'Login' | translate " style="margin-left:5px;" (click)="loginPortal()"
            [typeButton]="'save'">
        </app-button>

    </div>
</div>
<div class="card">
    

    <!-- <app-core-basic-table [dataTable]="lstServicesChose" displayField='key' [tableConfig]="tableConfig" [hasTool]="false"
        [columnTable]="arrColumn"  [selectionRowMode]="'single'">
    </app-core-basic-table> -->
    <p-table [value]="lstServices" [totalRecords]='total' [showCurrentPageReport]="true"  (onLazyLoad)="onLoadService($event)" [lazy]="true" [columns]="arrColumn" [paginator]="true" [rows]='10' selectionMode="single"
        [(selection)]="currentSelection" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" >
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of arrColumn">
                    {{col.title}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [pSelectableRow]="data">
                <td *ngFor="let col of arrColumn">
                    <!-- <ng-container *ngIf="col.key === 'name'">
                        <div style="display: flex; align-items: center; height: 100%; width: 100%;">
                            <div class="div-img-type" style="background-image: url({{data.img}});"></div>
                            <div style="padding-left: 0.5rem;">{{data[col.key]}}</div>
                            
                        </div>
                        
                    </ng-container> -->
                    <ng-container >
                        {{data[col.key]}}
                    </ng-container>

                </td>
            </tr>
        </ng-template>
    </p-table>
    
    <app-button [labelName]="'OK' | translate " style="margin-left:5px; margin-top: 10px;" (click)="getUrlMap()"
        [typeButton]="'save'">
    </app-button>
</div>
