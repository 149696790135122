import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    private active: any = null;

    constructor(
        private http: HttpClient,
        public sanitizer: DomSanitizer
    ) { }

    async setActiveTheme(themeContent: any): Promise<any> {
        await this.removeStyleCore();
        if (themeContent !== null) {
            await this.setStyleCore(themeContent['--layout'])
            Object.keys(themeContent).forEach(property => {
                if (property !== '--layout') {
                    document.documentElement.style.setProperty(
                        property,
                        themeContent[property]
                    );
                }
            });
        } else {
            // themeContent = { "--color_bg_menu": "#e8e8e8", "--color_text_menu": "#2e3440", "--color_menu_selected": "#5e81ac", "--color_bg_sub_menu": "#E4E9F4", "--color_shortcut_bg": "#eceff4", "--color_shortcut_text": "#333333", "--color_shortcut_bg_dropmenu": "#ffffff", "--color_shortcut_selected": "#5e81ac", "--color_header_bg_left": "#E4E9F4", "--color_header_bg_right": "#eceff4", "--color_bg": "#eceff4", "--color_bg_panel": "#ffffff", "--color_text": "#333333", "--color_core": "#5E81AC", "--color_core_header": "#E5E9F0", "--color_icon_core": "#ffffff", "--color_core_required": "#f44336", "--color_core_tab_highlight": "#ffffff", "--color_core_tab_highlight_text": "#333333", "--color_border": "#bdbdbd", "--color_core_placeholder": "#BDBDBD", "--color_header_text": "#333333", "--color_window_bar_bg": "#d8dee9", "--color_window_bar_text": "#333333", "--color_window_bar_selected": "#5e81ac", "--color_menu_text_highlight": "#ffffff", "--font_family": "Roboto", "--font_size_small": "12px", "--font_size_medium": "14px", "--font_size_big": "16px", "--layout": "Mirage" }
            // await this.setStyleCore(themeContent['--layout'])
            // Object.keys(themeContent).forEach(property => {
            //     if (property !== '--layout') {
            //         document.documentElement.style.setProperty(
            //             property,
            //             themeContent[property]
            //         );
            //     }
            // });
            this.http.get('assets/themes/default.json').subscribe((res: any) => {
                this.active = res;
                this.setStyleCore(res.properties['--layout'])

                Object.keys(res.properties).forEach(property => {
                    if (property !== '--layout') {
                        document.documentElement.style.setProperty(
                            property,
                            res.properties[property]
                        );
                    }

                });

            });
        }

    }


    async removeStyleCore() {
        // if(id === null)
        // if (id !== 'PrimeNG' && id !== null) {
        //     let class_name = id === 'ThaiStyle' ? 'div-thailanlayout-class' : 'div-mirage-class'
        //     document.documentElement.classList.remove(class_name)
        // }

        document.documentElement.classList.remove('div-thailanlayout-class')
        document.documentElement.classList.remove('div-mirage-class')

    }

    async setStyleCore(id: any) {
        if (id !== 'PrimeNG') {
            let class_name = id === 'ThaiStyle' ? 'div-thailanlayout-class' : 'div-mirage-class'
            document.documentElement.classList.add(class_name)
        }
    }

    async setActiveThemeUM(themeId: any): Promise<any> {
        // Khi lỗi mặc định chọn default theme
        // this.active = themeName['defaultThemeUM'];
        // Object.keys(this.active.properties).forEach(property => {
        //     document.documentElement.style.setProperty(
        //         property,
        //         this.active.properties[property]
        //     );
        // });
    }
}
