<div class="search-container" [ngStyle]="{'top': type === 'searchmodel' ? '110px' : '0px'}">
    <ng-container *ngIf="isLoadedForm">
        <div [formGroup]="formData" class="fluid grid formgrid m-0">
            <ng-container *ngFor="let field of fieldList">
                <ng-container *ngIf="field.FIELD_ISSEARCH">
                    <ng-container *ngIf="field.FIELD_ISUNIQUE">
                        <!-- Dùng để ngắt dòng -->
                        <div style="width: 100%"></div>
                    </ng-container>
                    <div class="col-md-{{field.FIELD_SPAN}} md-4">
                        <ng-container [ngSwitch]="field.FIELD_TYPE">
                            <ng-container *ngSwitchCase="'text'">
                                <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [formControlName]="field.FIELD_NAME" (enterEvent)="onEnterEvent($event, field)">
                                </app-input>
                            </ng-container>
                            <ng-container *ngSwitchCase="'number'">
                                <app-input [type]="'number'" [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [vFormat]="field.FIELD_VFORMAT"
                                    [formControlName]="field.FIELD_NAME" (enterEvent)="onEnterEvent($event, field)">
                                </app-input>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <app-core-datetime [formControlName]="field.FIELD_NAME" [labelName]="field.FIELD_LABEL"
                                    [appendTo]="'body'" [calConfig]="calConfig"></app-core-datetime>
                            </ng-container>
                            <ng-container *ngSwitchCase="'datetime'">
                                <app-core-datetime [formControlName]="field.FIELD_NAME" [labelName]="field.FIELD_LABEL"
                                    [appendTo]="'body'" [calConfig]="calConfig"></app-core-datetime>
                            </ng-container>
                            <ng-container *ngSwitchCase="'select'">
                                <app-select [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [formControlName]="field.FIELD_NAME"
                                    [dataLookup]="lookupCondition[field.FIELD_TABLELINK]"
                                    [dataSource]="field.FIELD_ISDOMAIN ? null : field.FIELD_FILTER" displayField="DESCR"
                                    valueField="CODE" (returnObj)="onReturnObjValue($event, field)"></app-select>
                            </ng-container>
                            <ng-container *ngSwitchCase="'treeselect'">
                                <app-core-tree-select [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [formControlName]="field.FIELD_NAME" [placeholder]="field.FIELD_PLACEHOLDER"
                                    [dataSource]="field.FIELD_ISDOMAIN ? null : field.FIELD_FILTER">
                                </app-core-tree-select>
                            </ng-container>
                            <ng-container *ngSwitchCase="'tree'">
                                <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [dataSource]="field"
                                    [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED"
                                    [placeholder]="field.FIELD_PLACEHOLDER" [required]="field.FIELD_REQUIRED"
                                    [maxlength]="field.FIELD_LENGTH" type="input-tree">
                                </app-input>
                            </ng-container>
                            <ng-container *ngSwitchCase="'textarea'">
                                <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [formControlName]="field.FIELD_NAME" type="text-area">
                                </app-input>
                            </ng-container>
                            <ng-container *ngSwitchCase="'text-button'">
                                <app-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [formControlName]="field.FIELD_NAME" type="text-button" typeIcon="search">
                                </app-input>
                            </ng-container>
                            <ng-container *ngSwitchCase="'search'">
                                <app-input-search [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [dataSource]="field.FIELD_FILTER" [formControlName]="field.FIELD_NAME"
                                    [required]="field.FIELD_REQUIRED" [disabledCtrl]="field.FIELD_DISABLED"
                                    [placeholder]="field.FIELD_PLACEHOLDER" (returnObj)="onReturnObjValue($event, field)"></app-input-search>
                                <!-- <app-fillter-input [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [dataSource]="field.FIELD_FILTER" [formControlName]="field.FIELD_NAME">
                                </app-fillter-input> -->
                            </ng-container>
                            <ng-container *ngSwitchCase="'checkbox'">
                                <app-core-checkbox [chkLabel]="field.FIELD_LABEL" [hasFormCtrl]="true"
                                    [isLabelLeft]="false" [formControlName]="field.FIELD_NAME"
                                    [typeValue]="field.FIELD_CHB_TYPE">
                                </app-core-checkbox>
                            </ng-container>
                            <ng-container *ngSwitchCase="'array'">
                                <app-core-whereclause mode='array' [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [titleDialog]='field.FIELD_LABEL' [formControlName]="field.FIELD_NAME"
                                    [maxLength]="field.FIELD_LENGTH" [isDisabledCtrl]="field.FIELD_DISABLED"
                                    [required]="field.FIELD_REQUIRED" [isTypeArray]="true"></app-core-whereclause>
                            </ng-container>
                            <ng-container *ngSwitchCase="'json'">
                                <app-core-whereclause mode='json' [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                    [titleDialog]='field.FIELD_LABEL' [formControlName]="field.FIELD_NAME"
                                    [maxLength]="field.FIELD_LENGTH" [isDisabledCtrl]="field.FIELD_DISABLED"
                                    [required]="field.FIELD_REQUIRED" [isTypeArray]="true"></app-core-whereclause>
                            </ng-container>
                            <ng-container *ngSwitchCase="'tricheckbox'">
                                <app-core-state-checkbox [chkLabel]="field.FIELD_LABEL" [hasFormCtrl]="true"
                                    [formControlName]="field.FIELD_NAME" [typeValue]="field.FIELD_CHB_TYPE">
                                </app-core-state-checkbox>
                                <!-- <app-core-state-checkbox [hasFormCtrl]="true" chkLabel="test state" formControlName='CHK_STATE'
                                [typeValue]="'string'"></app-core-state-checkbox> -->
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <!-- <div>{{field.FIELD_TYPE}} : {{field.FIELD_LABEL}}</div> -->
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>

<div class="fluid grid formgrid btn-footer" style="display: flex; justify-content: space-between">
    <div>
        <ng-container *ngIf="showImportBtn">
            <app-button [labelName]="'Accept' | translate" (clickEvent)="onAcceptRecord()">
            </app-button>
        </ng-container>
    </div>

    <div>
        <app-button [labelName]="'Cancel' | translate" [typeButton]="'cancel'" (clickEvent)="onCancel()"></app-button>
        <app-button [labelName]="'Reset' | translate" [typeButton]="'reset'" style="margin-left: 10px"
            (clickEvent)="onReset()">
        </app-button>
        <app-button [labelName]="'Search' | translate" style="margin-left: 10px" (clickEvent)="onSearch()"></app-button>
    </div>
</div>