<app-loading [class.hidden]="!isLoading || isWizard"></app-loading>

<ng-container *ngIf="dataSource.length > 0 && isActive; else noData">
    <div [ngStyle]="{'padding': isWizard ? '10px' : 'unset'}">
        <ng-container *ngTemplateOutlet="viewContent; context: {$implicit: dataSource}"></ng-container>
    </div>
    <ng-template #viewContent let-tabList>
        <ng-container *ngIf="tabList.length > 0">
            <app-core-tab [tabs]="tabList" [title]="titleRef" [content]="contentRef">
            </app-core-tab>
            <ng-template #titleRef let-tab>
                <div (click)="clickTab(tab, $event)" class="tab-title">
                    <span [ngClass]="{'text-decoration-under': tabId === tab['INFORMATION']['TAB_ID'] && (isWizard || isFilter)}" style="padding-right: 5px;"> {{ tab.INFORMATION.DESCR || ''}}</span>

                    <ng-container *ngIf="tab.HIDE">
                        <i class="pi pi-caret-right" (click)="showHideTab(tab)"></i>
                    </ng-container>
                    <ng-container *ngIf="!tab.HIDE">
                        <i class="pi pi-caret-down" (click)="showHideTab(tab)"></i>
                    </ng-container>
                </div>
            </ng-template>
            <ng-template #contentRef let-tab>
                <div *ngIf="!isWizard" [ngClass]="{'d-none': tab.HIDE}" class="template-form-bounder">
                    <ng-container *ngTemplateOutlet="templateForm; context: {$implicit: tab}">
                    </ng-container>
                </div>
                <div *ngIf="isWizard" [ngClass]="{'d-none': tab.HIDE}" class="template-form-bounder" style="height: 210px;
                    background: #f5f5f5; border: 1px dashed #dee2e6;">
                </div>
                <ng-container *ngFor="let itemtab of tab.TABS">
                    <div class="tab-set-custom" [ngStyle]="{'padding-top': isWizard ? '2%' : 'unset'}">
                        <ng-container *ngTemplateOutlet="viewContent; context: {$implicit: itemtab}">
                        </ng-container>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-template #templateForm let-tab>
            <app-core-table #dynamic [dataSource]="tab" [where]="tab.WHERE" [isMapApp]="isMapApp" [isLayout]="isLayout" [isParentLock] = "tab.isParentLock"
                [isFilter]="isFilter" [isReorder]="isReorder" [arrField]="arrField" (evtReOrder)="onColReoreder($event)"
                (evtColResize)="onColResize($event)" (eventEmit)="onEventEmit($event, tab)" [parent] = "this"
                (eventAddCalendar)="onEventAddCalendar($event)" (evtColConf)="onEvtColConf($event)"></app-core-table>
        </ng-template>
    </ng-template>
</ng-container>

<ng-template #noData>
    <div class="d-flex align-items-center justify-content-center">
        No data
    </div>
</ng-template>