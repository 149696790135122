<ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'view'">
        <ng-container *ngTemplateOutlet="viewTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'edit'">
        <ng-container *ngTemplateOutlet="editTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'add'">
        <ng-container *ngTemplateOutlet="editTemplate"></ng-container>
    </ng-container>
</ng-container>

<ng-template #viewTemplate>
    <p-accordion>
        <p-accordionTab [header]="'List of attachments' | translate" [selected]="listAttachment.length > 0">
            <div class="mt-3" style="display: flex; overflow: auto;">
                <div class="d-flex mr-3" *ngFor='let item of listAttachment; let i = index'>
                    <div class="attachment-bounder">
                        <div class="attachment-item" [ngStyle]="{'background-image': 'url(' + item.url + ')'}"
                            (click)="onOpenFile(item)"></div>
                        <div class="attachment-desc">{{item.descr}}</div>
                    </div>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>
   
</ng-template>

<ng-template #editTemplate>
    <span>{{ 'File Upload' | translate }} </span>
    <div class="upload-container ">
        <div class="no-file-upload" (click)="upload.click()">
            <div class="icn-upload-file"></div>
            <input #upload hidden type="file" (change)="readURL($event)" />
        </div>

        <div class="list-file-upload" *ngFor='let item of listAttachment.slice().reverse(); let i = index'>
            <div class="attachment-bounder">
                <div class="attachment-item" [ngStyle]="{'background-image': 'url(' + item.url + ')'}"
                    (click)="onOpenFile(item)"></div>
                <div class="attachment-desc">{{item.descr}}</div>

                <ng-container *ngIf="mode === 'edit' || mode === 'add'">
                    <div (click)="removeAttachment(item)" class="attachment-delete">
                        <i class="pi pi-times" style="font-size: 20px;"></i>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    
</ng-template>