<div class="run-bpmn-class">
    <div class="infomation">
        <div class="content-infomation">
            <div class="lab-info">{{'Job' |  translate}}</div>
            <div class="btn-job">

            </div>
        </div>
    </div>
    <p-tabView (onChange) = "tapChange($event)">
        <p-tabPanel header="{{'Detail' | translate }}">
            <div style="height: 100%; width: 100%;">
                <app-core-window #coreWindow [isMapApp]="true" [windowId]="windowId" [whereWf]="whereWf"  (createDynamicEvent)="onCoreWindowEmitEvent($event)"
                    [isWfApp]="true"></app-core-window>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{'Job' | translate }}">
            <div class="wp-job">
                <div class="wp-form" class="col-3"  style="height: 600px; overflow: auto;">
                    <div [formGroup]="formData" >
                        <ng-container *ngFor="let field of fieldList">
                            <ng-container *ngIf="field.FIELD_SHOW == 'Y'">
                                <ng-container [ngSwitch]="field.FIELD_TYPE">
                                    <ng-container *ngSwitchCase="'text'">
                                        <app-input  [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"
                                        [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED"></app-input>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'number'">
                                        <app-input [type]="'number'" [labelName]="field.FIELD_LABEL"
                                        [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" [disabledCtrl]="field.FIELD_DISABLED">
                                    </app-input>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'date'">
                                        <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [disabledCtrl]="field.FIELD_DISABLED"
                                        [formControlName]="field.FIELD_NAME"  [calConfig]="{ showTime: false }" ></app-core-datetime>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'datetime'">
                                        <app-core-datetime [labelName]="field.FIELD_LABEL" [isLabelLeft]="false" [disabledCtrl]="field.FIELD_DISABLED"
                                        [formControlName]="field.FIELD_NAME"  [calConfig]="{ showTime: true }" ></app-core-datetime>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'select'">
                                        <app-select  [labelName]="field.FIELD_LABEL"
                                        [isLabelLeft]="false" [formControlName]="field.FIELD_NAME"
                                        [dataLookup]="lookupCondition[field.FIELD_NAME]" displayField="DESCR" [disabledCtrl]="field.FIELD_DISABLED"
                                        valueField="CODE">
                                        </app-select>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'text-button'">
                                        <app-input  [required]="false" [labelName]="field.FIELD_LABEL" [isLabelLeft]="false"  [formControlName]="field.FIELD_NAME" type="text-button"></app-input>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'T'">
                                        <app-input [type]="'text-area'" [labelName]="field.FIELD_LABEL" [disabledCtrl]="field.FIELD_DISABLED"
                                        [isLabelLeft]="false" [formControlName]="field.FIELD_NAME" ></app-input>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <div>{{field.FIELD_TYPE}} : {{field.FIELD_LABEL}}</div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                    <!-- <div class="btn-save">
                        <app-button [labelName]="'Save' | translate" [ngClass]="{'hiddenImage': isShowIma === false, 'show-image': isShowIma === true }"  typeButton="save" icon='pi pi-save' style="margin-left: 10px;"
                        (click)="isShowIma?onClickSave(true, false):''"></app-button>
                    </div> -->
                </div>
                <div class="wp-workflow col-9">
                    <div class="wp-diagram">
                        <div class="canvas">
                            <div #divDiagram class="diagram"></div>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{'History' | translate }}">
            <div class="history">
                <app-core-basic-table [dataTable]="tableDataHistory" displayField='key' [tableConfig]="tableConfig" [hasTool]="false"
                [toolConfig]="toolTableConfig" [columnTable]="tableRowDefination1"></app-core-basic-table>
            </div>
        </p-tabPanel>
    </p-tabView>

    <app-dialog-prime #dialogPrime [isAppendBody]="true"></app-dialog-prime>
    <app-dialog-prime #dialogUser [isAppendBody]="true"></app-dialog-prime>
</div>

