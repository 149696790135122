
export const LISTTRANGTHAI = [
    {id: '1', name: 'Tạo'},
    {id: '2', name: 'Sẵn sàng làm việc'},
    {id: '3', name: 'Đang làm việc'},
    {id: '4', name: 'Xong việc'},
    {id: '5', name: 'Đóng'}
  ];

export const CONFIG_FIELD_HISTORY = {
    sys_table: { SU_CO: { Layout: 2 } },
    sys_column: {
    1: {
        ColumnID:  1,
        ColumnName: 'type',
        ColumnAlias:  'Hoạt động',
        ColumnType:  'C',
        ColumnIndex:  1,
        IsNull:  false,
        IsPryKey:  true,
        IsSearch:  null,
        isReadField:  true,
    }, 2: {
        ColumnID:  2,
        ColumnName:  'stepName',
        ColumnAlias:  'Bước',
        ColumnType:  'T',
        ColumnIndex:  2,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 3: {
        ColumnID:  3,
        ColumnName:  'sendFrom',
        ColumnAlias:  'Người gửi',
        ColumnType:  'T',
        ColumnIndex:  3,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 4: {
        ColumnID:  4,
        ColumnName:  'assignedTo',
        ColumnAlias:  'Người nhận',
        ColumnType:  'T',
        ColumnIndex:  4,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 5: {
        ColumnID:  5,
        ColumnName:  'date',
        ColumnAlias:  'Thời gian',
        ColumnType:  'D',
        ColumnIndex:  5,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 6: {
        ColumnID:  6,
        ColumnName:  'message',
        ColumnAlias:  'Tin nhắn',
        ColumnType:  'T',
        ColumnIndex:  6,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }
}
};

export const CONFIG_FIELD = {
  sys_table: { SU_CO: { Layout: 2 } },
  sys_column: {
    1: {
        ColumnID: 1,
        ColumnName: 'objectid',
        ColumnAlias: 'objectid',
        ColumnType: 'N',
        ColumnIndex: 1,
        IsNull: false,
        IsPryKey: true,
        IsSearch: null,
        isReadField: false,
    }, 2: {
        ColumnID: 2,
        ColumnName: 'name',
        ColumnAlias: 'Tên',
        ColumnType: 'T',
        ColumnIndex: 2,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 3: {
        ColumnID: 3,
        ColumnName: 'jobTypeId',
        ColumnAlias: 'Loại quy trình',
        ColumnType: 'C',
        ColumnIndex: 3,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 4: {
        ColumnID: 4,
        ColumnName: 'assignedTo',
        ColumnAlias: 'Giao cho',
        ColumnType: 'C',
        ColumnIndex: 4,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    // }, 5: {
    //     ColumnID: 5,
    //     ColumnName: 'assignedType',
    //     ColumnAlias: 'Loại giao việc',
    //     ColumnType: 'N',
    //     ColumnIndex: 5,
    //     IsNull: true,
    //     IsPryKey: false,
    //     isReadField: false,
    //     IsSearch: false
    }, 6: {
        ColumnID: 6,
        ColumnName: 'stage',
        ColumnAlias: 'Trạng thái',
        ColumnType: 'C',
        ColumnIndex: 6,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 7: {
        ColumnID: 7,
        ColumnName: 'description',
        ColumnAlias: 'Mô tả',
        ColumnType: 'T',
        ColumnIndex: 7,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
  }, 8: {
        ColumnID: 8,
        ColumnName: 'percentageComplete',
        ColumnAlias: '% Hoàn thành',
        ColumnType: 'N',
        ColumnIndex: 8,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 11: {
        ColumnID: 11,
        ColumnName: 'startDate',
        ColumnAlias: 'Ngày bắt đầu',
        ColumnType: 'D',
        ColumnIndex: 10,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
  }, 12: {
        ColumnID: 12,
        ColumnName: 'dueDate',
        ColumnAlias: 'Ngày hạn',
        ColumnType: 'D',
        ColumnIndex: 11,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }
}
};

export const CONFIG_FIELD_BPMN = {
      1: {
          ColumnID: 1,
          ColumnName: 'JobId',
          ColumnAlias: 'JobId',
          ColumnType: 'N',
          ColumnIndex: 1,
          IsNull: false,
          IsPryKey: true,
          IsSearch: null,
          isReadField: false,
      }, 2: {
          ColumnID: 2,
          ColumnName: 'JobName',
          ColumnAlias: 'JobName',
          ColumnType: 'text',
          ColumnIndex: 2,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: true
      }, 3: {
          ColumnID: 3,
          ColumnName: 'Description',
          ColumnAlias: 'Description',
          ColumnType: 'text',
          ColumnIndex: 3,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: false
      }, 4: {
          ColumnID: 4,
          ColumnName: 'AssignedTo',
          ColumnAlias: 'AssignedTo',
          ColumnType: 'text',
          ColumnIndex: 4,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: false
      }, 5: {
          ColumnID: 5,
          ColumnName: 'AssignedType',
          ColumnAlias: 'Loại giao việc',
          ColumnType: 'number',
          ColumnIndex: 5,
          IsNull: true,
          IsPryKey: false,
          isReadField: false,
          IsSearch: false
      }, 6: {
          ColumnID: 6,
          ColumnName: 'ParentJobId',
          ColumnAlias: 'ParentJobId',
          ColumnType: 'number',
          ColumnIndex: 6,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: false
      }, 7: {
          ColumnID: 7,
          ColumnName: 'StartDate',
          ColumnAlias: 'StartDate',
          ColumnType: 'date',
          ColumnIndex: 7,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: false
    }, 8: {
          ColumnID: 8,
          ColumnName: 'DueDate',
          ColumnAlias: 'DueDate',
          ColumnType: 'date',
          ColumnIndex: 8,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: false
      }, 11: {
          ColumnID: 11,
          ColumnName: 'Note',
          ColumnAlias: 'Note',
          ColumnType: 'text',
          ColumnIndex: 10,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: false
    }, 12: {
          ColumnID: 12,
          ColumnName: 'OwnedBy',
          ColumnAlias: 'OwnedBy',
          ColumnType: 'text',
          ColumnIndex: 11,
          IsNull: true,
          IsPryKey: false,
          isReadField: true,
          IsSearch: false
      }, 13: {
        ColumnID: 13,
        ColumnName: 'PercComplete',
        ColumnAlias: 'PercComplete',
        ColumnType: 'number',
        ColumnIndex: 13,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 14: {
        ColumnID: 14,
        ColumnName: 'JobTypeId',
        ColumnAlias: 'JobTypeId',
        ColumnType: 'number',
        ColumnIndex: 14,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 15: {
        ColumnID: 12,
        ColumnName: 'StatusId',
        ColumnAlias: 'StatusId',
        ColumnType: 'number',
        ColumnIndex: 11,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
     }, 16: {
        ColumnID: 16,
        ColumnName: 'WindowId',
        ColumnAlias: 'WindowId',
        ColumnType: 'number',
        ColumnIndex: 11,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 17: {
        ColumnID: 17,
        ColumnName: 'TableId',
        ColumnAlias: 'TableId',
        ColumnType: 'number',
        ColumnIndex: 17,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 18: {
        ColumnID: 18,
        ColumnName: 'RecordId',
        ColumnAlias: 'RecordId',
        ColumnType: 'number',
        ColumnIndex: 11,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }
  };

