import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Calendar } from 'primeng/calendar';

import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-core-datetime',
    templateUrl: './core-datetime.component.html',
    styleUrls: ['./core-datetime.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR
            , useExisting: forwardRef(() => CoreDatetimeComponent)
            , multi: true
        }
    ]
})
export class CoreDatetimeComponent implements OnInit {
    valueDate: any;
    @ViewChild('pCalender') pCalender!: Calendar | any;
    @Input() labelName: any = '';
    @Input() showLabel: boolean = true;
    @Input() labelWidth = '200';
    @Input() controlWidth = 'auto';
    @Input() isLabelLeft: boolean = false;
    @Input() showIcon: boolean = true;
    @Input() placeholder: string = '';
    @Input() appendTo: any = 'body';
    @Input() hasFormCtrl: boolean = true;
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() selectDate: EventEmitter<any> = new EventEmitter();
    @Output() valueModelChange: EventEmitter<any> = new EventEmitter();
    @Output() blurEvent: EventEmitter<any> = new EventEmitter();
    @Input() hasButton: boolean = false
    @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
    isShowCalendar = false;
    ctrl: FormControl = new FormControl();
    requiredClass: boolean = true;
    private onChange: (value: any) => void = () => { };
    private onTouched: () => void = () => { };
    _required: any = false;
    @Input('required')
    set required(val: boolean) {
        this._required = val;
        this.requiredClass = val;
        if (this.ctrl) {
            this.ctrl.setValidators(val === true ? [Validators.required] : [Validators.nullValidator]);
        }
    }
    get required() {
        return this._required;
    }
    private _isReadOnly = false;
    @Input('isReadOnly')
    set isReadOnly(val: boolean) {
        this._isReadOnly = val;
        if (this.ctrl) {
            // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }
    }
    get isReadOnly() {
        return this._isReadOnly;
    }
    _config: any;
    @Input('calConfig')
    set calConfig(config: any) {
        this._config = config
        this.setConfig(config);
        // if(this.modeCalendar === 'single') {
        //     this.setConfig(config);
        // }

    }
    get calConfig() {
        return this._config;
    }
    _isShowTime: any;
    @Input()
    set isShowTime(bool: any) {
        this.cdr.detectChanges();
        this._isShowTime = bool
    }
    get isShowTime() {
        return this._isShowTime;
    }
    _modeCalendar: any = 'single';
    @Input()
    set modeCalendar(mode: any) {
        // this.cdr.detectChanges();
        this._modeCalendar = mode
    }
    get modeCalendar() {
        return this._modeCalendar;
    }

    _disabled = false;
    @Input('disabledCtrl')
    set disabledCtrl(val: boolean) {
        this._disabled = val;
        // if (this.ctrl) {
        //     val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        // }
    }
    get disabledCtrl() {
        return this._disabled;
    }
    _valueModel: any;
    @Input()
    get valueModel() {
        return this._valueModel === "" ? null : this._valueModel;
    }

    set valueModel(value) {
        if (this.required) {
            if (value !== null && value !== '') {
                this.requiredClass = false;
            } else {
                this.requiredClass = true
            }
        } else {
            this.requiredClass = false
        }

        this._valueModel = value;
        this.valueModelChange.emit(this._valueModel);
    }

    c$: any = null;
    constructor(
        private cdr: ChangeDetectorRef
    ) {
        this.initForm();
        this.c$ = window['c$'];
    }

    ngOnInit(): void {
    }

    setConfig(config: any) {
        this.cdr.detectChanges();

        Object.keys(config).forEach((key: any) => {
            this.pCalender[key] = config[key]
        })
    }

    onDateBlur() {
        if (!(this.disabledCtrl || this.isReadOnly)) {
            if (this.pCalender.value === null && this.ctrl.value !== null) {
                this.ctrl.setValue(null);
            }
            this.blurEvent.emit();
        }
    }
    onFocusEle(evt: any) {
        console.log(evt)
    }
    onSelectDate(evt: any) {
        this.requiredClass = false;
        this.selectDate.emit(evt)
    }

    initForm() {
        this.ctrl = new FormControl(null, {
            updateOn: 'change',
            validators: this._required === true ? Validators.required : Validators.nullValidator
        });
        this.isReadOnly ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });

        this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
            this.cdr.detectChanges()
            if (val) {
                if (this.pCalender.selectionMode === 'single') {
                    const date = new Date(val);
                    this.onChange(date.getTime())
                    this.valueChange.emit(date.getTime())
                } else {
                    const arr: any = [];
                    val.forEach((item: any) => {
                        if (item !== null) {
                            const date = new Date(item);
                            arr.push(date.getTime());
                        }
    
                    })
                    this.onChange(arr)
                    this.valueChange.emit(arr)
                }
            } else {
                this.onChange(null);
                this.valueChange.emit(null);
            }
        })
    }

    writeValue(_value: any): void {
        this.cdr.detectChanges();
        if (this.pCalender.selectionMode === 'single') {
            if (_value !== null && _value !== '') {
                const date = new Date(_value);
                if (isNaN(date.getTime())) {
                    this.ctrl.setValue(null, { emitEvent: false });
                } else {
                    this.ctrl.setValue(date, { emitEvent: false });
                }
            } else {
                this.ctrl.setValue(null, { emitEvent: false });
            }
            if (this._required) {
                if (_value === null || _value === '') {
                    this.requiredClass = true;
                } else {
                    this.requiredClass = false;
                }
            } else {
                this.requiredClass = false;
            }
        } else {
            const arr: any = [];
            if (_value && this.checkValueArr(_value)) {
                _value.forEach((item: any) => {
                    if (item !== null) {
                        const date = new Date(item);
                        arr.push(date);
                    }

                })
                this.ctrl.setValue(arr, { emitEvent: false });
            } else {
                this.ctrl.setValue(null, { emitEvent: false });
            }
            if (this._required) {
                if (!_value || !this.checkValueArr(_value)) {
                    this.requiredClass = true;
                } else {
                    this.requiredClass = false;
                }
            } else {
                this.requiredClass = false;
            }
        }



    }


    checkValueArr(arr: any[]) {
        let count = 0;
        arr.forEach((item: any) => {
            if(!item) {
                count++;
            }
        })
        return count === 0 ? true : false
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }
    onIconClick() {
        this.isShowCalendar = !this.isShowCalendar
    }
}
