
import { AppService } from './../app.service';
import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/app-base/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    itemsSigup: MenuItem[] = [];
    mode = 'login';
    validateForm: FormGroup;
    passwordVisible = false;
    loading = false;
    private clockSession = 0;
    private timer!: Subscription;
    private sublogin!: Subscription;
    private subget!: Subscription;
    private globalListenFunc = null;
    private oauth_state = '';
    credential: { CLIENTCODE: string, USERNAME: string; PASSWORD: string } = {
        CLIENTCODE: 'abc',
        USERNAME: 'abc',
        PASSWORD: 'abc',
    };
    urlBgLogin = null;
    urlIcnLogin = null;
    objConfigSystem = null;
    language: any = null;
    checked = false;
    appPages = [
        { title: 'vi', icon: 'assets/um-images/vi-VN.png' },
        { title: 'en', icon: 'assets/um-images/en-EN.png' }
    ];
    arrLang: any[] = []
    selectedValue = 'vi';

    txtUser = 'Username';
    txtClientCode = 'Company Code';
    txtPass = 'Password';

    constructor(
        private authService: AuthService,
        private router: Router,
        public appService: AppService,
        public translate: TranslateService
    ) {
        if (localStorage) {
            this.arrLang = [...this.appService.listLang]
            const arr: any[] = []
            this.arrLang.forEach((item: any) => {
                arr.push(item.CODE)
            })
            translate.addLangs(arr);
            const languague = sessionStorage.getItem('language');
            if (languague) {
                translate.setDefaultLang(languague);
                translate.use(languague);
                this.selectedValue = languague;

                // if (languague === 'vi') {
                //   this.language = this.appPages[0];
                // } else {
                //   this.language = this.appPages[1];
                // }
                const obj = this.arrLang.find((v: any) => v.CODE === languague)
                if (obj) {
                    this.language = obj
                }
            } else {
                translate.setDefaultLang('vi');
                sessionStorage.setItem('language', 'vi');
                this.selectedValue = 'vi';
                // this.language = this.appPages[0];
                const obj = this.arrLang.find((v: any) => v.CODE === 'vi')
                if (obj) {
                    this.language = obj
                }
            }
            console.log(this.language)
        }

        this.validateForm = new FormGroup({
            // USERNAME: new FormControl('005230', [Validators.required]),
            CLIENTCODE: new FormControl('', [Validators.required]),
            USERNAME: new FormControl('', [Validators.required]),
            PASSWORD: new FormControl('', [Validators.required]),
            REMEMBER: new FormControl(false),
            // LinkPortal: new FormControl(false),
        }, { updateOn: 'change' });
    }

    ngOnInit() {
        this.itemsSigup = [
            {
                label: 'Đăng ký dùng thử',
                icon: 'pi pi-sign-in',
                command: () => this.handlerSignup()
            },
            {
                label: 'Bắt đầu sử dụng',
                icon: 'pi pi-check-square',
                command: () => this.handlerSignupUsing()
            }
        ];

        this.authService.getJSON().subscribe(data => {
            this.objConfigSystem = data;
            this.urlBgLogin = data.image;
            this.urlIcnLogin = data.logo;
        });

        setTimeout(() => {
            try {
                const remember = localStorage.getItem(this.appService.hostName1 + 'loginRemember');
                if (remember === 'true') {
                    const obj = localStorage.getItem(this.appService.hostName1 + 'loginInfo');
                    if (obj) {
                        const newObj = JSON.parse(obj);
                        const params = {
                            CLIENTCODE: newObj.CLIENTCODE,
                            USERNAME: newObj.USERNAME,
                            PASSWORD: newObj.PASSWORD,
                            REMEMBER: true
                        };
                        this.validateForm.patchValue(params);
                    } else {
                        this.validateForm.reset();
                    }
                } else {
                    this.validateForm.reset();
                }


                // if (sessionStorage.length > 0 && sessionStorage[this.appService.hostName1 + 'currentUser']) {
                //     const obj = this.appService.convertStringToJson(sessionStorage[this.appService.hostName1 + 'currentUser']);
                //     const params = {
                //         CLIENTCODE: obj.ClientCode,
                //         USERNAME: obj.username,
                //         PASSWORD: obj.password,
                //         REMEMBER: true
                //     };

                //     this.validateForm.patchValue(params);
                //     if (this.authService.currentLoginValue === true) {
                //         // this.checkUSERAD(this.validateForm.value, 'bypass');
                //     }
                // } else {
                //     const params = {
                //         CLIENTCODE: '',
                //         USERNAME: '',
                //         PASSWORD: '',
                //         REMEMBER: false
                //     };
                //     this.validateForm.reset();
                // }

            } catch (error) {
                sessionStorage.clear();
                this.router.navigate(['/login']);
            }
        }, 500);

        this.checkLoggedIn();
    }

    /** Kiểm tra xem đã login hay chưa */
    private checkLoggedIn() {
        const a = sessionStorage.getItem(this.appService.hostName1 + 'currentLogin');
        if (a && JSON.parse(a)) {
            // Đã login => trở về dashboard
            this.router.navigate(['/']);
        }
    }


    switchLang(lang: any, opLanguage: any) {
        if (localStorage) {
            this.translate.setDefaultLang(lang.CODE);
            this.translate.use(lang.CODE);
            sessionStorage.setItem('language', lang.CODE);
            this.language = lang;
            this.selectedValue = lang.CODE;
            opLanguage.hide();
        }
    }

    login() {
        if (this.validateForm.valid) {
            if (this.validateForm.value['REMEMBER']) { // Cần xem xét lại cái này vì chưa thấy tác dụng của nút remember
                this.authService.remember = true;
                localStorage.setItem(this.appService.hostName1 + 'loginRemember', 'true');
                localStorage.setItem(this.appService.hostName1 + 'loginInfo', JSON.stringify(this.validateForm.value));
            } else {
                localStorage.setItem(this.appService.hostName1 + 'loginRemember', 'false');
                this.authService.remember = false;
            }

            this.loading = true;
            this.checkUser(this.validateForm.value, 'login');
        } else {
            this.appService.alert("Please fill in your full username and password", 'info');
        }
    }

    handlerSignup() {
        this.mode = 'signup';
    }

    handlerSignupUsing() {
        localStorage.setItem('prevBackPage', this.router.url);
        // this.router.navigate(['/payment']);
        window.open(this.appService.urlReport + 'payment', '_blank');

    }

    handlerChangeModeForm(evt: string) {
        this.mode = evt;
    }

    forgotPassword() {
        this.appService.alert('Contact admin to retrieve the password');
    }

    private queryLogin(data: any, mode: string) {
        if (this.sublogin) {
            this.sublogin.unsubscribe();
        }
        this.sublogin = this.authService.login(data).subscribe((response) => {
            if (response?.model?.success === true) {
                const redirect = this.authService.redirectUrl
                    ? this.authService.redirectUrl
                    : '/';
                const obj = response.model.token;
                if (obj.userPortal !== '' && obj.userPortal != null) {
                    const a = obj.userPortal;
                    const b = obj.passwordPortal;
                    if (!this.authService._isLoggedInPortal) {
                        this.authService.getAuthorize().subscribe(res => {
                            this.oauth_state = res;
                            const str =
                                this.oauth_state +
                                // tslint:disable-next-line:max-line-length
                                '&user=' + a + '&pass=' + b + '&url=' + this.appService.hostName + '&callback=' + obj.urlPortal + '/home/login_portal_callback.html';
                            const urlportalhome =
                                obj.urlPortal + '/home/login_portal.html?oauth_state=' +
                                str;
                            // this.authService._login = true;
                            this.authService._isLoggedInPortal = true;
                            this.authService.currentLoginPortalSubject.next(true);
                            localStorage.setItem('loginPortal', 'true');
                            window.location.href = urlportalhome;
                            // console.log(window.location.href);
                            // window.open(urlportalhome );

                        });
                    }
                    else {
                        this.router.navigate([redirect]);
                    }
                }
                else {
                    this.router.navigate([redirect]);
                }
            } else {
                let msg = '';
                if (response?.model?.message === 'NOT_LICENSE') {
                    msg = `Account don't have license`;
                    //this.appService.alert(msg, 'error');
                  //  return;
                }
                else if (response.model.message === 'License Expired!') {
                    msg = `License Expired!`;
                   // this.appService.alert(msg, 'error');
                   // return;
                }
                else if (response.model.message === 'License Not Active!') {
                    msg = `License Not Active!`;
                   // this.appService.alert(msg, 'error');
                   // return;
                }
                else if (response.model.message === 'Invalid Config License') {
                    // localStorage.setItem('license', response.model.message);
                    // localStorage.setItem('clientcode', response.model.token.clientcode);
                    msg = 'Invalid Config License';
                    //this.router.navigate(['/license']);
                } else {
                    msg = 'Wrong account, password';
                }
                this.appService.alert(msg, 'error');
            }

            this.loading = false;
        }, err => {
            this.appService.alert('An error occurred. Please try again', 'error');
            this.loading = false;
        });
    }

    private checkUser(data: any, mode: string) {
        data['USER_AD'] = 'F';
        this.queryLogin(data, mode);
    }

    ngOnDestroy() {
        if (this.sublogin) {
            this.sublogin.unsubscribe();
        }
        if (this.subget) {
            this.subget.unsubscribe();
        }
    }
}
