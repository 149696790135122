import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dangky',
  templateUrl: './dangky.component.html',
  styleUrls: ['./dangky.component.scss']
})
export class DangkyComponent implements OnInit {
  arrMessage: any = null
  message: string = ''
  dataMessage: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient
  ) { }

  async ngOnInit() {
    const data: any = await this.http.get('assets/config-register/config.json').pipe(take(1)).toPromise()
    this.arrMessage = data

    this.route.queryParams.subscribe(params => {
      this.dataMessage = this.arrMessage.find((f: any) => f.code === params.success)
      if (this.dataMessage) {
        this.message = this.dataMessage.message
      }
    })
  }

  handleAgree() {
    this.router.navigate(['/login']);
  }
}
