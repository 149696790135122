export const CONFIG_FIELD_HISTORY = {
    sys_table: { SU_CO: { Layout: 2 } },
    sys_column: {
    1: {
        ColumnID:  1,
        ColumnName: 'type',
        ColumnAlias:  'Hoạt động',
        ColumnType:  'C',
        ColumnIndex:  1,
        IsNull:  false,
        IsPryKey:  true,
        IsSearch:  null,
        isReadField:  true,
    }, 2: {
        ColumnID:  2,
        ColumnName:  'stepName',
        ColumnAlias:  'Bước',
        ColumnType:  'T',
        ColumnIndex:  2,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 3: {
        ColumnID:  3,
        ColumnName:  'sendFrom',
        ColumnAlias:  'Người gửi',
        ColumnType:  'T',
        ColumnIndex:  3,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 4: {
        ColumnID:  4,
        ColumnName:  'assignedTo',
        ColumnAlias:  'Người nhận',
        ColumnType:  'T',
        ColumnIndex:  4,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 5: {
        ColumnID:  5,
        ColumnName:  'date',
        ColumnAlias:  'Thời gian',
        ColumnType:  'D',
        ColumnIndex:  5,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }, 6: {
        ColumnID:  6,
        ColumnName:  'message',
        ColumnAlias:  'Tin nhắn',
        ColumnType:  'T',
        ColumnIndex:  6,
        IsNull:  true,
        IsPryKey:  false,
        isReadField:  true,
        IsSearch:  true
    }
}
};