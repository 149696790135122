import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WebdatarockService } from 'app/analytics-system/services/webdatarock.service';
import { AppService } from 'app/app-base/app.service';

@Component({
  selector: 'app-dialog-build-tree',
  templateUrl: './dialog-build-tree.component.html',
  styleUrls: ['./dialog-build-tree.component.scss']
})
export class DialogBuildTreeComponent implements OnInit {
  clientId = '';
  arrTable: any[] = [];

  public _arrField: any = [];
  @Input('arrField')
  set arrField(arrField: any) {
    this._arrField = arrField;
  }

  arrFieldRelation: any[] = [];

  crtTable: any;
  crtField: any;
  crtFieldRelation: any;

  public formGroup: FormGroup = new FormGroup({});

  @Output() onBuildTree: EventEmitter<any> = new EventEmitter();
  @Output() closeDialogBuildTree: EventEmitter<any> = new EventEmitter();

  constructor(
    private appService: AppService,
    public translate: TranslateService,
    private webDatarockService?: WebdatarockService
  ) {
    this.formGroup = new FormGroup({
      cboField: new FormControl(null),
      cboTable: new FormControl(null),
      cboFieldRelation: new FormControl(null)
    });

    // this.formGroup.controls.cboTable.valueChanges.subscribe((res: any) => {
    //   if (res) {
    //     var tableId = res.tableId ?? res;
    //     this.webDatarockService?.getColumnByTableId(tableId).subscribe(resCol => {
    //       resCol.features.forEach((col: any) => {
    //         col.CODE = col.ColumnId;
    //         col.DESCR = col.Alias;
    //       })
    //       this.arrFieldRelation = resCol.features;
    //     });
    //   } else {
    //     this.arrFieldRelation = [];
    //   }
    // });
  }

  ngOnInit(): void {
    this.queryDataService();
  }

  onReturnObjField(res: any) {
    this.crtField = res;
  }

  onReturnObjTable(res: any) {
    this.crtTable = res;
    if (res) {
      var tableId = res.tableId ?? res;
      this.webDatarockService?.getColumnByTableId(tableId).subscribe(resCol => {
        resCol.features.forEach((col: any) => {
          col.CODE = col.ColumnId;
          col.DESCR = col.Alias;
        })
        this.arrFieldRelation = resCol.features;
      });
    } else {
      this.arrFieldRelation = [];
    }
  }

  onReturnObjFieldRelation(res: any) {
    this.crtFieldRelation = res;
  }

  queryDataService() {
    const arrData = [
      {
        serviceId: 0,
        serviceName: 'All'
      }
    ];
    const localClient: any = this.appService.currentUser;
    this.clientId = JSON.parse(localClient).clientid;
    const clientid = this.clientId;
    // this.appService.showLoading();
    const parmas = {
      pageSize: 1000,
      pageNumber: 1,
      ClientId: clientid
    };
    this.webDatarockService?.queryTreeServices(parmas).subscribe((res) => {
      if (res.success) {
        res.model.forEach((item: any) => {
          if (item.sysTables.length > 0) {
            item.sysTables.forEach((table: any) => {
              table.userNamePortal = item.userNamePortal;
              table.passWordPortal = item.passWordPortal;
              table.urlToken = item.urlToken;

              table.CODE = table.tableId;
              table.DESCR = table.alias;
              this.arrTable.push(table)
            });
          }
        })
      }
    });
  }

  clickBuildTree() {
    this.onBuildTree.emit({
      keyItem: this.crtField,
      keyParent: this.crtFieldRelation,
      table: this.crtTable
    });
  }

  closeDialog() {
    this.closeDialogBuildTree.emit();
  }
}
