<div class="toolbar-header">
    <div class="toolbarleft">
        <ng-container *ngIf="userStatus !== 3 && !permissionList['ADD'] && !isFilter && is_showtool && !isParentLock">
            <button pButton pRipple [disabled]="disableTool['insert']" type="button" icon="pi pi-cz-add"
                pTooltip="{{'Add' | translate}} [F2]" tooltipPosition="top" class="p-button-rounded p-button-text"
                (click)="onClick('insert')"></button>
        </ng-container>
        <button pButton pRipple type="button" icon="pi pi-cz-mode" tooltipPosition="top"
            class="p-button-rounded p-button-text" pTooltip="{{'Change mode' | translate}} [F10]"
            (click)="onChangeMode()"></button>
        <ng-container *ngIf="!permissionList['SEARCH'] && is_showtool">
            <button pButton pRipple [disabled]="disableTool['search']" type="button" icon="pi pi-cz-search"
                pTooltip="{{'Search' | translate}} [F3]" tooltipPosition="top" class="p-button-rounded p-button-text"
                (click)="onClick('search')"></button>
        </ng-container>
        <ng-container *ngIf="userStatus !== 3 && !permissionList['EDIT'] && !isFilter && is_showtool && !isParentLock">
            <button pButton pRipple [disabled]="disableTool['edit']" type="button" icon="pi pi-cz-edit"
                pTooltip="{{'Edit' | translate}} [F4]" tooltipPosition="top" class="p-button-rounded p-button-text"
                (click)="onClick('update')"></button>
        </ng-container>
        <ng-container
            *ngIf="userStatus !== 3 && !permissionList['SAVE'] && !permissionList['EDIT'] && !isFilter && is_showtool && !isParentLock">
            <ng-container *ngIf="dataSource.INFORMATION.ARCHIVE_TYPE === 'auto'">
                <button pButton pRipple [disabled]="disableTool['save']" type="button" icon="pi pi-cz-save"
                    pTooltip="{{'Save' | translate}} [F6]" tooltipPosition="top" class="p-button-rounded p-button-text"
                    (click)="onClick('saveArchive')"></button>
            </ng-container>
            <ng-container
                *ngIf="!permissionList['ARCHIVE'] && dataSource.INFORMATION.ARCHIVE_TYPE && dataSource.INFORMATION.ARCHIVE_TYPE !== 'auto'">
                <button pButton pRipple [disabled]="disableTool['save']" type="button" icon="pi pi-cz-save"
                    pTooltip="{{'Save' | translate}} [F6]" tooltipPosition="top" class="p-button-rounded p-button-text"
                    (click)="overlayArchive.toggle($event)"></button>
            </ng-container>
            <ng-container *ngIf="permissionList['ARCHIVE'] || !dataSource.INFORMATION.ARCHIVE_TYPE">
                <button pButton pRipple [disabled]="disableTool['save']" type="button" icon="pi pi-cz-save"
                    pTooltip="{{'Save' | translate}} [F6]" tooltipPosition="top" class="p-button-rounded p-button-text"
                    (click)="onClick('save')"></button>
            </ng-container>

        </ng-container>
        <ng-container
            *ngIf="userStatus !== 3 && !permissionList['DELETE'] && !isFilter && is_showtool && !isParentLock">
            <!-- <button pButton pRipple [disabled]="disableTool['delete']" type="button" icon="pi pi-cz-delete"
                pTooltip="{{'Delete' | translate}} [F8]" tooltipPosition="top" class="p-button-rounded p-button-text"
                (click)="onClick('delete')"></button> -->
            <ng-container *ngIf="dataSource.INFORMATION.ARCHIVE_TYPE === 'auto'">
                <button pButton pRipple [disabled]="disableTool['delete']" type="button" icon="pi pi-cz-delete"
                    pTooltip="{{'Delete' | translate}} [F8]" tooltipPosition="top"
                    class="p-button-rounded p-button-text" (click)="onClick('deleteArchive')"></button>
            </ng-container>
            <ng-container
                *ngIf="!permissionList['ARCHIVE'] && dataSource.INFORMATION.ARCHIVE_TYPE && dataSource.INFORMATION.ARCHIVE_TYPE !== 'auto'">
                <button pButton pRipple [disabled]="disableTool['delete']" type="button" icon="pi pi-cz-delete"
                    pTooltip="{{'Delete' | translate}} [F8]" tooltipPosition="top"
                    class="p-button-rounded p-button-text" (click)="overlayDelete.toggle($event)"></button>
            </ng-container>
            <ng-container *ngIf="permissionList['ARCHIVE'] || !dataSource.INFORMATION.ARCHIVE_TYPE">
                <button pButton pRipple [disabled]="disableTool['delete']" type="button" icon="pi pi-cz-delete"
                    pTooltip="{{'Delete' | translate}} [F8]" tooltipPosition="top"
                    class="p-button-rounded p-button-text" (click)="onClick('delete')"></button>
            </ng-container>
        </ng-container>
        <ng-container
            *ngIf="userStatus !== 3 && !permissionList['LINK'] && canLinkTable && is_showtool && !isParentLock">
            <button pButton pRipple [disabled]="disableTool['link']" type="button" icon="pi pi-cz-link"
                pTooltip="{{'Link data' | translate}} [F7]" tooltipPosition="top" class="p-button-rounded p-button-text"
                (click)="onClick('link-table')"></button>
        </ng-container>
        <ng-container
            *ngIf="userStatus !== 3 && !permissionList['LINK'] && canLinkTable && is_showtool && !isParentLock">
            <button pButton pRipple [disabled]="disableTool['link']" type="button" icon="ci ci-unlink"
                pTooltip="{{'Unlink' | translate}}" tooltipPosition="top" class="p-button-rounded p-button-text"
                (click)="onClick('unlink')"></button>
        </ng-container>
        <ng-container *ngIf="userStatus !== 3 && dataSource.INFORMATION.COLUMN_LOCK && is_showtool">
            <ng-container
                *ngIf="permissionList['UNLOCK'] && currentData && currentData.length > 0 && checkLockRecord(currentData[this.currentData.length - 1])">
                <button pButton pRipple [disabled]="disableTool['lock']" type="button" icon="pi pi-cz-lock"
                    pTooltip="{{'Lock record' | translate}}" tooltipPosition="top"
                    class="p-button-rounded p-button-text" (click)="onClick('lock-record')"></button>
            </ng-container>
            <ng-container
                *ngIf="permissionList['LOCK'] && currentData && currentData.length > 0 && !checkLockRecord(currentData[this.currentData.length - 1])">
                <button pButton pRipple [disabled]="disableTool['unlock']" type="button" icon="pi pi-cz-lock-open"
                    pTooltip="{{'Unlock record' | translate}}" tooltipPosition="top"
                    class="p-button-rounded p-button-text" (click)="onClick('unlock-record')"></button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="canZoom && !isFilter">
            <button pButton pRipple type="button" icon="pi pi-cz-zoom" tooltipPosition="top"
                class="p-button-rounded p-button-text" pTooltip="{{'Zoom To Graphic' | translate}} [F9]"
                (click)="onClick('zoom')"></button>
        </ng-container>
        <ng-container
            *ngIf="userStatus !== 3 && dataSource && isShowBtnChoose && dataSource.INFORMATION.TAB_LEVEL === '0' && dataSource.INFORMATION.SEQ_NO === '1' && is_showtool">
            <button pButton pRipple type="button" icon="pi pi-cz-check" tooltipPosition="top"
                class="p-button-rounded p-button-text" pTooltip="{{'Choose' | translate}}"
                (click)="onChooseRecord()"></button>
        </ng-container>
        <ng-container
            *ngIf="userStatus !== 3 && !permissionList['ARCHIVE'] && dataSource.INFORMATION.ARCHIVE_TYPE && !isFilter && is_showtool">
            <button pButton pRipple [disabled]="disableTool['history']" type="button" icon="pi pi-cz-clock"
                pTooltip="{{'Data change history' | translate}}" tooltipPosition="top"
                class="p-button-rounded p-button-text" (click)="onClick('history')"></button>
        </ng-container>
        <ng-container
            *ngIf="userStatus !== 3 && !permissionList['ATTACHMENT'] && !isFilter && is_showtool && dataSource.INFORMATION.HASATTACHMENT === 'Y'">
            <button pButton pRipple [disabled]="disableTool['attachment']" type="button" icon="pi pi-cz-attachment"
                pTooltip="{{'Attachment' | translate}}" tooltipPosition="top" class="p-button-rounded p-button-text"
                (click)="onClick('attachment')"></button>
        </ng-container>
    </div>

    <div class="toolbarright">
        <ng-container *ngIf="dataSource">
            <div *ngIf="showFilter && !permissionList['FILTER']">
                <form [formGroup]="filterFormGroup" class="filter-field-form">
                    <ng-container *ngFor="let item of filterFields; let i = index">
                        <ng-container *ngIf="!isString(item)">
                            <!-- <div style="width: 150px;"> -->
                            <ng-container *ngIf="item[1] !== 'GETDATE'">
                                <app-select [showLabel]="false" [formControlName]="item[0] + i"
                                    style="margin-right: 10px" [dataLookup]="item[4]" [dataSource]="item[5]"
                                    [placeholder]="item[3]" [controlWidth]="'150'"></app-select>
                            </ng-container>
                            <ng-container *ngIf="item[1] === 'GETDATE'">
                                <app-core-datetime [showLabel]="false" [formControlName]="item[0] + i"
                                    style="margin-right: 10px" [placeholder]="item[3]" [showIcon]="false"
                                    [controlWidth]="'150'"
                                    [calConfig]="{ showTime: false, showButtonBar: true, selectionMode: 'range',
                                        yearNavigator: true, monthNavigator: true, yearRange: '1900:2099' }"></app-core-datetime>
                            </ng-container>
                            <!-- </div> -->
                        </ng-container>
                    </ng-container>
                </form>
            </div>

            <ng-container *ngIf="menubarItems">
                <div class="div-menutool-table-class">
                    <p-menubar [model]="menubarItems"></p-menubar>
                </div>

            </ng-container>
        </ng-container>
        <button pButton pRipple type="button" icon="pi pi-cz-download" tooltipPosition="top"
            *ngIf="userStatus !== 3 && isShowImportBtn && !permissionList['ADD']" class="p-button-rounded p-button-text"
            pTooltip="{{'Import data' | translate}}" (click)="onClick('import-data')"></button>
        <input #fileExcelImportData hidden type="file" (change)="loadExcelImportDataComplete($event)" />
        <button pButton pRipple type="button" icon="pi pi-cz-upload" tooltipPosition="top"
            class="p-button-rounded p-button-text" pTooltip="{{'Export data' | translate}}"
            (click)="onClick('export-data')"></button>
        <button pButton pRipple type="button" icon="pi pi-cz-refresh" tooltipPosition="top"
            class="p-button-rounded p-button-text" pTooltip="{{'Reload' | translate}} [F5]"
            (click)="onReloadTable()"></button>
        <button pButton pRipple type="button" icon="pi pi-cz-back" tooltipPosition="top"
            class="p-button-rounded p-button-text" pTooltip="{{'Previous' | translate}} [F11]"
            (click)="onClick('previous')"></button>
        <button pButton pRipple type="button" icon="pi pi-cz-next" tooltipPosition="top"
            class="p-button-rounded p-button-text" title="{{'Next' | translate}} [F12]"
            (click)="onClick('next')"></button>
    </div>
</div>

<!-- <div class="delete-options" *ngIf="isMultiSelect">
    <div style="color: red; font-size: 12px; padding-right: 10px; cursor: pointer;" (click)="onToolClick('delete-ok')">
        {{'Delete'
        | translate}}</div>
    <div style="color: #000; font-size: 12px; padding-right: 10px; cursor: pointer;"
        (click)="onToolClick('delete-cancel')">
        {{'Cancel' | translate}}</div>
</div> -->

<!-- Optimization code -->
<div class="table-body-container">
    <div [ngStyle]="{'display': mode === 'table'? 'block' : 'none'}">
        <ng-container *ngTemplateOutlet="tablePrimeng"></ng-container>
    </div>
    <div [ngStyle]="{'display': mode !== 'table'? 'block' : 'none'}">
        <ng-container *ngTemplateOutlet="form"></ng-container>
    </div>
</div>

<!-- <ng-container [ngSwitch]="mode">
    <div class="table-body-container">
        <ng-container *ngSwitchCase="'table'">
            <ng-container *ngTemplateOutlet="tablePrimeng"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="form"></ng-container>
        </ng-container>
    </div>
</ng-container> -->

<ng-template #form>
    <app-core-form [isModeView]="true" [dataSource]="dataSource" [bindData]="bindDataToForm" [parent]="this">
    </app-core-form>
    <!-- Clone từ #tablePrimeng -->
    <div class="grid align-center mt-3" style="margin: 0px; padding: 0px;">
        <div class="col-4 grid total-label">
            {{!isUpdate ? "View" : "Edit"}}:&nbsp;<span title="{{titleColumkey}}">{{indexCurrentSelect}}</span>/{{
            totalRecord }} record
        </div>
    </div>
</ng-template>

<ng-template #tablePrimeng>
    <div class="tbl-container">
        <p-table [columns]="tblColumnDef" [resizableColumns]="true" [autoLayout]="true" [value]="resultList"
            (onEditCancel)="onEditCancel($event)" (onEditInit)="onRowTableEdit($event)" #pTable
            (onColResize)="onColResize($event)" [rows]="pageSize" [loading]="tableLoading" responsiveLayout="scroll"
            (onRowSelect)="onRowClick($event)" [dataKey]="primaryKey" editMode="row" selectionMode="multiple"
            [metaKeySelection]="true" (onColReorder)="onColReorder($event)" [(selection)]="currentData"
            [rowHover]="true" (onRowUnselect)="onRowUnselect($event)" columnResizeMode="expand" [scrollable]="true"
            [reorderableColumns]="isReorder">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngIf="isReorder">
                        <th *ngFor="let col of columns" [pReorderableColumnDisabled]="false" pResizableColumn
                            pReorderableColumn pFrozenColumn [frozen]="col.rowIsFrozen"
                            [ngStyle]="{'font-style': col.rowDisable ? 'italic' : 'normal' }"
                            style="display: flex; align-items: center; justify-content: space-between;">
                            {{col.rowHeader}}

                            <i class="pi pi-cog cursor-pointer" [ngClass]="{'d-none': !isFilter}"
                                style="font-size: 14px; margin-left: 10px;" (click)="configCol($event, col)"></i>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="!isReorder">
                        <th *ngFor="let col of columns" pResizableColumn pFrozenColumn [frozen]="col.rowIsFrozen"
                            [ngStyle]="{'font-style': col.rowDisable ? 'italic' : 'normal' }">
                            <div>
                                <i *ngIf="(col.rowType !== 'date' && col.rowType !== 'datetime')" class="pi pi-filter"
                                    (click)="onFilterDistinct(col, overlayFilter, $event)"
                                    style="cursor: pointer; margin-right: 5px;"
                                    [ngClass]="{'filter-active': col.rowFilterDirty}"></i>
                                <!-- <i   class="pi pi-sort-alt"
                                    (click)="onSortClick(col)"
                                    style="padding-left: 5px; cursor: pointer;"
                                    [ngClass]="{'filter-active': col.rowFilterDirty}"></i> -->
                            </div>
                            {{col.rowHeader}}

                            <!-- Mất nút clearAll distinc vì xóa No. -->
                            <!-- <i *ngIf="col.rowFieldName === '__stt'"
                                class="pi pi-filter-slash mr-3" (click)="onFilterDistinctClearAll()"
                                style="line-height: inherit; float: left; cursor: pointer;"></i> -->

                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
                <tr (dblclick)="onRowDoubleClick(rowData)" [pEditableRow]="rowData" [pSelectableRow]="rowData">
                    <!-- [class.active]="currentData !== null && primaryKey !== null ? currentData[primaryKey] === rowData[primaryKey] : false" -->
                    <ng-container *ngFor="let col of columns">
                        <!-- Thêm mode === 'table' để khi sửa ở form không bị lỗi -->
                        <ng-container *ngIf="isUpdate && mode === 'table'">
                            <td class="tbl-td-item" *ngIf="col.rowDisable">
                                {{rowData[col.rowFieldName] || '-'}}
                            </td>
                            <td class="tbl-td-item"
                                *ngIf="(!col.rowDisable && !checkDK(col)) || checkLockRecord(rowData)">
                                {{rowData[col.rowFieldName] || '-'}}
                            </td>
                            <ng-container [ngSwitch]="col.rowType">
                                <td [pEditableColumn]='rowData' [pEditableColumnRowIndex]="ri"
                                    [pEditableColumnField]="col.rowFieldName" pFrozenColumn [frozen]="col.rowIsFrozen"
                                    [ngClass]='{"data-change-class": arr_dataChange.includes(rowData[primaryKey] + "_" + col.rowFieldName)}'
                                    class="tbl-td-item"
                                    *ngIf="!col.rowDisable && checkDK(col) && !checkLockRecord(rowData)">
                                    <ng-container *ngSwitchCase="'select'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-select [showLabel]="false" [hasFormCtrl]='false'
                                                    [labelName]="col.rowFieldName"
                                                    [dataLookup]="lookupCondition[col.rowDomainId]"
                                                    (click)="$event.stopPropagation()"
                                                    (valueChange)="onValueSelectChange($event, rowData, col.rowFieldName, ri)"
                                                    [dataSource]="col.rowIsDomain ? null : col.rowFieldFilter"
                                                    [(valueModel)]='rowData[col.rowFieldName]'
                                                    [required]="col.rowRequired" [disabledCtrl]="col.rowDisable">
                                                </app-select>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName + '_C']}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'date'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-core-datetime [showLabel]="false" [hasFormCtrl]='false'
                                                    [isShowTime]="false" [required]="col.rowRequired"
                                                    (click)="$event.stopPropagation()"
                                                    [calConfig]='{yearNavigator: true, monthNavigator: true, yearRange: "1900:2099"}'
                                                    (selectDate)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [(valueModel)]='rowData[col.rowFieldName]'>
                                                </app-core-datetime>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName + '_D']}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'datetime'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-core-datetime [showLabel]="false" [hasFormCtrl]='false'
                                                    [required]="col.rowRequired" [isShowTime]="true"
                                                    (click)="$event.stopPropagation()"
                                                    [calConfig]='{yearNavigator: true, monthNavigator: true, yearRange: "1900:2099"}'
                                                    (selectDate)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [(valueModel)]='rowData[col.rowFieldName]'>
                                                </app-core-datetime>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName + '_DT]}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'number'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-input [showLabel]="false" type="number" [hasFormCtrl]='false'
                                                    (blurEvent)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [vFormat]="col.rowFormat" [required]="col.rowRequired"
                                                    (click)="$event.stopPropagation()"
                                                    [(valueModel)]='rowData[col.rowFieldName]'
                                                    [disabledCtrl]="col.rowDisable"></app-input>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName + '_N']}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'img64'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <img [src]="rowData[col.rowFieldName]" />
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <img [src]="rowData[col.rowFieldName]" />
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'text'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-input [showLabel]="false" [hasFormCtrl]='false'
                                                    [required]="col.rowRequired" (click)="$event.stopPropagation()"
                                                    (blurEvent)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [(valueModel)]='rowData[col.rowFieldName]'
                                                    [disabledCtrl]="col.rowDisable"></app-input>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName]}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'checkbox'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-core-checkbox [hasFormCtrl]="false" [chkName]="'chk'"
                                                    (chkChange)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    (click)="$event.stopPropagation()" [chkLabel]="''"
                                                    [(valueModel)]="rowData[col.rowFieldName]" [isDisabled]="false"
                                                    [typeValue]="'string'" [isLabelLeft]="true">
                                                </app-core-checkbox>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName]}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'tricheckbox'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-core-state-checkbox [hasFormCtrl]="false" [chkLabel]=""
                                                    [(valueModel)]="rowData[col.rowFieldName]"
                                                    [typeValue]="'string'"></app-core-state-checkbox>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName]}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'treeselect'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-core-tree-select [showLabel]="false" [isLabelLeft]="false"
                                                    [(valueModel)]='rowData[col.rowFieldName]'
                                                    (valueChange)="onTreeSelectChange($event, rowData, col, ri)"
                                                    [disabledCtrl]="col.rowDisable" [required]="col.rowRequired"
                                                    [hasFormCtrl]='false' (click)="$event.stopPropagation()"
                                                    [dataSource]="col.rowIsDomain ? null : col.rowFieldFilter">
                                                </app-core-tree-select>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName]}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'search'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-input-search [showLabel]="false" [isLabelLeft]="false"
                                                    [dataSource]="col.rowIsDomain ? null : col.rowFieldFilter"
                                                    (click)="$event.stopPropagation()"
                                                    (valueChange)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [(valueModel)]='rowData[col.rowFieldName]'
                                                    (blurEvent)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [required]="col.rowRequired" [hasFormCtrl]='false'
                                                    [disabledCtrl]="col.rowDisable"></app-input-search>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName] + '_S' || '-'}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'json'">

                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-core-whereclause mode='json' [isShowLabel]="false"
                                                    [isLabelLeft]="false" [titleDialog]='col.rowFieldName'
                                                    [hasFormCtrl]="false" [isDisabledCtrl]="col.rowDisable"
                                                    [required]="col.rowRequired"
                                                    (blurEvent)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    (valueChange)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [(valueModel)]='rowData[col.rowFieldName]'
                                                    (click)="$event.stopPropagation()"
                                                    [isTypeArray]="true"></app-core-whereclause>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName] || '-'}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'array'">

                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-core-whereclause mode='array' [isShowLabel]="false"
                                                    [isLabelLeft]="false" [titleDialog]='col.rowFieldName'
                                                    [hasFormCtrl]="false" [isDisabledCtrl]="col.rowDisable"
                                                    [required]="col.rowRequired"
                                                    (blurEvent)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    (valueChange)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [(valueModel)]='rowData[col.rowFieldName]'
                                                    (click)="$event.stopPropagation()"
                                                    [isTypeArray]="true"></app-core-whereclause>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName] || '-'}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'tree'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-input [showLabel]="false" [isLabelLeft]="false"
                                                    [dataSource]="col.rowFieldFilter"
                                                    [(valueModel)]='rowData[col.rowFieldName]' [hasFormCtrl]='false'
                                                    [disabledCtrl]="col.rowDisable"
                                                    (valueChange)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    (blurEvent)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [required]="col.rowRequired" type="input-tree">
                                                </app-input>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName] || '-'}}
                                            </ng-template>
                                        </p-cellEditor>

                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <app-input [showLabel]="false" [required]="col.rowRequired"
                                                    (click)="$event.stopPropagation()"
                                                    (blurEvent)="onKey($event, rowData, col.rowFieldName, ri)"
                                                    [(valueModel)]='rowData[col.rowFieldName]' [hasFormCtrl]='false'
                                                    [disabledCtrl]="col.rowDisable"></app-input>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData[col.rowFieldName] || '-'}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!isUpdate">
                            <ng-container [ngSwitch]="col.rowType">
                                <td class="tbl-td-item" pFrozenColumn [frozen]="col.rowIsFrozen">
                                    <ng-container *ngSwitchCase="'select'">
                                        <span class="customer-badge" [ngStyle]="{'background-color': rowData['__color_code_' + col.rowFieldName]}">{{rowData[col.rowFieldName + '_C']}}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'search'">
                                        <!-- {{rowData[col.rowFieldName + '_S']}} -->
                                        <span class="customer-badge" [ngStyle]="{'background-color': rowData['__color_code_' + col.rowFieldName]}">{{rowData[col.rowFieldName + '_S']}}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'date'">
                                        <!-- {{rowData[col.rowFieldName + '_D']}} -->
                                        <span class="customer-badge" [ngStyle]="{'background-color': rowData['__color_code_' + col.rowFieldName]}">{{rowData[col.rowFieldName + '_D']}}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'datetime'">
                                        <!-- {{rowData[col.rowFieldName + '_DT']}} -->
                                        <span class="customer-badge" [ngStyle]="{'background-color': rowData['__color_code_' + col.rowFieldName]}">{{rowData[col.rowFieldName + '_DT']}}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'number'">
                                        <!-- {{rowData[col.rowFieldName + '_N']}} -->
                                        <span class="customer-badge" [ngStyle]="{'background-color': rowData['__color_code_' + col.rowFieldName]}">{{rowData[col.rowFieldName + '_N']}}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'img64'">
                                        <img [src]="rowData[col.rowFieldName]" />
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <!-- {{rowData[col.rowFieldName] || '-'}} -->
                                        <span class="customer-badge" [ngStyle]="{'background-color': rowData['__color_code_' + col.rowFieldName]}">{{rowData[col.rowFieldName] || '-'}}</span>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td class="table-no-data" [attr.colspan]="columns.length">
                        {{'No Data' | translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!-- <div class="grid align-center mt-3" style="margin: 0px; padding: 0px;">
            <div class="col-4 grid total-label">
                {{!isUpdate ? "View" : "Edit"}}:&nbsp;<span
                    title="{{titleColumkey}}">{{indexCurrentSelect}}</span>/{{ totalRecord }} record
            </div>
            <div class="col-8 grid justify-content-end">
                <p-paginator #paginator [rows]="pageSize" [totalRecords]="totalRecord" dropdownAppendTo = "body"
                    [rowsPerPageOptions]="[10,20,50, 100]" (onPageChange)="onPageChange($event)"
                    [showJumpToPageDropdown]="false" [showPageLinks]="true">
                </p-paginator>
            </div>
        </div> -->
        <div class="row" style="margin: 0; padding-top: 10px; align-items: center;">
            <div class="col-md-4 total-label">
                {{!isUpdate ? "View" : "Edit"}}:&nbsp;<span title="{{titleColumkey}}">{{indexCurrentSelect}}</span>/{{
                totalRecord }} record
            </div>
            <div class="col-md-8">
                <p-paginator #paginator [rows]="pageSize" [totalRecords]="totalRecord" dropdownAppendTo="body"
                    [rowsPerPageOptions]="[10,20,50, 100]" (onPageChange)="onPageChange($event)"
                    [dropdownItemTemplate]='tmpItem' [style]="{'width': '100%'}" [showJumpToPageDropdown]="false"
                    [showPageLinks]="true">
                </p-paginator>
                <ng-template #tmpItem let-item>
                    <div (click)="onChangeRowNum(item)">{{item.value}}</div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #exportDataFromExcel>
    <div class="row">
        <div class="col-md-6">
            <app-input [showLabel]="true" type="number" [hasFormCtrl]='false' [required]="true" labelName="Start Record"
                [(valueModel)]='objOptionExport.startRecord'></app-input>
        </div>
        <div class="col-md-6">
            <app-input [showLabel]="true" type="number" [hasFormCtrl]='false' [required]="true"
                labelName="Record Number" [(valueModel)]='objOptionExport.numRecord'></app-input>
        </div>
    </div>
    <div class="divBtnClass" style="padding-top: 10px; justify-content: flex-end; display: flex;">
        <app-button [labelName]='"Close" | translate' typeButton="reset" (clickEvent)="onClickReset('export')">
        </app-button>

        <app-button [labelName]='"Export" | translate' typeButton="save" (clickEvent)="onExportData()" icon='pi pi-save'
            style="margin-left: 10px;">
        </app-button>
    </div>
</ng-template>
<ng-template #importDataFromExcel>
    <div class="row">
        <div class="col-md-6">
            <app-select [hasFormCtrl]="false" [(valueModel)]="data_import.indexSheet" displayField="name"
                valueField="code" [labelName]="'Sheet' | translate" [dataLookup]="data_import.arrSheet"
                [isLabelLeft]="true" (valueChange)="onchangeSheet($event)" [required]="true">
            </app-select>
        </div>
        <div class="col-md-6">
            <app-select [hasFormCtrl]="false" [(valueModel)]="data_import.rowHeader" displayField="name"
                [required]="true" valueField="code" [labelName]="'Row header' | translate"
                [dataLookup]="data_import.dataRowHeader" [isLabelLeft]="true"
                (valueChange)="onchangeRowHeaderImportData($event)">
            </app-select>
        </div>
    </div>
    <div class="row" style="padding: 10px 0px;">
        <div class="col-md-4 div-row-importdata-class" style="font-weight: bold;">{{'Field Name' | translate}}</div>
        <div class="col-md-8 div-row-importdata-class" style="font-weight: bold;">{{'Excel Column' | translate}}</div>
    </div>
    <ng-container *ngFor="let item of arr_fieldTable">
        <div class="row" style="padding-bottom: 10px;">
            <div class="col-md-4 div-row-importdata-class">{{item.DESCR}}</div>
            <div class="col-md-8 div-row-importdata-class">
                <app-select [hasFormCtrl]="false" [(valueModel)]="item['columnExcel']" displayField="name"
                    [required]="false" valueField="code" [showLabel]='false' [dataLookup]="arr_columnExcel"
                    [isLabelLeft]="true">
                </app-select>
            </div>
        </div>
    </ng-container>
    <div class="divBtnClass" style="justify-content: flex-end; display: flex;">
        <app-button [labelName]='"Close" | translate' typeButton="reset" (clickEvent)="onClickReset('import')">
        </app-button>

        <app-button [labelName]='"Import" | translate' typeButton="save" (clickEvent)="onImportData()" icon='pi pi-save'
            style="margin-left: 10px;">
        </app-button>
    </div>
</ng-template>
<ng-template #tmpShowLog>
    <app-core-basic-table [dataTable]="arrLog" displayField='key' [tableConfig]="tableConfig" [hasTool]="false"
        [columnTable]="arrColumnLog" [arrFrozenColumns]="[]" [fieldEditList]="[]" [isMutiSelect]="false"
        [fieldColor]="['ActionName']" keyColor='color_action' [selectionRowMode]="'single'"
        (selectRowEvt)="onSelectRowLog($event)" (unSelectRowEvt)="detailLog = null">
    </app-core-basic-table>
    <ng-container *ngIf="detailLog && detailLog !== ''">
        <div style="font-size: var(--font_size_big); padding: 5px 0px;">{{ (currentLog && currentLog.ActionName ===
            'SAVE' ? 'Change Detail' : 'Delete detail') | translate}} ({{'Table' |
            translate}}&nbsp;{{dataSource.INFORMATION.TABLE_NAME}})</div>
        <div [title]="detailLog" [innerHTML]="detailLog"></div>
    </ng-container>
</ng-template>
<ng-template #tmpAttachment>
    <app-core-attachment [applicationId]="appService.c$['appId']" [permissionList]="permissionList"
        [primaryKey]="primaryKeyAttachment"
        [dataSource]="{dataSource, currentData: currentData[0]}"></app-core-attachment>
</ng-template>
<ng-template #tmpLock>
    <div class="row">
        <div class="col-md-12">
            <app-select [showLabel]="true" [hasFormCtrl]="false" labelName="Value" [dataLookup]="arrDataLock"
                [required]="true" [(valueModel)]="valLock"></app-select>
        </div>
    </div>
    <div class="divBtnClass" style="padding-top: 10px; justify-content: flex-end; display: flex;">
        <app-button [labelName]='"Close" | translate' typeButton="reset"
            (clickEvent)="dialogLockComponent.closeDialog()">
        </app-button>

        <app-button [labelName]='(modeColumnLock === "lock" ? "Unlock"  : "Lock") | translate' typeButton="save"
            (clickEvent)="changeLock()" icon='pi pi-save' style="margin-left: 10px;">
        </app-button>
    </div>
</ng-template>
<app-dialog-prime #dialogComponent [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>
<app-dialog-prime #dialogLogComponent [isAppendBody]="true" [isMinimize]="true" [maxiMizable]='true'></app-dialog-prime>
<app-dialog-prime #dialogLockComponent [isAppendBody]="true" [isMinimize]="true"></app-dialog-prime>

<!-- <p-confirmDialog #cd [style]="{width: '50vw'}" [key]="dataSource.INFORMATION.TABLE_ID"
    styleClass="confirm-dialog-class">
    <ng-template pTemplate="header">
        <div class="fluid grid formgrid">
            <div class="field col-12">{{ 'Bạn muốn xóa liên kết hay xóa dữ liệu?' | translate }}</div>
            <div class="field col-12">
                <app-core-radio-button [listRadio]="listRadio" [formControl]="radioCtrl"></app-core-radio-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <app-button [labelName]="'Exit' | translate" typeButton="close" (clickEvent)="onExitConfirmDialog()">
        </app-button>
        <app-button [labelName]="'Confirm' | translate" typeButton="save" (clickEvent)="onApplyConfirmDialog()">
        </app-button>
    </ng-template>
</p-confirmDialog> -->

<p-confirmDialog #cd_savechange [style]="{width: '40vw'}" [key]="primaryKey" styleClass="confirm-dialog-class">
    <ng-template pTemplate="footer">
        <app-button [labelName]="'Cancel' | translate" typeButton="cancel" (clickEvent)="cd_savechange.reject()">
        </app-button>
        <app-button [labelName]="'No' | translate" typeButton="reset" (clickEvent)="onNoSaveChange(evtHandle)">
        </app-button>
        <app-button [labelName]="'Yes' | translate" typeButton="save" (clickEvent)="cd_savechange.accept()">
        </app-button>
    </ng-template>
</p-confirmDialog>

<p-overlayPanel [appendTo]="'body'" #overlayFilter>
    <ng-template pTemplate>
        <div class="core-table-overlay-panel">
            <p-listbox [options]="lookupFieldDistinct[currentFieldDistinct.rowFieldName]"
                [(ngModel)]="selectedValuesDistinct" [metaKeySelection]="false" [checkbox]="true" [filter]="true"
                [multiple]="true" optionLabel="DESCR" [listStyle]="{'max-height':'250px'}"
                [style]="{'margin-bottom':'10px'}">
                <ng-template let-country pTemplate="item">
                    <div class="country-item">
                        <div>{{country.DESCR}}</div>
                    </div>
                </ng-template>
            </p-listbox>

            <div class="core-table-overlay-panel-footer">
                <app-button class="mr-3" [labelName]="'Clear' | translate"
                    (clickEvent)="onFilterDistinctClearAll(overlayFilter)" typeButton="cancel"></app-button>
                <app-button [labelName]="'Ok' | translate" typeButton="save" (clickEvent)="onFilterDistinctOk()">
                </app-button>
            </div>
        </div>

    </ng-template>
</p-overlayPanel>
<p-overlayPanel [appendTo]="'body'" #overlayArchive>
    <ng-template pTemplate>
        <div class="div-list-save-class">
            <div class="item-save-class" (click)="overlayArchive.hide(); onClick('save')">{{'Save' | translate}}</div>
            <div class="item-save-class" (click)="overlayArchive.hide(); onClick('saveArchive')">{{'Save & Archive' |
                translate}}</div>
        </div>

    </ng-template>
</p-overlayPanel>

<p-overlayPanel [appendTo]="'body'" #overlayDelete>
    <ng-template pTemplate>
        <div class="div-list-save-class">
            <div class="item-save-class" (click)="overlayDelete.hide(); onClick('delete')">{{'Delete' | translate}}
            </div>
            <div class="item-save-class" (click)="overlayDelete.hide(); onClick('deleteArchive')">{{'Delete & Archive' |
                translate}}</div>
        </div>

    </ng-template>
</p-overlayPanel>

<div *ngIf="isCofgColumn" class="p-menu" style="position: fixed; z-index: 9999999999999;"
    [style.left]="contextPosition.x" [style.top]="contextPosition.y">
    <div class="title d-flex align-items-center justify-content-between"
        style="padding: 0px 5px; font-size: 14px; font-weight: 600;">
        <span>{{titleColumn.rowHeader}}</span>
        <i class="pi pi-times cursor-pointer" (click)="onCloseConfigColumn()"
            style="font-size: 13px; font-weight: 600;"></i>
    </div>
    <div class="wp-item">
        <div class="item-list border-0" *ngFor="let item of cofg; let i = index">
            <div class="fluid grid formgrid" style="margin-top: 2px;">
                <app-core-checkbox (chkChange)="onCheckboxCofg($event,item)" [hasFormCtrl]="false"
                    [(valueModel)]="item.checked" [chkLabel]="item.label" [typeValue]="'boolean'">
                </app-core-checkbox>
            </div>
        </div>
    </div>
</div>

<div style="display: none;">
    <ng-container #viewRefHidden></ng-container>
</div>