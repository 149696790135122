import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { parse } from 'zipson/lib';
import { TableColumnDefination } from '../core-table/core-table.component';
import { CoreWindowService } from '../core-window/core-window.service';
import { RequestService, SearchResponse } from '../services/request.service';

@Component({
    selector: 'app-core-search',
    templateUrl: './core-search.component.html',
    styleUrls: ['./core-search.component.scss'],
    providers: [
        RequestService
    ]
})
export class CoreSearchComponent implements OnInit, OnChanges {

    @Input() isMapApp = false;
    @Input() isActive = true;
    @Input() windowId = null;
    @Input() showAcceptBtn = true;
    @Input() defaultWhere: any = null;

    closeDialog: EventEmitter<any> = new EventEmitter();
    rowEmit: EventEmitter<any> = new EventEmitter();

    public loading = true;
    public dataSource: any = null;
    public formGroup: FormGroup = new FormGroup({});

    private windowConfig: any = null;
    private lookupTab: any = null;

    // For Table && pagination
    public tblColumnDef: TableColumnDefination[] | any[] = [];
    public resultList: any[] = [];
    public pageIndex = 1;
    public pageSize = 100000;
    public currentData: any = null;
    public primaryKey: any = null;
    public totalRecord = 0;
    public lookupCondition: any = {};
    
    private paramSearch: any = {};
    private whereKvhc: any = null;

    constructor(
        private appService: AppService,
        private reqService: RequestService,
        private coreWindowService: CoreWindowService
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.windowId) {
            this.initWindow();
        }
    }

    run(data: any) {
        this.windowId = data.windowId;
        this.showAcceptBtn = false;
        this.initWindow();
    }

    onSearch(event: any) {
        if (event.typeButton === 'btnSearch') {
            const p = this.getWhereClause(event.formControl, event.fieldList);
            // Bắt đầu tìm từ bản ghi đầu tiên
            this.pageIndex = 1;
            this.paramSearch = {
                where: p.where,
                logic: p.logic
            };
            this.queryData(p.where, p.logic);
        } else if (event.typeButton === 'btnCancel') {
            this.closeDialog.emit();
        } else if (event.typeButton === 'btnReset') {
            this.clearData();
        } else if (event.typeButton === 'btnAccept') {
            this.rowEmit.emit(this.currentData);
        } else if (event.typeButton === 'btnSearchAdvanced2') {
            // Bắt đầu tìm từ bản ghi đầu tiên
            this.pageIndex = 1;
            this.paramSearch = {
                where: event.where,
                logic: event.logic
            };
            this.queryData(event.where, event.logic);
        }
    }

    private clearData() {
        this.totalRecord = 0;
        this.resultList = [];
        this.currentData = null;
    }

    private queryData(_where: any, logic = 'and') {
        this.loading = true;
        const url = this.dataSource.INFORMATION.URL_VIEW;
        let where = _where;
        const defaultWhere: any[] = this.dataSource.INFORMATION.DEFAULT_WHERE;
        if (defaultWhere && defaultWhere.length > 0) { // Khi cấu hình có sẵn where_clause, thì mọi query đều sẽ có điều kiện where clause này
            // const newArrayWhere: any[] = ['and'];
            // defaultWhere.forEach(x => {
            //     newArrayWhere[0] = x[0] === 'or' ? 'or' : 'and'; // Lấy kiểu logic
            //     newArrayWhere.push([x[1], x[2], x[3]]); // push kiểu logic
            // })
            // where.push(newArrayWhere);
            where.push(defaultWhere);
        }

        if (this.whereKvhc && this.whereKvhc.length > 1) { // tránh điều kiện 'and' / 'or'
            where.push(this.whereKvhc);
        }

        if (where) {
            this.reqService.service.search({
                url, where, logic
            }).subscribe((res: SearchResponse) => {
                this.totalRecord = res.total;
                this.resultList = res.features;

                res.features.forEach((item, index) => {
                    item['__stt'] = index + (this.pageIndex - 1) * this.pageSize + 1;
                });

                if (this.resultList.length > 0) {
                    this.coreWindowService.convertDateAndComboBox(this.tblColumnDef, this.resultList, this.lookupCondition, this.dataSource);
                }
                this.loading = false;
            }, err => {
                this.appService.notification(this.appService.getMessage('0010'));
                this.loading = false;
            });
        } else {
            this.loading = false;
            this.totalRecord = 0;
            this.resultList = [];
        }
    }


    private getWhereClause(formControl: FormGroup, fieldList: any[]) {
        const p: any = {
            logic: 'and',
            where: [],
        };
        Object.keys(formControl.controls).forEach((key) => {
            // tslint:disable-next-line:max-line-length
            const data = fieldList.filter((fil) => fil.FIELD_NAME === key);
            if (data.length > 0) {
                // Phân loại kiểu control để lấy đúng dữ liệu
                const value = formControl.controls[key].value;
                if (data[0].FIELD_TYPE === 'date' || data[0].FIELD_TYPE === 'datetime') {
                    if (value && value[0]) {
                        if (!value[1]) {
                            value[1] = value[0];
                        }
                        if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                            // kiểu date của arcgis hơi khác
                            const date0 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[0], 'first')}'`;
                            const date1 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[1], 'last')}'`;
                            // date0 sẽ tìm kiếm từ 00h00p00s, date1 sẽ tìm kiếm từ 23h59p59s của ngày
                            p.where.push([key, '>=', date0]);
                            p.where.push([key, '<=', date1]);
                        } else {
                            const date0 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[0], 'first');
                            const date1 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[1], 'last');
                            p.where.push([key, '>=', date0]);
                            p.where.push([key, '<=', date1]);
                        }
                    }
                } else if (data[0].FIELD_TYPE === 'select') {
                    if (value !== null) {
                        p.where.push([key, '=', value]);
                    }
                } else if (data[0].FIELD_TYPE === 'number') {
                    if (value !== '' && value !== null) {
                        p.where.push([key, '=', value]);
                    }
                } else if (data[0].FIELD_TYPE === 'search') {
                    if (value !== '' && value !== null) {
                        p.where.push([key, '=', value[key]]);
                    }
                } else {
                    if (value !== '' && value !== null) {
                        p.where.push([key, 'like', value]);
                    }
                }
            }
        });
        return p;
    }

    public async initWindow() {
        this.loading = true;
        const url = `${this.appService.urlWS}/SysCaches/SysCacheWindow/${this.windowId}`;
        const params = {};
        this.reqService.service.query({ url, params }).subscribe(res => {
            if (res.success) {
                this.windowConfig = parse(res.model.config);
                const config = this.reqService.mapConfig(this.windowConfig);
                if (config.error) {
                    this.appService.alert(config.error, 'error');
                    this.loading = false;
                    return;
                }
                this.lookupCondition = this.appService.getLookup();
                this.readConfig(config);
            }

            this.loading = false;
        }, err => {
            this.appService.notification('Window not found! Please check the configuration!', 'info');
            this.loading = false;
        });
    }

    private readConfig(config: any) {
        const tab = config.tabs[0];
        const defaultValue: any[] = [];
        const fieldList: any[] = [];
        tab.fields.forEach((field: any) => {
            let disabledCtrl = false;
            if (field.isreadonly === 'Y') { disabledCtrl = true; }
            if (field.defaultvalue !== null && field.defaultvalue !== '' && field.defaultvalue !== undefined) {
                defaultValue.push([field.fieldname, '=', field.defaultvalue]);
            }

            fieldList.push({ ...field, disabledCtrl });
        });

        // 21-09-2023: Sao lại thêm default value của các trường vào default where nhỉ
        // const where = tab.whereclause ? defaultValue.concat(JSON.parse(tab.whereclause)) : defaultValue;
        const where = JSON.parse(tab.whereclause);
        const information = {
            TAB_ID: tab.tabid,
            PARENT_ID: tab.parenttabid,
            TABLE_ID: tab.tableid,
            DESCR: tab.tabname,
            TAB_LEVEL: tab.tablevel !== null ? tab.tablevel.toString() : '0',
            TAB_MAX_LEVEL: tab.maxLevel,
            TABLE_DETAIL: tab.tabledetail,
            SEQ_NO: tab.orderno ? tab.orderno.toString() : null,
            DEFAULT_WHERE: where,
            IS_LIENKETDOHOA: 'N',
            IS_SINGLE_LINE_EDIT: tab.issingglelineedit,
            IS_TAB_TRUNG_GIAN: tab.istabtrunggian === 'Y',
            KHOA_CHINH: tab.columnkey,
            TRUONG_LK_CON: tab.truonglienketcon,
            TRUONG_LK_CHA: tab.truonglienketcha,
            TRUONG_LKTG_CON: tab.truongtrunggiancon,
            TRUONG_LKTG_CHA: tab.truongtrunggiancha,
            BANG_CHA: tab.banglienket,
            BANG_LK: tab.bangtrunggian,
            KHOA_CHINH_BANG_LK: tab.columnkeytrunggian,
            WHERE: (tab.tablevel === 0 && tab.orderno === 1 || tab.parenttabid === null) ? where : null,
            TABLE_NAME: tab.tablename,
            URL_EDIT: tab.urledit,
            URL_VIEW: tab.urlview,
            URL_EDIT_MAP: tab.urleditdohoa,
            URL_VIEW_MAP: tab.urlviewdohoa,
            LAYOUT_COLUMN: tab.layoutcolumn,
            PARENT_DATA: null,
            SERVICE_TYPE: tab.tabletype,
            WINDOW_ID: this.windowId,
            FILTER_FIELD: tab.fillterfield,
            FILTER_DEFAULT_VALUE: tab.fillterdefaultvalue,
            ORDER_BY: tab.orderbyclause ?? null,
            INIT_SHOW: tab.initshow === 'Y',
            ONCHANGE: tab.onchange,
            COLUMN_DOHOA: tab.columndohoa, // chưa thấy sử dụng
            COLUMN_CODE: tab.columncode, // Sử dụng để filter nhanh
            TABLEWORKFLOWID: tab.tableworkflowId,
            JOBTYPEIDS: tab.jobtypeids,
            LAYERINDEX: tab.layerindex,
            HASATTACHMENT: tab.hasattachment,
            KVHC_COLUMN: tab.kvhccolumn,
            ARCHIVE_TYPE: tab.logtype
        };

        this.dataSource = {
            FIELD_LIST: fieldList,
            INFORMATION: information,
            LAYOUT_CONFIG: null,
        };

        // initTable
        this.initTable();
    }

    public initTable() {
        const fieldList = this.dataSource.FIELD_LIST;
        this.primaryKey = this.dataSource.INFORMATION.KHOA_CHINH;

        switch (this.dataSource.INFORMATION.SERVICE_TYPE) {
            // case 'AutoData':
            //     this.reqService.switchType('autodata');
            //     break;
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgrest':
                this.reqService.switchType('postgre');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }

        this.pageIndex = 1;

        if (fieldList.length > 0) {
            this.tblColumnDef = [];
            fieldList.forEach((col: any) => {
                if (col.isdisplaygrid === 'Y') {
                    this.tblColumnDef.push({
                        rowHeader: col.alias,
                        rowFieldName: col.fieldname,
                        rowDomainId: col.isfromdomain === 'Y' ? col.domainid : null,
                        rowType: col.fieldtype,
                        rowFormat: col.vformat,
                        rowDisable: col.isreadonly === 'Y',
                        rowIsDomain: col.isfromdomain === 'Y',
                        rowIsFrozen: col.isfrozen === 'Y',
                        rowWidth: col.displaylength,
                        rowFieldFilter: {
                            FOREIGNTABLE: col.foreigntable,
                            FOREIGNTABLE_ID: col.foreigntableid,
                            COLUMNKEY: col.columnkey,
                            COLUMNDISPLAY: col.columndisplay,
                            COLUMNCODE: col.columncode,
                            WHEREFIELDNAME: col.wherefieldname, // sử dụng cho control select
                            WHERECLAUSE: col.whereclause,
                            FOREIGNWINDOW_ID: col.foreignwindowid,
                            PARENT_FIELDID: col.parentfieldid, // Đang dở dang,
                            FIELD_ISDOMAIN: col.isfromdomain === 'Y',
                            SERVICE_TYPE: col.foreigntableservicetype && col.foreigntableservicetype !== '' ?
                                col.foreigntableservicetype : this.dataSource.INFORMATION.SERVICE_TYPE // Control sử dụng kiểu service type nào
                        },
                        rowDirty: false,
                        rowFilterDirty: false,
                        rowFieldId: col.fieldid,
                        sortOrderNo: col.isfrozen === 'Y' ? -1 : col.orderno
                    });
                }
            });

            this.tblColumnDef = this.tblColumnDef.sort(
                (a, b) => a.sortOrderNo - b.sortOrderNo
            );

            console.log('TABLE COLUMN DEFINATION >>>>>> ', this.tblColumnDef);
        }

        
        // Kiểm tra khu vực hành chính, chỉ áp dụng với tab trên cùng nhất tức tab cha ở mức cao nhất (theo ý kiến của anh Khoa - 06/02/2022)
        // Update mới => áp dụng cho toàn bộ tab
        const infor = this.dataSource.INFORMATION;
        if (infor.KVHC_COLUMN && infor.KVHC_COLUMN !== 'N') {
            const where = ['or']
            this.createKvhcWhere(this.appService.appConfig.DSKVHC, where);
            this.whereKvhc = where;
        }


        // Cần tạo lookup ở đây vì có thể sử dụng core search trực tiếp (gọi selector) mà không phải render động và truyền windowId
        this.lookupCondition = this.appService.getLookup();
    }

    private createKvhcWhere(array: any[], where: any[]) {
        array.forEach(item => {
            if (item.listuserkvhc && item.listuserkvhc.length > 0) {
                this.createKvhcWhere(item.listuserkvhc, where);
            } else {
                where.push([this.dataSource.INFORMATION.KVHC_COLUMN, '=', item.codeKvhc]);
            }
        });
    }


    public onRowDoubleClick(data: any) {
        // this.rowEmit.emit(data);
    }

    public onPageChange(page: number) {
        this.pageIndex = page;
        this.queryData(this.paramSearch['where'], this.paramSearch['logic']);
    }

    private buildTabs(tabs: Array<any>, level: any) {
        const list: any[] = [];
        tabs.forEach(tab => {
            const defaultValue: any[] = [];
            const fieldList: any[] = [];
            tab.fields.forEach((field: any) => {
                let disabledCtrl = false;
                if (field.isreadonly === 'Y') { disabledCtrl = true; }
                if (field.defaultvalue !== null && field.defaultvalue !== '' && field.defaultvalue !== undefined) {
                    defaultValue.push([field.fieldname, '=', field.defaultvalue]);
                }

                fieldList.push({ ...field, disabledCtrl });
            });

            const where = defaultValue;
            const information = {
                TAB_ID: tab.tabid,
                PARENT_ID: tab.parenttabid,
                TABLE_ID: tab.tableid,
                DESCR: tab.tabname,
                TAB_LEVEL: tab.tablevel !== null ? tab.tablevel.toString() : '0',
                SEQ_NO: tab.orderno ? tab.orderno.toString() : null,
                DEFAULT_WHERE: where,
                IS_LIENKETDOHOA: 'N',
                KHOA_CHINH: tab.columnkey,
                TRUONG_LK_CON: tab.truonglienketcon,
                TRUONG_LK_CHA: tab.truonglienketcha,
                TRUONG_LKTG_CON: tab.truongtrunggiancon,
                TRUONG_LKTG_CHA: tab.truongtrunggiancha,
                BANG_CHA: tab.banglienket,
                BANG_LK: tab.bangtrunggian,
                WHERE: (tab.tablevel === 0 && tab.orderno === 1) ? where : null,
                TABLE_NAME: tab.tablename,
                URL_EDIT: tab.urledit,
                URL_VIEW: tab.urlview,
                PARENT_DATA: null
            };

            const item = {
                FIELD_LIST: fieldList,
                LAYOUT_CONFIG: null,
                INFORMATION: information,
                HIDE: false,
                TABS: this.buildTabs(tab.tabs, tab.tablevel + 1),
                CHILDREN: [],
                WHERE: information.WHERE
            };

            list.push(item);

            this.lookupTab[tab.tabid] = item;
        });

        return list;
    }
}
