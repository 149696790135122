import { FormControl, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, OnInit, Input, Output, forwardRef, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../services/request.service';

import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR
            , useExisting: forwardRef(() => SelectComponent)
            , multi: true
        },
        RequestService
    ]
})
export class SelectComponent implements OnInit, ControlValueAccessor {
    private getDataLookupEvent: EventEmitter<any> = new EventEmitter();
    private handleGetLookup: any = null;

    @Output() returnObj: EventEmitter<any> = new EventEmitter()
    requiredClass = false;
    _required = false;
    @Input('required')
    set required(val: boolean) {
        this._required = val;
        if (val) {
            if (this.valueModel !== null && this.valueModel !== '') {
                this.requiredClass = false;
            } else {
                this.requiredClass = true
            }
        } else {
            this.requiredClass = false
        }

        if (this.ctrl) {
            this.ctrl.setValidators(val === true ? [Validators.required] : [Validators.nullValidator]);
        }
    }
    get required() {
        return this._required;
    }

    dataLookupBinding: any[] = [];
    @Input('dataLookup')
    set dataLookup(data: any[]) {
        if (data !== null && data !== undefined) {
            this.dataLookupBinding = data;
        } else {
            this.dataLookupBinding = [];
        }
    }
    get dataLookup() {
        return this.dataLookupBinding;
    }

    _disabled = false;
    @Input('disabledCtrl')
    set disabledCtrl(val: boolean) {
        this._disabled = val;
        if (this.ctrl) {
            // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }
    }
    get disabledCtrl() {
        return this._disabled;
    }

    @Input() infoHelp = false;
    @Input() infoHelpContent = '';
    @Input() showLabel = true;
    @Input() labelName = '';
    @Input() labelWidth = '200';
    @Input() controlWidth = 'auto';
    @Input() placeholder = '';
    //   @Input() option = '';
    @Input() displayField = 'DESCR';
    @Input() valueField = 'CODE';
    @Input() isLabelLeft = true;
    @Input() allowClear = true;
    @Input() mode = 'default'; // 'multiple' | 'default' | 'treeselect'
    @Input() hasFormCtrl: boolean = true;
    @Input() isFilter: boolean = false;
    @Input() hasButton: boolean = false
    @Input() notUseTranslate = false;
    
    @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
    // For Relation field
    private _dataSource = null;
    @Input('dataSource')
    set dataSource(val: any) {
        this._dataSource = val;
        if (val && val.FIELD_ISDOMAIN === false) {
            this.initLookupNotDomain();
        }
    }
    get dataSource() { return this._dataSource; }

    // Binding two way, using for table Edit
    private _value = null;
    @Input('value')
    set value(val) {
        this._value = val;
        this.writeValue(val);
    }
    get value() { return this._value; }
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() valueModelChange: EventEmitter<any> = new EventEmitter();

    @Output() zoomClick: EventEmitter<any> = new EventEmitter();
    @Output() Event: EventEmitter<any> = new EventEmitter();
    @Output() blurEvent: EventEmitter<any> = new EventEmitter();

    ctrl: FormControl = new FormControl();
    _valueModel: any = null;
    @Input()
    get valueModel() {
        return this._valueModel === "" ? null : this._valueModel;
    }

    set valueModel(value) {
        this.checkRequire(value)
        this._valueModel = value;
        this.valueModelChange.emit(this._valueModel);
        this.writeValue(value);
    }
    private _isReadOnly = false;
    @Input('isReadOnly')
    set isReadOnly(val: boolean) {
        this._isReadOnly = val;
        if (this.ctrl) {
            // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }
    }
    get isReadOnly() {
        return this._isReadOnly;
    }
    private handleRequest: any = null;
    private defaultWhere: any = null;
    private onChange: (value: any) => void = () => { };
    private onTouched: () => void = () => { };

    constructor(
        private reqService: RequestService,
        private cd: ChangeDetectorRef
    ) {
        this.initForm();
    }

    ngOnInit() { }

    public onZoomClick() {
        this.zoomClick.emit();
    }

    /** hàm khởi tạo lookup cho control không phải domain (lookup list được query, không lấy từ sys_combo) */
    private initLookupNotDomain() {
        this.getDefaultWhere();
        if (this.dataSource) {
            if (this.dataSource.WHEREFIELDNAME && this.dataSource.WHEREFIELDNAME !== '' && this.dataSource.FIELD_PARENT_ID) {
                // Nếu có WHEREFIELDNAME => Phụ thuộc vào 1 trường nào đó => Lookup khởi tạo nên là mảng rỗng
                this.dataLookup = [];
                this.ctrl.setValue(null);
                this.checkRequire(this._valueModel);
                this.getDataLookupEvent.emit();
                return;
            }

            const url = this.dataSource.FOREIGNTABLE;
            switch (this.dataSource.SERVICE_TYPE) {
                // case 'AutoData':
                //     this.reqService.switchType('autodata');
                //     break;
                case 'CloudData':
                    this.reqService.switchType('clouddata');
                    break;
                case 'SQL':
                    this.reqService.switchType('sql');
                    break;
                case 'FeatureServer':
                case 'MapServer':
                    this.reqService.switchType('arcgis3x');
                    break;
                case 'postgrest':
                    this.reqService.switchType('postgre');
                    break;
                default:
                    this.reqService.switchType('sql');
                    break;
            }
            let where: any[] = [];
            if (this.defaultWhere) {
                where.push(this.defaultWhere);
            }
            if (this.handleRequest) {
                this.handleRequest.unsubscribe();
            }
            this.switchRequestType();
            this.handleRequest = this.reqService.service.search({
                url,
                where, // lấy tất cả dữ liệu
                logic: 'and'
            }).subscribe((res: any) => {
                if (res.success) {
                    const lookup: any[] = [];
                    res.features.forEach((item: any) => {
                        const obj: any = { ...item };
                        obj[this.valueField] = this.dataSource.COLUMNKEY ? item[this.dataSource.COLUMNKEY] : item[this.dataSource.COLUMNCODE];
                        obj[this.displayField] = item[this.dataSource.COLUMNDISPLAY];
                        lookup.push(obj);
                    });
                    this.dataLookup = lookup;
                }

                this.ctrl.setValue(null);
                this.checkRequire(this._valueModel);
                this.handleRequest.unsubscribe();
                this.getDataLookupEvent.emit();
            }, (err: any) => {
                this.handleRequest.unsubscribe();
            });
        }
    }

    switchRequestType() {
        switch (this.dataSource.SERVICE_TYPE) {
            // case 'AutoData':
            //     this.reqService.switchType('autodata');
            //     break;
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgrest':
                this.reqService.switchType('postgre');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }
    }
    private initForm() {
        this.ctrl = new FormControl(null, {
            validators: this._required === true ? Validators.required : Validators.nullValidator
        });
        this.isReadOnly ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });

        this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
            this.checkRequire(val);
            this.emitReturnObj(val);
            this.onChange(val && val !== '' ?val[this.valueField] : val);
        });
    }

    // Chỉnh sửa lại dữ liệu emit => sẽ loại bỏ CODE và DESCR khỏi object
    private emitReturnObj(val: any) {
        let newVal = null;
        if (val !== null) {
            newVal = Object.assign({}, val);
            if (newVal.hasOwnProperty(this.valueField)) {
                delete newVal[this.valueField];
            }
            if (newVal.hasOwnProperty(this.displayField)) {
                delete newVal[this.displayField];
            }
        }

        this.returnObj.emit(newVal);
    }

    // Lấy default where
    private getDefaultWhere() {
        let where: any = null;
        if (this.dataSource && this.dataSource.WHERECLAUSE) {
            const jsonWhere = JSON.parse(this.dataSource.WHERECLAUSE);
            if (jsonWhere && jsonWhere.length > 0) {
                where = [jsonWhere];
            }
        }

        this.defaultWhere = where;
    }

    onChangeValue(evt: any) {
        this.valueChange.emit(evt.value);
    }

    private findDataLut(val: any) {
        let dsSet: any = null;
        if (typeof (val) === 'string' || typeof (val) === 'number') {
            const dFilter = this.dataLookupBinding.filter((ds: any) => ds[this.valueField].toString() === val.toString());
            if (dFilter.length > 0) {
                dsSet = dFilter[0];
            }
        } else {
            if (!val) {
                this.ctrl.setValue(null);
                this.checkRequire(null);
                this.emitReturnObj(null);
                this.onChange(null);
                return;
            }
            let dFilter: any[] = [];
            let code = this.valueField;
            if (this.dataSource && this.dataSource.BINDFIELDNAME && this.dataSource.BINDFIELDNAME !== '') {
                code = this.dataSource.BINDFIELDNAME;
            } else if (this.dataSource && this.dataSource.WHEREFIELDNAME && this.dataSource.WHEREFIELDNAME !== '') {
                code = this.dataSource.WHEREFIELDNAME;
            }

            // Lỗi ở case cloud.applicationjs => admin.tnr => đơn bán hàng => thêm mới => Chọn bảng giá ra sai tiền tệ
            if (val.hasOwnProperty(this.valueField)) { // Nếu có CODE thì phải dùng CODE
                code = this.valueField;
            }
            // Trường hợp val là 1 object
            if (!this.dataSource) { // Lookup theo CODE
                dFilter = this.dataLookupBinding.filter((ds: any) =>
                    val[this.valueField] && ds[this.valueField].toString() === val[this.valueField].toString()
                );
            } else if (this.dataSource.FIELD_ISDOMAIN) {
                // Lookup lấy từ sys_combo => Phân thành 2 nhánh: Có BINDFIELDNAME/WHEREFIELDNAME hoặc không
                dFilter = this.dataLookupBinding.filter((ds: any) =>
                    val[code] && ds[this.valueField].toString() === val[code].toString()
                );
            } else {
                dFilter = this.dataLookupBinding.filter((ds: any) =>
                    val[code] && ds[this.valueField].toString() === val[code].toString()
                );
            }

            if (dFilter.length > 0) {
                dsSet = dFilter[0];
            }
        }

        this.ctrl.setValue(dsSet);
        this.checkRequire(dsSet);
        this.emitReturnObj(dsSet);
        this.onChange(dsSet ? dsSet[this.valueField] : dsSet);

        // Đoạn code này dùng để bổ trợ cho việc edit data ở mode table - core-table
        if (this.hasFormCtrl === false) {
            this._valueModel = dsSet ? dsSet[this.valueField] : dsSet;
            this.valueModelChange.emit(this._valueModel);
        }
    }

    // tslint:disable-next-line:variable-name
    writeValue(_value: any): void {
        if (this.handleRequest && this.handleRequest.closed === false) {
            if (this.handleGetLookup) {
                this.handleGetLookup.unsubscribe();
            }
            this.handleGetLookup = this.getDataLookupEvent.subscribe(() => {
                this._writeValue(_value);
                this.handleGetLookup.unsubscribe();
            });
        } else {
            this._writeValue(_value);
        }
        
    }

    private _writeValue(_value: any) {
        if (this.dataSource && this.dataSource.FIELD_ISDOMAIN === false) {
            // Trường hợp control sử dụng lookup từ bảng dữ liệu (thông qua truy vấn foreignTable để lấy lookup)
            if (this.dataSource.WHEREFIELDNAME && this.dataSource.WHEREFIELDNAME.length > 0) { // trường hợp là field Liên kết
                this.getLookupFromParent(_value);
            } else if (this.dataSource.BINDFIELDNAME && this.dataSource.BINDFIELDNAME.length > 0) { // trường hợp được set giá trị dữ liệu từ 1 object
                this.getLookupWithBindFieldName(_value);
            } else { // Trường hợp đứng lẻ một mình
                this.getLookupForSingleControl(_value);
            }
        } else {
            // Trường hợp control sử dụng lookup từ sys_combo
            if (_value !== null) {
                this.findDataLut(_value);
            } else {
                this.ctrl.setValue(null);
                this.checkRequire(null);
            }
        }
    }

    private checkRequire(val: any) {
        if (this._required) {
            if (this.mode === 'default') {
                if (val === null || val === undefined || val === '') {
                    this.requiredClass = true;
                    this.ctrl.setErrors({ incorrect: true });
                } else {
                    this.requiredClass = false;
                    this.ctrl.setErrors(null);
                }
            } else {
                if (val === null || val === undefined) {
                    this.requiredClass = true;
                    this.ctrl.setErrors({ incorrect: true });
                } else {
                    if (val.length === 0) {
                        this.requiredClass = true;
                        this.ctrl.setErrors({ incorrect: true });
                    } else {
                        this.requiredClass = false;
                        this.ctrl.setErrors(null);
                    }
                }
            }
        }
    }

    private getLookupForSingleControl(_value: any) {
        const url = this.dataSource.FOREIGNTABLE;
        let where: any[] = [];

        if (this.defaultWhere) {
            where.push(this.defaultWhere);
        }

        if (this.handleRequest) {
            this.handleRequest.unsubscribe();
        }
        this.switchRequestType()
        this.handleRequest = this.reqService.service.search({
            url,
            where
        }).subscribe((res: any) => {
            if (res.success) {
                const lookup: any[] = [];
                res.features.forEach((item: any) => {
                    const obj: any = { ...item };
                    obj[this.valueField] = this.dataSource.COLUMNKEY ? item[this.dataSource.COLUMNKEY] : item[this.dataSource.COLUMNCODE];
                    obj[this.displayField] = item[this.dataSource.COLUMNDISPLAY];
                    lookup.push(obj);
                });
                this.dataLookup = lookup;

                if (_value !== null) {
                    this.findDataLut(_value);
                } else {
                    this.ctrl.setValue(null);
                    this.checkRequire(null);
                }
            }

            this.handleRequest.unsubscribe();
        }, (err: any) => {
            this.handleRequest.unsubscribe();
        });
    }

    private getLookupFromParent(_value: any) {
        if (_value) {
            const url = this.dataSource.FOREIGNTABLE;
            let key = this.dataSource.WHEREFIELDNAME;
            console.log(_value);
            let where: any[] = [];
            if (_value.__fromParent || this.hasFormCtrl) {
                where = [[key, '=', _value[key]]];
            } else {
                const _key = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE;
                where = [[_key, '=', _value]];
            }

            if (this.defaultWhere) {
                where.push(this.defaultWhere);
            }

            if (this.handleRequest) {
                this.handleRequest.unsubscribe();
            }
            this.switchRequestType()
            this.handleRequest = this.reqService.service.search({
                url,
                where,
                logic: 'and'
            }).subscribe((res: any) => {
                if (res.success) {
                    this.dataLookup = [];
                    const lookup: any[] = [];
                    res.features.forEach((item: any) => {
                        const obj: any = {};
                        obj[this.valueField] = this.dataSource.COLUMNKEY ? item[this.dataSource.COLUMNKEY] : item[this.dataSource.COLUMNCODE];
                        obj[this.displayField] = item[this.dataSource.COLUMNDISPLAY];
                        lookup.push(obj);
                    });
                    this.dataLookup = lookup;
                    this.findDataLut(_value);
                }

                this.handleRequest.unsubscribe();
            });
        } else {
            this.emitReturnObj(null);
            this.ctrl.setValue(null);
            // this.dataLookup = []; // Không set về [] vì nó sẽ sai trong trường hợp thêm mới, tạo lookup đã có sẵn rồi, không reset
            this.checkRequire(null);
        }
    }

    // Trường hợp set giá trị từ key = BindFieldName của trường cha (Giống organization ở quotation) 
    private getLookupWithBindFieldName(_value: any) {
        if (_value) {
            this.findDataLut(_value)
        } else {
            this.ctrl.setValue(null);
            this.checkRequire(null);
        }
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

}
