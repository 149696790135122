<div>Đã kiểm tra</div>

<div class="d-flex pb-3 item-list align-items-center ng-star-inserted"  *ngFor="let item of menuList let i = index"
    >
    <!-- [ngClass]="{'d-none': !item.checked && !showAll}" -->
    <div class="custom-checkbox">
        <p-checkbox [binary]="true" [(ngModel)]="item.checked"
            >
        </p-checkbox>
    </div>
    <div class="card pointer border-0 pl-2"
        >
        <!-- [ngClass]="{'active': _currentItemApplication === item['idTaiSan']}" -->
        <ng-container *ngTemplateOutlet="templateListAplication; context:{ $implicit: item }">
        </ng-container>
    </div>
</div>

<p-button [label]="'Save' | translate"
(click)="onSave()">
</p-button>

<ng-template #templateListAplication let-item>
    <div class='label-box'>
        <div class="headerClass" [ngClass]="{'txt-active': item.isActive}">{{ item.tenHangMuc }}</div>
    </div>
</ng-template>

