import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-core-radio-button',
  templateUrl: './core-radio-button.component.html',
  styleUrls: ['./core-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR
      , useExisting: forwardRef(() => CoreRadioButtonComponent)
      , multi: true
    }
  ]
})
export class CoreRadioButtonComponent implements OnInit, ControlValueAccessor, AfterContentChecked {

  @Output() valueModelChange = new EventEmitter();
  @Output() chkChange = new EventEmitter();
  @Input() hasButton: boolean = false
  @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
  ctrl: FormControl = new FormControl();

  requiredClass = false;
  _required = false;
  @Input('required')
  set required(val: boolean) {
    this._required = val;
    this.requiredClass = val;
    if (this.ctrl) {
      this.ctrl.setValidators(val === true ? [Validators.required] : [Validators.nullValidator]);
    }
  }
  get required() {
    return this._required;
  }

  @Input() showLabel: boolean = true;
  @Input() hasFormCtrl: boolean = true;
  @Input() displayField = 'DESCR';
  @Input() valueField = 'CODE';
  @Input() listRadio: any[] = [];
  @Input() orientation = 'horizontal'; // Tạo list theo hướng dọc hay ngang, giá trị khả dụng: 'horizontal', 'vertical', mặc định là nằm ngang (horizontal)

  _valueModel: any;
  @Input()
  get valueModel() {
    return this._valueModel;
  }

  set valueModel(value) {

    if (value) {
      if (typeof (value) === 'object') {
        this._valueModel = value;
      } else {
        const val = this.listRadio.filter(fil => fil[this.valueField] === value);
        if (val && val.length > 0) {
          this._valueModel = val[0];
        } else {
          this._valueModel = value;
        }
      }
    } else {
      this._valueModel = value;
    }
    this.valueModelChange.emit(this._valueModel);
  }

  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initControl();
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  private initControl() {
    this.ctrl = new FormControl(null, {
      validators: this._required === true ? Validators.required : Validators.nullValidator
    });

    this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
      this.chkChange.emit(val);
      this.onChange(val);
    });
  }

  /** Value truyền vào có thể là dạng object (chứa cả CODE Và DESCR) hoặc chỉ là giá trị CODE */
  writeValue(_value: any): void {
    if (_value) {
      if (typeof (_value) === 'object') {
        this.ctrl.setValue(_value, { emitEvent: false });
      } else {
        const val = this.listRadio.filter(fil => fil[this.valueField] === _value);
        if (val && val.length > 0) {
          this.ctrl.setValue(val[0], { emitEvent: false });
        } else {
          this.ctrl.setValue(null, { emitEvent: false });
        }
      }
    } else {
      this.ctrl.setValue(null, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

}
