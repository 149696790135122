import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { RequestService } from 'app/core/services/request.service';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { User } from './login/login-model';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private reqService: RequestService,
        private router: Router,
        private appService: AppService,
        private http: HttpClient
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authService.currentUserValue;

        // Ghi chú: Khi 1 request gửi lên, kiểm tra xem token còn hạn sử dụng hay không
        // TH1: Nếu token còn thì tiếp tục request
        // TH2: Nếu token hết hạn nhưng tokenRefresh vẫn còn => tạo 1 request để lấy token mới
        // Gửi 1 request tới service để lấy token mới
        // Trước đó phải kiểm tra xem request có phải link service refresh token hay không
        // nếu phải thì tiếp tục request để tránh trường hợp vòng lặp vô hạn ở refresh token
        // sử dụng tap của rxjs để nhận dữ liệu về và chỉnh sửa, lưu trữ dữ liệu
        // sử dụng switchMap của rxjs để tiếp tục request cũ (request mà lúc đó token đang hết hạn) với token mới
        // sử dụng catchError của rxjs để bắt lỗi và trở về trang Login
        // TH3: Nếu cả tokenRefresh cũng hết hạn => logout và trở về trang đăng nhập

        // avoid infinite loop request refresh token when token expired
        // if (request.url.includes('/Login/RefreshToken')) {
        //     return next.handle(request);
        // }

        // avoid infinite loop request Logout when token expired
        // if (request.url.includes('/Login/Logout')) {
        //     return next.handle(request);
        // }

        if (currentUser && currentUser.token && request.url.indexOf('sendmail.esrivn.net') === -1) {
            const a = currentUser.expiresAt ? new Date(currentUser.expiresAt).getTime() - Date.now() : 0;
           // if (a > 0) { // Token còn hạn
                const checkAuth = request.headers.get('Authorization'); // Kiểm tra đã gửi tham số Authorzation chưa, gửi thì dùng token này
                if (checkAuth) {
                    return next.handle(request);
                }  else {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.token}`
                        }
                    });
    
                    return next.handle(request);
                }
            //}
            //  else { // Token hết hạn
            //     const tokenrefresh = currentUser.tokenRefesh;
            //     const url = `${this.appService.urlWS}/Login/RefreshToken`;
            //     const params: any = {};
            //     params['refreshToken'] = tokenrefresh;

            //     return this.reqService.service.query({ url, params, method: 'POST' }).pipe(
            //         tap(response => {
            //             if (response.success) {
            //                 const usermoi = [...response.token];
            //                 const usercu = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);
            //                 const myuser: User = {};
            //                 myuser.schema = usercu.schema;
            //                 myuser.schemadefault = usercu.schemadefault;
            //                 myuser.username = usercu.username;
            //                 myuser.password = usercu.password;
            //                 myuser.Roles = usercu.roles;
            //                 myuser.ClientCode = usercu.clientcode;
            //                 myuser.clientid = usercu.clientid;
            //                 myuser.userid = usercu.userid;
            //                 myuser.token = usermoi[0].token;
            //                 myuser.expiresin = usermoi[0].expiresIn;
            //                 myuser.tokenRefesh = usermoi[0].tokenRefesh;
            //                 const expiresInDuration = usermoi[0].expiresIn * 60;
            //                 const expires = new Date(Date.now() + expiresInDuration * 1000);
            //                 myuser.expiresAt = expires;
            //                 if (localStorage) {
            //                     if (!this.appService.c$) {
            //                         this.appService.c$ = {};
            //                         this.appService.setNowDate();
            //                     }
            //                     this.appService.c$['username'] = myuser.username;
            //                     this.appService.c$['userid'] = myuser.userid;
            //                     this.appService.c$['clientid'] = myuser ? myuser.clientid : null;
            //                     sessionStorage.setItem(this.appService.hostName1 + 'currentUser', JSON.stringify(myuser));
            //                     sessionStorage.setItem(this.appService.hostName1 + 'currentLogin', 'true');
            //                 }

            //                 this.authService.currentUserSubject.next(myuser);
            //                 this.authService.currentLoginSubject.next(true);
            //                 return null;
            //             } else {
            //                 this.authService.logout().subscribe(() => {
            //                     this.router.navigate(['/login']);
            //                 });
            //                 return next.handle(request);
            //             }
            //         }),
            //         switchMap(() => {
            //             request = request.clone({
            //                 setHeaders: {
            //                     Authorization: `Bearer ${this.authService.currentUserValue.token}`
            //                 }
            //             });
            //             return next.handle(request);
            //         }),
            //         catchError((err) => {
            //             this.authService.logout().subscribe(() => {
            //                 this.router.navigate(['/login']);
            //             });
            //             return next.handle(request);
            //         })
            //     );
            // }
        } else {
            return next.handle(request);
        }
    }
}
