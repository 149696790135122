import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from './prime-ng.module';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeService } from './themes/theme.service';
import { ButtonComponent } from './button/button.component';
import { RequestService } from './services/request.service';
import { ArcGIS3XService } from './services/arcgis3x.service';
// import { ArcGISService } from './services/arcgis.service';
import { AutoDataService } from './services/auto-data.service';
import { PostgreService } from './services/postgre.service';
import { SqlService } from './services/sql-odata.service';
import { CoreHeaderComponent } from './core-header/core-header.component';
import { CoreTabComponent } from './core-tab/core-tab.component';
import { CoreWindowComponent } from './core-window/core-window.component';
import { CoreFormComponent } from './core-form/core-form.component';
import { CoreTableComponent } from './core-table/core-table.component';
import { SelectComponent } from './select/select.component';
import { CoreWindowService } from './core-window/core-window.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogPrimeComponent } from './dialog-prime/dialog-prime.component';
import { LoadingComponent } from './loading/loading.component';
import { CoreTreeComponent } from './core-tree/core-tree.component';
import { ConfirmationService } from 'primeng/api';
import { CoreCheckboxComponent } from './core-checkbox/core-checkbox.component';
import { SearchDataComponent } from './core-table/search-data/search-data.component';
import { SearchBasicComponent } from './core-table/search-data/search-basic/search-basic.component';
import { SearchAdvanceComponent } from './core-table/search-data/search-advance/search-advance.component';
import { SearchMixComponent } from './core-table/search-data/search-mix/search-mix.component';
import { CoreDatetimeComponent } from './core-datetime/core-datetime.component';
import { CoreBasicTableComponent } from './core-basic-table/core-basic-table.component';
import { CoreSearchComponent } from './core-search/core-search.component';
import { CoreRadioButtonComponent } from './core-radio-button/core-radio-button.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { FormEditCalendarComponent } from './form-edit-calendar/form-edit-calendar.component'; // form edit calendar
import { CoreQrCodeComponent } from './core-qr-code/core-qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PassChangeComponent } from './pass-change/pass-change.component';
import { CoreTreeTableComponent } from './core-tree-table/core-tree-table.component';
import { CoreColorPickerComponent } from './core-color-picker/core-color-picker.component';
import { MenuShortcutComponent } from './menu-shortcut/menu-shortcut.component';
import { CoreWhereclauseComponent } from './core-whereclause/core-whereclause.component';
import { CoreTreeSelectComponent } from './core-tree-select/core-tree-select.component';
import { CoreStateCheckboxComponent } from './core-state-checkbox/core-state-checkbox.component';
import { CoreAttachmentComponent } from './core-attachment/core-attachment.component';
import { OracleService } from './services/oracle.service';
import { CoreImportDataComponent } from './core-import-data/core-import-data.component';
import { DataTableServiceComponent } from './data-table-service/data-table-service.component';
import { DashboardBuilderService } from 'app/dashboard-builder/services/dashboard-builder.service';
import { AttachmentsComponent } from './attachments/attachments.component';


const comp = [
  InputComponent,
  InputSearchComponent,
  ButtonComponent,
  CoreHeaderComponent,
  CoreFormComponent,
  CoreTabComponent,
  CoreTableComponent,
  CoreWindowComponent,
  SelectComponent,
  DialogPrimeComponent,
  LoadingComponent,
  CoreTreeComponent,
  CoreCheckboxComponent,
  SearchDataComponent,
  SearchBasicComponent,
  SearchAdvanceComponent,
  SearchMixComponent,
  CoreDatetimeComponent,
  CoreBasicTableComponent,
  CoreSearchComponent,
  CoreRadioButtonComponent,
  CoreQrCodeComponent,
  CoreTreeTableComponent,
  FormEditCalendarComponent,
  PassChangeComponent,
  CoreColorPickerComponent,
  MenuShortcutComponent,
  CoreWhereclauseComponent,
  CoreTreeSelectComponent,
  CoreStateCheckboxComponent,
  CoreAttachmentComponent,
  CoreImportDataComponent,
  DataTableServiceComponent,
  AttachmentsComponent
];

@NgModule({
  declarations: comp,
  exports: [comp, PrimeNgModule],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    TranslateModule,
    QRCodeModule
  ],
  providers: [
    ThemeService,
    RequestService,
    ArcGIS3XService,
    // ArcGISService,
    AutoDataService,
    PostgreService,
    SqlService,
    OracleService,
    CoreWindowService,
    ConfirmationService,
    DashboardBuilderService
  ]
})
export class CoreModule { }
