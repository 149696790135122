import { Injectable, Renderer2 } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { interval, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from 'app/app-base/auth.service';
import { AppService } from './app.service';
import { ThemeService } from 'app/core/themes/theme.service';
import { RequestService } from 'app/core/services/request.service';
import { PushNotificationService } from 'app/notification/notification.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    handleInterval = null;
    globalListenFunc = null;

    constructor(
        private appService: AppService,
        private authService: AuthService,
        private themeService: ThemeService,
        private reqService: RequestService,
        private router: Router,
        private pushNotificationService: PushNotificationService
    ) { 
        window['_reqService'] = this.reqService;
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.checkLogin(state.url);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    private checkLogin(url: string): Observable<boolean> {
        return this.authService.isLoggedIn(url).pipe(
            map((loggedIn) => {
                this.reqService.switchType(this.appService.dataAccessType);
                if (!loggedIn) {
                    if (localStorage.getItem('license')) {
                        if (localStorage.getItem('license') === 'ABCD') {
                            loggedIn = true;
                            this.router.navigate(['/license']);
                        } else {
                            // Store the attempted URL for redirecting
                            this.authService.redirectUrl = url;
                            if (this.appService.c$) {
                                delete this.appService.c$['username'];
                                delete this.appService.c$['userid'];
                                delete this.appService.c$['clientid'];
                            }

                            // Navigate to the login page with extras
                            this.router.navigate(['/login']);
                        }
                    }
                    else {
                        // Store the attempted URL for redirecting
                        this.authService.redirectUrl = url;
                        if (this.appService.c$) {
                            delete this.appService.c$['username'];
                            delete this.appService.c$['userid'];
                            delete this.appService.c$['clientid'];
                        }

                        // Navigate to the login page with extras
                        this.router.navigate(['/login']);
                    }
                } else {
                    //   this.checkSessionTimeout();
                    if (localStorage.getItem('license')) {
                        if (localStorage.getItem('license') === 'ABCD') {
                        }
                    } else {
                        const a = this.appService.currentUser;
                        const myuser = a ? JSON.parse(a) : null;
                        if (!this.appService.c$) {
                            this.appService.c$ = {};
                            this.appService.setNowDate();
                            this.appService.c$.dateFormat = 'dd/mm/yy';
                        }
                        this.appService.c$['username'] = myuser ? myuser.username : null;
                        this.appService.c$['userid'] = myuser ? myuser.userid : null;
                        this.appService.c$['clientid'] = myuser ? myuser.clientid : null;
                        let staffid = null;
                        try {
                            staffid = JSON.parse(myuser.staffid);
                        } catch (error) {
                            
                        }
                        this.appService.c$['staffid'] = staffid;
                        this.pushNotificationService.sendDataToServiceWorker();
                        // this.themeService.setActiveThemeUM(null);
                        // if (this.appService.appConfig) {
                        //     if (this.appService.appConfig.objTheme) {
                        //         this.themeService.setActiveTheme(this.appService.appConfig.objTheme.sysThemeId);
                        //     } else {
                        //         this.themeService.setActiveTheme(1);
                        //     }
                        // }
                    }

                }
                return loggedIn;
            }), catchError(this.handleSearchError)
        );
    }

    private handleSearchError(err: any) {
        return of(false);
    }

    // private checkSessionTimeout() {
    //     if (this.handleInterval) {
    //         clearInterval(this.handleInterval);
    //     }

    //     let clockSession = 0;
    //     this.globalListenFunc = document.addEventListener('mousemove', () => {
    //         clockSession = 0;
    //     });

    //     this.handleInterval = setInterval(() => {
    //         clockSession++;
    //         // clear session time out every 30 second (provide in environment)
    //         if (clockSession === environment.clockTimeout * 60) {
    //             clearInterval(this.handleInterval);
    //             this.appService.alert(this.appService.getMessage('017')).subscribe((a: boolean) => {
    //                 this.authService.logout().subscribe((response) => {
    //                     this.router.navigate(['/']);
    //                 });
    //             });
    //         }
    //     }, 1000);

    //     const eventMethod = window.addEventListener
    //         ? 'addEventListener'
    //         : 'attachEvent';
    //     const eventer = window[eventMethod];
    //     const messageEvent =
    //         eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    //     eventer(
    //         messageEvent,
    //         (msg) => {
    //             clockSession = 0;
    //         },
    //         false
    //     );
    // }

}
