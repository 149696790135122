// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // clockTimeout: 30,
  // dataAccess: 'sql', // arcgis or postgre or sql
  // mapType: 'arcgis', // arcgis or geoserver or ....
  // c$: 'c$', // Biến toàn cục để eval
  // environmentPaymentGooglePay: 'TEST',  // Môi trường google payment

  // Môi trường dev 1: máy 60
  urlProxy: 'https://water.esrivn.net/enterprisecloud/',
  wsUrl: 'https://water.esrivn.net/enterprisecloud/odata',
  wsUrlQutation: 'https://water.esrivn.net/enterprisecloud/odataQuotation',
  urlInit: 'https://water.esrivn.net/enterprisecloud',
  urlReport: 'https://water.esrivn.net/coretech/',
  // urlReport: 'https://water.esrivn.net/coretech/',
  urlWorkflow: 'https://water.esrivn.net/enterprisecloud/odataworkflow/',
  urlOdataAttachment: 'https://water.esrivn.net/enterprisecloud',
  urlAutoData: 'https://water.esrivn.net/enterprisecloud/auto',
  templateClientId: 39,
  applicationServerPublicKey: 'BF8q2TSw2AWY0al5D6KNmhysNx4qQ9vpYm6hea8r0drnYx6Rm2ZyHiYPR9fVn-1_EY-HhCeCvNSssx0VovLaews'

  //   // // may local oracle
  //   urlProxy: 'http://localhost/CoreTechApiOracle/',
  // wsUrl: location.protocol + '//' + location.hostname + '/CoreTechApiOracle/odata',
  // wsUrlQutation: location.protocol + '//' + location.hostname + '/CoreTechApiOracle/odataQuotation',
  // urlInit: location.protocol + '//' + location.hostname + '/CoreTechApiOracle',
  // urlReport: location.protocol + '//' + location.hostname + '/CoreTechApiOracle/',
  // urlWorkflow: 'http://localhost/CoreTechApiOracle/odataworkflow/',
  // urlOdataAttachment: location.protocol + '//' + location.hostname + '/CoreTechApiOracle',
  // urlAutoData: 'http://localhost/CoreTechApiOracle/auto',


  // Môi trường dev 1: máy luci
  // urlProxy: 'https://iocmeyhome.luci.vn/CoreTechApi/',
  // wsUrl: 'https://iocmeyhome.luci.vn/CoreTechApi/odata',
  // wsUrlQutation: 'https://iocmeyhome.luci.vn/CoreTechApi/odataQuotation',
  // urlInit: 'https://iocmeyhome.luci.vn/CoreTechApi',
  // urlReport: 'https://iocmeyhome.luci.vn/coretech/',
  // urlWorkflow: 'https://iocmeyhome.luci.vn/CoreTechApi/odataworkflow/',
  // urlOdataAttachment: 'https://iocmeyhome.luci.vn/CoreTechApi',
  // urlAutoData: 'https://iocmeyhome.luci.vn/CoreTechApi/auto',

  // Môi trường dev 1: máy 60 Cloud
  // urlProxy: 'https://water.esrivn.net/CloudCoreTechApi/',
  // wsUrl: 'https://water.esrivn.net/CloudCoreTechApi/odata',
  // wsUrlQutation: 'https://water.esrivn.net/CloudCoreTechApi/odataQuotation',
  // urlInit: 'https://water.esrivn.net/CloudCoreTechApi',
  // urlWorkflow: 'https://water.esrivn.net/CloudCoreTechApi/odataworkflow/',
  // urlReport: 'https://water.esrivn.net/coretech/',
  // urlOdataAttachment: 'https://water.esrivn.net/CloudCoreTechApi',

  // // Môi trường dev 2: Máy 66
  // urlProxy: 'https://coretech.vn:1314/',
  // wsUrl: 'https://coretech.vn:1314/odata',
  // wsUrlQutation: 'https://coretech.vn:1314/odataQuotation',
  // urlInit: 'https://coretech.vn:1314',
  // urlWorkflow: 'https://coretech.vn:1314/odataworkflow/',
  // urlReport: 'https://coretech.vn:1313/',
  // urlOdataAttachment: 'https://coretech.vn:1314',
  // //urlAutoData: 'https://devgeo.esrivn.net/coretech/auto',
  // urlAutoData: 'https://coretech:1314/auto',

  // // Môi trường dev 3: Máy các nhân (localhost)
  // urlProxy: 'http://localhost/CoreTechApi/',
  // wsUrl: location.protocol + '//' + location.hostname + '/CoreTechApi/odata',
  // wsUrlQutation: location.protocol + '//' + location.hostname + '/CoreTechApi/odataQuotation',
  // urlInit: location.protocol + '//' + location.hostname + '/CoreTechApi',
  // urlReport: location.protocol + '//' + location.hostname + '/CoreTech2/',
  // urlWorkflow: 'http://localhost/CoreTechApi/odataworkflow/',
  // urlOdataAttachment: location.protocol + '//' + location.hostname + '/CoreTechApi',
  // urlAutoData: 'http://localhost/CoreTechApi/auto',

  // Môi trường dev 3: Máy các nhân (localhost) CLOUD
  // urlProxy: 'http://localhost/CloudCoreTechApi/',
  // wsUrl: location.protocol + '//' + location.hostname + '/CloudCoreTechApi/odata',
  // wsUrlQutation: location.protocol + '//' + location.hostname + '/CloudCoreTechApi/odataQuotation',
  // urlInit: location.protocol + '//' + location.hostname + '/CloudCoreTechApi',
  // urlReport: location.protocol + '//' + location.hostname + '/cloudesrivn/',
  // urlWorkflow: 'http://localhost/CoreTechApi/odataworkflow/',
  // urlOdataAttachment: location.protocol + '//' + location.hostname + '/CloudCoreTechApi',


  // wsUrl: `${location.protocol}//${location.hostname}:${location.port}/CoreTechApi/odata`,
  // wsUrlQutation: `${location.protocol}//${location.hostname}:${location.port}/CoreTechApi/odataQuotation`,
  // urlInit: `${location.protocol}//${location.hostname}:${location.port}/CoreTechApi`,
  // urlReport: `${location.protocol}//${location.hostname}:${location.port}/CoreTech/`

  // ubuntu
  // urlProxy: 'https://192.168.20.16:8443/',
  // wsUrl: 'https://192.168.20.16:8443/odata',
  // wsUrlQutation: 'https://192.168.20.16:8443/odataQuotation',
  // urlInit: 'https://192.168.20.16:8443',
  // urlReport: 'https://192.168.20.16:8399/'
  // urlProxy: 'http://localhost:8098/',
  // wsUrl: 'http://localhost:8098/odata',
  // wsUrlQutation: 'http://localhost:8098/odataQuotation',
  // urlInit: 'http://localhost:8098',
  // urlReport: 'http://localhost:8098/'

  // urlProxy: 'https://cloud.applicationjs.com/',
  // wsUrl: 'https://cloud.applicationjs.com/odata',
  // wsUrlQutation: '',
  // urlInit: 'https://cloud.applicationjs.com',
  // urlReport: 'http://cloud.applicationjs.com/wwwroot/',
  // urlOdataAttachment: 'https://cloud.applicationjs.com',
  // urlWorkflow: 'https://cloud.applicationjs.com/odataworkflow/',
  // urlAutoData: 'https://cloud.applicationjs.com/auto',
  // templateClientId: 44,

  // urlProxy: 'https://coretech.esrivn.net:1371',
  // wsUrl: 'https://coretech.esrivn.net:1371/odata',
  // wsUrlQutation: 'https://coretech.esrivn.net:1371/odataQuotation',
  // urlInit: 'https://coretech.esrivn.net:1371',
  // urlReport: 'https://coretech.esrivn.net:1371/wwwroot/',
  // urlOdataAttachment: 'https://coretech.esrivn.net:1371',
  // urlWorkflow: 'https://coretech.esrivn.net:1371/odataworkflow/',
  // urlAutoData: 'https://coretech.esrivn.net:1371/auto',
  //Phu quoc api
  // urlProxy: 'https://water.esrivn.net/phuquoc/',
  // wsUrl: 'https://water.esrivn.net/phuquoc/odata',
  // wsUrlQutation: 'https://water.esrivn.net/phuquoc/odataQuotation',
  // urlInit: 'https://water.esrivn.net/phuquoc',
  // urlWorkflow: 'https://water.esrivn.net/phuquoc/odataworkflow/',
  // urlReport: 'https://water.esrivn.net/coretech/',
  // urlOdataAttachment: 'https://water.esrivn.net/phuquoc',
  // urlAutoData: 'http://localhost/CoreTechApi/auto',
  // wsUrlPortal: 'https://gis1101.esrivn.net/portal/',

  // Môi trường dev 1: máy 60 enterprise cloud
  // urlProxy: 'https://water.esrivn.net/CoreTechApiOracle/',
  // wsUrl: 'https://water.esrivn.net/CoreTechApiOracle/odata',
  // wsUrlQutation: 'https://water.esrivn.net/CoreTechApiOracle/odataQuotation',
  // urlInit: 'https://water.esrivn.net/CoreTechApiOracle',
  // // urlReport: 'https://water.esrivn.net/enterprisecloud/',
  // urlReport: location.protocol + '//' + location.hostname + '/CoreTech2/',
  // urlWorkflow: 'https://water.esrivn.net/CoreTechApiOracle/odataworkflow/',
  // urlOdataAttachment: 'https://water.esrivn.net/CoreTechApiOracle',
  // urlAutoData: 'https://water.esrivn.net/CoreTechApiOracle/auto',
  //templateClientId: 39,




  // may local sql enterprise 
  // urlProxy: 'http://localhost/enterprisecloud/',
  // wsUrl: location.protocol + '//' + location.hostname + '/enterprisecloud/odata',
  // wsUrlQutation: location.protocol + '//' + location.hostname + '/enterprisecloud/odataQuotation',
  // urlInit: location.protocol + '//' + location.hostname + '/enterprisecloud',
  // urlReport: location.protocol + '//' + location.hostname + '/enterprisecloud/',
  // urlWorkflow: 'http://localhost/enterprisecloud/odataworkflow/',
  // urlOdataAttachment: location.protocol + '//' + location.hostname + '/enterprisecloud',
  // urlAutoData: 'http://localhost/enterprisecloud/auto',

};
