<div class="d-flex" [ngClass]="{'NoLabelLeft': !isLabelLeft}">
    <div *ngIf="showLabel" class="labelInputClass" [style.maxWidth.px]="labelWidth" [ngClass] = "{'label-readonly': isReadOnly}">{{labelName}}
        <span *ngIf="required" [style.color]="'red'">&nbsp;*</span>
    </div>
    <div class="divControl">
        <ng-container *ngIf="hasFormCtrl">
            <div class="p-inputgroup inputControl" [ngClass]="{'hide-description': dataSource && !dataSource.COLUMNDISPLAY}">
                <p-autoComplete [formControl]="ctrl" [ngClass]="{'input-readonly': isReadOnly}"
                    [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly" [placeholder]="placeholder"
                    [required]="required" [suggestions]="results" [multiple]="false" [appendTo]="'body'"
                    [showEmptyMessage]="true" (completeMethod)="search($event)" (onBlur)="onAutoCompleteBlur($event)"
                    [field]="dataSource.COLUMNCODE ? dataSource.COLUMNCODE : dataSource.COLUMNKEY" (onSelect)="onDropdownClick($event)">
                    <ng-template let-res pTemplate="item">
                        <div style="display: flex; align-items: center;">
                            <span style="padding-left: 5px; color: #828282; font-style: italic;">{{res.DESCR}}</span>
                        </div>
                    </ng-template>
                </p-autoComplete>
                <span class="p-inputgroup-addon icn-open-form" (click)="onOpenForm()">
                    <i class="pi pi-ellipsis-h"></i>
                </span>
                <ng-container *ngIf="dataSource && dataSource.FOREIGNWINDOW_ID">
                    <span class="p-inputgroup-addon icn-open-window" (click)="openWindowToAdd()">
                        <i class="pi pi-plus"></i>
                    </span>
                </ng-container>
            </div>
            <div class="description" [ngClass]="{'hide-description': dataSource && !dataSource.COLUMNDISPLAY}">
                <div class="txt-descr" [title]="dataDescription || '-/-'">{{dataDescription || "-/-" }}</div>
            </div>


        </ng-container>
        <ng-container *ngIf="!hasFormCtrl">
            <div class="p-inputgroup inputControl" [ngClass]="{'hide-description': dataSource && !dataSource.COLUMNDISPLAY}">
                <p-autoComplete [(ngModel)]="valueModel" [ngClass]="{'input-readonly': isReadOnly}"
                    [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly" [placeholder]="placeholder"
                    [required]="required" [suggestions]="results" [multiple]="false" [appendTo]="'body'"
                    [showEmptyMessage]="true" (completeMethod)="search($event)" (onBlur)="onAutoCompleteBlur($event)"
                    [field]="dataSource.COLUMNCODE ? dataSource.COLUMNCODE : dataSource.COLUMNKEY" (onSelect)="onDropdownClick($event)">
                    <ng-template let-res pTemplate="item">
                        <div style="display: flex; align-items: center;">
                            <span style="padding-left: 5px; color: #828282; font-style: italic;">{{res.DESCR}}</span>
                        </div>
                    </ng-template>
                </p-autoComplete>
                <span class="p-inputgroup-addon icn-open-form" (click)="onOpenForm()">
                    <i class="pi pi-ellipsis-h"></i>
                </span>
                <ng-container *ngIf="dataSource && dataSource.FOREIGNWINDOW_ID">
                    <span class="p-inputgroup-addon icn-open-window" (click)="openWindowToAdd()">
                        <i class="pi pi-plus"></i>
                    </span>
                </ng-container>
            </div>
            <div class="description" [ngClass]="{'hide-description': dataSource && !dataSource.COLUMNDISPLAY}">
                <div class="txt-descr" [title]="dataDescription || '-/-'">{{dataDescription || "-/-" }}</div>
            </div>
        </ng-container>
    </div>
</div>


<app-dialog-prime #dialogComponent [isAppendBody]="true" [widthPanel]="'60vw'"></app-dialog-prime>