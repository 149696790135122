import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { OdataParams, RequestService } from 'app/core/services/request.service';
import { ReportViewerComponent } from 'app/reports/report-viewer/report-viewer.component';

@Component({
  selector: 'app-letter-form',
  templateUrl: './letter-form.component.html',
  styleUrls: ['./letter-form.component.scss']
})
export class LetterFormComponent implements OnInit {
  formLetter: FormGroup | any;
  modeSub: 'A' | 'E' = 'A';
  currentQuotationId: any = null;
  currentReportId: any = null;
  valueLetter: any = null;
  letterId: any = null;
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('containerRef', { static: false, read: ViewContainerRef }) containerRef: ViewContainerRef | any;
  constructor(
    public reqService: RequestService,
    public appService: AppService,
    private resolver: ComponentFactoryResolver,
  ) {

  }

  ngOnInit(): void {
  }
  initForm() {
    this.formLetter = new FormGroup({
      tenGoiThau: new FormControl(''),
      tenNhaThau: new FormControl(''),
      diaChiNhaThau: new FormControl(null),
      chuDauTu: new FormControl(null)
    });
  }
  run(data: any, isReload: any) {
    if (!isReload) {
      this.initForm();
    }
    if(data.currentData.length > 1) {
      this.onCloseEvent.emit(false);
      this.appService.createMessage("warning", "Select only 1 quotaion");
    } else {
      this.currentQuotationId = data.currentData[0].QuotationId;
      this.currentReportId = Number(data.menu.exttype);
      const param = ['QuotationId', '=', data.currentData[0].QuotationId];
      this.reqService.service.search(
        {
          url: this.appService.urlInitQutation + '/Letters',
          where: param
        }
      ).subscribe(res => {
        if (res.success) {
          if (res.features.length > 0) {
            this.modeSub = 'E';
            this.letterId = res.features[0].LetterId;
            this.valueLetter = {
              tenGoiThau: res.features[0].TenGoiThau,
              tenNhaThau: res.features[0].TenNhaThau,
              diaChiNhaThau: res.features[0].DiaChiNhaThau,
              chuDauTu: res.features[0].ChuDauTu
            };
            this.formLetter.setValue(this.valueLetter);
          } else {
            this.modeSub = 'A';
            this.formLetter.reset();
            this.letterId = null;
          }
        }
  
      });
    }

    
  }
  onResetForm() {
    if (this.modeSub === 'A') {
      this.formLetter.reset();
    } else {
      this.formLetter.reset(this.valueLetter);
    }

  }
  onSaveLetter() {
    const params = this.appService.returnParam(this.formLetter);
    params['quotationId'] = this.currentQuotationId;
    const paramLetter: OdataParams = {
      url: this.appService.urlInitQutation + '/Letters',
      data: params
    }
    this.reqService.service.insert(paramLetter).subscribe(resp => {
      if (resp.success) {
        this.appService.createMessage(
          'success',
          this.appService.getMessage('0001')
        );
        this.run(this.currentQuotationId, true);
      } else {
        this.appService.createMessage(
          'error',
          this.appService.getMessage('0002')
        );
      }
    })
  }
  onEditLetter() {
    const params = this.appService.returnParam(this.formLetter);
    params['quotationId'] = this.currentQuotationId;
    params['LetterId'] = this.letterId;
    const paramLetter: OdataParams = {
      url: this.appService.urlInitQutation + '/Letters',
      primaryKey: 'LetterId',
      data: params
    }
    this.reqService.service.update(paramLetter).subscribe(resp => {
      if (resp.success) {
        this.appService.createMessage(
          'success',
          this.appService.getMessage('0007')
        );
        this.run(this.currentQuotationId, true);
      } else {
        this.appService.createMessage(
          'error',
          this.appService.getMessage('0008')
        );
      }
    })
  }

  // Report
  onPrintLetter() {
    const paramWindows: OdataParams = {
      url: this.appService.urlWS + '/SysWindows',
      where: ['WindowId', '=', this.currentReportId]
    }
    this.reqService.service.search(paramWindows).subscribe(resp => {
      if (resp.success) {
        const compReport = this.renderComponent();
        const data = {
          currentData: {
            QuotationId: this.currentQuotationId
          },
          report: resp.features[0]
        };
        if (compReport) {
          compReport.instance.run(data);
        }


        // console.log(resp);
        // var params = JSON.parse(resp.features[0].ExtType);
        // params.QuotationId = this.currentQuotationId;
        // const reportParams = JSON.stringify(params);
        // const url = `${this.appService.urlReport}report/loadreport/${reportParams}`;
        // window.open(url, '_blank');
      }
    })
  }

  private renderComponent(): any {
    try {
      if (this.containerRef) { this.containerRef.clear(); }
      const componentFactory = this.resolver.resolveComponentFactory(ReportViewerComponent);
      const componentRef = this.containerRef.createComponent(componentFactory);
      return componentRef;
    } catch (error) {
      return null;
    }
  }
}
