<div class="core-header">
    <app-core-header></app-core-header>
</div>

<div class="wp">
    <div class="wf-group" [ngStyle] = "{'width': isExpanded ? '30%' : '0px'}">
        <div class="div-tree-container-class" *ngIf="isExpanded">
            <div class="list-header">
                <div class="header-delete">
                    <div (click)="onResultListInsert()" class="divAddClass">
                        <!-- <i nz-icon nzType="plus-circle"></i> -->
                        <div class="icn-add-wf"></div>
                        <label class="label-add">{{'Add workflow' | translate}}</label>
                    </div>
                    <span class="label-total">{{'Total' | translate}}: {{totalJobType}} {{'record' | translate}}</span>
                </div>
            </div>
            <div class="wp-menu">
                <app-core-tree #treeWf [dataTree]="arrWorkflow" [templateRef]="default" [treeConfig]="treeConfig"
                    [hasTemplate]="true"></app-core-tree>
                <ng-template #default let-node="treenode">
                    <div class="div-nodecustom-class" style="cursor: pointer;" (click)="onClickEdit($event, node)">
                        <div class="div-titlenode-class">
                            <!-- <div class="div-icontitle-class">
                                <i *ngIf="!node.expanded" class="icon-title-class pi pi-folder" style="font-size: var(--font_size_big); color: var(--color_text);"
                                    ></i>
                                <i *ngIf="node.expanded" class="icon-title-class pi pi-folder-open" style="font-size: var(--font_size_big); color: var(--color_text);"
                                    ></i>
                            </div> -->
                            <div class="div-nodetitle-class">
                                <span>
                                    {{ node.label }}
                                </span>

                            </div>
                        </div>
                        <div class="div-btnnode-class">
                            <div class="div-iconbtn-class" style="justify-content: center;">
                                <i class="pi pi-plus-circle btn-tree-class" *ngIf="!node.isLeaf"
                                    (click)="onClickAdd($event, node)"></i>
                            </div>
                            <div class="div-iconbtn-class" style="justify-content: flex-end;">
                                <i class="pi pi-minus-circle btn-tree-class" (click)="onClickDeleteItem($event, node)"
                                    *ngIf="node.isLeaf"></i>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="div-panel-expand-class" [ngStyle]="{'margin-left': isExpanded ? 0 : '-10px'}">
            <div class="panel-expand-class" (click)='onExpandPanel()'>
                <i class="pi" [ngClass]='{"pi-chevron-left": isExpanded, "pi-chevron-right": !isExpanded}'></i>
            </div>
        </div>

    </div>
    <!-- <div class="wf-content-move"  [class.hiddenContent]="!showLeftPanel" (click)="onShowHideLeftPanel()">
        <i class="pi pi-chevron-left"></i>
    </div> -->
    <div class="wp-workflow" [ngStyle] = "{'width': isExpanded ? '70%' : 'calc(100% - 15px + 10px)'}">
        <div class="content-workflow" [ngClass]="{'d-none': !isShowDesignWf}">
            <div class="dgn-workflow" #wpWorkflow>
                <div #workflow style="width: 70%; height: 100%; background: #fff;"> </div>
                <div class="tool-wf">
                    <i class="pi pi-folder-open" title="open BPMN diagram from local file system"
                        (click)="ipFile.click()">
                        <input type="file" #ipFile (change)="onChangeFile($event)" style="display: none">
                    </i>
                    <i class="pi pi-arrow-circle-down" title="Dowload as BPMN file" (click)="exportBPMN()"></i>
                    <i class="pi pi-image" title="Dowload as SVG image" (click)="exportSVG()"></i>
                </div>
                <div class="tool-wf-1">
                    <i class="pi pi-book" title="Toggle keyboard shortcuts overlay"></i>
                    <i class="pi pi-window-maximize" title="Toggle Fullscreen" (click)="onScreen()"></i>
                </div>
                <div class="infoStep" [formGroup]="formDataStep">
                    <div class="content-step">
                        <div class="title-form-step">{{'Detailed Step Properties' | translate}}</div>
                        <app-input  [labelName]="'Step name'" [required]="true" formControlName="stepName" [type] ="'text'"  [disabledCtrl]="true"
                        [maxlength]="250" [isLabelLeft]="false"></app-input>
                        <app-input  [labelName]="'Step Duration'" [required]="false" formControlName="stepDuration" [type] ="'number'"  [disabledCtrl]="isDisDurationStep"
                            [maxlength]="250" [isLabelLeft]="false"></app-input>
                        <label style="margin-top: 10px">{{'Assignment' | translate}}</label>
                        <app-core-radio-button [listRadio]="listRadio" formControlName="assignOwner"></app-core-radio-button>
                        <div *ngIf="isShowUserStep" style="margin-top: 5px">
                            <app-input [disabledCtrl]="false" [required]="false" [showLabel]="false"
                                [isLabelLeft]="false" formControlName="assignment" type="text-button"
                                (iconClick)="selectUserStep()" [maxlength]="2000"></app-input>
                        </div>
                        <div *ngIf="isShowExternal">
                            <app-select [labelName]="'External workflow'" [required]="false"
                                formControlName="externalWorkflow" [dataLookup]="arrExternalWorkflow"
                                [isLabelLeft]="false" displayField="DESCR" valueField="CODE"></app-select>
                            <app-select [labelName]="'External step'" [required]="false" formControlName="externalStep"
                                [dataLookup]="arrExternalStep" [isLabelLeft]="false" displayField="DESCR"
                                valueField="CODE"></app-select>
                        </div>
                        <div style="margin-top: 10px;">
                            <app-core-checkbox  (chkChange)="onChangAssign($event)" [hasFormCtrl]="true" formControlName ="changeAssign" [chkLabel]="'Recipient lock'" [typeValue]="'boolean'"></app-core-checkbox>

                            <div style="display: flex;">
                                <app-core-checkbox (chkChange)="onChkChangeTable($event)" [hasFormCtrl]="false"
                                [(valueModel)]="isStepApprove" [chkLabel]="'Need approve'" [typeValue]="'boolean'"></app-core-checkbox>
                                <app-core-checkbox (chkChange)="onChangeAPBack($event)" [hasFormCtrl]="false"
                                [(valueModel)]="isStepApproveNoBack" [chkLabel]="'Submit after approval'" [typeValue]="'boolean'"></app-core-checkbox>
                            </div>

                            <ng-container *ngIf="isShowFormApprove">
                                <app-select #typeApprove [labelName]="'Type Approve'" [required]="requiredFormApprove"
                                    formControlName="typeApprove" [dataLookup]="arrTypeApprove" [isLabelLeft]="false"
                                    displayField="DESCR" valueField="CODE"></app-select>
                                <app-input [required]="requiredFormApprove" labelName="User Approve" [isLabelLeft]="false"
                                    formControlName="userApprove" type="text-button" (iconClick)="selectUserApprove()"
                                    [maxlength]="1000"></app-input>
                                <app-select #typeApprove [labelName]="'Table Approve'" [required]="requiredFormApprove"
                                    formControlName="tableIdApprove" [dataLookup]="arrTable" [isLabelLeft]="false"
                                    displayField="DESCR" valueField="CODE"></app-select>
                                <app-select #typeApprove [labelName]="'Column Approve'" [required]="requiredFormApprove"
                                    formControlName="column" [dataLookup]="arrColumnApprove" [isLabelLeft]="false"
                                    displayField="DESCR" valueField="CODE"></app-select>
                                <app-input #valueApprove [labelName]="'Value Approve'" [required]="requiredFormApprove"
                                    formControlName="valueApprove" [isLabelLeft]="false"></app-input>
                            </ng-container>

                        </div>
                        <ng-container style="margin-top: 10px;">
                            <app-core-checkbox (chkChange)="onChkSendMail($event)" [hasFormCtrl]="false"
                            [(valueModel)]="isSendMail" [chkLabel]="'Send email'" [typeValue]="'boolean'">
                             </app-core-checkbox>
                             <ng-container *ngIf="isSendMail">
                                <app-select [labelName]="'Column Email'" formControlName="columnEmail" [dataLookup]="arrColumnEmail" [isLabelLeft]="false"
                                displayField="DESCR" valueField="CODE"></app-select>
                                <app-input [type]="'text-button'" [labelName]="'Teamplate'  | translate" [isLabelLeft]="false"
                                [isCtrlBlur]="false" formControlName="templateEmail" (iconClick)="showDialogEmail()"></app-input>
                             </ng-container>

                        </ng-container>

                        <div class="btnSaveStep">
                            <app-button style="margin-top:10px" [labelName]="'Save' | translate" typeButton="save"
                                icon='pi pi-save' [ngClass]="{'disBtnSaveStep': isDisBtnSaveStep}"
                                (clickEvent)="onSaveStep()"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-wf" [ngClass]="{'d-none': !isShowFormWf}">
            <form [formGroup]="formData" class="divFormClass">
                <div class="row user-form-row">
                    <div class="col-md-6 d-flex" style="padding:0">
                        <div class="col-md-6">
                            <app-input [disabledCtrl]="true" [labelName]="'Id'" [required]="false"
                                formControlName="JobTypeId" [isLabelLeft]="false"></app-input>
                        </div>
                        <div class="col-md-6">
                            <app-input #workflowName [labelName]="'Workflow name'" [required]="true"
                                formControlName="JobTypeName" [isLabelLeft]="false"></app-input>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <app-input #Description [labelName]="'Description'" [required]="false"
                            formControlName="Description" [isLabelLeft]="false"></app-input>
                    </div>
                </div>
                <div class="row user-form-row">
                    <div class="col-md-6 d-flex" style="padding:0">
                        <div class="col-md-6">
                            <app-input #NumDayComplete [labelName]="'Duration'" [required]="true"
                                formControlName="NumDayComplete" [type]="'number'" [isLabelLeft]="false"></app-input>
                        </div>
                        <div class="col-md-6">
                            <app-select  [labelName]="'Duration unit'" [required]="true"
                                formControlName="TimeUnit" [dataLookup]="arrTimeUnit" [isLabelLeft]="false"
                                displayField="DESCR" valueField="CODE"></app-select>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex" style="padding:0">
                        <div class="col-md-6">
                            <app-select [labelName]="'Application'" [isLabelLeft]="false" [required]="false"
                                formControlName="ApplicationId" [dataLookup]="arrApplication" displayField="DESCR"
                                [required]="true" valueField="CODE">
                            </app-select>
                        </div>
                        <div class="col-md-6">
                            <app-select [labelName]="'Window'" [isLabelLeft]="false" [required]="false"
                                formControlName="WindowId" [isFilter]="true" [dataLookup]="arrWindow"
                                displayField="DESCR" [required]="true" valueField="CODE">
                            </app-select>
                        </div>
                    </div>

                </div>
                <div class="row user-form-row">
                    <div class="col-md-6">
                        <app-input [required]="true" labelName="User" [isLabelLeft]="false"
                            formControlName="UserJobtype" type="text-button" (iconClick)="selectUserJobType()"
                            [maxlength]="1000"></app-input>
                    </div>
                    <div class="col-md-6 d-flex" style="padding:0">
                        <div class="col-md-6">
                            <app-select [labelName]="'Table'" [isFilter]="true" [isLabelLeft]="false" [required]="true"
                                formControlName="TableId" [dataLookup]="arrTable" displayField="DESCR"
                                valueField="CODE"></app-select>
                        </div>
                        <div class="col-md-6">
                            <app-select [labelName]="'Window onchange'" [isFilter]="true" [isLabelLeft]="false" [disabledCtrl]="false"
                            [required]="false" formControlName="Onchange" [dataLookup]="arrWindowOnchange"
                            displayField="DESCR" valueField="CODE"></app-select>
                        </div>
                    </div>
                </div>
                <div class="row user-form-row">
                    <div class="col-md-6 d-flex" style="padding:0">
                        <div class="col-md-6">
                            <app-select [labelName]="'Assigned To'" [isFilter]="true" [isLabelLeft]="false"
                            [required]="false" formControlName="AssignedTo" [dataLookup]="arrUser"
                            displayField="DESCR" valueField="CODE"></app-select>
                        </div>
                    </div>
                </div>
            </form>
            <div class="divBtnClass">
                <!-- <button nz-button class="btnClearClass" (click)="resetForm()">{{'Reset'}}</button>
                <button nz-button class="btnSaveClass" (click)="onSave()">{{'Save'}}</button> -->
                <app-button [labelName]="'Reset' | translate" typeButton="reset" (clickEvent)="resetForm()">
                </app-button>
                <app-button [labelName]="'Save' | translate" typeButton="save" icon='pi pi-save'
                    style="margin-left: 10px;" (clickEvent)="onSave()"></app-button>
            </div>
        </div>
    </div>
</div>
<app-dialog-prime #dialogPrime></app-dialog-prime>
<ng-template #tempEmail>
    <div [formGroup]="formDataStep">
        <app-input [labelName]="'Editor template'" [type]="'input-editor'" [isLabelLeft]="false" [required]="false"
             formControlName ="templateEmail">
        </app-input>
    </div>
    <div class="divBtnClass" style="margin-top: 10px;">
        <app-button [labelName]="'OK' | translate" typeButton="save" icon='pi pi-save' style="margin-left: 10px;" (clickEvent)="onSaveTemplatemail()"></app-button>
    </div>
</ng-template>
<ng-template #tempUserJobType>
    <div style="padding-bottom: 10px;">
        <app-core-tree #treeUserWf [dataTree]="dataUserJobtype" [nodeSelectedModel]="defaultCheckedUser"
            [treeConfig]="treeConfigUser" (selectNode)="onCheckUser($event)" (unSelectNode)="onUnCheckUser($event)">
        </app-core-tree>
    </div>
    <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
        <app-button [labelName]="'Cancel' | translate" typeButton="cancel" (clickEvent)="onCancelUser()"></app-button>
        <app-button labelName='OK' typeButton="save" style="margin-left: 10px;" (clickEvent)="onAddUserJobType()">
        </app-button>
    </div>
</ng-template>
<ng-template #tempUserStep>
    <div style="padding-bottom: 10px; height: 400px; overflow: auto;">
        <app-core-tree [dataTree]="dataUserStep" [nodeSelectedModel]="defaultCheckedUserStep"
            [treeConfig]="treeConfigUser" (selectNode)="onCheckUserStep($event)"
            (unSelectNode)="onUnCheckUserStep($event)"></app-core-tree>
    </div>
    <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
        <app-button [labelName]="'Save' | translate" typeButton="cancel" (clickEvent)="onCancelUserStep()"></app-button>
        <app-button labelName='OK' typeButton="save" style="margin-left: 10px;" (clickEvent)="onAddUserStep()">
        </app-button>
    </div>
</ng-template>
<ng-template #tempUserApprove>
    <div style="padding-bottom: 10px; height: 400px; overflow: auto;">
        <app-core-tree [dataTree]="dataUserApprove" [nodeSelectedModel]="defaultCheckedUserApprove"
            [treeConfig]="treeConfigUser" (selectNode)="onCheckUserApprove($event)"
            (unSelectNode)="onUnCheckUserApprove($event)"></app-core-tree>
    </div>
    <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
        <app-button [labelName]="'Cancel' | translate" typeButton="cancel" (clickEvent)="onCancelUserApprove()">
        </app-button>
        <app-button labelName='OK' typeButton="save" style="margin-left: 10px;" (clickEvent)="onAddUserApprove()">
        </app-button>
    </div>
</ng-template>
