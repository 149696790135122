import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';

@Component({
  selector: 'app-recall-job',
  templateUrl: './recall-job.component.html',
  styleUrls: ['./recall-job.component.scss']
})
export class RecallJobComponent implements OnInit {
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  currentJob: any = null;
  clientId: any;
  constructor(
    private workflowService: BpmnWorkflowService,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    
  }

  run(evt:any){
    this.onCloseEvent.emit(false);
    if (evt.currentData.length > 0) {
      if (evt.currentData[evt.currentData.length - 1].PercComplete === 100) {
        this.appService.notification('Công việc đã hoàn thành, không thể thực hiện', 'info');
      } else {
        this.clientId = this.appService.ClientId;
        const urlJob = evt.dataSource.INFORMATION.URL_EDIT;
        if (urlJob !== null && urlJob !== '') {
          let i = urlJob.indexOf('/WFJOBs');
          let url1 = urlJob.substr(0, i);
          this.workflowService.url = url1 + '/';

          this.udAssignStatus(evt.currentData[evt.currentData.length - 1]);
        }
      }
    }
  }

  udAssignStatus(job:any){
    this.appService.confirm(this.appService.getMessage('0039')).subscribe(res => {
      if (res) {
        const params = ['JobId', '=', job.JobId];
        this.workflowService.queryJob(params).subscribe(res => {
          if (res.success) {
            this.currentJob = res.features[0];
            const paramJob = res.features[0];
            paramJob.AssignStatusId = 1;
            paramJob.AssignedTo = paramJob.SendFrom;
            const paramJobStep = ['JobId', '=', job.JobId];
            this.workflowService.queryJobStep(paramJobStep).subscribe((Jt: any) => {
              if (Jt.success && Jt.features.length > 0) {
                const curStep = Jt.features[0].CurStep;
                const paramJobTypeStep = ['JobTypeId', '=', job.JobTypeId];
                this.workflowService.queryJobtypeStep(paramJobTypeStep).subscribe(res => {
                  if (res.success){
                    res.features.forEach(step => {
                      if (step.StepId === curStep) {
                        const duration = step.Duration;
                        if (duration !== null) {
                          this.workflowService.queryJobtype(paramJobTypeStep).subscribe(jobType => {
                            if (jobType.success) {
                               // kiem tra step co phai step bat dau
                              let isStepStart = false;
                              jobType.features[0].JobStepStart === curStep ? isStepStart = true : '';
                              if (jobType.features[0].NumDayComplete !== null) {
                                const valComplete = Math.round((duration / jobType.features[0].NumDayComplete) * 100);
                                if (this.currentJob.PercComplete >= valComplete) {
                                  const newPerComplete = this.currentJob.PercComplete - valComplete;
                                  paramJob.PercComplete = newPerComplete;
                                  this.updateJob(paramJob, curStep, Jt, isStepStart);
                                } else {
                                  this.updateJob(paramJob, curStep, Jt, isStepStart);

                                }
                              }else {
                                this.updateJob(paramJob, curStep, Jt, isStepStart);
                              }
                            }
                          });
                        }
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
  });
}

  updateJob(params:any, currentStep:any, Jt:any, isStepStart:any){
    this.workflowService.updateJob(params).subscribe(ud => {
      if (ud.success) {
        if (!isStepStart) {
          this.updateJobStep(params, currentStep, Jt);
        } else {
          this.onCloseEvent.emit(true);
          this.appService.notification('Thực hiện thành công', 'success');
        }
      }
    });
  }

  updateJobStep(job:any, curStep:any, Jt:any){
    const paramXref = [['JobTypeId', '=', job.JobTypeId], ['ClientId', '=', this.clientId]];
    this.workflowService.queryStepXref(paramXref).subscribe(res => {
      if (res.success) {
        const arrXref = res.features;
        arrXref.forEach(step => {
          if (step.NextStepId === curStep) {
            const curStepNew = step.StepId;
            const prUdJt = Jt.features[0];
            prUdJt.CurStep = curStepNew;
            this.workflowService.updateJobStep(prUdJt).subscribe(jt => {
              if (jt.success) {
                this.appService.notification('Thực hiện thành công', 'success');
                this.onCloseEvent.emit(true);
              }
            });
          }
        });
      }
    }, err => {
      this.appService.notification('Đã xảy ra lỗi', 'error');
    });
  }

  // updatePerComplete(job, curStep){
  //   const paramJobTypeStep = ['JobTypeId', '=', job.JobTypeId];
  //   this.workflowService.queryJobtypeStep(paramJobTypeStep).subscribe(res => {
  //     if (res.success){
  //       res.features.forEach(step => {
  //       if (step.id === curStep) {
  //         const duration = step.Duration;
  //         if (duration !== null) {
  //           if (this.currentJob.PercComplete >= duration) {
  //             const newPerComplete = this.currentJob.PercComplete - duration;
  //           }
  //         }
  //       }
  //       });
  //     }
  //   });
  // }
}
