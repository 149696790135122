import { Injectable } from '@angular/core';
import { loadModules } from 'esri-loader';


@Injectable()
export class ProjectionUtilService {
    private projection: any = null;
    private SpatialReference: any = null;
    private urlGeometryServer = 'https://coretech.vn:6443/arcgis/rest/services/Utilities/Geometry/GeometryServer';

    constructor() {
        this.init();
    }

    private async init() {
        const [
            SpatialReference,
            projection,
            GeometryService,
            esriConfig
        ] = await loadModules([
            'esri/SpatialReference',
            'esri/geometry/projection',
            'esri/tasks/GeometryService',
            'esri/config'
        ]);

        this.SpatialReference = SpatialReference;
        await projection.load();
        this.projection = projection;
        esriConfig.defaults.geometryService = new GeometryService(this.urlGeometryServer);
    }

    transform(geometry: any, spatialReference: number) {
        const outSpatialReference = new this.SpatialReference({ wkid: spatialReference });
        return this.projection.project(geometry, outSpatialReference);
    }

}
