<p-tree [value]="dataTree" [(selection)]="nodeSelectedModel" #treeMenu (onNodeSelect)="nodeSelect($event)"
    (onNodeUnselect)="nodeUnselect($event)" (onNodeExpand)="nodeExpand($event)" (onNodeCollapse)="nodeCollapse($event)"
    (onNodeDrop)="nodeDrop($event)" [draggableNodes]="dragNode" [droppableNodes]="dropNode" (onFilter)="treeFilter($event)">
    <ng-template let-node pTemplate="default">
        <ng-container *ngIf="hasTemplate">
            <ng-container *ngTemplateOutlet="templateRef, context: {treenode: node}"></ng-container>
        </ng-container>
        <ng-container *ngIf="!hasTemplate">
            <span>{{node.label}}</span>
        </ng-container>
    </ng-template>
</p-tree>