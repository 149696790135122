<div style="padding: 5px;">
    <div class="Sti-Form-Content">
        <ng-container *ngIf="loadSuccess">
            <form class="Sti-Form" [formGroup]="formGroup">
                <div class="row Sti-user-form-row">
                    <div class="col-md-12">
                        <app-input #nameReport [labelName]="'Tên báo cáo phân tích'" formControlName="WindowName" [required]="true"
                            (maxlength)="250" [isLabelLeft]="false" >
                        </app-input>
                    </div>

                    <!-- <div class="col-md-12">
                        <app-input #description [labelName]="'Description'" [maxlength]="'500'"
                            formControlName="Description" [isLabelLeft]="false"></app-input>
                        <div class="stylevisibility" [ngClass]="{'hiddenVisibility': !description.requiredClass }">
                        </div>
                    </div> -->

                    <div class="col-md-12">
                        <app-select #applicationSelectSave  labelName="Application" [isLabelLeft]="false"
                            [required]="false" formControlName="ApplicationId" [dataLookup]="dataApplicationSave"
                            displayField="DESCR" valueField="CODE">
                        </app-select>
                    </div>
                    <!-- <div class="col-md-12">
                        <app-select #componentSelectSave  labelName="Report Detail" [isLabelLeft]="false"
                            [required]="false" formControlName="ComponentName" [dataLookup]="dataComponentSave"
                            displayField="DESCR" valueField="CODE">
                        </app-select>
                    </div> -->
                </div>
            </form>
        </ng-container>
    </div>
    <div nz-row class="Sti-Border-Btn">
        <div  nzSpan="10"></div>
        <div class="Sti-message"  nzSpan="10" #message></div>
        <div class="Sti-Row-Btn"  nzSpan="14">
            <app-button [labelName]="'Save' | translate" typeButton="save"  (click)="EventSave()"></app-button>
            <app-button [labelName]="'Cancel' | translate" typeButton="close"  (click)="EventCancel()"></app-button>
        </div>
    </div>
</div>