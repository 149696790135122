import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from 'app/core/services/request.service';

@Component({
  selector: 'app-pass-change',
  templateUrl: './pass-change.component.html',
  styleUrls: ['./pass-change.component.scss']
})
export class PassChangeComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({});
  passwordVisibleC = false;
  passwordVisibleN = false;
  passwordVisibleF = false;
  public disabledCtrl = false;
  ClientCode = JSON.parse(this.appService.currentUser).ClientCode;
  @Output() changeComplate: EventEmitter<any> = new EventEmitter();

  constructor(
    public appService: AppService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private reqService: RequestService
  ) {
    this.formGroup = this.fb.group({
      CurrentPassword: new FormControl('', [Validators.required]),
      NewPassword: new FormControl('', [Validators.required]),
      ConfirmPassword: new FormControl('', [this.confirmValidator]),
    });
  }

  ngOnInit(): void {
    const obj = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);
    const chk = obj.username.toLowerCase().includes('admin')
    this.disabledCtrl = (chk && this.ClientCode == 'demo');
  }

  ngAfterViewInit() {
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.formGroup.controls.ConfirmPassword.updateValueAndValidity());
  }

  confirmValidator: any = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.formGroup.controls.NewPassword.value) {
      return { ConfirmPassword: true, error: true };
    }
    return {};
  }

  onClearCtrl() {
    this.formGroup.reset();
  }

  onBuild() {
    if (!this.disabledCtrl) {
      const params: any = {};
      let isCheckInput: Boolean = false;
      Object.keys(this.formGroup.value).forEach((key) => {
        params[key] = this.formGroup.controls[key].value;
        isCheckInput = this.formGroup.controls[key].value !== '' ? true : false;
      });

      if (isCheckInput) {
        const obj = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']);

        // const chk = obj.username.toLowerCase().includes('admin')
        // this.disabledCtrl = (chk && this.ClientCode == 'demo');

        params['UserId'] = obj.userid;
        if (this.checkPasswords(this.formGroup) === false) {
          this.appService.createMessage('error', this.appService.getMessage('0041'));
          return;
        } else {
          this.changePassWord(params).subscribe(res => {
            if (res['success'] === true) {
              this.appService.createMessage('success', this.appService.getMessage('0042'));
              this.formGroup.reset();
              this.changeComplate.emit();
              // this.dialogClause.closeDialog();
            } else {
              this.appService.createMessage('error', this.appService.getMessage('0043'));
            }
          });
        }
      } else {
        this.appService.createMessage('error', this.appService.getMessage('0012'));
      }
    }
  }

  public checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let password: any = group.get('NewPassword')!.value;
    let confirmPassword: any = group.get('ConfirmPassword')!.value;

    return password === confirmPassword ? true : false;
  }

  private changePassWord(params: {}) {
    const url = `${this.appService.urlWS}/SysUsers/ChangePws`;
    const odataParams = {
      url,
      primaryKey: 'UserId',
      data: params
    };
    return this.reqService.service.update(odataParams);
  }
}
