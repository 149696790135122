import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu-shortcut',
  templateUrl: './menu-shortcut.component.html',
  styleUrls: ['./menu-shortcut.component.scss']
})
export class MenuShortcutComponent implements OnInit, OnDestroy {
  handleLanguageChange: any = null
  isShowSubHeader: boolean = true;
  arrMenu: any[] = [];
  menuSelected: any[] = [];
  @Output() menuClick: EventEmitter<any> = new EventEmitter();
  @Output() showHeaderClick: EventEmitter<any> = new EventEmitter();
  private _dataMenu = false;

  @Input('dataMenu')
  set dataMenu(data: any) {
    console.log(data)
    data.forEach((item: any) => {
      if (item.TOOLTIP && item.TOOLTIP !== '') {
        try {
          const descr = JSON.parse(item.TOOLTIP);
          const text = descr[this.translate.currentLang];
          item.DESCR = text;
        } catch (error) {

        }
      }
    })
    this._dataMenu = data
    // this.buildMenu(data)
  }
  get dataMenu() {
    return this._dataMenu;
  }

  constructor(
    private translate: TranslateService
  ) { }
  ngOnDestroy(): void {
    if (this.handleLanguageChange) {
      this.handleLanguageChange.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.handleLanguageChange = this.translate.onLangChange.subscribe(res => {
      if (res && this.dataMenu.length > 0) {
        this.dataMenu.forEach((item: any) => {
          if (item.TOOLTIP && item.TOOLTIP !== '') {
            try {
              const descr = JSON.parse(item.TOOLTIP);
              const text = descr[this.translate.currentLang];
              item.DESCR = text;
            } catch (error) {

            }
          }
        });
        this.dataMenu = [...this.dataMenu]
      }
    });
  }
  buildMenu(data: any[]) {
    this.arrMenu = [];
    data.forEach((item: any) => {
      this.arrMenu.push({
        label: item.TITLE,
        items: item.SUB_FUNCTION.length > 0 ? this.buildSubMenu(item.SUB_FUNCTION) : null,
        icon: 'pi ' + item.ICON,
        data: item,
        command: (evt: any) => {
          this.onClickSubMenu(evt)
        }
      })
    })
    this.arrMenu = [...this.arrMenu]
  }
  buildSubMenu(arr: any[]) {
    const sub_menu: any[] = []
    arr.forEach((item: any) => {
      sub_menu.push({
        label: item.TITLE,
        items: item.SUB_FUNCTION.length > 0 ? this.buildSubMenu(item.SUB_FUNCTION) : null,
        icon: 'pi pi-fw pi-' + item.ICON,
        data: item,
        command: (evt: any) => {
          this.onClickSubMenu(evt)
        }
      })

    })
    return sub_menu;
  }
  onClickMenu(menu: any, ind: any) {
    this.buildMenu(menu.SUB_FUNCTION)
    this.dataMenu[ind].IS_ACTIVE = !this.dataMenu[ind].IS_ACTIVE
    this.dataMenu.forEach((item: any, index: any) => {
      if (index !== ind) {
        item.IS_ACTIVE = false;
      }
    })
    if (menu.SUB_FUNCTION.length === 0) {
      this.menuClick.emit(menu)
      this.dataMenu.forEach((item: any, index: any) => {
        item.IS_ACTIVE = false;
      })
    }
  }
  onClickSubMenu(menu: any) {
    if (menu.item.items == null) {
      menu.item.data.IS_ACTIVE = true
      this.menuClick.emit(menu.item.data)
      this.dataMenu.forEach((item: any, index: any) => {
        item.IS_ACTIVE = false;
      })
    }
    // menu.IS_ACTIVE = !menu.IS_ACTIVE
  }
  onShowHideSubHeader() {
    this.showHeaderClick.emit(this.isShowSubHeader)
    this.isShowSubHeader = !this.isShowSubHeader;
  }
  private hiddenAllSub(menu: Array<any>) {
    menu.forEach(item => {
      item.IS_ACTIVE = false;
      if (item.SUB_FUNCTION && item.SUB_FUNCTION.length > 0) {
        this.hiddenAllSub(item.SUB_FUNCTION);
      }
    });
  }

}
