import { indexOf } from 'lodash';
import { Checkbox } from 'primeng/checkbox';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app-base/app.service';
import { ConfigServicesDataService } from 'app/um/services';
import { SelectComponent } from 'app/core/select/select.component';
import { DashboardBuilderService } from 'app/dashboard-builder/services/dashboard-builder.service';
@Component({
  selector: 'app-groupportal',
  templateUrl: './groupportal.component.html',
  styleUrls: ['./groupportal.component.scss']
})
export class GroupportalComponent implements OnInit {

  @ViewChild('filterService') filterService!: SelectComponent;

  @Input() isWizard = false;
  @Output() chooseUrlService: EventEmitter<any> = new EventEmitter();
  @Output() chooseService: EventEmitter<any> = new EventEmitter();
  @Output() updateForm : EventEmitter<any> = new EventEmitter();

  arrColumn: any[] = [{
    key: 'groupname',
    title: 'Group Name',
    width: 'auto'
  }, {
    key: 'groupkey',
    title: 'Id Group',
    width: '100px'
  }]
  loading = false;
  total = 0;
  currentSelection: any = null;
  formGroupLogin: FormGroup = new FormGroup({});
  lstServicesChose: any = [];
  lstServices: any = [];
  listTypeService: any[] = [];
  passwordVisible = false;
  currentItem: any;
  @Input() urlPortal = '';
  @Input() usernamePortal = '';
  @Input() passPortal = '';
  @Input() serviceType: any = '';
  currentServiceType = '';
  isarcgisonline = '';
  urlarcgisonline = '';
  userarcgisonline = '';
  passwordarcgisonline = '';
  token = '';
  constructor(
    public appService: AppService,
    public translate: TranslateService,
    public configServicesDataService: ConfigServicesDataService,
    private cd: ChangeDetectorRef,
    private dashboardBuilderService: DashboardBuilderService,
  ) { }
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    var a = JSON.parse(this.appService.currentUser!);
    this.isarcgisonline = a.isarcgisonline;
    this.urlarcgisonline = a.urlarcgisonline;
    this.userarcgisonline = a.userarcgisonline;
    this.passwordarcgisonline = a.passwordarcgisonline;
    this.createLoginPortal();
  }

  createLoginPortal() {
    if (this.urlPortal != "" && this.usernamePortal != "" && this.passPortal != "" && localStorage.getItem('loginPortal') === 'true') {
      this.formGroupLogin = new FormGroup({
        urlPortal: new FormControl(this.urlPortal),
        userPortalLogin: new FormControl(this.usernamePortal),
        passWordPortalLogin: new FormControl(this.passPortal),
      });
      this.loginPortal();
    }
    else if (this.isarcgisonline == "Y") {
      this.formGroupLogin = new FormGroup({
        urlPortal: new FormControl(this.urlarcgisonline),
        userPortalLogin: new FormControl(this.userarcgisonline),
        passWordPortalLogin: new FormControl(this.passwordarcgisonline),
      });
    }
    else {
      this.formGroupLogin = new FormGroup({
        urlPortal: new FormControl('https://indoorvn.maps.arcgis.com/'),
        userPortalLogin: new FormControl('vinhptfpt'),
        passWordPortalLogin: new FormControl('V12345678v'),
      });
    }

  }

  resetLogin() {
    this.formGroupLogin.reset();
  }
  getUrlMap() {
    //this.chooseUrlService.emit(this.currentSelection);
    this.updateForm.emit(this.currentSelection.groupkey);
  }
  async loginPortal() {
    this.loading = true;
    let duongdanPortal = '';
    duongdanPortal = this.formGroupLogin.controls.urlPortal.value;
    duongdanPortal = duongdanPortal.slice(-1) === '/' ? duongdanPortal.substring(0, duongdanPortal.length - 1) : duongdanPortal;
    const urltoken = duongdanPortal + '/sharing/rest/generateToken';
    this.appService.appConfig = {};
    this.appService!.appConfig!.Services = [{
      applicationId: 1,
      clientId: JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']).clientId,
      hasTileCache: '',
      isDisplay: null,
      isIdentify: '',
      isVisible: '',
      optionName: null,
      orderNo: 1,
      serviceId: 1,
      serviceName: '',
      serviceType: '',
      spatialReference: null,
      urlEdit: '',
      urlView: '',
      urlToken: urltoken,
      urlVectorTile: '',
      userNamePortal: this.formGroupLogin.controls.userPortalLogin.value,
      passWordPortal: this.formGroupLogin.controls.passWordPortalLogin.value,
    }];
    console.log(this.formGroupLogin.controls.userPortalLogin.value);
    console.log(this.formGroupLogin.controls.passWordPortalLogin.value);
    // this.urlPortal = this.formGroupLogin.controls.urlPortal.value;
    // this.usernamePortal = this.formGroupLogin.controls.userPortalLogin.value;
    // this.passPortal = this.formGroupLogin.controls.passWordPortalLogin.value;

    this.token = await this.appService.getArcGISToken(duongdanPortal);
    // this.appService.generateArcGISTokenEvent.subscribe(async resp => {
    if (this.token == null) {
      this.appService.onMessage('0055');
      this.lstServicesChose = [];
      this.lstServices = [];
      this.loading = false;
      return;
    }
    // 	console.log(this.token);
    if (this.token != null) {
      await this.getServicesPublic();
      this.appService.createMessage('success', 'Login Successrull !');
      this.loading = false;
    } else {
      this.lstServicesChose = [];
      this.lstServices = [];
      this.appService.onMessage('0099');
      this.loading = false;
    }
    // });

  }
  
  async onLoadService(evt: any) {
    if (evt.first == 0 && this.lstServicesChose.length === 0) return;
    console.log(evt);
    let pagesize = evt.first / 10;
    await this.getServicesPublic();
  }
  async getServicesPublic() {
    this.lstServices =[];

    //	this.filterServices();
    let param: any = {};
    // console.log(token);
    let urlquery = '';
    let duongdanPortal = '';
    duongdanPortal = this.formGroupLogin.controls.urlPortal.value;
    duongdanPortal = duongdanPortal.slice(-1) === '/' ? duongdanPortal.substring(0, duongdanPortal.length - 1) : duongdanPortal;
    let org = '';

    urlquery = duongdanPortal + '/sharing/rest/community/groups';
    const paramItem = {
      num: 60,
      start: 1,
      sortField: 'title',
      sortOrder: 'asc',
      searchUserAccess: 'groupMember',
      searchUserName: this.formGroupLogin.controls.userPortalLogin.value,
      q: '-owner:"esri_livingatlas" -owner:"esri_boundaries" -owner:"esri_demographics"',
      f: 'json',
      token: this.token
    };
    this.dashboardBuilderService.queryGroupArcgis(urlquery,paramItem).subscribe(res => {
      console.log(res)
      const ketqua = res.results;
      ketqua.forEach((group:any) => {
        this.lstServices.push({
          groupkey: group.id,
          groupname : group.title
        });
      });
      console.log( this.lstServices);
      
    });

  }

  

  onSearchChange(evt: any) {
    let type = this.filterService.ctrl.value.CODE;
    let start = 1;

    //	this.filterServices();
    let param: any = {};
    // console.log(token);
    let urlquery = '';
    let duongdanPortal = '';
    duongdanPortal = this.formGroupLogin.controls.urlPortal.value;
    duongdanPortal = duongdanPortal.slice(-1) === '/' ? duongdanPortal.substring(0, duongdanPortal.length - 1) : duongdanPortal;
    let org = '';
    if (!duongdanPortal.includes('arcgis.com')) {
      org = 'orgid:0123456789ABCDEF';
    }
    let wheretype = ' type:("Feature Collection" OR "Web Map" OR "Vector Tile Service"  OR "Map Service" OR "Feature Service" OR "OGCFeatureServer") -typekeywords:"Table" ';
    switch (type) {
      case 'Feature Service':
        wheretype = ' type:("Feature Collection" OR "Feature Service" OR "OGCFeatureServer") -typekeywords:"Table"';
        break;
      case 'Map Service':
        wheretype = ' type:("Map Service") -typekeywords:"Table"';
        break;
      case 'Vector Tile Service':
        wheretype = ' type:("Vector Tile Service") -typekeywords:"Table"';
        break;
      case 'Web Map':
        wheretype = ' type:("Web Map") -typekeywords:"Table"';;
        break;
      default:
        break;
    }
    //	urlquery = duongdanPortal + '/sharing/rest/search?num=10&start=' + start + '&sortField=modified&sortOrder=desc&q= ' + org + ' -type:"Code Attachment" -type:"Featured Items" -type:"Symbol Set" -type:"Color Set" -type:"Windows Viewer Add In" -type:"Windows Viewer Configuration" -type:"Map Area" -typekeywords:"MapAreaPackage"&f=json';
    if (this.token === "") {
      urlquery = duongdanPortal + '/sharing/rest/search?num=10&start=' + start + '&sortField=modified&sortOrder=desc&q=(' + evt + ') ' + org + ' ' + wheretype + ' &f=json';
    }
    else {
      urlquery = duongdanPortal + '/sharing/rest/search?num=10&start=' + start + '&sortField=modified&sortOrder=desc&q=(' + evt + ')%20owner:' + this.formGroupLogin.controls.userPortalLogin.value + '%20' + org + ' ' + wheretype + ' &f=json' + '&token=' + this.token;
    }

    this.configServicesDataService.getDataFromMapServices(urlquery, 'POST', param).subscribe(resp => {
      this.lstServicesChose = [];
      this.lstServices = [];
      if (resp) {
        this.total = resp.total;
        console.log(resp);
        if (resp.log !== undefined) {
          this.appService.onMessage('0098');
          return;
        }
        resp.results.forEach((element: any) => {

          if (element.type === 'Feature Service' || element.type === 'Map Service' || element.type === 'Web Map' || (element.type === 'Vector Tile Service' && !this.isWizard)) {
            //if (element.access === 'public') {
            let thumbnail = duongdanPortal + '/sharing/rest/content/items/' + element.id + '/info/' + element.thumbnail;
            const tontai = this.lstServices.filter((fil: any) => fil.name === element.title);
            let img = '';
            let spatialReference: any;
            if (element.type === "Feature Service") {
              img = 'assets/imgs/imageservices/FeatureServer.svg';
            }
            else if (element.type === "Map Service") {
              img = 'assets/imgs/imageservices/MapServer.svg';
            }
            if (element.type === "Web Map") {
              img = 'assets/imgs/imageservices/WEBMAP.svg';
              spatialReference = JSON.stringify(element.extent);
            }
            if (element.type === "Vector Tile Service") {
              img = duongdanPortal + '/home/js/arcgisonline/img/item-types/vectortile16.svg';
            }
            if (tontai.length > 0) {
              tontai[0].type = tontai[0].type + '/' + element.type;
              tontai[0].url = tontai[0].url + '---' + element.url;
            }
            else {
              if (element.access === 'public') {
                this.lstServices.push({
                  type: element.type,
                  name: element.title,// + '----' + element.type,
                  image: element.thumbnail,
                  url: (element.url == null || element.url == "") ? duongdanPortal + '/sharing/rest/content/items/' + element.id : element.url,
                  checked: false,
                  access: element.access,
                  isvectortile: element.type === 'Vector Tile Service' ? true : false,
                  img: img,
                  thumbnail: thumbnail,
                  spatial: spatialReference

                });
              }
              else {
                this.lstServices.push({
                  type: element.type,
                  name: element.title,// + '----' + element.type,
                  image: element.thumbnail,
                  url: (element.url == null || element.url == "") ? duongdanPortal + '/sharing/rest/content/items/' + element.id : element.url,
                  checked: false,
                  access: element.access,
                  isvectortile: element.type === 'Vector Tile Service' ? true : false,
                  img: img,
                  thumbnail: thumbnail,
                  spatial: spatialReference,
                  urlPortal: duongdanPortal,
                  username: this.formGroupLogin.controls.userPortalLogin.value,
                  password: this.formGroupLogin.controls.passWordPortalLogin.value

                });
              }
            }
            //}

          }
        });
        this.lstServices.forEach((element: any) => {
          this.lstServicesChose.push(element);
        });


      }
    });
  }

  onChkChangeChooseService(evt: any, item: any) {
    this.lstServicesChose.forEach((element: any) => {
      element.checked = false;
    });

    if (evt.checked) {
      item.checked = true;
      this.currentItem = item;
    }
  }

}
