<div class="body-container">
    <ng-container *ngIf="showSearchByGraphic">
        <p-accordion [multiple]="true">
            <p-accordionTab [header]="'Search by graphic' | translate" *ngIf="showSearchByGraphic">
                <div class="fluid grid formgrid m-0">
                    <div class="col-md-6">
                        <div class="icon-draw icon-polygon" (click)="onDrawToolClick('POLYGON')"
                            [ngClass]="{'active': type === 'POLYGON'}">
                        </div>
                        <div class="icon-draw icon-circle" (click)="onDrawToolClick('CIRCLE')"
                            [ngClass]="{'active': type === 'CIRCLE'}">
                        </div>
                        <div class="icon-draw icon-rectangle" [ngClass]="{'active': type === 'RECTANGLE'}"
                            (click)="onDrawToolClick('RECTANGLE')">
                        </div>
                        <div class="icon-draw icon-clear" (click)="onDrawToolClick('CLEAR')"></div>
                    </div>

                    <div class="col-md-6">
                        <app-select [labelName]="'Spatial Relationship'" [isLabelLeft]="true" [formControl]="spatialRel"
                            [dataLookup]="lookupSpatialRel"></app-select>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab [header]="'Search by attributes' | translate" [selected]="true">
                <app-core-tab [tabs]='arrTab' [title]="titleRef" [content]="contentRef" [fieldDisabled]='"isDisabled"'>
                </app-core-tab>
                <ng-template #titleRef let-tab>
                    <div class="tab-title">
                        <ng-container *ngIf="tab.id !== 'mixed'">
                            {{ tab.title | translate }}
                        </ng-container>
                    </div>
                </ng-template>
                <ng-template #contentRef let-tab>
                    <ng-container *ngIf="tab.id === 'basic'">
                        <app-search-basic [isMapApp]="isMapApp" [dataSource]="currentTab"
                            (searchComplete)="querySearch($event)" [showImportBtn]="isShowAcceptBtn"></app-search-basic>
                    </ng-container>
                    <ng-container *ngIf="tab.id === 'advanced' ">
                        <app-search-advance style="width: 100%" [dataSource]="currentTab"
                            [showImportBtn]="isShowImportBtn" (searchComplete)="querySearch($event)">
                        </app-search-advance>
                    </ng-container>
                    <ng-container *ngIf="tab.id === 'mixed'">
                        <app-search-mix></app-search-mix>
                    </ng-container>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </ng-container>
    <ng-container *ngIf='!showSearchByGraphic'>
        <app-core-tab [tabs]='arrTab' [title]="titleRef" [content]="contentRef" [fieldDisabled]='"isDisabled"'>
        </app-core-tab>
        <ng-template #titleRef let-tab>
            <div class="tab-title">
                <ng-container *ngIf="tab.id !== 'mixed'">
                    {{ tab.title | translate }}
                </ng-container>
            </div>
        </ng-template>
        <ng-template #contentRef let-tab>
            <ng-container *ngIf="tab.id === 'basic'">
                <app-search-basic [isMapApp]="isMapApp" [dataSource]="currentTab" (searchComplete)="querySearch($event)"
                    [showImportBtn]="isShowAcceptBtn"></app-search-basic>
            </ng-container>
            <ng-container *ngIf="tab.id === 'advanced' ">
                <app-search-advance style="width: 100%" [dataSource]="currentTab" [showImportBtn]="isShowImportBtn"
                    (searchComplete)="querySearch($event)">
                </app-search-advance>
            </ng-container>
            <ng-container *ngIf="tab.id === 'mixed'">
                <app-search-mix></app-search-mix>
            </ng-container>
        </ng-template>
    </ng-container>
</div>

<div style="padding: 2px 10px; font-size: 11pt; width: 100%; background-color: #519cd81c;"
    [ngClass]="{'d-none': !isCountKQ}">{{coutKQ}}</div>