<div class="success_register">
  <div class="notify__register">
    <div class="notify__register--logo" [ngClass]="dataMessage.code === 'true' ? 'title__success' : 'title__error'">
      <div class="logo-company">
        <ng-container *ngIf="dataMessage.code === 'true'">
          <span>Xin chúc mừng, bạn đã kích hoạt thành công tài khoản!</span>
        </ng-container>

        <ng-container *ngIf="dataMessage.code !== 'true'">
          <span>Kích hoạt tài khoản không thành công!</span>
        </ng-container>
      </div>
    </div>
    <div class="notify__register--message"
      [ngClass]="dataMessage.code === 'true' ? 'message__success' : 'message__error'">
      <div>{{ message | translate }}</div>
    </div>
    <div class="notify__register--btn">
      <div class="btn__login" (click)="handleAgree()">
        < Quay về trang đăng nhập</div>
      </div>
    </div>
  </div>