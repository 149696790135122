

// import convertType from 'app/app-base/interface/reflect-convert';
import { Type } from '@angular/core';
export interface System {
  SYSTEM_ID: string;
  SYSTEM_NAME: string;
  SYSTEM_DETAIL: string;
  ORDER_NO: number;
  URLSERVICE_MAP: string;
  URLSERVICE_API: string;
}

export interface Kvhc {
  MAKVHC: string;
  TENKVHC: string;
}

export interface FunctionX {
  FUNCTION_ID: string;
  FUNCTION_NAME: string;
  FUNCTION_DETAIL: string;
  FUNCTION_PARENT: string;
  SYSTEM_ID: string;
  ORDER_NO: number;
  NAME: string;
  URL: string;
  FUNCTION_COMPONENT: string;
  SUB_FUNCTION: FunctionX[];
}
export interface KVHC {
  codeKvhc: string;
  level: number;
  parentCode: any;
  status: number;
  tenKvhc: string;
  userId: number;
  userKvhcId: number;
  userName: string;
}
export interface DANHMUC {
  CODE: string;
  DESCR: string;
}
export interface FunctionConfig {
  FUNCTION_ID: string;
  COMPONENT: Type<any>;
}
export interface FunctionPermission {
  columnid: number;
  functionname: string;
  functioncode: string;
  displaylogic: string;
  tableid: number;
}
export interface Menu {
  clientid: number;
  displaylogic: any;
  function_component: string;
  function_detail: string;
  function_name: string;
  has_sub: boolean;
  icon: string;
  id: number;
  isopen: string;
  menuitemtype: string;
  name: string;
  order_no: number;
  reportid: number;
  sub_function: FunctionX[];
  system_id: string;
  tabid: number;
  url: string;
  windowid: number;
  windowtype: string;
  exttype: any;
  extwindow: any;
  menudesc: string;
  windowcustomize: string;
  whereclause: string;
}

export interface Service {
  applicationId: number;
  clientId: number;
  hasTileCache: string;
  isDisplay: any;
  isIdentify: string;
  isVisible: string;
  optionName: any;
  orderNo: number;
  serviceId: number;
  serviceName: string;
  serviceType: string;
  spatialReference: any;
  urlEdit: string;
  urlView: string;
  urlToken: string;
  urlVectorTile: string;
  userNamePortal: string;
  passWordPortal: string;
    userSchema: string;
    passwordSchema: string;
}

export interface Layer {
  alias: string;
  applicationId: number;
  clientId: number;
  isAddMap: string;
  isIdentify: string;
  isVisible: string;
  layerId: number;
  layerIndex: number;
  layerName: string;
  geometryType: string;
  opacity: number;
  orderNo: number;
  serviceId: number;
  tableDoHoaId: number;
  windowId: any;
  toLienKetField: any;
  fromLienKetField: any;
  urlView: string;
  urlEdit: string;
  kvhcColumn: string;
}
export class Application {
  applicationId!: number;
  ApplicationName!: string;
  Description!: string;
  OrderNo!: number;
  Icon!: string;
  LinkUrl!: string;
  ClientId!: number;
  IsAppSystem!: string;
  UserPortal!: string;
  PasswordPortal!: number;
  designApp: any;
  // @convertType(User) students: Array<User>;
}
export interface General {
  CONFIG_NAME: string;
  CONFIG_VALUE: any;
  CONFIG_DESCR: string;
}

export interface Calendar {
  calCalendarId: number;
  groupId: number;
  sysUserCalendarId: number;
}

export interface AppConfig {
  Systems?: System[];
  Functions?: FunctionX[];
  Menus?: Menu[]; // lưu danh sách menu
  Services?: Service[] | null;
  Layers?: Layer[];
  General?: General[];
  Applications?: Application[];
  FunctionPermistion?: FunctionPermission[];
  PermissionEditorTool?: any[];
  DSKVHC?: KVHC[];
  wherekvhc?: string;
  sys_combo?: any[];
  sys_config?: any;
  objTheme?: any;
  listCalendar?: Calendar[];
}

export interface AppConfigApplication {
  Application?: Application[];
}
export interface toolTableConfig {
  isAdd?: boolean,
  isEdit?: boolean,
  isDelete?: boolean,
  isSave?: boolean,
  isSearch?: boolean,
  isInputSearch?: boolean,
}
