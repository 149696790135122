<div class="StiForm-CoreTech-Service">
    <div class="StiForm-CoreTech-Service-Container">
        <table>
            <tr>
                <td class="Container-left">
                    <div class="Container-left-body">
                        <div>
                            <!-- <input  class="coreTechServiceTextBox" placeholder="Find"> -->
                        </div>
                        <div class="Container-left-body1">
                            <ng-container *ngFor = "let service of dataServiceName; let i=index">
                                <div #ServiceCoreTech (click) = "clickEventServiceCoreTech(service.serviceId)" class="coreTechServiceRowButton" [ngClass]="{'coreTechServiceRowButtonSelected': service.serviceId === currentService}">
                                    <div class="coreTechServiceRowButton_Body">
                                        <table>
                                            <tr>
                                                <td>{{service.serviceName}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td class="Container-right">
                    <app-form-data-services #tableServiceCoreTech (keyupEmit) = "onKeyChangeDataService($event)" [datafilterService] = "datafilterService"></app-form-data-services>
                </td>
            </tr>
        </table>
    </div>
    <div class="StiForm-CoreTech-Service-Separator"></div>
    <div class="StiForm-CoreTech-Service-Button">
        <app-button style="margin-right: 2.5px; text-align: center;" [labelName]="'Ok'"
            (click)="openDataSource()"></app-button>
        <app-button style="margin-left: 2.5px; text-align: center;" [labelName]="'Cancel'"
            (click)="cancleDataSource()"></app-button>
    </div>
</div>