<div class="grid">
  <div class="list-container" pDroppable="tasks" (onDrop)="dropToDo()">
    <h1>Todo</h1>
    <div *ngFor="let item of listToDo;  let i=index">
      <div pDraggable="tasks" (onDragStart)="dragStart(item)" (onDragEnd)="dragEnd()">
        <div>{{item.detail}}</div>
        <div>{{item.user}}</div>
        <div>{{item.startDate}}</div>
        <div>{{item.dueDate}}</div>
      </div>
    </div>
  </div>
  <div class="list-container" pDroppable="tasks" (onDrop)="dropInProgress()">
    <h1>In progress</h1>
    <div *ngFor="let item of listInProgress">
      <div pDraggable="tasks" (onDragStart)="dragStart(item)" (onDragEnd)="dragEnd()">
        <div>{{item.detail}}</div>
        <div>{{item.user}}</div>
        <div>{{item.startDate}}</div>
        <div>{{item.dueDate}}</div>
      </div>
    </div>
  </div>
  <div class="list-container" pDroppable="tasks" (onDrop)="dropDone()">
    <h1>Done</h1>
    <div *ngFor="let item of listDone">
      <div pDraggable="tasks" (onDragStart)="dragStart(item)" (onDragEnd)="dragEnd()">
        <div>{{item.detail}}</div>
        <div>{{item.user}}</div>
        <div>{{item.startDate}}</div>
        <div>{{item.dueDate}}</div>
      </div>
    </div>
  </div>
  <div class="list-container" pDroppable="tasks" (onDrop)="dropTest()">
    <h1>Test</h1>
    <div *ngFor="let item of listTest">
      <div pDraggable="tasks" (onDragStart)="dragStart(item)" (onDragEnd)="dragEnd()">
        <div>{{item.detail}}</div>
        <div>{{item.user}}</div>
        <div>{{item.startDate}}</div>
        <div>{{item.dueDate}}</div>
      </div>
    </div>
  </div>
  <div class="list-container" pDroppable="tasks" (onDrop)="dropArchived()">
    <h1>Archived</h1>
    <div *ngFor="let item of listArchived">
      <div pDraggable="tasks" (onDragStart)="dragStart(item)" (onDragEnd)="dragEnd()">
        <div>{{item.detail}}</div>
        <div>{{item.user}}</div>
        <div>{{item.startDate}}</div>
        <div>{{item.dueDate}}</div>
      </div>
    </div>
  </div>
</div>
