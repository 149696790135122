<div class="d-flex" [ngClass]="{'NoLabelLeft': !isLabelLeft}" [style.width.px]="controlWidth">
    <div *ngIf="showLabel" [style.width.px]="labelWidth" class="labelClass" [ngClass] = "{'label-readonly': isReadOnly}">
        <ng-container *ngIf="notUseTranslate">
            {{labelName || "No title" }}
        </ng-container>
        <ng-container *ngIf="!notUseTranslate">
            {{labelName || "No title" | translate }}
        </ng-container>
        <span *ngIf="_required" [style.color]="'red'">&nbsp;*</span>
        <i *ngIf="infoHelp" class="infoHelpIcon pi pi-info-circle" [pTooltip]="infoHelpContent"></i>
    </div>
    <div style="display: flex; width: 100%;" [ngClass]="{'input-readonly': isReadOnly}"
        [ngStyle]="{'max-width': isLabelLeft && showLabel && showLabel ? 'calc(100% - 120px)' : '100%' }">
        <!-- <nz-input-group>
            <nz-select class="w-100" [ngClass]="{'required': requiredClass}" nzShowSearch [nzMaxTagCount]="3"
                (ngModelChange)="onChangeValue($event)" [nzMode]="mode" [formControl]="ctrl" nzAllowClear
                [nzPlaceHolder]="placeholder">
                <nz-option nzCustomContent *ngFor="let option of dataLookupBinding" [nzValue]="option"
                    [nzLabel]="option[displayField]" [nzDisabled]="disabledCtrl">
                    <span class="nz-option-custom-tooltip" nz-tooltip
                        [nzTooltipTitle]="option[displayField]">{{option[displayField || '-']}}</span>
                </nz-option>
            </nz-select>
        </nz-input-group> -->
        <ng-container *ngIf="hasFormCtrl">
            <span style="height: 100%; width: 100%; display: flex; align-items: center;">
                <p-dropdown [options]="dataLookupBinding" [filter]="isFilter || dataLookupBinding.length > 20"
                    [placeholder]="placeholder" [showClear]="disabledCtrl || isReadOnly ? false : true"
                    [formControl]="ctrl" [optionLabel]="displayField" [readonly]="disabledCtrl"
                    [disabled]="!disabledCtrl && isReadOnly" [autoDisplayFirst]="false" [required]="required"
                    [appendTo]="'body'" [ngClass]="{'core-required': requiredClass}" (onChange)="onChangeValue($event)"
                    (onBlur)="blurEvent.emit()">
                </p-dropdown>
                <ng-container *ngIf="hasButton">
                    <button pButton pRipple type="button" icon="pi pi-plus" (click)="btnCalcClick.emit()"
                        style="height: 30px;width: 30px;"></button>
                </ng-container>
            </span>

        </ng-container>
        <ng-container *ngIf="!hasFormCtrl">
            <span style="height: 100%; width: 100%; display: flex; align-items: center;">
                <p-dropdown [options]="dataLookupBinding" [filter]="isFilter || dataLookupBinding.length > 20"
                    [optionValue]="valueField" [placeholder]="placeholder"
                    [showClear]="disabledCtrl || isReadOnly ? false : true" [(ngModel)]="valueModel"
                    [optionLabel]="displayField" [ngClass]="{'input-readonly': isReadOnly}" [readonly]="disabledCtrl"
                    [disabled]="!disabledCtrl && isReadOnly" [autoDisplayFirst]="false" [required]="required"
                    [appendTo]="'body'" [ngClass]="{'core-required': requiredClass}" (onChange)="onChangeValue($event)"
                    (onBlur)="blurEvent.emit()">
                </p-dropdown>
                <ng-container *ngIf="hasButton">
                    <button pButton pRipple type="button" icon="pi pi-plus" (click)="btnCalcClick.emit()"
                        style="height: 30px;width: 30px;"></button>
                </ng-container>
            </span>

        </ng-container>
    </div>
</div>