import {
    Component, ComponentFactoryResolver, Input, EventEmitter,
    OnInit, TemplateRef, ViewChild, ViewContainerRef, ElementRef, ChangeDetectorRef, AfterViewInit,
    HostListener
} from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-dialog-prime',
    templateUrl: './dialog-prime.component.html',
    styleUrls: ['./dialog-prime.component.scss']
})
export class DialogPrimeComponent implements OnInit, AfterViewInit {
    display: any = false;
    isMini = false;
    createByDynamic = false; // được tạo tự động bằng code ts, k phải sử dụng selector (html)

    @Input() isMaxWidth = false;
    @Input() MaxWidthPanel = '400px';
    objStyle = {
        height: '100%'
    }
    @Input() styleClass: any = ''
    @Input() title: any = '';
    @Input() preTitle: any = '';
    @Input() isComponent: any = true;
    @Input() isModal: any = false;
    @Input() isAppendBody: any = false;
    @Input() widthPanel: any = '50vw';
    @Input() heightPanel: any = '90vh';
    @Input() heightDialog: any = 'auto';
    @Input() myContext: any = {};
    @Input() isMinimize: boolean = true;
    @Input() maxiMizable: boolean = false;
    @Input() top: any = '100px';
    @Input() bottom: any = 'auto';
    @Input() right: any = 'auto';
    @Input() left: any = 'auto';
    @Input() position: any = 'fixed';
    @Input() autoZIndex: any = false;
    @Input() isShowTitle: boolean = true;
   
    @Input() baseZIndex: any = '999';
    @Input() positionDialog: any = 'center';
    @ViewChild('form', { read: ViewContainerRef }) form!: ViewContainerRef;
    @ViewChild('pDialog', { static: true }) pDialog!: Dialog;

    _hasFooter: boolean = false;
    @Input() 
    get hasFooter() {
        return this._hasFooter
    }
    set hasFooter(bol: any) {
        this._hasFooter = bol;
    }
    private _templateRef!: TemplateRef<any>;
    @Input()
    get templateRef(): TemplateRef<any> {
        return this._templateRef;
    }
    set templateRef(templateRef: TemplateRef<any>) {
        this._templateRef = templateRef;
    }

    private _tmpFooter: any = null;
    @Input()
    get tmpFooter(): TemplateRef<any> {
        return this._tmpFooter;
    }
    set tmpFooter(templateRef: TemplateRef<any>) {
        this._tmpFooter = templateRef;
    }
    onClose: EventEmitter<any> = new EventEmitter();
    private comp: any = null;
    isMobileSize: boolean = false
    constructor(
        private resolver: ComponentFactoryResolver,
        public elem: ElementRef,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
    ) { }
    @HostListener("window:resize", ['$event'])
    private onResize(evt: any) {
        if (evt.target.screen.width <= 600) {
            this.isMobileSize = true
        } else {
            this.isMobileSize = false
        }
    }
    ngOnInit(): void {
        if (window.screen.width <= 600) {
            this.isMobileSize = true
        } else {
            this.isMobileSize = false
        }
    }
    ngAfterViewInit() {
        this.cd.detectChanges()
    }
    seflDestroy() {
        // tự hủy
        this.elem.nativeElement.remove();
    }
    showDialog(component: any, configForm: any, title: any, preTitle: string = '') {
        this.display = true;
        this.title = title;
        this.preTitle = preTitle;
        if (this.maxiMizable) {
            this.pDialog.maximize();
        }
        return this.setDialogContent(configForm, component);
    }

    setDialogContent(configForm: any, component: any) {
        this.cd.detectChanges();
        if (this.form) {
            this.form.clear();
        }
        const componentFactory = this.resolver.resolveComponentFactory(
            component
        );
        const componentRef: any = this.form.createComponent(componentFactory);
        Object.keys(configForm).forEach(key => {
            componentRef.instance[key] = configForm[key];
        });
        this.comp = componentRef.instance;
        return componentRef.instance;
    }

    closeDialog() {
        this.display = false;

        if (this.comp) {
            this.comp = null;
        }
        this.isMini = false;

        if (this.createByDynamic) {
            this.seflDestroy();
        }
    }

    onHideDialog() {
        this.closeDialog();
        this.onClose.emit();
    }

    onShow() {
        this.cd.detectChanges();
        this.display = true;
    }

    /** Hàm cho phép truyền domNode vào hiển thị
     *
     *  Tạo bởi Đại Nguyễn
     */
    setDomNode(domNode: any) {
        this.form.element.nativeElement.appendChild(domNode);
    }

    /** Hàm thêm class định nghĩa cho Dialog
     *
     *  Tạo bởi Đại Nguyễn
     */
    setClassName(className: any) {
        this.pDialog.styleClass = className;
    }
    onClickToolHeader(mode: any) {
        if (mode === 'hide') {
            this.isMini = true;
        } else {
            this.isMini = false;
        }
    }
    onDragEnd(evt: any) {
        // console.log(evt)
    }
}
