import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { DeleteResponse, RequestService } from '../services/request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { loadModules } from 'esri-loader';
import * as ace from 'ace-builds'

@Component({
  selector: 'app-core-attachment',
  templateUrl: './core-attachment.component.html',
  styleUrls: ['./core-attachment.component.scss']
})
export class CoreAttachmentComponent implements OnInit, AfterViewInit {
  treeConfig: any = {
    selectionMode: 'single',
    filter: true,
    virtualScroll: false,
    scrollHeight: 'flex',
    filterPlaceholder: 'Search'
  }
  loading: boolean = false
  arrDataTree: any[] = []
  type: any = 'arcgis'
  urlProxy: any = null
  baseList: any[] = []
  listAttachment: any[] = []
  arrTabAttachment: any[] = []
  clientId: any = null
  tabIndex: any = 0
  appId: any = null
  formAttachment: any = null
  formEditAttachment: any = null
  private baseFileUrl = 'assets/imgs/others/file_base.png';
  _dataSource: any = null
  currentNode: any = null
  isFulDialog: boolean = false
  userId: any = null
  owner: any = null
  // @Input() applicationId: any = null
  // @Input() permissionList: any = null
  @Input() modeAttachment: 'view' | 'edit' = 'edit'
  @Input() isModePlugin: boolean = false
  @Input() acceptFile: any = '*'
  @Input() primaryKey: any = null
  @ViewChild('dialogComponent', { static: true }) dialogComponent!: DialogPrimeComponent;
  @ViewChild('upload', { static: false }) uploadNode!: ElementRef;
  @ViewChild('tmpAttachInfo') tmpAttachInfo!: TemplateRef<any>
  @ViewChild('aceEle') aceEle!: ElementRef
  _permissionList: any = {
    ADD: false,
    EDIT: false,
    DELETE: false,
    SAVE: false
  }
  @Input()
  set permissionList(_data: any) {
    this._permissionList = _data
  }

  get permissionList() {
    return this._permissionList
  }
  @Input()
  set dataSource(_data: any) {
    this._dataSource = _data
    this.initData(_data)
  }

  get dataSource() {
    return this._dataSource
  }

  _applicationId: any = null
  @Input()
  set applicationId(id: any) {
    this._applicationId = id
  }

  get applicationId() {
    return this._applicationId
  }

  constructor(
    private appService: AppService,
    private reqService: RequestService,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    // this.urlProxy = this.appService.urlProxy;
    this.checkDomain()
    this.userId = JSON.parse(this.appService.currentUser).userid
    this.owner = JSON.parse(this.appService.currentUser).username
  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
  }

  checkDomain() {
    const hostname = window.location.hostname
    // const hostname = 'office.esrivn.net'
    if (this.appService.urlProxy.indexOf(hostname) > -1) {
      this.urlProxy = ''
    } else {
      this.urlProxy = this.appService.urlProxy;
    }
    // console.log(this.urlProxy)
  }

  initData(data: any) {
    this.arrTabAttachment = []
    this.arrDataTree = []
    if (data !== null) {
      this.dataSource.dataSource.INFORMATION.KHOA_CHINH = this.primaryKey ? this.primaryKey : this.dataSource.dataSource.INFORMATION.KHOA_CHINH
      switch (data.dataSource.INFORMATION.SERVICE_TYPE) {
        case 'SQL':
        // case 'AutoData':
        case 'CloudData':
          this.type = 'sql';
          this.loadSqlOdataAttachmentList(data);
          break;
        case 'FeatureServer':
        case 'MapServer':
        case 'WorkflowServices':
          this.type = 'arcgis';
          // this.loadArcGISAttachmentList(data);
          this.loadSqlOdataAttachmentList(data);
          break;
        default:
          this.type = 'sql';
          this.loadSqlOdataAttachmentList(data);
          break;
      }
    }
  }
  private loadSqlOdataAttachmentList(data: any) {
    this.reqService.switchType('sql');
    this.clientId = this.appService.ClientId;
    // this.appId = this.appService.c$['appId'];

    let urlRequest = '';
    // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
    const user = JSON.parse(this.appService.currentUser);
    if (!this.isModePlugin) {
      if (user.schema && user.schema !== user.schemadefault) {
        // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
        this.reqService.switchType('clouddata');
        urlRequest = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
      } else {
        // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
        urlRequest = this.appService.urlWS + '/Attachs';
      }
    } else {
      urlRequest = this.appService.urlWS + '/Attachs';
    }

    const info = data.dataSource.INFORMATION
    const currentData = data.currentData
    const where = [
      ['TableId', '=', info.TABLE_ID],
      ['RecordId', '=', currentData[info.KHOA_CHINH]],
      ['ClientId', '=', this.clientId],
      ['ApplicationId', '=', this.applicationId],
      ['AttachGroupParent', '=', null]
    ];
    this.reqService.service.search({
      url: urlRequest,
      where
    }).subscribe(res => {
      console.log(res)
      this.baseList = [];
      this.listAttachment = [];
      if (res.success) {
        let arr: any[] = []
        res.features.forEach(item => {
          if (item.AttachGroup) {
            arr.push(item.AttachGroup)
          }
        });
        arr = this.appService.removeDuplicateValue(arr)
        this.arrDataTree.push({
          title: 'Attachment',
          key: 'attachment',
          level: 0,
          isAddIcon: true,
          isDeleteIcon: false,
          icon: 'pi pi-paperclip',
          data: null,
          mode: 'group',
          selectable: false,
          group: null,
          children: [],
          expanded: true,
          isLoad: true
        })
        arr.forEach((item: any, ind: any) => {
          const arr_obj = res.features.filter((v: any) => v.AttachGroup === item)

          const arr_gr: any[] = []
          arr_obj.forEach((obj: any) => {

            if (obj) {
              const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + obj.UrlFile).replace(/\\/g, '/');
              const baseFileUrl = this.baseFileUrl;
              const obj_gr = {
                id: obj.AttachId,
                contentType: obj.FileType,
                size: obj.FileSize ? obj.FileSize : 0, // sql odata chưa trả về size của file
                descr: obj.Description,
                url: this.isImage(obj.FileType) ? urlImage : this.getImgThumbnail(obj.UrlFile),
                urlFile: obj.UrlFile,
                name: obj.AttachName,
                dataAttachment: obj,
                owner: obj.Owner,
                userid: obj.UserId
              }
              arr_gr.push({
                title: obj.AttachName ? obj.AttachName : obj.Description,
                key: obj.AttachId,
                level: 1,
                isAddIcon: false,
                isDeleteIcon: true,
                mode: 'file',
                selectable: false,
                icon: 'pi pi-images',
                data: obj_gr,
                group: null,
                children: []
              })
            }

          })
          this.arrDataTree[0].children.push({
            title: item,
            key: this.arrDataTree[0].key + '_' + item,
            level: 1,
            isAddIcon: true,
            isDeleteIcon: false,
            icon: 'pi pi-folder',
            data: null,
            selectable: false,
            group: item,
            mode: 'group',
            children: arr_gr,
            isLoad: false
          })

        })
        res.features.forEach((item: any) => {
          if (!item.AttachGroup) {
            const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + item.UrlFile).replace(/\\/g, '/');
            const baseFileUrl = this.baseFileUrl;
            const obj = {
              id: item.AttachId,
              contentType: item.FileType,
              size: item.FileSize ? item.FileSize : 0, // sql odata chưa trả về size của file
              descr: item.Description,
              url: this.isImage(item.FileType) ? urlImage : this.getImgThumbnail(item.UrlFile),
              urlFile: item.UrlFile,
              name: item.AttachName,
              dataAttachment: item,
              owner: item.Owner,
              userid: item.UserId
            }
            this.arrDataTree[0].children.push({
              title: item.AttachName ? item.AttachName : item.Description,
              key: item.AttachId,
              level: 1,
              isAddIcon: false,
              isDeleteIcon: true,
              mode: 'file',
              selectable: false,
              icon: 'pi pi-images',
              data: obj,
              group: null,
              children: []
            })
          }
        })
        // this.baseList = this.appService.deepCloneObject(this.listAttachment);
        this.onNodeSelect(this.arrDataTree[0])
      }
    });
  }
  buildGroupChild(evt: any, node: any, isBuildTab?: boolean) {
    this.reqService.switchType('sql');
    this.clientId = this.appService.ClientId;
    // this.appId = this.appService.c$['appId'];

    let urlRequest = '';
    // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
    const user = JSON.parse(this.appService.currentUser);
    if (!this.isModePlugin) {
      if (user.schema && user.schema !== user.schemadefault) {
        // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
        this.reqService.switchType('clouddata');
        urlRequest = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
      } else {
        // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
        urlRequest = this.appService.urlWS + '/Attachs';
      }
    } else {
      urlRequest = this.appService.urlWS + '/Attachs';
    }
    const info = this.dataSource.dataSource.INFORMATION
    const currentData = this.dataSource.currentData
    const where = [
      ['TableId', '=', info.TABLE_ID],
      ['RecordId', '=', currentData[info.KHOA_CHINH]],
      ['ClientId', '=', this.clientId],
      ['ApplicationId', '=', this.applicationId],
      // [
      //   'or',
      //   ['AttachGroupParent', '=', evt],
      //   ['AttachGroup', '=', evt]
      // ]
      ['AttachGroupParent', '=', evt],
    ];
    this.reqService.service.search({
      url: urlRequest,
      where
    }).subscribe(res => {
      if (res.success) {
        console.log(res)
        if (res.features.length > 0) {
          let arr: any[] = []
          res.features.forEach(item => {
            if (item.AttachGroup) {
              arr.push(item.AttachGroup)
            }
          });
          arr = this.appService.removeDuplicateValue(arr)
          arr.forEach((item: any, ind: any) => {
            const arr_obj = res.features.filter((v: any) => v.AttachGroup === item)

            const arr_gr: any[] = []
            arr_obj.forEach((obj: any) => {

              if (obj) {
                const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + obj.UrlFile).replace(/\\/g, '/');
                const baseFileUrl = this.baseFileUrl;
                const obj_gr = {
                  id: obj.AttachId,
                  contentType: obj.FileType,
                  size: obj.FileSize ? obj.FileSize : 0, // sql odata chưa trả về size của file
                  descr: obj.Description,
                  url: this.isImage(obj.FileType) ? urlImage : this.getImgThumbnail(obj.UrlFile),
                  urlFile: obj.UrlFile,
                  name: obj.AttachName,
                  dataAttachment: obj
                }
                arr_gr.push({
                  title: obj.AttachName ? obj.AttachName : obj.Description,
                  key: obj.AttachId,
                  level: 1,
                  isAddIcon: false,
                  isDeleteIcon: true,
                  mode: 'file',
                  selectable: false,
                  icon: 'pi pi-images',
                  data: obj_gr,
                  group: null,
                  children: []
                })
              }

            })
            node.children.push({
              title: item,
              key: node.key + '_' + item,
              level: 1,
              isAddIcon: true,
              isDeleteIcon: false,
              icon: 'pi pi-folder',
              data: null,
              selectable: false,
              group: item,
              mode: 'group',
              isLoad: false,
              children: arr_gr
            })
          })
          res.features.forEach((item: any) => {
            if (!item.AttachGroup) {
              const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + item.UrlFile).replace(/\\/g, '/');
              const baseFileUrl = this.baseFileUrl;
              const obj = {
                id: item.AttachId,
                contentType: item.FileType,
                size: item.FileSize ? item.FileSize : 0, // sql odata chưa trả về size của file
                descr: item.Description,
                url: this.isImage(item.FileType) ? urlImage : this.getImgThumbnail(item.UrlFile),
                urlFile: item.UrlFile,
                name: item.AttachName,
                dataAttachment: item
              }
              node.children.push({
                title: item.AttachName ? item.AttachName : item.Description,
                key: item.AttachId,
                level: 0,
                isAddIcon: false,
                isDeleteIcon: true,
                mode: 'file',
                selectable: false,
                icon: 'pi pi-images',
                data: obj,
                group: null,
                children: []
              })
            }
          })

          if (isBuildTab) {
            const arr_group: any[] = []
            const arr_file: any[] = []
            node.children.forEach((item: any) => {
              if (item.mode === 'group') {
                arr_group.push(item.title)
              } else {
                arr_file.push(item.data)
              }

            })
            this.arrTabAttachment.push({
              displayName: node.title,
              icon: node.icon,
              // dataTab: evt.data,
              id: node.key,
              isClose: true,
              arrDataImg: arr_file,
              arrGroup: arr_group,
              modeTab: 'group',
              isAddGroup: false,
              newGroupName: null,
              treeNode: node,
              nodeChildren: node.children
            })
            this.tabIndex = this.arrTabAttachment.length - 1
          }

        } else {
          // node.children = []
          console.log(node.children)
          if (isBuildTab) {
            const arr: any[] = []
            const arr_file: any[] = []
            node.children.forEach((item: any) => {
              if (item.mode === 'group') {
                arr.push(item.group)
              } else {
                arr_file.push(item.data)
              }

            })
            this.arrTabAttachment.push({
              displayName: node.title,
              icon: node.icon,
              // dataTab: evt.data,
              id: node.key,
              isClose: true,
              arrDataImg: arr_file,
              arrGroup: arr,
              modeTab: 'group',
              isAddGroup: false,
              newGroupName: null,
              isLoad: false,
              treeNode: node,
              nodeChildren: node.children
            })
            this.tabIndex = this.arrTabAttachment.length - 1
          }
        }

        console.log(this.arrDataTree)
        // this.baseList = this.appService.deepCloneObject(this.listAttachment);
      }
    });
  }
  isImage(type: string): boolean {
    if (type && type.split('/').length > 0) {
      if (type.split('/')[0] === 'image') {
        return true;
      }
    }
    return false;
  }

  onNodeSelect(evt: any) {
    if (evt.mode === 'file') {
      const ind = this.arrTabAttachment.findIndex((v: any) => v.id === evt.key)
      if (ind < 0) {
        if (this.isModePlugin) {
          if (this.isImage(evt.data.contentType)) {
            this.arrTabAttachment.push({
              displayName: evt.title,
              icon: evt.icon,
              // dataTab: evt.data,
              id: evt.key,
              isClose: true,
              arrDataImg: [evt.data],
              arrGroup: [],
              modeTab: 'file',
              treeNode: evt,
              nodeChildren: [],
              isFullSize: false
            })
            this.tabIndex = this.arrTabAttachment.length - 1
          } else if (this.checkFileType(evt.data.contentType)) {
            if (!this.permissionList['EDIT']) {
              const url_q = this.appService.urlOdataAttachment + '/' + evt.data.urlFile
              this.queryFileAttachment(url_q).subscribe((res: any) => {
                this.arrTabAttachment.push({
                  displayName: evt.title,
                  icon: evt.icon,
                  // dataTab: evt.data,
                  id: evt.key,
                  isClose: true,
                  arrDataImg: [evt.data],
                  arrGroup: [],
                  modeTab: 'file',
                  treeNode: evt,
                  nodeChildren: [],
                  dataPlugin: res,
                  isFullSize: false
                })
                this.tabIndex = this.arrTabAttachment.length - 1
                if (this.checkFileType(evt.data.contentType)) {
                  this.cdr.detectChanges()
                  let div_ace: any = document.getElementById('aceDiv_' + evt.key)

                  const editor = ace.edit(div_ace, {
                    theme: 'ace/theme/dracula',
                    mode: this.checkFileType(evt.data.contentType),
                    value: res
                  });
                  this.arrTabAttachment[this.tabIndex].aceEditor = editor
                }

              }, err => {
                this.appService.createMessage('error', 'Load file error')
              })
            } else {
              this.arrTabAttachment.push({
                displayName: evt.title,
                icon: evt.icon,
                // dataTab: evt.data,
                id: evt.key,
                isClose: true,
                arrDataImg: [evt.data],
                arrGroup: [],
                modeTab: 'file',
                treeNode: evt,
                nodeChildren: [],
                isFullSize: false
              })
              this.tabIndex = this.arrTabAttachment.length - 1
              if (evt.data.contentType === 'text/html') {
                const url_q = this.appService.urlOdataAttachment + '/' + evt.data.urlFile
                window.open(url_q, '_blank')
              }

            }

          } else {
            this.arrTabAttachment.push({
              displayName: evt.title,
              icon: evt.icon,
              // dataTab: evt.data,
              id: evt.key,
              isClose: true,
              arrDataImg: [evt.data],
              arrGroup: [],
              modeTab: 'file',
              treeNode: evt,
              nodeChildren: [],
              isFullSize: false
            })
            this.tabIndex = this.arrTabAttachment.length - 1
            const url_q = this.appService.urlOdataAttachment + '/' + evt.data.urlFile
            window.open(url_q, '_blank')
          }


        } else {
          this.arrTabAttachment.push({
            displayName: evt.title,
            icon: evt.icon,
            // dataTab: evt.data,
            id: evt.key,
            isClose: true,
            arrDataImg: [evt.data],
            arrGroup: [],
            modeTab: 'file',
            treeNode: evt,
            nodeChildren: [],
            isFullSize: false
          })
          this.tabIndex = this.arrTabAttachment.length - 1
        }

      } else {
        this.tabIndex = ind
        this.arrTabAttachment[ind].arrDataImg = [evt.data]
      }
    } else {
      const ind = this.arrTabAttachment.findIndex((v: any) => v.id === evt.key)
      if (ind > -1) {
        this.tabIndex = ind
      } else {
        if (!evt.isLoad) {
          if (evt.group) {
            evt.isLoad = true
            this.buildGroupChild(evt.group, evt, true)
          }

        } else {
          const arr: any[] = []
          const arr_file: any[] = []
          evt.isLoad = true
          evt.children.forEach((item: any) => {
            if (item.mode === 'group') {
              arr.push(item.group)
            } else {
              arr_file.push(item.data)
            }

          })
          this.arrTabAttachment.push({
            displayName: evt.title,
            icon: evt.icon,
            // dataTab: evt.data,
            id: evt.key,
            isClose: evt.key === 'attachment' ? false : true,
            arrDataImg: arr_file,
            arrGroup: arr,
            modeTab: 'group',
            isAddGroup: false,
            newGroupName: null,
            treeNode: evt,
            nodeChildren: evt.children
          })
          this.tabIndex = this.arrTabAttachment.length - 1
        }
      }

    }

  }
  queryChildren() {

  }
  onCloseTab(evt: any) {
    console.log(this.arrTabAttachment[evt.index])
    if (this.arrTabAttachment[evt.index].id !== 'attachment') {
      this.tabIndex = evt.index === 0 ? 0 : evt.index - 1
      this.arrTabAttachment.splice(evt.index, 1)
    }

  }
  onNodeExpand(evt: any) {
    console.log(evt)
    if (!evt.node.isLoad) {
      evt.node.isLoad = true
      this.buildGroupChild(evt.node.group, evt.node)
    }
  }
  onClickAdd(evt: any, node: any) { }
  onClickDeleteItem(evt: any, node: any) {
    console.log(evt, node)
    // this.afterDeleteFile(node)
    evt.stopPropagation()
    this.appService.confirm('Confirm delete this file').subscribe((conf: any) => {
      if (conf) {
        // if (this.type !== 'arcgis') {
        this.loading = true
        const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
        this.reqService.switchType('sql');
        this.deleteAttachmentFile(node.data.urlFile).subscribe((res: any) => {
          // this.reqService.service.delete({
          //   url: urlServer + '?url=' + node.data.urlFile,
          // }).subscribe((res: any) => {
          console.log(res)
          if (res.success) {
            this.loading = false
            if (node.parent) {
              const ind = node.parent.children.findIndex((v: any) => v.key === node.key)
              node.parent.children.slice(ind, 1)
            }
            this.onDeleteInDB(node)
          } else {
            this.loading = false
            this.appService.createMessage('error', 'Delete attachment file fail')
          }
        }, err => {
          this.loading = false
          this.appService.createMessage('error', 'Delete attachment file fail')
        })
        // } else {
        //   this.deleteArcGISAttachment(evt, node)
        // }

      }
    })

  }
  onDeleteInDB(node: any) {
    let urlDelete = '';
    this.reqService.switchType('sql');
    // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
    const user = JSON.parse(this.appService.currentUser);
    if (!this.isModePlugin) {
      if (user.schema && user.schema !== user.schemadefault) {
        // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
        this.reqService.switchType('clouddata');
        urlDelete = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
      } else {
        // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
        urlDelete = this.appService.urlWS + '/Attachs';
      }
    } else {
      urlDelete = this.appService.urlWS + '/Attachs';
    }
    this.loading = true
    this.reqService.service.delete({
      url: urlDelete,
      data: { AttachId: node.key },
      primaryKey: 'AttachId'
    }).subscribe((res: any) => {
      if (res.success) {
        this.afterDeleteFile(node)
        this.loading = false
        this.appService.createMessage('success', 'Delete attachment file success')
      } else {
        this.loading = false
        this.appService.createMessage('error', 'Delete attachment file fail')
      }
    }, err => {
      this.loading = false
      this.appService.createMessage('error', 'Delete attachment file fail')
    })
  }
  afterDeleteFile(node: any) {
    console.log(node)
    if (node.parent) {
      const ind = node.parent.children.findIndex((v: any) => v.key === node.key)
      node.parent.children.splice(ind, 1)
    }
    const ind_tab = this.arrTabAttachment.findIndex((v: any) => v.id === node.key)
    if (ind_tab > -1) {
      this.arrTabAttachment.splice(ind_tab, 1)

      this.tabIndex = this.tabIndex > 0 ? this.tabIndex - 1 : 0
      // this.tabIndex = this.tabIndex === ind_tab ? this.tabIndex > 0 ? this.tabIndex - 1 : 0 : this.tabIndex - 1
    }

    const ind_group = this.arrTabAttachment.findIndex((v: any) => node.parent && v.id === node.parent.key)
    if (ind_group > -1) {
      this.arrTabAttachment[ind_group].arrDataImg = this.arrTabAttachment[ind_group].arrDataImg.filter((v: any) => v.id !== node.key)
      // this.tabIndex = this.tabIndex === ind_tab ? this.tabIndex > 0 ? this.tabIndex - 1 : 0 : this.tabIndex - 1
    }
  }
  onDeleteAttachFile(url_file: any) {
    const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
    this.reqService.switchType('sql');
    return this.reqService.service.delete({
      url: urlServer + '?url=' + url_file,
    })
  }
  onUploadNewFile(evt: any) { }
  onClickAddGroup(evt: any) {
    evt.isAddGroup = true
  }
  onAddGroup(evt: any) {
    console.log(evt)
    if (evt.newGroupName && evt.newGroupName !== '') {
      evt.nodeChildren.unshift({
        title: evt.newGroupName,
        key: evt.treeNode.key + '_' + evt.newGroupName,
        level: 0,
        isAddIcon: true,
        isDeleteIcon: false,
        icon: 'pi pi-folder',
        data: null,
        selectable: false,
        group: evt.newGroupName,
        mode: 'group',
        children: []
      })
      evt.arrGroup.push(evt.newGroupName)
      evt.isAddGroup = false
      evt.newGroupName = null

    }
  }
  onCancelAddGroup(evt: any) {
    evt.isAddGroup = false
    evt.newGroupName = null
  }
  openGroup(tab: any, data: any, mode: any) {
    if (mode === 'group') {
      const ind = this.arrTabAttachment.findIndex((v: any) => v.id === data)
      if (ind > -1) {
        this.tabIndex = ind
      } else {
        const node = this.arrTabAttachment[this.tabIndex].nodeChildren.find((v: any) => v.key === this.arrTabAttachment[this.tabIndex].treeNode.key + '_' + data)
        node.parent = this.arrTabAttachment[this.tabIndex].treeNode
        const arr: any[] = []
        const arr_group: any[] = []
        node.children.forEach((item: any) => {
          if (item.mode === 'file') {
            arr.push(item.data)
          } else {
            arr_group.push(item.group)
          }
        })
        // const obj = this.
        this.arrTabAttachment.push({
          displayName: data,
          icon: 'pi pi-folder',
          // dataTab: evt.data,
          id: this.arrTabAttachment[this.tabIndex].treeNode.key + '_' + data,
          isClose: true,
          arrDataImg: arr,
          arrGroup: arr_group,
          modeTab: 'group',
          isAddGroup: false,
          newGroupName: null,
          nodeChildren: node.children,
          treeNode: node
        })
        this.tabIndex = this.arrTabAttachment.length - 1
      }
    } else {
      console.log(data)
      const ind = this.arrTabAttachment.findIndex((v: any) => v.id === data.id)
      if (ind > -1) {
        this.tabIndex = ind
      } else {
        if (!this.isModePlugin) {
          const node = tab.nodeChildren.find((v: any) => v.key === data.id)
          this.arrTabAttachment.push({
            displayName: data.name ? data.name : data.descr,
            icon: 'pi pi-images',
            // dataTab: evt.data,
            id: data.id,
            isClose: true,
            arrDataImg: [data],
            arrGroup: [],
            modeTab: 'file',
            isFullSize: false,
            treeNode: node,
            nodeChildren: node.children
          })
          this.tabIndex = this.arrTabAttachment.length - 1
        } else {
          if (this.isImage(data.contentType)) {
            const node = this.arrTabAttachment[this.tabIndex].nodeChildren.find((v: any) => v.key === data.id)
            node.parent = this.arrTabAttachment[this.tabIndex].treeNode
            this.arrTabAttachment.push({
              displayName: data.name ? data.name : data.descr,
              icon: 'pi pi-images',
              // dataTab: evt.data,
              id: data.id,
              isClose: true,
              arrDataImg: [data],
              arrGroup: [],
              modeTab: 'file',
              isFullSize: false,
              nodeChildren: node.children,
              treeNode: node
            })
            this.tabIndex = this.arrTabAttachment.length - 1
          } else if (this.checkFileType(data.contentType)) {
            // const url_q = this.appService.urlOdataAttachment + '/' + data.urlFile
            // this.queryFileAttachment(url_q).subscribe((res: any) => {
            //   this.arrTabAttachment.push({
            //     displayName: data.name ? data.name : data.descr,
            //     icon: 'pi pi-images',
            //     // dataTab: evt.data,
            //     id: data.id,
            //     isClose: true,
            //     arrDataImg: [data],
            //     arrGroup: [],
            //     modeTab: 'file',
            //     dataPlugin: res,
            //     isFullSize: false
            //   })
            //   this.tabIndex = this.arrTabAttachment.length - 1
            //   if (this.checkFileType(data.contentType)) {
            //     this.cdr.detectChanges()
            //     let div_ace: any = document.getElementById('aceDiv_' + data.id)

            //     const editor = ace.edit(div_ace, {
            //       theme: 'ace/theme/dracula',
            //       mode: this.checkFileType(data.contentType),
            //       value: res
            //     });
            //     this.arrTabAttachment[this.tabIndex].aceEditor = editor
            //   }
            // }, err => {
            //   this.appService.createMessage('error', 'Load file error')
            // })
            const node = this.arrTabAttachment[this.tabIndex].nodeChildren.find((v: any) => v.key === data.id)
            node.parent = this.arrTabAttachment[this.tabIndex].treeNode
            if (!this.permissionList['EDIT']) {
              const url_q = this.appService.urlOdataAttachment + '/' + data.urlFile
              this.queryFileAttachment(url_q).subscribe((res: any) => {
                this.arrTabAttachment.push({
                  displayName: data.name ? data.name : data.descr,
                  icon: 'pi pi-images',
                  // dataTab: evt.data,
                  id: data.id,
                  isClose: true,
                  arrDataImg: [data],
                  arrGroup: [],
                  modeTab: 'file',
                  dataPlugin: res,
                  isFullSize: false,
                  nodeChildren: node.children,
                  treeNode: node
                })
                this.tabIndex = this.arrTabAttachment.length - 1
                if (this.checkFileType(data.contentType)) {
                  this.cdr.detectChanges()
                  let div_ace: any = document.getElementById('aceDiv_' + data.id)

                  const editor = ace.edit(div_ace, {
                    theme: 'ace/theme/dracula',
                    mode: this.checkFileType(data.contentType),
                    value: res
                  });
                  this.arrTabAttachment[this.tabIndex].aceEditor = editor
                }
              }, err => {
                this.appService.createMessage('error', 'Load file error')
              })
            } else {
              this.arrTabAttachment.push({
                displayName: data.name ? data.name : data.descr,
                icon: 'pi pi-images',
                // dataTab: evt.data,
                id: data.id,
                isClose: true,
                arrDataImg: [data],
                arrGroup: [],
                modeTab: 'file',
                isFullSize: false,
                nodeChildren: node.children,
                treeNode: node
              })
              this.tabIndex = this.arrTabAttachment.length - 1
              if (data.contentType === 'text/html') {
                const url_q = this.appService.urlOdataAttachment + '/' + data.urlFile
                window.open(url_q, '_blank')
              }

            }
          } else {
            const node = this.arrTabAttachment[this.tabIndex].nodeChildren.find((v: any) => v.key === data.id)
            node.parent = this.arrTabAttachment[this.tabIndex].treeNode
            this.arrTabAttachment.push({
              displayName: data.name ? data.name : data.descr,
              icon: 'pi pi-images',
              // dataTab: evt.data,
              id: data.id,
              isClose: true,
              arrDataImg: [data],
              arrGroup: [],
              modeTab: 'file',
              isFullSize: false,
              nodeChildren: node.children,
              treeNode: node
            })
            this.tabIndex = this.arrTabAttachment.length - 1
            const url_q = this.appService.urlOdataAttachment + '/' + data.urlFile
            window.open(url_q, '_blank')
          }

        }
      }
    }
  }
  fileMaxSize: any = 20
  fileList: any[] = []
  async readURL(evt: any) {
    this.fileList = []
    const files = (evt.target as HTMLInputElement).files;
    console.log(files)
    if (files && files.length > 0) {

      const file = files[0];
      const size = file.size / 1024 / 1024; // unit in MB
      if (size < this.fileMaxSize) {
        const reader = new FileReader();
        const file_comp = this.isImage(file.type) ? await this.appService.compressImage(file) : file
        this.fileList.push(file_comp);
        reader.onload = (e: any) => {
          // if (this.type === 'arcgis') {
          //   this.arcGISUploadFile()
          // } else {
          //   this.openPanelInsert(e.target.result, file_comp)
          // }
          this.openPanelInsert(e.target.result, file_comp)
        };
        reader.readAsDataURL(file);

      } else {
        this.appService.alert(this.appService.getMessage('0019') + this.fileMaxSize + ' MB!', 'error');
      }
    }

  }
  initForm() {
    const obj = this.arrTabAttachment[this.tabIndex]
    console.log(obj)
    this.formAttachment = new FormGroup({
      Description: new FormControl(),
      AttachName: new FormControl(this.fileList.length > 0 ? this.fileList[0].name : null),
      AttachGroup: new FormControl(obj.id === 'attachment' ? null : obj.displayName),
      AttachGroupParent: new FormControl(obj.treeNode && obj.treeNode.parent ? obj.treeNode.parent.key !== 'attachment' ? obj.treeNode.parent.group : null : null),
      KeepFileName: new FormControl(this.isModePlugin)
    })
  }
  openPanelInsert(evt: any, file: any) {
    this.initForm()
    this.dialogComponent.isComponent = false
    this.dialogComponent.title = 'Add Attachment File'
    this.dialogComponent.templateRef = this.tmpAttachInfo
    this.dialogComponent.onShow()
    this.dialogComponent.onClose.subscribe((res: any) => {
      this.fileList = []
      // this.isFulDialog = false
      this.formAttachment.reset()
      this.uploadNode.nativeElement.value = null;
      // this.arrTabAttachment = []
      // this.arrDataTree = []
    })
  }
  regFielname: any = new RegExp('^[a-zA-Z0-9_.-]*$')
  checkFileName(filename: any) {
    // const reg: any = 
    return this.regFielname.test(filename)
  }
  uploadFile(file: any, fileNameInput?: any) {
    const urlRequest = this.appService.urlOdataAttachment + '/odata/ProccessFile/PostAttactment';
    const info = this.dataSource.dataSource.INFORMATION
    const currentData = this.dataSource.currentData
    const form = new FormData();
    form.append('file', file);
    form.append('clientID', this.clientId);
    form.append('appID', this.applicationId);
    form.append('tableID', info.TABLE_ID);
    form.append('recordID', currentData[info.KHOA_CHINH]);
    if (!this.isModePlugin) {
      if (this.formAttachment.value['KeepFileName']) {
        form.append('fileNameInput', file.name);
      }
    } else {
      form.append('fileNameInput', fileNameInput ? fileNameInput : this.formAttachment.value['AttachName']);
    }

    return this.reqService.service.query({
      url: urlRequest,
      params: form,
      method: 'POST',
      contentType: 'unset',
      proxy: this.urlProxy
    })
  }
  onClickReset() {
    // console.log(this.arrTabAttachment[this.tabIndex])
    this.dialogComponent.closeDialog()
  }
  buildParamAttach(item: any, file: any) {
    // console.log(file)
    const info = this.dataSource.dataSource.INFORMATION
    const currentData = this.dataSource.currentData
    const val = (item.model as string).replace(/\\/g, '/');
    const index = val.lastIndexOf('/');
    const fileName = val.substring(index + 1, val.length);
    const userId = JSON.parse(this.appService.currentUser).userid
    const userName = JSON.parse(this.appService.currentUser).username
    const data = {
      // AttachId: null,
      RecordId: currentData[info.KHOA_CHINH],
      TableId: info.TABLE_ID,
      UrlFile: val,
      Description: this.formAttachment.value['Description'] && this.formAttachment.value['Description'] !== '' ? this.formAttachment.value['Description'] : fileName,
      FileType: file.type,
      ClientId: this.clientId,
      ApplicationId: this.applicationId,
      AttachName: this.formAttachment.value['AttachName'],
      AttachGroup: this.formAttachment.value['AttachGroup'],
      AttachGroupParent: this.formAttachment.value['AttachGroupParent'],
      FileSize: Math.round(file.size / 1024),
      UserId: userId,
      Owner: userName
    };
    return data
  }
  onSaveAttach() {
    const filename = this.formAttachment.value['AttachName']
    if (this.formAttachment.value['KeepFileName']) {
      if (!this.checkFileName(this.fileList[0].name)) {
        this.appService.createMessage(
          'warning',
          // this.appService.getMessage('0012')
          'The file name only contains letters, numbers or characters _ -'
        );
        return
      }
     
    }
    if (!this.checkFileName(filename)) {
      this.appService.createMessage(
        'warning',
        // this.appService.getMessage('0012')
        'The file name only contains letters, numbers or characters _ -'
      );
      return
    }
    console.log(this.formAttachment)
    if (this.formAttachment.valid) {
      this.uploadFile(this.fileList[0]).subscribe((res: any) => {
        this.insertDataToSqlTable(res, this.fileList[0])
      })
    } else {
      this.appService.createMessage(
        'warning',
        this.appService.getMessage('0012')
      );
    }

  }
  private insertDataToSqlTable(res: any, file: any) {
    let urlRequest = '';
    // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
    let isClouddata: boolean = false
    const user = JSON.parse(this.appService.currentUser);
    if (!this.isModePlugin) {
      if (user.schema && user.schema !== user.schemadefault) {
        // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
        isClouddata = true
        this.reqService.switchType('clouddata');
        urlRequest = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
      } else {
        // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
        isClouddata = false
        urlRequest = this.appService.urlWS + '/Attachs';
      }
    } else {
      isClouddata = false
      urlRequest = this.appService.urlWS + '/Attachs';
    }
    const data = this.buildParamAttach(res, file)
    this.reqService.service.insert({
      url: urlRequest,
      data,
      primaryKey: 'AttachId'
    }).subscribe((resp: any) => {
      // console.log(resp)
      if (resp.success) {
        this.dialogComponent.closeDialog()
        this.appService.createMessage('success', 'Add file success')
        const attachId = isClouddata ? resp.features[0].id : resp.features[0].AttachId
        this.queryAttachmentSQL(attachId).subscribe((result: any) => {
          if (result.success) {
            const data_resp = result.features[0]
            const node = this.arrTabAttachment[this.tabIndex].treeNode

            const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + data_resp.UrlFile).replace(/\\/g, '/');
            const baseFileUrl = this.baseFileUrl;
            this.arrTabAttachment[this.tabIndex].arrDataImg.push({
              id: data_resp.AttachId,
              contentType: data_resp.FileType,
              size: data_resp.FileSize ? data_resp.FileSize : 0, // sql odata chưa trả về size của file
              descr: data_resp.Description,
              url: this.isImage(data_resp.FileType) ? urlImage : this.getImgThumbnail(data_resp.UrlFile),
              urlFile: data_resp.UrlFile,
              name: data_resp.AttachName,
              dataAttachment: data_resp,
              owner: data_resp.Owner,
              userid: data_resp.UserId
            })
            node.children.push({
              title: data_resp.AttachName ? data_resp.AttachName : data_resp.Description,
              key: data_resp.AttachId,
              level: 0,
              isAddIcon: false,
              isDeleteIcon: true,
              mode: 'file',
              selectable: false,
              icon: 'pi pi-images',
              data: {
                id: data_resp.AttachId,
                contentType: data_resp.FileType,
                size: data_resp.FileSize ? data_resp.FileSize : 0, // sql odata chưa trả về size của file
                descr: data_resp.Description,
                url: this.isImage(data_resp.FileType) ? urlImage : this.getImgThumbnail(data_resp.UrlFile),
                urlFile: data_resp.UrlFile,
                name: data_resp.AttachName,
                dataAttachment: data_resp
              },
              group: null,
              children: []
            })
          }
        })

      } else {
        this.appService.createMessage('error', 'Add file fail')
      }
    }, err => {
      this.appService.createMessage('error', 'Add file fail')
    })
  }
  queryAttachmentSQL(id: any) {
    this.reqService.switchType('sql');
    this.clientId = this.appService.ClientId;
    // this.appId = this.appService.c$['appId'];

    let urlRequest = '';
    // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
    const user = JSON.parse(this.appService.currentUser);
    if (!this.isModePlugin) {
      if (user.schema && user.schema !== user.schemadefault) {
        // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
        this.reqService.switchType('clouddata');
        urlRequest = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
      } else {
        // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
        urlRequest = this.appService.urlWS + '/Attachs';
      }
    } else {
      urlRequest = this.appService.urlWS + '/Attachs';
    }
    const where = ['AttachId', '=', id];
    return this.reqService.service.search({
      url: urlRequest,
      where
    })
  }
  deleteAttachmentFile(urlFile: any) {
    const urlDelete = this.appService.urlWS + '/Attachs';
    const list: any[] = [];
    const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
    let headers = new HttpHeaders({
      Accept: 'text/plain',
      'Content-Type': 'application/json'
    });

    const a = localStorage ? this.appService.currentUser : null;
    const currentUser = a ? JSON.parse(a) : null;
    if (currentUser && currentUser.token) {
      headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
    }
    const res = this.http.delete(urlServer + '?url=' + urlFile, { headers })
    return res.pipe(
      map((res: any) => {
        const resp: DeleteResponse = {
          data: res.model,
          success: res.success,
          message: res.message
        };
        return resp;
      }))
    // const res = this.reqService.service.delete({
    //     url: urlServer + '?url=' + urlFile
    // })
    // return res;


  }

  onUpdateAttach() {
    const dataFile = this.arrTabAttachment[this.tabIndex].arrDataImg[0]
    let urlRequest = '';
    // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
    const user = JSON.parse(this.appService.currentUser);
    if (!this.isModePlugin) {
      if (user.schema && user.schema !== user.schemadefault) {
        // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
        this.reqService.switchType('clouddata');
        urlRequest = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
      } else {
        // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
        urlRequest = this.appService.urlWS + '/Attachs';
      }
    } else {
      urlRequest = this.appService.urlWS + '/Attachs';
    }
    if (dataFile.dataAttachment.AttachName && dataFile.dataAttachment.AttachName !== '') {
      this.loading = true
      const info = this.dataSource.dataSource.INFORMATION
      const currentData = this.dataSource.currentData
      const userId = JSON.parse(this.appService.currentUser).userid
      const userName = JSON.parse(this.appService.currentUser).username
      const data: any = {
        AttachId: dataFile.dataAttachment.AttachId,
        RecordId: currentData[info.KHOA_CHINH],
        TableId: info.TABLE_ID,
        UrlFile: dataFile.dataAttachment.UrlFile,
        Description: dataFile.dataAttachment.Description,
        FileType: dataFile.dataAttachment.FileType,
        ClientId: this.clientId,
        ApplicationId: this.applicationId,
        AttachName: dataFile.dataAttachment.AttachName,
        AttachGroup: dataFile.dataAttachment.AttachGroup,
        AttachGroupParent: dataFile.dataAttachment.AttachGroupParent,
        FileSize: Math.round(dataFile.dataAttachment.FileSize),
        UserId: userId,
        Owner: userName
      }
      this.reqService.service.update({
        url: urlRequest,
        data,
        primaryKey: 'AttachId'
      }).subscribe((resp: any) => {
        if (resp.success) {
          this.loading = false
          this.appService.createMessage('success', 'Update attachment infomation success')
          this.arrTabAttachment[this.tabIndex].arrDataImg[0].name = this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.AttachName
          this.arrTabAttachment[this.tabIndex].arrDataImg[0].descr = this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.Description
          this.arrTabAttachment[this.tabIndex].displayName = this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.AttachName
          this.arrTabAttachment[this.tabIndex].treeNode.title = this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.AttachName
        } else {
          this.loading = false
          this.appService.createMessage('error', 'Update attachment infomation error')
        }
      }, err => {
        this.loading = false
        this.appService.createMessage('error', 'Update attachment infomation error')
      })
    } else {
      this.appService.createMessage(
        'warning',
        this.appService.getMessage('0012')
      );
    }

  }
  onFullBody(tab: any) {
    // console.log(tab)
    tab.isFullSize = !tab.isFullSize
  }
  onResetFileInfo() {
    this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.AttachName = this.arrTabAttachment[this.tabIndex].arrDataImg[0].name
    this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.Description = this.arrTabAttachment[this.tabIndex].arrDataImg[0].descr
  }
  onUpdateFile(tab: any) {
    // console.log(tab)
    const editor = tab.aceEditor
    const info = this.dataSource.dataSource.INFORMATION
    const currentData = this.dataSource.currentData
    const urlFile = tab.arrDataImg[0].urlFile
    this.deleteAttachmentFile(urlFile).subscribe((res: any) => {
      if (res.success) {
        // const file_name = filename ? filename : this.filePlugin && this.filePlugin.name ? this.filePlugin.name : ''
        const name_up = urlFile.substring(urlFile.lastIndexOf('/') + 1, urlFile.length)
        const file_name = tab.arrDataImg[0].name
        const file_up = new File([editor.getValue()], file_name ? file_name : name_up, {
          type: tab.arrDataImg[0].contentType
        })
        this.uploadFile(file_up, file_name && name_up === file_name ? file_name : '').subscribe((resp: any) => {
          // console.log(file_up)
          this.updateInfoFile(resp, file_up)
        })

      } else {
        this.appService.createMessage('error', this.appService.getMessage('0008'));
      }
    })
  }
  updateInfoFile(item: any, file: File) {
    const data = this.arrTabAttachment[this.tabIndex]
    const dataAttach = data.arrDataImg[0]
    const info = this.dataSource.dataSource.INFORMATION
    const currentData = this.dataSource.currentData
    const val = (item.model as string).replace(/\\/g, '/');
    const index = val.lastIndexOf('/');
    const fileName = val.substring(index + 1, val.length);
    const userId = JSON.parse(this.appService.currentUser).userid
    const userName = JSON.parse(this.appService.currentUser).username
    const param_update = {
      RecordId: currentData[info.KHOA_CHINH],
      TableId: info.TABLE_ID,
      UrlFile: val,
      Description: dataAttach.descr,
      FileType: file.type,
      ClientId: this.clientId,
      ApplicationId: this.applicationId,
      AttachName: dataAttach.name,
      AttachGroup: dataAttach.dataAttachment['AttachGroup'],
      AttachGroupParent: dataAttach.dataAttachment['AttachGroupParent'],
      FileSize: Math.round(file.size / 1024),
      AttachId: dataAttach.dataAttachment.AttachId,
      UserId: userId,
      Owner: userName
    }
    const chk = dataAttach.name && dataAttach.name !== '' ? true : false
    if (chk) {
      this.loading = true
      const urlRequest = this.appService.urlWS + '/Attachs';
      this.reqService.service.update({
        url: urlRequest,
        data: param_update,
        primaryKey: 'AttachId'
      }).subscribe((resp: any) => {
        if (resp.success) {
          this.loading = false
          this.appService.createMessage('success', 'Update attachment infomation success')
          this.arrTabAttachment[this.tabIndex].arrDataImg[0].urlFile = val
          this.arrTabAttachment[this.tabIndex].arrDataImg[0].size = Math.round(file.size / 1024)

          this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.UrlFile = val
          this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.FileSize = Math.round(file.size / 1024)

          // this.arrTabAttachment[this.tabIndex].treeNode.data = this.arrTabAttachment[this.tabIndex].arrDataImg[0].dataAttachment.AttachName
          this.arrTabAttachment[this.tabIndex].treeNode.data.urlFile = val
          this.arrTabAttachment[this.tabIndex].treeNode.data.size = Math.round(file.size / 1024)
          this.arrTabAttachment[this.tabIndex].treeNode.data.dataAttachment.UrlFile = val
          this.arrTabAttachment[this.tabIndex].treeNode.data.dataAttachment.FileSize = Math.round(file.size / 1024)
        } else {
          this.loading = false
          this.appService.createMessage('error', 'Update attachment infomation error')
        }
      }, err => {
        this.loading = false
        this.appService.createMessage('error', 'Update attachment infomation error')
      })
    } else {
      this.appService.createMessage(
        'warning',
        this.appService.getMessage('0012')
      );
    }

  }
  //Attachment Arcgis
  private async loadArcGISAttachmentList(data: any) {
    const info = data.dataSource.INFORMATION
    const currentData = data.currentData
    this.arrDataTree = []
    // const where = [
    //   ['TableId', '=', info.TABLE_ID],
    //   ['RecordId', '=', currentData[info.KHOA_CHINH]],
    //   ['ClientId', '=', this.clientId],
    //   ['ApplicationId', '=', this.appId],
    //   ['AttachGroupParent', '=', null]
    // ];
    this.reqService.switchType('arcgis3x');
    const content: any = {
      f: 'json',
    };
    const url = info.URL_EDIT + '/' + currentData[info.KHOA_CHINH] + '/attachments';
    this.reqService.service.query({
      url,
      params: content
    }).subscribe((resp) => {
      if (resp.data && resp.data.attachmentInfos) { // ông này là 4x
        this.baseList = [];
        this.listAttachment = [];
        const urlProxy = this.urlProxy;
        this.arrDataTree.push({
          title: 'Attachment',
          key: 'attachment',
          level: 0,
          isAddIcon: true,
          isDeleteIcon: false,
          icon: 'pi pi-paperclip',
          data: null,
          mode: 'group',
          selectable: false,
          group: null,
          children: [],
          expanded: true,
          isLoad: true
        })
        resp.data.attachmentInfos.forEach((item: any) => {
          // Thêm proxy cho url để fix lỗi CORS
          let urlImage = urlProxy + url + '/' + item.id;
          // this.listAttachment.push({
          //   id: item.id,
          //   contentType: item.contentType,
          //   size: item.size,
          //   name: item.name,
          //   url: this.isImage(item.contentType) ? urlImage : this.baseFileUrl,
          // });

        });

      } else if (resp.attachmentInfos) { // ông này là 3x
        console.log(resp.attachmentInfos)
        this.baseList = [];
        this.listAttachment = [];
        const urlProxy = this.urlProxy;
        this.arrDataTree.push({
          title: 'Attachment',
          key: 'attachment',
          level: 0,
          isAddIcon: true,
          isDeleteIcon: false,
          icon: 'pi pi-folder',
          data: null,
          mode: 'group',
          selectable: false,
          group: null,
          children: [],
          expanded: true,
          isLoad: true
        })
        resp.attachmentInfos.forEach((item: any) => {
          // Thêm proxy cho url để fix lỗi CORS
          let urlImage = urlProxy + url + '/' + item.id;
          const obj = {
            id: item.id,
            contentType: item.contentType,
            size: item.size ? item.size : 0, // sql odata chưa trả về size của file
            descr: item.name,
            url: this.isImage(item.contentType) ? urlImage : this.getImgThumbnail(item.UrlFile),
            urlFile: item.UrlFile,
            name: item.name,
            dataAttachment: item
          }
          this.arrDataTree[0].children.push({
            title: item.name,
            key: item.id,
            level: 1,
            isAddIcon: false,
            isDeleteIcon: true,
            mode: 'file',
            selectable: false,
            icon: 'pi pi-images',
            data: obj,
            group: null,
            children: []
          })
        });
      }
    });
  }
  private arcGISUploadFile() {
    loadModules([
      'esri/layers/FeatureLayer'
    ]).then(async ([
      FeatureLayer
    ]) => {
      let url = this.dataSource.dataSource.INFORMATION.URL_EDIT;
      const featureLayer = new FeatureLayer(url);
      const listRequest: any[] = [];
      const form = new FormData();
      form.append('attachment', this.fileList[0]);
      this.loading = true
      featureLayer.addAttachment(this.dataSource.currentData[this.dataSource.dataSource.INFORMATION.KHOA_CHINH], form, (res: any) => {
        this.loading = false
        if (res.success) {
          // const data_resp = resp.features[0]
          const node = this.arrTabAttachment[this.tabIndex].treeNode

          // const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + data_resp.UrlFile).replace(/\\/g, '/');
          const url_attachment = this.dataSource.dataSource.INFORMATION.URL_EDIT + '/' + this.dataSource.currentData[this.dataSource.dataSource.INFORMATION.KHOA_CHINH] + '/attachments';
          let urlImage = this.urlProxy + url_attachment + '/' + res.attachmentId;
          const baseFileUrl = this.baseFileUrl;
          this.arrTabAttachment[this.tabIndex].arrDataImg.push({
            id: res.attachmentId,
            contentType: this.fileList[0].type,
            size: this.fileList[0].size ? this.fileList[0].size : 0, // sql odata chưa trả về size của file
            descr: this.fileList[0].name,
            url: this.isImage(this.fileList[0].type) ? urlImage : this.getImgThumbnail(this.fileList[0].name),
            urlFile: null,
            name: this.fileList[0].name,
            dataAttachment: this.fileList[0],
          })
          node.children.push({
            title: this.fileList[0].name,
            key: res.id,
            level: 0,
            isAddIcon: false,
            isDeleteIcon: true,
            mode: 'file',
            selectable: false,
            icon: 'pi pi-images',
            data: {
              id: res.id,
              contentType: this.fileList[0].type,
              size: this.fileList[0].size ? this.fileList[0].size : 0, // sql odata chưa trả về size của file
              descr: this.fileList[0].name,
              url: this.isImage(this.fileList[0].type) ? urlImage : this.getImgThumbnail(this.fileList[0].UrlFile),
              urlFile: this.fileList[0].UrlFile,
              name: this.fileList[0].name,
              dataAttachment: this.fileList[0]
            },
            group: null,
            children: []
          })
        } else {
          this.appService.createMessage('error', res.message)
        }
      }, (err: any) => {
        this.loading = false
        this.appService.createMessage('error', err.message)
      })
    });
  }
  deleteArcGISAttachment(evt: any, node: any) {
    loadModules([
      'esri/layers/FeatureLayer'
    ]).then(async ([
      FeatureLayer
    ]) => {
      let url = this.dataSource.dataSource.INFORMATION.URL_EDIT;
      const featureLayer = new FeatureLayer(url);
      const recordId = this.dataSource.currentData[this.dataSource.dataSource.INFORMATION.KHOA_CHINH]
      featureLayer.deleteAttachments(recordId, [node.key], (resp: any) => {
        const res = resp[0]
        if (res.success) {
          if (node.parent) {
            const ind = node.parent.children.findIndex((v: any) => v.key === node.key)
            node.parent.children.slice(ind, 1)
          }
          this.afterDeleteFile(node)
          this.appService.createMessage('success', 'Delete attachment file success')
        } else {
          this.appService.createMessage('error', 'Delete attachment file error')
        }
      }, (err: any) => {
        console.log(err)
        this.appService.createMessage('error', err.message)
      })
    })
  }
  queryFileAttachment(url: any) {
    const contentType = 'application/json';

    let headers = new HttpHeaders({
      Accept: 'text/plain'
    });

    headers = headers.append('Content-Type', contentType);
    const res = this.http.get(this.appService.urlProxy + '?' + url, { headers, responseType: 'text' })
    return res;
  }

  checkFileType(filetype: any) {
    let type: any = null
    switch (filetype) {
      case 'text/html':
        type = 'ace/mode/html'
        break;
      case 'text/javascript':
        type = 'ace/mode/javascript'
        break;
      case 'text/css':
        type = 'ace/mode/css'
        break;
      case 'application/json':
        type = 'ace/mode/json'
        break;
      case 'text/xml':
        type = 'ace/mode/xml'
        break;
      default:
        type = null
        break;
    }
    return type
  }
  getImgThumbnail(url: any) {
    const file_path = url.substr(url.lastIndexOf('.') + 1).toLowerCase()
    let url_img: any = null
    switch (file_path) {
      case 'pdf':
        url_img = './assets/imgs/imgFile/pdf.png'
        break;
      case 'doc': case 'docx': case 'docm':
        url_img = './assets/imgs/imgFile/microsoft_word.png'
        break;
      case 'xls': case 'xlsm': case 'xlsx':
        url_img = './assets/imgs/imgFile/microsoft_excel.png'
        break;
      case 'pptx': case 'ppt': case 'pptm':
        url_img = './assets/imgs/imgFile/powerpoint.png'
        break;
      case 'js':
        url_img = './assets/imgs/imgFile/javascript.png'
        break;
      case 'css': case 'scss': case 'sass':
        url_img = './assets/imgs/imgFile/css.png'
        break;
      case 'htm': case 'html':
        url_img = './assets/imgs/imgFile/html.png'
        break;
      case 'json': case 'json5':
        url_img = './assets/imgs/imgFile/json.png'
        break;
      case 'xml':
        url_img = './assets/imgs/imgFile/xml.png'
        break;
      default:
        url_img = this.baseFileUrl
        break;
    }
    return url_img
  }
  onDeleteFile(tab: any, data: any, evt: any) {
    evt.stopPropagation()
    const node = tab.nodeChildren.find((v: any) => v.key === data.id)
    this.onClickDeleteItem(evt, node)

  }
  onViewFile(tab: any) {
    // console.log(tab)
    const url_q = this.appService.urlOdataAttachment + '/' + tab.arrDataImg[0].urlFile
    window.open(url_q, '_blank')
  }
}
