import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QRCodeComponent } from 'angularx-qrcode';

@Component({
    selector: 'app-core-qr-code',
    templateUrl: './core-qr-code.component.html',
    styleUrls: ['./core-qr-code.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR
            , useExisting: forwardRef(() => CoreQrCodeComponent)
            , multi: true
        }
    ]
})
export class CoreQrCodeComponent implements OnInit, ControlValueAccessor {

    @ViewChild('qrCode', { static: true }) qrCode?: QRCodeComponent;

    qrCodeUrl = '';

    @Input() showLabel = true;
    @Input() labelName = '';
    @Input() labelWidth = '200';
    @Input() isLabelLeft = true;
    @Input() notUseTranslate = false;

    private onChange: (value: any) => void = () => { };
    private onTouched: () => void = () => { };

    constructor() { }

    ngOnInit(): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            this.qrCodeUrl = obj;
        }
        this.onChange(obj);
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

}
