import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { listTypeGIS, urlGIS, urlODATA, urlSPACE, urlTABLE } from './dataImage';

@Component({
  selector: 'app-form-data-services',
  templateUrl: './form-data-services.component.html',
  styleUrls: ['./form-data-services.component.scss']
})
export class FormDataServicesComponent implements OnInit {
  
  @Output() clickEmit: EventEmitter<any> = new EventEmitter();
  @Output() blurEmit: EventEmitter<any> = new EventEmitter();
  @Output() keyupEmit: EventEmitter<any> = new EventEmitter();

  public dataService: any = null;
  public dataServiceName = null;
  public urlDataServiceCoreTech = null;
  public urlNameServiceCoreTech = null;
  public urlAliasServiceCoreTech = null;
  public currentTableId = 0;

  public dataJson = null;

  public currentTable = null;
  
  private _valueInput = '';
  @Input('valueInput')
  set valueInput(e: any) {
    this._valueInput = e;
  }
  get valueInput(): any {
    return this._valueInput;
  }

  private _datafilterService = null;
  @Input('datafilterService')
  set datafilterService(data: any) {
    this._datafilterService = data;
    this.binDataService(data);
  }
  get datafilterService(): any {
    return this._datafilterService;
  }
  constructor(
    public appService: AppService,
  ) { }

  ngOnInit(): void {
  }

  bindNameService() {

  }
  async openTable(event: any) {
    this.currentTable = event;
    this.currentTableId = event.tableId;
  }
  binDataService(data: any) {
    const arr: any[] = [];
    if (data !== null) {
      data.forEach((element: any) => {
        const ixdType = listTypeGIS.findIndex(type => type === element.serviceType)
        if (ixdType !== -1) {
          element.imgServiceType = urlGIS;
        } else if (element.serviceType === "SQL") {
          element.imgServiceType = urlODATA;
        } else {
          element.imgServiceType = urlTABLE;
        }
        element.imgSpace = urlSPACE;

        arr.push(element);
      });
      this.dataService = [...arr];
    }
  }

  clickInputEvent(e: any) {
    this.clickEmit.emit(e);
  }

  blurInputEvent(e: any) {
    this.blurEmit.emit(e);
  }

  onKeyChangeDataService(event: any) {
    this.keyupEmit.emit(event);
  }
}
