import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { urlSPACE, urlGIS, urlODATA, urlTABLE, listTypeGIS } from './dataImage';
import { ReportActionService } from 'app/reports/services/report-action.service';
import { ITypeDataServiceCoreTech } from 'app/reports/report-designer/config/app-config';
import { AppService } from 'app/app-base/app.service';

@Component({
  selector: 'app-data-table-service',
  templateUrl: './data-table-service.component.html',
  styleUrls: ['./data-table-service.component.scss']
})
export class DataTableServiceComponent implements OnInit {
  @Output() clickEmit: EventEmitter<any> = new EventEmitter();
  @Output() blurEmit: EventEmitter<any> = new EventEmitter();
  @Output() keyupEmit: EventEmitter<any> = new EventEmitter();

  private _valueInput = '';
  @Input('valueInput')
  set valueInput(e: any) {
    this._valueInput = e;
  }
  get valueInput(): any {
    return this._valueInput;
  }

  public dataService: any;
  public dataServiceName: any;
  public urlDataServiceCoreTech: any;
  public urlNameServiceCoreTech: any;
  public urlAliasServiceCoreTech: any;
  public currentTableId = 0;



  private _datafilterService: any;
  @Input('datafilterService')
  set datafilterService(data: any) {
    this._datafilterService = data;
    if (data) {
      this.binDataService(data);
    }
  }
  get datafilterService(): any {
    return this._datafilterService;
  }
  constructor(
    private appService: AppService,
    private raService: ReportActionService
  ) { }

  ngOnInit(): void {
    console.log("data-table-service.component");

  }

  bindNameService() {

  }
  async openTable(event: any) {
    let dataServiceCoreTech: ITypeDataServiceCoreTech = {
      tableName: '',
      tableAlias: '',
      tableUrl: '',
      tableType: '',
      urlView: ''
    };
    this.currentTableId = event.tableId;

    dataServiceCoreTech.tableName = event.tableName;
    dataServiceCoreTech.tableAlias = event.alias;
    let url = event.urlView;
    let token = '';
    // get du lieu
    if (event.urlToken != null) {
      if (this.appService.appConfig) {
        this.appService.appConfig.Services = [{
          applicationId: 1,
          clientId: JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']).clientId,
          hasTileCache: '',
          isDisplay: null,
          isIdentify: '',
          isVisible: '',
          optionName: null,
          orderNo: 1,
          serviceId: 1,
          serviceName: '',
          serviceType: '',
          spatialReference: null,
          urlEdit: event.urlEdit,
          urlView: event.urlView,
          urlToken: event.urlToken,
          urlVectorTile: "",
          userNamePortal: event.userNamePortal,
          passWordPortal: event.passWordPortal,
        }];
        token = await this.appService.getArcGISToken(url);
      }
    }

    const ixdType = listTypeGIS.findIndex(type => type === event.tableType)
    if (ixdType !== -1) {
      let stringArcGis = "/query?where=1%3D1&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson";
      if (token && token !== "") {
        stringArcGis = stringArcGis + "&token=" + token
      }
      url = `${url}${stringArcGis}`;
    } else {
      let stringDatabase = '';
      const tokenBearer = JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser'])
      if (tokenBearer) {
        const findParamsError = url.includes('?')
        if (tokenBearer.token) {
          stringDatabase = findParamsError ? "&tokenbearer=" + tokenBearer.token : "?tokenbearer=" + tokenBearer.token;
        }
      }
      url = `${url}${stringDatabase}`;
    }
    dataServiceCoreTech.tableUrl = url;
    dataServiceCoreTech.tableType = event.tableType;
    dataServiceCoreTech.urlView = event.urlView;
    this.raService.dataServiceCoreTech = dataServiceCoreTech;
  }
  binDataService(data: any) {
    const arr: any[] = [];
    if (data !== null) {
      data.forEach((element: any) => {
        const ixdType = listTypeGIS.findIndex(type => type === element.serviceType)
        if (ixdType !== -1) {
          element.imgServiceType = urlGIS;
        } else if (element.serviceType === "SQL") {
          element.imgServiceType = urlODATA;
        } else {
          element.imgServiceType = urlTABLE;
        }
        element.imgSpace = urlSPACE;

        arr.push(element);
      });
      this.dataService = [...arr];
    }
  }


  clickInputEvent(e: any) {
    this.clickEmit.emit(e);
  }

  blurInputEvent(e: any) {
    this.blurEmit.emit(e);
  }

  onKeyChangeDataService(event: any) {
    this.keyupEmit.emit(event);
  }
}
