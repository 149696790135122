import {
    Component,
    ElementRef,
    OnDestroy,
    Output,
    ViewChild,
    EventEmitter,
    OnInit,
    ComponentFactoryResolver,
    TemplateRef,
    ChangeDetectorRef
} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as converter from 'xml-js';
import { combineLatest, from, Observable } from 'rxjs';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { CONFIG_FIELD_HISTORY } from '../run-job/array-field-history';
import { ActivatedRoute } from '@angular/router';
import { parse } from 'zipson/lib';
import { RequestService } from 'app/core/services/request.service';
import { AuthService } from 'app/app-base/auth.service';
import { toolTableConfig } from 'app/app-base/interface/app-config';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';
import { CoreWindowComponent } from 'app/core/core-window';

declare var require: any
let BpmnViewer = require('bpmn-js/lib/Viewer');
let BpmnModeler = require('bpmn-js/lib/Modeler');

@Component({
    selector: 'app-view-job-workflow',
    templateUrl: './view-job-workflow.component.html',
    styleUrls: ['./view-job-workflow.component.scss']
})
export class ViewJobComponent implements OnInit, OnDestroy {
    // @ViewChild('containerRef', { static: false, read: ViewContainerRef })
    @ViewChild('formSelectStep', { static: true }) formSelectStep!: TemplateRef<any>;
    @ViewChild('dialogPrime', { static: true }) dialogPrime!: DialogPrimeComponent;
    @ViewChild('dialogUser', { static: true }) dialogUser!: DialogPrimeComponent;
    @ViewChild('divDiagram') private divDiagram!: ElementRef;
    @ViewChild('templateNextStep') templateNextStep!: TemplateRef<any>;
    @ViewChild('tempUser') tempUser!: TemplateRef<any>;
    @ViewChild('tempApprove') tempApprove!: TemplateRef<any>;
    @ViewChild('coreWindow') coreWindow!: CoreWindowComponent
    createDynamicEvent: EventEmitter<any> = new EventEmitter();


    // tslint:disable-next-line: no-output-on-prefix
    @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
    createTblFinish: EventEmitter<any> = new EventEmitter();
    private createTblHisFinish: EventEmitter<any> = new EventEmitter();
    public w$: any = 'w$';
    indexTap = 0;
    whereWf:any = null;
    public tableRowDefination:any = [];
    public pageIndex = 1;
    public tableDataSource = [];
    pageSize = 10;
    isOpenFormAddWf = true;
    public mode = 'table';
    arrNextStep:any = [];
    arrSelectApprove:any = [];
    isNextStep = true;
    currentJobType:any = null;
    defaultCheckedUser:any = [];
     strCheckedUser: any = '';
     dataUser = [];
     treeConfigUser: any = {
        selectionMode: 'checkbox',
        filter: false,
        virtualScroll: false,
    }

    currentData:any = null;
    public formData: FormGroup = new FormGroup({});
    public formDataStep!: FormGroup;
    public formApprove!: FormGroup;
    public isVisible:boolean = false;
    public urlImage = '';
    public divCurentStep = '';
    public isShowIma = false;
    bpmnJS: any;
    public tableDataHistory:any = [];
    public tableRowDefination1:any = [];
    public nzSpan = 24;
    public fieldList:any = [];
    configColumHis:any = CONFIG_FIELD_HISTORY;
    labSend = 'Send';
    arrTypeHis:any = { 1: 'Chấp nhận', 2: 'Gửi đi', 3: 'Trả lại', 4: 'Chuyển tiếp', 5: 'Hoàn thành', 6:'Approve', 7: 'Un approve'};
    arrTab: any = [{
        code: 'workflow',
        title: 'Workflow'
    }, {
        code: 'detail',
        title: 'Detail'
    }, {
        code: 'history',
        title: 'History'
    }];
    tableConfig = {
        scrollHeight: "auto",
        responsiveLayout: 'scroll',
        styleClass: "p-datatable-gridlines",
        paginator: true,
        rows: 20
    }
    toolTableConfig: toolTableConfig = {
        isAdd: true,
        isDelete: true,
        isEdit: true,
        isSave: true,
        isSearch: false
    }
    public listRadio = [
        { CODE: '1', DESCR: 'User' },
        { CODE: '2', DESCR: 'Owner' }
    ]

    private xml = `<?xml version="1.0" encoding="UTF-8"?>
    <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_00f3jo1" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="3.0.0-dev">
      <bpmn:process id="Process_03i8cud" isExecutable="true">
        <bpmn:startEvent id="StartEvent_1" />
      </bpmn:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_03i8cud">
          <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
            <dc:Bounds x="179" y="159" width="36" height="36" />
          </bpmndi:BPMNShape>
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </bpmn:definitions>`;

    dataConfig: any;
    loading!: boolean;
    formLoadFinish = false;
    currentStep: any;
    arrStep: any[] = [];
    diagram: any;
    currentStepOb: any;
    windowId: any;
    lookupCondition:any = {};
    arrJobtypeStep:any = [];
    clientId:any = null;
    public userid: any;
    percCompleJob = null;
    arrJobType:any = [];
    currentUser = null;
    stepApprove:any = null;
    dataUpdateField:any = null;
    stepExteralWorkflow:any = null;
    isShowUserNextStep = true;
    isRequiredAssignedTo1 = true;
    idNextStepSlect:any = null
    isBlockAssign: boolean = false

    dlgWidth = '900px';
    constructor(
        private appService: AppService,
        private workflowService: BpmnWorkflowService,
        private router: ActivatedRoute,
        private reqService: RequestService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,

    ) {}

    ngOnDestroy(): void {
        // this.onCloseEvent.emit(true);
    }

    ngOnInit(): void {
        if (localStorage) {
            const User = this.appService.currentUser;
            if (User !== null && User !== undefined) {
                this.currentUser = this.appService.convertStringToJson(User).username;
            }
        }

        // mobile wf
        const params:any = this.router.snapshot.paramMap;
        const windowId = eval(params['params']['windowId']);
        const jobId = eval(params['params']['jobId']);
        const username = params['params']['username'];
        const password = params['params']['password'];
        const clientId = eval(params['params']['clientId']);
        const appid = eval(params['params']['appId']);
        if (username && password && appid) {
            // Là ứng dụng bản đồ trên mobile
            // this.isWebApp = false;
            // this.loginMobile({ username, password, appid });
        } else {
            // Là ứng dụng bản đồ trên web
            // this.initWebMap();
        }

        if (windowId && jobId) {
            const paramJob = ['JobId', '=', jobId];
            const conFigWindow = this.workflowService.initData(windowId);
            const dataJob =  this.workflowService.queryJob(paramJob);
            const listReq = [conFigWindow, dataJob];
            combineLatest(listReq).subscribe((res: any) => {
                const result =  res[0].model.config ? parse(res[0].model.config) : null;
                const wdConfig = this.reqService.mapConfig(result);
                if (wdConfig.error) {
                    this.appService.alert(wdConfig.error, 'error');
                    return;
                }
                const objConfig = {dataSource: {FIELD_LIST: wdConfig.tabs[0].fields}, currentData: res[1].features[0]};
                if (res[1].features.length > 0) {
                    this.currentData =  res[1].features[0];
                    this.getWorkflowModel(this.currentData.JobTypeId);
                    this.queryJobtypeStep(this.currentData.JobTypeId);
                    this.dataLookUp(this.currentData.JobTypeId);
                }
                this.dataConfig = objConfig;
                this.currentUser = username;
                this.clientId = clientId;
            }, err => {

            });
        }

        this.lookupCondition['AssignedTo'] = [];


    }
    public run(evt:any) {
        this.currentData = evt.currentData[evt.currentData.length -1];
        if (evt.isMobile) {
            // const jobid = this.currentData.JobId;
            // const windowId = evt.WindowId;
        } else {
            this.init(evt);
        }
    }

    init(evt:any){
        if (this.currentData !== null){
            console.log(evt);
            const urlJob = evt.dataSource.INFORMATION.URL_EDIT;
            if (urlJob !== null && urlJob !== '') {
                let i = urlJob.indexOf('/WFJOBs');
                let url1 = urlJob.substr(0, i);
                this.workflowService.url = url1 + '/';

                this.cd.detectChanges();
                this.clientId = this.appService.ClientId;
                const user = this.appService.currentUser;
                this.userid = user ?  JSON.parse(user).userid : null;

                this.dataConfig = evt;
                this.getWorkflowModel(this.currentData.JobTypeId);
                this.queryJobtypeStep(this.currentData.JobTypeId);
                this.dataLookUp(this.currentData.JobTypeId);
                this.bpmnJS = new BpmnViewer.default({
                    container: this.divDiagram.nativeElement,
                    height: 1000,
                    width: 1500
                });
                this.tabInfo();
                this.tabHis();

            }

            // this.importDiagram(this.xml);
        } else {
            this.onCloseEvent.emit(false);
        }
    }

    udAssignStatus(job:any){
        const params = ['JobId', '=', job.JobId];
        this.workflowService.queryJob(params).subscribe(res => {
            if (res.success) {
                const paramsJob = res.features[0];
                paramsJob.AssignStatusId = 1;
                this.workflowService.updateJob(paramsJob).subscribe(item => {
                }, err => {
                    this.appService.notification('Đã xảy ra lỗi', 'error');
                });
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    getJob(jobId:any) {
        const params = ['JobId', '=', jobId];
        this.workflowService.queryJob(params).subscribe(res => {
            if (res.success && res.features.length > 0) {
                this.currentData = res.features[0];
                this.getWorkflowModel(this.currentData.JobTypeId);
                this.queryJobtypeStep(this.currentData.JobTypeId);
            }
        });
    }

    dataLookUp(JobTypeId:any) {
        const params = ['ClientId', '=', this.clientId];
        const paramsJobTypeUser = ['JobTypeId', '=', JobTypeId];

        const arrStatus = this.workflowService.querySatus();
        const arrUser = this.workflowService.queryJobtypeUser(paramsJobTypeUser);
        const arrJobtype = this.workflowService.queryJobtype(params);
        const arrAssignStatus = this.workflowService.queryAssignStatus([]);
        const arrPriority = this.workflowService.queryPriority([]);
        const list = [arrStatus, arrUser, arrJobtype, arrAssignStatus, arrPriority];
        combineLatest(list).subscribe((res: any) => {
            this.lookupCondition['JobStatusId'] = [];
            this.lookupCondition['AssignedTo'] = [];
            this.lookupCondition['JobTypeId'] = [];
            this.lookupCondition['AssignStatusId'] = [];
            this.lookupCondition['PriorityId'] = [];

            if (res[0].success) {
                res[0].features.forEach((item: { JobStatusId: any; StatusName: any; }) => {
                    this.lookupCondition['JobStatusId'].push({
                        CODE: item.JobStatusId,
                        DESCR: item.StatusName
                    });
                });
            }
            if (res[1].success) {
                res[1].features.forEach((item: { Username: any; }) => {
                    this.lookupCondition['AssignedTo'].push({
                        CODE: item.Username,
                        DESCR: item.Username
                    });
                });
            }
            if (res[2].success) {
                this.arrJobType = res[2].features;
                res[2].features.forEach((item: { JobTypeId: any; JobTypeName: any; }) => {
                    this.lookupCondition['JobTypeId'].push({
                        CODE: item.JobTypeId,
                        DESCR: item.JobTypeName
                    });
                });
            }

            if (res[3].success) {
                res[3].features.forEach((item: { AssignStatusId: any; Description: any; }) => {
                    this.lookupCondition['AssignStatusId'].push({
                        CODE: item.AssignStatusId,
                        DESCR: item.Description
                    });
                });
            }

            if (res[4].success) {
                res[4].features.forEach((item: { PriorityId: any; Description: any; }) => {
                    this.lookupCondition['PriorityId'].push({
                        CODE: item.PriorityId,
                        DESCR: item.Description
                    });
                });
            }
            this.lookupCondition['SendFrom'] =  this.lookupCondition['AssignedTo'];
            this.initForm();

        });
    }



    initForm() {
        this.loading = true;
        this.fieldList = [];
        this.formData.reset();
        this.dataConfig.dataSource.FIELD_LIST.forEach((item: any) => {
            if (item.fieldname === 'JobTypeId' || item.fieldname === 'JobStatusId' || item.fieldname === 'AssignStatusId') {
                item.disabledCtrl = true;
            }
            if (item.fieldname === 'AssignedTo') {
                item.fieldtype = "text-button";
            }
            this.fieldList.push({
                FIELD_NAME: item.fieldname,
                FIELD_LABEL: item.alias,
                FIELD_TYPE: item.fieldtype,
                FIELD_ORDER: item.orderno,
                FIELD_SHOW: item.isdisplay,
                FIELD_ISSEARCH: item.issearch,
                FIELD_GROUPTYPE: item.fieldgroup,
                FIELD_TABLELINK: item.domainid,
                FIELD_DISABLED: item.disabledCtrl
            });
        });

        this.fieldList = this.fieldList.sort((a: { FIELD_ORDER: number; }, b: { FIELD_ORDER: number; }) => a.FIELD_ORDER - b.FIELD_ORDER);
        const group:any = {};
        this.fieldList.forEach((item:any) => {
            group[item.FIELD_NAME] = new FormControl();
        });


        const currentLang:string = 'vi'

        this.fieldList.forEach((field: any) => {
          try {
              const descr = JSON.parse(field.FIELD_LABEL);
              if (descr) {
                const alias = descr[currentLang];
                field.FIELD_LABEL = alias;

              }

          } catch (error) {

          }
        });

        this.formData = new FormGroup(group);
        this.bindDataToForm();

    }

    bindDataToForm() {
        Object.keys(this.formData.controls).forEach(key => {
            this.formData.controls[key].setValue(this.dataConfig.currentData[key]);
        });
        this.formLoadFinish = true;
    }

    queryJobtypeStep(JobTypeId:any) {
        const param = ['JobTypeId', '=', this.currentData.JobTypeId];
        this.workflowService.queryJobtypeStep(param).subscribe(res => {
            if (res.success) {
                this.arrJobtypeStep = res.features;

            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }


    getWorkflowModel(JobTypeId:any) {
        // get digram
        const param = ['JobTypeId', '=', JobTypeId];
        this.workflowService.queryJobtype(param).subscribe(res => {
            if (res.success && res.features.length > 0) {
                this.currentJobType = res.features[0];
                const data = res.features[0].JobTypeData;
                const options = { compact: true, ignoreComment: true, spaces: 4 };
                this.diagram = JSON.parse(data);
                this.diagram = converter.js2xml(this.diagram, options);
                this.bpmnJS.importXML(this.diagram, (err: any) => {
                    this.getCurentStep();
                    const canvas = this.bpmnJS.get('canvas');
                    canvas.zoom('fit-viewport');
                    if (err) {
                        console.log(err);
                    }
                });
                const canvas = this.bpmnJS.get('canvas');
                canvas.zoom('fit-viewport');
                // this.bpmnJS.get('canvas').zoom('fit-viewport');
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });

    }

    getCurentStep() {
        const param = ['JobId', '=', this.currentData.JobId];
        this.workflowService.queryJobStep(param).subscribe(res => {
            if (res.success) {
                if (res.features.length > 0) {
                    this.currentStepOb = res.features[0];
                    this.currentStep = res.features[0].CurStep;
                    const params = ['JobId', '=', this.currentData.JobId];
                    this.workflowService.queryJob(params).subscribe(job => {
                        if (job.success && job.features.length > 0) {
                            this.isShowIma = (this.currentUser === job.features[0].AssignedTo && this.currentStep !== '') ? true : false;
                            this.percCompleJob = job.features[0].PercComplete;
                            Object.keys(this.formData.controls).forEach(key => {
                                this.formData.controls[key].setValue(job.features[0][key]);
                            });

                        }
                    });
                    // this.isCheckAllCase();
                    this.queryEndStep();
                    const paramTypeStep = ['JobTypeId', '=', this.currentData.JobTypeId];
                    this.workflowService.queryJobtypeStep(paramTypeStep).subscribe(res => {
                        if (res.success) {
                            if (res.features.length > 0) {
                                this.arrStep = res.features;
                                this.arrStep.forEach((item:any) => {
                                    if (item.StepId === this.currentStep) {
                                        item.isActive = 'running';
                                        return;
                                    }
                                    // else if (item.IsComplete) {
                                    //     item.isActive = 'complete';
                                    //     return false;
                                    // }
                                    else {
                                        item.isActive = 'notComplete';
                                    }
                                });
                                this.onChangColor();
                                const f = this.arrStep.filter((fil: { StepId: any; }) => fil.StepId === this.currentStep);
                                f[0] ? this.divCurentStep = f[0].StepName : '';

                            }
                        }
                    });
                }
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    queryEndStep(){
        const paramXref = [['StepId', '=', this.currentStep], ['JobTypeId', '=', this.currentData.JobTypeId]];
        this.workflowService.queryStepXref(paramXref).subscribe(res => {
            if (res.success) {
                if (res.features.length > 0) {
                    if (res.features[0].NextStepId === '' || res.features[0].NextStepId === null) {
                        this.labSend = 'Complete';
                    }
                }
            }
        });
    }


    getWorkingTime(startDate: any){
        const start = new Date(startDate).getTime();
        const end = new Date().getTime();
        const time = Math.abs(end - start);
        const timeWork = (time / 3600000).toFixed(2);

        return Number(timeWork);
    }

    activeStep(step: number) {
        this.arrStep.forEach((element: { isActive: string; }, ind: number) => {
            ind += 1;
            if (ind > step) {
                element.isActive = 'notComplete';
            } else if (ind === step) {
                element.isActive = 'running';
            } else {
                element.isActive = 'complete';
            }
        });
        this.onChangColor();

    }

    onChangColor() {
        this.bpmnJS.importXML(this.diagram, (err: any) => { });
        this.bpmnJS.on('shape.added', (e: { element: any; }) => {
            const element = e.element;
            const businessObject:any = element.businessObject;
            const face = this.bpmnJS.get('graphicsFactory');
            // var modeling = this.bpmnJS.get('modeling');
            const f = this.arrStep.filter((fil: { StepId: any; }) => fil.StepId === element.id);
            let elementRegistry:any = null;
            let gfx:any = null;
            let type:any = element.waypoints ? 'connection' : 'shape';
            if (f.length > 0) {
                switch (f[0].isActive) {
                    case 'running':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#FFFF00');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                    case 'complete':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#CCFF33');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                    case 'notComplete':
                        businessObject.di.set('stroke', '#000000');
                        businessObject.di.set('fill', '#98FB98');
                        elementRegistry = this.bpmnJS.get('elementRegistry');
                        gfx = elementRegistry.getGraphics(element);
                        type = element.waypoints ? 'connection' : 'shape';
                        face.update(type, element, gfx);
                        break;
                }

            }
        });
    }

    updateStatus() {
        const paramJob = ['JobId', '=', this.currentData.JobId]
        this.workflowService.queryJob(paramJob).subscribe(res => {
            if (res.success && res.features.length > 0) {
                const param = res.features[0];
                param.StatusId = 4;
                this.workflowService.updateJob(param).subscribe(res => {
                }, err => {
                    this.appService.notification('Đã xảy ra lỗi', 'error');
                });
            }

        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    private importDiagram(xml: string): Observable<{ warnings: Array<any> }> {
        return from(this.bpmnJS.importXML(xml) as Promise<{ warnings: Array<any> }>);
    }

    queryHistory(){
        const params = [['StepId','=',this.currentStep],['JobId','=', this.currentData.JobId]];
        let  arrStepAprove:any = [];
        let arrUserApporive = [];
        let userAssignApprove:any = [];
        this.workflowService.queryHistory(params).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    if (item.ActivityId === 6 || item.ActivityId === 7) {
                        arrStepAprove.push(item);
                    }
                });

                arrUserApporive = this.stepApprove.UserApprove.split(',');
                if (arrStepAprove.length > 0) {
                    arrUserApporive.forEach((user:any) => {
                        const f = arrStepAprove.filter((fill:any) => fill.SendFrom === user);
                        if (!f[0]) {
                            userAssignApprove.push(user);
                        }
                    });
                } else {
                    userAssignApprove = arrUserApporive;
                }

                this.arrNextStep = [{
                    CODE: this.stepApprove.StepId,
                    DESCR: this.stepApprove.StepName,
                    AssignTo: userAssignApprove[0]
                }];
            }
        });
    }

    onClickSave(isShowNoti: boolean, IsUdUser: boolean, isUdStatus: boolean = false) {
        const params:any = {};
        Object.keys(this.formData.value).forEach(key => {
            params[key] = this.formData.controls[key].value;
        });
        const paramsJob:any = {
            JobId: this.currentData.JobId,
            JobName: params['JobName'],
            Description: params['Description'],
            AssignedTo: params['AssignedTo'] !== null ? typeof (params['AssignedTo']) === 'object' ? params['AssignedTo'].CODE : params['AssignedTo'] : params['AssignedTo'],
            ParentJobId: params['ParentJobId'],
            StartDate: this.formatDate(params['StartDate']),
            DueDate: this.formatDate(params['DueDate']),
            Note: params['Note'],
            OwnedBy: params['OwnedBy'],
            PercComplete: params['PercComplete'],
            JobTypeId: params['JobTypeId'] !== null ? typeof (params['JobTypeId']) === 'object' ? params['JobTypeId'].CODE : params['JobTypeId'] : params['JobTypeId'],
            JobStatusId: params['JobStatusId'] !== null ? typeof (params['JobStatusId']) === 'object' ? params['JobStatusId'].CODE : params['JobStatusId'] : params['JobStatusId'],
            AssignStatusId: 1,
            PriorityId:  params['PriorityId'] !== null ? typeof (params['PriorityId']) === 'object' ? params['PriorityId'].CODE : params['PriorityId'] : params['PriorityId'],
            SendFrom: this.currentUser,
            WindowId: params['WindowId'],
            TableId: params['TableId'],
            RecordId: params['RecordId'],
            ClientId: this.clientId,
            ApplicationId: this.currentData.ApplicationId,
            CreateDate: this.currentData.CreateDate,
            UpdateTime:  this.formatDate(new Date()),
        };

        // cap nhat % hoan thanh
        // onSave
        if (isShowNoti) {
            this.workflowService.updateJob(paramsJob).subscribe(res => {
                if (res.success) {
                    this.appService.notification('Lưu thành công','success');
                    this.getCurentStep();
                    // this.addNotification(paramsJob.AssignedTo);
                }
            }, err => {
                this.appService.notification('Đã xảy ra lỗi', 'error');
            });
        } else {
            // isUdStatus: kết thúc

            paramsJob.SendFrom = this.currentUser;
            isUdStatus === true ? paramsJob.JobStatusId = 3 : '';
            isUdStatus === true ? paramsJob.PercComplete = 100 : '';
            isUdStatus === true ? paramsJob.AssignStatusId = null : '';
            isUdStatus === true ? paramsJob.AssignedTo = null : '';

            let valAssign:any = '';
            const value = this.formDataStep.controls['assignOwner'].value;
            const valueAssignOwner:any = value !== null ?  typeof(value) === 'object' ? value.CODE : value : value;
            if (valueAssignOwner !== null) {
                if ( valueAssignOwner === '2') {
                    valAssign =  paramsJob.OwnedBy;
                } else {
                    const valueAssignedTo1 = this.formDataStep.controls['assignedTo1'].value
                    valAssign = typeof(valueAssignedTo1) === 'object' ? valueAssignedTo1.CODE : valueAssignedTo1;
                }
            }

            paramsJob.Note =  this.formDataStep.controls['note1'].value;

            IsUdUser === true ? paramsJob.AssignedTo = valAssign !== null ? typeof (valAssign) === 'object' ? valAssign.CODE : valAssign : valAssign : valAssign;
            // check neu giao cho tôi hoặc giao cho người khác
            paramsJob.AssignedTo ===  paramsJob.SendFrom ? paramsJob.AssignStatusId = 1 :  paramsJob.AssignStatusId = null;
            // check step dau tien
            this.currentJobType.JobStepStart === this.currentStep ? paramsJob.JobStatusId = 2 : '';
            // neu la step approve se doi trang thai la 5
            this.stepApprove !== null ?  paramsJob.JobStatusId = 5 : ''

            // this.checkSendMail(paramsJob) // Ham nay gui mail cho nguoi tiep theo neu co cau hinh gui email thông báo
            const params = [['JobTypeId', '=', this.currentData.JobTypeId], ['StepId', '=', this.currentStep]];
            this.workflowService.queryJobtypeStep(params).subscribe(res => {
                if (res.success) {
                    if (res.features[0]) {
                        // tinh %
                        if (!isUdStatus) {
                            let valComplete = null;
                            const dayStep = res.features[0].Duration == null ? 0 : res.features[0].Duration;
                            let dayJob:any = this.percCompleJob == null ? 0 : this.percCompleJob;
                            const f:any = this.arrJobType.filter((fil: { JobTypeId: any; }) => fil.JobTypeId === this.currentData.JobTypeId);
                            if (f[0]) {
                                if (dayStep !== 0) {
                                    const totolPer = 0;
                                    const dayJobType = f[0].NumDayComplete;
                                    valComplete = Math.round((dayStep / dayJobType) * 100);
                                    paramsJob['PercComplete'] = this.isNextStep === true ? dayJob += valComplete : dayJob -= valComplete;
                                }
                            }
                        }

                        this.workflowService.updateJob(paramsJob).subscribe(res => {
                            if (res.success) {
                                this.onAddNotification(paramsJob.AssignedTo, paramsJob.JobName, paramsJob.PriorityId);
                            }
                        }, err => {
                            this.appService.notification('Đã xảy ra lỗi', 'error');
                        });
                    }
                }
            }, err => {
                this.appService.notification('Đã xảy ra lỗi', 'error');
            });
        }

    }

    public tapChange(e: any) {
        console.log(e);
        if (e.index === 1) {
            this.getWorkflowModel(this.currentData.JobTypeId);
        }
    }

    clickTapWf() {

    }

    public tabHis() {
        this.loading = true;
        const handle = this.createTblHisFinish.subscribe(() => {
            this.queryDataHis();
            handle.unsubscribe();
        });

        this.loading = false;
        this.tableRowDefination1 = [];
        Object.keys(this.configColumHis['sys_column']).forEach(item => {
            if (this.configColumHis['sys_column'][item].isReadField) {
                this.tableRowDefination1.push({
                    title: this.configColumHis['sys_column'][item].ColumnAlias,
                    key: this.configColumHis['sys_column'][item].ColumnName,
                    isEdit: false,
                    isOnlyForm: true,
                    // rowOrder: this.configColumHis['sys_column'][item].ColumnIndex,
                    // rowType: this.configColumHis['sys_column'][item].ColumnType,
                    // rowIsShow: true
                });
            }
        });
        this.tableRowDefination1 = this.tableRowDefination1.sort((a: { rowOrder: number; }, b: { rowOrder: number; }) => a.rowOrder - b.rowOrder);
        this.createTblHisFinish.emit();
    }
    public queryDataHis() {
        this.tableDataHistory = [];
        const params = ['JobId', '=', this.currentData.JobId];
        this.workflowService.queryHistory(params).subscribe(resp => {
            resp.features.forEach(item => {
                this.tableDataHistory.push({
                    type: this.arrTypeHis[item.ActivityId],
                    sendFrom: item.SendFrom,
                    assignedTo: item.AssignedTo,
                    stepName: item.StepName,
                    date: this.formatDateHis(item.UpdateTime),
                    message: item.Description
                });
            });
            this.tableDataHistory = JSON.parse(JSON.stringify(this.tableDataHistory));
        });
    }

    public tabInfo() {
        this.fieldList = [];
        const param = ['TableID', '=', this.currentData.TableId];
        let paramsKey:any = null;
        this.workflowService.queryColBPMN(param).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    if (item.IsPriKey === 'Y') {
                        paramsKey = item.ColumnName;
                    }
                });
                if (paramsKey !== null && this.currentData.RecordId !== null) {
                    this.whereWf = [paramsKey, '=', this.currentData.RecordId];
                    this.windowId = this.currentData.WindowId;
                } else {
                    this.appService.notification('Lỗi truy vấn detail', 'warning');
                }
            }

         });
    }

    onAddNotification(toUsername:any, jobName:any, priorityId:any){
        const paramsWindow = [['ApplicationID', '=', null],['ClientId', '=', this.clientId]];
        let description = 'Bạn được giao công việc từ ' + this.currentUser;
        jobName !== null ? description =  description + ' - ' + jobName : '';
        this.workflowService.querySysWindow(paramsWindow).subscribe(window => {
          if (window.success && window.features[0]) {
            const windowIdJob = window.features[0].WindowId;
            const paramSysTab = ['WindowId', '=', windowIdJob];
            this.workflowService.querySysTab(paramSysTab).subscribe(tab => {
              if (tab.success && tab.features[0]) {
                const tableIdJob = tab.features[0].TableId;
                const paramsNotify = {
                  FromUserId: null,
                  FromUsername: this.currentUser,
                  ToUserId: null,
                  ToUsername: toUsername,
                  SendDate: this.formatDate(new Date()),
                  Description: description,
                  WindowId: windowIdJob,
                  TableId: tableIdJob,
                  RecordId:  this.currentData.JobId,
                  PriorityId: priorityId,
                  ClientId: this.clientId
                };
                this.workflowService.addNotification(paramsNotify).subscribe(res => {
                  if (!res.success) {
                    this.appService.notification('Lỗi thêm mới Notification', 'error');
                  }
                }, err => {
                  this.appService.notification('Lỗi thêm mới Notification', 'error');
                });
              }
            });
          } else {
            this.appService.notification('Lỗi thêm mới Notification', 'error');
          }
        });
    }
    onCoreWindowEmitEvent(event: any) {
        this.createDynamicEvent.emit(event);
    }

    private deepCloneObject(obj: object) {
        return JSON.parse(JSON.stringify(obj));
    }

    public formatDate(date: string | number | Date | null) {
        let dateString:any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getFullYear();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += 'T';
            dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();

        } else {
            dateString = null;
        }
        return dateString;
    }

    formatDateHisAdd(date: string | number | Date | null){
        let dateString:any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getFullYear();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += ' ';
            dateString += newDate.getHours() >= 10 ? newDate.getHours() : '0' + newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes() >= 10 ? newDate.getMinutes() : '0' + newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds() >= 10 ? newDate.getSeconds() : '0' + newDate.getSeconds();
        } else {
            dateString = null;
        }
        return dateString;
    }

    formatDateHis(date: string | number | Date | null) {
        let dateString:any = '';
        if (date !== '' && date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getFullYear();
            dateString += ' ';
            dateString += newDate.getHours();
            dateString += ':';
            dateString += newDate.getMinutes();
            dateString += ':';
            dateString += newDate.getSeconds();
        } else {
            dateString = null;
        }
        return dateString;
    }
}
