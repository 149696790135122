import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app-base/app.service';
import { CoreWindowService } from 'app/core/core-window/core-window.service';

@Component({
    selector: 'app-search-basic',
    templateUrl: './search-basic.component.html',
    styleUrls: ['./search-basic.component.scss']
})
export class SearchBasicComponent implements OnInit {
    @Output() searchComplete: EventEmitter<any> = new EventEmitter();

    public _isRequiredSearch = true;
    public _columAs = '';

    public showSearchByGraphic = false; // Hiển thị option tìm kiếm bằng graphic hay không
    private _isMapApp = true;
    @Input() showImportBtn = false;
    /** Đánh dấu Form là dùng cho ứng dụng map hay không. Giá trị mặc định là true */
    @Input('isMapApp')
    get isMapApp(): any {
        return this._isMapApp;
    }
    set isMapApp(isMapApp: any) {
        this._isMapApp = isMapApp;
    }

    public isShowGeoSearch = true;

    private _dataSource: any = null;
    @Input('dataSource')
    get dataSource(): any {
        return this._dataSource;
    }
    set dataSource(data: any) {
        this._dataSource = data;
        if (data) {
            this.initFormControl();
        }
    }

    public isSearchMap = false;
    public isShapeValid = true;
    public type = null;
    public calConfig = { showTime: false, selectionMode: 'range', yearNavigator: true, monthNavigator: true, yearRange: '1900:2099' }

    // Form Control
    public isLoadedForm = false;
    public formData: FormGroup = new FormGroup({});
    public fieldList: any[] = [];
    public lookupCondition: any = {};
    public fieldan: any = null;
    public fielddisable: any = null;


    // Config cho số bản ghi, default = 10
    public _numberOfRecord = 10;
    public rows = -1;
    public lookupConditionAll = {};

    private objValueForm: any = {};
    constructor(
        private appService: AppService,
        private coreWindowService: CoreWindowService,
        private translate: TranslateService
    ) { }

    
    handleLanguageChange: any = null;
    ngOnInit() { 
        this.handleLanguageChange = this.translate.onLangChange.subscribe(res => {
            if (res && this.fieldList.length > 0) {
                this.updateLabelControl();
            }
        });
    }

    private updateLabelControl() {
        if (!this.dataSource) {
            return;
        }
        this.fieldList.forEach(item => {
            const findField = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === item.FIELD_NAME);
            try {
                const descr = JSON.parse(findField[0].alias);
                const text = descr[this.translate.currentLang];
                item.FIELD_LABEL = text;
            } catch (error) {

            }
        })
    }


    private initFormControl() {
        this.fieldList = [];
        this.fieldan = [];
        this.fielddisable = [];
        const layout = this._dataSource.INFORMATION.LAYOUT_COLUMN ?? 2;
        this.lookupCondition = this.appService.getLookup();
        this.dataSource.FIELD_LIST.forEach((item: any) => {
            let chbType = 'string';
            if (item.columntype === 'number') {
                chbType = 'number';
            } else if (item.columntype === 'boolean') {
                chbType = 'boolean'
            }
            const obj = {
                FIELD_NAME: item.fieldname,
                FIELD_LABEL: item.alias && item.alias !== '' ? item.alias.trim() : item.fieldname,
                FIELD_TYPE: item.fieldtype,
                FIELD_ORDER: item.orderno,
                FIELD_FOREIGNKEY: item.foreignkey,
                FIELD_FOREIGNDISPLAY: item.foreigndisplay,
                FIELD_TABLELINK: item.domainid,
                FIELD_ISSEARCH: item.issearch === 'Y',
                FIELD_SPAN: 12 / layout, // đổi thành 12 thay vì 24 vì layout của primeng, không phải ant

                // Cập nhật code mới vào 08-27-2021 (phục vụ cho relate field, display logic, ...)
                FIELD_ID: item.fieldid,
                FIELD_PARENT_ID: item.parentfieldid,
                FIELD_WHEREFIELDNAME: item.wherefieldname,
                FIELD_BINDFIELDNAME: item.bindfieldname,
                FIELD_DISPLAY_LOGIC: item.displaylogic && item.displaylogic !== '' ? item.displaylogic : null,
                FIELD_DISABLE_LOGIC: item.disablelogic && item.disablelogic !== '' ? item.disablelogic : null,
                FIELD_CHILD: item.children,
                FIELD_ISDOMAIN: item.isfromdomain === 'Y',
                FIELD_ISUNIQUE: item.isunique === 'Y',
                FIELD_CHB_TYPE: chbType,
                FIELD_FILTER: {
                    FOREIGNTABLE: item.foreigntable,
                    FOREIGNTABLE_ID: item.foreigntableid,
                    COLUMNKEY: item.columnkey,
                    COLUMNDISPLAY: item.columndisplay,
                    COLUMNCODE: item.columncode,
                    WHEREFIELDNAME: item.wherefieldname, // sử dụng cho control select
                    WHERECLAUSE: item.whereclause,
                    FOREIGNWINDOW_ID: item.foreignwindowid,
                    FIELD_ISDOMAIN: item.isfromdomain === 'Y',
                    FIELD_PARENT_ID: item.parentfieldid,
                    // Dùng cho filter-input (phân biệt khi có 2 control khác fieldname, nhưng đều trỏ vào chung 1 bảng)
                    FIELD_NAME: item.fieldname ? item.fieldname.trim() : item.fieldname,
                    SERVICE_TYPE: item.foreigntableservicetype && item.foreigntableservicetype !== '' ?
                        item.foreigntableservicetype : this.dataSource.INFORMATION.SERVICE_TYPE // Control sử dụng kiểu service type nào
                },
                FIELD_VFORMAT: item.vformat
            };
            // if (item.isunique === 'Y') {
            //     obj.FIELD_SPAN = 12;
            // }
            if (item.colspan) {
                obj.FIELD_SPAN = obj.FIELD_SPAN * item.colspan;
            }
            this.fieldList.push(obj);
        });

        console.log(this.fieldList)

        this.fieldList = this.fieldList.sort(
            (a, b) => a.FIELD_ORDER - b.FIELD_ORDER
        );

        const group: any = {};
        this.fieldList.forEach((item) => {
            if (item.FIELD_ISSEARCH) {
                if (item.FIELD_TYPE === 'date') {
                    group[item.FIELD_NAME] = new FormControl();
                } else if (item.FIELD_TYPE === 'select') {
                    group[item.FIELD_NAME] = new FormControl();
                } else {
                    group[item.FIELD_NAME] = new FormControl();
                }
            }
        });
        this.formData = new FormGroup(group);

        // Xử lý liên quan tới relate field (trường phụ thuộc)
        this.fieldList.forEach(field => {
            if (field.FIELD_ISSEARCH) {
                if (field.FIELD_PARENT_ID !== null) {
                    const parents = this.fieldList.filter(fil => fil.FIELD_ID === field.FIELD_PARENT_ID);
                    if (parents.length > 0) {
                        const parent = parents[0];
                        if (group[parent.FIELD_NAME]) {
                            group[parent.FIELD_NAME].valueChanges.subscribe((res: any) => {
                                if (res) {
                                    // trường hợp relate field
                                    if (field.FIELD_WHEREFIELDNAME && field.FIELD_WHEREFIELDNAME !== '') {
                                        let _val = this.objValueForm[parent.FIELD_NAME]

                                        if (field.FIELD_TYPE === 'qrcode') {
                                            _val = this.objValueForm[parent.FIELD_NAME] !== undefined ? this.objValueForm[parent.FIELD_NAME] : this.objValueForm;
                                        }
                                        group[field.FIELD_NAME].setValue(_val);
                                    } else if (field.FIELD_BINDFIELDNAME && field.FIELD_BINDFIELDNAME !== '') {
                                        const REGEXP = /\[([^\][]*)]/g;
                                        const names = field.FIELD_BINDFIELDNAME.match(REGEXP);
                                        let cloneBindFieldName: string = field.FIELD_BINDFIELDNAME;
                                        if (names && names.length > 0) {
                                            // FIELD_BINDFIELDNAME là biểu thức tính toán
                                            names.forEach((name: string) => {
                                                let fieldName = name.substring(1, name.length - 1);
                                                if (fieldName.includes('.')) {
                                                    const tabName = fieldName.split('.')[0]; // Tên tab
                                                    const fieldNameInTab = fieldName.split('.')[1]; // Tên field trong tab
                                                    for (let i in this.coreWindowService.lookupTab) {
                                                        const tab = this.coreWindowService.lookupTab[i];
                                                        if (tab.INFORMATION.TABLE_NAME === tabName) {
                                                            const _val = tab.INFORMATION.CURRENT_DATA ? tab.INFORMATION.CURRENT_DATA[fieldNameInTab] : null;
                                                            if (_val) {
                                                                cloneBindFieldName = cloneBindFieldName.replace(fieldName, _val);
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    cloneBindFieldName = cloneBindFieldName.replace(fieldName, this.objValueForm[parent.FIELD_NAME][fieldName]);
                                                }
                                            });
                                            const value = Math.round(eval(cloneBindFieldName));
                                            group[field.FIELD_NAME].setValue(value);
                                        } else {
                                            const fieldListCheck = ['search', 'select', 'treeselect'];
                                            if (!this.objValueForm[parent.FIELD_NAME] || this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME] === undefined) {
                                                return;
                                            }
                                            // FIELD_BINDFIELDNAME không phải biểu thức tính toán
                                            // kiểm tra kiểu control của field
                                            if (fieldListCheck.includes(field.FIELD_TYPE)) {
                                                // trường hợp truyền obj
                                                group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME]);
                                            } else {
                                                // trường hợp truyền giá trị: string/number/boolean
                                                group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME]);
                                            }
                                        }
                                    }
                                } else {
                                    // Không có giá trị tại trường cha => xóa trường con
                                    group[field.FIELD_NAME].reset();
                                }
                                group[field.FIELD_NAME].markAsDirty();
                            });
                        }
                    }
                }

                if (field.FIELD_CHILD) {
                    field.FIELD_CHILD.forEach((child: any) => {
                        if (child.calculation) {
                            if (group[field.FIELD_NAME]) {
                                group[field.FIELD_NAME].valueChanges.subscribe((res: any) => {
                                    if (res != null && res !== undefined) {
                                        const _v = [];
                                        for (let v = 0; v < child.calculationInfos.length; v++) {
                                            const info = child.calculationInfos[v];
                                            if (group[info.field]) {
                                                _v.push(group[info.field].value);
                                            }
                                        }
                                        const value = eval(child.calculation);
                                        if (value.toString() === 'NaN') {
                                            return;
                                        }
                                        if (group[child.fieldname]) {
                                            group[child.fieldname].setValue(value, { emitEvent: false });
                                        }
                                    }
                                });
                            }
                        }

                    });

                }

                if (field.FIELD_DISPLAY_LOGIC) {
                    // const value = eval(field.FIELD_DISPLAY_LOGIC);
                    const i = field.FIELD_DISPLAY_LOGIC.lastIndexOf('[');
                    const k = field.FIELD_DISPLAY_LOGIC.lastIndexOf(']');
                    const fieldname = field.FIELD_DISPLAY_LOGIC.substring(i + 2, k - 1);
                    const q = field.FIELD_DISPLAY_LOGIC.lastIndexOf('=');
                    const dk = field.FIELD_DISPLAY_LOGIC.substr(q + 1, field.FIELD_DISPLAY_LOGIC.length - q - 1);
                    if (group[fieldname] !== undefined) {
                        group[fieldname].valueChanges.subscribe((res: any) => {
                            // Nếu res khác null và res có CODE thì lấy res.CODE, không thì lấy res;
                            const value1 = res !== null && res.CODE ? res.CODE : res;

                            if (value1 && value1.toString() === dk) {
                                field.FIELD_HIDE = true;
                            } else {
                                field.FIELD_HIDE = false;
                            }
                        });
                    }

                    else {
                        this.fieldan.push(field);
                    }

                } else if (field.FIELD_DISABLE_LOGIC) {
                    // const value = eval(field.FIELD_DISPLAY_LOGIC);
                    const i = field.FIELD_DISABLE_LOGIC.lastIndexOf('[');
                    const k = field.FIELD_DISABLE_LOGIC.lastIndexOf(']');
                    const fieldname1 = field.FIELD_DISABLE_LOGIC.substring(i + 2, k - 1);
                    const q = field.FIELD_DISABLE_LOGIC.lastIndexOf('=');
                    const dk = field.FIELD_DISABLE_LOGIC.substr(q + 1, field.FIELD_DISABLE_LOGIC.length - q - 1);
                    if (group[fieldname1] !== undefined) {
                        group[fieldname1].valueChanges.subscribe((res: any) => {
                            // Nếu res khác null và res có CODE thì lấy res.CODE, không thì lấy res;
                            const value1 = res !== null && res.CODE ? res.CODE : res;
                            if (value1 !== null) {
                                if (value1[fieldname1] === dk) {
                                    field.FIELD_DISABLED = false;
                                } else {
                                    field.FIELD_DISABLED = true;
                                }
                            }
                        });
                    }
                    this.fielddisable.push(field);
                }
            }
        });
        this.updateLabelControl();
        this.isLoadedForm = true;
    }

    onEnterEvent(evt: any, field: any) {
        const val = field.FIELD_TYPE === 'number' ? Number(evt) : evt;
        this.formData.controls[field.FIELD_NAME].setValue(val ?? null);
        const data = {
            typeButton: 'btnSearch',
            formControl: this.formData,
            fieldList: this.fieldList
        };
        this.searchComplete.emit(data);
    }

    // Button Tìm kiếm
    onSearch() {
        const data = {
            typeButton: 'btnSearch',
            formControl: this.formData,
            fieldList: this.fieldList
        };
        this.searchComplete.emit(data);
    }

    onAcceptRecord() {
        const data = {
            typeButton: 'btnAccept'
        };
        this.searchComplete.emit(data);
    }

    onReset() {
        this.initDefault();
        this.initEventButton('btnReset');
    }

    onCancel() {
        this.initEventButton('btnCancel');
    }

    initEventButton(evt: any) {
        const data = {
            typeButton: evt,
            data: []
        };
        this.searchComplete.emit(data);
    }

    onReturnObjValue(data: any, field: any) {
        if (field) {
            this.objValueForm[field.FIELD_NAME] = data;
        }
    }


    private initDefault() {
        this.formData.reset();
    }
}
