import * as esriLoader from 'esri-loader';
import { EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';


export class Toolbars {

    /** Toolbar that supports basic navigation such as pan and zoom. */
    navigation: any;
    Navigation: any;
    navObserve: EventEmitter<string> = new EventEmitter();

    /**
     * Toolbar that supports functionality to create new geometries by drawing them:
     * points (POINT or MULTI_POINT), lines (LINE, POLYLINE, or FREEHAND_POLYLINE),
     * polygons (FREEHAND_POLYGON or POLYGON), or rectangles (EXTENT).
     */
    draw: any;
    Draw: any;
    // drawObserve: EventEmitter<string> = new EventEmitter();
    private drawCompleteHander: any;

    constructor(private map: any) {
        esriLoader.loadModules(['esri/toolbars/navigation', 'esri/toolbars/draw']).then(([Navigation, Draw]) => {
            this.Navigation = Navigation;
            this.Draw = Draw;
            this.navigation = new Navigation(this.map);
            this.draw = new Draw(this.map, { showTooltips: false });
        });
    }

    /**
     * Activates the toolbar for map navigation.
     * Activating the toolbar overrides default map navigation.
     *
     * @param navType Navigation type (`PAN`, `ZOOM_IN`, and `ZOOM_OUT`).
     *
     * @example
     * this.gisService.toolbars.navigationActivate(this.gisService.toolbars.Navigation.ZOOM_IN);
     */
    navigationActivate(navType: string) {
        this.navigationDeactivate();
        this.navigation.activate(navType);

        if (navType === this.Navigation.PAN) {
            this.map.enableRubberBandZoom();
        }
        this.navObserve.emit(navType);
    }

    /** Deactivates the toolbar and reactivates map navigation. */
    navigationDeactivate() {
        this.navigation.deactivate();
    }


    /**
     * Activates the toolbar for drawing geometries.
     * This method fired event after `draw-complete` event work.
     *
     * @param geometryType The type of geometry drawn (e.g. `POINT`, `LINE`, `POLYGON`).
     *
     * @example
     * this.gisService.toolbars.drawActivate(this.gisService.toolbars.Draw.POINT).subscribe(event => {
     * ...
     * });
     */
    drawActivate(geometryType: string): Observable<any> {
        const subject = new Subject();
        this.drawDeactivate();
        this.draw.activate(geometryType);
        this.drawCompleteHander = this.draw.on('draw-complete', (event: any) => subject.next(event));

        return subject.asObservable();
    }

    /**
     * Deactivates the toolbar and reactivates map navigation.
     */
    drawDeactivate() {
        if (this.drawCompleteHander) {
            this.drawCompleteHander.remove();
        }
        if (this.draw) {
            this.draw.deactivate();
        }
    }
}
