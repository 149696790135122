import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ColumnTreeTable {
    field: string,
    header: string;
    width?: string; // độ rộng của cột
    type: string; // kiểu dữ liệu hiển thị: text, checkbox, ...
}

@Component({
    selector: 'app-core-tree-table',
    templateUrl: './core-tree-table.component.html',
    styleUrls: ['./core-tree-table.component.scss']
})
export class CoreTreeTableComponent implements OnInit {

    @Input() datas: any[] = [];
    @Input() cols: ColumnTreeTable[] = [];

    @Output() eventEmit: EventEmitter<any> = new EventEmitter();

    constructor(
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {}

    onChkChange(row: any, column: any) {
        this.eventEmit.emit({
            type: 'checkbox',
            value: { row, column }
        })
    }

    onRadioChange(row: any, column: any) {
        console.log(this, row, column);
    }

    // Làm mới lại html --- chưa hoạt động chính xác
    refreshHtml() {
        this.cd.detectChanges();
    }

}
