import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseInterface, DeleteResponse, IdParams, InsertResponse, OdataParams, QdataParams, SearchResponse, UpdateResponse } from './request.service';
// import * as cloneDeep from 'lodash/cloneDeep';
import { AppService } from 'app/app-base/app.service';
import { clone } from 'lodash';


@Injectable()
export class SqlService implements BaseInterface {
    constructor(
        private http: HttpClient,
        private appService: AppService
    ) {
        // this.testDecode();
        // Service odata này hàm search/insert/update/delete không có proxy vì bản thân nó được đặt cùng domain với proxy => chung domain k dùng proxy
    }

    /** params có dạng object: { id: 1, name: 'hehe' } */
    query(q: QdataParams): Observable<any> {
        let url = q.url;

        // Kiểm tra có khác domain hay không
        if (this.appService.getDomainName(url) !== this.appService.getDomainName(location.hostname) && location.hostname !== 'localhost') {
            if (!url.includes(this.appService.urlProxy)) {
                url = q.proxy ? `${q.proxy}${url}` : `${this.appService.urlProxy}${url}`;
            }
        }

        const contentType = q.contentType ?? 'application/json';

        let headers = new HttpHeaders({
            Accept: 'text/plain'
        });

        if (contentType !== 'unset') {
            headers = headers.append('Content-Type', contentType);
        }


        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });
        const options: any = {
            headers,
            params: paramsToSend,
            responseType: q.responseType ?? 'json'
        };

        if (method === 'GET') {
            return this.http.get(url, options);
        } else {
            delete options.params; // POST phải gửi theo dạng body, không gửi params trên đường link url - ý kiến của anh Khoa
            return this.http.post(url, q.params, options);
        }
    }

    queryCustom(q: QdataParams): Observable<any> {
        let url = q.url;
        // Kiểm tra có khác domain hay không
        if (this.appService.getDomainName(url) !== this.appService.getDomainName(location.hostname) && location.hostname !== 'localhost') {
            if (!url.includes(this.appService.urlProxy)) {
                url = q.proxy ? `${q.proxy}${url}` : `${this.appService.urlProxy}${url}`;
            }
        }

        const contentType = q.contentType ?? 'application/json';

        let headers = new HttpHeaders({
            Accept: 'text/plain'
        });

        if (contentType !== 'unset') {
            headers = headers.append('Content-Type', contentType);
        }


        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });
        const options: any = {
            headers,
            params: paramsToSend,
            responseType: q.responseType ?? 'json'
        };

        if (method === 'GET') {
            return this.http.get(url, options);
        } else {
            delete options.params; // POST phải gửi theo dạng body, không gửi params trên đường link url - ý kiến của anh Khoa
            return this.http.post(url, q.params, options);
        }
    }

    getRecordById(q: IdParams): Observable<any> {
        let url = q.url + '/' + q.id
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const options: any = {
            headers,
            params: {},
            responseType: 'json'
        };

        return this.http.get(url, options).pipe(map((res: any) => {
            let resp = null;
            if (res) {
                resp = res;
            }
            return resp;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    /** Tìm kiếm dữ liệu cho ODATA => Đã hoàn thành */
    search(p: OdataParams): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        const val = this.decodeSql1(p.where);
        let convertWhere = val;
        if (p.or) {
            convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        }
        if (p.and) {
            convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        }

        if (p.groupBy) {
            convertWhere += `&$apply=groupby((${p.groupBy.join(',')}))`
        }

        if (p.select) { convertWhere += '&$select=' + p.select; }
        if (p.orderBy) {
            convertWhere += '&$orderby=' + p.orderBy.join(',');
        }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.get(url, {
            headers
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            } else {
                resp.features = res; // Trường hợp riêng cho core-table hàm initMultiFilter
            }
            return resp;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    searchForMobile(p: OdataParams, token: any): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        const val = this.decodeSql1(p.where);
        let convertWhere = val;
        if (p.or) {
            convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        }
        if (p.and) {
            convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        }

        if (p.select) { convertWhere += '&$select=' + p.select; }
        if (p.orderBy) {
            convertWhere += '&$orderby=' + p.orderBy.join(',');
        }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }

        return this.http.get(url, {
            headers
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    /** Cập nhật dữ liệu cho bảng ghi => Chưa hoàn thành */
    insert(p: OdataParams): Observable<InsertResponse> {
        const url = p.url;
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
            // 'Content-Type': 'application/problem+json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        // Cập nhật tháng 05, ngày 12, năm 2022, theo như anh Vinh yêu cầu, nếu điền khóa chính thì cứ gửi khóa chính, còn không muốn gửi khóa chính
        // Thì phải ẩn control khóa chính đi

        // không gửi khóa chính cùng giá trị vì service odata sẽ bị lỗi
        // if (p.primaryKey) {
        //     delete p.data[p.primaryKey];
        // }
        const list: any[] = [];
        if (p.data.length) {
            p.data.forEach((item: any) => {
                list.push(this.http.post(url, item, {
                    headers
                }))
            })
        } else {
            list.push(this.http.post(url, p.data, {
                headers
            }))
        }
        return combineLatest(list).pipe(
            map((res: any) => {
                const arrRes: any = [];
                let listSuccess: boolean = true;
                let messageErr: any = '';
                if (p.data.length) {
                    res.forEach((itemRes: any) => {
                        if (!itemRes.success) {
                            listSuccess = false;
                            messageErr = itemRes.message;
                        }
                        // Cập nhật lại model => In hoa ký tự đầu tiên
                        if (itemRes.model) {
                            Object.keys(itemRes.model).forEach(key => {
                                itemRes[key.charAt(0).toUpperCase() + key.slice(1)] = itemRes.model[key];
                            });
                            arrRes.push(itemRes)
                        }
                    })
                } else {
                    if (!res[0].success) {
                        listSuccess = false;
                        messageErr = res[0].message;
                    }
                    // Cập nhật lại model => In hoa ký tự đầu tiên
                    if (res[0].model) {
                        const data: any = {};
                        Object.keys(res[0].model).forEach(key => {
                            data[key.charAt(0).toUpperCase() + key.slice(1)] = res[0].model[key];
                        });
                        arrRes.push(data)
                    }
                }
                const result: InsertResponse = {
                    features: listSuccess ? arrRes : [],
                    success: listSuccess,
                    total: 1,
                    message: listSuccess || res[0].message ? res[0].message : messageErr
                };

                return result;
            }),
            catchError((error: any) => {
                const rq: InsertResponse = {
                    features: [],
                    total: 0,
                    success: false,
                    message: error ? error.message : 'Insert Error'
                };
                return of(rq)
            })
        );
    }

    /** Clone từ connect-database và đã tinh chỉnh lại => Đã hoàn thành */
    update(p: OdataParams): Observable<UpdateResponse> {
        const urlRequest = p.url;
        const key = p.primaryKey;
        let url = urlRequest;
        if (key) {
            url = `${urlRequest}/${p.data[key]}`;
        }

        // delete p.data[key];

        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }


        const list: any[] = [];
        if (p.data.length) {
            p.data.forEach((item: any) => {
                list.push(this.http.put(url, item, { headers }))
            })
        } else {
            list.push(this.http.put(url, p.data, { headers }))
        }

        return combineLatest(list).pipe(map((res: any) => {
            if (p.data.length) {
                let listSuccess: boolean = true;
                let messageErr: any = '';
                res.forEach((itemRes: any) => {
                    if (!itemRes.success) {
                        listSuccess = false;
                        messageErr = itemRes.message;
                    }
                })
                const resp: UpdateResponse = {
                    message: listSuccess ? res[0].message : messageErr,
                    success: listSuccess
                };
                return resp;
            } else {
                const resp: UpdateResponse = {
                    message: res[0]['message'],
                    success: res[0]['success']
                };
                return resp;
            }

        }), catchError((error: any) => {
            const rq: InsertResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Insert Error'
            };
            return of(rq)
        }));
    }

    /** Clone từ connect-database và đã tinh chỉnh lại => Đã hoàn thành */
    public delete(p: OdataParams): Observable<DeleteResponse> {
        // const params = p.data[p.dataSource.INFORMATION.KHOA_CHINH];
        const urlRequest = p.url;
        let url = urlRequest;

        // const url = `${p.url}/${params}`;

        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        const list: any[] = [];
        if (p.data && p.primaryKey) {
            const primaryKey = p.primaryKey
            if (p.data.length) {
                p.data.forEach((item: any) => {
                    const params = item[primaryKey];
                    url = `${urlRequest}/${params}`;
                    list.push(this.http.put(url, { headers }))
                })
            } else {
                const params = p.data[p.primaryKey];
                url = `${urlRequest}/${params}`;
                list.push(this.http.delete(url, { headers }))
            }
        } else {
            list.push(this.http.delete(urlRequest, { headers }));
        }

        return combineLatest(list).pipe(
            map((res: any) => {
                if (p.data.length) {
                    let listSuccess: boolean = true;
                    let messageErr: any = '';
                    const arrModel: any = [];
                    res.forEach((itemRes: any) => {
                        if (!itemRes.success) {
                            listSuccess = false;
                            messageErr = itemRes.message;
                        }
                        arrModel.push(itemRes);
                    })
                    const resp: DeleteResponse = {
                        data: arrModel,
                        message: listSuccess ? res[0].message : messageErr,
                        success: listSuccess
                    };
                    return resp;
                } else {
                    const resp: DeleteResponse = {
                        data: [res[0].model],
                        success: res[0].success,
                        message: res[0].message
                    };
                    return resp;
                }
            }),
            catchError((error: any) => {
                const rq: DeleteResponse = {
                    success: false,
                    data: [],
                    message: error ? error.message : 'Delete Error'
                };
                return of(rq)
            })
        );
    }

    private decodeSql(where: any, logic: string) {
        let decode = '';
        if (where) {
            if (where.length > 0) {
                decode += '$filter=';
                if (Array.isArray(where[0])) {
                    // Trường hợp multi
                    where.forEach((item: any, index: number) => {
                        decode = this.decode(item);
                        decode += index < (where.length - 1) && logic ? ` ${logic} ` : '';
                    });
                } else {
                    // Trường hợp where chỉ là dạng [key, operator, value]
                    decode = this.decode(where);
                }
            }
        }
        // if (p.select) { decode += '&select=' + p.select; }
        return decode;
    }

    private cloneDeep(where: any): any {
        let data: any = null;
        data = JSON.parse(JSON.stringify(where));
        return data;
    }

    private decodeSql1(where: string | any[], isBase = true) {
        let decode = ''; let logic = 'and';
        if (where) {
            if (where.length > 0) {
                if (isBase) {
                    decode = '$filter=';
                }
                const cloneWhere: any = clone(where);
                if (!Array.isArray(cloneWhere[0]) && cloneWhere[0] !== 'and' && cloneWhere[0] !== 'or') {
                    // Trường hợp mảng 1 chiều
                    decode += this.decode(cloneWhere);
                } else {
                    cloneWhere.forEach((item: string | any[], index: number) => {
                        if (index === 0 && (item === 'and' || item === 'or')) {
                            logic = item;
                        } else {
                            if (Array.isArray(item)) {
                                if (item.length === 0) {
                                    // mảng rỗng thì bỏ qua
                                    return;
                                }
                                if (item[0] === 'and' || item[0] === 'or') {
                                    // đệ quy lần nữa để lấy dữ liệu
                                    decode += `(${this.decodeSql1(item, false)})`;
                                } else if (Array.isArray(item[0])) {
                                    // item là 1 array có dạng [[key, operator, value], ...]
                                    logic = 'and';
                                    decode += `(${this.decodeSql1(item, false)})`;
                                } else {
                                    // item là 1 array có dạng [key, operator, value];
                                    decode += this.decode(item);
                                }
                                decode += index < (cloneWhere.length - 1) && logic ? ` ${logic} ` : '';
                            } else {
                                // cloneWhere bây giờ là dạng [key, operator, value], chỉ chạy 1 lần duy nhất với index === 0;
                                if (index === 0) {
                                    decode += this.decode(cloneWhere);
                                }
                            }
                        }
                    });
                }

            }
        }
        return decode;
    }

    private testDecode() {
        const where = ['table', 'in', [1, 2]];
        const where1 = ['or', ['table', 'in', [1, 2]], ['data', 'like', '1234']];
        const where2 = [
            'or',
            [
                'and',
                ['table', '=', 1],
                ['tableName', 'like', 'super']
            ],
            ['data', 'like', '1234']
        ];
        const whereDate = [
            'and',
            ['Day', '>=', new Date()],
            ['Day', '<=', new Date()]
        ];

        const where111 = [
            'and',
            ['SoHieuToBanDo', '=', 36],
            ['MaXa', '=', '20272'],
            ['SoThuTuThua', '=', 136]
        ];
        const where222 = [
            'and',
            ['SoHieuToBanDo', '=', 36],
            ['MaXa', '=', '20272'],
            ['SoThuTuThua', '=', 149]
        ];
        const where333 = [
            'or',
            where111,
            where222
        ];

        const whereCurrentUser = ['username', '=', '$userid'];

        // console.log('TEST SQL DECODE WHERE: ', this.decodeSql1(where));
        // console.log('TEST SQL DECODE WHERE 1: ', this.decodeSql1(where1));
        // console.log('TEST SQL DECODE WHERE 2: ', this.decodeSql1(where2));
        // console.log('TEST SQL DECODE WHERE DATE: ', this.decodeSql1(whereDate));
        // console.log('TEST SQL DECODE WHERE USERNAME: ', this.decodeSql1(where333));
    }

    private decode(item: any[]) {
        let str = '';
        // item là 1 array có dạng [key, operator, value];
        let val = item[2];
        let key = item[0];
        const resp = listOperatorSqlOdata.filter(fil => fil.key === item[1]);

        if (typeof (val) === 'string') {
            if (val.startsWith('c$')) {
                val = eval(val);
                if (typeof (val) === 'string') {
                    val = `'${val}'`;
                }
            } else {
                val = this.replaceSpecialCharacters(val);
                const first = val.charAt(0);
                const last = val.charAt(val.length - 1);
                if (first === '\'' && last === '\'') {
                    val = '\'' + eval(val.substring(1, val.length - 1)) + '\'';
                    val = val.toLowerCase();
                    key = `tolower(${key})`;
                } else if (first === '{' && last === '}') {
                    val = eval(val.substring(1, val.length - 1));
                } else if (first === '(' && last === ')') {
                    val = val.substring(1, val.length - 1);
                    val = val.split(',');
                    // key = `tolower(${key})`;
                } else {
                    val = `'${val}'`.toLowerCase();
                    key = `tolower(${key})`;
                }
            }
        } else {
            try {
                // convert to date format
                val = val.toISOString();
            } catch (error) {
            }
        }

        switch (resp[0].value) {
            case 'like':
                str += `contains(${key}, ${val})`;
                break;
            case 'in':
                if (val.length === 0) {
                    str += '1 = 2';
                } else {
                    str += `(${key} in (${val.join(',')}))`;
                }
                break;
            case 'not in':
                if (val.length === 0) {
                    str += '1 = 1';
                } else {
                    str += `not(${key} in (${val.join(',')}))`;
                }
                break;
            default:
                str += `${key} ${resp[0].value} ${val}`;
                break;
        }

        return str;
    }

    private replaceSpecialCharacters(attribute: any) {
        // replace the single quotes
        attribute = attribute.replace(/%/g, '%25');
        attribute = attribute.replace(/\+/g, '%2B');
        attribute = attribute.replace(/\//g, '%2F');
        attribute = attribute.replace(/\?/g, '%3F');

        attribute = attribute.replace(/#/g, '%23');
        attribute = attribute.replace(/&/g, '%26');
        return attribute;
    }
}

export const listOperatorSqlOdata = [
    { key: '=', value: 'eq' },
    { key: '<>', value: 'ne' },
    { key: '>', value: 'gt' },
    { key: '>=', value: 'ge' },
    { key: '<', value: 'lt' },
    { key: '<=', value: 'le' },
    { key: 'like', value: 'like' },
    { key: 'in', value: 'in' },
    { key: '!=', value: 'not eq' },
    { key: '!<>', value: 'not ne' },
    { key: '!>', value: 'not gt' },
    { key: '!>=', value: 'not ge' },
    { key: '!<', value: 'not lt' },
    { key: '!<=', value: 'not le' },
    { key: '!like', value: 'not like' },
    { key: '!in', value: 'not in' }
];
