export interface Message {
  MESSAGE_CODE: string;
  MESSAGE_DESCR: string;
}

export const MessageList: Message[] = [
  { MESSAGE_CODE: '0001', MESSAGE_DESCR: 'Inserted Successfully' },
  { MESSAGE_CODE: '0002', MESSAGE_DESCR: 'An error occurred. Insert Fail' },
  { MESSAGE_CODE: '0003', MESSAGE_DESCR: 'Successfully deleted data' },
  { MESSAGE_CODE: '0004', MESSAGE_DESCR: 'An error occurred. Delete Fail' },
  { MESSAGE_CODE: '0005', MESSAGE_DESCR: 'Confirm data delete' },
  { MESSAGE_CODE: '0006', MESSAGE_DESCR: 'Please enter your full details' },
  { MESSAGE_CODE: '0007', MESSAGE_DESCR: 'Updated Successfully' },
  { MESSAGE_CODE: '0008', MESSAGE_DESCR: 'An error occurred. Update Fail' },
  { MESSAGE_CODE: '0009', MESSAGE_DESCR: 'Confirm delete this object?' },
  { MESSAGE_CODE: '0010', MESSAGE_DESCR: 'Search error.' },
  { MESSAGE_CODE: '0011', MESSAGE_DESCR: 'Please choose services for table' },
  { MESSAGE_CODE: '0012', MESSAGE_DESCR: 'Required field cannot null' },
  { MESSAGE_CODE: '0013', MESSAGE_DESCR: 'Cannot insert data in there!' },
  { MESSAGE_CODE: '0014', MESSAGE_DESCR: 'Please enable mode update.' },
  { MESSAGE_CODE: '0015', MESSAGE_DESCR: 'Invalid maxlength, please check again!' },
  { MESSAGE_CODE: '0016', MESSAGE_DESCR: 'Invalid pattern, please check again!' },
  { MESSAGE_CODE: '0017', MESSAGE_DESCR: 'Please fill all required field!' },
  { MESSAGE_CODE: '0018', MESSAGE_DESCR: 'Upload files failed!' },
  { MESSAGE_CODE: '0019', MESSAGE_DESCR: 'Please choose file have size below ' },
  { MESSAGE_CODE: '0020', MESSAGE_DESCR: 'Please choose one record!' },
  { MESSAGE_CODE: '0021', MESSAGE_DESCR: 'No window found! Please check config!' },
  { MESSAGE_CODE: '0022', MESSAGE_DESCR: 'No url workflow found! Please check again!' },
  { MESSAGE_CODE: '0023', MESSAGE_DESCR: 'Url workflow is not format workflow ! Please check again!' },
  { MESSAGE_CODE: '0024', MESSAGE_DESCR: 'Username already exists' },
  { MESSAGE_CODE: '0025', MESSAGE_DESCR: `Layer don't have relate field between graphic and attribute table!` },
  { MESSAGE_CODE: '0026', MESSAGE_DESCR: 'URL not support Add/Edit/Delete Features!' },
  { MESSAGE_CODE: '0027', MESSAGE_DESCR: 'Please choose layer!' },
  { MESSAGE_CODE: '0028', MESSAGE_DESCR: 'Cannot read config, url error!' },
  { MESSAGE_CODE: '0029', MESSAGE_DESCR: 'Already exist in the database. Insert Fail!' },
  { MESSAGE_CODE: '0030', MESSAGE_DESCR: 'Does not exist in the database. Update Fail!' },
  { MESSAGE_CODE: '0031', MESSAGE_DESCR: 'Does not exist in the database. Delete Fail!' },
  { MESSAGE_CODE: '0032', MESSAGE_DESCR: 'Does not exist in the database. Open Report Fail!' },
  { MESSAGE_CODE: '0033', MESSAGE_DESCR: 'Not valid' },
  { MESSAGE_CODE: '0034', MESSAGE_DESCR: `Is Summary. Can't assignment` },
  { MESSAGE_CODE: '0035', MESSAGE_DESCR: `Window not found! Please check the configuration!` },
  { MESSAGE_CODE: '0036', MESSAGE_DESCR: `Please select Tab you want to create Layout` },
  { MESSAGE_CODE: '0098', MESSAGE_DESCR: 'Please check Url Portal again!' },
  { MESSAGE_CODE: '0099', MESSAGE_DESCR: 'Login Portall Fail!' },
  { MESSAGE_CODE: '0100', MESSAGE_DESCR: 'Login Successfull!' },
  { MESSAGE_CODE: '0037', MESSAGE_DESCR: 'Select only one record' },
  { MESSAGE_CODE: '0038', MESSAGE_DESCR: 'This menu have submenus, cannot delete!' },
  { MESSAGE_CODE: '0039', MESSAGE_DESCR: 'Confirm rescall job!' },
  { MESSAGE_CODE: '0040', MESSAGE_DESCR: 'An error occurred. The record already exists!' },
  { MESSAGE_CODE: '0040', MESSAGE_DESCR: 'An error occurred. The application order already exists!' },
  { MESSAGE_CODE: '0041', MESSAGE_DESCR: 'New password and confirmation password do not match!' },
  { MESSAGE_CODE: '0042', MESSAGE_DESCR: 'Change password successfully!' },
  { MESSAGE_CODE: '0043', MESSAGE_DESCR: 'Password change failed!' },
  { MESSAGE_CODE: '0044', MESSAGE_DESCR: 'Name or Email already exist!' },
  { MESSAGE_CODE: '0045', MESSAGE_DESCR: 'Account registration failed!' },
  { MESSAGE_CODE: '0046', MESSAGE_DESCR: 'Successful account registration! Please check your email.' },
  { MESSAGE_CODE: '0047', MESSAGE_DESCR: 'Registration information has not been entered.' },
  { MESSAGE_CODE: '0048', MESSAGE_DESCR: 'You must choose a package' },
  { MESSAGE_CODE: '0049', MESSAGE_DESCR: 'Company code already exists' },
  { MESSAGE_CODE: '0050', MESSAGE_DESCR: 'Email already exists' },
  { MESSAGE_CODE: '0051', MESSAGE_DESCR: 'Email already exists, Please check your email!' },
  { MESSAGE_CODE: '0052', MESSAGE_DESCR: 'Please agree to the terms to continue payment!' },
  { MESSAGE_CODE: '0053', MESSAGE_DESCR: 'Cannot delete readonly data!' },
  { MESSAGE_CODE: '0054', MESSAGE_DESCR: 'Portal can not access public, you must login portal!' },
  { MESSAGE_CODE: '0055', MESSAGE_DESCR: 'Invalid Token!' },
  { MESSAGE_CODE: '0056', MESSAGE_DESCR: 'Phone number already exists' },
  { MESSAGE_CODE: '0057', MESSAGE_DESCR: "Unchecking 'Is create group Portal' will delete this group in Portal. Do you want to do it?" },
  { MESSAGE_CODE: '0058', MESSAGE_DESCR: "Checking 'Is create group Portal' will add this group in Portal. Do you want to do that?" },
  { MESSAGE_CODE: '0059', MESSAGE_DESCR: "Session deleted successfully!" }
];
