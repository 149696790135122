export const dojoConfig = {
    async: true,
    packages: [
      {
        location: '/path/to/MyModule',
        name: 'MyModule'
      }
    ]
    // packages: [
    //     { name: 'libs', location: location.pathname.replace(/\/[^/]+$/, '') + '/libs' }
    // ],
    // aliases: [
    //     ['jquery', 'libs/jquery']
    // ]
};
