<form [formGroup]="validateForm" class="login-form">
  <div class="titleClass">
    <label>{{'Sign up for a trial' | translate}}</label>
  </div>
  <div class="fluid grid formgrid">
    <div class="field col-12 md-12">
      <app-input [type]="'text'" [labelName]="'Full name' | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
        formControlName="hoTen" (blurEvent)="onBlurName($event)"></app-input>
      <ng-container *ngIf="patternName && patternName.errors">
        <small class="p-error block" *ngIf="patternName.errors.required">
          {{'Name required.' | translate}}
        </small>
      </ng-container>
    </div>
    <div class="field col-12 md-12">
      <app-input aria-describedby="email-help" [type]="'text'" [labelName]="'Email'  | translate" [isLabelLeft]="false"
        [isCtrlBlur]="false" formControlName="email" (blurEvent)="onBlurEmail($event)"></app-input>


      <ng-container *ngIf="patternEmail && patternEmail.errors">
        <small class="p-error block" *ngIf="patternEmail.errors.required">
          {{'Email required.' | translate}}
        </small>
        <small class="p-error block" *ngIf="patternEmail.errors.pattern">
          {{'Email not valid.' | translate}}
        </small>
      </ng-container>
    </div>
    <div class="field col-12 md-12">
      <app-input [type]="'text'" [labelName]="'Phone number'  | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
        formControlName="dienThoai" (blurEvent)="onBlurPhone($event)"></app-input>

      <ng-container *ngIf="patternPhone && patternPhone.errors">
        <small class="p-error block" *ngIf="patternPhone.errors.required">
          {{'Phone required.' | translate}}
        </small>
        <small class="p-error block" *ngIf="patternPhone.errors.pattern">
          {{'Phone not valid.' | translate}}
        </small>
      </ng-container>
    </div>
    <div class="field col-12 md-12">
      <app-input [type]="'text'" [labelName]="'Company'  | translate" [isLabelLeft]="false" [isCtrlBlur]="false"
        formControlName="tenCongTy"></app-input>
    </div>
    <div class="field col-12 md-12">
      <app-input [type]="'text'" [labelName]="'Areas of Specialization'  | translate" [isLabelLeft]="false"
        [isCtrlBlur]="false" formControlName="linhVuc"></app-input>
    </div>
    <div class="field col-12 md-12">
      <app-input [type]="'text-area'" [labelName]="'Description'  | translate" [isLabelLeft]="false"
        [isCtrlBlur]="false" formControlName="moTa"></app-input>
    </div>

    <div class="field col-12 md-12" style="display: flex;
    align-items: center;
    justify-content: center;">
      <!-- water.esrivn.vn -->
      <!-- <p-captcha siteKey="6Lecg3AiAAAAAAswA5SQTSha7xGemJGFztCyrF_N"></p-captcha> -->
      <!-- cloud.applicationjs.com -->
      <!-- <p-captcha siteKey="6Lctj5siAAAAAEVH-LytpOCQh7JwZfzr9xM_Autm"></p-captcha> -->

      <p-captcha [siteKey]="siteKeyCapcha" (onResponse)="handleCaptchaResponse($event)"></p-captcha>
    </div>

    <div class="field col-12 md-12">
      <p-button [disabled]="!isRegister" [label]="'Register' | translate" (onClick)="handlerRegister()">
        <div style="width: 15px;">
          <ng-container *ngIf="!isRegister"><p-progressSpinner></p-progressSpinner></ng-container>
        </div>
      </p-button>
    </div>

    <div class=" field col-12 md-12">
      <span (click)="handlerModeLogin()" class="login-form-sign-up">{{'Do you have an account? Login' |
        translate}}</span>
    </div>
  </div>
</form>


<app-dialog-prime #dialogComponent [isAppendBody]="true" [isModal]="true" [isMinimize]="true" top="30%"
  MaxWidthPanel="50%"></app-dialog-prime>
<ng-template #messageSuccess>
  <div class="modal__message">
    <div class="modal__message--content">
      <div class="message__icon">
        <i class="pi pi-check-circle"></i>
      </div>
      <div class="message__text">
        {{ 'You have successfully registered an account! Please check your mailbox and activate your account!' |
        translate }}
      </div>
    </div>

    <div class="modal__message--footer">
      <app-button labelName="Đồng ý" (clickEvent)="handleCloseMessageDialog()"></app-button>
    </div>
  </div>
</ng-template>