import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-core-import-data',
  templateUrl: './core-import-data.component.html',
  styleUrls: ['./core-import-data.component.scss']
})
export class CoreImportDataComponent implements OnInit {
  val_file: any = {
    rowheader: null,
    currentSheet: null
  }
  dataSheet: any[] = []
  dataRowHeader: any[] = []
  indexExcelTab: any = 0
  dataImport: any[] = []
  dataHeader: any[] = []
  _dataExcel: any[] = []
  // @Input()
  // set dataExcel(data: any) {
  //   this._dataExcel = data
  //   if(data) {
  //     this.startUp(data)
  //   }
  // }
  // get dataExcel() {
  //   return this._dataExcel
  // }
  _configImport: any = null
  @Input()
  set configImport(config: any) {
    this._configImport = config
    if (config) {
      this.startUp(config)
    }
  }
  get configImport() {
    return this._configImport
  }
  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }
  startUp(config: any) {
    console.log(config)
    const obj = config.arrRowHeader.find((v: any) => this.val_file.currentSheet !== null && v.idSheet === this.val_file.currentSheet)
    if (obj) {
      this.dataRowHeader = obj.arrRowHeader
    }
  }
  onEditComplete(evt: any) { }
  onEditInit(evt: any) { }
  arrColumnExcel: any[] = []
  onchangeRowHeader(evt: any) {
    this.arrColumnExcel = []
    if (this.configImport.arrDataExcel.length > 0) {
      const objData = this.configImport.arrDataExcel.find((v: any) => v.idSheet === this.val_file.currentSheet)
      if (objData) {
        if (objData.data.length > 0) {
          objData.data[evt].forEach((item: any) => {
            this.arrColumnExcel.push({
              code: item,
              name: item
            })
          })
        }
      }
    }
  }
  onChangeSheet(evt: any, data: any) {
    this.val_file.rowheader = null
    this.dataRowHeader = []
    const obj = this.configImport.arrRowHeader.find((v: any) => v.idSheet === evt)
    if (obj) {
      this.dataRowHeader = obj.arrRowHeader
      // setTimeout(() => {
      //   this.val_file.rowheader = 0
      // }, 200)

    }
  }
  isNext: boolean = false
  onNextClick() {
    if (!this.isNext) {
      if (this.val_file.currentSheet !== null && this.val_file.rowheader !== null) {
        this.isNext = !this.isNext
      }
    } else {
      this.isNext = !this.isNext
    }


  }
  onClickReset() { }
}
