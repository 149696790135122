import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';
import { AppService } from 'app/app-base/app.service';
import { loadModules } from 'esri-loader';
import { GisService } from 'app/gis/arcgis3x/services/gis.service';

@Component({
  selector: 'app-core-whereclause',
  templateUrl: './core-whereclause.component.html',
  styleUrls: ['./core-whereclause.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR
      , useExisting: forwardRef(() => CoreWhereclauseComponent)
      , multi: true
    }
  ]
})
export class CoreWhereclauseComponent implements OnInit {
  requiredClass: boolean = false
  @Input() arrField: any[] = []
  @Input() isShowLabel: boolean = true
  @Input() titleDialog: any = '';
  @Input() labelName: any = '';
  @Input() isDisabledCtrl: boolean = false;
  @Input() maxLength: any = '500';
  @Input() isLabelLeft: boolean = false;
  @Input() isCtrlBlur: boolean = false;
  @Input() isTypeArray: boolean = false
  @Input() mode: any = 'array' // array, json, orderby, location
  @Input() hasFormCtrl: boolean = true
  @ViewChild('tmpClause') tmpClause!: TemplateRef<any>
  @ViewChild(DialogPrimeComponent) dialogPrime!: DialogPrimeComponent;
  @Output() valueModelChange: EventEmitter<any> = new EventEmitter();
  @Output() blurEvent: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() hasButton: boolean = false
  @Input() typeField: any = null
  @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
  // @Input() arrHeader: any[] = ['Key', 'Value', 'Color']
  // @Input() isAndOr: boolean = false;
  arrOrderType: any[] = [{
    CODE: 'asc',
    DESCR: 'Asc'
  }, {
    CODE: 'desc',
    DESCR: 'Desc'
  }]
  dataColumnDisplay: any[] = [{
    CODE: 'number',
    DESCR: 'Number'
  }, {
    CODE: 'text',
    DESCR: 'Text'
  }];
  @Input() isUniqueKey: any = false
  @Input() arrCtrl: any[] = [['KEY_0', 'VALUE_0', 'COLOR_0', 'TAG_0']]
  formCtrl: any = null
  hasColor: boolean = false
  hasTag: boolean = false
  arrListRadio: any[] = [{
    CODE: 'and',
    DESCR: 'AND'
  }, {
    CODE: 'or',
    DESCR: 'OR'
  }]
  valueLogic = false
  valueDatatype: any = null
  ctrl!: FormControl;
  arrCtrlWhereClause: any[] = []
  arrProp: any[] = []
  objVFormat: any = {}
  _valueModel: any = null;
  @Input()
  get valueModel() {
    return this._valueModel === "" ? null : this._valueModel;
  }

  set valueModel(value) {
    if (this.required) {
      if (value !== null && value !== '') {
        this.requiredClass = false;
      } else {
        this.requiredClass = true
      }
    } else {
      this.requiredClass = false
    }
    this.cd.detectChanges();
    this._valueModel = value;
    this.valueModelChange.emit(this._valueModel);
  }
  title_core: any = null;
  private _required = false;
  @Input('required')
  set required(val: boolean) {
    this._required = val;
    this.requiredClass = val;
    if (this.ctrl) {
      this.ctrl.setValidators(val === true ? [Validators.required] : [Validators.nullValidator]);
    }
  }
  get required() {
    return this._required;
  }
  private _isReadOnly = false;
  @Input('isReadOnly')
  set isReadOnly(val: boolean) {
    this._isReadOnly = val;
    if (this.ctrl) {
      val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
    }
  }
  get isReadOnly() {
    return this._isReadOnly;
  }
  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };
  constructor(
    private cd: ChangeDetectorRef,
    private appService: AppService,
    private gisService: GisService
  ) {
    this.initData()
    this.initForm();
  }

  ngOnInit(): void {
  }
  initData() {
    this.objVFormat = {
      number: [{
        CODE: 'minFractionDigits',
        DESCR: 'minFractionDigits',
        type: 'number'
      }, {
        CODE: 'maxFractionDigits',
        DESCR: 'maxFractionDigits',
        type: 'number'
      }, {
        CODE: 'min',
        DESCR: 'min',
        type: 'number'
      }, {
        CODE: 'max',
        DESCR: 'max',
        type: 'number'
      }, {
        CODE: 'step',
        DESCR: 'step',
        type: 'number'
      }, {
        CODE: 'reg',
        DESCR: 'reg',
        type: 'text'
      }, {
        CODE: 'style',
        DESCR: 'style',
        type: 'text'
      }],
      text: [{
        CODE: 'reg',
        DESCR: 'reg',
        type: 'text'
      }, {
        CODE: 'style',
        DESCR: 'style',
        type: 'text'
      }],
      lang: [{
        CODE: 'en',
        DESCR: 'en',
        type: 'lang'
      }, {
        CODE: 'vi',
        DESCR: 'vi',
        type: 'lang'
      }]
    }
  }
  initForm() {
    this.formCtrl = new FormGroup({})
    this.arrCtrl.forEach(item => {
      this.formCtrl.addControl(item[0], new FormControl(null, Validators.required));
      this.formCtrl.addControl(item[1], new FormControl(null, Validators.required));
      this.formCtrl.addControl(item[2], new FormControl(null, Validators.required));
      this.formCtrl.addControl(item[3], new FormControl(null, Validators.required));
    });
    this.ctrl = new FormControl(null, {
      updateOn: this.isCtrlBlur ? 'blur' : 'change',
      validators: this._required === true ? Validators.required : Validators.nullValidator
    });
    this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
      this.title_core = this.buildTooltip(val)
      this.onChange(val)
    })
  }
  buildTooltip(val: any) {
    let str = ''
    if (val !== null && val !== '' && this.mode !== 'orderby') {
      let obj: any
      try {
        obj = JSON.parse(val)
      } catch (error) {
        obj = val
      }
      console.log(obj)

      if (this.mode === 'array') {
        if (Array.isArray(obj)) {
          obj.forEach((item: any) => {
            str += '\n  [' + item.toString() + '],'
          })
          str = str.slice(0, -1)
          str = '[' + str + '\n]'
        }

      } else {
        if (typeof (obj) === 'object') {
          Object.keys(obj).forEach((key: any) => {
            str += '\n  ' + key + ': ' + obj[key] + ','
          })
          str = str.slice(0, -1)
          str = '{' + str + '\n}'
        }

      }

    }
    return str
  }
  handlerEvt: any = null;
  valueCache: any = null
  async onWriteClause() {
    this.cd.detectChanges()
    if (this.typeField) {
      this.arrProp = this.typeField === 'number' ? this.objVFormat[this.typeField] : this.typeField === 'lang' ? this.objVFormat[this.typeField] : this.objVFormat['text']
    }
    await this.bindData();
    this.dialogPrime.isComponent = false;
    this.dialogPrime.title = this.titleDialog;
    this.dialogPrime.templateRef = this.tmpClause;

    this.dialogPrime.onShow();
    this.handlerEvt = this.dialogPrime.onClose.subscribe((res: any) => {
      if (this.hasFormCtrl) {
        this.ctrl.setValue(res !== undefined ? res : this.valueCache);
      } else {
        this.valueModel = res !== undefined ? res : this.valueCache
      }
      this.valueChange.emit()
      this.formCtrl.reset();
      Object.keys(this.formCtrl.controls).forEach(key => {
        this.formCtrl.removeControl(key);
      });
      this.handlerEvt.unsubscribe();
    });
  }
  bindData() {
    const _value = this.hasFormCtrl ? this.ctrl.value : this._valueModel
    console.log(_value)
    if (_value && _value !== null && _value !== '') {
      this.arrCtrl = [];
      this.valueCache = _value;
      let arr: any
      try {
        arr = JSON.parse(_value);
      } catch (error) {
        arr = [_value];
      }
      this.formCtrl = new FormGroup({});
      if (this.mode === 'array') {
        setTimeout(() => {
          if (this.isTypeArray) {
            if (Array.isArray(arr[0])) {
              const obj_color = arr.find((v: any) => v[2] && v[2] !== undefined)
              const obj_tag = arr.find((v: any) => v[3] && v[3] !== undefined)
              this.hasColor = obj_color ? true : false;
              this.hasTag = obj_tag ? true : false;
              this.valueDatatype = typeof (arr[0][0]) === 'number' ? 'number' : 'text';
              arr.forEach((item: any, ind: any) => {
                this.arrCtrl.push(['KEY_' + ind, 'VALUE_' + ind, 'COLOR_' + ind, 'TAG_' + ind]);
                this.formCtrl.addControl('KEY_' + ind, new FormControl(item[0], Validators.required));
                this.formCtrl.addControl('VALUE_' + ind, new FormControl(item[1], Validators.required));
                this.formCtrl.addControl('COLOR_' + ind, new FormControl(item[2], Validators.required));
                this.formCtrl.addControl('TAG_' + ind, new FormControl(item[3], Validators.required));
              });
            } else {
              this.hasColor = false;
              this.hasTag = false
              // this.isNumber = typeof (arr[0][0]) === 'number' ? true : false;
              this.valueDatatype = typeof (arr[0]) === 'number' ? 'number' : 'text';
              arr.forEach((item: any, ind: any) => {
                this.arrCtrl.push(['KEY_' + ind, 'VALUE_' + ind, 'COLOR_' + ind, 'TAG_' + ind]);
                this.formCtrl.addControl('KEY_' + ind, new FormControl(item, Validators.required));
                this.formCtrl.addControl('VALUE_' + ind, new FormControl(null));
                this.formCtrl.addControl('COLOR_' + ind, new FormControl(null));
                this.formCtrl.addControl('TAG_' + ind, new FormControl(null));
              });
            }
          } else {
            const obj_color = arr.find((v: any) => v[2] && v[2] !== undefined)
            const obj_tag = arr.find((v: any) => v[3] && v[3] !== undefined)
            this.hasColor = obj_color ? true : false;
            this.hasTag = obj_tag ? true : false;
            // this.isNumber = typeof (arr[0][0]) === 'number' ? true : false;
            this.valueDatatype = typeof (arr[0][0]) === 'number' ? 'number' : 'text';
            arr.forEach((item: any, ind: any) => {
              this.arrCtrl.push(['KEY_' + ind, 'VALUE_' + ind, 'COLOR_' + ind, 'TAG_' + ind]);
              this.formCtrl.addControl('KEY_' + ind, new FormControl(item[0], Validators.required));
              this.formCtrl.addControl('VALUE_' + ind, new FormControl(item[1]));
              this.formCtrl.addControl('COLOR_' + ind, new FormControl(item[2]));
              this.formCtrl.addControl('TAG_' + ind, new FormControl(item[3]));
            });
          }
        }, 200)

      } else if (this.mode === 'orderby') {
        setTimeout(() => {
          arr.forEach((item: any, ind: any) => {
            const arr_val = item.split(" ")
            this.arrCtrl.push(['KEY_' + ind, 'VALUE_' + ind]);
            this.formCtrl.addControl('KEY_' + ind, new FormControl(arr_val[0], Validators.required));
            this.formCtrl.addControl('VALUE_' + ind, new FormControl(arr_val[1]));
            this.formCtrl.addControl('COLOR_' + ind, new FormControl(null));
            this.formCtrl.addControl('TAG_' + ind, new FormControl(null));
          })
        }, 200)

      } else {
        setTimeout(() => {
          Object.keys(arr).forEach((item: any, ind: any) => {
            this.arrCtrl.push(['KEY_' + ind, 'VALUE_' + ind, 'COLOR_' + ind, 'TAG_' + ind]);
            this.formCtrl.addControl('KEY_' + ind, new FormControl(item, Validators.required));
            this.formCtrl.addControl('VALUE_' + ind, new FormControl(arr[item]));
            this.formCtrl.addControl('COLOR_' + ind, new FormControl(null));
            this.formCtrl.addControl('TAG_' + ind, new FormControl(null));
          })
        }, 200)

      }


    } else {
      this.hasColor = false;
      this.hasTag = false
      // this.isNumber = false;
      // this.valueTypeKey = 'text';
      this.formCtrl = new FormGroup({});
      this.arrCtrl = this.mode === 'location' ? [['KEY_0', 'VALUE_0', 'COLOR_0', 'TAG_0'], ['KEY_1', 'VALUE_1', 'COLOR_1', 'TAG_1']] : [['KEY_0', 'VALUE_0', 'COLOR_0', 'TAG_0']];
      // this.ctrlDataType.setValue('text')

      if (this.mode === 'location') {
        this.valueDatatype = 'number'
        this.formCtrl.addControl(this.arrCtrl[0][0], new FormControl('x', Validators.required));
        this.formCtrl.addControl(this.arrCtrl[0][1], new FormControl(null, Validators.required));
        this.formCtrl.addControl(this.arrCtrl[0][2], new FormControl("#ffffff", Validators.required));
        this.formCtrl.addControl(this.arrCtrl[0][3], new FormControl(null, Validators.required));

        this.formCtrl.addControl(this.arrCtrl[1][0], new FormControl('y', Validators.required));
        this.formCtrl.addControl(this.arrCtrl[1][1], new FormControl(null, Validators.required));
        this.formCtrl.addControl(this.arrCtrl[1][2], new FormControl("#ffffff", Validators.required));
        this.formCtrl.addControl(this.arrCtrl[1][3], new FormControl(null, Validators.required));

        this.getCurrentLocation()
      } else {
        this.valueDatatype = 'text'
        this.arrCtrl.forEach((item: any) => {
          this.formCtrl.addControl(item[0], new FormControl(null, Validators.required));
          this.formCtrl.addControl(item[1], new FormControl(null, Validators.required));
          this.formCtrl.addControl(item[2], new FormControl("#ffffff", Validators.required));
          this.formCtrl.addControl(item[3], new FormControl(null, Validators.required));
        })
      }


    }
  }
  async onAddCtrl(evt: any) {
    await this.formCtrl.addControl('KEY_' + this.arrCtrl.length, new FormControl());
    await this.formCtrl.addControl('VALUE_' + this.arrCtrl.length, new FormControl());
    await this.formCtrl.addControl('COLOR_' + this.arrCtrl.length, new FormControl("#ffffff"));
    await this.formCtrl.addControl('TAG_' + this.arrCtrl.length, new FormControl());
    this.arrCtrl.push(['KEY_' + this.arrCtrl.length, 'VALUE_' + this.arrCtrl.length, 'COLOR_' + this.arrCtrl.length, 'TAG_' + this.arrCtrl.length]);
  }
  async onDeleteCtrl(item: any) {
    this.arrCtrl = await this.arrCtrl.filter(value => value[0] !== item[0] && value[1] !== item[1] && value[2] !== item[2] && value[3] !== item[3]);
    await this.formCtrl.removeControl(item[0]);
    await this.formCtrl.removeControl(item[1]);
    await this.formCtrl.removeControl(item[2]);
    await this.formCtrl.removeControl(item[3]);
  }
  onClearCtrl() {
    if (this.valueCache) {
      this.bindData();
    } else {
      if (this.mode === 'location') {
        // this.formCtrl.controls['VALUE_1'].reset()
        // this.formCtrl.controls['VALUE_0'].reset()
        this.getCurrentLocation()
      } else {
        this.formCtrl.reset();
      }

    }
  }
  checkValue(arrData: any[]) {
    let arr: any[] = []
    let count: any = 0
    arrData.forEach((item: any) => {
      if (item[1] !== null && item[1] !== '') {
        if (this.hasColor) {
          if (item[2] !== null && item[2] !== '') {
            count++
          }

        } else {
          count++
        }

      }
    })
    if (count === 0) {
      arrData.forEach((item: any) => {
        arr.push(item[0])
      })
    } else {
      arr = [...arrData]
    }
    return arr
  }
  onBuild() {
    const arrResult: any = [];
    let obj: any = {}
    if(this.arrCtrl.length > 0) {
      if (this.chkValFormDomain()) {
        if (this.isUniqueKey && !this.checkDuplicateValue()) {
          this.appService.createMessage('warning', 'There is a duplicate key, please check again!')
          return;
        }
        if (this.mode === 'array') {
          this.arrCtrl.forEach(item => {
            const arr = [];
            if (this.valueDatatype === 'number') {
              if (this.hasColor) {
                // arr.push(Number(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]], this.formCtrl.value[item[2]]);
                if (this.hasTag) {
                  arr.push(Number(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]], this.formCtrl.value[item[2]] === '' ? null : this.formCtrl.value[item[2]], this.formCtrl.value[item[3]]);
                } else {
                  arr.push(Number(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]], this.formCtrl.value[item[2]] === '' ? null : this.formCtrl.value[item[2]]);
                }
              } else {
                // arr.push(Number(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]]);
                if (this.hasTag) {
                  arr.push(Number(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]], null, this.formCtrl.value[item[3]]);
                } else {
                  arr.push(Number(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]]);
                }
              }
  
            } else {
              if (this.hasColor) {
                if (this.hasTag) {
                  arr.push(String(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]], this.formCtrl.value[item[2]] === '' ? null : this.formCtrl.value[item[2]], this.formCtrl.value[item[3]]);
                } else {
                  arr.push(String(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]], this.formCtrl.value[item[2]] === '' ? null : this.formCtrl.value[item[2]]);
                }
  
              } else {
                if (this.hasTag) {
                  arr.push(String(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]], null, this.formCtrl.value[item[3]]);
                } else {
                  arr.push(String(this.formCtrl.value[item[0]]), this.formCtrl.value[item[1]]);
                }
  
              }
            }
            arrResult.push(arr);
          });
          this.dialogPrime.closeDialog();
          this.dialogPrime.onClose.emit(this.isTypeArray ? JSON.stringify(this.checkValue(arrResult)) : JSON.stringify(arrResult));
        } else if (this.mode === 'orderby') {
          const val = this.appService.returnParam(this.formCtrl)
          const arr: any[] = []
          this.arrCtrl.forEach((item: any) => {
            arr.push(val[item[1]] && val[item[1]] !== '' ? val[item[0]] + ' ' + val[item[1]] : val[item[0]])
          })
          this.dialogPrime.closeDialog();
          this.dialogPrime.onClose.emit(JSON.stringify(arr));
        } else {
          this.arrCtrl.forEach((item: any) => {
            if (this.typeField) {
              const obj_f = this.arrProp.find((v: any) => v.CODE === this.formCtrl.value[item[0]])
              obj[this.formCtrl.value[item[0]]] = obj_f.type === 'number' ? Number(this.formCtrl.value[item[1]]) : this.formCtrl.value[item[1]]
            } else {
              if (this.mode === 'location') {
                obj[this.formCtrl.value[item[0]]] = Number(this.formCtrl.value[item[1]])
              } else {
                obj[this.formCtrl.value[item[0]]] = this.formCtrl.value[item[1]]
              }
  
            }
  
          })
          if(this.mode === 'location') {
            this.gisService.graphicUtil.clear('lyr_field_location')
          }
          
          this.dialogPrime.closeDialog();
          this.dialogPrime.onClose.emit(JSON.stringify(obj));
        }
  
      }
    } else {
      this.dialogPrime.closeDialog();
      this.dialogPrime.onClose.emit(null);
    }
   
  }
  checkDuplicateValue() {
    let chk = false
    const arr: any[] = []
    this.arrCtrl.forEach((item: any) => {
      arr.push(this.formCtrl.value[item[0]])
    })
    const arr_dup = Array.from(new Set(arr));
    if (arr.length > arr_dup.length) {
      chk = false
    } else {
      chk = true
    }
    return chk;
  }
  chkValFormDomain() {
    let count = 0;
    if (this.mode === 'location') {
      this.arrCtrl.forEach(item => {
        if (this.formCtrl.value[item[1]] === null || this.formCtrl.value[item[1]] === '') {
          count++;
        }
      })
    } else {
      this.arrCtrl.forEach(item => {
        if (this.formCtrl.value[item[0]] === null || this.formCtrl.value[item[0]] === '') {
          count++;
        }
      })
    }

    return count === 0 ? true : false;
  }
  onChangeDataType(evt: any) { }
  writeValue(_value: any): void {
    this.ctrl.setValue(_value);
  }
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  getCurrentLocation() {
    let obj = {}
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((point) => {
        this.formCtrl.controls['VALUE_0'].setValue(point.coords.longitude)
        this.formCtrl.controls['VALUE_1'].setValue(point.coords.latitude)

      }, err => { }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true });
    }
    // return obj
  }
  async onClickGetLocation() {
    const [Point, projection] = await loadModules(["esri/geometry/Point", "esri/geometry/projection"])
    this.appService.getLocation.emit()
    const handle = this.appService.returnLocation.subscribe((res: any) => {
      console.log(res)
      // this.formGroup.controls[field.FIELD_NAME].setValue(res)
      if (res) {
        const point_convert = projection.project(res, { wkid: 4326 })
        // const point_convert = coordinateFormatter.toLatitudeLongitude(res, 'dd')
        // console.log(point_convert)
        // const obj = JSON.parse(res)
        this.formCtrl.controls['VALUE_0'].setValue(point_convert.x)
        this.formCtrl.controls['VALUE_1'].setValue(point_convert.y)
      }

      handle.unsubscribe()
    })
  }
}
