import { SharedModule } from './../../shared/shared.module';
import { CoreModule } from 'app/core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarComponent } from './calendar.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TimeoutInterceptor } from 'app/app-base/TimeoutInterceptor';
import { JwtInterceptor } from 'app/app-base/JwtInterceptor ';
import { ErrorInterceptor } from 'app/app-base/ErrorInterceptor';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ApplicationService } from 'app/um/services';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin, timeGridPlugin, interactionPlugin
]);

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  CoreModule,
  FullCalendarModule,
  SharedModule,
];

const services: any[] = [
  ApplicationService
];

const components = [
  CalendarComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: modules,
  entryComponents: components,
  providers: [
    services,
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class Calendar1Module { }
