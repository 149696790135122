import { Injectable } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { loadModules } from 'esri-loader';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseInterface, DeleteResponse, IdParams, InsertResponse, OdataParams, QdataParams, SearchResponse, UpdateResponse } from './request.service';

export const dojoConfig = {
    async: true,
    packages: [
        {
            location: '/path/to/MyModule',
            name: 'MyModule'
        }
    ]
};

@Injectable()
export class ArcGIS3XService implements BaseInterface {
    constructor(
        private appService: AppService
    ) {
        // this.testDecode();
    }

    esriRequest(
        url: string,
        params: any = {},
        type: string = '',
        useProxy: boolean = false,
        usePost = true
    ): Observable<any> {
        const subject: Subject<any> = new Subject();
        let newUrl = url;

        switch (type) {
            case 'search':
                newUrl += '/query';
                usePost = false;
                break;
            case 'insert':
                newUrl += '/addFeatures';
                break;
            case 'update':
                newUrl += '/updateFeatures';
                break;
            case 'delete':
                newUrl += '/deleteFeatures';
                break;
            default:
                break;
        }

        loadModules(['esri/request', 'esri/config']).then(
            async ([request, esriConfig]) => {
                esriConfig.defaults.io.proxyUrl = this.appService.urlProxy;
                const referer = this.appService.getReferer(newUrl);
                if (esriConfig.defaults.io.corsEnabledServers.indexOf(referer) === -1) {
                    esriConfig.defaults.io.corsEnabledServers.push(referer);
                }
                if (url === null) return;
                // Khác domain thì dùng proxy????
                if (this.appService.getDomainName(url) !== this.appService.getDomainName(location.hostname)) {
                    useProxy = true;
                }

                let form = null;
                if (params.form) {
                    form = params.form;
                    delete params.form;
                }

                const reqData = {
                    url: newUrl,
                    content: params,
                    handleAs: 'json',
                    sync: false,
                    form,
                    callbackParamName: 'callback',
                };

                request(reqData, { usePost, useProxy }).then(
                    (response: any) => subject.next(response),
                    (error: any) => subject.next(error)
                ).catch((error: any) => {
                    subject.next(error);
                });
            }
        );

        return subject.asObservable();
    }

    query(q: QdataParams): Observable<any> {
        const url = q.url;
        const useProxy = q.proxy !== null && q.proxy !== undefined;
        const usePost = q.method === 'POST';
        const _params = {
            f: 'json',
            returnGeometry: true,
            spatialRel: 'esriSpatialRelEnvelopeIntersects'
        };

        return this.esriRequest(url, _params, 'search', useProxy, usePost);
    }

    queryCustom(q: QdataParams): Observable<any> {
        const url = q.url;
        const useProxy = q.proxy !== null && q.proxy !== undefined;
        const usePost = q.method === 'POST';

        return this.esriRequest(url, q.params, '', useProxy, usePost);
    }

    getRecordById(q: IdParams): Observable<any> {
        const url = q.url;
        const params = {
            objectIds: q.id,
            f: 'json',
            outFields: ['*'],
            returnGeometry: true
        }
        return this.esriRequest(url, params, 'search', false, false).pipe(map((res: any) => {
            let result: any = null;
            if (res.httpCode && res.httpCode !== 200) {
                return null;
            }

            if (res.message === 'Timeout exceeded') {
                return null;
            }

            if (res && res.features) {
                const geometryType = res.geometryType;
                const wkid = res.spatialReference ?? 102100;
                result = [];

                res.features.forEach((item: any) => {
                    if (item.geometry) {
                        item.geometry.spatialReference = wkid;
                        item.geometry.geometryType = geometryType;
                        item.attributes.__geometry = item.geometry;
                    }
                    result.push(item.attributes);
                });
            }
            return result;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    search(p: OdataParams) {
        const where = this.decodeSql(p.where);
        const outFields = p.select ? [p.select] : ['*'];
        const _params: any = {
            f: 'json',
            outFields,
            returnGeometry: p.returnGeometry !== undefined ? p.returnGeometry : true,
            spatialRel: 'esriSpatialRelEnvelopeIntersects',
            where
        };

        if (p.geometry) {
            _params['geometry'] = JSON.stringify(p.geometry);
            _params['geometryType'] = 'esriGeometryEnvelope';
            if (p.geometry.type === 'polygon') {
                _params['geometryType'] = 'esriGeometryPolygon';
                // _params.spatialRel = 'esriSpatialRelEnvelopeIntersects';
            }
        }

        if (p.spatialRelationship) {
            _params['spatialRel'] = p.spatialRelationship;
        }

        if (p.distinct) {
            _params['returnDistinctValues'] = true;
        }
        if (p.orderBy) {
            _params['orderByFields'] = p.orderBy;
        }
        if (p.groupBy) {
            _params['groupByFields'] = p.groupBy.join(',');
        }
        if (p.token) {
            _params['token'] = p.token;
        }


        const _paramsTotal = {
            ..._params,
            returnCountOnly: true
        };

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.startRecord) {
            _params['resultOffset'] = p.startRecord;
        }
        if (p.pageSize) {
            _params['resultRecordCount'] = p.pageSize;
        }

        const list = [];
        const url = `${p.url}`;

        list.push(this.esriRequest(url, _params, 'search'));
        list.push(this.esriRequest(url, _paramsTotal, 'search'));
        return combineLatest(list).pipe(map((res: any) => {
            const result: SearchResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Search Success'
            };

            if ((res[0].httpCode || res[1].httpCode) && (res[0].httpCode !== 200 || res[1].httpCode !== 200)) {
                result.success = false;
                result.message = res[0].message ? res[0].message : 'Request Error';
                return result;
            }

            if (res[0].message === 'Timeout exceeded') {
                result.success = false;
                result.message = 'Request timeout';
                return result;
            }

            if (res[0] && res[0].features) {
                const geometryType = res[0].geometryType;
                const wkid = res[0].spatialReference ?? 102100;

                res[0].features.forEach((item: any) => {

                    if (item.geometry) {
                        item.geometry.spatialReference = wkid;
                        item.geometry.geometryType = geometryType;
                        item.attributes.__geometry = item.geometry;
                    }

                    result.features.push(item.attributes);
                });

                result.total = res[1] ? res[1].count : 0;
            } else {
                result.success = false;
                result.message = 'Request error';
            }
            return result;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    searchForMobile(p: OdataParams, token: any) {
        const where = this.decodeSql(p.where);
        const outFields = p.select ? [p.select] : ['*'];
        const _params: any = {
            f: 'json',
            outFields,
            returnGeometry: p.returnGeometry !== undefined ? p.returnGeometry : true,
            spatialRel: 'esriSpatialRelEnvelopeIntersects',
            where
        };

        if (p.geometry) {
            _params['geometry'] = JSON.stringify(p.geometry);
        }


        const _paramsTotal = {
            ..._params,
            returnCountOnly: true
        };

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.startRecord) {
            _params['resultOffset'] = p.startRecord;
        }
        if (p.pageSize) {
            _params['resultRecordCount'] = p.pageSize;
        }

        const list = [];
        const url = `${p.url}`;

        list.push(this.esriRequest(url, _params, 'search'));
        list.push(this.esriRequest(url, _paramsTotal, 'search'));
        return combineLatest(list).pipe(map((res: any) => {
            const result: SearchResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Search Success'
            };

            if (res[0].message === 'Timeout exceeded') {
                result.success = false;
                result.message = 'Request timeout';
            }

            if (res[0] && res[0].features) {
                const geometryType = res[0].geometryType;
                const wkid = res[0].spatialReference ?? 102100;

                res[0].features.forEach((item: any) => {

                    if (item.geometry) {
                        item.geometry.spatialReference = wkid;
                        item.geometry.geometryType = geometryType;
                        item.attributes.__geometry = item.geometry;
                    }

                    result.features.push(item.attributes);
                });

                result.total = res[1] ? res[1].count : 0;
            } else {
                result.success = false;
                result.message = 'Request error';
            }
            return result;
        }), catchError((error: any) => {
            const rq: SearchResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Search error!'
            };
            return of(rq)
        }));
    }

    insert(p: OdataParams): Observable<any> {
        const url = `${p.url}`;
        const features: any = {};
        features['attributes'] = p.data;
        if (p.geometry) {
            features['geometry'] = p.geometry;
        }
        const paramsToSend = {
            f: 'json',
            features: JSON.stringify([features])
        };

        return this.esriRequest(url, paramsToSend, 'insert').pipe(map(res => {
            const result: InsertResponse = {
                features: [],
                total: 0,
                success: true,
                message: 'Insert Success'
            };
            if (res.message === 'Timeout exceeded') {
                result.success = false;
                result.message = 'Request timeout';
            }

            if (res) {
                result.features = res.addResults;
                result.total = res.addResults.length;
            }
            return result;
        }), catchError((error: any) => {
            const rq: InsertResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Insert Error'
            };
            return of(rq)
        }));
    }

    update(p: OdataParams) {
        const url = `${p.url}`;

        const features: any = {};
        features['attributes'] = p.data;
        if (p.geometry) {
            features['geometry'] = p.geometry;
        }
        const paramsToSend = {
            f: 'json',
            features: JSON.stringify([features])
        };
        return this.esriRequest(url, paramsToSend, 'update').pipe(map(res => {
            if (res.name === 'Error') {
                return {
                    success: false,
                    message: res.message
                };
            }

            if (res.updateResults && res.updateResults.length > 0 && res.updateResults[0].success === false) {{
                return {
                    success: false,
                    message: res.updateResults[0].error.description
                };
            }}
            const result: UpdateResponse = {
                success: true,
                message: ''
            };

            return result;
        }), catchError((error: any) => {
            const rq: InsertResponse = {
                features: [],
                total: 0,
                success: false,
                message: error ? error.message : 'Insert Error'
            };
            return of(rq)
        }));
    }

    delete(p: OdataParams): Observable<any> {
        const url = `${p.url}`;

        return this.deteleItSelf(url, p.data, p.primaryKey).pipe(map(res => {
            const response: DeleteResponse = {
                data: res.data ? res.data.deleteResults : [],
                success: res.error ? false : true,
                message: 'Xóa thành công'
            };
            return response;
        }), catchError((error: any) => {
            const rq: DeleteResponse = {
                success: false,
                data: [],
                message: error ? error.message : 'Delete Error'
            };
            return of(rq)
        }));
    }

    /** Xóa bản ghi ở trong bảng chính */
    private deteleItSelf(url: any, data: any, primaryKey: any) {
        const params = {
            f: 'json',
            objectIds: data[primaryKey],
        };
        return this.esriRequest(url, params, 'delete');
    }

    private decodeSql(whereClause: any[]) {
        let where = ''; let logic = 'and';
        if (whereClause === null || whereClause === undefined) {
            return '1=2';
        }

        if (whereClause.length === 0) {
            return '1=1';
        }
        const cloneWhere = JSON.parse(JSON.stringify(whereClause));
        if (cloneWhere.length > 0) {
            if (!Array.isArray(cloneWhere[0]) && cloneWhere[0] !== 'and' && cloneWhere[0] !== 'or') {
                where = this.decodeDeep(cloneWhere, '');
            } else {
                cloneWhere.forEach((item: any, index: any) => {
                    if (index === 0 && (item === 'and' || item === 'or')) {
                        logic = item;
                    } else {
                        if (Array.isArray(item) && item.length === 0) {
                            return;
                        }
                        if (Array.isArray(item) && (item[0] === 'and' || item[0] === 'or')) {
                            where += `(${this.decodeSql(item)}) ${logic} `;
                        } else if (Array.isArray(item[0])) {
                            where += `(${this.decodeSql(item)}) and `;
                        } else {
                            where += this.decodeDeep(item, logic);
                        }
                    }
                });
                const number = logic === 'and' ? 5 : 4;
                where = where.substring(0, where.length - number);
            }
        }

        return where;
    }

    private decodeDeep(item: any, logic: string) {
        const key = item[0];
        const operator = item[1];
        let value = item[2];
        let where = '';

        if (typeof (value) === 'string' && value.startsWith('c$')) {
            // where = `${item[0]} ${item[1]} ${eval(value)}`;
            value = eval(value);
        }

        switch (operator) {
            case 'like':
                where = `${key} LIKE N'%${value}%'`;
                break;
            case 'in':
                let type = 'number'; let val = '';
                if (value && value.length > 0) {
                    if (typeof (value[0]) === 'string') {
                        type = 'string';
                    }
                }
                if (type === 'string') {
                    value.forEach((x: string) => {
                        val = `${val}'${x}',`;
                    });
                    val = val.substring(0, val.length - 1);
                } else {
                    val = value.join(',')
                }

                if (value.length === 0) {
                    where = `1 = 2`;
                } else if (value.includes(null)) {
                    where = `${key} is null`;
                    const value1 = value.filter((fil: any) => fil !== null);
                    if (value1 && value1.length > 0) {
                        where = `${where} or ${key} in (${value1.join(',')})`
                    }
                } else {
                    where = `${key} in (${val})`;
                }
                break;
            case 'YEAR':
            case 'MONTH':
                where = `${operator}(${key}) = ${value}`;
                break;
            default:
                if (typeof (value) === 'string') {
                    if (value.startsWith('DATE ')) {
                        where += `${key} ${operator} ${value}`;
                    } else {
                        where += `${key} ${operator} N'${value}'`;
                    }
                } else {
                    if (value === null) {
                        where = `${key} is null`;
                    } else {
                        where = `${key} ${operator} ${value}`;
                    }
                }
                break;
        }
        if (logic !== '') {
            where += ` ${logic} `;
        }
        return where;
    }

    private testDecode() {
        const where = ['table', 'in', [1, 2]];
        const where1 = ['or', ['table', 'in', [1, 2]], ['data', 'like', '1234']];
        const where2 = [
            'or',
            [
                'and',
                ['table', '=', 1],
                ['tableName', 'like', 'super']
            ],
            ['data', 'like', '1234']
        ];
        const whereCurrentUser = ['username', '=', '$userid'];

        // const whereKvhc = [
        //     ['and', 'kvhc', '=', '0100700241'],
        //     ['or', 'kvhc', '=', '22222'],
        //     ['and', 'objectid', '<', '55'],
        //     ['or', 'ten', 'LIKE', 'Đại'],
        // ]

        const whereKvhc = [
            ['or', ['kvhc', '=', '22222'], ['and', ['objectid', '<', '55'], ['ten', 'LIKE', 'Đại']]]
        ]


        const calendarWhere = [
            'and',
            [
                'or',
                ['a', '=', 1],
                ['a', '=', 2],
                ['a', '=', 3],
                ['a', '=', 4],
                ['a', '=', 5],
            ],
            [
                'userid', '=', 'abc'
            ]
        ];

        console.log('TEST ARCGIS DECODE WHERE: ', this.decodeSql(whereKvhc));
        // console.log('TEST ARCGIS DECODE WHERE 1: ', this.decodeSql(where1));
        // console.log('TEST ARCGIS DECODE WHERE 2: ', this.decodeSql(where2));
        // console.log('TEST SQL DECODE WHERE USERNAME: ', this.decodeSql(whereCurrentUser));
    }
}
