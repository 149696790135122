import { Injectable } from '@angular/core';
import { AppService } from '../../app-base/app.service';
import { RequestService } from 'app/core/services/request.service';

@Injectable()
export class RoleService {
    public url = this.appService.urlWS;

    constructor(
        private appService: AppService,
        private reqService: RequestService
    ) { this.reqService.switchType(this.appService.dataAccessType); }

    // role
    public addRole(params: any) {
        const odataParams = {
            url: this.url + '/SysRoles',
            primaryKey: 'RoleId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public updateRole(params: any) {
        const odataParams = {
            url: this.url + '/SysRoles',
            primaryKey: 'RoleId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public deleteRole(id: any) {
        const params = { RoleId: id };
        const odataParams = {
            url: this.url + '/SysRoles',
            primaryKey: 'RoleId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public queryRole(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysRoles',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public queryRoleById(id: any) {
        const params = [['RoleId', '=', Number(id)]];
        const resp = this.reqService.service.search({
            url: this.url + '/SysRoles',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addGroupRoleUserToAccess(params: any) {
        const odataParams = {
            url: this.url + '/SysAccesses',
            primaryKey: 'AccessId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    updateGroupRoleUserApp(params: any) {
        const odataParams = {
            url: this.url + '/SysAccesses',
            primaryKey: 'AccessId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    queryAccess(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysAccesses',
            where: params,
            logic: 'and'
        });
        return resp;
    }


    // Table
    queryTable(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysTables',
            where: params,
            logic: 'or'
        });
        return resp;
    }

    queryTableByRole(id: any) {
        const params = [['RoleId', '=', Number(id)]];
        const resp = this.reqService.service.search({
            url: this.url + '/SysRoleFunctions',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // Function
    queryFunction(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysDmFunctions',
            where: params,
            logic: 'or'
        });
        return resp;
    }

    queryFunctionByTable(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysRoleFunctions',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    addFunction(params: any) {
        const odataParams = {
            url: this.url + '/SysDmFunctions',
            primaryKey: 'FunctionCode',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public updateFunction(params: any) {
        const odataParams = {
            url: this.url + '/SysDmFunctions',
            primaryKey: 'FunctionCode',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public deleteFunction(params: any) {
        const odataParams = {
            url: this.url + '/SysDmFunctions',
            primaryKey: 'FunctionCode',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public getColumnByTableId(tblId: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysColumns',
            where: ['TableId', '=', tblId],
            logic: 'and'
        });
        return resp;
    }

    public getColumnByTableIds(listId: any[]) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysColumns',
            where: ['TableId', 'in', listId],
            logic: 'and'
        });
        return resp;
    }

    // Menus
    public getMenuItem(params: any, orderby: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: params,
            logic: 'and',
            orderBy: orderby
        });
        return resp;
    }

    queryMenu(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysMenuItems',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    queryMenuTool(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SvMenuWindows',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    queryFunctionByMenu(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysRoleMenus',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    queryMenuByRole(id: any) {
        const params = [['RoleId', '=', Number(id)]];
        const resp = this.reqService.service.search({
            url: this.url + '/SysRoleMenus',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    queryRoleByMenu(id: any) {
        const params = [['MenuItemId', '=', Number(id)]];
        const resp = this.reqService.service.search({
            url: this.url + '/SysRoleMenus',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // Users
    queryUser(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SvUserGroups',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // queryGroup(params: any) {
    //     const resp = this.reqService.service.search({
    //         url: this.url + '/SysGroups',
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }

    queryRoleUser(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysAccesses',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // updateRoleUser(params: any) {
    //     const odataParams = {
    //         url: this.url + '/SysAccesses',
    //         primaryKey: 'AccessId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }

    addRoleUser(params: any) {
        const odataParams = {
            url: this.url + '/SysAccesses',
            primaryKey: 'AccessId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    deleteRoleUser(params: any) {
        const odataParams = {
            url: this.url + '/SysAccesses',
            primaryKey: 'AccessId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    // // Event Insert, Update, Delete, Query bang trung gian
    // // public queryRoleFunction(whereClause) {
    // //     const pTable = 'SysRoleFunction';
    // //     return this.connectDbService.searchODATA(this.url, pTable, whereClause);
    // // }

    public insertSysRoleFunction(params: any) {
        const odataParams = {
            url: this.url + '/SysRoleFunctions',
            primaryKey: 'RoleFunctionId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public updateSysRoleFunction(params: any) {
        const odataParams = {
            url: this.url + '/SysRoleFunctions',
            primaryKey: 'RoleFunctionId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public deleteSysRoleFunction(id: any) {
        const params = { RoleFunctionId: id };
        const odataParams = {
            url: this.url + '/SysRoleFunctions',
            primaryKey: 'RoleFunctionId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public insertSysRoleMenu(params: any) {
        const odataParams = {
            url: this.url + '/SysRoleMenus',
            primaryKey: 'RoleMenuId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public updateSysRoleMenu(params: any) {
        const odataParams = {
            url: this.url + '/SysRoleMenus',
            primaryKey: 'RoleMenuId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public deleteSysRoleMenu(id: any) {
        const params = { RoleMenuId: id };
        const odataParams = {
            url: this.url + '/SysRoleMenus',
            primaryKey: 'RoleMenuId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }
}
