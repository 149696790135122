<p-treeTable [value]="datas" [columns]="cols" [paginator]="false" [scrollable]="true" scrollHeight="269px" [resizableColumns]="true">
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" ttResizableColumn>
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <ng-container *ngFor="let col of columns; let i = index">
                <td>
                    <ng-container [ngSwitch]="col.type">
                        <ng-container *ngSwitchCase="'checkbox'">
                            <!-- p-treeTableToggler dùng để đóng mở children (nếu có)-->
                            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler> 
                            <app-core-checkbox [typeValue]="'boolean'" [(valueModel)]="rowData[col.field]" [isDisabled]="rowData[col.field + '_Disabled']"
                                (chkChange)="onChkChange(rowData, col)"></app-core-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'radio'">
                            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler> 
                            <app-core-radio-button [showLabel]="false" [listRadio]="[{CODE: rowData[col.field], DESCR: '123'}]"
                                (chkChange)="onRadioChange(rowData, col)"></app-core-radio-button>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                                {{rowData[col.field]}}
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
    </ng-template>
</p-treeTable>