import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app-base/app.service';
import { loadModules } from 'esri-loader';
import { combineLatest } from 'rxjs';
import { RequestService } from '../services/request.service';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

    public fileList: any[] = []; // Danh sách các file mới thêm
    public listAttachment: any[] = []; // Danh sách toàn bộ các file hiển thị trên layout
    public listDeteleAttachment: any[] = []; // Danh sách các file xóa
    public checkChange = false;

    private fileMaxSize = 5; // Dung lượng file tối đa được gửi, tính theo đơn vị MB
    private baseList: any[] = []; // Clone lưu trữ danh sách các file ban đầu của control, dùng để reset
    private baseFileUrl = 'assets/imgs/others/file_base.png';
    private urlProxy = '';

    // Biến dành cho SQL service
    private appId: any = null;
    private clientId: any = null;

    responsiveOptions = [
        {
            breakpoint: '500px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '350px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '200px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    @Output() public save: EventEmitter<any> = new EventEmitter();
    @ViewChild('upload', { static: false }) uploadNode!: ElementRef;

    private _data = null;
    /** cập nhật trạng thái của control */
    @Input('data')
    set data(val: any) {
        this._data = val;
        this.initial();
        // this.onReset();
    }
    get data() {
        return this._data;
    }

    /** Bao gồm: 'add' | 'edit' | 'view' */
    @Input() mode = 'view';

    private type = 'arcgis'; // Kiểu dịch vụ của attachment (arcgis, sql, postgre)

    constructor(
        private appService: AppService,
        private reqService: RequestService,
        private sanitizer: DomSanitizer,
        public translate: TranslateService
    ) {
        this.urlProxy = this.appService.urlProxy + '?';
    }

    ngOnInit() {
        this.fileList = [];
    }

    /** Phân loại kiểu dịch vụ attachment: SQL / ArcGIS / Postgrest ... */
    private initial() {
        if (this.data !== null) {
            switch (this.data.serviceType) {
                case 'SQL':
                // case 'AutoData':
                case 'CloudData':
                    this.type = 'sql';
                    this.loadSqlOdataAttachmentList();
                    break;
                case 'FeatureServer':
                case 'MapServer':
                case 'WorkflowServices':
                    this.type = 'arcgis';
                    this.loadArcGISAttachmentList();
                    break;
                default:
                    this.type = 'sql';
                    this.loadSqlOdataAttachmentList();
                    break;
            }
        }
    }

    /** Tải tập tin lên server */
    public onUploadFile() {
        if (this.type === 'sql') {
            this.sqlOdataUploadFile();
        } else if (this.type === 'arcgis') {
            this.arcGISUploadFile();
        } else {
        }
    }

    /** Tải tập tin lên server sau khi insert một dữ liệu mới */
    public uploadFileAfterInsert(p: any) {
        const data = p.data;
        if (data) {
            this.data = data;
        }
        this.onUploadFile();
    }

    /** Thực hiện sau khi save hoặc xóa attachment thành công */
    private afterSave(type = 'insert') {
        // lưu lại baselist nếu là mode edit
        if (this.mode === 'edit') {
            this.baseList = this.appService.deepCloneObject(this.listAttachment);
        }
        if (type === 'insert') {
            this.appService.notification('Upload file thành công', 'info');
        } else if (type === 'update') {
            this.appService.notification('Cập nhật file thành công', 'info');
        }
        this.onReset();
        this.save.emit();
    }

    /** Đọc và lưu trữ file từ local vào hàng chờ bộ nhớ của máy */
    public readURL(event: Event) {
        const files = (event.target as HTMLInputElement).files;
        if (files) {
            if (files[0]) {
                const file = files[0];
                const size = file.size / 1024 / 1024; // unit in MB
                if (size < this.fileMaxSize) {
                    if (this.uploadNode !== undefined && this.uploadNode !== null) {
                        this.uploadNode.nativeElement.value = '';
                    }
                    this.checkChange = true;

                    const reader = new FileReader();
                    this.fileList.push(file);

                    reader.onload = (e: any) => {
                        this.listAttachment.push({
                            id: null,
                            contentType: file.type,
                            size: file.size,
                            name: file.name,
                            url: this.isImage(file.type) ? e.target.result : this.baseFileUrl,
                            descr: file.name
                        });
                    };

                    reader.readAsDataURL(file);
                } else {
                    this.appService.alert(this.appService.getMessage('0019') + this.fileMaxSize + ' MB!', 'error');
                }
            }
        }
    }

    /** Reset lại trạng thái của Attachment */
    public onReset() {
        this.fileList = [];
        this.listDeteleAttachment = [];
        this.checkChange = false;
        this.listAttachment = this.appService.deepCloneObject(this.baseList);
        if (this.mode === 'add') {
            return;
        }
        this.initial(); // load lại, query dữ liệu để làm mới
    }

    /** Mở file ảnh ở 1 tab trình duyệt mới hoặc download nếu không phải ảnh */
    public onOpenFile(item: any) {
        if (this.type === 'sql') {
            const url = this.appService.urlOdataAttachment + '/' + item.urlFile;
            window.open(url, '_blank');
        } else {
            window.open(item.url, '_blank');
        }
    }

    /** Xóa các ảnh attachment hiện hữu, nhưng chưa thực hiện xóa hẳn ở trên services */
    public removeAttachment(item: any) {
        if (this.mode === 'edit') {
            if (item.id !== null && item.id !== undefined) { // có id nghĩa là ảnh trên server, còn ảnh local sẽ không có id
                this.listDeteleAttachment.push(item);
            }
            const a = this.listAttachment.filter(fil => fil.id === null); // Tìm danh sách các file đang ở local
            const index = a.indexOf(item); // lấy vị trí của file trong danh sách để xóa file tương ứng ở fileList
            this.fileList = this.fileList.filter((fil, idx) => idx !== index);
            this.listAttachment = this.listAttachment.filter(fil => fil !== item);
            this.checkChange = true;
        } else {
            const a = this.listAttachment.filter(fil => fil.id === null); // Tìm danh sách các file đang ở local
            const index = a.indexOf(item); // lấy vị trí của file trong danh sách để xóa file tương ứng ở fileList
            this.fileList = this.fileList.filter((fil, idx) => idx !== index);
            this.listAttachment = this.listAttachment.filter(fil => fil !== item);
            this.checkChange = true;
        }

    }

    /** Kiểm tra xem kiểu dữ liệu có phải ảnh hay không */
    private isImage(type: string): boolean {
        if (type && type.split('/').length > 0) {
            if (type.split('/')[0] === 'image') {
                return true;
            }
        }
        return false;
    }

    /**** KHU VỰC CODE CỦA SQL ODATA *****/

    /** Load danh sách attachment theo dịch vụ của Sql Odata */
    private loadSqlOdataAttachmentList() {
        this.reqService.switchType('sql');
        this.clientId = this.appService.ClientId;
        this.appId = this.appService.c$['appId'];

        let urlRequest = '';
        // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
        const user = JSON.parse(this.appService.currentUser);
        if (user.schema && user.schema !== user.schemadefault) {
            // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
            this.reqService.switchType('clouddata');
            urlRequest = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
        } else {
            // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
            urlRequest = this.appService.urlWS + '/Attachs';
        }

        const where = [
            ['TableId', '=', this.data.tableId],
            ['RecordId', '=', Number(this.data.recordId)],
            ['ClientId', '=', this.clientId],
            ['ApplicationId', '=', this.appId],
        ];
        this.reqService.service.search({
            url: urlRequest,
            where
        }).subscribe(res => {
            this.baseList = [];
            this.listAttachment = [];
            if (res.success) {
                res.features.forEach(item => {
                    // Thêm proxy cho url để fix lỗi CORS
                    // Thay thế ký tự \ thành /
                    const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + item.UrlFile).replace(/\\/g, '/');
                    const baseFileUrl = this.baseFileUrl;

                    this.listAttachment.push({
                        id: item.AttachId,
                        contentType: item.FileType,
                        size: 0, // sql odata chưa trả về size của file
                        name: item.Description,
                        url: this.isImage(item.FileType) ? urlImage : baseFileUrl,
                        urlFile: item.UrlFile,
                        descr: item.AttachName ? item.AttachName : item.name
                    });
                });
                this.baseList = this.appService.deepCloneObject(this.listAttachment);
            }
        });
    }

    /** Upload file lên service của odata */
    private sqlOdataUploadFile() {
        const urlRequest = this.appService.urlOdataAttachment + '/odata/ProccessFile/PostAttactment';

        const listRequest: any[] = [];
        this.fileList.forEach(async item => {
            const form = new FormData();
            // const file_nen: any = this.appService.compressImage(item)
            const file_nen = this.isImage(item.type) ? await this.appService.compressImage(item) : item
            form.append('file', file_nen);
            form.append('clientID', this.clientId);
            form.append('appID', this.appId);
            form.append('tableID', this.data.tableId);
            form.append('recordID', this.data.recordId);
            listRequest.push(
                this.reqService.service.query({
                    url: urlRequest,
                    params: form,
                    method: 'POST',
                    contentType: 'unset',
                    proxy: this.urlProxy
                })
            );
        });

        if (listRequest.length > 0) {
            combineLatest(listRequest).subscribe(res => {
                // Sau khi tải tệp lên server thành công, cần insert dữ liệu vào table sql odata
                this.insertDataToSqlTable(res);
            }, err => {
                this.save.emit();
                this.appService.notification(this.appService.getMessage('0018'), 'error');
            });
        } else { // Trường hợp không upload file nào cả => check trường hợp delete
            this.deleteSqlOdataAttachment(false);
        }
    }

    /** Ghi dữ liệu lưu trữ đường dẫn tới tệp vào database odata */
    private insertDataToSqlTable(res: any[]) {
        let urlRequest = '';
        // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
        const user = JSON.parse(this.appService.currentUser);
        if (user.schema && user.schema !== user.schemadefault) {
            // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
            urlRequest = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
        } else {
            // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
            urlRequest = this.appService.urlWS + '/Attachs';
        }
        const list: any[] = [];
        const userId = JSON.parse(this.appService.currentUser).userid
        const userName = JSON.parse(this.appService.currentUser).username
        res.forEach((item, idx) => {
            const val = (item.model as string).replace(/\\/g, '/');
            const index = val.lastIndexOf('/');
            const fileName = val.substring(index + 1, val.length);
            const data = {
                // AttachId: null,
                // RecordId: this.data.recordId,
                // TableId: this.data.tableId,
                // UrlFile: val,
                // Description: fileName,
                // FileType: this.fileList[idx].type,
                // ClientId: this.clientId,
                // ApplicationId: this.appId,
                // UserId: userId,
                // Owner: userName
                RecordId: this.data.recordId,
                TableId: this.data.tableId,
                UrlFile: val,
                Description: fileName,
                FileType: this.fileList[idx].type,
                ClientId: this.clientId,
                ApplicationId: this.appId,
                AttachName: fileName,
                AttachGroup: null,
                AttachGroupParent: null,
                FileSize: Math.round(this.fileList[idx].size / 1024),
                // UserId: userId,
                // Owner: userName
            };
            list.push(this.reqService.service.insert({
                url: urlRequest,
                data,
                primaryKey: 'AttachId'
            }));
        });

        combineLatest(list).subscribe(response => {
            // Sau khi lưu xong thì => bắt đầu xóa
            this.deleteSqlOdataAttachment(true);
        });

    }

    /** Xóa dữ liệu SQL ODATA */
    private deleteSqlOdataAttachment(hasInsert = false) {
        let urlDelete = '';
        // ( Cập nhật Ngày 21/06/2023) chia làm 2 trường hợp: so sánnh sessionStorage
        const user = JSON.parse(this.appService.currentUser);
        if (user.schema && user.schema !== user.schemadefault) {
            // - nếu schema và schemadefault khác nhau thì dùng service của clouddata
            urlDelete = `${this.appService.urlAutoData}/${user.dbname}/data/${user.schema}/Attach`;
        } else {
            // - nếu schema và schemadefault giống nhau thì dùng this.appService.urlWS + '/Attachs'
            urlDelete = this.appService.urlWS + '/Attachs';
        }

        const list: any[] = [];
        this.listDeteleAttachment.forEach(item => {
            list.push(this.reqService.service.delete({
                url: urlDelete,
                data: { AttachId: item.id },
                primaryKey: 'AttachId'
            }));
        });

        if (list.length > 0) {
            combineLatest(list).subscribe(res => {
                // Sau khi xóa trong dữ liệu trong DB => xóa tiếp dữ liệu trên server
                const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
                const listServer: any[] = [];
                res.forEach((item, index) => {
                    listServer.push(this.reqService.service.delete({
                        url: urlServer + '?url=' + this.listDeteleAttachment[index].urlFile,
                    }));
                });

                try {
                    combineLatest(listServer).subscribe(resp => {
                        this.afterSave('update');
                    }, err => {
                        this.afterSave('nothing');
                    });
                } catch (error) {
                }
            });
        } else {
            if (hasInsert) {
                this.afterSave('insert');
            } else {
                this.afterSave('nothing');
            }
        }
    }

    /**** KHU VỰC CODE CỦA ARCGIS *****/

    /** Load danh sách attachment theo dịch vụ của ArcGIS */
    private async loadArcGISAttachmentList() {
        this.reqService.switchType('arcgis3x');
        const content: any = {
            f: 'json',
        };
        const url = this.data.url + '/' + this.data.recordId + '/attachments';
        this.reqService.service.query({
            url,
            params: content
        }).subscribe((resp) => {
            if (resp.data && resp.data.attachmentInfos) { // ông này là 4x
                this.baseList = [];
                this.listAttachment = [];
                const urlProxy = this.urlProxy;
                resp.data.attachmentInfos.forEach((item: any) => {
                    // Thêm proxy cho url để fix lỗi CORS
                    let urlImage = urlProxy + url + '/' + item.id;
                    this.listAttachment.push({
                        id: item.id,
                        contentType: item.contentType,
                        size: item.size,
                        name: item.name,
                        url: this.isImage(item.contentType) ? urlImage : this.baseFileUrl,
                        descr: item.AttachName ? item.AttachName : item.name
                    });
                });
                this.baseList = this.appService.deepCloneObject(this.listAttachment);

            } else if (resp.attachmentInfos) { // ông này là 3x
                this.baseList = [];
                this.listAttachment = [];
                const urlProxy = this.urlProxy;
                resp.attachmentInfos.forEach((item: any) => {
                    // Thêm proxy cho url để fix lỗi CORS
                    let urlImage = urlProxy + url + '/' + item.id;
                    this.listAttachment.push({
                        id: item.id,
                        contentType: item.contentType,
                        size: item.size,
                        name: item.name,
                        url: this.isImage(item.contentType) ? urlImage : this.baseFileUrl,
                        descr: item.AttachName ? item.AttachName : item.name
                    });
                });
                this.baseList = this.appService.deepCloneObject(this.listAttachment);
            }
        });
    }

    /** Upload file lên service của arcgis */
    private arcGISUploadFile() {
        loadModules([
            'esri/layers/FeatureLayer'
        ]).then(async ([
            FeatureLayer
        ]) => {
            let url = this.data.url;
            const featureLayer = new FeatureLayer(url);
            const listRequest: any[] = [];
            this.fileList.forEach(async item => {
                const form = new FormData();
                // const file_nen: any = this.appService.compressImage(item)
                const file_nen = this.isImage(item.type) ? await this.appService.compressImage(item) : item
                form.append('attachment', file_nen);
                listRequest.push(featureLayer.addAttachment(this.data.recordId, form));
            });
            if (listRequest.length > 0) {
                combineLatest(listRequest).subscribe(res => {
                    this.deleteArcGISAttachment(true);
                }, err => {
                    this.save.emit();
                    this.appService.notification(this.appService.getMessage('0018'), 'error');
                    if (err && err.message) {
                        this.appService.notification(err.message, 'error');
                    }
                });
            } else {
                this.deleteArcGISAttachment(false);
            }
        });
    }

    /** Xóa ảnh attachment trên services */
    private deleteArcGISAttachment(hasInsert = false) {
        loadModules([
            'esri/layers/FeatureLayer'
        ]).then(async ([
            FeatureLayer
        ]) => {
            const arrayCombine: any[] = [];
            let url = this.data.url;
            const featureLayer = new FeatureLayer(url);
            this.listDeteleAttachment.forEach(item => {
                arrayCombine.push(featureLayer.deleteAttachments(this.data.recordId, [item.id]));
            });

            if (arrayCombine.length > 0) {
                try {
                    combineLatest(arrayCombine).subscribe(resp => {
                        this.afterSave('update');
                    }, err => {
                        this.afterSave('nothing');
                    });
                } catch (error) {
                }
            } else {
                if (hasInsert) {
                    this.afterSave('insert');
                } else {
                    this.afterSave('nothing');
                }
            }
        });
    }
}
