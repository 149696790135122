<ng-container *ngIf="hasFormCtrl">
  <app-input [hasFormCtrl]='true' [labelName]="labelName" [showLabel]="isShowLabel" [required]="required"
    [formControl]="ctrl" titleToolTip="{{title_core}}" [disabledCtrl]="isDisabledCtrl" [isReadOnly]="isReadOnly"
    [maxlength]="maxLength" [isLabelLeft]="isLabelLeft" type='text-button' (iconClick)="onWriteClause()"
    [hasFormCtrl]='true' (blurEvent)="blurEvent.emit()" [hasButton]="hasButton" (btnCalcClick)="btnCalcClick.emit()">
  </app-input>
</ng-container>
<ng-container *ngIf="!hasFormCtrl">
  <app-input [hasFormCtrl]='false' [labelName]="labelName" [showLabel]="isShowLabel" [required]="required"
    [(valueModel)]="valueModel" titleToolTip="{{title_core}}" [disabledCtrl]="isDisabledCtrl" [isReadOnly]="isReadOnly"
    [maxlength]="maxLength" [isLabelLeft]="isLabelLeft" type='text-button' (iconClick)="onWriteClause()"
    [hasFormCtrl]='false' (blurEvent)="blurEvent.emit()" [hasButton]="hasButton" (btnCalcClick)="btnCalcClick.emit()">
  </app-input>
</ng-container>
<ng-template #tmpClause>
  <div class="row" style="padding-bottom: 10px; margin: 0;" *ngIf="mode === 'array'">
    <div class="col-md-12">
      <app-select [labelName]="'Data Type' | translate " [isLabelLeft]="true" [required]="true"
        [dataLookup]="dataColumnDisplay" displayField="DESCR" valueField="CODE" [hasFormCtrl]='false'
        [(valueModel)]="valueDatatype" [placeholder]="'Data Type' | translate" (valueChange)="onChangeDataType($event)">
      </app-select>
    </div>

  </div>

  <div [formGroup]="formCtrl">

    <div class="row" style="margin: 0; padding-bottom: 5px;">
      <div
        [ngClass]="{'col-md-3': mode === 'array', 'col-md-5': mode === 'json' || mode === 'orderby' || mode === 'location'}">
        {{(mode !==
        'orderby' ? 'Key' : 'Field') | translate}}<span style="color: red;">*</span></div>
      <div
        [ngClass]="{'col-md-3': mode === 'array', 'col-md-5': mode === 'json' || mode === 'orderby' || mode === 'location'}">
        {{(mode !==
        'orderby' ? 'Value' : 'Order Type') | translate}}</div>
      <div class="col-md-2" style="display: flex; align-items: center;" *ngIf="mode === 'array'">{{'Color' | translate}}
        <i class="pi pi-angle-double-down" *ngIf="!hasColor"
          style="font-size: 16px; padding-left: 5px; cursor: pointer;" (click)="hasColor = !hasColor"></i>
        <i class="pi pi-angle-double-up" *ngIf="hasColor" style="font-size: 16px; padding-left: 5px; cursor: pointer;"
          (click)="hasColor = !hasColor"></i>
      </div>
      <div class="col-md-3" style="display: flex; align-items: center;" *ngIf="mode === 'array'">{{'Tag' | translate}}
        <i class="pi pi-angle-double-down" *ngIf="!hasTag" style="font-size: 16px; padding-left: 5px; cursor: pointer;"
          (click)="hasTag = !hasTag"></i>
        <i class="pi pi-angle-double-up" *ngIf="hasTag" style="font-size: 16px; padding-left: 5px; cursor: pointer;"
          (click)="hasTag = !hasTag"></i>
      </div>
      <div [ngClass]="{'col-md-1': mode === 'array', 'col-md-2': mode === 'json' || mode === 'orderby'}"
        *ngIf="mode !== 'location'"><i class="pi pi-file"
          style="font-size: 20px; color: blue; padding-left: 10px; cursor: pointer;" (click)="onAddCtrl($event)"></i>
      </div>
    </div>
    <div class="row">
      <div [ngClass]="{'col-md-10': mode === 'location', 'col-md-12': mode !== 'location'}">
        <ng-container *ngFor="let item of arrCtrl; let i = index">
          <div class="row" style="padding-bottom: 10px; margin: 0;">
            <div
              [ngClass]="{'col-md-3': mode === 'array', 'col-md-5': mode === 'json' || mode === 'orderby', 'col-md-6': mode === 'location'}">

              <ng-container *ngIf="mode !== 'location'">
                <ng-container *ngIf="mode !== 'orderby' && !typeField">
                  <app-input [showLabel]="false" [isLabelLeft]="false" [formControlName]="item[0]" [required]="true">
                  </app-input>
                </ng-container>
                <ng-container *ngIf="mode === 'orderby'">
                  <app-select [labelName]="''" [isLabelLeft]="false" [formControlName]="item[0]" [dataLookup]="arrField"
                    [showLabel]="false" displayField="DESCR" valueField="CODE" [required]="true"></app-select>
                </ng-container>
                <ng-container *ngIf="mode !== 'orderby' && typeField">
                  <app-select [labelName]="''" [isLabelLeft]="false" [formControlName]="item[0]" [dataLookup]="arrProp"
                    [showLabel]="false" displayField="DESCR" valueField="CODE" [required]="true"></app-select>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="mode === 'location'">
                <app-input [showLabel]="false" [isLabelLeft]="false" [formControlName]="item[0]" [required]="true"
                  [disabledCtrl]=" true">
                </app-input>
              </ng-container>
            </div>
            <div
              [ngClass]="{'col-md-3': mode === 'array', 'col-md-5': mode === 'json' || mode === 'orderby', 'col-md-6': mode === 'location'}">
              <!-- <app-input [showLabel]="false" [isLabelLeft]="false" [formControlName]="item[1]" [required]="false">
                </app-input> -->
              <ng-container *ngIf="mode !== 'orderby'">
                <app-input [showLabel]="false" [isLabelLeft]="false" [formControlName]="item[1]"
                  [required]="mode === 'location' ? true : false">
                </app-input>
              </ng-container>
              <ng-container *ngIf="mode === 'orderby'">
                <app-select [labelName]="''" [isLabelLeft]="false" [formControlName]="item[1]"
                  [dataLookup]="arrOrderType" [showLabel]="false" displayField="DESCR" valueField="CODE"
                  [required]="false"></app-select>
              </ng-container>
            </div>
            <div class="col-md-2" style="display: flex; align-items: center;" *ngIf="mode === 'array'">
              <ng-container *ngIf="hasColor">
                <app-core-color-picker [required]="false" [formControlName]="item[2]" [isLabelLeft]='false'
                  [showLabel]="false">
                </app-core-color-picker>
              </ng-container>

            </div>
            <div class="col-md-3" style="display: flex; align-items: center;" *ngIf="mode === 'array'">
              <ng-container *ngIf="hasTag">
                <app-input [showLabel]="false" [isLabelLeft]="false" [formControlName]="item[3]" [required]="false">
                </app-input>
              </ng-container>

            </div>
            <div (click)="onDeleteCtrl(item)" style="display: flex; align-items: center;" *ngIf="mode !== 'location'"
              [ngClass]="{'col-md-1': mode === 'array', 'col-md-2': mode === 'json' || mode === 'orderby'}"><i
                class="pi pi-trash" style="font-size: 20px; color: red; padding-left: 10px; cursor: pointer;"></i>
            </div>

          </div>
        </ng-container>
      </div>
      <div class="col-md-2" *ngIf="mode === 'location'" style="padding-bottom: 10px; display: flex; align-items: center;">
        <!-- <div class="btn-location-class" (click) = "onClickGetLocation()">
          <i class="pi pi-map-marker"></i>
        </div> -->
      </div>
    </div>

    
    <div class="divBtnClass" style="padding: 0px; align-items: flex-end; display: flex; justify-content: flex-end; padding-top: 20px;">
      <app-button [labelName]="'Reset' | translate " style="margin-left: 5px;" (click)="onClearCtrl()"
        [typeButton]="'reset'">
      </app-button>
      <app-button [labelName]="'Ok'" style="margin-left: 5px;" (click)="onBuild()" [typeButton]="'save'">
      </app-button> 
      <div class="btn-location-class" (click) = "onClickGetLocation()" style="margin-left: 10px;" *ngIf = "mode === 'location'">
        <i class="pi pi-map-marker"></i>
      </div>
    </div>
  </div>




</ng-template>
<app-dialog-prime [isAppendBody]="true"></app-dialog-prime>