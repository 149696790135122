import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { parse } from 'zipson/lib';
import { Information } from '.';
import { RequestService, SearchResponse } from '../services/request.service';
import { CoreWindowService } from './core-window.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-core-window',
    templateUrl: './core-window.component.html',
    styleUrls: ['./core-window.component.scss'],
    providers: [RequestService, CoreWindowService]
})
export class CoreWindowComponent implements OnInit, OnDestroy {

    @Output() openFormAddWfList: EventEmitter<any> = new EventEmitter();
    @Output() resWindow: EventEmitter<any> = new EventEmitter();
    @Output() eventCalendar: EventEmitter<any> = new EventEmitter();
    @Output() evtReOrder: EventEmitter<any> = new EventEmitter();
    @Output() evtColConf: EventEmitter<any> = new EventEmitter();
    @Output() evtColResize: EventEmitter<any> = new EventEmitter();
    @Output() createDynamicEvent: EventEmitter<any> = new EventEmitter(); // dùng để emit các sự kiện cần tạo động
    /** Ném data của tab được click */
    @Output() dataTabClick: EventEmitter<any> = new EventEmitter();
    rowEmit: EventEmitter<any> = new EventEmitter();
    zoomToGraphic: EventEmitter<any> = new EventEmitter();
    toolClick: EventEmitter<any> = new EventEmitter();
    initWindowSuccess: EventEmitter<any> = new EventEmitter();
    closeDialogPanel: EventEmitter<any> = new EventEmitter();
    afterInsertUpdateSuccesss: EventEmitter<any> = new EventEmitter();

    @ViewChildren('dynamic') private tableList!: QueryList<any>;

    public mode = '';
    public resultList: any[] = [];
    public menuWhereClause: any = null;
    public dangform = 'N';
    public _currentData = null;
    public isLoading = false;
    public tabId: any = null;
    public dataNotify: any = null;

    // các biến sử dụng để ngăn chặn hàm showHideTab khi chuyển tab khác
    private lookupTab: any = null;
    private layoutConfig: any = null;

    @Input() isReorder: boolean = false;
    private _isWfApp = false;
    /** Đánh dấu Form là dùng cho ứng dụng workflow hay không. Giá trị mặc định là false */
    @Input('isWfApp')
    get isWfApp(): any { return this._isWfApp; }
    set isWfApp(isWfApp: any) {
        this._isWfApp = isWfApp;
    }

    private _whereWf = null;
    /** Đánh dấu Form là dùng cho ứng dụng workflow hay không. Giá trị mặc định là false */
    @Input('whereWf')
    get whereWf(): any { return this._whereWf; }
    set whereWf(whereWf: any) {
        this._whereWf = whereWf;
    }

    private _isWdChiTiet = false;
    /** Đánh dấu Form là dùng cho ứng dụng xem Lịch sử hay không. Giá trị mặc định là false */
    @Input('isWdChiTiet')
    get isWdChiTiet(): any { return this._isWdChiTiet; }
    set isWdChiTiet(isWdChiTiet: any) {
        this._isWdChiTiet = isWdChiTiet;
    }

    // Biến select thửa trên bản đồ
    private _whereThua = [];
    get whereThua(): any { return this._whereThua; }
    set whereThua(whereThua: any) {
        this._whereThua = whereThua;
        this.loadThua();
    }

    private _isMapApp = false;
    /** Đánh dấu Form là dùng cho ứng dụng map hay không. Giá trị mặc định là true */
    @Input('isMapApp')
    get isMapApp(): any { return this._isMapApp; }
    set isMapApp(isMapApp: any) {
        this._isMapApp = isMapApp;
    }

    private _isWizard = false;
    /** Mode Winzard */
    @Input('isWizard')
    get isWizard(): any { return this._isWizard; }
    set isWizard(isWizard: any) {
        this._isWizard = isWizard;
    }
    @Input() isFilter = false;

    private _isActive = true;
    /** Kiểm tra điều kiện đóng/mở form */
    @Input('isActive')
    get isActive(): any { return this._isActive; }
    set isActive(isActive: any) {
        this._isActive = isActive;
    }

    private _windowId = null;
    public dataSource: any = [];
    /** Lưu giữ config của window sau khi gọi services */
    @Input('windowId')
    get windowId(): any { return this._windowId; }
    set windowId(windowId: any) {
        this.dataSource = [];
        this._windowId = windowId;
        if (this._windowId !== undefined && this._windowId !== null) {
            this.initData();
        }
    }

    @Input() isShowBtnChoose = false;
    private _isLayout = false;
    /** Mode Winzard */
    @Input('isLayout')
    get isLayout(): any { return this._isLayout; }
    set isLayout(isLayout: any) {
        this._isLayout = isLayout;
    }

    private _windowConfig = null;
    /** Lưu giữ config của window sau khi gọi services */
    get windowConfig(): any { return this._windowConfig; }
    set windowConfig(windowConfig: any) {
        this._windowConfig = windowConfig;
    }

    // for config column in wizard
    @Input() arrField: any = [];

    constructor(
        private appService: AppService,
        private reqService: RequestService,
        private coreWindowService: CoreWindowService,
        private cd: ChangeDetectorRef,
        private translate: TranslateService
    ) { }


    handleLangChange: any = null;
    ngOnInit(): void {
        this.handleLangChange = this.translate.onLangChange.subscribe(res => {
        });
    }


    ngOnDestroy(): void {
        if (this.handleLangChange) {
            this.handleLangChange.unsubscribe();
        }
    }


    public initData() {
        let url = `${this.appService.urlWS}/SysCaches/SysCacheWindow/${this.windowId}`;
        // For mode Winzard or Layout
        if (this.isWizard || this.isLayout) {
            url = `${this.appService.urlWS}/SysCaches/GetSysCacheWindowLayout/${this.windowId}`;
        }
        const params = {};
        this.isLoading = true;
        this.reqService.service.query({ url, params, responseType: 'json' }).subscribe(res => {
            // console.log(res);
            if (res.success) {
                this.windowConfig = res.model.config ? parse(res.model.config) : null;
                try {
                    this.layoutConfig = res.model.layout ? parse(res.model.layout) : null;
                } catch (error) {
                    this.layoutConfig = null;
                }
                this.resWindow.emit(this.windowConfig);
                this.initWindow();
            }
        }, err => {
            this.resWindow.emit(null);
            this.isLoading = false;
        });
    }

    public run(data: any = null) {
        this.coreWindowService.data = data; // Gán dữ liệu để Thủy có thể chạy tool ở SASSET
        if (data && data.windowId) {
            this.windowId = data.windowId;
        }
    }

    initDataNotify() {
        Object.keys(this.coreWindowService.lookupTab).forEach(key => {
            const information = this.coreWindowService.lookupTab[key].INFORMATION;
            if (this.dataNotify && information.TABLE_ID === this.dataNotify['TableId']) {
                information.WHERE = [information.KHOA_CHINH, '=', this.dataNotify['RecordId']];
                this.coreWindowService.lookupTab[key].WHERE = information.WHERE;
            }
        });
    }

    onColReoreder(evt: any) {
        this.evtReOrder.emit(evt)
    }

    onEvtColConf(evt: any) {
        this.evtColConf.emit(evt);
    }

    onColResize(evt: any) {
        this.evtColResize.emit(evt)
    }

    private initWindow() {
        if (this.windowConfig) {
            const wdConfig = this.reqService.mapConfig(this.windowConfig);
            if (wdConfig.error) {
                this.appService.alert(wdConfig.error, 'error');
                this.isLoading = false;
                return;
            }
            this.lookupTab = {}; 0
            console.log(wdConfig.tabs)
            this.dataSource = this.buildTabs(wdConfig.tabs, true);
            this.coreWindowService.lookupTab = this.lookupTab;
            if (this.isFilter || this.isWizard) {
                this.clickTab(this.dataSource[0], '');
            }
            this.cd.detectChanges(); // Dùng để tránh thông báo error
            // tinh chỉnh CHILDREN
            Object.keys(this.lookupTab).forEach(key => {
                if (this.lookupTab[key].INFORMATION && this.lookupTab[key].INFORMATION.PARENT_ID) {
                    if (this.lookupTab[this.lookupTab[key].INFORMATION.PARENT_ID]) {
                        this.lookupTab[this.lookupTab[key].INFORMATION.PARENT_ID].CHILDREN.push(this.lookupTab[key]);
                    }
                }
            });

            if (!this.isWizard) { // Emit để dành cho trường hợp query đồ họa
                this.initWindowSuccess.emit();
            }
        } else {
            this.appService.notification('Window not found! Please check the configuration!', 'info');
            // this.appService.notification('No window found! Please check config!', 'info');
        }
        this.isLoading = false
    }

    private buildTabs(list_tabs: Array<any>, isRoot = false) {
        const list: any[] = [];
        const tabs: any[] = list_tabs.filter((v: any) => v.iscollapse !== 'Y')
        tabs.forEach((tab, index) => {
            let listPer = this.appService.appConfig?.FunctionPermistion?.filter(
                (fil: any) => fil.tableid === tab.tableid
            );
            if (this.appService.appConfigAppId0?.FunctionPermistion) {
                const listPer0 = this.appService.appConfigAppId0?.FunctionPermistion?.filter(
                    (fil: any) => fil.tableid === tab.tableid
                );
                if (listPer && listPer0) {
                    listPer = listPer.concat(listPer0);
                } else {
                    listPer = listPer0;
                }
            }
            if (listPer && listPer.length > 0) {
                const a = listPer.filter((fil: any) => fil.functioncode === 'VIEW');
                if (a && a.length > 0) {
                    return;
                }
            }

            let where = tab.whereclause ? JSON.parse(tab.whereclause) : [];
            if (isRoot && index === 0 && this.menuWhereClause) {
                const menuWhereClause = JSON.parse(this.menuWhereClause);
                if (where.length > 0) {
                    where = [
                        'and',
                        where,
                        menuWhereClause
                    ];
                } else {
                    where = menuWhereClause;
                }
            }
            // các biến chưa sử dụng: tablelienket, layoutcolumn, iscache, iscollapse, columndisplay
            const information: Information = {
                TAB_ID: tab.tabid,
                PARENT_ID: tab.parenttabid,
                TABLE_ID: tab.tableid,
                DESCR: tab.tabname,
                TAB_LEVEL: tab.tablevel !== null ? tab.tablevel.toString() : '0',
                TAB_MAX_LEVEL: tab.maxLevel,
                TABLE_DETAIL: tab.tabledetail,
                SEQ_NO: tab.orderno ? tab.orderno.toString() : null,
                DEFAULT_WHERE: where,
                IS_LIENKETDOHOA: 'N',
                IS_SINGLE_LINE_EDIT: tab.issingglelineedit,
                IS_TAB_TRUNG_GIAN: tab.istabtrunggian === 'Y',
                IS_REFRESH_PARENT_TAB: tab.isrefreshtabparrent === 'Y',
                KHOA_CHINH: tab.columnkey,
                TRUONG_LK_CON: tab.truonglienketcon,
                TRUONG_LK_CHA: tab.truonglienketcha,
                TRUONG_LKTG_CON: tab.truongtrunggiancon,
                TRUONG_LKTG_CHA: tab.truongtrunggiancha,
                BANG_CHA: tab.banglienket,
                BANG_LK: tab.bangtrunggian,
                KHOA_CHINH_BANG_LK: tab.columnkeytrunggian,
                WHERE: (tab.tablevel === 0 && tab.orderno === 1 || tab.parenttabid === null) ? [] : null,
                TABLE_NAME: tab.tablename,
                URL_EDIT: tab.urledit,
                URL_VIEW: tab.urlview,
                URL_EDIT_MAP: tab.urleditdohoa,
                URL_VIEW_MAP: tab.urlviewdohoa,
                LAYOUT_COLUMN: tab.layoutcolumn,
                PARENT_DATA: null,
                SERVICE_TYPE: tab.tabletype,
                WINDOW_ID: this.windowId,
                FILTER_FIELD: tab.fillterfield,
                FILTER_DEFAULT_VALUE: tab.fillterdefaultvalue,
                ORDER_BY: tab.orderbyclause ?? null,
                INIT_SHOW: tab.initshow === 'Y',
                ONCHANGE: tab.onchange,
                COLUMN_DOHOA: tab.columndohoa, // chưa thấy sử dụng
                COLUMN_CODE: tab.columncode, // Sử dụng để filter nhanh
                COLUMN_DISPLAY: tab.columndisplay,
                TABLEWORKFLOWID: tab.tableworkflowId,
                JOBTYPEIDS: tab.jobtypeids,
                LAYERINDEX: tab.layerindex,
                HASATTACHMENT: tab.hasattachment,
                KVHC_COLUMN: tab.kvhccolumn,
                ColumnWorkflow: tab.columnworkflow,
                ColumnLinkLayer: tab.columnlinklayer,
                COLUMN_KEY: tab.columnkey,
                COLUMN_LOCK: tab.columnlock,
                ARCHIVE_TYPE: tab.logtype,
                NOT_SEND: tab.fieldautodata === 'Y' // Nếu bằng 'Y' thì không gửi tham số này khi thêm hoặc sửa
            };

            if (tab.description && tab.description !== '') {
                try {
                    const descr = JSON.parse(tab.description);
                    const text = descr[this.translate.currentLang];
                    information.DESCR = text;
                } catch (error) {
                    information.DESCR = tab.description;
                }
            }

            if (this.dataNotify && information.TABLE_ID === this.dataNotify['TableId']) {
                information.WHERE = [information.KHOA_CHINH, '=', this.dataNotify['RecordId']];
            }

            if (this.isWfApp) {
                information.WHERE = information.TAB_LEVEL === '0' && information.SEQ_NO === '1' ? this.whereWf : null;
            }
            // get layout config
            let layout = null;
            if (this.layoutConfig !== null && this.layoutConfig !== undefined) {
                this.layoutConfig.components.forEach((comp: any) => {
                    const tabid = comp.TabID == null? comp.tabID : comp.TabID;
                    if (tabid.toString() === information.TAB_ID.toString()) {
                        layout = comp;
                    }
                });
            }

            const childTabs = this.buildTabs(tab.tabs);
            const child = [];
            for (let i = tab.tablevel; i < tab.maxLevel; i++) {
                child.push(childTabs.filter(fil => fil.INFORMATION.TAB_LEVEL === (i + 1).toString()));
            }

            const item = {
                FIELD_LIST: tab.fields,
                LAYOUT_CONFIG: layout,
                INFORMATION: information,
                HIDE: false,
                TABS: child,
                CHILDREN: [],
                WHERE: information.WHERE
            };

            list.push(item);

            this.lookupTab[tab.tabid] = item;
        });

        return list;
    }

    private loadThua() {
        if (this.dataSource.length > 0) {
            const rootTable = this.tableList.get(0);
            if (rootTable) {
                rootTable.pageIndex = 1;
            }
            this.dataSource[0].WHERE = this.whereThua;
        }
    }

    // Load lại bản đồ, được gọi từ GIS module
    public reloadTableFromGISModule(evt: any, timeout: number = 0) {
        this.cd.detectChanges();
        setTimeout(() => {
            const rootTable = this.tableList.get(0);
            if (rootTable) {
                rootTable.reloadTableAndSelectFeature(evt);
            }
        }, timeout);
    }

    public reloadTable() {
        this.cd.detectChanges();
        setTimeout(() => {
            const rootTable = this.tableList.get(0);
            if (rootTable) {
                rootTable.onReloadTable();
            }
        }, 100);
    }


    clickTab(tab: any, evt: any) {
        this.tabId = tab.INFORMATION.TAB_ID;
        this.dataTabClick.emit(tab);
    }

    public showHideTab(tab: any) {
        tab.HIDE = !tab.HIDE;
    }

    public onEventEmit(event: any, tab: any) {
        switch (event.type) {
            case 'rowClick':
            case 'rowDblClick':
                this.onRowClick(event.value, tab, event);
                break;
            case 'rowClickNoDraw':
                this.onRowClick(event.value, tab, event, false);
                break;
            case 'toolClick':
                this.onToolClick(event.value, tab);
                break;
            case 'insertCustomize':
                this.onInsertCustomize(tab);
                break;
            // Case này là gì nhỉ???
            case 'rowEmit':
                this.rowEmit.emit(event.value);
                break;
            case 'closeDialog':
                this.closeDialogPanel.emit();
                break;
            case 'insertGeometry':
                // Emit sự kiện xóa geometry
                this.afterInsertUpdateSuccesss.emit();
                break;
            case 'zoom':
                // Emit sự kiện zoom tới bản đồ
                this.zoomToGraphic.emit(event);
                break;
            case 'search-by-graphic':
                this.toolClick.emit({
                    tool: 'search-by-graphic',
                    event
                });
                break;
            case 'menu-tool-click':
            case 'on-change-in-table':
            case 'menu-tool-click-window-customize':
                this.createDynamicEvent.emit(event);
                break;
            case 'reload-parent-tab':
                this.reloadParentTab(tab);
                break;
            default:
                break;
        }
    }

    private reloadParentTab(tab: any) {
        const parentTab: any = this.coreWindowService.findParentTab(tab.INFORMATION.TAB_ID);
        if (parentTab) {
            this.tableList.forEach(item => {
                if (item.dataSource.INFORMATION.TAB_ID === parentTab.INFORMATION.TAB_ID) {
                    item.onReloadTable();
                }
            });
        }
    }

    /** Emit sự kiện add calendar */
    public onEventAddCalendar(data: any) {
        this.eventCalendar.emit(data);
    }

    /** Xử lý sự kiện chọn dữ liệu ở tab cha thì tạo ra câu điều kiện where cho tab con */
    private onRowClick(row: any, tab: any, evt: any, emitEvent = true) {
        if (tab.INFORMATION.SEQ_NO === '1' && tab.INFORMATION.TAB_LEVEL === '0') {
            this._currentData = row;
        }

        tab.INFORMATION.CURRENT_DATA = row;

        // Đây là danh sách các tab con
        const tabList: any[] = tab.CHILDREN;
        if (tabList.length > 0) {
            tabList.forEach(child => {
                if (row !== null && row !== undefined) {
                    child.isParentLock = evt.isLock
                    this.getWhere(child, row);
                } else {
                    child.isParentLock = false
                    child.INFORMATION.PARENT_DATA = null;
                    child.WHERE = null;
                }
            });
        }

        if (this.isMapApp) {
            // Nếu dùng trên ứng dụng bản đồ => emit row để check dữ liệu
            // Hoặc nếu hiển thị nút chọn => emit row để gắn dữ liệu ở control filter-input
            // mặc định emit rowEmit, nhưng khi query bằng đồ họa thì không emit
            if (emitEvent) {
                this.rowEmit.emit({ row, tab });
            }
        }
    }

    /** Tab: là tab con hiện hành, rowData: là dữ liệu tab cha */
    private getWhere(tab: any, rowData: any) {
        const infor: Information = tab.INFORMATION;
        infor.PARENT_DATA = rowData;
        const val = rowData[infor.TRUONG_LK_CHA];
        let where: any = [];
        if (infor.IS_TAB_TRUNG_GIAN) { // trường hợp tab này là tab trung gian (phụ thuộc vào dữ liệu của 2 tab khác)
            where.push([infor.TRUONG_LK_CON, '=', val]); // điều kiện tiên quyết 1
            const parentTab: any = this.coreWindowService.findParentTab(infor.TAB_ID); // Lấy tab bố
            if (parentTab && parentTab.INFORMATION) {
                where.push([infor.TRUONG_LKTG_CON, '=', parentTab.INFORMATION.PARENT_DATA[infor.TRUONG_LKTG_CHA]]); // Điều kiện tiên quyết thứ 2
            }
            tab.WHERE = where;
            return;
        }

        if (infor.BANG_LK === null || infor.BANG_LK === undefined) { // Liên hệ cha con là một - nhiều
            where = [[infor.TRUONG_LK_CON, '=', val]];
            if (tab.INFORMATION.DEFAULT_WHERE && tab.INFORMATION.DEFAULT_WHERE.length > 0) {
                // if (tab.INFORMATION.DEFAULT_WHERE[0] !== 'and' && tab.INFORMATION.DEFAULT_WHERE[0] !== 'or') {

                //     where = where.concat(tab.INFORMATION.DEFAULT_WHERE);
                // } else {


                // }
                where = ['and', where, tab.INFORMATION.DEFAULT_WHERE];
                tab.WHERE = where;
            } else {
                tab.WHERE = where;
            }

            return;
        }

        // Trường hợp còn lại: Quan hệ nhiều - nhiều thông qua bảng trung gian
        const whereClause = [infor.TRUONG_LKTG_CHA, '=', val];

        switch (infor.SERVICE_TYPE) {
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            // case 'AutoData':
            //     this.reqService.switchType('autodata');
            //     break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
            case 'WorkflowServices':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgrest':
                this.reqService.switchType('postgre');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }
        this.reqService.service.search({
            url: tab.INFORMATION.BANG_LK,
            where: whereClause,
            logic: 'and'
        }).subscribe((res: SearchResponse) => {
            this.reqService.switchType(this.appService.dataAccessType);
            let array: any[] = [];
            // gián tiếp
            res.features.forEach(item => {
                if (item[infor.TRUONG_LKTG_CON] !== null) {
                    array.push(item[infor.TRUONG_LKTG_CON]);
                }
            });

            if (array.length > 0) {
                array = this.appService.removeDuplicateValue(array);
                where.push([infor.TRUONG_LK_CON, 'in', array]);
            } else {
                where = null;
            }

            tab.INFORMATION.WHERE = where;
            tab.WHERE = where;
        }, err => {
            this.reqService.switchType(this.appService.dataAccessType);
        });
    }

    // Nhận sự kiện mở hộp thoại thêm hoặc tìm kiếm
    private onToolClick(tool: any, tab: any) {
        this.toolClick.emit({ tool, tab });
    }

    private onInsertCustomize(tab: any) {
        const tabChildren = tab.CHILDREN[0];
        tabChildren.INSERT_NEW = { openInsert: true };
    }
}
