<div class="stiFormCoreTech">
  <div class="stiFormCoreTech__Formsearch">
    <input (click)="clickInputEvent($event)" (blur)="blurInputEvent($event)" [(ngModel)]="valueInput"
      (keyup)="onKeyChangeDataService($event)" class="coreTechServiceTextBox" placeholder="{{ 'Find' | translate }}">
  </div>
  <div class="stiFormCoreTech__Content">
    <ng-container *ngFor="let service of dataService; let i=index">
      <div class="stiFormCoreTechBlock">
        <div class="stiFormCorTechBlockHeader">
          <div style="padding: 6px 6px 6px 15px;"><b>{{service.serviceName}}<b></b></b></div>
        </div>
        <div class="stiFormCorTechBlocContainer">
          <ng-container *ngFor="let table of service.sysTables; let k=index">
            <div [ngClass]="{'stiFormCorTechButtonSelected': table.tableId === currentTableId}"
              class="stiFormCorTechButton" (click)="openTable(table)" title="table.alias">
              <table>
                <tr>
                  <td class="stiFormCorTechButton-Image">
                    <img [src]="service.imgServiceType">
                  </td>
                  <td class="stiFormCorTechButton-Name">{{table.alias}}</td>
                  <td class="stiFormCorTechButton-Space">
                    <img [src]="service.imgSpace">
                  </td>
                </tr>
              </table>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>