<ng-container *ngIf="tabs.length > 0">
    <div class="tabs-prime-container div-coretab-class">
        <p-tabView #tabView [scrollable]="true" (onClose) = "onCloseTab($event)" [(activeIndex)]="activeTabIndex" (onChange) = "onChangeTab($event)" styleClass = 'core-tab-class'>
            <ng-container *ngFor="let item of tabs">
                <p-tabPanel [disabled]="item[fieldDisabled] || isDisabledTab" [closable] = "item[keyIsClose]"
                    [headerStyleClass]=' item[fieldDisabled] ?"tab-disabled-class": "tab-active-class" '>
                    <ng-template pTemplate="header">
                        <ng-container *ngTemplateOutlet="title; context: { $implicit: item }"></ng-container>
                    </ng-template>
                    <ng-container *ngIf="isLazyLoadContent">
                        <ng-template pTemplate="content">
                            <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="!isLazyLoadContent">
                        <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
                    </ng-container>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>
</ng-container>