import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RequestService } from "app/core/services/request.service";
import { AppService } from "../app.service";

@Injectable()
export class SignupService {
  public tableName = 'SysDangKys';

  constructor(
    private appService: AppService,
    private reqService: RequestService,
  ) { }

  public addUser(params: any) {
    const odataParams = {
      url: `${this.appService.urlRegister}/${this.tableName}`,
      primaryKey: 'UserId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }

  checkCapcha(key: string) {
    const url = `${this.appService.urlRecapcha}?key=${key}`
    this.reqService.switchType(this.appService.dataAccessType);
    return this.reqService.service.query({
      url,
      params: null,
      method: 'POST'
    })
  }

  queryUserForEmaiAndPhone(params: any) {
    const resp = this.reqService.service.search({
      url: `${this.appService.urlWS}/${this.tableName}`,
      where: params,
      logic: 'and'
    });
    return resp;
  }

}
