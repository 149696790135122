<div class="d-flex" [ngClass]="{'NoLabelLeft': !isLabelLeft, 'textAreaControl': type === 'text-area'}"
  style="align-items: center;">
  <div *ngIf="showLabel" class="labelInputClass" [style.width.px]="labelWidth" [ngClass] = "{'label-readonly': isReadOnly}"
    style="min-width: 80px; align-items: center; max-width: 200px;">
    <ng-container *ngIf="notUseTranslate">
      {{labelName || "No title" }}
    </ng-container>
    <ng-container *ngIf="!notUseTranslate">
      {{labelName || "No title" | translate }}
    </ng-container>
    <span *ngIf="required" [style.color]="'red'">&nbsp;*</span>
    <i *ngIf="infoHelp" class="infoHelpIcon pi pi-info-circle" [pTooltip]="infoHelpContent"></i>
  </div>

  <div style="width: 100%; display: flex; align-items: center;" class="div-ctrl-class"
    [ngStyle]="{'max-width': isLabelLeft && showLabel ? 'calc(100% - 120px)' : '100%' }">
    <ng-container *ngIf="hasFormCtrl">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'number'">
          <p-inputNumber [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}" [formControl]="ctrl"
            mode="decimal" [minFractionDigits]="minFraction" [step]='step' [maxFractionDigits]="maxFraction"
            [ariaRequired]="required" (onBlur)='onBlurEvent($event)' [placeholder]="placeholder" [showButtons]="true"
            [readonly]="disabledCtrl" [pattern]="pattern" [disabled]="!disabledCtrl && isReadOnly"
            (onInput)='onInputNumberEnterEvent($event)' [style]="styleEle" [maxlength]="_maxLength">
          </p-inputNumber>
        </ng-container>
        <ng-container *ngSwitchCase="'text-button'">
          <div class="p-inputgroup">
            <input type="text" pInputText (click)="onClickEvent($event)" placeholder="Keyword" [formControl]="ctrl"
              [placeholder]="placeholder" [required]="required" [maxlength]="_maxLength " [pattern]="pattern"
              (keydown)='onEnterEvent($event)' [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly"
              (blur)='onBlurEvent($event)' [ngClass]="{'core-btn-required': requiredClass, 'input-readonly': isReadOnly}"
              style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; width: calc(100% - 30px);">
            <button type="button" style="height: 30px; width: 30px;" pButton pRipple
              [disabled]='(disabledCtrl || isReadOnly) && !isOpenLink' icon="pi pi-{{typeIcon}}" [title]="titleToolTip"
              [ngClass]="{'btn-required': requiredClass}" styleClass="p-button-warn" (click)="onClickIcon()"></button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'password'">
          <p-password [feedback]="false" [toggleMask]="true" [formControl]="ctrl" [placeholder]="placeholder"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right"
            [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}"></p-password>
        </ng-container>
        <ng-container *ngSwitchCase="'text-area'">
          <textarea (click)="onClickEvent($event)"
            [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}" pInputTextarea [style]="styleEle"
            [formControl]="ctrl" [placeholder]="placeholder" [rows]="rowTxtArea" [readonly]="disabledCtrl"
            [disabled]="!disabledCtrl && isReadOnly" (keydown)='onEnterEvent($event)' [pattern]="pattern"
            (blur)='onBlurEvent($event)'></textarea>
        </ng-container>
        <ng-container *ngSwitchCase="'input-tree'">
          <div class="p-inputgroup">
            <div style="width: 35%; display: flex; align-items: center;">
              <!-- <input type="text" pInputText [formControl]="ctrl" [placeholder]="placeholder"
                                [required]="required" [maxlength]="_maxLength "
                                [ngClass]="{'core-btn-required': requiredClass}"
                                style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; width: calc(100% - 30px);"> -->
              <div style="width: calc(100% - 30px);" [ngClass]="{'core-btn-required': requiredClass}">
                <p-autoComplete [formControl]="ctrl" [suggestions]="results" [appendTo]="'body'"
                  [showEmptyMessage]="true" (completeMethod)="search($event)" field="code" [forceSelection]="false"
                  [required]="required" [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly"
                  [placeholder]="placeholder" (onSelect)="onDropdownClick($event)">
                  <ng-template let-res pTemplate="item">
                    <div style="display: flex; align-items: center;">
                      <span>{{res.name}}</span>
                      <span style="padding-left: 5px; color: var(--color_text); font-style: italic;">{{res.code}}</span>
                    </div>

                  </ng-template>
                </p-autoComplete>
              </div>

              <button type="button" style="height: 30px; width: 30px; border-radius: 0px 3px 3px 0px;" pButton pRipple
                icon="pi pi-sitemap" [ngClass]="{'btn-required': requiredClass}" styleClass="p-button-warn"
                (click)="onClickOpenDialog()" [disabled]='disabledCtrl || isReadOnly'></button>
            </div>

            <div style="width: 65%; display: flex; align-items: center; padding-left: 0;" class="div-ten-class">
              <ng-container *ngIf="hasButton">
                <button pButton pRipple type="button" icon="pi pi-plus" (click)="btnCalcClick.emit()"
                  style="height: 30px;width: 30px;"></button>
              </ng-container>
              <span *ngIf="val_ten" style="padding-left: 5px;">
                {{val_ten}}
              </span>

            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'input-editor'">
          <p-editor [formControl]="ctrl" (keydown)='onEnterEvent($event)' (blur)='onBlurEvent($event)'
            [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}"
            [readonly]="disabledCtrl || isReadOnly" [modules]="{ toolbar: toolbarOptions }" [style]="{'height':'200px'}"
            (click)="onClickEvent($event)"></p-editor>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}" type="text"
            [formControl]="ctrl" [required]="required" [placeholder]="placeholder" [readonly]="disabledCtrl"
            [disabled]="!disabledCtrl && isReadOnly" [maxlength]="_maxLength" [pattern]="pattern"
            (keydown)='onEnterEvent($event)' (blur)='onBlurEvent($event)' pInputText [style]="styleEle"
            (click)="onClickEvent($event)" />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="hasButton && type !== 'input-tree'">
        <button pButton pRipple type="button" icon="pi {{iconBtn}}" style="height: 30px;width: 30px;"
          (click)="btnCalcClick.emit()"></button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!hasFormCtrl">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'number'">
          <p-inputNumber [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}"
            [minFractionDigits]="minFraction" [step]='step' [maxFractionDigits]="maxFraction"
            [(ngModel)]="valueModel" [ariaRequired]="required" (onBlur)='onBlurEvent($event)' [pattern]="pattern"
            [showButtons]="true" (onInput)='onInputNumberEnterEvent($event)' [maxlength]="_maxLength"
            [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly">
          </p-inputNumber>
        </ng-container>
        <ng-container *ngSwitchCase="'text-button'">
          <div class="p-inputgroup">
            <input type="text" pInputText (click)="onClickEvent($event)" placeholder="Keyword" [(ngModel)]="valueModel"
              [placeholder]="placeholder" [required]="required" [maxlength]="_maxLength "
              (keydown)='onEnterEvent($event)' (blur)='onBlurEvent($event)' [readonly]="disabledCtrl"
              [disabled]="!disabledCtrl && isReadOnly" [ngClass]="{'core-btn-required': requiredClass, 'input-readonly': isReadOnly}"
              style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; width: calc(100% - 30px);">
            <button type="button" style="height: 30px; width: 30px;" pButton pRipple
              [disabled]='disabledCtrl || isReadOnly' icon="pi pi-{{typeIcon}}"
              [ngClass]="{'btn-required': requiredClass}" styleClass="p-button-warn" (click)="onClickIcon()"></button>

          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'password'">
          <p-password [feedback]="false" [toggleMask]="true" [(ngModel)]="valueModel" [placeholder]="placeholder"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right"
            [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}"></p-password>
        </ng-container>
        <ng-container *ngSwitchCase="'text-area'">
          <textarea (click)="onClickEvent($event)"
            [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}" pInputTextarea
            [(ngModel)]="valueModel" [placeholder]="placeholder" [readonly]="disabledCtrl" [pattern]="pattern"
            [disabled]="!disabledCtrl && isReadOnly" [rows]="rowTxtArea" (keydown)='onEnterEvent($event)'
            (blur)='onBlurEvent($event)'></textarea>
        </ng-container>
        <ng-container *ngSwitchCase="'input-editor'">
          <p-editor [(ngModel)]="valueModel" (keydown)='onEnterEvent($event)' (blur)='onBlurEvent($event)'
            [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}"
            [modules]="{ toolbar: toolbarOptions }" [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly"
            [placeholder]='placeholder' [style]="{'height':'200px'}" (click)="onClickEvent($event)">
          </p-editor>
        </ng-container>
        <ng-container *ngSwitchCase="'input-tree'">
          <div class="p-inputgroup input-tree-class">
            <div style="width: 35%; display: flex; align-items: center;">
              <!-- <input type="text" pInputText [formControl]="ctrl" [placeholder]="placeholder"
                                [required]="required" [maxlength]="_maxLength "
                                [ngClass]="{'core-btn-required': requiredClass}"
                                style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; width: calc(100% - 30px);"> -->
              <div style="width: calc(100% - 30px);" [ngClass]="{'core-btn-required': requiredClass}">
                <p-autoComplete [(ngModel)]="valueModel" [suggestions]="results" [appendTo]="'body'"
                  [showEmptyMessage]="true" (completeMethod)="search($event)" field="code" [required]="required"
                  [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly" [placeholder]="placeholder"
                  (onSelect)="onDropdownClick($event)">
                  <ng-template let-res pTemplate="item">
                    <div style="display: flex; align-items: center;">
                      <span>{{res.name}}</span>
                      <span style="padding-left: 5px; color: var(--color_text); font-style: italic;">{{res.code}}</span>
                    </div>

                  </ng-template>
                </p-autoComplete>
              </div>

              <button type="button" style="height: 30px; width: 30px; border-radius: 0px 3px 3px 0px;" pButton pRipple
                icon="pi pi-sitemap" [ngClass]="{'btn-required': requiredClass}" [disabled]='disabledCtrl || isReadOnly'
                styleClass="p-button-warn" (click)="onClickOpenDialog()"></button>
            </div>

            <div style="width: 65%; display: flex; align-items: center; padding-left: 0;" class="div-ten-class">
              <ng-container *ngIf="hasButton">
                <button pButton pRipple type="button" icon="pi pi-plus" (click)="btnCalcClick.emit()"
                  style="height: 30px;width: 30px;"></button>
              </ng-container>
              <span *ngIf="val_ten" style="padding-left: 5px;">
                {{val_ten}}
              </span>

            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input [ngClass]="{'core-required': requiredClass, 'input-readonly': isReadOnly}" type="text"
            [(ngModel)]="valueModel" [placeholder]="placeholder" [required]="required" [maxlength]="_maxLength"
            [readonly]="disabledCtrl" [disabled]="!disabledCtrl && isReadOnly" (keydown)='onEnterEvent($event)'
            (blur)='onBlurEvent($event)' pInputText [pattern]="pattern" (click)="onClickEvent($event)" />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="hasButton && type !== 'input-tree'">
        <button pButton pRipple type="button" icon="pi pi-plus" style="height: 30px;width: 30px;"
          (click)="btnCalcClick.emit()"></button>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #tmpTree>
  <app-core-tree [dataTree]="arrDataTree" [treeConfig]="treeConfig" (nodeExpandEvt)="onnodeExpandEvt($event)"
    (treeFilterEvt)="onTreeFilter($event)" (selectNode)="onNodeSelect($event)" [(nodeSelectedModel)]="currentSelect"
    [templateRef]="nzTreeTemplate" [hasTemplate]="true">
    <ng-template #nzTreeTemplate let-node="treenode">
      <div style="display: flex; align-items: center;">
        <label style="margin-bottom: 0;">{{node.label}}</label>
        <label
          style="margin-bottom: 0; color: var(--color_text); font-style: italic; padding-left: 5px;">{{node.key}}</label>
      </div>
    </ng-template>
  </app-core-tree>
</ng-template>
<app-dialog-prime [isAppendBody]="true" [heightPanel]="'60%'"></app-dialog-prime>