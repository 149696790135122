import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { loadCss, loadModules, setDefaultOptions } from 'esri-loader';
import { AppService } from './app-base/app.service';
import { ThemeService } from './core/themes/theme.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { PushNotificationService } from './notification/notification.service';

declare global {
  interface Window {
    [key: string]: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ClientApp';
    getLocationCompleted: Subject<any> = new Subject();
  constructor(
    private themeService: ThemeService,
    private titleService: Title,
    private appService: AppService,
    private http: HttpClient,
    private pushNotify: PushNotificationService
  ) { }

    async ngOnInit(): Promise<any> {
        this.titleService.setTitle('IzyBuilder');
        this.appService.c$ = {};
        this.appService.c$.getSeq = async (storeName: any) => {
            const str = 'SELECT NEXT VALUE FOR ' + storeName + ' AS SeqVal; '
            const res = await this.execProcedure({ command: str }).pipe(take(1)).toPromise()
            return res.features[0].SeqVal
        }
        this.appService.c$.here =
            async () => {
                this.getCurrentLocation()
                const obj = await this.getLocationCompleted.asObservable().pipe(take(1)).toPromise()
                return JSON.stringify(obj)
            }

        try {
            const res: any = await this.http.get('config.json').pipe(take(1)).toPromise();
            if (res) {
                this.appService.urlInit = res.urlInit;
                this.appService.urlInitQutation = res.wsUrlQutation;
                this.appService.urlReport = res.urlReport;
                this.appService.urlProxy = res.urlProxy;
                this.appService.dataAccessType = res.dataAccess;
                this.appService.urlGetTheme = res.wsUrl + '/SysThemes';
                this.appService.urlOdataAttachment = res.urlOdataAttachment;
                this.appService.urlAutoData = res.urlAutoData;
                this.appService.urlAutoDataSys = res.urlAutoData;
                this.appService.urlWS = res.wsUrl;
                this.appService.tempClientId = res.templateClientId;
                this.appService.urlWorkflow = res.urlWorkflow;
                this.appService.envGooglePay = res.environmentPaymentGooglePay;
                this.appService.urlWebdatarocks = res.langWebdatarock;
                this.appService.applicationServerPublicKey = res.applicationServerPublicKey;
                this.appService.urlRegister = res.urlRegister;
                this.appService.urlRecapcha = res.urlRecapcha;
            }
            console.log(res);
        } catch (error) {

        }
    window['_appService'] = this.appService;
    this.appService.c$.dateFormat = 'dd/mm/yy';
    this.appService.setNowDate();
    window['c$'] = this.appService.c$;
    await this.themeService.setActiveTheme(null);
    setDefaultOptions({
      version: '3.40'
    });
    loadCss('3.40');
    if (this.appService.currentUser) {
      // Load Push Notification
      this.pushNotify.init();
    }
  }

  execProcedure(params: any) {
    const schema = JSON.parse(this.appService.currentUser).schema;
    const dbName = JSON.parse(this.appService.currentUser).dbname;
    const url = this.appService.urlAutoData + '/' + dbName + '/script/' + schema
    let headers = new HttpHeaders({
      Accept: 'text/plain',
      'Content-Type': 'application/json'
    });

    const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
    if (currentUser && currentUser.token) {
      headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
    }

        return this.http.post(url, params, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            let resp: any = {};
            resp.total = res.numberRecords;
            resp.success = res.result;
            resp.features = res.datas ? res.datas : [];
            resp.message = res.message;
            return resp;
            // return res
        }), catchError(this.handleSearchError));
    }
    private handleSearchError(error: any) {
        return throwError(error);
    }
    getCurrentLocation() {
        // const [Point, projection] = await loadModules(["esri/geometry/Point", "esri/geometry/projection"])
        let obj = {}
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((point) => {
                // projection.load().then(() => {
                //     const p = new Point(point.coords.longitude, point.coords.latitude)
                //     const p_convert = projection.project(p, { wkid: 102100 })
                //     const obj = {
                //         x: p_convert.x,
                //         y: p_convert.y,
                //     }
                //     this.getLocationCompleted.next(obj)
                // })
                const obj = {
                    x: point.coords.longitude,
                    y: point.coords.latitude,
                   
                }
                this.getLocationCompleted.next(obj)
                // return obj

            }, err => { }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true });
        }
    }
}
