import { Injectable, EventEmitter, Input } from '@angular/core';
import { RequestService, OdataParams } from 'app/core/services/request.service';
import { map } from 'rxjs/operators';
import { AppService } from '../../app-base/app.service';
import { Information } from '../model/report.interface';

@Injectable()

export class ReportService {
  private _windowConfig = null;
  /** Lưu giữ config của window sau khi gọi services */
  get windowConfig(): any { return this._windowConfig; }
  set windowConfig(windowConfig: any) {
    this._windowConfig = windowConfig;
  }

  private _appReport = null;
  @Input()
  set appReport(app: any) {
    this._appReport = app;
  }
  get appReport(): any {
    return this._appReport;
  }


  public lookupTab = {};
  public windowType = '';
  public dataSource = [];
  public layoutConfig = null;
  public windowId = null;
  public initWindowSuccess: EventEmitter<any> = new EventEmitter();

  private _jsObject = null;
  get jsObject(): any { return this._jsObject; }
  set jsObject(jsObject: any) { this._jsObject = jsObject; }

  public data = null;
  public linkdata = null;

  getDataDesigner: EventEmitter<any> = new EventEmitter();
  dataDesigner = null;
  getDataDesignerObjectBC: EventEmitter<any> = new EventEmitter();
  dataDesignerObjectBC = null;
  getdataMapService: EventEmitter<any> = new EventEmitter();
  dataMapService = null;
  public url = this.appService.urlWS + '/SvReports';

  constructor(
    private appService: AppService,
    private reqService: RequestService
  ) {
    this.reqService.switchType(this.appService.dataAccessType);
    this.getDataDesigner.subscribe(res => {
      this.dataDesigner = res;
    });

    this.getDataDesignerObjectBC.subscribe(res => {
      this.dataDesignerObjectBC = res;
    });

    this.getdataMapService.subscribe(res => {
      this.dataMapService = res;
    });
  }

  getUrlHost(path: string) {
    var currentPath = window.location.pathname;
    var lastSlashIndex = currentPath.lastIndexOf('/');
    var baseURL = window.location.origin + currentPath.substring(0, lastSlashIndex + 1);
    return baseURL + path;
  }

  getToken() {
    // const currentUser = JSON.parse(localStorage.getItem(this.appService.hostName1 + 'currentUser));
    // return currentUser ? currentUser.token : null;
  }




  public LayImageHoSoKyThuat(params: any) {
    const url = 'https://water.esrivn.net/ViwacoAPI/api/LayImageHoSoKyThuat';
    return this.reqService.service.query({ url, params });
  }



  public initData(windowId: any) {
    const url = `${this.appService.urlWS}/SysCaches/SysCacheWindow/${windowId}`;
    const params = {};
    return this.reqService.service.query({ url, params });
  }



  public buildTabs(tabs: Array<any>, level: any) {
    // const list: {
    //   FIELD_LIST: any[]; LAYOUT_CONFIG: null; INFORMATION: Information; HIDE: boolean; TABS: any[][]; CHILDREN: never[]; WHERE: any[]; GEOMETRY_PARAM: null; // Mặc định chưa có geometry trong các tham số đầu vào
    // }[] = [];
    // tabs.forEach(tab => {
    //     const fieldList: any[] = [];
    //     tab.fields.forEach((field: { isreadonly: string; }) => {
    //         let disabledCtrl = false;
    //         if (field.isreadonly === 'Y') { disabledCtrl = true; }
    //         // Field defaultvalue dùng để điền sẵn dữ liệu vào control
    //         fieldList.push({ ...field, disabledCtrl });
    //     });

    //     const where = tab.whereclause ? JSON.parse(tab.whereclause) : [];
    //     const information: Information = {
    //         TAB_ID: tab.tabid,
    //         PARENT_ID: tab.parenttabid,
    //         TABLE_ID: tab.tableid,
    //         DESCR: tab.tabname,
    //         TAB_LEVEL: tab.tablevel !== null ? tab.tablevel.toString() : '0',
    //         TAB_MAX_LEVEL: tab.maxLevel,
    //         TABLE_DETAIL: tab.tabledetail,
    //         SEQ_NO: tab.orderno ? tab.orderno.toString() : null,
    //         DEFAULT_WHERE: where,
    //         IS_LIENKETDOHOA: 'N',
    //         KHOA_CHINH: tab.columnkey,
    //         TRUONG_LK_CON: tab.truonglienketcon,
    //         TRUONG_LK_CHA: tab.truonglienketcha,
    //         TRUONG_LKTG_CON: tab.truongtrunggiancon,
    //         TRUONG_LKTG_CHA: tab.truongtrunggiancha,
    //         BANG_CHA: tab.banglienket,
    //         BANG_LK: tab.bangtrunggian,
    //         KHOA_CHINH_BANG_LK: tab.columnkeytrunggian,
    //         WHERE: (tab.tablevel === 0 && tab.orderno === 1) ? where : null,
    //         TABLE_NAME: tab.tablename,
    //         URL_EDIT: tab.urledit,
    //         URL_VIEW: tab.urlview,
    //         URL_EDIT_MAP: tab.urleditdohoa,
    //         URL_VIEW_MAP: tab.urlviewdohoa,
    //         LAYOUT_COLUMN: tab.layoutcolumn,
    //         PARENT_DATA: null,
    //         SERVICE_TYPE: this.windowType,
    //         WINDOW_ID: this.windowId,
    //         FILTER_FIELD: tab.fillterfield,
    //         FILTER_DEFAULT_VALUE: tab.fillterdefaultvalue,
    //         ORDER_BY: tab.orderbyclause ?? null,
    //         INIT_SHOW: tab.initshow === 'Y',
    //         ONCHANGE: tab.onchange,
    //         COLUMN_DOHOA: tab.columndohoa,
    //         TABLEWORKFLOWID: tab.TableWorkFlowId,
    //         JOBTYPEIDS: tab.JobTypeIds,
    //         LAYERINDEX: tab.LayerIndex,
    //         HASATTACHMENT: tab.hasattachment
    //     };

    //     // get layout config
    //     let layout = null;
    //     if (this.layoutConfig !== null && this.layoutConfig !== undefined) {
    //         this.layoutConfig.components.forEach((comp: { TabID: { toString: () => any; }; }) => {
    //             if (comp.TabID.toString() === information.TAB_ID.toString()) {
    //                 layout = comp;
    //             }
    //         });
    //     }

    //     const childTabs = this.buildTabs(tab.tabs, tab.tablevel + 1);
    //     const child = [];
    //     for (let i = tab.tablevel; i < tab.maxLevel; i++) {
    //         child.push(childTabs.filter(fil => fil.INFORMATION.TAB_LEVEL === (i + 1).toString()));
    //     }

    //     const item = {
    //         FIELD_LIST: fieldList,
    //         LAYOUT_CONFIG: layout,
    //         INFORMATION: information,
    //         HIDE: false,
    //         TABS: child,
    //         CHILDREN: [],
    //         WHERE: information.WHERE,
    //         GEOMETRY_PARAM: null // Mặc định chưa có geometry trong các tham số đầu vào
    //     };
    //     list.push(item);
    //     this.lookupTab[tab.tabid] = item;
    // });
    // return list;
  }
}

