export const mapOption = {
    logo: false,
    slider: false,
    sliderPosition: 'bottom-left',
    sliderStyle: 'large',
    showAttribution: false,
    fadeOnZoom: true,
    scale: 36111.9096,
    center: {
        type: 'point',
        x: 11778631.141708305,
        y: 2392563.6436379296,
        spatialReference: {
            wkid: 102100,
            latestWkid: 3857
        }
    },
    basemap: 'Bản đồ nền'
};
