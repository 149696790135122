import { indexOf } from 'lodash';
import { Checkbox } from 'primeng/checkbox';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app-base/app.service';
import { ConfigServicesDataService } from 'app/um/services';
@Component({
  selector: 'app-choosedatabase',
  templateUrl: './choosedatabase.component.html',
  styleUrls: ['./choosedatabase.component.scss']
})

export class ChoosedatabaseComponent implements OnInit {
  loading: any = false;
  arrAutoData: any;
  currentSelection: any = null;
  @Output() updateForm : EventEmitter<any> = new EventEmitter();
  constructor(public appService: AppService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    public configServicesDataService: ConfigServicesDataService) { }

  ngOnInit(): void {
    var a = JSON.parse(this.appService.currentUser!);
    this.onChooseAutoData();
  }
  onChooseAutoData() {
    const url = this.appService.urlAutoData + '/database';
    this.loading = true;
    this.configServicesDataService
      .queryAutoDataDatabase(url, 'GET', {})
      .subscribe(
        (res) => {
          if (res.success) {
            this.loading = false;
            this.arrAutoData = [];
            this.arrAutoData = [...res.features]
            this.arrAutoData.forEach((element: any) => {
              element.checked = false;
            });
            
          } else {
            this.loading = false;
            this.appService.createMessage('warning', res.message)
          }
        },
        (err) => {
          this.loading = false;
          this.appService.createMessage('warning', err.error.text)
        }
      );
  }
  onChkChangeImportAutoData(evt: any, item: any) {
    if (evt.checked == true) {
      this.arrAutoData.forEach((element: any) => {
        element.checked = false;
      });
      item.checked = true;
      this.currentSelection = item.dbName;
      
    }
  }
  close()
  {
    this.updateForm.emit(this.currentSelection);
  }
}
