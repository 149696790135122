import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-project-manager',
  templateUrl: './project-manager.component.html',
  styleUrls: ['./project-manager.component.scss']
})
export class ProjectManagerComponent implements OnInit, AfterViewInit {
  listToDo: any[] = [{
    id: 1,
    detail: "detail 1",
    startDate: "22/06/2022",
    dueDate: "23/06/2022",
    user: "long",
    type: "toDo"
  }];
  listInProgress: any[] = [{
    id: 2,
    detail: "detail 2",
    startDate: "24/06/2022",
    dueDate: "25/06/2022",
    user: "dai",
    type: "inProgress"
  }];
  listDone: any[] = [{
    id: 3,
    detail: "detail 3",
    startDate: "26/06/2022",
    dueDate: "27/06/2022",
    user: "co",
    type: "done"
  }];
  listTest: any[] = [{
    id: 4,
    detail: "detail 4",
    startDate: "28/06/2022",
    dueDate: "29/06/2022",
    user: "tuan",
    type: "test"
  }];
  listArchived: any[] = [{
    id: 5,
    detail: "detail 5",
    startDate: "30/06/2022",
    dueDate: "01/07/2022",
    user: "son",
    type: "archived"
  }];

  draggedTask: any;

  constructor() { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    // this.selectedProducts = [];
  }

  dragStart(task: any) {
    this.draggedTask = task;
  }

  dragEnd() {
    this.draggedTask = null;
  }

  dropToDo() {
    if (this.draggedTask) {
      console.log(this.draggedTask);
      const checkType = this.draggedTask.type;
      if (checkType !== "toDo") {
        this.draggedTask.type = "toDo";
        this.listToDo = [...this.listToDo, this.draggedTask];
        if (checkType === "inProgress") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listInProgress);
          this.listInProgress = this.listInProgress.filter((val, i) => i != draggedIndex);
        } else if (checkType === "done") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listDone);
          this.listDone = this.listDone.filter((val, i) => i != draggedIndex);
        } else if (checkType === "test") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listTest);
          this.listTest = this.listTest.filter((val, i) => i != draggedIndex);
        } else if (checkType === "archived") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listArchived);
          this.listArchived = this.listArchived.filter((val, i) => i != draggedIndex);
        }
        // this.draggedTask = null;
      }
    }
  }

  dropInProgress() {
    if (this.draggedTask) {
      console.log(this.draggedTask);
      const checkType = this.draggedTask.type;
      if (checkType !== "inProgress") {
        this.draggedTask.type = "inProgress";
        this.listInProgress = [...this.listInProgress, this.draggedTask];
        if (checkType === "toDo") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listToDo);
          this.listToDo = this.listToDo.filter((val, i) => i != draggedIndex);
        } else if (checkType === "done") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listDone);
          this.listDone = this.listDone.filter((val, i) => i != draggedIndex);
        } else if (checkType === "test") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listTest);
          this.listTest = this.listTest.filter((val, i) => i != draggedIndex);
        } else if (checkType === "archived") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listArchived);
          this.listArchived = this.listArchived.filter((val, i) => i != draggedIndex);
        }
        // this.draggedTask = null;
      }
    }
  }

  dropDone() {
    if (this.draggedTask) {
      console.log(this.draggedTask);
      const checkType = this.draggedTask.type;
      if (checkType !== "done") {
        this.draggedTask.type = "done";
        this.listDone = [...this.listDone, this.draggedTask];
        if (checkType === "toDo") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listToDo);
          this.listToDo = this.listToDo.filter((val, i) => i != draggedIndex);
        } else if (checkType === "inProgress") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listInProgress);
          this.listInProgress = this.listInProgress.filter((val, i) => i != draggedIndex);
        } else if (checkType === "test") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listTest);
          this.listTest = this.listTest.filter((val, i) => i != draggedIndex);
        } else if (checkType === "archived") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listArchived);
          this.listArchived = this.listArchived.filter((val, i) => i != draggedIndex);
        }
        // this.draggedTask = null;
      }
    }
  }

  dropTest() {
    if (this.draggedTask) {
      console.log(this.draggedTask);
      const checkType = this.draggedTask.type;
      if (checkType !== "test") {
        this.draggedTask.type = "test";
        this.listTest = [...this.listTest, this.draggedTask];
        if (checkType === "toDo") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listToDo);
          this.listToDo = this.listToDo.filter((val, i) => i != draggedIndex);
        } else if (checkType === "inProgress") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listInProgress);
          this.listInProgress = this.listInProgress.filter((val, i) => i != draggedIndex);
        } else if (checkType === "done") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listDone);
          this.listDone = this.listDone.filter((val, i) => i != draggedIndex);
        } else if (checkType === "archived") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listArchived);
          this.listArchived = this.listArchived.filter((val, i) => i != draggedIndex);
        }
        // this.draggedTask = null;
      }
    }
  }

  dropArchived() {
    if (this.draggedTask) {
      console.log(this.draggedTask);
      const checkType = this.draggedTask.type;
      if (checkType !== "archived") {
        this.draggedTask.type = "archived";
        this.listArchived = [...this.listArchived, this.draggedTask];
        if (checkType === "toDo") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listToDo);
          this.listToDo = this.listToDo.filter((val, i) => i != draggedIndex);
        } else if (checkType === "inProgress") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listInProgress);
          this.listInProgress = this.listInProgress.filter((val, i) => i != draggedIndex);
        } else if (checkType === "done") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listDone);
          this.listDone = this.listDone.filter((val, i) => i != draggedIndex);
        } else if (checkType === "test") {
          let draggedIndex = this.findIndex(this.draggedTask, this.listTest);
          this.listTest = this.listTest.filter((val, i) => i != draggedIndex);
        }
        // this.draggedTask = null;
      }
    }
  }

  findIndex(task: any, arrList: any) {
    let index = -1;
    for (let i = 0; i < arrList.length; i++) {
      if (task.id === arrList[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

}
