import { Injectable } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { environment } from 'environments/environment';
import { NotificationService, PushSubscription } from './generated';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {
    private swRegistration: any = null;
    private pushNotificationStatus = {
        isSubscribed: false,
        isSupported: false,
        isInProgress: false
    };

    constructor(
        private appService: AppService,
        private notificationService: NotificationService
    ) { }

    public init() {
        // Khu vực đăng ký service worker

        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            this.appService.alert("This browser does not support desktop notification", "warning");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            this._initial();
        } else {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    this._initial();
                } else if (permission === 'denied') {
                    this.appService.alert('Please allow notification permission to use notify!', 'info');
                }
            });
        }
    }

    private _initial() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.register(this.appService.hostName + 'assets/sw.js')
                .then(swReg => {
                    console.log('Service Worker is registered', swReg);

                    this.swRegistration = swReg;
                    // Kiểm tra đã subscription hay chưa
                    this.checkSubscription();
                })
                .catch(error => {
                    console.error('Service Worker Error', error);
                });

            navigator.serviceWorker.addEventListener('message', (event: any) => {
                console.log('EVENT FROM SERVICE WORKER >>>> ', event);
                this.appService.onNotificationPushNew.emit();
            });
            this.pushNotificationStatus.isSupported = true;
        } else {
            this.pushNotificationStatus.isSupported = false;
            this.appService.notification('Service worker not working', 'error');
        }
    }

    public sendDataToServiceWorker() {
        if (this.swRegistration) {
            const jsonString = JSON.stringify(this.appService.c$);
            this.swRegistration.active?.postMessage(jsonString);
        }
    }

    private checkSubscription() {
        if (!this.swRegistration) {
            return;
        }
        this.swRegistration.pushManager.getSubscription()
            .then((subscription: any) => {
                this.pushNotificationStatus.isSubscribed = !(subscription === null);
                if (!this.pushNotificationStatus.isSubscribed) {
                    // Chưa subscribe thì subscibe
                    this.subscribeUser();
                } else {
                    // Đã subscribe thì hủy subscribe
                    this.unsubscribeUser();
                }
            });
    }

    public subscribeUser() {
        if (!this.swRegistration) {
            return;
        }
        this.pushNotificationStatus.isInProgress = true;
        const applicationServerKey = this.urlB64ToUint8Array(this.appService.applicationServerPublicKey);
        this.swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        })
            .then((subscription: any) => {
                var newSub = JSON.parse(JSON.stringify(subscription));
                this.notificationService.subscribe(<PushSubscription>{
                    auth: newSub.keys.auth,
                    p256Dh: newSub.keys.p256dh,
                    endPoint: newSub.endpoint
                }).subscribe(s => {
                    this.pushNotificationStatus.isSubscribed = true;
                })
            })
            .catch((err: any) => {
                console.log('Failed to subscribe the user: ', err);
            })
            .then(() => {
                this.pushNotificationStatus.isInProgress = false;
            });
    }

    public unsubscribeUser() {
        if (!this.swRegistration) {
            return;
        }
        this.pushNotificationStatus.isInProgress = true;
        let sub: any = null;
        this.swRegistration.pushManager.getSubscription()
            .then((subscription: any) => {
                if (subscription) {
                    sub = JSON.parse(JSON.stringify(subscription));
                    return subscription.unsubscribe();
                }
            })
            .catch((error: any) => {
                console.log('Error unsubscribing', error);
            })
            .then(() => {
                this.notificationService.unsubscribe(<PushSubscription>{
                    auth: sub.keys.auth,
                    p256Dh: sub.keys.p256dh,
                    endPoint: sub.endpoint
                }).subscribe(() => {
                    this.pushNotificationStatus.isSubscribed = false;
                    this.pushNotificationStatus.isInProgress = false;

                    // Sau khi hủy xong subscribe thì subscribe lại
                    this.subscribeUser();
                });
            });
    }

    private urlB64ToUint8Array(base64String: String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
}
