<div>
  <app-core-tab [tabs]='arrTab' [title]="titleRef" [content]="contentRef" [fieldDisabled]='"isDisabled"'>
  </app-core-tab>
  <ng-template #titleRef let-tab>
    <div class="tab-title" *ngIf="tab.code === 'detail' && windowIdDetail || tab.code !== 'detail'">
      {{ tab.title | translate}}
    </div>
  </ng-template>
  <ng-template #contentRef let-tab>
    <ng-container *ngIf="tab.code === 'calendar'">
      <ng-container *ngTemplateOutlet="calendar"></ng-container>
    </ng-container>
    <ng-container *ngIf="tab.code === 'detail' && windowIdDetail">
      <ng-container *ngTemplateOutlet="detail"></ng-container>
    </ng-container>
  </ng-template>
</div>
<ng-template #calendar>
  <form [formGroup]="formGroupEdit">
    <div style="margin-bottom: 10px;">
      <!-- [legend]="'Calendar' | translate" -->
      <p-fieldset [toggleable]="true" [legend]="'Calendar' | translate">
        <!-- <p-header>
          <span class="pi pi-spin pi-spinner"></span>
          <span>{{'Calendar' | translate}}</span>
        </p-header> -->
        <div style="width: 100%; height: 100%; padding: 0 2rem;">
          <div style="margin-bottom: 5px;">
            <app-input [labelName]="'Sự kiện' | translate" [required]="true" [formControlName]="'eventEdit'"
              [maxlength]="200" [isLabelLeft]="true" [disabledCtrl]="mode === 'edit' ? false : true">
            </app-input>
          </div>
          <div style="margin-bottom: 5px;">
            <!-- <app-date-range [labelName]="'Từ ngày - đến ngày'"
              [formatDate]="checkedDay ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm:ss'" [showTime]="checkedDay ? false : true"
              [formControlName]="'dateFromTo'">
            </app-date-range> -->

            <div style="margin-bottom: 5px;">
              <app-core-datetime [formControlName]="'dateFromEdit'" [labelName]="'Từ ngày' | translate"
                [required]="true" [isLabelLeft]="true"
                [calConfig]="{ showTime: checkedDay ? false : true, maxDate: maxDate }"
                [disabledCtrl]="mode === 'edit' ? false : true"></app-core-datetime>
            </div>
            <div>
              <app-core-datetime [formControlName]="'dateToEdit'" [labelName]="'Đến ngày' | translate" [required]="true"
                [isLabelLeft]="true" [calConfig]="{ showTime: checkedDay ? false : true , minDate: minDate }"
                [disabledCtrl]="mode === 'edit' ? false : true"></app-core-datetime>
            </div>
          </div>
          <!-- <div>
            <app-select [labelName]="'Window'" [formControlName]="'chooseWindow'" [dataLookup]="arrWindow"
              displayField="DESCR" valueField="CODE" [required]="true">
            </app-select>
          </div> -->

          <div style="margin-bottom: 5px;">
            <app-core-checkbox [hasFormCtrl]="true" [chkLabel]="'Cả ngày' | translate"
              [formControlName]="'chkTimeUnitEdit'" [isDisabled]="mode === 'edit' ? false : true">
            </app-core-checkbox>
          </div>
        </div>

        <div *ngIf="mode === 'edit'" style="display: flex; justify-content: center;" class="form-add-footer">
          <!-- <app-button [labelName]="'Gửi thông báo' | translate" typeButton="save" (clickEvent)="openDialogNotifi()"
            icon='pi pi-bell' style="margin-left: 10px;">
          </app-button> -->
          <app-button class="ml-3" [labelName]="'Đồng ý' | translate" typeButton="save" (clickEvent)="onEditCalendar()">
          </app-button>
          <app-button class="ml-3" [labelName]="'Xóa' | translate" typeButton="reset" (clickEvent)="btnDeleteClick()">
          </app-button>
          <app-button class="ml-3" [labelName]="'Thoát' | translate" typeButton="close"
            (clickEvent)="closeDialogEdit()">
          </app-button>
        </div>
      </p-fieldset>
    </div>

    <div style="margin-bottom: 10px;" *ngIf="mode === 'edit'">
      <p-fieldset [legend]="'Notification' | translate" [toggleable]="true" [collapsed]="true">
        <form [formGroup]="formGroupNotifi">
          <ng-container style="width: 100%;">
            <div style="width: 100%; height: 100%; padding: 0 2rem;">
              <div style="margin-bottom: 5px;">
                <app-input [labelName]="'Nội dung thông báo' | translate" [required]="true"
                  [formControlName]="'eventNotifi'" [maxlength]="'200'" [isLabelLeft]="true">
                </app-input>
              </div>
              <div style="margin-bottom: 5px;">
                <!-- <app-core-datetime [formControlName]="'dateSendNotifi'" [labelName]="'Ngày gửi' | translate" [required]="true"
                  [isLabelLeft]="true" [calConfig]="{ showTime: checkedDay ? false : true }"></app-core-datetime> -->
                <!-- <app-input [labelName]="'Gửi trước: (ngày)' | translate" [required]="true" [type]="'number'"
                  [formControlName]="'numberDate'" [maxlength]="'200'" [isLabelLeft]="true">
                </app-input> -->

                <app-select [labelName]="'Thời điểm gửi' | translate" [formControlName]="'numberDate'"
                  [dataLookup]="arrDateSend" displayField="DESCR" valueField="CODE" [required]="true">
                </app-select>
              </div>
              <div style="margin-bottom: 5px;">
                <app-select [labelName]="'Mức độ ưu tiên' | translate" [formControlName]="'priority'"
                  [dataLookup]="arrPriotity" displayField="DESCR" valueField="CODE" [required]="true">
                </app-select>
              </div>
              <div style="margin-bottom: 5px;">
                <app-input [required]="true" [labelName]="'Người nhận' | translate" [isLabelLeft]="true"
                  [formControlName]="'treeUser'" type="text-button" (iconClick)="selectUser()"></app-input>
              </div>
              <!-- <div nzType="flex" nzJustify="end" nzAlign="middle" class="form-add-footer">
                <app-button class="ml-3" typeButton="save" [labelName]="'Gửi thông báo' | translate"
                  (clickEvent)="onSendNotifiModeEdit()">
                </app-button>
                <app-button class="ml-3" typeButton="close" [labelName]="'Thoát' | translate"
                  (clickEvent)="closeDialogNotifi()">
                </app-button>
              </div> -->
            </div>
          </ng-container>

          <div style="display: flex; justify-content: center;" class="form-add-footer">
            <app-button [labelName]="'Gửi thông báo' | translate" typeButton="save"
              (clickEvent)="onSendNotifiModeEdit()" icon='pi pi-bell' style="margin-left: 10px;">
            </app-button>

            <!-- <app-button class="ml-3" [labelName]="'Đồng ý' | translate" typeButton="save" (clickEvent)="onEditCalendar()">
            </app-button>
            <app-button class="ml-3" [labelName]="'Xóa' | translate" typeButton="reset" (clickEvent)="btnDeleteClick()">
            </app-button>
            <app-button class="ml-3" [labelName]="'Thoát' | translate" typeButton="close" (clickEvent)="closeDialogEdit()">
            </app-button> -->
          </div>
        </form>
      </p-fieldset>
    </div>
  </form>
</ng-template>
<ng-template #detail>
  <div style="height: 100%; width: 100%;">
    <app-core-window *ngIf="windowIdDetail" [isMapApp]="true" [windowId]="windowIdDetail" [whereWf]="whereCal"
      [isWfApp]="true" style="margin-top: 55px;" (createDynamicEvent)="onCoreWindowEmitEvent($event)"></app-core-window>
    <div *ngIf="!windowIdDetail">Không có dữ liệu</div>
  </div>
</ng-template>

<ng-template #tmpFormNotifi>
  <form [formGroup]="formGroupNotifi">
    <ng-container style="width: 100%;">
      <div style="width: 100%; height: 100%; padding: 0 2rem;">
        <div style="margin-bottom: 5px;">
          <app-input [labelName]="'Nội dung thông báo' | translate" [required]="true" [formControlName]="'eventNotifi'"
            [maxlength]="'200'" [isLabelLeft]="true">
          </app-input>
        </div>
        <div style="margin-bottom: 5px;">
          <!-- <app-core-datetime [formControlName]="'dateSendNotifi'" [labelName]="'Ngày gửi' | translate" [required]="true"
            [isLabelLeft]="true" [calConfig]="{ showTime: checkedDay ? false : true }"></app-core-datetime> -->
          <!-- <app-input [labelName]="'Gửi trước: (ngày)' | translate" [required]="true" [type]="'number'"
            [formControlName]="'numberDate'" [maxlength]="'200'" [isLabelLeft]="true">
          </app-input> -->
          <app-select [labelName]="'Thời điểm gửi' | translate" [formControlName]="'numberDate'"
            [dataLookup]="arrDateSend" displayField="DESCR" valueField="CODE" [required]="true">
          </app-select>
        </div>
        <div style="margin-bottom: 5px;">
          <app-select [labelName]="'Mức độ ưu tiên' | translate" [formControlName]="'priority'"
            [dataLookup]="arrPriotity" displayField="DESCR" valueField="CODE" [required]="true">
          </app-select>
        </div>
        <div style="margin-bottom: 5px;">
          <app-input [required]="true" [labelName]="'Người dùng'" [isLabelLeft]="true" [formControlName]="'treeUser'"
            type="text-button" (iconClick)="selectUser()"></app-input>
        </div>
        <div nzType="flex" nzJustify="end" nzAlign="middle" class="form-add-footer">
          <app-button class="ml-3" typeButton="save" [labelName]="'Gửi thông báo' | translate"
            (clickEvent)="onSendNotifiModeEdit()">
          </app-button>
          <app-button class="ml-3" typeButton="close" [labelName]="'Thoát' | translate"
            (clickEvent)="closeDialogNotifi()">
          </app-button>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<ng-template #tempUser>
  <div style="padding-bottom: 10px; height: 400px; overflow: auto;">
    <app-core-tree [dataTree]="arrUser" [(nodeSelectedModel)]="nodeSelected" [treeConfig]="treeConfig">
    </app-core-tree>
  </div>
  <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
    <app-button labelName='Cancel' typeButton="cancel" (clickEvent)="onCancelUser()"></app-button>
    <app-button labelName='OK' typeButton="save" style="margin-left: 10px;" (clickEvent)="onAddUser()"></app-button>
  </div>
</ng-template>

<app-dialog-prime #dialogNotifi></app-dialog-prime>
<app-dialog-prime #dialogUser [isAppendBody]="true"></app-dialog-prime>
