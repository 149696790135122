import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { RequestService, InsertResponse} from 'app/core/services/request.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-supplier-items',
  templateUrl: './add-supplier-items.component.html',
  styleUrls: ['./add-supplier-items.component.scss'],
  providers: [RequestService]
})
export class AddSupplierItemsComponent implements OnInit {
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  public menuList: any[] = [];
  public contractsID: any;
  constructor(
    public reqService: RequestService,
    public appService: AppService,
    private resolver: ComponentFactoryResolver,
    public translate: TranslateService,
  ) { 
    reqService.switchType('clouddata');
  }

  ngOnInit(): void {
  }

  run (data: any, isReload: any) { 
    //console.log("add-supplier-items123123213",'data', data, 'isReload', isReload);
    var Number = 0;
    if (data.data !== null){

      if (data.parentData !== null) {
        const param = ['ContractsID', '=', data.parentData.ContractsID];
        this.contractsID=  data.parentData.ContractsID;
        this.reqService.service.search(
          {
          url: 'https://office.esrivn.net/auto/esrivn_autodata/data/esrivn/ContractsItems',
          where: param
          }
        ).subscribe(res => {  
         // console.log('res', res);
          if (res.success) { 
            if (res.features.length > 0) { 
              res.features.forEach(evt => {
                this.menuList.push({
                  checked: false,
                  ContractsID: data.parentData.ContractsID,
                  SupplierContractID: data.parentData.SupplierContractID,
                  ItemName: evt.ItemName,
                  ContractsItemsID: evt.ContractsItemsID,
                  ItemValueUSD: evt.ItemValueUSD,
                  ItemValueVND: evt.ItemValueVND,
                });
              });
              this.FilterSupplierItems(data);
            } else {
              this.appService.alert('Contract Item no data !!!');
              this.onCloseEvent.emit(true);
            }
          } else {
            this.appService.alert('Something went wrong!!!', 'error');
            this.onCloseEvent.emit(true);
          }
        }, err => {
          this.appService.alert('Something went wrong!!!', 'error');
          this.onCloseEvent.emit(true);
        });
      } else {
        this.appService.alert('Please add a new supplier contract !!!');
        this.onCloseEvent.emit(true);
      }
    } else {
      this.appService.alert('Error! An error occurred. Please try again later !!!');
      this.onCloseEvent.emit(true);
    }
  }

  FilterSupplierItems (data: any){
    const Param = ['SupplierContractID', '=', data.parentData.SupplierContractID];
    this.reqService.service.search(
      {
      url: 'https://office.esrivn.net/auto/esrivn_autodata/data/esrivn/SupplierItems',
      where: Param
      }
    ).subscribe(res2 => {  
      // console.log('res2', res2);
      if(res2.features.length > 0){
        res2.features.forEach(e => {  
          // console.log('e', e);
          this.menuList = this.menuList.filter(function(i) {
            return (e.ContractsItemsID !== i.ContractsItemsID && e.ItemName !== i.ItemName);
          });
          if(this.menuList.length === 0){
            this.appService.alert('Added all Supplier items !!!');
            this.onCloseEvent.emit(true);
          }
        })
      }
    }, err => {
      this.appService.alert('Something went wrong!!!', 'error');
      console.log('err', err);
      this.onCloseEvent.emit(true);
    });

  
  }

  public onCheckboxChangeApplication(evt: any, item: any, index: number) { 
    
    item.checked = evt.checked;
   
  }
  onClickItemApplication(item: any) { 
    // console.log('item', item);
  }

  onSave() { 
     //console.log('onSave', this.menuList);
     const mes = 'Add Supplier Items !!!';
     this.appService.confirm(mes).subscribe((conf) => {
       if (conf) {
         if ( this.menuList.length >0 ){
          const param = ['ContractsID', '=', this.contractsID];
          
          this.reqService.service.search(
            {
            url: 'https://office.esrivn.net/auto/esrivn_autodata/data/esrivn/Contracts',
            where: param
            }
          ).subscribe(res => {  
           // console.log('res', res);
            if (res.success) { 
              if (res.features.length > 0) { 
                res.features.forEach(evt => {
                  if(evt.ContractCode == "A"){
                    this.menuList.forEach(f => {
                      if (f.checked){
                        //console.log('f', f);
                        const Param ={
                         SupplierContractID: f.SupplierContractID,
                         ItemName: f.ItemName,
                         ContractsItemsID: f.ContractsItemsID,
                         ItemValueUSD: f.ItemValueUSD,
                         ItemValueVND: f.ItemValueVND,
                        }
          
                        this.reqService.service.insert({
                          url: 'https://office.esrivn.net/auto/esrivn_autodata/data/esrivn/SupplierItems',
                          data: Param
                          //primaryKey: infor.KHOA_CHINH
                        }).subscribe((val: InsertResponse) => {
                            //console.log('val', val)
                            this.onCloseEvent.emit(true);
                            this.appService.notification(
                              this.appService.getMessage('0001'),
                              'success'
                            );
                        },(err) => {
                          this.onCloseEvent.emit(true);
                              this.appService.notification(
                                  this.appService.getMessage('0002'),
                                  'error'
                              );
                        });
                      }
                      
                    });
                  } else {
                    this.menuList.forEach(f => {
                      if (f.checked){
                        //console.log('f', f);
                        const Param ={
                         SupplierContractID: f.SupplierContractID,
                         ItemName: f.ItemName,
                         ContractsItemsID: f.ContractsItemsID
                        }
          
                        this.reqService.service.insert({
                          url: 'https://office.esrivn.net/auto/esrivn_autodata/data/esrivn/SupplierItems',
                          data: Param
                          //primaryKey: infor.KHOA_CHINH
                        }).subscribe((val: InsertResponse) => {
                            //console.log('val', val)
                            this.onCloseEvent.emit(true);
                            this.appService.notification(
                              this.appService.getMessage('0001'),
                              'success'
                            );
                        },(err) => {
                          this.onCloseEvent.emit(true);
                              this.appService.notification(
                                  this.appService.getMessage('0002'),
                                  'error'
                              );
                        });
                      }
                      
                    });
                  }
                });
                
              } else {
                this.appService.alert('Contract Item no data !!!');
                this.onCloseEvent.emit(true);
              }
            } else {
              this.appService.alert('Something went wrong!!!', 'error');
              this.onCloseEvent.emit(true);
            }
          }, err => {
            this.appService.alert('Something went wrong!!!', 'error');
            this.onCloseEvent.emit(true);
          });

           

         } else {
           this.onCloseEvent.emit(true);
         }
 
       } else{
         this.onCloseEvent.emit(true);
       }
     });
  }

}
