import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { RequestService } from 'app/core/services/request.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-process-quotation',
  templateUrl: './process-quotation.component.html',
  styleUrls: ['./process-quotation.component.scss']
})
export class ProcessQuotationComponent implements OnInit {
  formGroup: any = null;
  handlerEvt: any = null;
  display = false;
  params: any = {};
  currentNote: any = "";
  currentData: any = null;
  quotataionNo: any = null;
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    public reqService: RequestService,
    public appService: AppService
  ) {
    this.formGroup = new FormGroup({
      REASON: new FormControl()
    });
  }

  ngOnInit(): void {
  }
  async run(evt: any) {
    if (evt.currentData.length > 1) {
      this.onCloseEvent.emit(false);
      this.appService.createMessage("warning", "Select only 1 quotaion");
    } else {
      this.params = {}

      this.queryQuotation(evt.currentData[0]).subscribe((res: any) => {
        if (res.success && res.features.length > 0) {
          // this.currentData.ForEndUser = res.features[0].CompanyName
          Object.keys(evt.currentData[0]).forEach(key => {
            if (res.features[0][key]) {
              evt.currentData[0][key] = res.features[0][key]
            }
          })
          this.currentData = evt.currentData[0];
          const date = new Date();
          this.currentNote = evt.currentData[0].Note;
          this.queryCompany()
          if (evt.menu.name === 'Approve') {
            this.params['QuotationId'] = evt.currentData[0].QuotationId;
            this.params['StatusId'] = 3;
            this.params['UserApproved'] = JSON.parse(this.appService.currentUser).username;
            this.params['DateApproved'] = date;
            this.params['UserReject'] = null;
            this.params['DateReject'] = null;
            this.params['DateCreate'] = evt.currentData[0].DateCreate;
            this.params['Note'] = evt.currentData[0].Note;
            // await this.renderQuotationNo(evt.currentData[0]);
            if (evt.currentData[0].StatusId === 4) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was rejected. Cannot approve!");
            } else if (evt.currentData[0].StatusId === 3) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was aprroved. No need approve again!");
            } else if (evt.currentData[0].StatusId === 2) {
              const date = new Date();
              const dateStr = this.createStrDate(date);
              // let strqo = 'Esri-QT' + dateStr;
              this.quotataionNo = null;
              let strmax = '';
              const url = this.appService.urlInitQutation + '/Quotations';
              const odataparams = {
                url: url,
                where: ["QuotationNo", "like", dateStr]
              };
              const resp = this.reqService.service.search(odataparams);
              resp.subscribe(res => {
                if (res.success) {
                  if (res.features.length > 0) {
                    strmax = (this.findMax(res.features, 'QuotationNo') + 1) < 10 ? "0" + (this.findMax(res.features, 'QuotationNo') + 1) : (this.findMax(res.features, 'QuotationNo') + 1).toString();
                  } else {
                    strmax = '01';
                  }
                  this.quotataionNo = 'Esri-QT' + dateStr + strmax;
                  // this.updateStatus(this.params, evt.menu.name);
                  this.reqService.service.search({
                    url: this.appService.urlInitQutation + '/QuotationItems',
                    where: ['QuotationId', "=", evt.currentData[0].QuotationId]
                  }).subscribe(res => {
                    if (res.success) {
                      if (res.features.length > 0) {
                        this.reqService.service.search({
                          url: this.appService.urlInitQutation + '/Quotations',
                          where: ['QuotationId', "=", evt.currentData[0].QuotationId]
                        }).subscribe(resp => {
                          if (resp.success) {
                            if (resp.features[0].QuotationNetPrice && resp.features[0].QuotationNetPrice > -1) {
                              if (this.checkPriceQuotation(res.features, resp.features[0])) {
                                const obj = this.checkValid(evt.currentData[0]);
                                if (obj.valid) {
                                  this.updateStatus(this.params, evt.menu.name);
                                } else {
                                  this.onCloseEvent.emit(false);
                                  this.appService.createMessage('warning', "Fill data to fields: " + obj.fieldlist);
                                }
                              } else {
                                this.onCloseEvent.emit(false);
                                this.appService.createMessage("warning", "The price of this quotation is not correct");
                              }

                            } else {
                              this.onCloseEvent.emit(false);
                              this.appService.createMessage("warning", "This quotation hasn't calculate total price yet");
                            }

                          }
                        });

                      } else {
                        this.onCloseEvent.emit(false);
                        this.appService.createMessage("warning", "Don't have quotation item. Cannot approve!");
                      }
                    }
                  })
                } else {
                  this.onCloseEvent.emit(false);
                  this.appService.createMessage("error", "Error when approve this quotation");
                }
              });
              // await this.updateStatus(this.params, evt.menu.name);
            } else if (evt.currentData[0].StatusId === 1) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation hasn't submited yet. Submit first, please!");
            }
          } else if (evt.menu.name === 'Reject') {
            this.params['QuotationId'] = evt.currentData[0].QuotationId;
            this.params['StatusId'] = 4;
            this.params['UserApproved'] = null
            this.params['DateApproved'] = null;
            this.params['UserReject'] = JSON.parse(this.appService.currentUser).username;
            this.params['DateReject'] = date;
            this.params['QuotationNo'] = null;
            this.params['DateCreate'] = evt.currentData[0].DateCreate;
            if (evt.currentData[0].StatusId === 3) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was aprroved. Cannot reject!");
              // this.display = false;
            } else if (evt.currentData[0].StatusId === 4) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was rejected. No need reject again!");
            } else if (evt.currentData[0].StatusId === 2) {
              this.updateStatus(this.params, evt.menu.name);
            } else if (evt.currentData[0].StatusId === 1) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation hasn't submited yet. Submit first, please!");
            }
          } else if (evt.menu.name === 'Submit') {
            console.log(this.currentData)
            this.params['QuotationId'] = evt.currentData[0].QuotationId;
            this.params['StatusId'] = 2;
            this.params['UserReject'] = null;
            this.params['DateReject'] = null;
            this.params['UserApproved'] = null
            this.params['DateApproved'] = null;
            this.params['Note'] = evt.currentData[0].Note;
            this.params['QuotationNo'] = null;
            this.params['DateCreate'] = new Date();
            if (evt.currentData[0].StatusId === 2) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was submited. No need submit again!");
            } else if (evt.currentData[0].StatusId === 1 || evt.currentData[0].StatusId === 4) {
              this.reqService.service.search({
                url: this.appService.urlInitQutation + '/QuotationItems',
                where: ['QuotationId', "=", evt.currentData[0].QuotationId]
              }).subscribe(res => {
                if (res.success) {
                  if (res.features.length > 0) {
                    this.reqService.service.search({
                      url: this.appService.urlInitQutation + '/Quotations',
                      where: ['QuotationId', "=", evt.currentData[0].QuotationId]
                    }).subscribe(resp => {
                      if (resp.success) {
                        if (resp.features[0].QuotationNetPrice && resp.features[0].QuotationNetPrice > -1) {
                          if (this.checkPriceQuotation(res.features, resp.features[0])) {
                            const obj = this.checkValid(evt.currentData[0]);
                            if (obj.valid) {
                              this.updateStatus(this.params, evt.menu.name);
                            } else {
                              this.onCloseEvent.emit(false);
                              this.appService.createMessage('warning', "Fill data to fields: " + obj.fieldlist);
                            }
                          } else {
                            this.onCloseEvent.emit(false);
                            this.appService.createMessage("warning", "The price of this quotation is not correct");
                          }

                        } else {
                          this.onCloseEvent.emit(false);
                          this.appService.createMessage("warning", "This quotation hasn't calculate total price yet");
                        }

                      }
                    });

                  } else {
                    this.onCloseEvent.emit(false);
                    this.appService.createMessage("warning", "Don't have quotation item. Cannot submit!");
                  }
                }
              })


            } else if (evt.currentData[0].StatusId === 3) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was aprroved. Cannot submit!");
            }
          } else if (evt.menu.name === 'Recall to Draft') {
            this.params['QuotationId'] = evt.currentData[0].QuotationId;
            this.params['StatusId'] = 1;
            this.params['UserReject'] = null;
            this.params['DateReject'] = null;
            this.params['UserApproved'] = null
            this.params['DateApproved'] = null;
            this.params['Note'] = evt.currentData[0].Note;
            this.params['QuotationNo'] = null;
            this.params['DateCreate'] = null;
            if (evt.currentData[0].StatusId === 1) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation hasn't submited yet!");
            } else if (evt.currentData[0].StatusId === 2) {
              this.updateStatus(this.params, evt.menu.name);
            } else if (evt.currentData[0].StatusId === 3) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was aprroved. Cannot recall to draft!");
            } else if (evt.currentData[0].StatusId === 4) {
              this.onCloseEvent.emit(false);
              this.appService.createMessage("warning", "This quotation was rejected. Cannot recall to draft!");
            }
          }
        }
      })

    }

  }
  checkPriceQuotation(arrItem: any[], data: any) {
    let price = 0
    let chk = false
    arrItem.forEach((item: any) => {
      if (item.TotalPrice) {
        price += item.TotalPrice
      }

    })
    if (data.GrandTotal && Math.round(data.GrandTotal) === Math.round(price)) {
      chk = true
    } else {
      chk = false
    }
    return chk;
  }
  checkValid(data: any) {
    let chk = false;
    let fieldlist: any[] = [];
    let count = 0;
    Object.keys(data).forEach(key => {
      if (key === 'DayValid' || key === 'Delivery' || key === 'WarrantyPeriod' || key === 'PaymentTermName' || key === 'QuotationDate' || key === 'QuotationTypeId') {
        if (data[key] !== null && data[key] !== '' && data[key] !== undefined) {
          count++;
        } else {
          // chk = false;
          if (key === 'DayValid') {
            fieldlist.push('Day Valid');
          } else if (key === 'Delivery') {
            fieldlist.push('Delivery');
          } else if (key === 'WarrantyPeriod') {
            fieldlist.push('Warranty Period');
          } else if (key === 'PaymentTermName') {
            fieldlist.push('Payment Term');
          } else if (key === 'QuotationDate') {
            fieldlist.push('Quotation Date');
          } else if (key === 'QuotationTypeId') {
            fieldlist.push('Quotation Type');
          }

        }
        if (count === 6) {
          chk = true;
        } else {
          chk = false;
        }
      }
    });


    return {
      valid: chk,
      fieldlist: fieldlist.join(', ')
    };
  }
  updateStatus(params: any, name: any) {
    if (name === 'Reject') {
      this.display = true;
    } else {
      // this.onCloseEvent.emit(false)
      const title = "Confirm " + name.toLowerCase() + " this quotation?"
      this.appService.confirm(title, true).subscribe(res => {
        if (res) {
          this.PutStatus(params, name);
        } else {
          this.onCloseEvent.emit(false);
        }
      })
    }

  }
  PutStatus(params: any, name: any) {
    if (name === 'Approve') {
      params['QuotationNo'] = this.quotataionNo;
                                    
    }
    const url = this.appService.urlInitQutation + '/Quotations/PutStatusQuotation';
    const odataparams = {
      url: url,
      primaryKey: 'QuotationId',
      data: params
    };
    const resp = this.reqService.service.update(odataparams);
    resp.subscribe(res => {
      if (res.success) {
        this.appService.notification(name + " success", "success");
        // this.appService
        if (name === 'Submit') {

          const url = this.appService.urlInitQutation + '/staffs';
          const odataparams = {
            url: url,
            where: ["IsManager", "=", "Y"]
          };
          const resp = this.reqService.service.search(odataparams);
          resp.subscribe(res => {
            if (res.success) {
              let toemail = "";
              if (res.features.length > 0) {

                toemail = res.features[0].Email;
                //alert(toemail);

              }
              const paramMail = {
                // Opportunity: this.currentData.ContractNo,
                // Status: 'Submitted for Approval',
                // UserCreate: this.currentData.UserCreate,
                // toemail: toemail,
                // organizationname: this.currentData.CompanyName,
                "Status": "Submitted for Approval",
                "OpportunityNo": this.currentData.ContractNo,
                "SaleName": this.currentData.UserCreate,
                "toemail": toemail,
                "QuotationTo": this.currentData.CompanyName,
                "ForEndUser": this.currentData.ForEndUser,
                "QuoteAmount": this.currentData.GrandTotal + ' ' + this.currentData.CurrencyName
              }
              // console.log(paramMail)
              this.appService.reqUrl1('https://sendmail.esrivn.net/api/SendMailInfoQuotation', paramMail).subscribe(evt => { });
            }
          });
          // this.appService.reqUrl('https://coretech.vn:1316/api/SendMailInfoQuotation', paramMail).subscribe(evt => { });
        } else if (name === 'Approve' || name === 'Reject') {
          this.reqService.service.search({
            url: this.appService.urlInitQutation + '/Staffs',
            where: ['StaffCode', '=', this.currentData.UserCreate]
          }).subscribe(res => {
            if (res.success) {
              if (res.features.length > 0) {
                const paramMail = {
                  // Opportunity: this.currentData.ContractNo,
                  Status: name === 'Approve' ? 'Approved' : 'Rejected',
                  // UserCreate: this.currentData.UserCreate,
                  // // toemail: 'hiencn@esrivn.com'
                  // toemail: res.features[0].Email,
                  // organizationname: this.currentData.CompanyName "Status": "Submitted for Approval",
                  "OpportunityNo": this.currentData.ContractNo,
                  "SaleName": this.currentData.UserCreate,
                  "toemail": res.features[0].Email,
                  "QuotationTo": this.currentData.CompanyName,
                  "ForEndUser": this.currentData.ForEndUser,
                  "QuoteAmount": this.currentData.GrandTotal + ' ' + this.currentData.CurrencyName
                }
                this.appService.reqUrl1('https://sendmail.esrivn.net/api/SendMailInfoQuotation', paramMail).subscribe(evt => { });
              }

            }
          })

        }
        this.onCloseEvent.emit(true);
      } else {
        this.appService.notification(name + " error", "error");
      }
    });
  }
  onReject() {
    if (this.formGroup.value['REASON'] !== null && this.formGroup.value['REASON'] !== "") {
      if (this.currentNote !== null && this.currentNote !== "") {
        this.params['Note'] = this.currentNote + "\n" + this.formGroup.value['REASON'];
      } else {
        this.params['Note'] = this.formGroup.value['REASON'];
      }

    } else {
      this.params['Note'] = this.currentNote;
    }
    this.display = false;
    this.PutStatus(this.params, 'Reject');
  }
  onClose() {
    this.formGroup.reset();
    this.onCloseEvent.emit(false);
  }
  renderQuotationNo(data: any) {
    const date = new Date();
    const dateStr = this.createStrDate(date);
    // let strqo = 'Esri-QT' + dateStr;
    this.quotataionNo = null;
    let strmax = '';
    const url = this.appService.urlInitQutation + '/Quotations';
    const odataparams = {
      url: url,
      where: ["QuotationNo", "like", dateStr]
    };
    const resp = this.reqService.service.search(odataparams);
    resp.subscribe(res => {
      if (res.success) {
        if (res.features.length > 0) {
          strmax = (this.findMax(res.features, 'QuotationNo') + 1) < 10 ? "0" + (this.findMax(res.features, 'QuotationNo') + 1) : (this.findMax(res.features, 'QuotationNo') + 1).toString();
        } else {
          strmax = '01';
        }
        this.quotataionNo = 'Esri-QT' + dateStr + strmax;
      }
    });

  }
  findMax(data: any, key: any) {
    let max = 0;
    data.forEach((item: any) => {
      const str = item[key].substring(item[key].length - 2);
      if (parseFloat(str) > max) {
        max = parseFloat(str);
      }
    });
    return max;
  }
  createStrDate(date: Date) {
    const strMonth = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1).toString();
    const strYear = date.getFullYear();
    return (strYear + strMonth);
  }

  queryCompany() {
    this.reqService.switchType('sql')
    this.reqService.service.search({
      url: this.appService.urlInitQutation + '/COMPANYs',
      where: ['CompanyId', '=', this.currentData.CompanyEndpointId]
    }).subscribe((res: any) => {
      if (res.success && res.features.length > 0) {
        this.currentData.ForEndUser = res.features[0].CompanyName
      }
    })
  }
  queryQuotation(data: any) {
    this.reqService.switchType('sql')
    return this.reqService.service.search({
      url: this.appService.urlInitQutation + '/QUOTATIONs',
      where: ['QuotationId', '=', data.QuotationId]
    })
    // .subscribe((res: any) => {
    //   if(res.success && res.features.length > 0) {
    //     // this.currentData.ForEndUser = res.features[0].CompanyName
    //     Object.keys(data).forEach(key => {
    //       if(res.features[0][key]) {
    //         data[key] = res.features[0][key]
    //       }
    //     })
    //     console.log(data)
    //   }
    // })
  }

}
