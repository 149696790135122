<ng-container *ngIf="hasFormCtrl">
    <ng-container *ngIf="!isLabelLeft">
        <div class="label-Class" [ngClass] = "{'label-readonly': isReadOnly}">
            <ng-container *ngIf="notUseTranslate">
                {{chkLabel }}
            </ng-container>
            <ng-container *ngIf="!notUseTranslate">
                {{chkLabel | translate }}
            </ng-container>
        </div>
        <div style="display: flex; align-items: center;">
            <p-checkbox #chk [name]="chkName" [value]='chkValue' [label]="''" [formControl]="ctrl"
                [readonly]="isDisabled" [disabled]="!isDisabled && isReadOnly" [checkboxIcon]="chkIcon"
                [binary]="isBinary" (blur)="blurEvent.emit()"
                [trueValue]="typeValue === 'boolean' ? true : typeValue === 'string' ? 'Y' : 1"
                [falseValue]="typeValue === 'boolean' ? false : typeValue === 'string' ? 'N' : 0">
            </p-checkbox>
            <ng-container *ngIf="hasButton">
                <button pButton pRipple type="button" icon="pi pi-plus"
                    style="height: 30px;width: 30px; margin-left: 3px;" (click)="btnCalcClick.emit()"></button>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="isLabelLeft">
        <div *ngIf="isCoreForm" style="padding-top: 30px;"></div>
        <span style="display: flex; align-items: center; height: 30px; width: 100%;" [ngClass] = "{'label-readonly': isReadOnly}">
            <p-checkbox #chk [name]="chkName" [value]='chkValue'
                [label]=" notUseTranslate ? chkLabel : (chkLabel | translate)" [formControl]="ctrl"
                [readonly]="isDisabled" [disabled]="!isDisabled && isReadOnly" [checkboxIcon]="chkIcon"
                [binary]="isBinary" (blur)="blurEvent.emit()"
                [trueValue]="typeValue === 'boolean' ? true : typeValue === 'string' ? 'Y' : 1" [falseValue]="
        typeValue === 'boolean' ? false : typeValue === 'string' ? 'N' : 0">
            </p-checkbox>
            <ng-container *ngIf="hasButton">
                <button pButton pRipple type="button" icon="pi pi-plus"
                    style="height: 30px;width: 30px; margin-left: 3px;" (click)="btnCalcClick.emit()"></button>
            </ng-container>
        </span>

    </ng-container>

</ng-container>
<ng-container *ngIf="!hasFormCtrl">
    <ng-container *ngIf="!isLabelLeft">
        <div class="label-Class" [ngClass] = "{'label-readonly': isReadOnly}">
            <ng-container *ngIf="notUseTranslate">
                {{chkLabel}}
            </ng-container>
            <ng-container *ngIf="!notUseTranslate">
                {{chkLabel | translate }}
            </ng-container>
        </div>
        <div style="display: flex; align-items: center;">
            <p-checkbox #chk2 [name]="chkName" [value]='chkValue' [label]="''" [readonly]="isDisabled"
                [disabled]="!isDisabled && isReadOnly" [checkboxIcon]="chkIcon" [binary]="isBinary"
                [(ngModel)]="valueModel" (onChange)="chkChange.emit($event)" (blur)="blurEvent.emit()"
                [trueValue]="typeValue === 'boolean' ? true : typeValue === 'string' ? 'Y' : 1"
                [falseValue]="typeValue === 'boolean' ? false : typeValue === 'string' ? 'N' : 0">
            </p-checkbox>
            <ng-container *ngIf="hasButton">
                <button pButton pRipple type="button" icon="pi pi-plus"
                    style="height: 30px;width: 30px; margin-left: 3px;" (click)="btnCalcClick.emit()"></button>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="isLabelLeft">
        <ng-container *ngIf="labelLeftOut">
            <div *ngIf="isCoreForm" style="padding-top: 30px;"></div>
            <div class="p-field-checkbox d-flex align-items-center" [ngClass] = "{'label-readonly': isReadOnly}">
                <p-checkbox #chk2 [name]="chkName" [value]='chkValue' [readonly]="isDisabled"
                    [label]="chkLabel | translate" [disabled]="!isDisabled && isReadOnly" [checkboxIcon]="chkIcon"
                    [binary]="isBinary" [(ngModel)]="valueModel" (onChange)="chkChange.emit($event)"
                    (blur)="blurEvent.emit()"
                    [trueValue]="typeValue === 'boolean' ? true : typeValue === 'string' ? 'Y' : 1" [falseValue]="
        typeValue === 'boolean' ? false : typeValue === 'string' ? 'N' : 0">
                </p-checkbox>
                <ng-container *ngIf="hasButton">
                    <button pButton pRipple type="button" icon="pi pi-plus"
                        style="height: 30px;width: 30px; margin-left: 3px;" (click)="btnCalcClick.emit()"></button>
                </ng-container>
                <!-- <label class="p-checkbox-label cursor-pointer">{{chkLabel | translate}}</label> -->
            </div>
        </ng-container>

        <ng-container *ngIf="!labelLeftOut">
            <div *ngIf="isCoreForm" style="padding-top: 30px;"></div>
            <span style="display: flex; align-items: center; height: 30px; width: 100%;">
                <p-checkbox #chk2 [name]="chkName" [value]='chkValue'
                    [label]="notUseTranslate ? chkLabel : (chkLabel | translate)" [readonly]="isDisabled"
                    [disabled]="!isDisabled && isReadOnly" [checkboxIcon]="chkIcon" [binary]="isBinary"
                    [(ngModel)]="valueModel" (onChange)="chkChange.emit($event)" (blur)="blurEvent.emit()"
                    [trueValue]="typeValue === 'boolean' ? true : typeValue === 'string' ? 'Y' : 1" [falseValue]="
        typeValue === 'boolean' ? false : typeValue === 'string' ? 'N' : 0">
                </p-checkbox>
                <ng-container *ngIf="hasButton">
                    <button pButton pRipple type="button" icon="pi pi-plus"
                        style="height: 30px;width: 30px; margin-left: 3px;" (click)="btnCalcClick.emit()"></button>
                </ng-container>
            </span>

        </ng-container>



    </ng-container>

</ng-container>