import { Injectable } from '@angular/core';
import { AppService } from '../../app-base/app.service';
import { RequestService } from 'app/core/services/request.service';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {
  public url = this.appService.urlWS;
  public tableName = 'SysUser';

  constructor(
    private appService: AppService,
    private reqService: RequestService
  ) { this.reqService.switchType(this.appService.dataAccessType); }
  // New
  // public queryUser(params: any, pSize: any, pNumber: any) {
  //     const resp = this.reqService.service.search({
  //         url: this.url + '/SysUsers',
  //         where: params,
  //         pageSize: pSize,
  //         startRecord: pNumber,
  //         logic: 'and'
  //     });
  //     return resp;
  // }

  // User portal
  currentUser = JSON.parse(this.appService.currentUser);
  getListGroupUserPortal(groupid: string, params: any) {
    this.reqService.switchType('arcgis3x');
    return this.reqService.service.queryCustom({
      url: this.currentUser.urlPortal + `/sharing/rest/community/groups/${groupid}/userList`,
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'GET',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }

  getListUserInGroupPortal(params: any, url: string, groupid: string) {
    this.reqService.switchType('arcgis3x');
    // var url = '';
    if (groupid != null) {
      url = url + `/sharing/rest/community/groups/` + groupid + `/userlist`
    }

    return this.reqService.service.queryCustom({
      url: url,
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'GET',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }
  getListUserPortal(params: any, url: string) {
    this.reqService.switchType('arcgis3x');
    return this.reqService.service.queryCustom({
      url: url + `/sharing/rest/portals/self/users/search`,
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'GET',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }


  CheckUserTokenPortal(params: any) {
    this.reqService.switchType('arcgis3x');
    return this.reqService.service.queryCustom({
      url: this.currentUser.urlPortal + '/sharing/rest/generateToken',
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'POST',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }








  addKvhc(params: any) {
    const odataParams = {
      url: this.url + '/SysKvhcs',
      primaryKey: 'KvhcId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }
  public updateKvhc(params: any) {
    const odataParams = {
      url: this.url + '/SysKvhcs',
      primaryKey: 'KvhcId',
      data: params
    };
    const resp = this.reqService.service.update(odataParams);
    return resp;
  }
  public deleteKvhc(params: any) {
    const odataParams = {
      url: this.url + '/SysKvhcs',
      primaryKey: 'KvhcId',
      data: params
    };
    const resp = this.reqService.service.delete(odataParams);
    return resp;
  }
  queryKVHC(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SysKvhcs',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  queryKVHCByUser(id: any) {
    const params = [['userId', '=', Number(id)]];
    const resp = this.reqService.service.search({
      url: this.url + '/SvUserKvhcs',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  public insertSysUserKvhcs(params: any) {
    const odataParams = {
      url: this.url + '/SysUserKvhcs',
      primaryKey: 'UserKvhcId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }

  public deleteSysUserKvhcs(id: any) {
    const params = { UserKvhcId: id };
    const odataParams = {
      url: this.url + '/SysUserKvhcs',
      primaryKey: 'UserKvhcId',
      data: params
    };
    const resp = this.reqService.service.delete(odataParams);
    return resp;
  }

  queryUser(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SvUserGroups',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  public deleteUser(params: any) {
    const odataParams = {
      url: this.url + '/SysUsers',
      primaryKey: 'UserId',
      data: params
    };
    const resp = this.reqService.service.delete(odataParams);
    return resp;
  }

  public queryUserById(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SysUsers',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  public addUser(params: any) {
    const odataParams = {
      url: this.url + '/SysUsers',
      primaryKey: 'UserId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }

  public updateUser(params: any) {
    const odataParams = {
      url: this.url + '/SysUsers',
      primaryKey: 'userId',
      data: params
    };
    const resp = this.reqService.service.update(odataParams);
    return resp;
  }

  public queryGroup(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SysGroups',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  queryApplication(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SysApplications',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  queryApplicationByUser(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SysAccesses',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  // queryAccess(whereClause: any) {
  //     const pTable = 'SysAccess';
  //     return this.connectDbService.searchODATA(this.url, pTable, whereClause);
  // }

  queryGroupRoleByApplication(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SysAccesses',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  queryRole(params: any) {
    const resp = this.reqService.service.search({
      url: this.url + '/SysRoles',
      where: params,
      logic: 'and'
    });
    return resp;
  }
    queryListUser(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysUsers',
            where: params,
            logic: 'and'
        });
        return resp;
    }
  // updateGroupRoleApp(params: any) {
  //     const odataParams = {
  //         url: this.url,
  //         table: 'SysAccess',
  //         primaryKey: 'AccessId',
  //         data: params
  //     };
  //     const resp = this.reqService.service.update(odataParams);
  //     return resp;
  // }

  insertGroupRoleApp(params: any) {
    const odataParams = {
      url: this.url + '/SysAccesses',
      primaryKey: 'AccessId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }

  deleteGroupRoleApp(id: any) {
    const params = { AccessId: id };
    const odataParams = {
      url: this.url + '/SysAccesses',
      primaryKey: 'AccessId',
      data: params
    };
    const resp = this.reqService.service.delete(odataParams);
    return resp;
  }
  // queryCalender(params: any) {
  //     const resp = this.reqService.service.search({
  //         url: this.url + '/CalCalendars',
  //         where: params,
  //         logic: 'and'
  //     });
  //     return resp;
  // }
  // queryUserCalender(params: any) {
  //     const resp = this.reqService.service.search({
  //         url: this.url + '/SysUserCalendars',
  //         where: params,
  //         logic: 'and'
  //     });
  //     return resp;
  // }
  // deleteUserCalendar(id: any) {
  //     const params = { SysUserCalendarId: id };
  //     const odataParams = {
  //         url: this.url + '/SysUserCalendars',
  //         primaryKey: 'SysUserCalendarId',
  //         data: params
  //     };
  //     const resp = this.reqService.service.delete(odataParams);
  //     return resp;
  // }
  // insertUserCalendar(params: any) {
  //     const odataParams = {
  //         url: this.url + '/SysUserCalendars',
  //         primaryKey: 'SysUserCalendarId',
  //         data: params
  //     };
  //     const resp = this.reqService.service.insert(odataParams);
  //     return resp;
  // }

}
