import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { CoreWindowService } from 'app/core/core-window/core-window.service';

@Component({
    selector: 'app-search-data',
    templateUrl: './search-data.component.html',
    styleUrls: ['./search-data.component.scss']
})
export class SearchDataComponent implements OnInit, OnChanges {
    private _isMapApp = false;
    /** Đánh dấu Form là dùng cho ứng dụng map hay không. Giá trị mặc định là false */
    get isMapApp(): any { return this._isMapApp; }
    set isMapApp(isMapApp: any) {
        this._isMapApp = isMapApp;
    }

    @Output() dataSearchAdv: EventEmitter<any> = new EventEmitter();

    public type = '';
    public dl: any = null;
    public renderComp = null;
    public isCountKQ = false;
    public coutKQ = null;
    @Input() currentTab: any = null;
    @Input() dataSelect = null;
    @Input() isShowAcceptBtn = false;
    @Input() isShowImportBtn = false;
    
    arrTab: any[] = [];
    arrTab_cache: any[] = [{
        title: 'Basic',
        id: 'basic',
        isDisplay: true
    }, {
        title: 'Advanced',
        id: 'advanced',
        isDisplay: true
    }]

    // Search from graphic
    showSearchByGraphic = false; // mặc định không hiển thị tìm kiếm đồ họa
    spatialRel = new FormControl();
    lookupSpatialRel = [
        { CODE: 1, DESCR: 'Contains' },
        { CODE: 2, DESCR: 'Intersects' },
        { CODE: 3, DESCR: 'Overlaps' }
    ];
    geometryForSearch = null;
    spatialRelationship = null;

    constructor(
        private appService: AppService,
        private coreWindowService: CoreWindowService
    ) { }

    ngOnInit() {
        // this.showSearchGeneral = false;
        // const infor = this.currentTab.INFORMATION;
        this.arrTab = JSON.parse(JSON.stringify(this.arrTab_cache)) 
        
        // Chưa có case nào sử dụng tab tổng hợp => chưa biết thiết kế ra sao => tắt đi
        // if (infor.TAB_LEVEL === '0' && infor.SEQ_NO === '1' && this.checkTongHop()) {
        //     this.showSearchGeneral = true;
        //     this.arrTab.push({
        //         title: 'Mixed',
        //         id: 'mixed',
        //         isDisplay: false
        //     })
        // }

        if (this.currentTab.INFORMATION.SERVICE_TYPE === 'FeatureServer' || this.currentTab.INFORMATION.SERVICE_TYPE === 'MapServer') {
            // Điều kiện 1: hiện tại chỉ bật khi service là arcgis
            // this.showSearchByGraphic = true;
            // Điều kiện 2: chỉ khi đồ họa và thuộc tính cùng chung bảng mới hiển thị
            const listLayer = this.appService.appConfig.Layers.map((item: any) => item.tableDoHoaId);
            if (listLayer && listLayer.length > 0) {
                if (listLayer.includes(this.currentTab.INFORMATION.TABLE_ID)) {
                    this.showSearchByGraphic = true;
                }
            }
        }

        this.spatialRel.valueChanges.subscribe(res => {
            if (res && res.CODE) {
                this.spatialRelationship = res.CODE;
            }
        });

        this.spatialRel.setValue(this.lookupSpatialRel[1]);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentTab) {
            // this.showSearchGeneral = false;
            // this.arrTab = JSON.parse(JSON.stringify(this.arrTab_cache))
            // const infor = this.currentTab.INFORMATION;
            // if (infor.TAB_LEVEL === '0' && infor.SEQ_NO === '1' && this.checkTongHop()) {
            //     this.showSearchGeneral = true;
            //     this.arrTab.push({
            //         title: 'Mixed',
            //         id: 'mixed',
            //         isDisplay: false
            //     })
            // }
        }

        if (changes.dataSelect) {
            this.isShowAcceptBtn = this.dataSelect ? true : false;
        }
    }

    public onDrawToolClick(type: any) {
        this.type = type;
        this.appService.searchByGraphic.emit({
            value: type,
            tab: this.currentTab,
            component: this
        });

        if (type === 'CLEAR') {
            this.setGraphic(null);
        }
    }

    // Lưu trữ đồ họa dùng để query dữ liệu
    public setGraphic(geom: any) {
        if (geom) {
            this.geometryForSearch = geom.clone().geometry;
        } else {
            this.geometryForSearch = null;
        }
    }

    private checkTongHop() {
        const tabList = [];
        const baseTabStruct = this.coreWindowService.lookupTab;
        if (baseTabStruct) {
            Object.keys(baseTabStruct).forEach(key => {
                const item = baseTabStruct[key];
                item.FIELD_LIST.forEach((field: any) => {
                    if (field.issearchtonghop === 'Y') {
                        tabList.push(field);
                    }
                });
            });
        }

        return tabList.length > 0 ? true : false;
    }

    querySearch(evt: any) {
        this.isCountKQ = false;
        evt.geometry = this.geometryForSearch;
        this.dl = evt;
        this.dataSearchAdv.emit(this.dl);
    }
}
