import { Injectable } from '@angular/core';
import { AppService } from '../../app-base/app.service';
import { RequestService } from 'app/core/services/request.service';

@Injectable({ providedIn: 'root' })
export class GroupService {
    public url = this.appService.urlWS;
    public tableName = 'SysGroup';

    constructor(
        private appService: AppService,
        private reqService: RequestService
    ) {
        this.reqService.switchType(this.appService.dataAccessType);
    }

    public queryGroup(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysGroups',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public getGroupById(id: any) {
        const params = [['GroupId', '=', id]];
        const resp = this.reqService.service.search({
            url: this.url + '/SysGroups',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addGroup(params: any) {
        const odataParams = {
            url: this.url + '/SysGroups',
            primaryKey: 'GroupId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public updateGroup(params: any) {
        const odataParams = {
            url: this.url + '/SysGroups',
            primaryKey: 'GroupId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    public deleteGroup(params: any) {
        const odataParams = {
            url: this.url + '/SysGroups',
            primaryKey: 'GroupId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    // public updateGroupInUser(params: any) {
    //     const odataParams = {
    //         url: this.url + '/SysUsers',
    //         primaryKey: 'UserId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }

    // queryUser(params: any) {
    //     const resp = this.reqService.service.search({
    //         url: this.url + '/SysUsers',
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }

    // updateGroupRoleUserApp(params: any) {
    //     const odataParams = {
    //         url: this.url + '/SysAccesses',
    //         primaryKey: 'AccessId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }

    // queryAccess(params: any) {
    //     const resp = this.reqService.service.search({
    //         url: this.url + '/SysAccesses',
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }
    // insertGroupRoleApp(params: any) {
    //     const odataParams = {
    //         url: this.url + '/SysAccesses',
    //         primaryKey: 'AccessId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.insert(odataParams);
    //     return resp;
    // }

    // deleteGroupRoleApp(id: any) {
    //     const params = { AccessId: id };
    //     const odataParams = {
    //         url: this.url + '/SysAccesses',
    //         primaryKey: 'AccessId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.delete(odataParams);
    //     return resp;
    // }
    queryCalender(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/CalCalendars',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    queryRoleCalender(params: any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysUserCalendars',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    
    deleteRoleCalendar(id: any) {
        const params = { SysUserCalendarId: id };
        const odataParams = {
            url: this.url + '/SysUserCalendars',
            primaryKey: 'SysUserCalendarId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    insertRoleCalendar(params: any) {
        const odataParams = {
            url: this.url + '/SysUserCalendars',
            primaryKey: 'SysUserCalendarId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    changeReadOnly(params: any) {
        const odataParams = {
            url: this.url + '/SysUserCalendars',
            primaryKey: 'SysUserCalendarId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
}
