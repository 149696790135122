<!-- <div class="sub-header menu-item" *ngIf="dataMenu.length > 0" [ngClass]="{'active': isShowSubHeader}">
    <div class="icon-list-menu"></div>
    <div class="container-menu" [ngClass]="{'active': menu.IS_ACTIVE}" *ngFor="let menu of dataMenu">
        <div style="position: relative;" (click)="onClickMenu(menu)">
            <span>{{menu.TITLE}}</span>
            <i class="pi pi-angle-down ml-3" *ngIf="menu.SUB_FUNCTION.length > 0 && !menu.IS_ACTIVE"></i>
            <i class="pi pi-angle-right ml-3" *ngIf="menu.SUB_FUNCTION.length > 0 && menu.IS_ACTIVE"></i>
        </div>

        <div class="aaaaaaa" *ngIf="menu.SUB_FUNCTION.length > 0 && menu.IS_ACTIVE">
            <ng-container *ngTemplateOutlet="subMenu; context:{ $implicit: menu.SUB_FUNCTION }">
            </ng-container>
            <ng-template #subMenu let-menu>
                <ul class="sub-menu-ul">
                    <ng-container *ngFor="let item of menu">
                        <li [ngClass]="{'li-active': item.IS_ACTIVE}">
                            <div (click)="onClickSubMenu(item, menu)">
                                <span>{{item.TITLE}}</span>
                                <i class="pi pi-angle-down ml-3"
                                    *ngIf="item.SUB_FUNCTION.length > 0 && !item.IS_ACTIVE"></i>
                                <i class="pi pi-angle-right ml-3"
                                    *ngIf="item.SUB_FUNCTION.length > 0 && item.IS_ACTIVE"></i>
                            </div>
                            <ng-container *ngIf="item.SUB_FUNCTION.length > 0 && item.IS_ACTIVE">
                                <div class="bbbbbbb">
                                    <ng-container *ngTemplateOutlet="subMenu; context:{ $implicit: item.SUB_FUNCTION }">
                                    </ng-container>
                                </div>
                            </ng-container>
                        </li>
                    </ng-container>
                </ul>
            </ng-template>
        </div>


    </div>
</div> -->

<div class="div-shortcut-class" *ngIf="dataMenu.length > 0" [ngClass]="{'show-shortcut-class': isShowSubHeader}">
    <!-- <div class="icon-list-menu">
        <i class="pi pi-th-large" style="font-size: 18px; color: var(--color_shortcut_text);"></i>
    </div> -->
    <div class="item-shortcut-class" [ngClass]="{'active': menu.IS_ACTIVE}"
        *ngFor="let menu of dataMenu; let i = index;">
        <div style="position: relative; display: flex; align-items: center;" (click)="onClickMenu(menu, i)">
            <span style="display: flex; align-items: center;">
                <i class="pi {{menu.ICON}}" ></i>
                <span style="padding-left: 5px;">
                    {{menu.DESCR && menu.DESCR !== '' ? menu.DESCR : menu.TITLE}}
                </span>
            </span>
            <i class="pi pi-angle-down ml-3" *ngIf="menu.SUB_FUNCTION.length > 0 && !menu.IS_ACTIVE"></i>
            <i class="pi pi-angle-right ml-3" *ngIf="menu.SUB_FUNCTION.length > 0 && menu.IS_ACTIVE"></i>
        </div>
        <div class="sub-shortcut-class" *ngIf="menu.SUB_FUNCTION.length > 0 && menu.IS_ACTIVE">
            <!-- <div>
                <ng-container *ngFor='let item of menu.SUB_FUNCTION'>
                    <div style="display: flex;">
                        <ng-container *ngTemplateOutlet="subMenu; context:{ $implicit: item }">
                        </ng-container>
                        <ng-container *ngIf='item.SUB_FUNCTION.length >0 && item.IS_ACTIVE'>
                            <div>
                                <ng-container *ngFor='let sub of item.SUB_FUNCTION'>
                                    <ng-container *ngTemplateOutlet="subMenu; context:{ $implicit: sub }">
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    
                </ng-container>
            </div> -->
            <!-- <ng-container *ngTemplateOutlet="subMenu; context:{ $implicit: menu.SUB_FUNCTION }">
            </ng-container> -->
            <p-tieredMenu [model]="arrMenu"></p-tieredMenu>
        </div>
    </div>
</div>
<div class="show-hide-sub-header" *ngIf="dataMenu.length > 0" (click)="onShowHideSubHeader()"
    [ngClass]="{'active': isShowSubHeader, 'deactive': !isShowSubHeader}">
    <i class="pi pi-angle-up" style="font-size: 1.5rem" *ngIf="isShowSubHeader"></i>
    <i class="pi pi-angle-down" style="font-size: 1.5rem" *ngIf="!isShowSubHeader"></i>
</div>
<ng-template #subMenu let-menu>
    <ng-container *ngFor="let item of menu">
        <div [ngClass]="{'li-active': item.IS_ACTIVE}" style="display: flex;">
            <div class="item-sub-sc-class" (click)="onClickSubMenu(item)">
                <span>{{item.TITLE}}</span>
                <i class="pi pi-angle-down ml-3" *ngIf="item.SUB_FUNCTION.length > 0 && !item.IS_ACTIVE"></i>
                <i class="pi pi-angle-right ml-3" *ngIf="item.SUB_FUNCTION.length > 0 && item.IS_ACTIVE"></i>
            </div>
            <ng-container *ngIf="item.SUB_FUNCTION.length > 0 && item.IS_ACTIVE">
                <div style="top: -100%;">
                    <ng-container *ngTemplateOutlet="subMenu; context:{ $implicit: item.SUB_FUNCTION }">
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <!-- <div class="item-sub-sc-class" (click)="onClickSubMenu(menu)">
        <span>{{menu.TITLE}}</span>
        <i class="pi pi-angle-down ml-3" *ngIf="menu.SUB_FUNCTION.length > 0 && !menu.IS_ACTIVE"></i>
        <i class="pi pi-angle-right ml-3" *ngIf="menu.SUB_FUNCTION.length > 0 && menu.IS_ACTIVE"></i>
    </div> -->

</ng-template>