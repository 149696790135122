import { EventEmitter, Injectable, Input, Output } from "@angular/core";
import { AppService } from "app/app-base/app.service";
import { RequestService, OdataParams } from "app/core/services/request.service";
import { map, take } from "rxjs/operators";
import { parse } from "zipson/lib";

@Injectable()
export class DashboardBuilderService {
  private _url = this.appService.urlWS + '/AppSessions';
  private currentUser = JSON.parse(this.appService.currentUser);
  // Sự kiện query Application Builder
  queryAppBuilder: EventEmitter<any> = new EventEmitter();
  loadedComponent: EventEmitter<any> = new EventEmitter();
  loadedLanguage: EventEmitter<any> = new EventEmitter();
  resetInputSearchApp: EventEmitter<any> = new EventEmitter();
  openAllAppComponent: EventEmitter<any> = new EventEmitter();
  filterSearch: EventEmitter<any> = new EventEmitter();
  evtEditSession: EventEmitter<any> = new EventEmitter();
  evtDeletelSession: EventEmitter<any> = new EventEmitter();
  loadingEvent: EventEmitter<any> = new EventEmitter();

  private _selectedSession: any;
  @Input()
  get selectedSession(): any {
    return this._selectedSession
  }

  set selectedSession(data: any) {
    this._selectedSession = data;
  }

  private _arrSection: any;
  @Input()
  get arrSection(): any {
    return this._arrSection;
  }
  set arrSection(data: any) {
    this._arrSection = data;
  }

  gettoken = (localStorage && localStorage.gistoken) ? localStorage.gistoken : '';
  imageGisDefault = 'assets/um-images/icon-tree-app/default_thumb.png';
  imageDefault = 'assets/imgs/application-default.png';


  @Output() clickMenuItemToDashboard: EventEmitter<any> = new EventEmitter();
  constructor(
    private appService: AppService,
    private reqService: RequestService,
  ) {
    this.reqService.switchType(this.appService.dataAccessType);
  }


  async getTokenArcGIS(type: string) {
    const _localStorage = JSON.parse(this.appService.currentUser);
    var token = '';
    if (type === 'O') {
      const urltoken = _localStorage.urlarcgisonline + '/sharing/rest/generateToken';
      this.appService.appConfig = {};
      this.appService!.appConfig!.Services = [{
        applicationId: 1,
        clientId: JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']).clientId,
        hasTileCache: '',
        isDisplay: null,
        isIdentify: '',
        isVisible: '',
        optionName: null,
        orderNo: 1,
        serviceId: 1,
        serviceName: '',
        serviceType: '',
        spatialReference: null,
        urlEdit: '',
        urlView: '',
        urlToken: urltoken,
        urlVectorTile: '',
        userNamePortal: _localStorage.userarcgisonline,
        passWordPortal: _localStorage.passwordarcgisonline,
      }];
      const url = JSON.parse(this.appService.currentUser!).urlarcgisonline;
      token = await this.appService.getArcGISToken(url);
    } else {
      token = this.gettoken
    }
    return token
  }

  async getBackgroundImg(app: any) {
    const arcgisTokenList = (localStorage && localStorage.arcgisTokenList) ? parse(localStorage.arcgisTokenList) : '';
    // const portalToken = arcgisTokenList[0].token;
    let urlImg = '';
    app.typeImg = '';
    if (app.isAppSystem && (app.isAppSystem === 'G' || app.isAppSystem === 'O')) {
      app.typeImg = 'G';
      if (app.icon) {
        urlImg = app.icon;
        if (app.icon.includes('Media/')) {
          app.typeImg = '';
          urlImg = this.appService.urlInit + app.icon;
        }

        // const isUrlPortal = app.icon.includes(this.currentUser.userPortal || this.currentUser.userarcgisonline);
        // if (isUrlPortal) {
        if (!app.icon.includes('?token=')) {
          let token = await this.getTokenArcGIS(app.isAppSystem)
          urlImg = `${app.icon}?token=${token}`;
          // if (app.isAppSystem === 'O') {
          // }
          // if (app.isAppSystem === 'G') {
          //   urlImg = `${app.icon}`;
          // }
        } else {
          urlImg = `${app.icon}`;
        }
        // }
      } else {
        urlImg = this.imageGisDefault;
      }
    } else {
      urlImg = (app.icon && app.icon !== '') ? this.appService.urlInit + app.icon : this.imageDefault;
    }
    app.icon = urlImg;
    return app;
  }

  getAppByUsername(params: any) {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/Login/CloudgetAppByUsername',
      params,
    }).pipe(map(res => {
      return res;
    }));
  }

  updateListAppForAppservice(params: any) {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/Login/cloudgetAppByUsername',
      params,
    }).subscribe(res => {
      this.appService.application = res.model
    })
  }

  cloudgetAppByUsername(params: any) {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/Login/cloudgetAppByUsername',
      params,
    }).pipe(map(res => {
      return res;
    }));
  }
  queryCountApp(params: any) {
    this.reqService.switchType('arcgis3x');
    return this.reqService.service.queryCustom({
      // url: this.currentUser.urlPortal + '/' + this.currentUser.userPortal + '/sharing/rest/search',
      url: this.currentUser.userPortal + '/sharing/rest/search',
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'GET',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }
  queryAppGis(params: any) {
    this.reqService.switchType('arcgis3x');
    return this.reqService.service.queryCustom({
      // url: this.currentUser.urlPortal + '/' + this.currentUser.userPortal + '/sharing/rest/search',
      url: this.currentUser.urlPortal + '/sharing/rest/search',
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'GET',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }
  queryAppArcgisOnline(params: any) {
    this.reqService.switchType('arcgis3x');
    return this.reqService.service.queryCustom({
      // url: this.currentUser.urlPortal + '/' + this.currentUser.userPortal + '/sharing/rest/search',
      url: this.currentUser.urlarcgisonline + '/sharing/rest/search',
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'GET',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }
  queryGroupArcgis(url: string, params: any) {
    this.reqService.switchType('arcgis3x');
    return this.reqService.service.queryCustom({
      // url: this.currentUser.urlPortal + '/' + this.currentUser.userPortal + '/sharing/rest/search',
      url: url,
      params,
      responseType: 'json',
      contentType: 'application/json;charset=utf-8',
      method: 'GET',
      proxy: this.appService.urlProxy
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }
  getAuthorize() {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/Config/file_get_contents?username=' + JSON.parse(this.appService.currentUser).username,
      params: {},
      responseType: 'text',
      contentType: 'text/plain',
      method: 'GET'
    });
  }

  async queryListSession() {
    const data = await this.findAllSession().pipe(take(1)).toPromise();
    const sections = data.features
    this.arrSection = sections
  }

  findAllSession() {
    const p: OdataParams = {
      url: this._url,
      where: [['UserId', '=', JSON.parse(this.appService.currentUser!).userid]], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 10, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public querySession(id: any) {
    const p: OdataParams = {
      url: this._url,
      where: ['AppSessionId', '=', id], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 10, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public querySvsectionapps(clientid: string, userid: any) {
    const urlSvsectionapp = this.appService.urlWS + '/svsectionapps'
    const p: OdataParams = {
      url: urlSvsectionapp,
      where: [['clientId', '=', clientid], ['UserId', '=', userid]], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 10, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public querySvsectionappForSectionID(params: any) {
    const { clientid, applicationId, userid } = params;
    const urlSvsectionapp = this.appService.urlWS + '/svsectionapps'
    const p: OdataParams = {
      url: urlSvsectionapp,
      where: [['clientId', '=', clientid], ['applicationId', '=', applicationId], ['UserId', '=', userid]], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 10, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  insertSession(params: any) {
    const p: OdataParams = {
      url: this._url,
      data: params, // Thông số bắt buộc, dữ liệu dạng object để insert vào bảng
      primaryKey: 'AppSessionId' // Thông số bắt buộc, khóa chính của bảng
    };
    return this.reqService.service.insert(p);
  }

  public updateSession(params: any) {
    const p: OdataParams = {
      url: this._url,
      data: params, // Thông số bắt buộc, dữ liệu dạng object để sửa vào bảng, trong data phải có trường khóa chính
      primaryKey: 'AppSessionId' // Thông số bắt buộc, khóa chính của bảng
    };
    return this.reqService.service.update(p);
  }

  public deletelSession(param: any) {
    const p: OdataParams = {
      url: this._url, // Thông số bắt buộc, nhưng có thể để string rỗng '',
      data: param, // Thông số bắt buộc, dữ liệu dạng object của một bản ghi, trong data phải có trường khóa chính
      primaryKey: 'AppSessionId' // Thông số bắt buộc, khóa chính của bảng
    };
    return this.reqService.service.delete(p);
  }

  public queryApplicationbyId(id: any) {
    const params = [['ApplicationId', '=', id]];
    const resp = this.reqService.service.search({
      url: this.appService.urlWS + '/SysApplications',
      where: params,
      logic: 'and'
    });
    return resp;
  }
  public updateApplicationGis(obj: any) {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/SysApplications/UpdateSysApplication',
      params: obj,
      method: 'POST'
    });
  }
  public updateApplication(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SysApplications',
      primaryKey: 'applicationId',
      data: params
    };
    const resp = this.reqService.service.update(odataParams);
    return resp;
  }
  public deletelSection(param: any) {
    const p: OdataParams = {
      url: this.appService.urlWS + '/SectionApps',
      data: param, // Thông số bắt buộc, dữ liệu dạng object của một bản ghi, trong data phải có trường khóa chính
      primaryKey: 'sectionAppId' // Thông số bắt buộc, khóa chính của bảng
    };
    return this.reqService.service.delete(p);
  }

  public updateSectionApplication(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SectionApps',
      primaryKey: 'sectionAppId',
      data: params
    };
    const resp = this.reqService.service.update(odataParams);
    return resp;
  }
  public createSection(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SectionApps',
      primaryKey: 'sectionAppId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }

  public updateUser(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SysUsers',
      primaryKey: 'UserId',
      data: params
    };
    const resp = this.reqService.service.update(odataParams);
    return resp;
  }

  public queryUserById(id: any) {
    const resp = this.reqService.service.search({
      url: this.appService.urlWS + '/SysUsers',
      where: ['UserId', '=', id],
      logic: 'and',
      primaryKey: 'UserId'
    });
    return resp;
  }
}
