<ng-templte #formBack>
    <div class="wp">
        <div class="wp-nextstep">
            <app-core-radio-button [listRadio]="listRadio" [orientation]="'horizontal'"
            [formControl]="radioCtrl" (onChange)="onCheckboxChange($event)"></app-core-radio-button>
            <app-select [required]="true" [labelName]="'Assign to' | translate" [isLabelLeft]="false" [formControl]="username" 
                [dataLookup]="arrUser" displayField="DESCR" valueField="CODE" [ngClass]="{'d-none': !isShowUser}"> </app-select>
            <app-input [labelName]="'Note' | translate" [isLabelLeft]="false" [formControl] ="noteJob"  type="text-area"> </app-input>
            <div class="btn-back">
                <app-button  labelName='Cancel' typeButton="cancel" (clickEvent)="onCancel()"></app-button>
                <app-button class="btn-ok" labelName='Ok' typeButton="save" (click)="ok()"></app-button>
               
            </div>
        </div>
    </div>
</ng-templte>

<app-dialog-prime #dialogPrime [isAppendBody]="true"></app-dialog-prime>

