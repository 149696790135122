<ng-container *ngIf="dataSource && isActive; else noData">
    <app-search-data [currentTab]="dataSource" (dataSearchAdv)="onSearch($event)" [dataSelect]="currentData"
        [isShowAcceptBtn]="showAcceptBtn"></app-search-data>
    <div>
        <p-table [columns]="tblColumnDef" [value]="resultList" [scrollable]="true" [autoLayout]="true"
            [dataKey]="primaryKey" [metaKeySelection]="true" selectionMode="multiple" [(selection)]="currentData"
            scrollHeight="250px" scrollDirection="both">
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <ng-container *ngFor="let col of columns">
                        <col style="min-width: 150px;">
                    </ng-container>
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" pFrozenColumn [frozen]="col.rowIsFrozen">
                        {{col.rowHeader}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr (dblclick)="onRowDoubleClick(rowData)" [pSelectableRow]="rowData">
                    <!-- [class.active]="currentData !== null && primaryKey !== null ? currentData[primaryKey] === rowData[primaryKey] : false" -->
                    <ng-container *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.rowType">
                            <td class="tbl-td-item" pFrozenColumn [frozen]="col.rowIsFrozen">
                                <ng-container *ngSwitchCase="'select'">
                                    {{rowData[col.rowFieldName + '_C']}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'search'">
                                    {{rowData[col.rowFieldName + '_S']}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'date'">
                                    {{rowData[col.rowFieldName + '_D']}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'datetime'">
                                    {{rowData[col.rowFieldName + '_DT']}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'number'">
                                    {{rowData[col.rowFieldName + '_N']}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'img64'">
                                    <img [src]="rowData[col.rowFieldName]" />
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{rowData[col.rowFieldName] || '-'}}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</ng-container>

<ng-template #noData>
</ng-template>