<div style="height: 100%" class="tab-excel-class">
    <ng-container *ngIf="!isNext">
        <div class="row" style="padding: 10px 0px; margin: 0px;">
            <div class="col-md-6">
                <app-select [hasFormCtrl]="false" [(valueModel)]="val_file.currentSheet" displayField="name"
                    valueField="code" [labelName]="'Sheet' | translate" [dataLookup]="configImport.arrSheet"
                    [isLabelLeft]="true" [required]="true" (valueChange)="onChangeSheet($event, val_file)">
                </app-select>
            </div>
            <div class="col-md-6">
                <app-select [hasFormCtrl]="false" [(valueModel)]="val_file.rowheader" displayField="name"
                    valueField="code" [labelName]="'Row header' | translate" [dataLookup]="dataRowHeader"
                    [isLabelLeft]="true" [required]="true" (valueChange)="onchangeRowHeader($event)">
                </app-select>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4 div-row-importdata-class" style="font-weight: bold;">{{'Field Name' | translate}}</div>
            <div class="col-md-8 div-row-importdata-class" style="font-weight: bold;">{{'Excel Column' | translate}}
            </div>
        </div>
        <ng-container *ngFor="let item of configImport.arrFieldTable">
            <div class="row">
                <div class="col-md-4 div-row-importdata-class">{{item.name}}</div>
                <div class="col-md-8 div-row-importdata-class">
                    <app-select [hasFormCtrl]="false" [(valueModel)]="item['columnExcel']" displayField="name"
                        [required]="item.nullable ? false : true" valueField="code" [showLabel]='false'
                        [dataLookup]="arrColumnExcel" [isLabelLeft]="true">
                    </app-select>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="divBtnClass">
        <app-button [labelName]='"Close" | translate' typeButton="reset" (clickEvent)="onClickReset()">
        </app-button>

        <app-button [labelName]=' isNext ? "Previous" : "Next" | translate' typeButton="save" (clickEvent)="onNextClick()" icon='pi pi-save'
            style="margin-left: 10px;">
        </app-button>
    </div>
    <!-- <p-tabView [(activeIndex)]="indexExcelTab">
        <ng-container *ngFor="let item of dataExcel">
            <p-tabPanel [header]="item.tabName">

                <ng-container *ngTemplateOutlet="tmpImportData,  context: {dataCol: item};"></ng-container>
            </p-tabPanel>
        </ng-container>


    </p-tabView> -->
</div>
<!-- <ng-template #tmpImportData let-dataCol='dataCol'>
    <div style="height:100%; overflow: auto; padding-right: 10px;">
        <p-table [value]="dataImport" styleClass="p-datatable-gridlines" responsiveLayout="scroll"
            columnResizeMode="expand" [resizableColumns]="true" (onEditComplete)="onEditComplete($event)"
            (onEditInit)="onEditInit($event)">
            <ng-template pTemplate="header">
                <th style="background: var(--color_core_header); border-right: 1px solid var(--color_border); width: 20px;"
                    pResizableColumn></th>
                <ng-container *ngFor="let col of dataHeader">
                    <th style="padding: 1rem; background: var(--color_core_header); border-right: 1px solid var(--color_border); color: var(--color_text);"
                        pResizableColumn>
                        {{col.title | translate}}</th>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                <tr>
                    <td style="background: var(--color_core_header); width: 20px;">{{rowIndex + 1}}</td>
                    <ng-container *ngFor="let col of dataHeader; let ind = index">
                        <td [pEditableColumn]='data' [pEditableColumnRowIndex]='rowIndex'
                            [pEditableColumnField]='data[col.index]["index"]'
                            [ngStyle]="{'box-shadow': data[col.index]['color']  ? data[col.index]['color'] === '#FF0000' && (data[col.index]['value'] === '' || data[col.index]['value'] === null) ? 'inset 0 -1px 0px #FF0000' : 'none' : 'none' }">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="data[col.index]['value']">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span
                                        [ngStyle]="{'color': data[col.index]['color'] ? data[col.index]['color'] : 'var(--color_text)' }">
                                        {{data[col.index]['value']}}</span>
                                </ng-template>
                            </p-cellEditor>

                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</ng-template> -->