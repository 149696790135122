import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'app/app-base/app.service';
import { RequestService } from 'app/core/services/request.service';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-training-price-calculation',
    templateUrl: './training-price-calculation.component.html',
    styleUrls: ['./training-price-calculation.component.scss']
})
export class TrainingPriceCalculationComponent implements OnInit {
    isDisableVehice: boolean = true;
    checkOptionsOne = [
        { label: 'Travel by Airplane', value: 1 },
        { label: 'Travel by Car', value: 2 }
    ]
    formData: any = null;
    checked: any = false;
    arrContent: any[] = [];
    urlQuotation = '';
    formCtrlAddCost: any = null;
    // arrCtrlAddCost = [['NAME_0', 'COST_0']];
    arrCtrlAddCost: any = [];
    arrContentCache: any = [];
    arrPrice: any = [];
    arrProductGroup: any = [];
    arrComputer: any = [];
    isDisabledOutside = true;
    total_price = this.decimal.transform(+0, "1.2-2");
    _ItemId: any = null;
    currentData: any = null;
    arrProducrRealCost: any = [];
    arrRealCache: any = [];
    priceAdd_caache = 0;
    priceTotal_cache = 0;
    priceReal_cache = 0;
    arr_view_cache: any[] = [];
    parentData: any = null;
    classId: any = null;
    arrItemType: any = [];
    arrTS_Cache: any = [];
    arrTS_Real_Cache: any = [];
    total_table = 0;
    str_total: any = "";
    str_factor = "";
    str_heso = "";
    priceWeekendCost = 0;
    @Input() titleSaveBtn = 'Add';
    @Input() mode: 'add' | 'edit' = 'add';
    @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
    @Output() reloadRoot: EventEmitter<any> = new EventEmitter();
    arrTrainingService: any[] = [];
    constructor(
        public reqService: RequestService,
        public appService: AppService,
        public decimal: DecimalPipe
    ) {
        
        this.urlQuotation = this.appService.urlInitQutation;
        this.initData();
    }

    ngOnInit(): void {
    }
    run(evt: any) {
        this.mode = 'add';
      
        this.parentData = evt.parentData;
        this.initForm();
        if (evt.menu.name !== "Add Training Class") {
            if (evt.currentData.length > 0) {
                if (evt.currentData.length > 1) {
                    this.onCloseEvent.emit(false);
                    this.appService.createMessage("warning", "Select only 1 quotaion item");
                } else {
                    this.currentData = evt.currentData[0];
                    if (this.currentData.ItemTypeCode === 'TRAINING') {
                        const itemId = evt.currentData[0].ItemId;
                        this.titleSaveBtn = 'Update';
                        this.mode = 'edit';
                        const params = ["ItemId", "=", itemId];
                        this.reqService.service.search({
                            url: this.urlQuotation + '/VTrainingclasses',
                            where: params
                        }).subscribe(res => {
                            if (res.success) {
                                if (res.features.length > 0) {
                                    this.classId = res.features[0].ClassId;
                                    this.arr_view_cache = res.features;
                                    setTimeout(() => {
                                        this.bindData(res.features);
                                    }, 500)

                                }

                            }
                        });
                    } else {
                        this.onCloseEvent.emit(false);
                        this.appService.createMessage("warning", "This isn't a training class");
                    }
                }

            } else {
                this.onCloseEvent.emit(false);
                this.appService.createMessage("warning", "Hasn't choose a quotation item yet!");
            }


        } else {
            if (evt.parentData !== null) {
                this.mode = 'add';
                setTimeout(() => {
                    this.formData.controls['CLASS_TYPE'].setValue(1, { emitEvent: false });
                    this.formData.controls['CONTENT_GROUP'].setValue(this.arrContent.length > 0 ? this.arrContent[0].CODE : null, { emitEvent: false });
                }, 500);

            } else {
                this.onCloseEvent.emit(false);
                this.appService.createMessage("warning", "Hasn't choose a quotation yet!");
            }

        }



    }
    bindData(data: any) {
        const dataContent = this.arrContent.filter((v: any) => v.CODE === data[0].ContentGroupId)[0];
        const value: any = {
            CLASS_NAME: data[0].ClassName,
            CLASS_TYPE: parseFloat(data[0].ClassType),
            TRAINING_DAY: this.decimal.transform(+data[0].QtyDay, "1.2-2"),
            NO_STUDENT: data[0].QtyStudent,
            CONTENT_GROUP: dataContent.ContentGroupId,
            LOCATION: data[0].LocationCode === 'Hanoi' ? 1 : data[0].LocationCode === 'HCMC' ? 2 : 3,
            TRAVEL_BY: null,
            Manager: 0,
            Senior: 0,
            Staff: 0,
            COMPUTER: null,
            AIRPLANE_PRICE: this.decimal.transform(+0, "1.2-2"),
            CHK_AIRPLANE: false,
            CAR_PRICE: this.decimal.transform(+0, "1.2-2"),
            CHK_CAR: false,
        }
        Object.keys(this.formCtrlAddCost.controls).forEach(key => {
            this.formCtrlAddCost.removeControl(key);
        });
        // this.formCtrlAddCost = new FormGroup({});
        this.arrCtrlAddCost = [];
        let count = -1;
        let value_addcost: any = {};
        data.forEach((item: any, ind: any) => {
            if (item.PartNo === 'Manager' || item.PartNo === 'Senior' || item.PartNo === 'Staff') {
                value[item.PartNo] = item.TrainingQty;
            }
            const com = this.arrComputer.filter((v: any) => v.TraningServiceId === item.ProductId);
            if (com.length > 0) {
                value['COMPUTER'] = item.ProductId;
            }
            if (item.PriceRealCost !== null && item.PriceRealCost > 0) {
                if (item.PartNo === 'Airplane') {
                    value['CHK_AIRPLANE'] = true;
                    value['AIRPLANE_PRICE'] = item.PriceRealCost;
                } else if (item.PartNo === 'CarOrBus') {
                    value['CHK_CAR'] = true;
                    value['CAR_PRICE'] = item.PriceRealCost;
                } else {
                    count++;
                    const keyName = 'NAME_' + count;
                    const keyCost = 'COST_' + count;
                    this.arrCtrlAddCost.push(['NAME_' + count, 'COST_' + count]);
                    this.formCtrlAddCost.addControl('NAME_' + count, new FormControl());
                    this.formCtrlAddCost.addControl('COST_' + count, new FormControl());
                    value_addcost[keyName] = item.PartNo;
                    value_addcost[keyCost] = item.PriceRealCost;


                }
            }

        });
        // this.onClickCalculate();
        this.formData.setValue(value);
        setTimeout(() => {
            this.formCtrlAddCost.setValue(value_addcost)
        }, 100);

    }
    initData() {
        this.arrComputer = [];
        this.arrContent = [];
        const param: any[] = [];
        this.reqService.service.search(
            {
                url: this.urlQuotation + '/ContentGroups',
                where: param
            }
        ).subscribe(res => {
            if (res.success) {
                // this.arrContent = res.features;
                res.features.forEach((item: any) => {
                    item.CODE = item.ContentGroupId,
                        item.DESCR = item.ContentGroupName
                    this.arrContent.push(item)
                })
            }

        });
        const parampr = ["TraningServiceTypeId", "=", 19];
        this.reqService.service.search(
            {
                url: this.urlQuotation + '/TraningServices',
                where: parampr
            }
        ).subscribe(res => {
            if (res.success) {
                this.arrRealCache = res.features;
                this.arrProducrRealCost = [];
                res.features.forEach(item => {
                    if (item.PartNo !== 'Airplane' && item.PartNo !== 'CarOrBus') {
                        this.arrProducrRealCost.push({
                            CODE: item.PartNo,
                            DESCR: item.ProductName
                        })
                    }

                });
            }

        });
        combineLatest([this.reqService.service.search({
            url: this.urlQuotation + '/TraningServiceTypes',
            where: []
        }), this.reqService.service.search({
            url: this.urlQuotation + '/VTrainingPrices',
            where: []
        })]).subscribe(res => {
            res[0].features.forEach(item => {
                res[1].features.forEach(pr => {
                    if (pr.ProductGroupId === item.TraningServiceTypeId) {
                        pr.ProductGroupName = item.TraningServiceTypeName;
                    }
                })
            });
            // this.arrProductGroup = res[1].features.filter((v: any) => v.ProductGroupName === "Location");
            this.arrPrice = res[1].features;
            // this.arrComputer = res[1].features.filter((v: any) => v.ProductGroupName === "Computer");
            this.arrComputer = [];
            res[1].features.forEach((item: any) => {
                if (item.ProductGroupName === 'Computer') {
                    // this.arrComputer.push()
                    item.CODE = item.TraningServiceId,
                        item.DESCR = item.ProductName
                    this.arrComputer.push(item)
                }
            })
        });

        this.reqService.service.search(
            {
                url: this.urlQuotation + '/ItemTypes',
                where: []
            }
        ).subscribe(res => {
            if (res.success) {
                this.arrItemType = res.features;
            }

        });
    }
    initForm() {
        this.formData = new FormGroup({
            CLASS_NAME: new FormControl(),
            CLASS_TYPE: new FormControl(1, Validators.required),
            TRAINING_DAY: new FormControl(this.decimal.transform(+0, "1.2-2")),
            NO_STUDENT: new FormControl(0),
            CONTENT_GROUP: new FormControl(null, Validators.required),
            LOCATION: new FormControl(1, Validators.required),
            TRAVEL_BY: new FormControl(),
            Manager: new FormControl(0),
            Senior: new FormControl(0),
            Staff: new FormControl(0),
            COMPUTER: new FormControl(null, Validators.required),
            AIRPLANE_PRICE: new FormControl(this.decimal.transform(+0, "1.2-2")),
            CHK_AIRPLANE: new FormControl(false),
            CAR_PRICE: new FormControl(this.decimal.transform(+0, "1.2-2")),
            CHK_CAR: new FormControl(false),
            // TOTAL_PRICE: new FormControl()
        });
        // setTimeout(() => {
        //     this.formData.controls['CONTENT_GROUP'].setValue(this.arrContent.length > 0 ? this.arrContent[0].ContentGroupId : null);
        // }, 1000)
        this.formData.controls['LOCATION'].valueChanges.subscribe((val: any) => {
            const param = this.appService.returnParam(this.formData);
            if (param['LOCATION'] === 3) {
                this.isDisableVehice = false;
            } else {
                this.isDisableVehice = true;
            }
            this.formData.controls['TRAVEL_BY'].setValue(0);
        });

        this.formData.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
            const val = this.appService.returnParam(this.formData)
            // val.CAR_PRICE = val.CAR_PRICE === null || val.CAR_PRICE === '' ? 0 : val.CAR_PRICE
            // val.AIRPLANE_PRICE = val.AIRPLANE_PRICE === null || val.AIRPLANE_PRICE === '' ? 0 : val.AIRPLANE_PRICE
            this.arrTrainingService = [];
            let arrTS: any[] = [];
            if (val['Manager'] === null) {
                this.formData.controls['Manager'].setValue(0)
                val.Manager = 0
            } else if (val['Senior'] === null) {
                this.formData.controls['Senior'].setValue(0)
                val.Senior = 0
            } else if (val['Staff'] === null) {
                this.formData.controls['Staff'].setValue(0)
                val.Staff = 0
            } else if (val['NO_STUDENT'] === null) {
                this.formData.controls['NO_STUDENT'].setValue(0)
                val.NO_STUDENT = 0
            } else if (val['TRAINING_DAY'] === null) {
                this.formData.controls['TRAINING_DAY'].setValue(this.decimal.transform(+0, "1.2-2"))
                val.TRAINING_DAY = 0
            } else if (val['AIRPLANE_PRICE'] === null) {
                this.formData.controls['AIRPLANE_PRICE'].setValue(this.decimal.transform(+0, "1.2-2"))
                val.AIRPLANE_PRICE = 0
            } else if (val['CAR_PRICE'] === null) {
                this.formData.controls['CAR_PRICE'].setValue(this.decimal.transform(+0, "1.2-2"))
                val.CAR_PRICE = 0
            }
            let resultCal = 0;
            let heso = 0;
            this.priceTotal_cache = 0;
            // this.total_price = 0;
            const arr = this.arrContent.filter((v: any) => v.CODE === val['CONTENT_GROUP']);
            if (arr.length > 0) {
                // heso = val.CLASSTYPE === 1 ? arr[0].HeSo * 1.5 : arr[0].HeSo;
                heso = arr[0].HeSo * val.CLASS_TYPE;
                // const hesoClassType = arr[0].IsCustomize === "Y" ? 1.5 : 1;
                const classType = val.CLASS_TYPE === 1 ? "Standard" : "Customize"
                this.str_factor = "Factor of " + arr[0].ContentGroupName + " " + classType + " Class (Class Type Factor * Content group Factor): ";
                this.str_heso = val.CLASS_TYPE + " * " + arr[0].HeSo
            }
            let priceLocation = 0;
            let priceMaterial = 0;
            let pricePerDiem = 0;
            let priceManager = 0;
            let priceSenior = 0;
            let priceStaff = 0;
            let priceAirplane = 0;
            let priceCar = 0;

            if (val.LOCATION !== 3 && val.LOCATION !== null) {
                const arrLocation = this.arrPrice.filter((v: any) => v.PartNo === 'Location');

                priceLocation = arrLocation.length > 0 ? arrLocation[0].PriceCost : 0;
            } else if (val.LOCATION === 3) {
                pricePerDiem = this.arrPrice.filter((v: any) => v.PartNo === 'Per-Diem').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Per-Diem')[0].PriceCost : 0;
                if (val.CHK_AIRPLANE) {
                    priceAirplane = parseFloat(val.AIRPLANE_PRICE) * (parseFloat(val.Manager) + parseFloat(val.Senior) + parseFloat(val.Staff));
                }
                if (val.CHK_CAR) {
                    priceCar = parseFloat(val.CAR_PRICE) * (parseFloat(val.Manager) + parseFloat(val.Senior) + parseFloat(val.Staff));
                }
            }
            let priceComputer = 0;
            if (val.COMPUTER !== null) {
                priceComputer = this.arrPrice.filter((v: any) => v.TraningServiceId === val.COMPUTER).length > 0 ? this.arrPrice.filter((v: any) => v.TraningServiceId === val.COMPUTER)[0].PriceCost : 0;
            }
            priceManager = this.arrPrice.filter((v: any) => v.PartNo === 'Manager').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Manager')[0].PriceCost : 0;
            priceSenior = this.arrPrice.filter((v: any) => v.PartNo === 'Senior').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Senior')[0].PriceCost : 0;
            priceStaff = this.arrPrice.filter((v: any) => v.PartNo === 'Staff').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Staff')[0].PriceCost : 0;
            if (this.arrPrice.filter((v: any) => v.PartNo === 'Materials').length > 0) {
                priceMaterial = this.arrPrice.filter((v: any) => v.PartNo === 'Materials').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Materials')[0].PriceCost : 0;
            }
            // priceMaterial = this.arrPrice.filter((v: any) => v.ProductName === 'Training Materials')[0].PriceCost ? this.arrPrice.filter((v: any) => v.ProductName === 'Training Materials')[0].PriceCost : 0;

            const priceTotal = priceLocation * parseFloat(val.TRAINING_DAY) + priceComputer * parseFloat(val.TRAINING_DAY) * parseFloat(val.NO_STUDENT) +
                (priceManager * parseFloat(val.Manager) * (parseFloat(val.TRAINING_DAY) + 1)) * heso + (priceSenior * parseFloat(val.Senior) * (parseFloat(val.TRAINING_DAY) + 1)) * heso +
                (priceStaff * parseFloat(val.Staff) * (parseFloat(val.TRAINING_DAY) + 1)) * heso + pricePerDiem * (parseFloat(val.Senior) + parseFloat(val.Manager) + parseFloat(val.Staff)) * (parseFloat(val.TRAINING_DAY) + 2)
                + priceMaterial * parseFloat(val.NO_STUDENT) + priceAirplane + priceCar;
            // this.formData.controls['TOTAL_PRICE'].setValue(priceTotal);
            // resultCal = \
            let price_real = 0;
            this.arrTS_Real_Cache.forEach((item: any) => {
                if (item.partno === 'WeekendServicecost') {
                    item.priceCal = item.price * 0.5 * (priceManager * parseFloat(val.Manager) + priceSenior * parseFloat(val.Senior) + priceStaff * parseFloat(val.Staff));
                }
                price_real += item.priceCal;
            })

            resultCal = Math.round(priceTotal * 100) / 100;
            this.priceTotal_cache = Math.round(priceTotal * 100) / 100;
            this.total_price = this.decimal.transform(+(resultCal + price_real), "1.2-2");
            this.total_table = resultCal + price_real;
            this.arrPrice.forEach((item: any) => {
                if (item.PartNo === 'Location') {
                    if (val.LOCATION !== 3) {
                        arrTS.push({
                            name: val['LOCATION'] === 1 ? 'Esri VN Hanoi Office' : 'Esri VN HCMC Office',
                            price: item.PriceCost,
                            formular: item.ForMular,
                            priceCal: priceLocation * parseFloat(val.TRAINING_DAY),
                            partno: item.PartNo
                        })
                    }
                } else if (item.PartNo === 'Manager' || item.PartNo === 'Senior' || item.PartNo === 'Staff') {
                    if (val[item.PartNo] > 0) {
                        arrTS.push({
                            name: item.Desrc,
                            price: item.PriceCost,
                            formular: item.ForMular,
                            priceCal: parseFloat(item.PriceCost) * (parseFloat(val['TRAINING_DAY']) + 1) * parseFloat(val[item.PartNo]) * heso,
                            partno: item.PartNo
                        })
                    }
                } else if (item.PartNo === 'CustormerComputers' || item.PartNo === 'EsriVNComputers') {
                    if (item.TraningServiceId === val['COMPUTER']) {
                        arrTS.push({
                            name: item.Desrc,
                            price: item.PriceCost,
                            formular: item.ForMular,
                            priceCal: priceComputer * parseFloat(val.TRAINING_DAY) * parseFloat(val.NO_STUDENT),
                            partno: item.PartNo
                        });
                    }
                } else if (item.PartNo === 'Per-Diem') {
                    if (val.LOCATION === 3) {
                        arrTS.push({
                            name: item.Desrc,
                            price: item.PriceCost,
                            formular: item.ForMular,
                            priceCal: pricePerDiem * (parseFloat(val.Senior) + parseFloat(val.Manager) + parseFloat(val.Staff)) * (parseFloat(val.TRAINING_DAY) + 2),
                            partno: item.PartNo
                        });
                    }

                } else if (item.PartNo === 'Materials') {
                    arrTS.push({
                        name: item.Desrc,
                        price: item.PriceCost,
                        formular: item.ForMular,
                        priceCal: priceMaterial * parseFloat(val.NO_STUDENT),
                        partno: item.PartNo
                    });
                } else if (item.PartNo === 'Airplane') {
                    if (val.LOCATION === 3) {

                        if (val.CHK_AIRPLANE && parseFloat(val.AIRPLANE_PRICE) > 0) {
                            arrTS.push({
                                name: item.Desrc,
                                price: parseFloat(val.AIRPLANE_PRICE),
                                formular: item.ForMular,
                                priceCal: priceAirplane,
                                partno: item.PartNo
                            });
                        }

                    }

                } else if (item.PartNo === 'CarOrBus') {
                    if (val.LOCATION === 3) {

                        if (val.CHK_CAR && parseFloat(val.CAR_PRICE) > 0) {
                            arrTS.push({
                                name: item.Desrc,
                                price: parseFloat(val.CAR_PRICE),
                                formular: item.ForMular,
                                priceCal: priceCar,
                                partno: item.PartNo
                            });
                        }

                    }
                }
            });
            this.arrTS_Cache = arrTS;
            this.arrTrainingService = arrTS.concat(this.arrTS_Real_Cache);
            this.str_total = "";
            this.arrTrainingService.forEach(item => {
                // if (item.partno !== 'Manager' && item.partno !== 'Senior' && item.partno !== 'Staff') {

                // } else {
                //     this.str_total += " " + item.name + " * Factor +"
                // }
                this.str_total += " " + item.name + " +";
            });
            this.str_total = this.str_total.substr(0, this.str_total.length - 2);

        });
        this.formCtrlAddCost = new FormGroup({});
        this.arrCtrlAddCost.forEach((item: any) => {
            this.formCtrlAddCost.addControl(item[0], new FormControl(null));
            this.formCtrlAddCost.addControl(item[1], new FormControl(0))
        });
        this.formCtrlAddCost.valueChanges.subscribe((val_cache: any) => {
            const valueForm = this.appService.returnParam(this.formData)
            const val = this.appService.returnParam(this.formCtrlAddCost)
            let totalAddCost = 0;
            let priceHotel = 0;
            let priceEntertainment = 0;
            let priceHiringComputer = 0;
            let priceWeekendCost = 0;
            this.priceReal_cache = 0;
            this.arrTS_Real_Cache = [];
            this.arrTrainingService = this.arrTS_Cache;
            Object.keys(valueForm).forEach(key => {
                if (key.indexOf('COST_') > -1) {
                    this.formCtrlAddCost.controls[key].setValue(val[key], { emitEvent: false })
                }
            })
            this.arrCtrlAddCost.forEach((item: any) => {

                if (val[item[0]] !== null && val[item[0]] !== '' && val[item[0]] !== undefined) {
                    if (val[item[0]] === 'Hotel') {
                        const priceCache = parseFloat(val[item[1]]);
                        priceHotel = priceCache * (parseFloat(valueForm.Senior) + parseFloat(valueForm.Manager) + parseFloat(valueForm.Staff)) * (parseFloat(valueForm.TRAINING_DAY) + 2)
                        if (priceCache > 0) {
                            const arr = this.arrPrice.filter((v: any) => v.PartNo === val[item[0]]);
                            this.arrTS_Real_Cache.push({
                                name: arr[0].Desrc,
                                price: parseFloat(val[item[1]]),
                                formular: arr[0].ForMular,
                                priceCal: priceHotel,
                                partno: arr[0].PartNo
                            });
                        }

                    } else if (val[item[0]] === 'HiringComputer') {
                        const priceCache = parseFloat(val[item[1]]);
                        priceHiringComputer = priceCache + 5 * parseFloat(valueForm.TRAINING_DAY) * parseFloat(valueForm.NO_STUDENT);
                        if (priceCache > 0) {
                            const arr = this.arrPrice.filter((v: any) => v.PartNo === val[item[0]]);
                            this.arrTS_Real_Cache.push({
                                name: arr[0].Desrc,
                                price: parseFloat(val[item[1]]),
                                formular: arr[0].ForMular,
                                priceCal: priceHiringComputer,
                                partno: arr[0].PartNo
                            });
                        }

                    } else if (val[item[0]] === 'WeekendServicecost') {
                        const priceManager = this.arrPrice.filter((v: any) => v.PartNo === 'Manager').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Manager')[0].PriceCost : 0;
                        const priceSenior = this.arrPrice.filter((v: any) => v.PartNo === 'Senior').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Senior')[0].PriceCost : 0;
                        const priceStaff = this.arrPrice.filter((v: any) => v.PartNo === 'Staff').length > 0 ? this.arrPrice.filter((v: any) => v.PartNo === 'Staff')[0].PriceCost : 0;
                        const priceCache = parseFloat(val[item[1]]);
                        priceWeekendCost = priceCache * 0.5 * (priceManager * parseFloat(valueForm.Manager) + priceSenior * parseFloat(valueForm.Senior) + priceStaff * parseFloat(valueForm.Staff));
                        if (priceCache > 0) {
                            const arr = this.arrPrice.filter((v: any) => v.PartNo === val[item[0]]);
                            this.arrTS_Real_Cache.push({
                                name: arr[0].Desrc,
                                price: parseFloat(val[item[1]]),
                                formular: arr[0].ForMular,
                                priceCal: priceWeekendCost,
                                partno: arr[0].PartNo
                            });
                        }
                    } else if (val[item[0]] === 'Entertainment') {
                        const priceCache = parseFloat(val[item[1]]);
                        priceEntertainment = priceCache * parseFloat(valueForm['NO_STUDENT'])
                        if (priceCache > 0) {
                            const arr = this.arrPrice.filter((v: any) => v.PartNo === val[item[0]]);
                            this.arrTS_Real_Cache.push({
                                name: arr[0].Desrc,
                                price: parseFloat(val[item[1]]),
                                formular: arr[0].ForMular,
                                priceCal: priceEntertainment,
                                partno: arr[0].PartNo
                            });
                        }
                    } else {
                         totalAddCost += parseFloat(val[item[1]]);
                        if (parseFloat(val[item[1]]) > 0) {
                            const arr = this.arrPrice.filter((v: any) => v.PartNo === val[item[0]]);
                            this.arrTS_Real_Cache.push({
                                name: arr[0].Desrc,
                                price: parseFloat(val[item[1]]),
                                formular: arr[0].ForMular,
                                priceCal: parseFloat(val[item[1]]),
                                partno: arr[0].PartNo
                            });

                        }

                    }
                }

                // this.formCtrlAddCost.controls[item[1]].setValue(this.decimal.transform(+parseFloat(val[item[1]]), "1.2-2"), { emitEvent: false });

            });
            this.arrTrainingService = this.arrTrainingService.concat(this.arrTS_Real_Cache);
            this.arrTrainingService = [...this.arrTrainingService];
            this.priceReal_cache = totalAddCost + priceHiringComputer + priceHotel + priceWeekendCost + priceEntertainment;
            this.total_price = this.decimal.transform(+(this.priceTotal_cache + totalAddCost + priceHiringComputer + priceHotel + priceWeekendCost + priceEntertainment), "1.2-2");
            this.total_table = this.priceTotal_cache + totalAddCost + priceHiringComputer + priceHotel + priceWeekendCost + priceEntertainment;
            this.str_total = "";
            this.arrTrainingService.forEach(item => {
                // if (item.partno !== 'Manager' && item.partno !== 'Senior' && item.partno !== 'Staff') {
                //     this.str_total += " " + item.name + " +";
                // } else {
                //     this.str_total += " " + item.name + " * Factor +"
                // }
                this.str_total += " " + item.name + " +";
            });
            this.str_total = this.str_total.substr(0, this.str_total.length - 2)
        });



    }

    onClickCalculate() {
        // this.total_price = this.priceTotal_cache;
        // this.priceAdd_caache = 0;
        // Object.keys(this.formCtrlAddCost.value).forEach(key => {
        //     if (key.indexOf('COST') > -1) {
        //         this.priceAdd_caache += parseFloat(this.formCtrlAddCost.value[key]);
        //         this.total_price += parseFloat(this.formCtrlAddCost.value[key]);
        //     }
        // });
    }
    checkVaild() {
        let chk = false;
        let chkAll = false;
        let count = 0;
        let countAll = 0;
        let field = [];
        let vaild = false;
        const valueForm = this.appService.returnParam(this.formData)
        Object.keys(this.formData.controls).forEach(key => {
            if (key === 'Manager' || key === 'Senior' || key === 'Staff') {
                count += parseFloat(valueForm[key]);
                chk = count > 0 ? true : false;
                // if (chk) { countAll++; }
            } else if (key === 'TRAINING_DAY') {
                chk = valueForm[key] !== null && parseFloat(valueForm[key]) > 0 ? true : false;
                if (chk) { countAll++; }
                if (valueForm[key] === null || parseFloat(valueForm[key]) <= 0) {
                    field.push("Training day")
                }
            } else if (key === 'NO_STUDENT') {
                chk = valueForm[key] !== null && parseFloat(valueForm[key]) > 0 ? true : false;
                if (chk) { countAll++; }
                if (valueForm[key] === null || parseFloat(valueForm[key]) <= 0) {
                    field.push("No Student")
                }
            } else if (key === 'CLASS_TYPE') {
                chk = valueForm[key] !== null ? true : false;
                if (chk) { countAll++; }
                if (valueForm[key] === null) {
                    field.push("Class Type")
                }
            } else if (key === 'CONTENT_GROUP') {
                chk = valueForm[key] !== null ? true : false;
                if (chk) { countAll++; }
                if (valueForm[key] === null) {
                    field.push("Content Groups")
                }
            } else if (key === 'LOCATION') {
                chk = valueForm[key] !== null ? true : false;
                if (chk) { countAll++; }
                if (valueForm[key] === null) {
                    field.push("Location")
                }
            }
            if (!this.formData.controls[key].valid) {
                if (key === 'CLASS_NAME') {
                    field.push('Class Name');
                } else if (key === 'COMPUTER') {
                    field.push('Computers')
                }

            }

        })
        if (count === 0) {
            field.push("Esri VN Instructor")
        } else {
            countAll++;
        }
        if (countAll === 6) { chkAll = true; } else { chkAll = false };
        vaild = chkAll && this.formData.valid ? true : false;
        return { vaild: vaild, txt: field.join(", ") };
    }
    onSave() {
        const valueForm = this.appService.returnParam(this.formData)
        const itemtype = this.arrItemType.filter((v: any) => v.ItemTypeCode === 'TRAINING')[0];
        const txtClassType = valueForm['CLASS_TYPE'] === 1 ? ' Standard Class for ' : ' Customized Class for ';
        const descr = parseFloat(valueForm['TRAINING_DAY']) + ' Days' + txtClassType + parseFloat(valueForm['NO_STUDENT']) + ' Students';
        const paramsItem = {
            url: this.urlQuotation + '/QuotationItems',
            primaryKey: 'ItemId',
            data: {
                QuotationId: this.parentData.QuotationId,
                ItemTypeId: itemtype.ItemTypeId,
                QuanTiTy: 1,
                Desrc: descr,
                PriceUnit: this.total_table,
                CurrencyId: 1,
                PerDisCount: 0,
                TotalPrice: this.total_table,
                Discount: 0,
                QuotationItemName: valueForm['CLASS_NAME'],
                UnitId: 11
            }
        }
        const obj = this.checkVaild();
        if (obj.vaild) {
            if (this.mode === 'add') {
                this.onAddTrainingClass(paramsItem);
            } else {
                const formular = (this.total_table * this.currentData.QuanTiTy) - (this.total_table * this.currentData.QuanTiTy) * (this.currentData.PerDisCount / 100);
                const discount = (this.total_table * this.currentData.QuanTiTy) * (this.currentData.PerDisCount / 100);
                const paramQid = {
                    url: this.urlQuotation + '/QuotationItems',
                    primaryKey: 'ItemId',
                    data: {
                        ItemId: this.currentData.ItemId,
                        QuotationId: this.parentData.QuotationId,
                        ItemTypeId: this.currentData.ItemTypeId,
                        QuanTiTy: this.currentData.QuanTiTy,
                        Desrc: descr,
                        PriceUnit: this.total_table,
                        CurrencyId: this.currentData.CurrencyId,
                        PerDisCount: this.currentData.PerDisCount,
                        TotalPrice: formular,
                        Discount: Math.round(discount * 100) / 100,
                        QuotationItemName: valueForm['CLASS_NAME'],
                        UnitId: this.currentData.UnitId
                    }
                }
                this.reqService.service.update(paramQid).subscribe(res => {
                    if (res.success) {
                        const params = {
                            url: this.urlQuotation + '/TrainingClasses',
                            primaryKey: 'ClassId',
                            data: {
                                ItemId: this.currentData.ItemId,
                                QuotationId: this.parentData.QuotationId,
                                ClassName: valueForm['CLASS_NAME'],
                                ContentGroupId: valueForm['CONTENT_GROUP'],
                                TotalPrice: this.total_table,
                                QtyDay: parseFloat(valueForm['TRAINING_DAY']),
                                QtyStudent: parseFloat(valueForm['NO_STUDENT']),
                                LocationCode: valueForm['LOCATION'] === 1 ? 'Hanoi' : valueForm['LOCATION'] === 2 ? 'HCMC' : 'Outside',
                                ClassId: this.classId,
                                ClassType: valueForm['CLASS_TYPE']
                            }
                        }
                        this.onUpdateTrainingClass(params);
                    } else {
                        this.appService.createMessage('error', 'Update fail');
                    }
                });

            }
        } else {
            this.appService.createMessage('warning', "Fill in required field: " + obj.txt);
        }


    }
    onAddTrainingClass(paramsItem: any) {
        const valueForm = this.appService.returnParam(this.formData)
        this.reqService.service.insert(paramsItem).subscribe(resp => {
            if (resp.success) {
                const params = {
                    url: this.urlQuotation + '/TrainingClasses',
                    primaryKey: 'ClassId',
                    data: {
                        ItemId: resp.features[0].ItemId,
                        QuotationId: this.parentData.QuotationId,
                        ClassName: valueForm['CLASS_NAME'],
                        ContentGroupId: valueForm['CONTENT_GROUP'],
                        TotalPrice: this.total_table,
                        QtyDay: parseFloat(valueForm['TRAINING_DAY']),
                        QtyStudent: parseFloat(valueForm['NO_STUDENT']),
                        LocationCode: valueForm['LOCATION'] === 1 ? 'Hanoi' : valueForm['LOCATION'] === 2 ? 'HCMC' : 'Outside',
                        ClassType: valueForm['CLASS_TYPE']
                    }
                }
                this.reqService.service.insert(params).subscribe(cl => {
                    if (cl.success) {
                        this.onAddProduct(cl.features[0]);
                        this.onCloseEvent.emit(true);
                        this.appService.createMessage('success', 'Insert training class complete');
                        this.reloadRoot.emit();
                    } else {
                        this.appService.createMessage('error', 'Insert training class fail');
                    }
                });
            }
        });
    }
    onUpdateTrainingClass(param: any) {
        this.reqService.service.update(param).subscribe(resp => {
            if (resp.success) {
                this.onDeleteProduct();
            }
        })
    }
    onAddProduct(data: any) {
        const valueForm = this.appService.returnParam(this.formData)
        if (valueForm.CHK_AIRPLANE) {
            const prId = this.arrRealCache.filter((v: any) => v.PartNo === 'Airplane');
            this.onInsertRealCost(data.ClassId, prId[0].TraningServiceId, parseFloat(valueForm.AIRPLANE_PRICE), 1);
        }
        if (valueForm.CHK_CAR) {
            const prId = this.arrRealCache.filter((v: any) => v.PartNo === 'CarOrBus');
            this.onInsertRealCost(data.ClassId, prId[0].TraningServiceId, parseFloat(valueForm.CAR_PRICE), 1);
        }
        const value_add = this.appService.returnParam(this.formCtrlAddCost)
        this.arrCtrlAddCost.forEach((item: any) => {
            if (value_add[item[0]] !== null && (value_add[item[1]] !== null && parseFloat(value_add[item[1]]) > 0)) {
                const prId = this.arrRealCache.filter((v: any) => v.PartNo === value_add[item[0]]);
                this.onInsertRealCost(data.ClassId, prId[0].TraningServiceId, parseFloat(value_add[item[1]]), 1);
            }

        });
        Object.keys(this.formData.controls).forEach(key => {

            if (key === 'Manager' || key === 'Senior' || key === 'Staff') {

                if (parseFloat(valueForm[key]) > 0) {
                    const prId = this.arrPrice.filter((v: any) => v.PartNo === key);
                    this.onInsertRealCost(data.ClassId, prId[0].TraningServiceId, null, parseFloat(valueForm[key]));
                }

            } else if (key === 'COMPUTER') {
                // const prId = this.arrRealCache.filter((v: any) => (v.PartNo).toLowerCase().indexOf(key.toLowerCase()) > -1);
                const value = this.appService.returnParam(this.formData);
                this.onInsertRealCost(data.ClassId, value[key], null, parseFloat(valueForm['NO_STUDENT']));
            }
        });
    }
    onDeleteProduct() {
        const resp = this.reqService.service.query({
            url: this.urlQuotation + '/TrainingProducts/DeleteTrainingProductByClassId/' + this.classId,
            params: {},
            method: 'POST'
        });
        resp.subscribe(res => {
            if (res.success) {
                const data = {
                    ClassId: this.classId,
                }
                this.onAddProduct(data);
                this.onCloseEvent.emit(true);
                this.reloadRoot.emit();
                this.appService.createMessage('success', 'Update training class complete');
            }
        });
    }
    onInsertRealCost(ClassId: any, TraningServiceId: any, Price: any, qty: any) {
        const params = {
            url: this.urlQuotation + '/TrainingProducts',
            data: {
                ClassId: ClassId,
                ProductId: TraningServiceId,
                PriceRealCost: Price,
                TrainingQty: qty
            }
        }
        this.reqService.service.insert(params).subscribe(res => {
            if (res.success) {

            }
        });
    }
    onCancel() {
        if (this.titleSaveBtn === 'Add') {
            const parmasDefeault = {
                CLASS_NAME: null,
                CLASS_TYPE: 1,
                TRAINING_DAY: this.decimal.transform(+0, "1.2-2"),
                NO_STUDENT: 0,
                CONTENT_GROUP: null,
                LOCATION: 1,
                TRAVEL_BY: null,
                Manager: 0,
                Senior: 0,
                Staff: 0,
                COMPUTER: null,
                AIRPLANE_PRICE: 0,
                CHK_AIRPLANE: false,
                CAR_PRICE: 0,
                CHK_CAR: false,
            }
            this.arrCtrlAddCost.forEach((item: any) => {
                this.formCtrlAddCost.controls[item[0]].reset();
                this.formCtrlAddCost.controls[item[1]].reset(0);
            });
            this.formData.reset(parmasDefeault);
            this.total_price = this.decimal.transform(+0, "1.2-2");
        } else {
            this.bindData(this.arr_view_cache);
        }

        // this.onCloseEvent.emit();
    }
    async onAddCtrl(evt: any) {
        await this.formCtrlAddCost.addControl('NAME_' + this.arrCtrlAddCost.length, new FormControl());
        await this.formCtrlAddCost.addControl('COST_' + this.arrCtrlAddCost.length, new FormControl(0))
        this.arrCtrlAddCost.push(['NAME_' + this.arrCtrlAddCost.length, 'COST_' + this.arrCtrlAddCost.length]);
    }
    async onDeleteCtrl(item: any) {
        this.arrCtrlAddCost = await this.arrCtrlAddCost.filter((value: any) => value[0] !== item[0] && value[1] !== item[1]);
        await this.formCtrlAddCost.removeControl(item[0]);
        await this.formCtrlAddCost.removeControl(item[1]);
    }
    

}
