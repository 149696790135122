import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Checkbox } from 'primeng/checkbox';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-core-checkbox',
  templateUrl: './core-checkbox.component.html',
  styleUrls: ['./core-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR
      , useExisting: forwardRef(() => CoreCheckboxComponent)
      , multi: true
    }
  ]
})
export class CoreCheckboxComponent implements OnInit, AfterContentChecked {
  ctrl: FormControl = new FormControl();
  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };
  @ViewChild('chk') chk!: Checkbox | any;
  @Output() valueModelChange = new EventEmitter();
  @Output() chkChange = new EventEmitter();
  @Output() blurEvent: EventEmitter<any> = new EventEmitter();
  @Input() hasButton: boolean = false
  @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
  _config: any = null;
  @Input() hasFormCtrl: boolean = false;
  @Input() chkName: any = 'groupname';
  @Input() chkValue: any;
  @Input() chkLabel: any = '';
  @Input() chkCtrl: any = null;
  @Input() isDisabled: boolean = false;
  @Input() chkIcon: any = 'pi pi-check';
  @Input() isBinary: boolean = true;
  @Input() typeValue: 'boolean' | 'string' | 'number' = 'boolean';
  @Input() isLabelLeft: boolean = true;
  @Input() labelLeftOut: boolean = false;
  @Input() isCoreForm: boolean = false
  @Input() notUseTranslate = false;
  private _isReadOnly = false;
  @Input('isReadOnly')
  set isReadOnly(val: boolean) {
    this._isReadOnly = val;
    if (this.ctrl) {
      // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
    }
  }
  get isReadOnly() {
    return this._isReadOnly;
  }
  _valueModel: any = false;
  @Input()
  get valueModel() {
    return this._valueModel;
  }

  set valueModel(value) {
    this._valueModel = value;
    this.valueModelChange.emit(this._valueModel);
  }
  constructor(
    private cdr: ChangeDetectorRef
  ) {
    this.initForm();
  }
  initForm() {
    this.ctrl = new FormControl(null, {
      updateOn: 'change'
    });
    this.isReadOnly ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });

    this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
      this.onChange(val)
      this.chkChange.emit(val)
    })
  }
  ngOnInit(): void {
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  writeValue(_value: any): void {
    this.ctrl.setValue(_value, { emitEvent: false })
  }
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

}
