<div class="trainingClass">
    <form [formGroup]="formData" class="divFormClass">
        <div class="div1Class">
            <div class="col-md-5">
                <app-input labelName='Class Name:' formControlName="CLASS_NAME" [required]="true" [isLabelLeft]="true"
                    [disabledCtrl]='false'>
                </app-input>
            </div>
            <div class="col-md-7" style="display: flex; align-items: center;">
                <div style="padding-right: 10px; font-weight: bold;">Class Type<span style="color: red;"> *</span>
                </div>
                <app-core-radio-button [hasFormCtrl]="true" formControlName="CLASS_TYPE"
                    [listRadio]="[{CODE: 1, DESCR: 'Standard'}, {CODE: 1.5, DESCR: 'Customized'}]">
                </app-core-radio-button>
                <!-- <nz-radio-group formControlName="CLASS_TYPE">
                    <label nz-radio [nzValue]="1">Standard</label>
                    <label nz-radio [nzValue]="1.5">Customized</label>
                </nz-radio-group> -->
            </div>
        </div>
        <div class="div1Class">
            <div class="col-md-5">
                <div style="margin-bottom: 5px;">
                    <app-input labelName='Training Days:' formControlName="TRAINING_DAY" [required]="true" [isCtrlBlur] = 'false'
                        [isLabelLeft]="true" [disabledCtrl]='false' type="number" vFormat='{"minFractionDigits":2,"maxFractionDigits":2}'>
                    </app-input>
                </div>
                <div>
                    <app-input labelName='No. Students:' formControlName="NO_STUDENT" [required]="true" [isCtrlBlur] = 'false'
                        [isLabelLeft]="true" [disabledCtrl]='false' type="number">
                    </app-input>
                </div>
            </div>
            <div class="col-md-7">
                <div style="padding-right: 10px; font-weight: bold;">Content Groups<span style="color: red;"> *</span>
                </div>
                <app-core-radio-button [hasFormCtrl]="true" formControlName="CONTENT_GROUP" [listRadio]="arrContent"
                    valueField='CODE' displayField='DESCR'>
                </app-core-radio-button>
                <!-- <nz-radio-group formControlName="CONTENT_GROUP">
                    <ng-container *ngFor="let item of arrContent">
                        <label nz-radio [nzValue]="item.ContentGroupId">{{item.ContentGroupName}}</label>
                    </ng-container>

                </nz-radio-group> -->
            </div>
        </div>
        <div class="div1Class divBlockClass" style="display: block;">
            <div style="display: flex; justify-content: center;">
                <div class="col-md-6">
                    <div style="padding-right: 10px; font-weight: bold;">Location<span style="color: red;"> *</span>
                    </div>
                    <app-core-radio-button [hasFormCtrl]="true" formControlName="LOCATION" orientation='vertical'
                        [listRadio]="[{CODE: 1, DESCR: 'Esri VN Hanoi Office'}, {CODE: 2, DESCR: 'Esri VN HCMC Office'}, {CODE: 3, DESCR: 'Customer Provide Training Facilities'}]">
                    </app-core-radio-button>
                    <!-- <nz-radio-group formControlName="LOCATION">
                        <label nz-radio [nzValue]="1">Esri VN Hanoi Office</label>
                        <label nz-radio [nzValue]="2">Esri VN HCMC Office</label>
                        <label nz-radio [nzValue]="3">Customer Provide Training Facilities</label>
                    </nz-radio-group> -->
                    <div style="padding-left: 20px; padding-top: 5px;">
                        <div style="display: flex; align-items: center;">
                            <!-- <label nz-checkbox formControlName="CHK_AIRPLANE" style="padding-right: 10px;"
                                [nzDisabled]="formData.value['LOCATION'] === 3 ? false : true"></label> -->
                            <div class="col-md-1">
                                <app-core-checkbox [hasFormCtrl]="true" [chkName]="'CHK_AIRPLANE'" 
                                    [isDisabled]="isDisableVehice" formControlName="CHK_AIRPLANE"
                                    [typeValue]="'boolean'" [isLabelLeft]="true">
                                </app-core-checkbox>
                            </div>
                            <div class="col-md-11">
                                <app-input labelName='Travel by Airplane:' formControlName="AIRPLANE_PRICE" [isCtrlBlur] = 'false'
                                    [required]="false" [isLabelLeft]="true" [disabledCtrl]="isDisableVehice"
                                    type="number" vFormat='{"minFractionDigits":2,"maxFractionDigits":2}'>
                                </app-input>
                            </div>

                        </div>
                        <div style="display: flex; align-items: center; padding-top: 5px;">
                            <!-- <label nz-checkbox formControlName="CHK_CAR" style="padding-right: 10px;"
                                [nzDisabled]="isDisableVehice"></label> -->
                            <div class="col-md-1">
                                <app-core-checkbox [hasFormCtrl]="true" [chkName]="'CHK_CAR'"
                                    [isDisabled]="isDisableVehice" formControlName="CHK_CAR" [typeValue]="'boolean'"
                                    [isLabelLeft]="true">
                                </app-core-checkbox>
                            </div>
                            <div class="col-md-11">
                                <app-input labelName='Travel by Car/Bus:' formControlName="CAR_PRICE" [required]="false" [isCtrlBlur] = 'false'
                                    [isLabelLeft]="true" [disabledCtrl]="isDisableVehice" type="number" vFormat='{"minFractionDigits":2,"maxFractionDigits":2}'>
                                </app-input>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="padding-right: 10px; font-weight: bold;">Esri VN Instructor<span style="color: red;">
                            *</span> </div>
                    <div style="margin-bottom: 5px; padding-left: 10px;">
                        <app-input labelName='Manager Level:' formControlName="Manager" [required]="false" [isCtrlBlur] = 'false'
                            [isLabelLeft]="true" [disabledCtrl]='false' type="number">
                        </app-input>
                    </div>
                    <div style="margin-bottom: 5px; padding-left: 10px;">
                        <app-input labelName='Senior Level:' formControlName="Senior" [required]="false" [isCtrlBlur] = 'false'
                            [isLabelLeft]="true" [disabledCtrl]='false' type="number">
                        </app-input>
                    </div>
                    <div style="padding-left: 10px;">
                        <app-input labelName='Staff Level:' formControlName="Staff" [required]="false" [isCtrlBlur] = 'false'
                            [isLabelLeft]="true" [disabledCtrl]='false' type="number">
                        </app-input>
                    </div>
                </div>
            </div>
            <div style="display: flex;">
                <div class="col-md-6" style="padding-left: 20px;">
                    <!-- <nz-radio-group formControlName="TRAVEL_BY"
                        [nzDisabled]="formData.value['LOCATION'] === 3 ? false : true">
                        <label nz-radio [nzValue]="1">Travel by Airplane</label>
                        <label nz-radio [nzValue]="2">Travel by Car/Bus</label>
                    </nz-radio-group> -->
                </div>
                <div class="col-md-6">
                    <div style="padding-right: 10px; font-weight: bold;">Computers<span style="color: red;"> *</span>
                    </div>
                    <app-core-radio-button [hasFormCtrl]="true" formControlName="COMPUTER" [listRadio]="arrComputer"
                        valueField='CODE' displayField='DESCR'>
                    </app-core-radio-button>
                    <!-- <nz-radio-group formControlName="COMPUTER" style="padding-left: 10px;">
                        <ng-container *ngFor="let item of arrComputer">
                            <label nz-radio [nzValue]="item.TraningServiceId">{{item.ProductName}}</label>
                        </ng-container>
                    </nz-radio-group> -->
                </div>
            </div>

        </div>
        <div class="div1Class divBlockClass" style="display: block;">
            <div style="font-weight: bold; padding-left: 15px;">Additional Cost: </div>
            <div style="display: flex;">
                <div class="col-md-12">
                    <div [formGroup]="formCtrlAddCost">
                        <div class="row" style="height: 36px; font-weight: 500;">
                            <div class='col-md-5'>Item Name</div>
                            <div class='col-md-5'>Item Cost</div>
                            <div class="col-md-2">
                                <!-- <i nz-icon nzType="file" nzTheme="outline"
                                    style="font-size: 20px; color: blue; padding-left: 10px; cursor: pointer;"
                                    (click)="onAddCtrl($event)"></i> -->
                                <i class="pi pi-file"
                                    style="font-size: 20px; color: blue; padding-left: 10px; cursor: pointer;"
                                    (click)="onAddCtrl($event)"></i>
                            </div>
                        </div>
                        <ng-container *ngFor="let item of arrCtrlAddCost; let i = index">
                            <div class="row" style="padding-bottom: 10px;">
                                <div class='col-md-5'>
                                    <app-select [showLabel]='false' [isLabelLeft]="false" [required]="false"
                                        [formControlName]="item[0]" [dataLookup]="arrProducrRealCost"
                                        displayField="DESCR" valueField="CODE">
                                    </app-select>
                                </div>
                                <div class='col-md-5'>
                                    <app-input [showLabel]='false' [formControlName]="item[1]" [required]="false"
                                        [isLabelLeft]="true" [disabledCtrl]='false' type="number" [isCtrlBlur] = 'false'>
                                    </app-input>
                                    <!-- <input nz-input [formControlName]="item[1]" appNumberOnly
                                        [ngClass]="{'required-class': formCtrlAddCost.value[item[1]] === null}" /> -->
                                </div>
                                <div class="col-md-2">
                                    <!-- <i nz-icon nzType="delete"
                                        nzTheme="outline"
                                        style="font-size: 20px; color: red; padding-left: 10px; cursor: pointer;"></i> -->
                                    <i class="pi pi-trash"
                                        style="font-size: 20px; color: red; padding-left: 10px; cursor: pointer;"
                                        (click)="onDeleteCtrl(item)"></i>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <div class="divBtnClass" style="padding: 0px; align-items: flex-end;">
                            <button nz-button class="btnSaveClass" style="margin-right: 10px; margin-left: 0px;"
                                (click)="onBuild()">{{'Ok'}}</button>
                            <button nz-button class="btnClearClass" style="margin-right: 10px; margin-left: 0px;"
                                (click)="onClearCtrl()">{{'Reset'}}</button>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="col-md-3" style="display: flex; align-items: center; justify-content: center;">
                    <div class="btnClass" (click)="onClickCalculate()">Calculate</div>
                </div> -->
            </div>

        </div>
    </form>
    <div style="display: flex; align-items: center; padding: 0px 10px;">
        <div class="col-md-9" style="display: flex; align-items: center;">
            <app-input [labelName]="'Total Price'" [type]="'text'" [required]="false" [hasFormCtrl]='false'
                [(valueModel)]="total_price" [isLabelLeft]="true" [disabledCtrl]='true'></app-input>
        </div>
        <div class="col-md-3"
            style="display: flex; align-items: center; justify-content: center; padding: 10px 0px; flex-direction: column;">
            <div class="btnClass" (click)="onSave()">{{titleSaveBtn}}</div>
            <div class="btnClass" (click)="onCancel()" style="margin-top: 5px; background-color: #bcbcbc;">Reset</div>
        </div>
    </div>
   
    <p-accordion>
        <p-accordionTab header="Training Cost Detail" [disabled]="false">
            <!-- Content 1 -->
            <p-table [value]="arrTrainingService">
                <ng-template pTemplate="header">
                    <tr>
                        <th>STT</th>
                        <th>Training Serivice</th>
                        <th>Formular</th>
                        <th>Cost</th>
                        <th style="text-align: right;">Price</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                    <tr>
                        <td>{{rowIndex + 1}}</td>
                        <td>{{data.name}}</td>
                        <td>{{data.formular}}</td>
                        <td> {{data.price}} </td>
                        <td style="text-align: right;"> {{data.priceCal}} </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <!-- <div class="p-d-flex p-ai-center p-jc-between">
                        In total there are {{products ? products.length : 0 }} products.
                    </div> -->
                    <ng-container *ngTemplateOutlet = "footerTable"></ng-container>
                </ng-template>
            </p-table>
        </p-accordionTab>
    </p-accordion>

</div>
<ng-template #footerTable>

    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-weight: 500;">
        <div style="width: 90%;">Total price ({{str_total}})</div>
        <div style="width: 10%; text-align: right;">{{total_table}}</div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-style: italic;">
        <div style="width: 100%;">Note: {{str_factor}} {{str_heso}}</div>
    </div>
</ng-template>
<!-- <div>TEST</div> -->