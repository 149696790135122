import {
    AfterContentInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
    SimpleChanges,
    EventEmitter,
    OnInit,
    TemplateRef,
    ChangeDetectorRef
} from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { map, switchMap, take } from 'rxjs/operators';
import * as converter from 'xml-js';
import { combineLatest, from, Observable } from 'rxjs';
import { BpmnWorkflowService } from '../services/workflow.service';
import { AppService } from 'app/app-base/app.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/app-base/auth.service';
import { Injectable } from '@angular/core';
import { RequestService } from 'app/core/services/request.service';
import { Form, Item } from 'stimulsoft-viewer-angular/lib/services/objects';
import { CoreTreeComponent } from 'app/core/core-tree/core-tree.component';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';
let Modeler: any, Viewer: any;
declare var require: any;
let BpmnModeler = require('bpmn-js/lib/Modeler');



@Component({
    selector: 'app-workflow-design',
    templateUrl: './workflow-design.component.html',
    styleUrls: ['./workflow-design.component.scss']
})
export class WorkflowDesignComponent implements OnInit {
    private createTblFinish: EventEmitter<any> = new EventEmitter();
    @ViewChild('workflow', { static: true }) private el!: ElementRef;
    @ViewChild('wpWorkflow', { static: true }) private wpWorkflow!: ElementRef;
    @ViewChild('properties', { static: true }) private Divproperties!: ElementRef;
    @ViewChild('dialogPrime', { static: true }) dialogPrime!: DialogPrimeComponent;
    @ViewChild('formTem', { static: true }) formTem!: TemplateRef<any>;
    @ViewChild('tempUserJobType') tempUserJobType!: TemplateRef<any>;
    @ViewChild('tempUserStep') tempUserStep!: TemplateRef<any>;
    @ViewChild('tempUserApprove') tempUserApprove!: TemplateRef<any>;
    @ViewChild('treeWf', { static: true }) treeUsers!: CoreTreeComponent;
    @ViewChild('tempEmail', {static:true}) private tempEmail!: TemplateRef<any>

    isExpanded: boolean = true;
    private bpmnJS:any;
    private currentWF: any = {};
    private currentId = null;
    private result = [];

    public tableRowDefination = [];
    public tableRowDefinationChild = [];
    public tableDataSource = [];
    public tableDataHistory = [];
    public tableDataChild = [];
    public pageIndex = 1;
    public pageSize = 10;
    public stepPanLeftPanel = 2;
    public fieldList = [];
    public currentData: any = {};

    public formData!: FormGroup;
    public formDataStep!: FormGroup;
    public nzSpan = 11;

    public isDisAssignStep = true;
    public isDisDurationStep = true;
    public isDisBtnSaveStep = true;
    public isShowFormSave = false;
    // public activedNode: NzTreeNode;
    public searchValue = '';
    public totalJobType = 0;
    public labelResult = 'Total';
    mode = 'A';
    idCurrentStep = null;
    arrInfoStep:any = [];
    public arrApplication:any = [];
    public arrWindow:any = [];
    public dataUserJobtype:any = [];
    public dataUserStep:any = [];
    public dataUserApprove:any = [];
    public defaultCheckedUser:any = [];
    public defaultCheckedUserStep:any = [];
    public defaultCheckedUserApprove:any = [];
    public arrUserJobType:any = [];
    arrExternalWorkflow:any = [];
    arrExternalStep:any = [];

    isShowDesignWf = false;
    isShowFormWf = false;
    arrTimeUnit = [{CODE: 1, DESCR: 'Day'}, { CODE: 2, DESCR: 'Hour'}, { CODE: 3, DESCR: 'Minute'}];

    defaultExpandedKeys = ['0-0', '0-0-0', '0-0-1'];
    defaultSelectedKeys = ['0-0'];
    public searchValueUser = '';
    public userLogin = '';
    public nameApp = '';
    public logoApp = '';
    selectedValue = 'EN';
    public tenTrang = '';
    // @ViewChild('selectLang', { static: false }) selectLang: NzSelectComponent;
    appPages = [
        { title: 'VI', icon: 'assets/um-images/vi-VN.png' },
        { title: 'EN', icon: 'assets/um-images/en-EN.png' }
    ];

    treeConfig: any = {
        selectionMode: 'single',
        filter: true
    }

    treeConfigUser: any = {
        selectionMode: 'checkbox',
        filter: false,
        virtualScroll: false,
    }

    arrTypeApprove = [
        {CODE: 1, DESCR: '1 người duyệt'},
        {CODE: 2, DESCR: 'Duyệt đồng thời'},
        {CODE: 3, DESCR: 'Duyệt tuần tự'}
    ];
    public listRadio = [
        { CODE: '1', DESCR: 'User' },
        { CODE: '2', DESCR: 'Owner' }
    ]
    isShowUserStep = true;
    // public radioCtrl = new FormControl('1');
    arrColumnApprove:any = [];
    arrColumnEmail:any = [];
    arrWindowOnchange:any = [];

    isStepApprove:boolean = false;
    isSendMail:boolean = false;
    isStepApproveNoBack:boolean = false

    clientCode:any = null;

    private xml = `<?xml version="1.0" encoding="UTF-8"?>
  <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_00f3jo1" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="3.0.0-dev">
    <bpmn:process id="Process_03i8cud" isExecutable="true">
      <bpmn:startEvent id="StartEvent_1" />
    </bpmn:process>
    <bpmndi:BPMNDiagram id="BPMNDiagram_1">
      <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_03i8cud">
        <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
          <dc:Bounds x="179" y="159" width="36" height="36" />
        </bpmndi:BPMNShape>
      </bpmndi:BPMNPlane>
    </bpmndi:BPMNDiagram>
  </bpmn:definitions>`;
    arrWorkflow:any = [];
    arrUser:any = [];
    arrTable:any = [];
    clientId: any;
    userid: any;
    arrCheckUser:any =  [];
    arrCheckedUserStep:any =  '';
    arrCheckedUserApprove:any =  '';
    applicationId: any;
    isShowExternal:boolean = false;
    showLeftPanel = true;
    isShowFormApprove:boolean = false;
    requiredFormApprove:boolean = false

    constructor(
        private reqService: RequestService,
        private http: HttpClient,
        private workflowService: BpmnWorkflowService,
        private appService: AppService,
        private router: Router,
        public translate: TranslateService,
        // private i18n: NzI18nService,
        private authService: AuthService,
        private cd: ChangeDetectorRef,

    ) {

        // if (sessionStorage.getItem('language')) {
        //     translate.setDefaultLang(sessionStorage.getItem('language'));
        //     translate.use(sessionStorage.getItem('language'));
        //     this.selectedValue = sessionStorage.getItem('language');

        //     if (sessionStorage.getItem('language') === 'vi') {
        //         this.i18n.setLocale(vi_VN);
        //     } else {
        //         this.i18n.setLocale(en_US);
        //     }
        // } else {
        //     translate.setDefaultLang('vi');
        //     sessionStorage.setItem('language', 'vi');
        //     this.selectedValue = 'vi';
        //     this.i18n.setLocale(vi_VN);
        // }
    }


    ngOnInit() {
        this.applicationId = this.appService.objStoreAppId.appId;
        this.clientId = this.appService.ClientId;
        const user = this.appService.currentUser;
        user ? this.userid = JSON.parse(user).userid : '';
        user ? this.clientCode = JSON.parse(user).ClientCode : ''

        this.formDataStep = new FormGroup({
            stepName: new FormControl(),
            stepDuration: new FormControl(),
            assignment: new FormControl(),
            userApprove: new FormControl(),
            column: new FormControl(),
            valueApprove: new FormControl(),
            typeApprove: new FormControl(),
            externalWorkflow: new FormControl(),
            externalStep: new FormControl(),
            assignOwner: new FormControl(),
            columnEmail: new FormControl(),
            changeAssign: new FormControl(false),
            templateEmail: new FormControl(),
            tableIdApprove: new FormControl()

        });


        this.formDataStep.controls['tableIdApprove'].valueChanges.subscribe((item:any) => {
            if (item !== null && item !==undefined) {
                const tableIdApprove =  typeof(item) === 'object' ? item.CODE : item;
                this.querySysColumn(tableIdApprove)
            }

        });

        this.formDataStep.controls['externalWorkflow'].valueChanges.subscribe((item:any) => {
            if (item !== null && item !==undefined) {
                const jobTypeId =  typeof(item) === 'object' ? item.CODE : item;
                this.queryStep(jobTypeId, null);
            }

        });

        this.formDataStep.controls['assignOwner'].valueChanges.subscribe((item:any) => {
            if (item !== null && item !== undefined) {
                if (item === '2' || item.CODE === '2') {
                    this.isShowUserStep = false;
                } else {
                    this.isShowUserStep = true;
                }
            }
        });

        this.initBpmn();
        this.getAllWf();
        this.initFormFroup();
        this.getAllUser();


    }

    public onChangeFile(inputValue:any){
        var file: File = inputValue.target.files[0];
        var myReader: any = new FileReader();
        var fileType = inputValue.target.parentElement.id;
        myReader.onloadend =  (e:any) => {
            this.importDiagram(myReader.result);
        }

        myReader.readAsText(file);
    }
    eventBus:any

    initBpmn() {
        this.bpmnJS = new BpmnModeler.default({
            container: this.el.nativeElement,
            height: 560,
            keyboard: { bindTo: document },
        });
        this.importDiagram(this.xml);
        const canvas = this.bpmnJS.get('canvas');
        canvas.zoom('fit-viewport');
        this.eventBus = this.bpmnJS.get('eventBus');
        this.eventBus.on('element.click', (event:any) => {
            const { element } = event;
            console.log("element clicked:", element);
            // console.log(
            //   "element id + name:",
            //   element.id,
            //   element.businessObject.name
            // );
            // console.log("incoming (before):", element.incoming);
            // console.log("outgoing (after):", element.outgoing);
            this.idCurrentStep = element.id;
            this.getInfoStep(element);
            if (element.type === 'bpmn:IntermediateThrowEvent') {
                this.isShowExternal = true;
                this.getWfExternal();
            } else {
                this.isShowExternal = false;
            }
        });

        this.eventBus.on('element.changed', (event:any) => {
            console.log('element ', event.element, ' changed');
          });

    }

    run(data:any) {
        const dataWf = JSON.stringify(data.dataWindow);
        this.clearStorage();
        localStorage.setItem('WorkflowDataInfo', dataWf);
    }

    onScreen() {
        const elem = this.wpWorkflow.nativeElement;
        if (elem.requestFullscreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
        } else {
            if (elem.cancelFullScreen) {
                elem.cancelFullScreen();
            } else if (elem.mozCancelFullScreen) {
                elem.mozCancelFullScreen();
            } else if (elem.webkitCancelFullScreen) {
                elem.webkitCancelFullScreen();
            }
        }

    }

    canel() {
        const elem = this.wpWorkflow.nativeElement;
        elem.cancelFullScreen();
    }

    getInfoStep(element:any) {
        this.arrCheckedUserStep = '';
        if (element.id !== 'Process_03i8cud') {
            this.formDataStep.controls['stepName'].setValue(element.businessObject.name);
            // if (this.arrInfoStep.length > 0) {
            this.isDisAssignStep = false;
            this.isDisDurationStep = false;
            this.isDisBtnSaveStep = false;
            let count = 0;
            this.arrInfoStep.forEach((step:any) => {
                if (step.id === element.id) {
                    count += 1;
                    this.formDataStep.controls['stepDuration'].setValue(step.duration);
                    step.assignment !== '' ? this.formDataStep.controls['assignment'].setValue(step.assignment) : this.formDataStep.controls['assignment'].setValue(null);
                    this.formDataStep.controls['typeApprove'].setValue(step.typeApprove);
                    this.formDataStep.controls['userApprove'].setValue(step.userApprove);
                    this.formDataStep.controls['valueApprove'].setValue(step.valueApprove);
                    this.formDataStep.controls['externalWorkflow'].setValue(step.externalWorkflow);
                    this.formDataStep.controls['changeAssign'].setValue(step.changeAssign);
                    this.formDataStep.controls['tableIdApprove'].setValue(step.tableIdApprove);
                    this.formDataStep.controls['column'].setValue(step.column);

                    step.assignOwner === true ? this.formDataStep.controls['assignOwner'].setValue('2') : this.formDataStep.controls['assignOwner'].setValue('1');
                    this.isStepApproveNoBack = step.directApprove;

                    // this.isStepApprove =  step.onApprove === 'true' ? true :  false
                    this.isSendMail = step.sendMail;
                    this.formDataStep.controls['columnEmail'].setValue(step.columnEmail);
                    this.formDataStep.controls['templateEmail'].setValue(step.templateEmail);

                    if (step.externalWorkflow !== null) {
                        this.queryStep(step.externalWorkflow, step.externalStep)
                    }

                    if (step.onApprove === "true" || this.isStepApproveNoBack) {
                        this.isShowFormApprove = true;
                        this.isStepApprove = step.onApprove === "true"  ? true : false;
                        this.cd.detectChanges();

                        this.querySysColumn(step.tableIdApprove, step.columnApprove)
                    } else {
                        this.isStepApprove = false;
                        this.cd.detectChanges();
                        this.isShowFormApprove = false;
                    }
                    this.cd.detectChanges();
                }
            });

            if (count === 0) {
                // this.formDataStep.reset();
            //     this.formDataStep.controls['stepDuration'].setValue(null);
            //     this.formDataStep.controls['assignment'].setValue(null);
            //     this.formDataStep.controls['typeApprove'].setValue(null);
            //     this.formDataStep.controls['userApprove'].setValue(null);
            //     this.formDataStep.controls['column'].setValue(null);
            //     this.formDataStep.controls['valueApprove'].setValue(null);
            }
            if (element.incoming.length === 0) {
                this.isDisAssignStep = true;
                this.isDisDurationStep = false;
                this.isDisBtnSaveStep = false;
            }
        } else {
            this.formDataStep.reset();
            // this.formDataStep.controls['stepName'].setValue(null);
            // this.formDataStep.controls['stepDuration'].setValue(null);
            // this.formDataStep.controls['assignment'].setValue(null);
            // this.formDataStep.controls['typeApprove'].setValue(null);
            // this.formDataStep.controls['userApprove'].setValue(null);
            // this.formDataStep.controls['column'].setValue(null);
            // this.formDataStep.controls['valueApprove'].setValue(null);
            this.isDisAssignStep = true;
            this.isDisDurationStep = true;
            this.isDisBtnSaveStep = true;
            this.isStepApprove = false;
            this.isShowFormApprove = false;
            this.isSendMail = false
            this.isStepApproveNoBack = false
        }
    }

    onChangAssign(e:any){
        console.log(e);
        console.log(this.formDataStep.controls['changeAssign'].value);
    }

    onSaveStep() {
        if (!this.isDisBtnSaveStep) {
            if (this.formDataStep.valid) {
                if (this.idCurrentStep !== 'Process_03i8cud') {
                    const f:any = this.arrInfoStep.filter((fil:any) => fil.id === this.idCurrentStep);
                    if (f[0]) {
                        f[0].duration = this.formDataStep.controls['stepDuration'].value;
                        f[0].assignment =  this.formDataStep.controls['assignment'].value;
                        f[0].externalWorkflow = this.formDataStep.controls['externalWorkflow'].value !== null ? typeof(this.formDataStep.controls['externalWorkflow'].value) === 'object' ?  this.formDataStep.controls['externalWorkflow'].value.CODE :  this.formDataStep.controls['externalWorkflow'].value : this.formDataStep.controls['externalWorkflow'].value;
                        f[0].externalStep = this.formDataStep.controls['externalStep'].value !== null ? typeof(this.formDataStep.controls['externalStep'].value) === 'object' ?  this.formDataStep.controls['externalStep'].value.CODE :  this.formDataStep.controls['externalStep'].value : this.formDataStep.controls['externalStep'].value;
                        // f[0].assignOwner = this.formDataStep.controls['radioCtrl'].value  !== null ?  typeof(this.formDataStep.controls['radioCtrl'].value) === 'object' ? === '2' ? true : false
                        f[0].sendMail = this.isSendMail;
                        f[0].onApprove = this.isStepApprove === true ? 'true' : 'false';
                        f[0].directApprove = this.isStepApproveNoBack
                        f[0].changeAssign = this.formDataStep.controls['changeAssign'].value;
                        f[0].columnEmail = this.formDataStep.controls['columnEmail'].value !== null ?  typeof(this.formDataStep.controls['columnEmail'].value) === 'object' ?  this.formDataStep.controls['columnEmail'].value.CODE :  this.formDataStep.controls['columnEmail'].value : this.formDataStep.controls['columnEmail'].value;
                        f[0].templateEmail = this.formDataStep.controls['templateEmail'].value;
                        if (this.formDataStep.controls['assignOwner'].value === '2' || this.formDataStep.controls['assignOwner'].value.CODE === '2') {
                            f[0].assignOwner = true;
                        } else {
                            f[0].assignOwner = false;
                        }
                        if (this.isStepApprove || this.isStepApproveNoBack) {
                            f[0].typeApprove = this.formDataStep.controls['typeApprove'].value !== null ? typeof(this.formDataStep.controls['typeApprove'].value) === 'object' ?  this.formDataStep.controls['typeApprove'].value.CODE :  this.formDataStep.controls['typeApprove'].value : this.formDataStep.controls['typeApprove'].value;
                            f[0].userApprove =  this.formDataStep.controls['userApprove'].value;
                            f[0].tableIdApprove =  this.formDataStep.controls['tableIdApprove'].value !== null ?  typeof(this.formDataStep.controls['tableIdApprove'].value) === 'object' ?  this.formDataStep.controls['tableIdApprove'].value.CODE :  this.formDataStep.controls['tableIdApprove'].value : this.formDataStep.controls['tableIdApprove'].value;
                            f[0].columnApprove =  this.formDataStep.controls['column'].value !== null ?  typeof(this.formDataStep.controls['column'].value) === 'object' ?  this.formDataStep.controls['column'].value.CODE :  this.formDataStep.controls['column'].value : this.formDataStep.controls['column'].value;
                            f[0].valueApprove =  this.formDataStep.controls['valueApprove'].value;
                        } else {
                            f[0].typeApprove = null;
                            f[0].userApprove =  null;
                            f[0].tableIdApprove =  null;
                            f[0].columnApprove =  null;
                            f[0].valueApprove = null;
                        }

                        this.cd.detectChanges();

                    } else {
                        this.arrInfoStep.push({
                            id: this.idCurrentStep,
                            duration: this.formDataStep.controls['stepDuration'].value,
                            assignment: this.formDataStep.controls['assignment'].value,
                            typeApprove: this.formDataStep.controls['typeApprove'].value !== null ? typeof(this.formDataStep.controls['typeApprove'].value) === 'object' ?  this.formDataStep.controls['typeApprove'].value.CODE :  this.formDataStep.controls['typeApprove'].value : this.formDataStep.controls['typeApprove'].value,
                            userApprove: this.formDataStep.controls['userApprove'].value,
                            tableIdApprove: this.formDataStep.controls['tableIdApprove'].value !== null ? typeof(this.formDataStep.controls['tableIdApprove'].value) === 'object' ?  this.formDataStep.controls['tableIdApprove'].value.CODE :  this.formDataStep.controls['tableIdApprove'].value:  this.formDataStep.controls['tableIdApprove'].value,
                            columnApprove: this.formDataStep.controls['column'].value !== null ? typeof(this.formDataStep.controls['column'].value) === 'object' ?  this.formDataStep.controls['column'].value.CODE :  this.formDataStep.controls['column'].value:  this.formDataStep.controls['column'].value,
                            valueApprove:  this.formDataStep.controls['valueApprove'].value,
                            externalWorkflow: this.formDataStep.controls['externalWorkflow'].value !== null ? typeof(this.formDataStep.controls['externalWorkflow'].value) === 'object' ?  this.formDataStep.controls['externalWorkflow'].value.CODE :  this.formDataStep.controls['externalWorkflow'].value:  this.formDataStep.controls['externalWorkflow'].value,
                            externalStep: this.formDataStep.controls['externalStep'].value !== null ? typeof(this.formDataStep.controls['externalStep'].value) === 'object' ?  this.formDataStep.controls['externalStep'].value.CODE :  this.formDataStep.controls['externalStep'].value:  this.formDataStep.controls['externalStep'].value,
                            assignOwner: this.formDataStep.controls['externalStep'].value !== null ? this.formDataStep.controls['assignOwner'].value === '2'  || this.formDataStep.controls['assignOwner'].value.CODE === '2' ? true : false : false,
                            sendMail: this.isSendMail,
                            onApprove: this.isStepApprove,
                            directApprove: this.isStepApproveNoBack,
                            columnEmail:  this.formDataStep.controls['columnEmail'].value !== null ? typeof(this.formDataStep.controls['columnEmail'].value) === 'object' ?  this.formDataStep.controls['columnEmail'].value.CODE :  this.formDataStep.controls['columnEmail'].value:  this.formDataStep.controls['columnEmail'].value,
                            changeAssign: this.formDataStep.controls['changeAssign'].value,
                            templateEmail:  this.formDataStep.controls['templateEmail'].value,

                        });
                    }
                }

                this.onSaveWf();

            } else {
                this.appService.createMessage('warning', this.appService.getMessage('0006'));
            }
        }
    }

    getAllTable(item:any) {
        if (item !== null) {
            const windowId = typeof(item) === 'object' ?  item.CODE : item;
            const params = [['ClientId', '=', this.clientId], ['WindowId', '=', windowId]];
            let arrWindowId:any = [];
            this.arrTable = [];
            this.workflowService.querySysTab(params).subscribe(res => {
                if (res.success) {
                    if (res.features.length > 0) {
                        res.features.forEach( item => {
                            arrWindowId.push(item.TableId);
                        });
                        const params =  ['TableID', 'in', arrWindowId];
                        this.workflowService.querySysTable(params).subscribe(item => {
                            if (item.success) {
                                console.log(item);
                                this.arrTable = [];
                                item.features.forEach((resTable:any) => {
                                    this.arrTable.push({
                                        CODE: resTable.TableId,
                                        DESCR: resTable.TableName
                                    });
                                });
                                this.cd.detectChanges();
                                this.mode === 'E' ? this.formData.controls['TableId'].setValue(this.currentData.item.TableId) : '';
                            }
                        });
                    }

                }
            });
            // this.workflowService.queryTableByAplication(params).subscribe((res:any) => {
            //     if (res.success) {
            //         res.features.forEach((item:any) => {
            //             arrTable.push({
            //                 CODE: item.TableId,
            //                 DESCR: item.TableName
            //             });
            //         });
            //         this.arrTable = arrTable;
            //         this.cd.detectChanges();
            //         this.mode === 'E' ? this.formData.controls['TableId'].setValue(this.currentData.item.TableId) : '';
            //     }
            // });
        }
    }

    getAllUser(){
        const userAssign = this.formData.controls['UserJobtype'].value;
        this.arrUser = [];
        let arrUserSelect = [];
        if (userAssign !== '' && userAssign !== null) {
            arrUserSelect = userAssign.split(",");
            arrUserSelect.forEach((res:any) => {
                this.arrUser.push({
                    CODE: res,
                    DESCR: res
                });
            });
        }
        this.cd.detectChanges();
        this.mode === 'E' ? this.formData.controls['AssignedTo'].setValue(this.currentData.item.AssignedTo) : '';
    }

    buildTree(arr:any, init: boolean = true) {
        let results = [];
        const tree:any = [];
        // const mappedArr = [...this.filterDuplicateArrObj(arr, 'GroupId')];
        const tempArr = arr;
        // mappedArr.forEach(ele => {
        //     const params = {
        //         key: 'g' + ele.GroupId,
        //         title: ele.GroupName,
        //         id: ele.GroupId,
        //         expanded: true,
        //         isLeaf: false,
        //         isGroup: true,
        //         icon: 'assets/um-images/group.png',
        //         children: []
        //     };
        //     tree.push(params);
        //     if (init) {
        //         this.defaultExpandedKeys.push('g' + ele.GroupId);
        //     }
        // });
        tempArr.forEach((ele:any) => {
            ele.key = ele.JobTypeId;
            ele.title = ele.JobTypeName;
            ele.id = ele.JobTypeId;
            ele.data = ele;
            ele.isLeaf = true;
            ele.expanded = true;
            ele.isGroup = false;
            ele.icon = 'assets/imgs/workflow/icn-tree-wf.png';

        });
        tree.forEach((item:any) => {
            tempArr.forEach((ele:any) => {
                ele.key = ele.JobTypeId;
                ele.title = ele.JobTypeName;
                ele.id = ele.JobTypeId;
                ele.data = ele;
                ele.isLeaf = true;
                ele.expanded = true;
                ele.isGroup = false;
                ele.icon = 'assets/imgs/workflow/icn-tree-wf.png';

            });
        });
        results = tempArr;
        return results;
    }

    async listApplication(arrJobType:any) {
        let countJobType = 0;
        let application = null;
        this.arrApplication = [];
        const arrTreApplication:any = [];
        if (this.appService.application !== null) {
            application = this.appService.application;
            console.log(this.appService.application);

        } else {
            const params = { userid: this.userid };
            const res:any = await this.reqService.service.query({
                url: this.appService.urlWS + '/Login/cloudgetAppByUsername',
                params,
            }).pipe(take(1)).toPromise();
            application = res.model;
        }

        application.forEach((element:any) => {
          if (element.isAppSystem !== "Y" && element.isAppSystem !== "G" && element.isAppSystem !== "M" && element.isAppSystem !== "O") {
                this.arrApplication.push({
                    CODE: element.applicationId,
                    DESCR: element.applicationName
                });
                arrTreApplication.push(element);
            }
        });
        this.arrApplication = [...this.arrApplication];
        this.cd.detectChanges();

        const nodes:any = [];
        arrTreApplication.forEach((app:any) => {
            nodes.push({
                "label": app.applicationName,
                "key": app.applicationId,
                // "icon": 'assets/imgs/workflow/icn-tree-wf.png',
                // "iconOpen": 'assets/um-images/icon-tree-app/application_open.png',
                "children": [],
                "expanded": true,
                collapsedIcon: 'ci ci-folder',
                expandedIcon: 'ci ci-folder-open'
            });
        });

        arrJobType.forEach((jobType:any) => {
            nodes.forEach((node:any) => {
                if (node.key === jobType.ApplicationId) {
                    node['children'].push({
                        "label": jobType.JobTypeName,
                        "key": jobType.JobTypeId,
                        "data": jobType.JobTypeData,
                        "isLeaf": true,
                        "item": jobType,
                        collapsedIcon: 'ci ci-workflow',
                        expandedIcon: 'ci ci-workflow'
                    });
                }
            });
        });

        nodes.forEach((item:any) => {
            countJobType += item.children.length;
        });
        this.totalJobType = countJobType;
        this.arrWorkflow = [...nodes];
        this.checkDesignWfUM();

    }

    queryWindow(applicationId:any){
        const appId = typeof(applicationId) === 'object' ?  applicationId.CODE : applicationId;
        const param = ['ApplicationId','=', appId]
        this.workflowService.querySysWindow(param).subscribe((res:any) => {
            if (res.success) {
                this.arrWindow = [];
                res.features.forEach((item:any) => {
                    this.arrWindow.push({
                        CODE: item.WindowId,
                        DESCR: item.WindowName
                    })
                });
                this.cd.detectChanges();
                this.mode === 'E' ? this.formData.controls['WindowId'].setValue(this.currentData.item.WindowId) : '';
            }

        });

    }

    queryWindowOnChange(){
      this.arrWindowOnchange = [];
      const params:any = [['ClientId', '=', this.clientId],['ComponentName', '=', 'AddBPMNComponent']];
      this.workflowService.querySysWindow(params).subscribe((res:any) => {
        if (res.success) {
          res.features.forEach((item:any) => {
            if (item.WindowType === "CUSTOMIZE" ||item.WindowType === "PLUGIN") {
              this.arrWindowOnchange.push({
                  CODE: item.WindowId,
                  DESCR: item.WindowName
              })
           }
          })

          if (this.arrWindowOnchange.length > 0) {
            this.formData.controls['Onchange'].setValue(this.arrWindowOnchange[0]);
          }
        }

      })

    }

    checkDesignWfUM() {
        if (localStorage.getItem('WorkflowDataInfo')) {
            const localWf = localStorage.getItem('WorkflowDataInfo');

            const wfDataInfo = localWf ? JSON.parse(localWf) : '';
            this.onClickEdit('', wfDataInfo)
            // this.mode = 'E';
            // this.isShowFormWf = true;
            // this.isShowDesignWf = true;
            // this.currentData.item = wfDataInfo;
            // const options = { compact: true, ignoreComment: true, spaces: 4 };
            // let val = JSON.parse(this.currentData.item.JobTypeData);
            // this.currentWF = this.currentData.item.data;
            // val = converter.js2xml(val, options);
            // this.bpmnJS.importXML(val, (err:any) => {
            //     if (err) {
            //         console.log(err);
            //     }
            // });

            // this.bindValueToControl(this.currentData.item);
            // this.resetConfigStep();
            // this.getAllStepClick();
            // this.getUserJobType();
        }
    }


    onClearSearch() {
        this.searchValue = '';
    }
    public onResultListInsert() {
        this.clearStorage();
        this.resetForm();
        this.resetConfigStep();
        this.mode = 'A';
        this.isShowFormWf = true;
        this.isShowDesignWf = false;
        this.initFormFroup();
    }

    hilight(n:any) {
        const element = n.component.elRef.nativeElement.querySelector('.title-node-custom');
        element.classList.add('font-highlight');
    }

    activeNode(data:any) {
        // this.activedNode = data.node;
    }

    onExpandChange(evt:any) {
        this.defaultExpandedKeys = evt.keys;
    }


    onExpand(n:any) {
        n.parentNode.isExpanded = true;
        if (n.parentNode.parentNode) {
            this.onExpand(n.parentNode);
        }
    }

    onClickAdd($event:any, node:any) {
        this.mode = 'A';
        this.resetForm();
        this.isShowDesignWf = false;
        this.isShowFormWf = true;
        this.formData.controls['ApplicationId'].setValue(node.key);

        // this.initBpmn();
    }
    onClickDeleteItem($event:any, menu:any) {
        this.appService.confirm(this.appService.getMessage('0005')).subscribe(res => {
            if (res) {
                this.onDelete(menu.key);
            }
        });
    }

    async onClickEdit(evt:any, menu:any) {
        this.clearStorage();
        if (menu.parent) {
            this.mode = 'E';
            this.isShowFormWf = true;
            this.isShowDesignWf = true;
            this.currentData = menu;
            const options = { compact: true, ignoreComment: true, spaces: 4 };
            this.currentWF = menu.item.JobTypeData;
            if (menu.item.JobTypeData !== null) {
                let val = JSON.parse(menu.item.JobTypeData);
                val = converter.js2xml(val, options);
                this.bpmnJS.importXML(val, (err:any) => {
                    if (err) {
                        console.log(err);
                    }
                });
            }

            await this.bindValueToControl(menu.item);
            this.queryColumnEmail(this.currentData.item.TableId);
            // const myTimeout = setTimeout( () => this.querySysColumn(), 1000);
            this.resetConfigStep();
            this.getAllStepClick();
            this.getUserJobType();
            this.querySysTable(menu.item)
        } else {
            // this.resetForm();
            // this.resetConfigStep();
            // this.mode = 'A';
            // this.isShowDesignWf = false;
            // this.isShowFormWf = true;
            // this.formData.controls['ApplicationId'].setValue(menu.key);
        }
    }

    getAllStepClick() {
        const params = ['JobTypeId', '=', this.currentData.item.JobTypeId];
        this.workflowService.queryJobtypeStep(params).subscribe(res => {
            if (res.success) {
                console.log(res);
                res.features.forEach(step => {
                    this.arrInfoStep.push({
                        id: step.StepId,
                        duration: step.Duration,
                        assignment: step.AssignedTo,
                        typeApprove: step.TypeApprove,
                        userApprove: step.UserApprove,
                        valueApprove: step.ValueApprove,
                        tableIdApprove: step.TableIdApprove,
                        columnApprove: step.ColumnApprove,
                        externalWorkflow: step.ExternalWorkflow,
                        externalStep: step.ExternalStep,
                        assignOwner: step.AssignOwner,
                        sendMail: step.SendMail,
                        columnEmail: step.ColumnEmail,
                        directApprove: step.DirectApprove,
                        onApprove: step.OnApprove,
                        changeAssign: step.ChangeAssign,
                        templateEmail: step.TemplateEmail

                    });
                });

            }
        });
    }

    resetConfigStep() {
        this.arrInfoStep = [];
        this.formDataStep.reset();
        this.formDataStep.controls['assignOwner'].setValue('1');
    }

    private bindValueToControl(data:any) {
        Object.keys(this.formData.value).forEach(key => {
            this.formData.controls[key].setValue(data[key]);
        });


    }

    private initFormFroup() {
        this.formData = new FormGroup({
            JobTypeId: new FormControl(),
            JobTypeName: new FormControl(),
            Description: new FormControl(),
            NumDayComplete: new FormControl(),
            TimeUnit: new FormControl(),
            AssignedTo: new FormControl(),
            TableId: new FormControl(),
            WindowId: new FormControl(),
            ApplicationId: new FormControl(),
            UserJobtype: new FormControl(),
            Onchange: new FormControl()
        });

        this.formData.controls['ApplicationId'].valueChanges.subscribe((item:any) => {
            if (item !== null) {
                this.queryWindow(item);
            }
        });

        this.formData.controls['WindowId'].valueChanges.subscribe((item:any) => {
            if (item !== null) {
                this.getAllTable(item);
            }
        });

        this.queryWindowOnChange();
    }

    queryStep(JobTypeId:any, externalStep:any){
        const params = ['JobTypeId','=',JobTypeId];
        this.arrExternalStep = [];
        this.workflowService.queryJobtypeStep(params).subscribe(res => {
            console.log();
            if (res.success) {
                res.features.forEach(item => {
                    this.arrExternalStep.push({
                        CODE: item.StepId,
                        DESCR: item.StepName
                    });
                });

                externalStep !== null ?  this.formDataStep.controls['externalStep'].setValue(externalStep) : '';
            }
        });
    }
    getAllWf() {
        const params = ['ClientId', '=', this.clientId];
        this.workflowService.queryJobtype(params).subscribe((res: any) => {
            if (res.success) {
                this.listApplication(res.features);
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    getWfExternal(){
        this.arrExternalWorkflow = [];
        const params = [['ClientId', '=', this.clientId],['TableId', '=', this.currentData.item.TableId]];
        this.workflowService.queryJobtype(params).subscribe((res: any) => {
            if (res.success) {
                res.features.forEach((item:any) => {
                    this.arrExternalWorkflow.push({
                        CODE: item.JobTypeId,
                        DESCR: item.JobTypeName
                    });
                });
            }
        }, err => {
            this.appService.notification('Đã xảy ra lỗi', 'error');
        });
    }

    private deepCloneObject(obj: Object) {
        return JSON.parse(JSON.stringify(obj));
    }

    public onRowClick(data:any) {
        this.currentData = data;
        const options = { compact: true, ignoreComment: true, spaces: 4 };
        let val = data.JOB_TYPE_DATA;
        val = JSON.parse(data.JobTypeData);
        this.currentWF = data.JobTypeData;
        val = converter.js2xml(val, options);      // to convert javascript object to xml text
        this.bpmnJS.importXML(val, (err:any) => {
            const eventBus = this.bpmnJS.get('task');
            eventBus.on('element.click',  (event:any) => {
                const { element } = event;
                console.log('element clicked:', element);
                console.log('element id + name:', element.id, element.businessObject.name);
                console.log('incoming (before):', element.incoming);
                console.log('outgoing (after):', element.outgoing);
            });
            if (err) {
                console.log(err);
            }

        });
    }

    exportSVG() {
        this.bpmnJS.saveSVG({ format: true },  (error:any, svg:any) =>{
            if (error) {
                return;
            }

            const svgBlob = new Blob([svg], {
                type: 'image/svg+xml'
            });

            // const fileName = Math.random().toString().substring(7) + '.svg';
            const fileName = 'diagram.svg'
            console.log(fileName);

            const downloadLink = document.createElement('a');
            downloadLink.download = fileName;
            downloadLink.innerHTML = 'Get BPMN SVG';
            downloadLink.href = window.URL.createObjectURL(svgBlob);
            // downloadLink.onclick = function (event) {
            //     document.body.removeChild(event.target);
            // };
            downloadLink.style.visibility = 'hidden';
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    }

    exportBPMN(){
        this.bpmnJS.saveXML({ format: true },  (error:any, bpmn:any) =>{
            if (error) {
                return;
            }

            const svgBlob = new Blob([bpmn], {
                type: 'image/bpmn+xml'
            });

            // const fileName = Math.random().toString().substring(7) + '.bpmn';
            const fileName = 'diagram.bpmn'

            const downloadLink = document.createElement('a');
            downloadLink.download = fileName;
            downloadLink.innerHTML = 'Get BPMN SVG';
            downloadLink.href = window.URL.createObjectURL(svgBlob);
            // downloadLink.onclick = function (event) {
            //     document.body.removeChild(event.target);
            // };
            downloadLink.style.visibility = 'hidden';
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    }

    public onResetDiagramWf() {
        this.currentData = null;
        this.importDiagram(this.xml);
    }

    selectUserStep(){
        // this.strCheckedUser = '';
        // const jobTypeId = this.formData.controls['JobTypeId'].value;
        const params = ['JobTypeId', '=', this.currentData.item.JobTypeId];
        this.defaultCheckedUserStep = [];
        this.dataUserStep = [];
        this.workflowService.queryJobtypeUser(params).subscribe(res => {
          if (res.success) {
           this.dataUserStep = this.buildTreeUser(res.features);
           const userSlected = this.formDataStep.controls['assignment'].value;
           if (userSlected !== undefined && userSlected !== '' && userSlected !== null) {
               const listUser = userSlected.split(",");
               listUser.forEach((user:any) => {
                this.dataUserStep.forEach((ele: any) => {
                    if (ele.id === user) {
                        this.defaultCheckedUserStep.push(ele);
                    }
                  });
               });
           }
           this.dialogPrime.title = 'Select User';
           this.dialogPrime.isComponent = false;
           this.dialogPrime.templateRef = this.tempUserStep;
           this.dialogPrime.onShow();
          }
        });
        //

        // const appId =  this.formData.controls['ApplicationId'].value !== null ? typeof (this.formData.controls['ApplicationId'].value) === 'object' ? this.formData.controls['ApplicationId'].value.CODE : this.formData.controls['ApplicationId'].value : this.formData.controls['ApplicationId'].value;
        // if (appId !== null) {
        //     this.dataUserStep = [];
        //     this.defaultCheckedUserStep = [];
        //     const params = {
        //         pageSize: 1000,
        //         pageNumber: 1,
        //         appid: appId
        //     };
        //     this.workflowService.queryUserRoleByAplication(params).subscribe((res:any) => {
        //         console.log(res);
        //         if (res.success) {
        //             let valUserJobType = [];
        //             const arrRole:any = [];
        //             res.features.forEach((element:any) => {
        //                 if (arrRole.length === 0) {
        //                     arrRole.push({
        //                         roleId: element.roleId,
        //                         roleName: element.roleName,
        //                         user: []
        //                     });
        //                 } else {
        //                     const f = arrRole.filter((fill:any) => fill.roleId === element.roleId);
        //                     if (!f[0]) {
        //                         arrRole.push({
        //                             roleId: element.roleId,
        //                             roleName: element.roleName,
        //                             user: []
        //                         });
        //                     }
        //                 }
        //             });

        //             arrRole.forEach((element:any) => {
        //                 const f = res.features.filter((fill:any) => fill.roleId === element.roleId);
        //                 if (f.length > 0) {
        //                     f.forEach((res:any) => {
        //                         element.user.push(res);
        //                     });
        //                 }
        //             });


        //             this.dataUserStep = this.buildTreeUser1(arrRole, res.features);
        //             this.cd.detectChanges();

        //             const valUserStep = this.formDataStep.controls['assignment'].value;
        //             if (valUserStep !== '' && valUserStep !== null) {
        //                 const listUser = valUserStep.split(",");
        //                 listUser.forEach((user:any) => {
        //                 this.dataUserStep.forEach((ele: any) => {
        //                     if (ele.children) {
        //                         ele.children.forEach((element: any) => {
        //                             if (element.id === user) {
        //                                 this.defaultCheckedUserStep.push(element);
        //                             }
        //                         });
        //                     }
        //                     });
        //                 });
        //                 console.log(listUser);

        //             }
        //             this.defaultCheckedUserStep = [...this.defaultCheckedUserStep];
        //             this.dialogPrime.title = 'Select User';
        //             this.dialogPrime.isComponent = false;
        //             this.dialogPrime.templateRef = this.tempUserStep;
        //             this.dialogPrime.onShow();
        //         }
        //     });
        // } else {
        //     this.appService.notification('Vui lòng chọn Ứng dụng','info');
        // }
    }

    onAddUserStep(){
        this.arrCheckedUserStep.length  > 0 ?  this.formDataStep.controls['assignment'].setValue(this.arrCheckedUserStep) :
        this.formDataStep.controls['assignment'].setValue(null);
        this.dialogPrime.onHideDialog();
    }

    onCheckUserStep(evt:any){
        let userNewAdd = evt.currentNode.id;
        const valUSerStep = this.formDataStep.controls['assignment'].value;
        if (valUSerStep !== null) {
            this.arrCheckedUserStep = valUSerStep + ','+ userNewAdd
        } else {
            this.arrCheckedUserStep !== '' ? this.arrCheckedUserStep =  this.arrCheckedUserStep + ',' +  userNewAdd : this.arrCheckedUserStep = userNewAdd;
        }
    }

    onUnCheckUserStep(evt:any){
        let userRemove = evt.currentNode.id;
        let arrCheckedUserStep = this.arrCheckedUserStep.split(",");
        arrCheckedUserStep =  arrCheckedUserStep.filter((fill:any) => fill !== userRemove);
        let strUser = '';
        arrCheckedUserStep.forEach((res:any) => {
            strUser = strUser + res + ',';
        });
        this.arrCheckedUserStep =  strUser.substring(0, strUser.length -1);

    }

    onCancelUserStep (){
        this.dialogPrime.onHideDialog();
    }


    selectUserJobType() {
        const appId =  this.formData.controls['ApplicationId'].value !== null ? typeof (this.formData.controls['ApplicationId'].value) === 'object' ? this.formData.controls['ApplicationId'].value.CODE : this.formData.controls['ApplicationId'].value : this.formData.controls['ApplicationId'].value;
        if (appId !== null) {
            this.dataUserJobtype = [];
            this.defaultCheckedUser = [];
            const params = {
                pageSize: 1000,
                pageNumber: 1,
                appid: appId
            };
            this.workflowService.queryUserRoleByAplication(params).subscribe((res:any) => {
                console.log(res);
                if (res.success) {
                    let arrUserJobType = [];
                    const arrRole:any = [];
                    res.features.forEach((element:any) => {
                        if (arrRole.length === 0) {
                            arrRole.push({
                                roleId: element.roleId,
                                roleName: element.roleName,
                                user: []
                            });
                        } else {
                            const f = arrRole.filter((fill:any) => fill.roleId === element.roleId);
                            if (!f[0]) {
                                arrRole.push({
                                    roleId: element.roleId,
                                    roleName: element.roleName,
                                    user: []
                                });
                            }
                        }
                    });

                    arrRole.forEach((element:any) => {
                        const f = res.features.filter((fill:any) => fill.roleId === element.roleId);
                        if (f.length > 0) {
                            f.forEach((res:any) => {
                                res.userName = res.userName
                                element.user.push(res);
                            });
                        }
                    });

                    this.dataUserJobtype = this.buildTreeUser1(arrRole, res.features);
                    this.cd.detectChanges();
                    if (this.mode === 'E') {
                        const paramsJobTypeUser = ['JobTypeId', '=', this.currentData.item.JobTypeId];
                        this.workflowService.queryJobtypeUser(paramsJobTypeUser).subscribe(TypeUser => {
                            if (TypeUser.success) {
                                this.onReloadTree();
                            }
                        }, err => {
                            this.appService.notification('Đã xảy ra lỗi', 'error');
                        });
                    } else {
                        const valJobtype = this.formData.controls['UserJobtype'].value;
                        arrUserJobType =  valJobtype !== null ?  valJobtype.split(",") : [];
                        arrUserJobType.forEach((user:any) => {
                            this.dataUserJobtype.forEach((ele: any) => {
                                if (ele.children) {
                                    ele.children.forEach((element: any) => {
                                        if (element.id === user) {
                                            this.defaultCheckedUser.push(element);
                                        }
                                    });
                                }
                            });
                        });
                        this.defaultCheckedUser = [...this.defaultCheckedUser];
                        // this.onReloadTree();
                    }
                    this.dialogPrime.title = 'Select User';
                    this.dialogPrime.isComponent = false;
                    this.dialogPrime.templateRef = this.tempUserJobType;
                    this.dialogPrime.onShow();
                }
            });
        } else {
            this.appService.notification('Vui lòng chọn Ứng dụng','info');
        }
    }
    onCheckUser(evt:any) {
        console.log(evt);
        const paramsWfUser = ['ClientId', '=', this.clientId];
        const node = evt.currentNode;
        const arrRQ = [this.workflowService.querySysUser(paramsWfUser), this.workflowService.queryUserBpmn(paramsWfUser)]
        let arrSysUser: any[] = [];

        if (this.mode === 'E') {
            // query table WfUser xem da co username chua
            let arrWfUser:any = [];
            combineLatest(arrRQ).subscribe(res => {
                if (res[0].success) {
                    arrSysUser = res[0].features
                }
                if (res[1].success) {
                    arrWfUser = res[1].features;
                    // check add/ delete tree
                    if (node.children) {
                        if (node.children.length === 0) {
                            // check user da co trong WfUser chua
                            const f = arrWfUser.filter((fill:any) => fill.Username === node.id);
                            const paramsUserJobType = {
                                JobTypeId: this.currentData.item.JobTypeId,
                                ClientId: this.clientId,
                                Username: node.id,
                                WfUserUsername: null
                            };
                            if (f[0]) {
                                this.workflowService.addJobtypeUser(paramsUserJobType).subscribe(res => {
                                    if (res.success) {
                                        this.onReloadTree();
                                    } else {
                                        this.appService.notification('Đã xảy ra lỗi', 'error');
                                    }
                                }, err => {
                                    this.appService.notification('Đã xảy ra lỗi', 'error');
                                });
                            } else {
                                const paramsJob = {
                                    Username : node.id,
                                    GroupId: null,
                                    ClientId : this.clientId
                                };
                                this.workflowService.addUserBpmn(paramsJob).subscribe(item => {
                                    if (item.success) {
                                        this.workflowService.addJobtypeUser(paramsUserJobType).subscribe(res => {
                                            if (res.success) {
                                                this.onReloadTree();
                                            } else {
                                                this.appService.notification('Đã xảy ra lỗi', 'error');
                                            }
                                        }, err => {
                                            this.appService.notification('Đã xảy ra lỗi', 'error');
                                        });
                                    }
                                });
                            }

                        } else {
                            const results = this.getMenuChecked(node.children);
                            const listIns = [];
                            results.forEach((ele:any) => {
                                const f = arrWfUser.filter((fill:any) => fill.Username === ele);
                                const f1 = this.defaultCheckedUser.filter((fill:any) => fill.userName === ele);
                                const paramsJobTypeUser = {
                                    JobTypeId: this.currentData.item.JobTypeId,
                                    ClientId: this.clientId,
                                    Username: ele,
                                    WfUserUsername: null
                                };
                                if (!f1[0]) {
                                    if (f[0]) {
                                        this.workflowService.addJobtypeUser(paramsJobTypeUser).subscribe(resp => {
                                            if (resp.success) {
                                                this.onReloadTree();
                                            } else {
                                                this.appService.notification('Đã xảy ra lỗi', 'error');
                                            }
                                        }, err => {
                                            this.appService.notification('Đã xảy ra lỗi', 'error');
                                        });
                                    } else {
                                        const f = arrSysUser.filter(fill => fill.UserName === ele)
                                        if (f[0]) {
                                            const paramsJob = {
                                                Username : ele,
                                                GroupId: null,
                                                ClientId : this.clientId,
                                                Email: f[0].Email,
                                                Phone: f[0].Phone
                                            };
                                            this.workflowService.addUserBpmn(paramsJob).subscribe(item => {
                                                if (item.success) {
                                                    this.workflowService.addJobtypeUser(paramsJobTypeUser).subscribe(resp => {
                                                        if (resp.success) {
                                                            this.onReloadTree();
                                                        } else {
                                                            this.appService.notification('Đã xảy ra lỗi', 'error');
                                                        }
                                                    }, err => {
                                                        this.appService.notification('Đã xảy ra lỗi', 'error');
                                                    });
                                                }
                                            });
                                        }


                                    }
                                }
                            });

                        }
                    } else {
                        const f = arrWfUser.filter((fill:any) => fill.Username === node.userName);
                        const paramsUserJobType = {
                            JobTypeId: this.currentData.item.JobTypeId,
                            ClientId: this.clientId,
                            Username: node.userName,
                            WfUserUsername: null
                        };
                        if (f[0]) {
                            this.workflowService.addJobtypeUser(paramsUserJobType).subscribe(res => {
                                if (res.success) {
                                    this.onReloadTree();
                                } else {
                                    this.appService.notification('Đã xảy ra lỗi', 'error');
                                }
                            }, err => {
                                this.appService.notification('Đã xảy ra lỗi', 'error');
                            });
                        } else {
                            // tim user trong SysUser de lay email
                            const f = arrSysUser.filter(fill => fill.UserName === node.userName)
                            if (f[0]) {
                                const paramsJob = {
                                    Username : node.userName,
                                    GroupId: null,
                                    ClientId : this.clientId,
                                    Email: f[0].Email,
                                    Phone: f[0].Phone
                                };
                                this.workflowService.addUserBpmn(paramsJob).subscribe(item => {
                                    if (item.success) {
                                        this.workflowService.addJobtypeUser(paramsUserJobType).subscribe(res => {
                                            if (res.success) {
                                                this.onReloadTree();
                                            } else {
                                                this.appService.notification('Đã xảy ra lỗi', 'error');
                                            }
                                        }, err => {
                                            this.appService.notification('Đã xảy ra lỗi', 'error');
                                        });
                                    }
                                });
                            }

                        }
                    }
                }
            })

        } else {
            if (node.children) {
                node.children.forEach((res:any) => {
                    const f = this.arrCheckUser.filter((fill:any) => fill.id === res.id);
                    if (!f[0]) {
                        this.arrCheckUser.push({
                            id: res.userName,
                            name: res.userName
                        });
                    }
                });
            } else {
                const f = this.arrCheckUser.filter((fill:any) => fill.id === node.id);
                if (!f[0]) {
                    this.arrCheckUser.push({
                        id: node.userName,
                        name: node.userName
                    });
                }
            }
        }
    }
    onUnCheckUser(evt:any) {
        console.log(evt);
        const paramsWfUser = ['ClientId', '=', this.clientId];
        const node = evt.currentNode;
        if (this.mode === 'E') {
            // query table WfUser xem da co username chua
            let arrWfUser:any = [];
            this.workflowService.queryUserBpmn(paramsWfUser).subscribe(user => {
                if (user.success) {
                    arrWfUser = user.features;
                    // check add/ delete tree
                    if (node.children) {
                        if (node.children.length === 0) {
                            this.arrUserJobType.forEach((ele:any) => {
                                if (ele.Username === node.origin.id) {
                                    const params = { JobTypeUserId: ele.JobTypeUserId};
                                    this.workflowService.deleteJobtypeUser(params).subscribe(res => {
                                        if (res.success) {
                                            this.onReloadTree();
                                        } else {
                                            this.appService.notification('Đã xảy ra lỗi', 'error');
                                        }
                                    }, err => {
                                        this.appService.notification('Đã xảy ra lỗi', 'error');
                                    });
                                }
                            });

                        } else {
                            const results = this.getMenuChecked(node.children);
                            const listDel: any[] = [];
                            const arrJobTypeUserId:any = [];
                            this.arrUserJobType.forEach((item:any) => {
                                results.forEach((ele:any) => {
                                    if (item.Username === ele) {
                                        arrJobTypeUserId.push(item.JobTypeUserId);
                                    }
                                });
                            });
                            arrJobTypeUserId.forEach((ele:any) => {
                                const param = { JobTypeUserId: ele };
                                listDel.push(this.workflowService.deleteJobtypeUser(param));
                            });
                            combineLatest(listDel).subscribe((res: any) => {
                                this.onReloadTree();
                            });

                        }
                    } else {
                        const userJobyTypeId = this.arrUserJobType.filter((fil:any) => fil.Username === node.userName);
                        if (userJobyTypeId[0]) {
                            const params = { JobTypeUserId: userJobyTypeId[0].JobTypeUserId};
                            console.log(this.arrUserJobType,node);
                            this.workflowService.deleteJobtypeUser(params).subscribe(res => {
                                if (res.success) {
                                    this.onReloadTree();
                                } else {
                                    this.appService.notification('Đã xảy ra lỗi', 'error');
                                }
                            }, err => {
                                this.appService.notification('Đã xảy ra lỗi', 'error');
                            });
                        }

                    }

                }
            });
        } else {
            if (node.children) {
                node.children.forEach((res:any) => {
                    const f = this.arrCheckUser.filter((fill:any) => fill.id === res.id);
                    if (f[0]) {
                       this.arrCheckUser = this.arrCheckUser.filter((fill:any) => fill.id !== res.id);
                    }
                });
            } else {
                const f = this.arrCheckUser.filter((fill:any) => fill.id === node.id);
                if (f[0]) {
                    this.arrCheckUser = this.arrCheckUser.filter((fill:any) => fill.id !== node.id);
                 }
            }

        }
    }
    onReloadTree(){
        const params = ['JobTypeId', '=', this.currentData.item.JobTypeId];
        this.arrUserJobType = [];
        const arrKeyChecked: any = [];
         this.defaultCheckedUser = [];
        this.workflowService.queryJobtypeUser(params).subscribe(res => {
            if (res.success) {
                // this.arrUserJobType = res.features;
                // const arrRole = [];
                // const appId =  this.formData.controls['ApplicationId'].value !== null ? typeof (this.formData.controls['ApplicationId'].value) === 'object'
                // ? this.formData.controls['ApplicationId'].value.CODE : this.formData.controls['ApplicationId'].value : this.formData.controls['ApplicationId'].value;
                // this.defaultCheckedUser = [];
                // this.arrUserJobType.forEach((res:any) => {
                //     this.dataUserJobtype.forEach((ele: any) => {
                //         if (ele.children) {
                //             ele.children.forEach((element: any) => {
                //                 if (res.Username === element.id) {
                //                     this.defaultCheckedUser.push(element);
                //                 }
                //             });
                //         } else {
                //             if (res.Username === ele.id) {
                //                 this.defaultCheckedUser.push(ele);
                //             }
                //         }
                //     });
                // });
                // this.defaultCheckedUser = [...this.defaultCheckedUser];

                // test mới
                res.features.forEach(item => {
                    arrKeyChecked.push(item.Username);
                    this.arrUserJobType.push(item);
                });
                const arrUserFlat = this.flattenTree(this.dataUserJobtype);
                arrUserFlat.forEach((user: any) => {
                    if (user.children) {
                        const length = user.children.filter((x: any) => arrKeyChecked.includes(x.id)).length
                        if (length > 0 && length === user.children.length) {
                            this.defaultCheckedUser.push(user);
                        }
                    }
                    if (arrKeyChecked.includes(user.userName)) {
                        this.defaultCheckedUser.push(user);
                    }
                });
            }
        });
    }

    // user approve
    selectUserApprove(){
        const params = ['JobTypeId', '=', this.currentData.item.JobTypeId];
        this.defaultCheckedUserApprove = [];
        this.dataUserApprove = [];
        const userSlected = this.formDataStep.controls['userApprove'].value;
        userSlected !== null ? this.arrCheckedUserApprove = userSlected : this.arrCheckedUserApprove = '';
        this.workflowService.queryJobtypeUser(params).subscribe(res => {
          if (res.success) {
           this.dataUserApprove = this.buildTreeUser(res.features);
           if (userSlected !== undefined && userSlected !== '' && userSlected !== null) {
               const listUser = userSlected.split(",");
               listUser.forEach((user:any) => {
                this.dataUserApprove.forEach((ele: any) => {
                    if (ele.id === user) {
                        this.defaultCheckedUserApprove.push(ele);
                    }
                  });
               });
           }
           this.dialogPrime.title = 'Select User';
           this.dialogPrime.isComponent = false;
           this.dialogPrime.templateRef = this.tempUserApprove;
           this.dialogPrime.onShow();
          }
        });
    }

    onCheckUserApprove(evt:any){
        let userNewAdd = evt.currentNode.id;
        const valUSerStep = this.formDataStep.controls['userApprove'].value;
        if (valUSerStep !== null) {
            this.arrCheckedUserApprove = valUSerStep + ','+ userNewAdd;
        } else {
            this.arrCheckedUserApprove !== '' ? this.arrCheckedUserApprove =  this.arrCheckedUserApprove + ',' +  userNewAdd : this.arrCheckedUserApprove = userNewAdd;
        }
    }

    onUnCheckUserApprove(evt:any){
        let userRemove = evt.currentNode.id;
        let arrCheckedUser = this.arrCheckedUserApprove.split(",");
        arrCheckedUser =  arrCheckedUser.filter((fill:any) => fill !== userRemove);
        let strUser = '';
        arrCheckedUser.forEach((res:any) => {
            strUser = strUser + res + ',';
        });
        this.arrCheckedUserApprove =  strUser.substring(0, strUser.length -1);
    }

    onCancelUserApprove(){
        this.dialogPrime.onHideDialog();
    }

    onAddUserApprove(){
        this.arrCheckedUserApprove.length  > 0 ?  this.formDataStep.controls['userApprove'].setValue(this.arrCheckedUserApprove) :
        this.formDataStep.controls['userApprove'].setValue(null);
        this.dialogPrime.onHideDialog();
    }

    querySysColumn(tableId:any, columnApprove: any = null){
        // if (columnApprove !== null) {
            const params = ['TableId','=', tableId];
            this.arrColumnApprove = [];
            this.workflowService.queryColBPMN(params).subscribe(res => {
                if (res.success) {
                    this.arrColumnApprove = [];
                    res.features.forEach(item => {
                        if (item.DomainId !== null) {
                            this.arrColumnApprove.push({
                                CODE: item.ColumnName,
                                DESCR: item.Alias
                            });
                        }
                    });

                    this.arrColumnApprove = [...this.arrColumnApprove]
                    this.formDataStep.controls['column'].setValue(columnApprove);
                    this.cd.detectChanges()
                }

            });
        // } else {
        //     this.formDataStep.controls['column'].setValue(columnApprove);
        // }
    }

    queryColumnEmail(tableId:any) {
        const params = ['TableId','=', tableId];
        this.arrColumnEmail = [];
        this.workflowService.queryColBPMN(params).subscribe(res => {
            if (res.success) {
                res.features.forEach(item => {
                    this.arrColumnEmail.push({
                        CODE: item.ColumnName,
                        DESCR: item.Alias
                    });

                });
                this.cd.detectChanges()
            }
        });
    }

    onChkChangeTable(evt:any){
        if (evt.checked) {
            this.isShowFormApprove = true;
            this.requiredFormApprove = true
            this.isStepApproveNoBack = false
        } else {
            this.requiredFormApprove = false
            this.cd.detectChanges();
            this.isShowFormApprove = false;
        }

    }

    onChangeAPBack(evt:any){
        console.log(this.isStepApprove);
        if (evt.checked) {
            this.isShowFormApprove = true;
            this.requiredFormApprove = true
            this.isStepApprove = false;
            this.cd.detectChanges();
        } else {
            this.requiredFormApprove = false
            this.cd.detectChanges();
            this.isShowFormApprove = false;
        }

        this.cd.detectChanges();
    }


    onChkSendMail(evt:any){
        if (evt.checked) {

            const userName = this.formDataStep.controls['assignment'].value;
            let arrRQ: any = [];
            let checkEmail:boolean = true

            if (userName !== '' && userName !== null) {
                const check = userName.includes(',')
                if (check) {
                    const arrUserName = userName.split(',');
                    arrUserName.forEach((user:any) => {
                        const params = ['UserName', '=', user]
                        arrRQ.push(this.workflowService.queryUserBpmn(params))
                    })

                } else {
                    const params = ['UserName', '=', userName]
                    arrRQ = [this.workflowService.queryUserBpmn(params)]
                }

                combineLatest(arrRQ).subscribe( res => {
                    console.log(res);
                    res.forEach( (item: any) => {
                        if (item.success && item.features[0]) {
                            if (item.features[0].Email === null || item.features[0].Email === '') {
                                this.appService.notification(`User ${item.features[0].Username} không có email`, 'error')
                                checkEmail = false
                                this.isSendMail = false
                            }

                        }
                    })

                })

            }
        } else {

        }

    }

    onAddUserJobType() {
        let strCheckUser:any = '';
        if (this.mode === 'E') {
            this.defaultCheckedUser.forEach((res:any) => {
                if (!res.children) {
                    strCheckUser = strCheckUser + res.label + ',';
                }
            });
            strCheckUser = strCheckUser.substring(0, strCheckUser.length - 1);
            this.formData.controls['UserJobtype'].setValue(strCheckUser);
            // arrChecked.length  > 0 ?  this.formData.controls['UserJobtype'].setValue(JSON.stringify(arrChecked)) :  this.formData.controls['UserJobtype'].setValue(null);
        } else {
            this.arrCheckUser.forEach((res:any) => {
                strCheckUser = strCheckUser + res.id + ',';
            });
            strCheckUser = strCheckUser.substring(0, strCheckUser.length - 1);
            this.formData.controls['UserJobtype'].setValue(strCheckUser);
            // this.arrCheckUser.length  > 0 ?  this.formData.controls['UserJobtype'].setValue(JSON.stringify( this.arrCheckUser)) :  this.formData.controls['UserJobtype'].setValue(null);
        }
        this.getAllUser();
        this.dialogPrime.onHideDialog();
    }

    getUserJobType() {
        const params = ['JobTypeId', '=', this.currentData.item.JobTypeId];
        this.arrUserJobType = [];
        const arrVal:any = [];
        let strUserJobType = '';
        this.workflowService.queryJobtypeUser(params).subscribe(res => {
            if (res.success) {
                this.arrUserJobType = res.features;
                res.features.forEach(ele => {
                    // arrVal.push({
                    //     id: ele.Username,
                    //     name: ele.Username
                    // });
                    strUserJobType = strUserJobType  + ele.Username + ',';
                });
                strUserJobType = strUserJobType.substring(0, strUserJobType.length - 1);
                this.formData.controls['UserJobtype'].setValue(strUserJobType);
                this.getAllUser();
            }
        });
    }

    buildTreeUser1(arrRole:any, arrUser:any) {
        let  arrRoleTree:any = [];
        arrRole.forEach((ele:any) => {
            const params = {
                key: ele.roleId,
                label: ele.roleName,
                id: ele.roleId,
                data: ele,
                expanded: true,
                // isLeaf: false,
                children: []
            };
            arrRoleTree.push(params);
        });
        arrUser.forEach((item:any) => {
            arrRoleTree.forEach((ele:any) => {
                if (item.roleId === ele.key) {
                    item.key = item.roleId + '_' + item.userId;
                    item.label = item.userName;
                    item.id =  item.roleId + '_' + item.userId;;
                    item.data = item;
                    item.isLeaf = true;
                    // item.expanded = true;
                    ele.children.push(item);
                }
            });
        });
        arrRoleTree.forEach((ele:any) => {
            ele.children.forEach((element:any) => {
                const f = this.arrUserJobType.filter((fill:any) => fill.Username === element.label);
                if (f[0]) {
                    element.JobTypeUserId = f[0].JobTypeUserId;
                }
            });
        });
        arrRoleTree = [...arrRoleTree];

        return arrRoleTree;
    }

    buildTreeUser(arrUser:any) {
        let  arrUserTree:any = [];
        arrUser.forEach((ele:any) => {
            const params = {
                key: ele.Username,
                label: ele.Username,
                id: ele.Username,
                data: ele,
                expanded: false,
                children: []
            };
            arrUserTree.push(params);
        });
        arrUserTree = [...arrUserTree];

        return arrUserTree;
    }


    flattenTree(arr: any) {
        let res: any = [];
        arr.forEach((ele: any) => {
            res.push(ele);
            if (ele.children) {
                const temp = this.flattenTree(ele.children);
                res = res.concat(temp);
            }
        });
        return res;
    }

    onCancelUser() {
        this.dialogPrime.onHideDialog();
    }


    getMenuChecked(arrMenu:any) {
        let result: any = [];
        arrMenu.forEach((ele: any) => {
            if (!ele.children) {
                if (ele.data.IsSummary !== 'Y') { result.push(ele.userName); }
            } else {
                if (ele.children.length === 0) {
                    result.push(ele.userName);
                } else {
                    const temp = this.getMenuChecked(ele.children);
                    result = result.concat(temp);
                }
            }
        });
        return result;

    }


    addUserJobType(jobTypeId:any) {
        const valJobtype = this.formData.controls['UserJobtype'].value;
        let arrUserSelect:any = [];
        arrUserSelect = valJobtype.split(",");
        // check user da co trong wfUser chua, chua co thi Add them
        const params = ['ClientId', '=', this.clientId];
        let arrWfUser:any = [];
        this.workflowService.queryUserBpmn(params).subscribe(user => {
            if (user.success) {
                arrWfUser = user.features;
                arrUserSelect.forEach((res:any) => {
                    const f = arrWfUser.filter((fill:any) => fill.Username === res);
                    if (f[0]) {
                        const param = {
                            ClientId: this.clientId,
                            JobTypeId: jobTypeId,
                            Username: res,
                            WfUserUsername: null
                        };
                        this.workflowService.addJobtypeUser(param).subscribe(resp => {
                            if (resp.success) {
                            } else {
                                this.appService.notification('Đã xảy ra lỗi', 'error');
                            }
                        }, err => {
                            this.appService.notification('Đã xảy ra lỗi', 'error');
                        });
                    } else {
                        const paramsJob = {
                            Username : res,
                            GroupId: null,
                            ClientId : this.clientId
                        };
                        this.workflowService.addUserBpmn(paramsJob).subscribe(item => {
                            if (item.success) {
                                const param = {
                                    ClientId: this.clientId,
                                    JobTypeId: jobTypeId,
                                    Username: res
                                };
                                this.workflowService.addJobtypeUser(param).subscribe(resp => {
                                    if (resp.success) {

                                    } else {
                                        this.appService.notification('Đã xảy ra lỗi', 'error');
                                    }
                                }, err => {
                                    this.appService.notification('Đã xảy ra lỗi', 'error');
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    public onSave() {
        if (this.formData.valid) {
            this.onSaveWf();
        } else {
            this.appService.createMessage('warning', this.appService.getMessage('0006'));
        }
    }

    public async onShowFormCreate() {
        this.isShowFormSave = true;
        this.dialogPrime.isComponent = false;
        this.dialogPrime.title = 'Add Workflow';
        this.dialogPrime.templateRef = this.formTem;
        this.dialogPrime.onShow();
        this.formData.reset();
    }

    async onSaveWf() {
        const arrTuan:any = [];
        let invalidStepName = true;
        let assignStep1 = '';
        // tslint:disable-next-line: prefer-const
        let arrJobTypeStep:any = [];
        const arrTypeStepStart = ['bpmn:startEvent', 'bpmn:task', 'bpmn:exclusiveGateway', 'bpmn:intermediateThrowEvent', 'bpmn:endEvent'];
        const arrTypeStep = [ 'bpmn:startEvent', 'bpmn:task', 'bpmn:exclusiveGateway', 'bpmn:intermediateThrowEvent','bpmn:endEvent'];

        const result = await this.bpmnJS.saveXML({ format: true });
        const result1 = converter.xml2json(result.xml, {
            compact: true,
            spaces: 2,
        });

        const JSONData = JSON.parse(result1);
        const bpmnProcess = JSONData['bpmn:definitions']['bpmn:process']['bpmn:subProcess'] ? JSONData['bpmn:definitions']['bpmn:process']['bpmn:subProcess'] : JSONData['bpmn:definitions']['bpmn:process'];
        let startEvent = '';
        let countStart = 0;
        // check để chọn bước bắt đầu

        if (bpmnProcess['bpmn:startEvent']) {
            // neu co 2 step bat dau
            if (bpmnProcess['bpmn:startEvent'].length > 1) {
                countStart = countStart + 1;
                this.appService.notification('Không thể lưu: Có 2 bước bắt đầu', 'error');
                return;
            } else {
                startEvent = bpmnProcess['bpmn:startEvent']._attributes.id;
                countStart = countStart + 1;
            }

        }
        arrTypeStepStart.forEach(item => {
            if (bpmnProcess[item]) {
                if (bpmnProcess[item].length) {
                    bpmnProcess[item].forEach((element:any) => {
                        if (element['bpmn:outgoing'] && element['bpmn:incoming'] === undefined) {
                            startEvent = element._attributes.id;
                            countStart = countStart + 1;
                        }
                    });

                } else {
                    if (bpmnProcess[item]['bpmn:outgoing'] && bpmnProcess[item]['bpmn:incoming'] === undefined) {
                        startEvent = bpmnProcess[item]._attributes.id;
                    }
                }
            }
        });

        if (startEvent == '') {
            this.appService.notification('Thiết kế sai bước bắt đầu', 'warning');
            return;
        }

        // get step
        if (countStart > 1) {
            this.appService.notification('Không thể lưu: Có 2 bước bắt đầu', 'error');
            return;
        }
        const sequenceFlow = bpmnProcess['bpmn:sequenceFlow'];
        // Lấy tên và id step
        arrTypeStep.forEach(type => {
            if (bpmnProcess[type]) {
                const val = bpmnProcess[type];
                if (val.length) {
                    val.forEach((res:any) => {
                        arrJobTypeStep.push({
                            StepId: res._attributes.id,
                            NextStepId: '',
                            StepName: (res._attributes.name) ? res._attributes.name : '',
                            Description: '',
                            AssignedTo: '',
                            Duration: 0,
                            JobTypeId: 0,
                            TypeApprove: null,
                            UserApprove: null,
                            TableIdApprove: null,
                            ColumnApprove: null,
                            ValueApprove: null,
                            ExternalWorkflow: null,
                            ExternalStep: null,
                            WhereStep: null,
                            AssignOwner: null


                        });
                    });
                } else {
                    arrJobTypeStep.push({
                        StepId: val._attributes.id,
                        NextStepId: '',
                        StepName: (val._attributes.name) ? val._attributes.name : '',
                        Description: '',
                        AssignedTo: '',
                        Duration: 0,
                        JobTypeId: 0,
                        TypeApprove: null,
                        UserApprove: null,
                        TableIdApprove: null,
                        ColumnApprove: null,
                        ValueApprove: null,
                        ExternalWorkflow: null,
                        ExternalStep: null,
                        WhereStep: null,
                        AssignOwner: null
                    });
                }
            }
        });

        // kiem tra step name
        arrJobTypeStep.forEach((res:any) => {
            if (res.StepName === '') {
                invalidStepName = false;
            }
        });


        // ghep arrJobTypeStep với form config step
        arrJobTypeStep.forEach((step:any) => {
            this.arrInfoStep.forEach((item:any) => {
                if (step.StepId === item.id) {
                    step.AssignedTo = item.assignment;
                    step.Duration = item.duration;
                    step.TypeApprove = item.typeApprove;
                    step.UserApprove = item.userApprove;
                    step.TableIdApprove  = item.tableIdApprove ;
                    step.ColumnApprove = item.columnApprove;
                    step.ValueApprove = item.valueApprove;
                    step.ExternalWorkflow = item.externalWorkflow;
                    step.ExternalStep = item.externalStep;
                    step.AssignOwner = item.assignOwner;
                    step.SendMail = item.sendMail;
                    step.ColumnEmail = item.columnEmail;
                    step.DirectApprove = item.directApprove;
                    step.OnApprove = item.onApprove;
                    step.ChangeAssign = item.changeAssign;
                    step.TemplateEmail = item.templateEmail;


                }
            });
        });

        this.arrInfoStep.forEach((item:any) => {
            if (item.id === startEvent) {
                if (item.assignment !== '' && item.assignment !== null) {
                    // checkAssignStep1 = false;
                    assignStep1 = item.assignment;
                }
            }
        });


        if (sequenceFlow) {
            if (sequenceFlow && sequenceFlow.length) {
                arrJobTypeStep.forEach((step:any) => {
                    let count = 0;
                    sequenceFlow.forEach((res:any) => {
                        if (step.StepId === res._attributes.sourceRef) {
                            count += 1;
                            arrTuan.push({
                                StepId: step.StepId,
                                NextStepId: res._attributes.targetRef,
                                Description: step.StepName,
                            });
                        }
                    });

                    if (count === 0) {
                        arrTuan.push({
                            StepId: step.StepId,
                            NextStepId: '',
                            Description: step.StepName,
                        });
                    }
                });
            } else {
                arrJobTypeStep.forEach((step:any) => {
                    let count = 0;
                    if (step.StepId === sequenceFlow._attributes.sourceRef) {
                        count += 1;
                        arrTuan.push({
                            StepId: step.StepId,
                            NextStepId: sequenceFlow._attributes.targetRef,
                            Description: step.StepName,
                        });
                    }
                    if (count === 0) {
                        arrTuan.push({
                            StepId: step.StepId,
                            NextStepId: '',
                            Description: step.StepName,
                        });
                    }
                });
            }

        } else {
            arrJobTypeStep.forEach((step:any) => {
                arrTuan.push({
                    StepId: step.StepId,
                    NextStepId: '',
                    Description: step.StepName,
                });
            });

        }

        if (sequenceFlow && sequenceFlow.length) {
            sequenceFlow.forEach((item:any) => {
                if (item._attributes.name) {
                    const f = arrJobTypeStep.filter((fill:any) => fill.StepId === item._attributes.targetRef);
                    if (f[0]) {
                        f[0].WhereStep = item._attributes.name;
                    }
                }
            });
        }

        if (this.mode === 'E') {
            if (invalidStepName) {
                // if (assignStep1 !== ''){
                    const paramJobTypeStep = ['JobTypeId', '=', this.currentData.item.JobTypeId];
                    this.workflowService.queryJobtypeStep(paramJobTypeStep).subscribe(val => {
                        if (val.total > 0) {
                            console.log(val);
                            val.features.forEach(res => {
                                this.workflowService.deleteJobtypeStep({ JobTypeStepId: res.JobTypeStepId }).subscribe(res => {
                                }, err => {
                                    this.appService.notification('Đã xảy ra lỗi', 'error');
                                });
                            });
                        }
                    }, err => {

                    });

                    this.workflowService.queryStepXref(paramJobTypeStep).subscribe(val => {
                        if (val.total > 0) {
                            console.log(val);
                            val.features.forEach(res => {
                                this.workflowService.deleteStepXref({ JobTypeStepXrefId: res.JobTypeStepXrefId }).subscribe(res => {
                                }, err => {
                                    this.appService.notification('Đã xảy ra lỗi', 'error');
                                });
                            });
                        }
                    }, err => {
                        this.appService.notification('Đã xảy ra lỗi', 'error');
                    });

                    const params:any = {};
                    Object.keys(this.formData.value).forEach(key => {
                        params[key] = this.formData.controls[key].value;
                    });

                    const param = {
                        JobTypeId: this.currentData.item.JobTypeId,
                        JobTypeName: params['JobTypeName'],
                        AssignedTo:  params['AssignedTo'] !== null ? typeof (params['AssignedTo']) === 'object' ? params['AssignedTo'].CODE : params['AssignedTo'] : params['AssignedTo'],
                        JobTypeData: JSON.stringify(JSONData),
                        Description: params['Description'],
                        NumDayComplete: params['NumDayComplete'],
                        CreateDate: this.dateToDate(this.currentData.item.CreateDate),
                        JobStepStart: startEvent,
                        TimeUnit: params['TimeUnit'] !== null ? typeof (params['TimeUnit']) === 'object' ? params['TimeUnit'].CODE : params['TimeUnit'] : params['TimeUnit'],
                        clientId: this.clientId,
                        TableId: params['TableId'] !== null ? typeof (params['TableId']) === 'object' ? params['TableId'].CODE : params['TableId'] : params['TableId'],
                        ApplicationId: params['ApplicationId'] !== null ? typeof (params['ApplicationId']) === 'object' ? params['ApplicationId'].CODE : params['ApplicationId'] : params['ApplicationId'],
                        WindowId: params['WindowId'] !== null ? typeof (params['WindowId']) === 'object' ? params['WindowId'].CODE : params['WindowId'] : params['WindowId']
                    };
                    this.workflowService.updateJobtype(param).subscribe(res => {
                        this.appService.notification('Update success', 'success');
                        this.getAllWf();
                        arrJobTypeStep.forEach((step:any) => {
                            step.JobTypeId = this.currentData.item.JobTypeId;
                            console.log(step);
                            this.workflowService.addJobtypeStep(step).subscribe(result => {
                                console.log(result);
                            }, err => {
                                this.appService.notification('Đã xảy ra lỗi', 'error');
                            });
                        });

                        arrTuan.forEach((xref:any) => {
                            xref.JobTypeId = this.currentData.item.JobTypeId;
                            xref.ClientId = this.clientId;
                            console.log(xref);
                            this.workflowService.addStepXref(xref).subscribe(result => {
                                console.log(result);
                            }, err => {
                                this.appService.notification('Đã xảy ra lỗi', 'error');
                            });
                        });
                    }, err => {
                        this.appService.notification('Đã xảy ra lỗi', 'error');
                    });

                    this.updateSysTable(param);

                // } else {
                //     this.appService.notification('Invalid assignment step start', 'warning');
                // }
            } else {
                this.appService.notification('Invalid step name', 'warning');
            }
        } else {
            // Them moi
            const date = new Date();
            const params:any = {};
            Object.keys(this.formData.value).forEach(key => {
                params[key] = this.formData.controls[key].value;
            });

            const param = {
                AssignedTo : params['AssignedTo'] !== null ? typeof (params['AssignedTo']) === 'object' ? params['AssignedTo'].CODE : params['AssignedTo'] : params['AssignedTo'],
                JobTypeName: params['JobTypeName'],
                JobTypeData: JSON.stringify(JSONData),
                Description: params['Description'],
                NumDayComplete: params['NumDayComplete'],
                CreateDate: date,
                JobStepStart: startEvent,
                TimeUnit: params['TimeUnit'] !== null ? typeof (params['TimeUnit']) === 'object' ? params['TimeUnit'].CODE : params['TimeUnit'] : params['TimeUnit'],
                clientId: this.clientId,
                TableId: params['TableId'] !== null ? typeof (params['TableId']) === 'object' ? params['TableId'].CODE : params['TableId'] : params['TableId'],
                ApplicationId: params['ApplicationId'] !== null ? typeof (params['ApplicationId']) === 'object' ? params['ApplicationId'].CODE : params['ApplicationId'] : params['ApplicationId'],
                WindowId: params['WindowId'] !== null ? typeof (params['WindowId']) === 'object' ? params['WindowId'].CODE : params['WindowId'] : params['WindowId']

            };

            this.workflowService.addJobType(param).subscribe((res: any) => {
                if (res.success === true) {
                    this.appService.notification('Inserted Successfully', 'success');
                    // sau khi them moi set currentdata de edit luon
                    const key = {key: res.features[0].JobTypeId, item: res.features[0]};
                    this.currentData = key;
                    this.mode = 'E';
                    this.isShowFormWf = true;
                    this.isShowDesignWf = true;

                    this.getAllWf();
                    this.addUserJobType(res.features[0].JobTypeId);
                    arrJobTypeStep.forEach((res1:any) => {
                        res1.JobTypeId = res.features[0].JobTypeId;
                        console.log(res1);
                        this.workflowService.addJobtypeStep(res1).subscribe(step => {
                            console.log(result);
                        }, err => {
                            this.appService.notification('Đã xảy ra lỗi', 'error');
                        });
                    });
                    arrTuan.forEach((res2:any) => {
                        res2.JobTypeId = res.features[0].JobTypeId;
                        console.log(res2);
                        this.workflowService.addStepXref(res2).subscribe(xref => {
                            console.log(result);
                        }, err => {
                            this.appService.notification('Đã xảy ra lỗi', 'error');
                        });
                    });

                }
            }, err => {
                this.appService.notification('Đã xảy ra lỗi', 'error');
            });

            this.updateSysTable(param);
        }
    }

    updateSysTable(paramWf:any){
        const value = this.formData.controls['Onchange'].value;
        const windowOnchange = typeof(value) === 'object' ? value.CODE : value
        let paramsTableUpdate = null;
        const params =  ['TableID', '=',  paramWf.TableId];
        this.workflowService.querySysTable(params).subscribe(res => {
            console.log(res);
            if (res.success) {
                if (res.features[0]) {
                    paramsTableUpdate = res.features[0];
                    paramsTableUpdate.OnChange = windowOnchange

                    this.workflowService.updateSysTable(paramsTableUpdate).subscribe(res => {
                        if (!res.success) {
                            this.appService.notification('Lỗi lưu Window onchange', 'error')
                        }

                    }, err => {

                    })
                }
            }

        })
    }

    querySysTable(params:any){
        const paramsTable = ['TableID', '=', params.TableId]
        this.workflowService.querySysTable(paramsTable).subscribe(res => {
            if (res.message && res.features[0]) {
                const val = res.features[0].OnChange;
                if (val !== '' && val !== null) {
                    this.formData.controls['Onchange'].setValue(Number(val))
                }
            }
        })
    }

    public onDelete(jobTypeId:any) {
        this.workflowService.deleteJobtype({JobTypeId: jobTypeId}).subscribe(res => {
            console.log(res);
            if (res.success) {
                this.appService.notification('Xóa thành công', 'success');
                this.getAllWf();
                this.resetForm();
            }
        }, err => {
            this.appService.notification(err.message, 'error');
        });
    }

    onShowHideLeftPanel(){
        this.showLeftPanel = !this.showLeftPanel;
    }

    showDialogEmail(){
        this.dialogPrime.title = 'Template Email';
        this.dialogPrime.isComponent = false;
        this.dialogPrime.templateRef = this.tempEmail;
        this.dialogPrime.onShow();
    }


    onSaveTemplatemail(){
        this.dialogPrime.onHideDialog();
    }



    public resetForm() {
        this.importDiagram(this.xml);
        this.currentData = null;
        this.formData.reset();
        this.clearStorage();
        this.arrUser = [];
        this.arrWindow = [];
        this.cd.detectChanges();
    }

    public dateToDate(date:any) {
        let dateString = '';
        if (date !== null) {
            const newDate = new Date(date);
            dateString += newDate.getFullYear();
            dateString += '-';
            dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
            dateString += '-';
            dateString += newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
        } else {
            dateString = '';
        }

        return dateString;
    }


    private importDiagram(xml: string): Observable<{ warnings: Array<any> }> {
        return from(this.bpmnJS.importXML(xml) as Promise<{ warnings: Array<any> }>);
    }



    clearStorage() {
        if (localStorage.getItem('WorkflowDataInfo')) { localStorage.removeItem('WorkflowDataInfo'); }
    }
    onExpandPanel() {
        this.isExpanded = !this.isExpanded
    }

}
