import { Component, Input, forwardRef, OnInit, Output, EventEmitter, ViewChild, TemplateRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';
import { RequestService } from '../services/request.service';

import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR
            , useExisting: forwardRef(() => InputComponent)
            , multi: true
        }
    ]
})
export class InputComponent implements OnInit, ControlValueAccessor, AfterViewInit {

    requiredClass = false;
    loading_tree: boolean = false
    treeConfig: any = {
        selectionMode: 'single',
        loading: this.loading_tree,
        filter: true
    }
    val_ten: any = null;

    private _required = false;
    @Input('required')
    set required(val: boolean) {
        this._required = val;
        this.requiredClass = val;
        if (this.ctrl) {
            this.ctrl.setValidators(val === true ? [Validators.required] : [Validators.nullValidator]);
        }
    }
    get required() {
        return this._required;
    }
    toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']
    ];
    // @Input() isReadOnly: boolean = false;
    @Input() isOpenLink: boolean = false
    @Input() isFeedBack = false;
    @Input() infoHelp = false;
    @Input() infoHelpContent = '';
    @Input() pattern = '';
    @Input() showLabel = true;
    @Input() labelName = '';
    @Input() labelWidth = '200';
    @Input() placeholder = '';
    @Input() isLabelLeft = true;
    @Input() maxValue = 2147483648;
    @Input() _disabledIcon = true;
    @Input() rowTxtArea = '4';
    @Input() vFormat: any = null;
    @Input() isCtrlBlur: boolean = true;
    @Input() hasFormCtrl: boolean = true;
    @Input() hasButton: boolean = false;
    @Input() iconBtn: any = 'pi-plus';
    @Input() titleToolTip: any = null;
    @Input() notUseTranslate = false;


    @ViewChild(DialogPrimeComponent) dialogPrime!: DialogPrimeComponent;
    @ViewChild('tmpTree') tmpTree!: TemplateRef<any>;
    ctrl: FormControl = new FormControl();

    step: any = 1
    minFraction: any = null;
    maxFraction: any = null;
    styleEle: any = ''

    public isPasswordType = false;
    _valueModel: any = null;
    @Input()
    get valueModel() {
        return this._valueModel === "" ? null : this._valueModel;
    }

    set valueModel(value) {
        if (this.required) {
            if (value !== null && value !== '') {
                this.requiredClass = false;
            } else {
                this.requiredClass = true
            }
        } else {
            this.requiredClass = false
        }
        this.cd.detectChanges();
        if (this.type === 'input-tree') {
            if (value !== null && value !== '') {
                if (this.isBindData) {
                    this.searchWithValue(value)
                }
            } else {
                this._valueModel = null;
                this.valueModelChange.emit(this._valueModel);
            }


        } else {
            this._valueModel = value;
            this.valueModelChange.emit(this._valueModel);
        }

    }
    private _type = 'text';
    @Input('type')
    set type(val) {
        this._type = val;
        if (val === 'password') {
            this.isPasswordType = true;
        }
    }
    get type() { return this._type; }

    private _value = null;
    @Input('value')
    set value(val) {
        this._value = val;
        this.writeValue(val);
    }
    get value() { return this._value; }
    @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
    @Output() iconClick: EventEmitter<any> = new EventEmitter();
    @Output() enterEvent: EventEmitter<any> = new EventEmitter();
    @Output() blurEvent: EventEmitter<any> = new EventEmitter();
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    @Output() valueModelChange: EventEmitter<any> = new EventEmitter();
    @Output() clickEvent: EventEmitter<any> = new EventEmitter();

    public _maxLength = 524288;
    @Input() typeIcon = 'ellipsis-h';
    @Input('maxlength')
    set maxLength(val: any) {
        this._maxLength = val ? val : 524288;
    }
    get maxLength() {
        return this._maxLength;
    }
    _dataSource: any = null;
    @Input('dataSource')
    set dataSource(val: any) {
        this._dataSource = val;
    }
    get dataSource() {
        return this._dataSource;
    }
    private _disabledCtrl = false;
    @Input('disabledCtrl')
    set disabledCtrl(val: boolean) {
        this._disabledCtrl = val;
        if (this.ctrl) {
            // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }
    }
    get disabledCtrl() {
        return this._disabledCtrl;
    }

    private _isReadOnly = false;
    @Input('isReadOnly')
    set isReadOnly(val: boolean) {
        this._isReadOnly = val;
        if (this.ctrl) {
            // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        }
    }
    get isReadOnly() {
        return this._isReadOnly;
    }

    currentSelect: any = null;
    handleRequest: any = null;
    arrDataTree: any[] = [];
    private onChange: (value: any) => void = () => { };
    private onTouched: () => void = () => { };

    constructor(
        private reqService: RequestService,
        private cd: ChangeDetectorRef
    ) { }
    ngAfterViewInit(): void {
        this.cd.detectChanges()
    }

    ngOnInit() {
        this.initVformat();
        this.initForm();
    }

    // Khởi tạo vformat cho input type number
    private initVformat() {
        if (this.vFormat && this.vFormat !== '' && typeof (this.vFormat) === 'string') {
            // const index1 = this.vFormat.indexOf('.');
            // const index2 = this.vFormat.indexOf('-');
            // const minFraction = Number(this.vFormat.substring(index1 + 1, index2));
            // const maxFraction = Number(this.vFormat.substring(index2 + 1, this.vFormat.length));

            // if (minFraction <= maxFraction) {
            //     this.minFraction = minFraction;
            //     this.maxFraction = maxFraction;
            // }
            const obj = JSON.parse(this.vFormat);
            if (obj.maxFractionDigits) {
                this.maxFraction = obj.maxFractionDigits
            }
            if (obj.minFractionDigits) {
                if (this.maxFraction) {
                    if (this.minFraction <= this.maxFraction) {
                        this.minFraction = obj.minFractionDigits
                    }

                } else {
                    this.minFraction = obj.minFractionDigits
                }

            }

            if (obj.step) {
                this.step = obj.step
            }
            if (obj.reg) {
                this.pattern = obj.reg
            }
            if (obj.style) {
                if (this.type === 'number') {
                    const arr = obj.style.split(';')
                    const obj_style: any = {}
                    arr.forEach((str: any) => {
                        const key = str.substring(0, str.indexOf(':'))
                        const val = str.substring(str.indexOf(':') + 1, str.length).trim()
                        obj_style[key] = val
                    })
                    this.styleEle = obj_style
                } else {
                    this.styleEle = obj.style
                }

            }

        }
    }

    private initForm() {
        // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        this.ctrl = new FormControl(null, {
            updateOn: this.isCtrlBlur ? 'blur' : 'change',
            validators: this._required === true ? Validators.required : Validators.nullValidator
        });
        this.isReadOnly ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
        this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
            if (this._required) {
                if (val === null || val === '') {
                    this.requiredClass = true;
                } else {
                    this.requiredClass = false;
                }
            }

            if (this.type === 'number') {
                if (val !== null && val !== undefined && val !== '') {
                    if (this.vFormat) {
                        try {
                            this.ctrl.setValue(val);
                            this.onChange(Number(val));
                        } catch (error) {
                            this.onChange(null);
                        }
                    } else {
                        this.onChange(Number(val));
                    }
                } else {
                    this.onChange(null);
                }
            } else if (this.type === 'input-tree') {
                if (this.currentSelect) {
                    const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
                    this.onChange(this.currentSelect.data[tag]);
                }
            } else {
                this.onChange(val === '' ? null : val);
            }
        });
    }
    onDropdownClick(evt: any) {
        // console.log(evt)
        // console.log(evt)
        // this.ctrl.setValue(evt['code'], {emitEvent: false})
        if (this.hasFormCtrl) {
            this.onChange(evt['code']);
        } else {
            this.valueModel = evt['code']
            this.val_ten = evt['name']
            this.valueModelChange.emit(evt['code'])
        }

        this.valueChange.emit(evt['code']);
    }

    onChangeValue() {
        if (this.type === 'number' && this.ctrl.value !== null) {
            const value = Number(this.ctrl.value);
            if (value > this.maxValue) {
                this.ctrl.setValue(this.maxValue.toString());
            }
        }
    }

    clearTxt() {
        this.ctrl.reset();
        this.onChange(this.ctrl.value);
    }
    
    onClickIcon() {
        if ((!this.disabledCtrl && !this.isReadOnly) || this.isOpenLink) {
            this.iconClick.emit();
        }
    }

    onEnterEvent(evt: any) {
        if (evt.keyCode === 13) {
            this.enterEvent.emit(evt.target.value);
        }
    }
    isBindData: any = true
    searchWithValue(_value: any) {
        const url = this.dataSource.FOREIGNTABLE;
        switch (this.dataSource.SERVICE_TYPE) {
            // case 'AutoData':
            //     this.reqService.switchType('autodata');
            //     break;
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgrest':
                this.reqService.switchType('postgre');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }
        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
        const where = [[tag, '=', _value]]
        this.queryDataTree(where, url).subscribe((res: any) => {
            if (res.success) {
                this.isBindData = false
                if (res.features.length > 0) {
                    this.val_ten = res.features[0][this.dataSource.COLUMNDISPLAY]
                    const obj = {
                        code: res.features[0][tag],
                        name: res.features[0][this.dataSource.COLUMNDISPLAY]
                    }
                    this._valueModel = obj
                } else {
                    this.val_ten = null
                    this._valueModel = null
                }

                if (this.required) {
                    if (this._valueModel !== null && this._valueModel !== '') {
                        this.requiredClass = false;
                    } else {
                        this.requiredClass = true
                    }
                } else {
                    this.requiredClass = false
                }
            }
        });
    }
    results: any = [];
    search(evt: any) {
        const url = this.dataSource.FOREIGNTABLE;
        switch (this.dataSource.SERVICE_TYPE) {
            // case 'AutoData':
            //     this.reqService.switchType('autodata');
            //     break;
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgrest':
                this.reqService.switchType('postgre');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }
        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
        const where = [[tag, 'like', evt.query]]
        // this.results = [];
        this.queryDataTree(where, url).subscribe((res: any) => {
            if (res.success) {
                const arr: any = [];
                const tag_1 = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
                const tag_2 = this.dataSource.COLUMNCODE ? this.dataSource.COLUMNCODE : this.dataSource.COLUMNKEY
                res.features.forEach((item: any) => {
                    arr.push({
                        code: item[tag_1],
                        name: item[this.dataSource.COLUMNDISPLAY],
                        // key: item[tag_1]
                    })
                    this.results = [...arr]
                })

            }
        });
    }
    initDataTree() {
        if (this.dataSource) {
            const url = this.dataSource.FOREIGNTABLE;
            // const url = 'https://water.esrivn.net/server/rest/services/CHU/DM_KVHC_MOI/FeatureServer/1'
            switch (this.dataSource.SERVICE_TYPE) {
                // case 'AutoData':
                //     this.reqService.switchType('autodata');
                //     break;
                case 'CloudData':
                    this.reqService.switchType('clouddata');
                    break;
                case 'SQL':
                    this.reqService.switchType('sql');
                    break;
                case 'FeatureServer':
                case 'MapServer':
                    this.reqService.switchType('arcgis3x');
                    break;
                case 'postgrest':
                    this.reqService.switchType('postgre');
                    break;
                default:
                    this.reqService.switchType('sql');
                    break;
            }
            // let where: any[] = [[this.dataSource.TREECOLUMN, "=", null]];
            let where: any[] = [[this.dataSource.TREECOLUMN, "is", null]];
            if (this.handleRequest) {
                this.handleRequest.unsubscribe();
            }
            this.loading_tree = true;
            this.queryDataTree(where, url).subscribe((res: any) => {
                if (res.success) {
                    this.arrDataTree = [];
                    const field_display = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
                    res.features.forEach((item: any) => {
                        this.arrDataTree.push({
                            label: item[this.dataSource.COLUMNDISPLAY],
                            key: item[field_display],
                            expanded: false,
                            selectable: true,
                            data: item,
                            "leaf": false,
                            "expandedIcon": "pi pi-folder-open",
                            "collapsedIcon": "pi pi-folder",
                            children: [],
                        })
                    })
                    this.loading_tree = false
                }
            })

        }
    }
    queryDataTree(where: any[], url: any) {
        if (this.dataSource.DEFAULT_WHERE) {
            where = where.concat(this.dataSource.DEFAULT_WHERE);
        }
        const res = this.reqService.service.search({
            url,
            where, // lấy tất cả dữ liệu
            logic: 'and'
        });
        return res;
    }
    arr_filter: any[] = [];
    onTreeFilter(evt: any) {
        this.arr_filter = evt.filteredValue;
        // console.log(this.arrDataTree)
        // console.log(evt)
    }
    onnodeExpandEvt(evt: any) {
        // const url = this.dataSource.FOREIGNTABLE;
        if (evt.node.children.length === 0) {
            // const url = 'https://water.esrivn.net/server/rest/services/CHU/DM_KVHC_MOI/FeatureServer/1'
            const url = this.dataSource.FOREIGNTABLE;
            const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
            // const where = [[tag, '=', _value]]
            const where = [[this.dataSource.TREECOLUMN, '=', evt.node.data[tag]]]
            this.queryDataTree(where, url).subscribe((res: any) => {
                if (res.success) {
                    evt.node.children = [];
                    const field_display = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
                    res.features.forEach((item: any) => {
                        evt.node.children.push({
                            label: item[this.dataSource.COLUMNDISPLAY],
                            key: item[field_display],
                            expanded: false,
                            selectable: true,
                            data: item,
                            "leaf": false,
                            "expandedIcon": "pi pi-folder-open",
                            "collapsedIcon": "pi pi-folder",
                            children: [],
                        })
                    })

                    this.arr_filter.forEach((item: any) => {
                        const index = this.arrDataTree.findIndex((v: any) => v.key === item.key)
                        if (index > -1) {
                            this.arrDataTree[index].children = [...item.children]
                        }
                    })
                }
            });
        }



    }
    onNodeSelect(evt: any) {
        this.val_ten = evt.data[this.dataSource.COLUMNDISPLAY]
        const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
        if (this.hasFormCtrl) {
            this.ctrl.setValue({ code: evt.data[tag], name: evt.data[this.dataSource.COLUMNDISPLAY] })
        } else {
            this._valueModel = { code: evt.data[tag], name: evt.data[this.dataSource.COLUMNDISPLAY] }
            this.valueModelChange.emit(this._valueModel)
        }
        this.valueChange.emit()
        this.dialogPrime.closeDialog();
    }
    onClickOpenDialog() {
        // console.log(this.disabledCtrl, this.isReadOnly)
        if (!this.disabledCtrl) {
            this.initDataTree();
            this.dialogPrime.isComponent = false;
            this.dialogPrime.title = 'Select value';
            this.dialogPrime.templateRef = this.tmpTree;
            this.dialogPrime.onShow();
            this.dialogPrime.onClose.subscribe(() => {
                this.arrDataTree = [];
                this.currentSelect = null;
            })
        }

    }

    // Hàm của input number (control của primeng)
    onInputNumberEnterEvent(evt: any) {
        if (evt.originalEvent.keyCode === 13) {
            this.enterEvent.emit(evt.originalEvent.target.value);
        }
    }

    // tslint:disable-next-line:variable-name
    writeValue(_value: any): void {
        if (this.type === 'number') {
            this.ctrl.setValue(_value);
        } else if (this.type === 'input-tree') {
            if (_value !== null && _value !== '') {
                // const url = 'https://water.esrivn.net/server/rest/services/CHU/DM_KVHC_MOI/FeatureServer/1'
                const url = this.dataSource.FOREIGNTABLE;
                switch (this.dataSource.SERVICE_TYPE) {
                    // case 'AutoData':
                    //     this.reqService.switchType('autodata');
                    //     break;
                    case 'CloudData':
                        this.reqService.switchType('clouddata');
                        break;
                    case 'SQL':
                        this.reqService.switchType('sql');
                        break;
                    case 'FeatureServer':
                    case 'MapServer':
                        this.reqService.switchType('arcgis3x');
                        break;
                    case 'postgrest':
                        this.reqService.switchType('postgre');
                        break;
                    default:
                        this.reqService.switchType('sql');
                        break;
                }
                const tag = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
                const where = [[tag, '=', _value]]
                this.queryDataTree(where, url).subscribe((res: any) => {
                    if (res.success) {
                        if (res.features.length > 0) {
                            this.val_ten = res.features.length > 0 ? res.features[0][this.dataSource.COLUMNDISPLAY] : null
                            const tag_1 = this.dataSource.COLUMNKEY ? this.dataSource.COLUMNKEY : this.dataSource.COLUMNCODE
                            this.ctrl.setValue({ code: res.features[0][tag_1] });

                        } else {
                            this.val_ten = null;
                            this.ctrl.setValue(_value);
                        }

                    }
                })
            } else {
                this.val_ten = null;
                this.ctrl.setValue(_value);
            }

        } else {
            this.ctrl.setValue(_value);
            // if (this.onChange !== undefined) {
            //     this.onChange(_value);
            // }
        }

        if (this._required) {
            if (_value === null || _value === '') {
                this.requiredClass = true;
            } else {
                this.requiredClass = false;
            }
        } else {
            this.requiredClass = false;
        }
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    onBlurEvent(evt: any) {
        this.blurEvent.emit(evt);
    }

    onClickEvent(evt: any) {
        this.clickEvent.emit();
    }
    onClickIconList() { }
}
