import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { ReportService } from 'app/reports/services/reports.service';

@Component({
  selector: 'app-send-params-for-config',
  template: `<ng-container *ngIf="isRenderComp">
    <app-param-config #viewParamConfig></app-param-config>
  </ng-container>`
})
export class SendParamsForConfigComponent implements OnInit {
  isRenderComp: boolean = false
  public params = {};
  constructor(
    private appService: AppService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
  }

  getMenuToolItem(evt: any) {
    const ReportCurentData = JSON.stringify(this.params);
    const url = this.reportService.getUrlHost('report/loadreport');
    if (evt.isMobile) {
      // window['nsWebViewBridge'].emit('report', { url });
      // window['nsWebViewBridge'].emit('reportParams', this.params);
      // window['nsWebViewBridge'].off('reportParams', this.params);

    } else {
      if (evt.typeRun === "MENUITEM") {
        this.isRenderComp = true;
        window.PamramsReport = ReportCurentData;
      } else {
        this.isRenderComp = false;
        let win: any = window.open(url, '_blank');
        win.PamramsReport = ReportCurentData;
      }
    }
  }
}
