export const ColumManager = {
    0: {
        ColumnID: 0,
        ColumnName: 'JobId',
        ColumnAlias: 'Id',
        ColumnType: 'N',
        ColumnIndex: 1,
        IsNull: true,
        IsPryKey: false,
        isReadField: false,
        IsSearch: true },
   1: {
        ColumnID: 1,
        ColumnName: 'JobName',
        ColumnAlias: 'Tên',
        ColumnType: 'T',
        ColumnIndex: 1,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 2: {
        ColumnID: 2,
        ColumnName: 'JobTypeId',
        ColumnAlias: 'Quy trình',
        ColumnType: 'C',
        ColumnIndex: 2,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 3: {
        ColumnID: 3,
        ColumnName: 'Description',
        ColumnAlias: 'Mô tả',
        ColumnType: 'T',
        ColumnIndex: 3,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 4: {
        ColumnID: 4,
        ColumnName: 'AssignedTo',
        ColumnAlias: 'Giao cho',
        ColumnType: 'C',
        ColumnIndex: 4,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 5: {
        ColumnID: 5,
        ColumnName: 'StartDate',
        ColumnAlias: 'Ngày bắt đầu',
        ColumnType: 'D',
        ColumnIndex: 5,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
  }, 6: {
        ColumnID: 6,
        ColumnName: 'DueDate',
        ColumnAlias: 'Ngày hạn',
        ColumnType: 'D',
        ColumnIndex: 6,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 7: {
      ColumnID: 7,
      ColumnName: 'PercComplete',
      ColumnAlias: '%',
      ColumnType: 'N',
      ColumnIndex: 7,
      IsNull: true,
      IsPryKey: false,
      isReadField: true,
      IsSearch: false
  },8: {
      ColumnID: 8,
      ColumnName: 'StatusId',
      ColumnAlias: 'Trạng thái',
      ColumnType: 'C',
      ColumnIndex: 8,
      IsNull: true,
      IsPryKey: false,
      isReadField: true,
      IsSearch: false
   }
};

export const columReportWorking = {
    0: {
        ColumnID: 0,
        ColumnName: 'StepName',
        ColumnAlias: 'Giai đoạn',
        ColumnType: 'T',
        ColumnIndex: 1,
        IsNull: true,
        IsPryKey: false,
        isReadField: false,
        IsSearch: true
    }, 1: {
        ColumnID: 1,
        ColumnName: 'totalDurationPercent',
        ColumnAlias: 'Thời gian hoàn thành trung bình',
        ColumnType: 'hourComlete',
        ColumnIndex: 1,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 2: {
        ColumnID: 2,
        ColumnName: 'SLA',
        ColumnAlias: 'SLA',
        ColumnType: 'hour',
        ColumnIndex: 2,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }, 3: {
        ColumnID: 3,
        ColumnName: 'Mission',
        ColumnAlias: 'Nhiệm vụ',
        ColumnType: 'times',
        ColumnIndex: 3,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: false
    }
};

export const columReportAssign = {
    0: {
        ColumnID: 0,
        ColumnName: 'Member_manager',
        ColumnAlias: 'Thành viên',
        ColumnType: 'T',
        ColumnIndex: 0,
        IsNull: true,
        IsPryKey: false,
        isReadField: false,
        IsSearch: true
    }, 1: {
        ColumnID: 1,
        ColumnName: 'Times',
        ColumnAlias: 'Được giao',
        ColumnType: 'times',
        ColumnIndex: 1,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 2: {
        ColumnID: 2,
        ColumnName: 'WaitingTime',
        ColumnAlias: 'Thời gian chờ',
        ColumnType: 'hour',
        ColumnIndex: 2,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 3: {
        ColumnID: 3,
        ColumnName: 'SLA',
        ColumnAlias: 'SLA giai đoạn',
        ColumnType: 'hour',
        ColumnIndex: 3,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 4: {
        ColumnID: 4,
        ColumnName: 'OutOfDate',
        ColumnAlias: 'Quá hạn',
        ColumnType: 'times',
        ColumnIndex: 4,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 5: {
        ColumnID: 5,
        ColumnName: 'OverDueTime',
        ColumnAlias: 'TG quá hạn',
        ColumnType: 'hour',
        ColumnIndex: 5,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }
};


export const columTableJob = {
    0: {
        ColumnID: 0,
        ColumnName: 'jobName',
        ColumnAlias: 'jobName',
        ColumnType: 'T',
        ColumnIndex: 0,
        IsNull: true,
        IsPryKey: false,
        isReadField: false,
        IsSearch: true
    },
      1: {
        ColumnID: 1,
        ColumnName: 'description',
        ColumnAlias: 'Description',
        ColumnType: 'T',
        ColumnIndex: 1,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 2: {
        ColumnID: 2,
        ColumnName: 'assignedTo',
        ColumnAlias: 'assignedTo',
        ColumnType: 'T',
        ColumnIndex: 2,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 3: {
        ColumnID: 3,
        ColumnName: 'percComplete',
        ColumnAlias: 'percComplete',
        ColumnType: 'T',
        ColumnIndex: 3,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }, 4: {
        ColumnID: 4,
        ColumnName: 'curStep',
        ColumnAlias: 'curStep',
        ColumnType: 'T',
        ColumnIndex: 4,
        IsNull: true,
        IsPryKey: false,
        isReadField: true,
        IsSearch: true
    }
}

