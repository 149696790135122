import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, HostListener, Inject, Injector, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app-base/app.service';
import { ConfirmationService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { combineLatest, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { CoreFormComponent } from '../core-form/core-form.component';
import { CoreSearchComponent } from '../core-search/core-search.component';
import { Information } from '../core-window';
import { CoreWindowService } from '../core-window/core-window.service';
import { DialogPrimeComponent } from '../dialog-prime/dialog-prime.component';
import { DeleteResponse, InsertResponse, RequestService, SearchResponse } from '../services/request.service';
import { SearchDataComponent } from './search-data/search-data.component';
import { WindowService } from 'app/um/services';
import { PluginService } from 'app/plugins/plugins.service';
import * as XLSX from 'xlsx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { SavedomainService } from '../services/savedomain.service';
export interface TableColumnDefination {
    rowRequired?: boolean;
    rowHeader: string;
    rowFieldName: string;
    rowDomainId: any;
    rowType: any;
    rowFormat: any;
    rowDisable: boolean;
    rowIsDomain: boolean;
    rowIsFrozen: boolean;
    sortOrderNo: number;
    rowWidth: number;
    rowFieldFilter: {
        FOREIGNTABLE: any;
        FOREIGNTABLE_ID: any;
        COLUMNKEY: any;
        COLUMNDISPLAY: any;
        COLUMNCODE: any;
        PARENT_FIELDID: any; // dùng để lọc lấy rowFieldFilter của trường cha
        PARENT_COLDEF?: any; // dữ liệu cấu hình của field cha
        WHEREFIELDNAME: any; // sử dụng cho control select
        WHERECLAUSE: any; // sử dụng cho control select/input search
        FOREIGNWINDOW_ID: any;
        SERVICE_TYPE?: any;
        TREECOLUMN?: any,
        FIELD_ISDOMAIN: boolean;
    };
    rowDirty: boolean; // đánh đấu cell đã sửa hay chưa, true là đã sửa
    rowFilterDirty: boolean; // đánh dấu cell đã filter hay chưa
    rowFieldId: any; // id của field, dùng để lọc lấy rowFieldFilter của trường cha
}

export interface QueryParams {
    where: any;
    isPageChange?: boolean;
    logic?: string;
    geometry?: any;
}

@Component({
    selector: 'app-core-table',
    templateUrl: './core-table.component.html',
    styleUrls: ['./core-table.component.scss'],
    providers: [
        RequestService
    ]
})
export class CoreTableComponent implements OnInit, OnDestroy {
    @Output() eventEmit: EventEmitter<{
        type: string;
        value: any;
        isLock: boolean;
        tab?: any;
    }> = new EventEmitter();
    private pageChangeFinish: EventEmitter<any> = new EventEmitter();

    @Output() eventAddCalendar: EventEmitter<any> = new EventEmitter(); // event add cho calendar
    @Output() evtReOrder: EventEmitter<any> = new EventEmitter();
    @Output() evtColResize: EventEmitter<any> = new EventEmitter();
    @Output() evtColConf: EventEmitter<any> = new EventEmitter();

    @ViewChild('pTable') pTable!: Table | any;
    @ViewChild(CoreFormComponent, { static: false }) formControl!: CoreFormComponent;
    @ViewChild('dialogComponent', { static: true }) dialogPrime!: DialogPrimeComponent;
    @ViewChild('dialogLockComponent', { static: true }) dialogLockComponent!: DialogPrimeComponent;
    @ViewChild('dialogLogComponent', { static: true }) dialogLog!: DialogPrimeComponent;
    @ViewChild('paginator', { static: false }) paginator!: Paginator;
    @ViewChild('viewRefHidden', { read: ViewContainerRef }) viewRefHidden!: ViewContainerRef;
    @ViewChild('fileExcelImportData') fileExcelImportData!: ElementRef;
    @ViewChild('importDataFromExcel') importDataFromExcel!: TemplateRef<any>;
    @ViewChild('exportDataFromExcel') exportDataFromExcel!: TemplateRef<any>;
    @ViewChild('tmpShowLog') tmpShowLog!: TemplateRef<any>;
    @ViewChild('tmpAttachment') tmpAttachment!: TemplateRef<any>;
    @ViewChild('tmpLock') tmpLock!: TemplateRef<any>;
    @Input() parent: any = null
    // reorder table
    @Input() isReorder: boolean = true;

    /** Mảng phẳng tất cả các tab */
    @Input() baseTabStruct = null;
    /** Số lượng bản ghi mỗi trang, mặc định là 10 */
    @Input() pageSize = 10;
    /** Vị trí trang trong bảng, mặc định trang 1 */
    @Input() pageIndex = 1;
    /** Dữ liệu để khởi tạo bảng với header, ... */
    @Input() dataSource: any = null;
    private _where: any = null;
    /** Điều kiện tìm kiếm bằng câu lệnh */
    @Input() where: any = null;
    /** Điệu kiện tìm kiếm bằng hình học */
    @Input() geometry = null;
    /** Mở form insert */
    @Input() insertNew: any = null;
    /** Hiển thị nút chọn, sử dụng ở filter-input component */
    @Input() isShowBtnChoose = false;
    /** Kiểm tra có phải bảng được mở có trong module bản đồ */
    @Input() isMapApp = false;
    /** Kiểm tra có phải mode filter trong wizard */
    @Input() isFilter = false;
    @Input() isParentLock: boolean = false
    /** Kiểm tra có phải hiển thị ở Layout */
    // @Input() isLayout = false;
    private _isLayout = false;
    @Input()
    get isLayout(): boolean { return this._isLayout; }
    set isLayout(isLayout) {
        this._isLayout = isLayout;
    }
    public arrSaveRecord: any[] = [];
    public loading = false;
    public resultList: any[] = [];
    public tblColumnDef: TableColumnDefination[] = [];
    public lookupCondition: any = {};
    public currentData: any = null;
    public primaryKey: any = null;
    public bindDataToForm: any = null;
    public totalRecord = 0;
    public is_showtool = true;
    public mode = 'table'; // table || form
    public isUpdate = false; // Đang chế độ sửa hay view
    public quickSearch!: FormControl;
    public filterFormGroup!: FormGroup;
    public filterFields: any[] = [];
    public tableLoading = false;
    public selectedRow: any[] = [];

    public tooltype = null; // Kiểu Tool đang được kích hoạt

    public canLinkTable = false;
    public canZoom = false; // Có thể zoom tới vị trí (nếu có dữ liệu về geometry)
    public canQuickSearch = false;

    cache_DataTable: any = null;
    cache_RowSelected: any = null;

    // import, export 
    isShowImportBtn: boolean = true
    data_import: any = {
        dataExcel: [],
        arrSheet: [],
        indexSheet: 0
    };
    //



    // Log
    tableConfig: any = {
        scrollHeight: "auto",
        responsiveLayout: 'scroll',
        styleClass: "p-datatable-gridlines",
        paginator: true,
        rows: 10,
        resizableColumns: false
    }
    arrColumnLog: any[] = [
        {
            key: 'TableId',
            title: 'Table Id',
            type: 'number',
            maxlength: '100',
            isRequired: false,
            isEdit: false,
            width: '10%',
            isOnlyForm: true
        }, {
            key: 'RecordId',
            title: 'Record Id',
            type: 'number',
            maxlength: '100',
            isRequired: false,
            isEdit: false,
            width: '10%',
            isOnlyForm: true
        }, {
            key: 'UserName',
            title: 'User Name',
            type: 'string',
            maxlength: '100',
            isRequired: false,
            isEdit: false,
            width: '25%',
            isOnlyForm: true
        }, {
            key: 'CREATE_DATE',
            title: 'Create Date',
            type: 'date',
            maxlength: '100',
            isRequired: false,
            isEdit: false,
            width: '35%',
            isOnlyForm: true
        }, {
            key: 'ActionName',
            title: 'Action Name',
            type: 'string',
            maxlength: '100',
            isRequired: false,
            isEdit: false,
            width: '20%',
            isOnlyForm: true
        }
    ]
    //


    public listRadio = [
        { CODE: '1', DESCR: 'Xóa liên kết' },
        { CODE: '2', DESCR: 'Xóa dữ liệu' }
    ]
    public radioCtrl = new FormControl('1');

    public disableTool: any = {
        search: false,
        insert: false,
        edit: false,
        save: false,
        delete: false,
        link: false,
        zoom: false,
        history: false
    };


    // Biến lưu trữ dành cho phần filter distinct
    public lookupFieldDistinct: any = {};
    public currentFieldDistinct: any = null;
    public selectedValuesDistinct: any[] = [];
    private listValuesDistinct: any = {};
    private whereFilterDistinct: any = null; // Lữu trữ câu điều kiện của bộ lọc (icon phễu ở title);

    editCache: {
        [key: string]: { edit: boolean; data: any; editForUpdate: boolean };
    } = {};

    // Các biến cục bộ
    private _urlEdit = '';
    // các biến sử dụng để tách biệt 2 hàm onRowClick và onRowDblClick
    private initShowFirstTime = true;
    private handleRequestSearch: any = null;
    private preventChangePage = false;


    // MenuItemCustomize
    public menuList: any[] = [];
    public menubarItems: any = null;
    public permissionList: any = {};

    public isFirstTimeInit = true;
    public showFilter = false;

    private reloadParams: any = null; // Lưu trữ params lại params query lần gần nhất để thực hiện reload
    private whereKvhc: any = null;
    private whereBeforeSearch: any = null;
    private whereFilter: any = null; // Lưu trữ điều kiện filter;

    // confg cho wizard
    cofg: any = [{
        label: 'Display',
        value: 'IsDisplay',
        checked: false
    }, {
        label: 'Search',
        value: 'IsSearch',
        checked: false
    }, {
        label: 'Search TongHop',
        value: 'IsSearchTongHop',
        checked: false
    }, {
        label: 'Require',
        value: 'IsRequire',
        checked: false
    }, {
        label: 'ReadOnly',
        value: 'IsReadOnly',
        checked: false
    }, {
        label: 'Display Grid',
        value: 'IsDisplayGrid',
        checked: false
    }, {
        label: 'Filter Field',
        value: 'IsFilterField',
        checked: false
    }, {
        label: 'Frozen',
        value: 'IsUnique',
        checked: false
    }];
    contextPosition = { x: '0', y: '0' };
    isCofgColumn = false;
    titleColumn: any;

    archiveType: 'manual' | 'auto' = 'manual'

    @Input() arrField: any = [];
    arrDataField: any = [];
    urlProxy: any = null;
    private reqServiceSQL: RequestService;
    private reqServiceAutoData: RequestService;
    userStatus: number = 0;


    constructor(
        public appService: AppService,
        private coreWindowService: CoreWindowService,
        public viewContainerRef: ViewContainerRef,
        private cd: ChangeDetectorRef,
        private confirmService: ConfirmationService,
        public translate: TranslateService,
        public windowService: WindowService,
        private elemRef: ElementRef,
        public pluginService: PluginService,
        private http: HttpClient,
        private domSanitizer: DomSanitizer,
        private reqService: RequestService,
        injector: Injector,
        private savedomainservice: SavedomainService

    ) {
        // this.urlProxy = this.appService.urlProxy + '?';
        this.checkDomain()
        this.reqServiceSQL = new RequestService(injector);
        this.reqServiceSQL.switchType('sql');
        this.reqServiceAutoData = new RequestService(injector);
        this.reqServiceAutoData.switchType('clouddata');



        // Thêm status trong user
        // status = 0 hoặc 1: bình thường
        // status = 3 không thêm sửa xóa, thêm attachment, link-table, un-link
        const currenrUser = JSON.parse(this.appService.currentUser);
        this.userStatus = currenrUser.status;
    }
    isMobileScreen: boolean = false
    @HostListener("window:resize", ['$event'])
    private onResize(evt: any) {
        if (evt.target.screen.width <= 600) {
            this.isMobileScreen = true
        } else {
            this.isMobileScreen = false
        }
    }

    handleLanguageChange: any = null;
    ngOnInit(): void {
        // console.log(this);
        // Thay đổi rowHeader khi đổi ngôn ngữ (nếu có cấu hình đa ngôn ngữ cho tên header)
        this.handleLanguageChange = this.translate.onLangChange.subscribe(res => {
            if (res && this.dataSource) {
                this.dataSource.FIELD_LIST.forEach((field: any) => {
                    try {
                        const descr = JSON.parse(field.alias);
                        const text = descr[this.translate.currentLang];
                        const findCol = this.tblColumnDef.filter(fil => fil.rowFieldName === field.fieldname);
                        if (findCol && findCol.length > 0) {
                            findCol[0].rowHeader = text;
                        }
                    } catch (error) {

                    }
                });
            }
            if (res && this.menubarItems) {
                this.menubarItems.forEach((item: any) => {
                    if (item.data && item.data.menudesc && item.data.menudesc !== '') {
                        try {
                            const descr = JSON.parse(item.data.menudesc);
                            const text = descr[this.translate.currentLang];
                            item.label = text;
                        } catch (error) {

                        }
                    }
                })
                this.menubarItems = [...this.menubarItems]
            }
        });
        if (window.screen.width <= 600) {
            this.isMobileScreen = true
        } else {
            this.isMobileScreen = false
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.cd.detectChanges()
        if (changes.isMapApp) {
            if (this.isMapApp && this.dataSource) {
                const a = this.dataSource.FIELD_LIST.filter((fil: any) => fil.columndohoa !== null && fil.columndohoa !== undefined);
                if (a.length > 0) {
                    this.canZoom = true;
                }
            }
        }

        if (changes.where) {
            this._where = changes.where.currentValue;
            this.whereBeforeSearch = JSON.parse(JSON.stringify(this._where));
            this.initTable();
        }

        // if (changes.geometry && changes.geometry.currentValue) {
        //     this.queryData({
        //         where: [],
        //         geometry: this.geometry.clone().geometry
        //     });
        // }

        if (changes.dataSource) {
            if (this.isMapApp) {
                const a = this.dataSource.FIELD_LIST.filter((fil: any) => fil.columndohoa !== null && fil.columndohoa !== undefined);
                if (a.length > 0) {
                    this.canZoom = true;
                }
            }
            const infor = this.dataSource.INFORMATION;
            let menu = this.appService.appConfig && this.appService.appConfig.Menus ? this.appService.appConfig.Menus : [];
            if (this.appService.appConfigAppId0 && this.appService.appConfigAppId0.Menus) {
                menu = menu.concat(this.appService.appConfigAppId0.Menus);
            }
            this.menuList = this.appService.getMenuType(menu, 'TOOL');
            this.menuList = this.menuList.filter(fil => fil.tabid === infor.TAB_ID && fil.windowid.toString() === infor.WINDOW_ID.toString());
            this.menuList.forEach(item => item.disabled = false);
            this.menuList = this.menuList.sort(this.appService.dynamicsort('order_no', 'asc'));
            this.menuList.forEach(item => item.showMenu = true);
            this.menuList = this.appService.deepCloneObject(this.menuList);
            this.createMenubar();
            // this.initQuickSearch();
            if (this.isFirstTimeInit) {
                this.isFirstTimeInit = false;
                if (infor.FILTER_FIELD) { // nếu có filter field > load control filter field
                    this.initMultiFilter(infor);
                } else {
                    // Ở trong Layout sẽ không query data
                    if (!this.isLayout) {
                        this.queryData({
                            where: this._where
                        });
                    }
                }
            }
        }

        if (changes.insertNew) {
            if (this.insertNew) {
                if (this.insertNew.openInsert) {
                    this.onInsert();
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (this.handleLanguageChange) {
            this.handleLanguageChange.unsubscribe();
        }
    }

    private changeTypeRequest() {
        switch (this.dataSource.INFORMATION.SERVICE_TYPE) {
            case 'CloudData':
                this.reqService.switchType('clouddata');
                break;
            case 'SQL':
                this.reqService.switchType('sql');
                break;
            case 'FeatureServer':
            case 'MapServer':
            case 'WorkflowServices':
                this.reqService.switchType('arcgis3x');
                break;
            case 'postgrest':
                this.reqService.switchType('postgre');
                break;
            case 'oracle':
                this.reqService.switchType('oracle');
                break;
            default:
                this.reqService.switchType('sql');
                break;
        }
    }

    private async initMultiFilter(infor: Information) {
        const filterFields = JSON.parse(infor.FILTER_FIELD);
        const group: any = {};
        let whereStore: any[] = ['and'];
        this.whereFilter = whereStore;

        for (let index = 0; index < filterFields.length; index++) {
            const item = filterFields[index];
            // 0: khóa, 1: biểu thức, 2: giá trị mặc định, 3 placeholder, 4: lookup, 5: config FIELD_FILTER
            if (index === 0 && typeof (item) === 'string') {
                whereStore[0] = item;
                continue;
            }
            const key = item[0]; // trường điều kiện chọn lọc
            const operator = item[1]; // biểu thức lọc lấy giá trị của trường
            const val = item[2]; // giá trị mặc định

            const a = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === key);
            item[3] = a && a.length > 0 ? a[0].alias : key; // Placeholder
            // dùng key + index vì có trường hợp người dùng sử dụng cùng 1 field nhưng có 2 bộ lọc
            // (ví dụ trường date - applyDate: lọc theo năm và lọc theo tháng)
            group[key + index] = new FormControl();

            if (operator === 'GETDATE') {
                group[key + index].valueChanges.subscribe((value: any) => {
                    const b = whereStore.filter((fil: any) => fil[0] && fil[0] === 'and' && fil[1][0] === key);
                    if (value && value[0]) {
                        if (!value[1]) {
                            return;
                            value[1] = value[0];
                        }
                        let date0 = null;
                        let date1 = null;
                        if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                            // kiểu date của arcgis hơi khác
                            date0 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[0], 'first')}'`;
                            date1 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[1], 'last')}'`;
                        } else {
                            date0 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[0], 'first');
                            date1 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[1], 'last');
                        }

                        if (b.length > 0) {
                            b[0][1][2] = date0;
                            b[0][2][2] = date1;
                        } else {
                            whereStore.push(['and', [key, '>=', date0], [key, '<=', date1]]);
                        }
                    } else {
                        whereStore = whereStore.filter((fil: any) => b[0] && fil !== b[0]);
                        this.whereFilter = whereStore; // Cần gán lại vì hàm filter phía trên trả ra 1 địa chỉ ô nhớ mới
                    }

                    this.pageIndex = 1;
                    // Cần phải có changePage để giao diện UI hiển thị đúng page
                    this.paginator.changePage(this.pageIndex - 1);
                    // Ở trong Layout sẽ không query data
                    if (!this.isLayout) {
                        this.queryData({
                            where: this._where
                        });
                    }
                });

                continue;
            }

            let newKey = key;
            if (operator && operator !== '') {
                newKey = `${operator}(${key})`;
            }

            group[key + index].valueChanges.subscribe((res: any) => {
                if (res) {
                    const oldKey = whereStore.filter((fil: any) => fil[0] && fil[0] === newKey);
                    let value = res; // Không phải res.CODE như ngày xưa vì control select giờ chỉ emit mỗi giá trị
                    if (value === '__NULL_VALUE') {
                        value = null;
                    }
                    if (oldKey.length > 0) {
                        oldKey[0][2] = value;
                    } else {
                        const where = [newKey, '=', value];
                        whereStore.push(where);
                    }
                } else {
                    whereStore = whereStore.filter((fil: any) => fil[0] !== newKey);
                    this.whereFilter = whereStore; // Cần gán lại vì hàm filter phía trên trả ra 1 địa chỉ ô nhớ mới
                }

                // // Cần phải có changePage để giao diện UI hiển thị đúng page => khi change page sẽ gọi query data

                if (this.mode === 'table') {
                    if (this.pageIndex !== 1) {
                        this.pageIndex = 1;
                        this.paginator.changePage(this.pageIndex - 1);
                    } else {
                        this.queryData({
                            where: this._where
                        });
                    }
                } else {
                    // Ở trong Layout sẽ không query data
                    if (!this.isLayout) {
                        this.queryData({
                            where: this._where
                        });
                    }
                }
            });


            const field = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === key)[0];
            if (field) {
                const a = this.appService.getLookup();
                const fieldServiceType = field.foreigntableservicetype && field.foreigntableservicetype !== '' ?
                    field.foreigntableservicetype : this.dataSource.INFORMATION.SERVICE_TYPE;
                item[4] = field.isfromdomain === 'Y' ? a[field.domainid] : []; // lookup cho control select
                item[5] = null;
                if (item[4] && item[4].length === 0) {
                    if (field.fieldtype === 'select') { // Nếu field là control select
                        const fieldFilter = {
                            FOREIGNTABLE: field.foreigntable,
                            FOREIGNTABLE_ID: field.foreigntableid,
                            COLUMNKEY: field.columnkey,
                            COLUMNDISPLAY: field.columndisplay,
                            COLUMNCODE: field.columncode,
                            TREECOLUMN: field.treecolumn,
                            WHEREFIELDNAME: field.wherefieldname, // sử dụng cho control select
                            BINDFIELDNAME: field.bindfieldname,
                            WHERECLAUSE: field.whereclause,
                            FOREIGNWINDOW_ID: field.foreignwindowid,
                            FIELD_ISDOMAIN: field.isfromdomain === 'Y',
                            FIELD_PARENT_ID: field.parentfieldid,
                            // Dùng cho filter-input (phân biệt khi có 2 control khác fieldname, nhưng đều trỏ vào chung 1 bảng)
                            FIELD_NAME: field.fieldname ? field.fieldname.trim() : field.fieldname,
                            SERVICE_TYPE: fieldServiceType // Control sử dụng kiểu service type nào
                        }

                        item[5] = fieldFilter;
                        if (val) {
                            group[key + index].setValue(val);
                        }
                    } else {
                        // Tạm thời bỏ query distinct ở filter (distinct sẽ có ở icon hình cái phễu bên cạnh tên field ở row header)

                        let url = this.dataSource.INFORMATION.URL_VIEW;
                        let select: any = [key];
                        if (operator && operator !== '') {
                            select = [`${operator}(${key}) as ${key}`];
                        }
                        let where: any = [];
                        let groupby: any = null;
                        if (fieldServiceType === 'SQL') {
                            select = null;
                            groupby = [`${key}`];
                        }
                        const res: any = await this.reqService.service.search({
                            url,
                            distinct: true,
                            where,
                            select,
                            groupBy: groupby,
                            returnGeometry: false
                        }).pipe(take(1)).toPromise();

                        let features: any[] = res.features;
                        if (fieldServiceType === 'SQL') {
                            const arr: any[] = [];
                            if (operator === 'YEAR') {
                                features.forEach(item => {
                                    if (item[key]) {
                                        const date = new Date(item[key]);
                                        const obj: any = {};
                                        obj[key] = date.getFullYear();
                                        if (!arr.map(x => x[key]).includes(obj[key])) {
                                            arr.push(obj);
                                        }
                                    }
                                });
                                features = arr;
                            }
                        }
                        const lookup: any[] = [];
                        features.forEach(item => {
                            if (item[key] === null) {
                                lookup.push({
                                    CODE: '__NULL_VALUE',
                                    DESCR: 'empty'
                                });
                            } else {
                                lookup.push({
                                    CODE: item[key],
                                    DESCR: item[key]
                                });
                            }

                        });
                        item[4] = lookup;
                        this.cd.detectChanges();
                        if (val) {
                            const a = lookup.filter(fil => fil.CODE == val);
                            if (a && a.length > 0) {
                                group[key + index].setValue(a[0]);
                            }
                        }
                    }
                } else if (item[4]) {
                    const vall = item[4].filter((fil: any) => val && fil.CODE.toString() === val.toString());
                    const __val = vall[0] ? vall[0].CODE : null;
                    group[key + index].setValue(__val);
                }
            }
        }
        this.filterFields = filterFields;
        this.filterFormGroup = new FormGroup(group);
        this.showFilter = true;

        // Ở trong Layout sẽ không query data
        if (!this.isLayout) {
            this.queryData({
                where: this._where
            });
        }
    }

    /** Hàm filter nhanh theo giá trị từng row (query distinct) */
    onFilterDistinct(col: any, overlay: any, event: any) {
        overlay.toggle(event);
        this.getDistinctLookup(col);
    }

    private createKvhcWhere(array: any[], where: any[]) {
        array.forEach(item => {
            if (item.listuserkvhc && item.listuserkvhc.length > 0) {
                this.createKvhcWhere(item.listuserkvhc, where);
            } else {
                where.push([this.dataSource.INFORMATION.KVHC_COLUMN, '=', item.codeKvhc]);
            }
        });
    }

    private createMenubar() {
        this.menubarItems = null;
        const menuList = this.appService.deepCloneObject(this.menuList);
        const list = this.getMenu(menuList);

        console.log(this.menubarItems)
        list.forEach((item: any) => {
            if (item.data && item.data.menudesc && item.data.menudesc !== '') {
                try {
                    const descr = JSON.parse(item.data.menudesc);
                    const text = descr[this.translate.currentLang];
                    item.label = text;
                } catch (error) {

                }
            }
        })
        this.menubarItems = list.length > 0 ? list : null;
    }

    public getMenu(menu: Array<any>) {
        const array: any[] = [];
        if (menu) {
            menu.forEach(item => {
                if (item.sub_function && item.sub_function.length > 0) {
                    const items = this.getMenu(item.sub_function);
                    array.push({
                        data: item,
                        label: item.name,
                        icon: item.icon ? 'pi ' + item.icon : 'pi pi-star-o',
                        items: items.length > 0 ? items : null,
                        command: () => { this.onClickMenuTool(item); }
                    });
                } else {
                    array.push({
                        data: item,
                        label: item.name,
                        icon: item.icon ? 'pi ' + item.icon : 'pi pi-star-o',
                        command: () => { this.onClickMenuTool(item); }
                    });
                }
            });
        }
        return array;
    }
    baseUrl: any = null
    public async onClickMenuTool(menu: any) {
        const data = {
            parentData: this.dataSource.INFORMATION.PARENT_DATA,
            lookup: this.coreWindowService.lookupTab,
            currentData: this.currentData,
            windowId: this.dataSource.INFORMATION.WINDOW_ID,
            dataSource: this.dataSource,
            data: this.coreWindowService.data,
            menu,
            currentComponent: this
        };

        if (menu.windowcustomize && menu.windowcustomize !== '') {
            this.eventEmit.emit({
                type: 'menu-tool-click-window-customize',
                value: { menu, data },
                isLock: false,
                tab: null
            });
            return;
        }
        if (menu.windowtype === "PLUGIN") {
            if (menu.function_component && menu.function_component !== '') {
                this.reqService.switchType(this.appService.dataAccessType);
                const urlRequest = this.appService.urlWS + '/Attachs';
                const appId = this.appService.c$['appId'];
                const where = [
                    ['TableId', '=', 57],
                    ['RecordId', '=', parseInt(menu.system_id)],
                    ['ClientId', '=', this.appService.ClientId],
                    ['ApplicationId', '=', appId],
                    ['AttachName', '=', menu.function_component]
                ];
                const res = await this.reqService.service.search({
                    url: urlRequest,
                    where
                }).pipe(take(1)).toPromise();
                if (res.success) {
                    if (res.features.length > 0) {
                        const item = res.features[0];
                        let urlPlugin = (this.appService.urlProxy + this.appService.urlOdataAttachment + '/' + item.UrlFile).replace(/\\/g, '/');
                        this.baseUrl = this.appService.urlOdataAttachment + '/' + item.UrlFile
                        // const filejs = await this.pluginService.loadFileScript(urlPlugin)
                        // if (typeof (filejs) === 'function') {
                        //     const obj = filejs.call(this, this, { data: this.currentData, parentData: this.dataSource.INFORMATION.PARENT_DATA })
                        // } else {
                        //     const obj = filejs.run(this, { data: this.currentData, parentData: this.dataSource.INFORMATION.PARENT_DATA })
                        // }
                        this.pluginService.loadFileScript(urlPlugin).then((p: any) => {
                            window['c$'].plugin = p
                            p.run(this, { data: this.currentData, parentData: this.dataSource.INFORMATION.PARENT_DATA })
                        })
                    }
                }
            } else {
                this.appService.createMessage('success', "Selector hasn't been selected")
            }

            return
        }
        if (menu.windowtype === 'STORE') {
            if (this.currentData && this.currentData.length > 0) {
                this.appService.confirm('Excute this store procedure?').subscribe((res: any) => {
                    if (res) {
                        let str = ''
                        let str_exec = ''
                        const schema = JSON.parse(this.appService.currentUser).schema;
                        if (menu.exttype) {
                            const arrParams = JSON.parse(menu.exttype)
                            const data = this.currentData[0]
                            arrParams.forEach((item: any) => {
                                const field = item.substring(1, item.length)
                                const val = typeof (data[field]) === 'number' ? data[field] : "'" + data[field] + "'"
                                str += item + ' = ' + val + ','
                            })
                            str = str.substring(0, str.length - 1)
                        }
                        str_exec = str !== '' ? 'EXEC ' + schema + '.' + menu.function_component + ' ' + str + ';' : 'EXEC ' + schema + '.' + menu.function_component + ';'
                        this.loading = true
                        this.windowService.queryDichVu([['ClientId', '=', JSON.parse(this.appService.currentUser).clientid], ['ServiceType', '=', 'CloudData'], ['ServiceName', '=', 'CloudService']], 'SysServices').subscribe((res: any) => {
                            if (res.success && res.features.length > 0) {
                                let data: any = res.features[0].UrlEdit;
                                this.execProcedure(schema, { command: str_exec }, data).subscribe((res: any) => {
                                    if (res.success) {
                                        this.loading = false
                                        this.appService.createMessage('success', 'Execute store procedure success')
                                    } else {
                                        this.loading = false
                                        this.appService.createMessage('error', res.message)
                                    }
                                }, err => {
                                    this.loading = false
                                    this.appService.createMessage('error', err.error.text)
                                })
                            }

                        })
                    }
                })
            } else {
                this.appService.createMessage('warning', 'No data selected')
            }

            return
        }
        if (menu['SUB_FUNCTION'] && menu['SUB_FUNCTION'].length === 0) {
            this.reqServiceSQL.service.search({
                url: this.appService.urlWS + '/syswindows',
                where: ['windowid', '=', Number(menu.system_id)],
                logic: 'and',
            }).subscribe((res) => {
                if (res.success) {
                    const _config = res.features[0];
                    this.eventEmit.emit({
                        type: 'menu-tool-click',
                        value: { _config, menu, res, data },
                        isLock: false,
                        tab: null
                    });
                }
            }, err => {
                this.dialogPrime.closeDialog();
                this.appService.alert('Something went wrong!!!', 'error');
            });
        }
    }

    private initTable() {
        if (!this.isFirstTimeInit) {
            this.queryData({
                where: this._where
            });
            return;
        }
        const fieldList = this.dataSource.FIELD_LIST;
        this._urlEdit = this.dataSource.INFORMATION.URL_EDIT;
        this.primaryKey = this.dataSource.INFORMATION.KHOA_CHINH;

        if (this.dataSource.INFORMATION.TAB_MAX_LEVEL === 0) {
            // this.pageSize = 20;
            this.pageSize = 10;
        }

        this.getPermission();

        this.changeTypeRequest();
        this.pageIndex = 1;

        if (fieldList.length > 0) {
            this.tblColumnDef = [];
            fieldList.forEach((col: any) => {
                if (col.isdisplaygrid === 'Y') {
                    const colDef = {
                        rowRequired: col.isrequire === 'Y' ? true : false,
                        rowHeader: col.alias && col.alias !== '' ? col.alias : col.fieldname, // dùng tạm fieldname, sau phải đổi là alias
                        rowFieldName: col.fieldname,
                        rowDomainId: col.isfromdomain === 'Y' ? col.domainid : null,
                        rowType: col.fieldtype,
                        rowFormat: col.vformat,
                        rowDisable: col.isreadonly === 'Y',
                        rowIsDomain: col.isfromdomain === 'Y',
                        rowIsFrozen: col.isfrozen === 'Y',
                        rowWidth: col.displaylength,
                        rowFieldFilter: {
                            FOREIGNTABLE: col.foreigntable,
                            FOREIGNTABLE_ID: col.foreigntableid,
                            COLUMNKEY: col.columnkey,
                            COLUMNDISPLAY: col.columndisplay,
                            COLUMNCODE: col.columncode,
                            WHEREFIELDNAME: col.wherefieldname, // sử dụng cho control select
                            WHERECLAUSE: col.whereclause,
                            FOREIGNWINDOW_ID: col.foreignwindowid,
                            PARENT_FIELDID: col.parentfieldid, // Đang dở dang
                            SERVICE_TYPE: col.foreigntableservicetype && col.foreigntableservicetype !== '' ?
                                col.foreigntableservicetype : this.dataSource.INFORMATION.SERVICE_TYPE,
                            TREECOLUMN: col.treecolumn,
                            FIELD_ISDOMAIN: col.isfromdomain === 'Y'
                        },
                        rowDirty: false,
                        rowFilterDirty: false,
                        rowFieldId: col.fieldid,
                        sortOrderNo: col.isfrozen === 'Y' ? -1 : col.orderno
                    };

                    try {
                        const descr = JSON.parse(col.alias);
                        const text = descr[this.translate.currentLang];
                        colDef.rowHeader = text;
                    } catch (error) {

                    }

                    this.tblColumnDef.push(colDef);
                }
            });
            // Trường hợp parent ở trong cùng tab
            this.tblColumnDef.forEach(item => {
                const a = this.tblColumnDef.filter(fil => fil.rowFieldId && fil.rowFieldId === item.rowFieldFilter.PARENT_FIELDID);
                if (a && a.length > 0) {
                    item.rowFieldFilter['PARENT_COLDEF'] = a[0];
                }
            });

            this.tblColumnDef = this.tblColumnDef.sort(
                (a, b) => a.sortOrderNo - b.sortOrderNo
            );


        }

        // Kiểm tra khu vực hành chính, chỉ áp dụng với tab trên cùng nhất tức tab cha ở mức cao nhất (theo ý kiến của anh Khoa - 06/02/2022)
        // Update mới => áp dụng cho toàn bộ tab
        const infor = this.dataSource.INFORMATION;
        if (infor.KVHC_COLUMN && infor.KVHC_COLUMN !== 'N') {
            const where = ['or']
            this.createKvhcWhere(this.appService.appConfig.DSKVHC, where);
            this.whereKvhc = where;
        }

        this.lookupCondition = this.appService.getLookup();
    }

    private getDistinctLookup(col: any) {
        this.currentFieldDistinct = col;
        this.selectedValuesDistinct = [];
        this.lookupFieldDistinct[col.rowFieldName] = [];
        let url = this.dataSource.INFORMATION.URL_VIEW;
        let select = [col.rowFieldName];
        let where: any[] = [];
        if (this.dataSource.INFORMATION.DEFAULT_WHERE.length > 0) {
            where = ['and', this.dataSource.INFORMATION.DEFAULT_WHERE];
        }
        if (this.whereFilterDistinct) {
            where.push(this.whereFilterDistinct);
        }
        this.reqService.service.search({
            url,
            distinct: true,
            where,
            select,
            returnGeometry: false,
            orderBy: [`${col.rowFieldName} asc`]
            // pageSize: 10, // Không thể thêm tham số này vì nó sẽ làm service arcgis bị lỗi
            // startRecord: 0
        }).subscribe(res => {
            const lookup: any[] = [];
            // Thêm index để mảng luôn đảm bảo dưới 100 phần tử => khi bật overlay panel sẽ nhanh hơn
            const array: any[] = [];
            // xóa các phần tử trùng lặp
            res.features.forEach(item => {
                if (!array.map(x => x[col.rowFieldName]).includes(item[col.rowFieldName])) {
                    array.push(item);
                }
            })

            array.forEach((item, index) => {
                if (index > 99) {
                    return;
                }

                const obj = {
                    CODE: item[col.rowFieldName],
                    DESCR: item[col.rowFieldName]
                }

                if (obj.CODE === null) {
                    obj.DESCR = '(blank)';
                }

                if (col.rowType === 'select') {
                    let elem = null;
                    if (col.rowIsDomain) {
                        elem = this.lookupCondition[col.rowDomainId].filter((fil: any) => fil.CODE === item[col.rowFieldName]);
                    } else if (this.lookupCondition[col.rowFieldName + '_C']) {
                        elem = this.lookupCondition[col.rowFieldName + '_C'].filter((fil: any) => fil.CODE === item[col.rowFieldName]);
                    }

                    if (elem && elem.length > 0) {
                        obj.DESCR = elem[0].DESCR;
                    }
                }
                // else if (col.rowType === 'date' || col.rowType === 'datetime') { // Tạm thời đang tắt lọc date
                // let dateString = '';
                // if (item[col.rowFieldName] !== undefined && item[col.rowFieldName] !== null) {
                //     const d = new Date(item[col.rowFieldName])
                //     const newDate = this.coreWindowService.convertDateToUTC(d)
                //     item[col.rowFieldName] = newDate
                //     if (!isNaN(newDate.getTime())) {
                //         dateString += newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate();
                //         dateString += '-';
                //         dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
                //         dateString += '-' + newDate.getFullYear();

                //         if (col.rowType === 'datetime') {
                //             dateString += ' ';
                //             dateString += newDate.getHours() > 9 ? newDate.getHours() : '0' + newDate.getHours();
                //             dateString += ':';
                //             dateString += newDate.getMinutes() > 9 ? newDate.getMinutes() : '0' + newDate.getMinutes();
                //             dateString += ':';
                //             dateString += newDate.getSeconds() > 9 ? newDate.getSeconds() : '0' + newDate.getSeconds();
                //         }
                //     }

                //     obj.DESCR = dateString;
                // }
                // }
                lookup.push(obj);
            });
            this.lookupFieldDistinct[col.rowFieldName] = lookup;
        });
    }

    private resetFilterDistinct() {
        this.whereFilterDistinct = null;
        this.listValuesDistinct = {};
        this.tblColumnDef.forEach(item => item.rowFilterDirty = false);
        this.currentFieldDistinct = null;
        this.selectedValuesDistinct = [];
    }

    onFilterDistinctClearAll(overlayFilter: any) {
        overlayFilter.hide();
        this.resetFilterDistinct();
        this.queryData({
            where: this._where
        });
    }

    onFilterDistinctClear() {
        const rowFieldName = this.currentFieldDistinct.rowFieldName;
        this.selectedValuesDistinct = [];
        this.listValuesDistinct[rowFieldName] = [];
        this.onFilterDistinctOk();
    }

    onFilterDistinctOk(queryData = true) {
        const rowFieldName = this.currentFieldDistinct.rowFieldName;
        const saveValues = JSON.parse(JSON.stringify(this.selectedValuesDistinct));
        this.listValuesDistinct[rowFieldName] = saveValues;
        let superWhere = ['and'];

        this.tblColumnDef.forEach(item => {
            const whereStructure = this.listValuesDistinct[item.rowFieldName];
            if (whereStructure && whereStructure.length > 0) {
                let where: any = null;
                const values = whereStructure.map((item: any) => item.CODE);
                switch (item.rowType) {
                    case 'number':
                        where = [item.rowFieldName, 'in', values];
                        break;
                    case 'date':
                    case 'datetime':
                        where = ['or'];
                        if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                            values.forEach((x: any) => {
                                where.push([item.rowFieldName, '=', new Date(x)]);
                            });
                        } else {
                            values.forEach((x: any) => {
                                let d = new Date(x);
                                if (d instanceof Date) {
                                    const year = d.getFullYear();
                                    const month = d.getMonth();
                                    const date = d.getDate();
                                    const hour = d.getHours();
                                    const minute = d.getMinutes();
                                    const second = d.getSeconds();

                                    const dateUTC = Date.UTC(year, month, date, hour, minute, second);
                                    d = new Date(dateUTC);
                                }

                                where.push([item.rowFieldName, '=', d]);
                            })

                        }
                        break;
                    default:
                        where = ['or'];
                        values.forEach((x: any) => {
                            where.push([item.rowFieldName, '=', x]);
                        });
                        break;
                }
                superWhere.push(where);
                item.rowFilterDirty = true;
            } else {
                item.rowFilterDirty = false;
            }
        });

        if (superWhere.length > 1) {
            this.whereFilterDistinct = superWhere;
        } else {
            this.whereFilterDistinct = null;
        }

        if (queryData) {
            this.onReloadTable();
        }
    }
    onSelectValueChange(evt: any, data: any, col: any) {
        console.log(evt)
    }
    onKey(evt: any, data?: any, field?: any, index?: any) {
        const obj = {
            data: data,
            field: field,
            index: index,
            originalEvent: evt
        }
        this.onEditComplete(obj)
        this.isEditComplete = false

    }

    private queryData(obj: QueryParams, isFirstRecord?: boolean) {
        this.preventChangePage = false;
        const isPageChange = obj.isPageChange ?? false;
        const logic = obj.logic ?? 'and';
        const geometry = obj.geometry ?? null;
        this.reloadParams = obj; // Lưu trữ lại params để có thể reload;

        this.loading = true;
        const startRecord = (this.pageIndex - 1) * this.pageSize;
        const pageSize = this.pageSize;

        const url = this.dataSource.INFORMATION.URL_VIEW;
        if (!url || url === '') {
            this.appService.alert('Cấu hình đường dẫn dịch vụ bị lỗi, vui lòng kiểm tra lại cấu hình', 'error');
            return;
        }
        let where: any[] = [];
        const defaultWhere: any[] = this.dataSource.INFORMATION.DEFAULT_WHERE;
        if (defaultWhere && defaultWhere.length > 0) { // Khi cấu hình có sẵn where_clause, thì mọi query đều sẽ có điều kiện where clause này
            // const newArrayWhere: any[] = ['and'];
            // defaultWhere.forEach(x => {
            //     newArrayWhere[0] = x[0] === 'or' ? 'or' : 'and'; // Lấy kiểu logic
            //     newArrayWhere.push([x[1], x[2], x[3]]); // push kiểu logic
            // })
            // where.push(newArrayWhere);
            where.push(defaultWhere);
        }
        if (this.whereFilter && this.whereFilter.length > 1) {
            where.push(this.whereFilter);
        }
        if (this.whereFilterDistinct) {
            where.push(this.whereFilterDistinct);
        }
        if (this.whereKvhc && this.whereKvhc.length > 1) { // tránh điều kiện 'and' / 'or'
            where.push(this.whereKvhc);
        }

        if (obj.where) {
            if (where.length === 0) {
                where = obj.where;
            } else {
                if (obj.where.length > 0) {
                    where.push(obj.where);
                }
            }
        }

        let params: any = { url, where, startRecord, pageSize, logic };

        if (geometry) {
            params['geometry'] = geometry;
        }

        // if (this.dataSource.INFORMATION.ORDER_BY !== null) {
        //     params['orderBy'] = [this.dataSource.INFORMATION.ORDER_BY];
        // }
        if (this.dataSource.INFORMATION.ORDER_BY !== null) {
            let arr: any;
            try {
                arr = JSON.parse(this.dataSource.INFORMATION.ORDER_BY)
            } catch (error) {
                arr = [this.dataSource.INFORMATION.ORDER_BY]
            }
            params['orderBy'] = arr;
        }

        if (this.handleRequestSearch) {
            this.handleRequestSearch.unsubscribe();
        }

        if (obj.where) { // Điều kiện where có dữ liệu
            if (Array.isArray(where[0]) && where[0].length === 0) { // convert [[]] thành [] để query all
                params.where = [];
            }
            this.tableLoading = true;
            this.changeTypeRequest()
            this.handleRequestSearch = this.reqService.service.search(params).subscribe(
                (res: SearchResponse) => {
                    this.editCache = {};
                    this.totalRecord = res.total;
                    this.tableLoading = false;

                    if (!res.success) {
                        this.totalRecord = 0;
                        this.resultList = [];
                        this.eventEmit.emit({
                            type: 'rowClick',
                            value: null,
                            isLock: false
                        });
                        this.arrSaveRecord = [];
                        this.arr_dataChange = [];
                        this.isUpdate = false;
                        this.onUpdate(false)
                        this.bindDataToForm = null;
                        this.mode = 'table';
                        this.currentData = []; // clear dữ liệu cũ
                        this.pageChangeFinish.emit();
                        this.loading = false;

                        const message = res.message ? `Tab ${this.dataSource.INFORMATION.TABLE_NAME}: ${res.message}` : this.appService.getMessage('0010');
                        this.appService.notification(message, 'error');
                        return;
                    }

                    this.resultList = res.features;
                    this.isUpdate = false;
                    // this.onUpdate(false)
                    if (this.resultList.length > 0) {
                        this.coreWindowService.convertDateAndComboBox(
                            this.tblColumnDef,
                            this.resultList,
                            this.lookupCondition,
                            this.dataSource
                        );

                        // this.updateEditCache();

                        if (this.initShowFirstTime) {
                            this.mode = this.dataSource.INFORMATION.INIT_SHOW && this.resultList.length === 1 ? 'form' : 'table';
                            this.initShowFirstTime = false;
                        }
                        if (!isPageChange) {
                            // Mặc định chọn bản ghi đầu tiên
                            this.currentData = [this.resultList[0]]
                            this.onRowClick({ data: this.resultList[0] }, geometry);
                            setTimeout(() => {
                                this.bindDataToForm = this.resultList[0];
                            }, 200)

                        } else {
                            if (isFirstRecord) {
                                this.currentData = [this.resultList[0]]
                                this.onRowClick({ data: this.resultList[0] }, geometry);
                                // this.bindDataToForm = this.resultList[0];
                                setTimeout(() => {
                                    this.bindDataToForm = this.resultList[0];
                                }, 200)
                            } else {
                                const _pk = this.dataSource.INFORMATION.KHOA_CHINH;
                                const a = this.resultList.filter(fil => this.bindDataToForm && fil[_pk] === this.bindDataToForm[_pk]);
                                if (a && a.length > 0) {
                                    this.currentData = [a[0]];
                                    // this.bindDataToForm = a[0];
                                    setTimeout(() => {
                                        this.bindDataToForm = a[0];
                                    }, 200)
                                    this.onRowClick({ data: a[0] }, geometry);
                                }
                            }

                        }

                        if (geometry) {
                            // Vẽ geometry nếu có geometry
                            this.appService.drawGeometryFromTable.emit(res.features);
                        }
                    } else {
                        this.eventEmit.emit({
                            type: 'rowClick',
                            value: null,
                            isLock: false
                        });
                        this.bindDataToForm = null;
                        this.mode = 'table';
                        this.currentData = []; // clear dữ liệu cũ
                        this.setPermission();
                    }
                    this.arrSaveRecord = [];
                    this.arr_dataChange = [];

                    this.cache_DataTable = JSON.stringify(this.resultList)
                    this.pageChangeFinish.emit();
                    this.loading = false;
                    // khoanb update tính toán
                    this.dataSource.FIELD_LIST.forEach((field: any) => {
                        if (field.children) {
                            const _v = [];
                            if (field.children[0].calculationInfos) {
                                for (let v = 0; v < field.children[0].calculationInfos.length; v++) {
                                    const info = field.children[0].calculationInfos[v];

                                    if (info.func) {// childs
                                        _v.push(this.calculateChilds(info));
                                    }
                                    else if (info.tab)// parent
                                    {
                                        // 	_v[v]=parentRecord[info.field];
                                    }

                                }
                                // tslint:disable-next-line:no-eval
                                const value = eval(field.children[0].calculation);
                                this.coreWindowService.updateCalc(value);
                            }
                        }
                    });
                    // Luôn hiển thị dạng form khi đang trong Layout
                    if (this.isLayout) {
                        this.mode = 'form';
                    }
                },
                (err) => {
                    this.tableLoading = false;
                    this.appService.notification(
                        this.appService.getMessage('0010')
                    );
                    this.cleanTable();
                    // this.reloadParams = null;
                    this.loading = false;
                }
            );
        } else {
            // Xóa trắng dữ liệu
            this.cleanTable();
            this.reloadParams = null;
        }
    }

    public calculateChilds(info: any) {
        const records = this.appService.deepCloneObject(this.resultList);
        let result: any = info.func === 'min' ? Number.MAX_VALUE : info.func === 'max' ? Number.MIN_VALUE : 0;
        for (let i = 0; i < records.length; i++) {
            let value = records[i][info.field];
            if (i === 0 && typeof (value) === 'string') {
                result = '';
            }
            switch (info.func) {
                case "avg":
                case "sum":
                    result += value;
                    break;
                case "count":
                    result++;
                    break;
                case "min":
                    if (value < result) { result = value; }
                    break;
                case "max":
                    if (value > result) { result = value; }
                    break;
            }
        }
        if (info.func === "avg") { result /= records.length; }
        return result;
    }

    private cleanTable() {
        this.loading = false;
        this.tableLoading = false;
        this.currentData = [];
        this.totalRecord = 0;
        this.resultList = [];
        this.arrSaveRecord = [];
        this.arr_dataChange = [];
        this.selectedValuesDistinct = [];
        this.listValuesDistinct = {};
        this.isUpdate = false;
        this.onUpdate(false)
        this.eventEmit.emit({
            type: 'rowClick',
            value: null,
            isLock: false
        });

        // Object.keys(this.disableTool).forEach(key => {
        //     this.disableTool[key] = true;
        // });
    }


    checkParent() { }

    private getPermission() {
        const info = this.dataSource.INFORMATION;
        // this.canLinkTable = info.PARENT_ID !== null && info.BANG_LK !== null && !info.IS_TAB_TRUNG_GIAN ? true : false;
        // 13h47 ngày 14 tháng 8 năm 2023: thay điều kiện hiển thị nút link theo yêu cầu của anh Vinh
        this.canLinkTable = info.PARENT_ID !== null;
        const list = this.appService.appConfig?.FunctionPermistion?.filter(
            (fil: any) => fil.tableid === this.dataSource.INFORMATION.TABLE_ID
        );
        this.permissionList = {};
        if (list && list.length > 0) {
            list.forEach((item: any) => {
                this.permissionList[item.functioncode] = true;
                if (item.displaylogic !== null && item.displaylogic !== undefined && item.displaylogic !== '') {
                    this.permissionList[item.functioncode + '_DP_LOGIC'] = item.displaylogic;

                    // Phải kiểm tra logic ngay
                    const clauses = JSON.parse(item.displaylogic);
                    let count = 0;
                    clauses.forEach((clause: any) => {
                        let keyClause: string = clause[0];
                        const operator = clause[1];

                        let currentData = null;
                        let field = [];
                        if (keyClause.includes('.')) {
                            const tabName = keyClause.split('.')[0];
                            keyClause = keyClause.split('.')[1];
                            for (let i in this.coreWindowService.lookupTab) {
                                const tab = this.coreWindowService.lookupTab[i];
                                if (tab.INFORMATION.TABLE_NAME === tabName) {
                                    currentData = tab.INFORMATION.CURRENT_DATA;
                                    field = tab.FIELD_LIST.filter((fil: any) => fil.fieldname === keyClause);
                                }
                            }
                        } else {
                            currentData = this.currentData && this.currentData.length > 0 ? this.currentData[this.currentData.length - 1] : null;
                            field = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === keyClause);
                        }

                        if (field.length > 0 && currentData) {
                            let value = clause[2];
                            value = operator !== 'in' && field[0].columntype === 'number' ? Number(clause[2]) : clause[2];
                            switch (operator) {
                                case '<>':
                                    count = currentData[keyClause] !== value ? count + 1 : count;
                                    break;
                                case '=':
                                    count = currentData[keyClause] === value ? count + 1 : count;
                                    break;
                                case '>=':
                                    count = currentData[keyClause] >= value ? count + 1 : count;
                                    break;
                                case '<=':
                                    count = currentData[keyClause] <= value ? count + 1 : count;
                                    break;
                                case '>':
                                    count = currentData[keyClause] > value ? count + 1 : count;
                                    break;
                                case '<':
                                    count = currentData[keyClause] < value ? count + 1 : count;
                                    break;
                                case 'in':
                                    let array: any[] = value.split(',');
                                    if (field[0].columntype === 'number') {
                                        array = array.map((x: any) => Number(x));
                                    }
                                    count = array.includes(currentData[keyClause]) ? count + 1 : count;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });

                    this.permissionList[item.functioncode] = count === clauses.length;
                }
            });
        } else {
            const val = this._urlEdit && this._urlEdit !== '' ? false : true;
            this.isShowImportBtn = !val
            // set full quyền cho table
            this.permissionList = {
                ADD: val,
                EDIT: val,
                DELETE: val,
                // SEARCH: val,
                LINK: val,
                LOCK: val,
                UNLOCK: val,
                ARCHIVE: val,
                SAVE: val,
                ATTACHMENT: val,
                FILTER: val
            };
        }
        this.checkParent()
    }

    private setPermission() {
        if (this.currentData && this.currentData.length > 0) {
            // Đoạn này dành cho các tool thêm, sửa, xóa
            Object.keys(this.permissionList).forEach(key => {
                if (key.includes('_DP_LOGIC')) {
                    const old_key = key.substring(0, key.indexOf('_DP_LOGIC'));
                    const clauses = JSON.parse(this.permissionList[key]);
                    let count = 0;
                    clauses.forEach((clause: any) => {
                        let keyClause: string = clause[0];
                        const operator = clause[1];

                        let currentData = null;
                        let field = [];
                        if (keyClause.includes('.')) {
                            const tabName = keyClause.split('.')[0];
                            keyClause = keyClause.split('.')[1];
                            for (let i in this.coreWindowService.lookupTab) {
                                const tab = this.coreWindowService.lookupTab[i];
                                if (tab.INFORMATION.TABLE_NAME === tabName) {
                                    currentData = tab.INFORMATION.CURRENT_DATA;
                                    field = tab.FIELD_LIST.filter((fil: any) => fil.fieldname === keyClause);
                                }
                            }
                        } else {
                            currentData = this.currentData[this.currentData.length - 1];
                            field = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === keyClause);
                        }

                        if (field.length > 0 && currentData) {
                            let value = clause[2];
                            value = operator !== 'in' && field[0].columntype === 'number' ? Number(clause[2]) : clause[2];
                            switch (operator) {
                                case '<>':
                                    count = currentData[keyClause] !== value ? count + 1 : count;
                                    break;
                                case '=':
                                    count = currentData[keyClause] === value ? count + 1 : count;
                                    break;
                                case '>=':
                                    count = currentData[keyClause] >= value ? count + 1 : count;
                                    break;
                                case '<=':
                                    count = currentData[keyClause] <= value ? count + 1 : count;
                                    break;
                                case '>':
                                    count = currentData[keyClause] > value ? count + 1 : count;
                                    break;
                                case '<':
                                    count = currentData[keyClause] < value ? count + 1 : count;
                                    break;
                                case 'in':
                                    let array: any[] = value.split(',');
                                    if (field[0].columntype === 'number') {
                                        array = array.map((x: any) => Number(x));
                                    }
                                    count = array.includes(currentData[keyClause]) ? count + 1 : count;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });

                    this.permissionList[old_key] = count === clauses.length;
                }
            });

            // Đoạn này dành cho các menu customize
            this.menuList.forEach(item => {
                if (item.displaylogic !== null && item.displaylogic !== undefined && item.displaylogic !== '') {
                    const clauses: any[] = JSON.parse(item.displaylogic);
                    let count = 0;
                    clauses.forEach(clause => {
                        const operator = clause[1];
                        const field = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === clause[0]);
                        if (field.length > 0) {
                            const value = field[0].columntype === 'number' ? Number(clause[2]) : clause[2];
                            switch (operator) {
                                case '<>':
                                    count = this.currentData[clause[0]] !== value ? count + 1 : count;
                                    break;
                                case '=':
                                    count = this.currentData[clause[0]] === value ? count + 1 : count;
                                    break;
                                case '>=':
                                    count = this.currentData[clause[0]] >= value ? count + 1 : count;
                                    break;
                                case '<=':
                                    count = this.currentData[clause[0]] <= value ? count + 1 : count;
                                    break;
                                case '>':
                                    count = this.currentData[clause[0]] > value ? count + 1 : count;
                                    break;
                                case '<':
                                    count = this.currentData[clause[0]] < value ? count + 1 : count;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                    item.showMenu = count === clauses.length;
                }
            });
        } else {
            this.getPermission();
            this.menuList.forEach(item => item.showMenu = true);
        }
    }


    onClick(type: any) {
        this.tooltype = this.tooltype === type ? null : type;
        this.eventEmit.emit({
            type: 'toolClick',
            value: type,
            isLock: false
        });
        switch (type) {
            case 'zoom':
                this.appService.zoomEvent.emit({
                    row: this.currentData[this.currentData.length - 1],
                    tab: this.dataSource
                });
                break;
            case 'first':
                this.onFirstRow();
                break;
            case 'last':
                this.onLastRow();
                break;
            case 'previous':
                this.onPreviousRow();
                break;
            case 'next':
                this.onNextRow();
                break;
            case 'insert':
                this.onInsert();
                break;
            case 'update':
                this.onUpdate();
                break;
            case 'save':
                this.onSave(false);
                break;
            case 'delete':
                this.onDelete();
                break;
            case 'deleteArchive':
                this.onDelete(true);
                break;
            case 'search':
                this.onSearch();
                break;
            case 'link-table':
                this.onLinkTable();
                break;
            case 'lock-record':
                this.onChangeLockRecord('lock');
                break;
            case 'unlock-record':
                this.onChangeLockRecord('unlock');
                break;
            case 'import-data':
                this.onImportExcel();
                break;
            case 'export-data':
                this.onExportExcel();
                break;
            case 'refresh':
                this.onReloadTable();
                break;
            case 'saveArchive':
                this.onSave(false, true);
                break;
            case 'history':
                this.onShowLog();
                break;
            case 'attachment':
                this.onShowAttachment();
                break;
            case 'unlink':
                this.unlink();
                break;
            default:
                break;
        }

    }
    onSaveArchive() {
        this.onSave(false, true)
    }
    primaryKeyAttachment: any = null
    onShowAttachment() {
        this.primaryKeyAttachment = null
        if (this.dataSource.INFORMATION.KHOA_CHINH) {
            this.dialogLog.isComponent = false;
            this.dialogLog.title = 'Attachment File'
            this.dialogLog.widthPanel = '80vw'
            this.dialogLog.heightDialog = '80vh'
            this.dialogLog.templateRef = this.tmpAttachment
            this.dialogLog.onShow()
        } else {
            // this.appService.createMessage('warning', 'Column key is not configured')
            const params = [['TableId', '=', this.dataSource.INFORMATION.TABLE_ID], ['ClientId', '=', this.appService.ClientId], ['IsPriKey', '=', 'Y']]
            this.reqServiceSQL.service.search({
                url: this.appService.urlWS + '/SysColumns',
                where: params,
                logic: 'and'
            }).subscribe((res: any) => {
                if (res.success) {
                    // const obj = res.features.find((v: any) => v.IsPriKey === 'Y')
                    if (res.features.length > 0) {
                        this.primaryKeyAttachment = res.features[0].ColumnName
                        this.dialogLog.isComponent = false;
                        this.dialogLog.title = 'Attachment File'
                        this.dialogLog.widthPanel = '80vw'
                        this.dialogLog.heightDialog = '80vh'
                        this.dialogLog.templateRef = this.tmpAttachment
                        this.dialogLog.onShow()
                    } else {
                        this.appService.createMessage('warning', 'Column key is not configured')
                    }
                }
            })
        }

    }
    arrLog: any[] = []
    initDataLog() {
        const params = [['TableId', '=', this.dataSource.INFORMATION.TABLE_ID], ['ClientId', '=', this.appService.ClientId]]
        this.queryLog(params).subscribe((res: any) => {

            if (res.success) {
                res.features.forEach((item: any) => {
                    item.CREATE_DATE = this.buildDateStr(new Date(item.CreateDate))
                    item.color_action = item.ActionName === 'DELETE' ? '#FF0000' : item.ActionName === 'SAVE' ? '#00FF00' : null
                })
                this.arrLog = [...res.features]
            }
        })
    }
    async onShowLog() {
        await this.initDataLog()
        this.dialogLog.isComponent = false;
        this.dialogLog.title = 'Data change history'
        this.dialogLog.templateRef = this.tmpShowLog
        this.dialogLog.onShow()
    }
    buildDateStr(date: Date) {
        const d = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
        const m = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
        const y = date.getFullYear()
        const h = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
        const mi = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
        const s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
        return h + ':' + mi + ':' + s + ' ' + d + '/' + m + '/' + y
    }
    domainLock: any[] = []
    checkLockRecord(record: any) {
        let chk = false;
        if (!record) {
            return chk;
        }
        const data = record[this.dataSource.INFORMATION.COLUMN_LOCK];
        if (data) {
            const obj = this.dataSource.FIELD_LIST.find((v: any) => v.fieldname === this.dataSource.INFORMATION.COLUMN_LOCK)
            if (obj && obj.isfromdomain === 'Y') {
                this.domainLock = JSON.parse(this.appService.appConfig.sys_combo[obj.domainid])
                const ind = this.domainLock.findIndex((v: any) => v[0] === data)
                if (ind > -1) {
                    // chk = ind[3].toUpperCase() === 'LOCK' ? true :  false
                    if (this.domainLock[ind][3] && this.domainLock[ind][3] !== '') {
                        chk = this.domainLock[ind][3].toUpperCase() === 'LOCK' ? true : false
                    } else {
                        chk = ind === 0 ? true : false
                    }
                } else {
                    // chk = false
                    chk = typeof (data) === 'string' ? (data === 'Y' ? true : false) : (data === 1 ? true : false)
                }
            } else {
                chk = typeof (data) === 'string' ? (data === 'Y' ? true : false) : (data === 1 ? true : false)
            }
        } else {
            chk = false
        }
        return chk
    }
    getValueDomainLock() {
        const obj_data: any = {
            dataLock: [],
            dataUnLock: []
        }
        const obj = this.dataSource.FIELD_LIST.find((v: any) => v.fieldname === this.dataSource.INFORMATION.COLUMN_LOCK)
        if (obj && obj.isfromdomain === 'Y') {
            this.domainLock = JSON.parse(this.appService.appConfig.sys_combo[obj.domainid])
            this.domainLock.forEach((item: any, ind: any) => {
                if (item[3] && item[3].toUpperCase() === 'LOCK') {

                    obj_data.dataLock.push({
                        CODE: item[0],
                        DESCR: item[1],
                        data: item
                    })
                } else if (item[3] && item[3].toUpperCase() === 'UNLOCK') {
                    obj_data.dataUnLock.push({
                        CODE: item[0],
                        DESCR: item[1],
                        data: item
                    })
                } else {
                    if (ind === 0) {
                        obj_data.dataLock.push({
                            CODE: item[0],
                            DESCR: item[1],
                            data: item
                        })
                    } else {
                        obj_data.dataUnLock.push({
                            CODE: item[0],
                            DESCR: item[1],
                            data: item
                        })
                    }

                }
            })
        }
        return obj_data
    }
    arrDataLock: any[] = []
    modeColumnLock: any = 'lock'
    onChangeLockRecord(mode: any) {
        this.modeColumnLock = mode
        if (this.domainLock && this.domainLock.length > 0) {
            const url = this.dataSource.INFORMATION.URL_EDIT
            const primarykey = this.dataSource.INFORMATION.KHOA_CHINH
            const val = this.currentData && this.currentData.length > 0 ? this.currentData[this.currentData.length - 1] : null
            if (val) {
                this.arrDataLock = mode === 'lock' ? this.getValueDomainLock().dataUnLock : this.getValueDomainLock().dataLock
                this.dialogLockComponent.isComponent = false
                this.dialogLockComponent.widthPanel = '40vw'
                this.dialogLockComponent.title = mode === 'lock' ? 'Select unlock value' : 'Select lock value'
                this.dialogLockComponent.templateRef = this.tmpLock
                this.dialogLockComponent.onShow()
                this.dialogLockComponent.onClose.subscribe(() => {
                    this.valLock = null
                    this.arrDataLock = []
                })
            } else {
                this.appService.notification("Select a record", 'warn')
            }

        } else {
            // this.appService.notification("Don't have lock domain config!", 'error')
            const url = this.dataSource.INFORMATION.URL_EDIT
            const primarykey = this.dataSource.INFORMATION.KHOA_CHINH
            const val = this.currentData && this.currentData.length > 0 ? this.currentData[this.currentData.length - 1] : null
            if (val) {
                const data: any = {}
                data[primarykey] = val[primarykey]
                const arrField = this.dataSource.FIELD_LIST
                arrField.forEach((field: any) => {
                    if (field.fieldname !== primarykey) {
                        data[field.fieldname] = val[field.fieldname]
                    }

                })
                // data[this.dataSource.INFORMATION.COLUMN_LOCK] = mode === 'lock' ? this.domainLock[1][0] : this.domainLock[0][0]
                const obj_f = this.dataSource.FIELD_LIST.find((v: any) => v.fieldname === this.dataSource.INFORMATION.COLUMN_LOCK)
                let val_lock: any = null
                if (obj_f) {
                    val_lock = obj_f.columntype === 'number' ? mode === 'lock' ? 0 : 1 : mode === 'lock' ? 'N' : 'Y'
                }
                data[this.dataSource.INFORMATION.COLUMN_LOCK] = val_lock
                // if (mode === 'lock') {

                //     data[this.dataSource.INFORMATION.COLUMN_LOCK] = typeof (val[this.dataSource.INFORMATION.COLUMN_LOCK]) === 'number' ? 1 : 'Y'
                // } else {
                //     data[this.dataSource.INFORMATION.COLUMN_LOCK] = typeof (val[this.dataSource.INFORMATION.COLUMN_LOCK]) === 'number' ? 0 : 'N'
                // }
                this.reqService.service.update({
                    url: url,
                    primaryKey: primarykey,
                    data: data
                }).subscribe((res: any) => {
                    if (res.success) {
                        // this.onReloadTable()
                        this.appService.createMessage('success', this.modeColumnLock === 'lock' ? 'Unlock record success' : 'Lock record success')
                        this.currentData[this.currentData.length - 1][this.dataSource.INFORMATION.COLUMN_LOCK] = val_lock
                        const ind = this.resultList.findIndex((v: any) => v[primarykey] === this.currentData[this.currentData.length - 1][primarykey])
                        this.resultList[ind][this.dataSource.INFORMATION.COLUMN_LOCK] = val_lock
                        this.currentData = [...this.currentData]
                        this.resultList = [...this.resultList]
                        this.eventEmit.emit({
                            type: 'rowClick',
                            value: this.currentData[this.currentData.length - 1],
                            isLock: this.checkLockRecord(this.currentData[this.currentData.length - 1])
                        })
                    }
                }, err => {
                    this.appService.createMessage('success', this.modeColumnLock === 'lock' ? 'Unlock record error' : 'Lock record error')
                })
            } else {
                this.appService.notification("Select a record", 'warn')
            }
        }


    }
    valLock: any
    changeLock() {
        // const valLock: any = null
        if (this.valLock && this.valLock !== '') {
            const val = this.currentData && this.currentData.length > 0 ? this.currentData[this.currentData.length - 1] : null
            const url = this.dataSource.INFORMATION.URL_EDIT
            const primarykey = this.dataSource.INFORMATION.KHOA_CHINH
            const data: any = {}
            data[primarykey] = val[primarykey]
            const arrField = this.dataSource.FIELD_LIST
            arrField.forEach((field: any) => {
                if (field.fieldname !== primarykey) {
                    data[field.fieldname] = val[field.fieldname]
                }

            })
            data[this.dataSource.INFORMATION.COLUMN_LOCK] = this.valLock;
            this.reqService.service.update({
                url: url,
                primaryKey: primarykey,
                data: data
            }).subscribe((res: any) => {
                if (res.success) {
                    // this.onReloadTable()
                    this.appService.createMessage('success', this.modeColumnLock === 'lock' ? 'Unlock record success' : 'Lock record success')
                    this.currentData[this.currentData.length - 1][this.dataSource.INFORMATION.COLUMN_LOCK] = this.valLock
                    const ind = this.resultList.findIndex((v: any) => v[primarykey] === this.currentData[this.currentData.length - 1][primarykey])
                    this.resultList[ind][this.dataSource.INFORMATION.COLUMN_LOCK] = this.valLock
                    this.currentData = [...this.currentData]
                    this.resultList = [...this.resultList]
                    if (this.mode === 'form') {
                        if (this.formControl.formGroup.controls[this.dataSource.INFORMATION.COLUMN_LOCK]) {
                            this.formControl.formGroup.controls[this.dataSource.INFORMATION.COLUMN_LOCK].setValue(this.valLock)
                        }

                    }
                    this.eventEmit.emit({
                        type: 'rowClick',
                        value: this.currentData[this.currentData.length - 1],
                        isLock: this.checkLockRecord(this.currentData[this.currentData.length - 1])
                    })
                    this.dialogLockComponent.closeDialog()
                }
            }, err => {
                this.appService.createMessage('error', this.modeColumnLock === 'lock' ? 'Unlock record error' : 'Lock record error')
            })
        } else {
            this.appService.createMessage('warning', this.appService.getMessage('0012'))
        }

    }
    onChooseRecord() {
        this.eventEmit.emit({
            type: 'rowEmit',
            value: this.currentData,
            isLock: false
        });
    }

    onReloadTable(isFirstRecord?: boolean) {
        if (this.reloadParams) { // có reload params => query lại
            // this.reloadParams.isPageChange = false;
            this.queryData(this.reloadParams, isFirstRecord);
        } else { // Không có ( = null) thì clean Table;
            this.cleanTable();
        }
    }

    /** Hàm load lại table và chọn bản ghi với khóa được chỉ định */
    reloadTableAndSelectFeature(evt: any) {
        if (this.reloadParams) { // có reload params => query lại
            // this.reloadParams.isPageChange = false;
            this.queryData(this.reloadParams, false);
            const handle = this.pageChangeFinish.subscribe(() => {
                handle.unsubscribe();
                const currentData = this.resultList.filter(fil => {
                    let count = 0;
                    evt.where.forEach((item: any) => {
                        if (fil[item[0]] === item[1]) {
                            count++;
                        }
                    });

                    return count === evt.where.length;
                });

                if (currentData.length > 0) {
                    this.currentData = currentData;
                }
            });
        } else { // Không có ( = null) thì clean Table;
            this.cleanTable();
        }
    }

    onChangeMode() {
        this.initShowFirstTime = false;
        this.mode = this.mode === 'table' ? 'form' : 'table';
        if (this.mode === 'form') {
            // trong trường hợp là form, nếu có dữ liệu => bind vào các control
            if (this.currentData && this.currentData.length > 0) {
                this.bindDataToForm = Object.assign({}, this.currentData[this.currentData.length - 1]);
                this.currentData = this.currentData.filter((fil: any, index: number) => index === this.currentData.length - 1);
            } else {
                this.bindDataToForm = null;
            }
            this.cd.detectChanges();
            this.formControl.isModeView = this.isUpdate ? false : true;
            if (this.isUpdate) {
                this.isUpdate = false;
                this.onUpdate()
            }
            // this.refreshCurrentData();
        } else {
            // mode về table => tắt update
            this.cd.detectChanges();
            if (this.isUpdate) {
                this.isUpdate = false;
                this.onUpdate()
            }

            // Cần phải có changePage để giao diện UI hiển thị đúng page
            // Comment đi vì hiện tại load cả form lẫn table trong 1 lần luôn
            // Lý do: Để tối ưu code, khi chuyển đổi trạng thái giữa table và form sẽ không phải load lại nữa (do ngIf)
            // this.paginator.changePage(this.pageIndex - 1);
        }
    }
    onNoSaveChange(event: any, isPageChange = true) {
        this.arrSaveRecord = [];
        this.arr_dataChange = [];
        if (event) {
            const pageIndex = event.first || event.first === 0 ? (event.first / this.pageSize) + 1 : event;
            this.pageIndex = pageIndex;
            if (this.preventChangePage) {
                this.preventChangePage = false;
                return;
            }
            this.queryData({
                where: this._where,
                isPageChange
            });
            if (this.isUpdate) {
                this.isUpdate = false
                setTimeout(() => {
                    this.onUpdate()
                }, 300)

            }
        } else {
            this.isUpdate = !this.isUpdate;
            this.queryData({
                where: this._where
            });
            // const cache = JSON.parse(this.cache_RowSelected)
            // const index_sel = this.arrSaveRecord.findIndex((v: any) => v[this.primaryKey] === cache[this.primaryKey])
            // this.currentData = index_sel > -1 ? this.arrSaveRecord[index_sel] : cache
        }

        this.confirmService.close()
    }
    checkColLock(col: any, data: any) { }
    checkDK(col: any) {
        let chk = col.rowType !== 'editor' && col.rowType !== 'qrcode' ? true : false
        return chk;
    }
    evtHandle: any = null
    onPageChange(event: any, isPageChange = true) {
        this.evtHandle = event;
        if (this.preventChangePage) {
            this.preventChangePage = false;
            return;
        }
        setTimeout(() => {
            if (this.arrSaveRecord.length > 0) {
                this.confirmService.confirm({
                    key: this.primaryKey,
                    message: this.translate.instant('Have data changed, save changes?'),
                    header: this.translate.instant('Messages from the system'),
                    accept: () => {
                        const pageIndex = event.first || event.first === 0 ? (event.first / this.pageSize) + 1 : event;
                        this.pageIndex = pageIndex;
                        this.pageSize = event.rows
                        this.mode = 'table'
                        this.onSave(isPageChange);
                        this.confirmService.close();
                    },
                    reject: () => {
                        this.confirmService.close();
                        this.preventChangePage = true;
                        this.paginator.changePage(this.pageIndex - 1)
                        // const pageIndex = event.first || event.first === 0 ? (event.first / this.pageSize) + 1 : event;
                        // this.pageIndex = pageIndex;
                    }
                });
            } else {
                // const pageIndex = event.first || event.first === 0 ? (event.first / this.pageSize) + 1 : event;
                // this.pageIndex = pageIndex;
                // if (this.preventChangePage) {
                //     this.preventChangePage = false;
                //     return;
                // }
                const pageIndex = event.first || event.first === 0 ? (event.first / this.pageSize) + 1 : event;
                this.pageIndex = pageIndex;
                this.pageSize = event.rows
                this.queryData({
                    where: this._where,
                    isPageChange
                });
                if (this.isUpdate) {
                    this.isUpdate = false
                    setTimeout(() => {
                        this.onUpdate()
                    }, 300)

                }
            }
        }, 200)
    }

    onvalueModelChange(evt: any) {
        // console.log(evt)
    }
    cache_rowEditt: any = null
    onRowTableEdit(evt: any) {
        this.isEditComplete = false

    }
    onEditCancel(evt: any) {
    }
    arr_dataChange: any[] = [];
    isEditComplete: boolean = false;
    onEditComplete(evt: any) {
        // this.isEditComplete = t/rue;
        const index_c = this.arrSaveRecord.findIndex((v: any) => v[this.primaryKey] === evt.data[this.primaryKey]);
        const data = JSON.parse(this.cache_DataTable);
        this.autoCalc(data, evt);
        this.relateFieldInTableMode(data, evt);

        if (index_c > -1) {
            const index = this.tblColumnDef.findIndex((v: any) => v.rowFieldName === evt.field)
            if (this.tblColumnDef[index].rowType === 'date' || this.tblColumnDef[index].rowType === 'datetime') {
                const v_new = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(evt.data[evt.field], false)
                    : this.appService.convertDateToString(evt.data[evt.field], true)
                const v_old = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(data[evt.index][evt.field], false)
                    : this.appService.convertDateToString(data[evt.index][evt.field], true)
                if (v_new !== v_old) {
                    this.arrSaveRecord[index_c] = evt.data
                } else {
                    let count = 0;
                    Object.keys(evt.data).forEach((key: any) => {
                        if (data[evt.index][key] !== undefined) {
                            data[evt.index][key] = data[evt.index][key] === '' ? null : data[evt.index][key]
                            evt.data[key] = evt.data[key] === '' ? null : evt.data[key]
                            const index = this.tblColumnDef.findIndex((v: any) => v.rowFieldName === key)
                            if (index > -1) {
                                if (this.tblColumnDef[index].rowType === 'date' || this.tblColumnDef[index].rowType === 'datetime') {
                                    const v_new = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(evt.data[key], false)
                                        : this.appService.convertDateToString(evt.data[key], true)
                                    const v_old = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(data[evt.index][key], false)
                                        : this.appService.convertDateToString(data[evt.index][key], true)
                                    if (v_new !== v_old) {
                                        count++;
                                    }
                                } else {
                                    if (evt.data[key] !== data[evt.index][key]) {
                                        count++;
                                    }
                                }
                            }


                        }

                    })
                    if (count === 0) {
                        this.arrSaveRecord.splice(index_c, 1)
                    }
                }
            } else {
                if (evt.data[evt.field] !== data[evt.index][evt.field]) {
                    this.arrSaveRecord[index_c] = evt.data
                } else {
                    let count = 0;
                    Object.keys(evt.data).forEach((key: any) => {
                        if (data[evt.index][key] !== undefined) {
                            data[evt.index][key] = data[evt.index][key] === '' ? null : data[evt.index][key]
                            evt.data[key] = evt.data[key] === '' ? null : evt.data[key]
                            const index = this.tblColumnDef.findIndex((v: any) => v.rowFieldName === key)
                            if (index > -1) {
                                if (this.tblColumnDef[index].rowType === 'date' || this.tblColumnDef[index].rowType === 'datetime') {
                                    const v_new = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(evt.data[key], false)
                                        : this.appService.convertDateToString(evt.data[key], true)
                                    const v_old = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(data[evt.index][key], false)
                                        : this.appService.convertDateToString(data[evt.index][key], true)
                                    if (v_new !== v_old) {
                                        count++;
                                    }
                                } else {
                                    if (evt.data[key] !== data[evt.index][key]) {
                                        count++;
                                    }
                                }
                            }


                        }

                    })
                    if (count === 0) {
                        this.arrSaveRecord.splice(index_c, 1)
                    }
                }
            }

        } else {
            const index = this.tblColumnDef.findIndex((v: any) => v.rowFieldName === evt.field)
            if (this.tblColumnDef[index].rowType === 'date' || this.tblColumnDef[index].rowType === 'datetime') {
                const v_new = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(evt.data[evt.field], false)
                    : this.appService.convertDateToString(evt.data[evt.field], true)
                const v_old = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(data[evt.index][evt.field], false)
                    : this.appService.convertDateToString(data[evt.index][evt.field], true)
                if (v_new !== v_old) {
                    this.arrSaveRecord.push(evt.data)
                }

            } else {
                if (evt.data[evt.field] !== data[evt.index][evt.field]) {
                    this.arrSaveRecord.push(evt.data)
                }
            }
        }
        this.checkChange(evt.data, data[evt.index], evt.field);
    }

    checkChange(new_data: any, old_data: any, field: any) {
        const index = this.tblColumnDef.findIndex((v: any) => v.rowFieldName === field)
        if (this.tblColumnDef[index].rowType === 'date' || this.tblColumnDef[index].rowType === 'datetime') {
            const v_new = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(new_data[field], false)
                : this.appService.convertDateToString(new_data[field], true)
            const v_old = this.tblColumnDef[index].rowType === 'date' ? this.appService.convertDateToString(old_data[field], false)
                : this.appService.convertDateToString(old_data[field], true)
            if (v_new !== v_old) {
                if (!this.arr_dataChange.includes(new_data[this.primaryKey] + '_' + field)) {
                    this.arr_dataChange.push(new_data[this.primaryKey] + '_' + field)
                }
            } else {
                const indx = this.arr_dataChange.findIndex((v: any) => v === new_data[this.primaryKey] + '_' + field)
                if (indx > -1) {
                    this.arr_dataChange.splice(indx, 1)
                }
            }
        } else {
            if (new_data[field] !== old_data[field]) {
                if (!this.arr_dataChange.includes(new_data[this.primaryKey] + '_' + field)) {
                    this.arr_dataChange.push(new_data[this.primaryKey] + '_' + field)
                }
            } else {
                const indx = this.arr_dataChange.findIndex((v: any) => v === new_data[this.primaryKey] + '_' + field)
                if (indx > -1) {
                    this.arr_dataChange.splice(indx, 1)
                }
            }
        }
    }

    // autoCalc(data: any, evt: any) {
    //     // khoanb update tính toán
    //     this.dataSource.FIELD_LIST.forEach((field: any) => {
    //         if (field.children) {
    //             // let info = field.children[0].calculationInfos;
    //             const _v = [];
    //             if (field.children[0].calculationInfos) {
    //                 let check = true;
    //                 for (let v = 0; v < field.children[0].calculationInfos.length; v++) {
    //                     const info = field.children[0].calculationInfos[v];
    //                     _v.push(this.resultList[evt.index][info.field]);
    //                     if (info.func) {// childs
    //                         _v.push(this.calculateChilds(info));
    //                         // const  value =  eval(field.children[0].calculation);
    //                         this.coreWindowService.updateCalc(_v[1]);
    //                         check = false;
    //                     }
    //                     else if (info.tab)// parent
    //                     {
    //                         // 	_v[v]=parentRecord[info.field];
    //                     }

    //                 }
    //                 // tslint:disable-next-line:no-eval
    //                 const value = eval(field.children[0].calculation);

    //                 this.resultList[evt.index][field.children[0].fieldname] = value;
    //                 // console.log(value);
    //                 // if(check)
    //                 // {
    //                 // this.currentData[field.children[0].fieldname] = value;
    //                 // }
    //                 // else
    //                 // {   this.getdulieuservice.changeSource(value);}

    //                 // this.getdulieuservice.changeSource(value);
    //             }
    //         }
    //     });
    // }

    // DaiNT sửa hàm này vì hàm cũ (bên trên) lỗi với APP CONTRACT TRACKING
    private autoCalc(data: any, evt: any) {
        const fields = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === evt.field);
        if (fields && fields.length > 0) {
            const field = fields[0];
            if (field.children) {
                field.children.forEach((child: any) => {
                    if (child.calculation) {
                        const _v = [];
                        for (let v = 0; v < child.calculationInfos.length; v++) {
                            const info = child.calculationInfos[v];

                            if (info.tab !== undefined) { // Nếu có tab => giá trị lấy từ tab, info.tab có giá trị là number
                                const tabss = this.coreWindowService.lookupTab[info.tab];
                                const _val = tabss.INFORMATION.CURRENT_DATA ? tabss.INFORMATION.CURRENT_DATA[info.field] : null;
                                if (_val) {
                                    _v.push(_val);
                                }
                            } else {
                                if (this.resultList[evt.index][info.field] !== null && this.resultList[evt.index][info.field] !== undefined) {
                                    _v.push(this.resultList[evt.index][info.field]);
                                }
                            }
                        }
                        const value = eval(child.calculation);
                        if (!value || value.toString() === 'NaN') {
                            return;
                        }

                        this.resultList[evt.index][child.fieldname] = value;
                    }
                });
            }
        }
    }

    // thực hiện thay đổi trường cha con ở chế độ sửa trên table
    private relateFieldInTableMode(data: any, evt: any) {
        const fields = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === evt.field);
        const res = evt.data[evt.field];
        if (fields && fields.length > 0) {
            const field = fields[0];
            if (field.children) {
                field.children.forEach((child: any) => {
                    // Đoạn này hơi ngược so với core-form, ở core-form thì tìm ngược về các control cha và theo dõi sự thay đổi của control cha
                    // Ở đây là tìm các control con sau đó thay đổi giá trị của các control con
                    if (res) {
                        // trường hợp relate field
                        if (child.wherefieldname && child.wherefieldname !== '') {
                            this.resultList[evt.index][child.fieldname] = {
                                '__fromParent': true
                            };
                            this.resultList[evt.index][child.fieldname][child.wherefieldname] = res;
                        } else if (child.bindfieldname && child.bindfieldname !== '') {
                            // const REGEXP = /\[([^\][]*)]/g;
                            // const names = child.bindfieldname.match(REGEXP);
                            // let cloneBindFieldName: string = child.bindfieldname;
                            // if (names && names.length > 0) {
                            //     // FIELD_BINDFIELDNAME là biểu thức tính toán
                            //     names.forEach((name: string) => {
                            //         let fieldName = name.substring(1, name.length - 1);
                            //         if (fieldName.includes('.')) {
                            //             const tabName = fieldName.split('.')[0]; // Tên tab
                            //             const fieldNameInTab = fieldName.split('.')[1]; // Tên field trong tab
                            //             for (let i in this.coreWindowService.lookupTab) {
                            //                 const tab = this.coreWindowService.lookupTab[i];
                            //                 if (tab.INFORMATION.TABLE_NAME === tabName) {
                            //                     const _val = tab.INFORMATION.CURRENT_DATA ? tab.INFORMATION.CURRENT_DATA[fieldNameInTab] : null;
                            //                     if (_val) {
                            //                         cloneBindFieldName = cloneBindFieldName.replace(fieldName, _val);
                            //                     }
                            //                 }
                            //             }
                            //         } else {
                            //             cloneBindFieldName = cloneBindFieldName.replace(fieldName, this.objValueForm[parent.FIELD_NAME][fieldName]);
                            //         }
                            //     });
                            //     const value = Math.round(eval(cloneBindFieldName));
                            //     this.resultList[evt.index][child.fieldname] = {
                            //         '__fromParent': true
                            //     };
                            //     this.resultList[evt.index][child.fieldname][child.wherefieldname] = value;
                            // } else {
                            //     const fieldListCheck = ['search', 'select', 'treeselect'];
                            //     if (!this.objValueForm[parent.FIELD_NAME] || this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME] === undefined) {
                            //         return;
                            //     }
                            //     // FIELD_BINDFIELDNAME không phải biểu thức tính toán
                            //     // kiểm tra kiểu control của field
                            //     if (fieldListCheck.includes(field.FIELD_TYPE)) {
                            //         // trường hợp truyền obj
                            //         group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME]);
                            //     } else {
                            //         // trường hợp truyền giá trị: string/number/boolean
                            //         group[field.FIELD_NAME].setValue(this.objValueForm[parent.FIELD_NAME][field.FIELD_BINDFIELDNAME]);
                            //     }
                            // }
                        }
                    } else {
                        // Không có giá trị tại trường cha => xóa trường con
                        this.resultList[evt.index][child.fieldname] = null;
                    }
                });
            }
        }
    }

    onRowEdit(dataEmit: any, row: any, item: any) {
    }
    onValueChange(evt: any) {
    }
    onRowUnselect(evt: any) {
        setTimeout(() => {
            if (this.currentData && this.currentData.length === 0) {
                this.currentData = [evt.data];
            }
            this.setPermission();
        }, 0);
        // setTimeout(() => {
        //     this.currentData = JSON.parse(this.cache_RowSelected)
        // })
        // console.log(evt)
        // this.currentData = JSON.parse(this.cache_RowSelected)
        // this.onRowClick(evt)
    }
    indexCurrentSelect: any = 1;
    titleColumkey: any = null
    onRowClick(evt: any, geometry = null) {
        const rowData = evt.data;
        if (evt.type !== 'checkbox') {
            if (this.reloadParams) {
                this.reloadParams.isPageChange = true;
            }
            this.cache_RowSelected = JSON.stringify(this.currentData)
            if (geometry) {
                this.eventEmit.emit({
                    type: 'rowClickNoDraw',
                    value: rowData,
                    isLock: this.checkLockRecord(rowData)
                });
            } else {
                this.eventEmit.emit({
                    type: 'rowClick',
                    value: rowData,
                    isLock: this.checkLockRecord(rowData)
                });
            }
            const key = this.dataSource.INFORMATION.COLUMN_KEY
            const ind = this.resultList.findIndex((v: any) => v[key] === evt.data[key])
            this.titleColumkey = this.dataSource.INFORMATION.COLUMN_KEY ? this.dataSource.INFORMATION.COLUMN_KEY : this.dataSource.INFORMATION.COLUMN_CODE + " = " + evt.data[key]
            this.indexCurrentSelect = (ind + 1) + (this.pageSize * (this.pageIndex - 1))
            this.setPermission();
        }

    }

    onRowDoubleClick(rowData: any) {
        if (this.mode === 'table' && this.isUpdate) {
            return;
        }
        this.currentData = [rowData];
        this.onChangeMode();
    }

    /** Kết nối dữ liệu của 2 bảng thông qua 1 bảng trung gian (kết nối dữ liệu ở bảng con tới với bảng cha qua bảng TG) */
    private onLinkTable() {
        const configForm = {
            isMapApp: false,
            windowId: this.dataSource.INFORMATION.WINDOW_ID,
            dataSource: this.dataSource,
        };
        const dialogReturn: CoreSearchComponent = this.dialogPrime.showDialog(
            CoreSearchComponent,
            configForm,
            'Link Data'
        );
        dialogReturn.initTable();
        // dialogReturn.initWindow();
        dialogReturn.closeDialog.subscribe((res) => {
            this.dialogPrime.closeDialog();
        });

        let info = this.dataSource.INFORMATION;
        dialogReturn.rowEmit.subscribe((res) => {
            if (info.BANG_LK !== null && !info.IS_TAB_TRUNG_GIAN) {
                // trường hợp nhiều nhiều (insert bảng trung gian) 
                // Thực hiện công việc insert hoặc update dữ liệu trong bảng trung gian và requery table con
                const data: InsertResponse = {
                    features: res,
                    total: 1,
                    message: '',
                    success: true
                };
                this.afterInsert(data);
            } else {
                // Bản ghi đã có liên kết, bạn có muốn nhập lại không, nút OK - CANCEL
                let isNew = true;
                res.forEach((x: any) => {
                    if (x[info.TRUONG_LK_CON] !== null) {
                        isNew = false;
                    }
                });
                if (isNew) {
                    this._sendRequestUpdateToLinkTable(res);
                } else {
                    this.appService.confirm('Bản ghi đã có liên kết, bạn có muốn nhập lại không').subscribe(confirm => {
                        if (confirm) {
                            this._sendRequestUpdateToLinkTable(res);
                        }
                    });
                }
            }
        });
    }

    // tách từ hàm delete
    private async unlink() {
        // trường hợp 2.1 - có bảng lk trung gian thì xóa ở bảng lk trung gian
        const infor = this.dataSource.INFORMATION;
        const pData = infor.PARENT_DATA;

        if (infor.BANG_LK !== null && !infor.IS_TAB_TRUNG_GIAN) {
            // Lấy điều kiện để query bảng trung gian
            const whereClause: any[] = ['and'];
            whereClause.push([infor.TRUONG_LKTG_CHA, '=', pData[infor.TRUONG_LK_CHA]]);
            this.changeTypeRequest()
            this.reqService.service.search({
                url: infor.BANG_LK,
                where: whereClause,
                logic: 'and',
            }).subscribe((res) => {
                const deleteList = res.features.filter((fil) => {
                    let count = 0;
                    this.currentData.forEach((item: any) => { // kiểm tra danh sách bản ghi được chọn
                        if (fil[infor.TRUONG_LKTG_CON] === item[infor.TRUONG_LK_CON]) {
                            count++
                        }
                    });

                    return count !== 0;
                });
                const listRequest: any[] = [];
                deleteList.forEach((item) => {
                    item[infor.TRUONG_LKTG_CON] = null;
                    listRequest.push(
                        this.reqService.service.delete({
                            url: infor.BANG_LK,
                            data: item,
                            primaryKey: infor.KHOA_CHINH_BANG_LK,
                        })
                    );
                });

                const dataKeyReturn: any[] = [];
                this.currentData.forEach((item: any) => {
                    dataKeyReturn.push(item[infor.TRUONG_LK_CON]);
                })

                combineLatest(listRequest).subscribe((resp) => {
                    const response: DeleteResponse = {
                        data: dataKeyReturn,
                        success: true,
                        message: 'Xóa liên kết thành công',
                    };
                    this.confirmService.close();
                    this.deleteSuccess(response);
                }, (err) => {
                    this.loading = false;
                    this.appService.notification('Xóa không thành công!', 'error');
                }
                );
            });
        } else {
            // trường hợp 2.2 - update lại trường liên kết thành null
            const info = this.dataSource.INFORMATION;
            const keyNotSend: any[] = ['_D', '_DT', '_V', '_C', '_N', '_S', '__color_code_', '__stt', info.KHOA_CHINH, info.TRUONG_LK_CON];
            const listRequest: any[] = [];
            this.currentData.forEach((data: any) => {
                const dataSend: any = {};
                // Đặt khóa chính lên đầu vì nó phải như thế mới chạy autodata
                dataSend[info.KHOA_CHINH] = data[info.KHOA_CHINH];
                // Ghi đè trường liên kết
                dataSend[info.TRUONG_LK_CON] = null;
                // remove các trường thừa
                Object.keys(data).forEach(key => {
                    // nếu key không có trong danh sách không gửi thì add vào dataSend
                    if (!(keyNotSend.some(v => key.includes(v)))) {
                        dataSend[key] = data[key];
                    }
                });
                listRequest.push(
                    this.reqService.service.update({
                        url: this._urlEdit,
                        primaryKey: this.primaryKey,
                        data: dataSend,
                    })
                );
            });

            combineLatest(listRequest).subscribe(response => {
                this.setCurrentData(-1);
                this.onReloadTable();
                this.confirmService.close();
                this.appService.notification(this.appService.getMessage('0003'), 'success');
            }, err => {
                this.setCurrentData(-1);
                this.onReloadTable();
                this.confirmService.close();
                this.appService.notification('Xóa không thành công!', 'error');
            });
        }
    }

    private _sendRequestUpdateToLinkTable(res: any[]) {
        const info = this.dataSource.INFORMATION;
        const listRequest: any[] = [];
        const keyNotSend: any[] = ['_D', '_DT', '_V', '_C', '_N', '_S', '__color_code_', '__stt', info.KHOA_CHINH, info.TRUONG_LK_CON];
        res.forEach((data: any) => {
            const dataSend: any = {};
            // Đặt khóa chính lên đầu vì nó phải như thế mới chạy autodata
            dataSend[info.KHOA_CHINH] = data[info.KHOA_CHINH];
            // Ghi đè trường liên kết
            dataSend[info.TRUONG_LK_CON] = info.PARENT_DATA[info.TRUONG_LK_CHA];
            // remove các trường thừa
            Object.keys(data).forEach(key => {
                // nếu key không có trong danh sách không gửi thì add vào dataSend
                if (!(keyNotSend.some(v => key.includes(v)))) {
                    dataSend[key] = data[key];
                }
            });
            listRequest.push(
                this.reqService.service.update({
                    url: this._urlEdit,
                    primaryKey: this.primaryKey,
                    data: dataSend,
                })
            );
        });

        if (listRequest.length > 0) {
            combineLatest(listRequest).subscribe(response => {
                let count = 0;
                response.forEach((item: any) => {
                    if (item.success) {
                        count++;
                    }
                });
                if (count === listRequest.length) {
                    this.onReloadTable();
                    this.emitEventReloadParentTab();
                    this.appService.notification(
                        this.appService.getMessage('0007'),
                        'success'
                    );
                } else {
                    this.onReloadTable();
                    this.emitEventReloadParentTab();
                    this.appService.notification(
                        this.appService.getMessage('0008'),
                        'error'
                    );
                }

            })
        }
    }

    /** Chuyển về trang đầu tiên và chọn bản ghi đầu tiên */
    private onFirstRow() {
        // Có dữ liệu mới trả về bản ghi đầu được
        if (this.totalRecord > 0) {
            if (this.pageIndex !== 1) {
                const fisrtPage = this.pageChangeFinish.subscribe(() => {
                    fisrtPage.unsubscribe();
                    // Cài đặt mặc định chọn item đầu tiên
                    this.setCurrentData(0);
                });

                this.pageIndex = 1;
                this.onPageChange(1);
            } else {
                // Chuyển bản ghi hiện tại về bản ghi đầu
                this.setCurrentData(0);
            }
        }
    }

    /** Chuyển về trang cuối cùng và chọn bản ghi cuối cùng */
    private onLastRow() {
        // Có dữ liệu mới trả về bản ghi cuối được
        if (this.totalRecord > 0) {
            const surplus = this.totalRecord % this.pageSize;
            const indexLastPage =
                surplus === 0
                    ? this.totalRecord / this.pageSize
                    : (this.totalRecord - surplus) / this.pageSize + 1;

            if (this.pageIndex !== indexLastPage) {
                const lastPage = this.pageChangeFinish.subscribe(() => {
                    lastPage.unsubscribe();
                    // Cài đặt mặc định chọn bản ghi cuối cùng
                    this.resultList.length > 0
                        ? this.setCurrentData(this.resultList.length - 1)
                        : this.setCurrentData(-1);
                });

                this.pageIndex = indexLastPage;
                this.onPageChange(indexLastPage);
            } else {
                // Chuyển bản ghi hiện tại về bản cuối
                this.setCurrentData(this.resultList.length - 1);
            }
        }
    }

    /** Chuyển sang bản ghi tiếp theo */
    private onNextRow() {
        const lastChooseData = this.currentData && this.currentData.length > 0 ? this.currentData[this.currentData.length - 1] : null;
        // Kiểm tra bảng đã chọn bản ghi nào chưa
        if (lastChooseData) {
            const findIndex = this.resultList.indexOf(lastChooseData);
            if (findIndex === this.resultList.length - 1) {
                // return; // Không làm gì như anh Vinh yêu cầu
                // Trường hợp bản ghi ở cuối cùng của trang => sang trang mới và chọn bản ghi đầu tiên nếu không phải là trang cuối cùng
                const nextRecord = this.pageChangeFinish.subscribe(() => {
                    nextRecord.unsubscribe();
                    this.resultList.length > 0
                        ? this.setCurrentData(0)
                        : this.setCurrentData(-1);
                });

                const surplus = this.totalRecord % this.pageSize;
                const indexLastPage =
                    surplus === 0
                        ? this.totalRecord / this.pageSize
                        : (this.totalRecord - surplus) / this.pageSize + 1;
                if (indexLastPage !== this.pageIndex) {
                    this.pageIndex = this.pageIndex + 1;
                    // Cần phải có changePage để giao diện UI hiển thị đúng page
                    this.paginator.changePage(this.pageIndex - 1);
                }
            } else {
                // Chuyển bản ghi hiện tại sang bản ghi tiếp theo
                this.setCurrentData(findIndex + 1);
            }
        }
    }

    /** Chuyển về bản ghi trước */
    private onPreviousRow() {
        const lastChooseData = this.currentData && this.currentData.length > 0 ? this.currentData[this.currentData.length - 1] : null;
        // Kiểm tra bảng đã chọn bản ghi nào chưa
        if (lastChooseData) {
            const findIndex = this.resultList.indexOf(lastChooseData);
            if (findIndex > 0) {
                this.setCurrentData(findIndex - 1);
            } else {
                // return; // Không làm gì như anh Vinh yêu cầu
                // Trường hợp là bản ghi đầu tiên của trang => về trang cũ và chọn bản ghi cuối cùng (nếu không phải là trang đầu tiên)
                const previousPage = this.pageChangeFinish.subscribe(() => {
                    previousPage.unsubscribe();
                    this.resultList.length > 0
                        ? this.setCurrentData(this.resultList.length - 1)
                        : this.setCurrentData(-1);
                });

                if (this.pageIndex > 1) {
                    this.pageIndex = this.pageIndex - 1;
                    // Cần phải có changePage để giao diện UI hiển thị đúng page
                    this.paginator.changePage(this.pageIndex - 1);
                }
            }
        }
    }

    /** Đặt index = -1 để bỏ highlight dữ liệu được chọn */
    private setCurrentData(index: any) {
        this.currentData = index >= 0 ? [this.resultList[index]] : [];
        const currentData = this.currentData.length > 0 ? this.currentData[0] : null;
        this.indexCurrentSelect = (index + 1) + (this.pageSize * (this.pageIndex - 1))

        this.eventEmit.emit({
            type: 'rowClick',
            value: currentData,
            isLock: this.checkLockRecord(currentData)
        });
        this.setPermission();

        if (this.mode === 'form') {
            // Nếu đang ở dạng form => bind dữ liệu mới
            this.bindDataToForm = currentData;
            if (this.permissionList.hasOwnProperty('EDIT_DP_LOGIC')) {
                this.onUpdate(!this.permissionList['EDIT_DP_LOGIC']);
            }
        }

    }

    /** Mở dialog hiển thị form sửa */
    private onUpdate(isUpdate: any = null) {
        this.evtHandle = null;
        if (this.mode === 'form') {
            if (!this.checkLockRecord(this.currentData[this.currentData.length - 1])) {
                this.isUpdate = isUpdate ?? !this.isUpdate;
                if (this.formControl) {
                    this.formControl.editForm(this.isUpdate);
                    this.formControl.modeAttachment = this.isUpdate ? 'edit' : 'view';
                }
            } else {
                this.isUpdate = isUpdate ?? !this.isUpdate;
                if (this.isUpdate) {
                    this.appService.alert('This record is locked!', 'warn')
                }

            }

        } else {
            // this.isUpdate = !this.isUpdate;
            // this.isUpdate = isUpdate ?? !this.isUpdate;
            if (!this.isUpdate) {
                this.isUpdate = isUpdate ?? !this.isUpdate;
                this.resultList.forEach((item: any) => {
                    if (!this.checkLockRecord(item)) {
                        this.pTable.initRowEdit(item)
                    }

                })

            } else {
                if (this.arrSaveRecord.length > 0) {
                    this.confirmService.confirm({
                        key: this.primaryKey,
                        message: this.translate.instant('Have data changed, save changes?'),
                        header: this.translate.instant('Messages from the system'),
                        accept: () => {
                            this.isUpdate = isUpdate ?? !this.isUpdate;
                            this.onSave(false);
                            this.confirmService.close();
                        },
                        reject: () => {
                            this.confirmService.close();
                        }
                    });
                } else {
                    this.isUpdate = isUpdate ?? !this.isUpdate;
                }
            }
        }
    }

    private emitEventReloadParentTab() {
        if (this.dataSource.INFORMATION.IS_REFRESH_PARENT_TAB) {
            this.eventEmit.emit({
                type: 'reload-parent-tab',
                value: null,
                isLock: false
            });
        }
    }

    checkValidate(data: any) {
        const arr = this.tblColumnDef.filter((v: any) => v.rowRequired);
        let count = 0;
        let chk = false;
        arr.forEach((item: any) => {
            if (data[item.rowFieldName] !== null && data[item.rowFieldName] !== '') {
                count++;
            }
        })
        if (count === arr.length) {
            chk = true;
        } else {
            chk = false;
        }
        return chk;
    }
    onTreeSelectChange(evt: any, data: any, col: any, ind: any) {
        this.onEditComplete({
            data: data,
            field: col.rowFieldName,
            index: ind,
            originalEvent: evt
        })
        this.isEditComplete = false
    }
    public addArchive(params: any) {
        let resp: any = null
        const currentUser = JSON.parse(this.appService.currentUser)
        if (currentUser.schema && currentUser.schemadefault !== currentUser.schema) {
            const url = this.appService.urlAutoData + '/' + currentUser.dbname + '/data/' + currentUser.schema + '/SysLog'
            const odataParams = {
                url: url,
                primaryKey: 'SysLogId',
                data: params
            };
            resp = this.reqServiceAutoData.service.insert(odataParams);
        } else {
            const odataParams = {
                url: this.appService.urlWS + '/SysLogs',
                primaryKey: 'SysLogId',
                data: params
            };
            resp = this.reqServiceSQL.service.insert(odataParams);

        }
        return resp;
    }
    saveArchive(recordId: any, mode: any, dataDetail: any) {
        const currentUser = JSON.parse(this.appService.currentUser)
        const d = new Date()
        const dateUTC = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
        const params = {
            RecordId: recordId,
            "TableId": this.dataSource.INFORMATION.TABLE_ID,
            "ActionName": mode,
            "ClientId": this.appService.ClientId,
            "UserName": currentUser.username,
            "UserId": currentUser.userid,
            "CreateDate": new Date(dateUTC),
            "IpAddress": null,
            "Detail": JSON.stringify(dataDetail)
        }
        return this.addArchive(params)
    }
    objDataChange(oldData: any, newData: any) {
        let obj: any = {}
        Object.keys(newData).forEach((key: any) => {
            if (newData[key] !== oldData[key]) {
                obj[key] = oldData[key]
            }
        })
        return obj
    }

    //Lưu attachmnet
    checkDomain() {
        const hostname = window.location.hostname
        // const hostname = 'office.esrivn.net'
        if (this.appService.urlProxy.indexOf(hostname) > -1) {
            this.urlProxy = ''
        } else {
            this.urlProxy = this.appService.urlProxy;
        }
        // console.log(this.urlProxy)
    }
    uploadFile(file: any, info: any, currentData?: any) {
        this.checkDomain()
        const clientId: any = this.appService.ClientId;
        const appId = this.appService.c$['appId'];
        // this.urlProxy = this.appService.urlProxy + '?';
        const urlRequest = this.appService.urlOdataAttachment + '/odata/ProccessFile/PostAttactment';
        // const info = this.dataSource.dataSource.INFORMATION
        // const currentData = this.dataSource.currentData
        const form = new FormData();
        form.append('file', file);
        form.append('clientID', clientId);
        form.append('appID', appId);
        form.append('tableID', info.TABLE_ID);
        form.append('recordID', currentData ? currentData[info.KHOA_CHINH] : 0);
        return this.reqService.service.query({
            url: urlRequest,
            params: form,
            method: 'POST',
            contentType: 'unset',
            proxy: this.urlProxy
        })
    }
    deleteAttachmentFile(urlFile: any) {
        const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const a = localStorage ? this.appService.currentUser : null;
        const currentUser = a ? JSON.parse(a) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }
        const res = this.http.delete(urlServer + '?url=' + urlFile, { headers })
        return res.pipe(
            map((res: any) => {
                const resp: DeleteResponse = {
                    data: res.model,
                    success: res.success,
                    message: res.message
                };
                return resp;
            }))
    }
    /** Lưu các bản ghi */
    private onSave(isPageChange: boolean, isSaveArchive?: boolean) {
        // return;
        const currentData = this.currentData && this.currentData.length > 0 ? this.currentData[this.currentData.length - 1] : null;
        if (this.mode === 'form') {
            if (currentData === null) {
                this.appService.notification(this.appService.getMessage('0013'), 'error');
                return;
            }

            if (this.formControl.isModeView) {
                this.appService.notification(this.appService.getMessage('0014'), 'warn');
                return;
            }

            if (this.formControl.formGroup.valid === false) {
                if (this.formControl.formGroup.errors?.maxlength) {
                    this.appService.notification(this.appService.getMessage('0015'), 'error');
                } else if (this.formControl.formGroup.errors?.pattern) {
                    this.appService.notification(this.appService.getMessage('0016'), 'error');
                } else {
                    this.appService.notification(this.appService.getMessage('0012'), 'error');
                }
                return;
            }

            const data = this.appService.deepCloneObject(this.formControl.formGroup.value);
            const dataSend: any = {};

            // Kiểu mới: chỉ gửi những dữ liệu được thay đổi trong control (control nào không thay đổi thì cũng không gửi luôn)
            const info = this.dataSource.INFORMATION;
            let controlPrimaryKeyChange = false;

            // Đặt khóa chính lên đầu vì nó phải như thế mới chạy autodata
            dataSend[info.KHOA_CHINH] = currentData[info.KHOA_CHINH];

            // Kiểm tra xem có trường image hay không
            // Có thay đổi field image thì upload attachment trước
            const arr_fieldimg = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldtype === 'image');
            if (arr_fieldimg && arr_fieldimg.length > 0) {
                const arr_file = this.formControl.arrFileImage
                const arr_req: any[] = []
                const arr_field: any[] = []
                const arr_delete: any[] = []
                const arr_field_delete: any[] = []
                arr_fieldimg.forEach((item: any) => {
                    if (currentData[item.fieldname] === data[item.fieldname]) {
                        dataSend[item.fieldname] = currentData[item.fieldname]
                    } else {
                        if (currentData[item.fieldname] && currentData[item.fieldname] !== '') {
                            const url = currentData[item.fieldname].substr(currentData[item.fieldname].lastIndexOf('AttachmentFiles'), currentData[item.fieldname].length)
                            arr_delete.push(this.deleteAttachmentFile(url))
                            arr_field_delete.push(item.fieldname)
                        }
                    }
                })
                if (arr_delete.length > 0) {
                    const arrSaveAferDelete: any[] = []
                    combineLatest(arr_delete).subscribe((resp: any) => {
                        if (arr_file.length > 0) {
                            arr_file.forEach((obj_f: any) => {
                                if (obj_f.file) {
                                    arr_field.push(obj_f.fieldname)
                                    arr_req.push(
                                        this.uploadFile(obj_f.file, info, currentData)
                                    )
                                }
                            })
                            arr_field_delete.forEach((fd: any) => {
                                const ind_file = arr_file.findIndex((v: any) => v.fieldname === fd)
                                if (ind_file < 0) {
                                    dataSend[fd] = data[fd]
                                }
                            })
                            combineLatest(arr_req).subscribe((res: any) => {
                                res.forEach((item: any, ind_f: any) => {
                                    const url_file = (item.model as string)
                                    const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + url_file).replace(/\\/g, '/');
                                    const field_name = arr_field[ind_f]
                                    dataSend[field_name] = urlImage
                                })
                                this.onSaveForm(data, dataSend, info, controlPrimaryKeyChange, currentData, isSaveArchive)
                            }, err => {
                                this.appService.createMessage('error', 'Upload files error')
                                return;
                            })

                        } else {
                            arr_fieldimg.forEach((item: any) => {
                                dataSend[item.fieldname] = data[item.fieldname]
                            })
                            this.onSaveForm(data, dataSend, info, controlPrimaryKeyChange, currentData, isSaveArchive)
                        }
                    }, err => {
                        this.appService.createMessage('error', 'Delete files error')
                        return;
                    })
                } else {
                    if (arr_file.length > 0) {
                        arr_file.forEach((obj_f: any) => {
                            if (obj_f.file) {
                                arr_field.push(obj_f.fieldname)
                                arr_req.push(
                                    this.uploadFile(obj_f.file, info, currentData)
                                )
                            }
                        })
                        combineLatest(arr_req).subscribe((res: any) => {
                            res.forEach((item: any, ind_f: any) => {
                                const url_file = (item.model as string)
                                const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + url_file).replace(/\\/g, '/');
                                const field_name = arr_field[ind_f]
                                dataSend[field_name] = urlImage
                            })
                            this.onSaveForm(data, dataSend, info, controlPrimaryKeyChange, currentData, isSaveArchive)
                        }, err => {
                            this.appService.createMessage('error', 'Upload files error')
                            return;
                        })

                    } else {
                        arr_fieldimg.forEach((item: any) => {
                            dataSend[item.fieldname] = currentData[item.fieldname]
                        })
                        this.onSaveForm(data, dataSend, info, controlPrimaryKeyChange, currentData, isSaveArchive)
                    }
                }

            } else {
                this.onSaveForm(data, dataSend, info, controlPrimaryKeyChange, currentData, isSaveArchive)
            }



        } else {
            const listUpdate = this.arrSaveRecord;
            if (listUpdate.length > 0) {
                let count = 0;
                listUpdate.forEach((item: any) => {
                    if (this.checkValidate(item)) {
                        count++;
                    }
                })
                if (count === listUpdate.length) {
                    const listRequest: any[] = [];
                    const listArchive: any[] = [];
                    listUpdate.forEach((item: any) => {
                        let data: any = {}
                        const data_save = this.appService.deepCloneObject(item);
                        data[this.primaryKey] = data_save[this.primaryKey]
                        Object.keys(data_save).forEach((key: any) => {
                            if (key !== this.primaryKey) {
                                data[key] = data_save[key]
                            }
                        })
                        Object.keys(item).forEach((key) => {
                            const arrayCheck = ['_D', '_DT', '_V', '_C', '_N', '_S', '__color_code_', '__stt'];
                            if (arrayCheck.some(v => key.includes(v))) {
                                delete data[key];
                            } else {
                                const array = this.dataSource.FIELD_LIST.filter(
                                    (fil: any) => fil.fieldname === key
                                );
                                if (array && array[0] && array[0].fieldautodata !== 'Y' && data[key]) {
                                    const fieldtype = array[0].fieldtype;
                                    switch (fieldtype) {
                                        case 'date': case 'datetime':
                                            data[key] = new Date(data[key]);
                                            if (data[key] instanceof Date) {
                                                const year = data[key].getFullYear();
                                                const month = data[key].getMonth();
                                                const date = data[key].getDate();
                                                const hour = data[key].getHours();
                                                const minute = data[key].getMinutes();
                                                const second = data[key].getSeconds();

                                                const dateUTC = Date.UTC(year, month, date, hour, minute, second);
                                                data[key] = new Date(dateUTC);
                                            }
                                            break;
                                        case 'select':
                                            data[key] = data[key].CODE ?? data[key]; // Nếu có data[key].CODE thì lấy nó, không thì lấy data[key]
                                            break;
                                        case 'search':
                                            const tag = array[0].columnkey ? array[0].columnkey : array[0].columncode;
                                            data[key] = data[tag];
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                        });

                        const info = this.dataSource.INFORMATION;
                        if (info.PARENT_DATA) {
                            // Có dữ liệu cha phải thuộc trường hợp liên kết 1-1 mới thay đổi
                            if (info.BANG_LK === null || info.BANG_LK === undefined) {
                                data[info.TRUONG_LK_CON] = info.PARENT_DATA[info.TRUONG_LK_CHA]; // data or dataSend???
                            }
                        }
                        listRequest.push(
                            this.reqService.service.update({
                                url: this._urlEdit,
                                primaryKey: this.primaryKey,
                                data,
                            })
                        );
                        const arr = JSON.parse(this.cache_DataTable)
                        const obj = arr.find((v: any) => v[this.dataSource.INFORMATION.KHOA_CHINH] === data[this.dataSource.INFORMATION.KHOA_CHINH])
                        if (isSaveArchive && obj) {
                            listArchive.push(this.saveArchive(item[this.primaryKey], 'SAVE', this.objDataChange(obj, data)))
                        }
                    });

                    combineLatest(listRequest).subscribe((res) => {
                        let countRes = 0
                        res.forEach((item: any) => {
                            if (item.success) {
                                countRes++
                            }
                        })
                        if (countRes === res.length) {
                            this.savedomainservice.autoSaveDomainTable(this.dataSource.INFORMATION.TABLE_ID, this.appService.ClientId)
                            listUpdate.forEach((item: any) => {
                                const result = this.resultList.filter(
                                    (fil) =>
                                        fil[this.primaryKey] === item[this.primaryKey]
                                );
                                if (result.length > 0) {
                                    Object.assign(result[0], item);
                                    if (!isPageChange) {
                                        this.queryData({
                                            where: this._where
                                        });
                                    }

                                }

                            });
                            if (isSaveArchive) {
                                combineLatest(listArchive).subscribe()
                            }
                            // this.arrSaveRecord = [];
                            this.arrSaveRecord = [];
                            this.arr_dataChange = [];
                            if (isPageChange) {
                                // if (this.preventChangePage) {
                                //     this.preventChangePage = false;
                                //     return;
                                // }

                                this.queryData({
                                    where: this._where,
                                    isPageChange
                                });
                                if (this.isUpdate) {
                                    this.isUpdate = false
                                    setTimeout(() => {
                                        this.onUpdate()
                                    }, 300)

                                }
                            } else {
                                this.onUpdate()
                            }

                            this.emitEventReloadParentTab();

                            this.appService.notification(
                                this.appService.getMessage('0007'),
                                'success'
                            );
                        } else {
                            this.appService.notification(
                                this.appService.getMessage('0008'),
                                'error'
                            );
                        }

                    });
                } else {
                    this.appService.createMessage(
                        'warning',
                        this.appService.getMessage('0012')
                    );
                }

            } else {
                this.appService.createMessage('warning', 'No data is changed')
            }

        }
    }

    // Tách phần lưu form
    onSaveForm(data: any, dataSend: any, info: any, controlPrimaryKeyChange: any, currentData: any, isSaveArchive: any) {
        // thêm điều kiện kiểm tra field isreadonly !== 'Y' để khi update không gửi dữ liệu trong field disabled
        // Bỏ điều kiện isreadonly !== 'Y' vì trường calculation đặt isreadonly === 'Y' thì khi sửa không thể cập nhật được
        Object.keys(data).forEach((key) => {
            // Pristine = true: NGƯỜI DÙNG chưa thay đổi nội dung của control, dirty = true là người dùng đã thay đổi giá trị cũ của control
            if (key === info.KHOA_CHINH) {
                if (this.formControl.formGroup.controls[key].dirty) {
                    controlPrimaryKeyChange = true;
                }
            }
            // if (this.formControl.formGroup.controls[key].dirty) {
            const array = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === key);
            if (array && array[0] && array[0].fieldautodata !== 'Y') {
                const fieldtype = array[0].fieldtype;
                const columntype = array[0].columntype;
                const val = data[key];
                switch (fieldtype) {
                    case 'date':
                    case 'datetime':
                        if (val !== undefined) {
                            if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                                dataSend[key] = val !== null ? new Date(val).getTime() : null;
                            } else {
                                if (columntype === 'date' || columntype === 'datetime') {
                                    dataSend[key] = val !== null ? new Date(val) : null;
                                    if (dataSend[key] instanceof Date) {
                                        const year = dataSend[key].getFullYear();
                                        const month = dataSend[key].getMonth();
                                        const date = dataSend[key].getDate();
                                        const hour = dataSend[key].getHours();
                                        const minute = dataSend[key].getMinutes();
                                        const second = dataSend[key].getSeconds();

                                        const dateUTC = Date.UTC(year, month, date, hour, minute, second);
                                        dataSend[key] = new Date(dateUTC);
                                    }
                                } else {
                                    dataSend[key] = val !== null ? new Date(val).getTime() : null;
                                }

                            }
                        }
                        break;
                    case 'select':
                        if (val !== undefined) {
                            if (val !== null && val.CODE !== undefined) {
                                dataSend[key] = val.CODE;
                            } else {
                                dataSend[key] = val;
                            }
                        }
                        break;
                    case 'search':
                        if (data[key] !== undefined) {
                            dataSend[key] = val && val[array[0].columnkey] !== undefined ? val[array[0].columnkey] : val;
                        }
                        break;
                    case 'image': break;
                    default:
                        dataSend[key] = data[key] !== undefined ? data[key] : currentData[key];
                        break;
                }

            }
            // }
        });

        if (this.reqService.type === 'sql') {
            // Nếu bảng dữ liệu là kiểu sql odata => gửi đầy đủ bộ dữ liệu
            Object.keys(currentData).forEach(key => {
                const array = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === key);
                if (array && array[0]) {
                    const fieldtype = array[0].fieldtype;
                    const columntype = array[0].columntype;
                    switch (fieldtype) {
                        case 'date':
                        case 'datetime':
                            if (data[key] !== undefined) {
                                if (columntype === 'date' || columntype === 'datetime') {
                                    dataSend[key] = data[key] !== null ? new Date(data[key]) : null;
                                    if (dataSend[key] instanceof Date) {
                                        const year = dataSend[key].getFullYear();
                                        const month = dataSend[key].getMonth();
                                        const date = dataSend[key].getDate();
                                        const hour = dataSend[key].getHours();
                                        const minute = dataSend[key].getMinutes();
                                        const second = dataSend[key].getSeconds();

                                        const dateUTC = Date.UTC(year, month, date, hour, minute, second);
                                        dataSend[key] = new Date(dateUTC);
                                    }
                                } else {
                                    dataSend[key] = data[key] !== null ? new Date(data[key]).getTime() : null;
                                }
                            } else {
                                dataSend[key] = currentData[key];
                            }
                            break;
                        case 'select':
                            if (data[key] !== undefined) {
                                if (data[key] !== null && data[key].CODE !== undefined) {
                                    dataSend[key] = data[key].CODE;
                                } else {
                                    dataSend[key] = data[key];
                                }
                            } else {
                                dataSend[key] = currentData[key];
                            }
                            break;
                        case 'search':
                            if (data[key] !== undefined) {
                                if (this.formControl.formGroup.controls[key] && this.formControl.formGroup.controls[key].pristine) {
                                    // Pristine nghĩa là NGƯỜI DÙNG chưa thay đổi giá trị của control
                                    dataSend[key] = currentData[key];
                                } else {
                                    dataSend[key] = data[key] && data[key][array[0].columnkey] !== undefined ? data[key][array[0].columnkey] : data[key];
                                }
                            } else {
                                dataSend[key] = currentData[key];
                            }
                            break;
                        case 'image': break;
                        default:
                            dataSend[key] = data[key] !== undefined ? data[key] : currentData[key];
                            break;
                    }
                }
            });
        }

        if (info.PARENT_DATA) {
            // Có dữ liệu cha phải thuộc trường hợp liên kết 1-1 mới thay đổi
            if (info.BANG_LK === null || info.BANG_LK === undefined) {
                data[info.TRUONG_LK_CON] = info.PARENT_DATA[info.TRUONG_LK_CHA]; // data or dataSend???
            }
        }
        if (Object.keys(dataSend).length === 1 && !controlPrimaryKeyChange) {
            if (this.formControl.appAttachment) { // có appAttachment thì mới gọi được phương thức uploadFileAfterInsert
                this.formControl.appAttachment.uploadFileAfterInsert({ data: null });
            }
            return;
        }
        if (Object.keys(this.objDataChange(currentData, dataSend)).length === 0) {
            this.appService.notification('No data is changed', 'warn');
            return;
        }

        // // Xóa các key thừa
        // const cloneDataSend = Object.assign({}, dataSend);
        // dataSend = {};
        // const keyNotSend: any[] = ['_D', '_DT', '_V', '_C', '_N', '_S', '__color_code_', '__stt'];
        // Object.keys(cloneDataSend).forEach(key => {
        //     // nếu key không có trong danh sách không gửi thì add vào dataSend
        //     if (!(keyNotSend.some(v => key.includes(v)))) {
        //         dataSend[key] = data[key];
        //     }
        // });

        this.reqService.service.update({
            url: this._urlEdit,
            primaryKey: this.primaryKey,
            data: dataSend
        }).subscribe((res) => {
            if (res.success) {
                this.savedomainservice.autoSaveDomainTable(this.dataSource.INFORMATION.TABLE_ID, this.appService.ClientId)
                const a = this.pageChangeFinish.subscribe(() => {
                    const b = this.resultList.filter(fil => fil[this.primaryKey] === dataSend[this.primaryKey]);
                    if (b.length > 0) {
                        this.currentData = [b[0]];
                    } else {
                        this.currentData = [dataSend];
                    }

                    this.emitEventReloadParentTab();
                    this.onUpdate(false);
                    a.unsubscribe();
                });
                if (this.formControl.appAttachment) { // có appAttachment thì mới gọi được phương thức uploadFileAfterInsert
                    this.formControl.appAttachment.uploadFileAfterInsert({ data: null });
                }
                if (isSaveArchive) {
                    this.saveArchive(currentData[this.primaryKey], 'SAVE', this.objDataChange(currentData, dataSend)).subscribe()
                }
                this.queryData({
                    where: this._where,
                    isPageChange: true
                });
                this.appService.notification(
                    this.appService.getMessage('0007'),
                    'success'
                );
                // update nếu là domain
                // tslint:disable-next-line:radix
                // const ketqua = this.savedomainservice.saveDomainFixNew(info.TABLE_ID, Number.parseInt(this.appService.objStoreAppId.appId));
                // ketqua.subscribe(kq => {
                //     if (kq) {
                //     }
                // });
            } else {
                if (res.message) {
                    this.appService.notification(res.message, 'error');
                } else {
                    this.appService.notification(this.appService.getMessage('0008'), 'error');
                }
            }
        }, err => {
            this.appService.notification(this.appService.getMessage('0008'), 'error');
        });
    }

    /** Mở dialog hiển thị form thêm mới */
    private onInsert() {
        if (this.dataSource.INFORMATION.PARENT_ID) {
            if (this.dataSource.INFORMATION.PARENT_DATA === null || this.dataSource.INFORMATION.PARENT_DATA === undefined) {
                this.appService.alert('Please select parent record!', 'warn');
                return;
            }
        }

        const configForm = {
            dataSource: this.dataSource,
            isShowButton: true,
            okTitle: 'Insert',
            mode: 'add',
            isModeView: false,
            parent: this
        };

        // Sửa đổi lúc 16h38p ngày 31/01/2023 theo yêu cầu của anh Vinh, toàn bộ dialog bật ra khi thêm mới, tìm kiếm đều có chung độ rộng 60vw;
        this.dialogPrime.isComponent = true
        this.dialogPrime.widthPanel = this.isMobileScreen ? '90vw' : '60vw';
        this.dialogPrime.heightPanel = this.isMobileScreen ? '70vh' : '90vh';
        if (!this.dataSource.LAYOUT_CONFIG || this.dataSource.LAYOUT_CONFIG.rows.length === 0) {
            // Sửa đổi lúc 15h15p ngày 07/03/2023 theo yêu cầu của anh Vinh, set độ rộng dialog theo column layout
            let vw = this.dataSource.INFORMATION.LAYOUT_COLUMN * 20;
            if (vw >= 100) {
                vw = 100;
            }

            if (vw !== 0 && !this.isMobileScreen) {
                this.dialogPrime.widthPanel = vw + 'vw';
            }
        }
        const dialogReturn: CoreFormComponent = this.dialogPrime.showDialog(
            CoreFormComponent,
            configForm,
            this.dataSource.INFORMATION.DESCR,
            'Insert'
        );
        dialogReturn.modeAttachment = 'add';
        const infor = this.dataSource.INFORMATION;
        dialogReturn.ngOnChanges(configForm as any);
        dialogReturn.initValueWhenInsert(infor);
        dialogReturn.dataEmit.subscribe((res) => {
            if (res !== null) {
                // Có dữ liệu
                const params: any = this.getParams(dialogReturn);

                // Trường hợp 1 - nhiều
                if (infor.IS_TAB_TRUNG_GIAN || infor.BANG_LK === undefined || infor.BANG_LK === null) {
                    if (infor.PARENT_DATA) {
                        params[infor.TRUONG_LK_CON] = infor.PARENT_DATA[infor.TRUONG_LK_CHA];
                    } else {
                        // this.appService.alert('Chưa chọn dữ liệu cho tab cha!', 'error');
                        // return;
                    }
                }

                if (infor.IS_TAB_TRUNG_GIAN) { // Insert bảng trung gian
                    // => lấy thêm cả id của tab ông
                    const parentTab: any = this.coreWindowService.findParentTab(infor.TAB_ID);
                    params[infor.TRUONG_LKTG_CON] = parentTab.INFORMATION.PARENT_DATA[parentTab.INFORMATION.TRUONG_LK_CHA];
                }
                const arr_fieldimg = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldtype === 'image');
                if (arr_fieldimg && arr_fieldimg.length > 0) {
                    const arr_file = dialogReturn.arrFileImage
                    const arr_req: any[] = []
                    const arr_field: any[] = []
                    if (arr_file.length > 0) {
                        arr_file.forEach((obj_f: any) => {
                            if (obj_f.file) {
                                arr_field.push(obj_f.fieldname)
                                arr_req.push(
                                    this.uploadFile(obj_f.file, infor)
                                )
                            }
                        })
                        combineLatest(arr_req).subscribe((res: any) => {
                            res.forEach((item: any, ind_f: any) => {
                                const url_file = (item.model as string)
                                const urlImage = (this.urlProxy + this.appService.urlOdataAttachment + '/' + url_file).replace(/\\/g, '/');
                                const field_name = arr_field[ind_f]
                                params[field_name] = urlImage

                            })
                            this.insertData(params, infor, dialogReturn)
                        }, err => {
                            this.appService.createMessage('error', 'Upload files error')
                            return;
                        })

                    } else {
                        this.insertData(params, infor, dialogReturn);
                    }
                } else {
                    this.insertData(params, infor, dialogReturn);
                }


            } else {
                // đóng dialog
                this.dialogPrime.closeDialog();
            }
        });
    }

    insertData(params: any, infor: any, dialogReturn: any) {
        this.reqService.service
            .insert({
                url: infor.URL_EDIT,
                data: params,
                primaryKey: infor.KHOA_CHINH
            })
            .subscribe(
                (val: InsertResponse) => {
                    if (val.success === false) {
                        const message = val.message && val.message !== '' ? val.message : this.appService.getMessage('0002');
                        this.appService.notification(
                            message,
                            'error'
                        );
                        return;
                    }

                    if (this.reqService.type === 'clouddata' && val.features && val.features[0]) {
                        if (val.features[0].id !== '') {
                            val.features[0].objectId = val.features[0].id;
                        } else {
                            val.features[0].objectId = params[infor.KHOA_CHINH];
                        }
                    }
                    // update nếu là domain
                    // tslint:disable-next-line:radix
                    // const ketqua = this.savedomainservice.saveDomainFixNew(this.dataSource.INFORMATION.TABLE_ID, Number.parseInt(this.appService.objStoreAppId.appId));
                    // ketqua.subscribe(kq => {
                    //     if (kq) { }
                    // });
                    this.savedomainservice.autoSaveDomainTable(this.dataSource.INFORMATION.TABLE_ID, this.appService.ClientId)

                    const a = this.pageChangeFinish.subscribe(() => {
                        if (this.totalRecord === 1) {
                            this.currentData = [this.resultList[0]];
                            this.bindDataToForm = this.resultList[0];
                            this.eventEmit.emit({
                                type: 'rowClick',
                                value: this.currentData[0],
                                isLock: this.checkLockRecord(this.currentData[0])
                            });
                        }
                        a.unsubscribe();
                    });


                    // setTimeout(() => {
                    //     // if (infor.TAB_LEVEL === '0' && infor.SEQ_NO === '1') {
                    //     //     // trường hợp thêm mới ở tab to nhất => đóng form add
                    //     //     this.dialogPrime.closeDialog();
                    //     // } else {
                    //     //     // Nếu không thì chỉ reset
                    //     //     dialogReturn.btnResetClick();
                    //     //     dialogReturn.initLayout();
                    //     // }

                    //     // Nếu không thì chỉ reset
                    //     dialogReturn.btnResetClick();
                    //     dialogReturn.initLayout();
                    //     dialogReturn.initValueWhenInsert(this.dataSource.INFORMATION);
                    // }, 1000);
                    // this.afterInsert(val);
                    // this.emitEventReloadParentTab();

                    // // do something if have onchange
                    // if (this.dataSource.INFORMATION.ONCHANGE) {
                    //     // this.eventEmit.emit({
                    //     //     type: 'on-change-in-table',
                    //     //     value: { val, params, dataSource: this.dataSource, currentComponent: this }
                    //     // })
                    //     this.OnChangeAfterInsert(val.features[0], { val, params, dataSource: this.dataSource, currentComponent: this })
                    // }

                    //Attachemnt cũ
                    const saveEvent = dialogReturn.saveAttachmentEmit.subscribe((res: any) => {
                        setTimeout(() => {
                            // if (infor.TAB_LEVEL === '0' && infor.SEQ_NO === '1') {
                            //     // trường hợp thêm mới ở tab to nhất => đóng form add
                            //     this.dialogPrime.closeDialog();
                            // } else {
                            //     // Nếu không thì chỉ reset
                            //     dialogReturn.btnResetClick();
                            //     dialogReturn.initLayout();
                            // }

                            // Nếu không thì chỉ reset
                            dialogReturn.btnResetClick();
                            dialogReturn.initLayout();
                            dialogReturn.initValueWhenInsert(this.dataSource.INFORMATION);
                        }, 1000);
                        this.afterInsert(val);
                        this.emitEventReloadParentTab();

                        // do something if have onchange
                        if (this.dataSource.INFORMATION.ONCHANGE) {
                            // this.eventEmit.emit({
                            //     type: 'on-change-in-table',
                            //     value: { val, params, dataSource: this.dataSource, currentComponent: this }
                            // })
                            this.OnChangeAfterInsert(val.features[0], { val, params, dataSource: this.dataSource, currentComponent: this })
                        }
                        saveEvent.unsubscribe();
                    });

                    // emit event trước khi đóng panel
                    console.log(val)
                    if (val.features != null) {
                        dialogReturn.insertAttachment(val.features[0]);
                    } else {
                        dialogReturn.insertAttachment(null);
                    }
                    //


                    this.eventAddCalendar.emit({ primaryKey: infor.KHOA_CHINH, recordId: val.features[0].objectId, tableId: this.dataSource.INFORMATION.TABLE_ID }); // emit event insert for calendar
                },
                (err) => {
                    this.appService.notification(
                        this.appService.getMessage('0002'),
                        'error'
                    );
                }
            );
    }
    queryWindow(WindowId: any) {
        return this.reqServiceSQL.service.search({
            url: this.appService.urlWS + '/SysWindows',
            where: ['WindowId', '=', WindowId]
        })
    }
    queryPluginFile(WindowId: any) {
        this.reqService.switchType(this.appService.dataAccessType);
        const urlRequest = this.appService.urlWS + '/Attachs';
        const appId = this.appService.c$['appId'];
        const where = [
            ['TableId', '=', 57],
            ['RecordId', '=', WindowId],
            ['ClientId', '=', this.appService.ClientId],
            ['ApplicationId', '=', appId],
        ];
        return this.reqService.service.search({
            url: urlRequest,
            where
        })
    }
    executeWindowStore(data_window: any, data_insert: any) {
        this.appService.confirm('Excute this store procedure?').subscribe((res: any) => {
            if (res) {
                let str = ''
                let str_exec = ''
                const schema = JSON.parse(this.appService.currentUser).schema;
                if (data_window.ExtType) {
                    const arrParams = JSON.parse(data_window.ExtType)
                    const data = data_insert
                    arrParams.forEach((item: any) => {
                        const field = item.substring(1, item.length)
                        const val = typeof (data[field]) === 'number' ? data[field] : "'" + data[field] + "'"
                        str += item + ' = ' + val + ','
                    })
                    str = str.substring(0, str.length - 1)
                }
                str_exec = str !== '' ? 'EXEC ' + schema + '.' + data_window.ComponentName + ' ' + str + ';' : 'EXEC ' + schema + '.' + data_window.ComponentName + ';'
                this.loading = true
                this.windowService.queryDichVu([['ClientId', '=', JSON.parse(this.appService.currentUser).clientid], ['ServiceType', '=', 'CloudData'], ['ServiceName', '=', 'CloudService']], 'SysServices').subscribe((res: any) => {
                    if (res.success && res.features.length > 0) {
                        let data: any = res.features[0].UrlEdit;
                        this.execProcedure(schema, { command: str_exec }, data).subscribe((res: any) => {
                            if (res.success) {
                                this.loading = false
                                this.appService.createMessage('success', 'Execute store procedure success')
                            } else {
                                this.loading = false
                                this.appService.createMessage('error', res.message)
                            }
                        }, err => {
                            this.loading = false
                            this.appService.createMessage('error', err.error.text)
                        })
                    }

                })
            }
        })
    }
    OnChangeAfterInsert(data: any, objEmit: any) {
        const infor = this.dataSource.INFORMATION
        const windowid = infor.ONCHANGE && !isNaN(Number(infor.ONCHANGE)) ? Number(infor.ONCHANGE) : null
        if (windowid) {
            this.queryWindow(windowid).subscribe((res: any) => {
                if (res.success) {
                    const obj = res.features[0]
                    if (obj.WindowType === 'PLUGIN') {
                        this.queryPluginFile(windowid).subscribe(async (resp: any) => {
                            if (resp.success && resp.features.length > 0) {
                                const item = res.features[0];
                                if (item.ComponentName && item.ComponentName != '') {

                                    let urlPlugin = (this.appService.urlProxy + this.appService.urlOdataAttachment + '/' + item.UrlFile).replace(/\\/g, '/');
                                    this.baseUrl = this.appService.urlOdataAttachment + '/' + item.UrlFile
                                    // const filejs = await this.pluginService.loadFile(urlPlugin)
                                    // if (typeof (filejs) === 'function') {
                                    //     const obj = filejs.call(this, this, { data: [data], parentData: this.dataSource.INFORMATION.PARENT_DATA })
                                    // } else {
                                    //     const obj = filejs.run(this, { data: [data], parentData: this.dataSource.INFORMATION.PARENT_DATA })
                                    // }
                                    this.pluginService.loadFileScript(urlPlugin).then((p: any) => {
                                        window['c$'].plugin = p
                                        p.run(this, { data: this.currentData, parentData: this.dataSource.INFORMATION.PARENT_DATA })
                                    })
                                } else {
                                    this.appService.createMessage('success', "Selector hasn't been selected")
                                }

                            }
                        })
                    } else if (obj.WindowType === 'STORE') {
                        this.executeWindowStore(obj, data)
                    } else {
                        objEmit['windowName'] = obj.ComponentName;
                        this.eventEmit.emit({
                            type: 'on-change-in-table',
                            value: objEmit,
                            isLock: false
                        })
                    }
                }
            })
        } else {
            if (infor.ONCHANGE) {
                this.eventEmit.emit({
                    type: 'on-change-in-table',
                    value: objEmit,
                    isLock: false
                })
            }
        }
    }


    /** Thực hiện sau khi thêm mới bản ghi vào bảng, sẽ phân chia nhiều trường hợp vì mqh 1 - nhiều và nhiều - nhiều */
    private afterInsert(val: InsertResponse) {
        const infor = this.dataSource.INFORMATION;

        if (infor.IS_TAB_TRUNG_GIAN) {
            if (val.success) {
                this.appService.notification(
                    this.appService.getMessage('0001'),
                    'success'
                );
                this.queryData({ where: this._where });
            } else {
                this.appService.notification(
                    this.appService.getMessage('0002'),
                    'error'
                );
            }
            return;
        }

        if (infor.BANG_LK !== null) {
            // Phải kiểm tra TRUONG_LK_CON khi viết thường có là objectid hay không, nếu có thì là service của arcgis
            // => Service Insert của arcgis luôn trả về key là objectId nên phải bắt điều kiện để lấy đúng giá trị khóa chính
            const newPK = val.features[0].hasOwnProperty('objectId') ? val.features[0].objectId : val.features[0][infor.TRUONG_LK_CON];
            // Trường hợp nhiều nhiều, và không phải là tab To nhất (lv: 0, seq_no: 1)
            const params: any = {};
            const whereClause: any[] = [];
            params[infor.TRUONG_LKTG_CON] = newPK;
            const pData = infor.PARENT_DATA;
            if (pData) {
                params[infor.TRUONG_LKTG_CHA] = pData[infor.TRUONG_LK_CHA];
                whereClause.push([infor.TRUONG_LKTG_CHA, '=', pData[infor.TRUONG_LK_CHA]]);
            }

            // bỏ đoạn DBQUYEN theo ý anh Vinh, vì ngày xưa có thể thêm option query vào bảng trung gian dựa trên trường whereclause
            // if (infor.WHERE_CLAUSE && infor !== '') {
            //     const a = eval(infor.WHERE_CLAUSE);
            //     whereClause.push(a);
            // }

            if (whereClause.length > 0) {
                this.changeTypeRequest()
                this.reqService.service.search({ url: infor.BANG_LK, where: whereClause }).subscribe((res: SearchResponse) => {
                    const result = res.features;
                    const listDataRequest = [];
                    if (result.length > 0) {
                        // đã có bản ghi
                        const arrayCheck = result.filter(fil => fil[infor.TRUONG_LKTG_CON] === newPK);
                        // Nếu arrayCheck không có kết quả => chưa có liên hệ giữa bản ghi này và tab cha => tạo 1 liên kết giữa cha và con
                        if (arrayCheck.length === 0) {
                            // let newArray = result.map((item) => {
                            //     if (item[infor.TRUONG_LKTG_CON] !== null) {
                            //         delete item[infor.TRUONG_LKTG_CON];
                            //     }
                            //     return item;
                            // });

                            // newArray = this.removeDuplicateObjInArray(newArray);
                            // newArray.forEach((item) => {
                            //     const type = item[infor.TRUONG_LKTG_CON] === undefined ? 'insert' : 'update';
                            //     item[infor.TRUONG_LKTG_CON] = newPK;
                            //     const p = {
                            //         url: infor.BANG_LK,
                            //         data: item,
                            //         primaryKey: infor.KHOA_CHINH_BANG_LK,
                            //     };
                            //     if (type === 'insert') {
                            //         // Kiểu insert thì xóa khóa chính
                            //         delete p.data[infor.KHOA_CHINH_BANG_LK];
                            //         listDataRequest.push(this.reqService.service.insert(p));
                            //     } else {
                            //         listDataRequest.push(this.reqService.service.update(p));
                            //     }
                            // });

                            const data: any = {}
                            data[infor.TRUONG_LKTG_CON] = newPK;
                            data[infor.TRUONG_LKTG_CHA] = pData[infor.TRUONG_LK_CHA];
                            const p = {
                                url: infor.BANG_LK,
                                data,
                                primaryKey: infor.KHOA_CHINH_BANG_LK,
                            };
                            listDataRequest.push(this.reqService.service.insert(p));
                        } else {
                            // Đã có liên hệ giữa 2 bản ghi này => hiện tại bỏ trống, không làm gì
                        }
                    } else {
                        // trường hợp tạo mới hoàn toàn
                        listDataRequest.push(
                            this.reqService.service.insert({
                                url: infor.BANG_LK,
                                data: params,
                                primaryKey: infor.KHOA_CHINH_BANG_LK,
                            })
                        );
                    }

                    combineLatest(listDataRequest).subscribe((resp) => {
                        if (this._where === null) {
                            this._where = [
                                [
                                    infor.KHOA_CHINH,
                                    'in',
                                    [newPK],
                                ],
                            ];
                        } else {
                            const where = this._where[0];
                            where[2].push(newPK);
                        }

                        this.appService.notification(
                            this.appService.getMessage('0001'),
                            'success'
                        );
                        this.queryData({ where: this._where });
                    });
                });
            } else {
                // Không cập nhật bảng trung gian vì chưa chọn dữ liệu ở tab cha
                this.appService.notification(
                    this.appService.getMessage('0001'),
                    'success'
                );
                this.queryData({ where: this._where });
            }
        } else {
            if (val.success) {
                this.appService.notification(
                    this.appService.getMessage('0001'),
                    'success'
                );
                this.queryData({ where: this._where });
            } else {
                this.appService.notification(
                    this.appService.getMessage('0002'),
                    'error'
                );
            }
        }
    }

    public onExitConfirmDialog() {
        this.confirmService.close();
    }

    public async onApplyConfirmDialog() {
        if (this.radioCtrl.value === '2' || this.radioCtrl.value.CODE === '2') {
            // Trường hợp 1: xóa dữ liệu
            // Bước thực hiện: xóa bản ghi -> sau đó xóa (hoặc sửa) bản ghi trung gian có liên kết tới bản ghi vừa xóa (là trường hợp 2)

            const deleteRes = await this.reqService.service.delete({
                url: this.dataSource.INFORMATION.URL_EDIT,
                data: this.currentData && this.currentData.length > 0 ? this.currentData[0] : this.currentData,
                primaryKey: this.dataSource.INFORMATION
                    .KHOA_CHINH,
            }).pipe(take(1)).toPromise();

            if (!deleteRes.success) {
                this.loading = false;
                this.appService.notification(
                    this.appService.getMessage('0004'),
                    'error'
                );
            }
        }

        // Trường hợp 2: xóa liên kết

        // trường hợp 2.1 - có bảng lk trung gian thì xóa ở bảng lk trung gian
        const infor = this.dataSource.INFORMATION;
        const pData = infor.PARENT_DATA;

        if (infor.BANG_LK !== null && !infor.IS_TAB_TRUNG_GIAN) {
            // Lấy điều kiện để query bảng trung gian
            const whereClause: any[] = ['and'];
            whereClause.push([infor.TRUONG_LKTG_CHA, '=', pData[infor.TRUONG_LK_CHA]]);
            this.changeTypeRequest()

            this.reqService.service.search({
                url: infor.BANG_LK,
                where: whereClause,
                logic: 'and',
            }).subscribe((res) => {
                const deleteList = res.features.filter((fil) => {
                    let count = 0;
                    this.currentData.forEach((item: any) => { // kiểm tra danh sách bản ghi được chọn
                        if (fil[infor.TRUONG_LKTG_CON] === item[infor.TRUONG_LK_CON]) {
                            count++
                        }
                    });

                    return count !== 0;
                });
                const listRequest: any[] = [];
                deleteList.forEach((item) => {
                    item[infor.TRUONG_LKTG_CON] = null;
                    listRequest.push(
                        this.reqService.service.delete({
                            url: infor.BANG_LK,
                            data: item,
                            primaryKey: infor.KHOA_CHINH_BANG_LK,
                        })
                    );
                });

                const dataKeyReturn: any[] = [];
                this.currentData.forEach((item: any) => {
                    dataKeyReturn.push(item[infor.TRUONG_LK_CON]);
                })

                combineLatest(listRequest).subscribe((resp) => {
                    const response: DeleteResponse = {
                        data: dataKeyReturn,
                        success: true,
                        message: 'Xóa liên kết thành công',
                    };
                    this.confirmService.close();
                    this.deleteSuccess(response);
                }, (err) => {
                    this.loading = false;
                    this.appService.notification('Xóa không thành công!', 'error');
                }
                );
            });
        } else {
            // trường hợp 2.2 - update lại trường liên kết thành null
            const info = this.dataSource.INFORMATION;
            const keyNotSend: any[] = ['_D', '_DT', '_V', '_C', '_N', '_S', '__color_code_', '__stt', info.KHOA_CHINH, info.TRUONG_LK_CON];
            const listRequest: any[] = [];
            this.currentData.forEach((data: any) => {
                const dataSend: any = {};
                // Đặt khóa chính lên đầu vì nó phải như thế mới chạy autodata
                dataSend[info.KHOA_CHINH] = data[info.KHOA_CHINH];
                // Ghi đè trường liên kết
                dataSend[info.TRUONG_LK_CON] = null;
                // remove các trường thừa
                Object.keys(data).forEach(key => {
                    // nếu key không có trong danh sách không gửi thì add vào dataSend
                    if (!(keyNotSend.some(v => key.includes(v)))) {
                        dataSend[key] = data[key];
                    }
                });
                listRequest.push(
                    this.reqService.service.update({
                        url: this._urlEdit,
                        primaryKey: this.primaryKey,
                        data: dataSend,
                    })
                );
            });

            combineLatest(listRequest).subscribe(response => {
                this.setCurrentData(-1);
                this.onReloadTable();
                this.confirmService.close();
                this.appService.notification(this.appService.getMessage('0003'), 'success');
            }, err => {
                this.setCurrentData(-1);
                this.onReloadTable();
                this.confirmService.close();
                this.appService.notification('Xóa không thành công!', 'error');
            });
        }
    }

    private getWhereClause(formControl: FormGroup, fieldList: any[]) {
        const p: any = {
            logic: 'and',
            where: [],
        };
        Object.keys(formControl.controls).forEach((key) => {
            // tslint:disable-next-line:max-line-length
            const data = fieldList.filter((fil) => fil.FIELD_NAME === key);
            if (data.length > 0) {
                // Phân loại kiểu control để lấy đúng dữ liệu
                const value = formControl.controls[key].value;
                if (data[0].FIELD_TYPE === 'date' || data[0].FIELD_TYPE === 'datetime') {
                    if (value && value[0]) {
                        if (!value[1]) {
                            value[1] = value[0];
                        }
                        if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                            // kiểu date của arcgis hơi khác
                            const date0 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[0], 'first')}'`;
                            const date1 = `DATE '${this.reqService.formatDateTo_YYYYMMDD_HHMISS(value[1], 'last')}'`;
                            // date0 sẽ tìm kiếm từ 00h00p00s, date1 sẽ tìm kiếm từ 23h59p59s của ngày
                            p.where.push([key, '>=', date0]);
                            p.where.push([key, '<=', date1]);
                        } else {
                            const date0 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[0], 'first');
                            const date1 = this.reqService.formatDateTo_YYYYMMDD_HHMISS_SQL(value[1], 'last');
                            p.where.push([key, '>=', date0]);
                            p.where.push([key, '<=', date1]);
                        }
                    }
                } else if (data[0].FIELD_TYPE === 'select') {
                    if (value !== null) {
                        p.where.push([key, '=', value]);
                    }
                } else if (data[0].FIELD_TYPE === 'number') {
                    if (value !== '' && value !== null) {
                        p.where.push([key, '=', value]);
                    }
                } else if (data[0].FIELD_TYPE === 'search') {
                    if (value !== '' && value !== null) {
                        // p.where.push([key, '=', value[key]]);
                        p.where.push([key, '=', value]); // đổi giá trị vì dữ liệu trả ra bây giờ không còn là object nữa
                    }
                } else {
                    if (value !== '' && value !== null && value !== undefined) {
                        p.where.push([key, 'like', value]);
                    }
                }
            }
        });
        return p;
    }

    public queryByGeometry(params: any) {
        this.geometry = params.graphic.clone().geometry;
        this.queryData({
            where: [],
            geometry: this.geometry
        });
    }

    /** Mở dialog hiển thị form tìm kiếm */
    private onSearch() {
        const configForm = {
            currentTab: this.dataSource,
            isMapApp: this.isMapApp
        };
        let isTabCha = false;
        if (this.dataSource.INFORMATION.TAB_LEVEL === '0' && this.dataSource.INFORMATION.SEQ_NO === '1') {
            isTabCha = true;
        }
        this.dialogPrime.isComponent = true
        this.dialogPrime.widthPanel = '60vw';
        if (!this.dataSource.LAYOUT_CONFIG || this.dataSource.LAYOUT_CONFIG.rows.length === 0) {
            let vw = this.dataSource.INFORMATION.LAYOUT_COLUMN * 20;
            if (vw >= 100) {
                vw = 100;
            }
            if (vw !== 0) {
                this.dialogPrime.widthPanel = vw + 'vw';
            }
        }

        const dialogReturn: SearchDataComponent = this.dialogPrime.showDialog(
            SearchDataComponent,
            configForm,
            this.dataSource.INFORMATION.DESCR,
            'Search'
        );

        // dialogReturn.typeService = this.dataSource.INFORMATION.SERVICE_TYPE;
        dialogReturn.dataSearchAdv.subscribe((res) => {
            if (res.typeButton === 'btnCancel') {
                this.dialogPrime.closeDialog();
            } else if (res.typeButton === 'btnSearch') {
                const p = this.getWhereClause(
                    res.formControl,
                    res.fieldList
                );
                // Bắt đầu tìm từ bản ghi đầu tiên
                this.pageIndex = 1;
                this.preventChangePage = true;
                if (this.paginator) {
                    this.paginator.changePage(0);
                }

                // Xử lý việc tìm kiếm có thêm điều kiện của tab cha
                if (isTabCha) {
                    this._where = p.where.length === 0 ? [] : p.where;
                } else {
                    // if (p.where.length === 0) {
                    //     this._where = this.whereBeforeSearch;
                    // } else {
                    //     if (this._where && this._where.length > 0) {
                    //         if (this.whereBeforeSearch && this.whereBeforeSearch.length > 0) {
                    //             this._where = ['and', this.whereBeforeSearch, p.where];
                    //         } else {
                    //             this._where = ['and', p.where];
                    //         }
                    //     } else {
                    //         this._where = p.where;
                    //     }
                    // }
                    if (p.where.length === 0) {
                        this._where = [];
                    } else {
                        this._where = p.where;
                    }
                }

                this.resetFilterDistinct();

                this.queryData({
                    where: this._where,
                    isPageChange: false,
                    logic: p.logic,
                    geometry: res.geometry
                });
            } else if (res.typeButton === 'btnSearchAdvanced2') {
                // const where: any[] = [];
                // this._where = where.concat(res.where);
                // Xử lý việc tìm kiếm có thêm điều kiện của tab cha
                if (this._where && this._where.length > 0) {
                    this._where = ['and', this.whereBeforeSearch, res.where];
                } else {
                    this._where = res.where;
                }

                this.resetFilterDistinct();

                // Bắt đầu tìm từ bản ghi đầu tiên
                this.pageIndex = 1;
                this.preventChangePage = true;
                this.paginator.changePage(0);
                this.queryData({
                    where: this._where,
                    isPageChange: false,
                    geometry: res.geometry
                });
            } else if (res.typeButton === 'btnSearchMix') {
                // Xử lý việc tìm kiếm có thêm điều kiện của tab cha
                if (this._where && this._where.length > 0) {
                    this._where = ['and', this.whereBeforeSearch, res.where];
                } else {
                    this._where = res.where;
                }

                this.resetFilterDistinct();

                // Bắt đầu tìm từ bản ghi đầu tiên
                this.pageIndex = 1;
                this.preventChangePage = true;
                this.paginator.changePage(0);
                this.queryData({
                    where: this._where,
                    isPageChange: false,
                    logic: res.logic,
                    geometry: res.geometry
                });
            }
        });

        const handle = this.dialogPrime.onClose.subscribe(() => {
            this.appService.deActiveDraw.emit();
            handle.unsubscribe();
        });
    }

    /** Hàm trả về 1 mảng chứa các bản ghi (object) không trùng lặp nhau */
    private removeDuplicateObjInArray(array: Array<any>) {
        const newArray = array.filter((item, index) => {
            const deepItem = JSON.parse(JSON.stringify(item));
            delete deepItem[this.dataSource.INFORMATION.KHOA_CHINH_BANG_LK];

            const jsonStr = JSON.stringify(deepItem);
            return (
                index ===
                array.findIndex((obj) => {
                    const deepObj = JSON.parse(JSON.stringify(obj));
                    delete deepObj[this.dataSource.INFORMATION.KHOA_CHINH_BANG_LK];
                    return JSON.stringify(deepObj) === jsonStr;
                })
            );
        });
        return newArray;
    }

    private getRecordsUpdate(): Array<any> {
        const list: any[] = [];
        try {
            Object.keys(this.editCache).forEach((key) => {
                if (this.editCache[key].editForUpdate) {
                    list.push(this.editCache[key].data);
                }
            });
        } catch (error) { }

        return list;
    }

    public getParams(formControl: CoreFormComponent) {
        // Gộp chung 2 nhóm có trường có group và không có group với nhau, có thể gộp vì cùng 1 kiểu cấu trúc

        const objAttr: any = {};
        if (formControl.typeOfFormControl === 'default') {
            let groupAll: any[] = [];
            groupAll = groupAll.concat(formControl.fieldNoGroup);
            Object.keys(formControl.fieldGroup).forEach((key) => {
                groupAll = groupAll.concat(formControl.fieldGroup[key]);
            });
            groupAll.forEach((item) => {
                if (item.FIELD_NAME) {
                    const a = this.getEachParam(formControl, item);
                    if (a !== undefined) {
                        objAttr[item.FIELD_NAME] = a;
                    }
                }
            });
        } else {
            this.dequyParams(formControl.fieldNoGroup, objAttr, formControl);
        }

        const list: any[] = this.dataSource.FIELD_LIST;
        let filterfield = null;
        list.forEach(field => {
            if (field.fieldname === this.dataSource.INFORMATION.FILTER_FIELD) {
                filterfield = field;
            }
            if (objAttr[field.fieldname] === undefined && field.defaultvalue) {
                let val = null;
                if (typeof (field.defaultvalue) === 'string' && field.defaultvalue.startsWith('c$')) {
                    val = eval(field.defaultvalue);
                } else {
                    val = field.defaultvalue;
                }

                // Dành riêng 1 đoạn cho field date
                if (field.fieldtype === 'date' || field.fieldtype === 'datetime') {
                    if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                        val = val ? val.getTime() : null;
                    } else {
                        val = new Date(val);
                        if (val instanceof Date) {
                            if (field.columntype === 'date' || field.columntype === 'datetime') {
                                const year = val.getFullYear();
                                const month = val.getMonth();
                                const date = val.getDate();
                                const hour = val.getHours();
                                const minute = val.getMinutes();
                                const second = val.getSeconds();

                                const dateUTC = Date.UTC(year, month, date, hour, minute, second);
                                val = new Date(dateUTC);
                            } else {
                                val = val !== null ? new Date().getTime() : null;
                            }
                        }
                    }
                }

                objAttr[field.fieldname] = val;
            }
        });

        // Cái này là gì nhỉ??? cần kiểm tra lại sau khi cập nhật Multi filter fields
        // if (this.dataSource.INFORMATION.FILTER_FIELD && filterfield && !filterfield.defaultvalue) {
        //     let val = this.filterControl.value;
        //     val = val !== null && val.CODE ? val.CODE : val;
        //     objAttr[filterfield.fieldname] = val;
        // }

        return objAttr;
    }

    private dequyParams(rows: any[], objAttr: any, formControl: any) {
        rows.forEach(row => {
            row.forEach((col: any) => {
                if (col.rows) {
                    this.dequyParams(col.rows, objAttr, formControl);
                } else {
                    if (col.FIELD_NAME) {
                        const a = this.getEachParam(formControl, col);
                        if (a !== undefined) {
                            objAttr[col.FIELD_NAME] = a;
                        }
                    }
                }
            });
        });
    }

    private getEachParam(formControl: any, col: any) {
        // Thêm điều kiện nếu field isreadonly === 'Y' thì nghĩa là không thêm trường đó vào insert/update
        if (formControl.formGroup.controls[col.FIELD_NAME] && col.FIELD_READ_ONLY !== 'Y') {
            if (col.NOT_SEND) {
                return undefined;
            }
            const value = formControl.formGroup.controls[col.FIELD_NAME].value;
            let data = null;
            if (col.FIELD_TYPE === 'select') {
                data = value
                    ? value.CODE !== null && value.CODE !== undefined
                        ? value.CODE
                        : value
                    : null;
            } else if (col.FIELD_TYPE === 'search') {
                // data = value
                //     ? value[col.FIELD_FILTER.COLUMNKEY]
                //     : null;
                data = value
            } else if (col.FIELD_TYPE === 'date' || col.FIELD_TYPE === 'datetime') {
                data = value ? new Date(value) : null;
                // Nếu là arcgis => lấy timestamp
                if (this.reqService.type === 'arcgis' || this.reqService.type === 'arcgis3x') {
                    data = data ? data.getTime() : null;
                } else {
                    if (data instanceof Date) {
                        const columntypes = formControl.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === col.FIELD_NAME);
                        let columntype = null;
                        if (columntypes.length > 0) {
                            columntype = columntypes[0].columntype;
                        }

                        if (columntype === 'date' || columntype === 'datetime') {
                            const year = data.getFullYear();
                            const month = data.getMonth();
                            const date = data.getDate();
                            const hour = data.getHours();
                            const minute = data.getMinutes();
                            const second = data.getSeconds();

                            const dateUTC = Date.UTC(year, month, date, hour, minute, second);
                            data = new Date(dateUTC);
                        } else {
                            data = data !== null ? new Date().getTime() : null;
                        }

                    }
                }
            } else if (col.FIELD_TYPE === 'checkbox') {
                data = value;
            } else {
                data = value;
            }

            return data;
        } else {
            if (col.FIELD_READ_ONLY !== 'Y') {
                return col.FIELD_DEFAULT_VALUE;
            } else {
                // trả ra undefined chứ không phải null để check cho chuẩn (vì đôi khi sửa dữ liệu cũ về null vẫn phải gửi null)
                return undefined;
            }
        }
    }
    onColReorder(evt: any) {
        this.evtReOrder.emit(evt);
    }
    configCol(evt: any, col: any) {
        evt.preventDefault();
        evt.stopPropagation();
        // this.itemRightClick = null;
        if (!this.isCofgColumn) {
            this.isCofgColumn = true;
            this.titleColumn = {
                rowHeader: col.rowHeader,
                rowFieldName: col.rowFieldName
            };
            this.contextPosition.x = this.getPosition(evt).x + 'px';
            this.contextPosition.y = this.getPosition(evt).y + 'px';
            this.initConfCol(col.rowFieldName);
        }
    }
    initConfCol(rowFieldName: string) {
        // const field = this.dataSource.FIELD_LIST.find((v: any) => v.fieldname.toLowerCase() === rowFieldName.toLowerCase());
        // const field = this.arrField.find((v: any) => v.FieldName.toLowerCase() === rowFieldName.toLowerCase());
        // this.cofg.forEach((ele: any) => {
        //     // ele.checked = field[ele.value.toLowerCase()] === 'Y' ? true : false;
        //     ele.checked = field[ele.value] === 'Y' ? true : false;
        // });
        const where = ['TabId', '=', this.dataSource.INFORMATION.TAB_ID];
        this.windowService.getFieldByWhere(where).subscribe(res => {
            if (res.success) {
                this.arrDataField = res.features;
                const field = res.features.find((v: any) => v.FieldName.toLowerCase() === rowFieldName.toLowerCase());
                this.cofg.forEach((ele: any) => {
                    // ele.checked = field[ele.value.toLowerCase()] === 'Y' ? true : false;
                    ele.checked = field[ele.value] === 'Y' ? true : false;
                });
            }
        });
    }
    onCloseConfigColumn() {
        this.isCofgColumn = false;
        this.titleColumn = '';
    }
    onCheckboxCofg(evt: any, item: any) {
        const result = this.arrDataField.find((field: any) => field.FieldName === this.titleColumn.rowFieldName);
        result[item.value] = item.checked ? 'Y' : 'N';
        this.windowService.updateField({ ...result }).subscribe(res => {
            this.evtColConf.emit({
                event: evt,
                item: item,
                titleColumn: this.titleColumn
            });
            this.appService.createMessage('success', this.appService.getMessage('0007'));
        });
    }
    getPosition(el: any) {
        const modalDialog = el.target.closest('.p-dialog-mask');
        const positionModal = modalDialog.children[0].getBoundingClientRect();
        let xPosition = 0;
        let yPosition = 0;

        if (el) {
            xPosition = el.clientX - positionModal.left;
            yPosition = el.clientY - positionModal.top;
        }
        return {
            x: xPosition,
            y: yPosition
        };
    }

    onColResize(evt: any) {
        this.evtColResize.emit(evt);
    }

    onToolClick(mode: any) {
        if (mode === 'delete-ok') {
            this.onDelete();
        } else if (mode === 'delete-cancel') {
            this.currentData = JSON.parse(this.cache_RowSelected)
        }
    }

    // selectRow(e: any) {
    //     e.stopPropagation();;
    // }
    checkDataDelete() {
        let count = 0
        this.currentData.forEach((item: any) => {
            if (!this.checkLockRecord(item)) {
                count++
            }
        })
        return count === this.currentData.length ? true : false
    }
    /** Xóa bản ghi - nhưng chưa xóa attachments */
    private onDelete(isSaveArchive?: boolean) {
        if (this.currentData.length > 0) {
            if (this.checkDataDelete()) {
                this.appService
                    .confirm(this.appService.getMessage('0009'))
                    .subscribe((conf) => {
                        if (conf) {
                            if (this.canLinkTable) {
                                this.deleteLinkTable()
                                return;
                            }
                            this.loading = true;
                            const arr_fieldimg = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldtype === 'image');
                            if (arr_fieldimg.length > 0) {
                                const arr_delete: any[] = []
                                this.currentData.forEach((item: any) => {
                                    arr_fieldimg.forEach((field_img: any) => {
                                        if (item[field_img.fieldname] && item[field_img.fieldname] !== '') {
                                            const url_attach = item[field_img.fieldname].substr(item[field_img.fieldname].lastIndexOf('AttachmentFiles'), item[field_img.fieldname].length)
                                            arr_delete.push(this.deleteAttachmentFile(url_attach))
                                        }
                                    })
                                })
                                combineLatest(arr_delete).subscribe()
                            }
                            if (this.dataSource.INFORMATION.IS_TAB_TRUNG_GIAN) {
                                this.reqService.service
                                    .delete({
                                        url: this.dataSource.INFORMATION.URL_EDIT,
                                        data: this.currentData && this.currentData.length > 0 ? this.currentData[0] : this.currentData,
                                        primaryKey: this.dataSource.INFORMATION
                                            .KHOA_CHINH,
                                    })
                                    .subscribe(
                                        (res: DeleteResponse) => {
                                            if (isSaveArchive) {
                                                this.currentData.forEach((item: any) => {
                                                    this.saveArchive(item[this.primaryKey], 'DELETE', item).subscribe()
                                                })

                                            }
                                            this.deleteSuccess(res);
                                        },
                                        (err) => {
                                            this.loading = false;
                                            this.appService.notification(
                                                this.appService.getMessage('0004'),
                                                'error'
                                            );
                                        }
                                    );
                                return;
                            }
                            this.savedomainservice.autoSaveDomainTable(this.dataSource.INFORMATION.TABLE_ID, this.appService.ClientId)
                            this.deleteSingleTable(isSaveArchive);
                            // trường hợp bảng đơn
                        }
                    });
            } else {
                this.appService.alert('Have a locked record!', 'warn');
            }

        } else {
            this.appService.alert('Please choose at least one record!', 'warn');
        }
    }

    private async deleteLinkTable() {
        // Trường hợp 1: xóa dữ liệu
        // Bước thực hiện: xóa bản ghi -> sau đó xóa (hoặc sửa) bản ghi trung gian có liên kết tới bản ghi vừa xóa (là trường hợp 2)

        const deleteRes = await this.reqService.service.delete({
            url: this.dataSource.INFORMATION.URL_EDIT,
            data: this.currentData && this.currentData.length > 0 ? this.currentData[0] : this.currentData,
            primaryKey: this.dataSource.INFORMATION
                .KHOA_CHINH,
        }).pipe(take(1)).toPromise();

        if (!deleteRes || !deleteRes.success) {
            this.loading = false;
            this.appService.notification(
                this.appService.getMessage('0004'),
                'error'
            );
        } else {
            this.deleteSuccess(deleteRes);
        }
    }

    /** Xóa dữ liệu với bảng đơn */
    private deleteSingleTable(isSaveArchive?: boolean) {
        const list: any[] = [];
        let canDelete = 0, canNotDelete = 0;
        this.currentData.forEach((item: any) => {
            const obj = {
                url: this.dataSource.INFORMATION.URL_EDIT,
                data: item,
                primaryKey: this.dataSource.INFORMATION.KHOA_CHINH
            };

            const checked = this.checkPermisionDelete(obj);

            if (checked) {
                canDelete++;
                list.push(this.reqService.service.delete(obj));
            } else {
                canNotDelete++;
            }
        });

        if (canNotDelete > 0) {
            setTimeout(() => { // Phải sử dụng setTimeout vì đoạn code này đang lồng trong 1 confirm khác => đợi confirm kia tắt đi mới bật được
                let strConfirm = '';
                if (this.translate.currentLang === 'vi') {
                    strConfirm = `Có ${canNotDelete} bản ghi không được quyền xóa, bạn có muốn xóa ${canDelete} bản ghi còn lại không?`;
                } else {
                    const has = canNotDelete === 1 ? 'Have' : 'Has';
                    const records = canNotDelete === 1 ? 'record' : 'records'

                    strConfirm = `${has} ${canNotDelete} ${records} don't have permission delete, do you wan't delete records left?`
                }
                this.appService.confirm(strConfirm).subscribe(res => {
                    if (res) {
                        this.combineToDelete(list, isSaveArchive);
                    }
                })
            }, 700);
        } else {
            this.combineToDelete(list, isSaveArchive);
        }
    }

    private combineToDelete(list: any[], isSaveArchive?: boolean) {
        this.reqService.service.delete({
            url: this.dataSource.INFORMATION.URL_EDIT,
            data: this.currentData && this.currentData.length > 0 ? this.currentData[0] : this.currentData,
            primaryKey: this.dataSource.INFORMATION
                .KHOA_CHINH,
        });

        combineLatest(list).subscribe((res: any) => {
            if (isSaveArchive) {
                this.currentData.forEach((item: any) => {
                    this.saveArchive(item[this.primaryKey], 'DELETE', item).subscribe()
                })

            }
            this.deleteSuccess1(res);

        }, err => {
            // xóa thất bại
            this.appService.notification(
                this.appService.getMessage('0004'),
                'error'
            );
        });
    }

    /** Hàm kiểm tra xem bản ghi có được phép xóa hay không */
    private checkPermisionDelete(obj: any) {
        let valid = true;
        const currentData = obj.data;
        const list = this.appService.appConfig?.FunctionPermistion?.filter(
            (fil: any) => fil.tableid === this.dataSource.INFORMATION.TABLE_ID
        );

        if (list && list.length > 0) {
            list.forEach((item: any) => {
                if (item.functioncode === 'DELETE' && item.displaylogic !== null && item.displaylogic !== undefined && item.displaylogic !== '') {
                    const clauses = JSON.parse(item.displaylogic);

                    let count = 0;
                    clauses.forEach((clause: any) => {
                        const operator = clause[1];
                        const field = this.dataSource.FIELD_LIST.filter((fil: any) => fil.fieldname === clause[0]);
                        if (field.length > 0) {
                            const value = field[0].columntype === 'number' ? Number(clause[2]) : clause[2];
                            switch (operator) {
                                case '<>':
                                    count = currentData[clause[0]] !== value ? count + 1 : count;
                                    break;
                                case '=':
                                    count = currentData[clause[0]] === value ? count + 1 : count;
                                    break;
                                case '>=':
                                    count = currentData[clause[0]] >= value ? count + 1 : count;
                                    break;
                                case '<=':
                                    count = currentData[clause[0]] <= value ? count + 1 : count;
                                    break;
                                case '>':
                                    count = currentData[clause[0]] > value ? count + 1 : count;
                                    break;
                                case '<':
                                    count = currentData[clause[0]] < value ? count + 1 : count;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });

                    if (count === clauses.length) {
                        valid = false;
                    }
                }
            })
        }

        return valid;
    }

    /** Sự kiện xảy ra sau khi thực hiện request delete */
    private deleteSuccess(res: any) {
        this.loading = false;
        if (res.success) {
            // xóa thành công
            // biến lưu trữ giá trị hiện tại sẽ về null
            this.setCurrentData(-1);
            // truy vấn lại dữ liệu theo điều kiện cũ
            if (
                this.totalRecord % this.pageSize === 1 &&
                this.pageIndex > 1 &&
                this.resultList.length === 1
            ) {
                this.pageIndex--;
            }

            if (res.data.length > 0 && this._where !== null) {
                if (this.dataSource.INFORMATION.BANG_LK !== null) {
                    // thay đổi điều kiện where trong trường hợp quan hệ nhiều nhiều
                    this._where = this.getWhereClauseAfterDeleteIntermediate(res.data);
                }
            }

            this.appService.notification(
                this.appService.getMessage('0003'),
                'success'
            );
            this.queryData({
                where: this._where
            });
            this.mode = 'table';
            this.emitEventReloadParentTab();
        } else {
            // xóa thất bại
            this.appService.notification(
                this.appService.getMessage('0004'),
                'error'
            );
        }
    }

    /** Sự kiện xảy ra sau khi thực hiện 1 danh sách request delete */
    private deleteSuccess1(res: DeleteResponse[]) {
        let countSuccess = 0;
        let countError = 0;
        this.currentData = []; // Reset danh sách xóa
        res.forEach(item => {
            if (item.success) {
                countSuccess++;
            } else {
                countError++;
            }
        });

        // biến lưu trữ giá trị hiện tại sẽ về null
        this.setCurrentData(-1);
        // truy vấn lại dữ liệu theo điều kiện cũ
        if (
            this.totalRecord % this.pageSize === 1 &&
            this.pageIndex > 1 &&
            this.resultList.length === 1
        ) {
            this.pageIndex--;
        }

        if (this._where !== null) {
            if (this.dataSource.INFORMATION.BANG_LK !== null) {
                // thay đổi điều kiện where trong trường hợp quan hệ nhiều nhiều
                // this._where = this.getWhereClauseAfterDeleteIntermediate(res.data);
            }
        }

        let string = ''; let type = 'success';
        if (countError === 0) {
            string = this.appService.getMessage('0003');
        } else if (countSuccess === 0) {
            type = 'error';
            string = this.appService.getMessage('0004');
        } else {
            string = `Xóa thành công: ${countSuccess}; Xóa thất bại: ${countError}`;
        }

        this.appService.notification(string, type);
        this.queryData({ where: this._where });
        this.emitEventReloadParentTab();
        // this.mode = 'table';
    }

    /** Lấy lại câu điều kiện where sau khi xóa bảng trung gian */
    private getWhereClauseAfterDeleteIntermediate(list: Array<any>) {
        const where: any[] = this._where;
        if (where !== null) {
            where.forEach(item => {
                if (typeof (item) === 'object') {
                    if (item[1] === 'in') {
                        item[2] = item[2].filter((fil: any) => {
                            return !list.includes(fil);
                        });
                    }
                }
            });
        }
        return where;
    }
    onValueSelectChange(evt: any, data?: any, field?: any, index?: any) {
        const obj = {
            data: data,
            field: field,
            index: index,
            originalEvent: evt
        }
        this.onEditComplete(obj)
        this.isEditComplete = false
    }
    onChangeRowNum(num: any) {
        // console.log(num)
    }
    public isString(str: any) {
        return typeof (str) === 'string';
    }

    onSortClick(col: any) {
        console.log(col)
    }
    //import, export
    arr_columnExcel: any[] = []
    arr_fieldTable: any[] = []
    loadExcelImportDataComplete(file: any) {
        const fileList: any = file.target.files;
        // this.data_import.dataRowHeader = [];
        this.data_import.dataExcel = [];
        this.data_import.arrSheet = [];
        this.data_import.indexSheet = 0;
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const ab: ArrayBuffer = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(ab);

            /* grab first sheet */
            wb.SheetNames.forEach((item: any, index: any) => {
                this.data_import.arrSheet.push({
                    code: index,
                    name: item
                })
                const wsname: string = item;
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                const data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
                const arr: any[] = [];
                const arrData: any[] = [];
                const arrColumn: any[] = [];
                const arrHeaderExcel: any[] = [];
                this.arr_columnExcel = [];
                if (data.length > 0) {
                    data[0].forEach((col: any, ind: any) => {
                        arrColumn.push({
                            code: ind,
                            name: col
                        })

                    })
                    data.forEach((res: any, ind: any) => {
                        if (res && res.length > 0) {
                            arr.push({
                                code: ind,
                                name: (ind + 1).toString()
                            })

                            const obj: any = {};
                            if (ind > 0) {
                                res.forEach((resp: any, ind_d: any) => {
                                    obj[data[0][ind_d]] = resp;
                                });
                                arrData.push(obj)
                            }
                        }

                    })
                    this.data_import.dataRowHeader = arr;
                    this.data_import.rowHeader = 0;
                    // this.data_import.arrColumn = arrHeaderExcel
                    this.data_import.dataExcel.push({
                        dataImport: arrData,
                        arrColumn: arrColumn,
                        data_file: data
                    })
                }

            })
            this.arr_columnExcel = [...this.data_import.dataExcel[0].arrColumn]
            this.initDataImportData()
            this.openDialogImportData()
        };
        reader.readAsArrayBuffer(fileList[0]);
    }
    initDataImportData() {
        this.arr_fieldTable = []
        this.dataSource.FIELD_LIST.forEach((item: any) => {
            if (item.isdisplay === 'Y') {
                this.arr_fieldTable.push({
                    CODE: item.fieldid,
                    DESCR: item.alias ? item.fieldname + ' (' + item.alias + ')' : item.fieldname,
                    name: item.fieldname,
                    data: item
                })
            }

        })
    }
    openDialogImportData() {
        this.cd.detectChanges()
        this.dialogPrime.isComponent = false
        this.dialogPrime.title = 'Import data to table'
        this.dialogPrime.templateRef = this.importDataFromExcel
        this.dialogPrime.onClose.subscribe(() => {
            this.fileExcelImportData.nativeElement.value = null;
        })
        this.dialogPrime.onShow();
    }
    onImportExcel() {
        this.cd.detectChanges();
        this.fileExcelImportData.nativeElement.click();
    }
    objOptionExport: any = {
        startRecord: 1,
        numRecord: 10
    }
    onExportExcel() {
        this.cd.detectChanges()
        this.objOptionExport = {
            startRecord: ((this.pageIndex - 1) * this.pageSize) + 1,
            numRecord: this.pageSize
        }
        this.dialogPrime.isComponent = false
        this.dialogPrime.title = 'Export data to excel file'
        this.dialogPrime.templateRef = this.exportDataFromExcel
        this.dialogPrime.onClose.subscribe(() => {
            // this.fileExcelImportData.nativeElement.value = null;
        })
        this.dialogPrime.onShow();
    }
    onExportData() {
        if (this.objOptionExport.startRecord && this.objOptionExport.numRecord) {
            this.queryDataForExport()
        } else {
            this.appService.createMessage('warning', this.appService.getMessage('0012'));
        }

    }
    handleRqData: any = null
    queryDataForExport() {
        // const logic = this.logic ?? 'and';
        const logic = 'and'
        const geometry = this.geometry ?? null;

        this.loading = true;
        const startRecord = this.objOptionExport.startRecord - 1;
        const pageSize = this.objOptionExport.numRecord;

        const url = this.dataSource.INFORMATION.URL_VIEW;
        if (!url || url === '') {
            this.appService.alert('Cấu hình đường dẫn dịch vụ bị lỗi, vui lòng kiểm tra lại cấu hình', 'error');
            return;
        }
        let where: any[] = [];
        const defaultWhere: any[] = this.dataSource.INFORMATION.DEFAULT_WHERE;
        if (defaultWhere && defaultWhere.length > 0) {
            where.push(defaultWhere);
        }
        if (this.whereFilter && this.whereFilter.length > 1) {
            where.push(this.whereFilter);
        }
        if (this.whereFilterDistinct) {
            where.push(this.whereFilterDistinct);
        }
        if (this.whereKvhc && this.whereKvhc.length > 1) { // tránh điều kiện 'and' / 'or'
            where.push(this.whereKvhc);
        }

        if (this._where) {
            if (where.length === 0) {
                where = this._where;
            } else {
                if (this._where.length > 0) {
                    where.push(this._where);
                }
            }
        }

        let params: any = { url, where, startRecord, pageSize, logic };

        if (geometry) {
            params['geometry'] = geometry;
        }

        if (this.dataSource.INFORMATION.ORDER_BY !== null) {
            let arr: any;
            try {
                arr = JSON.parse(this.dataSource.INFORMATION.ORDER_BY)
            } catch (error) {
                arr = [this.dataSource.INFORMATION.ORDER_BY]
            }
            params['orderBy'] = arr;
        }

        if (this.handleRqData) {
            this.handleRqData.unsubscribe();
        }
        if (Array.isArray(where[0]) && where[0].length === 0) { // convert [[]] thành [] để query all
            params.where = [];
        }
        // this.tableLoading = true;
        this.loading = true
        this.changeTypeRequest()
        this.handleRqData = this.reqService.service.search(params).subscribe((res: any) => {
            if (res.success) {
                this.loading = false
                if (res.features.length > 0) {
                    const arr_data: any[] = []
                    // const arr_field = this.dataSource.FIELD_LIST.filter((v: any) => v.isdisplaygrid === 'Y')
                    const arr_field = this.dataSource.FIELD_LIST
                    res.features.forEach((item: any) => {
                        let obj: any = {}
                        arr_field.forEach((field: any) => {
                            obj[field.fieldname] = item[field.fieldname]
                        })
                        arr_data.push(obj)
                    })
                    const wb = XLSX.utils.book_new();
                    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(arr_data);
                    const wscols: any[] = [];
                    ws['!cols'] = wscols;
                    XLSX.utils.book_append_sheet(wb, ws, this.dataSource.INFORMATION.TABLE_NAME)
                    XLSX.writeFile(wb, "data.xlsx");
                    this.appService.createMessage('success', 'Export data success');
                } else {
                    this.appService.createMessage('warning', `Don't have data to export`);
                }

            } else {
                this.loading = false
                this.appService.createMessage('error', 'Query data error');
            }
        }, err => {
            this.loading = false
            this.appService.createMessage('error', 'Query data error');
        })
    }
    onchangeSheet(evt: any) {
        if (evt !== null) {
            this.data_import.rowHeader = 0;
            this.arr_columnExcel = [...this.data_import.dataExcel[this.data_import.indexSheet].arrColumn]
            this.arr_fieldTable.forEach((item: any) => {
                item.columnExcel = null
            })
            this.arr_fieldTable = [...this.arr_fieldTable]
        } else {
            // this.data_import.rowHeader = 0;
            this.arr_columnExcel = [];
            this.arr_fieldTable.forEach((item: any) => {
                item.columnExcel = null
            })
            this.arr_fieldTable = [...this.arr_fieldTable]
        }
    }
    onchangeRowHeaderImportData(evt: any) {
        if (evt !== null) {
            const data = this.data_import.dataExcel[this.data_import.indexSheet]
            this.data_import.dataExcel[this.data_import.indexSheet].arrColumn = [];
            data.data_file[evt].forEach((col: any, ind: any) => {
                this.data_import.dataExcel[this.data_import.indexSheet].arrColumn.push({
                    code: ind,
                    name: col
                })

            })
            this.arr_columnExcel = [...this.data_import.dataExcel[this.data_import.indexSheet].arrColumn]
        } else {
            this.arr_fieldTable.forEach((item: any) => {
                item.columnExcel = null
            })
            this.arr_fieldTable = [...this.arr_fieldTable]
            this.arr_columnExcel = [];
        }
    }
    onClickReset(type: any) {
        if (type === 'import') {
            this.arr_columnExcel = [];
            this.data_import = {
                dataExcel: [],
                arrSheet: [],
                indexSheet: 0
            }
            this.dialogPrime.closeDialog();
        } else {
            this.dialogPrime.closeDialog()
        }

    }
    buildParamImportData() {
        const arr: any[] = [];
        const arr_data = this.data_import.dataExcel[this.data_import.indexSheet].data_file;
        arr_data.forEach((res: any, ind: any) => {
            if (res && res.length > 0) {
                if (ind > this.data_import.rowHeader) {
                    const obj: any = {}
                    this.arr_fieldTable.forEach((item: any) => {
                        if (item.columnExcel !== null) {
                            obj[item.name] = res[item.columnExcel] ? res[item.columnExcel] : null
                        } else {
                            obj[item.name] = null
                        }

                    })
                    arr.push(obj)
                }
            }

        })
        return arr
    }
    onImportData() {
        const infor = this.dataSource.INFORMATION
        const arrData = this.buildParamImportData()
        const arr: any[] = []
        this.changeTypeRequest()
        arrData.forEach((item: any) => {
            arr.push(this.reqService.service
                .insert({
                    url: infor.URL_EDIT,
                    data: item,
                    primaryKey: infor.KHOA_CHINH
                }))
        })

        combineLatest(arr).subscribe((resp: any) => {
            let count = 0
            let arrFail: any[] = []
            resp.forEach((item: any, ind: any) => {
                if (item.success) {
                    count++
                } else {
                    arrFail.push(ind)
                }
            })
            if (count === resp.length) {
                this.appService.createMessage('success', 'Import data to table success')
            } else {
                this.appService.createMessage('error', this.translate.instant('Import data to table error at rows: ') + arrFail.join(','))
            }
            this.onReloadTable()
            this.dialogPrime.closeDialog()
        })
    }
    //

    //
    execProcedure(schemaName: any, params: any, url_autodata: any) {
        const url = url_autodata + '/' + 'script/' + schemaName
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.post(url, params, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            let resp: any = {};
            resp.total = res.numberRecords;
            resp.success = res.result;
            resp.features = res.datas ? res.datas : [];
            resp.message = res.message;
            return resp;
            // return res
        }), catchError(this.handleSearchError));
    }
    private handleSearchError(error: any) {
        return throwError(error);
    }
    //

    // Query log
    detailLog: any = null
    currentLog: any = null
    onSelectRowLog(evt: any) {
        this.detailLog = null
        this.currentLog = evt
        if (evt && evt.Detail) {
            const val = JSON.parse(evt.Detail)
            if (Object.keys(val).length > 0) {
                let str: any = ''
                let div = document.createElement('div')
                // const div_t = document.createElement('div')
                // div_t.innerText = this.dataSource.INFORMATION.TABLE_NAME
                // div.append(div_t)
                const div_f = document.createElement('div')
                div_f.innerText = "{"
                div.append(div_f)
                Object.keys(val).forEach((key: any) => {
                    const v = typeof (val[key]) === 'string' ? '"' + val[key] + '"' : val[key]
                    // str += '\n' + '  ' + key + ': ' + v + ','
                    const span = document.createElement('div')
                    span.innerText = key + ': ' + v + ','
                    span.style.paddingLeft = '10px'
                    div.append(span)
                })
                const div_e = document.createElement('div')
                div_e.innerText = "}"
                div.append(div_e)
                // str = str.substring(0, str.length - 1)
                // this.detailLog = '{' + str + '\n}'
                this.detailLog = this.domSanitizer.bypassSecurityTrustHtml(div.outerHTML)
            }
        }
    }
    public queryLog(params: any) {
        let resp: any = null
        const currentUser = JSON.parse(this.appService.currentUser)
        if (currentUser.schema && currentUser.schemadefault !== currentUser.schema) {
            const url = this.appService.urlAutoData + '/' + currentUser.dbname + '/data/' + currentUser.schema + '/SysLog';
            resp = this.reqServiceAutoData.service.search({
                url: url,
                where: params,
                logic: 'and'
            });
        } else {
            resp = this.reqServiceSQL.service.search({
                url: this.appService.urlWS + '/SysLogs',
                where: params,
                logic: 'and'
            });
        }

        return resp;
    }
    //

    @HostListener('keydown.F2', ['$event'])
    private onKeyDownF2(e: KeyboardEvent) {
        e.preventDefault();
        if (!this.permissionList['ADD']) {
            this.onClick('insert');
        }
    }

    @HostListener('keydown.F3', ['$event'])
    private onKeyDownF3(e: KeyboardEvent) {
        e.preventDefault();
        if (!this.permissionList['SEARCH']) {
            this.onClick('search');
        }
    }

    @HostListener('keydown.F4', ['$event'])
    private onKeyDownF4(e: KeyboardEvent) {
        e.preventDefault();
        if (!this.permissionList['EDIT']) {
            this.onClick('update');
        }
    }

    @HostListener('keydown.F5', ['$event'])
    private onKeyDownF5(e: KeyboardEvent) {
        e.preventDefault();
        this.onReloadTable();
    }

    @HostListener('keydown.F6', ['$event'])
    private onKeyDownF6(e: KeyboardEvent) {
        e.preventDefault();
        if (!this.permissionList['EDIT']) {
            // this.onClick('save');
            if (!this.permissionList['ARCHIVE'] && this.dataSource.INFORMATION.ARCHIVE_TYPE && this.dataSource.INFORMATION.ARCHIVE_TYPE === 'auto') {
                this.onClick('saveArchive');
            } else if (!this.permissionList['ARCHIVE'] && this.dataSource.INFORMATION.ARCHIVE_TYPE && this.dataSource.INFORMATION.ARCHIVE_TYPE !== 'auto') {
                this.onClick('save')
            } else {
                this.onClick('save')
            }
        }
    }

    @HostListener('keydown.F7', ['$event'])
    private onKeyDownF7(e: KeyboardEvent) {
        e.preventDefault();
        if (!this.permissionList['LINK']) {
            this.onClick('link-table');
        }
    }

    @HostListener('keydown.F8', ['$event'])
    private onKeyDownF8(e: KeyboardEvent) {
        e.preventDefault();
        if (!this.permissionList['DELETE']) {
            if (!this.permissionList['ARCHIVE'] && this.dataSource.INFORMATION.ARCHIVE_TYPE && this.dataSource.INFORMATION.ARCHIVE_TYPE === 'auto') {
                this.onClick('deleteArchive');
            } else if (!this.permissionList['ARCHIVE'] && this.dataSource.INFORMATION.ARCHIVE_TYPE && this.dataSource.INFORMATION.ARCHIVE_TYPE !== 'auto') {
                this.onClick('delete')
            } else {
                this.onClick('delete')
            }

        }
    }

    @HostListener('keydown.F9', ['$event'])
    private onKeyDownF9(e: KeyboardEvent) {
        e.preventDefault();
        this.onClick('zoom');
    }

    @HostListener('keydown.F10', ['$event'])
    private onKeyDownF10(e: KeyboardEvent) {
        e.preventDefault();
        this.onChangeMode();
    }

    @HostListener('keydown.F11', ['$event'])
    private onKeyDownF11(e: KeyboardEvent) {
        e.preventDefault();
        this.onClick('previous');
    }

    @HostListener('keydown.F12', ['$event'])
    private onKeyDownF12(e: KeyboardEvent) {
        e.preventDefault();
        this.onClick('next');
    }

    @HostListener('click', ['$event'])
    private focusOnRef(e: any) {
        // phải đặt tabIndex để có thể focus vào component, từ đó thực hiện điều hướng bằng các phím F(2-5)
        this.elemRef.nativeElement.tabIndex = 0;
    }
}
