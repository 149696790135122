
import { Injectable } from '@angular/core';
import { AppService } from '../../app-base/app.service';
import { RequestService } from 'app/core/services/request.service';

@Injectable({
    providedIn: 'root'
})
export class DomainService {
    public url = this.appService.urlWS;
    public tableName = 'SysDomain';

    constructor(
        private appService: AppService,
        private reqService: RequestService
    ) { this.reqService.switchType(this.appService.dataAccessType); }

    queryDomain(params:any) {
        const resp = this.reqService.service.search({
            url: this.url + '/SysDomains',
            where: params,
            logic: 'and'
        });
        return resp;
        // const pTable = 'SysRole';
        // return this.connectDbService.searchODATA(this.url, pTable, '');
    }

    public getTableByAppId(appId:any) {
        const params = ['applicationId', '=', appId];
        const resp = this.reqService.service.search({
            url: this.url + '/Svtablebyapplications',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public getTableByAppIdCache(appId:any) {
        const params = [['applicationId', '=', appId], ['isCache', '=', 'Y']];
        const resp = this.reqService.service.search({
            url: this.url + '/Svtablebyapplications',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public getDetailDomain(domainId:any) {
        this.reqService.switchType('sql');
        const params = ['DomainId', '=', domainId];
        const resp = this.reqService.service.search({
            url: this.url + '/SysDomains',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public getQueryDomain(params:any) {
        this.reqService.switchType('sql');
        const resp = this.reqService.service.search({
            url: this.url + '/SysDomains',
            where: params,
        });
        return resp;
    }

    public updateDomain(params:any) {
        const odataParams = {
            url: this.url + '/SysDomains',
            table: this.tableName,
            primaryKey: 'DomainId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
        // const pTable = 'SysApplication';
        // return this.connectDbService.updateODATA(this.url, pTable, key, params);
    }

    public addDomain(params:any) {
        // const pTable = 'SysClient';
        // return this.connectDbService.insertODATA(this.url, pTable, params);
        const odataParams = {
            url: this.url + '/SysDomains',
            table: this.tableName,
            primaryKey: 'DomainId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public deleteDomain(params:any) {
        // return this.connectDbService.deleteODATA(this.url, this.tableName, params);


        const odataParams = {
            url: this.url + '/SysDomains',
            table: 'SysDomain',
            primaryKey: 'DomainId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

}
