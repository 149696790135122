import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectManagerComponent } from './project-manager.component';
import { SharedModule } from 'src/shared/shared.module';
import { ProjectMangerRoutingModule } from './project-manager-routing.module';
import { CoreModule } from 'app/core/core.module';

@NgModule({
  declarations: [
    ProjectManagerComponent
  ],
  imports: [
    CommonModule,
    ProjectMangerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule
  ],
  entryComponents: [ProjectManagerComponent],
  exports: [ProjectManagerComponent],
})
export class ProjectManagerModule { }
