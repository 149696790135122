import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { RequestService } from 'app/core/services/request.service';
import { ReportService } from 'app/reports/services/reports.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-filter-custom-report',
  template: ''
})


export class FilterCustomReportComponent implements OnInit {
  params: any;
  constructor(
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
  }
  async getMenuToolItem(data: any) {
    this.params["urlAutoData"] = window.c$["urlAutoData"];
    this.params["username"] = window.c$["username"];
    const url = this.reportService.getUrlHost('report/filterreport');
    let win: any = window.open(url, '_blank');
    win.PamramsReport = JSON.stringify(this.params);
  }

}
