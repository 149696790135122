import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { AppService } from '../../app-base/app.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, filter, map, timeout } from 'rxjs/operators';
import { RequestService } from 'app/core/services/request.service';
import { async } from '@angular/core/testing';
import { combineLatest, throwError } from 'rxjs';
@Injectable()
export class ApplicationService {
    public url = this.appService.urlWS + '/SysApplications';
    public folderRoot = 'Applications';
    public url1 = this.appService.urlWS;
    private urlProxy = '';
    public fileList: any = []; // Danh sách các file mới thêm
    public clientId: any | null = null;
    public fileName: any | null = null;
    public listAttachment: any = []; // Danh sách toàn bộ các file hiển thị trên layout
    public listDeteleAttachment: any = []; // Danh sách các file xóa
    public checkChange = false;
    public fileUploadParams: any = {};

    private fileMaxSize = 5; // Dung lượng file tối đa được gửi, tính theo đơn vị MB
    private baseList: any = []; // Clone lưu trữ danh sách các file ban đầu của control, dùng để reset
    private baseFileUrl = 'assets/imgs/others/file_base.png';

    @ViewChild('upload', { static: false }) uploadNode!: ElementRef;

    public getWindow(params: any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysWindows',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // List app tree
    public listApplicationTree: any[] = [];
    constructor(
        private appService: AppService,
        private http: HttpClient,
        private reqService: RequestService,
    ) {
        this.reqService.switchType(this.appService.dataAccessType);
        this.urlProxy = this.appService.urlProxy + '?';
    }


    public cloneApp(url: any, params: any) {
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.post(url, params, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            // let resp: any = {};
            // resp.total = res.numberRecords;
            // resp.success = res.result;
            // resp.features = res.datas ? res.datas : [];
            // resp.message = res.message;
            // return resp;
             return res;
        }), catchError(this.handleSearchError));
    }
  
    public executeStoreProcedure(storeName: any, params: any) {
        const url = this.appService.urlAutoData + '/script2/executestore/' + storeName
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.post(url, params, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            let resp: any = {};
            resp.total = res.numberRecords;
            resp.success = res.result;
            resp.features = res.datas ? res.datas : [];
            resp.message = res.message;
            return resp;
            // return res
        }), catchError(this.handleSearchError));
    }


    deleteApp(url: any, params: any) {
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.post(url, params, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            let resp: any = {};
            resp.total = res.numberRecords;
            resp.success = res.result;
            resp.features = res.datas ? res.datas : [];
            resp.message = res.message;
            return resp;
            // return res
        }), catchError(this.handleSearchError));
    }

    public queryTreeApp(_params: any) {
        const ex = null;
        const result = this.reqService.service.query({
            url: this.appService.urlWS + '/Config/GetListConfigApplication',
            params: _params,
            method: 'GET'
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                success: false,
                model: [],
                message: 'Tìm thất bại'
            };
            if (res) {
                resp.success = true;
                resp.model = res;
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(ex!));
        return result;
    }

    public queryConfig(_params: any) {
        const ex = null;
        const result = this.reqService.service.query({
            url: this.appService.urlWS + '/Config/Init',
            params: _params,
            method: 'GET'
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                success: false,
                model: [],
                message: 'Tìm thất bại'
            };
            if (res) {
                resp.success = true;
                resp.model = res;
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(ex!));
        return result;
    }

    public queryApplicationbyId(id: any) {
        const params = [['ApplicationId', '=', id]];
        const resp = this.reqService.service.search({
            url: this.url,
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public queryWindowbyApp(appid: any) {
        const params = [['ApplicationId', '=', appid]];
        const resp = this.reqService.service.search({
            url: this.appService.urlWS +'/Syswindows',
            where: params,
            logic: 'and'
        });
        return resp;
    }
    public querycachebyWindow(windowid: any) {
        const params = [['WindowId', '=', windowid]];
        const resp = this.reqService.service.search({
            url: this.appService.urlWS +'/Syscaches',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // public queryApplicationbyName(name: any) {
    //     const params = [['ApplicationName', '=', name]];
    //     const resp = this.reqService.service.search({
    //         url: this.url,
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }

    public addApplication(params: any) {
        const odataParams = {
            url: this.url,
            primaryKey: 'ApplicationId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    public deleteApplication(params: any) {
        const odataParams = {
            url: this.url,
            primaryKey: 'ApplicationId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    public updateApplication(params: any) {
        const odataParams = {
            url: this.url,
            primaryKey: 'ApplicationId',
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }

    execProcedure(urlExec: any, params: any) {
        const url = urlExec + '/coretech_new/' + 'script/dbo'
        let headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const currentUser = this.appService.currentUser ? JSON.parse(this.appService.currentUser) : null;
        if (currentUser && currentUser.token) {
            headers = headers.set('Authorization', `Bearer ${currentUser.token}`);
        }

        return this.http.post(url, params, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            let resp: any = {};
            resp.total = res.numberRecords;
            resp.success = res.result;
            resp.features = res.datas ? res.datas : [];
            resp.message = res.message;
            return resp;
            // return res
        }), catchError(this.handleSearchError));
    }
    private handleSearchError(error: any) {
        return throwError(error);
    }


    // public updateApplicationFiel(params: any, table: any) {
    //     const odataParams = {
    //         url: this.url + '/' + table,
    //         primaryKey: 'ApplicationId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }


    queryService(params: any) {
        this.reqService.switchType('sql')
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysServices',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    queryLayer(params: any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysLayers',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // queryLayerByService(id: any) {
    //     const params = [['ServiceId', '=', id]];
    //     const resp = this.reqService.service.search({
    //         url: this.appService.urlWS + '/SysLayers',
    //         where: params,
    //         logic: 'and'
    //     });
    //     return resp;
    // }

    // updateLayer(params: any) {
    //     const odataParams = {
    //         url: this.appService.urlWS + '/SysLayers',
    //         primaryKey: 'LayerId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }

    public deleteAppService(id: any) {
        const params = { AppServiceId: id };
        const odataParams = {
            url: this.appService.urlWS + '/SysAppServices',
            primaryKey: 'AppServiceId',
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    // updateAppService(params: any) {
    //     const odataParams = {
    //         url: this.appService.urlWS + '/SysAppServices',
    //         primaryKey: 'AppServiceId',
    //         data: params
    //     };
    //     const resp = this.reqService.service.update(odataParams);
    //     return resp;
    // }



    insertAppService(params: any) {
        const odataParams = {
            url: this.appService.urlWS + '/SysAppServices',
            primaryKey: 'AppServiceId',
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }

    queryAppService(params: any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysAppServices',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    // public postFile(params: any) {
    //     const endpoint = this.appService.urlInit + '/ViwacoAPI/api/Viwaco/UploadImage';
    //     const formData: FormData = new FormData();
    //     formData.append('IMAGE', params.FILE_TO_UPLOAD, params.FILE_TO_UPLOAD.name);
    //     formData.append('APPLICATION_ID', params.APPLICATIONID);
    //     formData.append('APPLICATION_NAME', params.APPLICATIONNAME);
    //     formData.append('NO_ORDER', params.NOORDER);
    //     formData.append('URL_LINK', params.URLLINK);
    //     formData.append('DESCRIPTION', params.DESCRIPTION);
    //     return this.http.post(endpoint, formData).pipe(map((res: any) => res));
    // }

    // public update_postFile(params: any) {
    //     const endpoint = this.appService.urlInit + '/ViwacoAPI/api/Viwaco/Update_UploadImage';
    //     const formData: FormData = new FormData();
    //     formData.append('IMAGE', params.FILE_TO_UPLOAD, params.FILE_TO_UPLOAD.name);
    //     formData.append('APPLICATION_ID', params.APPLICATIONID);
    //     formData.append('APPLICATION_NAME', params.APPLICATIONNAME);
    //     formData.append('NO_ORDER', params.NOORDER);
    //     formData.append('URL_LINK', params.URLLINK);
    //     formData.append('DESCRIPTION', params.DESCRIPTION);
    //     return this.http.post(endpoint, formData).pipe(map((res: any) => res));
    // }

    // // domain
    public getDomainByAppId(clientid: any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysDomains',
            where: ['ClientId', '=', clientid],
            logic: 'and'
        });
        return resp;
    }
    // khoanb ODATA
    public queryDichVu(params: any, tablename: string) {
        const resp = this.reqService.service.search({
            url: this.url1 + '/' + tablename,
            where: params,
            logic: 'and'

        });
        return resp;
    }
    public queryDichVuOrderBy(params: any, tablename: string, order: any = []) {
        // const params = [
        //     ['ClientCode', '=', clientcode]

        // ];
        const resp = this.reqService.service.search({
            url: this.url1 + '/' + tablename,
            where: params,
            logic: 'and',
            orderBy: order
        });
        return resp;
    }
    // ODATA
    public addDichVu(params: any, table: any, key: any) {
        const odataParams = {
            url: this.url1 + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.insert(odataParams);
        return resp;
    }
    // ODATA
    public updateDichVu(params: any, table: any, key: any) {
        const odataParams = {
            url: this.url1 + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.update(odataParams);
        return resp;
    }
    // ODATA
    public deleteDichVu(params: any, table: any, key: any) {

        const odataParams = {
            url: this.url1 + '/' + table,
            primaryKey: key,
            data: params
        };
        const resp = this.reqService.service.delete(odataParams);
        return resp;
    }

    // public async saveImageFile(files: any, folder: any, nameImg: any, paramLogo: any, table: any) {
    //     //  upload image
    //     if (files.length > 0) {
    //         const form = new FormData();
    //         form.append('file', files[0]);
    //         form.append('rootFolder', this.folderRoot);
    //         form.append('subRootFolder', folder);
    //         form.append('nameFile', nameImg);

    //         const req = new HttpRequest('POST', this.appService.urlWS + '/ProccessFile/CreateFolder', form, {
    //         });

    //         await this.http.request(req).pipe(filter(e => e instanceof HttpResponse)).subscribe(
    //             async () => {
    //                 await this.updateApplicationFiel(paramLogo, table).subscribe(s => { });
    //             }, () => {
    //                 // this.msg.error('upload failed.');
    //             }
    //         );
    //     }
    // }


    // // Xử lý file với application
    // /** Kiểm tra xem kiểu dữ liệu có phải ảnh hay không */
    private isImage(type: string): boolean {
        if (type && type.split('/').length > 0) {
            if (type.split('/')[0] === 'image') {
                return true;
            }
        }
        return false;
    }

    // /** Đọc và lưu trữ file từ local vào hàng chờ bộ nhớ của máy */
    public readURL(event: Event) {
        if ((event.target as HTMLInputElement).files) {
            if ((event.target as HTMLInputElement).files![0]) {

                const file = (event.target as HTMLInputElement).files![0];
                const size = file.size / 1024 / 1024; // unit in MB
                if (size < this.fileMaxSize) {
                    this.fileList[0] = file;

                    if (this.uploadNode !== undefined && this.uploadNode !== null) {
                        this.uploadNode.nativeElement.value = '';
                    }
                    this.checkChange = true;

                    const reader = new FileReader();

                    reader.onload = (e: any) => {
                        this.listAttachment.push({
                            id: null,
                            contentType: file.type,
                            size: file.size,
                            name: file.name,
                            url: this.isImage(file.type) ? e.target.result : this.baseFileUrl,
                        });
                        console.log(this.fileList, file);
                    };

                    reader.readAsDataURL(file);
                } else {
                    // this.appService.alert(this.appService.getMessage('0019') + this.fileMaxSize + ' MB!', 'error');
                }
            }
        }
    }

    public uploadFileApplication() {
        const urlRequest = this.appService.urlOdataAttachment + '/odata/ProccessFile/PostFileApplication';
        const listRequest = [];
        var results = null;
        if (this.fileList.length > 0) {
            const form = new FormData();
            form.append('file', this.fileList[0]);
            for (const key in this.fileUploadParams) {
                if (Object.prototype.hasOwnProperty.call(this.fileUploadParams, key)) {
                    form.append(key, this.fileUploadParams[key]);
                }
            }
            results = this.reqService.service.query({
                url: urlRequest,
                params: form,
                method: 'POST',
                contentType: 'unset',
                proxy: this.urlProxy
            })
        }
        return results;
    }

    closeBaseFile(item: any) {
        this.fileList = [];
        this.listAttachment = [];
    }

    // /** Xóa dữ liệu SQL ODATA */
    public deleteSqlOdataAttachment() {
        const list = [];
        list.push(this.listDeteleAttachment);

        if (this.listDeteleAttachment.length > 0) {
            // Sau khi xóa trong dữ liệu trong DB => xóa tiếp dữ liệu trên server
            const urlServer = this.appService.urlOdataAttachment + '/odata/ProccessFile/DeleteAttactment';
            const listServer: any = [];
            this.listDeteleAttachment.forEach((item: any, index: any) => {
                listServer.push(this.reqService.service.delete({
                    url: urlServer + '?url=' + this.listDeteleAttachment[index]
                }));
            });
            combineLatest(listServer).subscribe(resp => {
            });
        } else {
        }
    }
}

export interface SearchResponse {
    model: Array<any>;
    success: boolean;
    message: string;
}
