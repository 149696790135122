import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/app-base/auth.guard';
import { ProjectManagerComponent } from './project-manager.component';

const PROJECTMANGER_ROUTES: Routes = [
  {
      path: '',
      component: ProjectManagerComponent,
      // canActivate: [AuthGuard],
  }
];

@NgModule({
  declarations: [],
  imports: [
    // CommonModule
    RouterModule.forChild(PROJECTMANGER_ROUTES)
  ],
  exports: [RouterModule]
})
export class ProjectMangerRoutingModule { }
