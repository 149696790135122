
/**
 * Set existing vendor modules into SystemJS registry.
 * This way SystemJS won't make HTTP requests to fetch imported modules
 * needed by the dynamicaly loaded Widgets.
 */
declare const SystemJS: any;
import * as angularCore from '@angular/core';
import * as angularCommon from '@angular/common';
import * as angularCommonHttp from '@angular/common/http';
import * as angularForms from '@angular/forms';
import * as angularAnimations from '@angular/animations';
import * as angularPlatformBrowser from '@angular/platform-browser';
import * as angularPlatformBrowserDynamic from '@angular/platform-browser-dynamic';
import * as CoreModule from 'app-core-sdk';
import * as fullcalendar from '@fullcalendar/core'
SystemJS.set('@fullcalendar/core', SystemJS.newModule(fullcalendar));
SystemJS.set('@angular/core', SystemJS.newModule(angularCore));
SystemJS.set('@angular/common', SystemJS.newModule(angularCommon));
SystemJS.set('@angular/common/http', SystemJS.newModule(angularCommonHttp));
SystemJS.set('@angular/forms', SystemJS.newModule(angularForms));
SystemJS.set('@angular/animations', SystemJS.newModule(angularAnimations));
SystemJS.set('@angular/platform-browser', SystemJS.newModule(angularPlatformBrowser));
SystemJS.set('@angular/platform-browser-dynamic', SystemJS.newModule(angularPlatformBrowserDynamic));
SystemJS.set('app-core-sdk', SystemJS.newModule(CoreModule));

SystemJS.config({ meta: { '*': { authorization: true } } });
/** --------- */

export interface WidgetParams {
    widgetContent: string;
    widgetSelector: string;
    widgetRef: any;
    widgetModule?: string;
}

@angularCore.Injectable({
    providedIn: 'root'
})
export class PluginService {
    constructor(
        private compiler: angularCore.Compiler,
        private injector: angularCore.Injector,
        private componentFactoryResolver: angularCore.ComponentFactoryResolver
    ) { }

    public async loadFile(url: any) {
        const module = await SystemJS.import(url);

        return module
        // var js = document.createElement('script');
        // js.type = 'module'
        // js.src = url;

        // js.onload = function () {

        //  }
        // document.head.appendChild(js);
    }
    js_tag: any = null
    async loadFileScript(url: any) {
        // var js = document.createElement('script');
        // js.type = 'module'
        // js.src = url;
        // js.onload = function () {
        //     //    run.call(this)
        //     // window['run']()
        // }
        // document.head.appendChild(js);
        // return js
        // const url_n = './assets/js/jsfile.js'
        return import(url)
        // const str = "import('" + url + "').then(function (P) {P.run(" + JSON.stringify(window['c$']) + "," + JSON.stringify(data) + ");});"
        // // const str = "import('https://cloud.applicationjs.com/?https://cloud.applicationjs.com/AttachmentFiles/54/2228/57/3096/jsfile.js').then(function (P) {P.run(" + JSON.stringify(window['c$']) + "," + JSON.stringify(data) + ");});"
        // this.js_tag = document.createElement('script');
        // this.js_tag.innerHTML = str
        // document.head.appendChild(this.js_tag);
    }
    removeScript() {
        if(this.js_tag) {
            document.head.removeChild(this.js_tag);
        }
        
    }
    public async loadWidgets(params: WidgetParams) {
        const module = await SystemJS.import('data:text/javascript;base64,' + params.widgetContent);
        // compile module
        let component = null;
        let mdKey: any = null;
        Object.keys(module).forEach((key) => {
            mdKey = key;
        });

        const moduleName = module[mdKey].name;
        const moduleFactory = await this.compiler.compileModuleAsync(module[moduleName]);
        const moduleRef = moduleFactory.create(this.injector);
        const componentProvider = moduleRef.injector.get(params.widgetSelector);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentProvider);
        component = params.widgetRef.createComponent(componentFactory);

        return component;
    }

    public async createWidgetByFile(params: WidgetParams) {
        const module = await SystemJS.import(params.widgetContent);
        // compile module
        let component = null;
        let mdKey: any = null;
        Object.keys(module).forEach((key) => {
            mdKey = key;
        });

        if (mdKey === null) {
            return null;
        }

        const moduleFactory = await this.compiler.compileModuleAsync(module[mdKey]);
        // resolve component factory
        const moduleRef = moduleFactory.create(this.injector);
        const componentProvider = moduleRef.injector.get(params.widgetSelector);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentProvider);
        component = params.widgetRef.createComponent(componentFactory);
        return component;
    }
}