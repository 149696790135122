import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WebdatarockService } from 'app/analytics-system/services/webdatarock.service';
import { AppService } from 'app/app-base/app.service';
import { combineLatest } from 'rxjs';
import { FormDataServicesComponent } from './form-data-services/form-data-services.component';
import { listTypeGIS } from './form-data-services/dataImage';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-dialog-data-services',
    templateUrl: './dialog-data-services.component.html',
    styleUrls: ['./dialog-data-services.component.scss']
})
export class DialogDataServicesComponent implements OnInit {
    public arrTable = null;
    public arrTableFilter = null;
    public valueTable = '';
    dataService: any[] = [];
    @Output() closeDialogFormCoreTechData: EventEmitter<any> = new EventEmitter();
    @ViewChild('tableServiceCoreTech', { static: false }) tableServiceCoreTech?: FormDataServicesComponent;
    @Output() dataJSON: EventEmitter<any> = new EventEmitter();

    private _dataCoreTechData = null;
    @Input('dataFormCoreTechData')

    get dataFormCoreTechData(): any {
        return this._dataCoreTechData;
    }

    set dataFormCoreTechData(data: any) {
        this._dataCoreTechData = data;
        this.currentService = 0;
        this.queryDataService();
        // this.queryTableDataSource();
    }

    public _currentApp: any = null;
    @Input('currentApp')
    set currentApp(currentApp: any) {
        this._currentApp = currentApp;
    }

    public dataServiceName: any = null;
    public currentService = 0;
    public datafilterService: any = null;

    clientId = '';

    constructor(
        private appService: AppService,
        private webDatarockService?: WebdatarockService
    ) { }

    ngOnInit(): void {
        const localClient: any = this.appService.currentUser;
        this.clientId = JSON.parse(localClient).clientid;
    }

    async queryDataService() {
        const arrData = [
            {
                serviceId: 0,
                serviceName: 'All'
            }
        ];
        const localClient: any = this.appService.currentUser;
        this.clientId = JSON.parse(localClient).clientid;
        const clientid = this.clientId;
        // this.appService.showLoading();
        const params = {
            pageSize: 200,
            pageNumber: 1,
            ClientId: clientid
        }; this.dataService = [
            {
                "serviceId": 1,
                "serviceName": "Domain table",
                "clientId": clientid,
                "serviceType": "domain",
                "sysTables": [
                    {
                        "tableId": 100000,
                        "tableName": "Domain domain",
                        "alias": "Domain domain",
                        "tableType": "DomainTable",
                        "columnKey": "Id",
                        "hasAttachment": "N",
                        "tableLienKet": null,
                        "isCache": "N",
                        "layerIndex": 0,
                        "layerId": null,
                        "serviceId": 1,
                        "clientId": clientid,
                        "kvhcColumn": "N",
                        "tableDetail": "Table",
                        //   "urlEdit": this.webDatarockService?.tableDomain + "?clientid=" + clientid,
                        //   "urlView": this.raService.tableDomain + "?clientid=" + clientid,
                        "columnDisplay": null,
                        "columnCode": null,
                        "onChange": null,
                        "tableWorkFlowId": null,
                        "jobTypeIds": null,
                        "treeColumn": null,
                        "columnLock": null,
                        "logType": null,
                        "columnWorkflow": null,
                        "columnLinkLayer": null,
                        "userNamePortal": null,
                        "passWordPortal": null,
                        "urlToken": null
                    }
                ]
            }
        ]
        const dataServiceApp = await this.webDatarockService?.GetListConfigApplication(params).pipe(take(1)).toPromise();
        console.log('dataServiceApp', dataServiceApp);
        const findDataServiceApp = dataServiceApp.model.find((dataApp: any) => dataApp.applicationId === this._currentApp);
        if (findDataServiceApp.sysService.length > 0) {

            const dataServiceTable = await this.webDatarockService?.queryTreeServices(params).pipe(take(1)).toPromise();
            findDataServiceApp.sysService.forEach((service: any) => {
                this.dataService.push({
                    "serviceId": service.serviceId,
                    "serviceName": service.serviceName,
                    "serviceType": service.serviceType,
                    "sysTables": [],
                    "clientId": service.clientId
                })
            });
            this.dataService.forEach((service: any) => {
                const findServiceTable = dataServiceTable.model.filter((f: any) => f.serviceId == service.serviceId)

                if (findServiceTable.length > 0) {
                    findServiceTable.forEach((service: any) => {
                        if (service.sysTables.length > 0) {
                            service.sysTables.forEach((table: any) => {
                                table.userNamePortal = service.userNamePortal;
                                table.passWordPortal = service.passWordPortal;
                                table.urlToken = service.urlToken;
                                this.dataService[1].sysTables.push(table)
                            });
                        }
                    });
                }
            });

            this.dataServiceName = [...arrData, ...this.dataService];
            this.datafilterService = this.dataService;
            // this.webDatarockService?.queryTreeServices(params).subscribe((res) => {
            //     if (res.success) {
            //         this.dataService = res.model;
            //         this.dataService.forEach((element: any) => {
            //             if (element.sysTables.length > 0) {
            //                 element.sysTables.forEach((table: any) => {
            //                     table.userNamePortal = element.userNamePortal;
            //                     table.passWordPortal = element.passWordPortal;
            //                     table.urlToken = element.urlToken;
            //                 });
            //             }
            //         });
            //         this.dataServiceName = [...arrData, ...this.dataService];
            //         this.datafilterService = this.dataService;
            //     }
            //     else {
            //         this.dataServiceName = [...arrData, ...this.dataService];
            //         this.datafilterService = this.dataService;
            //     }
            // });
        }
    }

    cancleDataSource() {
        this.closeDialogFormCoreTechData.emit();
    }

    async openDataSource() {
        const event: any = this.tableServiceCoreTech?.currentTable;
        // this.urlNameServiceCoreTech = event.tableName;
        // this.urlAliasServiceCoreTech = event.alias;
        let url = event.urlView;
        let token = '';
        // get du lieu
        if (event.urlToken != null) {
            if (this.appService.appConfig) {
                this.appService.appConfig.Services = [{
                    applicationId: 1,
                    clientId: JSON.parse(sessionStorage[this.appService.hostName1 + 'currentUser']).clientId,
                    hasTileCache: '',
                    isDisplay: null,
                    isIdentify: '',
                    isVisible: '',
                    optionName: null,
                    orderNo: 1,
                    serviceId: 1,
                    serviceName: '',
                    serviceType: '',
                    spatialReference: null,
                    urlEdit: event.urlEdit,
                    urlView: event.urlView,
                    urlToken: event.urlToken,
                    urlVectorTile: "",
                    userNamePortal: event.userNamePortal,
                    passWordPortal: event.passWordPortal,
                }];
                token = await this.appService.getArcGISToken(event.urlToken);
            }
        }

        // if (event.tableType === "AutoData") {
        //   url = url.slice(0, url.length - 8);
        // }
        this.webDatarockService?.getdulieubaocao(url, event.tableType, token !== "" ? token : null).subscribe((res) => {
            if (res.success) {
                if (event.tableType === "AutoData") {
                    const dataJson = [...res.features];
                    this.dataJSON.emit({ dataJson, url, config: this.tableServiceCoreTech?.currentTable, column: [] });
                    this.closeDialogFormCoreTechData.emit();
                } else {
                    this.webDatarockService?.getColumnByTableId(event.tableId).subscribe(resCol => {
                        const newArr: any = [];

                        var arrForeign = resCol.features.filter(fil => fil.ForeignTableId);
                        if (arrForeign.length > 0) {
                            var list: any = [];
                            arrForeign.forEach(col => {
                                list.push(this.webDatarockService?.getTableByTableId(col.ForeignTableId));
                            })
                            combineLatest(list).subscribe((tableForeign: any) => {
                                var listTable: any = [];
                                tableForeign.forEach((table: any) => {
                                    listTable.push(this.webDatarockService?.getdulieubaocao(table.features[0].UrlView, table.features[0].TableType, token));
                                    combineLatest(listTable).subscribe((dataTable: any) => {
                                        console.log(dataTable);
                                    }, (err: any) => {
                                        console.log(err);
                                    });
                                })
                            }, (err: any) => {
                                console.log(err);
                            });
                        }
                        res.features.map(item => {
                            // const json: any = {};
                            // resCol.features.forEach(col => {
                            //   col.CODE = col.ColumnId;
                            //   col.DESCR = col.Alias;
                            //   json[col['Alias']] = item[col['ColumnName']];
                            // });
                            // newArr.push(json)

                            resCol.features.forEach(col => {
                                col.CODE = col.ColumnId;
                                col.DESCR = col.Alias;
                                item[col['Alias']] = item[col['ColumnName']];
                            });
                            newArr.push(item)
                        });
                        const dataJson = [...newArr];
                        this.dataJSON.emit({ dataJson, url, config: this.tableServiceCoreTech?.currentTable, column: resCol.features });
                        this.closeDialogFormCoreTechData.emit();
                    });
                }
            }
        }, err => {
            console.log(err);
        });
    }

    clickEventServiceCoreTech(id: any) {
        this.currentService = id;
        if (id === 0) {
            this.datafilterService = this.dataService;
        } else {
            this.datafilterService = this.dataServiceName.filter((data: any) => data.serviceId === id);
        }
    }

    onKeyChangeDataService(event: any) {
        var arr: any[] = [];
        console.log(this.currentService);
        if (this.currentService === 0) {
            this.dataServiceName.forEach((element: any) => {
                if (element.serviceId !== 0) {
                    const _element: any = { ...element };
                    _element.sysTables = _element.sysTables.filter((tbl: any) => tbl.alias.toUpperCase().indexOf(event.currentTarget.value.toUpperCase()) > -1);
                    if (_element.sysTables.length > 0) {
                        arr.push(_element);
                    }
                }
            });
        } else {
            const _element: any = { ...this.dataServiceName.find((data: any) => data.serviceId === this.currentService) };
            _element.sysTables = _element.sysTables.filter((tbl: any) => tbl.alias.toUpperCase().indexOf(event.currentTarget.value.toUpperCase()) > -1);
            if (_element.sysTables.length > 0) {
                arr.push(_element);
            }
        }
        this.datafilterService = [...arr];
    }
}
