<ng-container *ngIf="hasFormCtrl">
  <div class="list-radio-button" [class.vertical]="orientation === 'vertical'">
    <div *ngFor="let radioItem of listRadio; let i = index" class="p-field-radiobutton">
      <p-radioButton [inputId]="i.toString()" [value]="radioItem" [formControl]="ctrl"></p-radioButton>
      <label [for]="i.toString()" class="label-Class">
        <ng-container *ngIf="showLabel">
          {{radioItem[displayField] | translate}}
        </ng-container>
      </label>
      <ng-container *ngIf="hasButton">
        <button pButton pRipple type="button" icon="pi pi-plus" style="height: 30px;width: 30px; margin-left: 3px;"
          (click)="btnCalcClick.emit()"></button>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!hasFormCtrl">
  <div class="list-radio-button" [class.vertical]="orientation === 'vertical'">
    <div *ngFor="let radioItem of listRadio; let i = index" class="p-field-radiobutton">
      <p-radioButton [inputId]="i.toString()" [value]="radioItem" [(ngModel)]="valueModel"></p-radioButton>
      <label [for]="i.toString()" class="label-Class">
        <ng-container *ngIf="showLabel">
          {{radioItem[displayField] | translate}}
        </ng-container>
      </label>
      <ng-container *ngIf="hasButton">
        <button pButton pRipple type="button" icon="pi pi-plus" style="height: 30px;width: 30px; margin-left: 3px;"
          (click)="btnCalcClick.emit()"></button>
      </ng-container>
    </div>
  </div>
</ng-container>