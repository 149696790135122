import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { toolTableConfig } from 'app/app-base/interface/app-config';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-core-basic-table',
  templateUrl: './core-basic-table.component.html',
  styleUrls: ['./core-basic-table.component.scss']
})
export class CoreBasicTableComponent implements OnInit, AfterContentChecked {
  @Output() selectionRowChange = new EventEmitter();
  @Output() isMutiSelectChange = new EventEmitter();
  @Output() firstIndexChange = new EventEmitter();

  @Output() selectRowEvt = new EventEmitter();
  @Output() unSelectRowEvt = new EventEmitter();
  @Output() editInitEvt = new EventEmitter();
  @Output() editCompleteEvt = new EventEmitter();
  @Output() toolClickEvt = new EventEmitter();
  @Output() dbClickEvt = new EventEmitter();
  @Output() pageChangeEvt = new EventEmitter();

  @Input() fieldColor: any[] = []
  @Input() keyColor: any = 'color_col'
  @Input() displayField: any = 'key';
  @Input() valueKey: any = 'valueKey';
  @Input() descrKey: any = 'descrKey';
  @Input() setWidthField: any = 'width';
  @Input() fieldEditList: any = [];
  @Input() fieldHiddenList: any = [];
  @Input() arrFrozenColumns: any = [];
  @Input() selectionRowMode: any = 'single'
  @Input() hasTool: boolean = true;
  @Input() sortFieldList: any[] = [];
  @Input() filterFieldList: any[] = [];
  @Input() titleField: any = 'title';
  @Input() arrToolCustomize: any[] = []
  @Input() percentField: any = null
  @Input() totalValueField: any = null
  isEditInit = false;
  // isMutiSelect = false;
  _toolConfig: toolTableConfig = {
    isAdd: false,
    isEdit: false,
    isDelete: false,
    isSave: false,
    isSearch: false
  };
  selectionMode: any = 'single'
  @Input()
  get toolConfig() {
    return this._toolConfig;
  }
  set toolConfig(data: any) {
    this.cdr.detectChanges()
    this._toolConfig = data;
  }
  @ViewChild('pTable') pTable!: Table | any;
  countClick = 0;
  _selectMode = null;
  _dataTable: any = [];
  @Input()
  get dataTable() {
    return this._dataTable;
  }
  set dataTable(data: any) {
    this.cdr.detectChanges()
    this._dataTable = data;
    // this.setDataTable(data);
  }
  _columnTable: any = [];
  @Input()
  get columnTable() {
    return this._columnTable;
  }
  set columnTable(data: any) {
    this._columnTable = data;
    // this.setDataTable(data)
  }
  _tableConfig: any = [];
  @Input()
  get tableConfig() {
    return this._tableConfig;
  }
  set tableConfig(config: any) {
    this.cdr.detectChanges()
    this._tableConfig = config;
    this.setTableConfig(config)
  }
  _selectionRow: any;
  @Input()
  get selectionRow() {
    return this._selectionRow;
  }

  set selectionRow(nodeSelected) {
    this._selectionRow = nodeSelected;
    this.selectionRowChange.emit(this._selectionRow);
  }
  _firstIndex: any = 0;
  @Input()
  get firstIndex() {
    return this._firstIndex;
  }

  set firstIndex(index) {
    this.cdr.detectChanges()
    this._firstIndex = index;
    this.firstIndexChange.emit(this._firstIndex);
  }


  _isMutiSelect: any;
  @Input()
  get isMutiSelect() {
    return this._isMutiSelect;
  }

  set isMutiSelect(isMutiSelect: boolean) {
    this.countClick++;
    this.cdr.detectChanges();
    if (isMutiSelect) {
      // this._selectMode = this.pTable.selectionMode;
      this.selectionRow = []
      this.selectionMode = undefined;
    } else {
      this.selectionMode = this.selectionRowMode;
      if (this.selectionRowMode === 'single') {
        this.selectionRow = null;
      } else {
        this.selectionRow = [];
      }

    }
    

    this._isMutiSelect = isMutiSelect;
    setTimeout(() => {
      this.setCssStyle(isMutiSelect);
    }, 100)
    // this.isMutiSelectChange.emit(this._isMutiSelect);
  }
  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  setCssStyle(isMutiSelect: boolean) {
    const frozen = document.querySelectorAll(".td-mutiselect-class")
    frozen.forEach((item: any) => {
      if (isMutiSelect) {
        item.style.left = (parseFloat(item.style.left) + 40) + 'px'
      } else {
        if (this.countClick > 1) {
          item.style.left = (parseFloat(item.style.left) - 40) + 'px'
        }
      }

    })
  }
  setTableConfig(config: any) {
    this.cdr.detectChanges();

    Object.keys(config).forEach((key: any) => {
      this.pTable[key] = config[key]
    })
  }
  setDataTable(data: any) {
    this.cdr.detectChanges();
    // this.pTable.value = [...data];
    this.dataTable.forEach((item: any) => {
      const ind = this.fieldEditList.findIndex((v: any) => v.key === item[this.displayField]);
      if (ind > -1) {
        item.isEdit = true;
        item.typeInput = this.fieldEditList[ind].typeInput
      } else {
        item.isEdit = false;
      }
    })
    // this.dataTable = [...this.dataTable]
  }
  onRowSelect(evt: any) {
    evt.originalEvent.stopPropagation()
    if(evt.type !== 'checkbox') {
      this.selectRowEvt.emit(this.selectionRow)
    }
    
    // this.isMutiSelect = false
  }
  onKeyDown(evt: any) {
    if(evt.keyCode === 39 || evt.keyCode === 37) {
      evt.stopPropagation()
    }
  }
  onRowUnselect(evt: any) {
    evt.originalEvent.stopPropagation()
    this.unSelectRowEvt.emit({
      currentRow: evt.data,
      rows: this.selectionRow
    })
  }
  onEditComplete(evt: any) {
    this.isEditInit = false;
    this.pTable.selectionMode = this.selectionRowMode;
    this.editCompleteEvt.emit(evt);
  }
  onEditInit(evt: any) {
    this.isEditInit = true;
    if (this.selectionRowMode === 'single') {
      this.selectionRow = null;
    } else {
      this.selectionRow = [];
    }

    this.pTable.selectionMode = null;
    this.editInitEvt.emit(evt);
  }
  onHeaderCheckboxToggle(evt: any) {
    
    if(evt.checked) {
      this.selectionRow = this.selectionRow.filter((e:any, ind: any) => ind < this.firstIndex + this.tableConfig.rows)
    }
  }
  onToolClick(mode: any) {
    this.toolClickEvt.emit({
      tool: mode,
      data: this.selectionRow
    })
  }
  checkEdit(item: any) {
    const ind = this.fieldEditList.findIndex((v: any) => v.key === item[this.displayField]);
    return ind
  }
  onDbClick(evt: any, rowData: any) {
    evt.stopPropagation();
    if (!this.isEditInit && !this.isMutiSelect) {
      this.selectionRow = rowData
      this.dbClickEvt.emit(this.selectionRow)
    }

  }
  onPageChange(evt: any) {
    this.pageChangeEvt.emit(evt)
  }
}
