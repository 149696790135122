import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import vi from '@angular/common/locales/vi';
import en from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppBaseModule } from './app-base/app-base.module';
import { ErrorInterceptor } from './app-base/ErrorInterceptor';
import { JwtInterceptor } from './app-base/JwtInterceptor ';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './app-base/TimeoutInterceptor';
import { ClientService } from './um/services/client.service';
import { MessageService } from 'primeng/api';
import { SharedModule } from 'src/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { Calendar1Module } from './calendar/calendar.module';
import { ReportActionService } from './reports/services/report-action.service';
import { BpmnWorkflowService } from './workflow/services/workflow.service';
import { WfService } from './workflow/services/wf-odata.service';
import { ReportCustomerFunctionService } from './reports/services/report-customer-function.service';
import { ReportService } from './reports/services/reports.service';

import { ProjectManagerModule } from './project-manager/project-manager.module';
import { CustomizeModule } from './customize/customize.module';
import { PaymentOnlineComponent } from './payment-online/payment-online.component';
import { PluginFileService } from './plugins/plugin-file.service';
import { WorkflowModule } from './workflow/workflow.module';


registerLocaleData(vi);
registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppBaseModule,
        CoreModule,
        Calendar1Module,
        WorkflowModule,
        HttpClientModule,
        SharedModule,
        TranslateModule,
        ProjectManagerModule,
        // CustomizeModule
    ],
    providers: [
        DecimalPipe,
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 120000 },
        MessageService,
        ClientService,
        ReportActionService,
        BpmnWorkflowService,
        WfService,
        ReportCustomerFunctionService,
        ReportService,
        PluginFileService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
