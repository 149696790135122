import { Injectable, Injector } from '@angular/core';
import { Message } from 'app/app-base/interface/message';
import { RequestService, OdataParams } from 'app/core/services/request.service';
import { Observable, Subject } from 'rxjs';
import { AppService } from '../../app-base/app.service';
import { WfService } from './wf-odata.service';
import { catchError, filter, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { loadModules, loadScript } from 'esri-loader';




@Injectable()
export class BpmnWorkflowService {
    public url = this.appService.urlWorkflow;
    public urlMail = this.appService.urlInit + '/api/Mail/sendMailContent';
    // public url = 'http://localhost/publicCoreTechApi/odataworkflow/';
    protected reqService: RequestService;

    constructor(
        private appService: AppService,
        protected wfService: WfService,
        private http: HttpClient,
        injector: Injector
    ) {
        this.reqService = injector.get(RequestService);
        this.reqService.switchType(this.appService.dataAccessType);
    }

    public initData(windowId:any): Observable<any>{
        const subject: Subject<any> = new Subject();
        const regEx = new RegExp('^[0-9]*$');
        if (regEx.test(windowId)) {
            // Đã có config rồi thì load luôn, không query lại Service nữa
            // if (this.coreWindowService.windowConfig[this.windowId]) {
            //     this.windowConfig = this.coreWindowService.windowConfig[this.windowId];
            //     await this.initWindow();
            // } else {
            const url = `${this.appService.urlWS}/SysCaches/SysCacheWindow/${windowId}`;
            // For mode Winzard
            // if (this.isWizard || this.isLayout) {
            //     url = `${this.appService.urlWS}/SysCaches/GetSysCacheWindowLayout/${this.windowId}`;
            // }
            // const params = ['windowid', '=', Number(this.windowId)];
            const params = {};
            this.reqService.service.query({ url, params }).subscribe(res => {
                if (res.success) {
                    // th.windowConfig = res.model.config ? parse(res.model.config) : null;
                    // this.coreWindowService.windowConfig[this.windowId] = this.windowConfig;
                    // this.initWindow();
                    subject.next(res);
                }
            }, err => {
                subject.next(err);
                console.log(err);
            });
        } else {
            this.appService.alert(Message[4], 'error');
        }
        return subject.asObservable();
    }


    public getDoMainByTable(params:any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/' + 'SysDomains',
            where: params,
            logic: 'and',
        });
        return resp;
    }

    public queryColBPMN(params:any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysColumns',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public querySysWindow(params:any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysWindows',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public querySysDomain(params:any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysDomains',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public querySysTable(params:any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysTables',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public updateSysTable(params:any) {
        const odataParams = {
            url: this.appService.urlWS + '/SysTables',
            primaryKey: 'TableId',
            data: params
        };
        const resp = this.wfService.update(odataParams);
        return resp;
    }

    public querySysField(params:any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysFields',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public querySysUser(params:any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysUsers',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public queryUserBpmn(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfUsers',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addUserBpmn(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfUsers',
            primaryKey: 'Id',
            data: params
        });
        return resp;
    }

    public deleteUserBpmn(params:any) {
        const odataParams = {
            url: this.url + 'WfUsers',
            primaryKey: 'Id',
            data: params
        };
        const resp = this.wfService.delete(odataParams);
        return resp;
    }

    public queryJobtype(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfJobTypes',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public updateJobtype(params:any) {
        const odataParams = {
            url: this.url + 'WfJobTypes',
            primaryKey: 'JobTypeId',
            data: params
        };
        const resp = this.wfService.update(odataParams);
        return resp;
    }

    public addJobType(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfJobTypes',
            primaryKey: 'JobTypeId',
            data: params
        });
        return resp;
    }

    public deleteJobtype(params:any) {
        const odataParams = {
            url: this.url + 'WfJobTypes',
            primaryKey: 'JobTypeId',
            data: params
        };
        const resp = this.wfService.delete(odataParams);
        return resp;
    }



    public queryJobtypeStep(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfJobTypeSteps',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public updateJobtypeStep(params:any) {
        const odataParams = {
            url: this.url + 'WfJobTypeSteps',
            primaryKey: 'JobTypeStepId',
            data: params
        };
        const resp = this.wfService.update(odataParams);
        return resp;
    }

    public deleteJobtypeStep(params:any) {
        const odataParams = {
            url: this.url + 'WfJobTypeSteps',
            primaryKey: 'JobTypeStepId',
            data: params
        };
        const resp = this.wfService.delete(odataParams);
        return resp;
    }

    public addJobtypeStep(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfJobTypeSteps',
            primaryKey: 'JobTypeStepId',
            data: params
        });
        return resp;
    }


    public queryStepXref(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfJobTypeStepXrefs',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public deleteStepXref(params:any) {
        const odataParams = {
            url: this.url + 'WfJobTypeStepXrefs',
            primaryKey: 'JobTypeStepXrefId',
            data: params
        };
        const resp = this.wfService.delete(odataParams);
        return resp;
    }

    public addStepXref(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfJobTypeStepXrefs',
            primaryKey: 'JobTypeStepXrefId',
            data: params
        });
        return resp;
    }


    public queryAssignStatus(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfAssignedStatuses',
            where: params,
            logic: 'and'
        });
        return resp;
    }


    public queryPriority(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfPrioritys',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public queryHistory(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfHistorys',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addHistory(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfHistorys',
            primaryKey: 'HistoryId',
            data: params
        });
        return resp;
    }


    public queryJobtypeUser(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfJobTypeUsers',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addJobtypeUser(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfJobTypeUsers',
            primaryKey: 'JobTypeUserId',
            data: params
        });
        return resp;
    }
    public deleteJobtypeUser(params:any) {
        const odataParams = {
            url: this.url + 'WfJobTypeUsers',
            primaryKey: 'JobTypeUserId',
            data: params
        };
        const resp = this.wfService.delete(odataParams);
        return resp;
    }
    public queryJob(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfJobs',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    public addJob(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfJobs',
            primaryKey: 'JobId',
            data: params
        });
        return resp;
    }


    public updateJob(params:any) {
        const odataParams = {
            url: this.url + 'WfJobs',
            primaryKey: 'JobId',
            data: params
        };
        const resp = this.wfService.update(odataParams);
        return resp;
    }


    public addJobStep(params:any) {
        const resp = this.wfService.insert({
            url: this.url + 'WfJobSteps',
            primaryKey: 'JobStepId',
            data: params
        });
        return resp;
    }

    public queryJobStep(params:any) {
        const resp = this.wfService.search({
            url: this.url + 'WfJobSteps',
            primaryKey: 'JobStepId',
            where: params,
        });
        return resp;
    }

    public updateJobStep(params:any) {
        const resp = this.wfService.update({
            url: this.url + 'WfJobSteps',
            primaryKey: 'JobStepId',
            data: params
        });
        return resp;
    }



    public querySatus() {
        const resp = this.wfService.search({
            url: this.url + 'WfJobStatuses',
            where: [],
            logic: 'and'
        });
        return resp;
    }


    public addNotification(params:any) {
        const resp = this.reqService.service.insert({
            url: this.appService.urlWS + '/SysNotifications',
            primaryKey: 'NotificationId',
            data: params
        });
        return resp;
    }

    querySysTab(params:any){
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysTabs',
            where: params,
            logic: 'and'
        });
        return resp;
    }

    querySysTableClould(params:any, url:any){
        this.reqService.switchType('clouddata')
        const resp = this.reqService.service.search({
            url: url,
            where: params,
            logic: 'and'
        });

        const handle = resp.subscribe(res => {
            this.reqService.switchType(this.appService.dataAccessType);
            handle.unsubscribe();
        })
        return resp;
    }

    queryTableByAplication(params:any){
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/Svtablebyapplications',
            where: params,
            logic: 'and'
        });
        return resp;
    }


    public queryUserRoleByAplication(_params:any) {
        const ex:any = null;
        const result = this.reqService.service.query({
            url: this.appService.urlWS + '/Config/GetUserIdByApplication',
            params: _params,
            method: 'GET'
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res) {
                resp.success = true;
                resp.features = res;
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(ex));
        return result;
    }

    public queryManagerJob(_params:any) {
        const ex:any = null;
        const result = this.reqService.service.query({
            url: this.url + 'WfJobs/GetWfJobsType',
            params: _params,
            method: 'GET'
        }).pipe(map((res: any) => {
            const resp: SearchResponse = {
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res) {
                resp.success = true;
                resp.features = res;
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(ex));
        return result;
    }





    public queryWorkflowId(id:any, table:any, key:any, url = this.url) {
        const p: OdataParams = {
            url: this.url + table,
            where: [key, '=', id], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
            pageSize: 10, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
            startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
            logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
        };
        return this.wfService.search(p);
    }

    queryWorkflow(table:any) {
        const p: OdataParams = {
            url: this.url + table,
            where: [],
            logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
        };
        return this.wfService.search(p);
    }

    insertWorkflow(params:any, table:any, primaryKey:any) {
        const p: OdataParams = {
            url: this.url + table,
            data: params, // Thông số bắt buộc, dữ liệu dạng object để insert vào bảng
            primaryKey // Thông số bắt buộc, khóa chính của bảng
        };

        return this.wfService.insert(p);
    }

    public updateWorkflow(params:any, table:any, primaryKey:any) {
        const p: OdataParams = {
            url: this.url + table,
            data: params, // Thông số bắt buộc, dữ liệu dạng object để sửa vào bảng, trong data phải có trường khóa chính
            primaryKey // Thông số bắt buộc, khóa chính của bảng
        };

        return this.wfService.update(p);
    }

    public deleteWorkflowId(param:any, table:any, primaryKey:any) {
        const p: OdataParams = {
            url: this.url + table, // Thông số bắt buộc, nhưng có thể để string rỗng '',
            data: param, // Thông số bắt buộc, dữ liệu dạng object của một bản ghi, trong data phải có trường khóa chính
            primaryKey // Thông số bắt buộc, khóa chính của bảng
        };

        return this.wfService.delete(p);
    }

    public getValueTable(param:any, urlRequest:any){
        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });
        const paramsToSend = new HttpParams({ fromObject: param });
        return this.http.get(urlRequest, {
            headers,
            params: paramsToSend
        });
    }

    public esriRequest(
        url: string,
        params: any = {},
        type: string = '',
        usePost: boolean = true,
        useProxy: boolean = true
    ): Observable<any> {
        const subject: Subject<any> = new Subject();
        let newUrl = url;

        switch (type) {
            case 'add':
                newUrl += '/addFeatures';
                break;
            case 'delete':
                newUrl += '/deleteFeatures';
                break;
            case 'edit':
                newUrl += '/updateFeatures';
                break;
            case 'query':
                newUrl += '/query';
                break;
            default:
                break;
        }

        loadModules(['esri/request', 'esri/config']).then(
            ([esriRequest, esriConfig]) => {
                esriConfig.defaults.io.proxyUrl = this.appService.urlProxy;
                const reqData = {
                    url: newUrl,
                    // content: JSON.stringify(params),
                    content: params,
                    handleAs: 'json',
                    sync: true,
                    callbackParamName: 'callback',
                };
                console.log('reqData', reqData);
                esriRequest(reqData, { usePost, useProxy }).then(
                    (response:any) => subject.next(response),
                    (error:any) => subject.next(error)
                );
            }
        );

        return subject.asObservable();
    }

    public queryUserById(params: any) {
        const resp = this.reqService.service.search({
            url: this.appService.urlWS + '/SysUsers',
            where: params,
            logic: 'and'
        });
        return resp;
    }
}

export interface SearchResponse {
    features: Array<any>;
    success: boolean;
    message: string;
}

