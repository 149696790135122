<div class="d-flex" [ngClass]="{'NoLabelLeft': !isLabelLeft}"
    style="align-items: center;">
    <div *ngIf="showLabel" class="labelInputClass" [style.width.px]="labelWidth"
        style="min-width: 80px; align-items: center; max-width: 200px;">
        <ng-container *ngIf="notUseTranslate">
            {{labelName || "No title" }}
        </ng-container>
        <ng-container *ngIf="!notUseTranslate">
            {{labelName || "No title" | translate }}
        </ng-container>
    </div>

    <!-- <img style='display:block; width:100px;height:100px;' [src]="qrCodeUrl" /> -->
    <ng-container *ngIf="qrCodeUrl">
        <qrcode #qrCode elementType="img"  [qrdata]="qrCodeUrl" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
    </ng-container>
</div>
