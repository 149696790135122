import { Component, OnDestroy, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { BpmnWorkflowService } from 'app/workflow/services/workflow.service';
import { RunBPMNComponent } from '../run-job/run-bpmn-workflow.component';

@Component({
  selector: 'app-get-job',
  templateUrl: './get-job.component.html',
  styleUrls: ['./get-job.component.scss']
})
export class GetJobComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCloseEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('divGetJob', { static: false, read: ViewContainerRef }) private containerRef!: ViewContainerRef;
  createDynamicEvent: EventEmitter<any> = new EventEmitter();

  currentUser:any = null;
  currentData:any = null;
  currentStepName = null;
  clientId: any;


  constructor(
    private workflowService: BpmnWorkflowService,
    private appService: AppService,
    private componentFactoryResolver: ComponentFactoryResolver,

  ) { }

  ngOnInit(): void {
    this.clientId = this.appService.ClientId;
    if (localStorage) {
      // const User = localStorage.getItem(this.appService.hostName1 + 'currentUser');
      // if (User !== null && User !== undefined) {
      //     this.currentUser = this.appService.convertStringToJson(User).username;
      // }
      this.currentUser = JSON.parse(this.appService.currentUser).username;

  }

  }

  ngOnDestroy(): void {
    console.log('destroy');
    this.onCloseEvent.emit(true);
}

  run(evt:any){
    if (evt.currentData.length > 0){
      this.currentData = evt.currentData[evt.currentData.length -1];
      const urlJob = evt.dataSource.INFORMATION.URL_EDIT;
      if (urlJob !== null && urlJob !== '') {
          let i = urlJob.indexOf('/WFJOBs');
          let url1 = urlJob.substr(0, i);
          this.workflowService.url = url1 + '/';

          this.onAddHistory();
          this.udAssignStatus(this.currentData);
          setTimeout(() => {
            const comp =  this.renderComponent(RunBPMNComponent);
            comp.instance.run(evt);
            if (comp.instance.createDynamicEvent) {
              comp.instance.createDynamicEvent.subscribe((res: any) => {
                  this.createDynamicEvent.emit(res);
              });
          }
          }, 200);
      }
    } else {
        this.onCloseEvent.emit(false);

    }
  }


  udAssignStatus(job:any){
    const params = ['JobId', '=', job.JobId];
    this.workflowService.queryJob(params).subscribe(res => {
      if (res.success) {
        const job = res.features[0];
        job.AssignStatusId = 1;
        job.AssignedTo = this.currentUser;
        this.workflowService.updateJob(job).subscribe(res => {
          console.log(res);
          this.appService.notification('Thực hiện thành công', 'success');
          // this.onCloseEvent.emit(true);
        });
      }
    });
  }

  onAddHistory(){
    const params = ['JobId', '=', this.currentData.JobId];
    this.workflowService.queryJob(params).subscribe(res => {
      if (res.success) {
        const currentJob = res.features[0];
        const paramJob = res.features[0];
        this.workflowService.queryJobStep(params).subscribe((Jt: any) => {
          if (Jt.success && Jt.features.length > 0) {
            const curStep = Jt.features[0].CurStep;
            const paramJobTypeStep = [['StepId', '=', curStep], ['JobTypeId', '=', paramJob.JobTypeId]];
            this.workflowService.queryJobtypeStep(paramJobTypeStep).subscribe(res => {
              if (res.success){
                if (res.features[0]) {
                  this.currentStepName = res.features[0].StepName;
                  this.addHistory(curStep, this.currentStepName);
                }
              }
            });
          }
        });
      }
    });
  }

  addHistory(stepId:any, stepName:any){
    const paramHis = [['JobId', '=', this.currentData.JobId], ['StepId', '=', stepId], ['ActivityId', '=', 2]];
    this.workflowService.queryHistory(paramHis).subscribe(res => {
      if (res.success) {
        const params:any = {
          UpdateTime: this.formatDateHisAdd(new Date()),
          Description: '',
          StepName: stepName,
          AssignedTo: this.currentUser,
          SendFrom: this.currentUser,
          StepId: stepId,
          ActivityId: 1,
          Duration: null,
          JobId: this.currentData.JobId,
          JobTypeId: this.currentData.JobTypeId,
          ClientId: this.clientId
        };
        if (res.features.length > 0) {
          const timeStart = res.features[0].UpdateTime;
          params.Duration = this.getWaitingTime(timeStart);
        }
        this.workflowService.addHistory(params).subscribe(res => {
          console.log(res);
      }, err => {
          this.appService.notification('Đã xảy ra lỗi', 'error');
      });
      }
    });

    // const params = {
    //   UpdateTime: this.formatDateHisAdd(new Date()),
    //   Description: '',
    //   StepName: stepName,
    //   AssignedTo: this.currentUser,
    //   SendFrom: this.currentUser,
    //   StepId: stepId,
    //   ActivityId: 1,
    //   JobId: this.currentData.JobId,
    //   ClientId: this.clientId
    // };
    // this.workflowService.addHistory(params).subscribe(res => {
    //     console.log(res);
    // }, err => {
    //     this.appService.notification('Đã xảy ra lỗi', 'error');
    // });
  }

  getWaitingTime(startDate:any){
    const start = new Date(startDate).getTime();
    const end = new Date().getTime();
    const time = Math.abs(end - start);
    const timeWork = (time / 3600000).toFixed(2);

    return Number(timeWork);
}

  formatDateHisAdd(date:any){
    let dateString:any = '';
    if (date !== '' && date !== null) {
        const newDate = new Date(date);
        dateString += newDate.getFullYear();
        dateString += '-';
        dateString += newDate.getMonth() + 1 > 9 ? (newDate.getMonth() + 1) : '0' + (newDate.getMonth() + 1);
        dateString += '-';
        dateString += newDate.getDate() >= 10 ? newDate.getDate() : '0' + newDate.getDate();
        dateString += ' ';
        dateString += newDate.getHours();
        dateString += ':';
        dateString += newDate.getMinutes();
        dateString += ':';
        dateString += newDate.getSeconds();
    } else {
        dateString = null;
    }
    return dateString;
  }


  private renderComponent(component:any): any {
    this.containerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.containerRef.createComponent(componentFactory);
    return componentRef;
}

}
