// Import Menu component
import { FormWebdatarocksComponent } from 'app/analytics-system/form-webdatarocks/form-webdatarocks.component';
import { CalendarComponent } from 'app/calendar/calendar.component';
import { CoreWindowComponent } from 'app/core/core-window/core-window.component';
import { LetterFormComponent } from '../../customize/quotation/letter-form/letter-form.component';
import { ProcessQuotationComponent } from '../../customize/quotation/process-quotation/process-quotation.component';
import { TrainingPriceCalculationComponent } from '../../customize/quotation/training-price-calculation/training-price-calculation.component';
import { ReportViewerComponent } from 'app/reports/report-viewer/report-viewer.component';
import { AddBPMNComponent } from 'app/workflow/menu-tool/add-job/add-bpmn.component';
import { BackJobComponent } from 'app/workflow/menu-tool/back-job/back-job.component';
import { GetJobComponent } from 'app/workflow/menu-tool/get-job/get-job.component';
import { RecallJobComponent } from 'app/workflow/menu-tool/restore-job/recall-job.component';
import { RunBPMNComponent } from 'app/workflow/menu-tool/run-job/run-bpmn-workflow.component';
import { WorkflowManagerComponent } from 'app/workflow/workflow-manager/workflow-manager.component';
import { TaoHangMucComponent } from 'app/customize/speedmain/tao-hang-muc/tao-hang-muc.component';
import { ThemTaiSanComponent } from 'app/customize/speedmain/them-tai-san/them-tai-san.component';
import { TaoHaoMonComponent } from 'app/customize/speedmain/tao-hao-mon/tao-hao-mon.component';
import { AddSupplierItemsComponent } from 'app/customize/TrackingSystem/add-supplier-items/add-supplier-items.component';
import { DialogPrimeComponent } from 'app/core/dialog-prime/dialog-prime.component';
import { ViewJobComponent } from 'app/workflow/menu-tool/view-job/view-job-workflow.component';

export const functionConfig: FunctionConfig[] = [
    {
        FUNCTION_ID: '1',
        COMPONENT: CoreWindowComponent,
        NAME: 'ConfigWindowComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '2',
        COMPONENT: CoreWindowComponent,
        NAME: 'FormControlComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '22',
        COMPONENT: LetterFormComponent,
        NAME: 'LetterFormComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '30',
        COMPONENT: TaoHangMucComponent,
        NAME: 'TaoHangMucComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '31',
        COMPONENT: ThemTaiSanComponent,
        NAME: 'ThemTaiSanComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '32',
        COMPONENT: AddSupplierItemsComponent,
        NAME: 'AddSupplierItemsComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '33',
        COMPONENT: TaoHaoMonComponent,
        NAME: 'TaoHaoMonComponent',
        IS_OPEN_DIALOG: false
    },
    {
        FUNCTION_ID: '4',
        COMPONENT: TrainingPriceCalculationComponent,
        NAME: 'TrainingPriceCalculationComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '5',
        COMPONENT: ProcessQuotationComponent,
        NAME: 'ProcessQuotationComponent',
        IS_OPEN_DIALOG: true
    },
    // {
    //     FUNCTION_ID: '6',
    //     COMPONENT: AddWorkflowComponent,
    //     NAME: 'AddWorkflowComponent'
    // },
    // {
    //     FUNCTION_ID: '7',
    //     COMPONENT: ArcgisWorkflowComponent,
    //     NAME: 'ArcgisWorkflowComponent'
    // },
    {
        FUNCTION_ID: '8',
        COMPONENT: RunBPMNComponent,
        NAME: 'RunBPMNComponent',
        IS_OPEN_DIALOG: true
    },
    // {
    //     FUNCTION_ID: '9',
    //     COMPONENT: RunArcgisworkflowComponent,
    //     NAME: 'RunArcgisworkflowComponent'
    // },
    // {
    //     FUNCTION_ID: '10',
    //     COMPONENT: ViewArcgisWorkflowComponent,
    //     NAME: 'ViewArcgisWorkflowComponent'
    // },
    // {
    //     FUNCTION_ID: '11',
    //     COMPONENT: CoreSearchComponent,
    //     NAME: 'CoreSearchComponent'
    // },
    {
        FUNCTION_ID: '12',
        COMPONENT: ReportViewerComponent,
        NAME: 'ReportViewerComponent',
        IS_OPEN_DIALOG: true
    },
    // {
    //     FUNCTION_ID: '13',
    //     COMPONENT: CalendarComponent,
    //     NAME: 'CalendarComponent'
    // },
    // {
    //     FUNCTION_ID: '12',
    //     COMPONENT: ReportViewerComponent,
    //     NAME: 'ReportViewerComponent'
    // },
    {
        FUNCTION_ID: '13',
        COMPONENT: CalendarComponent,
        NAME: 'CalendarComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '14',
        COMPONENT: AddBPMNComponent,
        NAME: 'AddBPMNComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '15',
        COMPONENT: ViewJobComponent,
        NAME: 'ViewJobComponent',
        IS_OPEN_DIALOG: true
    },
    // {
    //     FUNCTION_ID: '16',
    //     COMPONENT: WorkflowDesignComponent,
    //     NAME: 'WorkflowDesignComponent'
    // },
    {
        FUNCTION_ID: '17',
        COMPONENT: WorkflowManagerComponent,
        NAME: 'WorkflowManagerComponent',
        IS_OPEN_DIALOG: true
    }
    ,
    {
        FUNCTION_ID: '18',
        COMPONENT: GetJobComponent,
        NAME: 'GetJobComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '19',
        COMPONENT: BackJobComponent,
        NAME: 'BackJobComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '20',
        COMPONENT: FormWebdatarocksComponent,
        NAME: 'FormWebdatarocksComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '21',
        COMPONENT: RecallJobComponent,
        NAME: 'RecallJobComponent',
        IS_OPEN_DIALOG: true
    },
    {
        FUNCTION_ID: '999',
        COMPONENT: DialogPrimeComponent,
        NAME: 'DialogPrimeComponent',
        IS_OPEN_DIALOG: false
    }
];

export interface FunctionConfig {
    FUNCTION_ID: string;
    COMPONENT: any;
    NAME: string;
    IS_OPEN_DIALOG: boolean; // true = mở component ở dialog, false = mở component chạy ngầm (thường là các file chạy mã code request ngầm)
}
