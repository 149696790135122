import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseInterface, DeleteResponse, InsertResponse, OdataParams, QdataParams, SearchResponse, UpdateResponse } from 'app/core/services/request.service';
import { AppService } from 'app/app-base/app.service';
import { cloneDeep } from 'lodash';


@Injectable()
export class WfService implements BaseInterface {
    private urlProxy = '';

    constructor(
        private http: HttpClient,
        private appService: AppService
    ) {
        // this.testDecode();
    }

    /** params có dạng object: { id: 1, name: 'hehe' } */
    query(q: QdataParams): Observable<any> {
        const url = q.url;
        const urlRequest = q.proxy ? `${q.proxy}/${url}` : url;

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        const method = q.method ? q.method : 'GET';

        const paramsToSend = new HttpParams({ fromObject: q.params });

        if (method === 'GET') {
            return this.http.get(urlRequest, {
                headers,
                params: paramsToSend
            });
        } else {
            return this.http.post(urlRequest, q.params, {
                headers,
                params: paramsToSend
            });
        }
    }

    queryCustom(q: QdataParams): Observable<any> {
        return of(true);
    }

    getRecordById(id: any): Observable<any> {
        return of(true);
    }

    /** Tìm kiếm dữ liệu cho ODATA => Đã hoàn thành */
    search(p: OdataParams): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        const val = this.decodeSql1(p.where);
        let convertWhere = val;
        if (p.or) {
            convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        }
        if (p.and) {
            convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        }

        if (p.select) { convertWhere += '&$select=' + p.select; }
        if (p.orderBy) {
            convertWhere += '&$orderby=' + p.orderBy.join(',');
        }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        return this.http.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(map((res:any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(this.handleSearchError));
    }

    searchForMobile(p: OdataParams, token:any): Observable<SearchResponse> {
        const _url = p.url; // this.appService.urlWs;
        const val = this.decodeSql1(p.where);
        let convertWhere = val;
        if (p.or) {
            convertWhere += `(${convertWhere}) or (${this.decodeSql(p.or, 'and')})`;
        }
        if (p.and) {
            convertWhere += `(${convertWhere}) and (${this.decodeSql(p.or, 'or')})`;
        }

        if (p.select) { convertWhere += '&$select=' + p.select; }
        if (p.orderBy) {
            convertWhere += '&$orderby=' + p.orderBy.join(',');
        }
        let url = `${_url}/?$count=true`;

        // Nếu truyền pageNumber và pageSize thì => lazyload, còn không sẽ load toàn bộ
        if (p.pageSize) {
            url += `&$top=${p.pageSize}`;
        }
        if (p.startRecord) {
            url += `&$skip=${p.startRecord}`;
        }

        url = convertWhere !== '' ? `${url}&${convertWhere}` : url;

        return this.http.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(map((res:any) => {
            const resp: SearchResponse = {
                total: 0,
                success: false,
                features: [],
                message: 'Tìm thất bại'
            };
            if (res['value']) {
                resp.total = res['@odata.count'] || res['value'].length;
                resp.success = true;
                resp.features = res['value'];
                resp.message = 'Tìm kiếm thành công';
            }
            return resp;
        }), catchError(this.handleSearchError));
    }

    private handleSearchError(error:any) {
        const rq: SearchResponse = {
            features: [],
            total: 0,
            success: false,
            message: error.message
        };
        return throwError(rq);
    }

    /** Cập nhật dữ liệu cho bảng ghi => Chưa hoàn thành */
    insert(p: OdataParams): Observable<InsertResponse> {
        const url = p.url;
        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
        });

        // không gửi khóa chính cùng giá trị vì service odata sẽ bị lỗi
        const a = p.primaryKey;
        a ? delete p.data[a] : '';

        return this.http.post(url, p.data, { headers }).pipe(map((res: any) => {
            // Cập nhật lại model => In hoa ký tự đầu tiên
            const data:any = {};
            Object.keys(res.model).forEach(key => {
                data[key.charAt(0).toUpperCase() + key.slice(1)] = res.model[key];
            });
            const result: InsertResponse = {
                features: [data],
                success: res.success,
                total: 1,
                message: res.message
            };

            return result;
        }), catchError(this.handleInsertError));
    }

    private handleInsertError(error:any) {
        const rq: InsertResponse = {
            features: [],
            total: 0,
            success: false,
            message: error.message
        };
        return throwError(rq);
    }

    /** Clone từ connect-database và đã tinh chỉnh lại => Đã hoàn thành */
    update(p: OdataParams): Observable<UpdateResponse> {
        const urlRequest = p.url;
        const key = p.primaryKey;
        const url = key ?  `${urlRequest}/${p.data[key]}` : '';

        // delete p.data[key];

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json'
        });

        return this.http.put(url, p.data, { headers }).pipe(map((res:any) => {
            const resp: UpdateResponse = {
                message: res['message'],
                success: res['success'],
                features: res['model']
            };
            return resp;

        }), catchError(this.handleUpdateError));
    }

    private handleUpdateError(error:any) {
        const rq: UpdateResponse = {
            success: false,
            message: error.message
        };
        return throwError(rq);
    }

    /** Clone từ connect-database và đã tinh chỉnh lại => Đã hoàn thành */
    public delete(p: OdataParams): Observable<DeleteResponse> {
        // const params = p.data[p.dataSource.INFORMATION.KHOA_CHINH];
        const a = p.primaryKey;
        const params = a ?  p.data[a] : '';
        const urlRequest = p.url;
        const url = `${urlRequest}/${params}`;

        // const url = `${p.url}/${params}`;

        const headers = new HttpHeaders({
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        });

        return this.http.delete(url, { headers }).pipe(
            map((res: any) => {
                const resp: DeleteResponse = {
                    data: [res.model],
                    success: res.success,
                    message: res.message
                };
                return resp;
            }),
            catchError(err => {
                const rq: DeleteResponse = {
                    data: [],
                    success: false,
                    message: err
                };
                return throwError(rq);
            })
        );
    }

    private handleDeleteError(error:any) {
        const rq: DeleteResponse = {
            data: [],
            success: false,
            message: error
        };
        return throwError(rq);
    }

    private decodeSql(where:any, logic:any) {
        let decode = '';
        if (where) {
            if (where.length > 0) {
                decode += '$filter=';
                if (Array.isArray(where[0])) {
                    // Trường hợp multi
                    where.forEach((item:any, index:any) => {
                        decode = this.decode(item);
                        decode += index < (where.length - 1) && logic ? ` ${logic} ` : '';
                    });
                } else {
                    // Trường hợp where chỉ là dạng [key, operator, value]
                    decode = this.decode(where);
                }
            }
        }
        // if (p.select) { decode += '&select=' + p.select; }
        return decode;
    }

    private decodeSql1(where:any, isBase = true) {
        let decode = ''; let logic = 'and';
        if (where) {
            if (where.length > 0) {
                if (isBase) {
                    decode = '$filter=';
                }
                const cloneWhere = cloneDeep(where);
                if (!Array.isArray(cloneWhere[0]) && cloneWhere[0] !== 'and' && cloneWhere[0] !== 'or') {
                    // Trường hợp mảng 1 chiều
                    decode += this.decode(cloneWhere);
                } else {
                    cloneWhere.forEach((item:any, index:any) => {
                        if (index === 0 && (item === 'and' || item === 'or')) {
                            logic = item;
                        } else {
                            if (Array.isArray(item)) {
                                if (item[0] === 'and' || item[0] === 'or') {
                                    // đệ quy lần nữa để lấy dữ liệu
                                    decode += `(${this.decodeSql1(item, false)})`;
                                } else {
                                    // item là 1 array có dạng [key, operator, value];
                                    decode += this.decode(item);
                                }
                                decode += index < (cloneWhere.length - 1) && logic ? ` ${logic} ` : '';
                            } else {
                                // cloneWhere bây giờ là dạng [key, operator, value], chỉ chạy 1 lần duy nhất với index === 0;
                                if (index === 0) {
                                    decode += this.decode(cloneWhere);
                                }
                            }
                        }
                    });
                }

            }
        }
        return decode;
    }

    private testDecode() {
        const where = ['table', 'in', [1, 2]];
        const where1 = ['or', ['table', 'in', [1, 2]], ['data', 'like', '1234']];
        const where2 = [
            'or',
            [
                'and',
                ['table', '=', 1],
                ['tableName', 'like', 'super']
            ],
            ['data', 'like', '1234']
        ];
        const whereDate = [
            'and',
            ['Day', '>=', new Date()],
            ['Day', '<=', new Date()]
        ];

        const where111 = [
            'and',
            ['SoHieuToBanDo', '=', 36],
            ['MaXa', '=', '20272'],
            ['SoThuTuThua', '=', 136]
        ];
        const where222 = [
            'and',
            ['SoHieuToBanDo', '=', 36],
            ['MaXa', '=', '20272'],
            ['SoThuTuThua', '=', 149]
        ];
        const where333 = [
            'or',
            where111,
            where222
        ];

        const whereCurrentUser = ['username', '=', '$userid'];

        console.log('TEST SQL DECODE WHERE: ', this.decodeSql1(where));
        console.log('TEST SQL DECODE WHERE 1: ', this.decodeSql1(where1));
        console.log('TEST SQL DECODE WHERE 2: ', this.decodeSql1(where2));
        console.log('TEST SQL DECODE WHERE DATE: ', this.decodeSql1(whereDate));
        console.log('TEST SQL DECODE WHERE USERNAME: ', this.decodeSql1(where333));
    }

    private decode(item:any) {
        let str = '';
        // item là 1 array có dạng [key, operator, value];
        let val = item[2];
        let key = item[0];
        const resp = listOperatorSqlOdata.filter(fil => fil.key === item[1]);

        if (typeof (val) === 'string') {
            if (val.startsWith('c$')) {
                val = eval(val);
                if (typeof(val) === 'string') {
                    val = `'${val}'`;
                }
            } else {
                val = this.replaceSpecialCharacters(val);
                const first = val.charAt(0);
                const last = val.charAt(val.length - 1);
                if (first === '\'' && last === '\'') {
                    val = '\'' + eval(val.substring(1, val.length - 1)) + '\'';
                    val = val.toLowerCase();
                    key = `tolower(${key})`;
                } else if (first === '{' && last === '}') {
                    val = eval(val.substring(1, val.length - 1));
                } else if (first === '(' && last === ')') {
                    val = val.substring(1, val.length - 1);
                    val = val.split(',');
                    // key = `tolower(${key})`;
                } else {
                    val = `'${val}'`.toLowerCase();
                    key = `tolower(${key})`;
                }
            }
        } else {
            try {
                // convert to date format
                val = val.toISOString();
            } catch (error) {
            }
        }

        switch (resp[0].value) {
            case 'like':
                str += `contains(${key}, ${val})`;
                break;
            case 'in':
                str += `(${key} in (${val.join(',')}))`;
                break;
            case 'not in':
                str += `not(${key} in (${val.join(',')}))`;
                break;
            default:
                str += `${key} ${resp[0].value} ${val}`;
                break;
        }

        return str;
    }

    private replaceSpecialCharacters(attribute:any) {
    // replace the single quotes
        attribute = attribute.replace(/%/g, '%25');
        attribute = attribute.replace(/\+/g, '%2B');
        attribute = attribute.replace(/\//g, '%2F');
        attribute = attribute.replace(/\?/g, '%3F');

        attribute = attribute.replace(/#/g, '%23');
        attribute = attribute.replace(/&/g, '%26');
        return attribute;
    }
}

export const listOperatorSqlOdata = [
    { key: '=', value: 'eq' },
    { key: '<>', value: 'ne' },
    { key: '>', value: 'gt' },
    { key: '>=', value: 'ge' },
    { key: '<', value: 'lt' },
    { key: '<=', value: 'le' },
    { key: 'like', value: 'like' },
    { key: 'in', value: 'in' },
    { key: '!=', value: 'not eq' },
    { key: '!<>', value: 'not ne' },
    { key: '!>', value: 'not gt' },
    { key: '!>=', value: 'not ge' },
    { key: '!<', value: 'not lt' },
    { key: '!<=', value: 'not le' },
    { key: '!like', value: 'not like' },
    { key: '!in', value: 'not in' }
];
