import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WebdatarockService } from 'app/analytics-system/services/webdatarock.service';
import { AppService } from 'app/app-base/app.service';
import { SelectComponent } from 'app/core/select/select.component';

@Component({
  selector: 'app-dialog-form-save',
  templateUrl: './dialog-form-save.component.html',
  styleUrls: ['./dialog-form-save.component.scss']
})
export class DialogFormSaveComponent implements OnInit {
  // Gán sự kiện khi lưu xong
  @Output() saveSuccess: EventEmitter<any> = new EventEmitter();

  // Gán sự kiện Cancle Form Save
  @Output() closeDialogFormSave: EventEmitter<any> = new EventEmitter();

  // gán sự kiện khi khởi tạo xong formGroup
  public loadSuccess = false;

  // Mảng Data Application Select Form
  public dataApplicationSave: any = [];
  // public dataApplicationSaveAs: any = [];

  // Mảng Data Component Select Form
  public dataComponentSave = [];
  public dataComponentSaveas = [];

  // Khởi tạo biến formGroup
  formGroup: FormGroup = new FormGroup({});

  public jsonWebDatarocks = {};

  private _dataFormSave = '';
  @Input('dataFormSave')
  set dataFormSave(data: any) {
    this._dataFormSave = data.dataForm;
    this.proccessFormSave(data);
  }
  get dataFormSave() {
    return this._dataFormSave;
  }

  public _currentApp: any = null;
  @Input('currentApp')
  set currentApp(currentApp: any) {
    this._currentApp = currentApp;
  }
  get currentApp() {
    return this._currentApp;
  }

  @ViewChild('applicationSelectSave', { static: false })
  applicationSelectSave!: SelectComponent;

  // @ViewChild('componentSelectSave', { static: false })
  // componentSelectSave: SelectComponent;
  clientId = '';

  constructor(
    private appService: AppService,
    private webDatarockService: WebdatarockService,
  ) {
    this.initFormFroup();
    this.dataSelectForm();
  }

  ngOnInit(): void {
    const localClient: any = this.appService.currentUser;
    this.clientId = JSON.parse(localClient).clientid;
  }

  ngOnDestroy(): void {
    console.log('Destroy');
  }

  proccessFormSave(data: any) {
    this._dataFormSave = data;
    if (data.mode === "A") {
      this.formGroup.reset();
      if (this.currentApp) {
        this.formGroup.controls['ApplicationId'].setValue(
          this.currentApp
        );
      }
    }
    if (data.mode === "E") {
      this.bindFormSaveAs(data.dataForm);
    }
  }

  // Gán data Select Form
  dataSelectForm() {
    let application = null;

    if (this.appService.application !== null) {
      application = this.appService.application;
    }
    if (application) {
      application.forEach((app: any) => {
        if (app.isAppSystem !== 'Y') {
          this.dataApplicationSave.push({
            CODE: app.applicationId,
            DESCR: app.applicationName,
          });
        }
      });
    }

    // // Form Save
    // this.dataComponentSave = [...DataComponentApp];

    // // Form Save As
    // this.dataComponentSaveas = [...DataComponentApp];
  }

  private initFormFroup() {
    this.formGroup = new FormGroup({
      WindowName: new FormControl(),
      // Description: new FormControl(),
      ApplicationId: new FormControl(),
      // ComponentName: new FormControl()
    });
    this.loadSuccess = true;
  }

  bindFormSaveAs(data: any) {
    // this.formGroup.controls['WindowName'] = data['WindowName'];
    // const dFilter = this.dataApplicationSave.filter((ds: any) => ds['CODE'].toString() === data['ApplicationId'].toString());
    // if (dFilter.length > 0) {
    //   this.formGroup.controls['WindowName'] = dFilter[0];
    // }
    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.controls[key].setValue(
        data[key]
      );
    });
  }

  paramsAdd() {
    const params: any = {};
    Object.keys(this.formGroup.value).forEach((key) => {
      params[key] = this.formGroup.controls[key].value;
    });
    params['ApplicationId'] = this.applicationSelectSave.ctrl['value'] !== null ? this.applicationSelectSave.ctrl['value'].CODE : '';
    params['ComponentName'] = "FormWebdatarocksComponent";
    params['WindowType'] = "ANALYSTFORM";
    return params;
  }

  paramsUpdate() {
    const params: any = {};
    Object.keys(this.formGroup.value).forEach((key) => {
      params[key] = this.formGroup.controls[key].value;
    });
    params['ComponentName'] = "FormWebdatarocksComponent";
    params['WindowType'] = "ANALYSTFORM";
    params['WindowId'] = this.dataFormSave.dataForm.WindowId;
    params['CacheId'] = this.dataFormSave.dataForm.CacheId;;
    params['Report'] = JSON.stringify(this.dataFormSave.jsonWebDatarocks);
    params['ClientId'] = this.clientId;
    params['ApplicationId'] = this.applicationSelectSave.ctrl['value'] !== null ? this.applicationSelectSave.ctrl['value'].CODE : '';
    return params;
  }

  // Sự kiện lưu
  async EventSave() {
    if (this.dataFormSave.mode === 'A') {
      const params = this.paramsAdd();
      this.webDatarockService.insertSysWindowsWebDatarocks(params).subscribe(res => {
        params['Report'] = JSON.stringify(this.dataFormSave.jsonWebDatarocks);
        params['ClientId'] = this.clientId;
        params['WindowId'] = res.features[0].WindowId;
        this.webDatarockService.insertSysCachesWebDatarocks(params).subscribe(resCache => {
          this.saveSuccess.emit();
        });
      });
    } else {
      const params = this.paramsUpdate();
      this.webDatarockService.updateSysWindowsWebDatarocks(params).subscribe(res => {
        this.webDatarockService.updateSysCachesWebDatarocks(params).subscribe(resCache => {
          this.saveSuccess.emit();
        });
      });
    }
  }

  // Sự kiện thoat
  EventCancel() {
    this.closeDialogFormSave.emit();
  }
}
