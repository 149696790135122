import { Injectable, Input } from "@angular/core";
import { AppService } from "app/app-base/app.service";
import { OdataParams, RequestService } from "app/core/services/request.service";
import { catchError, map } from "rxjs/operators";

@Injectable()

export class ReportActionService {
  public tableDomain: string = this.appService.urlWS + '/' + 'SysDomains/querydomain';
  public url = this.appService.urlWS + '/SvReports';
  public currentApp: any;


  private _jsObject = null;
  get jsObject(): any { return this._jsObject; }
  set jsObject(jsObject: any) { this._jsObject = jsObject; }


  private _appReport = null;
  @Input()
  set appReport(app: any) {
    this._appReport = app;
  }
  get appReport(): any {
    return this._appReport;
  }

  private _appIDforFormSave = null;
  @Input()
  set appIDforFormSave(app: any) {
    this._appIDforFormSave = app;
  }
  get appIDforFormSave(): any {
    return this._appIDforFormSave;
  }


  private _dataServiceCoreTech: any = null;
  @Input('dataServiceCoreTech')
  set dataServiceCoreTech(data: any) {
    this._dataServiceCoreTech = data;
  }

  get dataServiceCoreTech(): any {
    return this._dataServiceCoreTech;
  }

  constructor(
    private appService: AppService,
    private reqService: RequestService
  ) {
    if (localStorage) {

      this.currentApp = this.appService.objStoreAppId ? this.appService.objStoreAppId.appId : undefined;
    }
  }

  queryDomain(params: any) {
    const resp = this.reqService.service.search({
      url: this.appService.urlWS + '/SysDomains',
      where: params,
      logic: 'and'
    });
    return resp;
  }

  queryReport() {
    const p: OdataParams = {
      url: this.url,
      where: [], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 20, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  insertReport(params: any) {
    const p: OdataParams = {
      url: this.url,
      data: params, // Thông số bắt buộc, dữ liệu dạng object để insert vào bảng
      primaryKey: 'ReportId' // Thông số bắt buộc, khóa chính của bảng
    };
    return this.reqService.service.insert(p);
  }

  public updateReport(params: any) {
    const p: OdataParams = {
      url: this.url,
      data: params, // Thông số bắt buộc, dữ liệu dạng object để sửa vào bảng, trong data phải có trường khóa chính
      primaryKey: 'ReportId' // Thông số bắt buộc, khóa chính của bảng
    };
    return this.reqService.service.update(p);
  }

  public queryReportId(id: any) {
    const p: OdataParams = {
      url: this.url,
      where: ['ReportId', '=', id], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 20, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public queryReportApplication(id: any) {
    const p: OdataParams = {
      url: this.url, // Thông số bắt buộc
      where: ['ApplicationId', '=', id], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 20, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public deletelReportId(param: any) {
    const p: OdataParams = {
      url: this.url, // Thông số bắt buộc, nhưng có thể để string rỗng '',
      data: param, // Thông số bắt buộc, dữ liệu dạng object của một bản ghi, trong data phải có trường khóa chính
      primaryKey: 'ReportId' // Thông số bắt buộc, khóa chính của bảng
    };
    return this.reqService.service.delete(p);
  }

  public queryAppService() {
    const p: OdataParams = {
      url: this.appService.urlWS + '/SvApplicationServices', // Thông số bắt buộc, nhưng có thể để string rỗng '',
      where: [], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 2, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }


  public queryAppServiceid(id: any) {
    const p: OdataParams = {
      url: this.appService.urlWS + '/SvApplicationServices', // Thông số bắt buộc, nhưng có thể để string rỗng '',
      where: ['ApplicationId', '=', id], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 20, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public queryReportAll(clientid: any) {
    const p: OdataParams = {
      url: this.appService.urlWS + '/SysWindows', // Thông số bắt buộc
      where: [['WindowType', '=', 'report'], ['ClientId', '=', clientid]], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }


  public queryTableService() {
    const p: OdataParams = {
      url: this.appService.urlInit + '/odataquotation', // Thông số bắt buộc, nhưng có thể để string rỗng '',
      where: [], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public getSysCacheForWindowId(WindowId: any) {
    const p: OdataParams = {
      url: `${this.appService.urlWS}/SysCaches`, // Thông số bắt buộc, nhưng có thể để string rỗng '',
      where: ['WindowId', '=', WindowId], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public getSysCacheForWindowIdForMobile(WindowId: any, token: any) {
    const p: OdataParams = {
      url: `${this.appService.urlWS}/SysCaches`, // Thông số bắt buộc, nhưng có thể để string rỗng '',
      where: ['WindowId', '=', WindowId], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.searchForMobile(p, token);
  }

  public queryReportid(id: any) {
    const url = `${this.appService.urlWS}/SvReports/SvReportOne/${id}`;
    const params = {};
    return this.reqService.service.query({ url, params });
  }

  cloudgetAppByUsername(params: any) {
    return this.reqService.service.query({
      url: this.appService.urlWS + '/Login/cloudgetAppByUsername',
      params,
    }).pipe(map(res => {
      return res;
    }));
  }

  public queryApplicationClient(clientid: any) {
    var url = this.appService.urlWS + '/SysApplications';
    const p: OdataParams = {
      url: url, // Thông số bắt buộc
      where: [['ClientId', '=', clientid], ['IsAppSystem', '<>', "M"], ['IsAppSystem', '<>', "G"], ['IsAppSystem', '<>', "O"]], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      pageSize: 20, // Thông số tùy chọn, Số bản ghi kết quả trả về mỗi lần query,
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public queryTreeServices(params: any) {
    const ex = null;
    const result = this.reqService.service.query({
      url: this.appService.urlWS + '/' + 'Config/GetListConfigService',
      params,
      method: 'GET'
    }).pipe(map((res: any) => {
      const resp: SearchResponse = {
        success: false,
        model: [],
        message: 'Tìm thất bại'
      };
      if (res) {
        resp.success = true;
        resp.model = res;
        resp.message = 'Tìm kiếm thành công';
      }
      return resp;
    }), catchError(ex!));
    return result;
  }

  public GetListConfigApplication(params: any) {
    const ex = null;
    const result = this.reqService.service.query({
      url: this.appService.urlWS + '/' + 'Config/GetListConfigApplication',
      params,
      method: 'GET'
    }).pipe(map((res: any) => {
      const resp: SearchResponse = {
        success: false,
        model: [],
        message: 'Tìm thất bại'
      };
      if (res) {
        resp.success = true;
        resp.model = res;
        resp.message = 'Tìm kiếm thành công';
      }
      return resp;
    }), catchError(ex!));
    return result;
  }

  public queryTreeServicesConfigInit(params: any) {
    const ex = null;
    const result = this.reqService.service.query({
      url: this.appService.urlWS + '/' + 'Config/Init',
      params,
      method: 'GET'
    }).pipe(map((res: any) => {
      const resp: SearchResponse = {
        success: false,
        model: [],
        message: 'Tìm thất bại'
      };
      if (res) {
        resp.success = true;
        resp.model = res;
        resp.message = 'Tìm kiếm thành công';
      }
      return resp;
    }), catchError(ex!));
    return result;
  }

  public queryConfig(_params: any) {
    const ex = null;
    const result = this.reqService.service.query({
      url: this.appService.urlWS + '/Config/Init',
      params: _params,
      method: 'GET'
    }).pipe(map((res: any) => {
      const resp: SearchResponse = {
        success: false,
        model: [],
        message: 'Tìm thất bại'
      };
      if (res) {
        resp.success = true;
        resp.model = res;
        resp.message = 'Tìm kiếm thành công';
      }
      return resp;
    }), catchError(ex!));
    return result;
  }
}


export interface SearchResponse {
  model: Array<any>;
  success: boolean;
  message: string;
}
