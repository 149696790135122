import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/app-base/app.service';
import { ReportService } from 'app/reports/services/reports.service';

@Component({
  selector: 'app-demo-landinfo',
  template: `<stimulsoft-viewer-angular #viewer></stimulsoft-viewer-angular>
  `,
})
export class QuotationReportComponent implements OnInit {
  public params: any = {};
  constructor(
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
  }

  async getMenuToolItem(data: any) {
    const key = data.KHOA_CHINH;
    const QuotationId = data.currentData[key];
    const reportId = data.report.WindowId;
    const url = this.reportService.getUrlHost(`report/view/${key}/${QuotationId}/${reportId}`);
    if (data.isMobile) {
      const mobigis = window['_mobigis'];
      mobigis.emitUrlReport.emit(url);
    } else {
      let ReportCurentData = {
        key: key,
        idKey: QuotationId,
        idReport: reportId
      }
      let win: any = window.open(url, '_blank');
      win.PamramsReport = JSON.stringify(ReportCurentData);
    }
  }
}
