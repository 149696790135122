import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashBoardModule)
    loadChildren: () => import('./dashboard-builder/dashboard-builder.module').then(m => m.DashBoardBuilderModule)
  },
  {
    path: 'example',
    loadChildren: () => import('./example/example.module').then(m => m.ExampleModule)
  },
  {
    path: 'gis',
    loadChildren: () => import('./gis/gis.module').then(m => m.GisModule)
  },
  {
    path: 'non-gis',
    loadChildren: () => import('./non-gis/non-gis.module').then(m => m.NonGisModule)
  },
  {
    path: 'um',
    loadChildren: () => import('./um/um.module').then(m => m.UmModule)
  },
  {
    path: 'autodata',
    loadChildren: () => import('./autodata/autodata.module').then(m => m.AutodataModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'analytics-system',
    loadChildren: () => import('./analytics-system/analytics-system.module').then(m => m.AnalyticsSystemModule)
  },
  {
    path: 'webdatarocks',
    loadChildren: () => import('./analytics-system/form-webdatarocks/form-webdatarocks.module').then(m => m.FormWebdatarocksModule)
  }, {
    path: 'workflow',
    loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule)
  }, {
    path: 'project-manager',
    loadChildren: () => import('./project-manager/project-manager.module').then(m => m.ProjectManagerModule)
  }, {
    path: 'payment',
    loadChildren: () => import('./payment-online/payment-online.module').then(m => m.PaymentOnlineModule)
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
