<div class="div-change">
  <div class="form-ChangePws">
    <form [formGroup]="formGroup" class="formGroup">
      <div class="row user-form-row" style="position: relative;">
        <div class="col-md-12" style="padding: 0;">
          <app-input [isReadOnly]="disabledCtrl" [isFeedBack]="true" type="password" #currentPassword [labelName]="'Current Password' | translate"
            [required]="true" formControlName="CurrentPassword" [maxlength]="50" [isLabelLeft]="false" >
          </app-input>
          <div style="position: absolute; right: 16px;">
          </div>
        </div>

        <div class="col-md-12" style="padding: 0;">
          <app-input [isReadOnly]="disabledCtrl" [isFeedBack]="true" type="password" #newPassword [labelName]="'New Password' | translate"
            [required]="true" formControlName="NewPassword" [maxlength]="50" [isLabelLeft]="false">
          </app-input>
          <div style="position: absolute; right: 16px;">
          </div>
        </div>

        <div class="col-md-12" style="padding: 0;">
          <app-input [isReadOnly]="disabledCtrl" [isFeedBack]="true" type="password" #confirmPassword [labelName]="'Confirm Password' | translate"
            [required]="true" formControlName="ConfirmPassword" [maxlength]="50" [isLabelLeft]="false">
          </app-input>
          <div style="position: absolute; right: 16px;">
          </div>
        </div>
        <div class="col-md-12 padding-0">
          <ng-template #passwordErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              {{'Please confirm your password!' | translate}}
            </ng-container>
            <ng-container *ngIf="control.hasError('ConfirmPassword')">
              {{'Password is inconsistent!' | translate}}
            </ng-container>
          </ng-template>
        </div>
      </div>

      <div class="row user-form-row" style="position: relative;">
        <div class="col-md-12 padding-0">
          <div class="divBtnClass">
            <app-button [labelName]="'Reset' | translate" typeButton="reset" (click)="onClearCtrl()" [disabledCtrl]="disabledCtrl"></app-button>
            <app-button [labelName]="'Ok' | translate" typeButton="save" (click)="onBuild()"
              style="padding-left: 0.5rem;" [disabledCtrl]="disabledCtrl"></app-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
