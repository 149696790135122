import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app-base/app.service';
import { OdataParams, RequestService } from 'app/core/services/request.service';
import { CoreTechTable } from 'app/um/config/um-tables';
import { combineLatest } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebdatarockService {
  constructor(
    private appService: AppService,
    private reqService: RequestService
  ) { this.reqService.switchType(this.appService.dataAccessType); }

  public queryTreeServices(params: any) {
    const ex: any = null;
    const result = this.reqService.service.query({
      url: this.appService.urlWS + '/' + 'Config/GetListConfigService',
      params,
      method: 'GET'
    }).pipe(map((res: any) => {
      const resp: SearchResponse = {
        success: false,
        model: [],
        message: 'Tìm thất bại'
      };
      if (res) {
        resp.success = true;
        resp.model = res;
        resp.message = 'Tìm kiếm thành công';
      }
      return resp;
    }), catchError(ex));
    return result;
  }
  
  public GetListConfigApplication(params: any) {
    const ex = null;
    const result = this.reqService.service.query({
      url: this.appService.urlWS + '/' + 'Config/GetListConfigApplication',
      params,
      method: 'GET'
    }).pipe(map((res: any) => {
      const resp: SearchResponse = {
        success: false,
        model: [],
        message: 'Tìm thất bại'
      };
      if (res) {
        resp.success = true;
        resp.model = res;
        resp.message = 'Tìm kiếm thành công';
      }
      return resp;
    }), catchError(ex!));
    return result;
  }

  public getdulieubaocao(url: any, typeservice: String, token: any) {
    switch (typeservice) {
      case 'SQL':
        this.reqService.switchType('sql');
        break;
      // case 'AutoData':
      //   this.reqService.switchType('autodata');
      //   break;
      case 'CloudData':
        this.reqService.switchType('clouddata');
        break;
      case 'FeatureServer':
      case 'MapServer':
      case 'WorkflowServices':
        this.reqService.switchType('arcgis3x');
        break;
      case 'postgrest':
        this.reqService.switchType('postgre');
        break;
      default:
        this.reqService.switchType('sql');
        break;
    }

    return this.reqService.service.search({
      url,
      where: [],
      logic: 'and',
      token: token
    }).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }

  public getdulieubaocaoForMobile(url: any, typeservice: any, token: any) {
    switch (typeservice) {
      case 'SQL':
        this.reqService.switchType('sql');
        break;
      // case 'AutoData':
      //   this.reqService.switchType('autodata');
      //   break;
      case 'CloudData':
        this.reqService.switchType('clouddata');
        break;
      case 'FeatureServer':
      case 'MapServer':
      case 'WorkflowServices':
        this.reqService.switchType('arcgis3x');
        break;
      case 'postgrest':
        this.reqService.switchType('postgre');
        break;
      default:
        this.reqService.switchType('sql');
        break;
    }

    return this.reqService.service.searchForMobile({
      url,
      where: []
    }, token).pipe(map(res => {
      this.reqService.switchType(this.appService.dataAccessType);
      return res;
    }));
  }

  public queryAllWebDatarock() {
    const p: OdataParams = {
      url: this.appService.urlWS + '/SysWindows', // Thông số bắt buộc
      where: ['WindowType', '=', 'ANALYSTFORM'], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public getTableByTableId(tblId: any) {
    const resp = this.reqService.service.search({
      url: this.appService.urlWS + `/` + CoreTechTable.SysTables,
      where: ['TableId', '=', tblId],
      logic: 'and'
    });
    return resp;
  }

  // column for web
  public getColumnByTableId(tblId: any) {
    this.reqService.switchType(this.appService.dataAccessType)
    const resp = this.reqService.service.search({
      url: this.appService.urlWS + `/` + CoreTechTable.SysColumn,
      where: ['TableId', '=', tblId],
      logic: 'and'
    });
    return resp;
  }

  // column for mobile
  public getColumnByTableIdForMobile(tblId: any, token: any) {
    const resp = this.reqService.service.searchForMobile({
      url: this.appService.urlWS + `/` + CoreTechTable.SysColumn,
      where: ['TableId', '=', tblId],
      logic: 'and'
    }, token);
    return resp;
  }

  public getSysCacheForWindowId(WindowId: any) {
    this.reqService.switchType(this.appService.dataAccessType)
    const p: OdataParams = {
      url: `${this.appService.urlWS}/SysCaches`, // Thông số bắt buộc, nhưng có thể để string rỗng '',
      where: ['WindowId', '=', WindowId], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.search(p);
  }

  public getSysCacheForWindowIdForMobile(WindowId: any, token: any) {
    const p: OdataParams = {
      url: `${this.appService.urlWS}/SysCaches`, // Thông số bắt buộc, nhưng có thể để string rỗng '',
      where: ['WindowId', '=', WindowId], // Thông số tùy chọn, [] là query hết, where có dạng: ['itemid', 'LIKE', '999'] hoặc [['itemid', 'LIKE', '999'], ['itemname', '=', 'koala']]
      startRecord: 0, // Thông số tùy chọn, Số thứ tự tìm kiếm, là pageSize*pageIndex,
      logic: 'and' // Thông số tùy chọn, mặc định là and, thông số điền vào là and hoặc or
    };
    return this.reqService.service.searchForMobile(p, token);
  }

  public insertSysWindowsWebDatarocks(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SysWindows',
      primaryKey: 'WindowId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }

  public insertSysCachesWebDatarocks(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SysCaches',
      primaryKey: 'CacheId',
      data: params
    };
    const resp = this.reqService.service.insert(odataParams);
    return resp;
  }

  public updateSysWindowsWebDatarocks(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SysWindows',
      primaryKey: 'WindowId',
      data: params
    };
    const resp = this.reqService.service.update(odataParams);
    return resp;
  }

  public updateSysCachesWebDatarocks(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SysCaches',
      primaryKey: 'CacheId',
      data: params
    };
    const resp = this.reqService.service.update(odataParams);
    return resp;
  }

  public deletelWebDatarocks(paramWindow: any, paramCache: any) {
    // const p: OdataParams = {
    //   url: this.url, // Thông số bắt buộc, nhưng có thể để string rỗng '',
    //   data: param, // Thông số bắt buộc, dữ liệu dạng object của một bản ghi, trong data phải có trường khóa chính
    //   primaryKey: 'sysThemeId' // Thông số bắt buộc, khóa chính của bảng
    // };

    // return this.reqService.service.delete(p);
    const list = [];

    const odataParamWindow = {
      url: this.appService.urlWS + '/SysWindows',
      primaryKey: 'WindowId',
      data: paramWindow
    };
    list.push(this.reqService.service.insert(odataParamWindow));

    const odataParamCache = {
      url: this.appService.urlWS + '/SysCaches',
      primaryKey: 'CacheId',
      data: paramCache
    };
    list.push(this.reqService.service.delete(odataParamCache));

    combineLatest(list).subscribe({
      next: (resp: any) => {
        return true;
      },
      error: (error) => {
        console.log(error);
        this.appService.alert(error);
      }
    });
  }

  public deleteSysCache(params: any) {
    const odataParams = {
      url: this.appService.urlWS + '/SysCaches',
      primaryKey: 'CacheId',
      data: params
    };
    const resp = this.reqService.service.delete(odataParams);
    return resp;
  }
}

export interface SearchResponse {
  model: Array<any>;
  success: boolean;
  message: string;
}

