import { AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TabView } from 'primeng/tabview';

@Component({
    selector: 'app-core-tab',
    templateUrl: './core-tab.component.html',
    styleUrls: ['./core-tab.component.scss']
})
export class CoreTabComponent implements OnInit, AfterContentChecked {

    // Binding two way properties: Input: <variable name>, Output: <variable name> + Change
    @ViewChild('tabView') tabView: TabView | any;
    @Input() tabs: any = [];
    @Input() keyIsClose: any = 'isClose';
    @Input() isLazyLoadContent: boolean = true;
    @Output() tabsChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeTabEvt: EventEmitter<any> = new EventEmitter<any>();
    @Output() activeTabIndexChange: EventEmitter<any> = new EventEmitter<any>();
    // @Input() title!: TemplateRef<any>;
    // @Input() content!: TemplateRef<any>;
    @Input() fieldDisabled: any = 'isDisable'
    @Input() isDisabledTab: any = false
    _title!: any;
    @Input()
    get title() {
        return this._title;
    }
    set title(tmp: any) {
        this.cdr.detectChanges();
        this._title = tmp;
    }
    _content!: any;
    @Input()
    get content() {
        return this._content;
    }
    set content(tmp: any) {
        this.cdr.detectChanges();
        this._content = tmp;
    }
    _activeTabIndex: any;
    @Input()
    get activeTabIndex() {
        return this._activeTabIndex;
    }

    set activeTabIndex(index) {
        this.cdr.detectChanges();
        this._activeTabIndex = index;
        this.activeTabIndexChange.emit(this._activeTabIndex);
    }

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
    }
    ngAfterContentChecked() {
        // this.cdr.detectChanges();
    }
    ngOnChanges(simples: SimpleChanges): void {
        // console.log(simples);
    }

    public onCloseTab(event: any) {
        // event.close();
        // this.tabs = this.tabs.filter((fil: any, index: any) => index !== event.index);
        // this.tabsChange.emit(this.tabs);
        this.closeTabEvt.emit(event);
    }
    onChangeTab(evt: any) {
        this.tabsChange.emit(evt)
    }

}
