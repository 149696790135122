import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { RequestService } from '../services/request.service';

@Component({
  selector: 'app-core-tree-select',
  templateUrl: './core-tree-select.component.html',
  styleUrls: ['./core-tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR
      , useExisting: forwardRef(() => CoreTreeSelectComponent)
      , multi: true
    },
    RequestService
  ]
})
export class CoreTreeSelectComponent implements OnInit {
  valueSelect: any = null
  emptyMessage: any = null
  ctrl: FormControl = new FormControl();
  @Input() hasButton: boolean = false
  @Output() btnCalcClick: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueModelChange: EventEmitter<any> = new EventEmitter();
  @Output() returnObj: EventEmitter<any> = new EventEmitter()
  @Output() zoomClick: EventEmitter<any> = new EventEmitter();
  @Output() Event: EventEmitter<any> = new EventEmitter();
  @Output() blurEvent: EventEmitter<any> = new EventEmitter();
  @Input() controlWidth = 'auto';
  @Input() placeholder = '';
  @Input() showLabel = true;
  @Input() labelName = '';
  @Input() labelWidth = '200';
  @Input() isLabelLeft = true;
  @Input() hasFormCtrl: boolean = true;
  @Input() isReturnObj: boolean = false;
  _valueTreeSelect: any = null
  _dataLookup: any = null
  @Input()
  set dataLookup(obj: any) {
    this._dataLookup = obj
    if (obj) {

      this.initDataLookUpFromDomain(obj)
    }

  }
  get dataLookup() {
    return this._dataLookup
  }
  private _dataSource = {};
  dataLookupBinding: any[] = [];
  @Input('dataSource')
  set dataSource(val: any) {
    this._dataSource = val;
    if (this.dataSource) {
      this.initDataLookUp()
    } else {
      // this.dataLookupBinding = []
    }

  }
  get dataSource() { return this._dataSource; }
  _valueModel: any = null;
  @Input()
  get valueModel() {
    return this._valueModel;
  }

  set valueModel(value) {
    if (this.required) {
      if (value !== null && value !== '') {
        this.requiredClass = false;
      } else {
        this.requiredClass = true
      }
    } else {
      this.requiredClass = false
    }
    this._value = value;
    // this.valueModelChange.emit(this._valueModel);
  }
  requiredClass = false;
  _required = false;
  @Input('required')
  set required(val: boolean) {
    this._required = val;
    if (val) {
      if (this.valueModel !== null && this.valueModel !== '') {
        this.requiredClass = false;
      } else {
        this.requiredClass = true
      }
    } else {
      this.requiredClass = false
    }

    if (this.ctrl) {
      this.ctrl.setValidators(val === true ? [Validators.required] : [Validators.nullValidator]);
    }
  }
  get required() {
    return this._required;
  }

  _disabled = false;
  @Input('disabledCtrl')
  set disabledCtrl(val: boolean) {
    this._disabled = val;
    if (this.ctrl) {
      // val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
    }
  }
  get disabledCtrl() {
    return this._disabled;
  }
  private _isReadOnly = false;
  @Input('isReadOnly')
  set isReadOnly(val: boolean) {
    this._isReadOnly = val;
    if (this.ctrl) {
      val ? this.ctrl.disable({ emitEvent: false }) : this.ctrl.enable({ emitEvent: false });
    }
  }
  get isReadOnly() {
    return this._isReadOnly;
  }
  private handleRequest: any = null;
  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };
  constructor(
    private reqService: RequestService,
    private cd: ChangeDetectorRef
  ) {
    this.initForm();
  }

  ngOnInit(): void {
  }
  initForm() {
    this.ctrl = new FormControl(null, {
      validators: this._required === true ? Validators.required : Validators.nullValidator
    });

    this.ctrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
      this.returnObj.emit(val && val !== '' ? val.itemData : null)
      this.onChange(val ? this.isReturnObj ? val.itemData : val.data : null);
      if (this._required) {
        if (val === null || val === '') {
          this.requiredClass = true;
        } else {
          this.requiredClass = false;
        }
      } else {
        this.requiredClass = false;
      }
    });
  }
  initDataLookUpFromDomain(obj: any) {
    const arr = obj.data
    const dataSource = obj.dataSource.FIELD_FILTER
    if (obj.dataSource.FIELD_TABLELINK === -1) {
      arr.forEach((item: any) => {
        if (item['PARENT_CODE'] === null) {
          this.dataLookupBinding.push({
            "label": item['DESCR'],
            "data": item['CODE'],
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
            itemData: item,
            children: []
          })
        }
      });
      this.buildChildrenTree(this.dataLookupBinding, arr, dataSource, true)
    } else {
      arr.forEach((item: any) => {
        if (item[dataSource.TREECOLUMN] === null) {
          this.dataLookupBinding.push({
            "label": item[dataSource.COLUMNDISPLAY],
            "data": dataSource.COLUMNKEY ? item[dataSource.COLUMNKEY] : item[dataSource.COLUMNCODE],
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
            itemData: item,
            children: []
          })
        }
      });
      this.buildChildrenTree(this.dataLookupBinding, arr, dataSource, false)
    }


  }
  initDataLookUp(_value?: any) {
    const url = this.dataSource.FOREIGNTABLE;
    switch (this.dataSource.SERVICE_TYPE) {
      // case 'AutoData':
      //     this.reqService.switchType('autodata');
      //     break;
      case 'CloudData':
        this.reqService.switchType('clouddata');
        break;
      case 'SQL':
        this.reqService.switchType('sql');
        break;
      case 'FeatureServer':
      case 'MapServer':
        this.reqService.switchType('arcgis3x');
        break;
      case 'postgrest':
        this.reqService.switchType('postgre');
        break;
      default:
        this.reqService.switchType('sql');
        break;
    }
    let where: any[] = [];
    if (this.handleRequest) {
      this.handleRequest.unsubscribe();
    }
    if (this.dataSource.WHERE) {
      where = where.concat(JSON.parse(this.dataSource.WHERE));
    }
    if (_value) {
      let key = this.dataSource.WHEREFIELDNAME;
      let whereparent = [[key, '=', _value[key]]];
      where = where.concat(whereparent)
    }
    this.dataLookupBinding = []
    this.handleRequest = this.reqService.service.search({
      url,
      // where: ['cap', '<', 3], 
      where: where,
      logic: 'and',
      orderBy: this.dataSource.COLUMNKEY ? [this.dataSource.COLUMNKEY] : [this.dataSource.COLUMNCODE]
    }).subscribe((res: any) => {
      if (res.success) {
        const lookup: any[] = [];
        res.features.forEach((item: any) => {
          if (item[this.dataSource.TREECOLUMN] === null) {
            this.dataLookupBinding.push({
              "label": item[this.dataSource.COLUMNDISPLAY],
              "data": this.dataSource.COLUMNKEY ? item[this.dataSource.COLUMNKEY] : item[this.dataSource.COLUMNCODE],
              "expandedIcon": "pi pi-folder-open",
              "collapsedIcon": "pi pi-folder",
              itemData: item,
              children: []
            })
          }
        });
        this.buildChildrenTree(this.dataLookupBinding, res.features, this.dataSource, false)
      }

      if (this.hasFormCtrl) {
        if (this._value !== null && this._value !== '') {
          this.findItemLookUp(this.dataLookupBinding, this._value)
          this.ctrl.setValue(this.valueSelect)
        } else {
          this.ctrl.setValue(null)
        }

      } else {
        if (this._value !== null && this._value !== '') {
          this.findItemLookUp(this.dataLookupBinding, this._value)
          this._valueModel = this.valueSelect
        } else {
          this._valueModel = null
        }

      }


      this.handleRequest.unsubscribe();
    }, (err: any) => {
      this.handleRequest.unsubscribe();
    });
  }

  buildChildrenTree(arr: any[], arrAll: any[], dataSource: any, isKvhc: boolean) {
    if (isKvhc) {
      arr.forEach((item: any) => {
        const arr_fil = arrAll.filter((v: any) => v['PARENT_CODE'] === item.data)
        arr_fil.forEach((chil: any) => {
          item.children.push({
            "label": chil['DESCR'],
            "data": chil['CODE'],
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
            itemData: chil,
            children: []
          })

        })
        this.buildChildrenTree(item.children, arrAll, dataSource, isKvhc)
      })
    } else {
      arr.forEach((item: any) => {
        const arr_fil = arrAll.filter((v: any) => v[this.dataSource.TREECOLUMN] === item.data)
        arr_fil.forEach((chil: any) => {
          item.children.push({
            "label": chil[this.dataSource.COLUMNDISPLAY],
            "data": this.dataSource.COLUMNKEY ? chil[this.dataSource.COLUMNKEY] : chil[this.dataSource.COLUMNCODE],
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
            itemData: chil,
            children: []
          })

        })
        this.buildChildrenTree(item.children, arrAll, dataSource, isKvhc)
      })
    }

  }

  findItemLookUp(arr: any, val: any) {
    arr.forEach((item: any) => {
      if (item.data === val) {
        this.valueSelect = item
      }
      this.findItemLookUp(item.children, val)
    })
  }
  onChangeValue(evt: any) {
    this.valueModelChange.emit(this.isReturnObj ? evt.node.itemData : evt.node.data)
    this.valueChange.emit(this.isReturnObj ? evt.node.itemData : evt.node.data)
  }
  _value: any = null
  writeValue(_value: any): void {
    this._value = _value
    if ((this.dataSource || this.dataLookup) && this._value !== null && this._value !== '') {
      if (this.dataSource.WHEREFIELDNAME && this.dataSource.WHEREFIELDNAME.length > 0) { // trường hợp là field Liên kết
        // this.getLookupFromParent(_value);
        this.initDataLookUp(_value)
    }  else {
        this.findItemLookUp(this.dataLookupBinding, this._value)
        this.ctrl.setValue(this.valueSelect)
      }

    } else {
      this.ctrl.setValue(null)
    }

    // if (_value === null || _value === '') {
    //   this.ctrl.setValue(null)
    // }
    if (this._required) {
      if (_value === null || _value === '') {
        this.requiredClass = true;
      } else {
        this.requiredClass = false;
      }
    } else {
      this.requiredClass = false;
    }
  }
  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
}
